import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article38/01.jpg";
import photo02 from "../../images/mag/article38/02.jpg";
import photo03 from "../../images/mag/article38/03.jpg";
import photo04 from "../../images/mag/article38/04.jpg";
import photo05 from "../../images/mag/article38/05.jpg";
import photo06 from "../../images/mag/article38/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article38 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "سرقفلی مغازه حقی است که قانون گذار برای مستاجرین مراکز و املاک تجاری تعیین کرده تا به نوعی آن‌ها را تحت حمایت و حفاظت خود قرار داده باشد.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = " سرقفلی مغازه به زبان ساده ، حق سرقفلی یعنی چی ، تفاوت سرقفلی و حق کسب و پیشه ، فرق مالکیت و سرقفلی ، صفر تا صد سرقفلی و نکات آن ، سرقفلی ملک تجاری ، قوانین و مقررات سرقفلی "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[37].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[37].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[37].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[37].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[37].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[37].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="سرقفلی - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[37].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی داریم به تمامی سوالات شما در رابطه با سرقفلی مغازه، تاریخچه و قوانین مربوط پاسخ دهیم. پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سرقفلی مغازه حقی است که قانون گذار برای مستاجرین مراکز و املاک تجاری تعیین می کند تا به نوعی آن‌ها را تحت حمایت و حفاظت خود قرار دهد. در قوانین قدیمی از این حق با عنوان حق کسب و پیشه یا تجارت نام می بردند. در حالی که مطابق با قوانین تازه تفاوت‌هایی میان حق سرقفلی و حق کسب و پیشه وجود دارد. این حق به طور کامل برای اماکن و مراکز و املاک تجاری تدارک دیده شد و لذا نمی‌توان از آن به عنوان حقی برای اماکن مسکونی استفاده کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله به دنبال پاسخگویی به این پرسش هستیم که سرقفلی مغازه چیست و تمایل داریم صفر تا صد سرقفلی مغازه و نکات ملک تجاری را بیان کنیم و به بررسی تمام نکات حقوقی سرقفلی اقدام نماییم. پس اگر شما هم قصد خرید و فروش سرقفلی دارید مطالعه این مقاله از سایت املاک و مستغلات هومینجا را دست ندهید .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                سرقفلی یعنی چه ؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="سرقفلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سرقفلی یعنی چه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                توضیح سرقفلی به زبان ساده را با ذکر یک مثال آغاز می‌نماییم. فرض کنید فردی یک دهنه مغازه را از یک مالک اجاره نموده است و در آن کسب و کاری را راه اندازی می‌کند. او با سعی و تلاش فراوان شهرتی برای خود فراهم آورده و مشتریان زیادی هم پیدا می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از مدتی اعتبار قرارداد او با مالک به اتمام می رسد و باید از آن مغازه برود و یا مالک تصمیم می گیرد او را از مغازه بیرون کند و از شهرت و اعتبار مستاجر به نفع خود استفاده کند. به همین دلیل قانونگذار سعی دارد با قانون سرقفلی، این حق و یا ارزش افزوده پنهان را برای مستاجر اماکن تجاری حفظ کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس می‌توان سرقفلی را همان حق کسب و پیشه به شمار آورد. در حالی که سرقفلی نمی‌تواند مدت زمان اجاره را به صورت خودکار تمدید کند. پس باید در هنگام عقد قرارداد و دریافت <Link className="link" to="/house-tracking-code" target="_blank"> کد رهگیری </Link>، مالک و مستاجر در این خصوص با یکدیگر به توافقاتی دست پیدا کنند. اگر در این زمینه به توافق دست نیافته باشید، در هنگام تخلیه ملک خود نیز نمی‌توانید ادعایی را مطرح کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                سرقفلی به زبان ساده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به عبارت ساده‌تر می‌توان گفت سرقفلی حقی است که بر اساس آن یک مستاجر قادر است از یک ملک تجاری با پرداخت مبلغی اندک با عنوان اجاره بها استفاده کند. سپس با اتمام مدت زمان اجاره به تصرف آن ملک ادامه دهد. حق سرقفلی جز اموال غیر منقول به حساب می آید و نوعی حق شرعی تلقی می‌شود. این حق نزد <LinkMui className="link" href="https://www.shora-gc.ir/" target="_blank">  شورای نگهبان </LinkMui> اعتبار داشته و می‌توان آن را به عنوان ضمانت مورد استفاده قرار داد. این حق قابل توقیف نیز است .
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت حق سرقفلی و حق کسب پیشه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="حق کسب و پیشه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  حق کسب و پیشه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                حق کسب پیشه پیش از عقد اجاره وجود ندارد و  با عقد اجاره برای مستاجر به وجود خواهد آمد. از سوی دیگر این حق مخصوص مستاجران بوده و می‌توان آن را با<Link className="link" to="/property-document" target="_blank"> سند </Link> رسمی انتقال داد. در حالی که برای سرقفلی چنین امکان و یا شرطی وجود نداشته و می‌توان آن را با یک قرارداد کاملاً عادی نیز منتقل ساخت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سرقفلی به محل کسب و کار و یا مکان تجاری مربوط می‌شود. در حالی که حق کسب پیشه به کاسب و یا شغل تجاری باز می‌گردد. در حقیقت حق کسب پیشه را می‌توان به مکان و یا محلی اختصاص داد که دارای کاسب است. این در حالی است که حق سرقفلی هیچگونه توجهی به کسب و کاسب ندارد و کاملاً ثابت باقی خواهد ماند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                حق سرقفلی یک مغازه به عرف محل وابسته می باشد و در اختیار موجر و یا مالک است. پس مستاجر در تعیین آن نقشی بر عهده نخواهد داشت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالی که حق کسب و پیشه مطابق با قوانین و شرایط مطرح می شود به طور کلی به منظور حفاظت و حمایت از حقوق اشخاص حقیقی و یا حقوقی در نظر گرفته می شود و قضات و یا محاکم می‌توانند بر اساس نظرات کارشناسی مبالغ حق کسب پیشه را مشخص سازند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای فهم بهتر این موضوع به ذکر مثالی خواهیم پرداخت.
              </Typography>
              <Typography className="h3" variant="h3">
                ذکر مثال واقعی از تعریف سرقفلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فرض نمایید که شما یک <Link className="link" to="/Listing/1047" target="_blank"> مغازه </Link> را به اجاره خود در آورده‌اید. اما حق سرقفلی آن را به مالک پرداخت نکرده و چند سال پشت سر هم سال اجاره نامه را تمدید نموده‌اید. حالا در مغازه شما یک کسب و کار موفق به راه اندازی می شود. در حالی که مالک تمایل ندارد اجاره شما را تمدید کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در چنین شرایطی شما می‌توانید دو راهکار را مورد استفاده قرار دهید. یکی از این راهکارها این است که شما به عنوان یک مستاجر حق کسب پیشه خود را به مغازه دیگری انتقال دهید. چرا که خودتان با تلاش و پشتکارتان آن را به دست آورده‌اید. اما از آنجایی که در موقع عقد قرارداد مبلغی را به عنوان حق سرقفلی به موجر و یا مالک پرداخت نکرده‌اید نمی‌توانید انتظار دریافت پول از وی داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از سوی دیگر مالک هم نمی‌تواند با کسب و کار شما برای خود تجارتی راه اندازی کرده و یا آن را به فردی اجاره دهد و یا با نام و عنوان کسب و کار شما به فعالیت تجاری مشغول شود. در این صورت و در اصطلاح شما، حق کسب و پیشه را با خود به محل مغازه دیگری انتقال داده‌اید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                راهکار دومی که پیش رو دارید این است که با مالک مغازه به توافق برسید و حق کسب و پیشه خود را به او واگذار کنید و در ازای آن مبلغی دریافت نمایید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این شرایط هم باید میان دو طرف معامله نوعی <Link className="link" to="/transferring-property" target="_blank"> سند رسمی تنظیم</Link> و امضا شود و سپس مالک و یا موجر در محل مورد نظر کسب و کار شما را با نام و عنوان سابق ادامه دهد. پس در این صورت و در اصطلاح در این شرایط بیان می‌شود که مستاجر حق کسب و پیشه خود را به موجر منتقل کرده است. در واقع خرید سرقفلی در چنین وضعیتی اتفاق می‌افتد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                انواع سرقفلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالت معمول سرقفلی را به دو دسته اصلی تقسیم بندی می‌کنند. این دو دسته عبارتند از: سرقفلی واقعی و سرقفلی مجازی.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ادامه به این موضوع خواهیم پرداخت که سرقفلی مجازی و سرقفلی واقعی چیست.
              </Typography>
              <Typography className="h3" variant="h3">
                سرقفلی واقعی یا حق سرقفلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سرقفلی واقعی که  حق سرقفلی نیز نام دارد به امکانات محل یک کسب و کار بستگی دارد  و نوع کسب و کار در آن بی‌تأثیر است. به طور کلی می‌توان گفت سرقفلی یک مغازه مبنای قراردادی داشته و امتیاز ویژه‌ای است که مالک با عقد قرارداد به مستاجر خود واگذار کرده و در ازای آن پولی را دریافت می‌نماید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نوع از مستاجران، مالکان سرقفلی نام دارند. تمامی دفاتر اسناد رسمی موظف هستند شرایط عمومی تنظیم سند سرقفلی واقعی را مراعات نمایند. علاوه بر آن، برای سند اجاره اماکن تجاری تصریح کنند که عقد اجاره با سرقفلی صورت پذیرفت و یا بدون آن است .
              </Typography>
              <Typography className="h3" variant="h3">
                سرقفلی مجازی یا حق کسب و پیشه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حق کسب و پیشه سر و کار ما با میزان تلاش و موفقیتی است که در کسب و کار و حسن شهرت یک مغازه ایجاد می شود. از این حق در برخی از کتب به نام حقوق تجاری یاد می شود و یا به آن سرقفلی مجازی نیز اطلاق می‌گردد.
              </Typography>
              <Typography className="h2" variant="h2">
                قوانین مربوط به سرقفلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="قوانین سرقفلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قوانین و نکات سرقفلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رهن و اجاره سرقفلی از پیچیدگی‌های مخصوص به خود برخوردار بوده و تابع قوانین و شرایط منحصر به فردی هم است. در واقع باید بدانید که چگونه در هنگام عقد قرارداد به توافق درباره سرقفلی دست پیدا کنید. همچنین باید نحوه انتقال سرقفلی را بدانید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهمترین شرایط و قوانین مربوط به قراردادهای سرقفلی مغازه، ایجاد توافق میان مالک و مستاجر ملک است. این توافق به اشکال مختلفی روی می­دهد. برای نمونه ممکن است در هنگام بستن قرارداد، مستاجر ملک مبلغی را با عنوان سرقفلی به مالک پرداخت کند و  در اصطلاح به خرید سرقفلی دست زده باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این شرایط مالک و یا موجر هم وظیفه دارد در هنگام تخلیه ملک مورد نظر به محاسبه قیمت سرقفلی، آن هم به قیمت روز اقدام نموده و آن مبلغ را به مستاجر پرداخت کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این صورت باید قرارداد شما حتماً عنوان اجاره را داشته باشد. از سوی دیگر مستاجر نیز باید تصریح کند که مبلغ مورد نظر تنها با عنوان سرقفلی به موجر پرداخت شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مالک و مستاجر می‌توانند به این توافق دست پیدا کنند که مالک باید تا زمانی که مستاجر تمایل دارد قرارداد را با وی تمدید کند و این کار را هر ساله و با همان نرخ سابق انجام دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این شرایط هم در صورتی که مهلت قرارداد خاتمه پیدا کند و در صورت عدم تمایل مالک به تمدید قرارداد، لازم است مبلغی را به عنوان سرقفلی به مستاجر پرداخت نماید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                نحوه محاسبه قیمت سرقفلی از طرف دادگاه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="قیمت سرقفلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  محاسبه قیمت سرقفلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دادگاه با در نظر گرفتن چند عامل مبلغ سرقفلی را محاسبه می‌کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله این عوامل می‌توان به موارد زیر اشاره کرد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                موقعیت محل کسب و یا تجارت و میزان مرغوبیت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شرایط اجاره و کیفیت و مزایایی که در قرارداد اجاره ملک تعیین شده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                طول مدت اشتغال مستاجر به کسب و کار
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حسن شهرت او و اعتباراتی که به دست آورده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                وضعیت محل اجاره از لحاظ بنا و ساختار
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مخارجی که مستاجر به منظور آماده سازی ملک انجام داده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                قفسه بندی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تزیینات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نوع کسب و کار و ...
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت سرقفلی مغازه و حق مالکیت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مالکیت به یک حق تمام و کمال اطلاق می‌گردد. یک مالک حق دارد هرگونه دخل و تصرفی را در مال خود انجام دهد. این در حالی است که سرقفلی تنها به بحث اجاره مرتبط است و آن هم اجاره اماکن تجاری. در واقع سرقفلی به منفعت محل اجاره ارتباط پیدا می‌کند. در حالی که مالکیت به عین مال مرتبط میشود و به منفعت آن ارتباطی ندارد.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط و نحوه خرید سرقفلی مغازه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/Listing/612" target="_blank"> خرید </Link> مغازه با سرقفلی تشریفات و شرایط ویژه خود را دارد. بر اساس قراردادهای سرقفلی مغازه، مستاجران می‌توانند در ابتدا مبلغی را به مالک پرداخت نمایند و بر اساس آن اجاره بهای کمی را نیز در طول دوره پرداخت کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از سوی دیگر مالک نیز موظف می‌شود که با اتمام مدت زمان اجاره ملک، با همان مستاجر قرارداد را تمدید کند. یعنی مستاجر نسبت به دیگران حق تقدم و اولویت دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نکته‌ای که باید به آن دقت داشته باشید این است که پس از خرید سرقفلی یک مغازه باز هم بیشتر اختیارات آن در مورد ملک به صاحب و یا مالک اختصاص پیدا می‌کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس خریدار نمی‌تواند بدون اجازه مالک تغییری در ملک بدهد. همچنین خریدار در حوزه تغییر کسب و کار و یا فروش و انتقال آن به فرد دیگر ندارد. تمامی این شرایط با توجه به توافقاتی که میان طرفین انجام می‌شود قابل تغییر و تحول است. نحوه انتقال سرقفلی هم می‌تواند به صورت اختیاری، قهری و یا قضایی باشد. برای انجام این کار باید به دفاتر اسناد رسمی مراجعه کرده و مدارکی از قبیل شناسنامه و کارت ملی معتبر دو طرف، اصل سند مالکیت و اصل اجاره نامه، استعلام سرقفلی از دفترخانه صادر کننده اجاره نامه، گواهی مالیات مشاغل از اداره دارایی ، استعلام  از بانک در ارتباط گرو نبودن سند برای <Link className="link" to="/way-to-get-house-loan" target="_blank"> وام </Link> و  ... را ارائه دهید.
              </Typography>
              <Typography className="h2" variant="h2">
                ریشه و تاریخچه املاک تجاری و سرقفلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در کتب فقهی و حقوقی در مورد تعریف سرقفلی و یا ریشه و تاریخچه آن صحبتی به میان نیامده است. با این که حق سرقفلی در فقه ریشه دارد. در قانون سال ۱۳۵۶، با عنوان حق کسب و پیشه یا تجارت یاد شده بود. در حالی که در سال ۱۳۷۶ قانون جدیدی شکل گرفت و تغییراتی هم در آن صورت پذیرفت تا به شکل حق سرقفلی امروزی درآمد.
              </Typography>
              <Typography className="h2" variant="h2">
                دعاوی‌های مربوط به سرقفلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="دعاوی سرقفلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دعاوی سرقفلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در زمینه حقوق سرقفلی ممکن است چند دعوی شکل بگیرد. برای نمونه در صورتی که ملک تجاری نباشد و مالک به دریافت سرقفلی اقدام نموده باشد، مطابق با <LinkMui className="link" href="https://rc.majlis.ir/fa/law/show/92943" target="_blank">  قوانین </LinkMui> روابط موجر و مستاجر مصوب در سال ۱۳۷۶ سرقفلی میزان مبلغی خواهد بود که در ازای اجاره دادن ملک به مالک تعلق پیدا می‌کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این قانون به صراحت اعلام کرد که دریافت سرقفلی در ازای اجاره املاک غیرتجاری خلاف قوانین و موازین می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر مستاجری این مبلغ را به موجر پرداخت کرد این امکان را دارد که مبلغ پرداختی را پس بگیرد. در این صورت مستاجر قادر به طرح دعوای استرداد مبلغی که با عنوان سرقفلی پرداخت کرده است، خواهد بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در صورت تقاضای تخلیه ملک از جانب موجر نیز امکان طرح دعوی وجود خواهد داشت. ممکن است مالک بخواهد بنا به دلایلی از قبیل تغییر شغل، انتقال به غیر، احداث بنا و یا بنابه دلایل و نیازهای شخصی، تقاضای تخلیه ملک را به مستاجر ارائه دهد که در این صورت هم وی می‌تواند طرح دعوی ارائه دهد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مهم در مورد خرید و فروش ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا درتلاش است تا همواره اطلاعات مناسبی در اختیار کاربران قرار دهد. با دنبال کردن <Link className="link" to="/" target="_blank"> وب سایت</Link> و هم چنین <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا</Link> از اخرین اخبار و مطالب جذاب ما مطلع شوید. ما در این مقاله سعی کردیم شما را با موضوع سرقفلی و نکات حقوقی آن بیشتر آشنا کنیم.
              </Typography>


              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article38;