import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article39/01.jpg";
import photo02 from "../../images/mag/article39/02.jpg";
import photo03 from "../../images/mag/article39/03.jpg";
import photo04 from "../../images/mag/article39/04.jpg";
import photo05 from "../../images/mag/article39/05.jpg";
import photo06 from "../../images/mag/article39/06.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article39 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "از آنجایی که کودکان روحیه حساس و لطیفی دارند و وقت زیادی را در اتاق خواب خود می گذرانند بنابراین دکوراسیون اتاق کودک بسیار مهم می باشد.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "تزئین دکور اتاق کودک ، نکات و ترفندهای مهم در طراحی اتاق کودک ، ایده های جذاب برای دکوراسیون اتاق کودک ، مدل های خاص و جذاب برای دکور اتاق کودک ، طراحی داخلی اتاق پسر و دختر کودک "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[38].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[38].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[38].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[38].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[38].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[38].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="اتاق کودک - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[38].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجایی که کودکان روحیه بسیار حساس و لطیفی دارند و وقت زیادی را در اتاق خواب خود می گذرانند بنابراین دکوراسیون و چیدمان اتاق کودک از اهمیت بالایی برخوردار است.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دکوراسیون اتاق کودک بسیار حائز اهمیت است. کودکان تمایل دارند در اتاقی بازی کنند که با توجه به علاقه آنها چیدمان شده باشد. یکی از مهم ترین مسائل در چیدمان اتاق کودک حفظ انرژی و هیجان فضای اتاق می باشد. وسایلی که برای اتاق کودک انتخاب می شود باید از رنگ آمیزی و نقاشی های زیبا و جذاب باشند. هومینجا به شما نکاتی جالب در این مورد پیشنهاد می دهد. پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                چیدمان اتاق کودک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="چیدمان اتاق کودک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  چیدمان اتاق کودک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دکوراسیون اتاق کودک  باید بر اساس اهداف خاصی چیده شود مانند اتاق بازی، اتاق مطالعه و خلوت نشینی. اما مهمترین هدف در طراحی اتاق کودک، فراهم آوردن مکانی راحت و امن برای کودک می باشد. این موضوع از نکات مهم دکوراسیون اتاق کودک می باشد که در پایان یک روز شلوغ، بتواند در اتاقش به خوابی عمیق و با ارامش برود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/small-house-decoration" target="_blank">
                  بیشتر بخوانید : دکوراسیون خانه های کوچک
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نکات مهم در دکوراسیون اتاق کودک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی اتاق کودک بر روحیه کودکان تاثیرگذار است. اتاق کودک باید به گونه ای چیدمان شود که باعث رشد استعدادها و توانایی های کودک شود. چنانچه دکوراسیون اتاق بدرستی انجام نشود ممکن است سبب پرخاشگری کودک شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                استانداردهای خاصی وجود دارند که برای طراحی اتاق کودک استفاده می شوند. علاوه بر رعایت این استانداردها فضای اتاق کودک باید دارای محیطی شاد باشد. ما در زیر به بررسی یکسری از این استانداردها می پردازیم. هم چنین مطالعه مقاله <Link className="link" to="/fengshui" target="_blank"> فنگ شویی </Link> دید بهتری از نحوه چیدمان به شما ارایه می دهد.
              </Typography>
              <Typography className="h3" variant="h3">
                نور :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است اتاقی را برای کودک در نظر بگیرید که از نور کافی برخوردار باشد. نور طبیعی در رشد کودکان تاثیر بسیار زیادی دارد. برای تنظیم بهتر نور اتاق از پرده های کرکره ای استفاده کنید. چنانچه قصد دارید از پرده های پارچه ای استفاده کنید مدلی را انتخاب کنید که الیاف طبیعی داشته باشد. همچنین بهتر است نور مهتابی را برای روشنایی اتاق فرزند خود در نظر بگیرید. هم چنین مطالعه مقاله انتخاب <Link className="link" to="/chandelier-in-decoration" target="_blank"> لوستر </Link> مناسب می تواند در این زمینه به شما کمک کند.
              </Typography>
              <Typography className="h3" variant="h3">
                کفپوش :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کفپوش اتاق کودک بهتر است رنگ روشن باشد تا فضای اتاق را دلباز نشان دهد. همچنین کفپوش اتاق باید نرم انتخاب شود تا کودک هنگام بازی آسیب نبیند. بهتر است کفپوش قابلیت شستشو داشته باشد تا در صورت کثیفی راحت تمیز شود.
              </Typography>
              <Typography className="h3" variant="h3">
                کاغذ دیواری :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کاغذ دیواری اتاق کودک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاغذ دیواری اتاق کودک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                می توان گفت کاغذ دیواری در طراحی اتاق کودک بسیار کاربردی می باشد. کاغذ دیواری جذاب علاوه بر زیبایی اتاق کودک، سبب افزایش خلاقیت کودکان نیز می شود. همچنین انتخاب کاغذ دیواری نامناسب سبب می شود تا ریز گردهایی در فضا پخش شوند. این گرده ها برای ریه کودکان مضر هستند. بنابراین سعی کنید از جنس مرغوب کاغذ دیواری برای اتاق کودکان استفاده کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال چیست ؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                رنگ :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است برای رنگ اتاق کودک از رنگ های شاد و ملایم استفاده کنید. رنگ های تیره سبب افسردگی در فرزندان می شوند. همچنین رنگ های تند، پرخاشگری را برای کودکان به ارمغان می آورند. رنگ های ملایم مانند سبز روشن، آبی آسمانی و لیمویی گزینه های مناسبی برای اتاق کودک هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                اسباب بازی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که بچه ها عاشق اسباب بازی هستند انتخاب اسباب بازی مورد علاقه کودکان موجب آرامش و نشاط در آنها می شود. بنابراین بهتر است از کودکان بخواهیم تا اسباب بازی دلخواهشان را انتخاب کنند و در قفسه های اتاقشان به سلیقه خود قرار دهند.
              </Typography>
              <Typography className="h2" variant="h2">
                طراحی اتاق دختران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="طراحی اتاق دختران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  طراحی اتاق دختران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دخترها به رنگ اتاق بسیار توجه می کنند. برای طراحی اتاق دختر بچه ها می توانید رنگ های کرم، لیمویی، یاسی و صورتی را بکار ببرید. چنانچه قصد دارید در دکوراسیون اتاق دخترتان کاغذ دیواری باشد بهتر است از طرح هایی استفاده کنید که با روحیه لطیف دختر بچه ها سازگار باشد. برای طرح <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> روتختی </Link> و پرده می توانید از مدل های گل دار بهره ببرید. دختر بچه ها طرح های فانتزی و مدرن دوست دارند. بنابراین پیشنهاد می کنیم که کاراکترهای دخترانه و عروسکی مثل خرسی ها را در اتاقشان بکار ببرید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/pouf" target="_blank">
                  بیشتر بخوانید : پاف چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                طراحی اتاق پسران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="طراحی اتاق پسران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  طراحی اتاق پسران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای انتخاب رنگ اتاق پسر بچه ها بهتر است سراغ رنگ های سبز و آبی روشن بروید. از آنجا که پسرها عاشق تم های ورزشی و ماجراجویانه هستند بهتر است برای دکوراسیون اتاقشان از این طرح ها استفاده کنید. اگر فرزندتان به ورزش خاصی علاقه دارد می توانید پوسترهای مربوط به آن را تهیه کنید و به دیوار بزنید. همچنین تابلو با کاراکترهای کارتونی و هوافضا و همچنین حیات وحش ایده جذابی برای دیوار اتاق پسران می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چیدمان اتاق پسر بچه ها باید به گونه ای باشد که فضای کافی برای شیطنت و بازی های تکی و دورهمی فراهم شود .
              </Typography>
              <Typography className="h2" variant="h2">
                چند ایده جذاب برای اتاق کودک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="شلف و قفسه کودک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قفسه بندی اتاق کودک
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تاب از بازی های مورد علاقه کودکان می باشد. داشتن تاب در اتاق رویای هر کودکی است. امروزه اضافه کردن یک تاب در فضای اتاق دیگر کار دشواری نیست. چنانچه اتاق کودک فضای کافی دارد می توانید یک تاب در مرکز اتاق نصب کنید. محل قرار گیری تاب باید به گونه ای باشد که مزاحمتی برای رفت و آمد و بازی بچه ها نداشته باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بهتر است در اتاق کودک از شلف و  قفسه استفاده کنید. برای اینکه بچه ها بتوانند وسایل خود را در فضای مناسبی قرار دهند نیاز به قفسه هایی است که در ارتفاع مناسب قرار داشته باشند. قفسه های چند ضلعی انتخاب کنید تا فضای اتاق جذاب تر شود. همچنین می توانید قفسه های رنگی بکار ببرید تا فضای اتاق کودکانه تر شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                چنانچه دیوارهای اتاق کودک ساده انتخاب شده می‌توانید با عکس‌هایی از خود کودک یا هر چه که مورد علاقه کودک می باشد  اتاق را زیباتر کنید. پیشنهاد دیگر این است که با توجه به سن کودک می‌توانید با نصب یک تخته سیاه زیبا او را به نقاشی و یادگیری تشویق کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/laundry-room" target="_blank">
                  بیشتر بخوانید : لاندری روم چیست ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا همواره درتلاش است تا مطالب جالبی در اختیار کاربران قرار دهد. با دنبال کردن <Link className="link" to="/" target="_blank"> وب سایت </Link> و هم چنین <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link> از این مطالب بهره ببرید. ما در این مقاله سعی کردیم تا به شما ایده های جذابی برای دکوراسیون اتاق کودکان دلبندتان پیشنهاد دهیم. امیدواریم این مطلب در چیدمان اتاق زیبای فرزند شما، مفید و کارآمد واقع شود.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش خانه ملک آپارتمان پنت هاوس در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره خانه ملک آپارتمان پنت هاوس در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article39;