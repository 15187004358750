import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article29/01.jpg";
import photo02 from "../../images/mag/article29/02.jpg";
import photo03 from "../../images/mag/article29/03.jpg";
import photo04 from "../../images/mag/article29/04.jpg";
import photo05 from "../../images/mag/article29/05.jpg";
import photo06 from "../../images/mag/article29/06.jpg";
import photo07 from "../../images/mag/article29/07.jpg";
import photo08 from "../../images/mag/article29/08.jpg";
import photo09 from "../../images/mag/article29/09.jpg";
import photo10 from "../../images/mag/article29/10.jpg";
import photo11 from "../../images/mag/article29/11.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article29 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "تهران شهری چهارفصل است که گشت‌وگذار در آن بخصوص در فصل زمستان می‌تواند یکی از لذت‌بخش‌ترین تفریحات برای افراد باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "مکانهای دیدنی تهران در زمستان | بهترین جاهای دیدنی تهران در زمستان | جاذبه های گردشگری تهران در زمستان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[28].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[28].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[28].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[28].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[28].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[28].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="تفریحات زمستانی تهران" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[28].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                ۱ ) پیست اسکی <LinkMui className="link" href="https://www.tochal.org/" target="_blank" rel="noreferrer">توچال</LinkMui> :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="پیست توچال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیست اسکی توچال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیست اسکی توچال در شمال تهران قرار گرفته است . به سبب دسترسی آسان و
                پارکینگ بزرگ در زمستان جزء شلوغ‌ترین پیست‌های تهران محسوب می‌شود. یکی از اولین گزینه ها برای تفریحات زمستانی تهران می باشد.
                <br />
                در انتهای خیابان ولنجک تابلوی پارکینگ توچال قابل مشاهده است.
                <br />
                یک روز خاطره انگیز با مجموعه ای از تفریحات منتظر شماست.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : کافه کتاب
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                تفریحات توچال :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تله کابین توچال، اسنوموبیل توچال، شبیه ساز وی آر توچال، تله سی یژ چشمه، سورتمه و ….
              </Typography>
              <Typography className="h2" variant="h2">
                ۲ ) پیست اسکی شمشک :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="پیست شمشک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیست اسکی شمشک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیست شمشک بیشتر ماه سال پوشیده از برف است. یکی از حرفه ای ترین پیست های کشور می باشد که بلندی آن به بیش از 3 هزار متر می رسد.
                <br />
                موقعیت پیست در 57 کیلومتری شمال شرق <Link className="link" to="tehran-zones" target="_blank">تهران</Link> قرار دارد.
                محل آن در شهر شمشک دربندسر از توابع رودبار قصران شهرستان شمیرانات استان تهران واقع شده است.
              </Typography>
              <Typography className="h3" variant="h3">
                امکانات رفاهی ورزشی پیست <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%BE%DB%8C%D8%B3%D8%AA_%D8%A7%D8%B3%DA%A9%DB%8C_%D8%B4%D9%85%D8%B4%DA%A9" target="_blank" rel="noreferrer">شمشک</LinkMui> :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امکاناتی نظیر دو دستگاه تله سیژ، دو دستگاه تله اسکی بشقابی و نورافکنهای عظیم زرد رنگ را می توان از این مجموعه نام برد.
                <br />
                وجود دو هتل و چندین رستوران، سفر خاطره انگیزی را برای علاقه مندان رقم می زند.
              </Typography>
              <Typography className="h2" variant="h2">
                ۳ ) پیست اسکی <LinkMui className="link" href="http://dizinskiresort.ir/?ln=fa" target="_blank" rel="noreferrer">دیزین</LinkMui> :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="پیست دیزین - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیست اسکی دیزین
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دیزین بهترین گزینه برای اسکی بازی است. پیست دیزین مجهزترین پیست اسکی ایران نیز به شمار می رود. این پیست شامل ۴ تله كابين
                ، ۲ تله سيژ، ۹ تله اسكی بشقابی و ۱ تله اسكی چكشی است. در تابستان نیز پیست دیزین برای اسکی روی چمن استفاده می شود.
                <br />
                در ۸۰ کیلومتری جاده چالوس بعد از عبور از هتل گچسر خروجی ولایت رود، دیزین قرار دارد. این جاده در اصل مسیر دیزین است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                امکانات و <LinkMui className="link" href="https://skicomplex.ir/" target="_blank" rel="noreferrer noopener">تفریحات پیست اسکی دیزین</LinkMui> :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله امکانات پیست دیزین، می توان به تله کابین، تله سیژ، تله
                اسکی بشقابی، تله اسکی چرخشی، زمین تنیس، مدرسه اسکی، اسنوموبیل، رستورانهای دیزین، تیراندازی، کایت سواری، پاراگلایدر اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                ۴ ) پیست اسکی <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%BE%DB%8C%D8%B3%D8%AA_%D8%A7%D8%B3%DA%A9%DB%8C_%D8%A2%D8%A8%D8%B9%D9%84%DB%8C" target="_blank" rel="noreferrer noopener">آبعلی</LinkMui> :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="پیست آبعلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیست اسکی آبعلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر تفریحات زمستانی تهران می توان به پیست آبعلی اشاره کرد. پیست اسکی آبعلی قدیمی‌ترین پیست‌ کشور است که امکانات قابل‌قبولی برای بازدیدکنندگان دارد.
                <br />
                <LinkMui className="link" href="https://www.google.com/maps/place/%D9%BE%DB%8C%D8%B3%D8%AA+%D8%A7%D8%B3%DA%A9%DB%8C+%D8%A2%D8%A8%D8%B9%D9%84%DB%8C%E2%80%AD/@35.7681695,51.9877295,17z/data=!3m1!4b1!4m5!3m4!1s0x3f91d3032506c629:0xf7d1af90067c5128!8m2!3d35.7681695!4d51.9877295?hl=fa"
                  target="_blank" rel="noreferrer noopener">ادرس پیست ابعلی
                </LinkMui> تهران،
                شهرستان دماوند، رودهن، آبعلی، جاده هراز می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                امکانات و تفریحات پیست اسکی آبعلی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امکانات پیست آبعلی شامل پیست اسکی (مبتدی، حرفه‌ای)، پیست سورتمه‌سواری، رستوران، هتل، سرویس بهداشتی، نمازخانه، زمین‌های تنیس (برای تابستان)،
                سوار‌کاری (برای تابستان) و پرش و کایت می باشد. سورتمه سواری و برف بازی از جمله تفریحات این پیست است.
                <br />
                <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%AF%D8%B1%D8%A8%D9%86%D8%AF_(%D8%AA%D9%87%D8%B1%D8%A7%D9%86)" target="_blank" rel="noreferrer noopener">دربند</LinkMui> منطقه ای
                خوش آب و هوا واقع در شمالی ترین نقطه شهر تهران است.
              </Typography>
              <Typography className="h2" variant="h2">
                ۵ ) دربند :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="دربند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  میدان دربند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چشم انداز فوق العاده بدون ترافیک و آلودگی هوا هدیه دربند به پایتخت نشینان است. پیاده روری در مسیر سنگی جذابیت دربند را دوچندان کرده است.
                <br />
                فقط با ۱۰ دقیقه پیاد‌روی می توانید به محدوده‌ای سرسبز با جویباری طبیعی و تعدادی آبشار کوچک برسید.
                <br />
                خیابان دربند از میدان قدس (تجریش) در ارتفاع ۱,۶۰۰ متری از سطح دریا آغاز می‌شود و در میدان سربند در ارتفاع ۱,۸۰۰ متری از سطح دریا به پایان می‌رسد.
              </Typography>
              <Typography className="h3" variant="h3">امکانات و تفریحات دربند :</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله تفریحات و مناظر دربند که می توان از آنها استفاده کرد: کوهنوردی، آبشار دوقلو دربند، تله سیژ دربند می باشد. همچنین رستورانهای معروف به نام
                های رستوران کوهپایه، رستوران باغ بهشت دربند، رستوران پای کوه، رستوران باغ ایرانی، سفره خانه آبشار را می توان از دیگر تفریحات دربند نام برد.
              </Typography>
              <Typography className="h2" variant="h2">۶ ) درکه :</Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="درکه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مجموعه تفریحی درکه تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%AF%D8%B1%DA%A9%D9%87" target="_blank" rel="noreferrer noopener">درکه</LinkMui> یکی از محله های
                شمال <Link className="link" to="/tehran-zones" target="_blank">تهران</Link> است.
                بواسطه قرارگیری در دامنه کوههای شمال تهران از آب و هوای مطبوعی برخوردار است.
                <br />
                درکه در نزدیکی
                محله‌های <Link className="link" to="/Listing/492" target="_blank">اوین</Link> و <Link className="link" to="/Listing/58" target="_blank">ولنجک</Link> و
                در غرب شمیران قرار دارد.
                <br />
                در تاریخچه نام گذاری درکه آمده است؛ که از دو کلمه «دَرِ» مخفف دره و «که» نشانه تصغیر گرفته شده که به معنای دره کوچک است؛ تشکیل شده است.
                <br />
                مرتفع‌ترین قسمت درکه را می‌توان قله شاه نشین معرفی کرد که ۳۸۰۰ متر ارتفاع دارد.
                <br />
                از جمله تفریحات و مکانهای محله درکه، می توان به امامزاده سید محمد
                والی، رودخانه درکه، جنگل کارا درکه، پناهگاه پلنگ چال، قله چین کلاغ و رستورانهایی نظیر اسپیو درکه، رستوران البرز و کافه گیل درکه اشاره کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">
                  بیشتر بخوانید : کافه های قلیان دار تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۷ ) دریاچه تار :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="دریاچه تار - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مجموعه تفریحی دریاچه تار
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دریاچه‌های تار و هویر نیز از دیگر تفریحات زمستانی تهران هستند. این دریاچه در روستایی به نام هویر در ۳۰ کیلومتری این شهر واقع‌ شده‌اند.
                <br />
                برای رسیدن به دریاچه تار مسیر ذیل را طی کنید : شهرستان دماوند، چنار شرق، جاده دریاچه تار
                <br />
                دریاچه در ارتفاع حدود ۳۰۰۰ متری از سطح دریا قرار دارد و آب آن از کوه‌های قره داغ، سیاهچال و شاه‌نشین
                در شمال و آبراهه‌های فصلی تأمین می‌شود. در کف دریاچه تار نیز چشمه‌های آب زیرزمینی وجود دارد که مقداری از آب دریاچه را تامین می کند.
                <br />
                قوچ، میش البرزی و کل بز، پوشش جانوری تار را تشکیل می‌دهد و آبزیانی چون ماهی قزل‌آلای رنگین‌کمان زرد پر، ماهی قزل‌آلای خال
                قرمز و ماهی زردکش در این دریاچه‌ زندگی می‌کنند.
                <br />
                امکانات و تفریحات دریاچه تار :
                <br />
                تفریحات دریاچه تار شامل شنا، قایق رانی، ماهیگیری، کوهنوردی، دوچرخه سواری، کمپینگ، آفرود و پاراگلایدر می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                ۸ ) مجموعه فرهنگی سعد آباد :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="سعد آباد - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاخ سعد آباد
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui href="http://sadmu.ir" target="_blank" rel="noreferrer noopener" className="link">مجموعه تاریخی و فرهنگی سعد آباد</LinkMui> یکی
                از برجسته‌ترین مکان‌های تاریخ معاصر ایران است.
                <br />
                بنای کاخ <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%DA%A9%D8%A7%D8%AE%E2%80%8C%D9%85%D9%88%D8%B2%D9%87_%D8%B3%D8%B9%D8%AF%D8%A2%D8%A8%D8%A7%D8%AF" target="_blank" rel="noreferrer">سعد آباد</LinkMui> در
                زمان حکومت قاجار پایه گذاری شد و پس از کودتای سال ۱۲۹۹ توسط رضاخان، با به قدرت رسیدن خاندان پهلوی،
                بناهای موجود در مجموعه سعد آباد به دستور رضاخان ساخته و بازسازی شدند. مجموعه کاخ سعد آباد در شمالی‌ترین قسمت شهر تهران و روی کوهپایه توچال است.
                <br />
                مناطق شمالی <Link className="link" to="/listings/rent_tehran_niyavaran" target="_blank">نیاوران</Link> از
                شرق، <Link className="link" to="/listings/buy_tehran_velenjak" target="_blank">ولنجک</Link> از
                غرب و <Link className="link" to="/listings/buy_tehran_tajrish" target="_blank">تجریش</Link> از جنوب، مجموعه کاخ سعد آباد را احاطه کرده‌اند.
              </Typography>
              <Typography className="h3" variant="h3">
                موزه ها و کاخ های مجموعه سعد آباد :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاخ‌های مختلف مجموعه تاریخی و فرهنگی سعد آباد پس از انقلاب اسلامی ایران،
                تغییر کاربری داده‌اند. تعداد زیادی از آن‌ها به موزه تبدیل شده‌اند. ۱۸ ساختمان اصلی از کاخ‌های کوچک و بزرگ در مجموعه سعد آباد وجود دارند.
                <br />
                کاخ ها عبارتند از : کاخ احمد شاهی، کاخ شهوند، کاخ سفید (ملت)، کاخ اختصاصی سعداباد، کاخ سیاه (اسود)، کاخ شمس، کاخ اشرف، کاخ ملکه مادر،
                کاخ بهمن، کاخ شهرام، کاخ ملکه عصمت دولتشاهی،
                کاخ قدیم ولیعهد رضا پهلوی (کرباس)، کاخ فرهناز و علیرضا، کاخ فرح پهلوی، کاخ ملکه توران، کاخ لیلا پهلوی، موزه برادران امیدوار، موزه آب می باشند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : کافه های شمال تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۹ ) باغ فردوس تهران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="باغ فردوس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  باغ فردوس تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر تفریحات زمستانی تهران می توان باغ فردوس را نام برد. باغ فردوس محله‌ای در <Link className="link" to="/tehran-zone-1" target="_blank">شمیران</Link> می باشد که
                در نزدیکی خیابان ولیعصر و میدان <Link className="link" to="/Listings/buy_tehran_tajrish" target="_blank">تجریش</Link> واقع شده‌
                است. فردوس یکی از باغ‌های زیبای پایتخت محسوب می‌شود که از زمان قاجار به یادگار مانده است.
                <br />
                باغ فردوس که با نام باغ موزه فردوس، باغ معیری و موزه سینما هم معروف است،  به دوران قاجار و پادشاهی محمد شاه باز می گردد
                و از آثار ملی کشور محسوب می‌شود. از باغ‌های اشرافی این منطقه تنها عمارتی که باقی مانده است باغ فردوس می باشد که در اختیار موزه سینما قرار دارد.
                <br />
                باغ فردوس ۲۰ هزار متر مربع وسعت دارد و مساحت عمارت آن هزار متر مربع است. این مکان شامل سه و نیم طبقه به عرض ۲۶ متر
                و به طول تقریبا ۳۴ متر می باشد. باغ فردوس در گذر زمان، تغییرات زیادی پیدا کرده است و بسیاری از بخش‌های آن تخریب شده است.
              </Typography>
              <Typography className="h3" variant="h3">
                امکانات باغ فردوس تهران :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سالن های سینما مانند سینما فردوس، سینما تمدن و سینما توگراف، هم چنین وجود کافه باغ فردوس، کافه ویونا و کتاب فروشی
              </Typography>
              <Typography className="h2" variant="h2">
                ۱۰) قلعه لمبسر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="قلعه لمبسر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قلعه لمبسر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ز دیگر جاهای دیدنی تهران در
                زمستان می توان قلعه لمبسر را نام برد. قلعه ی لمبسر یا لمسر در شمال دشت قزوین در منطقه الموت و در نزدیکی روستای رازمیان قرار دارد. در زمان
                اسماعیلیان از این قلعه به دلیل موقعیت استراتژیک و معماری آن به عنوان یک دژ نظامی فوق العاده استفاده می شد. این قلعه بر روی کوهی مرتفع قرار
                گرفته است. لمبسر از سه طرف با پرتگاه احاطه شده است . این قلعه آب انبارهای بسیاری دارد . آب مصرفی قلعه لمبسر از رودخانه نینه روز تامین می شود.
              </Typography>
              <Typography className="h2" variant="h2">جمع بندی:</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا درتلاش
                است تا همواره اطلاعات مناسبی در اختیار کاربران قرار دهد. با دنبال کردن وب سایت و هم چنین بلاگ هومینجا از اخرین اخبار و مطالب جذاب ما مطلع شوید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article29;