import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber, separator } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import { Link } from "react-router-dom";

class Marks extends React.Component{

    isMount = false ;
    token = localStorage.getItem('tokenUserHomeInja');

    constructor(props){
        super(props);
        this.state={
          giftShow : "none" ,
          openAlert : false ,
          message : "" ,
          noMarksPage : true ,
          marksPage : false ,
          marksInfo : null,
          typeAlert : 3
        }
    }

    componentDidMount(){
      this.isMount = true ;
      document.title = 'نشان شده ها در بزرگترین فایلنگ املاک ایران'
      if(this.isMount){
        this.getMarks();
      }
    }

    componentWillUnmount(){
    }

    getMarks = () => {
      Promise.resolve(axios({
        url: baseUrl + "v1/user/marks",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        if(res.data.data.length > 0){
          this.setState({marksInfo:res.data.data,marksPage:true,noMarksPage:false});
        }
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
      })
    }
    
    closeAlertModal = () =>{
      this.setState({openAlert:false});
    }

    MarkListing = () => {
      let result = [] ;
      this.state.marksInfo.forEach((element,index)=>{
        result.push(
          <Button key={index} component={Link} to={"/Listing/" + element.info.id} target="_blank"
            sx={{maxWidth:"100%",minWidth:"100%",padding:"0",color:"black",border:"2px solid #eee",borderTopRightRadius:"40px",overflow:"hidden",textAlign:"right",
                marginTop:"5px"}}
          >
            <Grid container>
              <Grid item xs={3} 
                sx={{background:`url(${element.info.small.length > 0 ? element.info.small[0].path : noLogoAgancyImage})`,
                    backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
              </Grid>
              <Grid item container xs={9} sx={{padding:{xs:"1%",lg:"3%"}}}>
                <Grid item container xs={6}>
                  <Grid item xs={12} md={6}>
                    <Grid sx={{padding:"2% 0"}}>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>استان :</Typography>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                        {element.info.state.name}
                      </Typography>
                    </Grid>
                    <Grid sx={{padding:"2% 0"}}>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>شهر :</Typography>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                        {element.info.city.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid sx={{padding:"2% 0"}}>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>محله :</Typography>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                        {element.info.neighbourhood.name}
                      </Typography>
                    </Grid>
                    <Grid sx={{padding:"2% 0"}}>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>منطقه :</Typography>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                        {element.info.zone.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={6}>
                  <Grid item xs={12} md={6}>
                    { element.info.show_price ?
                       element.info.transaction_id === 1 ?
                          <Grid sx={{padding:"2% 0"}}>
                            <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>خرید :</Typography>
                            <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                              { parseInt(element.info.total_price)  ?
                                  `${convertToPersianNumber(separator(element.info.total_price))} تومان`
                                : "توافقی"
                              }
                            </Typography>
                          </Grid>
                        : element.info.transaction_id === 2 ?
                            <>
                              <Grid sx={{padding:"2% 0"}}>
                                <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>رهن :</Typography>
                                <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                                  { parseInt(element.info.mortgage) ?
                                      `${convertToPersianNumber(separator(element.info.mortgage))} تومان`
                                    : "توافقی"
                                  }
                                </Typography>
                              </Grid>
                              <Grid sx={{padding:"2% 0"}}>
                                <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>اجاره :</Typography>
                                <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                                  { parseInt(element.info.rent) ?
                                      `${convertToPersianNumber(separator(element.info.rent))} تومان`
                                    : "توافقی"
                                  }
                                </Typography>
                              </Grid>
                            </>
                          : <Grid sx={{padding:"2% 0"}}>
                              <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>پیش خرید :</Typography>
                              <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                                { parseInt(element.info.prebuy) ?
                                    `${convertToPersianNumber(separator(element.info.prebuy))} تومان`
                                  : "توافقی"
                                }
                              </Typography>
                            </Grid>
                      : <Grid sx={{padding:"2% 0"}}>
                          <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>قیمت :</Typography>
                          <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>توافقی</Typography>
                        </Grid>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid sx={{padding:"2% 0"}}>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>متراژ :</Typography>
                      <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4",fontSize:{xs:"13px",md:"16px"},fontWeight:"bold"}}>
                        {convertToPersianNumber(element.info.area ? element.info.area : "")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        )
      })
      return result;
    }

    render() {
      return (
        <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column',alignItems:"center"}} sx={{marginRight:{md:'270px',xs:'0'}}}>
          { this.state.noMarksPage ?
              <Grid sx={{width:"100%"}}>
                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>نشان شده ها</Typography>
                <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                  <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                    {"کاربر گرامی شما آگهی نشان شده ای ندارید"}
                  </Typography>
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          { this.state.marksPage ?
              <Grid sx={{width:"100%"}}>
                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>نشان شده ها</Typography>
                <Grid sx={{padding:{xs:"2% 2%",md:"2% 6%"}}}>
                  {this.MarkListing()}
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          <GiftLogo display={this.state.giftShow}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
          
      )
    };
}

export default Marks;