import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article32/01.jpg";
import photo02 from "../../images/mag/article32/02.jpg";
import photo03 from "../../images/mag/article32/03.jpg";
import photo04 from "../../images/mag/article32/04.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article32 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "دکوراسیون خانه های کوچک یک چالش برای افراد خانه است که ما در این مطلب از هومینجا به بررسی ایده های کاربردی، برای دکوراسیون می پردازیم.";
    document.getElementsByTagName('meta')["keywords"].content = "خانه های کوچک لاکچری | دکوراسیون داخلی منزل کوچک | چند ترفند برای دکوراسیون ملک های کوچک | بهترین ایده طراحی خانه های کوچک"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[31].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[31].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[31].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[31].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[31].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[31].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="دکوراسیون خانه کوچک - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[31].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به افزایش قیمت مسکن درصد زیادی از مردم مجبور به خرید و یا نقل مکان به خانه‌هایی کوچک هستند. شاید چیدمان در این فضاهای محدود یک چالش باشد اما طراحان دکوراسیون ترفندهایی را برای اینگونه فضاها در نظر دارند. در این مطلب از<Link className="link" to="/" target="_blank">  هومینجا </Link>قصد داریم تا نکات و ایده های کاربردی، برای <Link className="link" to="/small-house-decoration" target="_blank">   دکوراسیون خانه های کوچک </Link>ارائه دهیم. اگر به دنبال راهکارهایی برای افزایش فضا و استفاده بهینه از آن هستید این مقاله را دنبال کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                ایده های مناسب برای دکوراسیون خانه های کوچک
              </Typography>
              <Typography className="h3" variant="h3">
                ۱ - انتخاب رنگ مناسب
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="رنگ مناسب دکوراسیون - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رنگ عناصر منزل
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است برای دیوار، کاغذ دیواری و رنگ عناصر مهم دکوراتیور از رنگ های روشن استفاده کنید. رنگ های تیره باعث می شوند که فضا کوچک تر بنظر برسد. همچنین از انتخاب لوازمی با طرح های شلوغ و رنگ های تیره بپرهیزید. ایده ما برای انتخاب رنگ، بکارگیری رنگ های روشن مانندطوسی، آبی فیروزه ای، کرمی و یا سفید می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                ۲ -  استفاده از مبلمان مناسب
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مبل ها جزو لوازم کاربردی اما جاگیر منزل می باشند. برای فضاهای کوچک بهتر است از مبلمان ال شکل استفاده کنید. همچنین می توانید یک کاناپه و دو صندلی در روبروی آن یا انواع پاف ها را برای دکوراسیون خانه کوچک انتخاب نمایید. از طرفی اگر فضای منزل شما محدود است و یا دارای<Link className="link" to="/where-to-buy-sleep-product" target="_blank">  اتاق خواب  </Link>نیست، می توانید از مبل های تختخواب شو که به صورت تک نفره، دونفره یا سه نفره در بازار موجود هستند بهره ببرید. انتخاب مبلمان و میزهای پایه کوتاه نیز ایده مناسب دیگری برای فضاهای کوچک می باشد چون سقف را بلندتر نشان می دهد و محیط خانه بزرگتر جلوه می کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید: فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                ۳ - بکارگیری شلف های نظم دهنده
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="شلف و قفسه بندی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شلف های نظم دهنده
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در خانه های کوچک، از فضای دیوارها هم می‌توانید استفاده بهینه کنید. قفسه‌بندی دیوارها و ایجاد شلف‌ها در اتاق خواب، نشیمن، آشپزخانه و حتی سرویس بهداشتی فوق العاده کاربردی و مفید هستند. شما با نصب شلف و قرار دادن وسایل تزئینی یا کتاب، می توانید علاوه بر نظم بخشیدن به محیط، خانه خود را نیز زیبا کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                ۴ -  نورپردازی مناسب در خانه های کوچک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نور یکی از مهمترین المان هایی است که نقش بسزایی در بزرگ یا کوچک به نظر رسیدن فضای خانه دارد. در واقع با استفاده از یک نورپردازی مناسب در محیط منزل می توانید دکوراسیون خانه کوچک خود را ایده ال کنید. همچنین می توانید از دیوارکوب های دیواری برای نورپردازی بیشتر خانه خود استفاده کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                ۵ -  استفاده از آیینه در دکوراسیون داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آینه نمادی از  روشنایی می باشد. در قسمت های کم نور خانه می توانید از آینه استفاده کنید. آینه را می توانید در راهرو ها، اتاق پذیرایی و یا نشیمن قرار دهید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه 1
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                ۶ -  استفاده از پرده ساده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین ایده برای انتخاب پرده، استفاده از پرده‌های تور ساده با حجم بیشتر پارچه می باشد. این مدل از پرده‌ها فضای خانه را بزرگ تر نشان می دهد و سبب افزایش نور در خانه می شود. همچنین بهتر است از پرده‌های بلند در <Link className="link" to="/chandelier-in-decoration" target="_blank">  دکوراسیون </Link>خانه های کوچک و آپارتمان‌ها ااستفاده کنید. این کار سبب می شود تا سقف بلندتر به نظر آید فضا وسیع‌تر دیده شود.
              </Typography>
              <Typography className="h3" variant="h3">
                ۷ - انتخاب فرش مناسب
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="فرش کردن خانه های کوچک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ایده فرش کردن فضاهای کوچک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ابعاد فرش در دکوراسیون منزل اهمیت بالایی دارد. انتخاب فرش کوچک فضای خانه را نیز کوچک نشان می دهد. برای اینکه فضای ملک شما بزرگتر جلوه کند، بهتر است انتخاب شما فرش های بزرگ با طرح های برجسته باشد. فرش کردن فضا باید به گونه ای باشد که کف اتاق کامل مفروش شود یا حداقل مبلمان خانه کامل روی فرش قرار گیرد.
              </Typography>
              <Typography className="h3" variant="h3">
                ۸ -  مرتب نگه داشتن فضای خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جدا از اینکه مرتب نگه داشتن منزل یکی از اصول فنگ شویی است، می توان این موضوع را از نکات مهم در دکوراسیون خانه های کوچک نیز دانست. این کار سبب می شود تا فضای خانه شما بزرگتر دیده شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/fengshui" target="_blank">
                  بیشتر بخوانید: فنگ شویی چیست؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                ۹ -  بکارگیری وسایل رنگی در دکوراسیون منزل
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                استفاده از وسایل رنگی شاد می تواند فضای خانه را شاداب کند. استفاده از کوسن های رنگارنگ یا روتختی های رنگی یکی از ایده های جذاب برای دکوراسیون خانه های کوچک می باشد. همچنین بهتر است تا سقف اتاق را رنگی متفاوت از دیوارها بزنید. البته باید حواستان باشد تا هارمونی رنگ ها را نیز رعایت کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                ۱۰ -  استفاده از پلان باز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی پلان باز خانه یکی از جدیدترین سبک های طراحی خانه است که در فضاهای کوچک مورد استقبال زیادی قرار گرفته است. با بکارگیری این سبک طراحی منزل می توانید تا حد ممکن از فضای خالی موجود در خانه بهترین استفاده را داشته باشید. در حالت عادی قسمت هایی از خانه توسط راهرو و راه پله ها اشغال می شود که با استفاده از پلان باز می توانید این فضا را به اتاق نشیمن اختصاص دهید.
              </Typography>
              <Typography className="h2" variant="h2">جمع بندی:</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی کردیم تا بهترین تکنیک‌ها و ایده‌ها را برای دکوراسیون خانه های کوچک ارائه دهیم. امید است این مطالب برای شما عزیزان مفید واقع شود.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article32;