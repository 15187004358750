import {Box, Button, Grid, Modal,TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl,convertToPersianNumber} from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import moment from 'jalali-moment';

const ticketStatus = [{f:"NEW",n:"جدید"},{f:"COMPLETED",n:"تکمیل شده"},{f:"REPLY",n:"پاسخ داده شده"}];

class Tickets extends React.Component{

  isMount = false ;
  token = localStorage.getItem("tokenUserHomeInja");
  userInfo = JSON.parse(localStorage.getItem("infoUserHomeInja"));

  constructor(props){
    super(props);
    this.state={
      giftShow: false ,
      openAlert: false,
      message: "",
      userTicketsInfo: [] ,
      ticketModal: false ,
      ticketInfo: [],
      ticketMessage: null ,
      ticketId: null ,
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true ;
    document.title = 'تیکت های کاربری هومینجا'
    let infoUserHomeInja = JSON.parse(localStorage.getItem("infoUserHomeInja"));
    if(this.isMount){
      if(infoUserHomeInja.type.id > 4){
        this.getSupporterTickets();
      }else{
        this.getUserTickets();
      }
    }
  }

  componentWillUnmount(){
    this.isMount = false ;
  }

  closeAlertModal = () => {
    this.setState({openAlert:false});
  }

  getUserTickets = () => {
    Promise.resolve(axios({
      url: baseUrl+ "v1/ticket/userTikets",
      method: "GET",
      headers: {"Authorization":"bearer "+ this.token}
    }))
    .then((res) => {
      this.setState({userTicketsInfo:res.data.data});
    }).catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش کنید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",typeAlert:2});
    })
  }

  getSupporterTickets = () => {
    Promise.resolve(axios({
      url: baseUrl+ "v1/ticket/new",
      method: "GET",
      headers: {"Authorization":"bearer "+ this.token}
    }))
    .then((res) => {
      this.setState({userTicketsInfo:res.data.data});
    }).catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش کنید",typeAlert:2});
    })
  }

  tickets = () => {
    let result = [];
    this.state.userTicketsInfo.forEach((element,index) => {
      result.push(
        <Grid sx={{margin:{xs:"1% 1%",md:"1% 8% 0 8%"},textAlign:"center"}} key={index}>
          <Button onClick={() => this.onClickTicket(index)} sx={{padding:"0",maxWidth:"100%",minWidth:"100%",color:"black"}} disabled={this.state.giftShow}>
            <Grid container sx={{border:"2px solid #00C8E9",borderTopLeftRadius:"30px",overflow:"hidden"}}>
              <Grid item container xs={7} md={9}>
                <Grid item xs={12} lg={4} sx={{padding:{xs:"5px",md:"25px"}}}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold"}}>عنوان تیکت : {this.state.userTicketsInfo[index].title}</Typography>
                </Grid>
                { this.userInfo.type.id < 5 ?
                    <Grid item xs={12} lg={4} sx={{padding:{xs:"5px",md:"25px"}}}>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold"}}>دسته بندی : {this.state.userTicketsInfo[index].category.name_fa}</Typography>
                    </Grid>
                  : <Grid item xs={12} lg={4} sx={{padding:{xs:"5px",md:"25px"}}}>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold"}}>دسته بندی : پشتیبان</Typography>
                    </Grid>
                }
                <Grid item xs={12} lg={4} sx={{padding:{xs:"5px",md:"25px"}}}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold"}}>
                    تاریخ : {convertToPersianNumber(moment(this.state.userTicketsInfo[index].created_at).format('jYYYY/jM/jD'))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={5} md={3} sx={{background:"linear-gradient(90deg, #CCF4FB 0%, rgba(204, 244, 251, 0.36) 100%)",padding:{xs:"5px",md:"25px"},display:"flex",alignItems:"center",justifyContent:"center"}}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold"}}>وضعیت : {this.checkTicketStatus(this.state.userTicketsInfo[index].status)}</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      )
    })
    return result;
  }

  checkTicketStatus = (field) => {
    for(let i = 0 ; i < ticketStatus.length ; i++){
      if(ticketStatus[i].f === field){
        return ticketStatus[i].n
      }
    }
  }

  onClickTicket = (index) => {
    this.setState({giftShow:true,ticketId:this.state.userTicketsInfo[index].ticket_id});
    Promise.resolve(axios({
      url: baseUrl+ "v1/ticket/getTicket/" + this.state.userTicketsInfo[index].id,
      method: "GET",
      headers: {"Authorization":"bearer "+ this.token}
    }))
    .then((res) => {
      this.setState({ticketInfo:res.data.data,giftShow:false,ticketModal:true});
    }).catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش کنید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",giftShow:false,typeAlert:2});
    })
  }

  onCloseTicketModal = () => {
    this.setState({ticketModal:false});
  }

  createMessages = () => {
    let result = [];
    result.push(
      <Grid sx={{bgcolor:"white",border:"2px solid #01A7C4",padding:"10px",margin:"5px 0",borderRadius:"5px"}} key="0">
        <Grid sx={{display:"flex",justifyContent:"space-between"}}>
          <Grid>
            <Typography sx={{fontFamily:"shabnam",display:"inline-block",color:"#01A7C4"}}>فرستنده :</Typography>
            <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4"}}>
              {this.state.ticketInfo[0].user.name} {this.state.ticketInfo[0].user.lastname}
            </Typography>
          </Grid>
          { this.userInfo.type.id > 4 ?
              <Grid>
                <Typography sx={{fontFamily:"shabnam",display:"inline-block",color:"#01A7C4"}}>آیدی کاربر :</Typography>
                <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4"}}>
                  {this.state.ticketInfo[0].user.id}
                </Typography>
              </Grid>
            : <Grid></Grid>
          }
        </Grid>
        <Grid sx={{height:"1px",bgcolor:"#eee",width:"100%",margin:"5px 0"}}></Grid>
        <Typography sx={{fontFamily:"shabnam",display:"inline-block",color:"#01A7C4"}}>عنوان :</Typography>
        <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4"}}>
          {this.state.ticketInfo[0].title}
        </Typography>
        <Grid sx={{height:"1px",bgcolor:"#eee",width:"100%",margin:"5px 0"}}></Grid>
        <Typography sx={{fontFamily:"shabnam",display:"inline-block"}}>
        {this.state.ticketInfo[0].message}
        </Typography>
      </Grid>
    )
    if(this.state.ticketInfo[0].comments.length > 0){
      this.state.ticketInfo[0].comments.forEach((element,index) => {
        result.push(
          <Grid sx={{bgcolor:"white",border:"1px solid #eee",padding:"10px",margin:"5px 0"}} key={index + 1}>
            <Grid>
              <Typography sx={{fontFamily:"shabnam",display:"inline-block",color:"#01A7C4"}}>فرستنده :</Typography>
              <Typography sx={{fontFamily:"shabnam",display:"inline-block",marginRight:"5px",color:"#01A7C4"}}>
                {this.state.ticketInfo[0].comments[index].user.name} {this.state.ticketInfo[0].comments[index].user.lastname}
              </Typography>
            </Grid>
            <Grid sx={{height:"1px",bgcolor:"#eee",width:"100%",margin:"5px 0"}}></Grid>
            <Typography sx={{fontFamily:"shabnam",display:"inline-block"}}>
              {this.state.ticketInfo[0].comments[index].comment}
            </Typography>
          </Grid>
        )
      })
    }
    return result;
  }

  onChangeTicketMessage = (event,newValue) => {
    this.setState({ticketMessage:event.target.value});
  }

  onClickSendMessage = () => {
    if(this.state.ticketMessage !== null){
      let ticketMessage = this.state.ticketMessage ;
      ticketMessage = ticketMessage.trim();
      if(ticketMessage !== ""){
        this.setState({giftShow:true})
        Promise.resolve(axios({
          url: baseUrl+ "v1/comment/create",
          method: "POST",
          headers: {"Authorization":"bearer "+ this.token},
          data: {
            ticket_id: this.state.ticketId,
            comment: ticketMessage 
          }
        }))
        .then((res) => {
          this.setState({openAlert:true,message:" پیام با موفقیت ارسال شد منتظر پاسخ پشتیبان بمانید",giftShow:false,typeAlert:1});
          this.updateMessage();
        }).catch((err) => {
          this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش کنید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",giftShow:false,typeAlert:2});
        })
      }
    }
  }

  updateMessage = () => {
    Promise.resolve(axios({
      url: baseUrl+ "v1/ticket/getTicket/" + this.state.ticketInfo[0].id,
      method: "GET",
      headers: {"Authorization":"bearer "+ this.token}
    }))
    .then((res) => {
      this.setState({ticketInfo:res.data.data,ticketMessage:""});
    }).catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش کنید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",typeAlert:2});
    })
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'6%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>تیکت ها</Typography>
        </Grid>
        <Grid>  
          { this.state.userTicketsInfo.length > 0 ?
              this.tickets()
            : <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {'تیکتی جهت نمایش وجود ندارد'}
                </Typography>
              </Grid>
          }
          <Modal
            open={this.state.ticketModal}
            onClose={this.onCloseTicketModal}
            sx={{overflowY:"auto"}}
          >
              <Box 
                sx={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',bgcolor:'white',padding:{xs:"0 5px",md:"0 40px"},width:{xs:"90%",md:"700px"},
                    height:{xs:"95%",md:"auto"},overflowY:"auto"}}
              >
                <Grid>
                  <Button sx={{padding:"0",minWidth:"35px",maxWidth:"35px","&:hover":{bgcolor:"transparent"},borderRadius:"50%",margin:"5px 0"}} onClick={this.onCloseTicketModal}>
                    <HighlightOffIcon sx={{fontSize:"35px"}}/>
                  </Button>
                </Grid>
                <Grid sx={{border:"1px solid #eee",borderRadius:"5px",bgcolor:"#eee",height:{xs:"65%",md:"600px"},overflowY:"auto",padding:"5px"}}>
                  { this.state.ticketInfo.length !== 0 ?
                      this.createMessages()
                    : <Grid></Grid>
                  }
                </Grid>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeTicketMessage}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  sx={{padding:"5px",margin:"5px 0"}}
                  value={this.state.ticketMessage}
                  placeholder="پاسخ"
                  multiline
                  rows={3}
                />
                <Grid sx={{textAlign:"center"}}>
                  <Button onClick={this.onClickSendMessage} disabled={this.state.giftShow}
                    sx={{bgcolor:"#14C9E8",color:"black",fontWeight:"bold",fontFamily:"shabnam","&:hover":{bgcolor:"#14C9E8"},margin:{xs:"0",md:"10px 0"},
                        padding:{xs:"10px 20px",md:"10px 50px"}}}
                  >
                    ارسال
                    <TaskAltSharpIcon sx={{color:"black !important",marginRight:"5px"}}/>
                  </Button>
                </Grid>
              </Box>
          </Modal>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
      </Grid>
    )
  };
}

export default Tickets;