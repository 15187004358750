import { Button, Grid, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import backgroundImage from "../../images/tehranZones/zone01/zone01.jpg";
import ShowCityLocation from "../materilas/showCityLocation";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link } from "react-router-dom";
import { setCanonicalTag } from "../materilas/vmp";
import articleImage15 from "../../images/mag/article15/01.jpg";
import articleImage20 from "../../images/mag/article20/01.jpg";
import articleImage26 from "../../images/mag/article26/01.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/tehranZones.css"


class Zone21 extends React.Component {

  prevPositionY = 0;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      openLinks: false,
    }
  }

  componentDidMount() {
    document.title = "منطقه ۲۱ تهران - نقشه - پارک - مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا"
    document.getElementById("main-heading").innerHTML = "منطقه 21 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه بیست و یک";
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه منطقه 21 شهرداری تهران هستید. در این صفحه از سایت املاک هومینجا اطلاعاتی نظیر : محله های منطقه بیست و یک، قیمت ملک و آپارتمان و هر آنچه در مورد منطقه بیست و یک تهران نیاز دارید، رایگان در اختیار شما می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی کامل منطقه یک شهر تهران | بررسی منطقه 1 تهران | نقشه منطقه 1تهران بزرگ | امکانات منطقه 1 | منطقه 1 کجاست | محدوده منطقه یک شهرداری | خیابان های منطقه یک | قیمت ملک در منطقه یک | خرید آپارتمان در منطقه 1 | رهن و اجاره آپارتمان در منطقه 1 | فروش آپارتمان منطقه 1 | صفر تا صد منطقه یک | نقشه ناحیه های منطقه 1 | بهترین محله های منطقه 1 | قیمت خانه کلنگی منطقه 1 | شهرداری منطقه 1 | راهنمای منطقه 1 تهران | قیمت خانه ویلایی منطقه 1 | آگهی املاک منطقه 1 | جستجوی ملک در منطقه 1 | سرمایه گذاری در املاک منطقه یک";
    setCanonicalTag();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkPos);
  }

  onClickMenu = (event) => {
    event.preventDefault();
    let toggle = !this.state.openMenu;
    this.setState({ openMenu: toggle, anchorEl: event.currentTarget });
    this.closeMenuScroll();
  }

  onCloseMenu = () => {
    this.setState({ openMenu: false });
    window.removeEventListener("scroll", this.checkPos);
    this.prevPositionY = 0;
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  closeMenuScroll = () => {
    this.prevPositionY = window.scrollY;
    window.addEventListener("scroll", this.checkPos);
  }

  checkPos = () => {
    let currentPos = window.screenY;
    if (currentPos > this.prevPositionY || currentPos < this.prevPositionY) {
      this.onCloseMenu()
    }
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid sx={{ width: "100%", height: { xs: "200px", md: "400px" }, background: `url(${backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <Grid sx={{
            maxWidth: "1200px", margin: "auto", display: "flex", justifyContent: { xs: "center", md: "flex-start" }, alignItems: "end", height: "100%",
            padding: { xs: "10px 10px 2% 10px", lg: "0 0 2% 0" }
          }}>
            <Grid sx={{ bgcolor: "#00000075", padding: "10px 80px", borderRadius: "8px" }}>
              <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "25px", fontWeight: "bold" }}>
                منطقه ۲۱
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: "1200px", margin: "auto", padding: { xs: "10px", lg: "0" } }}>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h2">
            منطقه ۲۱ تهران
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            منطقه ۲۱ یکی از غربی ترین مناطق تهران می باشد که از شمال
            به بزرگراه شیخ فضل الله نوری و <Link to="/Listings/buy_tehran_otubantehrankaraj" className="zone_text_link" target="_blank">آزادراه تهران کرج</Link>، از غرب
            به گرمدره  و بزرگراه لشکری، از شرق به رودخانه <Link to="/Listings/buy_tehran_kan" className="zone_text_link" target="_blank">کن</Link> و از جنوب به <Link to="/Listings/buy_tehran_fath" className="zone_text_link" target="_blank">بزرگراه فتح</Link> و جاده قدیم محدود می شود.
            <br />
            از جمله محله های معروف منطقه بیست و یک میتوان به وردآورد و <Link to="/Listings/buy_tehran_tehransar" className="zone_text_link" target="_blank">تهرانسر</Link> اشاره
            کرد. جمعیت ۱۶۲ هزار نفری در مساحت ۵۱ کیلومتر مربع درحال امرار معاش هستند.
          </Typography>
          <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h3">
              منطقه بیست و یک تهران برروی نقشه
            </Typography>
            <Grid sx={{ width: "100%", height: "400px", borderRadius: "15px", overflow: "hidden", display: { xs: "none", md: "block" } }}>
              <ShowCityLocation
                positions={
                  [
                    [35.716500, 51.290703],
                    [35.700889, 51.290016],
                    [35.687226, 51.286583],
                    [35.676908, 51.278687],
                    [35.677466, 51.266670],
                    [35.681649, 51.237488],
                    [35.703955, 51.183243],
                    [35.708695, 51.163673],
                    [35.716779, 51.139297],
                    [35.728207, 51.126595],
                    [35.734617, 51.120415],
                    [35.740469, 51.103249],
                    [35.743813, 51.085739],
                    [35.746878, 51.087112],
                    [35.744927, 51.095009],
                    [35.742141, 51.114922],
                    [35.742977, 51.125908],
                    [35.744927, 51.139984],
                    [35.741305, 51.156464],
                    [35.720681, 51.208305],
                    [35.717615, 51.236115],
                    [35.717615, 51.236115],
                  ]
                }
                position={
                  [35.714022, 51.193725]
                }
                zoom={12}
              />
            </Grid>
            <Grid sx={{ width: "100%", height: "300px", borderRadius: "15px", overflow: "hidden", display: { xs: "block", md: "none" }, margin: "auto" }}>
              <ShowCityLocation
                positions={
                  [
                    [35.716500, 51.290703],
                    [35.700889, 51.290016],
                    [35.687226, 51.286583],
                    [35.676908, 51.278687],
                    [35.677466, 51.266670],
                    [35.681649, 51.237488],
                    [35.703955, 51.183243],
                    [35.708695, 51.163673],
                    [35.716779, 51.139297],
                    [35.728207, 51.126595],
                    [35.734617, 51.120415],
                    [35.740469, 51.103249],
                    [35.743813, 51.085739],
                    [35.746878, 51.087112],
                    [35.744927, 51.095009],
                    [35.742141, 51.114922],
                    [35.742977, 51.125908],
                    [35.744927, 51.139984],
                    [35.741305, 51.156464],
                    [35.720681, 51.208305],
                    [35.717615, 51.236115],
                    [35.717615, 51.236115],
                  ]
                }
                position={
                  [35.714022, 51.193725]
                }
                zoom={11}
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            پارک های منطقه ۲۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            پارک گلریز ، پارک بهار ، پارک نیلوفر ، پارک اقاقیا ، پارک نرگس.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            پارک ریما ، پارک یاس ، پارک مریم ، پارک شهدا ، پارک شهدای خبرنگار.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز خرید منطقه ۲۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            <a className="zone_text_link" href="https://www.kouroshmall.com/" target="_blank" rel="noreferrer">کوروش</a> ، پاساژ الهام ، مراکز تجاری صدف ، پاساژ رحیمیان ، مرکز خرید رضا ، مجتمع تجاری و
            شهربازی الماس.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            آکواریوم امپراطور ، مرکز تجاری تهرانسر ، فروشگاه های زنجیره ای رفاه ، مرکز خرید شهرداری ، فروشگاه مبلمان ، فروشگاه سپه.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز درمانی منطقه ۲۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            درمانگاه شبانه روزی خیریه صاحب کوثر ، درمانگاه شبانه روزی سایپا ، درمانگاه شبانه روزی شهید صنیع خانی ، درمانگاه شبانه روزی شهرداری منطقه ۲۱ ، مرکز جراحی محدود و سرپائی خیریه وردآورد.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز طبیعت گردی منطقه ۲۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            موزه خودرو های تاریخی ، سالن ورزشی نفت تهران ، باشگاه سوارکاری شهرک غزالی.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            مجموعه ورزشی معلم ، مقبرة الشهدای پارک گلریز تهرانسر ، مقبرة الشهدای شهرک غزالی ، مقبرة الشهدای پارک گلریز.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            محله های منطقه ۲۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            تهران سر ، شهرک دانشگاه شریف ، وردآورد ، شهرک غزالی ، شهرک فرهنگیان.
          </Typography>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={(event) => { this.onClickMenu(event) }}
              sx={{ margin: "20px 0", border: "2px solid #d7d7d7", padding: "10px 40px", boxShadow: "0 2px 2px 0 #ebebeb", borderRadius: "8px" }}
            >
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "black" }}>
                سایر مناطق تهران
              </Typography>
              <KeyboardArrowDownRoundedIcon sx={{ color: "gray !important" }} />
            </Button>
            <Menu
              open={this.state.openMenu}
              onClose={this.onCloseMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ "& .MuiPaper-root": { maxHeight: "300px", overflow: "scroll-y" } }}
            >
              <MenuItem>
                <Link to={'/tehran-zone-1'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-2'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-3'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-4'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-5'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-6'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-7'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-8'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-9'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-10'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-11'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-12'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-13'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-14'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-15'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-16'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-17'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-18'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-19'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-20'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-21'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu} sx={{ padding: "8px 40px" }}> منطقه ۲۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-22'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۲</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 0 0" }}>
              مقالات مرتبط
            </Typography>
            <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Link to="/buying-house-in-Tehran" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage15})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        نکات مهم در مورد خرید ملک در تهران
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/moving-the-house" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage20})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        فوت و فن اثاث کشی راحت
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/real-estate" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage26})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        مشاور املاک کیست
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
          <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
            <Link to={'/Listings/buy_tehran_otubantehrankaraj'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اتوبان تهران کرج
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_otubantehrankaraj'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اتوبان تهران کرج
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bashgahenaft'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در باشگاه نفت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bashgahenaft'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در باشگاه نفت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bozorgrahefath'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بزرگراه فتح
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bozorgrahefath'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بزرگراه فتح
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{
            bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "1300px" : "0px", md: this.state.openLinks ? "700px" : "0px" }
            , transition: "max-height 0.6s linear", overflow: "hidden"
          }}
          >
            <Link to={'/Listings/buy_tehran_tehransar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در تهرانسر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_tehransar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در تهرانسر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_tehransarshomali'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در تهرانسر شمالی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_tehransarshomali'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در تهرانسر شمالی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_chitgar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در چیتگر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_chitgar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در چیتگر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakebistbahman'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک ۲۲ بهمن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakebistbahman'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک ۲۲ بهمن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakeesteghlal'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک استقلال
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakeesteghlal'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک استقلال
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakeazadi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک آزادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakeazadi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک آزادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakedaneshgah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک دانشگاه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakedaneshgah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک دانشگاه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakedaneshgahshirif'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک دانشگاه شریف
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakedaneshgahshirif'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک دانشگاه شریف
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakedarya'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک دریا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakedarya'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک دریا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakeshahrdari'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک شهرداری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakeshahrdari'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک شهرداری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakeghazali'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک غزالی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakeghazali'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک غزالی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakefarhangiyan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک فرهنگیان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakefarhangiyan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک فرهنگیان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakekazemiyeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک کاظمیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakekazemiyeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک کاظمیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_fadakto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در فدک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_fadakto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در فدک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_verdavard'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در وردآورد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_verdavard'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در وردآورد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_vilashahr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ولیعصر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_vilashahr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ولیعصر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              ما در این صفحه به معرفی  منطقه 21 تهران – نقشه – پارک – مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا می پردازیم.
              همچنین منطقه 21 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه بیست و یک را مورد بررسی قرار می دهیم.
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Zone21;

