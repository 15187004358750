import { Button,  Grid, Typography, Tooltip, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import locationIcon from "../images/listing/location.png";
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import vip1Image from "../images/listing/vip1.png";
import vip2Image from "../images/listing/vip2.png";
import vip3Image from "../images/listing/vip3.png";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { Link } from "react-router-dom";

class RequestVip extends React.Component{

    isMount = false;
    token = localStorage.getItem('tokenUserHomeInja');
    user = JSON.parse(localStorage.getItem("infoUserHomeInja"));

    constructor(props){
        super(props);
        this.state={
          giftShow : "none" ,
          openAlert : false ,
          message : "" ,
          isLoading: false ,
          noRequestVipPage : true ,
          requestVipPage : false ,
          vipRequestInfo : [] ,
          typeAlert : 3 ,
          filterSelected : "" ,
          vipRequestFilter : []
        }
    }

    componentDidMount(){
      this.isMount = true ;
      document.title = 'درخواستهای VIP در پلتفرم خدمات ملکی'
      if(this.isMount){
        this.getVipRequest();
      }
    }

    componentWillUnmount(){
      this.isMount = false ;
    }

    closeAlertModal = () =>{
      this.setState({openAlert:false});
    }

    getVipRequest = () => {
      this.setState({vipRequestInfo:[],vipRequestFilter:[],giftShow:"",filterSelected:""});
      Promise.resolve(axios({
        url: baseUrl + "v1/vip_request/get",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        if(res.data.data.length > 0){
          this.setState({vipRequestInfo:res.data.data,vipRequestFilter:res.data.data,requestVipPage:true,noRequestVipPage:false});
        }else{
          this.setState({requestVipPage:false,noRequestVipPage:true});
        }
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
      })
      .finally(() => {
        this.setState({giftShow:"none"});
      })
    }

    vipRequests = () => {
      let result = [];
      this.state.vipRequestFilter.forEach((element,index) => {
        result.push(
          <Grid item xs={2} sm={4} lg={4} xl={3} key={element.id}>
            <Grid sx={{border:"2px solid #eee",borderTopRightRadius:"30px",overflow:"hidden"}}>
              <Grid sx={{background:`url(${element.listing.small.length > 0 ? element.listing.small[0].path : noLogoAgancyImage})`,
                backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover",height:{xs:"200px",sm:"190px"}}}
              >
                <Grid sx={{bgcolor:"transparent",width:"20%",marginRight:"auto"}}>
                  <Typography component={"img"} 
                    src={element.plan.id === 2 ? 
                          vip1Image
                            : element.plan.id === 3 ?
                              vip2Image
                              : element.plan.id === 4 ?
                                vip3Image
                                : null
                        } 
                    alt="vip" 
                    sx={{width:"100%",height:"auto"}}
                  />
                </Grid>
                <Grid sx={{bgcolor:"transparent",width:"20%",marginRight:"auto",display:"flex",justifyContent:"center"}}>
                  <Tooltip title={<Typography sx={{fontFamily:"shabnam",fontSize:"12px"}}>مشاهده آگهی</Typography>}>
                    <Button component={Link} to={"/Listing/" + element.listing_id} target="_blank"
                      sx={{padding:"0","& svg":{color:"white !important"},"&:hover":{"& svg":{color:"#eee !important"}}}} disableRipple
                    >
                      <VisibilityRoundedIcon sx={{color:"#eee !important", fontSize:"30px"}}/>
                    </Button>
                  </Tooltip>
                  
                </Grid>
              </Grid>
              <Grid sx={{padding:"10px 0",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Typography component={"img"} src={locationIcon} alt="location icon" sx={{width:{xs:"15px",md:"20px"},height:"auto"}}></Typography>
                <Typography sx={{fontSize:{xs:"13px",md:"16px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"10px",display:"inline-block",color:"black"}}>
                  {element.listing.city.name}  -
                </Typography>
                <Typography sx={{fontSize:{xs:"13px",md:"16px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"5px",display:"inline-block",color:"black"}}>
                  {element.listing.neighbourhood.name}
                </Typography>
              </Grid>
              { this.user.type.id <= 4 ?
                  <Button onClick={() => this.onClickCancelVip(element)} disabled={this.state.isLoading}
                    sx={{maxWidth:"100%",minWidth:"100%",bgcolor:"#01C7E9","&:hover":{bgcolor:"#01C7E9"},borderRadius:"0",padding:"12px 0"}}
                  >
                  <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",color:"black"}}>
                    لغو درخواست
                  </Typography>
                  </Button>
                : <Grid sx={{borderTop:"1px solid #eee"}}>
                    <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      <PersonRoundedIcon sx={{fontSize:"15px",marginLeft:"5px",color:"gray !important"}}/>
                      <Typography sx={{fontFamily:"shabnam",fontSize:"15px"}}>
                          {element.user.name + " " + element.user.lastname}
                      </Typography>
                    </Grid>
                    <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      <LocalPhoneRoundedIcon sx={{fontSize:"15px",marginLeft:"5px",color:"gray !important"}}/>
                      <Typography sx={{fontFamily:"shabnam",fontSize:"15px"}}>
                          {element.user.mobile ? convertToPersianNumber(element.user.mobile) : "فاقد شماره تماس"}
                      </Typography>
                    </Grid>
                  </Grid>
              }
              
            </Grid>
          </Grid>
        )});
      return result ;
    }

    onClickCancelVip = (element) => {
      this.setState({giftShow:"",isLoading:true});
      Promise.resolve(axios({
        url: baseUrl + "v1/listing/freeInventory",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: {
          vip_request_id : element.id
        }
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"درخواست آگهی ویژه با موفقیت لغو شد",giftShow:"none",isLoading:false,typeAlert:1});
        this.getVipRequest();
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است ، مجداد تلاش کنید در صورت تکرار مشکل با پشتیبانی تماس حاصل فرمائید",giftShow:"none",
                      isLoading:false,typeAlert:2});
      })
    }

    onChangeFilter = (event) => {
      this.setState({filterSelected:event.target.value});
      let data = [...this.state.vipRequestInfo] ;
      if(event.target.value === 1){
        this.setState({vipRequestFilter:data});
      }
      if(event.target.value === 2){
        let result = [];
        for(let i = 0 ; i < data.length ; i++){
          if(data[i].plan.id === 2){
            result.push(data[i]);
          }
        }
        this.setState({vipRequestFilter:result});
      }
      if(event.target.value === 3){
        let result = [];
        for(let i = 0 ; i < data.length ; i++){
          if(data[i].plan.id === 3){
            result.push(data[i]);
          }
        }
        this.setState({vipRequestFilter:result});
      }
      if(event.target.value === 4){
        let result = [];
        for(let i = 0 ; i < data.length ; i++){
          if(data[i].plan.id === 4){
            result.push(data[i]);
          }
        }
        this.setState({vipRequestFilter:result});
      }
    }

    render() {
      return (
        <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column',alignItems:"center"}} sx={{marginRight:{md:'270px',xs:'0'}}}>
          { this.state.noRequestVipPage ?
              <Grid sx={{width:"100%"}}>
                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>درخواست آگهی ویژه</Typography>
                <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                  <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                    {"کاربر گرامی شما درخواست آگهی ویژه ای ندارید"}
                  </Typography>
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          { this.state.requestVipPage ?
              <Grid sx={{width:"100%"}}>
                <Grid sx={{display:"flex",justifyContent:"space-between",flexDirection:{xs:"column",md:"row"}}}>
                  <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},paddingRight:"6%",fontWeight:'bold'}}>درخواست آگهی ویژه</Typography>
                  <Grid sx={{paddingLeft:"6%",textAlign:"center"}}>
                    <FormControl sx={{minWidth:120,border:"1px solid #eee",borderRadius:"4px"}}>
                      <InputLabel sx={{fontFamily:"shabnam",fontWeight:"bold",bgcolor:"white"}}>{"فیلتر"}</InputLabel>
                      <Select
                        sx={{fontFamily:"shabnam",fontWeight:"bold"}}
                        value={this.state.filterSelected}
                        onChange={this.onChangeFilter}
                      >
                        <MenuItem value={1} sx={{fontFamily:"shabnam",fontWeight:"bold"}}>همه</MenuItem>
                        <MenuItem value={2} sx={{fontFamily:"shabnam",fontWeight:"bold"}}> VIP 1</MenuItem>
                        <MenuItem value={3} sx={{fontFamily:"shabnam",fontWeight:"bold"}}> VIP 2</MenuItem>
                        <MenuItem value={4} sx={{fontFamily:"shabnam",fontWeight:"bold"}}> VIP 3</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container sx={{padding:{xs:"2% 2%",md:"2% 6%"}}} spacing={{xs:1,md:2}} columns={{xs:2,sm:8,lg:12,xl:12}}>
                  { this.state.vipRequestFilter.length > 0 ?
                      this.vipRequests()
                    : <Grid sx={{textAlign:"center",width:"100%"}}>
                        <Typography sx={{fontFamily:"shabnam",padding:"20px 0",fontWeight:"bold"}}>
                          درخواستی وجود ندارد
                        </Typography>
                      </Grid>
                  }
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          <GiftLogo display={this.state.giftShow}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
      )
    };
}

export default RequestVip;