import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import photo01 from "../../images/mag/article68/01.jpg";
import photo02 from "../../images/mag/article68/02.jpg";
import photo03 from "../../images/mag/article68/03.jpg";
import photo04 from "../../images/mag/article68/04.jpg";
import photo05 from "../../images/mag/article68/05.jpg";
import photo06 from "../../images/mag/article68/06.jpg";
import photo07 from "../../images/mag/article68/07.jpg";
import photo08 from "../../images/mag/article68/08.jpg";

import "../../styles/article.css";
import {
  ArticleAd1,
  ArticleAd2,
  ArticleAd3,
  ArticleAd4,
} from "../mag/articleAd";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import { article as articlesData } from "../mag/articles";
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article68 extends React.Component {
  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false,
    };
  }

  componentDidMount() {
    document.getElementsByTagName("meta")["description"].content =
      "جمشیدیه تهران با ارتفاع متوسط ۱۸۰۰ متر بالاتر از سطح دریا، یکی از مرتفع‌ترین مناطق مسکونی تهران به‌شمار می‌آید. | هومینجا";
    document.getElementsByTagName("meta")["keywords"].content =
      "جمشیدیه تهران کجاست | جمشیدیه منطقه چند | صفر تا صد محله جمشیدیه | پارک جمشیدیه تهران | امکانات محله جمشیدیه | قیمت آپارتمان در جمشیدیه تهران | خرید خانه جمشیدیه | رهن و اجاره خانه در جمشیدیه ";
    document.getElementsByTagName("meta")["author"].content =
      "شرکت وب گستران سورین";
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName("meta")["description"].content =
      "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML =
      articlesData[67].h1 + " | هومینجا ";
  };

  setTitle = () => {
    document.title = articlesData[67].title + " | هومینجا ";
  };

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  };

  openAuth = (type) => {
    switch (type) {
      case "L":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      case "R":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  };

  onCloseAuth = () => {
    this.setState({ openAuthModal: false });
  };

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  };

  render() {
    return (
      <Grid
        sx={{
          marginTop: { xs: "61px", md: "88px" },
          bgcolor: "#E7F0F3",
          padding: { xs: "5px", md: "15px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <SideBar access={articlesData[67].access} />
        <Grid container sx={{ maxWidth: "1610px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              sx={{
                bgcolor: "white",
                borderRadius: "8px",
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                padding: { xs: "5px", md: "30px" },
              }}
            >
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[67].h1}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px 0 20px",
                }}
              >
                <Typography className="date_author">
                  نویسنده : {articlesData[67].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[67].date)}
                </Typography>
              </Grid>
              <Typography
                component={"img"}
                src={photo01}
                alt="جمشیدیه - هومینجا  "
                className="image"
              />
              <Typography
                className="h2"
                variant="h2"
                style={{ display: "none" }}
              >
                {articlesData[67].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                جمشیدیه یکی از بهترین محله های تهران محسوب می شود. در این مقاله
                از{" "}
                <Link className="link" to="/HomeMag" target="_blank">
                  {" "}
                  هوم مگ{" "}
                </Link>{" "}
                قصد داریم شما را با زیر و بم محله{" "}
                <Link
                  className="link"
                  to="/Jamshidie-Neighborhood"
                  target="_blank"
                >
                  {" "}
                  جمشیدیه{" "}
                </Link>{" "}
                ، امکانات، قیمت خانه، و موقعیت دسترسی آن آشنا کنیم. پس با{" "}
                <Link className="link" to="/" target="_blank">
                  {" "}
                  هومینجا{" "}
                </Link>{" "}
                همراه شوید.
              </Typography>
              <Typography className="h2" variant="h2">
                موقعیت محله جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                محله جمشیدیه در ارتفاعات بلند منطقه{" "}
                <Link className="link" to="/tehran-zone-1" target="_blank">
                  {" "}
                  یک{" "}
                </Link>{" "}
                شهرداری تهران قرار دارد. دلیل محبوبیت محله جمشیدیه، وجود پارک
                بزرگ و جنگلی جمشیدیه می باشدکه روزانه گردشگران و طبیعت دوستان
                زیادی را به سمت خود می کشاند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                مهم ترین و اصلی ترین خیابان محله جمشیدیه، خیابان شهید حکمت الله
                امیدوار می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این محله خوش آب و هوا از خیابان{" "}
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  {" "}
                  نیاوران{" "}
                </Link>{" "}
                شروع و تا انتهای پارک جمشیدیه ادامه دارد. خیابان{" "}
                <Link className="link" to="/Listing/1344" target="_blank">
                  {" "}
                  امیدوار{" "}
                </Link>{" "}
                از نیاوران تا فیضیه به صورت یکطرفه و از خیابان{" "}
                <Link className="link" to="/Listing/1096" target="_blank">
                  {" "}
                  فیضیه{" "}
                </Link>{" "}
                تا انتهای ورودی پارک جمشیدیه به صورت دوطرفه ادامه دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه محله جمشیدیه :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo02}
                  alt="تاریخچه جمشیدیه - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">کاخ نیاوران</figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همان طور که گفته شد محله جمشیدیه بر روی کوهپایه های قله کلکچال
                واقع شده است . به همین علت از آب و هوای مطبوع و دلپذیری برخوردار
                است. همین امر موجب شد ناصرالدین قاجار روانه این محله شود و بنای
                کاخ نیاوران را احداث نماید. به تدریج اشراف ایل قاجار به این محله
                زیبا نقل مکان کردند و ویلاهای اعیان با مساحت های بزرگ را بنا
                کردند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                محله جمشیدیه در گذشته از دوقسمت قلعه و نجیب خانه متشکل شده بود.
                قلعه به محل فعالیت و{" "}
                <Link className="link" to="/Business-Right" target="_blank">
                  {" "}
                  کسب{" "}
                </Link>{" "}
                مردم، نجیب خانه هم به محل زندگی و استراحت می گفتند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Andarzgoo-blvd" target="_blank">
                  بیشتر بخوانید : خیابان اندرزگو تهران | معرفی کامل اندرزگو با
                  تصویر و نقشه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                پارک جمشیدیه تهران :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                پارک جمشیدیه یکی از معروفترین بوستان های تهران محسوب می شود که
                به پارک سنگی هم شهرت دارد. بعد از پارک ملت{" "}
                <Link
                  className="link"
                  to="/Listings/buy_tehran_valiasr"
                  target="_blank"
                >
                  {" "}
                  ولیعصر{" "}
                </Link>
                ، پارک جمشیدیه به عنوان دومین پارک مشهور و پر تردد تهران محسوب
                می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                وجود 10 هکتار مساحت پارک با درختان و گونه های مختلف{" "}
                <Link
                  className="link"
                  to="/keeping-apartment-flower"
                  target="_blank"
                >
                  {" "}
                  گیاهی{" "}
                </Link>{" "}
                آب و هوای بسیار مطبوعی را برای پارک سنگی جمشیدیه به ارمغان آورده
                است. از ویژگی های مهم پارک جمشیدیه می توان به دریاچه و آبنماهای
                سنگی اشاره کرد. هم چنین وجود کفپوش سنگی، پارک جمشیدیه را از
                تمامی پارک های{" "}
                <Link className="link" to="/tehran-zones" target="_blank">
                  {" "}
                  تهران{" "}
                </Link>{" "}
                متمایز کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اغلب مجسمه های پارک هنر دست مجسمه ساز معروف "{" "}
                <LinkMui
                  className="link"
                  href="https://fa.wikipedia.org/wiki/%D9%86%D8%A7%D8%B5%D8%B1_%D9%87%D9%88%D8%B4%D9%85%D9%86%D8%AF_%D9%88%D8%B2%DB%8C%D8%B1%DB%8C#:~:text=%D9%86%D8%A7%D8%B5%D8%B1%20%D9%87%D9%88%D8%B4%D9%85%D9%86%D8%AF%20%D9%88%D8%B2%DB%8C%D8%B1%DB%8C%20%D8%AF%D8%B1%20%D8%B3%D8%A7%D9%84,%D9%BE%D8%A7%D8%B1%DA%A9%20%D8%AC%D9%85%D8%B4%DB%8C%D8%AF%DB%8C%D9%87%20%D8%AA%D9%87%D8%B1%D8%A7%D9%86%20%D8%A8%D8%B1%D9%BE%D8%A7%20%D8%B4%D8%AF%D9%87%E2%80%8C%D8%A7%D8%B3%D8%AA."
                  target="_blank"
                >
                  {" "}
                  ناصر هوشمند{" "}
                </LinkMui>{" "}
                " می باشد که باعث جذب گردشگر از تمامی نقاط تهران و ایران شده
                است. پارک جمشیدیه دارای 4 ورودی می باشد که مسیر رفتن به آن از
                طریق خیابان نیاوران (
                <Link
                  className="link"
                  to="/Listings/buy_tehran_niyavaran"
                  target="_blank"
                >
                  {" "}
                  دکتر باهنر{" "}
                </Link>
                ) میسر است.
              </Typography>
              <Typography className="h3" variant="h3">
                مکان های تفریحی و دیدنی پارک جمشیدیه :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo03}
                  alt="پارک جمشیدیه - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">پارک جمشیدیه</figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این قسمت به طور مختصر جاهای دیدنی، مکان های خاص و{" "}
                <Link
                  className="link"
                  to="/Tehran-spectaculars"
                  target="_blank"
                >
                  جاذبه{" "}
                </Link>{" "}
                های پارک جمشیدیه تهران را نام برده و توضیح کوتاهی در رابطه با آن
                می دهیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> آبشارها، دریاچه ها و فواره های سنگی :</strong>
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                جریان آب در سراسر پارک سنگی پوشیده از گیاهان، پارک جمشیدیه را
                چشم نواز کرده است. سر و صدای اردک و غازهای زیبا با رقص ماهی در
                دریاچه ها لحظاتی زیبایی را رقم می زند. استراحت بر روی نیمکت های
                پارک جمشیدیه با منظره آبشار و فواره های سنگی خاطره ماندگار این
                پارک است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> مسیر و گذرگاه های سنگ فرش شده : </strong>
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اولین مورد در بدو ورود شما مسیرهای سنگفرش شده با سنگ های رودخانه
                ای می باشد که سراسر پارک را در بر می گیرد و شما را از جریان شهری
                کاملا دور می نماید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                درختان بلند با عمر بیش از 200 سال پوشش گیاهی منحصر بفردی را برای
                پارک جمشیدیه تهران به ارمغان آورده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> مجسمه های سنگی حیوانات : </strong>
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یکی دیگر از جاذبه های پارک جمشیدیه تهران وجود تخته سنگ های غول
                پیکر است که سیمایی نزدیک به حیوانات دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                هدف از طراحی و ساخت مجسمه ها، درگیر کردن ذهن و قوه تخیل
                بازدیدکنندگان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                وجود پوشش گیاهی خاص با فاصله اندک از خیابان اصلی و پر ترافیک
                نیاوران، هم زمان تماشای مجسمه های بزرگ سنگی می تواند رویاپردازی
                تماشاگران را به چالش بکشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> بوستان فردوسی : </strong>
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo04}
                  alt="بوستان فردوسی - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  بوستان فردوسی{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یکی دیگر از جاذبه های دیدنی پارک جمشیدیه تهران، بوستان{" "}
                <LinkMui
                  className="link"
                  href="https://fa.wikipedia.org/wiki/%D9%81%D8%B1%D8%AF%D9%88%D8%B3%DB%8C"
                  target="_blank"
                >
                  {" "}
                  فردوسی{" "}
                </LinkMui>{" "}
                می باشد. این بوستان حدود 16 هکتار است که به نام شاعر بزرگ پارسی
                مزین شده است. ترکیب دو عنصر سنگ و گیاهان فصلی نسبت اصالت بوستان
                فردوسی به پارک جمشیدیه تهران را نشان می دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> نمایشگاه فصلی : </strong>
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در فصول سال{" "}
                <Link className="link" to="/Midex" target="_blank">
                  {" "}
                  نمایشگاه{" "}
                </Link>{" "}
                های مختلفی در سرتاسر پارک برگزار می گردد. برای مثال هنرمندان و
                نقاشان در این پارک گرد هم امده و آثار خود را در معرض نمایش قرار
                می دهند.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/Farmanieh-Neighborhood"
                  target="_blank"
                >
                  بیشتر بخوانید : فرمانیه تهران؛ معرفی کامل این محله همراه با
                  عکس و نقشه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                دسترسی محله جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از تجریش تا میدان نیاوران محل استقرار اتوبوس و تاکسی می باشد و
                نزدیکترین ایستگاه مترو به جمشیدیه، ایستگاه متروی{" "}
                <Link
                  className="link"
                  to="/Listings/buy_tehran_tajrish"
                  target="_blank"
                >
                  {" "}
                  تجریش{" "}
                </Link>
                ، ایستگاه مترو قائم و{" "}
                <Link
                  className="link"
                  to="/Listings/rent_tehran_ajudanieh"
                  target="_blank"
                >
                  {" "}
                  آجودانیه{" "}
                </Link>
                است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای ورود به محله ی جمشیدیه از اتوبان صدر و{" "}
                <Link
                  className="link"
                  to="/Listings/buy_tehran_kamranieh"
                  target="_blank"
                >
                  {" "}
                  کامرانیه{" "}
                </Link>{" "}
                می توانید به خیابان نیاوران و سپس به خیابان امیدوار راه پیدا
                کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                وجود شیب زیاد از ابتدای خیابان نیاوران رفت و آمد را برای پیاده
                سواران کمی سخت کرده است و این مشقت در{" "}
                <Link
                  className="link"
                  to="/tehran-winter-entertaiments"
                  target="_blank"
                >
                  {" "}
                  زمستان{" "}
                </Link>{" "}
                مشکلات ترافیکی و تصادفی زیادی را به همراه دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                رستوران های محله جمشیدیه :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo05}
                  alt="رستوران جمشیدیه - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  رستوران جمشیدیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این قسمت معروفترین{" "}
                <Link
                  className="link"
                  to="/best-restaurant-tehran-region1"
                  target="_blank"
                >
                  {" "}
                  رستوران{" "}
                </Link>{" "}
                های محله جمشیدیه و اطراف آن را معرفی می کنیم.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                رستوران جمشیدیه
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                رستوران{" "}
                <LinkMui
                  className="link"
                  href="https://morshedrestaurant.com"
                  target="_blank"
                >
                  {" "}
                  مرشد{" "}
                </LinkMui>
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                مستوران
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                رستوران{" "}
                <LinkMui
                  className="link"
                  href="https://nayeb.com"
                  target="_blank"
                >
                  {" "}
                  نایب{" "}
                </LinkMui>
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                پیتزا چمن
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                <LinkMui
                  className="link"
                  href="https://nakhl-palm.com"
                  target="_blank"
                >
                  {" "}
                  نخل{" "}
                </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز مهم محله جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این پاراگراف به معرفی مکان ها و مراکز خرید،{" "}
                <Link
                  className="link"
                  to="/Tehran-District-One-Luxurious-Towers"
                  target="_blank"
                >
                  {" "}
                  برج معروف{" "}
                </Link>{" "}
                و خیابان های مهم جمشیدیه می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                ساختمان الیزه جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یکی از لوکس ترین ساختمان منطقه یک تهران، ساختمان مجلل الیزه می
                باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ساختمانی در همسایگی جنوبی پارک جمشیدیه با ویو پارک و تهران ابدی
                با نمایی از سنگ و سیمان تمام نگاه ها را به سمت خود جذب می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ساختمان 9 طبقه که در هر طبقه 4 واحد وجود دارد و از جمله امکانات
                آن می توان به سیستم خانه هوشمند، آشپرخانه صنعتی، تجهیزات کامل و
                پیشرفته الکترونیکی و روشنایی، برق اضطراری تمام اتوماتیک، سونا و
                جکوزی اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                خیابان های معروف جمشیدیه :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo06}
                  alt="خیابان جمشیدیه تهران - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  خیابان جمشیدیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                جمشیدیه از خیابان ها و کوچه های دلنشین برخوردار است که در زیر به
                تعدادی از آنها اشاره می کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                خیابان <strong> جمشید </strong> که دارای سینما و رستوران است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                شهید <strong> حکمت الله امیدوار </strong>به عنوان معروف ترین
                خیابان جمشیدیه که از خیابان شهید باهنر نیاوران شروع و تا انتهای
                پارک جمشیدیه ادامه پیدا می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> خیابان کوهسار </strong>خیابان کوهسار در شمال محله
                جمشیدیه می باشد که سفارت خانه کشور اتریش هم در خیابان واقع است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از جمله خیابان های مهم دیگر محله جمشیدیه میتوان به خیابان مقدسی،{" "}
                <Link
                  className="link"
                  to="/Listings/rent_tehran_marjan"
                  target="_blank"
                >
                  {" "}
                  مرجان{" "}
                </Link>
                ،{" "}
                <Link
                  className="link"
                  to="/Listings/buy_tehran_bukan"
                  target="_blank"
                >
                  {" "}
                  بوکان{" "}
                </Link>{" "}
                و{" "}
                <Link
                  className="link"
                  to="/Listings/buy_tehran_homayun"
                  target="_blank"
                >
                  {" "}
                  همایون{" "}
                </Link>{" "}
                اشاره کرد.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/Zaferanieh-Neighborhood"
                  target="_blank"
                >
                  بیشتر بخوانید : زعفرانیه تهران ؛ بررسی کامل شرایط زندگی و
                  امکانات محله لوکس زعفرانیه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                راهنمای قیمت و معامله آپارتمان در جمشیدیه :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo07}
                  alt="قیمت آپارتمان در جمشیدیه تهران - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  قیمت آپارتمان در جمشیدیه{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این قسمت توجه شما را به صحبتهای کارشناس ملکی هومینجا در مورد
                قیمت ملک و انواع معاملات آپارتمان نظیر خرید و فروش آپارتمان و هم
                چنین رهن و اجاره خانه جلب می کنیم.
              </Typography>
              <Typography className="h3" variant="h3">
                قیمت آپارتمان در جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در محله جمشیدیه همه نوع خانه و آپارتمان مسکونی یافت می شود. به
                دلیل مجاورت با رودخانه کلکچال اجازه ساخت و ساز به خانه های کنار
                رودخانه داده نمی شود تا بستر خانه های ویلایی کاربری تالار عروسی
                به خود بگیرند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                قیمت آپارتمان با سال ساخت بالا در جمشیدیه از حداقل 150 میلیون
                تومان ( در زمان نگارش مقاله شهریور 1402) شروع و تا به ساختمان
                های نوساز با متری 250 و بعضا برج های بسیار لوکس با قیمت نزدیک به
                300 میلون تومان می رسد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                خرید خانه ویلایی یا زمین در جمشیدیه که بدون مشکل و ریشه باشد
                بسیار سخت است ولی با این وجود قیمت زمین در جمشیدیه بسته به محل
                آن از 400 میلیون تا 500 میلون هم ممکن است تجاوز کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای اطلاع از قیمت آپارتمان در جمشیدیه با{" "}
                <Link className="link" to="/Contact" target="_blank">
                  {" "}
                  کارشناسان{" "}
                </Link>{" "}
                هومینجا در ارتباط باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                خرید آپارتمان در جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همیشه خرید ملک یا آپارتمان در بالاشهر چالش های مخصوص به خود را
                داشته است. یکی از این مشکلات، قیمت همیشه بالای آپارتمان نسبت به
                بقیه مناطق تهران می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای خرید و یا سرمایه گذاری در آپارتمان و برج های جمشیدیه نیاز
                به مشاوره تخصصی می باشد که این خدمات از وظایف سایت املاک و
                مستغلات هومینجا برای شما عزیزان است.
              </Typography>
              <Typography className="h3" variant="h3">
                فروش آپارتمان در جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از تمامی مالکان و سازندگان دعوت می نماییم تا برای بهترین تجربه
                فروش املاک خود در جمشیدیه با هومینجا در ارتباط باشند. از جمله
                خدمات هومینجا عکاسی و تهیه رایگان کاتالوگ برای ملک شماست. تعیین
                وقت بازدید و درخواست عقد قرارداد با تخفیف، تماما خدمات هومینجا
                برای مالکان می باشد و در سامانه قابل استفاده است.
              </Typography>
              <Typography className="h3" variant="h3">
                اجاره آپارتمان در جمشیدیه :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo08}
                  alt="رهن و اجاره ملک در جمشیدیه - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  رهن و اجاره ملک در جمشیدیه{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رهن و اجاره آپارتمان و یا حتی تمدید قرارداد اجاره سالیانه همواره
                از مشکلات مستاجران مناطق شمالی تهران می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تورم و افزایش هر ساله ی قیمت شرایط را برای پرداخت حق اجاره
                ماهیانه بسیار دشوار کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای بهترین جستجو و یافتن ملک مورد نظر لطفا به صفحه آگهی های
                هومینجا مراجعه کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                هم چنین شما میتوانید با ثبت درخواست ملک با شرایط مورد نظر در
                سایت هومینجا، در کمترین زمان آپارتمان مسکونی یا ملک اداری خود را
                بیابید.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/Elahiyeh-Neighborhood-Fereshteh-Street"
                  target="_blank"
                >
                  بیشتر بخوانید : الهیه تهران کجاست ؟ معرفی کامل خیابان فرشته
                  تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بهترین آژانس املاک جمشیدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در محدوده منطقه یک ، نیاوران، کامرانیه و قیطریه تعداد بسیار
                بالایی آژانس املاک درحال فعالیت هستند. از جمله آژانس های فعال می
                توان به سروستان و{" "}
                <LinkMui
                  className="link"
                  href="https://neginrealestates.com"
                  target="_blank"
                >
                  {" "}
                  نگین بوکان{" "}
                </LinkMui>{" "}
                اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین سایت معاملات ملک در جمشدیه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سامانه تخصصی معاملات املاک و مستغلات هومینجا به عنوان بهترین
                سایت خرید وفروش آپارتمان و بزرگترین فایلینگ رهن و اجاره در
                جمشیدیه در خدمت شما مالکان و خریداران محترم خانه در جمشیدیه می
                باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                با ثبت نام و تکمیل اطلاعات خود در وب سایت املاک هومینجا از
                هزاران خدمات رایگان استفاده نمایید.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/buying-house-in-Tehran"
                  target="_blank"
                >
                  بیشتر بخوانید : نکات مهم در مورد خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ما در این مقاله از هومینجا سعی کردیم تا به معرفی کامل محله
                جمشیدیه بپردازیم. امید است که مطالعه این مطلب در انتخاب شما مفید
                واقع شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای مشاهده سریع آگهی های خرید و فروش یا رهن و اجاره ملک در
                جمشیدیه روی لینک های زیر کلیک نمایید.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link
                  to={"/Listings/buy_tehran_jamshideh"}
                  className="zone_link"
                  target="_blank"
                >
                  <Typography className="zone_link_text">
                    خرید آپارتمان در جمشیدیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link
                  to={"/Listings/rent_tehran_jamshideh"}
                  className="zone_link"
                  target="_blank"
                >
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان جمشیدیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#4B4B4B",
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                >
                  برچسب ها :
                </Typography>
                <Button
                  component={Link}
                  to={"/HomeMag"}
                  onClick={() => this.onClickTag("محله گردی")}
                  sx={{
                    color: "#4B4B4B",
                    bgcolor: "#E7F0F3",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#E7F0F3" },
                  }}
                >
                  محله گردی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography
                  sx={{
                    fontFamily: "shabnam",
                    color: "#C5CCCE",
                    textAlign: "center",
                  }}
                >
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui
                    href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <WhatsAppIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TwitterIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TelegramIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem("infoUserHomeInja") ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سپردن ملک
                      </Typography>
                      <AddBusinessRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سفارش ملک
                      </Typography>
                      <HouseRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("L")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ورود
                      </Typography>
                      <PersonRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("R")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ثبت نام
                      </Typography>
                      <PersonAddAlt1RoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal
          open={this.state.openAuthModal}
          close={() => this.onCloseAuth()}
          type={this.state.modalType}
        />
      </Grid>
    );
  }
}

export default Article68;
