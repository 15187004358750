import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article15/01.jpg";
import photo02 from "../../images/mag/article15/02.jpg";
import photo03 from "../../images/mag/article15/03.jpg";
import photo04 from "../../images/mag/article15/04.jpg";
import photo05 from "../../images/mag/article15/05.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article15 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک در تهران یک کار کاملاً تخصصی است. ما در این مقاله از هومینجا به نکات مهم خرید و فروش ملک در تهران پرداخته ایم.";
    document.getElementsByTagName('meta')["keywords"].content = "نکات مهم معامله ملک | نکات خرید و فروش آپارتمان | ترفندهای خرید و فروش موفق در املاک | نکات عقد قرارداد ملک | بهترین سایت خرید و فروش ملک در تهران | معرفی بهترین روش معاملات املاک و مستغلات | راهنمای خرید و فروش خانه | نکات کلید هنگام خرید و فروش مسکن"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[14].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[14].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[14].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[14].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[14].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[14].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="نکات مهم در مورد خرید ملک در تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[0].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تهران
                علاوه بر این که پایتخت کشور است، یک کلانشهر با جمعیت بسیار محسوب می‌شود، خرید ملک مناسب در مناطق ۲۲‌گانه تهران، با توجه به تنوع ملک از نظر نوع
                (تجاری، مسکونی، زمین) متراژ و موقعیت مکانی یک چالش بزرگ به
                نظر می‌رسد. به همین دلیل است که خرید و فروش ملک در تهران یک کار کاملاً تخصصی است. بنابراین باید با کمک گرفتن از کارشناسان
                با تجربه در این بخش انجام گیرد. ما قصد داریم در این مقاله از هومینجا به نکات مهم خرید و فروش ملک در تهران بپردازیم.
              </Typography>
              <Typography className="h2" variant="h2">
                خرید ملک در تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از زمان‌های بسیار قدیم،
                ملک یک دارایی سرمایه‌ای محسوب می‌شود که به مرور زمان دارای ارزش افزوده خواهد بود. به همین دلیل است که سرمایه‌گذاری برای خرید ملک باید با توجه
                به فاکتور‌های بسیاری انجام گیرد تا خریدار دچار ضرر و زیان نشود. به طور مثال خرید ملک در مناطقی که با رشد جمعیتی و اقتصادی همراه هستند و به
                مرور پیشرفت زیادی خواهند داشت یک سرمایه‌گذاری عالی است اما ممکن است در مناطقی شاهد رشد چشمگیری نباشیم و اصطلاحاً این مناطق رشد خود را کرده باشند.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="خرید و فروش ملک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خرید ملک در تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گاهی اوقات نیز ملک هایی در شرایط ویژه قرار می گیرند، مثلا ممکن است یک ملک با موقعیت عالی
                از نظر ظاهری در محلی قرار داشته باشد که طرح‌های عمرانی شهرداری در آنجا انجام خواهد گرفت و به این ترتیب عملاً خریدار باید سال‌ها منتظر تعیین تکلیف باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی مناطق تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بنابراین یافتن یک ملک
                مناسب و متناسب با بودجه خریدار که از هر نظر بتواند برای وی سوددهی به همراه داشته باشد نیازمند بررسی فایل‌های بسیاری است. اگر قرار باشد برای خرید
                ملک مورد نظرتان به همه مناطق تهران سر بزنید و یا حتی همه موارد موجود در یک منطقه را بازبینی کنید و در مورد آن‌ها بررسی کرده و برای خرید تصمیم‌گیری
                کنید ممکن است فرصت خرید را از دست بدهید و یا زمان کافی برای سر زدن به همه مشاورین املاک در یک منطقه را نداشته باشید! راه بهتری نیز برای این کار
                وجود دارد؛ سامانه انلاین خرید و فروش هومینجا! یک سامانه آنلاین که امکان بررسی موقعیت‌های مناسب در همه مناطق را به سادگی چند کلیک برای شما فراهم می‌سازد.
                <br />
                در این سامانه
                شما می‌توانید به تفکیک مناطق همه ملک‌هایی که با بودجه و شرایط مورد نظر شما هماهنگی دارند را مشاهده کنید و در مورد آن با مشاورین مجموعه در تماس باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                نکات مهم خرید ملک در تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای این که ملک مناسبی را در تهران خریداری کنید به این نکات توجه داشته باشید:
              </Typography>
              <Typography className="h3" variant="h3">
                بررسی موقعیت ملک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="خرید ملک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بررسی موقعیت ملک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قرار است ملکی را در تهران خریدای کنید حتماً امکانات منطقه‌ای از جمله
                زیرساخت‌های خدماتی مثل بیمارستان، مدرسه، فروشگاه و… را در نظر داشته باشید. همچنین دسترسی محلی به مترو، ‌ایستگاه اتوبوس و تاکسی را نیز فراموش نکنید.
              </Typography>
              <Typography className="h3" variant="h3">
                بودجه خود را در نظر بگیرید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هنگام خرید ملک باید سقف بودجه خود را مشخص کنید و به دنبال
                ملکی با موقعیت مناسب و متناسب با بودجه خود باشید. اگر قرار است ملکی در تهران بخرید باید با میانگین قیمت‌ها در مناطق مختلف تهران آشنایی داشته باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                ویژگی‌های ملک را بررسی کنید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از این که محله و منطقه متناسب با بودجه خود را انتخاب کردید نوبت به بررسی ویژگی‌های ملک مورد
                نظر می‌رسد. مسائلی مثل شمالی یا جنوبی بودن، داشتن فضای سبز، بالکن، حیاط، پارکینگ، انباری، میزان نور، قوانین مربوط به ملک و… را در نظر داشته باشید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-fraud" target="_blank">
                  بیشتر بخوانید : انواع کلاهبرداریه در معاملات املاک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                دنبال دردسر نباشید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گاهی اوقات قیمت پایین برخی ملک‌ها ممکن است شما را برای خرید
                وسوسه کند. به یاد داشته باشید که هیچ ارزانی بی‌حکمت نیست، پس اگر قصد معامله ملک دارید حتماً در مورد اسناد و مدارک هویتی مالک اصلی و سند ملک حساس باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                طول عمر ملک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="نکات خرید ملک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاثیر عمر ملک در قیمت
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قرار است ملک ویلایی یا آپارتمانی بخرید، حتماً طول عمر آن را در نظر بگیرید. هر ساختمانی عمر مفید دارد. ساختمان‌های
                قدیمی یا اصطلاحاً کلنگی نیازمند تعمیرات و گا‌هاً بازسازی یا تخریب و ساخت مجدد هستند که ممکن است هزینه‌های بیشتری نسبت به خرید یک ملک نوساز به همراه داشته باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                شرایط اجتماعی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در هر منطقه‌ای ممکن است کوچه یا محله‌ای وجود داشته باشد که به لحاظ
                اجتماعی محیط مناسبی برای زندگی نبوده و شرایط بزهکاری را به همراه داشته باشد. بنابراین توجه داشته باشید که ملک مورد نظر شما در چنین مناطقی قرار نداشته باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                زمان تحویل ملک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="تحویل ملک درتهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  زمان تحویل ملک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نکته مهم دیگر در زمان خرید ملک، توجه به زمان تحویل آن است. سعی کنید در زمان نوشتن قولنامه همه
                شرایط را در نظر داشته باشید و همه چیز از جمله شکل پرداخت بیعانه و مبلغ آن، مدت زمان تحویل ملک، شکل تسویه حساب و… را به شکل شفاف و دقیق در قولنامه مشخص کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                موقعیت جغرافیایی و آب و هوایی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از نکات مهم، توجه به آب و هوا و موقعیت جغرافیایی ملک است. این که محله مورد نظر شما با کمبود و یا فشار کم آب مواجه نباشد.
                زیرساخت‌هایی چون فاضلاب شهری و لوله کشی‌ها را در نظر داشته و همچنین شرایط آب و هوایی منطقه چون گرما، سرما و آلودگی‌های صوتی و آلاینده‌های هوا را در نظر بگیرید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست ؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                فروش ملک در تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر تجربه فروش ملک در تهران را داشته باشید می‌دانید که یافتن یک مشتری بی‌دردسر و
                مطمئن کار دشواری است. ضمن این که پرداخت کمیسیون به بنگاه‌های مشاور املاک منطقه‌ای برای یافتن هرچه سریعتر مشتری مشکل دیگری است که اصولاً فروشنده‌ها با آن مواجه هستند.
                <br />
                سپردن ملک برای فروش در یک منطقه به بنگاه‌های املاک موجود، امکان معرفی آن ملک به مشتریان مناطق دیگر را به شکل قابل توجهی کاهش می‌دهد.
                هرچند آن ملک به اصطلاح اکازیون باشد و شرایط خوبی داشته باشد، در نهایت توسط افرادی که به دنبال یک ملک در آن منطقه هستند و به مشاور املاک مراجعه می‌کنند بازدید می‌شود.
                <br />
                اگر قصد دارید برای فروش ملک در تهران اقدام کنید و قصد دارید که آگهی فروش ملک شما توسط همه افراد واجد شرایط
                خرید ملک شما در تهران و حتی سایر شهر‌های کشور دیده شود، باید آگهی فروش آن را در سامانه آنلاین خرید و فروش ملک هومینجا ثبت کنید. مشاوران املاک هومینجا آماده
                هستند تا همه امکانات این سامانه مدرن آنلاین را برای فروش ملک شما در اختیارتان قرار دهند. در ادامه با مزایای خرید و فروش ملک در سایت هومینجا با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع‌بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خرید ملک در تهران همانقدر که به دلیل وجود موقعیت‌های متنوع بسیار آسان است،
                می‌تواند سخت و چالش برانگیز نیز باشد. امکاناتی که در سایت هومینجا در اختیار کاربران قرار می‌گیرد شرایط یک خرید و فروش آسان ملک در تهران را برای همه فراهم می‌سازد.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی ملک")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی ملک
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/buying-house-in-Tehran" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/buying-house-in-Tehran" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/buying-house-in-Tehran" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article15;