import React from "react";
import logoBackground from '../images/logoBackground.png';
import { Grid, Typography } from "@mui/material";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { setCanonicalTag } from "./materilas/vmp";
import { Link } from "react-router-dom";

class Terms extends React.Component {

  componentDidMount() {
    document.title=" قوانین درج آگهی املاک و مستغلات | هومینجا";
    document.getElementById("main-heading").innerHTML = "صفحه مربوط به فعالیت کاربران در بهترین سایت جستجو و درج آگهی ملک | املاک و مستغلات هومینجا"
    document.getElementsByTagName('meta')["description"].content ="شما در صفحه مربوط به قوانین و مقررات نحوه فعالیت کاربران در هومینجا هستید. تعهدات کاربران درقبال نحوه شارژ ریالی، عقد قرارداد، بارگذاری محتوا و آگهی به طور کامل شرح داده شده است.";
    document.getElementsByTagName('meta')["keywords"].content ="قوانین درج آگهی ملک | قوانین استفاده از هومینجا | قوانین فعالیت در بهترین سایت املاک | نحوه فعالیت در سایت جستجوی املاک هومینجا |مسئولیت سایت املاک | قوانین شرکت وب گستران سورین | تعدات کاربران هومینجا"
    setCanonicalTag();
  }

  render() {
    return (
      <Grid sx={{marginTop:{xs:"60px",md:"88px"},width:"100%",bgcolor:"#E7F0F3",padding:"40px 0"}}>
        <Grid 
          sx={{background:`url(${logoBackground})`,backgroundPosition:{xs:"50% 60%",md:"50% 80%"},backgroundRepeat:"no-repeat",backgroundSize:{xs:"50%",md:"40% auto"},margin:{xs:"0 2%",
              md:'0 10%'},backgroundAttachment:"fixed",bgcolor:"white",padding:"16px",borderRadius:"8px"}}
        >
          <Grid sx={{padding:"2% 0 2%",display:"flex",alignItems:"center"}}>
            <VerifiedUserIcon sx={{fontSize:{xs:"30px",md:"40px"}}}/>
            <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},fontWeight:"bold",display:"inline-flex",marginRight:"10px"}}>
              قوانین و مقررات استفاده از خدمات وب سایت هومینجا
            </Typography>
          </Grid>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify",marginBottom:"10px",fontWeight:"bold"}}>
           تذکر : منظور از سامانه معاملات املاک هومینجا، پلتفرم درج و جستجوی اگهی ملک هومینجا، وب سایت املاک و مستغلات هومینجا و ... تماما <Link className="zone_text_link" to="/" target="_blank">homeinja.com</Link> می باشد 
          </Typography>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify",marginBottom:"5px"}}>
          کاربران و مشتریان وب سایت جستجو و درج آگهی املاک و مستغلات هومینجا می بایست قبل از عضویت و استفاده از مطالب این سامانه، قوانین و مقررات را به
          طور کامل مطالعه و مورد پذیرش قرار دهند. در صورت عدم موافقت با شرایط و مقررات لازم است بلافاصله استفاده از این سامانه را متوقف نمایند. در نظر داشته 
          باشید که کلیه ضوابط و قوانین هومینجا منطبق با قوانین و مقررات جمهوری اسلامی ایران، قوانین تجارت الکترونیک ، زیر نظر مرکز توسعه تجارت الکترونیکی(اینماد) و مرکز توسعه فناوری اطلاعات و رسانه های دیجیتال تنظیم شده است.
          </Typography>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify",marginBottom:"5px"}}>
          - هدف سامانه انلاین معاملات مسکن
           هومینجا ایجاد بستری مطمئن برای انجام معاملات ملک می باشد. هومینجا تمام تلاش خود را برای اطمینان از اصالت و صحت و سقم اطلاعات وارده در این سامانه به کار
           خواهد بست اما، به هیچ وجه تضمینی بابت درستی و صحت مطالب، ارائه نمی دهد و هرگونه مسئولیتی در مورد درستی و صحت اطلاعات وارده در سامانه را از خود سلب می
           نماید. گرچه مسئولین سایت نهایت تلاش و دقت خودشان را برای بررسی اصالت، صحت و سقم مطالب ارائه شده به عمل می اورند. قیمت های عنوان شده در آگهی های کاربران،
           قیمت های اولیه و نظر شخصی کاربر است و ممکن است با ثمن نهایی معامله اختلاف داشته باشد. پلتفرم املاک  هومینجا در قبال قیمت های اعلام شده مسئولیتی نخواهد داشت.
          </Typography>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify",marginBottom:"5px"}}>
          خدمات و محتویات وب سایت تخصصی املاک هومینجا صرفا براى استفاده شخصى و غیرتجارى شما عرضه شده و حق استفاده از همه اطلاعات موجود، فقط برای سایت محفوظ
           است. هر گونه سوء‌استفاده از این اطلاعات پیگرد قانونی دارد و هیچ فرد حقیقی، حقوقی یا سایتی به هیچ عنوان نمی‌تواند از اطلاعات موجود در سایت براى فروش، تولید
           مجدد یا ایجاد ترافیک در سایت خودش استفاده کند. اگر مایل هستید از سرویس‌ها و محتویات سایت برای مقاصد تجارى استفاده کنید باید، این موضوع را با مسئولین
           سایت در میان بگذارید و در صورت موافقت مسئولین سایت، با ذکر منبع اقدام به استفاده
            از اطلاعات کنید. وب سایت هومینجا هرگونه مسئولیت ضرر و زیان احتمالی کاربران (مادی یا معنوی)
             را از خود سلب می نماید. هومینجا همواره سعی خواهد نمود تا بستر ایمنی را برای خدمت رسانی به کاربران فراهم سازد و محیط سامانه را از آلودگی به انواع ویروس‌های رایانه‌ای مصون بدارد.
              اما کاربران نیز می‌بایست احتیاط لازم را در این خصوص ملاحظه بفرمایند. هومینجا هیچ مسئولیت و تعهدی نسبت به فروش، اجاره و رهن ملک 
             آگهی شده در این سامانه نخواهد داشت و تنها مسئول قراردادهای منعقد شده در دفتر مرکزی هومینجا می باشد. شرکت وب گستران سورین ( هومینجا) 
             هیچگونه مسئولیتی در قبال ادعاها و هزینه های خارج از قرارداد ندارد و هرگونه توافق خارج از قرارداد بین طرفین برای این شرکت الزام آور نخواهد بود.
          </Typography>
          <Typography  sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify",marginBottom:"5px"}}>
          ممکن است سرویس‌های
           سایت به هر دلیلى در زمان‌های مختلف و با اطلاع قبلی، تغییر داده شود. حق تغییر شرایط سرویس‌ها برای سایت محفوظ است  و کاربران توافق می‌کنند که استفاده 
          مستمرشان از سایت به معنی پذیرش هرگونه تغییری می باشد. اگر شرایط استفاده از سرویس‌ها دستخوش تغییرات عمده شود ، حتما در سایت اطلاع رسانی خواهد شد..
          </Typography>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"25px"},fontWeight:"bold",margin:"2% 0"}}>
            تعهدات کاربران
          </Typography>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify"}}>
           <ul>
            <li>
            	کاربران متعهد می شوند مطالب سامانه را فقط در جهت بهره بری شخصی و غیر‌تجاری مورد استفاده قرار دهند.
            </li>
            <li>
            	کاربران متعهد می شوند که مطالب این سامانه را در تارنماهای شخصی منتشر نکنند. مگر اینکه چنین نشری با هماهنگی و ذکر نام هومینجا صورت بگیرد.
            </li>
            <li>
            	کاربران متعهد می شوند که از انجام اعمالی که منجر به آسیب به سامانه و از کار افتادگی جزئی و کلی آن می شود، خودداری نمایند.
            </li>
            <li>
            	کاربران متعهد می شوند که محتوا و مطالب موجود در سامانه را در جهت فروش و تولید محتوا در تارنماهای تجاری دیگر منتشر نکنند.
            </li>
            <li>
            	اطلاع رسانی و هرگونه ارتباط سامانه با کاربران از طریق اطلاعات وارد شده توسط آن ها می باشد. بنابراین کاربران می بایست در زمان عضویت،
               اطلاعات کامل، صحیح و دقیق خود را در سامانه وارد نمایند. بدیهی است که در صورت وارد ساختن اطلاعات نادرست کاربران حق اعتراض نخواهند داشت.
            </li>
            <li>
            	کاربران متعهد می شوند که در زمان افزودن ملک در سامانه اطلاعات دقیق و صحیح ملک مورد نظر خود را وارد نمایند.
            </li>
            <li>
            	کاربران متعهد می شوند که از محتوا و مطالب سامانه انلاین هومینجا در جهت مقاصدی که بر خلاف قوانین موضوعه و قوانین و مقررات این سامانه است، بهره نخواهند برد.
            </li>
            <li>
            	کاربران متعهد می شوند که قبل از انجام هرگونه معامله، از صحت وسقم آگهی مد
               نظرشان اطمینان یابند. همچنین کاربران می بایست اسناد و مدارک ملک مورد نظر را بررسی و حتی در صورت نیاز با مشاوره ای آگاه اقدام به مشورت نمایند.
            </li>
            <li>
            	هومینجا برای هیچ کاربری به عنوان نماینده املاک عمل نمی کند و هیچ گونه خرید ، فروش یا تبادل ملک انجام نمی 
              دهد. هومینجا صرفا برای انتشار آگهی املاک ایجاد شده است. گروه هومینجا تعهدی برای تأیید آگهی هایی که اعضای سایت منتشر می کنند، ندارد. هومینجا
               تنها بین جویندگان ملک، مالکین و مشاورین املاک، ارتباط ایجاد می کند و مسئولیت هیچ ضرر یا هزینه ای را که به هر طریق از معامله ملک ناشی می شود، 
              بر عهده نمی گیرد. همچنین در صورت بروز مشکل بین هومینجا، کاربران و مشاورین املاک، مساله طبق قوانین و مقررات جمهوری اسلامی ایران حل و فصل می شود.
            </li>
            <li>
              برای دریافت مشاوره جهت تبدیل وجه شما؛ ناشی از خرید و فروش ملک با رمزارز، به سایت و پشتیبانی <a className="zone_text_link" href="https://mycryptocommerce.com" target="_blank" >صرافی کریپتوکامرس </a> مراجعه نمایید.
            </li>
           </ul>
          </Typography>
          <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"justify",padding:"0 0 5%"}}>
          قوانین مربوط به بلاگ هومینجا برای برقراری ارتباط بهتر با مشاورین املاک، مصاحبه و گزارشاتی را بر 
          روی بخش بلاگ خود در زمینه املاک و مشکلات صنفی منتشر می کند . استفاده از مطالب مندرج در این بخش با ذکر منبع بلامانع است.  نکته مهم اینکه قوانین مندرج 
          در این صفحه در طول زمان قابل تغییر است، این تغییر در همین صفحه درج و به روزرسانی می‌شود. مسئولیت مطالعه و آگاهی از این تغییرات برعهده کاربران است.
          </Typography>
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography component={"p"} >
          این صفحه مربوط به قوانین فعالیت کاربران در بهترین سایت جستجو و درج آگهی ملک | املاک و مستغلات هومینجامی باشد.
          </Typography>
          </Grid>
      </Grid>
    )
  };
}

export default Terms;