import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article66/01.jpg";
import photo02 from "../../images/mag/article66/02.jpg";
import photo03 from "../../images/mag/article66/03.jpg";
import photo04 from "../../images/mag/article66/04.jpg";
import photo05 from "../../images/mag/article66/05.jpg";
import photo06 from "../../images/mag/article66/06.jpg";
import photo07 from "../../images/mag/article66/07.jpg";

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article66 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "کامرانیه یکی از گران قیمت ترین محله های منطقه یک تهران می باشد که ما در این مقاله به بررسی این خیابان و تمامی امکانات آن می پردازیم. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "همه چیز در مورد محله کامرانیه | خرید ملک در کامرانیه | فروش ملک در کامرانیه | رهن و اجاره ملک در محله کامرانیه | امکانات خیابان کامرانیه | قیمت ملک و آپارتمان در کامرانیه | معرفی کامل محله کامرانیه در منطقه ۱ تهران | بهترین خیابان های کامرانیه | قیمت خانه در کامرانیه | عکس خیابان کامرانیه | مشاور املاک کامرانیه | کامرانیه تهران کجاست | کامرانیه منطقه چنده"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[65].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[65].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[65].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[65].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[65].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[65].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کامرانیه تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[65].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کامرانیه یکی از محله های کوچک و قدیمی <Link className="link" to="/tehran-zone-1" target="_blank">  منطقه یک </Link> شهرداری تهران محسوب می شود. در این مقاله از سایت هومینجا قصد داریم تا به معرفی کامل محله کامرانیه، راهنمای خرید یا اجاره آپارتمان، دسترسی و امکانات رفاهی محله و بررسی <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">  برج های لوکس </Link> و آپارتمان های شیک و معروف آن بپردازیم. پس با تیم محتوا <LinkMui className="link" href="https://sorinapp.com" target="_blank">  سورین </LinkMui> همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                کامرانیه تهران کجاست
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="محله کامرانیه تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کامرانیه تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیابان کامرانیه به عنوان یکی از شمالی ترین <Link className="link" to="/tehran-zones" target="_blank">  مناطق </Link> تهران محسوب می شود. یکی از گران قیمت ترین محله های تهران است که با محله نیاوران از شمال همسایه می باشد. محله کامرانیه از جنوب به اتوبان شهید صدر و از غرب با بلوار <Link className="link" to="/Listings/buy_tehran_andarzgoo" target="_blank">  اندرزگو </Link> و از شرق به محله <Link className="link" to="/Listings/buy_tehran_farmaniyeh" target="_blank">  فرمانیه </Link> منتهی می شود. محله کامرانیه در سه کیلومتری <Link className="link" to="/Listings/rent_tehran_tajrish" target="_blank">  تجریش </Link> و در همسایگی محله <Link className="link" to="/Listings/rent_tehran_chizer" target="_blank">  چیذر </Link> و <Link className="link" to="/Listings/buy_tehran_ghaitarieh" target="_blank">  قیطریه </Link> واقع شده است.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمین های کامرانیه در ابتدا متعلق به میرزا سعید خان انصاری، وزیر ناصرالدین شاه بود. بعد از آن <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%DA%A9%D8%A7%D9%85%D8%B1%D8%A7%D9%86_%D9%85%DB%8C%D8%B1%D8%B2%D8%A7" target="_blank"> کامران میرزا </LinkMui> پسر ارشد ناصرالدین شاه با خرید زمین های محله <Link className="link" to="/Listings/buy_tehran_jamaran" target="_blank">  جماران </Link> و <Link className="link" to="/Listings/rent_tehran_hesarbuali" target="_blank">  حصار بوعلی </Link>، علنا محله کامرانیه را تصاحب کرد و با ترک اجباری مردم برای خود باغ و اقامتگاه تابستانی برپا کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از فوت کامران میرزا ، برخی زمین های جنوبی کامرانیه به فرمانفرما فروخته شد. که محله کامرانیه از همین نقطه شروع شد و رفته رفته به مساحت کامرانیه اضافه شد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Andarzgoo-blvd" target="_blank">
                  بیشتر بخوانید : خیابان اندرزگو تهران | معرفی کامل خیابان اندرزگو با تصویر و نقشه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                آب و هوای کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از ویژگی های محله کامرانیه میتوان به هوای دلپذیر آن اشاره کرد. دلیل این امر وجود کوه های کلکچال در شمال و باغ های فراوان ( باغ سفارت ایتالیا) و درختان کهن در مرکز و جنوب این محله مرفه نشین است.
              </Typography>
              <Typography className="h2" variant="h2">
                برج های معروف کامرانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="برج روما رزیدنس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  برج روما کامرانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کامرانیه به دلیل واقع بودن در شیب خیابان بازدار و قواره زمین های بزرگ بستر بسیار مناسبی برای برج های لوکس یا آسمان خراش های مدرن می باشد. به همین دلیل کامرانیه را بهشت آپارتمان های لاکچری با امکانات هتلینگ دانست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج های کوه نور و روما رزیدنس به عنوان مشهورترین مجتمع های مسکونی تجاری کامرانیه در شمیران دانست.
              </Typography>
              <Typography className="h3" variant="h3">
                برج روما رزیدنس کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج فوق لاکچری کامرانیه بر زمینی به مساحت 7 هزار متر مربع ساخته شده است. برج روما دارای 65 واحد مسکونی و 15 طبقه می باشد که طبقه 14 و 15 آن پنت هاوس برج روما قرار دارد. واحد های این برج از 30 متر تا 1400 با <Link className="link" to="/Interior-architecture-and-interior-design" target="_blank">  معماری </Link> بسیار متفاوت از بقیه ساختمان های تهران می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مالک برج روما کامرانیه، مهندس <LinkMui className="link" href="https://rasooldanialzade.com" target="_blank">  رسول دانیال زاده </LinkMui> ( سلطان فولاد ایران) می باشد. این برج توسط مهندس معمار معروف، <LinkMui className="link" href="https://farzaddaliri.com/" target="_blank">  فرزاد دلیری </LinkMui> طراحی و ساخته شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از واحد های پنت هاوس برج روما رزیدنس متعلق به آقای گل ایران علی دایی می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله امکانات برج روما می توان به سالن بیلیارد، سینما سه بعدی، سالن بدنسازی، استخرکوکان و بزرگسالان همراه با جکوزی و سونا، <Link className="link" to="/smart-home" target="_blank">  خانه هوشمند </Link> جهت کنترل انرژی اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                برج کوه نور کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج کوه نور که از جمله قدیمی ترین ساختمان های معروف کامرانیه و فرمانیه بشمار می رود دارای دوطبقه تجاری و چندین طبقه مسکونی است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مالک کوه نور آقایان اسکندری و حسینی هستند که با طراح معروف فرزاد دلیری برای ساخت آن وارد همکاری شدند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محل کوه نور در تقاطع اندرزگو، لواسانی و کامرانیه می باشد که زیبایی خاصی به این منطقه روا داشته است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">
                  بیشتر بخوانید : الهیه تهران کجاست ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                خیابان های مهم کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله کامرانیه از دو قسمت کامرانیه شمالی که شامل خیابان شهید علیرضا بازدار است و کامرانیه جنوبی که خیابان پاشا ظهری می باشد تقسیم شده است. این دو، خیابان اصلی محله کامرانیه هستند. از جمله خیابان های مهم دیگر می توان به خیابان تاجیکی، خیابان <Link className="link" to="/Listing/1058" target="_blank">  ثروتی </Link>، خیابان <Link className="link" to="/Listing/1061" target="_blank">  شیبانی </Link>، خیابان <Link className="link" to="/Listing/1062" target="_blank">  مروارید </Link> و خیابان <Link className="link" to="/Listing/1063" target="_blank">  داریوش </Link> اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                پارک و بوستان های کامرانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="بوستان صدر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بوستان صدر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کامرانیه به دلیل حضور باغ های قدیمی و درختان کهن دارای پارک و بوستان های متعددی می باشد که در زیر نام بعضی از آنها آورده شده است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان پگاه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان امام علی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پارک مینا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستام مهر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان صدر
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز خرید کامرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجتع خرید کوه نور
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجتمع تجاری دریای نور
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجتمع تجاری - اداری روما سنتر
              </Typography>
              <Grid className="box">
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  بیشتر بخوانید : معرفی کامل محله نیاوران تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مسیر رفتن به کامرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای دسترسی به محله کامرانیه جدا از ماشین شخصی از وسایل نقلیه عمومی نیز می توانید بهره ببرید. نزدیکترین ایستگاه مترو به کامرانیه، شهید صدر می باشد. اما با خطوط اتوبوس رانی متعددی راه رسیدن به کامرانیه را فراهم می سازد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                از مهم‌ترین خطوط اتوبوسرانی کامرانیه، خطوط اتوبوس شهرک قائم به تجریش و یا شهرک صدف به بلوار قیطریه می باشد. همچنین از طریق پایانه لاله به پایانه افشار نیز می توان به کامرانیه دسترسی داشت.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه 1 تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                قیمت آپارتمان در کامرانیه تهران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="قیمت ملک در کامرانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قیمت ملک در کامرانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که آپارتمان های ساخته شده در کامرانیه جز لوکس ترین آپارتمان های تهران می باشد بنابراین قیمت ملک در کامرانیه بسیا نجومی می باشد. همچنین عواملی مانند سن بنا، کیفیت متریال استفاده شده، نوع ملک نیز بر قیمت خانه تاثیرگذار است. اما بطور کلی دلیل گرانی ملک در کامرانیه متراژهای بالای واحدها و برج های لوکس این منطقه می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                آگهی خرید و فروش ملک در کامرانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="خرید و فروش ملک در کامرانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خرید و فروش ملک در کامرانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت از مقاله به آگهی های خرید و فروش ملک در کامرانیه که در سامانه املاک و مستغلات هومینجا به ثبت رسیده است می پردازیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/buy_tehran_kamranieh" target="_blank">
                  خرید و فروش ملک در کامرانیه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                آگهی رهن و اجاره ملک در کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت از مقاله به آگهی های رهن و اجاره ملک در کامرانیه که در سامانه املاک و مستغلات هومینجا به ثبت رسیده است می پردازیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/rent_tehran_kamranieh" target="_blank">
                  رهن و اجاره ملک در کامرانیه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بهترین مشاور املاک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="املاک وی ای پی کامرانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  املاک وی ای پی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مشاورین املاک بسیاری در این منطقه مشغول به فعالیت می باشند که هر کدام به نوبه ی خود در کارشان ماهر هستند. در این بخش از مقاله به معرفی تعدادی از این مشاورین املاک فعال در منطقه می پردازیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بنگاه املاک کامرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آژانس متین
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بنگاه وی آی پی
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین سایت معاملات ملک در کامرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایت هومینجا سامانه تخصصی در زمینه خرید و فروش و رهن و اجاره ملک می باشد که تمامی مناطق تهران را پشتیبانی می کنند. اما می توان گفت هومینجا به صورت تخصصی روی منطقه 1 به خصوص محله کامرانیه فعالیت دارد. بنابراین با کمک سامانه هومینجا و پشتیبان های 24 ساعته قادر خواهید بود بهترین ملک و آپارتمان را در زمینه خرید و فروش یا رهن و اجاره بیابید.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد شاید تصمیم به سکونت در محله کامرانیه را داشته باشند اما آشنایی کاملی از این خیابان نداشته باشند. ما در این <Link className="link" to="/Kamranieh-Neighborhood" target="_blank">  مقاله </Link> سعی کردیم تا به معرفی کامل این محله بپردازیم. امید است که مطالعه این مطلب در انتخاب شما مفید واقع شود.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_farmaniyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در فرمانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_farmaniyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره اپارتمان در فرمانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article66;