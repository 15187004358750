import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article08/01.jpg";
import photo02 from "../../images/mag/article08/02.jpg";
import photo03 from "../../images/mag/article08/03.jpg";
import photo04 from "../../images/mag/article08/04.jpg";
import photo05 from "../../images/mag/article08/05.jpg";
import photo06 from "../../images/mag/article08/06.jpg";
import photo07 from "../../images/mag/article08/07.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";



class Article08 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "فنگ شویی هنر و علمی قدیمی است که برای چیدمان و دکوراسیون درست اشیا در ساختمان های مسکونی و تجاری به کار می رود";
    document.getElementsByTagName('meta')["keywords"].content = "چیدمان وسایل خانه بر اساس اصول فنگ شویی | رابطه دکوراسیون و فنگ شویی | طراحی و دکوراسیون آپارتمان بر اساس انرژی |تاثیرات فنگ شویی در محیط | صفر تا صد فنگ شویی | کاربردهای فنگ شویی در خانه "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[7].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[7].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[7].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[7].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[7].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[7].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="فنگ شویی چیست؟ از کاربرد علم فنگ شویی در خانه چه می دانید؟ - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[7].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این روزها فنگ  شویی در بین
                مردم بسیار محبوب شده است. عده ای فکر می کنند با جابجایی لوازم منزل، ثروت و خوشبختی به سمتشان سرازیر می شود. آیا واقعا اینچنین است؟ ما در <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link>
                به نحوه چیدمان و دکوراسیون منزل بر اساس فنگ شویی می پردازیم. با ما همراه باشید تا با فنگ شویی و اثرات آن در محیط خانه بیشتر آشنا شوید.
              </Typography>
              <Typography className="h2" variant="h2">
                فنگ شویی چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="فنگ شویی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  فنگ شویی یا چیدمان منزل چیست
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در زبان چینی فنگ شویی به معنای آب و باد است. علمی قدیمی است که برای چیدمان و دکوراسیون درست اشیا در ساختمان های مسکونی و تجاری بکار می رود.
                بطور خلاصه می توان گفت فنگ شویی فلسفه و هنر ایجاد تعادل و هماهنگی در انرژی های محیط اطرافمان می باشد. با دانستن این علم می توان انرژی را تشخیص داد
                و به بهترین شکل از آن استفاده کرد. به طور کلی هدف از فنگ شویی را می توان ایجاد حس هماهنگی در خانه و در نتیجه آرامش و نشاط اعضای خانواده دانست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اولین اصل فنگ شویی خلوت کردن است تا انرژی بهتری در جریان باشد. برای خلوت کردن می توانید از هر جایی شروع
                کنید. کمد لباس هایی که سالی یکبار هم استفاده نمی کنید یا میز کارتان که پر از کاغذها و خودکارهای بی مصرف است یا ذهنتان که سرشار از نگرانی و استرس است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Midex" target="_blank">
                  بیشتر بخوانید : نمایشگاه میدکس چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاریخچه فنگ شویی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این علم به 4000 سال قبل از میلاد برمی گردد. پیدایش
                فنگ شویی از قاره آسیا اتفاق افتاد. مردم چین اولین کسانی بودند که متوجه شدند برای بهبود کیفیت زندگی بهتر است بجای نبرد با طبیعت به هماهنگی با آن بپردازند.
                <br />
                مردی به نام فورهس را می توان پایه گذار
                این علم دانست. یک روز که کنار رودخانه در حال مراقبه بود لاک پشت عظیمی را مشاهده کرد که در حال بیرون آمدن از آب بود. خال های سفید و سیاه روی لاک آن در یک مربع
                قرار داشتند. ترتیب قرارگیری خال ها به گونه ای بود که جمع آن ها از هر طرف برابر 15 می شد. این کشف باعث تفکر بسیار و شگفتی شد و سرانجام علم فنگ شویی را رقم زد.
              </Typography>
              <Typography className="h2" variant="h2">
                عناصر فنگ شویی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="عناصر فنگ شویی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  عناصر فنگ شویی چیست
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گردش انرژی در فنگ شویی از پنج عنصر آب، چوب، آتش، خاک و فلز می باشد. همه
                این عناصر در اصول اولیه علم فنگ شویی نشانگر تغییرند. یعنی باعث آفرینش دیگری می شوند. آب باعث رشد درخت می شود. خاک فلز را بوجود می آورد. درخت باعث سوخت آتش می شود.
                <br />
                هر کدام از این عناصر با یک جهتی همراه هستند. انرژی عنصر چوب در جهت شرق و جنوب شرق، آتش در جنوب، خاک مرکز و جهت شمال شرق و جنوب
                غرب موثر است. عنصر فلز بر جهت غرب و شمال غرب و عنصر آب در جهت شمال تاثیرگذار است. این عناصر برای کارایی باید در هماهنگی باشند در غیر اینصورت مشکل ساز می شوند.
              </Typography>
              <Typography className="h2" variant="h2">
                فنگ شویی و استفاده از رنگ ها
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="فنگ شویی و رنگ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  فنگ شویی و استفاده از رنگ ها
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رنگ ها اثر قابل توجهی روی عملکرد افراد دارند. سعی کنید به جای استفاده از
                یک رنگ برای دکوراسیون از چند رنگ هماهنگ برای طراحی استفاده کنید. رنگ های تیره احساس آرامش را به ارمغان می آورند در حالیکه رنگ های روشن باعث افزایش انرژی می شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رنگ قرمز برای خوش شانسی استفاده می شود. این رنگ بهترین انتخاب برای درب ورودی منزل است.
                <br />
                رنگ های زرد و بنفش برای روحیه انسان بسیار موثرند. استفاده از این رنگ ها برای اتاق نشیمن و <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> اتاق خواب </Link> مناسب هستند.
                <br />
                رنگ نارنجی و قهوه ای بعنوان سمبل سلامتی و دانش استفاده می شوند.
                <br />
                رنگ سفید نیز موجب استحکام پیوند خانوادگی می شود.
                <br />
                برای ایجاد حس تازگی و رشد از رنگ سبز استفاده کنید.
                <br />
                آبی و سیاه برای ایجاد خنکی و آرامش و ایجاد حریم خصوصی کاربرد دارد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/smart-home" target="_blank">
                  بیشتر بخوانید : خانه هوشمند چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                قوانین فنگ شویی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محیط های
                زندگی از جمله محیط کار و خانه ما دارای انرژی های متفاوتی هستند. شما با دانستن قوانین  فنگ شویی می توانید با کوچکترین تغییرات بهترین سطح انرژی را به ارمغان بیاورید.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="قوانین فنگ شویی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  چیدمان با استفاده از قوانین فنگ شویی
                </figcaption>
              </figure>
              <ul className="p">
                <li className="li">آب باعث جذب انرژی مثبت می شود. بنابراین بهتر است از آکواریوم ، تنگ ماهی یا <Link className="link" to="/keeping-apartment-flower" target="_blank"> گلی </Link> که در آب رشد می کند برا ی حفظ انرژی در خانه استفاده کنید.</li>
                <li className="li">
                  از انجا که سرویس بهداشتی و حمام و <Link className="link" to="/laundry-room" target="_blank"> لاندری روم </Link> منبع انرژی های منفی است بنابراین سعی کنید تا حد امکان حوله و مسواک خود را در حمام و
                  دستشویی قرار ندهید. برای این کار بهتر است کمد سربسته ای را برای این مورد مدنظر قرار دهید. چنانچه مکان سربسته وجود ندارد بهتر است از مسواک های درپوش دار استفاده کنید.
                </li>
                <li className="li">راهروی ورودی باید مرتب باشد. وسایل غیر ضروری را برداشته و کفش ها را مرتب در جا کفشی قرار دهید.</li>
                <li className="li">از عناصر طبیعی مثل گل و چوب و سنگ برای جذب انرژی استفاده کنید. از گل مصنوعی تا حد امکان در چیدمان منزل استفاده نکنید.</li>
                <li className="li">بهتر همیشه یک ظرف میوه تازه یا چند شاخه گل طبیعی روی میز پذیرایی قرار دهید.</li>
                <li className="li">آینه روبروی درب ورودی نباشد چون انرژی های خوب و مثبتی که وارد خانه می شود در برخورد با آینه منعکس شده و بیرون می رود.</li>
                <li className="li">انرژی حمام منفی و ناخوشایند است. زمانیکه از حمام استفاده نمی کنید برای جلوگیری از ورود انرژی های منفی درب حمام را ببندید.</li>
                <li className="li">گوشه و کنار خانه را با گل و گیاه پر کنید. گیاهان با برگ های پهن مانند زنبق، بامبو و گونه های یشم در صدر گیاهان انرژی بخش قرار دارند</li>
                <li className="li">هر جای خانه که نشتی دارد را تعمیر کنید</li>
              </ul>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                اما دیگر نکاتی که در این قسمت باید مدنظر قرار داد عبارتند از:
              </Typography>
              <ul className="p">
                <li className="li">از آنجا که انرژی طبقه همکف به کل خانه تاثیرگذار است باید همیشه مرتب باشد.</li>
                <li className="li">تلویزیون را در اتاق خواب قرار ندهید</li>
                <li className="li">آینه کاری بالای میز ناهار خوری نماد برکت است اما آینه نباید اجاق گاز و سرویس بهداشتی و آشپزخانه را نشان دهد.</li>
                <li className="li">از آینه کاری سقف اتاق خواب اجتناب کنید</li>
                <li className="li">در سرویس بهداشتی نمک تصفیه شده قرار دهید و ماهی یک بار آن را شسته و سالی یکبار نیز آن را تعویض کنید</li>
                <li className="li">بهتر است لباس های اضافی در کمد چیده شود. انباشته کردن لباس ها پشت درب اتاق باعث مسدود شدن انرژی می شود.</li>
                <li className="li">گوشه ای از خانه را برای تنها بودن و استراحت و تفکر خودتان اختصاص دهید.</li>
                <li className="li">برای ایجاد روابط سالم بین اعضای خانواده چند شاخه گل زرد را در گلدان سرامیکی کرم رنگ و در جنوب غربی منزل قرار دهید.</li>
                <li className="li">برای پیشرفت شغلی بهتر است اتاق کار را در قسمت شمالی قرار دهید.</li>
                <li className="li">
                  قراردادن عکس های اعضای خانواده معمولا در شرق خانه یکی از موارد مهم دیگری است که در فنگ شویی بهتر است به آن توجه کرد
                  . عکس شاد خانوادگی برای بهبود روابط خوب هستند. بهتر است این عکس ها را  در شرق اتاق نشیمن قرار دهید. همچنین عکس زوج ها در دو قاب جداگانه نباشد
                </li>
                <li className="li">از تصاویر جنگی و غم انگیز خودداری کنید.</li>
                <li className="li">از آنجا که آویزان‌کردن اسلحه حال و هواي جنگ را برای انسان زنده می‌کند، می‌تواند باعث بروز مشاجره اعضاي خانواده شود. بنابراین از انجام این کار خودداری کنید.</li>
                <li className="li">
                  شومینه نماد آتش
                  و بخش شمالی نماد آب است! با نصب شومینه یک چرخه مخرب ایجاد می کنید. بنابراین بهتر است شومینه را خاموش نگه دارید و از وسایل چوبی در این فضا استفاده ‌‌كنيد‌‌ .
                </li>
                <li className="li">از آنجا که تقابل آب و اتش باعث ایجاد عدم تفاهم و مشاجره می شود بنابراین در آشپزخانه یخچال و گاز را کنار هم قرار ندهید.</li>
                <li className="li">زدودن گرد و غباراز وسایل و لوازم منزل نقش مهمی در جریان داشتن انرژی دارد</li>
              </ul>
              <Typography className="h2" variant="h2">
                فنگ شویی و دکوراسیون
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در فنگ شویی رعایت یک سری اصول ساده در دکوراسیون داخلی منزل به خصوص <Link className="link" to="/small-house-decoration" target="_blank"> دکوراسیون خانه های کوچک </Link> می تواند تا حد زیادی آرامش و خوشبختی را به ارمغان آورد. محل قرارگیری وسایل و <Link className="link" to="/pouf" target="_blank"> مبلمان </Link> در فنگ شویی یکی از
                مسائل مهم در طراحی دکوراسیون منزل می باشد. پس سعی کنید با بکارگیری این علم و هنر ،انرژی مثبت را در منزل تان جاری سازید و از زندگی سالم تر و موفق تری برخوردار شوید.
              </Typography>
              <Typography className="h3" variant="h3">
                آینه:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="فنگ شویی و دکوراسیون - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاثیر اینه درفنگ شویی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آینه در فنگ شویی به عنوان وسیله ای برای جذب و تغییر جهت انرژی بکار گرفته می شود.آینه ها برای  بزرگ نشان داده شدن و پنهان
                کردن فضا کاربرد دارند. نکته مهمی که در فنگ شویی با آینه قابل توجه است این است که آینه باید سالم و کامل باشد و به شکلی نباشد که اشیا تکه تکه و شکسته نمایش داده شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال چیست ؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                ساعت:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                استفاده از ساعت در فنگ شویی بسیار حائز اهمیت است. ساعت در فنگ شویی
                اندازه گیری گذر زمان است. فضاهایی در خانه که بهتر است ساعت در آن بکار گرفته شود آشپزخانه ، اتاق نشیمن یا اتاق کار است. بکارگیری ساعت در اتاق خواب نیز از ضروریات است.
              </Typography>
              <Typography className="h3" variant="h3">
                نقاشی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                بکارگیری نقاشی می تواند انرژی محیط را افزایش دهد. برای افزایش طول عمر و سلامتی بهتر است از تصاویر آهوی کوهی، دختران زیبا، بامبو یا درخت کاج استفاده کنید
              </Typography>
              <Typography className="h3" variant="h3">
                لوستر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از نظر فنگ‌شویی روشن‌ بودن تمامی زوایا و گوشه‌های خانه، یکی از مهم‌ترین اصول برای حفظ جریان گردش انرژی است. بنابراین انتخاب <Link className="link" to="/chandelier-in-decoration" target="_blank"> لوستر </Link> کریستالی می‌تواند بهترین گزینه برای انتشار نور به نقاط مختلف خانه باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                نور طبیعی:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="دکوراسیون و فنگ شویی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاثیر نور در فنگ شویی و دکوراسیون
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نور طبیعی و هوای تازه یکی از موارد مهم برای به جریان انداختن انرژی مثبت
                در فنگ شویی هستند. باز کرد پنجره و استفاده از پرده های نازک و روشن باعث ورود نور و هوای تازه به خانه می شود. این امر باعث جریان انرژی در منزل می شود.
              </Typography>
              <Typography className="h2" variant="h2">نتیجه گیری:</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به زندگی پرتنش امروزی همه ما بدنبال مکانی برای استراحت و ارامش هستیم . خانۀ هر کس می تواند امن‌ترین و اصلی‌ترین منبع آرامش او باشد. بنابراین برای داشتن خانه‌ای پر از
                آرامش بهتر است در هومینجا با فنگ شویی آشنا شویدو با اجرای آن‌ها در چیدمان و دکوراسیون خانه خود فضایی آرامش‌بخش را برای خود و خانواده‌تا ن به ارمغان آوری
              </Typography>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("خانه و سبک زندگی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  خانه و سبک زندگی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/fengshui" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/fengshui" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/fengshui" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article08;