import { Grid } from "@mui/material";
import React from "react";

export class ArticleAd1 extends React.Component {
  render(){
    return(
      <Grid></Grid>
      // <Grid sx={{position:{xs:"static",md:"sticky"},top:"100px",overFlow:"hidden"}}>
      //   <Typography component={"img"} src={imageAd2} alt="advertisement" sx={{width:"100%",height:"auto",borderRadius:"5px"}}/>
      // </Grid>
    )
  }
}

export class ArticleAd2 extends React.Component {
  render(){
    return(
      <Grid></Grid>
      // <Grid sx={{position:{xs:"static",md:"sticky"},top:"100px",overFlow:"hidden"}}>
      //   <Typography component={"img"} src={imageAd2} alt="advertisement" sx={{width:"100%",height:"auto",borderRadius:"5px"}}/>
      // </Grid>
    )
  }
}

export class ArticleAd3 extends React.Component {
  render(){
    return(
      <Grid></Grid>
    )
  }
}

export class ArticleAd4 extends React.Component {
  render(){
    return(
      <Grid></Grid>
    )
  }
}

