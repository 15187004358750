import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article46/01.jpg";
import photo02 from "../../images/mag/article46/02.jpg";
import photo03 from "../../images/mag/article46/03.jpg";
import photo04 from "../../images/mag/article46/04.jpg";
import photo05 from "../../images/mag/article46/05.jpg";
import photo06 from "../../images/mag/article46/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article46 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "با وجود تمام تفاوت هایی که بین معماری داخلی و طراحی داخلی وجود دارد اما هر دو مکمل یکدیگر هستند و به بهبود فضا کمک می کنند.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "معماری داخلی و طراحی داخلی چه فرقی دارند | بازار کار معماری داخلی | همه چیز در مورد معماری داخلی و طراحی داخلی | طراح داخلی و معمار داخلی چه ویژگی هایی دارند "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[45].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[45].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[45].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[45].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[45].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[45].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="معماری و طراحی داخلی - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[45].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چه تفاوتی بین معماری داخلی و طراحی داخلی وجود دارد؟ برای درک بهتر این مطلب تعریف مختصری از هرکدام داریم تا بهتر تفاوت های آنها مشخص شود. پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از ما این دو عبارت را به اشتباه به جای هم بکار می بریم. با توجه به اینکه هر دو کلمه از لحاظ کاربردی وجه اشتراک زیادی با هم دارند اما در اصل تفاوت های بسیاری نیز ما بین آن ها وجود دارد. ما در <Link className="link" to="/HomeMag" target="_blank">  هومینجا بلاگ </Link> به تعریف وظایف و حوزه ی کاری هر کدام می پردازیم و تفاوت های این دو را مود بررسی قرار می دهیم.
              </Typography>
              <Typography className="h2" variant="h2">
                معماری داخلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="معماری داخلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  معماری داخلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                علم مدلسازی فضای درونی یک ساختمان را معماری داخلی می نامند. معماری علم آفرینش فضا می باشد. معماری داخلی بر اساس استانداردهای موجود انجام می شود.قدمت معماری به تاریخ زندگی بشر برمی گردد. این علم در گذشته، فن و مهارت برای ایجاد فضای امن بود اما امروزه به معنای ساختمان سازی با تلفیقی از هنر، روانشناسی، توسعه اقتصادی، جامعه شناسی، محیط زیست و حتی <Link className="link" to="/fengshui" target="_blank">  فنگ شویی </Link> می باشد. معماری داخلی با ویژگی های روحی و روانی انسان ارتباط مستقیم دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ساخت یک ملک به بهترین شکل، عوامل متعددی دخیل هستند. بطور کلی می توان گفت معماری داخلی راه ارتباطی بین <LinkMui className="link" href="https://en.wikipedia.org/wiki/Architecture" target="_blank">  معماری </LinkMui> و طراحی داخلی می باشد. به کمک معمار داخلی می توان فضای داخلی ساختمان را برای انجام فعالیت های روزانه، کار و زندگی بهینه کرد. این علم دایره وسیعی از فرم، متریال، نور، رنگ، سقف و <Link className="link" to="/accent-wall" target="_blank">  دیوار </Link> است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">
                  بیشتر بخوانید : معرفی برج های لوکس منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                وظایف معمار داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                1. مهندس معمار داخلی باید مواد و مصالح را کامل بشناسد و پیشنهاد استفاده از هر مصالح ساختمانی را متناسب با سبک و فضای کاربردی بدهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                2. نیازهای پروژه را بررسی کند و طرحی ارائه دهد که با بودجه کارفرما و قوانین و مقررات مطابقت داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                3. به نرم افزارهای معماری و طراحی تسلط داشته باشد تا بتواند طرح خود را به بهترین طریق به کارفرما ارائه دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                طراحی داخلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="طراح داخلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  طراح داخلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به هنر طراحی فضای داخلی ساختمان طراحی داخلی می گویند. طراحی داخلی حرفه ای است که در آن تمام جوانب طراحی فضاهای درونی مورد بررسی قرار می گیرد. این رشته یکی از شاخه های طراحی معماری می باشد. در طراحی داخلی دو عنصر زیبایی بصری و کاربردی بودن فضا نقش اساسی ایفا می کند. طراحی داخلی ترکیبی از معماری داخلی و <Link className="link" to="/small-house-decoration" target="_blank"> دکوراسیون داخلی </Link> می باشد. هدف آن طراحی فضاهای مختلف می باشد. طراح داخلی فضا ها و فرم های داخلی ساختمان را با تمرکز بر اصول طراحی معماری سازماندهی می کند. طراح داخلی در صورت داشتن سررشته در کارهای هنری و درک هنری بالا می تواند در چیدمان تزئینات داخلی نیز فعالیت کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی داخلی گرایش های مختلفی دارد که از مهمترین گرایش های آن می توان موارد زیر را نام برد:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی داخلی ساختمان مسکونی،طراحی داخلی ساختمان تجاری، مراکز تفریحی، شرکتی، درمانی
              </Typography>
              <Grid className="box">
                <Link className="link" to="/smart-home" target="_blank">
                  بیشتر بخوانید : خانه هوشمند
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                وظایف طراح داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراح داخلی وظایف و مسئولیت های متفاوتی دارد که در زیر به شرح برخی از این وظایف می پردازیم:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                1. طراح داخلی باید با کارفرما ارتباط موثر برقرار کند و خواسته های کارفرما را به شیوه‌ای کاملاً حرفه ای و فنی اجرا کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                2. طراح داخلی مسئولیت انتخاب و چیدمان لوازم منزل و مبلمان، رنگ، نور و… را انجام می دهد. با وجود اینکه بخش در حوزه‌ی کاری دکوراسیون داخلی است ، ولی یک طراح داخلی نیز قادر به انجام دادن آن می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                3. طراح داخلی باید مهارت های کامل و جامع نرم افزاری از جمله (CAD)، (۳D Home Design)، (RoomSketcher) و حتی (AutoDesk) را داشته باشد و قادر باشد نقشه‌های دو بعدی و سه بعدی را طراحی کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراح داخلی معمولا با معماران و پیمانکاران ساختمان همکاری می کند تا  خواسته های کارفرما را انجام دهند.  پروژه طراحی امکان دارد مربوط به یک فضای مسکونی باشد یا یک اداره، یک هتل و یا هر فضای داخلی دیگر!
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت های معماری داخلی و طراحی داخلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="تفاوت معماری و طراحی داخلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  معماری داخلی و طراحی داخلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید برای شما این سوال پیش آمده باشد که چه زمان از معمار داخلی و چه زمانی از طراح داخلی باید مشورت بگیریم؟ ما در اینجا به برخی از تفاوت های این دو می پردازیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                زمانیکه شما قصد تغییراتی مثل جابجایی دیوارها، تغییرات تاسیساتی یا کم و زیاد کردن در و پنجره را دارید باید از معمار داخلی کمک بگیرید. اما زمانیکه در چیدمان و دکوراسیون داخلی منزل نیاز به مشورت دارید باید از ایده های یک طراح داخلی بهره مند شوید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای معماری داخلی نیاز به تحصیل در دانشگاه و مدرک دانشگاهی دارید. اما طراح داخلی با تجربه زیبا شناختی فضای داخلی میسر می شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                معماری داخلی علمی انجام پذیر است اما طراح داخلی با دید هنری قابلیت اجرا دارد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/building-certificate" target="_blank">
                  بیشتر بخوانید : همه چیز راجع به صدور پروانه ساختمان
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                از دیگر تفاوت های معماری و طراحی داخلی می توان به موارد زیر اشاره کرد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                معماری داخلی از ساخت بنا آغاز می شود ولی طراحی داخلی بعد از اتمام ساخت آغاز می شود و در داخل فضا اجرا می شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                طراحی داخلی وابسته به معماری داخلی است اما معماری داخلی کاملا مستقل عمل می کند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                طراحی داخلی روی فضا تاثیرگذار است اما معماری داخلی روی سازه کار می کند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در معماری داخلی به مباحث تکنیکی فضا می پردازیم اما در طراحی داخلی با ظاهر فضای داخلی ساختمان در ارتباط هستیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                معمار داخلی همیشه از ابتدا تا انتهای کار وجود دارد. اما طراحی داخلی گاهی از ابتدای کار و در برخی موارد بعد از اتمام ساخت ایفای نقش می کند. طراحی داخلی روی قسمت های هنری، چیدمان، نورپردازی، رنگ و نوع متریال تاثیر گذار است. با وجود تمام تفاوت هایی که بین معماری داخلی و طراحی داخلی وجود دارد اما هر دو مکمل یکدیگر هستند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اما با وجود تفاوت هایی که بین معماری و طراحی داخلی وجود دارد یک طراح داخلی برای بازسازی هر فضایی از جمله مسکونی و تجاری بطور حتم به  مشورت و نظارت یک معمار داخلی نیازمند است.
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت حوزه فعالیت معمار داخلی و طراح داخلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="حوزه فعالیت طراحی داخلی و معماری داخلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  حوزه فعالیت طراحی داخلی و معماری داخلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تفکیک فضاهای داخلی به فضاهای کوچکتر از قبیل <Link className="link" to="/children-room-decoration" target="_blank">  اتاق خواب </Link>، آشپزخانه، نشیمن، پذیرایی، <Link className="link" to="/laundry-room" target="_blank">  لاندری روم </Link> و  سایر بخش های خانه در حوزه‌ی فعالیت معمار داخلی می باشد. علاوه‌ بر این کف‌سازی، تغییرات سازه‌ای در بنا، لوله کشی، جابه‌جایی دیوار، ساخت <Link className="link" to="/building-facades-types" target="_blank">  نما </Link> هم از وظایف معمار داخلی می باشد. اما کارهای اجرایی بعدی با طراح داخلی است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه تصمیم دارید پس از کف‌سازی داخلی منزل، آن را کفپوش کنید و یا کفپوش را تغییر دهید باید با مشورت طراح داخلی این کار را انجام دهید. طراح با توجه به مسائلی همچون انتقال صدا و خواص صوتی کفپوش، اشتعال پذیری، محل خروج گاز، الزامات الکترسیته ساکن و… نوع مناسب کفپوش را به شما پیشنهاد می‌کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مهم در مورد خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                حرفه معماری داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمار داخلی باید توانایی این مسئله را داشته باشد که بطور همزمان آرایش بصری، آسایش محیطی و آرامش ذهنی را برقرار کند. بنابراین جدا از بکارگیری خلاقیت و حس زیباشناسی باید از آموخته های علمی نیز استفاده کند. معمار داخلی باید مسائل و مشکلات فضا را ساماندهی کند. آن ها باید بتوانند محیط هایی زیبا و خلاقانه را برای فعالیت های خود ایجاد کنند. معماری داخلی با بکارگیری مفاهیم و ایده هایی که سازمان دهی شدند قادر خواهد بود فضایی را برای کارفرما پیاده سازی کند. این کار می تواند سودآوری بیشتری داشته باشد یا فضای زندگی افراد را برای هدف خاصی مدلسازی کند.
              </Typography>
              <Typography className="h3" variant="h3">
                حرفه طراحی داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراح داخلی با توجه به عملکرد خود دانسته های هنری و زیبایی شناسی را با استانداردهای علمی ترکیب می کند. طراح داخلی محیط اطراف را در بهترین حالت برای فعالیت های خاص انسانی سازگار می کند. معمولا طراح داخلی برای ارتقای یک محیط استخدام می شود. مبلمان کردن و بازسازی یا ایجاد یک محیط تازه برای کار و زندگی از وظایف طراح داخلی می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر وظایف طراح، برقراری ارتباط بین کاربر و راه های بهینه برای انجام فعالیت های کاربر به کمک عوامل موثر فضایی در یک محیط مشخص می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                تحصیلات معماری داخلی و طراحی داخلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="تحصیلات معماری داخلی و طراحی داخلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تحصیلات معماری داخلی و طراحی داخلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا جوامع آموزشی رشته معماری و طراحی داخلی را یکپارچه می دانند. کمتر موسسه یا دانشگاهی وجود دارد که این دو رشته را بطور جداگانه تدریس کند. هر آموزشگاهی بسته به دایره تخصص اساتید خود یکی از رشته ای طراحی یا معماری را ارائه می دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ایران در حال حاضر دانشگاه ها و موسسات آموزش عالی رشته معماری داخلی را تدریس می کنند. اما برخی از موسسات مانند <LinkMui className="link" href="https://acecr.ac.ir/fa" target="_blank">  جهاد دانشگاهی </LinkMui> دوره های طراحی داخلی را برگزار می کنند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/keeping-apartment-flower" target="_blank">
                  بیشتر بخوانید : نکاتی که در مورد نگهداری گیاهان آپارتمانی باید بدانیم
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                رشته معماری داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دانشجویان این رشته مجموعه ای از توانایی های مختلف را آموزش می بینند. معماری داخلی در برخی دروس با رشته معماری مشترک است. دانشجویان هر دو رشته دروس تخصصی مهندسی و فن ساختمان را می گذرانند. این دروس مجموعه ای از اطلاعات فنی، مهندسی و سازه ای می باشد. برخی از دروس اطلاعات تئوری و طراحی کاربردی و استاندارد محور را در اختیار دانشجویان قرار می دهد. این دروس تئوری بین رشته های طراحی داخلی و معماری داخلی مشترک هستند. همچنین دروسی هم با هدف زیبایی شناسی در این رشته قرار دارد. تحصیل کنندگان رشته معماری داخلی نسبت به ساختمان و فن آن اشراف کامل دارند و قادرند یک پلان را با رعایت تمام اصول طرح ریزی کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                رشته طراحی داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دانش آموختگان این رشته کمتر دروس تخصصی مربوط به مهندسی و فن ساختمان را می گذرانند. بیشتر تمرکز آموزش این رشته بر روی دروس تئوری و کاربردی طراحی می باشد. در این رشته نیز مانند معماری داخلی دروس زیبایی شناسی مانند عکاسی و گرافیک و رنگ شناسی تدریس می شود. دانشجویان این رشته قادرند یک پلان و فضای کاربردی را با رعایت تمام اصول اما بدون دخالت در عناصر سازه ای طراحی کنند.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با وجود تفاوت هایی که در معماری داخلی و دکوراسیون داخلی وجود دارد، هر دو مکمل یک دیگر هستند. معماری داخلی از شروع تصمیم گیری تا اتمام کار ساخت حضور دارد ولی طراحی داخلی در بعضی موارد از ابتدای کار و در بعضی  دیگر بعد از تمام شدن ساخت بنا شروع به کار می کند. همچنین طراحی داخلی روی قسمت های هنری، چیدمان، نورپردازی، رنگ، کف، سقف ونوع متریال و… در فضای داخلی فعالیت دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                سوالات متداول
              </Typography>
              <Typography className="h4" variant="h4">
                1. معماری داخلی به چه معناست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی یک فضای جدید داخلی برای یک محیط معماری داخلی نام دارد. در واقع معماری داخلی، توصیف کلی فضای داخلی ساختمان می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                2. طراحی داخلی چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی داخلی هنر یا پرسه ی طراحی فضای داخلی ساختمان می باشد. برنامه ریزی و نظارت بر طراحی و اجرای فضای داخلی و طراحی مبلمان توسط طراحی داخلی صورت می پذیرد.
              </Typography>
              <Typography className="h4" variant="h4">
                3. تحصیلات طراح داخلی چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تخصص در این رشته یادگیری رنگ ها و پارچه ها، تمرینات طراحی با کامپیوتر، ترسیم، برنامه ریزی فضایی، طراحی مبلمان، معماری نیاز است. اکثر طراحان بعد از اتمام تحصیل با یک شرکت یا طراح داخلی که به صورت حرفه ای فعالیت می کند همکاری می کنند تا بتوانند تخصص و تجربه لازم را پیدا کنند و در آینده بتوانند شرکتی برای خود تاسیس کنند.
              </Typography>
              <Typography className="h4" variant="h4">
                4. تفاوت معماری داخلی و طراحی داخلی چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معماری داخلی شامل طراحی تمامی عناصر فضای داخلی اعم از عناصر سازه ای و غیر سازه ای می شود اما طراحی داخلی شامل طراحی همه عناصر و فضای داخلی بدون ایجاد تغییرات ساختاری می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                5. کدام تخصص برای پروژه مناسب تر است؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تصمیم گیری در مورد طراحی پروژه باید مشخص کنید که چه میزان قصد مداخله در فضا را دارید. همچین تغییرات مدنظر شما تا چه اندازه است. میزان بودجه شما برای کار چقدر است. با بررسی تمام موارد می توانید طراح مناسب برای پروژه را انتخاب نمایید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/premade-canopy" target="_blank">
                  بیشتر بخوانید : سایبان پیش ساخت چیست؟
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article46;