import { Autocomplete, Button, Checkbox, Divider, Grid, OutlinedInput, TextField, Typography, Modal,Box, TextareaAutosize} from "@mui/material";
import React from "react";
import { baseUrl, checkedIcon, convertToPersianNumber, icon, separator } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import Resizer from "react-image-file-resizer";
import GiftLogo from '../component/gifLogo';
import NoLogo from '../images/noLogoAgancy.png';
import { onlyReciveNumber, kitchenEq , kitchenCabinet , cool_heat , safety , welfare , cover, facade  } from "./materilas/vmp";
import GetLocation from "./materilas/getLocation";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from "react-router-dom";

class SearchListing extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.searchRef = React.createRef();
      this.state={
        openAlert:false,
        message:'',
        giftShow:false,
        searchList:[],
        isLoading: false,
        editModal : false ,
        modalInfo : null ,
        listingEditInfo : null ,
        primaryInfoEditPage : false ,
        additionalInfoEditPage : false ,
        locationInfoEditPage : false ,
        imageEditPage : false ,
        areaSelected:"",
        yearSelected:"",
        parkingNumSelected:"",
        bedroomNumSelected:"",
        masterRoomNumSelected:"",
        bathNumSelected:"",
        frenchWcNumSelected:"",
        iranianWcNumSelected:"",
        totalPriceSelected:"",
        prebuySelected:"",
        mortgageSelected: "",
        rentSelected: "" ,
        elevatorSelected: false,
        storeSelected: false,
        facadeSelected: [],
        facadeDefault: [],
        kitchenEqSelected: [],
        kitchenEqDefault: [],
        kitchenCabinetSelected: [],
        kitchenCabinetDefault: [],
        coolHeatSelected: [],
        coolHeatDefault: [],
        safetySelected: [],
        safetyDefault: [],
        welfareSelected: [],
        welfareDefault: [],
        coverSelected: [],
        coverDefault: [],
        uploadedImages: null ,
        typeAlert:3
      }
  }

  componentDidMount(){

  }

  componentWillUnmount(){
  }

  onClickSearch = () =>{
    if (this.searchRef.current.value.trim() !== ''){
      this.setState({giftShow:true});
      this.search();
    }
    else{
      this.setState({openAlert:true,message:'چیزی برای جستجو وارد نکرده اید.',typeAlert:3})
    }
  }

  search = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/listing/searchListing',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token},
      params:{
        search:this.searchRef.current.value.trim()
      }
    }))
    .then((res) => {
        this.setState({searchList:res.data.data,giftShow:false})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',giftShow:false,typeAlert:2})
    });
  }

  editListing = (id) =>{
    this.setState({giftShow:true});
    Promise.resolve(axios({
        url: baseUrl + "v1/listing/getOne",
        method: "GET",
        params: {
        listing_id: id
        }
    }))
    .then((res) => {
        this.setState({listingEditInfo:res.data.data,uploadedImages:res.data.data,giftShow:false,editModal:true,modalInfo:res.data.data,primaryInfoEditPage:true});
        this.setDefaultValues(res.data.data);
    })
    .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است و بخش ویرایش غیر فعال است",giftShow:false,typeAlert:2});
    });
  }

  viewListing = (id) =>{
    localStorage.setItem('idListing',id)
  }

  //-----------------------------edit modal functions start----------------------------

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  onCloseEditModal = () => {
    this.setState({editModal:false});
    this.resetStates();
    this.search();
  }

  resetStates = () => {
    this.setState({
      modalInfo : null ,
      listingEditInfo : null ,
      primaryInfoEditPage : false ,
      additionalInfoEditPage : false ,
      locationInfoEditPage : false ,
      imageEditPage : false ,
      areaSelected:"",
      yearSelected:"",
      parkingNumSelected:"",
      bedroomNumSelected:"",
      masterRoomNumSelected:"",
      bathNumSelected:"",
      frenchWcNumSelected:"",
      iranianWcNumSelected:"",
      totalPriceSelected:"",
      prebuySelected:"",
      mortgageSelected: "",
      rentSelected: "" ,
      elevatorSelected: false,
      storeSelected: false,
      facadeSelected: [],
      facadeDefault: [],
      kitchenEqSelected: [],
      kitchenEqDefault: [],
      kitchenCabinetSelected: [],
      kitchenCabinetDefault: [],
      coolHeatSelected: [],
      coolHeatDefault: [],
      safetySelected: [],
      safetyDefault: [],
      welfareSelected: [],
      welfareDefault: [],
      coverSelected: [],
      coverDefault: [],
      uploadedImages: null ,
    })
  }

  setDefaultValues = (info) => {
    this.setState({areaSelected:info.area ? info.area : "",yearSelected:info.year ? info.year : "",parkingNumSelected:info.parking_num ? info.parking_num : "",
      bedroomNumSelected: info.bedroom_num ? info.bedroom_num : "",masterRoomNumSelected: info.master_room_num ? info.master_room_num : "",
      bathNumSelected:info.bath_num ? info.bath_num : "",iranianWcNumSelected: info.iranian_wc_num ? info.iranian_wc_num : "",frenchWcNumSelected:info.french_wc_num ? info.french_wc_num :"",
      elevatorSelected: info.elevator ? true : false,storeSelected: info.store_num ? true : false 
    });
    if(info.total_price !== null){
      this.setState({totalPriceSelected:info.total_price})
    }
    if(info.prebuy !== null){
      this.setState({prebuySelected:info.prebuy})
    }
    if(info.rent !== null){
      this.setState({rentSelected:info.rent})
    }
    if(info.mortgage !== null){
      this.setState({mortgageSelected:info.mortgage})
    }
    if(info.description !== null){
      document.getElementById("primaryinfo-desc").value = info.description ;
    }
    if(info.facade !== null){
      let defaultData = this.defaultFeatures(info.facade,facade)
      this.setState({facadeDefault : defaultData , facadeSelected : defaultData});
    }
    if(info.kitchen !== null){
      let defaultData = this.defaultFeatures(info.kitchen,kitchenEq)
      this.setState({kitchenEqDefault : defaultData , kitchenEqSelected : defaultData})
    }
    if(info.cabinet !== null){
      let defaultData = this.defaultFeatures(info.cabinet,kitchenCabinet)
      this.setState({kitchenCabinetDefault : defaultData , kitchenCabinetSelected : defaultData})
    }
    if(info.condition !== null){
      let defaultData = this.defaultFeatures(info.condition,cool_heat)
      this.setState({coolHeatDefault : defaultData , coolHeatSelected : defaultData})
    }
    if(info.safety !== null){
      let defaultData = this.defaultFeatures(info.safety,safety)
      this.setState({safetyDefault : defaultData , safetySelected : defaultData})
    }
    if(info.welfare !== null){
      let defaultData = this.defaultFeatures(info.welfare,welfare)
      this.setState({welfareDefault : defaultData , welfareSelected : defaultData})
    }
    if(info.cover !== null){
      let defaultData = this.defaultFeatures(info.cover,cover)
      this.setState({coverDefault : defaultData , coverSelected : defaultData})
    }
  }

  defaultFeatures = (defaultFeatures,feature) => {
    let result = [] ;
      for(let key in defaultFeatures){
        if(key !== "id" && key !== "listing_id"){
          if(parseInt(defaultFeatures[key])){
            for(let i = 0 ; i < feature.length ; i++){
              if(key === feature[i].f){
                let item = {};
                item.f = feature[i].f;
                item.n = feature[i].n;
                result.push(item);
              }
            }
          }
        }
      }
    return result;
  }

  onChangeArea = (event,newValue) =>{
    this.setState({areaSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeYear = (event,newValue) =>{
    this.setState({yearSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeParkingNum = (event,newValue) =>{
    this.setState({parkingNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeBedroomNum = (event,newValue) =>{
    this.setState({bedroomNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeMasterRoomNum = (event,newValue) =>{
    this.setState({masterRoomNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangebathNum = (event,newValue) =>{
    this.setState({bathNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeFrenchWcNum = (event,newValue) =>{
    this.setState({frenchWcNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeIranianWcNum = (event,newValue) =>{
    this.setState({iranianWcNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeMortgage = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({mortgageSelected:onlyReciveNumber(inputWithoutComma)});
  }

  onChangeRent = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({rentSelected:onlyReciveNumber(inputWithoutComma)});
  }

  onChangeTotalPrice = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({totalPriceSelected:onlyReciveNumber(inputWithoutComma)});
  }

  onChangePrebuy = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({prebuySelected:onlyReciveNumber(inputWithoutComma)});
  }

  onChangeElevator = (event,newValue) =>{
    this.setState({elevatorSelected:event.target.checked})
  }

  onChangeStore = (event,newValue) =>{
    this.setState({storeSelected:event.target.checked})
  }

  submitEditPrimaryInfo = () => {
    this.setState({showGif:true,isLoading:true})
    let params = {
      listing_id:this.state.listingEditInfo.id,
      transaction_id:this.state.listingEditInfo.transaction_id,
      document_id:this.state.listingEditInfo.document_id,
      estate_id:this.state.listingEditInfo.estate_id,
      kitchen_id:this.state.listingEditInfo.kitchen_id,
      state_id:this.state.listingEditInfo.state_id,
      city_id:this.state.listingEditInfo.city_id,
      zone_id:this.state.listingEditInfo.zone_id,
      neighbourhood_id:this.state.listingEditInfo.neighbourhood_id,
      current_situation_id:this.state.listingEditInfo.current.id,
    }
    if(this.state.areaSelected){
      params.area = this.state.areaSelected ;
    }
    if(this.state.yearSelected){
      params.year = this.state.yearSelected ;
    }
    if(this.state.parkingNumSelected){
      params.parking_num = this.state.parkingNumSelected ;
    }
    if(this.state.bedroomNumSelected){
      params.bedroom_num = this.state.bedroomNumSelected ;
    }
    if(this.state.masterRoomNumSelected){
      params.master_room_num = this.state.masterRoomNumSelected ;
    }
    if(this.state.bathNumSelected){
      params.bath_num = this.state.bathNumSelected ;
    }
    if(this.state.frenchWcNumSelected){
      params.french_wc_num = this.state.frenchWcNumSelected ;
    }
    if(this.state.iranianWcNumSelected){
      params.iranian_wc_num = this.state.iranianWcNumSelected ;
    }
    if(this.state.totalPriceSelected){
      params.total_price = this.state.totalPriceSelected ;
    }
    if(this.state.prebuySelected){
      params.prebuy = this.state.prebuySelected ;
    }
    if(this.state.mortgageSelected){
      params.mortgage = this.state.mortgageSelected ;
    }
    if(this.state.rentSelected){
      params.rent = this.state.rentSelected ;
    }
    if(this.state.elevatorSelected){
      params.elevator = 1 ;
    }
    if(this.state.storeSelected){
      params.store_num = 1 ;
    }
    let description = document.getElementById("primaryinfo-desc").value ;
    description = description.trim();
    if(description !== ""){
      params.description = description ;
    }
    Promise.resolve(axios({
      url: baseUrl+"v1/listing/edit",
      method: "POST",
      headers: {"Authorization":"bearer "+ this.token},
      data: params
    }))
    .then((res) => {
        this.setState({showGif:false,isLoading:false,openAlert:true,message:"ویرایش اطلاعات اولیه با موفقیت ثبت شد",primaryInfoEditPage:false,additionalInfoEditPage:true,
                      typeAlert:1})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"ویرایش اطلاعات اولیه  ثبت نشد",showGif:false,isLoading:false,typeAlert:2})
    });
  }

  skipEditPrimaryInfo = () => {
    this.setState({primaryInfoEditPage:false,additionalInfoEditPage:true});
  }

  /* ----------------------- edit additional info functions ------------------------ */

checkFeatures = (selected,feature) => {
let result = {};
for(let i = 0 ; i < feature.length ; i++){
  let isExist = false ;
  for(let j = 0 ; j < selected.length ; j++){
    if(feature[i].f === selected[j].f){
      isExist = true ;
    }
  }
  if(isExist){
    result[feature[i].f] = 1 ;
  }else{
    result[feature[i].f] = 0 ;
  }
}
return result;
}

onChangeFacade = (event,newValue) => {
this.setState({facadeSelected:newValue});
}

onChangeKitchenEq = (event,newValue) => {
this.setState({kitchenEqSelected:newValue});
}

onChangeKitchenCabinet = (event,newValue) => {
this.setState({kitchenCabinetSelected:newValue});
}

onChangeCoolHeat = (event,newValue) => {
this.setState({coolHeatSelected:newValue});
}

onChangeSafety = (event,newValue) => {
  this.setState({safetySelected:newValue});
}

onChangeWelfare = (event,newValue) => {
  this.setState({welfareSelected:newValue});
}

onChangeCover = (event,newValue) => {
this.setState({coverSelected:newValue});
}

submitEditAdditionalInfo = () => {
this.setState({showGif:true,isLoading:true});
let facadeData = this.checkFeatures(this.state.facadeSelected,facade);
facadeData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/facade/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: facadeData
}))
.then((res) => {
  this.postKitchenEq();
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات نمای ساختمان با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",showGif:false,
    isLoading:false,facadeSelected:[],kitchenEqSelected:[],kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[]
    ,typeAlert:2
  });
});
}

postKitchenEq = () => {
let kitchenEqData = this.checkFeatures(this.state.kitchenEqSelected,kitchenEq);
kitchenEqData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/equipment/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: kitchenEqData
}))
.then((res) => {
  this.postKitchenCabinet();
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات امکانات آشپزخانه با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
    showGif:false,isLoading:false,kitchenEqSelected:[],kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],
    typeAlert:2
  });
});
}

postKitchenCabinet = () => {
let kitchenCabinetData = this.checkFeatures(this.state.kitchenCabinetSelected,kitchenCabinet);
kitchenCabinetData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/cabinet/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: kitchenCabinetData
}))
.then((res) => {
  this.postCoolHeat();
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات نوع کابینت با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
    showGif:false,kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],isLoading:false,typeAlert:2
  });
});
}

postCoolHeat = () => {
let coolHeatData = this.checkFeatures(this.state.coolHeatSelected,cool_heat);
coolHeatData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/che/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: coolHeatData
}))
.then((res) => {
  this.postSafety();
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات تهویه مطبوع با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
    showGif:false,isLoading:false,coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
  });
});
}

postSafety = () => {
let safetyData = this.checkFeatures(this.state.safetySelected,safety);
safetyData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/safety/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: safetyData
}))
.then((res) => {
  this.postWelfare();
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات امکانات امنیتی با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
    showGif:false,isLoading:false,safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
  });
});
}

postWelfare = () => {
let welfareData = this.checkFeatures(this.state.welfareSelected,welfare);
welfareData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/welfare/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: welfareData
}))
.then((res) => {
  this.postCover();
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات امکانات رفاهی با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
    showGif:false,isLoading:false,welfareSelected:[],coverSelected:[],typeAlert:2
  });
});
}

postCover = () => {
let coverData = this.checkFeatures(this.state.coverSelected,cover);
coverData.listing_id = this.state.listingEditInfo.id ;
Promise.resolve(axios({
  url: baseUrl+"v1/cover/create",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: coverData
}))
.then((res) => {
  this.setState({openAlert:true,message:"ویرایش اطلاعات تکمیلی با موفقیت ثبت شد",additionalInfoEditPage: false, locationInfoEditPage: true,showGif:false,isLoading:false})
})
.catch((err) => {
  this.setState({
    openAlert:true,message:"ویرایش اطلاعات کفپوش ساختمان با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
    showGif:false,isLoading:false,coverSelected:[],typeAlert:2
  });
});
}

skipEditAdditionalInfo = () => {
this.setState({additionalInfoEditPage:false,locationInfoEditPage:true});
}

/* ----------------------- edit location info functions ------------------------ */

locationInfoEditPage = () => {
  if(this.state.listingEditInfo.latitude === null && this.state.listingEditInfo.longitude === null){
    return (
      <Grid sx={{width:"100%"}}>
        <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8% 2% 0"}}>۳ - تعیین محل آگهی</Typography>
        <Grid sx={{width:"90%",height:{xs:"300px",md:"600px"},margin:"auto",borderRadius:"8px",overflow:"hidden"}}>
          <GetLocation />
        </Grid>
        <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
          <Grid item xs={12} md={6}>
            <Button onClick={this.submitLocationInfo} disabled={this.state.isLoading}
              sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
              fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
            >
              ذخیره محل آگهی
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button onClick={this.skipSubmitLocationInfo} disabled={this.state.isLoading}
              sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
              fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
            >
              رد کردن این بخش
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }else{
    this.setState({locationInfoEditPage:false,imageEditPage:true});
    return <Grid></Grid>
  }
}

submitLocationInfo = () => {
  this.setState({showGif:true,isLoading:true})
  let latitudeInfo = localStorage.getItem("lat");
  let longitudeInfo = localStorage.getItem("lng");
  if(latitudeInfo && longitudeInfo){
    Promise.resolve(axios({
      url: baseUrl+"v1/listing/edit",
      method: "POST",
      headers: {"Authorization":"bearer "+ this.token},
      data: {
        latitude : latitudeInfo ,
        longitude : longitudeInfo ,
        listing_id : this.state.listingEditInfo.id ,
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"محل آگهی با موفقیت ثبت شد",showGif:false,isLoading:false,locationInfoEditPage: false ,imageEditPage: true,typeAlert:1});
      localStorage.removeItem('lat');
      localStorage.removeItem('lng');
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"ثبت محل آگهی با مشکل مواجه شد در صورت تکرار مشکل با پشتیبان تماس حاصل فرمائید",showGif:false,isLoading:false,typeAlert:2});
      localStorage.removeItem('lat');
      localStorage.removeItem('lng');
    });
  }else{
    this.setState({openAlert:true,message:"شما برای این آگهی موقعیتی ثبت نکردید",locationInfoEditPage: false ,imageEditPage: true,showGif:false,isLoading:false,typeAlert:3});
  }
}

skipSubmitLocationInfo = () => {
  this.setState({locationInfoEditPage: false ,imageEditPage: true});
}

/* ----------------------- edit image functions ------------------------ */

smallImageResized ;
largeImageResized ;
imageFile ;

onChangeAddImage = (e) => {
if(e.target.files[0]){
  if(e.target.files[0].size > 5000000){
    this.setState({openAlert:true,typeAlert:3,
      message:"حجم عکس نباید بیشتر از ۵ مگابایت باشد لطفا اقدام به کاهش حجم عکس نمائید یا با پشتیبان تماس حاصل نمائید تا در کاهش حجم عکس شما را یاری نماید"
    });
  }else{
    this.setState({showGif:true,isLoading:true});
    this.imageFile = e.target.files[0] ;
    this.smallImageResizer();
  }
}
}

smallImageResizer = () => {
  try {
    Resizer.imageFileResizer(
      this.imageFile,
      400,
      400,
      "JPEG",
      40,
      0,
      (uri) => {
        this.smallImageResized = uri ;
        this.largeImageResizer();
      },
      "file",
    );
  } catch (err) {
    this.setState({openAlert:true,message:"فایل موردنظر عکس نمیباشد",showGif:false,isLoading:false,typeAlert:2});
  }
}

largeImageResizer = () => {
try {
  Resizer.imageFileResizer(
    this.imageFile,
    1280,
    960,
    "JPEG",
    40,
    0,
    (uri) => {
      this.largeImageResized = uri ;
      this.postLargeImage();
    },
    "file",
  );
} catch (err) {
  this.setState({openAlert:true,message:"فایل موردنظر مشکل دارد",showGif:false,isLoading:false,typeAlert:2});
}
} 

postLargeImage = () => {
const formData = new FormData();
formData.append("image", this.largeImageResized);
formData.append("listing_id", this.state.listingEditInfo.id);
formData.append("type", "L");
Promise.resolve(axios({
  url: baseUrl+"v1/listing/addImages",
  method: "POST",
  headers: {"Authorization":"bearer "+ this.token},
  data: formData
}))
.then((res) => {
  this.postSmallImage();
})
.catch((err) => {
  this.setState({openAlert:true,message:"سامانه با مشکل مواجه شد و عکس بارگزاری نشد",showGif:false,isLoading:false,typeAlert:2});
});
}

postSmallImage = () => {
const formData = new FormData();
formData.append("image", this.smallImageResized);
formData.append("listing_id", this.state.listingEditInfo.id);
formData.append("type", "S");
Promise.resolve(axios({
  url: baseUrl+"v1/listing/addImages",
  method: "POST",
  headers: {
    "Authorization":"bearer "+ this.token,
    "Content-Type": "multipart/form-data"
  },
  data: formData
}))
.then((res) => {
  this.setState({openAlert:true,message:"عکس با موفقیت بارگزاری شد",showGif:false,isLoading:false,typeAlert:1});
  this.getUploadedImages();
})
.catch((err) => {
  this.setState({openAlert:true,message:"سامانه با مشکل مواجه شد و عکس بارگزاری نشد",showGif:false,isLoading:false,typeAlert:2});
  this.getLargeImage();
});
} 

getLargeImage = () => {
Promise.resolve(axios({
  url: baseUrl+'v1/listing/getOne',
  method: 'GET',
  params: {
    listing_id: this.state.listingEditInfo.id
  }
}))
.then((res) => {
  let largeImages = res.data.data.large ;
  this.deleteLargeImage(largeImages[largeImages.length - 1].id);
})
}

deleteLargeImage = (id) => {
Promise.resolve(axios({
  url: baseUrl+"v1/listing/deleteImage",
  method: "DELETE",
  headers: {"Authorization":"bearer "+ this.token},
  params : {
    small_id : 0 ,
    large_id : id
  }
}))
}

getUploadedImages = () => {
Promise.resolve(axios({
  url: baseUrl+'v1/listing/getOne',
  method: 'GET',
  params: {
    listing_id: this.state.listingEditInfo.id
  }
}))
.then((res) => {
  this.setState({uploadedImages:res.data.data});
})
.catch((err) => {
  this.setState({openAlert:true,message:"بروزرسانی تصاویر با مشکل مواجه شد",typeAlert:2});
});
}

uploadedImageThumbnails = () => {
if(this.state.uploadedImages){
  let result = [];
  this.state.uploadedImages.small.forEach((element,index) => {
    result.push(
    <Grid item xs={2} key={index}>
      <Grid
        sx={{bgcolor:"lightblue",height:{xs:"140px",lg:"200px"},border:"2px solid #eee",borderRadius:"4px",position:"relative",
        background:`url(${this.state.uploadedImages.small[index].path})` ,
        backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
      >
        <Button onClick={() => this.deleteImage(index)} disabled={this.state.isLoading}
          sx={{position:"absolute",left:"-2px",bottom:"-2px",minWidth:"40px",maxWidth:"40px",borderRadius:"0 10px 0 4px",
          bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"}}}
        >
        <DeleteOutlineOutlinedIcon sx={{color:"black !important"}} />
        </Button>
      </Grid>
    </Grid>
    )
  });
  return result;
}else{
  return <Grid></Grid>
}
}

deleteImage = (index) => {
  this.setState({showGif:true,isLoading:true});
  let token = localStorage.getItem('tokenUserHomeInja');
  Promise.resolve(axios({
    url: baseUrl+"v1/listing/deleteImage",
    method: "DELETE",
    headers: {"Authorization":"bearer "+ token},
    params : {
      small_id : this.state.uploadedImages.small[index].id,
      large_id : this.state.uploadedImages.large[index].id
    }
  }))
  .then((res) => {
    this.setState({openAlert:true,message:"عکس با موفقیت حذف شد",showGif:false,isLoading:false,typeAlert:1});
    this.getUploadedImages();
  })
  .catch((err) => {
    this.getUploadedImages();
    this.setState({openAlert:true,message:"حذف عکس با مشکل مواجه شد",showGif:false,isLoading:false,typeAlert:2});
  });
}

submitEdit = () => {
  this.setState({openAlert:true,message:"تغییرات با موفقیت ثبت شد",editModal:false,typeAlert:1});
  this.resetStates();
  this.search();
}

  //-----------------------------edit modal functions end----------------------------

  activeListing = (id) =>{
    this.setState({giftShow:true})
    Promise.resolve(axios({
      url: baseUrl + 'v1/listing/active',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        listing_id:id
      }
    }))
    .then((res) => {
      this.search();
      this.setState({openAlert:true,typeAlert:1,message:'آگهی با موفقیت فعال گردید.' + convertToPersianNumber(id) + 'آگهی شماره:',giftShow:false})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'فعال کردن آگهی با مشکل مواجه شد.',giftShow:false,typeAlert:2})
    });
  }

  needCorrect = (id) =>{
    this.setState({giftShow:true})
    Promise.resolve(axios({
      url: baseUrl + 'v1/listing/needCorrect',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        listing_id:id
      }
    }))
    .then((res) => {
      this.search();
      this.setState({openAlert:true,giftShow:false,typeAlert:1,
        message:'وضعیت آگهی به نیاز به اصلاح تغییر یافت لطفا به کاربر مربوطه اطلاع رسانی شود. آگهی شماره: ' + convertToPersianNumber(5) })
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'عملیات با مشکل مواجه شد.',giftShow:false,typeAlert:2})
    });
  }

  createSerchList = () =>{
    let list = [];
    this.state.searchList.forEach((element,index) =>{
      list.push(
        <Grid item xs={12} key={index} 
              style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%',flexWrap:'wrap',marginTop:10,marginBottom:10,
                      background:'linear-gradient(270deg, rgba(204, 244, 251, 0.14) 0%, #FFFFFF 100%)',boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.14)',
                      borderRadius:'0px 40px 0px 0px'}}>
          <Grid item sm={2} xs={6} style={{display:'flex',alignItems:'center',width:'100%'}}>
            {
              element.small.length === 0 ?
                <Typography component={'img'} src={NoLogo} style={{height:120,width:'90%',borderRadius:'0px 40px 0px 0px'}} alt="no-log"></Typography>
              :
                <Typography
                  style={{display:'flex',alignItems:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover',height:120,width:'90%',
                          borderRadius:'0px 40px 0px 0px',backgroundImage:`url(${element.small[0].path})`,backgroundPosition:'center'}}
                ></Typography>
            }
            
          </Grid>
          <Grid item sm={3.5} xs={6} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center'}}>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'استان:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.state.name}
                </Typography>
              </Grid>
              
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'شهر:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.city.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'منطقه:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.zone.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'محله:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',overflowX:'hidden'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.neighbourhood.name}
                </Typography>
              </Grid>
              
              
            </Grid>
          </Grid>
          <Grid item sm={3.5} xs={12} style={{display:'flex',width:'100%'}} 
                sx={{flexDirection:{sm:'column',xs:'row'},flexWrap:{sm:'nowrap',xs:'wrap'},marginTop:{sm:'0px',xs:'10px'}}}>
            <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'موبایل:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {convertToPersianNumber(element.user.mobile)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'متراژ:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {convertToPersianNumber(element.area)}
                </Typography>
              </Grid>
            </Grid>
            {
              element.transaction_id === 1  ?
              <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                  <Typography component={'p'} 
                    style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                    sx={{fontSize:{sm:'16px',xs:'13px'}}}
                  >
                    {'خرید:'}
                  </Typography>
                </Grid>
                <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                  <Typography component={'p'} 
                    style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                    sx={{fontSize:{sm:'16px',xs:'13px'}}}
                  >
                    {parseInt(element.total_price) ? convertToPersianNumber(separator(element.total_price)) : "توافقی"}
                  </Typography>
                </Grid>
              </Grid>
              : element.transaction_id === 3 ?
                  <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                    <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                      <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                      >
                        {'پیش خرید:'}
                      </Typography>
                    </Grid>
                    <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                      <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                      >
                        {parseInt(element.prebuy) ? convertToPersianNumber(separator(element.prebuy)) : "توافقی"}
                      </Typography>
                    </Grid>
                  </Grid>
                : <>
                  <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                    <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                      <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                      >
                        {'اجاره:'}
                      </Typography>
                    </Grid>
                    <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                      <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                      >
                        {parseInt(element.rent) ? convertToPersianNumber(separator(element.rent)) : "توافقی"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                    <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                      <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                      >
                        {'رهن:'}
                      </Typography>
                    </Grid>
                    <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                      <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                      >
                        {parseInt(element.mortgage) ? convertToPersianNumber(separator(element.mortgage)) : "توافقی"}
                      </Typography>
                    </Grid>
                  </Grid>
                  </>
            }
          </Grid>
          <Grid item sm={3} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}} 
                sx={{flexDirection:{sm:'column',xs:'row'},flexWrap:{sm:'nowrap',xs:'wrap'},marginTop:{sm:'0px',xs:'10px'}}}>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button component={Link} to={"/Listing/" + element.id} target={'_blank'}
                  style={{backgroundColor:'#003249',color:'white',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'مشاهده'}
              </Button>
            </Grid>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button onClick={() => this.editListing(element.id)} 
                  style={{backgroundColor:'#1d6181',color:'white',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'ویرایش'}
              </Button>
            </Grid>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button onClick={() => this.activeListing(element.id)} 
                  style={{backgroundColor:'#00C8E9',color:'black',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'تایید'}
              </Button>
            </Grid>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button onClick={() => this.needCorrect(element.id)} 
                  style={{backgroundColor:'white',color:'black',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',border:'1px solid #01C7E9',
                          boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.14)'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'اصلاح'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    })
    return list;
  }

  onClickEnter = (event) => {
    if(event.key === "Enter"){
      this.onClickSearch()
    }
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>جستجو آگهی</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >    
          <Grid item xs={10} style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',alignItems:'center',marginTop:25}}>
            <Grid item xs={8} style={{display:'flex',width:'100%'}}>
              <OutlinedInput variant="outlined" inputRef={this.searchRef}
                style={{borderStyle:'none',width:'100%',fontFamily:'shabnam',fontWeight:'bold',backgroundColor:'#F1F2F2',
                        maxHeight:70,borderRadius:0}}
                sx={{height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
                inputProps={{maxLength:30}}
                onKeyPress={(event) => {this.onClickEnter(event)}}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex',width:'100%'}}>
              <Button onClick={this.onClickSearch} 
                style={{backgroundColor:'#00C8E9',color:'black',fontFamily:'shabnam',
                        fontWeight:'bold',borderRadius:0,borderBottomLeftRadius:26}}
                sx={{width:{xs:'100%'},height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
              >
                {'جستجو'}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{display:'flex',alignItems:'center',width:'100%',marginTop:20,marginBottom:20}}>
            <Grid item lg={1.5} md={2.5} xs={2} style={{display:'flex',width:'100%'}}>
              <Typography component={'p'} 
                style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                sx={{fontSize:{sm:'16px',xs:'13px'}}}
              >
                {'نتیجه جستجو'}
              </Typography>
            </Grid>
            <Grid item lg={8.5} md={7.5} xs={8} style={{display:'flex',width:'100%',flexDirection:'column'}}>
              <Divider/>
            </Grid>
            <Grid item xs={0.5} style={{display:'flex',width:'100%'}}>
              <Typography component={'p'} 
                style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                sx={{fontSize:{sm:'16px',xs:'13px'}}}
              >
                {convertToPersianNumber(this.state.searchList.length)}
              </Typography>
            </Grid>
            <Grid item xs={1.5} style={{display:'flex',width:'100%'}}>
              <Typography component={'p'} 
                style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                sx={{fontSize:{sm:'16px',xs:'13px'}}}
              >
                {'آگهی'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} style={{display:'flex',alignItems:'center',width:'100%'}}>
            {
              this.state.searchList.length === 0 ?
                <Grid item xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',marginTop:'20%'}}>
                  <Typography variant="h1" sx={{fontSize:{md:'26px',xs:'16px'}}}
                    style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'center',marginBottom:5,width:'100%'}}>
                    {'هیچ موردی برای نمایش وجود ندارد.'}
                  </Typography>
                </Grid>
              :
                this.createSerchList()
            }
          </Grid>
          <Modal
            open={this.state.editModal}
            onClose={this.onCloseEditModal}
            sx={{overflowY:"auto",width:"100%"}}
          >
            <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width:{xs:"95%",md:"90%"},bgcolor:'white',height:"90vh",overflowY:"auto",
                display:{xs:"static",md:"flex"},alignItems:"center"}}
            >
              { this.state.primaryInfoEditPage ?
                  <Grid sx={{width:"100%"}}>
                  <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8%"}}>۱ - ویرایش اطلاعات اولیه</Typography>
                  <Grid sx={{width:"85%",margin:"auto"}}>
                      <Grid container columns={{xs:4,sm:8,md:12,lg:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}> 
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeArea}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.areaSelected)}
                          label="متراژ"
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeYear}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.yearSelected)}
                          label="سال ساخت"
                          inputProps={{
                              maxLength: 4
                          }}
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeParkingNum}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.parkingNumSelected)}
                          label="تعداد پارکینگ"
                          inputProps={{
                              maxLength: 2
                          }}
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeBedroomNum}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.bedroomNumSelected)}
                          label="تعداد خواب"
                          inputProps={{
                              maxLength: 3
                          }}
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeMasterRoomNum}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.masterRoomNumSelected)}
                          label="تعداد مستر"
                          inputProps={{
                              maxLength: 2
                          }}
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangebathNum}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.bathNumSelected)}
                          label="تعداد حمام"
                          inputProps={{
                              maxLength: 2
                          }}
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeFrenchWcNum}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.frenchWcNumSelected)}
                          label="تعداد سرویس بهداشتی فرنگی"
                          inputProps={{
                              maxLength: 2
                          }}
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <TextField 
                          fullWidth    
                          onChange={this.onChangeIranianWcNum}
                          className="ads-textfield"
                          id="ads-textfield-input"
                          value={convertToPersianNumber(this.state.iranianWcNumSelected)}
                          label="تعداد سرویس بهداشتی ایرانی"
                          inputProps={{
                              maxLength: 2
                          }}
                          />
                      </Grid>
                      { this.state.modalInfo ?
                          this.state.modalInfo.transaction_id === 1 ?
                              <Grid item xs={4} lg={3}>
                              <TextField 
                                  fullWidth    
                                  onChange={this.onChangeTotalPrice}
                                  className="ads-textfield"
                                  id="ads-textfield-input"
                                  value={convertToPersianNumber(separator(this.state.totalPriceSelected))}
                                  label="قیمت خرید"
                                  placeholder="تومان"
                                  inputProps={{
                                  maxLength: 20
                                  }}
                              />
                              </Grid>
                          : this.state.modalInfo.transaction_id === 3 ?
                              <Grid item xs={4} lg={3}>
                                  <TextField 
                                  fullWidth    
                                  onChange={this.onChangePrebuy}
                                  className="ads-textfield"
                                  id="ads-textfield-input"
                                  value={convertToPersianNumber(separator(this.state.prebuySelected))}
                                  label="قیمت پیش خرید"
                                  placeholder="تومان"
                                  inputProps={{
                                      maxLength: 20
                                  }}
                                  />
                              </Grid>
                              : <>
                                  <Grid item xs={4} lg={3}>
                                  <TextField 
                                      fullWidth    
                                      onChange={this.onChangeMortgage}
                                      className="ads-textfield"
                                      id="ads-textfield-input"
                                      value={convertToPersianNumber(separator(this.state.mortgageSelected))}
                                      label="رهن"
                                      placeholder="تومان"
                                      inputProps={{
                                      maxLength: 20
                                      }}
                                  />
                                  </Grid>
                                  <Grid item xs={4} lg={3}>
                                  <TextField 
                                      fullWidth    
                                      onChange={this.onChangeRent}
                                      className="ads-textfield"
                                      id="ads-textfield-input"
                                      value={convertToPersianNumber(separator(this.state.rentSelected))}
                                      label="اجاره"
                                      placeholder="تومان"
                                      inputProps={{
                                      maxLength: 20
                                      }}
                                  />
                                  </Grid>
                              </>
                          : <Grid></Grid>
                      }
                      <Grid item xs={4} lg={3} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                          <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>آسانسور</Typography>
                          <Checkbox checked={this.state.elevatorSelected} onChange={this.onChangeElevator}/>
                          <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>انباری</Typography>
                          <Checkbox checked={this.state.storeSelected} onChange={this.onChangeStore}/>
                      </Grid>
                      </Grid>
                  </Grid>
                  <Grid sx={{padding:"1% 14%"}}>
                      <Typography sx={{fontFamily:"shabnam",marginBottom:"10px"}}>توضیحات تکمیلی :</Typography>
                      <TextareaAutosize maxLength={1200} className="primaryinfo-textarea" id="primaryinfo-desc"
                      style={{width:"100%",maxWidth:"100%",minWidth:"100%",minHeight:"150px",backgroundColor:"#F2F2F2",border:"none",borderRadius:"8px",fontFamily:"shabnam",fontSize:"15px",
                              padding:"10px",boxSizing:"border-box"}}
                      />
                  </Grid>
                  <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
                      <Grid item xs={12} md={6}>
                      <Button onClick={this.submitEditPrimaryInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                          fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                      >
                          ویرایش اطلاعات اولیه
                      </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <Button onClick={this.skipEditPrimaryInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                          fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                      >
                          رد کردن این بخش
                      </Button>
                      </Grid>
                  </Grid>
                  </Grid>
              : <Grid></Grid>
              }
              { this.state.additionalInfoEditPage ?
                  <Grid sx={{width:"100%"}}>
                  <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8%"}}>۲ - ویرایش اطلاعات تکمیلی</Typography>
                  <Grid sx={{width:"85%",margin:"auto"}}>
                      <Grid container columns={{xs:4,sm:8,md:12,lg:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={facade}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='نمای ساختمان'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeFacade}
                              defaultValue={this.state.facadeDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={kitchenEq}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='امکانات آشپزخانه'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeKitchenEq}
                              defaultValue={this.state.kitchenEqDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={kitchenCabinet}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='نوع کابینت'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeKitchenCabinet}
                              defaultValue={this.state.kitchenCabinetDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={cool_heat}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='تهویه مطبوع'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeCoolHeat}
                              defaultValue={this.state.coolHeatDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={safety}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='امکانات امنیتی'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeSafety}
                              defaultValue={this.state.safetyDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={welfare}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='امکانات رفاهی'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeWelfare}
                              defaultValue={this.state.welfareDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={cover}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='کفپوش ساختمان'/>}
                              renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                  <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                  />
                                  {option.n}
                              </li>
                              )}
                              onChange={this.onChangeCover}
                              defaultValue={this.state.coverDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                          />
                      </Grid>
                      </Grid>
                  </Grid>
                  <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
                      <Grid item xs={12} md={6}>
                      <Button onClick={this.submitEditAdditionalInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                          fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                      >
                          ویرایش اطلاعات تکمیلی
                      </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <Button onClick={this.skipEditAdditionalInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                          fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                      >
                          رد کردن این بخش
                      </Button>
                      </Grid>
                      
                  </Grid>
                  </Grid>
              : <Grid></Grid>
              }
              { this.state.locationInfoEditPage ?
                  <Grid sx={{width:"100%"}}>{this.locationInfoEditPage()}</Grid>
              : <Grid></Grid>
              }
              { this.state.imageEditPage ?
                  <Grid sx={{width:"100%"}}>
                  <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8% 2% 0"}}>۴ - بارگزاری عکس</Typography>
                  <Grid sx={{width:{xs:"95%",lg:"80%"},margin:"auto",border:"2px solid #eee",borderRadius:"8px",padding:{xs:"40px 3px",md:"40px 10px"},textAlign:"center"}}>
                      <Grid container spacing={{xs:.5,md:2,lg:1}} columns={{xs:4,sm:6,md:8,xl:12}} justifyContent="center">
                      { this.state.uploadedImages === null ?
                          <Grid item xs={2}>
                              <Grid sx={{height:{xs:"140px",lg:"200px"},border:"2px solid #eee",borderRadius:"4px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                              <AddCircleOutlineIcon sx={{fontSize:"50px"}} />
                              </Grid>
                          </Grid>
                          :<Grid></Grid>
                      }
                      {this.uploadedImageThumbnails()}
                      </Grid>
                      <Button variant="contained" component="label" disabled={this.state.isLoading}
                      sx={{fontFamily:"shabnam",padding:{xs:"10px",md:"15px 50px"},borderRadius:"4px 15px 4px 15px",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",
                          fontWeight:"600",marginTop:"40px"}}
                      >
                      افزودن عکس +
                      <input type="file" hidden accept="image/*" onChange={this.onChangeAddImage}/>
                      </Button>
                  </Grid>
                  <Grid sx={{textAlign:"center"}}>
                      <Button onClick={this.submitEdit} disabled={this.state.isLoading}
                      sx={{fontFamily:"shabnam",padding:{xs:"10px 30px",md:"15px 100px"},borderRadius:"4px",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",
                          fontWeight:"600",marginTop:"40px"}}>
                      ثبت تغییرات
                      </Button>
                  </Grid>
                  </Grid>
              : <Grid></Grid>
              }
            </Box>
          </Modal>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
        
      </Grid>
    )
  };
}

export default SearchListing;