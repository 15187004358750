import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article42/01.jpg";
import photo02 from "../../images/mag/article42/02.jpg";
import photo03 from "../../images/mag/article42/03.jpg";
import photo04 from "../../images/mag/article42/04.jpg";
import photo05 from "../../images/mag/article42/05.jpg";
import photo06 from "../../images/mag/article42/06.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article42 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "در این مطلب از هومینجا به معرفی ده مورد از برج های لوکس منطقه 1 تهران می پردازیم. هر کدام بدلیل امکانات منحصر به فردی که دارند معروف هستند.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "لوکس ترین برج های منطقه یک تهران، برج های معروف منطقه 1 تهران، گرانترین برج های منطقه یک تهران، لیست برج های منطقه 1، قیمت برج های منطقه یک تهران، زندگی در برج های تهران ، برج های لاکچری تهران ، خرید و فروش برج لوکس در تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[41].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[41].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[41].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[41].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[41].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[41].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="برج های لوکس منطقه یک" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[41].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی داریم شما را با لوکس ترین برج های منطقه 1 تهران آشنا کنیم. پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                می توان گفت شیک‌ترین برج‌های مسکونی پایتخت در مناطق شمالی تهران یعنی منطقه های یک، <Link className="link" to="/tehran-zone-2" target="_blank"> دو </Link> و <Link className="link" to="/tehran-zone-3" target="_blank">سه </Link> واقع شده اند. مناطقی که بعنوان محله مرفه نشین شهر شناخته شده اند. برج‌های مسکونی معروفی در این مناطق بنا شده اند که هر کدام بدلیل امکانات ویژه و منحصر به فردی که دارند معروف هستند. در این مطلب از هومینجا به معرفی ده مورد از برج های لوکس <Link className="link" to="/tehran-zone-1" target="_blank"> منطقه 1 تهران </Link> می پردازیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                1) برج رولکس فرمانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="برج لوکس روما منطقه 1 - هومینجا" className="image" />
                <figcaption className="image_caption">
                  برج رولکس فرمانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بی شک برج  رولکس  را می توان یکی از باشکوه ترین و گرانترین برج های تهران دانست. این برج در خیابان <Link className="link" to="/Listings/buy_tehran_farmaniyeh" target="_blank">فرمانیه </Link> قرار دارد و دارای وسعتی به پهنای 2600 مترمربع است. برج رولکس  16 طبقه و 52 واحد مسکونی دارد. 10 طبقه از این برج واحدهای مسکونی دارد و دیگر طبقات آن شامل 5 طبقه پارکینگ و زیرزمین و یک طبقه لابی می باشد. واحدهای این ساختمان عظیم و مرتفع از 130 تا 300 متر مربع می باشند. افراد مشهوری چون کریم باقری و علی دایی از ساکنین این برج می باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج تمامی امکانات یک هتل 5 ستاره را دارا می باشد. تمام امکانات رفاهی این مجموعه مطابق با آخرین استانداردهای روز دنیا هستند. علت نامگذاری این برج همجواری با برند ساعت <LinkMui className="link" href="https://www.rolex.com/en-us" target="_blank"> رولکس </LinkMui> می باشد. معماری این برج به صورت کلاسیک است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نکته جالب در مورد این برج این است که ساکنین هر واحد به ازای هر مترمربع از مساحت ملک خود بین 200 تا 400 هزار تومان ماهانه شارژ پرداخت می کنند. از آنجا که هر واحد حداقل 130 مترمربع است! بنابراین با یک محاسبه ساده متوجه خواهید شد که هر کسی از عهده پرداخت شارژ ماهانه این برج بر نخواهد آمد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لابی این مجموعه وسعتی به اندازه 600 متر مربع را شامل می شود. این برج علاوه بر معماری چشمگیری که دارد از امکانات بی‌نظیری نیز برخوردار است. ارتفاع دیوار‌های این برج  6 متر می باشد. <Link className="link" to="/building-facades-types" target="_blank">نمای ساختمان </Link> از بهترین سنگ ساخته شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پنت هاوس های برج رولکس دارای امکاناتی فراتر از دیگر واحدها می باشد از جمله: آسانسور اختصاصی حمل خودرو، استخر روباز، جکوزی اختصاصی، آسانسور مجزا برای ساکنین، دستگیره ها و شیرآلات آب طلا.
              </Typography>
              <Typography className="h3" variant="h3">
                از امکانات بی نظیر برج رولکس می توان به موارد زیر اشاره کرد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لابی بسیار مجلل و با شکوه به همراه لابی من 24 ساعته
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پارک و فضای سبز اختصاصی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دارای دو استخر ٧٠٠ متری به صورت مجزا برای آقایان و بانوان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فضای سالن استخر کاملا شیشه‌ای
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن بیلیارد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن اجتماعات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن‌های بدنسازی و ایروبیک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حضور مربیان شنا، بدنسازی و ایروبیک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پزشک اختصاصی و خدمات درمانی اولیه و مجهز به تیم درمانی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اینترنت و ماهواره مرکزی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجموعه خدماتی و نظافتی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سیستم <Link className="link" to="/smart-home" target="_blank"> Smart Home </Link> سامسونگ
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کارواش
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سیستم انرژی خورشیدی برای تامین آب گرم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای <Link className="link" to="/Listings/rent_tehran_farmaniyeh" target="_blank"> رهن و اجاره آپارتمان لوکس منطقه فرمانیه </Link> اینجا کلیک کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                2) برج چناران الهیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="برج چناران الهیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  برج چناران الهیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مجتمع چناران پارک رزیدنس در خیابان <Link className="link" to="/Listings/rent_tehran_fereshte" target="_blank"> فرشته </Link>، در منطقه <Link className="link" to="/Listings/rent_tehran_elahieh" target="_blank"> الهیه </Link> قرار دارد. این برج توسط معماران برجسته پایتخت، فرزاد دلیری و امید غلامپور طراحی و اجرا شده است و یکی از لوکس ترین برج های منطقه 1 بشمار می آید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معماری خاص این برج مطابق با آخرین و مدرن ترین تکنولوژی های روز دنیا و استفاده از فضای سبز با طراحی ویژه مجموعه ای کامل و ایده آل برای زندگی لوکس پسندان می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کوچکترین آپارتمان این برج حدود 260 متر و بزرگترین آپارتمان این مجموعه  550 متر می باشد. فضای مسکونی برج حدود 8000 متر و فضای خارجی برج 5000 متر مربع مساحت دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج دارای امکانات امنیتی فوق العاده ای می باشد. به جز انواع دوربین‌ها و چک کردن‌های ورود و خروج، درب پارکینگ به طور اتوماتیک تنها برای خودروهایی که پلاک آن‌ها داخل سیستم ثبت شده است، باز می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                از امکانات بی نظیر برج چناران می توان موارد زیر را نام برد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لابی وسیع و مدرن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سرویس کترینگ و سرو غذا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خشک شویی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سینما
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن بدنسازی و بیلیارد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پیست دوچرخه سواری
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شیرآلات با روکش طلا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فضای سبز و آبشارهای مصنوعی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/keeping-apartment-flower" target="_blank"> گل کاری </Link> مدرن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای <Link className="link" to="/Listings/buy_tehran_elahieh" target="_blank"> خرید آپارتمان لوکس منطقه الهیه </Link> اینجا کلیک کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">
                  بیشتر بخوانید : کافه های قلیان دار در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                3) برج آلتون کورت نیاوران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="آلتون کورت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  التون کورت نیاوران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر برج های معروف و لوکس <Link className="link" to="/niavaran-tehran" target="_blank"> نیاوران </Link>، برج 24 طبقه آلتون کورت می باشد. این مجموعه در منطقه پارک مهر نیاوران، در شمال شهر تهران واقع شده است. این برج لوکس دارای  18 طبقه مسکونی می باشد که شامل 60 واحد مسکونی است. التون کورت توسط مهندس منصور وکیلی طراحی و با نظارت آقای هورآذر به اجرا درآمده است. پنت هاس های این مجموعه بسیار زیبا و مجلل می باشد که به صورت دوبلکس و تریبلکس، در طبقات بالایی برج قرار دارند. واحدهای این برج حداقل 250 و حداکثر 700 مترمربع متراژ دارند. متراژ دو پنت هاوس آن نیز 500 و 700 متر می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                در زیر به شرح تعدادی از امکانات این برج می پردازیم:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باند هلیکوپتر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لابی لاکچری با ۶۰۰ متر مساحت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                زمین تنیس
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن ماساژ
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن بیلیارد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                استخر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فواره
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن اجتماعات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پارکینگ طبقاتی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای <Link className="link" to="/Listings/buy_tehran_niyavaran" target="_blank"> خرید آپارتمان لوکس منطقه نیاوران </Link> اینجا کلیک کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                4) برج بام جمشیدیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="بام جمشیدیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بام جمشیدیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مجتمع مسکونی بام <Link className="link" to="/Listings/rent_tehran_jamshideh" target="_blank"> جمشیدیه </Link>بمساحت 2918 مترمربع در بهترین نقطه شمال تهران قرار دارد. این مجموعه نمایی به سبک رومی دارد و متریال سنگ و سیمان در نمای آن استفاده شده است. معماری این برج دارای در 9 طبقه ساختمان می باشد و به سبک کلاسیک طراحی شده است. مجتمع بام جمشیدیه دارای 24 واحد مسکونی است که در هر طبقه از ساختمان 4 واحد قرار دارد. موقعیت این برج در جنوب پارک جمشیدیه در دامنه رشته کوه البرز وجه تمایز این پروژه می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر امتیازات این برج طراحی و دکوراسیون بسیار زیبای لابی این مجتمع می باشد. لابی مجموعه زیربنایی به متراژ 768 مترمربع را شامل می شود که سقف آن در ارتفاع 9 متری قرار دارد و شکوه بسیاری را برای این بخش از ساختمان رقم زده است.
              </Typography>
              <Typography className="h3" variant="h3">
                از امکانات ویژه این برج می توان به موارد زیر اشاره کرد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آنتن مرکزی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سیستم خانه هوشمند BMS
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجموعه استخر، سونا و جکوزی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن سرگرمی کودک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                537 متر سالن ورزشی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دوربین مداربسته و مانیتورینگ
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فضای سبز
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن اجتماعات 373 متری
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فضای سبز پشت بام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن اجتماعات 373 متری
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اینترنت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برق اضطراری
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حراست فیزیکی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لابی من۲۴ ساعته
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آشپزخانه صنعتی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای <Link className="link" to="/Listings/buy_tehran_jamshideh" target="_blank">  خرید آپارتمان لوکس منطقه جمشیدیه </Link> اینجا کلیک کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : کافه های شمال تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                5) برج هرمس فرشته
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="برج هرمس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  هرمس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج باغ هرمس در بهترین منطقه مسکونی فرشته واقع شده است. لوکس ترین مراکز خرید و <Link className="link" to="/best-restaurant-tehran-region1" target="_blank"> رستوران ها </Link> در اطراف این پروژه قرار دارند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمین این مجتمع به مساحت 6000 متر مربع می باشد. این برج در  27 طبقه و 70 واحد مسکونی طراحی شده است. واحد های این مجموعه مساحت های ۴۲۰ و ۴۹۰ و ۹۲۵ متری دارند و خدمات و امکانات فوق العاده ای را برای ساکنین خود رقم زده اند.
              </Typography>
              <Typography className="h3" variant="h3">
                امکانات ویژه برج هرمس:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                محوطه فضای سبز ۳۹۰۰ مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لابی من۲۴ ساعته
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آشپزخانه صنعتی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                استخر آب گرم در بام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هلی پد ۸۰ مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن اسکواش ۹۰ مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن بدنسازی ۱۰۰ مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارتفاع لابی ۶/۵ متر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/laundry-room" target="_blank"> لاندری روم </Link>
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای <Link className="link" to="/Listings/buy_tehran_fereshte" target="_blank">  خرید آپارتمان لوکس منطقه فرشته </Link> اینجا کلیک کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مهم در مورد خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                جمع بندی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا همواره در تلاش است تا اطلاعات مناسبی در اختیار کاربران قرار دهد. با دنبال کردن <Link className="link" to="/" target="_blank"> وب سایت </Link> و هم چنین <LinkMui className="link" href="https://homeinja.info/" target="_blank"> بلاگ هومینجا </LinkMui> از اخرین اخبار و مطالب جذاب ما مطلع شوید. ما در این مقاله سعی کردیم شما را با <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank"> لوکس ترین برج های منطقه 1 تهران </Link> آشنا کنیم. امیدواریم که این مطلب برای شما جذاب بوده باشد.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <LinkMui href={'https://www.evimshahane.com/5-%d9%88%d8%a8-%d8%b3%d8%a7%db%8c%d8%aa-%d8%a8%d8%b1%d8%aa%d8%b1%d8%ae%d8%b1%db%8c%d8%af-%d9%88-%d9%81%d8%b1%d9%88%d8%b4-%d8%a7%d9%85%d9%84%d8%a7%da%a9%d8%8c-%d8%a2%d9%be%d8%a7%d8%b1%d8%aa%d9%85%d8%a7/'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    ۵ وب سایت برترخرید و فروش املاک، آپارتمان، خانه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </LinkMui>
                <Link to={'/Listings/buy_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک آپارتمان در تهران اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک آپارتمان در تهران اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_ajudanieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک آپارتمان در تهران آجودانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_ajudanieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک آپارتمان در تهران آجودانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_tajrish'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در تجریش
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_tajrish'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک آپارتمان در تهران تجریش
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{
                bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "5600px" : "0px", md: this.state.openLinks ? "1850px" : "0px" }
                , transition: "max-height 0.6s linear", overflow: "hidden"
              }}
              >
                <Link to={'/Listings/buy_tehran_jamaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در جماران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_amar'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در عمار
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_jamaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در جماران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک آپارتمان تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک آپارتمان تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_jamshideh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در جمشیدیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_jamshideh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در جمشیدیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_amar'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در عمار
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>

                <Link to={'/Listings/buy_tehran_moghadasardabili'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در مقدس اردبیلی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_moghadasardabili'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در مقدس اردبیلی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article42;