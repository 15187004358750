import { Button, Grid, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import backgroundImage from "../../images/tehranZones/zone01/zone01.jpg";
import ShowCityLocation from "../materilas/showCityLocation";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link } from "react-router-dom";
import { setCanonicalTag } from "../materilas/vmp";
import articleImage02 from "../../images/mag/article02/01.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/tehranZones.css"


class Zone05 extends React.Component {

  prevPositionY = 0;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      openLinks: false,
    }
  }

  componentDidMount() {
    document.title = "منطقه ۵ تهران - نقشه - پارک - مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا"
    document.getElementById("main-heading").innerHTML = "منطقه 5 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه پنج";
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه منطقه 5 شهرداری تهران هستید. در این صفحه از سایت املاک هومینجا اطلاعاتی نظیر : محله ها و خیابان های منطقه پنج، قیمت ملک و آپارتمان و هر آنچه در مورد منطقه پنج تهران نیاز دارید، رایگان در اختیار شما می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی کامل منطقه پنج شهر تهران | بررسی منطقه 5 تهران | نقشه منطقه 5 تهران بزرگ | امکانات منطقه 5 | منطقه 5 کجاست | محدوده منطقه پنج شهرداری | خیابان های منطقه پنج | قیمت ملک در منطقه پنج | خرید آپارتمان در منطقه 5 | رهن و اجاره آپارتمان در منطقه 5 | فروش آپارتمان منطقه 5 | صفر تا صد منطقه پنج | نقشه ناحیه های منطقه 5 | بهترین محله های منطقه 5 | قیمت خانه کلنگی منطقه 5 | شهرداری منطقه 5 | راهنمای منطقه 5 تهران | قیمت خانه ویلایی منطقه 5 | آگهی املاک منطقه 5 | جستجوی ملک در منطقه 5 ";
    setCanonicalTag();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkPos);
  }


  onClickMenu = (event) => {
    event.preventDefault();
    let toggle = !this.state.openMenu;
    this.setState({ openMenu: toggle, anchorEl: event.currentTarget });
    this.closeMenuScroll();
  }

  onCloseMenu = () => {
    this.setState({ openMenu: false });
    window.removeEventListener("scroll", this.checkPos);
    this.prevPositionY = 0;
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  closeMenuScroll = () => {
    this.prevPositionY = window.scrollY;
    window.addEventListener("scroll", this.checkPos);
  }

  checkPos = () => {
    let currentPos = window.screenY;
    if (currentPos > this.prevPositionY || currentPos < this.prevPositionY) {
      this.onCloseMenu()
    }
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid sx={{ width: "100%", height: { xs: "200px", md: "400px" }, background: `url(${backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <Grid sx={{
            maxWidth: "1200px", margin: "auto", display: "flex", justifyContent: { xs: "center", md: "flex-start" }, alignItems: "end", height: "100%",
            padding: { xs: "10px 10px 2% 10px", lg: "0 0 2% 0" }
          }}>
            <Grid sx={{ bgcolor: "#00000075", padding: "10px 80px", borderRadius: "8px" }}>
              <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "25px", fontWeight: "bold" }}>
                منطقه ۵
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: "1200px", margin: "auto", padding: { xs: "10px", lg: "0" } }}>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h2">
            منطقه ۵ تهران
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            منطقه
            ۵ در شمال غربی شهر تهران واقع شده است. بواسطه قرارگیری در رشته کوههای البرز آب و هوای معتدل همراه با پوشش گسترده ای گیاهان، موجب افزایش
            ساخت و ساز در این منطقه شده است. نبود ترافیک و دسترسی راحت به وسایل حمل و نقل عمومی و بزرگراه ها از دیگر مزایای منطقه ۵ شهرداری تهران است.
            <br />
            منطقه ۵ تهران از جنوب به بزرگراه شهید لشکری و میدان <Link to={"/Azadi"} className="zone_text_link" target="_blank">آزادی</Link>
            ، از شرق به بزرگراه <Link to={"/Listings/buy_tehran_ashrafiesfahani"} className="zone_text_link" target="_blank">اشرفی اصفهانی</Link> و بزرگراه جناح و <Link to={"/tehran-zone-2"} className="zone_text_link" target="_blank">منطقه ۲</Link>
            ( در مجاورت <Link to={"/Listings/buy_tehran_satarkhan"} className="zone_text_link" target="_blank">ستارخان</Link>
            ، <Link to={"/Listings/buy_tehran_marzdaran"} className="zone_text_link" target="_blank">مرزداران</Link>
            ، <Link to={"/Listings/rent_tehran_shahrakegharb"} className="zone_text_link" target="_blank">شهرک
              غرب</Link> و <Link to={"/Listings/rent_tehran_saadatabad"} className="zone_text_link" target="_blank">سعادت آباد</Link>) و
            از غرب به <Link className="zone_text_link" to="/tehran-zone-22" target="_blank">منطقه ۲۲</Link> منتهی
            می شود. جمعیت ۸۵۰ هزار نفری در وسعت ۵۴ کیلومتر مربع درحال زندگی هستند.
          </Typography>
          <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h3">
              منطقه پنج تهران برروی نقشه
            </Typography>
            <Grid sx={{ width: "100%", height: "400px", borderRadius: "15px", overflow: "hidden", display: { xs: "none", md: "block" } }}>
              <ShowCityLocation
                positions={
                  [
                    [35.847317460194596, 51.33773803710938],
                    [35.817534763995525, 51.23336791992188],
                    [35.76517922004933, 51.263236999511726],
                    [35.74316846139925, 51.27079010009766],
                    [35.725611214962285, 51.28692626953126],
                    [35.7164130173296, 51.291732788085945],
                    [35.717249260994514, 51.307182312011726],
                    [35.712789193374775, 51.33602142333985],
                    [35.75069181054449, 51.332244873046875],
                    [35.7671292467937, 51.337394714355476],
                    [35.77882840327371, 51.3358497619629],
                    [35.780360308212735, 51.338939666748054],
                    [35.79177903299131, 51.34099960327149],
                  ]
                }
                position={
                  [35.7718648269944, 51.29954338073731]
                }
                zoom={11}
              />
            </Grid>
            <Grid sx={{ width: "100%", height: "300px", borderRadius: "15px", overflow: "hidden", display: { xs: "block", md: "none" }, margin: "auto" }}>
              <ShowCityLocation
                positions={
                  [
                    [35.847317460194596, 51.33773803710938],
                    [35.817534763995525, 51.23336791992188],
                    [35.76517922004933, 51.263236999511726],
                    [35.74316846139925, 51.27079010009766],
                    [35.725611214962285, 51.28692626953126],
                    [35.7164130173296, 51.291732788085945],
                    [35.717249260994514, 51.307182312011726],
                    [35.712789193374775, 51.33602142333985],
                    [35.75069181054449, 51.332244873046875],
                    [35.7671292467937, 51.337394714355476],
                    [35.77882840327371, 51.3358497619629],
                    [35.780360308212735, 51.338939666748054],
                    [35.79177903299131, 51.34099960327149],
                  ]
                }
                position={
                  [35.7718648269944, 51.29954338073731]
                }
                zoom={11}
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            پارک‌های منطقه ۵ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            پارک تفریحی دره کن ، پارک جنگلی کوهسار ، باغ وحش ارم ، پارک ارم ، بوستان جوانمردان
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز خرید منطقه ۵ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            فروشگاه مگامال ، مرکز خرید هایپر ، پردیس زندگی ، همیلا مال.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            مرکز خرید کوروش ، مجتمع تجاری اداری بوستان ، تیراژه ( سرزمین عجایب) ، المپیا ، اشرفی مال.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز درمانی منطقه ۵ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            بیمارستان صارم ، بیمارستان پیامبران ، بیمارستان امید ، بیمارستان فردا ، بیمارستان ابن سینا ، بیمارستان عرفان نیایش ، روانپزشکی آزادی.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            سینما‌های منطقه ۵ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            پردیس سینمایی زندگی ، سینما سمرقند ، سینما مگامال ، پردیس سینمایی کوروش.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            محله های منطقه 5:
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            شهران ، شهرزیبا ، بهاران ، سازمان آب ، حصارک.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            سازمان برنامه ، بلوار فردوس ، جنت آباد ، شهرک اکباتان
          </Typography>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={(event) => { this.onClickMenu(event) }}
              sx={{ margin: "20px 0", border: "2px solid #d7d7d7", padding: "10px 40px", boxShadow: "0 2px 2px 0 #ebebeb", borderRadius: "8px" }}
            >
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "black" }}>
                سایر مناطق تهران
              </Typography>
              <KeyboardArrowDownRoundedIcon sx={{ color: "gray !important" }} />
            </Button>
            <Menu
              open={this.state.openMenu}
              onClose={this.onCloseMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ "& .MuiPaper-root": { maxHeight: "300px", overflow: "scroll-y" } }}
            >
              <MenuItem>
                <Link to={'/tehran-zone-1'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-2'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-3'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-4'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-5'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu} sx={{ padding: "8px 40px" }}> منطقه ۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-6'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-7'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-8'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-9'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-10'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-11'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-12'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-13'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-14'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-15'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-16'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-17'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-18'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-19'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-20'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-21'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-22'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۲</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 0 0" }}>
              مقالات مرتبط
            </Typography>
            <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Link to="/north-of-Tehran-cafe" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage02})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        کافه های شمال تهران
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
          <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
            <Link to={'/Listings/buy_tehran_shahran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahreziba'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرزیبا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahreziba'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرزیبا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_andishe'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اندیشه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_andishe'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اندیشه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_baharan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بهاران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_baharan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بهاران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{
            bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "5600px" : "0px", md: this.state.openLinks ? "1850px" : "0px" }
            , transition: "max-height 0.6s linear", overflow: "hidden"
          }}
          >
            <Link to={'/Listings/buy_tehran_kan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_kan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_almahdi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در المهدی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_almahdi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در المهدی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_baghefaiz'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در باغ فیض
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_baghefaiz'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در باغ فیض
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_punakjonubi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در پونک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_punakjonubi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در پونک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_hesarak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در حصارک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_hesarak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در حصارک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakenaft'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک نفت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakenaft'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک نفت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_kuohsar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کوهسار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_kuohsar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کوهسار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_moradabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در مرادآباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_moradabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در مرادآباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sazmanebarname'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سازمان برنامه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sazmanebarname'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سازمان برنامه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_eram'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ارم
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_eram'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ارم
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_parvaz'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در پرواز
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_parvaz'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در پرواز
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sazmaneab'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سازمان آب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sazmaneab'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سازمان آب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_abazar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اباذر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_abazar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اباذر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ferdos'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در فردوس
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ferdos'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در فردوس
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_mehranj'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در مهران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_mehranj'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در مهران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ekbatan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اکباتان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ekbatan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اکباتان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bimeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بیمه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bimeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بیمه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_apadana'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آپادانا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_apadana'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آپادانا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ayatkashani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آیت الله کاشانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ayatkashani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آیت الله کاشانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_esfahaniashraf'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اشرفی اصفهانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_esfahaniashraf'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اشرفی اصفهانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_iranzamin'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ایران زمین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_iranzamin'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ایران زمین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bulvartaavon'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بلوار تعاون
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bulvartaavon'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بلوار تعاون
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bulvarferdos'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بلوار فردوس
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bulvarferdos'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بلوار فردوس
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bolivar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بولیوار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bolivar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بولیوار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_payambar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در پیامبر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_payambar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در پیامبر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_janatabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در جنت آباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_janatabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در جنت آباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_satari'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ستاری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_satari'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ستاری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sardarjangal'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سردار جنگل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sardarjangal'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سردار جنگل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_solaghan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سولقان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_solaghan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سولقان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakeekbatan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک اکباتان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakeekbatan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک اکباتان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahidmehdibakeri'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهید مهدی باکری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahidmehdibakeri'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهید مهدی باکری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_dehkadeolampik'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دهکده المپیک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_dehkadeolampik'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دهکده المپیک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bulvareayvank'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بلوار ایوانک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_bulvareayvank'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بلوار ایوانک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_abshar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آبشار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_abshar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آبشار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shoara'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شعرا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shoara'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شعرا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakevalfajr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک والفجر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakevalfajr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک والفجر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sharbiyani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شربیانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sharbiyani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شربیانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_nahid'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ناهید
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_nahid'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ناهید
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_nirugah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در نیروگاه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_nirugah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در نیروگاه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_abshenasan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آبشناسان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_abshenasan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آبشناسان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_kankuee'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کنکوی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_kankuee'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کنکوی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_earmkuee'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ارمکوی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_earmkuee'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ارمکوی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              ما در این صفحه به معرفی  منطقه 5 تهران – نقشه – پارک – مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا می پردازیم.
              همچنین منطقه 5 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه پنج را مورد بررسی قرار می دهیم.
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Zone05;