import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import MapImage from "../images/map.jpg"
import { Link } from "react-router-dom";

class NotFound extends React.Component {

  render(){
    return(
      <Grid sx={{margin:{xs:"100px 0 0",md:"200px 0 0"},textAlign:"center"}}>
        <Grid sx={{width:"200px",height:"200px",backgroundImage:`url(${MapImage})`,backgroundPosition:"center",backgroundSize:"cover",margin:"auto"}}></Grid>
        <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"50px",md:"115px"},color:"#14C9E8"}}>404</Typography>
        <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:"20px"}}>صفحه ای با این آدرس وجود ندارد</Typography>
        <Button component={Link} to="/"
           sx={{fontFamily:"shabnam",margin:"30px 0",bgcolor:"#14C9E8",color:"white",fontWeight:"bold","&:hover":{bgcolor:"#14C9E8"}}}
        >
          بازگشت به صفحه اصلی سایت
        </Button>
      </Grid>
    )
  }

}

export default NotFound ;