import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article36/01.jpg";
import photo02 from "../../images/mag/article36/02.jpg";
import photo03 from "../../images/mag/article36/03.jpg";
import photo04 from "../../images/mag/article36/04.jpg";
import photo05 from "../../images/mag/article36/05.jpg";
import photo06 from "../../images/mag/article36/06.jpg";
import photo07 from "../../images/mag/article36/07.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article36 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "مسکن مهر و طرح نهضت ملی مسکن، برای اقشار مختلف جامعه به ویژه آن‌هایی که توانایی خرید منزل مناسب ندارند، تدارک دیده شده است.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = " طرح نهضت ملی مسکن ۱۴۰۱، مسکن مهر ۱۴۰۱، سند زدن مسکن مهر ، ثبت نام مسکن مهر 1401 ، ثبت نام نهضت ملی مسکن 1401 ، قیمت مسکن مهر، صفر تا صد تشکیل پرونده نهضت ملی مسکن ۱۴۰۱"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[35].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[35].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[35].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[35].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[35].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[35].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="طرح ملی نهضت مسکن - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[35].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افرادی که در کشور ایران زندگی می‌کنند به طور حتم نام مسکن مهر و طرح نهضت ملی مسکن را بارها و بارها از رسانه‌های مختلف و افراد متعدد شنیده‌اند. این دو طرح در واقع اقداماتی هستند که به منظور تامین واحد مسکونی برای اقشار مختلف جامعه به ویژه آن‌هایی که توانایی خرید منزل مناسب ندارند تدارک دیده می شود. این طرح‌ها از بسیاری جهات به یکدیگر شباهت داشته و برخی از ویژگی‌ها، شرایط و ضوابط آن‌ها با یکدیگر اختلافاتی دارند. در  <Link className="link" to="/mehr-house" target="_blank"> این مقاله </Link> از <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link> به ارائه مطالبی در مورد طرح نهضت ملی مسکن و مسکن مهر خواهیم پرداخت و تفاوت‌های میان این دو طرح را بررسی خواهیم کرد.  <Link className="link" to="/apartment-pre-sale" target="_blank"> شرط خرید </Link> و <Link className="link" to="/property-document" target="_blank"> سند زدن مسکن </Link>  در  طرح نهضت ملی مسکن و مراحل ثبت نام در این <LinkMui className="link" href="https://saman.mrud.ir/" target="_blank"> طرح </LinkMui> از دیگر مواردی است که به آن خواهیم پرداخت.
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت طرح نهضت ملی مسکن و مسکن مهر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="مسکن مهر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مسکن مهر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همان‌طور که می‌دانید در حال حاضر در کشور ایران بسیاری از افراد از داشتن یک ملک مناسب محروم هستند. به همین دلیل برخی از سازمان های دولتی، اقدامات و خدماتی را جهت خانه دار شدن مردم تدارک دیده‌اند. از جمله این طرح‌ها می‌توان به طرح مسکن <LinkMui className="link" href="https://emehr.ntdc.ir/" target="_blank"> مهر </LinkMui> و طرح نهضت ملی مسکن اشاره کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از نظر بسیاری از افراد، طرح نهضت ملی مسکن در واقع ادامه همان مسکن مهر است. دلیل اصلی این اظهار نظر، شباهت‌های زیاد میان این دو طرح می باشد. در این پروژه‌ها زمین مورد نیاز برای ساخت واحدهای مسکونی، توسط دولت تامین می شود.  سپس مسکن‌ها و ساختمان‌های احداث شده با شرایطی ویژه و با قیمتی مناسب در اختیار متقاضیان واجد شرایط قرار می‌گیرند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-fraud" target="_blank">
                  بیشتر بخوانید :  انواع کلاهبرداری در معاملات املاک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                شرایط ثبت نام
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حال حاضر افراد متاهل، برخی از مجردین با شرایط و ضوابط خاص و یا افرادی که سرپرست خانوار هستند این امکان را دارند که در زمان مقرر در طرح نهضت ملی مسکن ثبت نام کنند. این افراد باید در مراحل ثبت نام، مدارک مورد نیاز از قبیل کارت ملی و شناسنامه و یا گواهی لازم برای اثبات سکونت ۵ ساله در شهر و استان محل تقاضا و ... را ارائه دهند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به طور کلی، نهضت و طرح مسکن ملی ۴۰۰ هزار واحد مسکونی را در بر می‌گیرد. از میان این تعداد ۲۰۰ هزار واحد در شهرهای جدید و با مشارکت و همیاری بخش خصوصی احداث می‌شود. همچنین صد هزار واحد آن به مناطق با بافت فرسوده اختصاص پیدا می‌کند.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="شرکت بازآفرینی شهری - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شرکت بازآفرینی شهری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این پروژه‌ها به وسیله <LinkMui className="link" href="https://udrc.ir/" target="_blank"> شرکت بازآفرینی </LinkMui> شهری ساخته و پیگیری می‌شوند. تکمیل صد هزار واحد دیگر نیز به عهده <LinkMui className="link" href="https://bme.ir/Pages/Home.aspx" target="_blank"> بنیاد مسکن انقلاب اسلامی </LinkMui> خواهد بود. طرح نهضت ملی مسکن  ابتدا در استان کرمان آغاز شد و بعد از آن به ده استان دیگر اختصاص پیدا کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                اصلی ترین تفاوت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از اصلی‌ترین موارد اختلاف و اساسی‌ترین تفاوت طرح نهضت ملی مسکن و مسکن مهر، تعداد طبقات واحدها و ساختمان‌ها است. طبق گفته مسئولان، قرار است که در طرح نهضت ملی مسکن، واحدها در نهایت در ۷ طبقه احداث شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بنابراین در این پروژه‌ها با ساختمان‌هایی چندین طبقه و مرتفع سر و کار نخواهیم داشت. یکی دیگر از موارد اختلاف میان این دو طرح، مکان مسکن مهر و موقعیت جغرافیایی و محل قرارگیری ساختمان‌ها می باشد. در واقع یکی از مهمترین مشکلات در حوزه واگذاری و <Link className="link" to="/Listings/buy_tehran" target="_blank"> فروش </Link> مسکن مهر، موقعیت محلی و مکانی پروژه‌ها بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ساخت این واحدها از اراضی خارج از شهر استفاده می‌شد. به همین دلیل این ساختمان‌ها زیرساخت‌ و امکانات رفاهی کافی برای زندگی را نداشتند و <Link className="link" to="/smart-home" target="_blank"> امکانات و تجهیزات </Link> آن‌ها برای یک زندگی راحت، قابل قبول نبود. وجود این معضلات هزینه‌های بسیار زیادی را به دولت تحمیل می‌کرد تا به تامین مناسب امکانات اقدام کنند و زیرساخت‌های مورد نیاز را به وجود آورند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بسیاری از افراد پس از ورود به سایت مسکن مهر، هنگامی که متوجه می‌شدند مسکن مهر کجاست، از خرید مسکن مهر منصرف می شدند و از ثبت نام در این طرح خودداری به عمل می‌آوردند. در حالی که این مشکل در طرح نهضت مسکن ملی برطرف شده است. برای احداث این پروژه‌ها، مکان یابی درون شهرها صورت می پذیرد. لذا دولت نیز با هزینه‌های گزاف در حوزه تامین و ساخت زیرساخت‌های اصلی و فراهم کردن تجهیزات رفاهی و ... روبرو نخواهد شد.
              </Typography>
              <Typography className="h2" variant="h2">
                شهرهای محل اجرای طرح نهضت مسکن ملی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="طرح نهضت ملی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  طرح نهضت ملی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حال حاضر طرح نهضت ملی مسکن در شهرهای کرمان، بم، سیرجان، جوزم، شهداد، گلباف، ماهان، جوبار، خاتون آباد، نظام شیر، کهنوج، جیرفت و ... صورت می پذیرد و در اصفهان نیز در شهرهایی از قبیل کاشان، قمصر، جوشقان، نایین، انارک و ... انجام می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این طرح در استان خراسان جنوبی نیز در شهرهایی مانند بیرجند، طبس و نهبندان به اجرا در می‌آید. محل اجرای این پروژه‌ها در زنجان، آب بر و در استان هرمزگان در شهر جاسک است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/professional-real-estate" target="_blank">
                  بیشتر بخوانید : چطور یک مشاور املاک حرفه ای شویم؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرایط و چگونگی پرداخت اقساط در طرح نهضت ملی مسکن و برآورد قیمت آن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بسیاری از افرادی که در طرح نهضت ملی مسکن ثبت نام می‌کنند تمایل دارند بدانند شانس خانه دار شدن آن‌ها در این طرح تا چه اندازه است. یکی از مهمترین نکاتی که این افراد باید به آن دقت داشته باشند این است که در این پروژه‌ها چیزی در حدود ۵۰ تا ۶۰ درصد از واحدها به شرکت سازنده مربوط می باشد. مابقی واحدها یعنی ۳۰ تا ۴۰ درصد از آن‌ها به دولت ارتباط پیدا می‌کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                متقاضیانی که واجد شرایط ثبت نام در این طرح هستند می‌توانند به سامانه <Link className="link" to="/amlak.mrud.ir" target="_blank"> saman.mrud.ir </Link>  مراجعه  و مدارک لازم را در این وب سایت بارگذاری کنند. بعد از تکمیل مراحل ثبت نام و ارائه مدارک، در هنگام <Link className="link" to="/house-tracking-code" target="_blank"> عقد قرارداد</Link>  واگذاری واحدها نیز باید چیزی در حدود ۳۰ تا ۴۰ میلیون تومان پرداخت نمایند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پرداخت ۲۵ درصد از کل مبلغ واحدها در پایان فرایند اسکلت ضرورت دارد. همچنین ۲۵ درصد دیگر را باید در پایان مراحل سفت کاری پرداخت کنید. پرداخت ۲۰ درصد در زمان <Link className="link" to="/building-facades-types" target="_blank"> نازک کاری </Link> ضرورت داشته و لازم است ۱۵ درصد باقی مانده را هنگام تحویل کلید بپردازید.
              </Typography>
              <Typography className="h3" variant="h3">
                قیمت هر واحد آپارتمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از نکات و سوالات مد نظر متقاضیان این طرح در نظر گرفتن <Link className="link" to="/Listings" target="_blank"> قیمت واحد های آپارتمانی </Link> در طرح نهضت ملی مسکن است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طبق گفته مسئولان از جمله <LinkMui className="link" href="https://inbr.ir/?cat=4" target="_blank"> معاون مسکن </LinkMui> و ساختمان وزارت راه و شهرسازی، قیمت متوسط ساخت هر یک متر مربع مسکن در این طرح،  حدود ۱.۵ میلیون تومان در مورد خانه‌های یک طبقه و <Link className="link" to="/Listing/287" target="_blank"> ویلایی </Link> در نظر گرفته شده و ۲ تا ۲.۵ میلیون تومان برای واحدهای <Link className="link" to="/Listings/rent_tehran" target="_blank"> آپارتمانی </Link> تعیین و محاسبه شده است. به طور کلی قیمت واحدها و ساختمان‌ها در این طرح با توجه به چند عامل متغیر خواهد بود. یکی از مهمترین این عوامل نوع سازه مورد نظر است. تعداد طبقات ساختمان‌ها نیز در قیمت نهایی اثرگذار خواهد بود. پس نمی‌توان قیمت دقیقی را برای واحدها و ساختمان‌های مختلف در طرح نهضت ملی مسکن مشخص نمود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/building-certificate" target="_blank">
                  بیشتر بخوانید : همه چیز راجع به صدور پروانه ساختمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرایط خرید و سند زدن در طرح نهضت ملی مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="سند زنی مسکن مهر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سند زدن مسکن مهر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بر اساس <LinkMui className="link" href="https://rc.majlis.ir/fa/law/show/135262" target="_blank">  آیین‌ نامه اجرایی قانون ساماندهی و عرضه مسکن </LinkMui>، شرایط طرح نهضت ملی مسکن به این شرح است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی متقاضیان و افراد تحت سرپرستی آن‌ها باید در پنج سال اخیر، فاقد زمین مسکونی و یا واحد مسکونی باشند. از سوی دیگر خریداران نباید از تاریخ ۲۲ بهمن سال ۱۳۵۷ تاکنون از امکانات نهادهای عمومی و غیر دولتی مرتبط با تامین مسکن و یا تسهیلات مربوط به این‌ بخش استفاده کرده باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                متقاضیان باید متاهل بوده و یا سرپرست خانوار باشند. برخی از افراد نیز می‌توانند بدون شرط تاهل و یا سرپرست خانوار بودن، از جمله متقاضیان واجد شرایط این طرح باشند. از جمله این افراد می‌توان به زنان مجرد حداقل ۳۵ ساله اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                شرایط ثبت نام مردان مجرد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مردان مجرد با حداقل ۴۵ سال سن، نخبگان علمی، معلولین جسمی و حرکتی با داشتن حداقل ۲۰ سال، بیماران خاص، مردان مجرد ۲۳ تا ۴۵ ساله به شرط ارائه تعهد نامه محضری درباره ارائه سند رسمی ازدواج دائم در زمان واگذاری واحد، واجد شرایط ثبت نام در طرح نهضت ملی خواهند بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                متقاضیان این طرح باید دارای حداقل سابقه ۵ ساله سکونت در شهر مورد تقاضا را داشته باشند و در پنج سال اخیر از تسهیلات ساخت مسکن و یا خرید آن از <Link className="link" to="/way-to-get-house-loan" target="_blank"> سیستم بانکی کشور </Link> استفاده نکرده باشند. این افراد نباید هیچگونه منع قانونی برای دریافت تسهیلات بانکی داشته و توان تامین هزینه ساخت مسکن را نیز دارا باشند .
              </Typography>
              <Typography className="h2" variant="h2">
                مدارک مورد نیاز برای ثبت نام در طرح نهضت ملی مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                متقاضی این طرح باید در زمان ثبت نام، تصویر کارت ملی و تصویر شناسنامه خود و افراد تحت سرپرستی را در وب سایت بارگذاری کند. بارگذاری تصویر گواهی سرپرستی در صورت لزوم نیز ضرورت دارد. این افراد باید پس از سپری کردن مراحل ثبت نام برای بارگذاری تصاویر و مدارک خود به قسمت مشاهده و پیگیری درخواست در سامانه مورد نظر مراجعه کنند .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/way-to-get-house-loan" target="_blank">
                  بیشتر بخوانید : نحوه دریافت وام مسکن ۱۴۰۱
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ثبت نام مجردین واجد شرایط در طرح نهضت ملی مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="شرایط ثبت نام مسکن مهر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شرایط ثبت نام
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این دسته از متقاضیان دارای برخی از شرایط عمومی ثبت نام اولیه بوده و باید شرایط دیگری را نیز بپذیرند. برای نمونه متقاضیان مرد مجرد باید حداقل ۲۳ سال تمام داشته و حداکثر سن آن‌ها ۴۵ سال تمام باشد. این افراد نباید سابقه مالکیت مسکن و یا زمینی با کاربری مسکونی داشته باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین نباید از تاریخ ۲۲ بهمن سال ۱۳۵۷ تاکنون از امکانات دولتی و یا نهادهای عمومی غیر دولتی مرتبط با تأمین مسکن زمین و یا تسهیلات یارانه‌ای استفاده کرده باشند. همسر متقاضی نیز باید در زمان انعقاد قرارداد واگذاری واحد، حائز شرایط قانون ساماندهی و عرضه مسکن، آیین نامه‌های اجرایی و اصلاحات آن باشد. سابقه سکونت حداقل ۵ ساله در شهر مورد تقاضا، ارائه تعهد نامه محضری مبنی بر ارائه عقدنامه رسمی ازدواج دائم در زمان واگذاری واحد و .... از دیگر شرایط ثبت نام مجردین در طرح نهضت ملی مسکن است .
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط انعقاد قرارداد طرح نهضت ملی مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این طرح اولویت ثبت نام با متقاضیان متاهل بوده و از میان آن‌ها، اولویت با افرادی است که جمعیت بیشتری دارند. زمان اتصال متقاضیان به پروژه‌های طرح نهضت مسکن ملی بر اساس ضوابط ابلاغ شده از وزارت راه و شهرسازی صورت می پذیرد و مبتنی بر سهم آورده و ارائه عقدنامه رسمی ازدواج دائم به <LinkMui className="link" href="https://www.mrud.ir/" target="_blank"> اداره کل راه و شهرسازی  </LinkMui> و سپس درج آن در سامانه است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در صورت عدم ارائه عقدنامه در زمان واگذاری واحد، ثبت نام متقاضی ملغی می شود و کل سهم آورده و پرداخت شده بدون سود به وی مسترد می‌گردد. ظرف مدت زمان ۶ ماه پس از لغو ثبت نام متقاضیان مجرد، متقاضی دیگری برای واگذاری واحد جایگزین خواهد شد. در صورت عدم وجود متقاضی متاهل و مطابق با شرایط، در صورتی که متقاضی مجرد تمایل داشته باشد، واحد با شرایطی خاص به وی واگذار خواهد شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از این شرایط این است که متقاضی مجرد تمامی بهای زمین و هزینه احداث را به صورت نقدی و بر اساس قیمت روز و با کسر اصل آورده پرداخت کند. تسهیلات دریافت شده توسط وی نیز با نرخ مصوب <LinkMui className="link" href="https://www.cbi.ir/simplelist/4425.aspx" target="_blank"> شورای پول و اعتبار </LinkMui> محاسبه می شود و اقساطی خواهند شد. متقاضیان مجرد باید تمامی یارانه‌های پرداخت شده را به قیمت روز باز پرداخت نمایند. این یارانه‌ها شامل تخفیف هزینه <Link className="link" to="/building-certificate" target="_blank"> صدور پروانه </Link>، نظام مهندسی، انشعابات و .. هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                نحوه ثبت نام در طرح نهضت ملی مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="وزارت راه و شهرسازی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وزارت راه و شهرسازی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی مراحل ثبت نام واجدین شرایط و متقاضیان در این طرح به صورت غیر حضوری انجام می‌شود. پس نیازی به مراجعه حضوری به هیچ یک از مراکز مربوطه نخواهید داشت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وزارت راه و شهرسازی در حال حاضر بستری را در فضای وب و اینترنت راه اندازی کرده است. بنابراین متقاضیان این طرح این امکان را دارند که به راحت‌ترین شکل ممکن فرآیند و مراحل ثبت نام خود را نهایی کنند. همچنین می توانند پیگیری‌های مورد نیاز در خصوص نتایج نهضت مسکن را انجام دهند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/buy_tehran_daryachechitgar" target="_blank">
                  بیشتر بخوانید : خرید و فروش ملک، آپارتمان در تهران - دریاچه چیتگر
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مراحل ثبت نام طرح مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ثبت‌ نام در این طرح باید در ابتدا وارد سایت این پروژه به آدرس <LinkMui className="link" href="https://saman.mrud.ir/" target="_blank"> saman.mrud.ir </LinkMui>  شوید. پس از آن گزینه ثبت نام نهضت ملی مسکن را انتخاب کنید. با این انتخاب، تمامی قوانین و شرایط طرح نهضت ملی مسکن پیش روی شما قرار خواهند گرفت. با پذیرش این قوانین وارد مرحله ارائه کد ملی و شماره موبایل خواهید شد که باید آن‌ها را در فیلد مربوطه وارد کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سپس کد امنیتی را وارد سازید و به مرحله بعد بروید. در این مرحله باید مشخصات فردی خود را کامل نموده و گزینه سبز را انتخاب کنید. در مرحله پیش رو باید متن هشدار را مطالعه کرده و با لمس "متوجه شدم" به گام بعدی بروید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اطلاعات محل سکونت خود را نیز وارد نموده و سپس به گام بعدی قدم بگذارید. در مرحله پیش رو باید اطلاعات افراد تحت سرپرستی خود را به طور کامل وارد سازید تا به صفحه بعد وارد شوید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیش نمایش اطلاعاتی که وارد کردید را مشاهده کنید. سپس به تایید شماره همراه اقدام نمایید. در نهایت و با طی کردن تمامی مراحل ثبت نام، کادری با مضمون، موفقیت مراحل ثبت نام در طرح نهضت ملی برای شما به نمایش در خواهد آمد.
              </Typography>
              <Typography className="h2" variant="h2">
                سوالات متداول
              </Typography>
              <Typography className="h3" variant="h3">
                چه کسانی واجد شرایط ثبت نام مسکن مهر می باشند؟
              </Typography>
              <Typography component={'li'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}> افراد متاهل، برخی از مجردین با شرایط و ضوابط خاص و یا افرادی که سرپرست خانوار هستند این امکان را دارند که در زمان مقرر در طرح نهضت ملی مسکن ثبت نام کنند. </Typography>
              <Typography className="h3" variant="h3">
                آیا متقاضیان مجرد امکان ثبت نام در مسکن مهر را دارند؟
              </Typography>
              <Typography component={'li'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}> این دسته از متقاضیان باید شرایط عمومی ثبت نام اولیه راا داشته باشند. به عنوان مثال متقاضیان مرد مجرد باید حداقل ۲۳ سال تمام و حداکثر ۴۵ سال تمام باشد. همچنین نباید سابقه مالکیت مسکن  و یا زمینی با کاربری مسکونی  را داشته باشند.
              </Typography>
              <Typography className="h3" variant="h3">
                چگونه در سامانه نهضت ملی مسکن ثبت نام کنیم؟
              </Typography>
              <Typography component={'li'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>
                برای ثبت‌ نام در این طرح باید در ابتدا وارد سایت این پروژه به saman.mrud.ir شوید. پس از آن گزینه ثبت نام نهضت ملی مسکن را انتخاب کنید. با این انتخاب، تمامی قوانین و شرایط طرح نهضت ملی مسکن پیش روی شما قرار خواهند گرفت. با پذیرش این قوانین وارد مرحله ارائه کد ملی و شماره موبایل خواهید شد که باید آن‌ها را در فیلد مربوطه وارد کنید.
              </Typography>
              <Typography component={'li'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>
                پس از تکمیل اطلاعات، کادری با مضمون موفقیت مراحل ثبت نام در طرح نهضت ملی برای شما نمایش داده می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با هومینجا همراه باشید تا از اخرین اخبار و<Link className="link" to="/HomeMag" target="_blank"> مطالب حوزه مسکن </Link> آگاه شوید . مشاوران و متخصصان هومینجا راحت ترین و کم هزینه ترین مسیر خانه دار شدن شما را فراهم می آورند.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article36;