import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article01/01.jpg";
import photo02 from "../../images/mag/article01/02.jpg";
import photo03 from "../../images/mag/article01/03.jpg";
import photo04 from "../../images/mag/article01/04.jpg";
import photo05 from "../../images/mag/article01/05.jpg";
import photo06 from "../../images/mag/article01/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article01 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "نمایشگاه میدکس از معتبرترین نمایشگاه های معماری و دکوراسیون داخلی می باشد. در هومینجا به تعریف و بررسی اهداف برگزاری نمایشگاه می پردازیم";
    document.getElementsByTagName('meta')["keywords"].content = 'نمایشگاه بین المللی معماری و دکوراسیون داخلی | هدف از برگزاری نمایشگاه میدکس | دستاوردهای نمایشگاه میدکس'
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[0].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[0].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[0].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[0].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[0].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[0].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="نمایشگاه میدکس چیست؟" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[0].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اکثر افرادی که عاشق <Link className="link" to="/chandelier-in-decoration" target="_blank">دکوراسیون و معماری</Link> داخل هستند از اواخر دی ماه منتظر
                برگزاری نمایشگاه بین المللی می باشند. در این مطلب از هومینجا به بررسی اهداف برگزاری نمایشگاه،
                مزایای شرکت در نمایشگاه و تاریخ و محل برگزاری نمایشگاه میدکس می پردازیم. پس با هومینجا همراه باشید تا اطلاعات جامعی از این نمایشگاه بدست آورید.
              </Typography>
              <Typography className="h3" variant="h3">
                نمایشگاه بین المللی میدکس چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="نمایشگاه میدکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایشگاه میدکس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://midex.ir/fa/" target="_blank" rel="noreferrer">نمایشگاه میدکس</LinkMui> از معتبرترین نمایشگاه ها در حوزه معماری و دکوراسیون داخلی می باشد.
                در اخرین سالی که این نمایشگاه برگزار شد با حضور بیش از 250 شرکت از 25 کشور جهان بود. این موضوع نشان دهنده اهمیت بالای این
                نمایشگاه می باشد. این نمایشگاه فرصتی ارزشمند برای صاحبان صنایع است تا توانمندی ها و فرآیندهایشان را در حوزه معماری و طراحی
                داخلی در سطح جهانی عرضه کنند. در این نمایشگاه شرکت های داخلی محصولات و نوآوری های خود را برای بازدیدکنندگان به نمایش می گذارند.
                به عبارتی هدف از برگزاری این نمایشگاه همکاری و جمع شدن تمامی شرکت هایی است که در زمینه معماری و دکوراسیون داخلی فعالیت دارند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-interesting-things" target="_blank">
                  بیشتر بخوانید : دانستنی های جالب املاک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مسئول برگزاری نمایشگاه چه گروهی می باشد؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گروه نمانگر که سابقه ی برگزاری ده ها نمایشگاه بین المللی را دارد،
                مسئولیت برگزاری این نمایشگاه را نیز بر عهده دارد. نمانگر موسس انجمن برگزارکنندگان نمایشگاه های بین المللی ایران
                (IEOA) می باشد. این گروه عضویت در اتحادیه جهانی نمایشگاه های بین المللی (UFI) را بدست آورده است. همچنین تمامی نمایشگاه های خود را با تایید
                این اتحادیه بین المللی برگزار می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                مزایای شرکت در نمایشگاه میدکس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمایشگاه میدکس بهترین فرصت برای افراد و شرکت هایی است که
                در زمینه دکوراسیون و طراحی و معماری، فرش، پرده، مبلمان فعال هستند. همچنین این نمایشگاه برای کسانی که در حوزه فناوری های نوین ساختمان،
                تجهیزات هوشمند سازی و ناشران کتب تخصصی و دانشگاهی، نورپردازی فعالیت دارند، بهترین موقعیت برای ارائه و عرضه دستاوردهایشان می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                این نمایشگاه برای شرکت هایی که غرفه گرفته و محصولاتشان را عرضه می کنند مزایای بسیاری دارد. از جمله:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                1- معرفی توانمندی ها و محصولات در سطح گسترده و بین المللی
                <br />
                2- برقراری ارتباط مستقیم با مشتریان و شناخت نیازهای آنان
                <br />
                3- شناخت رقبا و آشنایی با خدمات، محصولات و برنامه های آنان
                <br />
                4- فرصت جذب سرمایه گذار
                <br />
                5- بروز رسانی دانش فنی و آشنایی با دستاوردهای نوین صنعت
                <br />
                6- انجام مذاکرات تجاری و عقد قراردادهای داخلی و خارجی
              </Typography>
              <Typography className="h3" variant="h3">
                نمایشگاه میدکس در چه زمینه ای فعالیت دارد؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="میدکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شمای کلی نمایشگاه میدکس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                نمایشگاه میدکس، نمایشگاه تخصصی در حوزه معماری و دکوراسیون داخلی، معماری و طراحی، فضای باز، سازه و مصالح،
                زیست محیطی، تاسیسات و خدمات می باشد. هر کدام از این دسته ها خود شامل زیرمجموعه هایی می باشد که در اینجا به آن می پردازیم.
              </Typography>
              <Typography className="h4" variant="h4">
                دکوراسیون داخلی:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  انواع پارکت، موکت و قرنیز
                </li>
                <li>
                  انواع کاغذ دیواری
                </li>
                <li>
                  انواع لوازم ویژه دکوراسیون و تزئینی
                </li>
                <li>
                  مبلمان ورزشی و تفریحی
                </li>
                <li>
                  انواع نرده و حفاظ
                </li>
                <li>
                  انواع پوشش سقف
                </li>
                <li>
                  انواع پرده و موکت
                </li>
                <li>
                  کالاهای هنری
                </li>
                <li>
                  لوازم اداری و خانگی با طراحی ویژه
                </li>
                <li>
                  نورپردازی و تجهیزات روشنایی
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                معماری و طراحی:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  طراحی داخلی اماکن مسکونی
                </li>
                <li>
                  طراحی و مشاوره معماری
                </li>
                <li>
                  طراحی و دکوراسیون آشپزخانه
                </li>
                <li>
                  طراحی اماکن اداری و تجاری
                </li>
                <li>
                  طراحی و مشاوره نما و محوطه
                </li>
                <li>
                  طراحی و ایده پردازی
                </li>
                <li>
                  تجهیزات مبلمان و سایر لوازم دکوراسیون
                </li>
                <li>
                  طراحی و مشاوره دکوراسیون نمایشگاهی
                </li>
                <li>
                  طراحی استخر و سونا
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                الکترونیکی:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }} >
                <li>
                  هوشمند سازی BMS
                </li>
                <li>
                  سیستم های کنترل از راه دور
                </li>
                <li>
                  یراق آلات الکترونیکی
                </li>
                <li>
                  سیستم های امنیتی ساختمان
                </li>
                <li>
                  تجهیزات حفاظتی و ایمنی
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                نمای داخلی و عمومی فضای باز:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  سیستم های کنترل خودکار
                </li>
                <li>
                  درب و پنجره
                </li>
                <li>
                  شیشه و محافظ های خورشیدی
                </li>
                <li>
                  فضای سبز
                </li>
                <li>
                  تجهیزات آبنما و آبیاری
                </li>
                <li>
                  مبلمان باغی
                </li>
                <li>
                  طرح های خاص بام
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                سازه و مصالح:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="غرفه نمایشگاه میدکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  غرفه سازی در نمایشگاه میدکس
                </figcaption>
              </figure>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  خانه های پیش ساخت چوبی و بتنی
                </li>
                <li>
                  سرامیک و کاشی و کفپوش
                </li>
                <li>
                  عایق صوتی
                </li>
                <li>
                  پوشش های سقف و دیوار
                </li>
                <li>
                  سنگ های تزئینی
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                زیست محیطی:
              </Typography>
              <ul className="P" sx={{ textAlign: 'justify' }}>
                <li>
                  پوشش پروفیل و کاغذ
                </li>
                <li>
                  خانه سبز سازگار با محیط زیست
                </li>
                <li>
                  تکنولوژی انرژی خورشیدی
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                تاسیسات:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  سیستم های تصفیه هوا
                </li>
                <li>
                  تصفیه آب و رسوب زداها
                </li>
                <li>
                  تجهیزات ویژه استخر و سونا
                </li>
                <li>
                  لوله و اتصالات شیرآلات بهداشتی
                </li>
                <li>
                  اطفا حریق
                </li>
              </ul>
              <Typography className="h4" variant="h4">
                خدمات:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  نشریات و مجلات
                </li>
                <li>
                  وب سایت ها
                </li>
                <li>
                  خدمات بیمه
                </li>
                <li>
                  مراکز علمی و دانشگاهی
                </li>
                <li>
                  خدمات پیمانکاری
                </li>
              </ul>
              <Typography className="h3" variant="h3">
                نمایشگاه میدکس چه زمانی برگزار می شود؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمایشگاه بین المللی معماری و دکوراسیون داخلی میدکس، نمایشگاهی چهار روزه می باشد. این نمایشگاه معمولا هفته آخر دی ماه آغاز
                به کار می کند. بازدید از نمایشگاه از ساعت 9 صبح لغایت 5 بعدازظهر می باشد. بازدید از این نمایشگاه برای عموم افراد آزاد می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                نمایشگاه میدکس کجا برگزار می شود؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                نمایشگاه بین المللی میدکس هر سال در محل دائمی نمایشگاه بین المللی واقع در شمال غرب تهران یعنی اتوبان چمران برپا می شود.
                <br />
                برای دیدن محل دقیق نمایشگاه تهران در <LinkMui href="https://goo.gl/maps/3GHFARZhKKRyzRvz8" className="link">گوگل مپ</LinkMui> کلیک کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                راه های دسترسی به نمایشگاه میدکس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای رفتن به نمایشگاه میدکس می توانید از طریق خودرو، مترو و یا خطوط بی آر تی اقدام نمایید. این نمایشگاه همچنین دارای چندین پارکینگ وسیع می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                دسترسی های محلی به نمایشگاه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                درب شمالی ( ورودی پارکینگ طبقاتی ) : بزرگراه چمران به سمت جنوب بعد از پمپ بنزین.
                <br />
                درب شمالی ( تردد پیاده ) : بزرگراه چمران به سمت چهارراه پارک وی.
                <br />
                دسترسی شمالی ( ورودی شماره 2 پارکینگ طبقاتی ) : خیابان یمن – خیابان رشید فضل الله.
                <br />
                دسترسی جنوبی : بزرگراه نیایش – خیابان سئول به سمت شمال – انتهای جاده اختصاصی نمایشگاه – روبروی درب اصلی باشگاه انقلاب.
                <br />
                درب جنوب غربی: بزرگراه نیایش – خیابان سئول به سمت شمال – جاده اختصاصی نمایشگاه – ورودی اول.
              </Typography>
              <Typography className="h3" variant="h3">
                نزدیکترین ایستگاه های مترو به نمایشگاه بین المللی تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نقشه مترو و نزدیک‌ترین ایستگاه ها به نمایشگاه بین المللی تهران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="متروی نمایشگاه میدکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نزدیکترین ایستگاه های مترو به نمایشگاه بین المللی تهران
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                راه های دسترسی اتوبوس تندرو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نقشه اتوبوسرانی و نزدیک‌ترین ایستگاه های بی آرتی به نمایشگاه بین المللی تهران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="بی ار تی نمایشگاه میدکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  راه های دسترسی اتوبوس تندرو به نمایشگاه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمایشگاه میدکس با مشارکت شرکت های داخلی و
                خارجی با محوریت معماری و دکوراسیون داخلی خدمات خود را عرضه می کند. هدف از برگزاری این نمایشگاه معرفی توانمندی ها و فناوری های نوین،
                اشتغال زدایی و ایجاد فرصت های تجاری جدید می باشد. با هومینجا همراه باشید تا از آخرین اخبار و اطلاعات نمایشگاه میدکس باخبر باشید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Midex" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Midex" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Midex" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article01;