import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article04/01.jpg";
import photo02 from "../../images/mag/article04/02.jpg";
import photo03 from "../../images/mag/article04/03.jpg";
import photo04 from "../../images/mag/article04/04.jpg";
import photo05 from "../../images/mag/article04/05.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article04 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "یکی از مهمترین معاملات خرید و فروش ملک است. بنابراین بهتر است با انواع سند های ملکی آشنا شوید تا هنگام معامله دچار مشکل نشوید";
    document.getElementsByTagName('meta')["keywords"].content = "اسناد رسمی و عادی املاک | سند شش دانگ | سند منگوله دار | سند تک برگ | استعلام سند ملک | ثبت اسناد و املاک | سند منگوله دار | سند وفقی ، عرصه و اعیان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[3].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[3].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[3].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[3].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[3].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[3].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="article photo" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[3].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قبل از هرگونه معامله ای بهتر است انواع سند را بشناسید. یکی از مهمترین این معاملات خرید و فروش ملک است.
                بنابراین بهتر است با <Link className="link" to="/property-document" target="_blank"> انواع سند های ملکی </Link> آشنا شوید. اگر با اطلاعات ناکافی وارد <Link className="link" to="/real-estate-fraud" target="_blank"> معامله </Link> شوید امکان دارد دچار مشکل شوید و برای حل آن مجبور باشید به
                مراجع قضایی مراجعه نمایید. ما در <Link className="link" to="/HomeMag" target="_blank"> هومینجا بلاگ </Link> سعی بر این داریم تا انواع این اسناد را به طور کامل مورد بررسی قرار دهیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                سند چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به هر نوشته ای که بواسطه ارائه آن به دادگاه بتوان حقی را اثبات کرد سند گفته می شود. اسناد بطور کلی به دو دسته رسمی و عادی تقسیم می شوند.
              </Typography>
              <Typography className="h3" variant="h3">
                سند رسمی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به سندی که در <LinkMui className="link" href="https://ssaa.ir/" target="_blank">  اداره ثبت اسناد و املاک </LinkMui> یا <LinkMui className="link" href="https://portal.notary.ir/registries_list" target="_blank">  دفاتر اسناد رسمی </LinkMui> تنظیم می شود سند رسمی گفته می شود. رسمی بودن سند یعنی سند با رعایت قوانین و توسط مامور رسمی تنظیم اسناد منعقد شده باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                سند عادی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سندی است که بصورت
                <Link className="link" to="/house-tracking-code" target="_blank"> قولنامه ای  </Link> ، افراد برای توافق امور بین خودشان تنظیم می کنند.  این اسناد در دفاتر اسناد رسمی ثبت نشده است. همچنین اسنادی که توسط مأمورینِ
                رسمیِ تنظیمِ اسناد، تنظیم شده ولی مأمور، صلاحیت تنظیم سند را ندارد و یا مقررات قانونی در تنظیم آن رعایت نشده باشد نیز سند عادی محسوب می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate" target="_blank">
                  بیشتر بخوانید : مشاور املاک کیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                انواع سند ملکی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="انواع سند ملکی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سند های ملکی
                </figcaption>
              </figure>
              <ul>
                <li>
                  <Typography className="h3" variant="h3">
                    سند ملکی مشاع
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    کلمه مشاع به معنای شراکت است. زمانیکه ملکی دارای
                    چندین مالک باشد سند این ملک مشاع نام دارد. به عبارتی سند میان شرکا به اشتراک گذاشته می شود. توجه داشته باشید زمانی که سند یک ملک مشاع باشد یعنی
                    تمام قسمت های ملک متعلق به شرکا است و تمامی شریکان سهم یکسانی از ملک را دارا می باشند. خرید و فروش چنین ملک هایی از حساسیت بالایی برخوردار هستند.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند شش دانگ
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    شش دانگ به معنای مالکیت شش جهت است که این شش جهت یعنی شمال
                    و جنوب تا شرق و غرب و بالا و پایین. بالا و پایین ملک به فضای بالا و زیرزمین ملک دلالت دارد. وقتی صحبت از سند شش دانگ می شود  یعنی سندی که تماما
                    و کاملا به نام یک شخص باشد و شخص دیگری در مالکیت ملک شریک نیست. سند شش دانگ از معتبرترین اسناد ملکی است و در تمامی مراکز دارای اعتبار است.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند رهنی
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    ممکن است برای خرید ملک موردنظر نیاز به <Link className="link" to="/way-to-get-house-loan" target="_blank"> وام و تسهیلات بانکی </Link> داشته باشیم. در این شرایط سند در رهن بانک گذاشته می شود
                    و پس از پایان دوره و پرداخت به بانک سند به مالک برگردانده می شود. چنانچه در زمان معین وام پرداخت نشود بانک می تواند ملک موردنظر را تصاحب کند.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند تفکیکی
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    سند تفکیکی برخلاف
                    سند شش  دانگ می باشد. این نوع سند برای املاکی استفاده می شود که هر قسمت از ملک در اختیار یک مالک قرار داشته باشد. قسمت های تقسیم شده ملک هر
                    کدام دارای یک سند است. هر کدام از این سندهای تفکیکی از لحاظ حقوقی معتبرند. سند تفکیکی برای املاکی که به صورت مشترک خریداری می شوند کاربرد دارد.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند تک برگ:
                  </Typography>
                  <figure className="image_wrapper">
                    <Typography component={"img"} src={photo03} alt="سند تک برگ - هومینجا" className="image" />
                    <figcaption className="image_caption">
                      سند تک برگ
                    </figcaption>
                  </figure>
                  <Grid className="box">
                    <Link className="link" to="/professional-real-estate" target="_blank">
                      بیشتر بخوانید : چطور به یک مشاور املاک حرفه ای تبدیل شویم
                    </Link>
                  </Grid>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    در قدیم سندها بصورت چند برگی و دست نویس بودند. امروزه اسناد ملکی بصورت تک برگ و <LinkMui className="link" href="https://mycryptocommerce.com/about" target="_blank">  کامپیوتری </LinkMui> تنظیم می شوند. در صورت
                    فروش ملک سند تک برگ باطل می شود و سند جدیدی برای مالک جدید صادر می شود. مشخصات کامل ملک همراه با کروکی ملک بصورت کاملا شفاف و خوانا در سند درج می شود.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند بنچاق
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    در گذشته سند بنچاق توسط کاتبین نوشته میشد و
                    توسط معتمدین و علمای دینی مهر و امضا می شد . گرچه این نمونه از سندها امروزه خیلی رایج نیست ولی نزد مراجع قانونی هنوز دارای اعتبار است.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند منگوله‌دار
                  </Typography>
                  <figure className="image_wrapper">
                    <Typography component={"img"} src={photo04} alt="سند منگوله دار - هومینجا" className="image" />
                    <figcaption className="image_caption">
                      سند منگوله دار
                    </figcaption>
                  </figure>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    سند منگوله دار به صورت
                    دفترچه ای با یک تکه نخ و سرب توسط <LinkMui className="link" href="https://www.post.ir/" target="_blank">  اداره پست </LinkMui> پلمپ شده است. این سند معتبر می باشد. مندرجات داخل این سند توسط یکی از متصدیات مربوطه در ثبت با
                    خودنویس مشکی نوشته می شود. به علت دست نویس بودن این سند جعل آن نیز بسیار راحت است. این اسناد فاقد آدرس ملک و کدپستی و مشخصات کامل ملک می باشد.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند المثنی
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    چنانچه سند ملکی بنابر هر دلیلی از بین برود مانند مفقود شدن یا آتش سوزی یا گم کردن، مالک می تواند مجدد <Link className="link" to="/transferring-property" target="_blank"> تقاضای سند </Link>
                    مالکیت دهد که به آن سند المثنی گفته می شود.در این صورت باید موارد را کتبی اعلام کند و استشهادیه‌ای که به صورت فرم چاپی میباشد، تنظیم و به امضای
                    حداقل سه نفر برساند. استشهادیه را به اداره‌ی ثبت تسلیم کند و اداره‌ی ثبت به وسیله‌ی بخش نامه‌ای فقدان سند را به دفاتر اسناد رسمی اعلام می کند.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند معارض
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    زمانی که برای یک ملک، دوبار سند صادر
                    شده باشد و نام ها و تاریخ متفاوت باشد به آن سند، سند معارض گفته می شود. سند معارض یک سند اختلافی است و باید دادگاه در خصوص آن رای صادر نماید.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند شورایی
                  </Typography>
                  <figure className="image_wrapper">
                    <Typography component={"img"} src={photo05} alt="سند شورایی - هومینجا" className="image" />
                    <figcaption className="image_caption">
                      سند شورایی
                    </figcaption>
                  </figure>
                  <Grid className="box">
                    <Link className="link" to="/amlak.mrud.ir" target="_blank">
                      بیشتر بخوانید : معرفی سایت ملی املاک و اسکان
                    </Link>
                  </Grid>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    سند شورایی یک سند مالکیت عادی است که توسط
                    شورای محلی مهر شده است. این سند فاقد اعتبار لازم است و برای تبدیل شدن به سند رسمی، باید مراحل زیادی را در اداره‌های ثبت اسناد پشت سر بگذارد.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند مفروزی
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    اصطلاح مفروز در برابر مشاع به کار می‌رود . زمانی که چند نفر
                    ملکی را مشاعی داشته باشند و بخواهند سهم خود را جدا کنند تا به طور مستقل از ملک خود بهره برداری کنند، باید آن ملک به نسبت سهم  آنان افراز شود.
                    <br />
                    همانطور که گفتیم منظور از مشاع، ملکی است که میان چند نفر مشترک باشد. منظور از مفروز ملکی است که بعد از اشاعه به صورت چند سهم جداگانه  میان شرکا تقسیم شود.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند وقفی
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    هنگامی که صاحبان ملک آن را وقف کرده باشند ، <LinkMui className="link" href="https://my.oghaf.ir/sc/" target="_blank">  اداره ی اوقاف </LinkMui>
                    می تواند با اجازه مالک، ملک را به صورت 99 ساله به دیگران منتقل کند. خرید فروش اموال وقفی، تنها با اجازه و هماهنگی اداره اوقاف قابل انجام است.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند ورثه ای
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    یکی دیگر از انواع سند، سند
                    ورثه ای است که بعد از فوت مالک به کسانی که وراث این ملک محسوب می شوند، تعلق می گیرد. هر زمان که مالک ملک فوت کند، <LinkMui className="link" href="https://rc.majlis.ir/fa/law/show/92284" target="_blank">  بر طبق قانون ملک </LinkMui> او به وراث
                    می‌رسد که  به این ملک، ملک ورثه‌ای می گویند. ورثه می‌توانند با ارائه مدارک به اداره ثبت، به اندازه‌ سهم الارث خود درخواست صدور سند مالکیت کنند.
                  </Typography>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند وکالتی
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    سند وکالتی سندی است که به نام شخص  دیگری بعنوان وکیل فروشنده اصلی تنظیم شده است. در این صورت باید وکالت آنها بصورت رسمی در محضر ثبت شود.
                  </Typography>
                  <ul>
                    <li>
                      <Typography className="p" sx={{ textAlign: 'justify' }}>
                        سند ذمه ای
                      </Typography>
                    </li>
                    <li>
                      <Typography className="p" sx={{ textAlign: 'justify' }}>
                        سند بنیادی
                      </Typography>
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography className="h3" variant="h3">
                    سند عرصه و اعیان:
                  </Typography>
                  <Typography className="p" sx={{ textAlign: 'justify' }}>
                    سند زمینی که آپارتمان بر روی آن ساخته شده را سند عرضه می نامند. به سند هر واحد آپارتمان نیز سند اعیان می گویند. زمانی که مالکین آپارتمان
                    بخواهند واحد های آپارتمان را تخریب کنند و دوباره تعدادی واحد بر روی آن بسازد. در اینجا سهم هرکدامشان از زمین زیربنایی با توجه به سند اعیان
                    مشخص می شود. میزان سهم مالکان بستگی به متراژ و اندازه اعیان دارد . بنابراین میتوان از شهرداری و یا ادارات مربوط سهم خود را از عرصه مشخص کرد.
                  </Typography>
                </li>
              </ul>
              <Grid className="box">
                <Link className="link" to="/building-certificate" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد صدور پروانه ساختمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نتیجه گیری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدون شک در معاملات ملک ، سند
                نقش تعیین‌کننده‌ای را ایفا می‌کند. سند هویت یک ملک می باشد. به همین دلیل در این مطلب از هومینجا  سعی کردیم شما را با انواع رایج سند ملکی آشنا کنیم
                .بنابراین نیاز است  تا  در خرید خانه یا ملک نهایت دقت را داشته باشید زیرا با یک  اشتباه امکان دارد تمام سرمایه زندگی تان را به سادگی از دست بدهید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/property-document" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/property-document" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/property-document" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article04;