import React from "react";
import { MapContainer, TileLayer,Polygon } from 'react-leaflet';


function ShowCityLocation(props) {
  let position = props.position;
  let polygon = props.positions;

  return(
    <MapContainer center={position} zoom={props.zoom ? props.zoom : 12} style={{width:"100%",height:"100%"}}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { props.positions ?
        <Polygon pathOptions={{color:"#13aec9"}} positions={polygon} />
        : null
      }
      
    </MapContainer>
  )
}

export default ShowCityLocation ;

