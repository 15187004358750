import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article27/01.jpg";
import photo02 from "../../images/mag/article27/02.jpg";
import photo03 from "../../images/mag/article27/03.jpg";
import photo04 from "../../images/mag/article27/04.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article27 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "نحوه صحبت و مذاکره شما را از مشاور معمولی به مشاور املاک حرفه ای تبدیل می کند. پس با هومینجا همراه باشید تا شما را با این فنون اشنا کنیم.";
    document.getElementsByTagName('meta')["keywords"].content = "فنون مشاور املاک موفق | صفر تا صد تبدیل به مشاور املاک پر درآمد | نکاتی که باید هر مشاور املاک موفق در نظر بگیرد."
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[26].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[26].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[26].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[26].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[26].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[26].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="چطور یک مشاور املاک حرفه ای شویم؟ - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[26].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با بروز مشکلات اقتصادی ناشی از بحران کرونا خیلی از ما شغل خود را از دست داده ایم. کمبود شغل و هم زمان افزایش نرخ مسکن ممکن است
                ما را به سمت شغل پردرامد <Link className="link" to="/real-estate" target="_blank">مشاور املاک  </Link> مشتاق کند. پس با بلاگ هومینجا همراه شوید تا راز و رمز تبدیل شدن به مشاور املاک حرفه ای را به شما بگوییم.
              </Typography>
              <Typography className="h2" variant="h2">
                چگونه یک مشاور املاک موفق شویم؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطمئنا انتظار ندارید با خواندن یک مقاله و در عرض 10 دقیقه تبدیل به یک مشاور املا حرفه ای شوید ! ما در اینجا به مواردی اشاره می کنیم که شما با
                تمرین و صرف وقت می توانید به یک مشاور املاک پردرامد تبدیل شوید. اشتیاق ، انگیزه و پشتکار شماست که شغل املاک را برای شما جذاب و پر درامد می کند.
                <br />
                در این مقاله از سایت هوینجا به نکاتی که باید هر مشاور املاک موفق در نظر بگیرد اشاره می کنیم:
                <br />
                در کل می توان تمام نکات را در سه دسته بندی کلی جا داد:
              </Typography>
              <Typography className="h2" variant="h2">
                روی خودتان کار کنید!
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به قول دریاسالار امریکایی ویلیام اچ مک ریون اگر میخواهی دنیا رو تغییر بدی اول از مرتب کردن تخت خودت شروع کن شاید همین جمله خلاصه کل موارد زیر باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                آراستگی ظاهری :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="ظاهر مشاور املاک" className="image" />
                <figcaption className="image_caption">
                  ویژگی ظاهری مشاور املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همیشه ادمها جذب انسانهای شیک پوش و اراسته می شوند و
                همیشه افراد تمیز و خوش لباس قابل اعتمادترند . این نکته را هرگر فراموش نکنید لباس شما بر نگاه مشتری به ملکی که شما معرفی می کنید تاثیر مستقیم دارد.
              </Typography>
              <Typography className="h3" variant="h3">
                شخصیت کاری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مسلما داشتن شخصیتی جنگنده و خستگی ناپذیر
                می تواند یکی از مهم ترین ابزار برای رسیدن به اهداف شما باشد. داشتن شخصیت کاریزماتیک برگ برنده برای تبدیل شدن به یک مشاور املاک حرفه ای است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                پر انرژی باشید:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همیشه لبخند میتواند
                شروع بسیار خوبی برای یک ارتباط باشد. افراد پرانرژی و فعال هم موفق ترند و هم این انرژی را می توانند برای تحقق اهدافشون به دیگران منتقل کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                ورزش کنید :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید فکر کنید ورزش چه ربطی به فنون مشاور املاک دارد؟
                <br />
                ورزش کردن به جر اینکه شما رو سرحال تر میکند و به زیبایی ظاهری شما کمک میکند، اعتماد به نفس شما را هم بالاتر میبرد.
                این می تواند یک کمک بزرگ به شما باشد تا بتوانید به یک مشاور املا موفق تبدیل شوید. همچنین شما را به عقد قراردادهای بهتر و بیشتر سوق می دهد.
              </Typography>
              <Typography className="h3" variant="h3">
                صدای رسا :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سعی کنید روی گقتار و تن صدای خود کار کنید. به این ترتیب که جلوی آیینه با خود تمرین کنید .
                به یاد داشته باشید همیشه صحبت با صدای کمی بالاتر از نرمال شما را فردی با اعتماد به نفس معرفی می کند و قابلیت مدیریت مذاکرات را در شما بالا می برد.
              </Typography>
              <Typography className="h3" variant="h3">
                شنونده خوبی باشید :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="شنونده بودن مشاور املاک" className="image" />
                <figcaption className="image_caption">
                  مدیریت مذاکره بوسیله گوش دادن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به یاد داشته باشید مردم بیشتر از اینکه دوست داشته باشند گوش کنند، علاقه دارند بیشتر صحبت کنند و با کسانی که باتمرکز و اشتیاق به صحبتهای آنها
                گوش دهند ارتباط بهتری برقرار می کنند. پس در نظر داشته باشید اول شما باید شنونده خوبی باشید تا بتوانید جلسه و مشتری را به سمت اهداف خود سوق دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                برنامه ریزی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آخرین اقدام در جهت خود شناسی این است که با خودتون صادق باشید و شناخت کامل از سطح خودتون داشته
                باشید تا درک کنید کجای بازار قراد دارید. این موضوع به شما کمک کند برنامه ریزی دقیق و منطقی برای پیش برد اهداف کوتاه مدت و بلند مدت خود داشته باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                تخصص و مهارت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدون کسب تخصص و آگاهی هیچ شغلی را شروع نکنید.
                <br />
                اولین اقدام شما اخذ مجوزهای مربوطه است و یا مراجعه و کار در مشاور املاک دارای جواز و سابقه می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-fraud" target="_blank">
                  بیشتر بخوانید :انواع کلاهبرداری در معاملات ملک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مطالعه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مطالعه کتاب و مقالات مرتبط غافل نشوید و هرروز را به یادگیری موضوع جدیدی بپردازید. از این رو می توانید از
                مطالب بلاگ هومینجا استفاده کنید. این را بدانید که هر چه اطلاعات شما در زمینه کاری شما بیشتر باشد شانش بالاتری برای تبدیل شدن به مشاور املاک حرفه ای دارید.
              </Typography>
              <Typography className="h3" variant="h3">
                تحقیق :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با هر سوالی که در ذهن شما ایجاد شد شروع به تحقیق کنید. مطالب و نکات مختلف را پیدا کنید
                و سعی کنید همیشه دایره اطلاعات تخصصی خود را بروز نگه دارید. این ایتم شما را از یک مشاور املاک معمولی به سمت تبدیل شدن به مشاور املاک موفق راهنمایی می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                آگاهی از بازار محلی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آگاهی کامل و به روز شما از قیمت انواع ملک یکی از روشهای
                تبدیل شدن به مشاور املاک حرفه ای است. شما باید از قیمت های ملک مسکونی ، آپارتمان و ملک اداری و تجاری در محله کاری مطلع باشید. درصورتی که مشتری احساس کند
                اطلاعات شما ناقص است به هیچ عنوان اعتماد نمی کند. اما اگر اطلاعات شما از آخرین قیمت ها و قراردادها به روز باشد می توانید به همکاری بیشتر امیدوار باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                تکنولوژی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سعی کنید همیشه خودتان را به روز نگه دارید و از پیشرفت روز افزون اینترنت و شبکه
                های اجتماعی به نفع خودتان استفاده کنید . می دانیم امروزه بسیاری از مردم وقت زیادی را صرف فضای مجازی می کنند از این رو فضای مجازی بستر بسیار مناسبی
                برای فعالیت و بازار یابی می باشد. از سایت های خرید و فروش ملک مثل <Link className="link" to="/" target="_blank">سامانه انلاین معاملات املاک هومینجا</Link> برای دریافت فایل و هم چنین نمایش آنها استفاده کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                فن مذاکره :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="فن مذاکره در مشاور املاک" className="image" />
                <figcaption className="image_caption">
                  فنون مذاکره از جمله خصوصیات مشاور املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                درمورد فنون مذاکره مطالعه کنید .در دوره و کلاسهای مهارت فروش شرکت کنید. از کتابهای صوتی غافل نشوید و
                هر زمان آزاد بودید به نکات مربوط به مدیریت جلسه بپردازید. نحوه صحبت و کنترل جلسه مذاکره می تواند شما را از مشاور معمولی به مشاور املاک حرفه ای تبدیل کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید :نکات مهم در مورد خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                فوت آخر
              </Typography>
              <Typography className="h3" variant="h3">
                ذهن خوانی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سعی کنید در اولین دیدار با مشتری سکوت کنید و اجازه دهید تا ابتدا او شروع به گفت و گو کند. هنگام صحبت سعی کنید مواردی که در ذهن مشتری مهم
                است را استخراج کنید و هنگام گفت و گو حرفهای او را تایید کنید تا با ذهن مشتری ارتباط برقرار کنید. این حرکت، شما را به یک مشاور املاک مورد اعتماد تبدیل می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                حمایت از مشتری :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سعی کنید فقط
                به عقد قرارداد فکر نکنید چون فروش شما را کوتاه مدت می کند. حمایت شما از مشتری و رفع نیازهای آن باعث میشود شما در ذهن مشتری یک مشاور املاک حرفه ای تصور شوید.
                <br />
                قطعا حمایت شما باعث رضایت مشتری از نحوه خدمات شما می شود .این بهترین روش تبلیغات هم محسوب می شود .همین مشتری برای شما چندین قرارداد به ارمغان خواهد آورد.
              </Typography>
              <Typography className="h3" variant="h3">
                اهل عمل بودن :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از قولهای بیجا و غیر قابل اجرا به شدت اجتناب کنید .سعی کنید در حیطه وظایف و مسئولیت خود به مشتری قول دهید. در صورتی
                که نتوانید به وعده های خود عمل کنید به شدت کار سختی برای تبدیل شدن به مشاور املاک حرفه ای دارید. یادتان باشد اعتماد چندین ساله ممکن است یک یه شبه نابود شود.
              </Typography>
              <Typography className="h3" variant="h3">
                ارتباط مستمر :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سعی کنید با مشتری های خود ارتباط
                دوستانه و مستمر داشته باشید . آنها را از فعالیت های خود آگاه کنید تا برای همیشه به عنوان یک مشاور املاک حرفه ای در لیست مخاطبان مشتریان خود قرار داشته باشید.
                <br />
                این یکی از مهم ترین موارد برای افزایش فروش شماست. هم چنین با رقبا و همکاران خود ارتباط قوی ایجاد کنید . روابط اجتماعی مهم ترین رفتار یک مشاور املاک حرفه ای است.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع بندی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همانطور که در ابتدای مقاله سایت
                هومینجا گفته شد ” شما با خواندن یک مقاله به یک مشاور املاک حرفه ای تبدیل نمی شوید بلکه مجموعه رفتار و افکار شماست که شما را به یک مشاور املاک موفق تبدیل می کند.”
                <br />
                ما برای شما سایت املاک
                هویمنجا را معرفی می کنیم تا به راحتی از خدمات درج آگهی املاک، جست جوی ملک، عقد قرارداد با تخفیف و هزاران خدمات مربوط به خرید فروش و رهن و اجاره املاک بهره ببرید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مشاورین املاک")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مشاورین املاک
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/professional-real-estate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/professional-real-estate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/professional-real-estate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article27;