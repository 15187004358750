import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import AgencyBackground from "../images/AgencyBackground.jpg"
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { setCanonicalTag } from "./materilas/vmp";
import { Link } from "react-router-dom";


class Agancy extends React.Component {

  isMount = false;
  token = localStorage.getItem("tokenUserHomeInja");

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      giftShow: false,
      openAlert: false,
      agenciesInfo: null,
      agencySort: null,
      searchText: "",
      agencyCounter: 5,
      typeAlert: 3
    }
  }

  componentDidMount() {
    document.title = "معرفی بهترین آژانس های املاک در ایران | سایت املاک و مستغلات هومینجا";
    document.getElementById("main-heading").innerHTML = "لیست برترین آژانس های املاک در کشور به همراه آدرس و شماره تماس";
    document.getElementsByTagName('meta')["description"].content ="شما در صفحه بهترین بنگاه ها و آژانس املاک و مستغلات در ایران هستید. هومینجا بانک اطلاعاتی آگهی های ملکی آژانس های املاک در ایران می باشد. آگهی های فروش و رهن و اجاره بهترین مشاوران املاک در تهران را جستجو کنید."
    document.getElementsByTagName('meta')["keywords"].content = "معتبرترین آژانس املاک تهران | معرفی بهترین مشاور املاک | بهترین آژانس مسکن تهران | برترین مشاور املاک منطقه | بهترین آژانس معاملات املاک در کشور ایران | بهترین دفاتر املاک و مستغلات | بهترین بنگاه مشاور املاک تهران | موفق ترین مشاور املاک ایران | معروف ترین مشاورین املاک تهران | بزرگترین مشاور املاک تهران | بهترین سایت مشاور املاک | بهترین نرم افزار مشاورین املاک  "  
    this.isMount = true;
    if (this.isMount) {
      this.getAllAgency();
      setCanonicalTag();
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  getAllAgency = () => {
    this.setState({ giftShow: true });
    Promise.resolve(axios({
      url: baseUrl + "v1/agancy/all",
      method: "GET",
    }))
      .then((res) => {
        this.setState({ agenciesInfo: res.data.data, agencySort: res.data.data });
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید", typeAlert: 2 });
      })
      .finally(() => {
        this.setState({ giftShow: false });
      })
  }

  closeAlertModal = () => {
    this.setState({ openAlert: false });
  }

  onChangeSearchText = (event, value) => {
    this.setState({ searchText: event.target.value })
  }

  onClickSearch = () => {
    let agenciesInfo = this.state.agenciesInfo;
    let searchText = this.state.searchText;
    searchText = searchText.trim();
    let result = [];
    if (searchText === "") {
      this.setState({ agencyCounter: 5, agencySort: this.state.agenciesInfo });
    } else {
      for (let i = 0; i < agenciesInfo.length; i++) {
        if (agenciesInfo[i].name.includes(searchText)) {
          result.push(agenciesInfo[i]);
          continue
        }
        if (agenciesInfo[i].manager_name.includes(searchText)) {
          result.push(agenciesInfo[i]);
          continue
        }
        if (agenciesInfo[i].phone.includes(searchText)) {
          result.push(agenciesInfo[i]);
          continue
        }
        if (agenciesInfo[i].address.includes(searchText)) {
          result.push(agenciesInfo[i]);
          continue
        }
      }
      this.setState({ agencyCounter: 5, agencySort: result });
    }
  }

  createAgency = () => {
    let result = [];
    let agencies = this.state.agencySort.slice(0, this.state.agencyCounter);
    agencies.forEach((element, index) => {
      result.push(

        <Grid
          sx={{
            width: "100%", margin: "10px 0", border: "2px solid #9AD6F8", borderRadius: "30px 8px 8px 8px", padding: "10px 0",
            background: "linear-gradient(0deg, rgba(154,214,248,0.1) 0%, rgba(255,255,255,1) 100%)",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:{xs:"column",md:"row"}
          }}
        >
          <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "center",width:{xs:"100%",md:"20%"} }}>
            <Typography component={"img"} src={element.logo_path ? element.logo_path : noLogoAgancyImage} alt="agency_logo"
              sx={{ width: "120px", height: "120px", borderRadius: "50%" }}
            />
          </Grid>
          <Grid sx={{display:"flex",alignItems:"center",justifyContent:"center",width:{xs:"100%",md:"80%"},flexDirection:"column"}}>
            <Grid sx={{ display:"flex" , alignItems:"center",justifyContent:"space-between",width:{xs:"100%",md:"100%",lg:"90%"},flexDirection:{xs:"column",md:"row"} }}>
              <Grid sx={{display:"flex",alignItems:"center",justifyContent:{xs:"center",md:'flex-start'},width:{xs:"100%",md:"33%"}}}>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", display: "inline-block", color: "#01A7C4" }}>نام آژانس:</Typography>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "normal", display: "inline-block", marginRight: "5px",fontSize:"14px" }}>{element.name}</Typography>
              </Grid>
              <Grid sx={{display:"flex",alignItems:"center",justifyContent:{xs:"center",md:'flex-start'},width:{xs:"100%",md:"33%"}}}>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", display: "inline-block", color: "#01A7C4" }}>نام مدیر:</Typography>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "normal", display: "inline-block", marginRight: "5px",fontSize:"14px" }}>{element.manager_name}</Typography>
              </Grid>
              <Grid sx={{display:"flex",alignItems:"center",justifyContent:{xs:"center",md:'flex-start'},width:{xs:"100%",md:"33%"}}}>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", display: "inline-block", color: "#01A7C4" }}>شماره تماس:</Typography>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "normal", display: "inline-block", marginRight: "5px",fontSize:"14px" }}>{convertToPersianNumber(element.phone)}</Typography>
              </Grid>
            </Grid>
            <Grid sx={{  display:"flex" , alignItems:"center",justifyContent:{xs:"center",md:"space-between"},width:{xs:"90%",md:"100%",lg:"90%"},flexDirection:{xs:"column",md:"row"} }}>
              <Grid sx={{display:"flex",alignItems:"center",justifyContent:{xs:"center",md:'flex-start'},width:{xs:"100%",md:"75%"}}}>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold",  color: "#01A7C4" }}>آدرس:</Typography>
                <Typography sx={{ fontFamily: "shabnam", fontWeight: "normal", marginRight: "5px",fontSize:"14px", }}>{convertToPersianNumber(element.address)}</Typography>
              </Grid>
              <Grid sx={{display:"flex",alignItems:"center",justifyContent:{xs:"center",md:'flex-start'},width:{xs:"90%",md:"25%"}}}>
                <Button component={Link} to={"/AgencyPage/" + element.id} target="_blank" rel="noreferrer" sx={{ color: "#01A7C4",fontFamily: "shabnam", fontWeight: "bold" ,textAlign: { xs: "center", md: "right" }}} key={index} disableRipple>جزئیات</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid >
      )
    });
    return result;
  }

  onClickMoreAgency = () => {
    this.setState({ agencyCounter: this.state.agencyCounter + 5 });
  }

  onClickEnter = (event) => {
    if (event.key === "Enter") {
      this.onClickSearch()
    }
  }

  onClickClearSearch = () => {
    this.setState({ searchText: "" });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, width: '100%' }}>
        <Grid
          sx={{ width: "100%", background: `url(${AgencyBackground})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <Grid sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "rgba(0, 0, 0, 0.25)" }}>
            <Grid sx={{ textAlign: "center", width: { xs: "98%", lg: "40%" }, margin: { xs: "100px 0", md: "200px 0" } }}>
              <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "20px", md: "30px" }, color: "white", fontWeight: "bold", padding: "0 0 10px" }}>آژانس های همکار با هومینجا</Typography>
              <Grid sx={{ display: "flex", padding: "10px 0 0" }}>
                <TextField
                  sx={{ bgcolor: "white", borderRadius: "0 4px 30px 0", flexGrow: "1" }}
                  InputProps={{
                    sx: {
                      fontFamily: "shabnam", fontSize: "20px"
                    }
                  }}
                  value={this.state.searchText}
                  onChange={this.onChangeSearchText}
                  inputProps={{ maxLength: 30 }}
                  onKeyPress={(event) => { this.onClickEnter(event) }}
                />
                {this.state.searchText.length > 0 ?
                  <Button onClick={this.onClickClearSearch}
                    sx={{ bgcolor: "white", "&:hover": { bgcolor: "white" }, "&:hover svg": { color: "black !important" }, minWidth: "30px", maxWidth: "30px", borderRadius: "0", color: "white" }}
                  >
                    <CloseRoundedIcon sx={{ color: "gray !important" }} />
                  </Button>
                  : <Grid sx={{ width: "30px", bgcolor: "white" }}></Grid>
                }
                <Button disabled={this.state.giftShow} onClick={this.onClickSearch}
                  sx={{
                    bgcolor: "#14C9E8", "&:hover": { bgcolor: "#14C9E8" }, flexBasis: "200px", borderRadius: "30px 0 0 4px", fontFamily: "shabnam", fontWeight: "bold", color: "white",
                    fontSize: { xs: "15px", md: "20px" }
                  }}
                >
                  جستجو آژانس
                  <SearchRoundedIcon sx={{ color: "white !important", fontSize: { xs: "20px", lg: "30px" } }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", textAlign: "center", padding: "30px 0 0", fontSize: "20px" }}>لیست آژانس ها</Typography>
          <Grid sx={{ width: "100%", padding: "2% 8%" }}>
            {this.state.agencySort ?
              this.state.agencySort.length > 0 ?
                <>
                  {this.createAgency()}
                  {this.state.agencySort.length > this.state.agencyCounter ?
                    <Grid item xs={4} lg={8} xl={12} sx={{ width: "100%", textAlign: "center", padding: "4% 0" }}>
                      <Button sx={{ width: "300px", bgcolor: "rgb(20, 201, 232)", fontFamily: "shabnam", fontWeight: "bold", color: "black" }}
                        onClick={this.onClickMoreAgency}
                      >
                        {"موارد بیشتر"}
                      </Button>
                    </Grid>
                    : <Grid></Grid>
                  }
                </>
                : <Grid>
                  <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", textAlign: "center", padding: "20px 0" }}>
                    آژانسی جهت نمایش وجود ندارد
                  </Typography>
                </Grid>
              : <Grid></Grid>
            }
          </Grid>
        </Grid>
        <Grid sx={{ display: "none" }}>
            <Typography>
            ما در این صفحه به معرفی بهترین آژانس های املاک در ایران | سایت املاک و مستغلات هومینجا می پردازیم.
            </Typography>
            <Typography>
              همچنین لیست برترین آژانس های املاک در کشور به همراه آدرس و شماره تماس را ذکر می کنیم.
              </Typography>
              <Typography>
              <a className="zone_text_link" href="https://sorinapp.com" target="_blank" rel="noreferrer">وب گستران سورین </a> مالک مادی و معنوی سایت املاک و مستغلات سورین می باشد.
              </Typography>
              <Typography>
              دراین صفحه از سامانه املاک هومینجا به معرفی بهترین آژانس های املاک و مشاورین املاک پرداخته شده است.
              </Typography>
              <Typography>
              در این صفحه نام آژانس همراه با اطلاعات تماس برای شما نمایش داده می شود. هم چنین آگهی های فروش ملک ، ثبت درخواست ملک، اگهی رهن و اجاره ملک توسط آژانس در سایت هومینجا درج شده است. شما هم میتوانید با ثبت نام و معرفی آژانس املاک خود از امکانات هومینجا برای افزایش فروش استفاده کنید. اعتبار ماهانه رایگان، اختصاصی صفحه مجزا جهت معرفی آژانس املاک و مشاورین فعال، عقد قرارداد با تخفیف، و امکان کار به صورت مشاور وابسته هومینجا به از جمله خدمات بهترین سایت املاک ایران به بهترین آژانس های املاک در ایران می باشد.
              </Typography>
              <Typography>
              امروزه با پیشرفت تکنولوژی کسب و کارهای سنتی جای خود را با کسب و کارهای مجازی عوض کرده اند. دیگر برای خرید ملک یا رهن و اجاره املاک نیازی به بنگاه های سنتی املاک ندارید و با جستجو در بهترین سایت املاک کشور میتوانید ملک مورد نظر خود را با امکانات دلخواه جستجو کنید . 
              </Typography>
              <Typography>
              هم چنین برای فروش ملک یا رهن و و اجاره ملک خود نیاز به سپردن به آژانس مختلف را ندارید و تنها با درج آگهی ملک خود در کمترین زمان و بهترین قیمت به فروش برسانید و یا اجاره دهید.
              </Typography>
              <Typography>
              هم چنین عقد قرارداد برای مالکان یا خریداران با چالش های فراوانی همراه است. هومینجا با ارائه خدمات شایسته توانسته هزینه ناشی از عقد قرارداد ها را مدیریت کرده و کاهش دهد.
              </Typography>
              <Typography>
              مشاورین وابسته هومینجا به صورت دورکاری قابلیت درآمدزایی دارند و میتوانند از تمام امکانات سایت به صورت رایگان استفاده کنند.
              </Typography>
              <Typography>
              هومینجا به عنوان بزرگترین آژانس اینترنتی املاک توانسته نیاز مردم ایران را تامین کند. علاوه بر کاهش هزینه و زمان لازم برای جستجوی ملک، به افزایش بازخورد و ارزش افزوده بنگاه های املاک کمک شایانی کرده است.
              </Typography>
              <Typography>
              با ثبت ملک خود در سامانه هومینجا به جمع همکاران ما بپیوندید. هم چنین شرکت سورین را به تبدیل شدن به بزرگترین فایلینگ کشور یاری دهد.
              </Typography>
         </Grid> 

        <GiftLogo display={this.state.giftShow ? '' : 'none'} />
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert} />
      </Grid>
    )
  };
}

export default Agancy;