import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article56/01.jpg";
import photo02 from "../../images/mag/article56/02.jpg";
import photo03 from "../../images/mag/article56/03.jpg";
import photo04 from "../../images/mag/article56/04.jpg";
import photo05 from "../../images/mag/article56/05.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article56 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = " خوشبو کردن منزل علاوه بر استشمام رایحه مطبوع بر آرامش ذهنی افراد نیز تاثیرگذار است. ما در اینجا به خوشبو کردن خانه با ترکیبات طبیعی می پردازیم.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "بهترین خوشبو کننده خانه | خوشبو نگه داشتن خانه | بهترین خوشبو کننده خانه | ساخت خوشبو کننده خانه | خوشبو کننده طبیعی منزل | چگونه خانه خوشبو داشته باشیم | راههای خوشبو کردن منزل"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[55].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[55].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[55].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[55].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[55].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[55].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="خوشبو کردن منزل - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[55].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه داری تنها به تمیز کردن وسایل خانه یا <Link className="link" to="/Spring-Cleaning" target="_blank">  خانه تکانی عید </Link> محدود نمی شود بلکه یکی از نکات مهم خانه داری خوشبو نگه داشتن منزل می باشد. خیلی از افراد برای خوشبو کردن منزل از خوشبو کننده های شیمیایی استفاده می کنند. این خوشبو کننده ها با اینکه باعث ایجاد رایحه ای مطبوع در محیط می شود اما در طولانی مدت می تواند سبب آلرژی و بیماری های پوستی و تنفسی شود. بنابراین ما در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> به شما چندین روش برای خوشبو کردن خانه با استفاده از ترکیبات طبیعی را آموزش می دهیم.
              </Typography>
              <Typography className="h2" variant="h2">
                روشن کردن شمع
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="خوشبو کردن خانه با شمع" className="image" />
                <figcaption className="image_caption">
                  خوشبو کردن خانه با شمع
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                روشن کردن شمع در خانه هم سبب تازگی و خوشبو کردن فضا می شود و هم حس آرامش را به اعضای خانواده منتقل می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                البته با یک پیشنهاد شگفت انگیز می توانید عطر دل انگیزی را در خانه فراهم نمایید. به این صورت که داخل جاشمعی را پر از دانه های قهوه کنید و سپس وارمر شمع را روشن کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/fengshui" target="_blank">
                  بیشتر بخوانید : فنگ شویی چیست و چه کاربردی در طراحی منزل دارد
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                استفاده از روغن های طبیعی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                می توانید از روغن های طبیعی برای خوشبو نگه داشتن خانه بهره ببرید. روغن های اسطوخودوس، میخک و لیمو علاوه بر عطر خوشی که در خانه پخش می کنند خواص درمانی نیز دارند. برای پخش عطر این روغن ها در محیط می توانید این روغن ها را در فیلتر هوا یا تصفیه کننده بریزید. همچنین می توان روغن ها را در شیشه ای بریزید و  چند چوب داخل آن قرار دهید تا بوی آن در فضا پراکنده شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                روغن هایی که به خوشبو کردن منزل کمک می کند می توان روغن بابونه، روغن چوب صندل، روغن رز، روغن ترنج، روغن لیمو و روغن میخک را نام برد.
              </Typography>
              <Typography className="h2" variant="h2">
                استفاده از سرکه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین روش های از بین بردن بوی نامطبوع استفاده از سرکه می باشد. برای این کار می توانید سرکه را در فضای خانه اسپری کنید. اما باید به این نکته توجه داشته باشید که مستقیم سرکه را بر روی لباس یا مبل نپاشید تا باعث آسیب و تغییر رنگ وسایل نشود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین سرکه برای از بین بردن بوی سیگار در فضای منزل بسیار کارایی دارد. زمانی که بوی سیگار در خانه پیچیده کافی است که ظرفی از سرکه را در قسمت های خانه قرار دهید تا بو از بین برود.
              </Typography>
              <Typography className="h2" variant="h2">
                استفاده از تصفیه کننده هوا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="استفاده از تصفیه هوا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تصفیه کننده هوا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دستگاه های تصفیه کننده هوا علاوه بر اینکه گرد و غبار را از خانه دور نگه می دارد باعث معطر شدن فضای منزل نیز می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از روش های دیگر برای خوشبو نگه داشتن خانه استفاده از گلاب در دستگاه بخور سرد می باشد که علاوه بر ایجاد رایحه خوش در منزل، آرامش را نیز به همراه دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت لازم به ذکر است که <Link className="link" to="/smart-home" target="_blank">  خانه های هوشمند </Link> قابلیت تصفیه هوا را به صورت خودکار دارا هستند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                زدودن چربی های آشپزخانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ماندن چربی در فضای خانه سبب ایجاد بوی نامطبوع در فضای خانه می شود. بنابراین یکی از روش های از بین بردن بوی بد خانه، پاک کردن چربی ها و آلودگی ها از منزل بخصوص فضای اشپزخانه می باشد. برای این کار می توانید از چربی زداهای قوی که دارای اسانس خوشبو کننده نیز می باشند استفاده نمایید.
              </Typography>
              <Typography className="h2" variant="h2">
                استفاده از گل های طبیعی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از روش های تصفیه هوا استفاده از <Link className="link" to="/keeping-apartment-flower" target="_blank">  گل های آپارتمانی </Link> طبیعی می باشد. گیاهان طبیعی با تولید اکسیژن بیشتر هوای منزل را تمیز و تازه نگه می دارند. بنابراین باعث ایجاد بوی خوش نیز در محیط خانه می شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله گل هایی که به خوشبو کردن خانه کمک می کند می توان به گل نیلوفر، ارکیده، رزماری، اکالیپتوس، فوفل اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                خوشبو کردن فرش ها با جوش شیرین
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از روش های خوشبو کردن منزل استفاده از جوش شیرین می باشد. بهتر است مقداری جوش شیرین را روی فرش ها بریزید و بعد از گذشت زمان  کوتاهی جوش شیرین را با جاروبرقی از روی فرش ها جمع کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                ترکیب آب و وانیل
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مخلوطی از آب و وانیل نیز رایحه خوبی را در محیط خانه ایجاد می کند. برای اینکه عطر این ترکیب در خانه بهتر بپیچد بهتر است که این ترکیب را به مدت یک ساعت در فر قرار دهید تا تمام فضای خانه مملو از این عطر شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین می توانید مقداری وانیل را به لامپ سرد بزنید سپس لامپ را روشن کنید. با گرم شدن لامپ عطر وانیل در فضای خانه آکنده می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                خوشبو کردن خانه با میوه ها
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="خوشبو کردن با میوه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خوشبو کننده طبیعی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از روش های طبیعی برای از بین بردن بوی نامطبوع خانه استفاده از پوست میوه هایی مانند پرتقال و نارنگی و خیار می باشد. برای این کار می توانید پوست میوه ها را داخل ظرف آبی گذاشته و در گوشه ای از خانه قرار دهید تا عطر آن در فضای خانه استشمام شود. همچنین در زمستان اگر شومینه روشن می کنید پوست میوه ها را داخل شومینه بریزید تا ضمن سوختن، عطری خوشبو فضای خانه را در بر گیرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/children-room-decoration" target="_blank">
                  بیشتر بخوانید : هر آنچه برای دکوراسیون اتاق کودک نیاز دارید
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نمک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بیش از نیمی از افراد جامعه نمک را برای ضدعفونی مفید می دانند. اما از نمک برای از بین بردن بوی بد منزل نیز می توان استفاده کرد. برای این کار کافی است که ترکیبی از اب و نمک را در قسمت هایی از خانه که بوی نامطبوع از آن استشمام می شود، بخصوص یخچال قرار دهید. بدین ترتیب بوی بد آن قسمت از بین می رود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هم چنین از نمک برای ضدعفونی و قارچ زدایی <Link className="link" to="/laundry-room" target="_blank">  لاندری روم </Link> یا رختشویخانه هم استفاده می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                چند پیشنهاد شگفت انگیز برای درست کردن خوشبو کننده های طبیعی
              </Typography>
              <Typography className="h3" variant="h3">
                خوشبو کننده زنجبیلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تهیه این خوشبو کننده کافی است ترکیبات زیر را تهیه کنید. این مواد را با هم مخلوط کنید و بجوشایند و بعد از سرد شدن بعنوان یک خوشبو کننده ایده آل استفاده نمایید.
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                ریشه زنجبیل
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                یک قاشق غذاخوری عصاره ی بادام
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                3 عدد چوب دارچین
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                مقداری آب
              </Typography>
              <Typography className="h3" variant="h3">
                خوشبو کننده نعنا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این خوشبو کننده نیز رایحه ای بسیار مطبوع به خانه می دهد. بنابراین برای ساخت این خوشبو کننده بهتر است ترکیبات زیر را آماده نمایید.
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                یک قاشق چایخوری عصاره ی وانیل
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                یک قاشق چایخوری عصاره ی نعناع
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                دو عدد لیموترش سبز
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                کمی آب
              </Typography>
              <Typography className="h3" variant="h3">
                خوشبو کننده اسطوخودوس
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="خوشبو کننده اسطوخودوس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رایحه اسطوخودوس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این خوشبو کننده دارای رایحه ای گرم می باشد که عطری آرامش بخش دارد. استفاده از این رایحه برای <Link className="link" to="/where-to-buy-sleep-product" target="_blank">  اتاق خواب </Link> و قبل از استراحت بسیار پیشنهاد می شود. ترکیباتی که برای این خوشبو کننده نیاز است به شرح زیر می باشد.
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                دو قاشق غذا خوری عصاره ی وانیل
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                10 قطره روغن ضروری اسطوخدوس
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                5 قطره روغن ضروری بابونه
              </Typography>
              <Typography className="h3" variant="h3">
                اسپری با رایحه ی شکوفه
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                عصاره ی وانیل
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                8 قطره روغن اسطوخدوس
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                5 قطره روغن گرانیوم (شمعدانی)
              </Typography>
              <Typography className="p" componenet="li" sx={{ textAlign: 'justify' }}>
                4 قطره روغن گریپ فروت
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Successful-Women-Real-Estate" target="_blank">
                  بیشتر بخوانید : زنان مشاور املاک موفق
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همانطور که در این مقاله از همینجا ذکر شد روش های زیادی برای خوشبو کردن خانه وجود دارد که علاوه بر معطر شدن فضای خانه به صرفه نیز هستند. همچنین این ترکیبات از انجایی که فاقد مواد شیمیایی هستند برای سلامتی نیز خطری در پی ندارند. امیدواریم با مطالعه این مقاله، فضایی عطرآگین برای منزل خود ایجاد کنید و لذت ببرید
              </Typography>
              <Typography className="h4" variant="h4">
                هومینجا سامانه <Link className="link" to="/Best-Iranian-Real-Estate-Site" target="_blank">  تخصصی </Link> خرید و فروش، رهن و اجاره املاک و مستغلات
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("خانه و سبک زندگی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  خانه و سبک زندگی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article56;