import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import photo01 from "../../images/mag/article67/01.jpg";
import photo02 from "../../images/mag/article67/02.jpg";
import photo03 from "../../images/mag/article67/03.jpg";
import photo04 from "../../images/mag/article67/04.jpg";
import photo05 from "../../images/mag/article67/05.jpg";
import photo06 from "../../images/mag/article67/06.jpg";
import photo07 from "../../images/mag/article67/07.jpg";
import photo08 from "../../images/mag/article67/08.jpg";

import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "../../styles/article.css";
import {
  ArticleAd1,
  ArticleAd2,
  ArticleAd3,
  ArticleAd4,
} from "../mag/articleAd";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import { article as articlesData } from "../mag/articles";
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article67 extends React.Component {
  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false,
    };
  }

  componentDidMount() {
    document.getElementsByTagName("meta")["description"].content =
      "تمرینات کاردیو شامل تمریناتی می باشد که ضربان قلب شما را افزایش می دهد و باعث بهبود عملکرد شش ها و قلب و سیستم گردش خون می شود.";
    document.getElementsByTagName("meta")["keywords"].content =
      "حرکات کاردیو با تصویر | نمونه تمرینات کاردیو | تمرینات کاردیو برای شکم و پهلو | کاردیو بانوان | ورزش کاردیو چیست | انواع ورزشهای کاردیو | تفاوت ورزش کاردیو با هوازی | نمونه تمرینات کاردیو | تمرینات کاردیو در باشگاه | کاردیو در منزل | تفاوت کاردیو با بدنسازی";
    document.getElementsByTagName("meta")["author"].content =
      "شرکت وب گستران سورین";
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName("meta")["description"].content =
      "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML =
      articlesData[66].h1 + " | هومینجا ";
  };

  setTitle = () => {
    document.title = articlesData[66].title + " | هومینجا ";
  };

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  };

  openAuth = (type) => {
    switch (type) {
      case "L":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      case "R":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  };

  onCloseAuth = () => {
    this.setState({ openAuthModal: false });
  };

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  };

  render() {
    return (
      <Grid
        sx={{
          marginTop: { xs: "60px", md: "88px" },
          bgcolor: "#E7F0F3",
          padding: { xs: "5px", md: "15px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <SideBar access={articlesData[66].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              sx={{
                bgcolor: "white",
                borderRadius: "8px",
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                padding: { xs: "5px", md: "30px" },
              }}
            >
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[66].h1}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px 0 20px",
                }}
              >
                <Typography className="date_author">
                  نویسنده : {articlesData[66].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[66].date)}
                </Typography>
              </Grid>
              <Typography
                component={"img"}
                src={photo01}
                alt="کاردیو - هومینجا"
                className="image"
              />
              <Typography
                className="h2"
                variant="h2"
                style={{ display: "none" }}
              >
                {articlesData[66].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                انجام تمرینات کاردیو یکی از بهترین راه ها برای رسیدن به{" "}
                <Link
                  className="link"
                  to="/weight-loss-and-fitness"
                  target="_blank"
                >
                  {" "}
                  تناسب اندام{" "}
                </Link>
                ،کاهش وزن و ارتقای سطح سلامت شماست. در این مقاله از سایت{" "}
                <Link className="link" to="/" target="_blank">
                  {" "}
                  هومینجا{" "}
                </Link>{" "}
                شما را با تمرینات کاردیو، مزایای آن، تفاوت بدنسازی و کاردیو آشنا
                خواهیم کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                تمرینات کاردیو چیست:
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo02}
                  alt="تمرینات کاردیو - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  تمرینات کاردیو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                کاردیو به تمریناتی گفته می شود که ضربان قلب شما را بالا می برد و
                باعث بهبود عملکرد قلبی، شش ها، سیستم گردش خون، کمک به کاهش وزن و
                افزایش قدرت بدنی می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                کلمه کاردیو در فرهنگ لغت به معنی فعالیت بدنی است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                هر فعالیتی که ضربان قلب شما را تا ۵۰ الی ۷۵٪ افزایش دهد جز
                تمرینات کاردیو محسوب می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                چه ورزش هایی کاردیو می باشد:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همانطور که گفتیم تمرینات کاردیو حسابی باعث افزایش ضربان قلب شما
                می شود، برای این که متوجه شوید تمرین شما جز تمرینات کاردیو هست؛
                لازم نیست ضربان قلب خود را دنبال کنید. اگر نمی توانید حین تمرین
                به راحتی صحبت کنید، این به این معناست که تمرین شما در دسته
                تمرینات کاردیو قرار دارد.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/weight-loss-and-fitness"
                  target="_blank"
                >
                  بیشتر بخوانید : کاهش وزن اصولی و ترفندهای رسیدن به تناسب اندام
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این تمرینات شامل موارد زیر می باشد :
              </Typography>
              <Typography className="h3" variant="h3">
                تمرینات اینتروال :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تمرینات اینتروال به تمریناتی گفته می شود که بر پایه شدت تمرین
                است. در این نوع سبک از تمرین شما شدت ورزش خود را از شدت پایین به
                بالا و از بالا به پایین به شکل متناوب تغییر می دهید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                مثلا 3 دقیقه دوچرخه سواری با توانایی 60 درصدی و سپس بعد از آن 3
                دقیقه با توانایی 100 درصدی. بسیاری از تحقیق ها می گویند که
                تمرینات بدنی تناوبی با شدت بالا به افراد کمک می کند تا 9 برابر
                بیشتر از روش های دیگر چربی بسوزاند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong> نکاتی راجع به تمرینات اینتروال</strong>
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تمرینات اینتروال شدت بالایی دارد، پس یادتان باشد قبل از تمرین
                حتما بدن خود را گرم کنید. به دلیل شدت بالای تمرین افرادی که دچار
                مشکلات قلبی و عروقی هستند قبل از انجام تمرینات با پزشک خود مشورت
                کنند. بعد از اتمام تمرین حتما پروتئین و کربوهیدرات کافی به بدن
                خود برسانید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در تمرینات اینتروال پیشرفت مرحله به مرحله داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong>
                  چه کسانی نمی توانند تمرینات اینتروال را انجام دهند؟!
                </strong>
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                افرادی که دچار پوکی استخوان هستند.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                افرادی که دچار بیماری قلبی و عروقی هستند.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کودکان زیر 10 سال
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                زنان باردار
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                افرادی که آرتروز دارند.
              </Typography>
              <Typography className="h3" variant="h3">
                الپتیکال :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر جز افراد مبتدی هستید که به تازگی می خواهید ورزش کاردیو را
                تجربه کنید، برای شروع دستگاه الپتیکال بهترین گزینه برای شما می
                باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                الپتیکال جزتمرینات سبک محسوب می شود که کل عضلات بدن شما را درگیر
                می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                دویدن :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                دویدن سوخت و ساز بدن شما را بالا می برد و به شما کمک می کند تا{" "}
                <LinkMui
                  className="link"
                  href="https://fa.wikipedia.org/wiki/%DA%A9%D8%A7%D9%84%D8%B1%DB%8C#:~:text=%DA%A9%D8%A7%D9%84%D8%B1%DB%8C%20%D8%A8%D8%B2%D8%B1%DA%AF%20%DB%8C%D8%A7%20%DA%A9%DB%8C%D9%84%D9%88%DA%AF%D8%B1%D9%85%E2%80%8C%DA%A9%D8%A7%D9%84%D8%B1%DB%8C(%DA%A9%DB%8C%D9%84%D9%88%DA%A9%D8%A7%D9%84%D8%B1%DB%8C,%D8%A8%D8%A7%20%DB%B4%2F%DB%B2%20%DA%A9%DB%8C%D9%84%D9%88%DA%98%D9%88%D9%84%20%D9%85%DB%8C%E2%80%8C%D8%A8%D8%A7%D8%B4%D8%AF."
                  target="_blank"
                >
                  {" "}
                  کالری{" "}
                </LinkMui>{" "}
                زیادی بسوزانید. همچنین باعث کنترل سطح قند خون شما می شود .
              </Typography>
              <Typography className="h3" variant="h3">
                دوچرخه سواری:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                دوچرخه سواری یکی از بهترین تمرینات در کاردیو می باشد که علاوه بر
                تقویت عضلات پا و باسن سبب بالا رفتن ضربان قلب و چربی سوزی نیز می
                شود.
              </Typography>
              <Typography className="h3" variant="h3">
                شنا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                شنا کردن بر فشار خون، سطح{" "}
                <LinkMui
                  className="link"
                  href="https://kermany.com/high-cholesterol"
                  target="_blank"
                >
                  {" "}
                  کلسترول{" "}
                </LinkMui>
                ، توده عضلانی و سیستم اعصاب مرکزی موثر است. زمانی شنا کردن جز
                کاردیو محسوب می شود که ضربان قلب شما را بالا ببرد.
              </Typography>
              <Typography className="h3" variant="h3">
                زومبا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                زومبا ورزشی است که بر پایه رقص می باشد. این ورزش به کاهش وزن شما
                کمک بسیاری می کند. اگر به دنبال یک ورزش هیجان انگیز و گروهی
                هستید حتما در کلاس های زومبا شرکت کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                طناب زدن:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                طناب زدن جز کم هزینه ترین تمرینات کاردیو محسوب می شود و شما به
                راحتی می توانید این ورزش را در{" "}
                <Link className="link" to="/Home-Freshener" target="_blank">
                  {" "}
                  خانه{" "}
                </Link>{" "}
                انجام دهید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                خوشبختانه تمرینات کاردیو دارای تنوع بسیاری است ، که این یک حسن
                به حساب می آید زیرا شما می توانید نسبت به سلیقه خود و این که از
                چه نوع فعالیتی بیشتر لذت می برید، تمرینات را آغاز کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر جز افرادی هستید که دوست دارید داخل{" "}
                <LinkMui
                  className="link"
                  href="https://fitboxgym.ir"
                  target="_blank"
                >
                  {" "}
                  باشگاه{" "}
                </LinkMui>{" "}
                تمرین کنید، تردمیل، دوچرخه ثابت، شرکت در کلاس های زومبا برای شما
                مناسب است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همچنین اگر از آن دسته افرادی هستید که می خواهید در فضای باز
                تمرین داشته باشید؛ دویدن،{" "}
                <Link
                  className="link"
                  to="/tehran-winter-entertaiments"
                  target="_blank"
                >
                  {" "}
                  کوه نوردی{" "}
                </Link>{" "}
                و دوچرخه سواری گزینه های خوبی برای شماست.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همچنین شما می توانید تمرینات کاردیو را در خانه انجام دهید که
                شامل تمریناتی مثل طناب زدن درجا ، اسکات جامپ و …. می باشد.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/What-Is-Functional-Training"
                  target="_blank"
                >
                  بیشتر بخوانید : فانکشنال ترینینگ و بررسی کامل آن؛ معرفی بهترین
                  مربی خانم فانکشنال
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                انواع تمرینات کاردیو:
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo03}
                  alt="انواع تمرینات کاردیو - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  انواع تمرینات کاردیو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                شما باید تمرینات کاردیو را مرحله به مرحله انجام دهید تا به
                بدنتان اسیب نرسانید. تمرینات کاردیو ضربان قلب شما را ۵۰ الی ۷۵٪
                افزایش می دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر جز افراد مبتدی هستید و تازه می خواهید این نوع تمرینات را
                انجام دهید بهتر است از تمرینات کاردیو با شدت پایین شروع کنید و
                بعد از آن مرحله به مرحله تمرینات را پیچیده تر کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                حرکات کاردیو با شدت پایین:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                میزان ضربان قلب در تمرینات کاردیو با شدت پایین باید بین ۵۰ تا
                ۵۵٪ باشد
              </Typography>
              <Typography className="h3" variant="h3">
                حرکات کاردیو با شدت متوسط:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ضربان قلب (
                <LinkMui
                  className="link"
                  href="https://mhrglobal.com/en"
                  target="_blank"
                >
                  {" "}
                  mhr{" "}
                </LinkMui>
                ) شما در این نوع تمرینات باید بین ۶۰ تا ۷۰٪ باشد ، معمولا متداول
                ترین تمرینات کاردیو در این سطح قرار دارند.
              </Typography>
              <Typography className="h3" variant="h3">
                حرکات کاردیو با شدت بالا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تمرینات با حداکثر mhr به میزان ۷۰تا ۷۵٪ خواهد بود. در این نوع
                تمرین، شدت تمرین کاردیو به قدری بالاست که فرد قادر نخواهد بود به
                راحتی حرف بزند.
              </Typography>
              <Typography className="h2" variant="h2">
                کاردیو یا بدنسازی:
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo04}
                  alt="کاردیو یا بدنسازی - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  کاردیو یا بدنسازی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                مشکل بسیاری از افرادی که قصد کاهش وزن دارند انتخاب نوع ورزش است.
                همیشه سوالشان این است که سراغ کاردیو بروند یا بدنسازی؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                باید بگویم شما با هر جلسه تمرین کاردیو کالری سوزی بیشتری نسبت به
                تمرینات بدنسازی دارید. بگذارید با مثالی از یک ورزش کاردیو مثل
                دوچرخه سواری، میزان کالری سوزی را برای شما توضیح دهیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر فردی ۷۳ کیلوگرم وزن داشته باشد با ۳۰ دقیقه دوچرخه سواری ۲۵۰
                تا ۳۶۵ کالری خواهد سوزاند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از طرف دیگر اگر همین فرد به سراغ تمرینات بدنسازی برود با ۳۰
                دقیقه تمرین حدودا ۱۳۰ تا ۲۲۰ کالری خواهد سوزاند. حال متوجه کالری
                سوزی بین ورزش کاردیو و بدنسازی شدید؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                انجام ورزش کاردیودر ۳۰ دقیقه : ۲۵۰ تا ۳۶۵ کیلوکالری
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                انجام ورزش با وزنه :۱۳۰ تا ۲۲۰ کیلوکالری
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/Best-Board-Game-Cafe-In-Tehran"
                  target="_blank"
                >
                  بیشتر بخوانید : کافه بردگیم؛ معرفی بهترین کافه های بازی در
                  تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ایا بدنساز کارها هم باید تمرینات کاردیو انجام دهند؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                داشتن یک برنامه کاردیو در تمرین شما فواید بسیاری را به دنبال
                دارد .
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                کاردیو تاثیرات بسیار خوبی در سلامت قلب و عروق شما دارد؛ تاثیراتی
                که با انجام تمرینات قدرتی به دست نمی آید .
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تمرینات کاردیو عملکرد ورزشی بدنسازان را افزایش می دهد. همچنین به
                شما کمک می کند پس از یک جلسه کار با وزنه؛ ریکاوری بهتری داشته
                باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                به طور کلی اگر می خواهید عضله سازی داشته باشید و قدرتتان افزایش
                پیدا کند ، باید تمرینات کاردیو را بعد از تمرینات بدنسازی انجام
                دهید. این کار باعث می شود شما برای تمرینات وزنه برداری خسته
                نشوید و انرژی کافی را داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                انجام تمرینات کاردیو کمتر از ۲۰ دقیقه بعد از تمرینات قدرتی، آن
                هم یک تا دوبار در هفته به هیچ وجه مانع عضله سازی شما نمی شود.
              </Typography>
              <Typography className="h2" variant="h2">
                فواید ورزش کاردیو:
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo05}
                  alt="فواید کاردیو - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">فواید کاردیو</figcaption>
              </figure>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کاهش استرس
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کمک به کاهش وزن
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                افزایش میل جنسی
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کاهش خطر بیماری های قلبی
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کاهش ابتلا به بیماری های فشار خون و کلسترول
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                داشتن اعتماد به نفس بالا
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                افزایش تراکم استخوانی
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کاهش افسردگی
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                افزایش جریان خون در بدن
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              ></Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                کمک به داشتن خواب بهتر
              </Typography>
              <Typography className="h2" variant="h2">
                اشتباهات رایج در تمرینات کاردیو
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo06}
                  alt="تصورات غلط در مورد کاردیو - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  تصورات غلط در مورد کاردیو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اغلب اشتباهاتی در این ورزش بین ورزشکاران رخ می دهد که در این
                قسمت به بررسی آن ها می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                اشتباه اول:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                خیلی از افراد تصور می کنند کاردیو تنها شامل تمریناتی است که
                ضربان قلب را بالا ببرد. در صورتیکه تمامی فعالیت‌های فیزیکی اعم
                از جابه‌جایی وزنه‌ها، حمام رفتن و غلت زدن در رختخواب باعث افزایش
                ضربان قلب می‌شوند. این فعالیت ها برای اینکه در دسته کاردیو قرار
                گیرند باید تعداد تنفس و ضربان قلب را بالا ببرد و سیستم قلبی و
                عروقی شما را نیز درگیر کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                مثلا  پیاده روی و دوچرخه سواری با سرعت کم، کاردیو محسوب نمی شود.
                با اینکه روی سیستم قلبی و عروقی اثر می گذارد ولی قلب و شش‌ها را
                به‌‌ اندازه‌ای تحت فشار قرار نمی‌دهد که عملکرد خود را بهبود
                بخشند.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/best-restaurant-tehran-region1"
                  target="_blank"
                >
                  بیشتر بخوانید : بهترین رستوران های منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                اشتباه دوم:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اینکه افراد تصور می کنند که کاردیو و ورزش هوازی یکی هستند از
                دیگر اشتباهات تمرین کاردیو می باشد. ورزش هوازی یکی از انواع
                کاردیو به شمار می آید، اما تنها نوع آن نیست. طی تحقیقاتی که صورت
                گرفت تمرینات هوازی و غیرهوازی باعث بهبود عملکرد قلبی و عروقی می
                شوند بنابراین هر دو را می توان در دسته تمرینات کاردینو قرار داد.
              </Typography>
              <Typography className="h3" variant="h3">
                اشتباه سوم:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از آنجا که تمرینات قلبی و عروقی با ضربان قلب در ارتباط هستند،
                عده‌ای بر این باورند که درهنگام تمرین باید دستگاه ضربان‌سنج را
                همراه خود داشته باشند که این تصوری اشتباه می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                راه بهتری برای کنترل ضربان قلب درحین راه رفتن وجود دارد. اگر در
                حین تمرین قادر نیستید که به راحتی صحبت کنید، حتی گفتن جملات
                کوتاه، به این معناست که تمرینات شما در دسته‌ی کاردیو می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                اشتباه چهارم:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تنها دویدن و شنا کردن و دوچرخه سواری تمرینات کاردیو هستند که این
                مطلب درست نیست. در کاردیو نوع تمرین مهم نیست بلکه نحوه انجام
                دادن تمرین حائز اهمیت است. بنابراین رقصیدن یا برخی از فعالیت های
                خانه مانند جابجایی{" "}
                <Link className="link" to="/pouf" target="_blank">
                  {" "}
                  مبل{" "}
                </Link>{" "}
                و جمع آوری برگ{" "}
                <Link
                  className="link"
                  to="/keeping-apartment-flower"
                  target="_blank"
                >
                  {" "}
                  درختان{" "}
                </Link>{" "}
                نیز شامل تمرینات کاردیو می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین زمان انجام تمرینات کاردیو
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo07}
                  alt="بهترین زمان تمرینات کاردیو - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  بهترین زمان تمرینات کاردیو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                طبق نتایجی که از تحقیقات بعمل آمده در مجله‌ی فیزیولوژی منتشر شده
                است، انجام ۴-۵ بار تمرین کاردیو در هفته برای بهبود سلامت سیستم
                قلبی و عروقی و افزایش طول عمر مفید می باشد. این نکته لازم به ذکر
                است که نیازی نیست تمرینات کاردیو هم‌زمان با جلسه‌ی تمرینی باشگاه
                انجام شود. می‌توانید این حرکات را در برنامه‌ی روزانه‌ی خود جای
                دهید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای اینکه بهترین تمرین را در طول روز داشته باشید می توانید از
                فاکتور های زیر بهره ببرید.
              </Typography>
              <Typography className="h3" variant="h3">
                1. راحتی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر تصمیم بر این دارید که کاردیو را در برنامه روزانه خود
                بگنجانید بنابراین باید در انتخاب تمرینات کاردیو راحتی را مدنظر
                قرار دهید.  به‌عنوان مثال اگر به استخر یا دوچرخه دسترسی راحتی
                ندارید بهتر است تمرینات کاردیوی شنا و دوچرخه را از برنامه ی خود
                حذف کنید. همچنین بهتر است حرکاتی را در برنامه خود قرار دهید که
                در خانه هم بتوانید آنها را انجام دهید و نیاز به وسایل خاصی
                نداشته باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                2. اولویت
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                بهترین تمرینی که برای کاردیو انجام می دهید، تمرینی است که
                به‌صورت مداوم انجام می شود. بنابراین اگر علاقه ای به ورزش‌های
                مهیج ندارید، بهتر است بدنبال دوچرخه‌سواری کوهستان نباشید. یا اگر
                ورزش های آبی را دوست ندارید شنا را برای تمرینات کاردیو انتخاب
                نکنید. ورزشی را در برنامه تمرینی خود قرار دهید که از انجام دادن
                آن هیجان‌زده شوید. از آنجا که تمرینات کاردیو تنوع بالایی دارد
                بنابراین خودتان را محدودد به یک حرکت خاص نکنید.
              </Typography>
              <Typography className="h3" variant="h3">
                3. محدودیت های فیزیکی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                چنانچه دچار آسیب دیدگی مفصلی هستید یا زانو درد و کمر درد دارید
                یا احساس ناراحتی در مچ دست خود می کنید؛ باید از انجام ورزش‌های
                پرتحرک مانند دویدن و بسکتبال خودداری نمایید. به‌جای آن می‌توانید
                ورزش‌های کم‌تحرک همچون دوچرخه سواری، شنا یا حرکات قدرتی را
                جایگزین کنید. همچنین اگر تمایل به انجام ورزش کاردیو با قدرت کمتر
                هستید ورزش هایی مثل{" "}
                <LinkMui
                  className="link"
                  href="https://en.wikipedia.org/wiki/PiYo"
                  target="_blank"
                >
                  {" "}
                  PiYo{" "}
                </LinkMui>{" "}
                (ترکیب پیلاتس و یوگا) را انتخاب کنید تا ضربان قلب‌تان بالا برود
                و بدون واردن شدن فشار بر روی مفاصل، به‌ خوبی عرق کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : کافه کتاب و معرفی بهترین کافی شاپ های کتاب در
                  تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بهترین مربی کاردیو :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo08}
                  alt="مربی کاردیو - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">مربی کاردیو</figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تا به اینجا آموختیم که تمرینات کاردیو یا هوازی برای قلب بسیار
                مفید است و به کاهش وزن و چربی سوزی کمک فراوانی می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                توجه به اینکه بسیار ضروریست که{" "}
                <LinkMui
                  className="link"
                  href="https://www.instagram.com/p/CrnVaUFLe3M/?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  {" "}
                  مربی{" "}
                </LinkMui>{" "}
                تاثیر بسیار زیادی در نتیجه نهایی شما دارد و میتواند تغییرات
                بزرگی در بدن شما ایجاد کند. به دلیل شدت و سختی بالای کاردیو یا
                هوازی، مربی ورزش های کاردیو باید در نوبه خود بهترین باشد و از
                دانش تخصصی بالایی بهر مند باشد تا از آسیب دیدگی جلوگیری کند و
                شما را به نتیجه دلخواه برساند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تیم ورزشی نیلافیت{" "}
                <LinkMui
                  className="link"
                  href="https://instagram.com/nilla__fit?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  {" "}
                  nilla__fit{" "}
                </LinkMui>{" "}
                به سرپرستی سرکار خانوم نیایش تاجی آمادگی کامل و دانش نخصصی لازم
                را برای ارائه برنامه ورزشی و بهبود تناسب اندام شما را دارد. برای
                دریافت برنامه کاردیو زیر نظر بهترین مربی کاردیو با شماره
                09335441075 تماس بگیرید یا به ایدی اینستاگرام نیلا فیت مراجعه
                کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                سخن پایانی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای رسیدن به هدفی که در ورزش دارید باید یک سری نکات را رعایت
                نمایید. مثلا: شما برای این که جلوی آسیب را بگیرید قبل از انجام
                حرکات کاردیو باید اصل گرم کردن را رعایت کنید. این کار جلوی اسیب
                دیدگی را در هنگام ورزش می گیرد. همچنین حتما بعد از اتمام ورزش
                حرکات کششی انجام دهید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سرد کردن و انجام حرکات کششی بعد از ورزش باعث می شود عضلات شما جا
                برای رشد کردن داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سعی کنید در انجام تمرینات مرحله به مرحله پیشرفت داشته باشید و در
                نهایت برای مشاهده نتایج صبور باشید. یادتان باشد خود را با هیچ
                فرد دیگری مقایسه نکنید زیرا ویژگی های بدنی هر فرد متفاوت است .
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                تیم ما با برنامه ریزی دقیق و اصولی ورزشی شما را به هدفتان نزدیک
                تر خواهد کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                جهت ارسال مقالات ورزشی خود و انتشار با نام شما، باما در{" "}
                <Link className="link" to="/Contact" target="_blank">
                  {" "}
                  ارتباط{" "}
                </Link>{" "}
                باشید.
              </Typography>
              <Typography className="h4" variant="h4">
                شرکت وب گستران{" "}
                <LinkMui
                  className="link"
                  href="https://sorinapp.com"
                  target="_blank"
                >
                  {" "}
                  سورین{" "}
                </LinkMui>{" "}
                حامی ورزشکاران ایران زمین
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#4B4B4B",
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                >
                  برچسب ها :
                </Typography>
                <Button
                  component={Link}
                  to={"/HomeMag"}
                  onClick={() => this.onClickTag("دانستنی ها ")}
                  sx={{
                    color: "#4B4B4B",
                    bgcolor: "#E7F0F3",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#E7F0F3" },
                  }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography
                  sx={{
                    fontFamily: "shabnam",
                    color: "#C5CCCE",
                    textAlign: "center",
                  }}
                >
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui
                    href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <WhatsAppIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TwitterIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TelegramIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem("infoUserHomeInja") ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سپردن ملک
                      </Typography>
                      <AddBusinessRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سفارش ملک
                      </Typography>
                      <HouseRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("L")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ورود
                      </Typography>
                      <PersonRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("R")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ثبت نام
                      </Typography>
                      <PersonAddAlt1RoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal
          open={this.state.openAuthModal}
          close={() => this.onCloseAuth()}
          type={this.state.modalType}
        />
      </Grid>
    );
  }
}

export default Article67;
