import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { baseUrl, separator } from "./materilas/vmp";
import axios from "axios";
import bi1 from '../images/inventory1.png';
import bi2 from '../images/inventory2.png';
import AlertModal from "./materilas/alert";


class InventoryUser extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        total:0,
        gift:0,
        till:0,
        rial:0,
        openAlert: false ,
        message:"" ,
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'موجودی در پلتفرم تخصصی املاک هومینجا'
    if (this.isMount){
      this.getInventory();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  getInventory = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/inventory',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      let inventory = res.data.data;
      this.setState({total:inventory.total,rial:inventory.rial,gift:inventory.gift,till:inventory.totalGift})
    })
    .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  createCard = (color,img,title,inventory) =>{
    return(
      <Grid item lg={6} xs={12} style={{display:'flex',flexDirection:'row',padding:'2%',justifyContent:'center'}}>
        <Grid style={{display:'flex',flexDirection:'column',borderRadius:32,border:'1px solid #35C5F2',backgroundColor:color,
                      boxShadow:'0px 1px 4px rgba(0, 200, 233, 0.14)',boxSizing:'border-box',alignItems:'center'}}
        >
          <Typography component={'img'} src={img} style={{width:'100%',marginTop:25}} alt="img_card"></Typography>
          <Typography component={'p'} style={{fontFamily:'shabnam',fontWeight:'700',marginTop:-20,width:'85%'}}
                      sx={{fontSize:{lg:24,md:28,sm:24,xs:16},marginBottom:{xs:'10px'}}}
          >{title}</Typography>
          <Typography component={'p'} style={{fontFamily:'shabnam',fontWeight:'700',textAlign:'center',width:'85%'}}
                      sx={{fontSize:{sm:32,xs:16},marginTop:{lg:'20px'},marginBottom:{xs:'30px'}}}
          >
            {separator(inventory) + ' ریال'} 
          </Typography>
        </Grid>
        
      </Grid>  
    )
  }

  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'0px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>موجودی</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',marginTop:50}}>
          <Button sx={{width:{lg:'40%',md:'50%',xs:'60%'},height:{md:'60px',xs:'50px'},fontSize:{lg:24,md:20,xs:16},fontWeight:{xs:700}}} 
                  style={{backgroundColor:'#00C8E9',borderRadius:10,color:'black',fontFamily:'shabnam'}}>
            {'افزایش موجودی + '}
          </Button>
        </Grid>
        <Grid container style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',marginTop:50}}
              sx={{paddingRight:'10%',paddingLeft:'10%'}}
        >
          {this.createCard('white',bi1,'اعتبار هدیه : ',this.state.gift)}
          {this.createCard('white',bi1,'کل اعتبار هدیه پرداخت شده : ',this.state.till)}
          {this.createCard('white',bi1,'موجودی ریالی : ',this.state.rial)}
          {this.createCard('#35C5F2',bi2,'کل موجودی (هدیه و ریالی) : ',this.state.total)}                 
        </Grid>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    )
  };
}

export default InventoryUser;