import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article09/01.jpg";
import photo02 from "../../images/mag/article09/02.jpg";
import photo03 from "../../images/mag/article09/03.jpg";
import photo04 from "../../images/mag/article09/04.jpg";
import photo05 from "../../images/mag/article09/05.jpg";
import photo06 from "../../images/mag/article09/06.jpg";
import photo07 from "../../images/mag/article09/07.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";



class Article09 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "لوستر بعنوان یکی از تجهیزات نورپردازی ابهت و جذابیتی بینظیر به دکوراسیون داخلی خانه می دهد. لوسترها در سبک های مختلف طراحی می شوند";
    document.getElementsByTagName('meta')["keywords"].content = "اهمیت لوستر در دکوراسیون داخلی |انواع لوستر در دکوراسیون داخلی | نکات انتخاب لوستر"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[8].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[8].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[8].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[8].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[8].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[8].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="انتخاب لوستر مناسب در دکوراسیون داخلی منزل - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[8].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لوستر بعنوان یکی از تجهیزات نورپردازی ابهت و جذابیتی بینظیر به دکوراسیون داخلی خانه می دهد. در هر فضایی از منزل می توان از
                لوستر استفاده کرد. نصب لوستر در ورودی، اتاق خواب، پذیرایی و آشپزخانه حال و هوای خاصی به فضای موردنظرتان می دهد. از آنجا که لوسترها با سبک ها
                و مدل های متنوعی در بازار موجودند بنابراین ممکن است انتخاب لوستر مناسب فضای موردنظر برای شما کاری دشوار باشد. در این <Link className="link" to="/chandelier-in-decoration" target="_blank"> مطلب </Link> از <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link> ما قصد
                داریم در مورد لوستر و تاثیر آن در دکوراسیون منزل بپردازیم.پس با هومینجا بلاگ همراه باشید تا بهترین انتخاب لوستر را برای منزل خود داشته باشید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/children-room-decoration" target="_blank">
                  بیشتر بخوانید : نکات جالب در مورد دکوراسیون اتاق کودک
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاریخچه لوستر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                استفاده از لوستر مربوط به 8 قرن پیش می شود. کشورهای
                اروپایی اولین کشورهایی بودند که از لوستر به منظور روشنایی استفاده می کردند. در آن دوره تنها قشر مرفه و ثروتمند جامعه از لوستر استفاده می کردند.
                اولین لوسترها چوبی بودند و به جای لامپ از شمع جهت روشنایی استفاده می کردند. در آن زمان از لوستر جهت نشان دادن ابهت و شکوه یک فضا استفاده می شد.
              </Typography>
              <Typography className="h2" variant="h2">
                نکات مهم در انتخاب لوستر :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="لوستر مناسب - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انتخاب لوستر مناسب
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این بخش به نکات ضروری که در هنگام خرید لوستر باید به آن توجه کنیم را به تفضیل توضیح داده ایم:
              </Typography>
              <Typography className="h3" variant="h3">
                اهمیت دکوراسیون داخلی منزل در انتخاب لوستر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لوسترها در سبک های مختلف مدرن، نئوکلاسیک و کلاسیک طراحی می شوند. بنابراین برای انتخاب بهترین گزینه باید بدانید که طراحی داخلی منزلتان به چه سبک است. جهت ایده گرفتن برای انتخاب پروسه انتخاب می توانید از مجلات طراحی داخلی استفاده کنید. هم چنین خواندن مقاله <Link className="link" to="/fengshui" target="_blank"> فنگ شویی </Link> می تواند به تصمیم گیری شما کمک شایانی بدهد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/keeping-apartment-flower" target="_blank">
                  بیشتر بخوانید : نگهداری گیاهان آپارتمانی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                تاثیر تناسب فضای داخلی منزل در انتخاب لوستر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وسعت فضایی که لوستر در آن نصب می شود بسایر حائز اهمیت می باشد. در فضایی با ابعاد وسیع، انتخاب
                لوستر بزرگ تناسب بیشتری با فضا ایجاد می کند. در <Link className="link" to="/small-house-decoration" target="_blank"> فضای کوچکتر </Link> بهتر است از چراغ آویز بجای لوستر استفاده کرد. همچنین استفاده از لوسترهای کوچک
                در فضای مناسب بزرگ اصلا انتخاب مناسبی نیست چون نور مورد نیاز شما را تامین نمی کند. همچنین برای فضاهای با متراژ بالاتر بهتر است از لوسترهایی با
                آویز کریستال استفاده کنید. چنین لوسترهایی بدلیل بکارگیری کریستال در ساخت آن به عنوان منشا نور عمل می کنند و روشنایی کافی را به ارمغان می آورند.
                <br />
                برای انتخاب لوستر متناسب با فضا می توانید از یک قاعده ساده استفاده کنید. طول و عرض اتاق را در واحد فوت با هم جمع کنید(هر فوت معادل 30 سانتی متر است)
                و حاصل را به واحد اینچ تبدیل کنید
                .این عدد در واحد اینچ با قطر لوستر تناسب دارد. چنانچه در نظر دارید لوستری را بالای میز ناهارخوری نصب کنید باید توجه کنید که قطر لوستر باید حداقل 30cm
                از عرض میز کمتر باشد و حداقل 120cm از دیوارهای میز فاصله داشته باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                ارتفاع لوستر و ارتباط آن با دکوراسیون داخلی منزل
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="ارتفاع لوستر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ارتباط ارتفاع لوستر با دکوراسیون
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ارتفاع و نحوه نصب لوستر یکی دیگر از موارد مهمی است که باید به آن توجه کرد. فاصله لوستر از سطح زمین،ارتفاع لوستر از
                سقف
                و ارتفاع لوستر از گوشه های خانه یا میز موضوعی است که باید آن را مورد بررسی قرار داد. لوستر باید طوری نصب شود که پایین ترین نقطه آن در ارتفاع 120cm
                از کف فضا باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                انتخاب لوستر بر اساس اکسسوری های موجود
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باید سعی بر این داشته باشید که لوستر منتخب شما با سبک و رنگ فضا و متریال
                های بکار رفته در دکوراسیون داخلی منزل تناسب داشته باشد. همچنین در نظر داشته باشید که بین لوستر و دیوارکوب ها و سایر منابع روشنایی سازگاری
                ایجاد کنید. همچنین بهتر است که جنس لوستری که انتخاب می کنید با جنس اجزای داخلی خانه مانند پنجره و دیوار و <Link className="link" to="/pouf" target="_blank"> مبلمان </Link> نیز هماهنگی داشته باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                تعداد لامپ های لوستر و تاثیر آن در فضای داخلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قبل از انتخاب لوستر ابتدا میزان روشنایی موردنظرتان را چک کنید. به این نکته توجه داشته باشید که لامپ ها منبع روشنایی لوسترها هستند. هر چه تعداد لامپ ها بیشتر باشد روشنایی بیشتری به فضا می دهند. اگر میزان روشنایی
                مورد نظر شما نسبت به فضا بیشتر باشد شما مجبورید تعدادی از این لامپ ها را خاموش نگه دارید. با توجه به این موضوع از زیبایی لوستر کاسته می شود.
                نکته مهم دیگری که باید به آن توجه داشت هر چه لوستر شما شاخه و چراغ های بیشتری داشته باشد بزرگتر خواهد بود و فضای شما را کمی دلگیر می کند.
              </Typography>
              <Typography className="h2" variant="h2">
                تمیز کردن لوستر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="تمیز کردن لوستر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نکات تمیزی لوستر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لوسترها گرد وغبار را سریع به خود جذب
                می کنند. اگر لوستر شما دارای طرحی پیچیده باشد بهتر است که هر ماه لوستر را تمیز کنید. با این کار طول عمر لوستر نیز افزایش می یابد. اما
                چنانچه دنبال گزینه ای هستید که کمتر نیاز به نظافت و تمیزی داشته باشد بهتر است طرح های ساده را برای انتخاب مدنظر قرار دهید. همچنین بهتر
                است موقع خرید از فروشنده درباره نحوه ی تمیزی لوستر مشورت کنید. برخی از محلول های پاک کننده ممکن است باعث آسیب رساندن به لوستر شما شوند. ضمنا در هنگام <Link className="link" to="/moving-the-house" target="_blank"> اثاث کشی </Link> باید توجه ویژه ای به بسته بندی لوستر داشته باشیم.
              </Typography>
              <Typography className="h3" variant="h3">
                استفاده از لوسترهای خاص و جدید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از بکار بردن لوسترهای عجیب و خاص ترسی نداشته باشید. چنانچه سبک معماری داخلی شما مدرن
                و کلاسیک می باشد می توانید هر مدل لوستری را انتخاب کنید. همچنین اگر روحیات شما با ابزار و اکسسوری های خاص سازگار می باشد از لوسترهای متفاوت استفاده کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-interesting-things" target="_blank">
                  بیشتر بخوانید :دانستنی های جالب املاک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                جنس و متریال لوستر انتخابی:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="جنس لوستر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انتخاب جنس و متریال لوستر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از متریال های مختلفی برای ساخت یک لوستر استفاده می شود
                . این مصالح شامل فلزاتی چون آلومینیوم، مس، روی، نقره و طلا می باشد. از دیگر مواردی که در ساخت لوستر می توان به آن اشاره کرد چوب، پلی استر ، پلاستیک،
                طلق یا شیشه است. تمام این موارد ذکر شده می تواند به تنهایی یا به صورت ترکیبی استفاده شود. اما موضوع مهمی که در انتخاب لوستر باید به آن دقت کافی داشت
                این است که کاربرد شما از لوستر بابت چه موضوعی است؟ چنانچه  هدف شما از انتخاب لوستر،روشنایی زیاد و دوام بالاست بهتر است از لوسترهای فلزی استفاده کنید.
                لوسترهای شیشه ای نیز نور زیادی برای شما بهمراه دارند. اما چنانچه شما به نور کم در محیط عادت دارید بهتر است از لوسترهایی با جنس چوب یا طلق استفاده کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                انتخاب رنگ لوستر متناسب با رنگ دکوراسیون
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="article photo" className="image" />
                <figcaption className="image_caption">
                  رنگ مناسب لوستر با دکوراسیون
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رنگ لوسترهای اصلی نباید از یک طیف رنگی پررنگ تر باشد. دو رنگ اصلی و استاندارد روشنایی خانه رنگ سفید
                و زرد یا همان مهتابی و آفتابی می باشد. به این نکته توجه داشته باشید که بهتر است لوسترهایی را انتخاب کنید که رنگ خنثی داشته باشند. لوسترهای
                رنگی موجب متفاوت شدن رنگ نورپردازی لامپ هایی  می شوند که در آن قرار می دهید. لوسترهای با شیشه مات برای افرادی مناسب است که عاشق نور کم هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                بایدها و نبایدهای استفاده از لوستر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="استفاده از لوستر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  استفاده مناسب از لوستر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در صورتیکه دکوراسیون داخلی خانه شلوغ است نباید از لوسترهای بزرگ با جزئیات زیاد و
                براق استفاده کرد. اگر دکوراسیون منزل شما ساده است می توانید از لوسترهای آویزدار و براق استفاده کنید. چنانچه سبک مدرن دوست دارید استفاده از لوسترهای
                ریموت دار <Link className="link" to="/smart-home" target="_blank"> هوشمند </Link> بسیار مناسب است. در صورتیکه فضای کلاسیک یا سلطنتی دارید استفاده از چنین لوسترهایی مناسب نیست. استفاده از لوسترهایی که اطراف لامپ را می گیرند
                به تنهایی مناسب فضای پذیرایی نیستند چون روشنایی اندکی دارند. در صورت استفاده از چنین لوسترهایی بهتر است از لوسترهای دیواری هم در کنار آن استفاده کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Midex" target="_blank">
                  بیشتر بخوانید :نمایشگاه میدکس چیست؟
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("خانه و سبک زندگی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  خانه و سبک زندگی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/chandelier-in-decoration" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/chandelier-in-decoration" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/chandelier-in-decoration" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article09;