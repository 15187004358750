import { Button, Grid, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import backgroundImage from "../../images/tehranZones/zone01/zone01.jpg";
import ShowCityLocation from "../materilas/showCityLocation";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link } from "react-router-dom";
import { setCanonicalTag } from "../materilas/vmp";
import articleImage27 from "../../images/mag/article27/01.jpg";
import articleImage22 from "../../images/mag/article22/01.jpg";
import articleImage19 from "../../images/mag/article19/01.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/tehranZones.css"


class Zone11 extends React.Component {

  prevPositionY = 0;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      openLinks: false,
    }
  }

  componentDidMount() {
    document.title = "منطقه ۱۱ تهران - نقشه - پارک - مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا"
    document.getElementById("main-heading").innerHTML = "منطقه 11 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه یازده";
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه منطقه 11 شهرداری تهران هستید. در این صفحه از سایت املاک هومینجا اطلاعاتی نظیر : محله ها و خیابان های منطقه یازده، قیمت ملک و آپارتمان و هر آنچه در مورد منطقه یازده تهران نیاز دارید، رایگان در اختیار شما می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی کامل منطقه یازده شهر تهران | بررسی منطقه 11 تهران | نقشه منطقه 11 تهران بزرگ | امکانات منطقه 11 | منطقه 11 کجاست | محدوده منطقه یازده شهرداری | خیابان های منطقه یازده | قیمت ملک در منطقه یازده | خرید آپارتمان در منطقه 11 | رهن و اجاره آپارتمان در منطقه 11 | فروش آپارتمان منطقه 11 | صفر تا صد منطقه یازده | نقشه ناحیه های منطقه 11 | بهترین محله های منطقه 11 | قیمت خانه کلنگی منطقه 11 | شهرداری منطقه 11 | راهنمای منطقه 11 تهران | قیمت خانه ویلایی منطقه 11 | آگهی املاک منطقه 11 | جستجوی ملک در منطقه 11 | سرمایه گذاری در املاک منطقه یازده";
    setCanonicalTag();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkPos);
  }

  onClickMenu = (event) => {
    event.preventDefault();
    let toggle = !this.state.openMenu;
    this.setState({ openMenu: toggle, anchorEl: event.currentTarget });
    this.closeMenuScroll();
  }

  onCloseMenu = () => {
    this.setState({ openMenu: false });
    window.removeEventListener("scroll", this.checkPos);
    this.prevPositionY = 0;
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  closeMenuScroll = () => {
    this.prevPositionY = window.scrollY;
    window.addEventListener("scroll", this.checkPos);
  }

  checkPos = () => {
    let currentPos = window.screenY;
    if (currentPos > this.prevPositionY || currentPos < this.prevPositionY) {
      this.onCloseMenu()
    }
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid sx={{ width: "100%", height: { xs: "200px", md: "400px" }, background: `url(${backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <Grid sx={{
            maxWidth: "1200px", margin: "auto", display: "flex", justifyContent: { xs: "center", md: "flex-start" }, alignItems: "end", height: "100%",
            padding: { xs: "10px 10px 2% 10px", lg: "0 0 2% 0" }
          }}>
            <Grid sx={{ bgcolor: "#00000075", padding: "10px 80px", borderRadius: "8px" }}>
              <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "25px", fontWeight: "bold" }}>
                منطقه ۱۱
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: "1200px", margin: "auto", padding: { xs: "10px", lg: "0" } }}>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h2">
            منطقه ۱۱ تهران
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            منطقه ۱۱ شهرداری تهران به دلیل وجود مراکز مهمی
            هم چون <a href="https://farsi.khamenei.ir/" className="zone_text_link" target="_blank" rel="noreferrer">بیت رهبری</a>، نهاد
            ریاست <a href="https://dolat.ir/" className="zone_text_link" target="_blank" rel="noreferrer">جمهوری</a>
            ، <a href="https://www.shora-gc.ir/" className="zone_text_link" target="_blank" rel="noreferrer">شورای نگهبان</a>
            ، <a href="https://www.president.ir/" className="zone_text_link" target="_blank" rel="noreferrer">شورای عالی امنیت ملی</a> و <a href="https://shekayat.bazresi.ir/" className="zone_text_link" target="_blank" rel="noreferrer">سازمان بازرسی کل کشور</a>
            ، به عنوان سیاسی ترین منطقه شهر تهران محسوب می شود.
            از دیگر ویژگی های منطقه ۱۱ شهر تهران وجود بافت های فرسوده
            در محله هایی همچون <Link to="/Listings/buy_tehran_moniriyeh" className="zone_text_link" target="_blank">منیریه</Link>
            ، <Link to="/Listings/buy_tehran_maidaneghazvin" className="zone_text_link" target="_blank">دروازه قزوین</Link> و <Link to="/Listings/rent_tehran_shaikhhadi" className="zone_text_link" target="_blank">خیابان شیخ هادی</Link> می باشد.
            <br />
            محدوده ی جغرافیای آن از شمال با <Link to={"/Listings/buy_tehran_maidaneenghlabeslami"} className="zone_text_link" target="_blank">میدان انقلاب</Link>
            ، <Link to={"/Listings/buy_tehran_azadi"} className="zone_text_link" target="_blank">خیابان آزادی</Link> و <Link to={"/tehran-zone-6"} className="zone_text_link" target="_blank">منطقه ۶</Link>، از
            شرق به خیابان <Link to={"/Listings/buy_tehran_vahdateslami"} className="zone_text_link" target="_blank">وحدت اسلامی</Link>، حافظ و <Link to={"/tehran-zone-12"} className="zone_text_link" target="_blank">منطقه ۱۲</Link>همجوار است. همچنین از
            غرب به خیابان <Link to={"/Listings/buy_tehran_navab"} className="zone_text_link" target="_blank">نواب صفوی</Link>
            ، میدان حق شناس و <Link to={"/tehran-zone-10"} className="zone_text_link" target="_blank">منطقه ۱۰</Link> و از
            جنوب به میدان <Link to={"/Listings/buy_tehran_rahahan"} className="zone_text_link" target="_blank">راه آهن</Link>
            ، خیابان <Link to={"/Listings/buy_tehran_shush"} className="zone_text_link" target="_blank">شوش</Link> و <Link to={"/tehran-zone-16"} className="zone_text_link" target="_blank">منطقه ۱۶</Link> منتهی می شود.
            <br />
            جمعیت ۲۸۸ هزار نفری در مساخت ۱۲ کیلومتر مربع در حال زندگی هستند.
          </Typography>
          <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h3">
              منطقه یازده تهران برروی نقشه
            </Typography>
            <Grid sx={{ width: "100%", height: "400px", borderRadius: "15px", overflow: "hidden", display: { xs: "none", md: "block" } }}>
              <ShowCityLocation
                positions={
                  [
                    [35.700522720414256, 51.37799263000489],
                    [35.65945812779679, 51.382112503051765],
                    [35.65938839065544, 51.38511657714844],
                    [35.66217782880548, 51.387863159179695],
                    [35.661619948971016, 51.38880729675294],
                    [35.66252650172269, 51.392240524292],
                    [35.65973707575313, 51.39352798461915],
                    [35.65855154020906, 51.4046859741211],
                    [35.70003481030259, 51.4127540588379],
                    [35.70149853168077, 51.41249656677246],
                  ]
                }
                position={
                  [35.680545, 51.394618]
                }
                zoom={13}
              />
            </Grid>
            <Grid sx={{ width: "100%", height: "300px", borderRadius: "15px", overflow: "hidden", display: { xs: "block", md: "none" }, margin: "auto" }}>
              <ShowCityLocation
                positions={
                  [
                    [35.700522720414256, 51.37799263000489],
                    [35.65945812779679, 51.382112503051765],
                    [35.65938839065544, 51.38511657714844],
                    [35.66217782880548, 51.387863159179695],
                    [35.661619948971016, 51.38880729675294],
                    [35.66252650172269, 51.392240524292],
                    [35.65973707575313, 51.39352798461915],
                    [35.65855154020906, 51.4046859741211],
                    [35.70003481030259, 51.4127540588379],
                    [35.70149853168077, 51.41249656677246],
                  ]
                }
                position={
                  [35.680545, 51.394618]
                }
                zoom={12}
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            پارک‌های منطقه ۱۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            پارک رازی ، بوستان افق ، باغ پادگان حر ، بوستان رازی ، بوستان شهرداری ، اوستا.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز خرید منطقه ۱۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            مرکز خرید فجر ، مجتمع تجاری مهرسان ، معین مال ، بازار جدید جمالزاده ، مرکز خرید جمهوری.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز درمانی منطقه ۱۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            درمانگاه خیریه شیخ مطهری
            ، <a className="zone_text_link" href="https://bankpezeshkan.com/profile/%D8%A2%D8%B2%D9%85%D8%A7%DB%8C%D8%B4%DA%AF%D8%A7%D9%87-%D9%BE%D8%A7%D8%B3%DA%A9%D8%A7%D9%84-%D8%B4%D9%87%D8%B1-%D9%85%D8%B4%D9%87%D8%AF/" target="_blank" rel="noreferrer">آزمایشگاه تشخیص طبی پاسکال</a> ،
            آزمایشگاه رایت.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            سینما‌های منطقه ۱۱ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            سینما پردیس ، شهر قشنگ ، فردوسی.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            محله های منطقه 11 تهران:
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            حسن آباد ، ابوسعید ، پاستور ، وحدت اسلامی ، انبار نفت.
          </Typography>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={(event) => { this.onClickMenu(event) }}
              sx={{ margin: "20px 0", border: "2px solid #d7d7d7", padding: "10px 40px", boxShadow: "0 2px 2px 0 #ebebeb", borderRadius: "8px" }}
            >
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "black" }}>
                سایر مناطق تهران
              </Typography>
              <KeyboardArrowDownRoundedIcon sx={{ color: "gray !important" }} />
            </Button>
            <Menu
              open={this.state.openMenu}
              onClose={this.onCloseMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ "& .MuiPaper-root": { maxHeight: "300px", overflow: "scroll-y" } }}
            >
              <MenuItem>
                <Link to={'/tehran-zone-1'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-2'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-3'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-4'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-5'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-6'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-7'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-8'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-9'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-10'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-11'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu} sx={{ padding: "8px 40px" }}> منطقه ۱۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-12'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-13'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-14'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-15'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-16'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-17'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-18'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-19'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-20'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-21'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-22'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۲</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 0 0" }}>
              مقالات مرتبط
            </Typography>
            <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Link to="/building-certificate" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage22})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        همه چیز راجع به صدور پروانه ساختمان
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/professional-real-estate" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage27})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        چطور یک مشاور املاک حرفه ای شویم؟
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/Azadi" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage19})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        محله آزادی
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
          <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
            <Link to={'/Listings/buy_tehran_abusaeed'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ابوسعید
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_abusaeed'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ابوسعید
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_orumieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ارومیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_orumieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ارومیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_eskandaridah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اسکندری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_eskandaridah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اسکندری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_amirbahador'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در امیربهادر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_amirbahador'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در امیربهادر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{
            bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "2250px" : "0px", md: this.state.openLinks ? "1300px" : "0px" }
            , transition: "max-height 0.6s linear", overflow: "hidden"
          }}
          >
            <Link to={'/Listings/buy_tehran_amiriye'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در امیریه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_amiriye'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در امیریه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_anbarnaft'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در انبار نفت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_anbarnaft'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در انبار نفت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_enghelabdah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در انقلاب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_enghelabdah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در انقلاب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_agahi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آگاهی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_agahi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آگاهی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_pastor'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در پاستور
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_pastor'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در پاستور
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_jamalzadeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در جمال زاده
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_jamalzadeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در جمال زاده
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_jomhuri'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در جمهوری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_jomhuri'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در جمهوری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_javadiye'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در جوادیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_javadiye'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در جوادیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_hor'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در حر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_hor'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در حر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_hasanabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در حسن آباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_hasanabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در حسن آباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_heshmatoldoleh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در حشمت الدوله
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_heshmatoldoleh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در حشمت الدوله
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_dokhaniyat'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دخانیات
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_dokhaniyat'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دخانیات
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_rahahan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در راه آهن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_rahahan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در راه آهن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shapur'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شاپور
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shapur'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شاپور
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shush'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شوش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shush'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شوش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shaikhhadi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شیخ هادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shaikhhadi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شیخ هادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_abasi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در عباسی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_abasi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در عباسی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_fruzesh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در فروزش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_fruzesh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در فروزش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_farhang'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در فرهنگ
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_farhang'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در فرهنگ
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ghalamestan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در قلمستان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ghalamestan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در قلمستان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_karegarjonubi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کارگر جنوبی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_karegarjonubi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کارگر جنوبی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_kashan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کاشان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_kashan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کاشان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_gomrok'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در گمرک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_gomrok'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در گمرک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_makhsus'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در مخصوص
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_makhsus'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در مخصوص
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_moniriyeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در منیریه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_moniriyeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در منیریه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_maidaneghazvin'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در میدان قزوین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_maidaneghazvin'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در میدان قزوین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_vahdateslami'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در وحدت اسلامی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_vahdateslami'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در وحدت اسلامی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_valiasrdah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ولیعصر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_valiasrdah'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ولیعصر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_helaleahmar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در هلال احمر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_helaleahmar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در هلال احمر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              ما در این صفحه به معرفی  منطقه 11 تهران – نقشه – پارک – مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا می پردازیم.
            </Typography>
            <Typography>
            همچنین منطقه 11 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه یازده را مورد بررسی قرار می دهیم.
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Zone11;