import { Typography } from "@mui/material";
import React from "react";
import {convertToPersianNumber} from './vmp'

class HomeinjaTypo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
    };
  }

  componentDidMount() {
    if (this.props.price) {
      if (Number.isInteger(this.props.price)) {
        this.setState({
          text:convertToPersianNumber(this.props.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) + " تومان "
        });
      } else {
        this.setState({
          text: this.props.price.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " تومان ",
        });
      }
    } else {
      this.setState({ text: this.props.text });
    }
  }

  render() {
    return (
      <Typography
        style={this.props.style}
        variant={this.props.variant}
        component={this.props.component}
        sx={{...this.props.sx, fontFamily: "shabnam"}}
      >
        {this.state.text}
      </Typography>
    );
  }
}

export default HomeinjaTypo;
