import React from "react";
import "../../styles/tehranMap.css"
import { Link } from "react-router-dom";


class TehranMap extends React.Component {

  render(){
    return(
      <div className="tehran_map_container">
        <svg version="1.1" id="Layer_1"  x="0px" y="0px"
          width="100%" height="100%" viewBox="0 0 841.89 595.28" enableBackground="new 0 0 841.89 595.28" >
          <g>
            <Link className="tehran_map_link" to="/tehran-zone-22">
              <path id="TEH-22" title="منطقه ۲۲" className="tehran_map_province" d="M46.74,185.85c2.146,1.673,3.637,2.835,5.14,4.004c0.148-0.054,0.406-0.065,0.488-0.19
                c0.725-1.125,0.204-3.214,1.597-3.531c2.008-0.457,4.192-0.168,6.3-0.151c0.619,0.006,1.029,0.438,0.782,1.048
                c-1.513,3.713,1.525,5.285,3.423,7.4c0.281,0.311,0.522,0.658,0.948,1.203c0.65-0.589,0.892-2.978,2.361-1.037
                c1.683,2.221,3.909,2.879,6.578,2.997c2.183,0.098,4.345,0.666,6.52,0.994c0.409,0.062,0.966,0.132,1.244-0.078
                c2.888-2.199,5.607-4.505,9.752-2.685c0.801,0.352,1.992-1.22,1.581-2.828c-0.498-1.957-1.26-3.779-2.148-5.582
                c-0.434-0.883-1.693-2.316,0.375-2.664c1.483-0.249,3.462-2.217,4.597,0.522c1.592,3.846,4.458,6.315,8.181,7.915
                c1.14,0.492,1.965,0.876,3.003-0.713c0.888-1.357,2.875-2.015,4.408-2.925c1.196-0.707,1.431,0.209,1.636,1.078
                c0.577,2.452,1.277,4.884,1.676,7.366c0.244,1.529,0.676,2.14,2.216,1.47c2.56-1.112,4.734-0.495,7.01,1.09
                c3.444,2.4,7.002,3.634,10.977,0.473c2.22-1.767,5.268-1.274,7.996-1.14c2.09,0.103,3.27-0.422,2.945-2.771
                c-0.256-1.838,0.69-2.422,2.413-2.319c1.579,0.094,3.17-0.037,4.751,0.033c2.128,0.092,4.076,0.114,4.307-2.796
                c0.097-1.23,0.933-0.876,1.567-0.349c1.411,1.17,3.062,1.286,4.784,1.21c2.544-0.11,3.438-1.277,1.907-3.353
                c-2.229-3.021-2.877-6.212-2.639-9.781c0.063-0.914-0.288-1.771-1.268-1.931c-2.119-0.349-3.746-0.887-2.646-3.614
                c0.203-0.504-0.433-0.865-1.011-0.957c-2.418-0.384-4.32-1.737-5.869-3.496c-3.193-3.625-7.671-2.682-11.66-3.485
                c-0.377-0.076-0.839,0.108-1.067-0.382c4.397-4.23,3.488-8.789-1.414-13.104c-2.353-2.071-4.083-5.176-6.311-7.597
                c-1.092-1.189-2.27-1.952-1.324-4.016c0.957,1.71,2.202,2.85,3.772,3.85c2.705,1.72,4.469,0.668,5.851-1.453
                c1.26-1.932,2.184-4.099,3.104-6.23c0.428-0.992,0.797-1.577,2.026-1.441c3.15,0.351,3.77,1.268,1.814,3.879
                c-2.505,3.346-3.369,6.748-2.433,10.858c0.454,1.995,1.005,2.277,2.658,1.365c1.911-1.054,4.169-2.79,3.797,1.654
                c-0.099,1.18,1.038,1.572,1.959,1.705c2.178,0.317,4.399,0.006,6.585,0.642c1.436,0.419,1.075-0.805,0.95-1.596
                c-0.554-3.501-1.124-6.999-1.736-10.786c2.585,0.552,4.531,2,6.692,2.925c0.726,0.311,1.526,1.117,2.291,0.277
                c0.727-0.802-0.05-1.598-0.393-2.311c-1.315-2.739-2.69-5.449-4.247-8.579c1.755,0.333,2.295,1.646,3.317,2.202
                c1.678,0.911,3.303,1.754,4.274-0.927c0.773-2.139,2.876-1.392,4.385-1.601c0.822-0.114,0.663,1.198,0.368,1.816
                c-1.69,3.536,1.811,6.006,1.929,9.256c0.044,1.18,3.03,0.003,4.349,1.7c-1.625,1.043-3.248,2.124-4.909,3.143
                c-2.161,1.324-2.585,3.115-0.973,4.979c1.445,1.672,0.222,2.143-0.863,2.81c-5.489,3.367-6.03,5.737-2.406,11.131
                c1.584,2.361,3.419,4.569,4.308,7.323c0.275,0.855,0.77,1.157,1.608,1.197c0.51,0.025,1.362,0.085,1.465,0.356
                c1.368,3.581,4.914,2.549,7.44,3.654c2.41,1.052,3.016-0.379,3.4-2.186c0.627-2.977,1.143-5.978,1.787-8.952
                c0.109-0.509,0.028-1.344,0.934-1.416c1.526,4.94,1.526,4.94,6.409,6.991c-5.298,1.641-5.496,5.568-5.276,9.773
                c0.084,1.604,0.815,2.043,2.203,1.844c3.942-0.563,7.877-1.18,11.824-1.706c1.192-0.16,2.325-0.456,3.309,0.995
                c1.103,1.625,3.128,0.486,4.712,0.801c1.358,0.269,2.547,1.163,3.589-1.008c0.802-1.669,3.092-1.2,4.635-0.615
                c2.805,1.064,4.246,0.4,4.955-2.603c0.285-1.204-0.031-3.263,1.864-3.462c1.681-0.179,6.133,3.724,6.029,5.123
                c-0.18,2.471,0.396,3.535,3.131,2.633c1.458-0.48,3.143-0.317,4.69-0.376c3.328-0.124,5.278-1.029,5.68-4.793
                c0.241-2.251,5.861-1.877,7.641,0.636c1.156,1.632,2.353,2.909,4.43,3.209c1.093,0.155,1.932,0.233,1.738-1.25
                c-0.42-3.219,1.965-2.985,3.654-2.49c3.413,0.997,5.141,0.381,5.33-3.506c0.104-2.156,1.982-1.777,3.443-1.428
                c4.672,1.116,9.289,1.403,14.002-0.005c1.665-0.496,2.26-1.137,2.137-2.784c-0.109-1.471-0.143-2.968,0.004-4.432
                c0.263-2.612-0.196-4.549-3.268-5.052c-1.728-0.283-1.757-1.855-1.837-3.254c-0.454-8.026-0.447-8.139-8.468-8.724
                c-2.499-0.181-4.914-0.732-7.818-0.881c2.645-1.934,5.386-2.675,7.984-3.704c3.738-1.483,6.987-3.488,8.546-7.465
                c0.464-1.181,0.693-2.4-0.608-3.053c-2.797-1.399-4.633-3.965-7.194-5.649c-2.284-1.502-4.692-2.404-7.411-1.901
                c-1.19,0.219-1.89,0.01-2.085-1.172c-0.479-2.898-0.942-5.8-1.322-8.712c-0.203-1.566,0.109-2.939,2.189-2.18
                c1.158,0.423,1.719-0.235,2.496-0.789c3.249-2.324,5.281-1.98,7.294,1.458c1.003,1.713,2.028,0.552,2.954,0.292
                c1.391-0.392,2.198-0.448,2.188,1.377c-0.005,1.134,0.347,2.272,0.537,3.408c0.632,3.793,1.898,4.821,5.698,4.76
                c1.349-0.021,1.729-0.835,2.099-1.929c1.017-3.005,2.061-6.001,1.766-9.235c-0.185-2.023,0.503-2.921,2.646-2.775
                c1.026,0.07,2.824,0.62,2.894-0.956c0.081-1.823,1.81-3.823-0.321-5.607c-1.37-1.148-2.565-2.511-3.822-3.792
                c-0.43-0.439-1.164-0.866-0.923-1.53c0.294-0.808,1.182-0.742,1.926-0.746c0.528-0.003,1.069-0.074,1.582,0.011
                c2.451,0.4,2.991-0.712,2.211-2.797c-0.984-2.627,0.303-2.429,2.086-1.761c0.887,0.332,1.827,0.527,2.71,0.868
                c0.989,0.382,1.629,0.333,1.695-0.936c0.035-0.704-0.084-1.627,1.02-1.602c0.926,0.023,1.016,0.939,1.384,1.547
                c3.267,5.396,0.768,10.791-0.065,16.17c-0.075,0.481-0.813,0.9-1.302,1.27c-7.503,5.673-11.637,13.088-11.844,22.601
                c-0.077,3.478-3.604,4.713-5.061,7.334c-0.544,0.978-1.582,1.684-2.403,2.507c-0.758,0.758-0.991,1.692-0.52,2.613
                c2.785,5.455,4.53,11.8,11.966,13.15c1.899,0.346,1.517,2.583,1.671,4.005c0.25,2.292,0.331,4.597,0.598,6.902
                c0.225,1.921,0.562,3.48,2.232,4.572c0.343,0.224,0.915,0.696,0.856,0.841c-1.732,4.261,1.961,9.098-1.335,13.121
                c-1.038,1.266-0.63,2.213-0.054,3.415c3.605,7.538,5.75,15.607,8.473,23.467c1.484,4.284,0.203,8.511-0.205,12.783
                c-0.376,3.943-1.556,7.859-0.654,11.891c1.424,6.359,5.914,10.452,10.924,13.761c10.328,6.82,14.748,17.475,19.315,28.137
                c0.927,2.163-0.019,2.553-1.943,2.497c-9.593-0.284-19.123,0.963-28.684,1.419c-5.647,0.269-11.325,0.516-16.981,1.037
                c-7.094,0.654-14.124-0.499-20.861-2.622c-8.237-2.597-16.702-2.997-25.181-3.687c-5.667-0.462-11.201-1.835-16.638-3.436
                c-13.699-4.037-26.519-9.965-38.997-16.989c-17.629-9.923-35.606-19.315-55.299-24.722c-7.305-2.007-14.595-1.415-21.821,0.495
                c-8.756,2.315-17.448,4.76-26.705,2.938c-2.646-0.522-5.478-0.087-8.224-0.095c-0.634-0.001-1.56,0.25-1.854-0.072
                c-2.423-2.645-5.841-1.214-8.676-2.22c-3.17-1.123-6.398-2.095-9.488-3.431c-5.535-2.392-10.85-5.151-15.184-9.488
                c-1.321-1.322-2.24-2.746-2.237-4.702C46.749,212.134,46.74,199.319,46.74,185.85"/>
              <text transform="matrix(1 0 0 1 241.5566 237.75)"  > ۲۲</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-4">
              <path id="TEH-4" title="منطقه ۴" className="tehran_map_province" d="M756.749,276.007c-5.947,0.655-11.064-0.382-16.038-2.324c-4.887-1.906-9.9-1.582-14.939-0.839
                c-3.015,0.443-6.067,0.24-9.117,0.625c-3.199,0.405-6.503,0.348-9.724,0.827c-2.618,0.389-4.58-0.653-5.193-2.979
                c-1.627-6.159-6.064-6.913-11.511-7.331c-11.7-0.894-23.35-2.411-35.022-3.675c-0.315-0.033-0.836,0.031-0.91-0.12
                c-2.091-4.271-6.35-2.532-9.397-2.82c-11.447-1.079-22.535-3.839-33.729-6.129c-8.73-1.785-17.449-3.558-26.363-4.342
                c-3.684-0.323-7.347-0.58-11.021-0.488c-2.195,0.054-2.121-1.192-1.319-2.283c2.817-3.834,3.197-8.138,2.912-12.64
                c-0.04-0.644,0.007-1.418,0.597-1.72c4.408-2.268,4.35-6.681,5.034-10.641c1.177-6.798,3.719-12.854,7.494-18.731
                c5.138-8.002,11.559-15.252,14.657-24.564c1.707-5.133,6.271-8.348,10.103-11.934c1.095-1.027,2.574-0.64,3.923-0.264
                c3.422,0.95,6.309,2.918,9.145,4.921c3.942,2.784,8.144,3.496,12.877,2.599c5.895-1.117,11.44,0.463,16.896,2.706
                c4.611,1.895,9.09,4.049,13.05,7.014c4.773,3.572,10.143,5.385,15.875,5.166c12.042-0.462,22.606,3.971,33.24,8.703
                c5.601,2.491,11.157,4.927,16.061,8.668c7.939,6.056,14.55,13.251,20.061,21.632c4.127,6.274,10.601,9.569,17.974,10.478
                c6.158,0.758,11.828,2.757,17.438,5.194c5.853,2.546,5.832,2.471,2.985,8.316c-1.905,3.915-3.693,8.484-7.39,10.412
                c-4.85,2.53-7.699,6.814-11.577,10.135C768.692,273.968,763.436,277.672,756.749,276.007"/>
              <text transform="matrix(1 0 0 1 669.6094 228.9863)"  > ۴</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-5">
              <path id="TEH-5" title="منطقه ۵" className="tehran_map_province"  d="M409.259,227.966c-4.064,0.752-4.141,1.083-3.601,4.887c0.913,6.438,1.573,12.915,2.331,19.374
                c1.001,8.546,2.012,17.092,2.986,25.639c0.929,8.136,1.892,16.269,2.69,24.418c0.511,5.223,1.495,10.403,1.335,15.699
                c-0.054,1.881-0.552,2.703-2.533,2.579c-2.105-0.132-4.225-0.044-6.338-0.018c-17.515,0.203-35.033,0.358-52.545,0.682
                c-2.709,0.049-3.381-0.817-3.331-3.398c0.148-8.027,0.023-16.059,0.078-24.088c0.01-1.664-0.434-2.919-1.514-4.275
                c-3.176-3.984-5.37-8.616-7.304-13.27c-3.375-8.123-9.409-13.805-16.033-19.152c-3.929-3.172-7.929-6.517-8.641-12.007
                c-0.508-3.92,0.677-7.771,1.456-11.52c1.046-5.038,0.876-9.731-0.872-14.511c-1.557-4.255-2.554-8.746-4.422-12.849
                c-2.079-4.568-0.817-8.64,0.784-12.773c0.89-2.295,0.816-4.25-0.061-6.63c-2.143-5.821-4.408-11.653-5.533-17.782
                c-0.652-3.556-2.586-5.761-5.872-6.715c-2.516-0.729-4.125-2.467-5.775-4.239c-1.198-1.284-1.527-2.987-2.018-4.591
                c-0.741-2.428,1.756-2.218,2.763-3.09c3.423-2.969,3.785-7.145,4.796-11.078c0.442-1.725,0.811-3.482,1.044-5.246
                c0.589-4.508,4.283-6.858,6.91-9.877c4.979-5.726,6.797-12.466,7.084-19.79c0.053-1.334-1.236-2.163-1.752-3.31
                c-0.579-1.288-1.184-2.942-0.093-3.816c0.883-0.705,2.445,0.227,3.459,0.939c1.548,1.087,2.128,2.803,2.402,4.626
                c0.418,2.798,0.733,5.619,1.333,8.38c0.31,1.423-0.097,1.985-1.382,2.176c-2.311,0.343-2.74,1.817-2.514,3.87
                c0.196,1.785,0.053,3.56-0.595,5.298c-0.487,1.307-1.042,2.315-2.646,2.144c-1.17-0.125-1.608,0.557-2.013,1.55
                c-2.94,7.191-6.923,13.992-8.038,21.859c-0.221,1.562-0.54,3.062-2.493,3.586c-1.84,0.493-1.304,2.164-1.293,3.471
                c0.011,1.327,0.92,1.661,2.09,1.888c3.607,0.695,7.169,1.664,10.794,2.235c2.4,0.377,3.061,1.494,3.008,3.807
                c-0.111,4.887,2.665,7.416,7.43,7.273c2.15-0.064,3.628-0.91,4.429-2.908c0.905-2.259,2.434-3.903,4.83-4.882
                c0.123,0.251,0.337,0.492,0.348,0.74c0.24,5.397,1.077,6.278,6.456,6.954c1.069,0.134,1.778-0.265,2.527-0.949
                c1.318-1.206,2.712-2.337,4.125-3.431c0.474-0.365,1.55-0.815,1.608-0.729c2.298,3.437,6.925-0.562,9.265,2.922
                c0.399,0.594,1.313-0.399,1.737-0.944c1.719-2.213,2.745-4.686,2.882-7.546c0.051-1.089-0.732-3.234,1.118-2.971
                c1.95,0.28,4.726-0.507,5.764,2.183c0.52,1.347,0.679,2.835,0.706,4.342c0.058,3.239,1.973,5.348,5.133,6.081
                c2.921,0.678,5.669,1.177,8.722-0.115c2.335-0.989,5.125-1.093,7.728-1.2c1.976-0.08,2.678-0.94,3.276-2.66
                c1.041-3.003,1.733-6.552,6.181-6.327c1.051,0.052,2.015,0.074,2.988,0.733c3.56,2.41,7.059,1.267,8.369-2.898
                c1.295-4.105,2.201-8.332,3.335-12.49c0.293-1.076,0.81-2.092,1.406-3.592c1.282,6.307,1.037,12.103,0.372,17.888
                c-0.584,5.075,1.267,9.77,2.165,14.608c0.313,1.689,0.674,2.993-1.716,2.899c-1.215-0.048-1.857,0.606-1.648,1.744
                c0.543,2.968-1.407,3.666-3.592,3.834c-2.21,0.168-2.136,1.119-1.824,2.925c0.874,5.064,1.569,10.166,2.135,15.275
                c0.568,5.121-0.428,10.076-2.186,14.881c-1.573,4.3-2.506,8.696-2.213,13.302L409.259,227.966z"/>
              <text transform="matrix(1 0 0 1 365.9473 245.1963)"  > ۵</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-21">
              <path id="TEH-21" title="منطقه ۲۱" className="tehran_map_province" d="M78.442,245.495c6.3,1.708,12.184,2.428,17.993,2.381c6.155-0.049,12.377-1.017,18.27-3.04
                c13.265-4.553,25.826-2.197,38.359,3.047c18.417,7.708,35.909,17.181,53.465,26.614c12.449,6.691,25.815,11.323,39.9,13.534
                c9.213,1.448,18.736,0.697,27.714,3.749c10.991,3.735,22.345,3.706,33.681,3.095c10.492-0.564,20.972-1.423,31.474-1.896
                c2.184-0.097,4.362-0.567,6.586-0.559c1.856,0.006,2.692,0.704,2.679,2.621c-0.051,6.763,0.633,13.612-0.259,20.255
                c-0.564,4.203-1.448,8.639-2.636,12.624c-1.85,6.203-2.265,13.112-6.65,18.492c-2.599,3.187-4.973,6.57-6.19,10.566
                c-0.55,1.804-1.903,1.343-3.084,1.366c-11.638,0.245-23.015-1.821-34.343-4.118c-7.607-1.544-15.351-2.516-22.753-4.989
                c-7.211-2.412-14.141-5.525-20.929-8.904c-14.173-7.06-28.564-13.765-42.276-21.639c-11.847-6.806-24.797-9.792-37.587-13.58
                c-5.475-1.624-10.904-3.391-14.699-8.084c-0.354-0.44-0.743-0.868-1.303-0.661c-1.809,0.676-3.279,0.539-4.993-0.677
                c-0.788-0.56-2.437,0.569-3.993,0.055c-4.821-1.591-8.812-4.466-12.671-7.561c-7.792-6.247-14.189-13.721-19.653-22.093
                c-5.174-7.928-13.456-11.213-22.119-13.574C84.738,250.424,81.76,249.147,78.442,245.495"/>
              <text transform="matrix(1 0 0 1 257.7217 321.2285)"  > ۲۱</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-2">
              <path id="TEH-2" title="منطقه ۲" className="tehran_map_province"  d="M409.246,227.99c3.132-6.687,5.773-13.637,6.771-20.897c0.566-4.114-0.862-8.498-1.376-12.761
                c-0.327-2.705-0.647-5.413-0.92-8.123c-0.166-1.663,0.541-2.56,2.326-2.626c2.326-0.085,3.832-1.015,3.392-3.723
                c-0.076-0.473,0.12-1.405,0.322-1.444c4.336-0.842,2.459-3.996,2.102-6.203c-0.967-5.995-0.385-12.002-0.525-17.999
                c-0.057-2.427-0.149-4.827-0.614-7.216c-0.127-0.652-0.268-1.631,0.718-1.844c1.113-0.24,1.016,0.75,1.191,1.431
                c0.133,0.509,0.446,1.003,0.457,1.507c0.068,3.664,2.363,4.529,5.28,3.745c3.247-0.872,6.971,0.447,9.821-2.738
                c1.303-1.455,3.755,0.51,5.781,0.34c2.078-0.174,3.762,0.372,4.348,2.987c0.485,2.164,2.638,2.045,4.478,2.011
                c1.944-0.036,2.065-1.36,2.182-2.822c0.082-1.039,0.487-2.053,0.547-3.089c0.114-2.005,1.11-2.63,2.938-2.088
                c1.455,0.43,2.874,0.988,3.697-0.999c0.096-0.233,0.838-0.464,1.078-0.33c3.577,1.991,5.999-1.355,9.005-1.948
                c1.25-0.247,0.908-1.313,0.654-2.325c-0.457-1.808-2.338-3.567-1.313-5.36c1.442-2.525,3.46-4.738,5.35-6.986
                c0.718-0.854,1.332-0.374,1.404,0.623c0.411,5.687,2.282,10.747,5.715,15.385c1.613,2.184,0.455,5.154,0.638,7.761
                c0.098,1.366,0.113,2.754-0.004,4.116c-0.17,1.959,0.647,2.735,2.556,2.514c2.276-0.263,3.048,0.701,3.248,3.045
                c0.448,5.216,1.196,10.414,2.088,15.575c0.247,1.409,0.044,2.469-0.59,3.571c-3.517,6.091-5.775,12.582-6.557,19.603
                c-0.449,4.036,0.778,7.934,0.595,11.963c-0.453,9.811-2.619,19.324-4.67,28.857c-0.975,4.534-1.411,9.105-1.751,13.714
                c-0.409,5.538-1.014,11.065-1.604,16.589c-0.253,2.359-1.314,4.476-2.671,6.355c-2.463,3.411-3.271,7.283-3.253,11.316
                c0.02,4.199-0.577,8.436,0.519,12.606c0.146,0.56,0.076,1.385-0.248,1.831c-2.705,3.755-1.186,7.728-0.561,11.587
                c0.297,1.848-0.06,2.364-1.93,2.565c-6.412,0.688-12.846,0.354-19.258,0.636c-10.222,0.444-20.44,0.644-30.669,0.636
                c-2.289-0.002-3.192-0.54-3.103-2.993c0.335-9.179-1.429-18.182-2.357-27.264c-1.259-12.319-3.569-24.475-5.097-36.743
                c-0.749-6.035-0.96-12.138-1.356-18.214c-0.141-2.143,0.899-4.09,1.239-6.158L409.246,227.99"/>
              <text transform="matrix(1 0 0 1 444 233.2803)"  > ۲</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-1">
              <path id="TEH-1" title="منطقه ۱" className="tehran_map_province"  d="M524.303,113.738c2.098,2.301,4.387,2.031,5.371-0.756c1.016-2.875,1.849-5.299,5.925-4.987
                c2.835,0.215,3.349-2.662,3.647-5.057c0.115-0.922-0.43-2.505,1.338-2.161c1.174,0.227,1.729,1.122,1.43,2.651
                c-0.813,4.145-0.602,4.253,3.776,5.371c1.387,0.354,2.294,0.318,2.313-1.409c0.017-1.478,0.78-2.304,1.758-0.847
                c3.376,5.042,8.739,5.184,13.854,6.019c2.688,0.438,5.382,0.853,8.079,1.224c0.8,0.111,2.063,0.339,2.114-0.727
                c0.146-3.135,2.377-2.376,4.104-2.33c3.332,0.087,6.043-0.637,9.015-2.703c4.665-3.242,10.34-2.2,15.582-0.762
                c4.486,1.231,8.949,2.317,13.634,2.172c0.964-0.029,1.803,0.159,2.664,0.734c4.979,3.316,9.248,2.165,11.858-3.115
                c0.328-0.662,0.621-1.34,0.94-2.005c0.478-0.989,0.254-2.805,1.458-2.827c1.42-0.026,1.452,1.781,1.939,2.904
                c0.479,1.106,0.427,2.257,0.521,3.412c0.22,2.719,1.69,4.924,4.334,5.081c3.025,0.178,3.754,1.829,3.808,4.13
                c0.061,2.517,0.698,3.412,3.11,1.915c0.626-0.388,1.224-0.845,2.111,0.183c1.712,1.987,3.753,1.266,5.483-0.187
                c1.656-1.394,2.251-4.688,4.354-4.476c1.34,0.136,2.239,3.271,3.599,4.834c3.326,3.828,7.374,6.905,11.128,10.287
                c0.388,0.35,0.998,0.535,1.242,0.952c1.903,3.239,4.798,4.014,8.289,3.553c1.675-0.221,2.485,0.609,2.689,2.314
                c0.276,2.328,1.681,3.51,3.982,4.244c7.423,2.369,14.747,5.046,22.148,7.487c1.985,0.655,2.305,1.365,0.864,2.93
                c-2.111,2.289-4.255,3.675-7.739,2.853c-2.704-0.637-5.672-0.137-8.522-0.2c-1.304-0.028-1.707,0.522-2.007,1.807
                c-2.206,9.49-7.371,17.331-13.81,24.47c-1.989,2.205-3.842,2.368-6.3,0.741c-5.346-3.54-10.529-7.353-16.374-10.102
                c-6.311-2.969-12.87-3.694-19.695-2.487c-2.123,0.375-4.09,0.081-6.128-0.851c-5.149-2.356-9.749-6.288-15.544-6.784
                c-7.923-0.678-15.448-2.9-23.089-4.761c-5.337-1.3-9.362,1.913-13.18,4.843c-6.114,4.69-12.636,8.227-20.303,9.695
                c-4.053,0.776-7.772,2.565-10.317,6.148c-0.866,1.217-2.159,1.959-3.494,2.658c-2.155,1.128-3.508,0.517-4.641-1.542
                c-2.388-4.334-5.034-8.529-7.324-12.912c-1.346-2.571-3.389-3.989-6.006-4.675c-2.731-0.716-5.521-1.624-8.299-1.669
                c-3.479-0.056-6.444-0.592-9.353-2.772c-2.178-1.633-4.908-0.214-7.242,0.645c-2.962,1.087-5.887,2.316-8.703,3.734
                c-1.801,0.907-2.541,0.297-2.756-1.361c-0.239-1.833-1.108-2.535-2.942-2.322c-1.321,0.154-2.507-0.439-2.306-1.897
                c0.439-3.192-1.33-6.582,1.323-9.651c1.068-1.236-0.686-2.655-1.737-3.61c-3.107-2.822-4.567-6.237-4.1-10.44
                c0.101-0.896-0.233-2.04,1.192-2.166c1.214-0.11,1.277,0.829,1.411,1.672c0.49,3.102,2.444,3.873,5.389,4.029
                c3.601,0.189,6.173-0.728,8.746-3.487c2.995-3.209,6.896-5.567,8.161-10.158c0.239-0.871,0.681-1.458,1.836-1.07
                C515.81,122.479,519.997,118.319,524.303,113.738"/>
              <text transform="matrix(1 0 0 1 577.9473 141.6367)"  > ۱</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-18">
              <path id="TEH-18" title="منطقه ۱۸" className="tehran_map_province"  d="M367.745,474.463c-1.18,1.167-3.23,0.913-4.206,2.53c0.821,1.088,1.712,0.445,2.563,0.214
                c0.837-0.227,1.796-0.488,2.246,0.476c1.391,2.976,3.574,3.899,6.884,2.275c0.035,0.278,0.072,0.555,0.109,0.829
                c-7.939,9.919-15.878,19.839-24.349,30.422c-0.406-4.986-2.659-8.018-5.609-10.69c-1.683-1.525-1.724-3.503-0.181-5.04
                c1.603-1.591,1.84-3.205,1.681-5.362c-0.391-5.365,0.036-10.736,0.55-16.093c0.53-5.519-0.682-10.655-3.495-15.468
                c-1.794-3.068-2.87-6.162-0.321-9.598c1.741-2.345,1.58-5.463,1.793-8.243c0.273-3.57,1.202-6.82,2.91-9.903
                c0.685-1.235,1.429-2.078-0.706-3.042c-2.837-1.282-3.133-4.399-3.24-7.116c-0.195-4.973-0.311-9.912-2.769-14.427
                c-0.403-0.743-0.113-1.408,0.005-2.133c0.854-5.183,1.678-10.369,2.483-15.559c0.17-1.092,0.654-2.238-0.936-2.913
                c-2.355-1-3.313-3.181-3.893-5.468c-0.541-2.134-1.219-4.339-1.167-6.495c0.093-3.766-0.671-7.044-4.163-9.951
                c9.567-1.271,18.463-1.175,27.322-0.443c4.725,0.391,9.492-0.273,14.18,0.795c1.022,0.232,1.927,0.305,1.825,1.602
                c-0.216,2.775,1.692,3.86,3.755,4.859c6.901,3.342,14.371,4.74,21.784,6.262c7.101,1.458,14.212,2.955,21.489,3.254
                c1.486,0.061,1.989,0.899,2.362,2.08c4.746,15.067,9.467,30.143,14.294,45.183c0.599,1.866-0.036,2.599-1.545,3.456
                c-12.458,7.066-25.201,13.637-37.254,21.414c-3.848,2.483-4.048,2.412-2.223,6.311c0.612,1.31-0.295,1.232-0.8,1.481
                c-1.417,0.696-2.836,1.494-4.349,1.871c-6.767,1.676-9.819,7.569-14.055,12.129c-0.886,0.956-1.362,3.285-3.015,2.251
                c-1.716-1.074-3.418-3.021-0.204-5.308c-4.662,0.783-4.662,0.783-2.697,6.266c-5.139,1.35-5.691,0.986-5.872-3.887
                c-0.269-0.344-0.431-0.714-0.346-1.162c0.058-0.301,0.171-0.563,0.215-0.587C368.032,472.178,368.378,473.527,367.745,474.463"/>
              <text transform="matrix(1 0 0 1 377.0225 426.1426)"  > ۱۸</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-3">
              <path id="TEH-3" title="منطقه ۳" className="tehran_map_province" d="M562.938,243.758c-5.11-0.255-9.983,0.26-14.723,2.642c-2.929,1.471-6.451,0.493-9.7,0.449
                c-1.545-0.023-1.135-1.511-1.173-2.49c-0.133-3.465,0.19-6.93-0.592-10.388c-0.938-4.147-3.072-6.607-7.25-6.607
                c-6.409,0-12.828,0.389-19.23,0.817c-7.333,0.488-14.613,1.693-21.992,1.655c-1.525-0.009-2.369-0.356-2.334-2.103
                c0.063-3.464-0.229-6.846,2.063-9.986c2.188-2.998,1.309-6.628,0.228-9.768c-2.791-8.107-1.031-15.204,3.724-22.004
                c3.906-5.585,3.862-11.786,1.381-17.813c-1.405-3.415-0.636-5.054,2.632-6.13c1.19-0.392,2.294-1.07,3.402-1.679
                c4.392-2.411,8.427-4.796,13.904-1.748c3.105,1.728,7.32,1.527,11.06,2.035c3.807,0.517,6.188,2.68,8.028,5.919
                c2.652,4.668,5.704,9.111,8.3,13.809c1.304,2.364,2.891,2.45,4.906,1.527c1.429-0.655,2.891-1.378,4.094-2.367
                c5.43-4.463,11.384-7.702,18.172-9.84c6.529-2.058,12.283-6.04,17.932-9.885c3.535-2.407,6.933-2.668,10.755-1.457
                c4.11,1.303,8.103,2.994,12.403,3.661c1.679,0.259,1.545,1.141,0.553,2.161c-0.073,0.075-0.16,0.154-0.255,0.185
                c-5.903,2.004-7.228,7.215-9.311,12.296c-3.89,9.487-9.531,18.088-16.247,25.815c-1.933,2.228-1.715,4.78-2.82,6.719
                c-2.715,4.762-0.385,10.326-3.239,14.828c-0.549,0.868-0.656,2.437-1.762,2.443c-2.955,0.018-2.422,1.998-2.401,3.723
                c0.044,3.7-0.243,7.282-1.619,10.832c-0.848,2.181-1.889,3.017-4.138,2.781C566.121,243.625,564.521,243.758,562.938,243.758"/>
              <text transform="matrix(1 0 0 1 538.3027 209.0981)"  > ۳</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-15">
              <path  id="TEH-15" title="منطقه ۱۵" className="tehran_map_province"  d="M573.373,380.028c3.808,0.75,6.699,2.62,8.941,5.123c4.735,5.283,10.346,6.601,17.11,5.174
                c2.86-0.603,5.742,0.873,8.603,1.414c7.627,1.447,15.214,3.112,22.787,4.814c1.479,0.329,1.697,0.023,2.663-1.256
                c2.148-2.849,1.641-5.8,1.696-8.801c0.024-1.445,0.229-2.152,1.979-1.635c3.423,1.014,6.907,1.82,10.371,2.691
                c1.497,0.377,1.516,1.233,1.31,2.623c-1.008,6.787-3.34,13.229-4.959,19.855c-0.757,3.094,0.885,5.12,4.116,5.412
                c3.021,0.271,6.012,0.895,9.035,1.131c1.78,0.139,2.326,0.83,2.291,2.551c-0.091,4.328-0.068,8.659-0.009,12.991
                c0.02,1.533-0.4,2.193-2.101,2.374c-3.437,0.368-4.993,2.03-4.82,5.392c0.137,2.628-0.776,3.95-3.281,4.401
                c-2.712,0.489-3.588,1.776-2.741,4.7c0.602,2.071,1.37,4.992-2.339,5.683c-1.658,0.311-0.936,1.371-0.473,2.127
                c0.992,1.614,2.097,3.158,3.161,4.728c1.302,1.916,1.935,3.84-0.178,5.608c-1.056,0.886-0.888,1.753-0.28,2.764
                c1.302,2.165,2.502,4.396,3.887,6.503c1.088,1.658,0.703,2.602-0.984,3.465c-1.437,0.732-2.525,2.344-4.098-0.09
                c-1.164-1.799-2.476-4.03-5.519-2.978c-0.683,0.235-1.778-0.495-2.6-0.938c-4.543-2.45-9.095-4.891-13.561-7.475
                c-1.204-0.693-2.043-1.157-3.376-0.205c-1.59,1.134-3.324,0.363-4.132-1.014c-2.544-4.343-6.127-7.138-10.656-9.335
                c-4.026-1.951-7.665-4.782-11.144-7.7c-1.591-1.333-3.403-1.754-5.526-1.805c-8.546-0.201-17.061,0.431-25.586,0.732
                c-2.415,0.087-3.108-0.577-3.316-3.129c-0.754-9.29-1.286-18.59-2.773-27.838c-1.366-8.502-2.98-16.938-4.892-25.314
                c-0.619-2.721,0.438-2.812,2.438-2.729c4.566,0.191,8.986-0.76,13.352-1.934c3.048-0.817,5.075-2.52,4.681-6.088
                C572.352,381.137,572.23,379.989,573.373,380.028"/>
              <text transform="matrix(1 0 0 1 598.1045 430.5459)"  > ۱۵</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-6">
              <path  id="TEH-6" title="منطقه ۶" className="tehran_map_province"  d="M475.449,302.709c0.077-5.989-0.541-12.023,1.237-17.901c0.885-2.93,1.933-5.734,3.463-8.437
                c2.374-4.201,2.898-8.844,2.448-13.682c-0.551-5.898-0.161-11.767,1.527-17.505c1.1-3.732,2.234-7.471,2.062-11.433
                c-0.062-1.397,0.367-2.159,1.944-2.164c12.421-0.042,24.685-2.384,37.102-2.596c8.625-0.149,11.192,3.216,11.688,11.281
                c0.449,7.291-1.103,14.212-3.272,21.074c-0.983,3.113-0.112,5.896,2.192,8.046c2.905,2.708,4.188,6.023,4.211,9.856
                c0.012,1.461,0.65,2.24,1.665,3.128c2.605,2.276,2.922,4.775,0.834,7.501c-1.002,1.304-1.197,2.615-1.193,4.115
                c0.018,6.02-0.05,12.04,0.04,18.057c0.029,1.901-0.562,2.618-2.519,2.589c-7.376-0.107-14.736,0.516-22.104,0.74
                c-11.044,0.336-22.073,1.134-33.132,1.158c-0.105,0-0.214,0-0.317,0.008c-7.854,0.623-7.855,0.623-7.875-7.182
                C475.446,307.144,475.449,304.927,475.449,302.709"/>
              <text transform="matrix(1 0 0 1 505 284.0371)"  > ۶</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-9">
              <path  id="TEH-9" title="منطقه ۹" className="tehran_map_province"  d="M421.094,377.451c-7.687-0.5-15.121-2.435-22.628-3.976c-4.008-0.82-8.035-1.553-12.063-2.271
                c-1.539-0.272-3.578,0.458-3.797-2.127c-4.373,0.16-4.46-2.74-4.352-5.927c0.033-0.956-1.02-0.938-1.74-0.911
                c-3.907,0.155-7.751-0.622-11.656-0.713c-8.851-0.208-17.695-0.805-26.555-0.55c-2.167,0.062-2.512-0.666-1.813-2.625
                c1.789-5.015,3.782-9.851,8.391-13.038c0.613-0.425,1.105-0.971,1.194-1.784c0.73-6.557,4.268-12.643,3.245-19.498
                c-0.264-1.761,0.55-2.388,2.341-2.406c2.992-0.032,5.826,0.724,8.727,1.224c7.109,1.22,14.255,1.087,21.397,0.2
                c0.523-0.065,1.054-0.08,1.58-0.092c9.802-0.228,19.624-0.126,29.397-0.776c8.02-0.532,16.018-0.396,24.024-0.503
                c3.547-0.046,4.544,0.921,5.032,4.584c0.675,5.053,0.007,10.037-0.949,14.969c-1.165,6.013-1.792,11.929-0.482,18.07
                c1.459,6.853-3.689,14.728-10.599,16.733C426.982,376.851,424.093,377.587,421.094,377.451"/>
              <text transform="matrix(1 0 0 1 385.9473 347.1162)"  > ۹</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-20">
              <path  id="TEH-20" title="منطقه ۲۰" className="tehran_map_province"  d="M569.736,524.561c-3.863-0.398-8.042-0.853-12.043,0.907c-0.762,0.334-1.513,0.244-1.992-0.442
                c-3.051-4.387-7.553-1.844-11.398-2.375c-0.846-0.117-0.928,0.708-0.577,1.358c0.399,0.739,0.845,1.459,1.338,2.141
                c1.819,2.514,1.995,4.404-1.288,6.088c-2.451,1.253-3.587,4.084-5.15,6.282c-1.477,2.081-1.977,1.315-3.044-0.173
                c-1.897-2.644-1.468-5.838-2.288-8.73c-0.798-2.822-1.256-5.739-1.857-8.616c-0.333-1.594-0.596-3.075,1.331-4.032
                c1.969-0.978,0.542-2.518,0.116-3.553c-2.246-5.455-4.692-10.825-7.053-16.23c-1.306-2.993-2.58-5.999-3.873-8.997
                c-0.973-2.248-2.182-4.316-4.079-5.947c-0.658-0.567-1.843-1.508-0.597-2.275c3.42-2.102,0.217-4.059-0.03-6.015
                c-0.134-1.063-1.255-0.719-2.11-0.458c-1.372,0.415-2.763,0.583-3.546-1.034c-0.4-0.83-1.313-1.66-0.909-2.577
                c0.475-1.075,1.704-0.588,2.57-0.543c9.173,0.463,17.597-1.664,24.873-7.392c4.705-3.703,8.771-8.191,13.924-11.351
                c0.811-0.496,1.611-0.999,2.42-1.493c1.322-0.804,2.685-2.237,2.936,0.944c0.105,1.358,1.727,0.807,2.702,0.858
                c8.863,0.462,17.695-0.469,26.543-0.691c3.612-0.089,5.655,0.772,7.116,4.424c2.676,6.695,2.896,6.786-4.232,7.009
                c-1.914,0.061-2.969,0.683-2.48,2.556c1.069,4.1-2.833,7.18-2.093,11.251c0.241,1.316-1.237,0.824-2.146,0.901
                c-1.85,0.15-3.704-1.862-5.618,0.86c-2.452,3.486-3.011,6.765-1.157,10.451c0.604,1.199,1.561,2.253,2.496,3.238
                c1.084,1.142,2.058,2.084,0.693,3.748c-0.848,1.038,0.176,1.99,0.79,2.783c1.157,1.497,2.514,2.843,3.617,4.373
                c1.239,1.723,3.664,3.285,1.109,5.827c-0.588,0.585-0.036,1.391,0.273,2.065c1.056,2.3,2.237,4.553,3.096,6.923
                c0.803,2.217,1.941,3.432,4.385,2.521c0.926-0.347,1.311,0.08,1.745,0.793c3.152,5.146,2.194,7.089-3.712,7.723
                c-2.603,0.279-5.174,0.835-7.764,1.229c-2.01,0.308-2.872-0.47-2.794-2.615c0.045-1.209,0.874-3.014-0.963-3.567
                c-1.33-0.401-2.596-0.312-3.818,1.219C574.088,525.315,571.792,524.202,569.736,524.561"/>
              <text transform="matrix(1 0 0 1 545 490)"  > ۲۰</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-16">
              <path  id="TEH-16" title="منطقه ۱۶" className="tehran_map_province"  d="M488.313,393.844c2.213-0.009,4.405-0.045,6.591,0.565c1.657,0.462,3.007,0.032,2.711-2.162
                c-0.299-2.225,1.147-1.81,2.339-1.477c6.473,1.808,13.096,2.322,19.732,1.769c6.588-0.549,13.124-1.624,19.72-2.229
                c3.006-0.276,5.882-0.074,8.763,0.497c1.798,0.358,2.329,1.976,2.693,3.569c1.588,6.971,2.557,14.049,3.485,21.126
                c1.156,8.852,2.296,17.707,2.899,26.621c0.155,2.276-0.234,3.575-2.796,4.275c-5.139,1.404-9.198,4.727-12.511,8.773
                c-7.898,9.646-18.646,11.925-30.276,12.24c-2.379,0.063-2.642-0.52-1.666-2.653c4.034-8.823,3.858-8.78-1.312-17.042
                c-4.138-6.608-7.943-13.421-11.923-20.13c-1.633-2.757-2.024-5.357,0.047-8.189c1.792-2.448,0.469-4.92-0.844-7.085
                c-1.693-2.793-3.796-5.304-6.152-7.6c-2.264-2.206-4.581-4.363-6.436-6.966c-0.723-1.013-1.339-1.976-0.785-3.168
                c0.512-1.104,1.654-0.672,2.552-0.725C486.196,393.794,487.256,393.839,488.313,393.844"/>
              <text transform="matrix(1 0 0 1 520 430.5459)"  > ۱۶</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-12">
              <path  id="TEH-12" title="منطقه ۱۲" className="tehran_map_province"  d="M520.479,390.026c-5.765,0.111-5.773,0.11-5.153-4.242c2.952-20.687,5.906-41.371,8.87-62.055
                c1.044-7.305,1.043-7.031,8.446-6.688c9.897,0.458,19.789-0.281,29.669-0.989c3.155-0.229,6.334-0.106,9.502-0.198
                c1.671-0.049,1.89,0.961,1.833,2.261c-0.244,5.578-0.369,11.162-0.75,16.729c-0.468,6.814-1.204,13.606-1.723,20.416
                c-0.744,9.785-1.03,19.587-0.44,29.391c0.09,1.491-0.814,1.484-1.776,1.553c-9.021,0.657-18.044,1.26-27.058,1.969
                C534.25,388.773,526.609,389.492,520.479,390.026"/>
              <text transform="matrix(1 0 0 1 538 358.3809)"  > ۱۲</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-7">
              <path  id="TEH-7" title="منطقه ۷" className="tehran_map_province"  d="M606.904,303.025c-8.959,1.488-16.256,5.641-23.91,8.836c-3.495,1.462-7.193,1.812-10.806,1.96
                c-8.733,0.359-17.465,1.075-26.226,0.843c-2.064-0.055-2.813-0.641-2.694-2.721c0.366-6.411-0.645-12.873,0.609-19.247
                c0.364-1.85,1.177-3.608,1.609-5.445c0.602-2.572,0.736-5.069-2.118-6.59c-1.183-0.631-1.344-1.779-1.348-3
                c-0.006-3.675-1.363-6.804-4.068-9.27c-2.204-2.013-2.739-4.22-1.825-7.115c0.977-3.095,1.53-6.33,2.197-9.519
                c0.379-1.809,1.222-2.725,3.233-2.508c1.668,0.181,3.38,0.129,5.062,0.017c3.001-0.203,6-0.092,8.208-3.02
                c1.159-1.533,3.539-0.971,5.352-0.848c5.136,0.352,10.259,0.871,15.376,1.421c7.712,0.828,15.416,1.737,23.706,2.679
                c-2.675,3.974-4.707,7.97-7.642,11.125c-4.493,4.829-3.84,9.159-0.987,14.549C595.561,284.486,600.083,294.003,606.904,303.025"/>
              <text transform="matrix(1 0 0 1 565 284.0371)"  > ۷</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-14">
              <path  id="TEH-14" title="منطقه ۱۴" className="tehran_map_province"  d="M637.723,337.953c-0.771,10.611-1.683,22.433-2.473,34.26c-0.357,5.351-0.487,10.715-0.695,16.074
                c-0.116,3.063-3.627,5.949-6.593,5.394c-8.348-1.565-16.551-3.8-24.937-5.22c-3.678-0.622-7.342,0.07-11.009-0.592
                c-4.443-0.801-7.478-3.727-10.663-6.413c-2.267-1.911-4.717-3.196-7.629-3.41c-1.56-0.113-2.023-0.792-1.921-2.191
                c0.569-7.635,1.081-15.273,1.714-22.904c0.347-4.186,0.053-8.391,0.717-12.586c0.333-2.104,0.913-2.796,3.047-2.984
                c10.123-0.904,20.222-2.063,30.339-3.028c7.725-0.739,15.463-1.332,23.195-1.987c0.525-0.042,1.06,0,1.58-0.078
                C637.74,331.47,637.74,331.465,637.723,337.953"/>
              <text transform="matrix(1 0 0 1 598.1045 367.75)"  > ۱۴</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-8">
              <path  id="TEH-8" title="منطقه ۸" className="tehran_map_province"  d="M701.694,273.653c0.115,1.578-0.811,1.645-1.836,1.729c-7.642,0.621-15.367,0.786-22.896,2.081
                c-5.317,0.915-10.405,3.284-15.539,5.16c-16.323,5.965-32.614,12.027-48.937,17.996c-5.365,1.964-5.319,1.845-8.104-3.169
                c-4.401-7.922-8.848-15.822-13.418-23.647c-1.394-2.384-1.814-4.306-0.13-6.838c2.975-4.465,5.648-9.143,8.235-13.851
                c1.243-2.264,2.86-2.765,5.191-2.312c15.151,2.941,30.411,5.394,45.423,8.92c9.725,2.283,19.543,2.58,29.331,3.701
                c6.347,0.729,12.759,1.467,18.509,4.75C699.815,269.482,700.985,271.464,701.694,273.653"/>
              <text transform="matrix(1 0 0 1 626.9473 279.9531)"  > ۸</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-11">
              <path  id="TEH-11" title="منطقه ۱۱" className="tehran_map_province"  d="M476.012,322.349c-0.626-2.527,0.673-3.491,3.939-3.358c11.908,0.492,23.797-0.991,35.715-0.614
                c1.76,0.055,3.532-0.409,5.305-0.575c1.758-0.168,2.396,0.429,2.078,2.386c-1.283,7.854-2.387,15.737-3.564,23.607
                c-1.58,10.566-3.167,21.131-4.753,31.696c-0.637,4.246-1.3,8.49-1.915,12.739c-0.187,1.289-0.938,1.952-2.146,1.792
                c-3.837-0.509-7.658-1.128-11.488-1.698c-1.132-0.168-1.379-0.935-1.682-1.941c-1.17-3.92-1.532-3.979-5.3-2.191
                c-2.451,1.162-4.526,2.982-7.354,3.552c-1.942,0.393-2.357,0.031-2.527-1.797c-0.514-5.526-1.231-11.032-1.805-16.554
                c-0.843-8.129-1.653-16.26-2.434-24.395C477.382,337.69,476.744,330.378,476.012,322.349"/>
              <text transform="matrix(1 0 0 1 492 358.3809)"  > ۱۱</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-13">
              <path  id="TEH-13" title="منطقه ۱۳" className="tehran_map_province"  d="M663.65,284.132c-3.366,6.164-6.565,12.309-9.299,18.691c-2.946,6.882-6.416,13.548-9.348,20.418
                c-2.258,5.287-5.828,6.958-11.086,7.241c-11.854,0.643-23.669,1.809-35.479,3.057c-7.104,0.753-14.237,1.198-21.354,1.813
                c-1.182,0.103-1.479-0.491-1.475-1.486c0.018-5.283,0.031-10.565,0.046-15.847c0.004-1.537,1.207-1.771,2.302-2.181
                c16.271-6.114,32.538-12.245,48.809-18.366c11.438-4.304,22.876-8.602,34.318-12.899C661.844,284.29,662.58,283.84,663.65,284.132"
                />
              <text transform="matrix(1 0 0 1 614.8945 321.625)"  > ۱۳</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-19">
              <path  id="TEH-19" title="منطقه ۱۹" className="tehran_map_province"  d="M484.791,443.4c-2.668,1.327-4.872,2.339-6.987,3.509c-1.366,0.756-2.06,0.65-2.96-0.815
                c-3.664-5.97-3.717-5.879-10.337-3.698c-2.472,0.813-4.572,2.123-6.362,3.955c-1.183,1.21-2.14,1.911-2.7-0.46
                c-0.143-0.6-0.551-1.135-0.818-1.709c-1.646-3.511-3.29-4.239-7.042-3.399c-1.557,0.349-1.329,1.426-1.308,2.427
                c0.032,1.641-0.507,2.727-2.326,2.608c-2.365-0.156-2.863,1.171-2.768,3.168c0.079,1.68,0.016,3.364-0.188,5.077
                c-1.528-3.017-1.693-6.378-2.235-9.586c-1.198-7.066-2.588-8.013-9.032-5.282c-0.362,0.152-0.774,0.189-1.276,0.306
                c1.694-3.381,6.935-6.922,10.524-6.921c1.856,0.002,3.171-0.576,4.421-1.901c3.694-3.911,8.119-6.985,12.206-10.449
                c4.389-3.719,7.561-8.442,10.936-13.01c3.04-4.11,6.912-7.028,11.922-8.346c0.93-0.246,1.707-0.612,2.583,0.262
                c4.317,4.322,8.496,8.74,12.162,13.661c2.188,2.94,1.683,5.689,0.166,8.42c-1.375,2.479-0.916,4.367,0.591,6.626
                c5.252,7.878,10.286,15.901,15.429,23.853c1.593,2.469,1.899,4.956,0.941,7.809c-2.636,7.868-2.562,7.893-10.993,7.893
                c-0.528,0-1.063-0.062-1.583,0.01c-3.017,0.422-4.33-0.789-3.953-3.885c0.163-1.353-0.045-2.746,0.044-4.113
                c0.155-2.442,0.025-4.511-2.851-5.571c-2.084-0.769-1.765-3.168-2.191-4.941c-0.257-1.068-0.28-2.349-1.62-2.299
                C485.043,446.678,484.855,445.321,484.791,443.4"/>
              <text transform="matrix(1 0 0 1 469 433.1426)"  > ۱۹</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-10">
              <path  id="TEH-10" title="منطقه ۱۰" className="tehran_map_province"  d="M461.982,319.052c3.735,0,6.483,0.095,9.222-0.031c1.889-0.089,2.72,0.714,2.886,2.487
                c0.862,9.312,1.737,18.62,2.606,27.932c0.479,5.126,0.78,10.276,1.503,15.37c0.396,2.803-0.398,4.738-2.661,6.171
                c-6.979,4.413-14.434,5.5-22.358,3.085c-3.323-1.013-6.646-2.027-9.948-3.101c-2.278-0.739-3.789-2.086-2.702-4.704
                c1.378-3.318,1.693-6.543,1.361-10.243c-0.387-4.293,0.784-8.751,1.44-13.111c0.938-6.215,2.211-12.351,0.535-18.7
                c-0.871-3.294,0.063-4.28,3.339-4.421C452.46,319.557,457.683,318.545,461.982,319.052"/>
              <text transform="matrix(1 0 0 1 454 349.5459)"  > ۱۰</text>
            </Link>
            <Link className="tehran_map_link" to="/tehran-zone-17">
              <path  id="TEH-17" title="منطقه ۱۷" className="tehran_map_province"  d="M479.252,381.147c0,1.057-0.116,2.129,0.024,3.166c0.259,1.878-1.116,4.748,0.92,5.431
                c2.539,0.85,5.638,0.972,8.288-0.565c1.183-0.685,2.233-1.604,3.433-2.252c1.062-0.573,2.454-1.582,3.425-0.702
                c0.873,0.792,0.43,2.418,0.228,3.646c-0.255,1.56-1.595,1.376-2.75,1.459c-2.933,0.207-5.861,0.476-8.794,0.682
                c-3.604,0.252-6.052,2.744-8.849,4.545c-1.589,1.023-3.084,2.198-4.642,3.273c-3.292,2.274-5.878,4.854-7.74,8.717
                c-3.332,6.903-8.789,12.267-15.435,16.266c-3.376,2.031-4.175,1.92-5.727-1.629c-5.963-13.64-10.147-27.865-13.664-42.298
                c-0.522-2.146,0.191-3.523,2.33-4.833c5.446-3.338,10.451-4.175,16.728-1.457c9.906,4.286,20.124,3.929,29.814-1.447
                c2.122-1.178,2.516-0.312,2.431,1.668C479.182,376.923,479.252,379.037,479.252,381.147"/>
              <text transform="matrix(1 0 0 1 445 398.583)"  > ۱۷</text>
            </Link>
        </g>
        </svg>
      </div>
    )
  }
}

export default TehranMap ;