import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article53/01.jpg";
import photo02 from "../../images/mag/article53/02.jpg";
import photo03 from "../../images/mag/article53/03.jpg";
import photo04 from "../../images/mag/article53/04.jpg";
import photo05 from "../../images/mag/article53/05.jpg";
import photo06 from "../../images/mag/article53/06.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
let text = "{قیمت منطقه‌ای*(تعداد پارکینگ تأمین شده*۲۰)}-ضریب* (مساحت ناخالص زیربنا) = هزینه جواز ساخت یک ساختمان مسکونی یک طبقه";
class Article53 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "برآورد هزینه ساخت خانه از مواردی است که برای ساخت آپارتمان و خانه‌های ویلایی مهم است که ما در هومینجا به بررسی کامل این موارد می پردازیم. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "هزینه ساخت و ساز در تهران | تخمین هزینه ساخت ساختمان | محاسبه هزینه ساخت ساختمان | هزینه ساخت خانه | هزینه ساخت ویلا | هزینه ساختمان سازی درسال ۱۴۰۲ | برآورد هزینه ساختمان سازی | روش های محاسبه هزینه ساخت و ساز | صفر تا صد محاسبه هزینه ساختمان | محاسبه آنلاین هزینه ساخت ساختمان |هزینه ساخت خانه در سال ۱۴۰۱| برآورد هزینه ساخت مسکن در تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[52].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[52].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[52].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[52].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[52].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[52].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="براورد هزینه ساخت - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[52].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برآورد هزینه ساخت خانه یکی از مواردی است که برای افراد با نیت ساخت آپارتمان، خانه‌های ویلایی و… بسیار مورد توجه است. این هزینه هرگز در سال‌های اخیر ثابت نبوده؛ چرا که مواردی چون قیمت زمین، مصالح ساختمانی، آهن آلات و… همواره نوسان و روندی افزایشی داشته است. در تعیین هزینه ساخت مسکن موارد بسیاری دخیل هستند که توجه به آن‌ها کمک می‌کند تا بتوانید هزینه ساخت هر متر مربع خانه را تا حدود زیادی پیش بینی کنید. ما در این <Link className="link" to="/1402-Tehran-Construction-Cost" target="_blank">  مقاله </Link> قصد داریم به بررسی کامل این موارد بپردازیم با ما همراه باشید.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="برآورد هزینه ساخت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  هزینه ساخت در تهران
                </figcaption>
              </figure>
              <Typography className="h2" variant="h2">
                هزینه ساخت خانه در سال ۱۴۰۱
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای این که بتوانیم بررسی خوبی از هزینه ساخت در سال ۱۴۰۲ داشته باشیم بهتر است ابتدا در مورد این هزینه‌ها در سال ۱۴۰۱ نگاهی بی‌اندازیم. بررسی‌ها نشان می‌دهد که برای ساخت یک واحد <Link className="link" to="/Listing/1183" target="_blank">  واحد آپارتمان 80 متری </Link> به طور متوسط بین ۶.۵ میلیون تا ۱۱ میلیون تومان به ازای هر متر مربع هزینه صرف می‌شود. یکی از اصلی‌ترین دلایل تعیین دامنه قیمتی تعداد طبقات است که این مبالغ برای ساخت ساختمان‌های یک طبقه تا ۱۶ طبقه در نظر گرفته شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باید به این نکته نیز توجه داشت که قیمت ساخت خانه در مناطق شهری و روستایی نیز متفاوت است. هزینه ساخت خانه در روستاها عموما کمتر از مناطق شهری است. قیمت ساخت هر متر مربع خانه در مناطق روستایی در سال ۱۴۰۱ نیز بنابر اعلام معاون بازسازی و مسکن روستایی <LinkMui className="link" href="https://www.bme.ir" target="_blank">  بنیاد </LinkMui> مسکن بین ۴.۵ تا ۵ میلیون تومان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مورد دیگری که باید به آن توجه شود این است که میزان تأثیر فاکتورهای موثر در ساخت و ساز هر سال متفاوت است. در سال ۱۴۰۱ اصلی‌ترین عامل تأثیر بر هزینه ساخت خانه قیمت آهن آلات به ویژه میلگرد بود که نوسانات قیمتی بسیاری را در سال گذشته پشت سر گذاشت.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Housing-Market-Prediction-1402" target="_blank">
                  بیشتر بخوانید : پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                عوامل تأثیرگذار بر هزینه ساخت مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهمترین عوامل تأثیر گذار بر هزینه ساخت خانه، قیمت <Link className="link" to="/Listing/46" target="_blank">  زمین </Link> و محل قرار گیری آن است. بنابراین هزینه ساخت در شهرهای بزرگ و کوچک با هم فرق داشته و حتی موقعیت قرارگیری ملک در مناطق مختلف شهری نیز می‌تواند تفاوت‌های بسیاری در شکل گیری هزینه‌ها داشته باشد. مهمترین مواردی که می‌توان به عنوان فاکتورهای تعیین هزینه ساخت خانه به آن‌ها اشاره کرد عبارتند از:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هزینه کسب جواز، <Link className="link" to="/building-certificate" target="_blank">  پروانه ساختمان </Link> و سایر مجوزهای قانونی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                قیمت تمام‌شده اسکلت ساختمان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                قیمت مصالح
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هزینه ساخت <Link className="link" to="/building-facades-types" target="_blank">  نما </Link>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هزینه <Link className="link" to="/small-house-decoration" target="_blank">  دکوراسیون </Link> داخلی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعداد طبقات
              </Typography>
              <Typography className="h2" variant="h2">
                بررسی عوامل تأثیرگذار بر قیمت ساخت و ساز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ادامه به بررسی جزئیات تأثیر هریک از عوامل موثر بر ساخت و ساخت می‌پردازیم. میزان تأثیرگذاری این عوامل ممکن است در سال‌های مختلف، متفاوت باشد. به طور مثال در یک سال قیمت زمین و در سال دیگر قیمت مصالح ساختمانی بیشترین تأثیر را داشته باشند.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت زمین
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="قیمت زمین - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قیمت زمین
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید بتوان یکی از اصلی‌ترین عوامل تأثیرگذار بر قیمت و سود ساخت و ساز را قیمت زمین دانست. عواملی چون موقعیت قرارگیری زمین و متراژ آن می‌تواند بر هزینه‌های ساخت تأثیر مستقیم داشته باشد. به طور مثال قیمت زمین در <Link className="link" to="/tehran-zones" target="_blank">  تهران </Link> نسبت به سایر شهرها بیشتر است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قیمت زمین‌ها در شهرهای کوچک و بزرگ یا مناطق شهری و روستایی متغیر است. حتی در شهرهای بزرگی چون تهران منطقه قرارگیری زمین می‌تواند بر قیمت ساخت و ساز تأثیر بگذارد. در یکی دو سال اخیر قیمت زمین در اکثر نقاط کشور با افزایش چندین درصدی همراه بوده است و بنابراین قیمت زمین را می‌توان یکی از تاثیرگذارترین عوامل بر هزینه ساخت و ساز مسکن در سال ۱۴۰۲ دانست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این زمینه زیرشاخه‌های دیگری چون طول بر زمین، دسترسی محلی، امکانات و میزان نزدیکی به فروشگاه، مراکز تفریحی، بازرگانی، مدرسه، درمانگاه و غیره و تراکم و…را باید در نظر داشت. انتخاب محله‌های در حال رشد، ملک‌های کلنگی و زمین‌های بدون بنا از جمله مواردی است که ساخت و ساز در آن‌ها با سود بسیار همراه است. برای ملک‌های کلنگی باید مواردی چون تخریب و آواربرداری را نیز در نظر داشته باشید. با این حال در مواردی که اسکلت ساختمان در حال تخریب آهن آلات مناسبی داشته باشد، می‌توان آن را جبرانی برای هزینه‌های تخریب و آواربرداری در نظر گرفت.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                هزینه جواز ساخت و سایر مجوزهای قانونی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دریافت جواز ساخت را باید یکی از پرهزینه‌ترین و طولانی‌ترین مراحل ساخت و ساز دانست. این مسئله به اندازه‌ای حائز اهمیت است که کارشناسان توصیه می‌کنند در فصول سرد سال به دنبال دریافت جواز ساخت و طی مراحل اداری آن باشید تا در فصول گرم با توجه به شرایط آب و هوایی و استفاده از روشنایی روز، پروژه ساخت را سریع‌تر و آسان‌تر پیش ببرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اولین قدمی که در اخذ جواز ساخت باید انجام داد، تحویل مدارک از جمله <Link className="link" to="/property-document" target="_blank">  سند </Link> مالکیت، کارت شناسایی مالک زمین و فیش پرداخت عوارض به شهرداری است. بعد از این مرحله بازرس شهرداری به محل احداث پروژه مراجعه کرده و تراکم و جزئیات ضروری نقشه بر اساس نظر وی تعیین و به مجری پروژه ابلاغ می‌گردد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از این مرحله باید مهندس پروژه و طراح ساختمان استخدام شده و برای تهیه نقشه‌های سازه اقدام شود. سپس نقشه‌ها به همراه برگه تعهد کارفرما و نظارت، برگه تعهد محاسبات و… به شهرداری تحویل داده می‌شود تا در نهایت جواز ساخت صادر گردد. جواز ساخت دارای دو سال اعتبار است و هزینه اخذ جواز ساخت در هر سال با افزایش ۳۰ درصدی همراه خواهد بود. باید به این نکته نیز توجه داشت که در مناطق دارای بافت فرسوده، شهرداری تخفیف‌های برای سازندگان در نظر می‌گیرد که شامل کاهش هزینه‌های کسب پروانه‌های ساختمانی می‌شود.
              </Typography>
              <Typography className="h2" variant="h2">
                هزینه جواز ساخت در سال ۱۴۰۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا برای تعیین هزینه جواز ساخت موارد زیر در نظر گرفته می‌شود:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                متراژ زمین
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مساحت ناخالص
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعداد طبقات ملک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعداد پارکینگ
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                قیمت ساختمان در منطقه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فرمول محاسبه هزینه جواز ساخت سال ۱۴۰۲ برای یک ساختمان یک طبقه عبارتند از:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                {text}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                منظور از مساحت ناخالص بنا در فرمول بالا، مجموع متراز تمامی بناهای موجود در ساختمان است.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت تمام شده اسکلت ساختمان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="قیمت اسکلت ساختمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قیمت اسکلت ساختمان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آهن یکی از محصولاتی است که در سال‌های اخیر نوسانات بسیاری را پشت سر گذاشته است. برای ساخت اسکلت ساختمان می‌توان از سازه‌های آهنی و فولادی یا سازه‌های بتنی استفاده کرد. سازه‌های بتنی عموما هزینه کمتری دارند چرا که میزان مقاطع فولادی به کار رفته در این نوع سازه‌ها بسیار کمتر است. اما باید به این نکته نیز توجه داشت که ساخت سازه‌های بتنی طولانی‌تر از سازه‌های فولادی است و بنابراین بازگشت سرمایه پروژه‌ها با سازه‌های بتنی دیرتر خواهد بود. از طرف دیگر اجرای مراحل سفت کاری و نازک کاری در سازه‌های فولادی تفاوت چندانی با سازه‌های بتنی ندارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قیمت ساخت اسکلت فلزی در فروردین سال ۱۴۰۲ متری ۲.۲ تا ۲.۷ میلیون تومان برآورد شده است اما می‌توان با سبک‌سازی سازه برای هر متر مربع ۵۰۰ هزار تومان کاهش در هزینه‌ها در نظر گرفت. این در حالی است که در تابستان ۱۴۰۱ این قیمت در حدود ۱ میلیون تومان برای هر متر مربع بوده است. حدود قیمت ساخت اسکلت‌های بتنی نیز در سال ۱۴۰۲ بین ۱.۸۰۰ تا ۲.۳۰۰ میلیون تومان برای هر متر مربع است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">
                  بیشتر بخوانید : معرفی لوکس ترین برج های منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                هزینه مصالح
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هزینه خرید مصالح را می‌توان یکی دیگر از موثرترین عوامل بر هزینه ساخت و ساز در سال ۱۴۰۲ دانست. آجر و جر و آهن، آجر و چوب، بلوک سیمانی و اسکلت چوبی رایج‌ترین مصالح ساختمانی در ایران محسوب می‌شوند. در مورد تعیین قیمت خرید مصالح مواردی چون کیفیت مصالح (معمولا از درجه یک تا سه طبقه‌بندی می‌شوند) و نوع مصالح در نظر گرفته می‌شود. بعد از فولاد، آجر، گچ و سیمان از پر مصرف‌ترین مصالح ساختمانی هستند که در یک سال اخیر قیمت ثابتی نداشته و نمی‌توان ۱۰۰ درصد قیمت آن‌ها را به شکل ثابت تعیین کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                هزینه نهاده های ساختمانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قیمت نهاده‌های ساختمانی در سال‌های اخیر همسو با تورم روندی افزایشی داشته است و یکی از عوامل مهم افزایش هزینه ساخت خانه در سال ۱۴۰۲ محسوب می‌شود. براساس آخرین داده‌های <LinkMui className="link" href="https://www.amar.org.ir" target="_blank">  مرکز آمار </LinkMui> ایران شاخص قیمت نهاده‌های ساختمانی مسکن در پاییز ۱۴۰۱ و در شهر تهران هزار و ۵۶۱ ثبت شده است. این میزان نسبت به فصل گذشته یعنی تابستان ۱۴۰۱، بالغ بر ۴/۶ درصد، نسبت به پاییز سال گذشته ۳۱/۶ درصد و نسبت به ۱۲ ماه منتهی به پاییز سال جاری (سالانه) ۳۴/۷ درصد افزایش داشته است. این در حالی است که تورم نهاده‌های ساختمانی در تابستان ۱۴۰۱ نسبت به فصل قبل از خود ۳/۴ درصد کاهش داشته است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به افزایش تورم در پایان سال ۱۴۰۱ می‌توان پیش بینی کرد که هزینه نهاده‌های ساختمانی در سال ۱۴۰۲ همچنان افزایشی بوده و هزینه‌های ساخت و ساز را افزایش دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                تعداد طبقات و واحدها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هزینه ساخت طبقات و واحدها هرساله توسط سازمان نظام مهندسی کشور اعلام می‌شود. این هزینه‌ها در سال ۱۴۰۲ بر اساس قیمت اعلام شده بر اساس این نهاد عبارتند از:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان ۱ و ۲ طبقه: ۶.۲۳۷. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان ۳ تا ۵ طبقه: ۷.۱۷۰. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان ۶ و ۷ طبقه: ۸.۲۱۵. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان۸ تا ۱۰ طبقه: ۹.۲۳۴. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان ۱۱ و ۱۲ طبقه: ۹.۷۱۷. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان ۱۳ تا ۱۵ طبقه: ۱۰.۷۱۷. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آپارتمان ۱۶ طبقه به بالا: ۱۱.۰۵۴. ۰۰۰ تومان به ازای هر مترمربع
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                هزینه نمای ساختمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای ساختمان را باید یکی از پرهزینه‌ترین بخش‌های ساخت و ساز دانست. هرچه نما مدرن‌تر باشد مطمئنا هزینه اجرای آن نیز بیشتر خواهد بود. در این مورد باید به عواملی چون برپایی و اجاره داربست، مصالح نمای ساختمان، دستمزد کارگران و… را در نظر داشت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                دکوراسیون و نمای داخلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="نمای داخلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای داخلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از نمای بیرونی باید به دکوراسیون و نمای داخلی توجه کرد. هزینه اجرای نمای داخلی نیز با توجه به المان‌هایی که برای ساخت نمای داخلی و دکوراسیون استفاده می‌شود و همچنین هزینه‌های مرتبط با طراحی، قیمت چوب، ام دی اف و… تعیین می‌گردد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                هزینه‌های پیش بینی نشده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجایی که نوسانات بازار در شرایط فعلی بسیار زیاد است، بدیهی است که هزینه‌های پیش بینی نشده بسیاری را در طول ساخت یک پروژه ساختمانی باید در نظر گرفت. مواردی چون افزایش قیمت دلار تنها یکی از عوامل تأثیرگذار بر بازار و افزایش هزینه‌های ساخت و ساز محسوب می‌شود. به این ترتیب چنین هزینه‌هایی یکی از مهمترین عوامل افزایش سرمایه لازم برای ساخت و ساز محسوب می‌شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هزینه‌هایی چون اجرای کانکس، داربست، دستمزد کارگران و… از جمله موارد ثابت و عوامل دیگری چون تأخیر در اجرای پروژه و افزایش هزینه‌های خرید مصالح و امثال آن از موارد متغیری است که باید به آن‌ها توجه کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                نحوه محاسبه هزینه ساخت و ساز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به تمام مواردی که در بالا به آن‌ها اشاره شد با یک حساب سرانگشتی ساده می‌تواند تا حدود زیادی هزینه‌های ساخت و ساز خانه را در هر شهر و با هر موقعیتی پیش بینی کرد. به علاوه وجود مواردی چون نرم‌افزار محاسبه هزینه ساختمان نیز کمک می‌کند تا این کار را ساده‌تر و دقیق‌تر انجام دهید.
              </Typography>
              <Typography className="h2" variant="h2">
                سود ساخت و ساز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هزینه‌های ساخت ساختمان و سرمایه‌گذاری در بخش مسکن نسبت به سال‌های قبل در ایران، افزایش داشته است. ضمن این که ریسک بازگشت سرمایه یا نرخ سود در این زمینه نیز با ریسک بالا همراه است. چرا که به دلیل شرایط تورمی و افزایش ناگهانی عواملی چون مصالح ساختمانی، نیروی کار و… دیگر سودهای کلان در این بخش را شاهد نیستیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از طرف دیگر جهش ۵۰۰ درصدی قیمت مسکن در یک دهه اخیر، قدرت خرید را به شدت کاهش داده و تولید و عرضه مسکن نیز دچار رکود شده است. با این حال می‌توان سودی بین ۲۰ تا ۴۰ درصد را با توجه به شرایط تأثیرگذار بر قیمت نهایی ساخت و ساز برای سازنده‌ها در نظر گرفت. اما باید توجه داشت مواردی چون تورم عمومی - مانند عوارض شهرداری و امثال آن- در حال افزایش است به طوری که عوارض شهرداری تا ۳۰۰ درصد افزایش داشته است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به موارد بیان شده قیمت زمین، عامل اصلی در میزان سود ساخت وساز به حساب می‌آید. در واقع باید گفت عاملی که ساختمان‌سازی را تبدیل به امری سودآور یا زیان‌ده می‌کند، زمین است. بنابراین اگر زمین در متراژ بالا و موقعیت مناسب باشد و بتوان براساس طرح تفصیلی، تعداد طبقات بالایی را برای ساخت و ساز در نظر گرفت، می‌توان سودی بین ۳۰ تا ۴۰ درصد هزینه ساخت ساختمان را در نظر گرفت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اصولا سود سازندگان از ساخت وساز واحدهای مسکونی بین ۳۰ تا ۴۰ درصد در مدت زمانی در حدود دو سال است. در سال‌های اخیر نمی‌توان انتظار داشت که سودهای ساخت وساز به بیش از ۴۰ درصد برسد.
              </Typography>
              <Typography className="h2" variant="h2">
                هزینه ساخت و ساز ویلا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید بتوان گفت تمام هزینه‌هایی که برای ساخت و ساز ساختمان در مناطق شهری تعریف کردیم در مورد ساخت ویلا نیز صدق می‌کند، با این تفاوت که گاهی مواردی چون افزایش هزینه‌های حمل و نقل، هزینه‌های مرتبط با خرید زمین، دریافت پروانه ساخت، متراژ، هزینه‌های مرتبط با نمای بیرونی و دکوراسیون داخلی و… در این بخش گاهی باعث افزایش هزینه‌های ساخت می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به طور مثال اگر موقعیت قرارگیری زمین در منطقه دورافتاده و با دسترسی سخت باشد، برای حمل مصالح ساختمانی باید نسبت به شهر هزینه‌های بیشتری را در نظر گرفت.
              </Typography>
              <Typography className="h2" variant="h2">
                راههای کاهش هزینه ساخت خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با تمام مطالبی که بیان کردیم، می‌توان بسیاری از هزینه‌های ساخت خانه را تا حدودی کاهش داد. در این بخش به برخی راه‌های کاهش هزینه‌های ساخت خانه اشاره می‌کنیم. این راهکارها عبارتند از:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                سپردن کار به پیمانکار
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="پیمانکار ساختمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیمانکار ساختمان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قصد دارید که خانه‌ای را شخصی‌سازی کنید بهتر است پروژه را به پیمانکار واگذار کنید. بر خلاف آنچه اکثر مالکان تصور می‌کنند که اگر خودشان پروژه را بر عهده بگیرند هزینه‌ها کمتر می‌شود، واگذاری پروژه‌ها به یک فرد با تجربه باعث صرفه جویی در هزینه‌ها می‌شود. اگر قصد ندارید که پروژه ساخت و ساز را به پیمانکار واگذار کنید می‌توانید از <LinkMui className="link" href="https://avval.ir/profile/0b136/%DA%A9%D8%A7%D8%B1-%D8%A7%D9%86%D8%B1%DA%98%DB%8C-%D9%BE%D9%88%DB%8C%D8%A7%D9%86" target="_blank">  مهندسین مشاور </LinkMui> با تجربه و کارکشته در پروژه استفاده کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                سبک‌سازی سازه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی سازه نقش مهمی در سبک‌سازی و در نهایت کاهش هزینه‌های ساخت دارد. بنابراین توصیه می‌شود پروژه ساخت را به یک شرکت محاسبات سازه با تجربه و منصف بسپارید. نکته مهمی که در این بخش باید به آن اشاره کنیم این است که بسیاری از مالکین تلاش می‌کنند تا با انجام کارهایی مثل فرار از تست خاک در هزینه‌ها صرفه جویی کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالی که تعیین نوع خاک و انجام مطالعات مرتبط با ژئوتکنیک مشخصات واقعی خاک را مشخص کرده و می‌توان در سبک‌سازی سازه گام‌های موثر و مطمئن‌تری برداشت. به این ترتیب با انجام تست خاک می‌توان در مواردی چون مصرف بتن و آهن در ساختمان صرفه جویی کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                انتخاب سازه و سقف مناسب
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مورد دیگری که برای کاهش هزینه ساخت می‌توان روی آن حساب کرد. استفاده از اسکلت بتنی به جای اسکلت فلزی است. در این صورت است که می‌توان ۲۰ تا ۳۰ درصد در اجرای اسکلت ساختمان صرفه جویی کرد. همچنین باید به این نکته توجه کرد که در دهانه‌های بلند اختلاف قیمت سازه بتنی با فلزی بیشتر می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                حذف واسطه‌ها در خرید مصالح
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین کارها برای کاهش هزینه ساخت خانه خرید عمده مصالح از درب کارخانه و حذف واسطه‌ها است. به این ترتیب مصالح را با قیمت بهتر و مناسب‌تری می‌توان خریداری کرد. در حال حاضر یکی از بهترین روش‌ها برای این کار جستجوی تولیدکننده‌ها از طریق اینترنت و برقراری ارتباط با آن‌ها است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                استفاده از فناوری‌های نوین ساخت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین راهکارها در این زمینه استفاده از جوش فورجینگ یا کوپلینگ میلگرد است. این روش کمک می‌کند که مصرف میلگرد را کاهش داد. در این زمینه شرکت‌های مشاور و مهندسین سازه می‌توانند بیشترین اطلاعات را در اختیار شما قرار دهند. ضمن این که استفاده از روش‌های جدید والپست نیز هم هزینه کمتری نسبت به روش‌های قدیمی دارد و هم اصولی‌تر است.
              </Typography>
              <Typography className="h2" variant="h2">
                محاسبه آنلاین هزینه ساخت ساختمان :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وب سایت و نرم افزار های مختلفی وجود دارد که با گرفتن اطلاعات جدول برآورد هزینه ساخت و ساز را ارایه می دهد. به علت نوسان شدید قیمت مصالح و تورم اقتصادی معمولا نرم افزار ها با خطاهای فاحشی همراه هستند . پیشنهاد هومینجا مشورت با متخصصان حوزه صنعت ساختمان به خصوص سازندگان و انبوه سازان است. استفاده از تجارب و تخصصان سازندگان بسیاری از موارد را برای شما آسان وقابل اجرا می کند.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم تحریریه هومینجا همواره تلاش کرده بهترین و به روز ترین اطلاعات را به صورت رایگان در اختیار تمام مردم ایران قرار دهد. در این مقاله از بلاگ هومینجا سعی کردیم تا با نحوه محاسبه هزینه ساخت و ساز ساختمان در تهران در سال ۱۴۰۲ را به طور مفصل بیان کنیم.
              </Typography>
              <Typography className="h4" variant="h4">
                با هومینجا یک کلیک تا قرارداد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین مطالعه مقالات زیر به افزایش اطلاعات شما کمک شایانی خواهد کرد.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Midex'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معرفی نمایشگاه میدکس
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/premade-canopy'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    سایبان پیش ساخته چیست
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/real-estate-fraud'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    انواع کلاهبرداری در معاملات ملک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/accent-wall'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    دیوار شاخص چیست
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/laundry-room'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    لاندری روم چیست
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/house-tracking-code'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    کد رهگیری املاک چیست
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/transferring-property'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    مراحل سند زنی ملک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Business-Right'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    سرقفلی مغازه به زبان ساده
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/top-5-real-estate-sites'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    سایت های برتر خرید و فروش املاک، آپارتمان، خانه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Interior-architecture-and-interior-design'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معماری داخلی و طراحی داخلی چیست و چه تفاوتی دارند
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("بازار مسکن ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  بازار مسکن

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article53;