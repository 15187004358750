import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import warehouse from "../images/features/warehouseIcon.png";
import elevator from "../images/features/elevatorIcon.png";
import parkingLot from "../images/features/parkingIcon.png";
import cctv from "../images/features/cctvIcon.png";
import welfareIcon from "../images/features/welfare.png";
import kitchen from "../images/features/kitchen.png";
import "../styles/home.css";
import { Link } from "react-router-dom";
import { convertToPersianNumber, separator } from "./materilas/vmp";

class Vip2 extends React.Component {

  isMount = false;

  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  checkFeatures = (field,info) => {
    if(info[field]){
      let fieldData = info[field];
      for(let i in fieldData){
        if(i !== "id" && i !== "listing_id"){
          if(parseInt(fieldData[i])){
            return true
          }
        }else{
          continue
        }
      }
      return false;
    }else{
      return false;
    }
  }
  
  createVip = () => {
    let show = [];
    let vipWithoutArchived = [];
    for(let i = 0 ; i < this.props.vip.length ; i++){
      if(this.props.vip[i].status_id !== 5){
        vipWithoutArchived.push(this.props.vip[i]);
      }
    }
    vipWithoutArchived.forEach((element,index) => {
      let isEven = index % 2 === 0 ? true : false ;
      show.push(
        <Button key={index} component={Link} to={"/Listing/" + element.id} disableRipple={true} target="_blank"
          sx={{padding:{xs:"0",md:"30px 0 0 0"},minWidth:"100%",minHeight:"100%",display:"block","&:hover":{bgcolor:"transparent"},color:"black"}}  
        >
          <Box  className={isEven ? "vip2-hover-top" : "vip2-hover-bottom"} 
                sx={{ paddingRight:{xs:"8%", md:isEven ? "8%" : "0"}, paddingLeft:{xs:"8%",md:isEven?"0%":"8%"},paddingTop:"40px",paddingBottom:{xs:"0",md:"40px"},
                      cursor: "pointer",transition:"all 0.9s",
                      backgroundImage:{
                        xs: "linear-gradient(0deg, rgba(1, 199, 233, 0.3) -0.08%, rgba(1, 199, 233, 0) 100%)",
                        md: isEven ?
                              "linear-gradient(to left, rgba(1, 199, 233, 0.3),rgba(1, 199, 233, 0))"
                            : "linear-gradient(to right, rgba(1, 199, 233, 0.3),rgba(1, 199, 233, 0))"},
                      backgroundRepeat:"no-repeat",backgroundPosition:{xs:"0px 0px",md:isEven?"-750px 0px":"750px 0px"},"&:hover":{backgroundPosition:"0px 0px"}
                    }}
          >
            <Grid container sx={{flexDirection:{xs:"row",md: isEven ? "row-reverse" :"row"}}}>
              <Grid item xs={12} md={8} sx={{display:"flex",alignItems:"center",justifyContent:isEven ? "flex-end" : "flex-start"}}>
                <Box className="vip2-img"
                  sx={{width:{xs:"100%",md:"98%"}, height:{xs:"200px",md:"400px",lg:"500px",xl:"600px"}, backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"center", 
                      backgroundImage:`url(${element.large.length !== 0 ? element.large[0].path : ""})`
                  }}
                >
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sx={{direction:isEven ? "rtl" : "ltr"}}>
                <Box sx={{width:{xs:"100%", md:"70%"}, padding:{xs:"0", md:"50px 0 90px 0"}}}>
                  <Box sx={{textAlign:{xs:"center", md:"none", marginBottom:{xs:"0", md:"130px"}}}}>
                    <Grid sx={{width:"100%"}}>
                      <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}}
                          text={element.estate.des+" "+convertToPersianNumber(element.area)+" متری "+element.neighbourhood.name}
                          sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" },float:{xs:"none", md:"right"}, margin:{xs:"20px 0 0 0", md:"20px 0"},fontWeight:"700",width:"100%"}}
                      ></HomeinjaTypo>
                    </Grid>
                    {element.show_price ?
                      element.transaction_id === 1 ?
                        <Box sx={{direction:"rtl"}}>
                          <Typography 
                            sx={{margin:{xs:"0",md:"10px 0",lg:"20px 0",xl:"30px 0"},fontFamily:"shabnam", 
                                fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                          >
                            قیمت:
                          </Typography>
                          <Typography sx={{margin:{xs:"0",md:"10px 0",lg:"20px 3px",xl:"30px 5px"},fontFamily:"shabnam",fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",
                                          display:"inline-block"}}
                          >
                            { parseInt(element.total_price) ?
                                `${convertToPersianNumber(separator(element.total_price))} تومان`
                              : "توافقی"
                            }
                          </Typography>
                        </Box>
                      : element.transaction_id === 2 ?
                          <Box sx={{direction:"rtl"}}>
                            <Grid sx={{width:"100%"}}>
                              <Typography 
                                sx={{margin:{xs:"0",md:"2px 0",lg:"5px 0",xl:"10px 0"},fontFamily:"shabnam",
                                    fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                              >
                                رهن:
                              </Typography>
                              { parseInt(element.mortgage) !== 0 ?
                                  <HomeinjaTypo price={parseInt(element.mortgage)} 
                                    sx={{margin:{xs:"0",md:"2px 0",lg:"5px 3px",xl:"10px 5px"}, fontFamily:"shabnam",
                                      fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                                  />
                                : <Typography 
                                    sx={{margin:{xs:"0",md:"2px 0",lg:"5px 3px",xl:"10px 5px"},fontFamily:"shabnam", 
                                        fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                                  >
                                    توافقی 
                                  </Typography>
                              }
                            </Grid>
                            <Grid sx={{width:"100%"}}>
                              <Typography 
                                sx={{margin:{xs:"0",md:"2px 0",lg:"5px 0",xl:"10px 0"},fontFamily:"shabnam", 
                                    fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                              >
                                اجاره:
                              </Typography>
                              { parseInt(element.rent) !== 0 ?
                                  <HomeinjaTypo price={parseInt(element.rent)} 
                                    sx={{margin:{xs:"0",md:"2px 0",lg:"5px 3px",xl:"10px 5px"}, fontFamily:"shabnam",
                                      fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                                  />
                                : <Typography 
                                    sx={{margin:{xs:"0",md:"2px 0",lg:"5px 3px",xl:"10px 5px"},fontFamily:"shabnam", 
                                        fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                                  >
                                    توافقی 
                                  </Typography>
                              }
                            </Grid>  
                          </Box>  
                        : <Box sx={{direction:"rtl"}}>
                            <Typography 
                              sx={{margin:{xs:"0",md:"10px 0",lg:"20px 0",xl:"30px 0"},fontFamily:"shabnam", 
                                  fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight:"700",display:"inline-block"}}
                            >
                              پیش خرید:
                            </Typography>
                            <Typography sx={{margin:{xs:"0",md:"10px 0",lg:"20px 3px",xl:"30px 5px"}, fontFamily:"shabnam",fontSize:{xs:"15px",md:"20px",lg:"25px"},
                                            fontWeight:"700",display:"inline-block"}}
                            >
                              { parseInt(element.prebuy) ?
                                  `${element.prebuy} تومان`
                                : "توافقی"
                              }
                            </Typography>
                          </Box>
                    : <Typography sx={{margin:{xs:"0",md:"10px 0",lg:"20px 3px",xl:"30px 5px"},fontFamily:"shabnam"
                      ,fontSize:{xs:"15px",md:"20px",lg:"30px"},fontWeight:"700"}}>
                      قیمت : توافقی
                      </Typography>
                    }
                  </Box>
                  <Grid item container columns={{ xs: 12, md: 12 }} spacing={{xs:0.5 ,md:1.6}} justifyContent="center" sx={{padding:{xs:"15px 0",md:"0"}}}>
                    {this.checkFeatures("safety",element) ?
                      <Grid item xs={2} md={4}>
                        <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                          <Typography component={"img"} src={cctv} sx={{width:"100%", height:"auto"}} alt="feature"></Typography>
                        </Box>
                      </Grid>
                      : <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={cctv} sx={{width:"100%", height:"auto",opacity:0.2}} alt="feature"></Typography>
                          </Box>
                        </Grid>
                    }
                    {this.checkFeatures("welfare",element) ?
                      <Grid item xs={2} md={4}>
                        <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                          <Typography component={"img"} src={welfareIcon} sx={{width:"100%", height:"auto"}} alt="feature"></Typography>
                        </Box>
                      </Grid>
                      : <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={welfareIcon} sx={{width:"100%", height:"auto",opacity:0.2}} alt="feature"></Typography>
                          </Box>
                        </Grid>
                    }
                    {element.elevator ?
                      <Grid item xs={2} md={4}>
                        <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                          <Typography component={"img"} src={elevator} sx={{width:"100%", height:"auto"}} alt="feature"></Typography>
                        </Box>
                      </Grid>
                      : <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={elevator} sx={{width:"100%", height:"auto",opacity:0.2}} alt="feature"></Typography>
                          </Box>
                        </Grid>
                    }
                    {element.parking_num ?
                      <Grid item xs={2} md={4}>
                        <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                          <Typography component={"img"} src={parkingLot} sx={{width:"100%", height:"auto"}} alt="feature"></Typography>
                        </Box>
                      </Grid>
                      : <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={parkingLot} sx={{width:"100%", height:"auto",opacity:0.2}} alt="feature"></Typography>
                          </Box>
                        </Grid>
                    }
                    {this.checkFeatures("kitchen",element) ?
                      <Grid item xs={2} md={4}>
                        <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                          <Typography component={"img"} src={kitchen} sx={{width:"100%", height:"auto"}} alt="feature"></Typography>
                        </Box>
                      </Grid>
                      : <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={kitchen} sx={{width:"100%", height:"auto",opacity:0.2}} alt="feature"></Typography>
                          </Box>
                        </Grid>
                    }
                    {element.store_num ?
                      <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={warehouse} sx={{width:"100%", height:"auto"}} alt="feature"></Typography>
                          </Box>
                      </Grid>
                      : <Grid item xs={2} md={4}>
                          <Box className="vip2-hover-icon" sx={{bgcolor:{xs:"white" , md:"#F1F2F2"}, borderRadius:"4px" , height:"95%"}}>
                            <Typography component={"img"} src={warehouse} sx={{width:"100%", height:"auto",opacity:0.2}} alt="feature"></Typography>
                          </Box>
                      </Grid>
                    }
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Button>
      )
    });
    return show;
  }

  render() {
    return (
      <>
        { this.props.vip === null ? <Box></Box>
          : <Typography variant='h2' component='h2' align='center'
              sx={{fontSize:{xs:'16px',md:"32px"},fontWeight:"700",fontFamily:"shabnam",margin:{xs:"20px 0",md:"56px 0 20px 0"},color:"#003249"}}
            >
              املاک منتخب هفته
            </Typography>
        }
        { this.props.vip === null ? <Box></Box> : this.createVip()}
      </>  
    );
  }
}

export default Vip2;
