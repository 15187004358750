import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { baseUrl } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class RequestConvert extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        giftShow:false,
        openAlert:false,
        message:'',
        user:props.user,
        radioValue:0,
        typeAlert: 3
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'تبدیل کاربری در وب سایت املاک هومینجا'
    if (this.isMount){
      
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false})
  }

  onChangeRadio = (event) =>{
    this.setState({radioValue:event.target.value})
  }

  onClickRequest = () =>{
    if (this.state.radioValue !== 0){
      this.setState({giftShow:true});
      this.convertRequest();
    }
    else{
      this.setState({openAlert:true,message:'لطفا یکی از انواع تبدیل کاربری را انتخاب نمایید.'})
    }
  }

  convertRequest = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/convert/create',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        type_id:this.state.radioValue,
      }
    }))
    .then(() => {
      this.setState({openAlert:true,message:'درخواست تغییر کاربری شما با موفقیت ثبت گردید.',giftShow:false,radioValue:0,typeAlert:1})
    })
    .catch((err) => {
      switch (err.response.status) {
        case 409:
          this.setState({openAlert:true,message:'شما دارای درخواست تغییر کاربری فعال میباشید و عملیات درخواست مجدد مجاز نمیباشد.',giftShow:false,radioValue:0,typeAlert:2})
          break;
        default:
          this.setState({openAlert:true,message:'عملیات با خطا مواجه شد لطفا بعد مجدد متحان نمایید یا با پشتیبانی تماس بگیرید.',giftShow:false,radioValue:0,typeAlert:2})
          break;
      }
    });
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواست تبدیل کاربری</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >    
          <Grid item xs={10} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Typography style={{fontFamily:'shabnam',fontSize:'19px',fontWeight:'bold',color:'#01A7C4'}}>{'نوع کاربری شما :'}</Typography>
            <Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold',marginRight:3}}>{this.state.user.type.description}</Typography>
          </Grid>
          <Grid item xs={10} 
                style={{border:'1px solid #00C8E9',boxShadow:'0px 0px 4px rgba(0, 200, 233, 0.14)',borderRadius:16,width:'100%',marginTop:80,
                        paddingRight:'5%',paddingLeft:'5%',display:'flex',flexDirection:'column'}}
          >
            <Typography style={{fontFamily:'shabnam',fontSize:'19px',fontWeight:'bold',marginTop:40}}>{'تبدیل کاربری به : '}</Typography>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',width:'100%',marginTop:70}}>
              <FormControl style={{width:'100%'}}>
                  {
                    this.state.user.type.id === 1 ?
                      <RadioGroup row value={this.state.radioValue} onChange={this.onChangeRadio}
                          style={{width:'100%',display:'flex',justifyContent:'space-around',direction:'initial',fontFamily:'shabnam',marginBottom:40}}
                      >
                        <FormControlLabel value={4} control={<Radio/>} 
                          label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'مشاور هومینجا'}</Typography>}/>
                        <FormControlLabel value={3} control={<Radio/>} 
                          label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'مشاور مستقل'}</Typography>}/>
                        <FormControlLabel value={2} control={<Radio/>} 
                          label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'صاحب ملک'}</Typography>}/>
                      </RadioGroup>
                    :
                      this.state.user.type.id === 2 ? 
                      <RadioGroup row value={this.state.radioValue} onChange={this.onChangeRadio}
                          style={{width:'100%',display:'flex',justifyContent:'space-around',direction:'initial',fontFamily:'shabnam',marginBottom:40}}
                      >
                        <FormControlLabel value={4} control={<Radio/>} 
                          label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'مشاور هومینجا'}</Typography>}/>
                        <FormControlLabel value={3} control={<Radio/>} 
                          label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'مشاور مستقل'}</Typography>}/>
                        <FormControlLabel value={2} control={<Radio/>} 
                          label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'عادی'}</Typography>}/>
                      </RadioGroup>
                      : 
                        this.state.user.type.id === 3 ?
                        <RadioGroup row value={this.state.radioValue} onChange={this.onChangeRadio}
                            style={{width:'100%',display:'flex',justifyContent:'space-around',direction:'initial',fontFamily:'shabnam',marginBottom:40}}
                        >
                          <FormControlLabel value={4} control={<Radio/>} 
                            label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'مشاور هومینجا'}</Typography>}/>
                          <FormControlLabel value={3} control={<Radio/>} 
                            label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'صاحب ملک'}</Typography>}/>
                          <FormControlLabel value={2} control={<Radio/>} 
                            label={<Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold'}}>{'عادی'}</Typography>}/>
                        </RadioGroup>
                        :
                          <Typography style={{fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:40,textAlign:'center',width:'100%'}}>
                            {'برای تغییر نوع کاربری خود باید با پشتیبان تماس بگیرید.'}
                          </Typography>
                  }
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={10} style={{display:'flex',flexDirection:'column',width:'100%',marginTop:30}}>
            <Typography style={{fontFamily:'shabnam',fontSize:'19px',fontWeight:'bold',color:'#01A7C4',width:'100%'}}>{'کاربر عادی:'}</Typography>
            <Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold',marginRight:3,textAlign:'justify',width:'100%'}}>
              {`پس از ثبت نام، پروفایل شما بصورت کاربر عادی می باشد که اعتبار اولیه 10هزار تومان و اعتبار
               ماهانه در صورت استفاده از اعتبار اولیه 10هزار تومان تعلق می گیرد. کاربری عادی مخصوص افرادی است که حداکثر 3 آگهی برای انتشار دارند.`}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{display:'flex',flexDirection:'column',width:'100%',marginTop:30}}>
            <Typography style={{fontFamily:'shabnam',fontSize:'19px',fontWeight:'bold',color:'#01A7C4',width:'100%'}}>{'صاحب ملک:'}</Typography>
            <Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold',marginRight:3,textAlign:'justify',width:'100%'}}>
              {`در صورتیکه مالک چندین خانه هستید یا جز انبوه سازان می باشید پنل صاحب ملک مناسب شما می باشد.
                در صورت استفاده از اعتبار اولیه ماهانه 100هزار تومان حساب شما شارژ می شود   .        
                هر صاحب ملک می تواند 50 آگهی فعال داشته باشد. 
                عکاسی از ملک شما و تهیه کاتالوگ از مزایای این نوع کاربری است. همچنین قابلیت تعیین زمان بازدید ملک در پنل شما وجود دارد. عقد قرارداد با 20درصد تخفیف تنظیم می شود    .
              `}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{display:'flex',flexDirection:'column',width:'100%',marginTop:30}}>
            <Typography style={{fontFamily:'shabnam',fontSize:'19px',fontWeight:'bold',color:'#01A7C4',width:'100%'}}>{'مشاوران مستقل:'}</Typography>
            <Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold',marginRight:3,textAlign:'justify',width:'100%'}}>
              {`در صورتیکه بعنوان مشاور در آژانس های املاک مشغول به کار هستید می توانید با تبدیل کاربر عادی به مشاور مستقل فروش خود را افزایش دهید.
                در صورت استفاده اعتبار اولیه ماهانه 200 هزار تومان به شما اعتبار ماهانه تعلق می گیرد .  
                مشاورین مستقل در سال می توانند 500 اگهی فعال داشته باشند.
              `}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{display:'flex',flexDirection:'column',width:'100%',marginTop:30}}>
            <Typography style={{fontFamily:'shabnam',fontSize:'19px',fontWeight:'bold',color:'#01A7C4',width:'100%'}}>{'مشاوران وابسته:'}</Typography>
            <Typography style={{fontFamily:'shabnam',fontSize:'18px',fontWeight:'bold',marginRight:3,textAlign:'justify',width:'100%'}}>
              {` در صورتیکه تمایل دارید بعنوان مشاور املاک به فعالیت بپردازید  می توانید هم به صورت حضوری و هم غیر حضوری با عقد قرارداد به تیم مشاورین هومینجا بپیوندید.
                  اعتبارهای نامحدود برای ثبت آگهی و در اختیار گذاشتن فایل های درخواست مشتریان برای مشاورین هومینجا امکانپذیر است. 
                  مشاورین وابسته دسترسی رایگان به بانک فایل هومینجا دارند.
                  همچنین از هر قراردادی که با همکاری شما تنظیم شود پورسانت دریافت خواهید کرد .  
                هر مشاور وابسته در سال می تواند 1500 آگهی فعال داشته باشد و در صورت نیاز و فعالیت بالای مشاور قابل ارتقا می باشد.
              `}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{width:'100%',display:'flex',justifyContent:'center',marginTop:100}}>
              <Button onClick={this.onClickRequest} sx={{width:{md:'50%',xs:'80%'}}}
                      style={{backgroundColor:'#00C8E9',borderRadius:8,height:'50px',color:'black',
                              fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}>
                {'ثبت درخواست'}
              </Button>
            </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
      </Grid>
    )
  };
}

export default RequestConvert;