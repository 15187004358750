import { Button, Grid, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import backgroundImage from "../../images/tehranZones/zone01/zone01.jpg";
import ShowCityLocation from "../materilas/showCityLocation";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link } from "react-router-dom";
import { setCanonicalTag } from "../materilas/vmp";
import articleImage02 from "../../images/mag/article02/01.jpg";
import articleImage01 from "../../images/mag/article01/01.jpg";
import articleImage08 from "../../images/mag/article08/01.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/tehranZones.css"


class Zone10 extends React.Component {

  prevPositionY = 0;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      openLinks: false,
    }
  }

  componentDidMount() {
    document.title = "منطقه ۱۰ تهران - نقشه - پارک - مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا"
    document.getElementById("main-heading").innerHTML = "منطقه 10 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه ده";
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه منطقه 10 شهرداری تهران هستید. در این صفحه از سایت املاک هومینجا اطلاعاتی نظیر : محله ها و خیابان های منطقه ده، قیمت ملک و آپارتمان و هر آنچه در مورد منطقه ده تهران نیاز دارید، رایگان در اختیار شما می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی کامل منطقه ده شهر تهران | بررسی منطقه 10 تهران | نقشه منطقه 10 تهران بزرگ | امکانات منطقه 10 | منطقه 10 کجاست | محدوده منطقه ده شهرداری | خیابان های منطقه ده | قیمت ملک در منطقه ده | خرید آپارتمان در منطقه 10 | رهن و اجاره آپارتمان در منطقه 10 | فروش آپارتمان منطقه 10 | صفر تا صد منطقه ده | نقشه ناحیه های منطقه 10 | بهترین محله های منطقه 10 | قیمت خانه کلنگی منطقه 10 | شهرداری منطقه 10 | راهنمای منطقه 10 تهران | قیمت خانه ویلایی منطقه 10 | آگهی املاک منطقه 10 | جستجوی ملک در منطقه 10 | سرمایه گذاری در املاک منطقه ده";
    setCanonicalTag();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkPos);
  }


  onClickMenu = (event) => {
    event.preventDefault();
    let toggle = !this.state.openMenu;
    this.setState({ openMenu: toggle, anchorEl: event.currentTarget });
    this.closeMenuScroll();
  }

  onCloseMenu = () => {
    this.setState({ openMenu: false });
    window.removeEventListener("scroll", this.checkPos);
    this.prevPositionY = 0;
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  closeMenuScroll = () => {
    this.prevPositionY = window.scrollY;
    window.addEventListener("scroll", this.checkPos);
  }

  checkPos = () => {
    let currentPos = window.screenY;
    if (currentPos > this.prevPositionY || currentPos < this.prevPositionY) {
      this.onCloseMenu()
    }
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid sx={{ width: "100%", height: { xs: "200px", md: "400px" }, background: `url(${backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <Grid sx={{
            maxWidth: "1200px", margin: "auto", display: "flex", justifyContent: { xs: "center", md: "flex-start" }, alignItems: "end", height: "100%",
            padding: { xs: "10px 10px 2% 10px", lg: "0 0 2% 0" }
          }}>
            <Grid sx={{ bgcolor: "#00000075", padding: "10px 80px", borderRadius: "8px" }}>
              <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "25px", fontWeight: "bold" }}>
                منطقه ۱۰
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: "1200px", margin: "auto", padding: { xs: "10px", lg: "0" } }}>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h2">
            منطقه ۱۰ تهران
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            منطقه ۱۰ شهرداری تهران در مرکز شهر تهران واقع شده است.جمعیت ۴۰۰ هزار نفری در مساحت ۸ کیلومتری مربع باعت ایجاد تراکم بسیار
            شده است. این تراکم ۴ برابر استاندارد و ۲ برابر میانگین تراکم در شهر تهران است. به منطقه ۱۰ شهر تهران از
            شمال به خیابان <Link to={"/Azadi"} className="zone_text_link" target="_blank">آزادی</Link> و <Link to={"/tehran-zone-2"} className="zone_text_link" target="_blank">منطقه ۲</Link>، از جنوب
            به خیابان <Link to={"/Listings/buy_tehran_ghazvin"} className="zone_text_link" target="_blank">قزوین</Link> و <Link to={"/tehran-zone-17"} className="zone_text_link" target="_blank">منطقه ۱۷</Link>، از شرق
            به بزرگراه <Link to={"/Listings/buy_tehran_navab"} className="zone_text_link" target="_blank">شهید نواب صفوی</Link> و <Link to={"/tehran-zone-11"} className="zone_text_link" target="_blank">منطقه ۱۱</Link> و
            از غرب به بزرگراه یادگار امامپ،
            خیابان هرمزان و <Link to={"/tehran-zone-9"} className="zone_text_link" target="_blank">منطقه ۹</Link> محدود می شود.
            <br />
            از جمله محله های منطقه ۱۰ تهران میتوان به
            مواردی
            چون <Link to={"/Listings/rent_tehran_jaihoon"} className="zone_text_link" target="_blank">جیحون</Link>
            ، <Link to={"/Listings/buy_tehran_zanjan"} className="zone_text_link" target="_blank">زنجان</Link>
            ، <Link to={"/Listings/buy_tehran_solaimani"} className="zone_text_link" target="_blank">سلیمانی</Link>
            ، <Link to={"/Listings/rent_tehran_taimurite"} className="zone_text_link" target="_blank">تیموری</Link>
            ، <Link to={"/Listings/buy_tehran_karun"} className="zone_text_link" target="_blank">کارون</Link>
            ، <Link to={"/Listings/buy_tehran_hashemi"} className="zone_text_link" target="_blank">هاشمی</Link>
            ، <Link to={"/Listings/buy_tehran_komail"} className="zone_text_link" target="_blank">کمیل</Link>
            ، <Link to={"/Listings/rent_tehran_rudaki"} className="zone_text_link" target="_blank">رودکی</Link>
            ، <Link to={"/Listings/rent_tehran_beryanak"} className="zone_text_link" target="_blank">بریانک</Link>
            ، <Link to={"/Listings/buy_tehran_shamshiri"} className="zone_text_link" target="_blank">شمشیری</Link>
            ، <Link to={"/Listings/buy_tehran_salsabil"} className="zone_text_link" target="_blank">سلسبیل</Link>،
            خیابان جمهوری اسلامی، <Link to={"/Listings/buy_tehran_malekeashtar"} className="zone_text_link" target="_blank">مالک اشتر</Link> و <Link to={"/Listings/rent_tehran_haftchenar"} className="zone_text_link" target="_blank">هفت چنار</Link> اشاره نمود.
          </Typography>
          <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h3">
              منطقه ده تهران برروی نقشه
            </Typography>
            <Grid sx={{ width: "100%", height: "400px", borderRadius: "15px", overflow: "hidden", display: { xs: "none", md: "block" } }}>
              <ShowCityLocation
                positions={
                  [
                    [35.70066212275495, 51.37790679931641],
                    [35.70017421349628, 51.35499000549317],
                    [35.69627083194685, 51.3552474975586],
                    [35.6950858390275, 51.3567066192627],
                    [35.68386238603814, 51.35404586791992],
                    [35.676402447539196, 51.35456085205079],
                    [35.67096392203554, 51.35215759277344],
                    [35.66789587237085, 51.36314392089844],
                    [35.667059111088115, 51.3688087463379],
                    [35.67096392203554, 51.38151168823243],
                  ]
                }
                position={
                  [35.682916, 51.367490]
                }
                zoom={13}
              />
            </Grid>
            <Grid sx={{ width: "100%", height: "300px", borderRadius: "15px", overflow: "hidden", display: { xs: "block", md: "none" }, margin: "auto" }}>
              <ShowCityLocation
                positions={
                  [
                    [35.70066212275495, 51.37790679931641],
                    [35.70017421349628, 51.35499000549317],
                    [35.69627083194685, 51.3552474975586],
                    [35.6950858390275, 51.3567066192627],
                    [35.68386238603814, 51.35404586791992],
                    [35.676402447539196, 51.35456085205079],
                    [35.67096392203554, 51.35215759277344],
                    [35.66789587237085, 51.36314392089844],
                    [35.667059111088115, 51.3688087463379],
                    [35.67096392203554, 51.38151168823243],
                  ]
                }
                position={
                  [35.682916, 51.367490]
                }
                zoom={13}
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            پارک‌های منطقه ۱۰ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            بوستان تهرانی ، بوستان هفت چنار، بوستان رضوان.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز درمانی منطقه ۱۰ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            بیمارستان شهریار ، بیمارستان بابک ، بیمارستان زنان و زایمان لولاگر ، بیمارستان اقبال ، بیمارستان آزادی.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            سینما‌های منطقه ۱۰ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            سینما کارون ، سینما جی.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            محله های منطقه 10 تهران:
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            قصرالدشت ، آزادی ، آذربایجان ، بریانک ، سلسبیل ، دامپزشکی.
          </Typography>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={(event) => { this.onClickMenu(event) }}
              sx={{ margin: "20px 0", border: "2px solid #d7d7d7", padding: "10px 40px", boxShadow: "0 2px 2px 0 #ebebeb", borderRadius: "8px" }}
            >
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "black" }}>
                سایر مناطق تهران
              </Typography>
              <KeyboardArrowDownRoundedIcon sx={{ color: "gray !important" }} />
            </Button>
            <Menu
              open={this.state.openMenu}
              onClose={this.onCloseMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ "& .MuiPaper-root": { maxHeight: "300px", overflow: "scroll-y" } }}
            >
              <MenuItem>
                <Link to={'/tehran-zone-1'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-2'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-3'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-4'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-5'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-6'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-7'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-8'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-9'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-10'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu} sx={{ padding: "8px 40px" }}> منطقه ۱۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-11'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-12'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-13'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-14'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-15'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-16'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-17'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-18'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-19'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-20'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-21'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-22'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۲</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 0 0" }}>
              مقالات مرتبط
            </Typography>
            <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Link to="/north-of-Tehran-cafe" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage02})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        کافه های شمال تهران
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/Midex" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage01})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        نمایشگاه میدکس چیست؟
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/fengshui" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage08})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        فنگ شویی چیست؟ از کاربرد علم فنگ شویی در خانه چه می دانید؟
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
          <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
            <Link to={'/Listings/buy_tehran_beryanak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بریانک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_beryanak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بریانک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_solaimani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سلیمانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_solaimani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سلیمانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_taimurite'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در تیموری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_taimurite'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در تیموری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shabirij'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شبیری جی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shabirij'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شبیری جی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{
            bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "5600px" : "0px", md: this.state.openLinks ? "1850px" : "0px" }
            , transition: "max-height 0.6s linear", overflow: "hidden"
          }}
          >
            <Link to={'/Listings/buy_tehran_salsabil'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سلسبیل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_salsabil'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سلسبیل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_haftchenar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در هفت چنار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_haftchenar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در هفت چنار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_zanjanjonubi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در زنجان جنوبی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_zanjanjonubi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در زنجان جنوبی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_hashemi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در هاشمی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_hashemi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در هاشمی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_karun'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کارون
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_karun'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کارون
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_azarbayejan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آذربایجان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_azarbayejan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آذربایجان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_azadite'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آزادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_azadite'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آزادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_emmamkhomaini'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در امام خمینی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_emmamkhomaini'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در امام خمینی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_jaihoon'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در جیحون
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_jaihoon'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در جیحون
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_hesamadin'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در حسام الدین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_hesamadin'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در حسام الدین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_khosh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در خوش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_khosh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در خوش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_dampezeshki'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دامپزشکی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_dampezeshki'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دامپزشکی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_rudaki'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در رودکی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_rudaki'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در رودکی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sina'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سینا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sina'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سینا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_malekeashtar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در مالک اشتر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_malekeashtar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در مالک اشتر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ghasroldasht'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در قصرالدشت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ghasroldasht'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در قصرالدشت
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_komail'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کمیل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_komail'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کمیل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_navab'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در نواب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_navab'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در نواب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ayatsaeedi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آیت الله سعیدی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ayatsaeedi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آیت الله سعیدی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              ما در این صفحه به معرفی  منطقه 10 تهران – نقشه – پارک – مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا می پردازیم.
              همچنین منطقه 10 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه ده را مورد بررسی قرار می دهیم.
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Zone10;