import { Button, Grid, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import backgroundImage from "../../images/tehranZones/zone01/zone01.jpg";
import ShowCityLocation from "../materilas/showCityLocation";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Link } from "react-router-dom";
import { setCanonicalTag } from "../materilas/vmp";
import articleImage15 from "../../images/mag/article15/01.jpg";
import articleImage20 from "../../images/mag/article20/01.jpg";
import articleImage26 from "../../images/mag/article26/01.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/tehranZones.css"


class Zone22 extends React.Component {

  prevPositionY = 0;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      openLinks: false,
    }
  }

  componentDidMount() {
    document.title = "منطقه ۲۲ تهران - نقشه - پارک - مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا"
    document.getElementById("main-heading").innerHTML = "منطقه 22 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه بیست و دو";
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه منطقه 22 شهرداری تهران هستید. در این صفحه از سایت املاک هومینجا اطلاعاتی نظیر : محله های منطقه بیست و دو، قیمت ملک و آپارتمان و هر آنچه در مورد منطقه بیست و دو تهران نیاز دارید، رایگان در اختیار شما می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی کامل منطقه یک شهر تهران | بررسی منطقه 1 تهران | نقشه منطقه 1تهران بزرگ | امکانات منطقه 1 | منطقه 1 کجاست | محدوده منطقه یک شهرداری | خیابان های منطقه یک | قیمت ملک در منطقه یک | خرید آپارتمان در منطقه 1 | رهن و اجاره آپارتمان در منطقه 1 | فروش آپارتمان منطقه 1 | صفر تا صد منطقه یک | نقشه ناحیه های منطقه 1 | بهترین محله های منطقه 1 | قیمت خانه کلنگی منطقه 1 | شهرداری منطقه 1 | راهنمای منطقه 1 تهران | قیمت خانه ویلایی منطقه 1 | آگهی املاک منطقه 1 | جستجوی ملک در منطقه 1 | سرمایه گذاری در املاک منطقه یک";
    setCanonicalTag();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.checkPos);
  }


  onClickMenu = (event) => {
    event.preventDefault();
    let toggle = !this.state.openMenu;
    this.setState({ openMenu: toggle, anchorEl: event.currentTarget });
    this.closeMenuScroll();
  }

  onCloseMenu = () => {
    this.setState({ openMenu: false });
    window.removeEventListener("scroll", this.checkPos);
    this.prevPositionY = 0;
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  closeMenuScroll = () => {
    this.prevPositionY = window.scrollY;
    window.addEventListener("scroll", this.checkPos);
  }

  checkPos = () => {
    let currentPos = window.screenY;
    if (currentPos > this.prevPositionY || currentPos < this.prevPositionY) {
      this.onCloseMenu()
    }
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid sx={{ width: "100%", height: { xs: "200px", md: "400px" }, background: `url(${backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <Grid sx={{
            maxWidth: "1200px", margin: "auto", display: "flex", justifyContent: { xs: "center", md: "flex-start" }, alignItems: "end", height: "100%",
            padding: { xs: "10px 10px 2% 10px", lg: "0 0 2% 0" }
          }}>
            <Grid sx={{ bgcolor: "#00000075", padding: "10px 80px", borderRadius: "8px" }}>
              <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "25px", fontWeight: "bold" }}>
                منطقه ۲۲
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: "1200px", margin: "auto", padding: { xs: "10px", lg: "0" } }}>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h2">
            منطقه ۲۲ تهران
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            منطقه ۲۲ شهرداری در شمال غربی تهران واقع شده است و بزرگترین منطقه شهری تهران است. منطقه
            بیست و دو جزو مناطق تازه ساز تهران است که دارای کلیه امکانات رفاهی و گردشگری می باشد. به نوعی منطقه ۲۲ کلیه کمبودهای اکثر مناطق تهران را جبران کرده است.
            <br />
            محدوده ی جغرافیایی منطقه ۲۲ از شمال به کوهستان البرز مرکزی، از شرق
            به حریم رودخانه کن و <Link to="/tehran-zone-5" className="zone_text_link" target="_blank">منطقه ۵</Link>، از جنوب به آزاد
            راه تهران – کرج و <Link to="/tehran-zone-21" className="zone_text_link" target="_blank">منطقه۲۱</Link> و از غرب هم به محدوده ی جنگل های وردآورد
            منتهی می شود.
          </Typography>
          <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 10px 0" }} variant="h3">
              منطقه بیست و دو تهران برروی نقشه
            </Typography>
            <Grid sx={{ width: "100%", height: "400px", borderRadius: "15px", overflow: "hidden", display: { xs: "none", md: "block" } }}>
              <ShowCityLocation
                positions={
                  [
                    [35.780587, 51.262207],
                    [35.789499, 51.214828],
                    [35.787828, 51.173630],
                    [35.772230, 51.141701],
                    [35.750500, 51.115952],
                    [35.741862, 51.115608],
                    [35.742977, 51.124878],
                    [35.745206, 51.140671],
                    [35.719009, 51.213799],
                    [35.719009, 51.213799],
                    [35.713991, 51.252251],
                    [35.716500, 51.290359],
                    [35.738797, 51.269417],
                    [35.745763, 51.267357],
                    [35.756351, 51.268044],
                    [35.770559, 51.261864],
                  ]
                }
                position={
                  [35.753537, 51.206014]
                }
                zoom={12}
              />
            </Grid>
            <Grid sx={{ width: "100%", height: "300px", borderRadius: "15px", overflow: "hidden", display: { xs: "block", md: "none" }, margin: "auto" }}>
              <ShowCityLocation
                positions={
                  [
                    [35.780587, 51.262207],
                    [35.789499, 51.214828],
                    [35.787828, 51.173630],
                    [35.772230, 51.141701],
                    [35.750500, 51.115952],
                    [35.741862, 51.115608],
                    [35.742977, 51.124878],
                    [35.745206, 51.140671],
                    [35.719009, 51.213799],
                    [35.719009, 51.213799],
                    [35.713991, 51.252251],
                    [35.716500, 51.290359],
                    [35.738797, 51.269417],
                    [35.745763, 51.267357],
                    [35.756351, 51.268044],
                    [35.770559, 51.261864],
                  ]
                }
                position={
                  [35.753537, 51.206014]
                }
                zoom={11}
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            پارک های منطقه ۲۲ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            دریاچه چیتگر ، پارک جنگلی چیتگر ، پارک جنگلی خرگوش دره ، شهربازی هزارویک شهر ، بوستان جوانمردان ایران.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            باغ ملی گیاه شناسی ، پارک جنگلی لتمال کن ، پارک جنگلی وردآورد ، پارک آبی او پارک ، پارک آبشار تهران.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز خرید منطقه ۲۲ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiYrOa5uoX7AhWI26QKHbcOAiwQFnoECAoQAQ&url=https%3A%2F%2Fwww.iranmall.com%2F&usg=AOvVaw3wnmX6TH9LGmXzbiFI8xTr" className="zone_text_link" target="_blank" rel="noreferrer">ایران مال</a> ،
            بام لند چیتگر ، مجتمع تجاری اداری طوبی خرید
            ، رزمال ، مجتمع گلستان خیام .
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            - <a href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&cad=rja&uact=8&ved=2ahUKEwiVqMfUuoX7AhWW7KQKHeXPDPwQFnoECA8QAQ&url=https%3A%2F%2Fwww.okcs.com%2F&usg=AOvVaw3BEjv1picM27pvdX_kKHyJ" className="zone_text_link" target="_blank" rel="noreferrer">فروشگاه افق کوروش</a> ،
            فروشگاه شهر ارگانیک ، سرزمین کودک آفتاب ، مرکز خرید تهران مال ،  تجاری اداری مادر.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز درمانی منطقه ۲۲ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            درمانگاه(سه باب) ، پلی کلینیک فیاض بخش ، درمانگاه اقاقیا ، درمانگاه المپیک ، درمانگاه المهدی ، درمانگاه امام رضا(ع) ، درمانگاه ایرانیان.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            درمانگاه تختی ، درمانگاه خاتم الانبیا ، درمانگاه خیریه ی ایران خودرو ، درمانگاه دکتر قره باغی ، درمانگاه دکتر مستوفی ، درمانگاه دکتر فرشید.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            درمانگاه رازی ، درمانگاه شفا ، درمانگاه شهاب ، درمانگاه شهرک راه آهن ، درمانگاه شهید باقری.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            درمانگاه صدرا ، درمانگاه گلچین ، درمانگاه گلستان غرب ، درمانگاه و کلینیک ارم ، درمانگاه کیمیا.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            سینماهای منطقه ۲۲ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            سینما ایران مال ، سینما جوان مردان ، سینما دهکده ، سینما مگامال ، سینما کوروش ، سینما بهمن.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 10px 0", textAlign: "justify" }}>
            سینما قدس ، سینما مرکزی ، سینما سمرقند ، سینما شاهد ، سینما عصر جدید ، سینما ماندان.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            سینما سپیده ، سینما فرهنگ ، سینما ایران ، سینما کارون ، سینما فرخ.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            مراکز طبیعت گردی منطقه ۲۲ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            مجموعه بام لند ، دریاچه شهدای خلیج فارس ، بوستان جنگلی چیتگر ، آبشار تهران ، باغ گیاه شناسی.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "20px", margin: "30px 0 10px 0" }} variant="h3">
            محله های منطقه ۲۲ تهران :
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", margin: "0 0 30px 0", textAlign: "justify" }}>
            دهکده المپیک ، زیبا دشت ، پیکان شهر ، کوهسار ، کوهک.
          </Typography>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={(event) => { this.onClickMenu(event) }}
              sx={{ margin: "20px 0", border: "2px solid #d7d7d7", padding: "10px 40px", boxShadow: "0 2px 2px 0 #ebebeb", borderRadius: "8px" }}
            >
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "black" }}>
                سایر مناطق تهران
              </Typography>
              <KeyboardArrowDownRoundedIcon sx={{ color: "gray !important" }} />
            </Button>
            <Menu
              open={this.state.openMenu}
              onClose={this.onCloseMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ "& .MuiPaper-root": { maxHeight: "300px", overflow: "scroll-y" } }}
            >
              <MenuItem>
                <Link to={'/tehran-zone-1'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-2'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-3'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-4'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-5'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-6'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-7'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-8'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-9'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-10'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-11'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-12'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-13'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-14'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-15'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-16'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-17'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-18'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-19'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-20'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-21'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-22'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu} sx={{ padding: "8px 40px" }}> منطقه ۲۲</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px", margin: "30px 0 0 0" }}>
              مقالات مرتبط
            </Typography>
            <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <Link to="/buying-house-in-Tehran" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage15})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        نکات مهم در مورد خرید ملک در تهران
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/moving-the-house" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage20})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        فوت و فن اثاث کشی راحت
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
              <Grid item xs={12} md={4}>
                <Link to="/real-estate" style={{ textDecoration: "none", color: "black" }} target="_blank">
                  <Grid sx={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #eee", boxShadow: "0px 4px 9px 0px #dedede82", "&:hover": { boxShadow: "0 4px 9px 0 #94949482" }, transition: "all 0.3s" }}>
                    <Grid sx={{ width: "100%", height: "250px", background: `url(${articleImage26})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                    </Grid>
                    <Grid sx={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Typography sx={{ fontFamily: "shabnam" }}>
                        مشاور املاک کیست
                      </Typography>
                    </Grid>
                  </Grid>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
          <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
            <Link to={'/Listings/buy_tehran_omid'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در امید
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_omid'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در امید
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_andishe'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اندیشه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_andishe'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اندیشه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_azadshahr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آزادشهر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_azadshahr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آزادشهر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_bozorgrahshahidkharazi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بزرگراه شهید خرازی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{
            bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "1300px" : "0px", md: this.state.openLinks ? "700px" : "0px" }
            , transition: "max-height 0.6s linear", overflow: "hidden"
          }}
          >
            <Link to={'/Listings/rent_tehran_bozorgrahshahidkharazi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بزرگراه شهید خرازی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_paykanshahr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در پیکان شهر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_paykanshahr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در پیکان شهر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_juzani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در جوزانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_juzani'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در جوزانی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_daryachechitgar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دریاچه چیتگر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_daryachechitgar'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دریاچه چیتگر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_dejban'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دژبان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_dejban'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دریاچه دژبان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_dehkadeolampik'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دهکده المپیک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_dehkadeolampik'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دهکده المپیک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_zibadashtbala'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در زیبا دشت بالا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_zibadashtbala'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در زیبا دشت بالا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_zibadashtpaeen'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در زیبا دشت پایین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_zibadashtpaeen'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در زیبا دشت پایین
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sharif'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شریف
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sharif'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شریف
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakegolestan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک گلستان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakegolestan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک گلستان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahidbagheri'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهید باقری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahidbagheri'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهید باقری
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_kuhsartoto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کوهسار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_kuhsartoto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کوهسار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_kuhaktoto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کوهک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_kuhaktoto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کوهک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_golestan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در گلستان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_golestan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در گلستان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_maidanesahel'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در میدان ساحل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_maidanesahel'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در میدان ساحل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_abshartoto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آبشار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_abshartoto'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آبشار
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahrakerahahan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک راه آهن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahrakerahahan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک راه آهن
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              ما در این صفحه به معرفی  منطقه 22 تهران – نقشه – پارک – مراکز خرید، درمانی، سینما و طبیعت گردی | هومینجا می پردازیم.
              همچنین منطقه 22 شهرداری شهر تهران به همراه نقشه، امکانات و محله های منطقه بیست و دو را مورد بررسی قرار می دهیم.
            </Typography>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default Zone22;

