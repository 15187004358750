import { Button, Grid, OutlinedInput, Typography, Modal, Backdrop } from "@mui/material";
import React from "react";
import { baseUrl, convertToEnglishNumber, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from './gifLogo';
import { Link } from "react-router-dom";

class VisitRequest extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        openAlert:false,
        message:'',
        giftShow:false,
        lists:[],
        confirmModal:false,
        listingId:null,
        visitorId:null,
        date:'',
        time:'',
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    if (this.isMount){
      this.getVisitRequset();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
      this.setState({openAlert:false});
  }

  getVisitRequset = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/request_visit/get',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      let items = res.data.data;
      this.setState({lists:items})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onClickListingId = (id) =>{
    localStorage.setItem('idListing',id)
  }

  openConfirmModal = (index) =>{
    this.setState({confirmModal:true,listingId:this.state.lists[index].listing_id,visitorId:this.state.lists[index].visitor_id})
  }

  createVisitRequest = () =>{
    let items = [];
    this.state.lists.forEach((element,index) =>{
      items.push(
        <Grid key={index} item xs={12} 
              style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom:'10px',
                      backgroundColor:index % 2 === 0 ? 'white' : '#CCF4FB',borderRadius:16,width:'100%',
                      flexWrap:'wrap',filter:'drop-shadow(0px 0px 2px rgba(0, 200, 233, 0.32))'}}
              
        >
          
          <Grid item lg={2} sm={4} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
            <Button style={{width:'100%',color:'black'}} onClick={() => this.onClickListingId(element.listing_id)} component={Link} to={'/listing'} target="_blank">
              <Grid item lg={6} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                    {'شماره آگهی:'}
                  </Typography>
                </Grid>
                <Grid item lg={6} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                    {element.listing_id}
                  </Typography>
                </Grid>
            </Button>
          </Grid>
          <Grid item lg={4} sm={8} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
            <Grid item lg={4} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                  {'آگهی دهنده:'}
                </Typography>
              </Grid>
              <Grid item lg={8} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                  {element.user.name + ' ' + element.user.lastname + ' - ' + convertToPersianNumber(element.user.mobile)}
                </Typography>
              </Grid>
          </Grid>
          <Grid item lg={4} sm={8} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
            <Grid item lg={4} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                {'بازدید کننده:'}
                </Typography>
              </Grid>
              <Grid item lg={8} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                  {element.visitor.name + ' ' + element.visitor.lastname + ' - ' + convertToPersianNumber(element.visitor.mobile)}
                </Typography>
              </Grid>
          </Grid>
          
          <Grid item lg={2} sm={4} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button onClick={() => this.openConfirmModal(index)}
                  style={{backgroundColor:'#003249',color:'white',fontFamily:'shabnam',fontSize:'14px',fontWeight:'bold'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{xs:'80%'}}}
              >
                {'ثبت بازدید'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    })
    return items;
  }

  closeConfirmModal = () =>{
    this.setState({confirmModal:false,listingId:null,visitorId:null})
  }

  onChangeDate = (event) =>{
    this.setState({date:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  onChangeTime = (event) =>{
    this.setState({time:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  onClickSave = () =>{
    let valueSplit = this.state.time.split(':');
    if (this.state.time.length !== 5 || valueSplit.length !== 2 || valueSplit[0] > 23 || valueSplit[1] > 59){
      this.setState({openAlert:true,message:'فرمت ساعت بازید صحیح نیست.',birth:'',typeAlert:3})
    }
    else{
      valueSplit = this.state.date.split('/');
      if (this.state.date.length !== 10 || valueSplit.length !== 3 || valueSplit[0].length !== 4 || valueSplit[1] > 12 || valueSplit[3] > 31){
        this.setState({openAlert:true,message:'فرمت تاریخ بازید صحیح نیست.',birth:'',typeAlert:3})
      }
      else{
        this.setState({giftShow:true,confirmModal:false});
        this.saveVisitTime();
      }
    }
    
  }

  saveVisitTime = () =>{
    Promise.resolve(axios({
      method:'POST',
      url:baseUrl + 'v1/visit_user/create',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        listing_id:this.state.listingId,
        visitor_id:this.state.visitorId,
        time:this.state.time,
        date:this.state.date
      }
    }))
    .then((res) =>{
      this.setState({listingId:null,visitorId:null,date:'',time:'',openAlert:true,message:'بازدید ثبت گردید.',giftShow:false,typeAlert:1})
      this.getVisitRequset();
    })
    .catch(() =>{
      this.setState({openAlert:true,message:'خطا در عملیات',giftShow:false,typeAlert:2})
    })
  }

  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواستهای بازدید</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >
          {
            this.state.lists.length > 0 ?
              this.createVisitRequest()
            :
              <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {' درخواست بازدید جهت نمایش ندارد.'}
                </Typography>
              </Grid>
          }    
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.confirmModal}
            onClose={this.closeConfirmModal}
            BackdropComponent={Backdrop}
            BackdropProps={{timeout:2000}}
            sx={{display:'flex',flexDirection:'row',alignItems:{md:'center',xs:'start'},justifyContent:'center',overflowY:'scroll'}}
          >
            <Grid item xs={8} 
              style={{backgroundColor:'white',display:'flex',width:'80%',border: '2px solid',borderColor:'black',
                    flexDirection: 'column',alignItems:'center',justifyContent:'center',padding:'6px 16px 6px 16px',zIndex:"0",
                    boxShadow:'0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'}}>
              <Grid item xs={11} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:'50px'}}>
                <Typography style={{fontFamily:'shabnam',marginTop:10}} sx={{fontSize:{sm:'24px',xs:'18px'}}}>{'ثبت تاریخ و ساعت بازدید'}</Typography>
              </Grid>
              <Grid item xs={11} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                <Typography style={{fontFamily:'shabnam',marginRight:'1%',marginBottom:5,width:'70%'}} sx={{fontSize:{sm:'16px',xs:'10px'}}}>
                  {'ساعت بازدید : '}
                </Typography>
                <OutlinedInput variant="outlined" placeholder="دقیقه : ساعت"
                            onChange={(event) => this.onChangeTime(event)} value={convertToPersianNumber(this.state.time)}
                            style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontWeight:'bold'}}
                            inputProps={{maxLength:5}} sx={{fontSize:{sm:'20px',xs:'14px'}}}
                />
              </Grid>
              <Grid item xs={11} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                <Typography style={{fontFamily:'shabnam',marginRight:'1%',marginBottom:5,width:'70%'}} sx={{fontSize:{sm:'16px',xs:'10px'}}}>
                  {'تاریخ بازدید : '}
                </Typography>
                <OutlinedInput variant="outlined" placeholder="روز/ماه/سال"
                            onChange={(event) => this.onChangeDate(event)} value={convertToPersianNumber(this.state.date)}
                            style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                            inputProps={{maxLength:10}} sx={{fontSize:{sm:'20px',xs:'14px'}}}
                />
              </Grid>
              
              <Grid item sm={8} xs={11} style={{width:'100%',display:'flex',justifyContent:'center',marginTop:100}}>
                <Button onClick={this.onClickSave} 
                        style={{backgroundColor:'#00C8E9',borderRadius:8,width:'50%',height:'50px',color:'black',
                                fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}>
                  {'ذخیره'}
                </Button>
              </Grid>
             
            </Grid>
          </Modal>
        </Grid>
        
      </Grid>
    )
  };
}

export default VisitRequest;