import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber ,report } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import { Link } from "react-router-dom";

class Reports extends React.Component{

  isMount = false ;
  token = localStorage.getItem("tokenUserHomeInja");

    constructor(props){
      super(props);
      this.state={
        giftShow:false,
        openAlert:false,
        message:"",
        reportsInfo:[],
        reportsDelete:[],
        typeAlert:3
      }
    }

    componentDidMount(){
      this.isMount = true ;
      if(this.isMount){
        this.props.user.user_type_id === 5 ? this.getReports() : this.getReportDelete();
      }
    }

    componentWillUnmount(){
      this.isMount = false ;
    }

    closeAlertModal = () => {
      this.setState({openAlert:false});
    }

    getReports = () => {
      this.setState({giftShow:true})
      Promise.resolve(axios({
        url: baseUrl + "v1/report/get",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        this.setState({reportsInfo:res.data.data,giftShow:false});
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجداد تلاش کنید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",giftShow:false,typeAlert:2})
      })
    }

    getReportDelete = () =>{
      this.setState({giftShow:true})
      Promise.resolve(axios({
        url: baseUrl + "v1/report/getDeleteRequest",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        this.setState({reportsDelete:res.data.data,giftShow:false});
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجداد تلاش کنید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",giftShow:false,typeAlert:2})
      })
    }

    checkProblem = (index) => {
      let data =  this.props.user.user_type_id === 5 ? this.state.reportsInfo[index] : this.state.reportsDelete[index];
      let {listing_id,created_at,updated_at,user_mobile,user_id,status,id, ...reportProblems} = data ;
      for(let reportProperty in reportProblems){
        if(reportProblems[reportProperty] === 1){
          for(let i = 0 ; i < report.length ; i++ ){
            if(report[i].f === reportProperty){
              return report[i].n
            }
          }
        }
      }
    }

    createReports = () => {
      let result = [] ;
      let data = this.props.user.user_type_id === 5 ? this.state.reportsInfo : this.state.reportsDelete;
      data.forEach((element,index) => {
        result.push(
          <Grid sx={{width:"100%",padding:{xs:"1% 5%",md:"1% 8% 0 8%"}}} key={index}>
            <Grid container columns={{xs:12,sm:12,lg:12}}
              sx={{textAlign:"center",width:'100%',border:"2px solid #eee",borderTopRightRadius:"30px",padding:"15px 0",
                  background:"linear-gradient(270deg, rgba(204, 244, 251, 0.14) 0%, rgb(255, 255, 255) 100%)"}}
            >
              <Grid item xs={12} sm={6} lg={2} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",width:'100%'}}>شماره آگهی : {convertToPersianNumber(element.listing_id)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.8} sx={{display:"flex",alignItems:"center",justifyContent:"center",width:'100%'}}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",width:'100%'}}> مشکل آگهی : {this.checkProblem(index)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.6} sx={{display:"flex",alignItems:"center",justifyContent:"center",width:'100%'}}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",width:'100%'}}> شماره کاربر : {convertToPersianNumber(element.user_id)}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={2.8} sx={{display:"flex",alignItems:"center",justifyContent:"center",width:'100%'}}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",width:'100%'}}> شماره تلفن : {convertToPersianNumber(element.user_mobile)}</Typography>
              </Grid>
              
              <Grid item xs={12} sm={12} lg={1.8} sx={{display:"flex",alignItems:"center",justifyContent:"center",width:'100%'}}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button onClick={() => this.onClickRequestDelete(index)} disabled={this.state.giftShow}
                      sx={{color:"black",fontFamily:"shabnam",fontWeight:"bold",padding:"3px 20px",bgcolor:"rgb(0, 200, 233)","&:hover":{bgcolor:"rgb(0, 200, 233)"},
                        width:{md:'80%',xs:'50%'}}}
                    >
                    {this.props.user.user_type_id === 5 ? ' رد کردن گزارش' : 'حذف گزارش'}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button component={Link} to={"/Listing/" + element.listing_id} target="_blank" disabled={this.state.giftShow}
                      sx={{color:"white",fontFamily:"shabnam",fontWeight:"bold",padding:"3px 20px",bgcolor:"rgb(0, 50, 73)","&:hover":{bgcolor:"rgb(0, 50, 73)"},
                        width:{md:'80%',xs:'50%'},marginTop:"5px"}}
                    >
                    {"مشاهده "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      })
      return result;
    }

    onClickRequestDelete = (index) => {
      this.setState({giftShow:true})
      let data = this.props.user.user_type_id === 5 && this.state.reportsInfo.length > 0 ?
          {
            listing_id: this.state.reportsInfo[index].listing_id,
            user_id: this.state.reportsInfo[index].user_id
          }
        :
          {
            listing_id: this.state.reportsDelete[index].listing_id
          }
          
      Promise.resolve(axios({
        url: baseUrl + (this.props.user.user_type_id === 5 ? "v1/report/deleteRequest" : "v1/report/delete"),
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: data
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"رد کردن گزارش با موفقیت انجام شد",giftShow:false,typeAlert:1});
        this.componentDidMount();
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجداد تلاش کنید",giftShow:false,typeAlert:2})
      })
    }

    render() {
      return (
        <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px'}}}>
          <Grid style={{display:'flex',flexDirection:'row',marginRight:'6%'}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>آگهی های گزارش شده</Typography>
          </Grid>
          <Grid style={{display:'flex',alignItems:'center',flexDirection:'column',marginTop:50,width:'100%'}}>    
            
            { this.state.reportsInfo.length > 0  || this.state.reportsDelete.length > 0 ?
                this.createReports()
              : <Grid style={{display:'flex',marginTop:'17%',width:'100%',height:'400px'}}>
                  <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                    {"آگهی گزارش شده ای وجود ندارد"}
                  </Typography>
                </Grid>
            }
            <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
            <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          </Grid>
        </Grid>
      )
    };
}

export default Reports;