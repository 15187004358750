import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article03/01.jpg";
import photo02 from "../../images/mag/article03/02.jpg";
import photo03 from "../../images/mag/article03/03.jpg";
import photo04 from "../../images/mag/article03/04.jpg";
import photo05 from "../../images/mag/article03/05.jpg";
import photo06 from "../../images/mag/article03/06.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article03 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content =
      "برای داشتن یک خواب راحت انتخاب کالای خواب مناسب بسیار حائز اهمیت است. در این مقاله از هومینجا به معرفی کالای خواب مرغوب می پردازیم.";
    document.getElementsByTagName('meta')["keywords"].content = "خرید کالای خواب | کالای خواب ایرانی یا خارجی | بهترین برند کالای خواب"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content =
      "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[2].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[2].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[2].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" }, maxWidth: "1000px", margin: "auto" }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[2].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[2].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[2].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کالای خواب از کجا بخریم؟ - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[2].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به مشغله های امروزی هیچ چیز بهتر از یک
                خواب خوب و راحت باعث از بین رفتن خستگی های روزانه نمی شود. برای داشتن یک خواب راحت انتخاب کالای خواب مناسب بسیار حائز اهمیت است.برای
                خرید کالای خواب از جمله بالش، تشک، لحاف و پتو باید دقت کافی داشت. در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> به معرفی کالای خواب مرغوب می پردازیم.همچنین
                در این مطلب از هومینجا به پاسخ سوالاتی از این قبیل که <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> کالای خواب </Link> از کجا بخریم؟ یا برند مناسب کالای خواب چه برندی می باشد؟ می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                نکاتی که در خرید کالای خواب باید مدنظر قرار داد؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کالای خواب  - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خرید کالای خواب روتختی
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/pouf" target="_blank">
                  بیشتر بخوانید : پاف چیست ؟
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای خرید کالای خواب با کیفیت بهتر است محصولاتی را انتخاب کنید که دارای استانداردهای طبی باشد. از پارچه های بادوام و با کیفیت در آن استفاده شده باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                استانداردهای طبی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مواردی که باعث اختلال در خواب می شود قرار نگرفتن گردن
                روی بالش است. این امر باعث آسیب رساندن به ستون فقرات و دیگر اندام های بدن می شود.بنابراین برای تهیه کالای خواب حتما از استانداردهای آن مطمئن شوید.
              </Typography>
              <Typography className="h3" variant="h3">
                اصالت کالا:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محصولات وارداتی  دارای کد محصولی <LinkMui className="link" href="https://www.asangsm.com/%D9%BE%D8%A7%D8%B1%D8%AA-%D9%86%D8%A7%D9%85%D8%A8%D8%B1-%DA%86%DB%8C%D8%B3%D8%AA/" target="_blank">  پارت نامبر </LinkMui> می باشد. با وارد کردن این کد در <LinkMui className="link" href="https://www.google.com/" target="_blank">  گوگل </LinkMui> شرکت تولید کننده و تمامی خصوصیات کالا مورد نظر در اختیار شما قرار می گیرد.
              </Typography>
              <Typography className="h3" variant="h3">
                جنس پارچه:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که پارچه های استفاده شده در تماس مستقیم با پوست بدن شما قرار می گیرد بنابراین جنس پارچه بسیار با اهمیت است. جنس پارچه باید ضدآلرژی باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                اجزای کالای خواب
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کالای خواب مناسب - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اجزای کالای خواب مثل روتختی و روبالشتی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کالای خواب شامل سرویس روتختی، بالش، لحاف، پتو می باشد. برای خرید هر یک از این اجزا باید دقت کافی داشت.
              </Typography>
              <Typography className="h3" variant="h3">
                انواع روتختی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                روتختی یکی از مواردی است که نقش مهمی در <Link className="link" to="/small-house-decoration" target="_blank"> دیزاین </Link> اتاق خواب دارد. انتخاب طرح و رنگ روتختی باید به گونه ای باشد که با سایر بخش های اتاق خواب هماهنگی داشته باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                روتختی سنگ دوزی شده:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جنس این روتختی معمولا از پارچه های ابریشمی و ساتن است.
                قیمت بالایی نسبت به دیگر انواع روتختی ها دارند. شستن آن ها در خانه به راحتی امکانپذیر نیست. بنابراین هزینه زیادی برای <Link className="link" to="/laundry-room" target="_blank"> خشکشویی </Link> باید پرداخت شود.
              </Typography>
              <Typography className="h3" variant="h3">
                روتختی کتان یا ساتن:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                روتختی های کتان برای استفاده بسیار مناسب هستند. شست و شوی
                ان ها به راحتی انجام می پذیرد. هزینه خرید آن نسبتا مناسب است. یکی از مشکلاتی که روتختی های ساتن دارند  به راحتی لک می شوند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت وفن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                چه بالشتی بخریم؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باید بالشی را خریداری کنید که به گردن فشاری نیاورد. بهترین نوع بالش از نوع پر یا ویسکوز می باشد. اتخاب  یک بالش نامناسب می‌تواند
                شما را دچار سردرد، بی‌حس شدن شانه و بازو کند. همچنین متخصصان بهداشت توصیه می‌کنند که بهتر است  هر ۱۲ تا ۱۸ ماه به فکر عوض کردن بالش خود باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین برند کالای خواب:
              </Typography>
              <Typography className="h3" variant="h3">
                بهترین برند تشک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از بهترین برند کالای خواب می توان به تاچ، ازدیلک یا سارو اشاره کرد. اما
                با توجه به قیمت بالای این برندها اکثر مردم به برندهای ایرانی روی آورده اند. در اینجا به معرفی برخی از برندهای معروف ایرانی می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                لایکو:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کالای خواب لایکو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بالش لایکو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شرکت اولین بار در سال 1355 به ثبت رسید. <LinkMui className="link" href="https://www.laicogroup.com/fa/" target="_blank">  لایکو </LinkMui> دارای تنوع رنگ و مدل
                بسیاری می باشد. بالش، سرویس روتختی، تشک و پتو از محصولاتی هستند که این شرکت تولید می کند. بالش های لایکو از استانداردهای لازم برخوردارند. از شعب این برند
                در تهران می توان به <Link className="link" to="/Listings/buy_tehran_saadatabad" target="_blank"> سعات آباد </Link> نبش علامه جنوبی، <Link className="link" to="/Listings/rent_tehran_pasdaran" target="_blank"> پاسداران </Link> نبش بهستان دهم، <Link className="link" to="/Listings/buy_tehran_mirdamad" target="_blank"> میرداماد </Link>، <Link className="link" to="/Listings/rent_tehran_tajrish" target="_blank"> تجریش </Link> و <Link className="link" to="/Listings/buy_tehran_gisha" target="_blank"> گیشا </Link> نام برد. این برند در اکثر شهرستان ها نیز نمایندگی فعال دارد.
              </Typography>
              <Typography className="h3" variant="h3">
                کالای خواب بروجرد:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                <LinkMui className="link" href="https://borujerdhome.co/product-category/%DA%A9%D8%A7%D9%84%D8%A7%DB%8C-%D8%AE%D9%88%D8%A7%D8%A8/sleep-products" target="_blank">  کالای خواب بروجرد </LinkMui> در رده نخست نساجی های ایرانی قرار دارد. این برند پارچه های مصرفی خود را از کارخانه های تولیدی <LinkMui className="link" href="https://nassaj.ir/fa" target="_blank">  نساجی </LinkMui> بروجرد،
                کارخانه وطن اصفهان و <LinkMui className="link" href="https://chapatlas.com/" target="_blank">  اطلس چاپ </LinkMui> تامین می کند. کلیه پارچه هایی که در این شرکت مورد استفاده قرار می گیرد دارای استانداردهای ملی می باشد.
                <br />
                برخی از شعب نساجی بروجرد در تهران پارک وی، ساعی،<Link className="link" to="/Listings/buy_tehran_poonak" target="_blank"> پونک </Link>، شهدا، <Link className="link" to="/Listings/buy_tehran_piruzi" target="_blank"> پیروزی </Link>، <Link className="link" to="/Listings/buy_tehran_gholhak" target="_blank"> قلهک </Link>، <Link className="link" to="/Listings/buy_tehran_tehranparsgharbi" target="_blank"> تهرانپارس </Link> و <Link className="link" to="/Listings/rent_tehran_heravi" target="_blank"> هروی </Link> می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                کالای خواب جواهری:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کالای خواب جواهری - هومینچا" className="image" />
                <figcaption className="image_caption">
                  برند کالای خواب جواهری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این محصول نیز از بهترین برندهای ایرانی می باشد. محصولات این برند با قیمت مناسب و کیفیت عالی تولید می شود. تنوع در طرح و ثبات بالای بافت و رنگ از ویژگی های برجسته این محصول می باشد.
                <br />
                از شعب این برند می توان به <LinkMui className="link" href="https://sanashoppingcenter.com/" target="_blank">  سانا </LinkMui> در <Link className="link" to="/Listings/buy_tehran_andarzgoo" target="_blank"> اندرزگو </Link>
                ، شعبه <Link className="link" to="/Listing/481" target="_blank"> فرشته </Link> در ولیعصر، <LinkMui className="link" href="https://galleriatehran.com/" target="_blank">  گالریا </LinkMui> در <Link className="link" to="/Listings/buy_tehran_velenjak" target="_blank"> ولنجک </Link>، گلستان در <Link className="link" to="/Listings/buy_tehran_shahrakegharb" target="_blank"> شهرک غرب </Link> و <LinkMui className="link" href="https://bamlandmall.ir/" target="_blank">  بام لند </LinkMui> در مجتمع تجاری بام لند، اسکای سنتر در لواسان، <LinkMui className="link" href="https://palladiummall.com/" target="_blank">  پالادیوم </LinkMui> در <Link className="link" to="/Listings/buy_tehran_asef" target="_blank"> مقدس اردبیلی </Link> و <LinkMui className="link" href="https://hayatsabz.co/" target="_blank">  حیات  </LinkMui> <Link className="link" to="/Listings/rent_tehran_niyavaran" target="_blank"> نیاوران </Link> اشاره کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/keeping-apartment-flower" target="_blank">
                  بیشتر بخوانید : نکاتی در مورد نگهداری گیاهان آپارتمانی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                افرا:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کالای خواب <LinkMui className="link" href="http://afra-home.com/" target="_blank">  افرا </LinkMui> از محصولات <LinkMui className="link" href="https://avval.ir/profile/3c524/%DA%A9%D8%A7%D8%B1%D8%AE%D8%A7%D9%86%D9%87-%D8%A8%D9%87-%D9%86%D8%AE-%D8%B3%D8%A7%D8%B2%D8%A7%D9%86" target="_blank">  شرکت به نخ سازان </LinkMui> می باشد. این شرکت حدود
                20سال می باشد که در صنعت نساجی ایفای نقش می کند. محصولات این شرکت سبک، لطیف و بسیار متنوع می باشد. برای تهیه این برند می توان از وب سایت شرکت یخ سازان  خرید کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                نرمینه بافت:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محصولات <LinkMui className="link" href="https://narminehbaft.com/" target="_blank">  نرمینه </LinkMui> از نخ اکرولیک می باشد. پتو لوکس نرمینه، گل برجسته، حریر، نگین، نرمین از انواع پتوهای این برند می باشد. سبکی و
                لطافت از خصوصیات این محصولات می باشد.  تجریش ، بازار بزرگ، <Link className="link" to="/Listings/buy_tehran_narmak" target="_blank"> نارمک </Link>، <Link className="link" to="/tehran-zone-10" target="_blank"> خیابان رودکی </Link>، میدان فردوسی، <Link className="link" to="/Listings/buy_tehran_afsariye" target="_blank"> افسریه </Link>، میدان شوش و <Link className="link" to="/Listings/buy_tehran_piruzi" target="_blank"> خیابان پیروزی </Link> از شعب فعال این کالای خواب می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                گلبافت:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شرکت در سال 1348 با تولید روبالشی و چرم مصنوعی کار خود را شروع کرد. در سال 1370 با تولید <LinkMui className="link" href="https://www.digikala.com/search/category-blankets/golbaft/" target="_blank">  پتوهای </LinkMui> رنگارنگ به معروفیت رسید. این برند نیز دارای رنگ و طرح های متنوع می باشد.
                <br />
                <Link className="link" to="/Listings/rent_tehran_sadeghieh" target="_blank"> صادقیه </Link>، <Link className="link" to="/Listings/buy_tehran_niruhavaee" target="_blank"> نیرو هوایی </Link>، <Link className="link" to="/Listings/buy_tehran_dolatabadtw" target="_blank"> دولت آباد </Link>، <Link className="link" to="/Listings/rent_tehran_janatabad" target="_blank"> جنت آباد </Link>و <Link className="link" to="/Listings/buy_tehran_farjam" target="_blank"> فرجام </Link> از جمله نمایندگی های فروش این برند در تهران می باشد .
                <br />
                با اینکه برند های خارجی از
                لحاظ قیمت بسیار گرانتر از برندهای مشابه ایرانی می باشند اما این برندها نیز طرفداران خاص خود را دارند. در اینجا به معرفی یکسری از برندهای معروف و با کیفیت خارجی می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                ژرمن کالا:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کالای خواب یک برند آلمانی می باشد.
                بالش های لاتکس از محصولات معروف <LinkMui className="link" href="https://www.germankala.ir/" target="_blank"> ژرمن کالا </LinkMui> می باشد. روتختی های این برند نیز کیفیت بالایی دارند. <Link className="link" to="/Listings/rent_tehran_tarasht" target="_blank"> طرشت </Link> میدان تیموری یکی از مراکز فعال فروش این برند می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                تاچ:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="روتختی- هومینجا" className="image" />
                <figcaption className="image_caption">
                  کالای خواب تاچ
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.tac-home.com/" target="_blank">  تاچ </LinkMui> یکی از برندهای باکیفیت ترکیه می باشد. از ویژگی های این برند داشتن کاور لحاف و روبالشی و تشک می باشد.
                برای خرید این محصول علاوه بر سایت آن می توان از نمایندگی های فعال در <Link className="link" to="/Tehran-spectaculars" target="_blank"> تهران </Link> از جمله <Link className="link" to="/Listings/rent_tehran_zafar" target="_blank"> شریعتی </Link>، <Link className="link" to="/Listings/buy_tehran_sohrovardi" target="_blank"> سهروردی </Link> جنوبی، خیابان <Link className="link" to="/Listings/buy_tehran_khajenasir" target="_blank"> خواجه عبداله </Link> نیز اقدام کرد.
                <Typography className="h3" variant="h3">
                  کاتن باکس:
                </Typography>
                کالای خواب <LinkMui className="link" href="https://cottonbox.ir/?v=a70d10dcd270" target="_blank"> کاتن باکس </LinkMui> از یک برند ترکیه ای می باشد. استفاده از الیاف طبیعی از ویژگی های منحصر به فرد این برند می باشد. برای خرید این برند می توان بصورت آنلاین از طریق سایت اقدام کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Midex" target="_blank">
                  بیشتر بخوانید : نمایشگاه میدکس
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                سارو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برند سارو  یکی از برندهای محبوب ترک می باشد.تمامی  محصولات این برند دارای  گارانتی است. این برند دارای قیمت
                بالایی نیز می باشد. برای خرید از این برند نیز می توان به سایت آن مراجعه کرد. همچنین با مراجعه به مراکز تهیه کالای خواب نیز می توان این برند را خریداری کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                کلام آخر :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که هر انسانی یک سوم از عمر خود را در خواب می گذراند انتخاب
                کالای خواب خوب و مرغوب از نکات اساسی است که باید مورد توجه قرار داد. عدم توجه به این مسئله باعث خستگی و کسل بودن در طول روز می شود. در این مطلب از  هومینجا
                ما به معرفی برندهای معروف ایرانی و خارجی پرداخته ایم. پس با مطالعه  این مقاله  و بررسی آن می توانید بهترین فروشگاه را برای خرید مرغوبترین کالای خواب انتخاب کنید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/where-to-buy-sleep-product" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/where-to-buy-sleep-product" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/where-to-buy-sleep-product" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article03;