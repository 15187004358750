import {Backdrop, Button, Divider, Grid, Modal, OutlinedInput, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import Avatar from '../images/avatar1.png';
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class searchUser extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        openAlert:false,
        message:'',
        giftShow:false,
        searchText:'',
        searchList:[],
        infoModal:false,
        name:'',
        lastname:'',
        father:'',
        birth:'',
        meli:'',
        mobile:'',
        insta:'',
        type:'',
        agency:'',
        userPicture:null,
        userId:'',
        status:'',
        typeAlert:3
      }
  }

  componentDidMount(){

  }

  componentWillUnmount(){
  }

  onChangeSearch = (event) =>{
    this.setState({searchText:event.target.value.trim()});
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  search = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/user/search',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token},
      params:{
        search:this.state.searchText
      }
    }))
    .then((res) => {
        this.setState({searchList:res.data.data,giftShow:false})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',giftShow:false,typeAlert:2})
    });
  }

  onClickSearch = () =>{
    if (this.state.searchText.trim() !== ''){
      this.setState({giftShow:true});
      this.search();
    }
    else{
      this.setState({openAlert:true,message:'چیزی برای جستجو وارد نکرده اید.',typeAlert:3})
    }
  }

  showInfoModal = (index)=>{
    this.setState({infoModal:true,name:this.state.searchList[index].name,lastname:this.state.searchList[index].lastname,father:this.state.searchList[index].fathername,
                  birth:this.state.searchList[index].date_of_birth,meli:this.state.searchList[index].meli_number,mobile:this.state.searchList[index].mobile,
                  insta:this.state.searchList[index].instagram_link,userId:this.state.searchList[index].id,userPicture:this.state.searchList[index].picture_path,
                  type:this.state.searchList[index].type.description,agency:this.state.searchList[index].agency !== null ? this.state.searchList[index].agency.name : '',
                  status:this.state.searchList[index].active ? 'فعال' : 'غیرفعال'})
  }

  closeInfoModal = () =>{
    this.setState({infoModal:false,name:'',lastname:'',father:'',birth:'',meli:'',mobile:'',insta:'',userId:'',userPicture:null,type:'',agency:''})
  }

  createSerchList = () =>{
    let list = [];
    this.state.searchList.forEach((element,index) =>{
      list.push(
        <Grid item xs={12} key={index} component='button' onClick={()=> this.showInfoModal(index)}
              style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around',
                      backgroundColor:index % 2 === 0 ? 'white' : '#CCF4FB',borderRadius:16,borderStyle:'none',
                      width:'100%',marginBottom:'10px',flexWrap:'wrap',filter:'drop-shadow(0px 0px 2px rgba(0, 200, 233, 0.32))'}}
        >
          <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'نام و نام خانوادگی: '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%'}}>
              {(element.name === null ? '' : element.name) + ' ' + (element.lastname === null ? '' : element.lastname)}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'شماره ملی: '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%'}}>
              {convertToPersianNumber(element.meli_number !== null ? element.meli_number : '')}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'موبایل: '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%'}}>
              {convertToPersianNumber(element.mobile !== null ? element.mobile : '')}
            </Typography>
          </Grid>
          
        </Grid> 
      )
    })
    return list;
  }

  activeUser = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/active',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        user_id:this.state.userId
      }
    }))
    .then((res) => {
      this.closeInfoModal();
      this.search();
      this.setState({openAlert:true,message:'کاربر مورد نظر با موفقیت تایید شد.',typeAlert:1})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'تایید کاربری با مشکل مواجه شد.',typeAlert:2})
    });
  }

  deActiveUser = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/deActive',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        user_id:this.state.userId
      }
    }))
    .then((res) => {
      this.closeInfoModal();
      this.search();
      this.setState({openAlert:true,message:'کاربر مورد نظر غیرفعال گردید.',typeAlert:1})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'عملیات با مشکل مواجه شد.',typeAlert:2})
    });
  }

  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>جستجو کاربران</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >    
          <Grid item xs={10} style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',alignItems:'center',marginTop:25}}>
            <Grid item xs={8} style={{display:'flex',width:'100%'}}>
              <OutlinedInput variant="outlined" onChange={(event) => this.onChangeSearch(event)} value={this.state.searchText}
                style={{borderStyle:'none',width:'100%',fontFamily:'shabnam',fontWeight:'bold',backgroundColor:'#F1F2F2',
                        maxHeight:70,borderRadius:0}}
                sx={{height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
                inputProps={{maxLength:30}}
              />
            </Grid>
            <Grid item xs={4} style={{display:'flex',width:'100%'}}>
              <Button onClick={this.onClickSearch} 
                style={{backgroundColor:'#00C8E9',color:'black',fontFamily:'shabnam',
                        fontWeight:'bold',borderRadius:0,borderBottomLeftRadius:26}}
                sx={{width:{xs:'100%'},height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
              >
                {'جستجو'}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{display:'flex',alignItems:'center',width:'100%',marginTop:20,marginBottom:20}}>
            <Grid item lg={1.5} md={2.5} xs={2} style={{display:'flex',width:'100%'}}>
              <Typography component={'p'} 
                style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                sx={{fontSize:{sm:'16px',xs:'13px'}}}
              >
                {'نتیجه جستجو'}
              </Typography>
            </Grid>
            <Grid item lg={8.5} md={7.5} xs={8} style={{display:'flex',width:'100%',flexDirection:'column'}}>
              <Divider/>
            </Grid>
            <Grid item xs={0.5} style={{display:'flex',width:'100%'}}>
              <Typography component={'p'} 
                style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                sx={{fontSize:{sm:'16px',xs:'13px'}}}
              >
                {convertToPersianNumber(this.state.searchList.length)}
              </Typography>
            </Grid>
            <Grid item xs={1.5} style={{display:'flex',width:'100%'}}>
              <Typography component={'p'} 
                style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                sx={{fontSize:{sm:'16px',xs:'13px'}}}
              >
                {'کاربر'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}>
            {
              this.state.searchList.length === 0 ?
                <Grid item xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',marginTop:'20%'}}>
                  <Typography variant="h1" sx={{fontSize:{md:'26px',xs:'16px'}}}
                    style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'center',marginBottom:5,width:'100%'}}>
                    {'هیچ موردی برای نمایش وجود ندارد.'}
                  </Typography>
                </Grid>
              :
                this.createSerchList()
              }
             
          </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.infoModal}
            onClose={this.closeInfoModal}
            BackdropComponent={Backdrop}
            BackdropProps={{timeout:2000}}
            sx={{display:'flex',flexDirection:'row',alignItems:{md:'center',xs:'start'},justifyContent:'center',overflowY:'scroll'}}
          >
              
            <Grid item xs={8} 
              style={{backgroundColor:'white',display:'flex',width:'80%',border: '2px solid',borderColor:'black',
                    flexDirection: 'column',alignItems:'center',justifyContent:'center',padding:'6px 16px 6px 16px',zIndex:"0",
                    boxShadow:'0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'}}>
              <Grid item xs={11} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center'}}>
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'24px',marginTop:10}}>{'اطلاعات کاربری'}</Typography>
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'24px',marginTop:10}}>{convertToPersianNumber(this.state.userId)}</Typography>
              </Grid>
              <Grid item xs={11} style={{display:'flex',alignItems:'center',flexDirection:'column',marginTop:50,width:'100%'}}
                    sx={{paddingLeft:{md:'100px',xs:'5px'},paddingRight:{md:'100px',xs:'5px'}}}
              >
                <Grid item xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                  <Grid style={{borderStyle:'solid',borderRadius:'100%',borderColor:'#00C8E9',borderWidth:'10px',
                                      display:'flex',alignItems:'center',justifyContent:'center'}}
                              sx={{width:{sm:'200px',xs:'120px'},height:{sm:'200px',xs:'120px'}}}
                  >
                    {
                      this.state.userPicture !== null ?
                        <Typography 
                            style={{width:'100%',height:'100%',borderRadius:'100%',backgroundImage:`url(${this.state.userPicture})`,
                            backgroundRepeat:'no-repeat',backgroundSize:'cover'}}
                        ></Typography>
                      :
                        <Typography component={'img'} src={Avatar}  alt="avatar"
                            sx={{width:{sm:'130px',xs:'45px'},height:{sm:'130px',xs:'45px'}}}
                        ></Typography>
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} 
                    style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',flexWrap:'wrap',width:'100%'}}
                    sx={{paddingRight:'5%',paddingLeft:'5%'}}
              >
                <Grid item xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'20px',xs:'10px'},color:"#029ab3"}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:{xs:'40%',lg:"auto"}}}
                        sx={{fontSize:{sm:'20px',xs:'17px'}}}
                    >{'وضعیت: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:{xs:'60%',lg:"auto"},marginRight:"5px"}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.status}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}  
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'نام کاربر: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.name}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}  
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'نام خانوادگی: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.lastname}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'شماره موبایل: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{convertToPersianNumber(this.state.mobile)}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'نام پدر: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.father}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'نوع کاربری: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.type}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'شماره ملی: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.meli !== null ? convertToPersianNumber(this.state.meli) : ''}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'تاریخ تولد: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.birth !== null ? convertToPersianNumber(this.state.birth) : ''}</Typography>
                </Grid>
                <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'آزانس املاک: '}</Typography>
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                    >{this.state.agency}</Typography>
                </Grid>
                <Grid item xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                      sx={{marginTop:{sm:'50px',xs:'20px'}}}
                >
                    <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                        sx={{fontSize:{sm:'16px',xs:'14px'}}}
                    >{'لینک اینستاگرام: '}</Typography>
                    <Grid sx={{width:'60%',overflowX:"auto"}}>
                      <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:"left",whiteSpace:"nowrap"}}
                        sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                      >{this.state.insta}</Typography>
                    </Grid>
                </Grid>
              </Grid>
              <Grid item xs={11} style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:40,flexWrap:'wrap'}}>
                <Grid item md={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                  <Button onClick={this.activeUser} 
                          style={{backgroundColor:'#00C8E9',borderRadius:8,height:'50px',color:'black',
                                  fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                          sx={{width:{sm:'60%',xs:'70%'}}}
                  >
                    {'تایید کاربر'}
                  </Button>
                </Grid>
                {
                  this.props.user.user_type_id === 6 ? 
                    <Grid item md={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                      <Button onClick={this.deActiveUser} 
                              style={{backgroundColor:'#003249',borderRadius:8,height:'50px',color:'white',
                                      fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                              sx={{width:{sm:'60%',xs:'70%'}}}
                      >
                        {'غیر فعال'}
                      </Button>
                    </Grid>
                  :
                    null
                }
                <Grid item md={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                  <Button onClick={this.closeInfoModal} 
                          style={{backgroundColor:'red',borderRadius:8,height:'50px',color:'white',
                                  fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                          sx={{width:{sm:'60%',xs:'70%'}}}
                  >
                    {'انصراف'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        </Grid>
        
      </Grid>
    )
  };
}

export default searchUser;