import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../styles/sideMenu.css'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CloseIcon from '@mui/icons-material/Close';
import City from "./city";
import State from "./state";
import Zone from "./zone";
import Neighbourhood from "./neib"
import Doucument from "./doc";
import Estate from "./estate";
import Transaction from "./transaction";
import NewListings from './newListing';
import SearchListing from './searchListing';
import NewUser from './newUser';
import SearchUser from './searchUser';
import Tickets from './tickets';
import SendTicket from './sendTicket';
import CreateAgancy from "./createAgancy";
import EditAgancy from "./editAgancy";
import Marks from "./marks";
import Reports from "./reports";
import Deposit from "./deposit";
import Withdraw from "./withdraw";
import InfoUser from "./infoUser";
import InventoryUser from './inventoryUser';
import VisitUser from "./visitUser";
import RequestHome from "./requestHome";
import RequestVip from "./requestVip";
import RequestHomeList from "./requestHomeList";
import ListingUser from "./listingUser";
import CreateListing from './createListing';
import VisitListingUser from "./visitListingUser";
import EditUserInfo from "./editUser";
import RequestContract from "./requestContract";
import RequestConvert from "./requestConvert";
import PurchasesUser from "./purchasesUser";
import ConvertList from "./convertList";
import AddInventory from "./addInventory";
import VisitRequest from "./visitRequset";
import DefineSupporter from "./defineSupporter";
import ContractList from "./contractList";
import WithdrawUser from "./withdrawUser";
import WithdrawAdmin from "./withdrawAdmin";
import CancelVisit from "./cancelVisit";
import AgancyAdmin from "./agencyAdmin";
import '../styles/employee.css'
import logoBackground from '../images/logoBackground.png';

class SideMenu extends React.Component{

    isMount = false;
    currenWidth = window.innerWidth;

    constructor(props){
        super(props);
        this.state={
            expandPanel:'',
            displayPanel:window.innerWidth <= 900 ? false : true,
            displayButtonPanel:window.innerWidth <= 900? true : false,
            page:''
        }
    }

    componentDidMount(){
        document.title = 'پروفایل - هومینجا'
        this.isMount = true;
        setTimeout(() => {
            if (this.props.page !== null){
                this.onClickPage(this.props.page);
            }
        }, 1500);
        this.currenWidth = window.innerWidth;
        if(this.isMount){
            window.addEventListener('resize',() =>{
                if (window.innerWidth !== this.currenWidth){
                    if (window.innerWidth <= 900 && this.currenWidth !== window.innerWidth){
                      this.currenWidth = window.innerWidth;
                      this.setState({displayPanel:false,displayButtonPanel:true})
                    }
                    else{
                      this.currenWidth = window.innerWidth;
                      this.setState({displayPanel:true,displayButtonPanel:false})
                    }
                }
                
            })
        }
    }

    componentWillUnmount(){

    }

    onExpand = (panel) => (event,isExpanded) =>{
        this.setState({expandPanel:isExpanded ? panel : false})
    }

    createAccord = () =>{
        let item = [];
        this.props.data.forEach((element,index) => {
            item.push(
                <Accordion key={index} expanded={this.state.expandPanel === index } onChange={this.onExpand(index)}
                    sx={{backgroundColor:'#14c9e8',width:'100%'}}
                >
                    <AccordionSummary className="accordSummery" 
                        expandIcon={ <ExpandMoreIcon/>}>
                        <Typography className="textSummer" component={'p'}>{element.s}</Typography>
                    </AccordionSummary>
                    {
                        element.d.length > 0 ?
                            this.createAction(element.d,element.a)
                        :
                            null
                    }
                </Accordion>

            )
        });
        return item;
    }

    createAction = (det,act) =>{
        let itemAction = [];
        det.forEach((element1,index1) =>{
            itemAction.push(
                <AccordionDetails key={index1} className="accordaction" 
                    onClick={()=>{
                        this.onClickPage(act[index1]);
                        if (this.state.displayPanel && window.innerWidth <= 900){
                            this.onClickFilter()
                        }
                    }}
                >
                    <Typography className="textAction">{element1}</Typography>
                </AccordionDetails>
            )
        })
        return itemAction;
    }

    onClickFilter = () =>{
        this.setState({displayPanel:!this.state.displayPanel,displayButtonPanel:!this.state.displayButtonPanel})
    }

    onClickPage = (page) =>{
        switch (page) {
            case 'state':
                this.setState({page:<State/>}) 
                break;
            case 'city':
                this.setState({page:<City/>}) 
                break;
            case 'zone':
                this.setState({page:<Zone/>}) 
                break;
            case 'neighbourhood':
                this.setState({page:<Neighbourhood/>}) 
                break;
            case 'doucument':
                this.setState({page:<Doucument/>}) 
                break;
            case 'estate':
                this.setState({page:<Estate/>}) 
                break;
            case 'transaction':
                this.setState({page:<Transaction/>}) 
                break;
            case 'newlistings':
                this.setState({page:<NewListings/>}) 
                break;
            case 'searchlisting':
                this.setState({page:<SearchListing/>}) 
                break;
            case 'newuser':
                this.setState({page:<NewUser/>}) 
                break;
            case 'searchuser':
                this.setState({page:<SearchUser user={this.props.user}/>})
                break;
            case 'tickets':
                this.setState({page:<Tickets/>})
                break;
            case 'sendticket':
                this.setState({page:<SendTicket/>})
                break;
            case 'createagancy':
                this.setState({page:<CreateAgancy/>})
                break;
            case 'editagancy':
                this.setState({page:<EditAgancy user={this.props.user}/>})
                break;
            case 'marks':
                this.setState({page:<Marks/>})
                break;
            case 'reports':
                this.setState({page:<Reports user={this.props.user}/>})
                break;
            case 'deposit':
                this.setState({page:<Deposit user={this.props.user}/>})
                break;
            case 'withdraw':
                this.setState({page:<Withdraw user={this.props.user}/>})
                break;
            case 'buy':
                this.setState({page:<PurchasesUser user={this.props.user}/>})
                break;
            case 'infouser':
                this.setState({page:<InfoUser/>})
                break;
            case 'inventory':
                this.setState({page:<InventoryUser/>})
                break;
            case 'visituser':
                this.setState({page:<VisitUser/>})
                break;
            case 'requesthome':
                this.setState({page:<RequestHome/>})
                break;
            case 'requesthomelist':
                this.setState({page:<RequestHomeList user={this.props.user}/>})
                break;
            case 'listinguser':
                this.setState({page:<ListingUser user={this.props.user}/>})
                break;
            case 'createlisting':
                this.setState({page:<CreateListing/>})
                break;
            case 'visitlitinguser':
                this.setState({page:<VisitListingUser/>})
                break;
            case 'requestvip':
                this.setState({page:<RequestVip/>})
                break;
            case 'edituser':
                this.setState({page:<EditUserInfo/>})
                break;
            case 'requestcontract':
                this.setState({page:<RequestContract/>})
                break;
            case 'requsetconvert':
                this.setState({page:<RequestConvert user={this.props.user}/>})
                break;
            case 'convertlist':
                this.setState({page:<ConvertList user={this.props.user}/>})
                break;
            case 'addinventory':
                this.setState({page:<AddInventory/>})
                break;
            case 'visitrequest':
                this.setState({page:<VisitRequest/>})
                break;
            case 'contractrequest':
                this.setState({page:<ContractList/>})
                break;
            case 'definesuport':
                this.setState({page:<DefineSupporter/>})
                break;
            case 'withdrawuser':
                this.setState({page:<WithdrawUser/>})
                break;
            case 'withdrawadmin':
                this.setState({page:<WithdrawAdmin/>})
                break;
            case 'cancelvisit':
                this.setState({page:<CancelVisit/>})
                break;
            case 'numberagency':
                this.setState({page:<AgancyAdmin/>})
                break;
            default:
                break;
        }
        
    }

    render(){
        return(
            <Container disableGutters style={{display:'flex',flexDirection:'row'}} maxWidth="2200px">
                <Grid style={{backgroundColor:'#14C9E8',overflowX:'hidden',flexDirection:'column',zIndex:100,overflowY:'scroll',height:'100%',WebkitOverflowScrolling:'touch'}} className="side_menu"
                        sx={{width:{md:270,xs:this.state.displayPanel ? '100%' : 0},height:{xs:'100vh'},position:{xs:'fixed'},borderBottomLeftRadius:{md:40},
                                overflowY:'scroll',top:{md:0},right:{md:0},display:{xs:this.state.displayPanel ? 'flex' : 'none'}}}
                >       
                    <Grid style={{display:'flex',marginTop:100,alignItems:'center'}} className="accordGridbackup"
                        sx={{flexDirection:{sm:'column',xs:'row'},flexWrap:{sm:'nowrap',xs:'wrap'}}}
                    >
                        {this.createAccord()}          

                    </Grid>
                    <Grid style={{position:'absolute',right:'3%',top:75}} sx={{display:{md:'none',xs:!this.state.displayButtonPanel ? 'flex' : 'none'} }}>
                        <IconButton size="small" className="filter_button_close" onClick={this.onClickFilter}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid style={{position:'absolute',display:this.state.displayButtonPanel ? 'flex' : 'none',top:75,left:'4vw'}}>
                    <IconButton size="large" className="filter_button" onClick={this.onClickFilter}>
                        <MenuOpenIcon fontSize="medium"/>
                    </IconButton>
                </Grid>
                {this.state.page !== "" ? 
                    this.state.page 
                :   <Grid
                        sx={{background:`url(${logoBackground})`,backgroundPosition:{xs:"50% 60%",md:"40% 60%"},backgroundRepeat:"no-repeat",backgroundSize:{xs:"50%",md:"40% auto"},
                            margin:'0 10%',backgroundAttachment:"fixed",height:{xs:'calc(100vh - 60px)',md:'calc(100vh - 88px)'},
                            width:"100%"}}>
                    </Grid>
                }

            </Container>
        )
    }
}

export default SideMenu;