import {  Button, Divider, Grid, OutlinedInput, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from './gifLogo';
import { Link } from "react-router-dom";

class CancelVisit extends React.Component{

    isMount = false;
    token = localStorage.getItem('tokenUserHomeInja');

    constructor(props){
        super(props);
        this.state={
            searchList:[],
            openAlert:false,
            message:'',
            typeAlert:null,
            giftShow:false,
            searchText:''
        }
    }

    componentDidMount(){
        this.isMount = true;
        if (this.isMount){
            this.getCancelVisit();
        }
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    closeAlertModal = () =>{
        this.setState({openAlert:false});
    }

    getCancelVisit = () =>{
        Promise.resolve(axios({
            url: baseUrl + 'v1/visit_user/getCanecl',
            method: 'GET',
            headers:{'Authorization':'bearer ' + this.token}
        }))
        .then((res) => {
            let items = res.data.data;
            this.setState({searchList:items})
        })
        .catch((err) => {
            this.setState({openAlert:true,message:'عملیات با مشکل مواجه شد.',typeAlert:2})
        });
    }

    deleteVisit = (id) =>{
        Promise.resolve(axios({
            url: baseUrl + 'v1/visit_user/delete',
            method: 'POST',
            headers:{'Authorization':'bearer ' + this.token},
            data:{
                visit_id:id
            }
        }))
        .then(() => {
            if (this.state.searchText !== ''){
                this.search();
            }
            else{
                this.getCancelVisit();
            }
            this.setState({openAlert:true,message:'عملیات لغو بازدید با موفقیت انجام شد.',typeAlert:1})
        })
        .catch(() => {
            this.setState({openAlert:true,message:'عملیات لغو بازدید با مشکل مواجه شد.',typeAlert:2})
        });
    }

    createCancelList = () =>{
        let items = [];
        this.state.searchList.forEach((element,index) =>{
          items.push(
            <Grid key={index} item xs={12} 
                  style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom:'10px',
                          backgroundColor:index % 2 === 0 ? 'white' : '#CCF4FB',borderRadius:16,width:'100%',
                          flexWrap:'wrap',filter:'drop-shadow(0px 0px 2px rgba(0, 200, 233, 0.32))'}}
                  
            >
                <Grid item lg={2} sm={4} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
                    <Button style={{width:'100%',color:'black'}} onClick={() => this.onClickListingId(element.listing_id)} component={Link} to={'/listing'} target="_blank">
                    <Grid item lg={6} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                            {'شماره آگهی:'}
                        </Typography>
                        </Grid>
                        <Grid item lg={6} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                            {element.listing_id}
                        </Typography>
                        </Grid>
                    </Button>
                </Grid>
                <Grid item lg={4} sm={8} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
                    <Grid item lg={4} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                        {'آگهی دهنده:'}
                        </Typography>
                    </Grid>
                    <Grid item lg={8} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                        {element.user.name + ' ' + element.user.lastname + ' - ' + convertToPersianNumber(element.user.mobile)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={4} sm={8} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
                    <Grid item lg={4} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                        {'بازدید کننده:'}
                        </Typography>
                    </Grid>
                    <Grid item lg={8} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}} sx={{fontSize:{sm:'16px',xs:'13px'}}}>
                        {element.visitor.name + ' ' + element.visitor.lastname + ' - ' + convertToPersianNumber(element.visitor.mobile)}
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid item lg={2} sm={4} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} sx={{height:{sm:'80px',xs:'60px'}}}>
                    <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
                    <Button onClick={() => this.deleteVisit(element.id)}
                        style={{backgroundColor:'#003249',color:'white',fontFamily:'shabnam',fontSize:'14px',fontWeight:'bold'}}
                        sx={{height:{sm:'30px',xs:'40px'},width:{xs:'80%'}}}
                    >
                        {'حذف بازدید'}
                    </Button>
                    </Grid>
                </Grid>
            </Grid>
          )
        })
        return items;
    }

    onChangeSearch = (event) =>{
        this.setState({searchText:event.target.value.trim()});
    }

    onClickSearch = () =>{
        if (this.state.searchText !== ''){
            this.search();
        }
        else{
            this.getCancelVisit();
        }
    }

    search = () =>{
        Promise.resolve(axios({
            url: baseUrl+'v1/visit_user/search',
            method: 'GET',
            headers:{'Authorization':'bearer ' + this.token},
            params:{
                search:this.state.searchText
            }
        }))
        .then((res) => {
            this.setState({searchList:res.data.data,giftShow:false})
        })
        .catch((err) => {
            this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',giftShow:false,typeAlert:2})
        });
    }

    render() {

      return (
        <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>لغو درخواست بازدید</Typography>
          </Grid>
          <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
          >    
            <Grid item xs={10} style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',alignItems:'center',marginTop:25}}>
                <Grid item xs={8} style={{display:'flex',width:'100%'}}>
                    <OutlinedInput variant="outlined" onChange={(event) => this.onChangeSearch(event)} value={this.state.searchText}
                        style={{borderStyle:'none',width:'100%',fontFamily:'shabnam',fontWeight:'bold',backgroundColor:'#F1F2F2',
                                maxHeight:70,borderRadius:0}}
                        sx={{height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
                        inputProps={{maxLength:30}}
                    />
                </Grid>
                <Grid item xs={4} style={{display:'flex',width:'100%'}}>
                    <Button onClick={this.onClickSearch} 
                        style={{backgroundColor:'#00C8E9',color:'black',fontFamily:'shabnam',
                                fontWeight:'bold',borderRadius:0,borderBottomLeftRadius:26}}
                        sx={{width:{xs:'100%'},height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
                    >
                        {'جستجو'}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{display:'flex',alignItems:'center',width:'100%',marginTop:20,marginBottom:20}}>
                <Grid item lg={1.5} md={2.5} xs={2} style={{display:'flex',width:'100%'}}>
                    <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                        {'نتیجه جستجو'}
                    </Typography>
                </Grid>
                <Grid item lg={8.5} md={7.5} xs={8} style={{display:'flex',width:'100%',flexDirection:'column'}}>
                    <Divider/>
                </Grid>
                <Grid item xs={0.5} style={{display:'flex',width:'100%'}}>
                    <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                        {convertToPersianNumber(this.state.searchList.length)}
                    </Typography>
                </Grid>
                <Grid item xs={1.5} style={{display:'flex',width:'100%'}}>
                    <Typography component={'p'} 
                        style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                        sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                        {'بازدید'}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}>
                {
                this.state.searchList.length === 0 ?
                    <Grid item xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',marginTop:'20%'}}>
                    <Typography variant="h1" sx={{fontSize:{md:'26px',xs:'16px'}}}
                        style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'center',marginBottom:5,width:'100%'}}>
                        {'هیچ موردی برای نمایش وجود ندارد.'}
                    </Typography>
                    </Grid>
                :
                    this.createCancelList()
                }
                
            </Grid>
            <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
            <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          </Grid>
          
        </Grid>
      )
    };
}

export default CancelVisit;