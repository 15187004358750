import { Autocomplete, Button, Checkbox, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Skeleton, Slider, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React from "react";
import { checkedIcon, convertToPersianNumber, icon, separator, baseUrl, bedRoom, facade, cover, cool_heat, kitchenEq, kitchenCabinet, safety, welfare } from "./materilas/vmp";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import '../styles/listings.css'
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import locationIcon from "../images/listing/location.png";
import { Link } from "react-router-dom";
import AlertModal from './materilas/alert';
import { setCanonicalTag } from "./materilas/vmp";



class Listings extends React.Component {

  isMount = false;
  optionSearch = null;
  prevScrollpos = window.pageYOffset;
  currenWidth = window.innerWidth;
  constructor(props) {

    super(props);
    this.state = {
      transaction: [],
      defualtTransaction: { des: 'خرید و فروش', id: 1 },
      transSearch: null,
      inputTrans: '',
      typeEstate: [],
      estateSearch: [],
      nameState: [],
      stateChoose: { name: 'تهران', id: 1 },
      stateSearch: null,
      inputState: '',
      defaultCity: { name: "تهران", id: 1 },
      city: [],
      citySearch: null,
      inputCity: '',
      disableCity: true,
      zone: [],
      zoneSearch: null,
      inputZone: '',
      disableZone: true,
      nieghbourhood: [],
      neibSearch: [],
      disableNaib: true,
      areaRange: [30, 3000],
      mortageRange: [10, 5000],
      rentRange: [0.5, 200],
      priceRange: [100, 900000],
      oldRange: [0, 50],
      roomNumber: null,
      clearText: 'حذف',
      closeText: "بستن",
      openText: "باز کردن",
      noOptionsText: "فاقد اطلاعات",
      displayPrice: false,
      displayRent: false,
      displayPer: false,
      parking: false,
      elevator: false,
      store: false,
      facadeSearch: [],
      coverSearch: [],
      coolHeatSearch: [],
      kitchenEquipmentSearch: [],
      kitchenCabinetSearch: [],
      safetySearch: [],
      welfareSearch: [],
      displayFilter: window.innerWidth <= 900 ? false : true,
      displayButtonFilter: window.innerWidth <= 900 ? true : false,
      pageSearch: 1,
      noResultPage: true,
      skeletonPage: false,
      resultInfo: [],
      adsCounter: 18,
      sortSelected: "",
      openAlert: false,
      message: "",
      typeAlert: 3,
      searchText: ""
    }
  }

  componentDidMount() {
    document.title = "جستجو ملک | جستجو پیشرفته ملک | جستجو آگهی |جستجو با انواع فیلتر |جستجو حرفه ای ملک | هومینجا"
    document.getElementById("main-heading").innerHTML = "مشاهده آگهی های مربوط به فروش انواع املاک در تهران"
    document.getElementsByTagName('meta')["description"].content = "اگهی فروش ملک درتهران ؛ شما در حال مشاهده به روز ترین آگهی های مربوط به فروش خانه، آپارتمان، ویلا، اداری و تجاری در بهترین سایت جستجوی ملک در تهران هستید. بهترین آگهی های رهن و اجاره در تهران را در بزرگترین سایت فایلینگ ایران ملاحظه بفرمایید. شما میتوانید با استفاده از فیلترهای پیشرفته هومینجا ملک مورد نظر خود را در کمترین زمان جستجو و پیدا کنید. با ثبت درخواست بازدید از ملک میتوانید به صورت حضوری از ملک دلخواه خود دیدن فرمایید.";
    document.getElementsByTagName('meta')["keywords"].content = "بهترین املاک برای فروش | فروش آپارتمان در تهران | بهترین روش فروش املاک و مستغلات | فروش ملک در کوتاه ترین زمان | آگهی رایگان فروش ملک | مشاهده آگهی های معاملات املاک در تهران | فروش آسان ملک شما | جستجوی ملک برای خرید | جستجو و خرید خانه"
    this.isMount = true;
    if (this.isMount) {
      this.getTransaction();
      this.getTypeEstate();
      this.getState();
      setCanonicalTag();
      this.optionSearch = this.props.option ? this.props.option : JSON.parse(localStorage.getItem('optionSearchHomeInja'))
      this.currenWidth = window.innerWidth;
      if (this.optionSearch !== null) {
        this.setState({
          transSearch: this.optionSearch.transaction === 1 ? { des: 'خرید و فروش', id: 1 } : { des: 'رهن و اجاره', id: 2 },
          stateSearch: this.optionSearch.state ? this.optionSearch.state : this.state.stateChoose,
          inputState: this.optionSearch.state ? this.optionSearch.state.name : this.state.stateChoose.name,
          inputTrans: this.optionSearch.transaction === 1 ? 'خرید و فروش' : 'رهن و اجاره',
          estateSearch: this.optionSearch.estate ? this.optionSearch.estate : [],
          inputCity: this.optionSearch.city ? this.optionSearch.city.name : '',
          citySearch: this.optionSearch.city ? this.optionSearch.city : null,
          inputZone: this.optionSearch.zone ? this.optionSearch.zone.name : '',
          zoneSearch: this.optionSearch.zone ? this.optionSearch.zone : null,
          neibSearch: this.optionSearch.nieghbourhood ? this.optionSearch.nieghbourhood : [],
          areaRange: this.optionSearch.area ? this.optionSearch.area : [30, 3000],
          mortageRange: this.optionSearch.mortage ? this.optionSearch.mortage : [10, 5000],
          rentRange: this.optionSearch.rent ? this.optionSearch.rent : [0.5, 200],
          priceRange: this.optionSearch.price ? this.optionSearch.price : [100, 900000],
          displayPrice: this.optionSearch.transaction === 1 ? true : false,
          displayRent: this.optionSearch.transaction === 2 ? true : false,
          roomNumber: this.optionSearch.room ? this.optionSearch.room === "great" ? { num: '11+' } : { num: this.optionSearch.room } : null
        }, () => { this.checkSearchListing() }
        )
        if (this.optionSearch.state) {
          this.getCity(this.optionSearch.state.id);
        }
        if (this.optionSearch.city) {
          this.getZone(this.optionSearch.city.id);
        }
        if (this.optionSearch.zone) {
          this.getNieghbourhood(this.optionSearch.zone.id);
        }
      }
      else {
        this.setState(
          {
            inputTrans: this.state.defualtTransaction.des, transSearch: this.state.defualtTransaction, inputState: this.state.stateChoose.name,
            stateSearch: this.state.stateChoose, displayPrice: this.state.defualtTransaction.id === 1 ? true : false,
            displayRent: this.state.defualtTransaction.id === 2 ? true : false, displayPer: this.state.defualtTransaction === 3 ? true : false,
            citySearch: this.state.defaultCity, inputCity: this.state.defaultCity.name
          }, () => { this.checkSearchListing() }
        )
        this.getCity(this.state.stateChoose.id);
        this.getZone(this.state.defaultCity.id);
      }
      window.addEventListener('resize', () => {

        if (window.innerWidth !== this.currenWidth) {
          if (window.innerWidth <= 900 && this.currenWidth !== window.innerWidth) {
            this.currenWidth = window.innerWidth;
            this.setState({ displayFilter: false, displayButtonFilter: true })
          }
          else {
            this.currenWidth = window.innerWidth;
            this.setState({ displayFilter: true, displayButtonFilter: false })
          }
        }
      })
      //window.addEventListener('scroll',this.onScrolling);
    }
  }

  componentWillUnmount() {
    this.isMount = false;
    window.removeEventListener('resize', () => { });
    window.removeEventListener('scroll', () => { });
    localStorage.removeItem("optionSearchHomeInja");
    this.setDefaultTags();
  }

  getTransaction = () => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/trans/get',
      method: 'GET'
    }))
      .then((res) => {
        this.setState({ transaction: res.data.data })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  onChangeTransaction = (event, newValue) => {
    event.preventDefault();
    this.setState({ transSearch: null, displayPrice: false, displayRent: false, displayPer: false, inputTrans: '' })
    if (newValue !== null) {
      this.setState({
        transSearch: newValue, displayPrice: newValue.id === 1 ? true : false, displayRent: newValue.id === 2 ? true : false,
        displayPer: newValue.id === 3 ? true : false, inputTrans: newValue.des
      })
    }
  }

  getTypeEstate = () => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/estate/get',
      method: 'GET'
    }))
      .then((res) => {
        this.setState({ typeEstate: res.data.data })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  onChangeTypeEstate = (event, newValue) => {
    this.setState({ estateSearch: newValue })
  }

  getState = () => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/state/get',
      method: 'GET'
    }))
      .then((res) => {
        this.setState({ nameState: res.data.data })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  onChangeState = (event, newValue) => {
    this.setState({
      inputState: '', inputCity: '', inputZone: '', disableCity: true, disableZone: true, disableNaib: true, stateSearch: null,
      citySearch: null, zoneSearch: null, neibSearch: []
    })
    if (newValue !== null) {
      this.getCity(newValue.id);
      this.setState({ stateSearch: newValue, inputState: newValue.name })
    }
  }

  getCity = (stateValue) => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/city/get',
      method: 'GET',
      params: {
        state_id: stateValue
      }
    }))
      .then((res) => {
        this.setState({ city: res.data.data, disableCity: res.data.data.length > 0 ? false : true })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  onChangeCity = (event, newValue) => {
    this.setState({ inputCity: '', inputZone: '', disableZone: true, disableNaib: true, citySearch: null, zoneSearch: null, neibSearch: [] })
    if (newValue !== null) {
      this.getZone(newValue.id);
      this.setState({ citySearch: newValue, inputCity: newValue.name })
    }
  }

  getZone = (city) => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/zone/get',
      method: 'GET',
      params: {
        city_id: city
      }
    }))
      .then((res) => {
        this.setState({ zone: res.data.data, disableZone: res.data.data.length > 0 ? false : true })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  onChangeZone = (event, newValue) => {
    this.setState({ inputZone: '', disableNaib: true, zoneSearch: null, neibSearch: [] })
    if (newValue !== null) {
      this.getNieghbourhood(newValue.id)
      this.setState({ zoneSearch: newValue, inputZone: newValue.name })
    }
  }

  getNieghbourhood = (zone) => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/neighb/get',
      method: 'GET',
      params: {
        zone_id: zone
      }
    }))
      .then((res) => {
        this.setState({ nieghbourhood: res.data.data, disableNaib: res.data.data.length > 0 ? false : true })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  onChangeNieghbourhood = (event, newValue) => {
    this.setState({ neibSearch: newValue })
  }

  onChangeAria = (event, newValue) => {
    this.setState({ areaRange: newValue })
  }

  onChangeMortage = (event, newValue) => {
    this.setState({ mortageRange: newValue })
  }

  onChangeRent = (event, newValue) => {
    this.setState({ rentRange: newValue })
  }

  onChangePrice = (event, newValue) => {
    this.setState({ priceRange: newValue })
  }

  onChangeOld = (event, newValue) => {
    this.setState({ oldRange: newValue })
  }

  onChangeBedRoom = (event, newValue) => {
    this.setState({ roomNumber: newValue })
  }

  onChangeParking = () => {
    this.setState({ parking: !this.state.parking })
  }

  onChangeElevator = () => {
    this.setState({ elevator: !this.state.elevator })
  }

  onChangeStore = () => {
    this.setState({ store: !this.state.store })
  }

  onChangeFacade = (event, newValue) => {
    this.setState({ facadeSearch: newValue })
  }

  onChangeCover = (event, newValue) => {
    this.setState({ coverSearch: newValue });
  }

  onChangeCoolHeat = (event, newValue) => {
    this.setState({ coolHeatSearch: newValue });
  }

  onChangeKitchenEq = (event, newValue) => {
    this.setState({ kitchenEquipmentSearch: newValue });
  }

  onChangeKitchenCabinet = (event, newValue) => {
    this.setState({ kitchenCabinetSearch: newValue })
  }

  onChangeSafety = (event, newValue) => {
    this.setState({ safetySearch: newValue });
  }

  onChangeWelfare = (event, newValue) => {
    this.setState({ welfareSearch: newValue })
  }

  onClickFilter = () => {
    this.setState({ displayFilter: !this.state.displayFilter, displayButtonFilter: !this.state.displayButtonFilter })
  }

  // onScrollingListings = () =>{
  //   let currentPos = window.pageYOffset;
  //   //var n = Array.from(document.getElementsByClassName("resultSearch"));

  //   if (currentPos + window.innerHeight / 2 > this.prevScrollpos){
  //     this.setState({pageSearch:this.state.pageSearch + 1})
  //     this.prevScrollpos += this.prevScrollpos;
  //     this.searchListing()
  //   }
  // }

  checkSearchListing = () => {
    switch (null) {
      case this.state.transSearch:
        this.setState({ openAlert: true, message: "نوع معامله وارد نشده است", typeAlert: 3 });
        break;
      case this.state.stateSearch:
        this.setState({ openAlert: true, message: "استان وارد نشده است", typeAlert: 3 });
        break;
      case this.state.citySearch:
        this.setState({ openAlert: true, message: "شهر وارد نشده است", typeAlert: 3 });
        break;
      default:
        if (window.innerWidth <= 900) {
          if (this.state.displayFilter) {
            this.setState({ displayFilter: false, displayButtonFilter: true })
          }
        }
        this.searchListing();
    }
  }

  searchListing = () => {
    this.setState({ skeletonPage: true, noResultPage: false, resultInfo: [] });
    let params = {
      state_id: this.state.stateSearch.id,
      transaction_id: this.state.transSearch.id,
      old_min: this.state.oldRange[0],
      old_max: this.state.oldRange[1],
      area_min: this.state.areaRange[0],
      area_max: this.state.areaRange[1],
      page: this.state.pageSearch,
      city_id: this.state.citySearch.id,
    }
    if (this.state.estateSearch.length > 0) {
      params.estate_id = this.state.estateSearch.map(element => element.id);
    }
    if (this.state.zoneSearch) {
      params.zone_id = this.state.zoneSearch.id;
    }
    if (this.state.neibSearch.length > 0) {
      params.neighbourhood_id = this.state.neibSearch.map(element => element.id);
    }
    if (this.state.transSearch.id === 1) {
      params.price_min = this.state.priceRange[0] * 1000000;
      params.price_max = this.state.priceRange[1] * 1000000;
    }
    if (this.state.transSearch.id === 2) {
      params.mortgage_min = this.state.mortageRange[0] * 1000000;
      params.mortgage_max = this.state.mortageRange[1] * 1000000;
      params.rent_min = this.state.rentRange[0] * 1000000;
      params.rent_max = this.state.rentRange[1] * 1000000;
    }
    if (this.state.transSearch.id === 3) {
      params.prebuy_min = this.state.priceRange[0] * 1000000;
      params.prebuy_max = this.state.priceRange[1] * 1000000;
    }
    if (this.state.roomNumber !== null) {
      if (this.state.roomNumber.num === "11+") {
        params.bedroom_num = "great";
      } else {
        params.bedroom_num = this.state.roomNumber.num;
      }
    }
    if (this.state.parking) {
      params.parking = 1;
    }
    if (this.state.elevator) {
      params.elevator = 1;
    }
    if (this.state.store) {
      params.store = 1;
    }
    if (this.state.facadeSearch.length > 0) {
      params.facade = this.state.facadeSearch.map(element => element.f);
    }
    if (this.state.coverSearch.length > 0) {
      params.cover = this.state.coverSearch.map(element => element.f);
    }
    if (this.state.coolHeatSearch.length > 0) {
      params.condition = this.state.coolHeatSearch.map(element => element.f);
    }
    if (this.state.kitchenEquipmentSearch.length > 0) {
      params.kitchen = this.state.kitchenEquipmentSearch.map(element => element.f);
    }
    if (this.state.kitchenCabinetSearch.length > 0) {
      params.cabinet = this.state.kitchenCabinetSearch.map(element => element.f);
    }
    if (this.state.safetySearch.length > 0) {
      params.safety = this.state.safetySearch.map(element => element.f);
    }
    if (this.state.welfareSearch.length > 0) {
      params.welfare = this.state.welfareSearch.map(element => element.f);
    }
    this.getSearch(params);
  }

  getSearch = (params) => {
    document.title = `${this.state.transSearch.des + " " + (this.state.estateSearch.length > 0 ? "ملک " + this.state.estateSearch[0].des : "ملک") + " " +
      (this.state.citySearch ? "در " + this.state.citySearch.name : "") +
      (this.state.neibSearch.length > 0 ? " - " + this.state.neibSearch.map(element => element.name).join(" - ") : this.state.zoneSearch ? (" " + this.state.zoneSearch.name) : "") +
      (this.state.estateSearch.length > 0 ? "" : " - آپارتمان - برج - اداری - تجاری - ویلایی - خانه")}  | هومینجا`;
    let searchText = "";
    searchText = `${this.state.transSearch.des + " " + (this.state.estateSearch.length > 0 ? "ملک " + this.state.estateSearch[0].des : "ملک") + " " + (this.state.citySearch ? " در " + this.state.citySearch.name : "")}`;
    if (this.state.neibSearch.length > 0) {
      for (let i = 0; i < this.state.neibSearch.length; i++) {
        if (i === 4) {
          searchText += " ..."
          break
        }
        searchText += (" - " + this.state.neibSearch[i].name);
      }
    } else if (this.state.zoneSearch) {
      searchText += " " + convertToPersianNumber(this.state.zoneSearch.name);
    }
    this.setState({ searchText: searchText });
    this.setH1(searchText);
    this.setMetaTagDesc(searchText);
    searchText = "";
    Promise.resolve(axios({
      url: baseUrl + "v1/listing/search",
      method: "GET",
      params: params,
    }))
      .then((res) => {
        if (res.data.data.length > 0) {
          this.setState({ resultInfo: this.putVipAdsTop(res.data.data), skeletonPage: false, adsCounter: 18, sortSelected: "" });
        } else {
          this.setState({ noResultPage: true, skeletonPage: false, sortSelected: "" });
        }
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید", skeletonPage: false, typeAlert: 2, sortSelected: "" })
      })
  }

  createSkeleton = () => {
    let result = [];
    for (let i = 0; i < 9; i++) {
      result.push(
        <Grid item xs={4} lg={4} xl={4} sx={{ width: "100%", padding: "10px" }} key={i}>
          <Grid
            sx={{
              width: "100%", padding: "0", "&:hover": { bgcolor: "transparent" }, color: "black", boxShadow: "0px 0px 17px -5px #D1D1D1",
              background: "linear-gradient(0deg, rgba(120,231,245,0.03) 0%, rgba(255,255,255,1) 100%)", borderRadius: "30px 4px 4px 4px"
            }}
          >
            <Grid sx={{ borderRadius: "30px 4px 4px 4px", overflow: "hidden", width: "100%" }}>
              <Grid container sx={{ width: '100%' }} columns={{ xs: 12, sm: 12, lg: 12 }}>
                <Grid item xs={5} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Skeleton variant="text" width="50%" animation="wave" />
                  <Skeleton variant="text" width="60%" animation="wave" />
                </Grid>
                <Grid item xs={7} sx={{ width: "100%", height: "200px", borderBottom: "1px solid #eee" }}>
                  <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
                </Grid>
              </Grid>
              <Grid container sx={{ width: "100%", padding: "30px 0" }} columns={{ xs: 12, sm: 12, lg: 12 }}>
                <Grid item xs={5} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                  <Skeleton variant="text" width="50%" animation="wave" />
                </Grid>
                <Grid item xs={7} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                  <Skeleton variant="text" width="50%" animation="wave" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return result;
  }

  createAds = () => {
    let result = [];
    let ads = this.state.resultInfo.slice(0, this.state.adsCounter);
    ads.forEach((element, index) => {
      result.push(
        <Grid item xs={4} lg={4} xl={4} sx={{ width: "100%", padding: "10px" }} key={element.created_at}>
          <Button disableRipple component={Link} to={"/Listing/" + element.id} target="_blank"
            sx={{
              width: "100%", padding: "0", "&:hover": { bgcolor: "transparent", boxShadow: "0px 0px 17px -5px #343434" }, color: "black", borderRadius: "40px 8px 8px 8px", boxShadow: "0px 0px 17px -5px #D1D1D1",
              border:
                element.plan_id === 2 ?
                  "2px solid #faa638"
                  : element.plan_id === 3 ?
                    "2px solid #fbb151"
                    : element.plan_id === 4 ?
                      "2px solid #fcc983"
                      : element.plan_id === 5 ?
                        "2px solid #c7c1d7"
                        : "2px solid #eee"
              , background:
                element.plan_id === 2 ?
                  "linear-gradient(0deg, rgba(250,166,56,0.7) 0%, rgba(255,255,255,1) 100%)"
                  : element.plan_id === 3 ?
                    "linear-gradient(0deg, rgba(251,177,81,0.5) 0%, rgba(255,255,255,1) 100%)"
                    : element.plan_id === 4 ?
                      "linear-gradient(0deg, rgba(252,201,131,0.5) 0%, rgba(255,255,255,1) 100%)"
                      : element.plan_id === 5 ?
                        "linear-gradient(0deg, rgba(199,193,215,0.5) 0%, rgba(255,255,255,1) 100%)"
                        : "linear-gradient(0deg, rgba(120,231,245,0.03) 0%, rgba(255,255,255,1) 100%)"
            }}
          >
            <Grid sx={{ borderRadius: "40px 8px 8px 8px", overflow: "hidden", width: "100%" }}>
              <Grid container sx={{ width: '100%' }} columns={{ xs: 12, sm: 12, lg: 12 }}>
                <Grid item xs={5} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  {element.show_price === 1 ?
                    element.transaction_id === 1 ?
                      <>
                        <Typography sx={{ width: "100%", fontFamily: "shabnam", textAlign: "center", fontWeight: "bold" }}>قیمت</Typography>
                        <Typography sx={{ fontWeight: "bold", fontSize: { xs: "12px", md: "16px" }, fontFamily: "shabnam" }}>
                          {parseInt(element.total_price) ?
                            `${convertToPersianNumber(separator(element.total_price))} تومان`
                            : "توافقی"
                          }
                        </Typography>
                      </>
                      : element.transaction_id === 3 ?
                        <>
                          <Typography sx={{ width: "100%", fontFamily: "shabnam", textAlign: "center", fontWeight: "bold" }}>پیش خرید</Typography>
                          <Typography sx={{ fontWeight: "bold", fontSize: { xs: "12px", md: "16px" }, fontFamily: "shabnam" }}>
                            {parseInt(element.prebuy) ?
                              `${convertToPersianNumber(separator(element.prebuy))} تومان`
                              : "توافقی"
                            }
                          </Typography>
                        </>
                        : element.transaction_id === 2 ?
                          <>
                            <Typography sx={{ width: "100%", fontFamily: "shabnam", textAlign: "center", fontWeight: "bold" }}>رهن</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: { xs: "12px", md: "16px" }, fontFamily: "shabnam" }}>
                              {parseInt(element.mortgage) ?
                                `${convertToPersianNumber(separator(element.mortgage))} تومان`
                                : "توافقی"
                              }
                            </Typography>
                            <Typography sx={{ width: "100%", fontFamily: "shabnam", textAlign: "center", fontWeight: "bold" }}>اجاره</Typography>
                            <Typography sx={{ fontWeight: "bold", fontSize: { xs: "12px", md: "16px" }, fontFamily: "shabnam" }}>
                              {parseInt(element.rent) ?
                                `${convertToPersianNumber(separator(element.rent))} تومان`
                                : "توافقی"
                              }
                            </Typography>
                          </>
                          : <Grid></Grid>
                    : <>
                      <Typography sx={{ width: "100%", fontFamily: "shabnam", textAlign: "center", fontWeight: "bold" }}>قیمت</Typography>
                      <Typography sx={{ width: "100%", fontFamily: "shabnam", textAlign: "center", fontWeight: "bold" }}>توافقی</Typography>
                    </>
                  }
                </Grid>
                <Grid item xs={7}
                  sx={{
                    width: "100%", height: { xs: "150px", md: "200px" }, background: `url(${element.small.length > 0 ? element.small[0].path : noLogoAgancyImage})`,
                    backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRight: "1px solid #eee", borderBottom: "1px solid #eee"
                  }}
                >
                  {element.small.length > 0 ?
                    <Typography component={"img"} src={element.small[0].path} sx={{ display: "none" }}
                      alt={`${element.transaction.des + " " + element.estate.des + " " + convertToPersianNumber(element.area) + " متری " + element.state.name + " - " + element.neighbourhood.name + " | هومینجا"}`}
                    />
                    : null}
                </Grid>
              </Grid>
              <Grid container sx={{ width: "100%", padding: "30px 0" }} columns={{ xs: 12, sm: 12, lg: 12 }}>
                <Grid item xs={5} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                  <Typography sx={{ fontFamily: "shabnam", textAlign: "center", fontWeight: "bold", width: "auto" }}>متراژ :</Typography>
                  <Typography sx={{ fontFamily: "shabnam", textAlign: "center", fontWeight: "bold", width: "auto", marginRight: "5px" }}>
                    {convertToPersianNumber(element.area)}
                  </Typography>
                  <Typography sx={{ fontFamily: "shabnam", textAlign: "center", fontWeight: "bold", width: "auto", marginRight: "5px" }}>
                    متر
                  </Typography>
                </Grid>
                <Grid item xs={7} sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row" }}>
                  <Typography component={"img"} src={locationIcon} alt="icon" sx={{ width: "20px", height: "auto" }} />
                  <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "center", fontWeight: "bold", width: "auto", marginRight: "5px" }}>
                    {element.state ? element.state.name : ""} -
                  </Typography>
                  <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "center", fontWeight: "bold", width: "auto", marginRight: "5px" }}>
                    {element.neighbourhood ? element.neighbourhood.name : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      )
    })
    return result;
  }

  onClickAd = (element) => {
    localStorage.setItem("idListing", JSON.stringify(element.id));
  }

  onClickMoreAds = () => {
    this.setState({ adsCounter: this.state.adsCounter + 18 });
  }

  onChangeSort = (event) => {
    this.setState({ sortSelected: event.target.value });
    let data = this.state.resultInfo;
    if (event.target.value === 1) {
      data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      this.setState({ resultInfo: this.putVipAdsTop(data) });
    }
    if (event.target.value === 2) {
      if (data[0].total_price) {
        data.sort(function (a, b) {
          return b.total_price - a.total_price;
        });
        this.setState({ resultInfo: this.putVipAdsTop(data) });
      }
      if (data[0].mortgage) {
        data.sort(function (a, b) {
          return b.mortgage - a.mortgage;
        });
        this.setState({ resultInfo: this.putVipAdsTop(data) });
      }
      if (data[0].prebuy) {
        data.sort(function (a, b) {
          return b.prebuy - a.prebuy;
        });
        this.setState({ resultInfo: this.putVipAdsTop(data) });
      }
    }
    if (event.target.value === 3) {
      if (data[0].total_price) {
        data.sort(function (a, b) {
          return a.total_price - b.total_price;
        });
        this.setState({ resultInfo: this.putVipAdsTop(data) });
      }
      if (data[0].mortgage) {
        data.sort(function (a, b) {
          return a.mortgage - b.mortgage;
        });
        this.setState({ resultInfo: this.putVipAdsTop(data) });
      }
      if (data[0].prebuy) {
        data.sort(function (a, b) {
          return a.prebuy - b.prebuy;
        });
        this.setState({ resultInfo: this.putVipAdsTop(data) });
      }
    }
  }

  putVipAdsTop = (data) => {
    data.sort(function (a, b) {
      switch (1) {
        case a.plan_id:
          return (a.plan_id + 6) - b.plan_id;
        case b.plan_id:
          return a.plan_id - (b.plan_id + 6);
        default:
          return a.plan_id - b.plan_id;
      }
    });
    return data;
  }

  oncloseAlert = () => {
    this.setState({ openAlert: false });
  }

  resetFilters = () => {
    this.setState({
      defualtTransaction: { des: 'خرید و فروش', id: 1 },
      transSearch: null,
      inputTrans: '',
      estateSearch: [],
      stateChoose: { name: 'تهران', id: 1 },
      stateSearch: null,
      inputState: '',
      city: [],
      citySearch: null,
      inputCity: '',
      disableCity: true,
      zone: [],
      zoneSearch: null,
      inputZone: '',
      disableZone: true,
      nieghbourhood: [],
      neibSearch: [],
      disableNaib: true,
      areaRange: [30, 3000],
      mortageRange: [10, 5000],
      rentRange: [0.5, 200],
      priceRange: [100, 900000],
      oldRange: [0, 50],
      roomNumber: null,
      displayPrice: false,
      displayRent: false,
      displayPer: false,
      parking: false,
      elevator: false,
      store: false,
      facadeSearch: [],
      coverSearch: [],
      coolHeatSearch: [],
      kitchenEquipmentSearch: [],
      kitchenCabinetSearch: [],
      safetySearch: [],
      welfareSearch: [],
      pageSearch: 1,
      adsCounter: 18,
      sortSelected: "",
    })
  }

  setH1 = (searchText) => {
    document.getElementById("main-heading").innerHTML = searchText;
  }

  setDefaultTags = () => {
    document.getElementsByTagName("h1")[0].innerHTML = "خرید - فروش - رهن - اجاره - ملک - مسکن - آپارتمان - خانه - برج - اداری - پنت هاوس - هومینجا";
    document.getElementsByTagName('meta')["description"].content = `خرید و فروش ملک مسکونی، تجاری، اداری، برج
    ،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد`
  }

  setMetaTagDesc = (searchText) => {
    document.getElementsByTagName('meta')["description"].content = searchText;
  }

  render() {
    return (
      <Container style={{ margin: 0, padding: 0, display: 'flex', flexDirection: 'row' }} maxWidth={'px'}>
        <Grid style={{ backgroundColor: '#14C9E8', overflowX: 'hidden', flexDirection: 'column', paddingLeft: 10, zIndex: "100", overflowY: 'scroll', height: '100%', WebkitOverflowScrolling: 'touch' }} className="side_filter"
          sx={{
            width: { md: 270, xs: this.state.displayFilter ? '100%' : 0 }, height: { xs: '100vh' }, position: { xs: 'fixed' }, borderBottomLeftRadius: { md: 40 }, overflowY: 'scroll',
            top: { md: 0 }, right: { md: 0 }, display: { xs: this.state.displayFilter ? 'flex' : 'none' }
          }}
        >
          <Grid style={{ display: 'flex', marginTop: 100, alignItems: 'center', transition: '0.3s' }} className="filterGrid"
            sx={{ flexDirection: { md: 'column', xs: 'row' }, flexWrap: { md: 'nowrap', xs: 'wrap' } }}
          >
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={this.state.transaction}
                getOptionLabel={(option) => option.des}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='نوع معامله *' />}
                isOptionEqualToValue={(option, value) => option.des === value.des}
                defaultValue={this.state.defualtTransaction}
                value={this.state.transSearch}
                inputValue={this.state.inputTrans}
                onChange={this.onChangeTransaction}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={this.state.typeEstate}
                getOptionLabel={(option) => option.des}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='نوع ملک' />}
                isOptionEqualToValue={(option, value) => option.des === value.des}
                multiple
                limitTags={1}
                value={this.state.estateSearch}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.des}
                  </li>
                )}
                onChange={this.onChangeTypeEstate}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={this.state.nameState}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='استان *' />}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                defaultValue={this.state.stateChoose}
                value={this.state.stateSearch}
                inputValue={this.state.inputState}
                onChange={this.onChangeState}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={this.state.city}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='شهر *' />}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                defaultValue={this.state.defaultCity}
                disabled={this.state.disableCity}
                value={this.state.citySearch}
                inputValue={this.state.inputCity}
                onChange={this.onChangeCity}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={this.state.zone}
                getOptionLabel={(option) => convertToPersianNumber(option.name)}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='منطقه' />}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                disabled={this.state.disableZone}
                inputValue={convertToPersianNumber(this.state.inputZone)}
                value={this.state.zoneSearch}
                onChange={this.onChangeZone}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={this.state.nieghbourhood}
                getOptionLabel={(option) => convertToPersianNumber(option.name)}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='محله' />}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                disabled={this.state.disableNaib}
                disableCloseOnSelect
                multiple
                value={this.state.neibSearch}
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {convertToPersianNumber(option.name)}
                  </li>
                )}
                onChange={this.onChangeNieghbourhood}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className='slider_listing'>
              <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: "10px" }}>
                <HomeinjaTypo sx={{ fontSize: { md: '0.7em', sm: '0.7em', xs: '0.6em' } }} text={'متراژ - مترمربع'} />
                <Typography style={{ fontFamily: 'shabnam', backgroundColor: 'white' }} sx={{ fontSize: { md: '0.9em', sm: '0.8em', xs: '0.6em' } }} component={'p'}>
                  {convertToPersianNumber(this.state.areaRange[1]) + ' تا ' + convertToPersianNumber(this.state.areaRange[0])}
                </Typography>
              </Grid>
              <Box>
                <Slider
                  getAriaLabel={() => 'متراژ'}
                  valueLabelDisplay="off"
                  min={30}
                  max={3000}
                  step={50}
                  value={this.state.areaRange}
                  onChange={this.onChangeAria}
                  valueLabelFormat={(x) => convertToPersianNumber(x) + ' مترمربع '}
                />
              </Box>
            </Grid>
            <Grid item md={11} sm={4} xs={6} className='slider_listing' sx={{ paddingTop: { xs: "10px", md: "0" } }}
              style={{ display: this.state.displayPrice || this.state.displayPer ? 'flex' : 'none' }}>
              <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <HomeinjaTypo sx={{ fontSize: { md: '0.7em', sm: '0.7em', xs: '0.6em' } }} text={'قیمت - میلیون تومان'} />
                <Typography style={{ fontFamily: 'shabnam', backgroundColor: 'white' }} sx={{ fontSize: { md: '0.9em', sm: '0.8em', xs: '0.6em' } }} component={'p'}>
                  {convertToPersianNumber(separator(this.state.priceRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.priceRange[0]))}
                </Typography>
              </Grid>
              <Box>
                <Slider
                  getAriaLabel={() => 'قیمت'}
                  valueLabelDisplay="off"
                  min={100}
                  max={900000}
                  step={500}
                  value={this.state.priceRange}
                  onChange={this.onChangePrice}
                  valueLabelFormat={(x) => convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                />
              </Box>
            </Grid>
            <Grid item md={11} sm={4} xs={6} className='slider_listing' style={{ display: this.state.displayRent ? 'flex' : 'none', paddingTop: "10px" }}>
              <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <HomeinjaTypo sx={{ fontSize: { md: '0.7em', sm: '0.7em', xs: '0.6em' } }} text={'رهن - میلیون تومان'} />
                <Typography style={{ fontFamily: 'shabnam', backgroundColor: 'white' }} sx={{ fontSize: { md: '0.9em', sm: '0.8em', xs: '0.6em' } }} component={'p'}>
                  {convertToPersianNumber(separator(this.state.mortageRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.mortageRange[0]))}
                </Typography>
              </Grid>
              <Box>
                <Slider
                  getAriaLabel={() => 'رهن'}
                  valueLabelDisplay="off"
                  min={10}
                  max={5000}
                  step={20}
                  value={this.state.mortageRange}
                  onChange={this.onChangeMortage}
                  valueLabelFormat={(x) => convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                />
              </Box>
            </Grid>
            <Grid item md={11} sm={4} xs={6} className='slider_listing' sx={{ display: this.state.displayRent ? 'flex' : 'none', paddingTop: { xs: "10px", md: "0" } }}>
              <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <HomeinjaTypo sx={{ fontSize: { md: '0.7em', sm: '0.7em', xs: '0.6em' } }} text={'اجاره - میلیون تومان'} />
                <Typography style={{ fontFamily: 'shabnam', backgroundColor: 'white' }} sx={{ fontSize: { sm: '0.9em', xs: '0.7em' } }} component={'p'}>
                  {convertToPersianNumber(separator(this.state.rentRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.rentRange[0]))}
                </Typography>
              </Grid>
              <Box>
                <Slider
                  getAriaLabel={() => 'اجاره'}
                  valueLabelDisplay="off"
                  min={0.5}
                  max={200}
                  step={1}
                  value={this.state.rentRange}
                  onChange={this.onChangeRent}
                  valueLabelFormat={(x) => convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                />
              </Box>
            </Grid>
            <Grid item md={11} sm={4} xs={6} className='slider_listing'>
              <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: { xs: "10px", md: "0" } }}>
                <HomeinjaTypo sx={{ fontSize: { md: '0.7em', sm: '0.7em', xs: '0.6em' } }} text={'سن بنا - سال'} />
                <Typography style={{ fontFamily: 'shabnam', backgroundColor: 'white' }} sx={{ fontSize: { md: '0.9em', sm: '0.8em', xs: '0.6em' } }} component={'p'}>
                  {convertToPersianNumber(separator(this.state.oldRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.oldRange[0]))}
                </Typography>
              </Grid>
              <Box>
                <Slider
                  getAriaLabel={() => 'سن بنا'}
                  valueLabelDisplay="off"
                  min={0}
                  max={50}
                  step={1}
                  value={this.state.oldRange}
                  onChange={this.onChangeOld}
                  valueLabelFormat={(x) => convertToPersianNumber(separator(x)) + ' سال'}
                />
              </Box>
            </Grid>
            <Grid item md={11} sm={12} xs={12}
              style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center',
                justifyContent: 'center', width: '90%', marginTop: '0.5vh', marginBottom: '0.5vh'
              }}>
              <Grid item xl={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} className="checkBoxGrid">
                <Checkbox checked={this.state.parking} onChange={this.onChangeParking} />
                <p style={{ fontSize: '0.85em', fontWeight: '700' }}>{'پارکینگ'}</p>
              </Grid>
              <Grid item xl={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} className="checkBoxGrid">
                <Checkbox checked={this.state.elevator} onChange={this.onChangeElevator} />
                <p style={{ fontSize: '0.85em', fontWeight: '700' }}>{'آسانسور'}</p>
              </Grid>
              <Grid item xl={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} className="checkBoxGrid">
                <Checkbox checked={this.state.store} onChange={this.onChangeStore} />
                <p style={{ fontSize: '0.85em', fontWeight: '700' }}>{'انباری'}</p>
              </Grid>
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={bedRoom}
                getOptionLabel={(option) => convertToPersianNumber(option.num)}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='تعداد اتاق خواب' />}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {convertToPersianNumber(option.num)}
                  </li>
                )}
                onChange={this.onChangeBedRoom}
                value={this.state.roomNumber}
                isOptionEqualToValue={(option, value) => option.num === value.num}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={facade}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='نمای ساختمان' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeFacade}
                value={this.state.facadeSearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={cover}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='کفپوش' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeCover}
                value={this.state.coverSearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={cool_heat}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='تهویه مطبوع' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeCoolHeat}
                value={this.state.coolHeatSearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={kitchenEq}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='امکانات آشپزخانه' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeKitchenEq}
                value={this.state.kitchenEquipmentSearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={kitchenCabinet}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='نوع کابینت' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeKitchenCabinet}
                value={this.state.kitchenCabinetSearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={safety}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='امکانات امنیتی' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeSafety}
                value={this.state.safetySearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={4} xs={6} className="listingAuto">
              <Autocomplete
                id="combo_filter"
                className="filter_combo"
                options={welfare}
                getOptionLabel={(option) => option.n}
                renderInput={(params) => <TextField className="textField" {...params} placeholder='امکانات رفاهی' />}
                disableCloseOnSelect
                multiple
                limitTags={1}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.n}
                  </li>
                )}
                onChange={this.onChangeWelfare}
                value={this.state.welfareSearch}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item md={11} sm={12}
              sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginRight: '3%',
                paddingTop: { md: '10%', xs: '5%' }, paddingBottom: { md: '10%', xs: '2%' }
              }}>
              <Button sx={{ width: { md: '100vw', xs: '50%' } }} onClick={this.checkSearchListing}
                style={{ backgroundColor: '#003249', color: 'white', fontFamily: 'shabnam', borderRadius: 65, fontSize: '1.25em', textAlign: 'center' }}>
                جستجو
              </Button>
            </Grid>
            <Grid item md={11} sm={12}
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginRight: '3%', marginBottom: "3%" }}>
              <Button sx={{ width: { md: '100vw', xs: '50%' }, border: "1px solid white" }} onClick={this.resetFilters} disableRipple
                style={{ backgroundColor: 'transparent', color: 'white', fontFamily: 'shabnam', borderRadius: 65, fontSize: '1em', textAlign: 'center' }}>
                پاک کردن فیلترها
              </Button>
            </Grid>
            <Grid style={{ position: 'absolute', left: '3%', top: 75 }} sx={{ display: { md: 'none', xs: !this.state.displayButtonFilter ? 'flex' : 'none' } }}>
              <IconButton size="small" className="filter_button_close" onClick={this.onClickFilter}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ marginRight: { xs: "0", md: "270px" }, marginTop: { xs: "70px", md: "88px" }, width: '100%', display: 'flex', flexDirection: 'column' }} className="resultSearch">
          {this.state.noResultPage ?
            <>
              <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "1.2rem", md: "1.5rem" }, padding: "2.5% 6%", fontWeight: 'bold', textAlign: { xs: "center", md: "right" } }}>
                {" نتایج جستجو " + this.state.searchText}
              </Typography>
              <Grid style={{ display: 'flex', marginTop: '20%', width: '100%', height: '400px' }}>
                <Typography variant="h1" style={{ fontFamily: 'shabnam', fontSize: '30px', textAlign: 'center', fontWeight: 'bold', width: '100%' }}>
                  {"آگهی جهت نمایش وجود ندارد"}
                </Typography>
              </Grid>
            </>
            : <Grid></Grid>
          }
          {this.state.resultInfo.length > 0 ?
            <>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: { xs: "flex-start", md: "space-between" }, padding: "2.1% 6%", flexDirection: { xs: "column", md: "row" } }}>
                <Grid sx={{ width: "100", marginBottom: { xs: "10px", md: "0" } }}>
                  <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "1.2rem", md: "1.5rem" }, fontWeight: 'bold', display: "inline-block", marginLeft: "5px" }}>
                    {" نتایج جستجو "}
                  </Typography>
                  <Typography variant="h2" sx={{ fontFamily: "shabnam", fontSize: { xs: "1.2rem", md: "1.5rem" }, fontWeight: 'bold', display: "inline-block", marginLeft: "8px" }}>
                    {this.state.searchText}
                  </Typography>
                </Grid>
                <FormControl sx={{ minWidth: 120, border: "1px solid #eee", borderRadius: "4px" }}>
                  <InputLabel sx={{ fontFamily: "shabnam", fontWeight: "bold", bgcolor: "white" }}>{"مرتب سازی"}</InputLabel>
                  <Select
                    sx={{ fontFamily: "shabnam", fontWeight: "bold" }}
                    value={this.state.sortSelected}
                    onChange={this.onChangeSort}
                  >
                    <MenuItem value={1} sx={{ fontFamily: "shabnam", fontWeight: "bold" }}>جدیدترین ها</MenuItem>
                    <MenuItem value={2} sx={{ fontFamily: "shabnam", fontWeight: "bold" }}>گران ترین ها</MenuItem>
                    <MenuItem value={3} sx={{ fontFamily: "shabnam", fontWeight: "bold" }}>ارزان ترین ها</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container sx={{ width: '100%', padding: { lg: "0 30px", sm: "0 70px", xs: "0 5px" } }} columns={{ xs: 4, lg: 8, xl: 12 }} id="ads_Container">
                {this.createAds()}
                {this.state.resultInfo.length > this.state.adsCounter ?
                  <Grid item xs={4} lg={8} xl={12} sx={{ width: "100%", textAlign: "center", padding: "4% 0" }}>
                    <Button sx={{ width: "300px", bgcolor: "rgb(20, 201, 232)", fontFamily: "shabnam", fontWeight: "bold", color: "black", "&:hover": { bgcolor: "rgb(20, 201, 232)" } }}
                      onClick={this.onClickMoreAds}
                    >
                      {"موارد بیشتر"}
                    </Button>
                  </Grid>
                  : <Grid></Grid>
                }
              </Grid>
            </>
            : <Grid></Grid>
          }
          {this.state.skeletonPage ?
            <>
              <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "1.2rem", md: "1.5rem" }, padding: "2.7% 6%", fontWeight: 'bold', textAlign: { xs: "center", md: "right" } }}>
                {"نتایج جستجو " + this.state.searchText}
              </Typography>
              <Grid container sx={{ width: '100%', padding: { lg: "0 30px", sm: "0 70px", xs: "0 5px" } }} columns={{ xs: 4, lg: 8, xl: 12 }}>
                {this.createSkeleton()}
              </Grid>
            </>
            : <Grid></Grid>
          }
        </Grid>
        <Grid style={{ position: 'absolute', left: '3%', display: this.state.displayButtonFilter ? 'flex' : 'none', top: 75 }}>
          <IconButton size="large" className="filter_button" onClick={this.onClickFilter}>
            <TuneIcon fontSize="medium" />
          </IconButton>
        </Grid>
        <AlertModal open={this.state.openAlert} close={this.oncloseAlert} message={this.state.message} type={this.state.typeAlert} />
        <Grid sx={{ display: "none" }}>
          <Typography component={"p"} >
            منظور از سامانه معاملات املاک هومینجا پلتفرم درج و جستجوی آگهی ملک هومینجا و وب سایت املاک و مستغلات هومینجا می باشد. از جمله خدمات وب سایت تخصصی و پلتفرم املاک و مستغلات هومینجا می توان به درخواست ملک، عقد قرارداد ملکی، خرید و فروش انواع ملک، آپارتمان، خانه ویلایی، برج، مسکن، اداری و تجاری، پنت هاوس اشاره کرد. همچنین رهن و اجاره انواع ملک، آپارتمان، خانه ویلایی، برج، مسکن، اداری و تجاری، پنت هاوس از دیگر خدمات هومینجا می باشد. از دیگر بخش های هومینجا می توان هوم مگ  را نام برد. هوم مگ  به عنوان وبلاگ و صفحه آموزشی، ناشر بهترین و مفیدترین مقالات و مطالب آموزنده در حوزه املاک و مستغلات در ایران است. در هومینجا مگ سعی بر این شده است که مطالب آموزشی مفیدی در اختیار خوانندگان و کاربران قرار گیرد. از جمله دسته بندی مطالب هوم مگ می توان به معرفی مناطق تهران، محله گردی، مسائل حقوقی املاک و آپارتمان اشاره کرد. سبک زندگی، آموزش مشاورین املاک و معماری و دکوراسیون منزل از دیگر دسته بندی های هوم مگ می باشد.
          </Typography>
          <Typography component={"p"}  >
            اگر بدنبال خواندن مطلبی در رابطه با سبک زندگی هستید، پیشنهاد ما به شما مقاله ی نکاتی در مورد نگهداری گیاهان آپارتمانی می باشد. از دیگر مطالب مرتبط با این دسته بندی می توان انتخاب لوستر مناسب در دکوراسیون داخلی منزل و فنگ شویی دانست.
            در حوزه مسائل حقوقی املاک می توان مقالات نکات ضروری پیش خرید و پیش فروش آپارتمان، نهضت ملی مسکن و مسکن مهر و یا مقاله جذاب کد رهگیری املاک را نام برد.
          </Typography>
          <Typography component={"p"} >
            برای کسانیکه بدنبال ملک در یک منطقه خاص هستند ولی اطلاعاتی در رابطه با ان منطقه و محله ندارند مقالات محله گردی را پیشنهاد می دهیم. این مقالات با تاریخچه منطقه شروع می شود و در ادامه به بررسی تمامی امکانات منطقه و راه های دسترسی به آن محله می پردازد.
            چنانچه شما قصد دارید بعنوان مشاور املاک در این زمینه فعالیت کنید، هومینجا به ارائه مطالبی میپردازد که شما را در این زمینه راهنمایی میکند.
          </Typography>
          <Typography component={"p"} >
            همچنین هوم مگ برای کسانیکه بدنبال ایده های جذاب برای طراحی و دکوراسیون منزل می باشند نیز راهکارهایی را ارائه داده است.
            از معرفی انواع نما، کف تا طراحی منزل و هر نوع مطلب در مورد املاک توسط تیم محتوای هومینجا نگارش و منتشر شده است.
            معرفی بهترین و معتبرترین آژانس های املاک هم جزو خدمات رایگان هومینجا می باشد.
            از دیگر بخش های جذاب هوم مگ بخش کافه گردی مقالات می باشد. این مقالات به معرفی کافه ها و رستوران های جذاب در تهران پرداخته است.
          </Typography>
          <Typography component={"p"} >
            کاربر گرامی هومینجا، شما در حال مشاهده آخرین آگهی های انواع معاملات املاک و مستعلات موجود در صفحه مورد نظر هستید. شما در بهترین سایت جستجوی آگهی فروش ملک درتهران هستید.
          </Typography>
          <Typography component={"p"} >
            برای ثبت آگهی ملک خود ابتدا ثبت نام بفرمایید و سپس نوع کاربری خود را انتخاب کنید. بسته به نوع کاربری اعتبار هدیه دریافت کنید تا املاک خود را به صورت رایگان در بهترین وب سایت درج آگهی انواع ملک در تهران درج کنید. برای اطلاع از خدمات با ما درارتباط باشید.
          </Typography>
          <Typography component={"p"} >
            شما می توانید با استفاده از فیلترهای پیشرفته، جستجوی دقیق تری داشته باشید.
          </Typography>
          <Typography component={"p"} >
            بازدید میلیونی ملک شما در سامانه املاک هومینجا
          </Typography>
          <Typography component={"p"} >
            هومینجا برترین سامانه املاک در ایران
          </Typography>
          <Typography component={"p"} >
            برای سفارش طراحی گرافیکی سایت املاک، به صفحه ی تماس با ما وب گستران <a className="zone_text_link" href="https://sorinapp.com/ContactSorin" target="_blank">سورین </a> مراجعه نمایید.
          </Typography>
        </Grid>
      </Container>
    )
  };
}

export default Listings;