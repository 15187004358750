import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article05/01.jpg";
import photo02 from "../../images/mag/article05/02.jpg";
import photo03 from "../../images/mag/article05/03.jpg";
import photo04 from "../../images/mag/article05/04.jpg";
import photo05 from "../../images/mag/article05/05.jpg";
import photo06 from "../../images/mag/article05/06.jpg";
import photo07 from "../../images/mag/article05/07.jpg";
import photo08 from "../../images/mag/article05/08.jpg";
import photo09 from "../../images/mag/article05/09.jpg";
import photo10 from "../../images/mag/article05/10.jpg";
import photo11 from "../../images/mag/article05/11.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article05 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "امروزه کمتر کسی برای چای و قلیان سفره خانه های قدیمی را انتخاب می کند . افراد ترجیح می دهند بیشتر به کافه های قلیان دار بروند";
    document.getElementsByTagName('meta')["keywords"].content = " قلیان گردی | کافه قلیان در تهران | سرو قلیان | بهترین کافه قلیان های تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[4].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[4].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[4].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[4].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[4].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[4].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کافه های قلیان دار در تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[4].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه گردی یکی از تفریحات اکثر افراد شده است. خیلی از جوانان برای دورهمی های خود کافه و رستوران ها را انتخاب می کنند. با توجه به اینکه
                امروزه کمتر کسی برای صرف چای و قلیان، قهوه خانه و سفره خانه های قدیمی را انتخاب می کند بنابراین چه جوانان و چه افراد میانسال ترجیح می دهند کافه
                هایی را مدنظر قرار دهند که قلیان سرو می کنند. ما در این مقاله از هومینجا به معرفی 10 مورد کافه های قلیان دار  می پردازیم. پس با ما همراه باشید.
                <LinkMui className="link" href="https://papionaccessory1.com/product-category/pod/" target="_blank">خرید دستگاه پاد.</LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                1- کافه گروک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کافه گروک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی ازکافه گروک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از کافه های شیک غرب تهران می باشد. این کافه در منطقه شهران واقع شده است. طرح درخت‌ بهاری و رنگ هایی که در این کافه استفاده شده
                جذابیت این کافه را دو چندان کرده است. این کافه از جمله کافه هایی است که قلیان سرو می کند. از امکانات جالب این کافه می توان به بازی های گروهی
                مانند مافیا که طرفداران زیادی هم دارد، اشاره کرد. منوی این کافه شامل انواع نوشیدنی‌های گرم و سرد و همچنین غذاهایی مانند پنینی و پاستا می باشد.
              </Typography>
              <ul>
                <li>
                  آدرس: شهران جنوبی، بلوار کوهسار‌، خیابان فرهنگ، مجتمع کوهسار، بلوک بی
                </li>
                <li>
                  ساعات کاری: از ۹:۳۰ تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/groke.cafe/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                2- کافه سالمبا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کافه سالمبا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه سالمبا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه نیز یکی دیگر از کافه قلیان های تهران می باشد. در این
                کافه پرتره هایی از بازیگران معروف نصب شده که باعث خاص بودن این کافه شده است. همچنین در کافه سالمبا انواع غذاهای فرنگی و ایرانی نیز سرو می شود.
              </Typography>
              <ul>
                <li>
                  آدرس: بلوار فردوس غرب، بین خیابان ورزی و پروانه جنوبی، پلاک ۴۴۶
                </li>
                <li>
                  ساعات کاری: از 12 تا 24
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/salemba_restaurant/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                3- کافه ربلان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کافه ربلان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه ربلان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ربلان یکی از لوکس‌ترین کافه های تهران می باشد که قلیان سرو
                می کند. ورودی  کافه ربلان به شکل کریستال‌های آبی تزیین شده است. سقف کافه از انواع چوب‌های نفیس ساخته شده است. ربلان ظرفیت حدودی ۱۰۰ نفر مهمان را
                دارا می باشد.  این کافه در آخرین طبقه مجتمع تجاری تندیس واقع شده است . با اینکه این کافه نسبتاً گران‌قیمت است اما با توجه به منطقه و کیفیت بالای
                آن، مورد پذیرش مشتریان و طرفداران زیادی قرار گرفته است. سرعت سرویس‌دهی این کافه بسیار  عالی می باشد. منوی غذا و کافی‌شاپ آن بسیار کامل است . طرح
                قلیان‌های کافه ربلان، بسیار زیبا و خاص می باشد. از جذابیت‌های این کافه می توان به اتاق آینه ربلان اشاره کرد که برای عکاسی مورد استفاده قرار می گیرد.
              </Typography>
              <ul>
                <li>
                  آدرس: تجریش، ابتدای خیابان فناخسرو، مرکز خرید تندیس، طبقه ۷
                </li>
                <li>
                  ساعات کاری: از ۹ تا ۲۳:۳۰
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/rebelangroup/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                4- کافه سی‌تی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کافه سی تی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه سی تی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه سی‌تی  از کافه‌های ساده در شرق تهران می باشد. این کافه دارای  محیطی خانوادگی است که  قلیان هم سرو می کند.
                انواع نوشیدنی‌ها و غذاهایی مثل پاستا، سالاد  در منوی کافه سی تی موجود است. در این کافه نیز، بازی‌های مختلفی مانند مافیا با رزرو قبلی انجام می‌شود.
              </Typography>
              <ul>
                <li>
                  آدرس: نارمک، خیابان گلبرگ شرقی، بین دردشتو اتوبان باقری، پلاک ۲۴۲
                </li>
                <li>
                  ساعات کاری: از ۹ تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/_cafe_city_/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                5- کافه خوان‌باشی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کافه خوان باشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه خوان باشی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر کافه های خوب تهران می توان به  خوان‌باشی اشاره کرد. کافه
                خوان‌باشی که در روف‌گاردن قرار گرفته منوی کاملی از انواع قلیان را شامل می شود. روف‌گاردن این کافه مجهز به سیستم گرمایشی است و در نتیجه در فصل‌های
                سرد هم قابل استفاده است. پیشنهاد ما این است که از نوشیدنی‌های خوش‌طعم این کافه بخصوص  بلک‌بری، بلواسکای، تروپیکا و بلوهاوایی نهایت لذت را ببرید.
              </Typography>
              <ul>
                <li>
                  آدرس: نیاوران، خیابان جماران، کوچه لاله، پلاک ۶
                </li>
                <li>
                  ساعات کاری: از ۱۱ تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/khanbashi_restaurant/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                6- کافه مدائن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="کافه مدائن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه مدائن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قلیان‌های عربی
                باکیفیت و غذاهای مراکشی، لبنانی و تونسی که در این کافه ارائه می شود بسیار مشهور هستند. تخفیفی که این کافه بر روی سرویس قلیان در ساعات ۳ تا
                ۶ بعد از ظهر گذاشته است را می توان از امتیازات آن دانست. دکوراسیون جالب و لوستر‌های پر زرق و برق و صندلی‌های حصیری از دیگر جذابیت های این کافه
                می باشد. سرویس‌دهی این کافه بینظیر  است. به شما پیشنهاد می‌کنیم در کنار سرویس قلیان، چای مراکشی نیز سفارش بدهید تا یک تجربه دلچسب داشته باشید.
              </Typography>
              <ul>
                <li>
                  آدرس: ظفرشرقی، خیابان فریدافشار، بلوار آرش شرقی، پلاک ۴۴
                </li>
                <li>
                  ساعات کاری: از ۱۲ تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/madaenhookah/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                7- کافه آهون
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="کافه آهون - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه آهون
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آهون را می توان یکی از بهترین‌ کافه قلیان های تهران معرفی کرد. این کافه بسیار به متروی ولیعصر
                نیز نزدیک می باشد. پرسنل کافه آهون بسیار حرفه ای خدمات دهی می کنند. منوی کافه آهون شامل انواع غذاهای اصیل ایرانی و نوشیدنی‌های گرم
                ، دمنوش‌ها، اسموتی، شیک و شربت‌های سنتی می باشد. قلیان های متنوعی در منوی آهون وجود دارد که پیشنهاد ما به شما قلیان شیرنارگیل می باشد.
              </Typography>
              <ul>
                <li>
                  آدرس: بلوار کشاورز، فلسطین جنوبی، نبش کوچه ذاکری
                </li>
                <li>
                  ساعات کاری: از ۱۰ تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/ahoon.restaurant/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                8- چیزفکتوری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="کافه چیز فکتوری - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه چیز فکتوری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه در بالاترین طبقه از مجتمع آواسنتر واقع شده است. ویوی بسیار جذاب از پایتخت و بخش اسموکینگ کافه که دارای سقف
                متحرک می باشد از جذابیت های این کافه به شمار می‌رود. می توان از این کافه بعنوان یکی از بهترین کافه هایی که قلیان سرو می کند نام برد.
                در این کافه شما می توانید از وای فای رایگان نیز بهره ببرید. قیمت منوی این کافه کمی گران است اما از لحاظ کیفیتی فوق العاده می باشد.
              </Typography>
              <ul>
                <li>
                  آدرس: اقدسیه، نبش فیروز بخش، مرکز خرید اوا سنتر
                </li>
                <li>
                  ساعات کاری: از  ۱۲تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/thecheesefactory.ir/" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                9- کافه دریاباز
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="کافه دریاباز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه دریاباز
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه یکی از خوش منظره‌ترین کافه های تهران می باشد. دریاباز در ولنجک واقع شده است. از مزایای ویزه کافه می توان به این موضوع اشاره کرد
                که مشتریان می توانند برای صرف قلیان و غذا یکی از چهار طبقه‌ی این کافه را بسته به سلیقه خود انتخاب کنند. منوی  این کافه شامل غذاهای
                ایرانی و بین المللی می باشد.. همچنین بین ساعت‌های ۱۲ ظهر تا ۶ بعداز ظهر، مشتریان می‌توانند از سرویس قلیان با نصف قیمت بهره مند شوند.
              </Typography>
              <ul>
                <li>
                  آدرس: ولنجک، بلوار دانشجو، خیابان گلریزان، بالاتر از میدان گلریزان
                </li>
                <li>
                  ساعات کاری: از  ۱۲تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/darya_baz_restaurant/" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                10- کافه لاهوت
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="کافه لاهوت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه لاهوت
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه لاهوت یک کافه فوق‌العاده لوکس واقع در اکباتان می باشد. طراحی دکوراسیون این
                کافه به سبک مراکشی می باشد.  در منوی این کافه علاوه بر غذاهای ایرانی، دسرهای خوش‌طعم مراکشی مانند باقلوا و کنافه‌بستنی نیز سرو می‌شود
                . تنه قلیان‌های این کافه مصریم می باشد . از ویژگی های این کافه می توان به تنباکوی دست‌سازی که در قلیان ها استفاده می‌شود، اشاره کرد.
              </Typography>
              <ul>
                <li>
                  آدرس: شهرک اکباتان، خیابان فلسفی،  بیمه چهارم، پلاک ۸۴
                </li>
                <li>
                  ساعات کاری: از ۱۱ تا ۲۴
                </li>
                <li>
                  نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/lahoot_cafe/?hl=en" target="_blank">اینستاگرام</LinkMui>
                </li>
              </ul>
              <Typography className="h2" variant="h2">جمع بندی :</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا درتلاش است تا همواره
                اطلاعات مناسب در اختیار کاربران قراردهد. با دنبال کردن وب سایت و هم چنین بلاگ هومینجا از اخرین اخبار و مطالب جذاب ما مطلع شوید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Tehran-cafe-with-hooka" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Tehran-cafe-with-hooka" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Tehran-cafe-with-hooka" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article05;