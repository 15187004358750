import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article11/01.jpg";
import photo02 from "../../images/mag/article11/02.jpg";
import photo03 from "../../images/mag/article11/03.jpg";
import photo04 from "../../images/mag/article11/04.jpg";
import photo05 from "../../images/mag/article11/05.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SideBar from "./SideBar";



class Article11 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "نگه داری گیاهان آپارتمانی با هر هدفی نیازمند رعایت یکسری اصول می باشد. هومینجا به شما کمک می کند فضای سبزی بی نظیر در خانه داشته باشید.";
    document.getElementsByTagName('meta')["keywords"].content = "شرایط نگه داری گیاهان آپارتمانی | راهنمای کامل مراقبت از گیاهان آپارتمانی | راز نگه داری انواع  گیاهان آپارتمانی | همه چیز در مورد نگهداری گیاهان در آپارتمان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[10].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[10].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[10].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[10].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[10].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[10].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="نکاتی که در مورد نگهداری گیاهان آپارتمانی باید بدانیم - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[10].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گیاهان آپارتمانی طراوت و شادابی را در محیط منزل به ارمغان می آورند. برخی از این گیاهان علاوه بر زیبایی تصفیه کننده هوا نیز می باشند. نگه داری گیاهان با هر هدفی که باشد نیازمند رعایت یکسری اصول می باشد. بنابراین با <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link>  همراه باشید تا با یادگیری این نکات فضای سبزی عالی را در محیط خانه خود تجربه کنید،هم چنین به زیبایی <Link className="link" to="/small-house-decoration" target="_blank"> دکوراسیون منزل </Link>  خود بیفزایید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین خواندن مقاله فوت وفن های <Link className="link" to="/Spring-Cleaning" target="_blank"> خانه تکانی عید </Link>  خالی از لطف نیست.
              </Typography>
              <Typography className="h2" variant="h2">
                فواید نگه داری گیاهان در منزل:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت از مقاله به ذکر بخشی از فواید نگهداری گیاه در آپارتمان مسکونی اشاره خواهیم کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                تنفس لذت بخش:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="گیاهان آپارتمانی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نگهداری گیاهان آپارتمانی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در فتوسنتز گیاهان دی اکسید کربن جذب و اکسیژن آزاد می شود. وقتی انسان نفس می کشد اکسیژن را گرفته و دی اکسید کربن را به محیط باز
                می گرداند. وجود گیاهان در آپارتمان باعث آزاد سازی بیشتر اکسیژن می شود. بنابراین  نگه داری گیاهان در منزل عمل تنفس را برای انسان بهبود می بخشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/chandelier-in-decoration" target="_blank">
                  بیشتر بخوانید : انتخاب لوستر مناسب در دکوراسیون منزل
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مرطوب کردن هوا:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گیاهان 97 درصد آبی را که جذب می کنند مجدد در هوا آزاد می کنند. بنابراین با نگه داری چند گیاه آپارتمانی مناسب رطوبت در خانه افزایش می یابد.
              </Typography>
              <Typography className="h3" variant="h3">
                تصفیه هوا:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گیاهان در شبانه روز حدود 87 درصد سموم موجود در فضا را از بین می برند. در محیط هایی که اشیا چاپی مانند کتاب و روزنامه وجود دارد گیاه نگه داری کنید تا سموم هوا از بین برود. این عمل در <Link className="link" to="/smart-home" target="_blank"> خانه های هوشمند </Link>  به صورت اتوماتیک انجام می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                تقویت سلامت جسم :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قراردادن گل و گیاه در اتاق های بیمارستان بسیار در روند بهبود بیماران تاثیر گذار است.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط نگه داری گیاهان آپارتمانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شرایط نگه داری گیاهان با هم متفاوت است. هر گیاه برای رشد به شرایط خاصی نیازمند است. ما در اینجا به بررسی این شرایط می پردازیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود گل و گیاه در اتاق <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> خواب </Link>  بخ خصوص <Link className="link" to="/children-room-decoration" target="_blank"> اتاق خواب کودک </Link>  میتواند تاثیرات بسزایی در اخلاقیات و رشد او داشته باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                نور:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="شرایط گیاهان اپارتمانی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شرایط نگه داری گیاهان آپارتمانی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا نور مورد نیاز گیاهان باید نور غیر مستقیم باشد. بهتر است
                دیوارهای خانه روشن باشد. روشنایی <Link className="link" to="/accent-wall" target="_blank"> دیوارها </Link>  باعث می شود نور خورشید به خوبی منعکس شود. در محیط هایی که نورطبیعی به اندازه کافی موجود نیست بهتر
                است از چند لامپ مهتابی سفید استفاده شود. نور کم باعث رنگ پریدگی برگ ها و کوچک ماندن برگ گیاهان می شود. همچنین نور زیاد باعث پژمردگی برگ گیاهان
                می شود. تعدادی از گیاهان هستند که در نور کم رشد خوبی دارند. از جمله این گیاهان می توان به سانسوریا ، برگ عبایی ، پتوس و برگ بامبو اشاره کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/fengshui" target="_blank">
                  بیشتر بخوانید : فنگ شویی چیست ؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                آبیاری گیاهان آپارتمانی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اکثر گیاهان در اثر آبیاری افراطی از بین می روند. آبیاری در گیاهان باید به میزانی باشد که آب مازاد از گلدان خارج شود. همچنین
                اضافه در زیر گلدان را خالی کنید زیرا باعث خیس بودن دائمی خاک گیاه می شود. خیس بودن دائمی باعث عدم تنفس و در نهایت از بین رفتن گیاه می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                زمان آبیاری به گیاهان:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین زمان آبیاری گیاهان در ساعت گرم روز می باشد. زمانی به گیاه آب دهید که خاک گلدان رو به خشکی است ولی هنوز کاملا خشک نشده است.
              </Typography>
              <Typography className="h3" variant="h3">
                عوامل موثر بر آبیاری گیاهان آپارتمانی:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="آبیاری گیاهان آپارتمانی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  آبیاری گیاهان آپارتمانی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هر چه درجه حرارت و شدت نور
                در آپارتمان بیشتر باشد نیاز گیاه به آب نیز افزایش می یابد. گیاهانی که در گلدان های کوچک هستند نسبت به گیاهانی که گلدان های آن ها بزرگتر است
                به آب بیشتری نیاز دارند. همچنین گیاهانی که خاک آن ها دیر عوض می شود نسبت به گیاهانی که بتازگی تعویض خاک داشته اند نیازمند آبیاری بیشتری هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                میزان رطوبت محیط:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کمبود رطوبت محیط موجب سوختگی برگ گیاهان می شود. همچنین کمبود رطوبت باعث ایجاد لکه های قهوه ای رنگ در گیاه می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                راه های افزایش رطوبت گیاهان آپارتمانی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین راه افزایش رطوبت هوا انتخاب یک زیر گلدانی مناسب می باشد. به این صورت که زیرگلدانی را کمی بزرگتر از قطر دهانه گلدان انتخاب کنید.
                داخل آن شن ریخته و روی شن ها آب بریزید. سپس گلدان را روی شن ها قرار دهید. تبخیر آب درون شن ها باعث ایجاد رطوبت مورد نیاز گیاه می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                کود:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کود مناسب یکی دیگر از نکاتی است که در نگه داری گیاهان آپارتمانی باید مورد توجه قرار داد. چهار ماه بعد از خرید گیاه، دادن کود ضروری می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                تهویه مناسب:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمانی که دمای هوا در بیرون از منزل بیش از 18 سانتی گراد باشد برای تعویض
                هوای اتاق بهتر است که پنجره ها باز گذاشته شود. باز کردن پنجره باعث خروج گازهای آلوده از اتاق می شود. همچنین اکسیژن باعث تقویت ساقه گیاه می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/laundry-room" target="_blank">
                  بیشتر بخوانید : لاندری روم چیست ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نکاتی که در نگه داری از گیاهان آپارتمانی باید مدنظر قرار داد
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="نگهداری گیاهان آپارتمانی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نکات مربوط به گیاهان اپارتمانی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای آبیاری به گیاه نباید از آب سرد استفاده کرد.
                <br />
                بهتر است هفته ای یکبار برگ گیاهان با دستمال نمدار پاک شود.
                <br />
                مقداری سرکه طبیعی در آب گیاهان ریخته شود.
                <br />
                به منظور دوام بیشتر گل ها مقداری قرص آسپرین در آب گلدان حل کنید.
                <br />
                در محیط های گرم چند تکه یخ در گلدان بیندازید تا بدین ترتیب شادابی گیاهان حفظ شود.
                <br />
                ما در این مقاله از هومینجا سعی کردیم تا یک راهنمای جامع برای <Link className="link" to="/keeping-apartment-flower" target="_blank"> نگه داری از گیاهان آپارتمانی </Link>  در اختیار شما قرار دهیم.
              </Typography>
              <Typography className="h2" variant="h2">
                سوالات متداول:
              </Typography>
              <Typography className="h3" variant="h3">
                1- کدام گیاه برای نگه داری به نور کمتری نیاز دارد؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برخی از گیاهان برای رشد به نور کمتری نیاز دارند و در سایه مقاوم تر
                هستند. از جمله این گیاهان می توان به سانسوریا، آگلونما، برگ عبایی، دیفن باخیا، برگ قاشقی و سانسوریا اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                2-کدام گیاهان آپارتمانی  برای کودکان و حیوانات خطرناک هستند؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با وجود تمام  مزایایی که نگهداری گیاهان آپارتمانی دارند ، تعدادی گیاه آپارتمانی سمی و خطرناک هم وجود دارد که می‌تواند مشکلاتی برای
                سلامتی ایجاد کنند. پتوس، زنبق، سوسن صلح، دیفن بافیا،خرزهره گیاهانی هستند که برای سلامتی انسان و حیوانات مضر هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                3- برای تصفیه هوا از چه گیاهانی باید استفاده کرد؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعضی از گیاهان آپارتمانی، نقش مهمی در مقابله با
                افزایش آلودگی های هوای داخل و تصفیه محیط <Link className="link" to="/Listings/buy_tehran" target="_blank"> آپارتمان </Link>  دارند. نخل اریکا، فیکوس، بامبو و سرخس گیاهانی هستند که برای تصفیه هوا بسیار موثرند.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("خانه و سبک زندگی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  خانه و سبک زندگی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/keeping-apartment-flower" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/keeping-apartment-flower" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/keeping-apartment-flower" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article11;