import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article10/01.jpg";
import photo02 from "../../images/mag/article10/02.jpg";
import photo03 from "../../images/mag/article10/03.jpg";
import photo04 from "../../images/mag/article10/04.jpg";
import photo05 from "../../images/mag/article10/05.jpg";
import photo06 from "../../images/mag/article10/06.jpg";
import photo07 from "../../images/mag/article10/07.jpg";
import photo08 from "../../images/mag/article10/08.jpg";
import photo09 from "../../images/mag/article10/09.jpg";
import photo10 from "../../images/mag/article10/10.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article10 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "مکان های دیدنی تهران از جمله جاذبه های طبیعی و تاریخی، هر ساله گردشگران زیادی را به سمت خود جذب می کنند.";
    document.getElementsByTagName('meta')["keywords"].content = "مراکز تفریحی تهران | جاهای دیدنی تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[9].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[9].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[9].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[9].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[9].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[9].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="مکان های دیدنی تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[0].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تهران بعنوان
                پایتخت ایران و پرجمعیت ترین شهر کشور دارای جاذبه های گردشگری بسیاری می باشد. مکان های دیدنی تهران از جمله جاذبه های طبیعی و تاریخی، هر
                ساله گردشگران زیادی را به سمت خود جذب می کند. به همین دلیل بعنوان یکی از مهم ترین مراکز گردشگری و بهترین مقاصد تفریحی ایران به شمار می آید. ما در این مقاله از <Link className="link" to="/" target="_blank"> هومینجا </Link> قصد داریم تا تعدادی از این مکان های دیدنی را به شما معرفی کنیم.
              </Typography>
              <Typography className="h2" variant="h2">
                ایران مال:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="ایران مال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مرکز خرید و تفریحی ایران مال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از جدیدترین مراکز تفریحی تهران ایران مال یا بازار بزرگ ایران می باشد. <LinkMui className="link" href="https://www.iranmall.com/" target="_blank">  ایران مال </LinkMui> واقع در <Link className="link" to="/tehran-zone-22" target="_blank"> منطقه 22 </Link> تهران و نزدیک به <Link className="link" to="/Listings/buy_tehran_daryachechitgar" target="_blank"> دریاچه </Link> چیتگر جز پنج مال برتر جهان می باشد. معماری خاص و امکانات فراوان این مجموعه، گردشگران زیادی را به سمت خود کشانده است. از امکانات این مجموعه می توان به مراکز خرید، شهربازی، پردیس سینمایی و مجموعه ورزشی و حتی هتل اشاره کرد. همچنین دارای بخش های دیدنی جذابی است که مسجد جامع، باغ دیدار، باغ ماهان، بازار سنتی، تالار آیینه، شربت خانه، باغ کتاب ایران یا کتابخانه جندی شاپور ایران را می توان نام برد. همچنین این مجموعه دارای مراکز تجاری متعددی نیز می باشد. هایپر استار، فروشگاه محصولات چرمی و جواهر آلات و محصولات زیبایی و ده ها فروشگاه دیگر از بخش های تجاری ایران مال می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بام تهران:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="بام تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بام تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر در شب قصد تهرانگردی را دارید پیشنهاد
                ما به شما بام تهران است. بام تهران حدفاصل انتهای خیابان <Link className="link" to="/Listings/buy_tehran_velenjak" target="_blank"> ولنجک </Link> تا ایستگاه <Link className="link" to="/tehran-winter-entertaiments" target="_blank"> تله کابین توچال </Link> می باشد. بام تهران چشم انداز زیبایی از شهر تهران
                دارد. این مکان جذاب دارای امکانات متعددی از جمله رستوران های متنوع، زیپ لاین، سورتمه، سینما پنج بعدی و میدی باس های برقی می باشد. همچنین اگر
                بدنبال بازی های هیجان انگیز مانند بگ جامپینگ یا بانجی جامپینگ و ترامپولین جامپینگ هستید بام تهران این تفریحات را در اختیار شما قرار می دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                روستای وردیج:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="روستای وردیج - هومینجا" className="image" />
                <figcaption className="image_caption">
                  زیبایی روستای وردیج کن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه تمایل دارید روز خود
                را با طبیعت گردی آغاز کنید روستای وردیج بهترین انتخاب برای شماست. این روستا از نظر جغرافیایی نزدیک به <Link className="link" to="/tehran-zone-21" target="_blank"> منطقه غرب </Link> تهران می باشد و زیر مجموعه کن و سولقان
                به حساب می آید. وردیج بافت کوهستانی دارد و از مناطق ییلاقی شهر تهران به شمار می آید. از جاذبه های گردشگری این روستا می توان به گردنه ارواح سنگی و چشمه
                ها و باغات این مکان طبیعی اشاره کرد. هر زمان که قصد سفر به روستای وردیج را داشتید حتما از روستای واریش که در امتداد این روستا واقع شده نیز بازدید کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-winter-entertaiments" target="_blank">
                  بیشتر بخوانید : تفریحات زمستانی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                پل طبیعت:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="پل طبیعت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پل طبیعت تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر جاذبه های شهر تهران می توان به <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%BE%D9%84_%D8%B7%D8%A8%DB%8C%D8%B9%D8%AA" target="_blank" rel="noreferrer" >پل طبیعت </LinkMui>
                اشاره کرد. این پل در محدوده <Link className="link" to="/Listings/buy_tehran_abasabad" target="_blank"> عباس آباد </Link> بزرگترین
                پل غیر خودرویی خاورمیانه می باشد. معماری خاص این پل به گونه ای است که از عرض بزرگراه مدرس گذشته و
                پارک طالقانی را در شرق به پارک آب و آتش در غرب وصل می کند. این پل علاوه بر زیبایی خاصی که به شهر تهران داده است  بعنوان نماد تهران نیز به حساب می آید.
              </Typography>
              <Typography className="h2" variant="h2">
                کاخ سعدآباد:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کاخ سعدآباد - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سعدآباد نیاوران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاخ سعدآباد <Link className="link" to="/niavaran-tehran" target="_blank"> نیاوران </Link> به عنوان برجسته ترین مکان های تاریخی و فرهنگی ایران می باشد. این مجموعه محل زندگی آخرین پادشاهان ایرانی در زمان قاجار و پهلوی بوده است. باغ های بسیار زیبایی این کاخ را  احاطه کرده اند. سعدآباد از نظر جغرافیایی در محدوده شمیرانات تهران و روی کوهپایه توچال واقع شده است. این مجموعه دارای سازه های بسیار دیدنی و جذابی می باشد. کاخ سفید، کاخ سبز یا کاخ مرمر، کاخ شمس، کاخ اشرف پهلوی، کاخ احمد شاهی، کاخ ملت، مجسمه آرش کمانگیر و کاخ سیاه از بناهای دیدنی این موزه هنری می باشد. هم چنین مطالعه مقاله <Link className="link" to="/north-of-Tehran-cafe" target="_blank"> کافه های شمال تهران </Link> خالی از لطف نیست..
              </Typography>
              <Typography className="h2" variant="h2">
                عمارت مسعودیه:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="عمارت مسعودیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  عمارت مسعودیه خیابان جمهوری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر مکان های دیدنی تهران می توان به <LinkMui href="https://fa.wikipedia.org/wiki/%D8%B9%D9%85%D8%A7%D8%B1%D8%AA_%D9%85%D8%B3%D8%B9%D9%88%D8%AF%DB%8C%D9%87_(%D8%AA%D9%87%D8%B1%D8%A7%D9%86)" className="link" target="_blank" rel="noreferrer" >عمارت مسعودیه </LinkMui>
                اشاره کرد. موقعیت این ملک در خیابان جمهوری نرسیده به <Link className="link" to="/Listings/rent_tehran_baharestan" target="_blank"> بهارستان </Link> قرار دارد. این بنای تاریخی در زمان قاجار به دستور مسعود میرزا حاکم اصفهان بنا شد.
                دو طرف این عمارت با سنگ فرش پوشیده شده است. این عمارت شامل پنج عمارت مجزا می باشد. هر کدام از این عمارت ها با گچ بری و کاشی کاری و خطاطی های خاصی مزین شده اند.
              </Typography>
              <Typography className="h2" variant="h2">
                دریاچه فشافویه:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="دریاچه فشافویه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دریاچه فشافویه شهر تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بخش های تفریحی استان تهران، <LinkMui href="https://www.instagram.com/daryache_fashafooye/" className="link" target="_blank" rel="noreferrer" >دریاچه ی فشافویه</LinkMui> می باشد.
                این دریاچه در کیلومتر ۱۸ اتوبان تهران – قم واقع شده است. فشافویه تمام امکانات تفریحی سواحل کیش، قشم، ترکیه، امارات را دارا
                می باشد. در مجموعه تفریحی ورزشی فشافویه می توانید از تفریحاتی مانند اسکی روی آب، جت اسکی، قایق سواری، کایاک سواری، تا ATV (موتورهای چهار چرخ)، پیست اتوموبیلرانی، پینت بال، سوارکاری بهره ببرید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : معرفی بهترین کافه کتاب های تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                خانه موزه مقدم:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="خانه موزه مقدم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خانه موزه مقدم واقع در میدان امام خمینی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                موزه مقدم از بناهای تاریخی دوران قاجار می باشد.این خانه محل سکونت خاندان محمدتقی خان از صاحب‌منصبان
                دربار قاجار بوده است. پس از آن در اختیار دکتر محسن مقدم از
                بنیان گذاران دانشگاه تهران و همسر فرانسوی‌اش سلما، قرار گرفت.
                خانه <LinkMui href="https://hotelyar.com/mag/travel-to-iran/%D8%A8%D8%A7%D8%BA_%D9%85%D9%88%D8%B2%D9%87_%D9%85%D9%82%D8%AF%D9%85_%D8%AE%D8%A7%D9%86%D9%87_%D8%AA%D9%87%D8%B1%D8%A7%D9%86" className="link" target="_blank" rel="noreferrer" >موزه مقدم</LinkMui> در
                محدوده میدان امام خمینی واقع شده است . خانه موزه مقدم تلفیقی از معماری ایرانی و المان‌های اروپایی می باشد. علاوه بر قدمت و معماری
                <Link className="link" to="/building-facades-types" target="_blank"> بنا </Link>، وجود آثار و کلکسیون های ارزشمند همچون سنگ تراشیده های نایاب، مهر ها، سفالینه و اسناد تاریخی معتبر  عنوان گران ترین خانه ایرانی را به
                این مجموعه داده است. طراحی و ساخت این خانه بر پایه اصول معماری ایرانی مانند حوض ایرانی‌، شیشه رنگی ایرانی و اصول فضاسازی باغ ایرانی می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                باغ فردوس:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="باغ فردوس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  باغ فردوس تجریش
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از باغ‌های زیبای دوره قاجاریه، <LinkMui href="https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%BA_%D9%81%D8%B1%D8%AF%D9%88%D8%B3" className="link" target="_blank" rel="noreferrer" >باغ فردوس </LinkMui>
                می باشد. این باغ با نام های باغ موزه فردوس، باغ معیری و موزه سینما نیز شناخته می‌شود. باغ فردوس در حوالی میدان <Link className="link" to="/Listings/buy_tehran_tajrish" target="_blank"> تجریش </Link> قرار دارد.
                این مکان دیدنی در حال حاضر به موزه سینما اختصاص داده شده است. فضای باغ فردوس ۲۰ هزار متر مربع وسعت دارد و مساحت عمارت آن هزار متر مربع می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">
                  بیشتر بخوانید : کافه های قلیان دار در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این  <Link className="link" to="/Tehran-spectaculars" target="_blank"> مطلب </Link> از هومینجا سعی کردیم مکان های دیدنی تهران را به اختصار معرفی کنیم.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Tehran-spectaculars" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Tehran-spectaculars" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Tehran-spectaculars" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}


export default Article10;