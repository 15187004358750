import { Autocomplete, Button, Checkbox, FormControl, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import React from "react";
import axios from "axios";
import { baseUrl } from "./materilas/vmp";
import AlertModal from "./materilas/alert";

class Doucument extends React.Component{

  isMount = false;

  constructor(props){
    super(props);
    this.state={
      doc:[],
      nameInput:'',
      idChoose:null,
      active:false,
      openAlert:false,
      message:'',
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true;
    if (this.isMount){
      this.getDoc();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  getDoc = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/document/get',
      method: 'GET',
    }))
    .then((res) => {
        this.setState({doc:res.data.data})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  editDoc = () =>{
    if (this.state.idChoose !== null){
      if (this.state.nameInput !== ''){
        let token = localStorage.getItem('tokenUserHomeInja');
        Promise.resolve(axios({
          url: baseUrl+'v1/document/edit',
          method: 'POST',
          headers:{'Authorization':'bearer ' + token},
          data:{
            id:this.state.idChoose,
            des:this.state.nameInput,
            active:this.state.active
          }
        }))
        .then((res) => {
            this.setState({nameInput:'',active:false,idChoose:null,openAlert:true,message:'ویرایش با موفقیت انجام شد',typeAlert:1})
            this.getDoc();
        })
        .catch((err) => {
            this.setState({nameInput:'',active:false,idChoose:null,openAlert:true,message:err.response.data.message,typeAlert:2}) 
        });
      }
      else{
        this.setState({openAlert:true,message:'فیلد نام نباید خالی باشد',typeAlert:3})
      }
    }
    else{
      this.setState({openAlert:true,message:'موردی جهت ویرایش انتخاب نشده',typeAlert:3})
    }
  }

  saveDoc = () =>{
    if (this.state.nameInput !== ''){
      let token = localStorage.getItem('tokenUserHomeInja');
      Promise.resolve(axios({
        url: baseUrl+'v1/document/create',
        method: 'POST',
        headers:{'Authorization':'bearer ' + token},
        data:{
          des:this.state.nameInput,
        }
      }))
      .then((res) => {
          this.setState({nameInput:'',active:false,idChoose:null,openAlert:true,message:'افزودن با موفقیت انجام شد',typeAlert:1})
          this.getDoc();
      })
      .catch((err) => {
          this.setState({nameInput:'',active:false,idChoose:null,openAlert:true})
          if (err.response.status === 500){
            this.setState({openAlert:true,message:err.response.data.message,typeAlert:2})
          }
          else{
            this.setState({openAlert:true,message:'نوع سند تکراری است',typeAlert:2})
          }
      });
    }
    else{
      this.setState({openAlert:true,message:'فیلد نام نباید خالی باشد',typeAlert:3})
    }
  }

  onChangeDoc = (event,newValue) =>{
    this.setState({nameInput:'',idChoose:null,active:false})
    if (newValue !== null){
      this.setState({nameInput:newValue.des,idChoose:newValue.id,active:newValue.active === 1 ? true : false})
    }
  }

  onChangeName = (event) =>{
    this.setState({nameInput:event.target.value})
  }

  onChangeActive = () =>{
    this.setState({active:!this.state.active})
  }

  onCloseAlert = () =>{
    this.setState({openAlert:false})
  }

  render() {

    return (
      <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',sm:'10px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',marginTop:100,marginBottom:100}}>
          <Typography variant="h6" style={{fontFamily:'shabnam',fontWeight:'bold'}}>ویرایش و افزودن انواع اسناد ملکی </Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <Grid item xs={12} className="employeeAuto">
            <Autocomplete
              id="state_combo"
              className="combo_employee"
              options={this.state.doc}
              getOptionLabel={(option) => option.des}
              renderInput={(params) => <TextField className="textField" {...params} placeholder='انواع اسناد ملکی'/>}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={this.onChangeDoc}
              clearText='حذف'
              closeText="بستن"
              openText="باز کردن"
              noOptionsText="فاقد اطلاعات"
                                        
            />
          </Grid>
          
        </Grid>
        <Grid item xs={12} 
              style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:100,borderStyle:'solid',borderRadius:20,
                      borderColor:'#01C7E9',width:'99%',flexWrap:'wrap'}}>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
            <FormControl sx={{width:{sm:'50ch',xs:'30ch'},justifyContent:'center',alignItems:'center'}}>
                <OutlinedInput placeholder='نام' inputProps={{maxLength:25}}
                    style={{fontFamily:'shabnam',borderStyle:'solid',borderWidth:2}}
                    sx={{width:{xs:'80%'},height:50}}
                    onChange={this.onChangeName}
                    value={this.state.nameInput}
                />
            </FormControl>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
            <Checkbox checked={this.state.active} onChange={this.onChangeActive}  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}/>
            <p style={{fontSize:'1.5em',fontWeight:'700'}}>{'فعال'}</p>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
            <Button style={{backgroundColor:'#01C7E9',height:50,width:'50%',fontFamily:'shabnam',color:'black'}} onClick={this.editDoc}>
              ویرایش نوع سند
            </Button>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
            <Button style={{backgroundColor:'#01C7E9',height:50,width:'50%',fontFamily:'shabnam',color:'black'}} onClick={this.saveDoc}>
              افزودن نوع سند
            </Button>
          </Grid>
        </Grid>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.onCloseAlert} type={this.state.typeAlert}/>
      </Grid>
    )
  };
}

export default Doucument;