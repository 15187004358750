import { Grid, Typography, TextField, Button } from "@mui/material";
import React from "react";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import {convertToPersianNumber, convertToEnglishNumber ,baseUrl} from "./materilas/vmp";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded';
import axios from "axios";


class DefineSupporter extends React.Component {

  token = localStorage.getItem("tokenUserHomeInja");

  constructor(props){
    super(props);
    this.state = {
      giftShow: false ,
      openAlert: false ,
      message: "",
      phoneNumber : "",
      typeAlert:3
    }
  }

  closeAlertModal = () => {
    this.setState({openAlert:false});
  }

  onChangePhoneNum = (event,newValue) => {
    this.setState({phoneNumber:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  onClickDefineSupporter = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/user/ds",
      method: "POST",
      headers: {'Authorization':'bearer ' + this.token},
      data: {
        mobile : this.state.phoneNumber
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"پشتیبانی با موفقیت اضافه / حذف شد",typeAlert:1});
    })
    .catch((err) => {
      switch(err.response.status){
        case 404 :
          this.setState({alertModal:true,alertMessage:"شماره وارد شده اشتباه می باشد",typeAlert:2})
          break;
        default :
          this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
      }
    })
    .finally(()=>{
      this.setState({giftShow:false});
    })
  }

  render(){
    return(
      <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px',xs:'0'}}}>
          <Grid style={{display:'flex',flexDirection:'row',marginRight:'6%'}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>تعریف پشتیبان</Typography>
          </Grid>
          <Grid sx={{padding:{xs:"100px 5%",md:"100px 0"}}}>
            <Grid sx={{width:{xs:"100%",md:"300px"},margin:"auto"}}>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",marginBottom:"5px"}}>
                شماره تلفن پشتیبان
              </Typography>
              <TextField 
                fullWidth    
                onChange={this.onChangePhoneNum}
                className="ads-textfield"
                id="ads-textfield-input"
                value={convertToPersianNumber(this.state.phoneNumber)}
                inputProps={{
                  maxLength: 11
                }}
              />
            </Grid>
            <Grid sx={{padding:{xs:"30px 0",md:"30px 0"},display:"flex",justifyContent:"center"}}>
              <Button onClick={this.onClickDefineSupporter}
                sx={{fontFamily:"shabnam",fontWeight:"bold",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",width:{xs:"100%",md:"300px"},
                    padding:"10px 0",fontSize:"16px"}}
              >
                اضافه کردن
                <DownloadDoneRoundedIcon sx={{color:"black !important", marginRight:"5px"}} />
                / حذف پشتیبان
                <DeleteRoundedIcon sx={{color:"black !important", marginRight:"5px"}}/>
              </Button>
            </Grid>
          </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    )
  }
}

export default DefineSupporter ;