import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import axios from "axios";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import LocationImg from '../images/location.png';
import AlertModal from "./materilas/alert";
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import { Link } from "react-router-dom";

class VisitUser extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        visits:[],
        openAlert:false,
        message:'',
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'بازدیدهای من در پلتفرم هومینجا'
    if (this.isMount){
      this.getUserVisit();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  getUserVisit = () =>{
    this.setState({visits:[]});
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/visits',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      let item = res.data;
      if (!item.hasOwnProperty('status')){
        item = item.data;
        this.setState({visits:item})
      }
    })
    .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  cancelVisit = (id) =>{
    this.setState({visits:[]});
    Promise.resolve(axios({
      url: baseUrl + 'v1/visit_user/cancel',
      method: 'Post',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        visit_id: id
      }
    }))
    .then(() => {
      this.setState({openAlert:true,message:'عملیات لغو بازدید انجام شد،در صورت مشکل در لغو پشتیبانی با شما تماس خواهند گرفت.',typeAlert:1})
      this.getUserVisit();
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'عملیات لغو بازدید با مشکل مواجه شد،خواهشمند است با پشتیبانی از طریق تیکت،چت آنلاین یا تماس با دفتر پیگیری بفرمایید.',typeAlert:2})
    });
  }

  onCloseAlert = () =>{
    this.setState({openAlert:false})
  }

  createVisitsList = () =>{
    let items = [];
    this.state.visits.forEach((element,index) =>{
      items.push(
        <Grid item xl={12} lg={6} xs={12} key={index} 
              style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',textDecoration:"none",color:"black",
                      borderTopRightRadius:40,borderStyle:'solid',width:'100%',borderColor:'#f3f3f2'}}
              sx={{flexWrap:{xl:'unset',xs:'wrap'}}}
        >
          <Grid item xl={3} xs={6} style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'120px'}}>
            {
              element.listing.small.length > 0 ?
                <Typography component={'img'} alt="image_visit"
                            style={{height:'100%',borderTopRightRadius:40,background:`url(${element.listing.small[0].path})`,
                                    backgroundRepeat:'no-repeat',backgroundSize:'cover',width:'100%',backgroundPosition:'center'}}>
                </Typography>
              : 
                <Typography component={'img'} src={noLogoAgancyImage} style={{width:'100%',height:'100%',borderTopRightRadius:40}} alt="no-agency"></Typography>
            }
          </Grid>
          <Grid item xl={3} xs={6} style={{display:'flex',alignItems:'center',justifyContent:'space-evenly',width:'100%',height:'120px'}}>
            <Typography component={'img'} src={LocationImg} style={{width:'24px',height:'26px'}} alt="location"></Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'700',textAlign:'center'}}>
              {element.listing.city.name + ' - ' + element.listing.neighbourhood.name + ' - ' + element.listing.area + ' متر'}
            </Typography>
          </Grid>
          <Grid item xl={3} xs={6} style={{display:'flex',flexDirection:'column',justifyContent:'center',width:'100%',alignItems:"center"}}>
            {
              element.status === 0 ?
                <Button style={{height:'50px',backgroundColor:'#003249',color:'white',fontFamily:'shabnam',fontSize:'15px',fontWeight:'700'}}
                        sx={{width:{sm:'50%',xs:'60%'}}} onClick={(event) => this.cancelVisit(element.id,event)}
                >{'لغو بازدید'}</Button>
              :
                <Button style={{height:'50px',backgroundColor:'#767d81',color:'white',fontFamily:'shabnam',fontSize:'15px',fontWeight:'700'}}
                        sx={{width:{sm:'50%',xs:'60%'}}} disabled={true}
                >{'در انتظار لغو'}</Button>
            }
            <Button component={Link} to={"/Listing"} onClick={() => this.onClickVisitList(element)} target="_blank"
              style={{height:'50px',backgroundColor:'#14C9E8',color:'white',fontFamily:'shabnam',fontSize:'15px',fontWeight:'700',marginTop:"5px"}}
                        sx={{width:{sm:'50%',xs:'60%'}}}
            >{'مشاهده آگهی'}</Button>
          </Grid>
          <Grid item xl={3} xs={6} 
                style={{display:'flex',alignItems:'center',width:'100%',height:'120px',backgroundColor:'#62DDFC'}}
                sx={{flexDirection:{xl:'row',xs:'column'},justifyContent:{xl:'space-between',xs:'center'}}}
          >
            <Grid item xl={6} xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'تاریخ : '}</Typography>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>
                {convertToPersianNumber(element.date)}
              </Typography>
            </Grid>
            <Grid item xl={6} xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'ساعت : '}</Typography>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>
                {convertToPersianNumber(element.time)}
              </Typography>
            </Grid>
          </Grid>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.onCloseAlert} type={this.state.typeAlert}/>
        </Grid>
      )
    })
    return items;
  }

  onClickVisitList = (element) => {
    localStorage.setItem("idListing",JSON.stringify(element.listing.id));
  }

  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'0px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>بازدیدهای من</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xl:'column',lg:'row',xs:'column'},flexWrap:{xl:'unset',lg:'wrap',xs:'unset'},
                    paddingLeft:{md:'130px',xs:'10px'},paddingRight:{md:'100px',xs:'5px'}}}
        >
          {
            this.state.visits.length > 0 ?
              this.createVisitsList()
            :
              <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {'شما هیچ آگهی جهت بازدید انتخاب نکرده اید.'}
                </Typography>
              </Grid>
          }
        </Grid>
      </Grid>
    )
  };
}

export default VisitUser;