import { Backdrop, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber,} from "./materilas/vmp";
import axios from "axios";
import Avatar from '../images/avatar1.png';
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class NewUsers extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        newUsers:[],
        openAlert:false,
        message:'',
        giftShow:false,
        infoModal:false,
        name:'',
        lastname:'',
        father:'',
        birth:'',
        meli:'',
        mobile:'',
        insta:'',
        userPicture:null,
        userId:null,
        userTypeId:'',
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    if (this.isMount){
      this.getNewUsers();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }
  
  getNewUsers = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/new',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token},
    }))
    .then((res) => {
      let items = res.data.data;
      this.setState({newUsers:items,giftShow:false})
    })
    .catch((err) => {
      this.setState({newUsers:[],giftShow:false})
    });
  }

  showInfoModal = (index)=>{
    this.setState({infoModal:true,name:this.state.newUsers[index].name,lastname:this.state.newUsers[index].lastname,father:this.state.newUsers[index].fathername,
                  birth:this.state.newUsers[index].date_of_birth,meli:this.state.newUsers[index].meli_number,mobile:this.state.newUsers[index].mobile,
                  insta:this.state.newUsers[index].instagram_link,userId:this.state.newUsers[index].id,userPicture:this.state.newUsers[index].picture_path,
                  userTypeId:this.state.newUsers[index].user_type_id})
  }

  closeInfoModal = () =>{
    this.setState({infoModal:false,name:'',lastname:'',father:'',birth:'',meli:'',mobile:'',insta:'',userId:'',userPicture:'',userTypeId:''})
  }

  createNewUserList = () =>{
    let items = [];
    this.state.newUsers.forEach((element,index) =>{
      items.push(
        <Grid item xs={12} key={index} component='button' onClick={()=> this.showInfoModal(index)}
              style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around',
                      backgroundColor:index % 2 === 0 ? 'white' : '#CCF4FB',borderRadius:16,borderStyle:'none',
                      width:'100%',marginBottom:'10px',flexWrap:'wrap',filter:'drop-shadow(0px 0px 2px rgba(0, 200, 233, 0.32))'}}
        >
          <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'نام و نام خانوادگی: '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%'}}>
              {element.name + ' ' + element.lastname}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'شماره ملی: '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%'}}>
              {convertToPersianNumber(element.meli_number)}
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'موبایل: '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%'}}>
              {convertToPersianNumber(element.mobile)}
            </Typography>
          </Grid>
          
        </Grid> 
      )
    })
    return items;
  }

  activeUser = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/active',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        user_id:this.state.userId
      }
    }))
    .then((res) => {
      this.closeInfoModal();
      this.getNewUsers();
      this.setState({openAlert:true,message:'کاربر مورد نظر با موفقیت تایید شد.',typeAlert:1})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'تایید کاربری با مشکل مواجه شد.',typeAlert:2})
    });
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>کاربران جدید</Typography>
        </Grid>
        { 
          this.state.newUsers.length > 0 ?
            <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                  sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
            >   
              {this.createNewUserList()}
            </Grid>
          :
            <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                  sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
            >   
              <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {'هیچ کاربر جدیدی جهت نمایش وجود ندارد.'}
                </Typography>
              </Grid>
            </Grid>
        }
        <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.infoModal}
          onClose={this.closeInfoModal}
          BackdropComponent={Backdrop}
          BackdropProps={{timeout:2000}}
          sx={{display:'flex',flexDirection:'row',alignItems:{md:'center',xs:'start'},justifyContent:'center',overflowY:'scroll'}}
        >
            
          <Grid item xs={8} 
            style={{backgroundColor:'white',display:'flex',width:'80%',border: '2px solid',borderColor:'black',
                  flexDirection: 'column',alignItems:'center',justifyContent:'center',padding:'6px 16px 6px 16px',zIndex:"0",
                  boxShadow:'0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'}}>
            <Grid item xs={11} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center'}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'24px',marginTop:10}}>{'اطلاعات کاربری'}</Typography>
            </Grid>
            <Grid item xs={11} style={{display:'flex',alignItems:'center',flexDirection:'column',marginTop:50,width:'100%'}}
                  sx={{paddingLeft:{md:'130px',xs:'10px'},paddingRight:{md:'100px',xs:'5px'}}}
            >
              <Grid item xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                <Grid style={{borderStyle:'solid',borderRadius:'100%',borderColor:'#00C8E9',borderWidth:'10px',
                                    display:'flex',alignItems:'center',justifyContent:'center'}}
                            sx={{width:{sm:'200px',xs:'120px'},height:{sm:'200px',xs:'120px'}}}
                >
                  {
                    this.state.userPicture !== null ?
                      <Typography 
                          style={{width:'100%',height:'100%',borderRadius:'100%',backgroundImage:`url(${this.state.userPicture})`,
                          backgroundRepeat:'no-repeat',backgroundSize:'cover'}}
                      ></Typography>
                    :
                      <Typography component={'img'} src={Avatar} alt="avatar"
                          sx={{width:{sm:'130px',xs:'45px'},height:{sm:'130px',xs:'45px'}}}
                      ></Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11} 
                  style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',flexWrap:'wrap',width:'100%'}}
                  sx={{paddingRight:'5%',paddingLeft:'5%',marginTop:{sm:'40px',xs:'20px'}}}
            >
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}  
                  sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'نام کاربر: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{this.state.name}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}  
                  sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'نام خانوادگی: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{this.state.lastname}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'شماره موبایل: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{convertToPersianNumber(this.state.mobile)}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'نام پدر: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{this.state.father}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'نوع کاربری: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >
                    {this.state.userTypeId === 1 ?
                      "عادی"
                      : this.state.userTypeId === 2 ?
                        "صاحب ملک"
                        : this.state.userTypeId === 3 ?
                          "مشاور مستقل" 
                          : this.state.userTypeId === 4 ?
                            "مشاور هومینجا"
                            : this.state.userTypeId === 5 ?
                              "پشتیبان"
                              : "ادمین"
                    }
                  </Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'شماره ملی: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{convertToPersianNumber(this.state.meli)}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'لینک اینستاگرام: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{this.state.insta}</Typography>
              </Grid>
              <Grid item lg={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{marginTop:{sm:'50px',xs:'20px'}}}
              >
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                      sx={{fontSize:{sm:'16px',xs:'14px'}}}
                  >{'تاریخ تولد: '}</Typography>
                  <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                      sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
                  >{convertToPersianNumber(this.state.birth)}</Typography>
              </Grid>
              
            </Grid>
            <Grid item xs={11} style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',marginTop:40,flexWrap:'wrap'}}>
              <Grid item md={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                <Button onClick={this.activeUser} 
                        style={{backgroundColor:'#00C8E9',borderRadius:8,height:'50px',color:'black',
                                fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                        sx={{width:{sm:'60%',xs:'70%'}}}
                >
                  {'تایید کاربر'}
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
                <Button onClick={this.closeInfoModal} 
                        style={{backgroundColor:'red',borderRadius:8,height:'50px',color:'white',
                                fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                        sx={{width:{sm:'60%',xs:'70%'}}}
                >
                  {'انصراف'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    )
  };
}

export default NewUsers;