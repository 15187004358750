import { Autocomplete, Button, Checkbox, FormControl, Grid,  MenuItem,  Select, TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl, checkedIcon,  convertToPersianNumber, icon, onlyReciveNumber, separator } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

class AddInventory extends React.Component{
 
  isMount = false ;
  token = localStorage.getItem("tokenUserHomeInja");

  constructor(props){
      super(props);
      this.state={
        openAlert: false ,
        giftShow: false ,
        message:"" ,
        typeAccount: "",
        userId: "",
        agencySelected: null,
        agencyOptions: [],
        amountMoney:"",
        typeAlert:3
      }
  }

  closeAlertModal = () =>{
      this.setState({openAlert:false});
  }

  onChangeTypeAccount = (event) => {
    this.setState({agencySelected:null,userId:"",amountMoney:"",typeAccount:event.target.value});
    if(event.target.value === 2){
      this.getAgency();
    }
  }

  getAgency = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/agancy/all",
      method: "GET",
      headers: {'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      this.setState({agencyOptions:res.data.data});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
    })
  }

  onChangeUserId = (event) => {
    this.setState({userId:onlyReciveNumber(event.target.value)});
  }

  onChangeAgency = (event,newValue) => {
    this.setState({agencySelected:newValue});
  }

  onChangeAmountMoney = (event,newValue) => {
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({amountMoney:onlyReciveNumber(inputWithoutComma)});
  }

  onClickAddInventory = () => {
    if(this.state.typeAccount === 1){
      if(this.state.userId){
        if(this.state.amountMoney){
          this.postAddUser();
        }else{
          this.setState({openAlert:true,message:"مبلغ را وارد کنید",typeAlert:3});
        }
      }else{
        this.setState({openAlert:true,message:"شماره کاربری را وارد کنید",typeAlert:3});
      }
    }
    if(this.state.typeAccount === 2){
      if(this.state.agencySelected){
        if(this.state.amountMoney){
          this.postAddAgency();
        }else{
          this.setState({openAlert:true,message:"مبلغ را وارد کنید",typeAlert:3});
        }
      }else{
        this.setState({openAlert:true,message:"آژانس را انتخاب کنید",typeAlert:3});
      }
    }
  }

  postAddUser = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/gift/add",
      method: "POST",
      headers: {'Authorization':'bearer ' + this.token},
      data:{
        user_id: this.state.userId,
        amount: this.state.amountMoney
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"افزایش موجودی با موفقیت انجام شد",typeAlert:1});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false,agencySelected:null,userId:"",amountMoney:""});
    })
  }

  postAddAgency = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/gift/addAgancy",
      method: "POST",
      headers: {'Authorization':'bearer ' + this.token},
      data:{
        agancy_id: this.state.agencySelected.id,
        amount: this.state.amountMoney
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"افزایش موجودی با موفقیت انجام شد",typeAlert:1});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false,agencySelected:null,userId:"",amountMoney:""});
    })
  }

  render() {
    return (
      <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'0'}}}>
        <Grid style={{display:'flex',flexDirection:'row',marginRight:'6%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>افزایش موجودی</Typography>
        </Grid>
        <Grid sx={{padding:{xs:"0 5px",md:"0 20%",lg:"0 30%",xl:"0 35%"}}}>
          <Grid sx={{width:"100%",margin:"10px 0"}}>
            <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",fontSize:"20px"}}>
              {"نوع حساب"}
            </Typography>
            <FormControl disabled={this.state.giftShow} fullWidth sx={{border:"2px solid #eee",borderRadius:"8px",marginTop:"10px",boxSizing:"border-box"}}>
              <Select
                sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center"}}
                onChange={this.onChangeTypeAccount}
                value={this.state.typeAccount}
              >
                <MenuItem value={1} sx={{fontFamily:"shabnam",fontWeight:"bold",justifyContent:"center"}}>کاربر</MenuItem>
                <MenuItem value={2} sx={{fontFamily:"shabnam",fontWeight:"bold",justifyContent:"center"}}>آژانس</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid sx={{width:"100%",margin:"10px 0"}}>
            <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",fontSize:"20px"}}>
              { this.state.typeAccount === 1?
                  "شماره کاربری"
                : this.state.typeAccount === 2 ?
                    "انتخاب آژانس"
                  : ""
              }
            </Typography>
            { this.state.typeAccount === 1 ?
                <TextField disabled={this.state.giftShow}
                  sx={{border:"2px solid #eee",borderRadius:"8px",marginTop:"10px",boxSizing:"border-box"}}
                  InputProps={{
                    style:{fontFamily:"shabnam",fontSize:"20px",fontWeight:"bold"}
                  }}
                  inputProps={{
                    maxLength: 8,
                    style:{textAlign:"center"}
                  }}
                  fullWidth
                  value={convertToPersianNumber(this.state.userId)}
                  onChange={this.onChangeUserId}
                >
                </TextField>
              : this.state.typeAccount === 2 ?
                  <Autocomplete
                    disabled={this.state.giftShow}
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    sx={{margin:"10px 0"}}
                    options={this.state.agencyOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField  {...params} placeholder='آژانس ها'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    onChange={this.onChangeAgency}
                    value={this.state.agencySelected}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                    noOptionsText="فاقد اطلاعات"
                  />
                : <Grid></Grid>
            }
          </Grid>
          <Grid sx={{width:"100%",margin:"10px 0"}}>
            <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",fontSize:"20px"}}>
              مبلغ به ریال
            </Typography>
            <TextField
              disabled={this.state.giftShow}
              sx={{border:"2px solid #eee",borderRadius:"8px",marginTop:"10px",boxSizing:"border-box"}}
              fullWidth
              InputProps={{
                style:{fontFamily:"shabnam",fontSize:"20px",fontWeight:"bold"}
              }}
              inputProps={{
                maxLength: 14,
                style:{textAlign:"center"}
              }}
              value={convertToPersianNumber(separator(this.state.amountMoney))}
              onChange={this.onChangeAmountMoney}
            >

            </TextField>
          </Grid>
          <Button onClick={this.onClickAddInventory} disabled={this.state.giftShow}
            sx={{fontFamily:"shabnam",fontWeight:"bold",color:"black",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},width:"100%",padding:"13px 0",margin:"20px 0",
                fontSize:"18px"}}
          >
            افزایش موجودی
            <AddRoundedIcon sx={{color:"black !important"}}/>
          </Button>
        </Grid>
        <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    )
  };
}

export default AddInventory;