import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article60/01.jpg";
import photo02 from "../../images/mag/article60/02.jpg";
import photo03 from "../../images/mag/article60/03.jpg";
import photo04 from "../../images/mag/article60/04.jpg";
import photo05 from "../../images/mag/article60/05.jpg";
import photo06 from "../../images/mag/article60/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article60 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "ما در این مقاله از هومینجا قصد داریم به تمام نکات پیرامون شرایط مشارکت در ساخت سال ۱۴۰۲ بپردازیم، با ما همراه باشید.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "مشارکت در ساخت چیست | مشارکت در ساخت تهران | قانون مشارکت در ساخت سال ۱۴۰۲ | محاسبه قدرالسهم در مشارکت | محاسبه آنلاین سود مشارکت در ساخت | کلاهبرداری در مشارکت در ساخت | انواع مشارکت در ساخت | مشارکت در ساخت بدون بلاعوض | قرارداد مشارکت در ساخت سال ۱۴۰۲ "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[59].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[59].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[59].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[59].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[59].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[59].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="مشارکت در ساخت سال 1402 - هومینجا - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[59].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی داریم صفر تا صد مشارکت در ساخت و ساز تهران در سال ۱۴۰۲ را با هم بررسی کنیم. پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مشارکت در ساخت چیست
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="مشارکت در ساخت چیست - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مشارکت در ساخت چیست
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از محبوب‌ترین راه‌های جذب سرمایه مشارکت در ساخت برای ساخت ملک‌های قدیمی و <Link className="link" to="/Listing/1118" target="_blank">  کلنگی </Link> است. معمولا مشارکت در ساخت طی یک قرارداد رسمی و با رعایت حقوق طرفین انجام می گیرد و برای هر دو طرف معامله با سود خوبی همراه خواهد بود. این روش به ویژه برای کسانی که صاحب زمین یا ملک با موقعیت مناسب هستند اما سرمایه و تخصص لازم برای ساخت و ساز را ندارند انتخاب مناسبی است. ما در این مقاله قصد داریم به تمام نکات پیرامون شرایط مشارکت در ساخت سال ۱۴۰۲ بپردازیم، با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                محاسبه قدرالسهم و میزان سود مالک و سازنده در قرارداد مشارکت ساخت سال ۱۴۰۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تعیین میزان سهم هریک از طرفین قرارداد مشارکت در ساخت روش‌های مختلفی وجود دارد، البته باید اشاره کرد که قانون مشارکت تابع هیچ شرایط خاصی نیست و می‌توان آن را کاملا مطابق با توافق طرفین در نظر گرفت، نباید از نظر دور داشت که مواردی چون موقعیت قرارگیری ملک، شهر محل قرارگیری، متراژ، قیمت ملک و… در تعیین محاسبه قدرالسهم مالک و سازنده تأثیرگذار هستند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/1402-House-Loan" target="_blank">
                  بیشتر بخوانید : وام مسکن ۱۴۰۲؛ شرایط و نحوه دریافت انواع وام مسکن
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به طور مثال در کلانشهری چون تهران، فرمول محاسبه مشارکت در ساخت در هر منطقه بر مبنای «قیمت هر مترمربع آپارتمان نوساز در آن منطقه» تعیین می‌شود. هرچه قیمت ملک بالاتر باشد سهم مالک زمین بیشتر و سهم سازنده کمتر می‌شود. البته همان طور که اشاره کردیم مواردی چون قیمت ساخت و ساز هر متر مربع، تعداد طبقات و واحدهای تجاری و مسکونی و… می‌تواند در تعیین قدر السهم‌ها تأثیر بسزایی داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نکته دیگر در تعیین قدرالسهم، مبلغی است که با عنوان «بلاعوض» محاسبه می شود و در ابتدای فرآیند مشارکت در ساخت، توسط سازنده به مالک پرداخت می‌گردد. اصولا این مبلغ به صورت توافقی بین طرفین تعیین می‌شود و گاهی نیز این مقدار به اندازه درصدی از بهای کل ملک محاسبه می‌شود. برای تعیین این مقدار یک فرمول کلی نیز وجود دارد که در صورت‌عدم توافق طرفین، از آن برای تعیین مبلغ بلاعوض استفاده می‌شود. فرمول محاسبه مبلغ بلاعوض در قرارداد مشارکت در ساخت به این شرح است:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                R = A٪ × D – G٪ × M
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کل ارزش ملک = M
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قدرالسهم سازنده = G
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کل هزینه ساخت = D
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قدرالسهم مالک = A
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مقدار بلاعوض = R
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                البته در تعیین این میزان وجه نکاتی نیز وجود دارد که باید به آن‌ها توجه شود. این نکات عبارتند از:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در صورتی که میزان وجه بلاعوض منفی باشد، باید قدرالسهم مالک کمتر شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                یکی دیگر از نکات مهم در تعیین میزان بلاعوض اعتبار سازنده است؛ چرا که سازنده‌های معتبر و خوش‌نام به واسطه اعتباری که دارند معمولا وجه کمتری را با عنوان بلاعوض پرداخت می‌کنند.
              </Typography>
              <Typography className="h2" variant="h2">
                مشارکت در ساخت بدون بلاعوض
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="مشارکت در ساخت بدون بلاعوض - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مشارکت در ساخت بدون بلاعوض
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا در اکثر قراردادهای مشارکت در ساخت سازنده مبلغی را به عنوان بلاعوض به مالک پرداخت می‌کند که این مبلغ عموما مابه التفاوت قدرالسهم مالک و سازنده است. گاهی نیز مبلغی برای رهن یا اجاره یک ملک در منطقه برای مالک در نظر گرفته می‌شود تا زمانی که پروژه مشارکت در ساخت به پایان برسد. همان طور که اشاره کردیم این مبلغ کاملا توافقی است و ممکن است به دلایلی سازنده آن را نپردازد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/1402-Tehran-Construction-Cost" target="_blank">
                  بیشتر بخوانید : برآورد هزینه ساخت ساختمان در تهران سال ۱۴۰۲
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مثلا در مواردی که مشارکت در ساخت در بافت فرسوده انجام می‌گیرد و قیمت زمین یا ملک کلنگی آنقدر پایین است که هیچ بلاعوضی را نمی‌توان برای آن در نظر گرفت و یا نهایتا مبلغی برای رهن و اجاره کردن یک ملک در منطقه برای مالک در نظر گرفته می‌شود که عموما به آن قرض الحسنه در مشارکت ساخت گفته می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حقیقت تعیین مبلغ بلاعوض برای ایجاد توازن در سود ساخت بین مالک و سازنده است و مبلغ ثابت شده و مشخصی برای محاسبه آن وجود ندارد. در یک قرارداد مشارکت در ساخت بدون بلاعوض مشخص خواهد شد که سازنده به فرد مبلغی پرداخت می‌کند یا هیچ مبلغی را پرداخت نمی‌کند. اما در شرایط کلی بلاعوض بر اساس متراژ محاسبه می‌شود. اگرچه عوامل دیگری نیز در تعیین آن نقش دارند که مهمترین آن‌ها عبارتند از: عرف، قدرالسهم طرفین، کیفیت ساخت، اعتبار سازنده و….
              </Typography>
              <Typography className="h2" variant="h2">
                قرارداد مشارکت در ساخت سال ۱۴۰۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در قرارداد مشارکت در ساخت دو طرف وجود دارد که عبارتند از: صاحب یا صاحبان زمین و سازنده یا سازندگان. هریک از این طرفین ممکن است یک شخص حقیقی یا حقوقی باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یک قرارداد رسمی عموما از بندهای متنوعی برخوردار است که هریک از آن‌ها ممکن است تبصره‌های مختلفی نیز داشته باشند. توجه به این بندها در تنظیم قرارداد مشارکت در ساخت و ساز سال ۱۴۰۲ نقش مهمی دارند. مهمترین بندهای یک قرارداد مشارکت تنظیم و نمونه آن به این شرح است :
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                طرفین قرارداد مشارکت در ساخت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                موضوع قرارداد و قدرالسهم طرفین
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مشخصات ملک یا زمین
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مشخصات کلی ساختمان جدید التاسیس
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تقسیم نامه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدت قرارداد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نحوه انتقال <Link className="link" to="/property-document" target="_blank"> سند </Link> به نام سازنده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پیش فروش در قرارداد مشارکت در ساخت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعهدات مالک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعهدات سازنده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آثار قرارداد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تخلفات ساخت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اضافه بنا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دوره تضمین
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شرایط قهری
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فسخ قرارداد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                وجه التزام ناشی از عدم انجام تعهدات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حل اختلاف
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اسناد و مدارک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نسخ قرارداد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لیست مصالح مصرفی
              </Typography>
              <Typography className="h2" variant="h2">
                محاسبه آنلاین سود مشارکت در ساخت
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="محاسبه سود مشارکت ساخت و ساز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  محاسبه سود مشارکت ساخت و ساز
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای این که بتوان سود مشارکت در ساخت را محاسبه کرد نیاز است که بدانید سهم شما به عنوان مالک یا سازنده از این مشارکت چقدر خواهد بود. در حقیقت نحوه محاسبه درصد مشارکت در ساخت سوالی است که برای اکثر افراد قبل از عقد قرارداد پیش می‌آید. مواردی وجود دارد که انواع مشارکت در ساخت در تعیین قدرالسهم‌ها تعیین کننده است. در ادامه به این موارد می‌پردازیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Housing-Market-Prediction-1402" target="_blank">
                  بیشتر بخوانید : پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                قدرالسهم سازنده و مالک در قرارداد مشارکت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همان طور که قبلا هم اشاره کردیم هیچ قانون خاصی برای تعیین سهم و سود سازنده و مالک وجود ندارد و در اکثر موارد این مبالغ با توجه به عرف منطقه تعیین می‌شود. معمولا در تعیین قدرالسهم مشارکت میزان آورده مالک و سازنده را برحسب ریال محاسبه می‌کنند که روش محاسبه آن به این ترتیب خواهد بود :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                قدرالسهم مالک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای این که بتوانیم درصد مشارکت مالک را محاسبه کنیم باید ابتدا دارایی او را ارزش‌گذاری کرد. این دارایی همان ملک کلنگی یا پروژه مورد نظر برای مشارکت است. در مورد زمین‌هایی که <Link className="link" to="/building-certificate" target="_blank">  پروانه </Link> ساخت دارند قیمت آن به دارایی مالک افزوده شده و قدرالسهم او را افزایش می‌دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                قدرالسهم سازنده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دارایی سازنده سرمایه‌ای که برای ساخت صرف می‌کند و دانش و تخصص او خواهد بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تعیین قدرالسهم‌ها به تعیین تقسیم واحدها در مشارکت و تعیین درصد کمک می‌کند.به این مثال توجه کنید:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مثال تعیین قدرالسهم در مشارکت در ساخت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یک زمین یا ملک کلنگی به ارزش ۱۰ میلیارد تومان را در نظر بگیرید. به طور مثال هزینه ساخت تقریبا ۸ میلیارد تومان محاسبه شده است. به این ترتیب سهم مالک زمین برابر است: ۵۶٪ = (۱۰+۸) / ۱۰
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                و سهم سازنده نیز ۴۴٪ = (۱۰+۸) / ۸ خواهد بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا برای تقسیم واحدهای آپارتمانی با توجه به سهم‌ها برای تعیین دقیق سهم از مبلغ بلاعوض استفاده می‌شود که در بخش قبل به نحوه محاسبه آن اشاره کردیم. معمولا مبلغ بلاعوض به گونه‌ای تعیین می‌شود که قدرالسهم طرفین ۵۰.۵۰ شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به این ترتیب فرمول محاسبه بلاعوض به این شکل خواهد بود:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قدرالسهم مالک * (آورده مالک + آورده سازنده) – آورده مالک = مبلغ بلاعوض
              </Typography>
              <Typography className="h2" variant="h2">
                مزایا و معایب مشارکت در ساخت مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="مزایا و معایب مشارکت در ساخت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مزایا و معایب مشارکت در ساخت
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مزایا و معایب را می‌توان هم برای مالک و هم برای سازنده در نظر گرفت. مهمترین نکته در مورد مشارکت در ساخت سود خوبی است که این کار در کوتاه مدت برای طرفین توافق مشارکت به همراه دارد. البته مزایای مشارکت در ساخت به همین یک مسئله ختم نمی‌شود. مهمترین مزایایی که برای این شکل از ساخت و ساز می‌توان در نظر گرفت به این شرح است :
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                افزایش مقاومت ساختمان و نوسازی آن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                امکان استفاده از استانداردهای نوین و مدون مهندسی در ساخت بنای جدید
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ایجاد واحدهای مسکونی و تجاری به تعداد بیشتر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ساخت <Link className="link" to="/building-facades-types" target="_blank">  نما </Link>ی ساختمان با توجه به استانداردهای روز
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                استفاده از مصالح، روش‌ها و تجهیزات مدرن ساخت و ساز
              </Typography>
              <Typography className="h2" variant="h2">
                خطرات و کلاهبرداری در مشارکت ساخت برای مالک یا مالکان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهمترین چالش‌های پیش روی مالکان، خطرات و <Link className="link" to="/real-estate-fraud" target="_blank"> کلاهبرداری </Link>های احتمالی در مشارکت ساخت است. مهمترین خطرات مشارکت در ساخت برای مالک یا مالکین عبارتند از :
              </Typography>
              <Typography className="h3" variant="h3">
                تاخیر انجام تعهدات مشارکت یاعدم تعهد سازنده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید بتوان گفت که رایج‌ترین مشکل در اکثر قراردادهای مشارکت در ساخت خانه کلنگی یا زمین، تأخیر یاعدم انجام تعهد توسط سازنده است. این تأخیر چه موجه و چه غیر موجه باشد مطمئنا برای مالک با خسارت‌هایی همراه خواهد بود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ضمن این که استرس و بار روانی که این مشکل به مالک وارد می‌کند نیز باید در نظر داشت. برای پیشگیری از مشکلات در چنین شرایطی بهتر است در زمان انعقاد قرارداد تمام ضمانت‌ها، تعهدات و خسارت‌ها را لحاظ کرد. در حقیقت چنین مشکلی با تنظیم یک قرارداد اصولی، نظارت مناسب در زمان ساخت و پیاده‎‌سازی صحیح قرارداد به حداقل می‌رسد. در این صورت می‌توان مواردی چون خسارات تأخیر و یاعدم تعهد را مطابق با آنچه در قرارداد آمده است دریافت کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                پیش فروش ملک خارج از توافق
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا در قرارداد مشارکت به موضوع پیش فروش و شرایط آن اشاره می‌شود و طرفین قرارداد باید در صورت اقدام برای پیش فروش مطابق با آن رفتار کنند اما باز هم ممکن است مشکل پیش فروش ملک خارج از توافق انجام گیرد. برای پیشگیری از این مشکل و یا اقدام قانونی در صورت بروز آن بهتر است در زمان عقد قرارداد به موضوع پیش فروش با همه جزئیات پرداخته شود. عموما شرایط و نکات پیش فروش با توجه به قدرالسهم هریک از طرفین تعیین می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                ساخت ملک بر خلاف فهرست مصالح
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا در یک قرارداد مشارکت فهرستی از متریال ساخت ملک تهیه می‌شود، حتی در یک قرارداد بی‌عیب و نقص به جایگزین احتمالی متریال‌ها نیز اشاره می‌شود. اما در صورتی که به چنین مواردی پرداخته نشده باشد ممکن است با مشکل ساخت ملک بر خلاف آنچه در قرارداد به عنوان متریال و مصالح ساخت اشاره شده است مواجه شوید. این فهرست باید به صورت واقع بینانه، سود دو طرفه، اصولی و همراه با گزینه‌های جایگزین تنظیم شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                ساخت ملک بر خلاف نقشه‌ها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گاهی ممکن است بعد از پایان پروژه مالک یا مالکین متوجه شوند که ساخت ملک جدید بر اساس نقشه‌ها پیش نرفته است. تنها راه پیشگیری از چنین تخلفی نظارت مداوم بر پروسه ساخت و ساز و همچنین اخذ ضمانت اجرایی اصولی جهت انجام تعهدات از سازنده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                واگذاری قرارداد به شخص ثالث
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مورد نیز از جمله مشکلات رایج در پروژه‌های مشارکت در ساخت محسوب می‌شود. البته همانند سایر موارد با تنظیم یک قرارداد مشارکت اصولی و استاندارد می‌توان از این موارد جلوگیری کرده و یا مجازات آن‌ها را تعیین کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                تخلفات بدون اطلاع و توافق با مالک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر در قرارداد مشارکت در ساخت و ساز به این موارد پرداخته نشده باشد نحوه پرداخت خسارات آن نیز مشخص نخواهد بود. بهترین راه برای پیشگیری از چنین تخلفی تعیین تخلفات مجاز و غیر مجاز و تعیین عواقب و نحوه پرداخت خسارات ناشی از آن در قرارداد مشارکت است. عدم تمکن مالی سازنده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است برای انجام مشارکت در ساخت سراغ سوابق سازنده بروید و از پروژه‌های قبلی که توسط او ساخته شده است دیدن کنید. علاوه بر در نظر گرفتن تمکن مالی سازنده باید خصوصیات اخلاقی سازنده و میزان متعهد بودن او را نیز در نظر داشته باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                خطرات و کلاهبرداری مشارکت در ساخت برای سازنده یا سازندگان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کلاهبرداری در مشارکت ساخت و ساز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کلاهبرداری در مشارکت ساخت و ساز
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت به بررسی مشکلات، خطرات و چلش های پیش رو برا ی سازندگان در زمینه مشارکت در ساخت را بررسی می کنیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                تأخیر یا عدم انجام تعهد توسط مالک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای جلوگیری از این خطر سعی کنید بند تاخیر یا عدم تعهدات دوطرف را در قرارداد قید کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                بازداشت بودن ملک، در رهن بودن ملک، ایرادهای ثبتی، اوقافی بودن ملک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این گونه مشکلات به دلیل عدم دقت و احراز کامل مالک صورت میگیرد. سازندگان با تجربه قبل از اقدام برای مشارکت در ساخت تمامی مدارک و مستندات مالک یا مالکین را استعلام و احراز می کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                وجود معارض
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود معارض از جمله مشکلات شایع در تقسیم قدرالسهم می باشد که برای جلوگیری از این رخداد مهندسین مشاور باید طرح تفضیلی را به دقت مورد بررسی قرار دهند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                اختلاف نظر بین مالکین (در مورد ملک‌هایی با چند مالک)
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود چندین مالک همیشه باعث ایجاد چالشهای فراوانی برای سازندگان می باشد که با تدوین قرارداد مناسب از اقدامات غیر منطقی مالکان جلوگیری می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                تغییر قوانین مرتبط با ساخت وساز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تغییر قوانین شهری از جمله مشکلات قراردادهاست که متاسفانه قابل پیشگیری نمی باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                تحریم و نبود مصالح موجود در لیست متریال
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مشکلات شایع در این روزها، نبود مواد مصرفی طبق قرارداد است. کشور ما جندین سال با تحریم درگیر بوده که این مشکلات در جند سال اخیر افزایش داشته است. با سخت تر شدن واردات معمولا تهیه اقلام مصرفی مشکل یا غیر قابل امکان شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                بروز شرایط فورس ماژور وعدم امکان تکمیل پروژه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همیشه در هر قرارداد شرایط فورس مازور را به دقت تدوین کنید تا از مشکلات آتی پیشگیری کند.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع‌بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به افزایش قیمت ملک و زمین در سال‌های اخیر، مشارکت در ساخت یکی از بهترین راه‌ها برای تأمین هزینه ساخت و ساز بوده است. مشارکت در ساخت و ساز با مزایای بسیاری از جمله سود بالا در مدت زمان کوتاه برای طرفین (سازنده و مالک) همراه است. برای این که بتوان از چالش‌ها و معایب مشارکت در ساخت در امان بود بهتر است که قرارداد مشارکت در ساخت را به صورت کاملا استاندارد و به صورت رسمی تهیه کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در متن مقاله به مهمترین نکاتی که در تنظیم قرارداد مشارکت در ساخت و ساز سال ۱۴۰۲ به آن نیاز دارید اشاره کردیم. همواره به یاد داشته باشید که یک قرارداد بی‌عیب و نقص که در آن به همه جزئیات پرداخته شده باشد می‌تواند راهکار مناسبی برای پیشگیری از بسیاری مشکلات و کلاهبرداری‌ها در پروژه‌های ساخت و ساز است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطالعه مقالات زیر از <Link className="link" to="/HomeMag" target="_blank"> بلاگ </Link> هومینجا به افزایش آگاهی شما کمک خواهد کرد.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/house-tracking-code'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    کد رهگیری املاک چیست
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/buying-house-in-Tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    نکات مهم در مورد خرید ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/way-to-get-house-loan'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    وام مسکن ۱۴۰۱
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article60;