import { Grid, Typography, Box, Button, TextField, Modal, Autocomplete, Checkbox, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../styles/listing.css";
import { baseUrl, separator, setCanonicalTag } from "./materilas/vmp";
import FavoriteBorderSharpIcon from '@mui/icons-material/FavoriteBorderSharp';
import AccessAlarmsRoundedIcon from '@mui/icons-material/AccessAlarmsRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import locationIcon from "../images/listing/location.png";
import { convertToPersianNumber, report, situation } from "./materilas/vmp";
import condition from "../images/features/condition.png";
import cctvIcon from "../images/features/cctvIcon.png";
import bedroom from "../images/features/bedroom.png";
import warehouseIcon from "../images/features/warehouseIcon.png";
import elevatorIcon from "../images/features/elevatorIcon.png";
import parkingIcon from "../images/features/parkingIcon.png";
import welfareIcon from "../images/features/welfare.png";
import kitchen from "../images/features/kitchen.png";
import { kitchenEq, welfare, safety, cool_heat, cover, facade, kitchenCabinet, icon, checkedIcon } from "./materilas/vmp";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShowLocation from "./materilas/showLocation";
import AuthModal from '../component/authmodal';
import AlertModal from "./materilas/alert";
import Avatar from "../images/listing/avatar.png";
import GifLogo from './gifLogo';
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import ArchivedPhoto from "../images/listing/archived.jpg";
import HideImageRoundedIcon from '@mui/icons-material/HideImageRounded';

class Listing extends React.Component {

  userInfo = JSON.parse(localStorage.getItem("infoUserHomeInja"));
  isMount = false;
  settingSlider = {
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3500,
  }

  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      message: "",
      showGif: "none",
      listingInfo: null,
      posterLiked: false,
      similarInfo: null,
      openAuthModal: false,
      openShareModal: false,
      openReportModal: false,
      report: null,
      id: props.id !== undefined ? props.id : window.location.pathname.substring(9),
      typeAlert: 3,
      isLoading: false
    }
  }

  componentDidMount() {
    document.title = "آگهی - هومینجا";
    this.isMount = true;
    if (this.isMount) {
      this.getListingInfo();
      setCanonicalTag();
    }
  }

  componentWillUnmount() {
    this.isMount = false;
    let link = document.querySelector("link[rel='canonical']");
    link.remove();
    // this.setDefaultTags();
  }

  checkActiveAndUser = () => {
    if (this.userInfo) {
      if (this.userInfo.type.id > 4) {
        return true
      } else {
        if (this.state.listingInfo.active === 1) {
          return true
        } else {
          return false
        }
      }
    } else {
      if (this.state.listingInfo.active === 1) {
        return true
      } else {
        return false
      }
    }
  }

  oncloseAlert = () => {
    this.setState({ openAlert: false });
  }

  getListingInfo = () => {
    this.setState({ showGif: "" });
    Promise.resolve(axios({
      url: baseUrl + 'v1/listing/getOne',
      method: 'GET',
      params: {
        listing_id: this.state.id
      }
    }))
      .then((res) => {
        this.setState({
          listingInfo: res.data.data ? res.data.data : null
        });
        this.getSimilar();
        this.isPosterMarked();
        this.setDocumentTitle(res.data.data);
        this.setH1(res.data.data);
        this.setMetaTagDesc(res.data.data);
      })
      .catch((err) => {
        switch (err.response.status) {
          case 404:
            window.location.pathname = "/not-found";
            break;
          default:
            this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید", typeAlert: 2 });
        }
      })
      .finally(() => {
        this.setState({ showGif: "none" });
      })
  }

  getSimilar = () => {
    Promise.resolve(axios({
      url: baseUrl + "v1/listing/similar",
      method: "GET",
      params: {
        listing_id: this.state.listingInfo.id,
        state_id: this.state.listingInfo.state_id,
        transaction_id: this.state.listingInfo.transaction_id,
        city_id: this.state.listingInfo.city_id,
        estate_id: this.state.listingInfo.estate_id,
        zone_id: this.state.listingInfo.zone_id,
        area_min: parseInt(this.state.listingInfo.area) - 20,
        area_max: parseInt(this.state.listingInfo.area) + 20
      }
    }))
      .then((res) => {
        this.setState({
          similarInfo: res.data.data.length > 0 ? res.data.data : null
        });
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
      });
  }

  isPosterMarked = () => {
    if (localStorage.getItem('infoUserHomeInja')) {
      let infoUserHomeInja = JSON.parse(localStorage.getItem('infoUserHomeInja'));
      Promise.resolve(axios({
        url: baseUrl + 'v1/mark/get',
        method: 'GET',
        headers: { 'Authorization': 'bearer ' + localStorage.getItem('tokenUserHomeInja') },
        params: {
          user_id: infoUserHomeInja.id
        }
      }))
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            if (this.state.listingInfo.id === res.data.data[i].listing_id) {
              this.setState({ posterLiked: true });
            }
          }
        })
        .catch((err) => {
          this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است", typeAlert: 2 });
        });
    }
  }

  sliderImages = () => {
    let images = [];
    this.state.listingInfo.large.forEach((element, index) => {
      images.push(
        <Grid key={index}
          sx={{
            height: { xs: "180px", sm: "350px", md: "550px", lg: "660px" }, width: "100%", background: `url(${this.state.listingInfo.large[index].path})`,
            backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: { xs: "100% 180px", sm: "100% 350px", md: "100% 550px", lg: "100% 660px" }
          }}
        >
          <Typography component={"img"} src={this.state.listingInfo.large[index].path} sx={{ display: "none !important" }}
            alt={document.title}
          />
        </Grid>
      );
    });
    return images;
  }

  situationIndicator = () => {
    let situationData = this.state.listingInfo.situation;
    let situations = [];
    let result = "";
    for (let i in situationData) {
      if (i !== "id" && i !== "listing_id") {
        if (parseInt(situationData[i])) {
          situations.push(i)
        } else {
          continue
        }
      }
    }
    for (let i = 0; i < situations.length; i++) {
      for (let j = 0; j < situation.length; j++) {
        if (situations[i] === situation[j].f) {
          result += (situation[j].n + " - ")
        }
      }
    }
    result = result.slice(0, -2);
    return result;
  }

  passedTime = () => {
    if (this.state.listingInfo.publish_time) {
      let listingTime = new Date(this.state.listingInfo.publish_time * 1000).getTime();
      let currentTime = new Date().getTime();
      let publishTime = currentTime - listingTime;
      let minutes = Math.floor(publishTime / 60000);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let remainedTime = 0;
      if (minutes < 1) {
        return "چندلحظه پیش"
      } else if (minutes < 60) {
        return convertToPersianNumber(minutes) + " دقیقه پیش";
      } else if (minutes >= 60 && hours < 24) {
        return convertToPersianNumber(hours) + " ساعت پیش";
      } else if (hours >= 24 && days < 7) {
        return convertToPersianNumber(days) + " روز پیش";
      } else if (days >= 7 && days < 30) {
        remainedTime = Math.floor(days / 7);
        return convertToPersianNumber(remainedTime) + " هفته پیش";
      } else if (days >= 30 && days < 366) {
        remainedTime = Math.floor(days / 30);
        return convertToPersianNumber(remainedTime) + " ماه پیش";
      } else {
        remainedTime = Math.floor(days / 365);
        return convertToPersianNumber(remainedTime) + " سال پیش";
      }
    } else {
      return "فاقد زمان";
    }

  }

  posterLikeButton = () => {
    if (localStorage.getItem('infoUserHomeInja')) {
      let infoUserHomeInja = JSON.parse(localStorage.getItem('infoUserHomeInja'));
      if (infoUserHomeInja.user_type_id === 5 || infoUserHomeInja.user_type_id === 6) {
        this.setState({ openAlert: true, message: "نوع کاربری شما نمی تواند نشان کند", typeAlert: 3 })
      } else {
        this.setState({ showGif: "" })
        Promise.resolve(axios({
          url: baseUrl + 'v1/mark/create',
          method: 'POST',
          headers: { 'Authorization': 'bearer ' + localStorage.getItem('tokenUserHomeInja') },
          data: {
            user_id: infoUserHomeInja.id,
            listing_id: this.state.listingInfo.id,
          }
        }))
          .then((res) => {
            this.state.posterLiked ?
              this.setState({ posterLiked: false, openAlert: true, message: "آگهی از نشان شده ها حذف شد", showGif: "none", typeAlert: 1 })
              : this.setState({ posterLiked: true, openAlert: true, message: "آگهی با موفقیت نشان شد", showGif: "none", typeAlert: 1 })
          })
          .catch((err) => {
            this.setState({ showGif: "none" })
          });
      }
    } else {
      this.setState({ openAuthModal: true });
    }
  }

  openShareModal = () => {
    this.setState({ openShareModal: true })
  }

  onCloseShareModal = () => {
    this.setState({ openShareModal: false })
  }

  onClickCopyLinkShare = () => {
    navigator.clipboard.writeText('https://homeinja.com/Listing/' + this.state.id);
    this.setState({ openShareModal: false, openAlert: true, message: "لینک آگهی با موفقیت کپی شد", typeAlert: 1 })
  }

  openReportModal = () => {
    this.setState({ openReportModal: true })
  }

  onCloseReportModal = () => {
    this.setState({ openReportModal: false, report: null })
  }

  onChangeReport = (event, newValue) => {
    this.setState({ report: newValue });
  }

  onClickReport = () => {
    if (localStorage.getItem('infoUserHomeInja')) {
      if (this.state.report !== null) {
        let infoUserHomeInja = JSON.parse(localStorage.getItem('infoUserHomeInja'));
        let result = {
          user_mobile: infoUserHomeInja.mobile,
          user_id: infoUserHomeInja.id,
          listing_id: this.state.listingInfo.id
        }
        for (let i = 0; i < report.length; i++) {
          let isExist = false;
          if (this.state.report.f === report[i].f) {
            isExist = true;
          }
          if (isExist) {
            result[report[i].f] = 1;
          } else {
            result[report[i].f] = 0;
          }
        }
        this.setState({ isLoading: true, showGif: "" });
        Promise.resolve(axios({
          url: baseUrl + "v1/report/create",
          method: "POST",
          data: result,
          headers: { 'Authorization': 'bearer ' + localStorage.getItem('tokenUserHomeInja') },
        }))
          .then((res) => {
            this.setState({ openAlert: true, message: "گزارش شما با موفقیت ثبت شد.", openReportModal: false, typeAlert: 1 });
          })
          .catch((err) => {
            switch (err.response.status) {
              case 400:
                this.setState({ openAlert: true, message: "درخواست نامعتبر می باشد", typeAlert: 2 });
                break;
              case 409:
                this.setState({ openAlert: true, message: "درخواست شما با تداخل مواجه شده است", typeAlert: 2 });
                break;
              case 500:
                this.setState({ openAlert: true, message: "خطای سرور", typeAlert: 2 });
                break;
              default:
                this.setState({ openAlert: true, message: "سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید", typeAlert: 2 })
                break;
            }
          })
          .finally(() => {
            this.setState({ isLoading: false, showGif: "none" })
          })
      } else {
        this.setState({ openAlert: true, message: "مشکلی انتخاب نشده است", typeAlert: 3 })
      }
    } else {
      this.setState({ openAuthModal: true });
    }
  }

  isLoggedInReport = () => {
    if (localStorage.getItem('infoUserHomeInja')) {
      let infoUserHomeInja = JSON.parse(localStorage.getItem('infoUserHomeInja'));
      if (infoUserHomeInja.user_type_id === 5 || infoUserHomeInja.user_type_id === 6) {
        this.setState({ openAlert: true, message: "نوع کاربری شما نمی تواند گزارش کند", typeAlert: 3 });
      } else {
        if (infoUserHomeInja.mobile === null) {
          this.setState({ openAlert: true, message: "شماره خود را هنوز وارد نکرده اید", typeAlert: 3 });
        } else {
          if (infoUserHomeInja.id === this.state.listingInfo.user_id) {
            this.setState({ openAlert: true, message: "شما نمی توانید برای آگهی خود گزارش ثبت کنید", typeAlert: 3 });
          } else {
            this.setState({ openReportModal: true });
          }
        }
      }
    } else {
      this.setState({ openAuthModal: true })
    }
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  checkFeatures = (field) => {
    if (this.state.listingInfo[field]) {
      let fieldData = this.state.listingInfo[field];
      for (let i in fieldData) {
        if (i !== "id" && i !== "listing_id") {
          if (parseInt(fieldData[i])) {
            return true
          }
        } else {
          continue
        }
      }
      return false;
    } else {
      return false;
    }
  }

  features = (field, vmpField) => {
    let fieldData = this.state.listingInfo[field];
    let haveFeatures = [];
    let result = "";
    for (let i in fieldData) {
      if (i !== "id" && i !== "listing_id") {
        if (parseInt(fieldData[i])) {
          haveFeatures.push(i)
        } else {
          continue
        }
      }
    }
    for (let i = 0; i < haveFeatures.length; i++) {
      for (let j = 0; j < vmpField.length; j++) {
        if (haveFeatures[i] === vmpField[j].f) {
          result += (vmpField[j].n + " - ")
        }
      }
    }
    result = result.slice(0, -2);
    return result;
  }

  similarPosts = () => {
    let result = [];
    this.state.similarInfo.forEach((element, index) => {
      result.push(
        <Grid item xs={4} key={index}>
          <Button component={Link} to={"/listing/" + element.id} target="_blank" disableRipple={true}
            sx={{ textDecoration: "none", color: "black", display: "block", padding: "0", "&:hover": { bgcolor: "transparent" } }}
          >
            <Grid sx={{ borderRadius: "20px", border: "1px solid #eee", textAlign: "center", boxShadow: "none", "&:hover": { boxShadow: "1px 0 13px 0 ##d1d1d1" }, transition: "all 0.3s" }}>
              <Box sx={{
                width: "100%", height: { xs: "200px", sm: "250px", md: "300px", lg: "400px" }, borderRadius: "20px 20px 0 0", boxShadow: "0px 2px 7px 0px #0000002e",
                backgroundImage: element.small && element.small.length > 0 ? `url(${element.small[0].path})` : `url(${noLogoAgancyImage})`, backgroundSize: "cover",
                backgroundRepeat: "no-repeat", backgroundPosition: "center"
              }}
              >
              </Box>
              <Grid sx={{ padding: "4%" }}>
                <Grid>
                  <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block" }}>{this.state.similarInfo[index].city.name} - </Typography>
                  <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block", marginRight: "5px" }}>{this.state.similarInfo[index].neighbourhood.name}</Typography>
                </Grid>

                {this.state.similarInfo[index].show_price === 1 ?
                  this.state.similarInfo[index].transaction_id === 1 ?
                    <>
                      <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block" }}>قیمت:</Typography>
                      <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "500", display: "inline-block", marginRight: "5px" }}>
                        {parseInt(this.state.similarInfo[index].total_price) ?
                          `${convertToPersianNumber(separator(this.state.similarInfo[index].total_price))} تومان`
                          : "توافقی"
                        }
                      </Typography>
                    </>
                    : this.state.similarInfo[index].transaction_id === 2 ?
                      <>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block" }}>رهن:</Typography>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "500", display: "inline-block", marginRight: "5px" }}>
                          {parseInt(this.state.similarInfo[index].mortgage) ?
                            `${convertToPersianNumber(separator(this.state.similarInfo[index].mortgage))} تومان`
                            : "توافقی"
                          }
                        </Typography>
                        <br />
                        <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block" }}>اجاره:</Typography>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "500", display: "inline-block", marginRight: "5px" }}>
                          {parseInt(this.state.similarInfo[index].rent) ?
                            `${convertToPersianNumber(separator(this.state.similarInfo[index].rent))} تومان`
                            : "توافقی"
                          }
                        </Typography>
                      </>
                      : <>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block" }}> پیش خرید:</Typography>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "500", display: "inline-block", marginRight: "5px" }}>
                          {parseInt(this.state.similarInfo[index].prebuy) ?
                            `${convertToPersianNumber(separator(this.state.similarInfo[index].prebuy))} تومان`
                            : "توافقی"
                          }
                        </Typography>
                      </>
                  : <>
                    <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block" }}>قیمت :</Typography>
                    <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "14px", md: "23px" }, fontWeight: "600", display: "inline-block", marginRight: "5px" }}>توافقی</Typography>
                  </>
                }
              </Grid>
            </Grid>
          </Button>
        </Grid>
      )
    });
    return result;
  }

  onClickCopy = () => {
    navigator.clipboard.writeText(this.state.listingInfo.user.mobile);
    this.setState({ openAlert: true, message: "شماره ی تلفن کپی شد" })
  }

  onClickRequestVisit = () => {
    if (localStorage.getItem('infoUserHomeInja')) {
      let infoUserHomeInja = JSON.parse(localStorage.getItem('infoUserHomeInja'));
      if (infoUserHomeInja.mobile === null) {
        this.setState({ openAlert: true, message: "شماره خود را هنوز وارد نکرده اید", typeAlert: 3 });
      } else {
        if (infoUserHomeInja.user_type_id === 5 || infoUserHomeInja.user_type_id === 6) {
          this.setState({ openAlert: true, message: "نوع کاربری شما نمی تواند بازدید ثبت کند", typeAlert: 3 });
        } else {
          this.setState({ showGif: "" });
          Promise.resolve(axios({
            url: baseUrl + 'v1/request_visit/create',
            method: 'POST',
            headers: { 'Authorization': 'bearer ' + localStorage.getItem('tokenUserHomeInja') },
            data: {
              listing_id: this.state.listingInfo.id
            }
          }))
            .then((res) => {
              this.setState({ openAlert: true, message: "درخواست بازدید با موفقیت ثبت شد", showGif: "none", typeAlert: 1 });
            })
            .catch((err) => {
              switch (err.response.status) {
                case 409:
                  this.setState({ openAlert: true, message: "کاربر گرامی برای این آگهی درخواست بازدید ثبت کرده اید", typeAlert: 2 });
                  break;
                default:
                  this.setState({ openAlert: true, message: "درخواست بازدید ثبت نشد لطفا با پشتیبانی تماس حاصل فرمائید", typeAlert: 2 });
              }
            })
            .finally(() => {
              this.setState({ showGif: "none" });
            })
        }
      }
    } else {
      this.setState({ openAuthModal: true });
    }
  }

  setDocumentTitle = (info) => {
    document.title = `${info.transaction.des !== null ? info.transaction.des : ""} - ${info.estate.des !== null ? info.estate.des : ""} - ${convertToPersianNumber(info.area !== null ? info.area : "")} متری  ${info.state.name !== null ? info.state.name : ""} - ${info.neighbourhood.name !== null ? info.neighbourhood.name : ""} - ${convertToPersianNumber(info.bedroom_num !== null ? info.bedroom_num : "")}  خوابه - سال ساخت ${convertToPersianNumber(info.year !== null ? info.year : "")} | هومینجا`
  }

  onClickCallAgent = () => {
    this.setState({ openAuthModal: true });
  }

  setH1 = (info) => {
    document.getElementsByTagName("h1")[0].innerHTML = `${info.transaction.des !== null ? info.transaction.des : ""} - ${info.estate.des !== null ? info.estate.des : ""} -
     ${convertToPersianNumber(info.area !== null ? info.area : "")} متری ${info.year !== null ? convertToPersianNumber(info.year) : ""} - ${info.bedroom_num !== null ? convertToPersianNumber(info.bedroom_num) : ''} خوابه - سند 
     ${info.document.des !== null ? info.document.des : ""} - ${info.current.des !== null ? info.current.des : ""} - ${info.tkitch.des !== null ? info.tkitch.des : ""} -
      ${info.state.name !== null ? info.state.name : ""} - ${info.neighbourhood.name !== null ? info.neighbourhood.name : ""} | هومینجا`
  }

  // setDefaultTags = () => {
  //   document.getElementsByTagName("h1")[0].innerHTML = "خرید - فروش - رهن - اجاره - ملک - مسکن - آپارتمان - خانه - برج - اداری - پنت هاوس - هومینجا";
  //   document.getElementsByTagName('meta')["description"].content = `خرید و فروش ملک مسکونی، تجاری، اداری، برج
  //   ،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد`
  // }

  setMetaTagDesc = (info) => {
    let price = info.transaction.id !== null ? info.transaction.id === 1 ? `خرید: ${info.total_price !== null ? info.total_price : ""}` : `رهن:  ${info.mortgage !== null ? info.mortgage : ""}  -  اجاره: ${info.rent !== null ? info.rent : ""}` : ""
    document.getElementsByTagName('meta')["description"].content = `آگهی - ${info.transaction.des !== null ? info.transaction.des : ""} - ${info.estate.des !== null ? info.estate.des : ""} - ${info.area !== null ? info.area : ""} متری ${price !== null ? price : ""} - ${info.year !== null ? info.year : ""} - ${info.bedroom_num !== null ? info.bedroom_num : ""} خوابه - ${info.current.des !== null ? info.current.des : ""} - آشپزخانه ${info.tkitch.des !== null ? info.tkitch.des : ""} - ${info.state.name !== null ? info.state.name : ""} - ${info.neighbourhood.name !== null ? info.neighbourhood.name : ""} در سایت هومینجا `
  }

  render() {
    return (
      <>
        {
          this.state.listingInfo &&

          <Grid sx={{ display: "none" }}>
            <Typography >
              {
                `نوع معامله: ${this.state.listingInfo.transaction !== null ? this.state.listingInfo.transaction.des : ""} . ${this.state.listingInfo.transaction.id === 1 ? "مبلغ خرید: " + convertToPersianNumber(separator(this.state.listingInfo.total_price !== null ? this.state.listingInfo.total_price : "")) : "مبلغ رهن: " + convertToPersianNumber(separator(this.state.listingInfo.mortgage !== null ? this.state.listingInfo.mortgage : "")) +
                  "." + "مبلغ اجاره: " + convertToPersianNumber(separator(this.state.listingInfo.rent !== null ? this.state.listingInfo.rent : ""))} . استان: ${this.state.listingInfo.state !== null ? this.state.listingInfo.state.name : ""} . شهر: ${this.state.listingInfo.city !== null ? this.state.listingInfo.city.name : ""} . 
                  منطقه: ${this.state.listingInfo.zone !== null ? convertToPersianNumber(this.state.listingInfo.zone.name) : ""} . محله: ${this.state.listingInfo.neighbourhood !== null ? this.state.listingInfo.neighbourhood.name : ""} .
                  موقعیت مکانی: ${this.state.listingInfo.situation !== null ? this.state.listingInfo.situation.eastern === 1 ? "شرقی" : "" : ""} ${this.state.listingInfo.situation !== null ? this.state.listingInfo.situation.north === 1 ? "شمالی" : "" : ""} ${this.state.listingInfo.situation !== null ? this.state.listingInfo.situation.sauth === 1 ? "جنوبی" : "" : ""}
                ${this.state.listingInfo.situation !== null ? this.state.listingInfo.situation.western === 1 ? "غربی" : "" : ""} . سال ساخت: ${this.state.listingInfo.year !== null ? convertToPersianNumber(this.state.listingInfo.year) : ""} . وضعیت ملک: ${this.state.listingInfo.current !== null ? this.state.listingInfo.current.des : ""}
               . نوع سند: ${this.state.listingInfo.document !== null ? this.state.listingInfo.document.des : ""} . اتاق: ${this.state.listingInfo.bedroom_num !== null ? convertToPersianNumber(this.state.listingInfo.bedroom_num) : "ندارد"} . نوع اشپزخانه: ${this.state.listingInfo.tkitch !== null ? this.state.listingInfo.tkitch.des : "ندارد"} . تعداد مستر : ${this.state.listingInfo.master_room_num !== null ? convertToPersianNumber(this.state.listingInfo.master_room_num) : "ندارد"} . 
               طبقه: ${this.state.listingInfo.floor_num !== null ? convertToPersianNumber(this.state.listingInfo.floor_num) : "ندارد"} . متراژ: ${this.state.listingInfo.area !== null ? convertToPersianNumber(this.state.listingInfo.area) : ""} . تعداد آسانسور: ${this.state.listingInfo.elevator !== null ? convertToPersianNumber(this.state.listingInfo.elevator) : "ندارد"} . تعداد پارکینگ: ${this.state.listingInfo.parking_num !== null ? convertToPersianNumber(this.state.listingInfo.parking_num) : "ندارد"} .
               انباری: ${this.state.listingInfo.store_num !== null ? convertToPersianNumber(this.state.listingInfo.store_num) : "ندارد"} . سرویس بهداشتی ایرانی: ${this.state.listingInfo.iranian_wc_num !== null ? convertToPersianNumber(this.state.listingInfo.iranian_wc_num) : "ندارد"} . سرویس بهداشتی فرنگی: ${this.state.listingInfo.french_wc_num !== null ? convertToPersianNumber(this.state.listingInfo.french_wc_num) : "ندارد"} . ${convertToPersianNumber(this.state.listingInfo.description !== null ? this.state.listingInfo.description : "")}`
              }
            </Typography>
            <Typography>
              {`${this.state.listingInfo.transaction.des !== null ? this.state.listingInfo.transaction.des : ""} - ${this.state.listingInfo.estate.des !== null ? this.state.listingInfo.estate.des : ""} -
              ${convertToPersianNumber(this.state.listingInfo.area !== null ? this.state.listingInfo.area : "")} متری ${this.state.listingInfo.year !== null ? convertToPersianNumber(this.state.listingInfo.year) : ""} - ${this.state.listingInfo.bedroom_num !== null ? convertToPersianNumber(this.state.listingInfo.bedroom_num) : ''} خوابه - سند
              ${this.state.listingInfo.document.des !== null ? this.state.listingInfo.document.des : ""} - ${this.state.listingInfo.current.des !== null ? this.state.listingInfo.current.des : ""} - ${this.state.listingInfo.tkitch.des !== null ? this.state.listingInfo.tkitch.des : ""} -
              ${this.state.listingInfo.state.name !== null ? this.state.listingInfo.state.name : ""} - ${this.state.listingInfo.neighbourhood.name !== null ? this.state.listingInfo.neighbourhood.name : ""} | هومینجا`}
            </Typography>
          </Grid>
        }
        <AlertModal open={this.state.openAlert} close={this.oncloseAlert} message={this.state.message} type={this.state.typeAlert} />
        <GifLogo display={this.state.showGif} />
        {this.state.listingInfo ?
          this.checkActiveAndUser() ?
            <Grid>
              <Grid sx={{ marginTop: "60px", padding: { xs: "4%", md: "4% 7%" }, display: "flex", flexDirection: 'column' }}>
                {/*------------modals ------------------*/}
                <AuthModal open={this.state.openAuthModal} close={this.onCloseAuth} type={"L"} />
                <Modal open={this.state.openShareModal} onClose={this.onCloseShareModal}>
                  <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", width: { xs: "200px", md: "400px" }, bgcolor: "white", border: '2px solid #000', padding: { xs: "4%", md: "2%" } }}>
                    <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", textAlign: "center" }}>اشتراک گزاری آگهی</Typography>
                    <Button onClick={this.onClickCopyLinkShare}
                      sx={{ border: "1px solid #eee", borderRadius: "5px", width: "50px", height: "50px", minWidth: "50px", minHeight: "50px", bgcolor: "#00C8E9" }}
                    >
                      <ContentCopyIcon sx={{ color: "black !important", width: "30px" }} />
                    </Button>
                    <Typography sx={{ display: "inline-block", fontFamily: "shabnam", marginRight: "10px" }}>کپی کردن لینک آگهی</Typography>
                  </Box>
                </Modal>
                <Modal open={this.state.openReportModal} onClose={this.onCloseReportModal}>
                  <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", width: { xs: "250px", md: "400px" }, bgcolor: "white", border: '2px solid #000', padding: { xs: "4%", md: "2%" }, textAlign: "center" }}>
                    <Typography sx={{ fontFamily: "shabnam", fontSize: "20px" }}>گزارش آگهی</Typography>
                    <Typography sx={{ fontFamily: "shabnam", fontSize: "15px", margin: "5% 0 2% 0" }}>مواردی که این آگهی مشکل دارد را انتخاب کنید</Typography>
                    <Autocomplete
                      id="report-list"
                      options={report}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField className="textField" {...params} placeholder='لیست مشکلات آگهی' />}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeReport}
                    />
                    <Button variant="contained" onClick={this.onClickReport} disabled={this.state.isLoading}
                      sx={{ fontFamily: "shabnam", margin: "4% 0", bgcolor: "#00C8E9", "&:hover": { bgcolor: "#00C8E9" } }}
                    >
                      ثبت گزارش
                    </Button>
                  </Box>
                </Modal>
                {/*------------slider container ------------------*/}
                <Grid container sx={{ boxShadow: { sx: "none", md: "0px 0px 4px rgba(0, 0, 0, 0.25)" }, borderRadius: { xs: "20px 20px 0 0", md: "0" } }} direction={{ md: "row-reverse" }}>
                  <Grid item xs={12} md={9} sx={{ borderRight: { xs: "none", md: "1px solid #80808052" }, borderRadius: { xs: "20px 20px 0 0", md: "0px 150px 0 0" }, overflow: "hidden" }}>
                    <Grid sx={{ height: { xs: "180px", sm: "350px", md: "550px", lg: "660px" }, width: "100%" }}>
                      <Slider {...this.settingSlider}>
                        {this.state.listingInfo.status_id === 5 ?
                          <Grid sx={{
                            height: { xs: "180px", sm: "350px", md: "550px", lg: "660px" }, width: "100%", backgroundImage: `url(${ArchivedPhoto})`, backgroundRepeat: "no-repeat",
                            backgroundPosition: "center", backgroundSize: "cover"
                          }}></Grid>
                          : this.state.listingInfo.large.length > 0 ?
                            this.sliderImages()
                            : <Grid sx={{
                              height: { xs: "180px", sm: "350px", md: "550px", lg: "660px" }, width: "100%", backgroundImage: `url(${noLogoAgancyImage})`, backgroundSize: "40%",
                              backgroundRepeat: "no-repeat", backgroundPosition: "center"
                            }}></Grid>
                        }
                      </Slider>
                    </Grid>
                    <Grid container sx={{ width: "100%", padding: "1% 4%" }} justifyContent="space-between" alignItems="center">
                      <Grid item xs={6}>
                        <Grid sx={{ display: "inline-flex" }}>
                          <Tooltip title={<Typography sx={{ fontFamily: "shabnam", fontSize: "12px" }}>تاریخ انتشار آگهی</Typography>}>
                            <Grid sx={{ display: "inline-flex", alignItems: "center" }}>
                              <AccessAlarmsRoundedIcon sx={{ fontSize: { xs: "15px", md: "16px" } }} />
                              <Typography sx={{ fontSize: { xs: "10px", md: "15px" }, fontFamily: "shabnam", color: "rgba(20, 201, 232, 1)", marginRight: "5px", cursor: "default" }}>
                                {this.passedTime()}
                              </Typography>
                            </Grid>
                          </Tooltip>
                        </Grid>
                        <Grid sx={{ display: "inline-flex", alignItems: "center", marginRight: "10px" }}>
                          <Tooltip title={<Typography sx={{ fontFamily: "shabnam", fontSize: "12px" }}>تعداد بازدید آگهی</Typography>}>
                            <Grid sx={{ display: "inline-flex", alignItems: "center" }}>
                              <RemoveRedEyeRoundedIcon sx={{ fontSize: { xs: "15px", md: "16px" } }} />
                              <Typography sx={{ fontSize: { xs: "10px", md: "15px" }, fontFamily: "shabnam", color: "rgba(20, 201, 232, 1)", marginRight: "5px", cursor: "default" }}>
                                {this.state.listingInfo.view ? convertToPersianNumber(this.state.listingInfo.view) : ""}
                              </Typography>
                            </Grid>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {this.state.listingInfo.status_id === 5 ?
                          <Grid>
                            <Typography sx={{ fontFamily: "shabnam", color: "rgba(20, 201, 232, 1)", fontWeight: "bold", fontSize: { xs: "12px", md: "16px" } }}>
                              آگهی بایگانی شده است
                            </Typography>
                          </Grid>
                          : this.state.posterLiked ?
                            <Tooltip title={<Typography sx={{ fontFamily: "shabnam", fontSize: "12px" }}>غیرفعال کردن نشان</Typography>}>
                              <Box onClick={this.posterLikeButton}
                                sx={{ border: "1px solid #eee", borderRadius: "50%", width: { xs: "25px", md: "5ّ0px" }, height: { xs: "25px", md: "50px" }, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                              >
                                <FavoriteIcon sx={{ fontSize: { xs: "15px", md: "30px" }, color: "black !important" }} />
                              </Box>
                            </Tooltip>
                            : <Tooltip title={<Typography sx={{ fontFamily: "shabnam", fontSize: "12px" }}>نشان کردن آگهی</Typography>}>
                              <Box onClick={this.posterLikeButton}
                                sx={{ border: "1px solid #eee", borderRadius: "50%", width: { xs: "25px", md: "50px" }, height: { xs: "25px", md: "50px" }, display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                              >
                                <FavoriteBorderSharpIcon sx={{ fontSize: { xs: "15px", md: "30px" }, color: "black !important" }} />
                              </Box>
                            </Tooltip>
                        }
                        {this.state.listingInfo.status_id === 5 ?
                          <Grid></Grid>
                          : <>
                            <Grid sx={{ marginRight: "4%", cursor: "pointer" }} onClick={this.openShareModal}>
                              <Tooltip title={<Typography sx={{ fontFamily: "shabnam", fontSize: "12px" }}>اشتراک گذاری</Typography>}>
                                <Box sx={{ border: "1px solid #eee", borderRadius: "50%", width: { xs: "25px", md: "50px" }, height: { xs: "25px", md: "50px" }, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <ShareOutlinedIcon sx={{ fontSize: { xs: "15px", md: "30px" }, color: "black !important" }} />
                                </Box>
                              </Tooltip>
                            </Grid>
                            <Grid sx={{ marginRight: "4%", cursor: "pointer" }} onClick={this.isLoggedInReport}>
                              <Tooltip title={<Typography sx={{ fontFamily: "shabnam", fontSize: "12px" }}>گزارش آگهی</Typography>}>
                                <Box sx={{ border: "1px solid #eee", borderRadius: "50%", width: { xs: "25px", md: "50px" }, height: { xs: "25px", md: "50px" }, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <ReportProblemOutlinedIcon sx={{ fontSize: { xs: "15px", md: "30px" }, color: "black !important" }} />
                                </Box>
                              </Tooltip>
                            </Grid>
                          </>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid container direction="row" sx={{ padding: { xs: "0 6%", md: "6% 6%", color: this.state.listingInfo.status_id === 5 ? "#c7c7c7" : "black" } }} alignItems="center">
                      <Typography component={"img"} src={locationIcon} alt="location icon" sx={{ width: { xs: "20px", md: "25px" }, height: "auto", margin: { xs: "3%", md: "1%" } }}></Typography>
                      <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontFamily: "shabnam", fontWeight: "600" }}>آدرس :</Typography>
                      <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontFamily: "shabnam", fontWeight: "600", marginRight: "3px" }}>
                        {this.state.listingInfo.city.name} -
                      </Typography>
                      <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontFamily: "shabnam", fontWeight: "600", marginRight: "5px" }}>
                        {this.state.listingInfo.neighbourhood.name}
                      </Typography>
                    </Grid>
                    <Grid sx={{ paddingRight: { xs: "8%", md: "14%" } }}>
                      {this.state.listingInfo.show_price ?
                        this.state.listingInfo.transaction_id === 1 ?
                          <Grid container direction={"row"} alignItems="center" sx={{ color: this.state.listingInfo.status_id === 5 ? "#c7c7c7" : "rgba(0, 206, 252, 1)", marginBottom: "10%" }}>
                            <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontWeight: "600", fontFamily: "shabnam", marginLeft: "5px" }}>قیمت: </Typography>
                            <Typography
                              sx={{ fontFamily: "shabnam", fontSize: { xs: "15px", md: "20px" }, fontWeight: "600" }}
                            >
                              {parseInt(this.state.listingInfo.total_price) ?
                                `${convertToPersianNumber(separator(this.state.listingInfo.total_price))} تومان`
                                : "توافقی"
                              }
                            </Typography>
                          </Grid>
                          : this.state.listingInfo.transaction_id === 2 ?
                            <Grid sx={{ color: this.state.listingInfo.status_id === 5 ? "#c7c7c7" : "rgba(0, 206, 252, 1)", marginBottom: "10%" }}>
                              <Grid container direction={"row"} alignItems="center">
                                <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontWeight: "600", fontFamily: "shabnam", marginLeft: "5px" }}> رهن: </Typography>
                                <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "15px", md: "20px" }, fontWeight: "600" }}>
                                  {parseInt(this.state.listingInfo.mortgage) ?
                                    `${convertToPersianNumber(separator(this.state.listingInfo.mortgage))} تومان`
                                    : "توافقی"
                                  }
                                </Typography>
                              </Grid>
                              <Grid container direction={"row"} alignItems="center">
                                <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontWeight: "600", fontFamily: "shabnam", marginLeft: "5px" }}> اجاره: </Typography>
                                <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "15px", md: "20px" }, fontWeight: "600" }}>
                                  {parseInt(this.state.listingInfo.rent) ?
                                    `${convertToPersianNumber(separator(this.state.listingInfo.rent))} تومان`
                                    : "توافقی"
                                  }
                                </Typography>
                              </Grid>
                            </Grid>
                            : this.state.listingInfo.transaction_id === 3 ?
                              <Grid container direction={"row"} alignItems="center" sx={{ color: this.state.listingInfo.status_id === 5 ? "#c7c7c7" : "rgba(0, 206, 252, 1)", marginBottom: "10%" }}>
                                <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontWeight: "600", fontFamily: "shabnam", marginLeft: "5px" }}>پیش خرید: </Typography>
                                <Typography price={parseInt(this.state.listingInfo.prebuy)} sx={{ fontFamily: "shabnam", fontSize: { xs: "15px", md: "20px" }, fontWeight: "600" }}>
                                  {parseInt(this.state.listingInfo.prebuy) ?
                                    `${convertToPersianNumber(separator(this.state.listingInfo.prebuy))} تومان`
                                    : "توافقی"
                                  }
                                </Typography>
                              </Grid>
                              : <Grid></Grid>
                        : <Grid container direction={"row"} alignItems="center" sx={{ color: this.state.listingInfo.status_id === 5 ? "#c7c7c7" : "rgba(0, 206, 252, 1)", marginBottom: "10%" }}>
                          <Typography sx={{ fontSize: { xs: "15px", md: "20px" }, fontWeight: "600", fontFamily: "shabnam", marginLeft: "5px" }}>قیمت:</Typography>
                          <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "15px", md: "20px" }, fontWeight: "600" }}>توافقی</Typography>
                        </Grid>
                      }
                    </Grid>
                    <Grid container sx={{ paddingRight: "10%", paddingBottom: "7%", color: this.state.listingInfo.status_id === 5 ? "#c7c7c7" : "black" }} rowSpacing={{ xs: 1, md: 0.5, lg: 1 }}>
                      <Grid item container direction={"row"}>
                        <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>نوع معامله: </Typography>
                        <Typography sx={{ fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>
                          {this.state.listingInfo.transaction.des ? this.state.listingInfo.transaction.des : " "}
                        </Typography>
                      </Grid>
                      {this.state.listingInfo.estate.des !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>نوع ملک:</Typography>
                          <Typography sx={{ fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{this.state.listingInfo.estate.des}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.area !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>متراژ:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.area)}</Typography>
                          <Typography sx={{ fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>متر</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.year !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>سال ساخت:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.year)}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.bedroom_num !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>اتاق:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.bedroom_num)}</Typography>
                          <Typography sx={{ fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>خوابه</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.floor_num !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>طبقه:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>
                            {this.state.listingInfo.floor_num === "0" ?
                              "همکف"
                              : convertToPersianNumber(this.state.listingInfo.floor_num)
                            }
                          </Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.document !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>نوع سند:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{this.state.listingInfo.document.des}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.current !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>وضعیت کنونی ملک:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{this.state.listingInfo.current.des}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.situation !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>موقعیت ملک:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{this.situationIndicator()}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.tkitch !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>نوع آشپزخانه:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{this.state.listingInfo.tkitch.des}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.bath_num !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>تعداد حمام:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.bath_num)}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.iranian_wc_num !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>تعداد سرویس بهداشتی ایرانی:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.iranian_wc_num)}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.french_wc_num !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>تعداد سرویس بهداشتی فرنگی:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.french_wc_num)}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                      {this.state.listingInfo.master_room_num !== null ?
                        <Grid item container direction={"row"}>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>تعداد خواب مستر:</Typography>
                          <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontWeight: "700", fontSize: { xs: "13px", lg: "16px" } }}>{convertToPersianNumber(this.state.listingInfo.master_room_num)}</Typography>
                        </Grid>
                        : <Grid></Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                {/*------------ feature icon and feature ------------------*/}
                {this.state.listingInfo.status_id === 5 ?
                  <Grid></Grid>
                  :
                  <Grid container direction={{ md: "row-reverse" }} >
                    {/*------------  feature icons ------------------*/}
                    <Grid item xs={12} md={9}>
                      <Grid container sx={{ padding: "2%" }}>
                        <Grid item container xs={12} md={6}>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={kitchen} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: this.checkFeatures("kitchen") ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={cctvIcon} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: this.checkFeatures("safety") ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={welfareIcon} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: this.checkFeatures("welfare") ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={condition} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: this.checkFeatures("condition") ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6}>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={parkingIcon} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: parseInt(this.state.listingInfo.parking_num) ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={elevatorIcon} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: parseInt(this.state.listingInfo.elevator) ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={warehouseIcon} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: parseInt(this.state.listingInfo.store_num) ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3} sx={{ padding: { xs: "5px", md: "10px" } }}>
                            <Box sx={{ bgcolor: "#F1F2F2", borderRadius: "4px" }}>
                              <Typography component={"img"} src={bedroom} alt="feature"
                                sx={{ width: "100%", height: "auto", opacity: parseInt(this.state.listingInfo.bedroom_num) ? "1" : "0.3" }}
                              ></Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/*------------  features ------------------*/}
                      <Grid container sx={{ padding: { xs: "0", md: "3%" } }}>
                        {this.checkFeatures("kitchen") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", marginLeft: { xs: "5px", md: "15px" } }}>امکانات آشپزخانه:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, marginLeft: "15px" }}>{this.features("kitchen", kitchenEq)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                        {this.checkFeatures("welfare") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0", width: "100%" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", width: { md: "25%", lg: "20%", xl: "14%" } }}>امکانات رفاهی:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, width: { xs: "75%", lg: "80%", xl: "86%" }, marginRight: { xs: "10px", md: "0" }, textAlign: "right" }}>{this.features("welfare", welfare)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                        {this.checkFeatures("safety") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0", width: "100%" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", width: { md: "25%", lg: "20%", xl: "15%" } }}>امکانات امنیتی:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, flexWrap: "wrap", marginRight: { xs: "10px", md: "0" }, width: { xs: "75%", lg: "80%", xl: "86%" }, textAlign: "right" }}>{this.features("safety", safety)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                        {this.checkFeatures("condition") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", marginLeft: "15px" }}>تهویه مطبوع:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, marginLeft: "15px" }}>{this.features("condition", cool_heat)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                        {this.checkFeatures("facade") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", marginLeft: "15px" }}>نمای ساختمان:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, marginLeft: "15px" }}>{this.features("facade", facade)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                        {this.checkFeatures("cover") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", marginLeft: "15px" }}>کفپوش ساختمان:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, marginLeft: "15px" }}>{this.features("cover", cover)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                        {this.checkFeatures("cabinet") ?
                          <Grid item xs={12} sx={{ display: "flex", borderBottom: "1px solid rgba(196, 196, 196, 0.7)", padding: "1% 0" }}>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600", marginLeft: "15px" }}>نوع کابینت:</Typography>
                            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, marginLeft: "15px" }}>{this.features("cabinet", kitchenCabinet)}</Typography>
                          </Grid>
                          : <Grid></Grid>
                        }
                      </Grid>
                    </Grid>
                    {/*------------ profile ------------------*/}
                    <Grid item xs={12} md={3} sx={{ padding: { xs: "7% 0", md: "2% 0" } }}>
                      <Grid container sx={{ boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)", minHeight: "100%", borderRadius: { xs: "4px 40px 4px 4px", md: "4px" } }}>
                        <Grid item xs={4} md={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5% 0" }}>
                          <Box sx={{
                            display: "flex", justifyContent: "center", alignItems: "center", width: { xs: "87px", md: "185px", lg: "215px" }, height: { xs: "87px", md: "185px", lg: "215px" }, borderRadius: "50%",
                            background: "linear-gradient(270deg, rgba(255,255,255,1) 50%, rgba(20,201,232,1) 50%, rgba(20,201,232,1) 100%)"
                          }}
                          >
                            <Box title="avatar"
                              sx={{
                                width: { xs: "80px", md: "170px", lg: "200px" }, height: { xs: "80px", md: "170px", lg: "200px" }, borderRadius: "50%",
                                background:
                                  this.state.listingInfo.user.agency_id ?
                                    this.state.listingInfo.user.agency.logo_path ?
                                      `url(${this.state.listingInfo.user.agency.logo_path})`
                                      : `white url(${Avatar})`
                                    : this.state.listingInfo.user.picture_path ?
                                      `url(${this.state.listingInfo.user.picture_path})`
                                      : `white url(${Avatar})`,
                                backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"
                              }}
                            >
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={8} md={12}>
                          {this.state.listingInfo.user.user_type_id === 1 || this.state.listingInfo.user.user_type_id === 2 ?
                            <Grid sx={{ display: "flex", justifyContent: "center", paddingTop: { xs: "5%", md: "0" } }}>
                              <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontSize: { xs: "12px", md: "13px", lg: "20px" }, fontWeight: "700" }}>صاحب ملک :</Typography>
                              <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "12px", md: "13px", lg: "20px" }, fontWeight: "700" }}>{this.state.listingInfo.user.name} {this.state.listingInfo.user.lastname}</Typography>
                            </Grid>
                            : this.state.listingInfo.user.user_type_id === 3 ?
                              <Grid sx={{ display: "flex", justifyContent: "center", paddingTop: { xs: "5%", md: "0" } }}>
                                <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontSize: { xs: "12px", md: "13px", lg: "20px" }, fontWeight: "700" }}>مشاور ملک :</Typography>
                                <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "12px", md: "13px", lg: "20px" }, fontWeight: "700" }}>{this.state.listingInfo.user.name} {this.state.listingInfo.user.lastname}</Typography>
                              </Grid>
                              : this.state.listingInfo.user.user_type_id === 4 ?
                                <Grid sx={{ display: "flex", justifyContent: "center", paddingTop: { xs: "5%", md: "0" } }}>
                                  <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontSize: { xs: "12px", md: "13px", lg: "20px" }, fontWeight: "700" }}>مشاور هومینجا :</Typography>
                                  <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "12px", md: "13px", lg: "20px" }, fontWeight: "700" }}>{this.state.listingInfo.user.name} {this.state.listingInfo.user.lastname}</Typography>
                                </Grid>
                                : <Grid></Grid>
                          }
                          {this.state.listingInfo.user.agency_id !== null ?
                            <Grid sx={{ display: "flex", justifyContent: "center", padding: { xs: "15px 0", md: "0" } }}>
                              <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" } }}>آژانس :</Typography>
                              <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, fontWeight: "700" }}>{this.state.listingInfo.user.agency.name}</Typography>
                            </Grid>
                            : <Grid></Grid>
                          }
                          {this.state.listingInfo.listing_number !== null ?
                            <Grid sx={{ display: "flex", justifyContent: "center", padding: { xs: "0 0 15px 0", md: "0" } }}>
                              <Typography sx={{ marginLeft: "5px", fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" } }}>کد ملک :</Typography>
                              <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, fontWeight: "700" }}>{convertToPersianNumber(this.state.listingInfo.listing_number)}</Typography>
                            </Grid>
                            : <Grid></Grid>
                          }
                          {this.state.listingInfo.user.user_type_id === 1 || this.state.listingInfo.user.user_type_id === 2 || this.state.listingInfo.user.user_type_id === 4 || this.state.listingInfo.user.user_type_id === 5 ?
                            <Grid sx={{ display: "flex", justifyContent: "center", padding: { xs: "0 0 4% 0", md: "20% 0 10% 0" } }}>
                              <Button variant="contained" onClick={this.onClickRequestVisit}
                                sx={{ fontFamily: "shabnam", fontSize: "15px", fontWeight: "600", color: "black", bgcolor: "rgba(20, 201, 232, 1)", padding: "3% 10%", "&:hover": { background: "rgba(20, 201, 232, 1)" } }}>
                                درخواست بازدید
                              </Button>
                            </Grid>
                            : this.userInfo ?
                              <Grid sx={{ display: "flex", justifyContent: "center", padding: { xs: "0 0 4% 0", md: "20% 0 10% 0" } }}>
                                <Grid sx={{ display: "flex", alignItems: "center", width: { xs: "170px", lg: "250px" } }}>
                                  <Typography
                                    sx={{ border: "2px solid #00C8E9", borderRadius: "0 6px 6px 0", height: { xs: "36px", md: "46px" }, display: "inline-flex", justifyContent: "center", alignItems: "center", bgcolor: "#CCF4FB", width: "100%", fontFamily: "shabnam" }}
                                  >
                                    {convertToPersianNumber(this.state.listingInfo.user.mobile ? this.state.listingInfo.user.mobile : "")}
                                  </Typography>
                                  <Button onClick={this.onClickCopy}
                                    sx={{ bgcolor: "#00C8E9", "&:hover": { bgcolor: "#00C8E9" }, borderTopRightRadius: "0", borderBottomRightRadius: "0", height: { xs: "40px", md: "50px" } }}
                                  >
                                    <ContentCopyIcon sx={{ color: "black !important", width: { xs: "20px", md: "30px" } }} />
                                  </Button>
                                </Grid>
                              </Grid>
                              : <Grid sx={{ display: "flex", justifyContent: "center", padding: { xs: "0 0 4% 0", md: "20% 0 10% 0" } }}>
                                <Grid sx={{ display: "flex", alignItems: "center", width: { xs: "170px", lg: "250px" } }}>
                                  <Button onClick={this.onClickCallAgent}
                                    sx={{
                                      bgcolor: "#00C8E9", "&:hover": { bgcolor: "#00C8E9" }, height: { xs: "40px", md: "50px" }, width: "100%",
                                      fontFamily: "shabnam", color: "black", fontWeight: "bold"
                                    }}
                                  >
                                    تماس با مشاور
                                    <CallRoundedIcon sx={{ color: "black !important", width: { xs: "20px", md: "30px" } }} />
                                  </Button>
                                </Grid>
                              </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {/*------------ description ------------------*/}
                {this.state.listingInfo.status_id === 5 ?
                  <Grid></Grid>
                  : this.state.listingInfo.description ?
                    <>
                      <Grid sx={{ padding: "1% 4%" }}>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "22px" }, fontWeight: "600" }}>توضیحات تکمیلی :</Typography>
                      </Grid>
                      <Grid sx={{ padding: "1% 4%", boxShadow: { xs: "none", md: "0px 0px 4px rgb(0 0 0 / 25%)" } }}>
                        <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "13px", md: "20px" }, fontWeight: "600", textAlign: "justify" }}>{this.state.listingInfo.description}</Typography>
                      </Grid>
                    </>
                    : <Grid></Grid>
                }
                {/*------------ map ------------------*/}
                {this.state.listingInfo.status_id === 5 ?
                  <Grid></Grid>
                  : this.state.listingInfo.latitude !== null && this.state.listingInfo.longitude !== null ?
                    <Grid sx={{ height: { xs: "200px", md: "400px" }, width: "100%", borderRadius: { xs: "0 0 0 40px", md: "0 0 0 150px" }, overflow: "hidden", margin: { xs: "20px 0", md: "30px 0" } }}>
                      <ShowLocation position={[Number(this.state.listingInfo.latitude), Number(this.state.listingInfo.longitude)]} />
                    </Grid>
                    : <Grid></Grid>
                }
                {/*------------ similar posters ------------------*/}
                {this.state.similarInfo ?
                  <Grid>
                    <Grid sx={{ textAlign: "center", margin: "3% 0" }}>
                      <Typography
                        sx={{
                          display: "inline-block", padding: "0 20px", borderRight: "3px solid rgba(1, 167, 196, 1)", borderLeft: "3px solid rgba(1, 167, 196, 1)",
                          fontFamily: "shabnam", fontSize: { xs: "14px", md: "25px" }, fontWeight: "700"
                        }}>
                        آگهی های مشابه
                      </Typography>
                    </Grid>
                    <Grid container spacing={{ xs: 2, md: 1, lg: 2, xl: 8 }} columns={{ xs: 4, md: 12 }} justifyContent="center">
                      {this.similarPosts()}
                    </Grid>
                  </Grid>
                  : <Grid></Grid>
                }
              </Grid>
            </Grid>
            : <Grid sx={{ marginTop: "60px", padding: "8% 0", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <HideImageRoundedIcon sx={{ fontSize: "60px", margin: "10px 0" }} />
              <Typography sx={{ fontFamily: "shabnam", textAlign: "center", fontWeight: "bold", fontSize: "25px" }}>
                آگهی مورد نظر غیرفعال می‌باشد
              </Typography>
            </Grid>
          : <Grid></Grid>
        }
      </>
    );
  }
}

export default Listing;