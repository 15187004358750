import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article47/01.jpg";
import photo02 from "../../images/mag/article47/02.jpg";
import photo03 from "../../images/mag/article47/03.jpg";
import photo04 from "../../images/mag/article47/04.jpg";
import photo05 from "../../images/mag/article47/05.jpg";
import photo06 from "../../images/mag/article47/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article47 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "در این مقاله از هومینجا به این مطلب می پردازیم که زنان مشاور املاک چه نکاتی را باید رعایت کنند تا در حرفه خود بعنوان بهترین ظاهر شوند.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "برترین مشاور املاک خانم | موفق ترین مشاور املاک زن | راز موفقیت بهترین زنان مشاور املاک | مهارت های زنان موفق مشاور املاک | بهترین مشاور املاک زن "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[46].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[46].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[46].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[46].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[46].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[46].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="مشاور املاک زن - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[46].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد مشاور املاک را کاری مردانه می دانند اما زنان نیز در این حوزه فعالیت دارند و بسیار موفق نیز ظاهر شدند. سوال اینجاست که زنان <Link className="link" to="/real-estate" target="_blank"> مشاور املاک </Link> باید چه نکاتی را رعایت کنند تا بتوانند در این شغل مردانه موفق شوند؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در <Link className="link" to="/" target="_blank">  هومینجا </Link> به بررسی تعدادی از این نکات می پردازیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                نکات مهم برای زنان مشاور املاک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="زنان مشاور املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  زنان مشاور املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                موارد زیر برای تبدیل یک زن به مشاور املاک <Link className="link" to="/professional-real-estate" target="_blank">  حرفه ای </Link> موفق نیاز است.
              </Typography>
              <Typography className="h3" variant="h3">
                ریسک پذیر بودن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانم ها ریسک پذیری کمتری در کار دارند اما برای موفقیت در این حرفه باید قدرت ریسک پذیری را تقویت کرد. زمانی که اهداف بزرگ را در نظر بگیرید در این صورت قدرت انجام معاملات بزرگ را نیز خواهید داشت و به درامد بالایی دست پیدا می کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید :کد رهگیری املاک چیست ؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                اعتماد به نفس بالا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اعتماد به نفس یکی از ملزومات این کار می باشد. زمانی که مشتری حس کند که شما اعتماد به نفس کافی را ندارید دیگر نمی تواند به شما اعتماد کند. پس همیشه سعی کنید در برخورد با مشتری با اعتماد به نفس کافی ظاهر شوید.
              </Typography>
              <Typography className="h3" variant="h3">
                آموزش صحیح
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ‌آموزش درست مهم‌ترین نکته موفقیت در حوزه املاک و مستغلات می باشد. آشنایی با روش‌های جدید بازاریابی و فروش مانند دیجیتال مارکتینگ، اصول و ترفندهای مذاکره، ارزیابی املاک از آموزش های لازم جهت دستیابی به موفقیت می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین مقالات حوزه املاک و مستغلات را در <Link className="link" to="/HomeMag" target="_blank">  بلاگ </Link> هومینجا دنبال کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                ظاهر مناسب
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اولین چیزی که مشتری در برخورد با مشاور به چشمش می آید ظاهر اوست. پس همیشه ظاهری مناسب داشته باشید. بهترین ظاهر، لباس رسمی و آرایش عرف می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                داشتن گوش شنوا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مشاور خوب باید شنونده خوبی نیز باشد. باید به دقت به حرف های مشتری گوش دهید و به نیازهای مشتری توجه کنید و هیچگاه حرف مشتری را قطع نکنید. مشاورین حرفه‌ای از این اطلاعات برای شناخت شخصیت مشتری خود استفاده می کنند. چنانچه شخصیت مشتری و نیاز های مشتری را به خوبی بشناسید، می توانید بهترین ملک را به مشتری خود پیشنهاد دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                یادگیری و بروز بودن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="بروز بودن بانوان مشاور املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بروز بودن بانوان مشاور
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه همه چیز در حال تغییر است پس بهتر است که بروز باشید. یکی از راه های پیشرفت کردن یادگیری و مطالعه است. هر چه علم شما در این زمینه بیشتر باشد مشتری های بیشتری به شما مراجعه می کنند. بنابراین در طول روز حتما ساعتی را به مطالعه اختصاص دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                صبور بودن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از نقاط قوت خانم ها صبور بودن در امور می باشد. از این خصوصیت نیز در برخورد با مشتریان استفاده کنید. مشتری زمان انجام معامله امکان دارد دچار تردید شود. بنابراین مشاور املاک حرفه ای باید در مقابل مشتری صبوری پیشه کند و سعی نکند یک معامله را به سرعت به قرارداد ختم کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-fraud" target="_blank">
                  بیشتر بخوانید : انواع کلاهبرداری در معاملات املاک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                پیگیر بودن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است هر روز با مشتری در ارتباط باشید تا اسم مشاور در ذهن مشتری حک شود و مشتری بداند که مشاور برایش وقت می‌گذارد.
              </Typography>
              <Typography className="h3" variant="h3">
                جلب اعتماد مشتری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اعتماد مشتری مهمترین بخش کار مشاور املاک می باشد. مشاورین خانم به دلیل نداشتن سابقه بد در شغل مشاور املاک و روحیه لطیف و صادقانه ای که دارند بهتر اعتماد مشتری را بدست می آورند.
              </Typography>
              <Typography className="h3" variant="h3">
                زبان بدن و فنون مذاکره
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این دو اصل از اصول مهم برای هر معامله یا حتی زندگی روزمره می باشد. زبان بدن باعث تاثیرگذاری بیشتر در مشتری می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                دلایل موفقیت زنان در مشاور املاک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="دلایل موفقیت زنان مشاور - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دلایل موفقیت زنان مشاور
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در سال‌های اخیر خانم‌ها نیز مانند آقایان در زمینه مشاور املاک فعال هستند و طبق تحققات انجام شده موفق تر از آقایان ظاهر شده اند. خیلی از افراد تمایل دارند دلیل این موفقیت را بدانند. ما در زیر به بررسی این دلایل می پردازیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                1- اعتماد بیشتر به خانم‌ها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه در سال‌های اخیر آمار تقلب، دزدی، اختلاس از جانب مردان زیاد دیده شده بنابراین بی اعتمادی نسبت به آقایان بیشتر است. از آنجا که خرید و فروش ملک کار حساسی است و با سرمایه زیادی سروکار دارد افراد نمی‌توانند به‌راحتی، سرمایه خود را به دست هر کس بسپارند. بنابراین خانم ها بدلیل صداقت در رفتار بهتر می توانند در این حرفه وارد عمل شوند و اعتماد مشتری را بدست آورند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                2- قدرت بیان بالا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در امر مشاور املاک قدرت بیان از اهمیت بالایی برخوردار است. از آنجا که خانم‌ها نسبت به آقایان قدرت بیان بالاتری دارند بنابراین موفق تر نیز ظاهر می شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                3- اجتماعی‌تر بودن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مزیت های خانم ها نسبت به آقایان اجتماعی بودن آنهاست. خوش برخورد بودن و پشتکار زنان باعث جذب مشتری و پایبند بودنشان می شود و این یکی از دلایل دیگر موفق بودن خانم ها در این حرفه می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                4- صبوری و پشتکار
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لازمه موفقیت در این حرفه صبوری بالاست. خانم‌ها آستانه تحمل بالایی در زمینه مشاور املاک دارند. زمانی که مشتری برای خرید یا فروش ملک به املاک مراجعه می‌کند، هرآنچه لازم است باید برای او توضیح داده شود. کارشناسان مرد سعی می کنند جلسه را زودتر به اتمام برسانند و غالباً در بیان مطالب، سطح بالا صحبت می‌کنند به گونه‌ای که مشتری متوجه موضوع نمی‌شود. برخلاف آقایان، خانم‌ها این قدرت را دارند که نحوه بیان خود را متناسب با سطح آگاهی مشتری تغییر دهند در نتیجه بهتر با مشتری ارتباط برقرار می کنند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/building-certificate" target="_blank">
                  بیشتر بخوانید : همه چیز راجع به صدور پروانه ساختمان
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                5- قدرت مدیریتی بالا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این موضوع را باید در نظر داشت که خانم‌ها نسبت به آقایان مدیران بهتری هستند و همین ویژگی باعث موفقیت بیشتر آن‌ها در زمینه مشاور املاک شده است. با توجه به اینکه در این حرفه روزانه با مشتریان زیادی مواجه هستید بنابراین نیاز به یک برنامه ریزی دقیق هست لذا  بانوان مشاور املاک شرایط بهتری را برای اداره امور فراهم می کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                6- نظم کاری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نظم کاری و مسئولیت‌پذیری یکی از ملزومات شغل مشاور املاک می باشد. خانم‌ها بدلیل منظم بودن عملکرد بهتری در این زمینه دارند. مشاور املاک خانم تمام کارها را طبق برنامه ریزی انجام می‌دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                سختی های کار مشاور املاک برای زنان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با اینکه خانم ها به علت خصوصیاتی مانند خوش‌برخوردی و اجتماعی بودن و حوصله بالا در ارائه اطلاعات در این کار موفق تر هستند اما این حرفه برای زنان مشکلاتی را نیز بهمراه دارد که در زیر به برخی از آن ها می پردازیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بازار مسکن همیشه با نوسان همراه است بنابراین ممکن است مدت زمان زیادی طول بکشد تا به پول دست یابید. گاهی نیز بازار راکد است و معاملات زیادی انجام نمی شود. بنابراین نباید ناامید شد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                از دیگر سختی های این شغل استرس زیادی است که با آن مواجه می شوید. باید زمان و انرژی زیادی برای جذب مشتری صرف کرد که این‌ کار برای بانوان کمی سخت‌تر است بنابراین بانوانی که در این شغل فعال هستند باید بنیه و ظرفیت کافی را برای این شغل داشته باشند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در کار املاک با رقبای زیادی روبرو هستید. هرچه تعداد این رقبا بیشتر باشد کار نیز سخت‌تر می شود. بنابراین باید آن‌ها را آنالیز کنید و  بدرستی با رقبا رفتار کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط صدور مجوز املاک برای خانم‌ها
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="صدور مجوز املاک بانوان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  صدور مجوز املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                صدور <Link className="link" to="/Real-Estate-Agency-License" target="_blank"> مجوز املاک </Link> برای خانم ها نیز مانند مجوز آقایان قانونی می باشد. اما می بایست یک شرط در آن رعایت شود و آن هم معرفی مردی به عنوان مباشر است. بقیه شرایط دریافت مجوز املاک برای خانم ها و آقایان تفاوتی ندارد و شامل موارد زیر می باشد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                داشتن مدرک دیپلم و حداقل 18 سال سن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حداقل متراژ 12 متری برای آژانس
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گذراندن دوره‌های آموزشی املاک و مستغلات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                متاهل بودن
              </Typography>
              <Typography className="h2" variant="h2">
                مدارک لازم برای اخذ مجوز املاک خانم ها
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه سند مالکیت محل تعیین شده برای آژانس املاک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مبایعه نامه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل و کپی تمامی صفحات شناسنامه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل و کپی کارت ملی (پشت و رو)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل تصویر گذرنامه و پروانه کار
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کپی مدرک تحصیلی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تاییدیه کد پستی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ۱۲ قطعه عکس پرسنلی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فیش واریز مبلغ تعیین شده
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                موفق ترین زن مشاور املاک دنیا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="جید میلز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  جید میلز
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای خیلی افراد جالب است که با موفق ترین زنان در این حوزه آشنا شوند. بنابراین ما در این مقاله از هومینجا شما را با برترین زن این حوزه آشنا می کنیم.
              </Typography>
              <Typography className="h3" variant="h3">
                جید میلز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                میلز با بیش از سی سال تجربه و فروش بالای 6 میلیارد دلار در عرصه املاک و مستغلات در سطح ملی و بین‌المللی بسیار مطرح می باشد.  خانم میلز بنیانگذار <LinkMui className="link" href="https://jademillsestates.com/" target="_blank">   شرکت “Jade Mills Estates”  </LinkMui> می باشد. او به عنوان یکی از معتمدترین نمایندگان در بازار املاک و مستغلات لس‌آنجلس شناخته شده است. جید میلز نه تنها معروف‌ترین مشاور املاک خانم در دنیا است بلکه حرفه‌‌ای‌ترین مشاور املاک در بین آقایان و بانوان نیز می باشد. جید رکورد بالاترین فروش را هم از آن خود کرده است. مشتریان جید معمولا افراد مشهور <LinkMui className="link" href="https://www.hollywood.com/" target="_blank">  هالیوود </LinkMui> مانند بریتنی اسپیرز، جنیفر آنیستون، وسلا وارد هستند. راز موفقیت جید میلز در صداقت، وفاداری و رابطه عمیق فکری است که با مشتریان برقرار می‌کند. او مشتریان خود را انتخاب می‌کند و این می تواند راز موفقیت مشهورترین مشاور املاک زن در جهان باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                میلز با مشتریان خود بسیار صادق است و در بعضی مواقع با آنکه می تواند سود زیادی از معامله دریافت کند اما مشتری را منصرف می کند. زیرا که معتقد است رضایت مشتری از سودی که دریافت می کند اهمیت بیشتری دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام پایانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مقاله از هومینجا سعی کردیم تا هر آنچه را که لازم است تا یک خانم بداند تا یک مشاور املاک حرفه ای شود بیان کنیم. امید است تا مطالعه این <Link className="link" to="/Successful-Women-Real-Estate" target="_blank">  مطلب </Link> برای شما مفید واقع شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تبدیل به بهترین مشاور املاک زن ایران مقالات زیر را مطالعه بفرمایید :
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/apartment-pre-sale'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/mehr-house'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Business-Right'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    سرقفلی مغازه و تمام نکاتی که باید در نظر گرفت
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Housing-Market-Prediction-1402'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مشاور املاک ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مشاور املاک

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article47;