import { Autocomplete, Button, Checkbox, FormControl, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import React from "react";
import axios from "axios";
import { baseUrl } from "./materilas/vmp";
import AlertModal from "./materilas/alert";

class State extends React.Component{

    isMount = false;

    constructor(props){
      super(props);
      this.state={
        nameState:[],
        nameInput:'',
        idChoose:null,
        active:false,
        openAlert:false,
        message:'',
        stateSearch:null,
        inputState:'',
        typeAlert:2
      }
    }

    componentDidMount(){
      this.isMount = true;
      if (this.isMount){
        this.getState();
      }
    }

    componentWillUnmount(){
      this.isMount = false;
    }

    getState = () =>{
      Promise.resolve(axios({
        url: baseUrl+'v1/state/get',
        method: 'GET',
      }))
      .then((res) => {
          this.setState({nameState:res.data.data})
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
      });
    }

    editState = () =>{
      if (this.state.idChoose !== null){
        if (this.state.nameInput !== ''){
          let token = localStorage.getItem('tokenUserHomeInja');
          Promise.resolve(axios({
            url: baseUrl+'v1/state/edit',
            method: 'POST',
            headers:{'Authorization':'bearer ' + token},
            data:{
              id:this.state.idChoose,
              name:this.state.nameInput,
              active:this.state.active
            }
          }))
          .then((res) => {
              this.setState({nameInput:'',active:false,idChoose:null,stateSearch:null,inputState:'',openAlert:true,message:'ویرایش با موفقیت انجام شد',typeAlert:1})
              this.getState();
          })
          .catch((err) => {
              this.setState({nameInput:'',active:false,idChoose:null,stateSearch:null,inputState:'',openAlert:true,message:err.response.data.message,typeAlert:2}) 
          });
        }
        else{
          this.setState({openAlert:true,message:'فیلد نام نباید خالی باشد',typeAlert:3})
        }
      }
      else{
        this.setState({openAlert:true,message:'موردی جهت ویرایش انتخاب نشده',typeAlert:3})
      }
    }

    saveState = () =>{
      if (this.state.nameInput !== ''){
        let token = localStorage.getItem('tokenUserHomeInja');
          Promise.resolve(axios({
            url: baseUrl+'v1/state/create',
            method: 'POST',
            headers:{'Authorization':'bearer ' + token},
            data:{
              name:this.state.nameInput,
            }
          }))
          .then((res) => {
              this.setState({nameInput:'',active:false,idChoose:null,stateSearch:null,inputState:'',openAlert:true,message:'افزودن با موفقیت انجام شد',typeAlert:1})
              this.getState();
          })
          .catch((err) => {
              this.setState({nameInput:'',active:false,idChoose:null,stateSearch:null,inputState:'',openAlert:true})
              if (err.response.status === 500){
                this.setState({openAlert:true,message:err.response.data.message,typeAlert:2})
              }
              else{
                this.setState({openAlert:true,message:'نام استان تکراری است',typeAlert:3})
              }
          });
      }
      else{
        this.setState({openAlert:true,message:'فیلد نام نباید خالی باشد',typeAlert:3})
      }
    }

    onChangeState = (event,newValue) =>{
      this.setState({nameInput:'',idChoose:null,stateSearch:null,inputState:'',active:false})
      if (newValue !== null){
        this.setState({nameInput:newValue.name,idChoose:newValue.id,active:newValue.active === 1 ? true : false,stateSearch:newValue,inputState:newValue.name})
      }
    }

    onChangeName = (event) =>{
      this.setState({nameInput:event.target.value})
    }

    onChangeActive = () =>{
      this.setState({active:!this.state.active})
    }

    onCloseAlert = () =>{
      this.setState({openAlert:false})
    }

    render() {
      return (
        <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'10px'}}}>
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',marginTop:100,marginBottom:100}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>ویرایش و افزودن استان ها</Typography>
          </Grid>
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center'}} className="employeeAuto">
            <Autocomplete
              id="state_combo"
              className="combo_employee"
              options={this.state.nameState}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField className="textField" {...params} placeholder='استان'/>}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={this.state.stateSearch}
              inputValue={this.state.inputState}
              onChange={this.onChangeState}
              clearText='حذف'
              closeText="بستن"
              openText="باز کردن"
              noOptionsText="فاقد اطلاعات"                              
            />
          </Grid>
          <Grid item xs={12} 
                style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:100,borderStyle:'solid',borderRadius:20,
                        borderColor:'#01C7E9',width:'99%',flexWrap:'wrap'}}>
            <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
              <FormControl sx={{width:{sm:'50ch',xs:'30ch'},justifyContent:'center',alignItems:'center'}}>
                  <OutlinedInput placeholder='نام' inputProps={{maxLength:25}}
                      style={{fontFamily:'shabnam',borderStyle:'solid',borderWidth:2}} 
                      sx={{width:{xs:'80%'},height:50}}
                      onChange={this.onChangeName}
                      value={this.state.nameInput}
                  />
              </FormControl>
            </Grid>
            <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
              <Checkbox checked={this.state.active} onChange={this.onChangeActive}  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}/>
              <p style={{fontSize:'1.5em',fontWeight:'700'}}>{'فعال'}</p>
            </Grid>
            <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
              <Button style={{backgroundColor:'#01C7E9',height:50,width:'50%',fontFamily:'shabnam',color:'black'}} onClick={this.editState}>
                ویرایش استان
              </Button>
            </Grid>
            <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
              <Button style={{backgroundColor:'#01C7E9',height:50,width:'50%',fontFamily:'shabnam',color:'black'}} onClick={this.saveState}>
                افزودن استان
              </Button>
            </Grid>
          </Grid>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.onCloseAlert} type={this.state.typeAlert}/>
        </Grid>
      )
    };
}

export default State;