import { Button, Grid, Menu, MenuItem, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../images/tehranZones/tehran.jpg";
import TehranMap from "./materilas/tehranMap";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { setCanonicalTag } from "./materilas/vmp";
import ShowCityLocation from "../component/materilas/showCityLocation";
import "../styles/tehranZones.css";


class TehranZones extends React.Component {

  prevPositionY = 0;

  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      openLinks: false,
    }
  }

  componentDidMount() {
    document.title = "معرفی مناطق 22 گانه تهران همراه با نقشه و محله های آن | املاک هومینجا"
    document.getElementById("main-heading").innerHTML = "معرفی کامل مناطق 22 گانه شهرداری شهر تهران به همراه عکس، نقشه و آگهی املاک"
    document.getElementsByTagName('meta')["description"].content = "شما در صفحه مربوط به معرفی مناطق 22 شهرداری تهران به همراه عکس و نقشه هستید. هومینجا اطلاعات مفیدی نظیر محله ها و امکانات هر منطقه، آگهی های املاک مربوط به هر منطقه از شهر تهران را در اختیار کاربران گذاشته است."
    document.getElementsByTagName('meta')["keywords"].content = "مناطق تهران به ترتیب | معرفی محله های مناطق تهران | مناطق 22 گانه تهران کدامند | نام مناطق تهران | لیست کامل مناطق تهران | فهرست محله های مناطق 22 گانه | راهنمای جامع مناطق شهر تهران | راهنمای محله های تهران | نقشه مناطق تهران بزرگ | آشنایی با محله های تهران | عکس مناطق تهران | بزرگترین مناطق تهران به ترتیب | نقشه گوگل مناطق تهران | دسته بندی مناطق تهران"
    setCanonicalTag();
  }

  onClickMenu = (event) => {
    event.preventDefault();
    let toggle = !this.state.openMenu;
    this.setState({ openMenu: toggle, anchorEl: event.currentTarget });
    this.closeMenuScroll();
  }

  onCloseMenu = () => {
    this.setState({ openMenu: false });
    window.removeEventListener("scroll", this.checkPos);
    this.prevPositionY = 0;
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  closeMenuScroll = () => {
    this.prevPositionY = window.scrollY;
    window.addEventListener("scroll", this.checkPos);
  }

  checkPos = () => {
    let currentPos = window.screenY;
    if (currentPos > this.prevPositionY || currentPos < this.prevPositionY) {
      this.onCloseMenu()
    }
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid sx={{ background: `url(${BackgroundImage})`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
          <Typography sx={{ padding: "100px 0", textAlign: "center", color: "white", fontFamily: "shabnam", fontWeight: "bold", fontSize: "30px" }} variant="h2">
            معرفی مناطق تهران
          </Typography>
        </Grid>
        <Grid sx={{ maxWidth: "1200px", margin: "auto" }}>
          <Typography sx={{ fontFamily: "shabnam", textAlign: "center", margin: { xs: "20px 0 -14px", md: "20px 0 -70px" }, fontWeight: "bold", fontSize: "18px" }}>انتخاب منطقه روی نقشه</Typography>
          <Grid sx={{ width: { xs: "100%", md: "70%" }, margin: "auto" }}>
            <TehranMap />
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf" }}></Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={(event) => { this.onClickMenu(event) }}
              sx={{ margin: "20px 0", border: "2px solid #d7d7d7", padding: "10px 40px", boxShadow: "0 2px 2px 0 #ebebeb", borderRadius: "8px" }}
            >
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "black" }}>
                انتخاب منطقه
              </Typography>
              <KeyboardArrowDownRoundedIcon sx={{ color: "gray !important" }} />
            </Button>
            <Menu
              open={this.state.openMenu}
              onClose={this.onCloseMenu}
              anchorEl={this.state.anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              sx={{ "& .MuiPaper-root": { maxHeight: "300px", overflow: "scroll-y" } }}
            >
              <MenuItem>
                <Link to={'/tehran-zone-1'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-2'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-3'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-4'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-5'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-6'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-7'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-8'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-9'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-10'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-11'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-12'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۲</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-13'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۳</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-14'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۴</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/tehran-zone-15'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۵</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۶</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۷</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۸</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۱۹</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۰</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۱</Typography>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'#'} className='menuLink' >
                  <Typography component={'p'} variant='p' className='textNav' onClick={this.scrollToTop} sx={{ padding: "8px 40px" }}> منطقه ۲۲</Typography>
                </Link>
              </MenuItem>
            </Menu>
          </Grid>
          <Grid sx={{ textAlign: { xs: "center", md: "right" }, width: { xs: "90%", md: "100%" }, margin: "auto" }}>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "25px", fontWeight: "bold", textAlign: "justify" }} variant="h2">
              معرفی شهر تهران
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "20px 0 30px", textAlign: "justify" }}>
              تهران به عنوان پایتخت ایران، پرجمعیت ترین شهر کشور محسوب می شود. با توجه به مساحت ۷۳۰ کیلومتر مربعی و جمعیت نه میلیون نفری، تهران به بزرگترین شهر ایران تبدیل شده است.
              آب و هوای تهران از دو مؤلفه کوهستان و دشت در شمال و جنوب تاثیر می گیرد. مناطق شمالی معتدل و مرطوب می باشد و هرچه به سمت مناطق جنوبی حرکت کنیم هوا گرم و خشک می شود.
              شهر تهران با ۲۲ منطقه و ۵۰ محله به کلان شهر تبدیل شده است. با گسترش جمعیت و افزایش حاشیه نشینی ، تعداد مناطق و محلات تهران در حال افزایش است.
              <LinkMui className="link" href="https://drmomtazi.com/" target="_blank">دندانپزشکی در منطقه 2 تهران</LinkMui>
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اراج، ازگل، استانبول، اسفندیار، اقدسیه، امام زاده
              قاسم، الهیه، اندرزگو، اوین، آبادان، آجودانیه، آصف، باغ فردوس. بام تهران، بلوار ارتش، بلوار اوشان، بلوار آفریقا، بلوار بسیج،
              بلوار شاهد، بلوار کاوه، بلوار گلها، بلوار نیرو هوایی، بلوار نیروی زمینی، پارک وی، پیراسته، تجریش، جعفرآباد، جماران، جمشیدیه، جوزستان، چیذر
              ، حصاربوعلی، حکمت، دارآباد، دربند، درکه. دزاشیب، دیباجی جنوبی، دیباجی شمالی، رستم آباد، زعفرانیه، سعد آباد، سوهانک، شریعتی، شهرک البرز
              ، شهرک دانشگاه بهشتی، شهرک شهید چمران، شهرک نفت، شهید محلاتی. صابونیان، صاحب قرانیه، عمار، فرشته، فرمانیه، فلاحی، قیطریه، کاشانک، کامرانیه
              ، گلابدره، ماکوئی پور، محمودیه، مقدس اردبیلی، موحد دانش، مینی سیتی، نارنجستان، نخل، نوبنیاد. نیاوران، ولنجک، ولیعصر، مرجان، بوکان، همایون.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>

            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۲
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اشرفی اصفهانی، ایثار، ایوانک، آذربایجان، آزادی، آسمانها، برق آلستوم، بزرگراه شهید همت، بلوار آیت الله کاشانی، بلوار پاکنژاد، بلوار
              فرحزادی، بوعلی، بهبودی، پردیسان. پرواز، پونک، توحید، تهران ویلا، تیموری، جلال آل احمد، خوش شمالی، خیابان اسکندری، درختی، دریا، دریان نو، زنجان
              ، سپهر، ستارخان، سرو، سعادت آباد، مرزداران، شادمان، شادمهر، شهدای شرکت برق، شهرآرا، شهرک آزمایش، شهرک ژاندارمری، شهرک غرب. شهرک قدس، شهرک هما
              ، شهید چوب تراش، صادقیه، صادقیه شمالی، طرشت، فرحزاد، فلاح زاده، کوهسار، کوهستان، کوی فراز، کوی نصر، گیشا، مترو شریف، مخابرات، مدیریت، همایونشهر.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۳
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              احتشامیه، اختیاریه، اسفندیار، امانیه،
              آرارات، بهروز، پاسداران، جردن، جلفا، حصاری، خواجه عبداله، داودیه، درب دوم، دروس، دولت، کلاهدوز، دیباجی، رستم آباد، زرگنده، سید
              خندان. سئول، شیخ بهایی، شیراز، ظفر، قبا، قلهک، کاوسیه، گلستان، گلستان شمالی، گلستان جنوبی، ملاصدرا، میدان کتابی، میرداماد، وارسته، ولیعصر، ونک.            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۴
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              مهران، کاظم آباد، کوهک، مجیدیه، شمس آباد، پاسداران، ضرابخانه، حسین آباد،
              مبارک آباد، شیان، لویزان، علم و صنعت، نارمک، تهرانپارس غربی، جوادیه، خاک سفید، تهرانپارس شرقی، قاسم آباد. ده نارمک، اوقاف، شمیران نو، حکیمیه،
              قنات کوثر، کوهسار، مجید آباد، بنی هاشم، پلیس، دلاوران. رسالت. سراج، شهدا، صیاد شیرازی، فرجام،
              میدان ملت، هروی، هنگام، پروین، ساقدوش، استخر، نوبنیاد، نیروی زمینی، وفامنش، تکاوران، مطهری، تیرانداز، جاده تلو، جاده دماوند، شهرک شریفی، عراقی.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۵
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              شهران، شهرزیبا، اندیشه، بهاران، کن، المهدی،
              باغ فیض، پونک، حصارک، شهرک نفت، کوهسار، مرادآباد، سازمان برنامه، ارم، پرواز، سازمان آب، اباذر، فردوس، مهران، اکباتان. بیمه، آپادانا،
              آیت الله کاشانی، اشرفی اصفهانی، ایران زمین، بلوار تعاون، بلوار فردوس، بولیوار، پیامبر، جنت آباد، ستاری. سردار جنگل، سولقان، شهر زیبا،
              شهرک اکباتان، شهید مهدی باکری، دهکده المپیک، بلوار ایوانک، آبشار، شعرا، شهرک والفجر، شربیانی، ناهید، نیروگاه، آبشناسان، کنکوی، ارمکوی.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۶
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اتوبان همت، 16 آذر، آرژانتین، اسدآبادی، اسفندیار، اسکندری،
              امیر آباد، ایرانشهر، بلوار آفریقا، بلوار کشاورز، بهجت آباد، بهشتی. پارک لاله، تهران ویلا، توانیر، جمال زاده، جمهوری، جنت، جهاد، حافظ،
              حجاب، رفتگر، زاگرس، زرتشت، ساعی، سنایی، سیندخت، شیخ بهایی جنوبی، شیراز جنوبی، طالقانی، عباس آباد، فاطمی، فلسطین، قائم مقام. قزل قلعه،
              کارگر شمالی، کردستان، کریم خان، کشاورز غربی، گاندی، گلها، لارستان، میدان انقلاب اسلامی، میدان ولیعصر، نجات اللهی، نصرت، وزراء، یوسف آباد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۷
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اجاره دار، ارامنه الف، ارامنه ب، امام حسین، امجدیه خاقانی، اندیشه، انقلاب، آپادانا،
              باغ صبا، بخت آزاد، بهار، حشمتیه، حقوقی، خواجه نصیر. خواجه نظام، دبستان، دهقان، سبلان، سرباز، سهروردی، شارق الف، شارق ب، شریعتی، شهید مدنی،
              شهید قندی، شیخ صفی، عباس آباد، قصر، کاج. کریمخان، گرگان، مدنی، مرودشت، مطهری، مفتح جنوبی، نظام آباد، نیلوفر، هفت تیر، بهشتی، سید خندان.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۸
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              تهرانپارس، دردشت، مدائن، هفت حوض، فدک، زرکش، نارمک، مجیدیه، کرمان. لشگر شرقی، لشگر غربی، وحیدیه، جشنواره، تسلیحات، سبلان، مدنی، بخت آزاد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۹
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              استاد معین، اکبری، امامزاده عبداله، بهبودی، تیموری،
              دکتر هوشیار، زرند، سرآسیاب، سی متری جی، شمشیری، شهرک فردوس. شهید دستغیب، صالحی، صنعتی، طوس، فتح، قزوین، مهرآباد، مهرآباد جنوبی، یادگار امام.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۰
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              بریانک، سلیمانی، تیموری، شبیری جی، سلسبیل، هفت چنار، زنجان جنوبی،
              هاشمی، کارون، آذربایجان، آزادی، امام خمینی، جیحون. حسام الدین، خوش، دامپزشکی، رودکی، سینا، مالک اشتر، قصرالدشت، کمیل، نواب، آیت الله سعیدی.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۱
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              ابوسعید، ارومیه،
              اسکندری، امیربهادر، امیریه، انبار نفت، انقلاب، آگاهی، پاستور، جمال زاده، جمهوری، جوادیه، حر، حسن آباد، حشمت الدوله. دخانیات، راه آهن،
              شاپور، شوش، شیخ هادی، عباسی، فروزش، فرهنگ، قلمستان، کارگر جنوبی، کاشان، گمرک، مخصوص، منیریه، میدان قزوین، وحدت اسلامی، ولیعصر، هلال احمر.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۲
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              امامزاده یحیی، امیر بهادر،
              امین حضور، ایران، آبشار، بازار، بهارستان، پامنار، پانزده خرداد، پیچ شمیران، تختی، توپخانه، حافظ. خراسان، دروازه شمیران، ری، سعدی،
              سلیمانیه، سنگلج، شاپور، شکوفه، صفی علیشاه، عشرت آباد، فردوسی، قیام، کوثر، لاله زار نو، محمدیه، مدنی، مولوی، میدان قیام، هرندی، هفده شهریور.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۳
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اشراقی، امامت، آشتیانی، پیروزی، تهران نو، حافظیه، دماوند، دهقان، زاهد گیلانی، زینبیه، سرخه حصار، شورا، شهید اسدی، صفا، نیروی هوایی.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۴
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              ابوذر،
              اتوبان محلاتی، افراسیابی شمالی، آهنگ، آهنگران، بروجردی، پاسدارگمنام، پرستار، تاکسیرانی، جابری، چهارصد دستگاه. خاوران، دژکام، دولاب، سر آسیاب،
              سیزده آبان، شاهین، شکوفه، شکیب، شهرابی، شهید محلاتی، صد دستگاه، فرزانه، فلاح، قصر فیروزه، مینا، نبرد، نبی اکرم، نیکنام، هفده شهریور، عارف.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۵
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              ابوذر، اتابک، اتوبان بعثت، افسریه، آهنگ، بی سیم، خاوران، خاورشهر، دولت آباد،
              رضویه، شوش، شهرک امیرالمومنین، شهید بروجردی. طیب، قصرفیروزه، قیامدشت، کیانشهر، مسعودیه، مشیریه، مطهری، مظاهری، مینابی، والفجر، هاشم آباد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۶
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              باغ آذری، تختی، جوادیه، چهارصد دستگاه، خزانه، راه آهن، رجایی، شهرک بعثت، علی آباد، نازی آباد، هلال احمر، یاخچی آباد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۷
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              ابوذر، امام زاده حسن، آیت اله سعیدی،
              آذری، باغ خزانه، بریانک، بلورسازی، جلیلی، زرند، زمزم، زهتابی، سجاد، صفنارد، فلاح، قزوین، قلعه مرغی، گلچین، مقدم، میدان بهاران، یافت آباد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۸
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اتوبان آزادگان، امام خمینی، بهداشت، توحید، تولیدارو، خلیج فارس، رجائی، سعید آباد،
              شاد آباد، شمس آباد، شهرک صاحب الزمان، شهرک فردوس، شهرک ولیعصر. صاحب الزمان، شهرک صادقیه، فتح، فردوس، ولیعصر، هفده شهریور، یافت آباد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۱۹
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              خانی آباد نو، اسفندیاری، بستان، بهمنیار، شریعتی، شکوفه، نعمت آباد، دولت خواه، اسماعیل آباد، شهید کاظمی، رسالت، عبدل آباد، یاخچی آباد، شهرک بعثت.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۲۰
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              ۱۳ آبان، استخر، اقدسیه، امین آباد، بلوار شهرداری، بهشتی، تقی آباد، جوانمرد، حمزه آباد، دولت اباد،
              دیلمان، سرتخت، شهادت، شهرری. صفائیه، ظهیر آباد، عباس آباد، علائین، غیوری، فیروزآبادی، کهریزک، منصوریه، نظامی، نفر آباد، ولی آباد.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۲۱
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              اتوبان تهران کرج، باشگاه نفت، بزرگراه فتح، تهرانسر، تهرانسر شمالی، چیتگر، شهرک ۲۲بهمن، شهرک استقلال، شهرک آزادی،
              شهرک دانشگاه. شهرک دانشگاه شریف. شهرک دریا، شهرک شهرداری، شهرک غزالی، شهرک فرهنگیان، شهرک کاظمیه، فدک، ورد آورد، ویلا شهر.
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold" }} variant="h3">
              محله های منطقه ۲۲
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", margin: "15px 0", textAlign: "justify" }}>
              امید، اندیشه، آزاد شهر، بزرگراه شهید خرازی، پیکان شهر، جوزانی، دریاچه چیتگر،
              دژبان، دهکده المپیک. زیبا دشت بالا، زیبا دشت پایین، شریف، شهرک گلستان، شهید باقری، کوهسار، کوهک، گلستان، میدان ساحل، آبشار.
            </Typography>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontSize: "30px", fontWeight: "bold", textAlign: "center", margin: "20px 0 10px" }} variant="h3">
            نقشه تهران
          </Typography>
          <Grid sx={{ width: { xs: "95%", md: "100%" }, height: "400px", borderRadius: "15px", overflow: "hidden", margin: "auto" }}>
            <ShowCityLocation
              position={
                [35.69522525087309, 51.39129638671876]
              }
              zoom={11}
            />
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              در این صفحه به معرفی مناطق 22 گانه تهران همراه با نقشه و محله های آن | املاک هومینجا می پردازیم.
            </Typography>
            <Typography>
              همچنین در این صفحه به معرفی کامل مناطق 22 گانه شهرداری شهر تهران به همراه عکس، نقشه و آگهی املاک می پردازیم.
            </Typography>
          </Grid>
          <Grid sx={{ width: "100%", height: "1px", bgcolor: "#dfdfdf", margin: "20px 0" }}></Grid>
          <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
          <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
            <Link to={'/Listings/buy_tehran_niyavaran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در نیاوران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_niyavaran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در نیاوران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_farmaniyeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در فرمانیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_farmaniyeh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در فرمانیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_zaaferanieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در زعفرانیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_zaaferanieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در زعفرانیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_elahieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در الهیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_elahieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در الهیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{
            bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "9100px" : "0px", md: this.state.openLinks ? "1000px" : "0px" }
            , transition: "max-height 0.6s linear", overflow: "hidden"
          }}
          >
            <Link to={'/Listings/buy_tehran_saadatabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سعادت آباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_saadatabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سعادت آباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_andarzgoo'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اندرزگو
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_andarzgoo'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اندرزگو
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_tajrish'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در تجریش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_tajrish'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در تجریش
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_poonak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در پونک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_poonak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در پونک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ghaitarieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در قیطریه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ghaitarieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در قیطریه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_tehranparsgharbi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در تهران پارس غربی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_tehranparsgharbi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در تهران پارس غربی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_tehranparsshargi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در تهران پارس شرقی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_tehranparsshargi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در تهران پارس شرقی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_dehkadeolampik'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در دهکده المپیک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_dehkadeolampik'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در دهکده المپیک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sohrovardi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سهروردی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sohrovardi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سهروردی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_velenjak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ولنجک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_velenjak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ولنجک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_marzdaran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در مرزداران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_marzdaran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در مرزداران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_sadeghieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در صادقیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_sadeghieh'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در صادقیه
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_vanak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در ونک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_vanak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در ونک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_shahran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_shahran'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهران
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_ekbatan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در اکباتان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_ekbatan'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در اکباتان
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_narmak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در نارمک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_narmak'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در نارمک
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_tavanir'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در توانیر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_tavanir'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در توانیر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_afrigha'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در بلوار آقریقا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_afrigha'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در بلوار آفریقا
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_karimkhanezand'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در کریم خان زند
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_karimkhanezand'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در کریم خان زند
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_hafthoaz'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در هفت حوض
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_hafthoaz'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در هفت حوض
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_mehrabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در مهرآباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_mehrabad'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در مهرآباد
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_salsabil'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در سلسبیل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_salsabil'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در سلسبیل
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_azadi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در آزادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_azadi'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در آزادی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_navab'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در نواب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_navab'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در نواب
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_niruhavaee'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در میدان نیرو هوایی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_niruhavaee'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در میدان نیرو هوایی
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/buy_tehran_valiasr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                خرید و فروش ملک  آپارتمان در شهرک ولیعصر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
            <Link to={'/Listings/rent_tehran_valiasr'} className="zone_link" target="_blank">
              <Typography className="zone_link_text">
                رهن و اجاره ملک  آپارتمان در شهرک ولیعصر
                <SearchRoundedIcon className="zone_link_icon" />
              </Typography>
            </Link>
          </Grid>
          <Grid sx={{ textAlign: "center" }}>
            <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
              <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default TehranZones;