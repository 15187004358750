import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article35/01.jpg";
import photo02 from "../../images/mag/article35/02.jpg";
import photo03 from "../../images/mag/article35/03.jpg";
import photo04 from "../../images/mag/article35/04.jpg";
import photo05 from "../../images/mag/article35/05.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article35 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "دراین مقاله از سایت املاک هومینجا قصد داریم تا به معرفی کلی نکات و مقررات مربوط به پیش خرید و پیش فروش آپارتمان، خانه و انواع ملک بپردازیم.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "صفر تا صد پیش خرید اپارتمان، ملک، اداری، تجاری، مسکونی | همه چیز در مورد پیش فروش آپارتمان | راهنمای کامل پیش خرید آپارتمان | شرایط پیش فروش آپارتمان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[34].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[34].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[34].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[34].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[34].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[34].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="پیش خرید و پیش فروش - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[34].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از <Link className="link" to="/" target="_blank"> سایت املاک هومینجا </Link> قصد داریم تا به معرفی کلی نکات و مقررات مربوط به پیش فروش آپارتمان، خانه و انواع ملک بپردازیم، پس  با <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link>همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در سال‌های اخیر با افزایش قیمت آپارتمان و کاهش قدرت خرید مشتریان، روبرو شده‌ایم. یکی از راهکارهای اساسی برای حل این مشکل، پیش خرید آپارتمان و املاک مسکونی، اداری، تجاری و ... است. پیش از اقدام به این کار توجه به برخی از نکات و شرایط اهمیت دارد. چراکه ممکن است پس از تنظیم قرارداد و انجام معامله متوجه شوید که از شما <Link className="link" to="/real-estate-fraud" target="_blank"> کلاهبرداری  </Link> شده بدین ترتیب سرمایه بالایی را از دست بدهید. به همین دلیل در این مقاله قصد داریم نکات ضروری برای پیش خرید و پیش فروش آپارتمان را مطرح نماییم .
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="پیش فروش اپارتمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نکات پیش فروش ساختمان
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                صفر تا صد پیش خرید ملک اداری، تجاری، مسکونی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از گام‌های بسیار مهم برای پیش خرید آپارتمان و یا پیش فروش آن، مرحله تنظیم قرارداد است. اگر به برخی از شروط در این مرحله توجه داشته باشید از بروز بسیاری از مشکلات حقوقی پیشگیری به عمل خواهید آورد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای این کار باید در ابتدا مشخصات کاملی از طرفین معامله در قرارداد ذکر شود. از جمله این مشخصات می‌توان به نام، نام خانوادگی، تاریخ تولد، شماره شناسنامه و یا آدرس محل سکونت دوطرف اشاره کرد. در این <Link className="link" to="/buying-house-in-Tehran" target="_blank">  مبایعه نامه </Link>باید سمت فروشنده ملک و اینکه آیا او می‌تواند ملک را به دیگری انتقال دهد یا خیر، نیز ذکر شود. در بند بعدی قرار داد باید مشخصات کامل ملک مورد معامله را ذکر کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله مواردی که طرح آن در این شرایط ضرورت دارد می‌توان به مساحت، طبقه، واحد، شماره ثبت و .. اشاره کرد. از سوی دیگر ذکر اشتراک برق، آب، گاز، تلفن، پارکینگ، انباری و ... که در واقع جزئی از ملحقات ملک مورد نظر هستند نیز ضرورت دارد. همچنین باید محل پارکینگ و یا انباری نیز مشخص شود. در قرارداد باید نقشه ساختمان، تعداد اتاق‌ها، کمد، جنس مصالح مورد استفاده، نوع کاربری زمین، زیربنای کل ساختمان، <Link className="link" to="/building-facades-types" target="_blank">  نمای بیرونی </Link> ، سیستم سرمایشی و گرمایشی و ... نیز مطرح شود.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="پیش خرید آپارتمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نکات پیش خرید ملک
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                نکات مهم دیگر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهمترین نکات که محل اختلافات بسیاری هم بوده، متراژ و مساحت آپارتمان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در بیشتر موارد مساحت نهایی ملک با متراژی که در مرحله ابتدایی پیش فروش مطرح شده، اختلافاتی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به همین دلیل قانون در این حوزه وارد شده و ضوابط و شرایطی را تعیین کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطابق با قانون اگر این اختلاف مساحت تا ۵ متر باشد اشکالی ایجاد نشده و دو طرف نیز نباید اعتراضی داشته باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در صورتی که اختلاف پیش آمده بیش از ۵ متر باشد پیش خریدار می‌تواند درخواست فسخ معامله را ارائه دهد و یا برای پرداخت مبلغ به توافقاتی با پیش‌فروشنده برسد. پیش‌فروشنده نیز می‌تواند خسارات را جبران کند و یا با خریدار توافق نموده و رضایت او را جلب نماید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست؟
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از نکات ضروری برای پیش خرید و پیش فروش آپارتمان توجه به قیمت آن و ذکر این نکته در قرارداد است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این نوع از معاملات، بخش مشخصی از قیمت در ابتدا پرداخت شده و مابقی آن به صورت قسطی و مطابق با مراحل پیشرفت پروژه پرداخت می‌گردد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس باید در قرارداد، تعداد اقساط، تاریخ پرداخت اقساط، مبلغ پرداخت و ... به طور دقیق ذکر شود. همچنین باید حق فسخ معامله در صورت عدم پرداخت اقساط و یا عدم تحویل به موقع پروژه از جانب سازنده و یا پیش‌فروشنده ملک نیز تعیین گردد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهم‌ترین شروطی که باید در این شیوه خرید و فروش به آن توجه داشته باشید تعیین تاریخ تحویل ملک مورد نظر است. برای این کار باید ضمانت اجرایی محکمی قرار دهید. بدین ترتیب اگر با تأخیر مواجه شدید می توانید زیان‌ها و خسارات وارد شده را جبران نمایید .
              </Typography>
              <Typography className="h2" variant="h2">
                همه چیز در مورد پیش فروش آپارتمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیش فروش آپارتمان مراحلی دارد که بر اساس قانون تنظیم شده است. در حالت معمول پس از اینکه <Link className="link" to="/building-certificate" target="_blank"> پروانه ساختمان </Link>توسط شهرداری منطقه صادر می‌شود، شناسنامه فنی مستقل برای هر کدام از واحدها نیز تنظیم می‌گردد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از این اقدام، پیش فروش کننده ملک، مجوز انتشار آگهی از مراجع ذی صلاح را دریافت نموده و می‌تواند در جراید و یا رسانه‌ها تبلیغات انجام دهد. بعد از این کار باید قرارداد پیش فروش در دفاتر اسناد رسمی تنظیم شود. به این نکته توجه داشته باشید که سرمایه‌گذاران نمی‌توانند بدون تنظیم سند رسمی و یا بدون اخذ مجوز انتشار <Link className="link" to="/Listings" target="_blank"> آگهی </Link>، به پیش فروش آپارتمان دست بزنند. در غیر این صورت به حبس از ۹۱ روز تا یکسال و یا پرداخت جریمه نقدی محکوم خواهند شد.
              </Typography>
              <Typography className="h2" variant="h2">
                سرمایه گذاری در ملک با پیش خرید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سرمایه گذاری در ملک با پیش خرید یکی از سرمایه گذاری‌های پرسود و پربازده است. به شرطی که با نکات ضروری برای پیش خرید و پیش فروش آپارتمان آشنایی کافی داشته باشید. در این نوع از معاملات، شرایط پرداخت، قیمت، تعهدات طرفین معامله، پیشرفت پروژه با املاک آماده، اندکی اختلاف دارد.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="پیش خرید ملک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سرمایه گذاری در ملک با پیش خرید
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به طور کلی اقدام به پیش خرید، برای افرادی که تمایل به خرید یک ملک دارند اما سرمایه کافی برای این کار در اختیار ندارند اقدامی کاملا مناسب و معقول و مقرون به صرفه است. از سوی دیگر ممکن است شما تمایل به خرید متراژ بیشتری داشته باشید و یا بخواهید در منطقه‌ای دست به خرید ملک بزنید که قیمت بالاتری دارد. در این صورت سازنده ملک قیمتی را به ازاء هر متر مربع اعلام کرده و درصدی از مبلغ کل را در ابتدای قرارداد دریافت می‌کند و مابقی قیمت آپارتمان نیز مطابق با مراحل پیشرفت کار و به صورت اقساطی پرداخت می‌گردد.
              </Typography>
              <Typography className="h2" variant="h2">
                قوانین پیش خرید و پیش فروش املاک و مستغلات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قوانین پیش خرید و پیش فروش املاک و مستغلات در سال ۱۳۸۹ تدوین شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                علاوه بر توجه به امکانات واحد، تعیین قیمت، زمان تحویل ملک و مساحت واحد پیش فروش شده، موارد دیگری نیز به چشم می‌خورد. یکی از مهمترین این نکات، تعیین تکلیف بیمه است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یعنی پیش فروشنده باید خود را بیمه مسئولیت کند تا بتواند از عهده خسارات ناشی از عدم رعایت موازین قانونی در برابر پیش‌خریدار برآید. اگر بنا دارای عیب بوده و یا تجهیزات آن خساراتی را به خریدار وارد کند، بیمه مسئولیت آن را جبران خواهد کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی مناطق  تهران همراه با نقشه
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در شرایطی که خسارات وارده بیش از مبلغ پرداختی بیمه باشد، پیش‌فروشنده باید مابه‌التفاوت آن را پرداخت کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در قوانین جدید، موضوع حل اختلافات میان دو طرف مطرح می شود و شرط مراجعه به داور پیش بینی می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یعنی داور انتخابی توسط پیش فروشنده و داور منتخب پیش‌خریدار، هیئت داورانی را انتخاب می کند و برای حل اختلافات با یکدیگر توافق می‌کنند. اگر توافقی میان طرفین صورت نپذیرد داوری از سوی رئیس دادگستری شهرستان برای حل اختلافات معرفی می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از قوانین جدید در حوزه <Link className="link" to="/apartment-pre-sale" target="_blank"> پیش خرید و پیش فروش املاک و مستغلات </Link> ، مرحله انتقال سند است. در این نوع از معاملات و پس از اتمام کارهای ساختمان در مدت زمان پیش‌بینی شده در قرارداد، خریدار می‌تواند به دفترخانه‌های اسناد رسمی مراجعه کند و درخواست انتقال سند رسمی را ارائه دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر پیش فروشنده پس از اعلام دفتر خانه، حداکثر تا ۱۰ روز برای انتقال سند در مکان مورد نظر حاضر نشود، دفترخانه، سند رسمی را به نام خریدار انتقال خواهد داد. بعد از انتقال رسمی واحد پیش فروش و انجام تعهدات دو طرف، قرارداد اعتباری ندارد و طرفین باید آن را به دفترخانه تحویل دهند .
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="قوانین پیش فروش اپارتمان" className="image" />
                <figcaption className="image_caption">
                  قوانین پیش خرید و پیش فروش آپارتمان
                </figcaption>
              </figure>
              <Typography className="h2" variant="h2">
                راهنمای کامل پیش خرید آپارتمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر تمایل به خرید آپارتمان دارید بهتر است پیش از هر اقدامی به چند نکته مهم توجه داشته باشید. برای نمونه پیش از امضای قرارداد بررسی کنید که ملک موردنظر شما در چه وضعیتی به سر می‌برد. آیا ملک مورد نظر در <Link className="link" to="/way-to-get-house-loan" target="_blank">  رهن بانک </Link>است یا خیر؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر ملک مورد معامله در رهن بانک باشد باید موارد مربوط به فک رهن را در قرارداد ذکر کنید. می‌توانید بخشی از مبلغ قابل پرداخت را نزد خود حفظ کنید و یا به زمانی موکول کنید که بدهی به بانک تسویه شده باشد. در معاملات پیش فروش آپارتمان امکان کلاهبرداری در مقیاس‌های کوچک و بزرگ وجود دارد. ممکن است یک شرکت نتواند به تعهدات خود عمل کند و یا حتی پا به فرار بگذارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس باید پیش از عقد قرارداد و انجام معامله، صلاحیت و سابقه شرکت سازنده را بررسی کنید و تحت تاثیر تبلیغات کاذب قرار نگیرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خریدار آپارتمان باید در همان ابتدای کار از تعداد کل واحدهای ساختمان اطلاع داشته باشد و یا تعداد واحدهای پیش فروش را بداند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر طی این بررسی‌ها به این نکته پی بردید که نصف بیشتر واحدها به صورت قطعی پیش فروش شده‌اند، اندکی صبر کنید. چراکه ممکن است شرکت سازنده تمایلی به انجام تمامی تعهدات خود نداشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قولنامه و یا قرارداد غیر رسمی تنظیم شده در بنگاه‌های املاک به تنهایی اعتبار ندارند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مواقع پیش‌فروشنده باید یک قرارداد پیش فروش رسمی در دفاتر اسناد رسمی تنظیم کرده و سپس مراحل <Link className="link" to="/property-document" target="_blank"> انتقال سند </Link> را نیز به صورت کاملاً قانونی و رسمی انجام دهد. در این صورت اگر فروشنده در میانه‌های کار پروژه را رها کند و یا تعهدات خود را به صورت نیمه کاره انجام دهد امکان طرح و بررسی آن در مراجع قضایی و قانونی ذیصلاح وجود خواهد داشت .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت وفن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرایط پیش فروش آپارتمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیش فروش آپارتمان دشواری‌ها و پیچیدگی‌های مخصوص به خود را دارد. یکی از مهمترین شرایط پیش فروش آپارتمان زمان تحویل ساختمان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالت معمول زمان اعلامی توسط سازندگان بر اساس شرایط موجود سنجیده می شود. تحویل ملک به زمانی در آینده واگذار می‌گردد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این معاملات، بهای ملک به صورت مرحله به مرحله پرداخت می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس یکی از نکات ضروری برای پیش خرید و پیش فروش آپارتمان تعیین دقیق مبلغ پیش پرداخت می باشد و مبلغی است که باید در هر مرحله و به صورت قسطی پرداخت کنید‌.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باید تاریخ دقیق هر کدام از مبالغ نیز ذکر شود و مشخص شود در کدام مرحله از پیشرفت پروژه باید آن را بپردازید.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع بندی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از وب سایت املاک هومینجا تلاش کردیم نکات ضروری برای پیش خرید و پیش فروش انواع آپارتمان  مشاور املاک را شرح دهیم .
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article35;