import { Autocomplete, Button, Grid, TextField, Typography ,Checkbox , Slider} from "@mui/material";
import { icon , checkedIcon , kitchenEq , kitchenCabinet , cool_heat , safety , welfare , cover , baseUrl} from "./materilas/vmp";
import { convertToPersianNumber, onlyReciveNumber, facade , separator } from "./materilas/vmp";
import React from "react";
import axios from "axios";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import AlertModal from "./materilas/alert";
import GifLogo from "./gifLogo";


class RequestHome extends React.Component{

  isMount = true ;

  constructor(props){
      super(props);
      this.state={
        notActivePage : true ,
        requestHomePage : false ,
        showGif : "none",
        alertModal: false ,
        alertMessage: "" ,
        isLoading: false ,
        transactionOptions: [] ,
        transactionSelected: null ,
        priceDisabled: true ,
        mortgageRentDisabled: true ,
        typeEstateOptions : [] ,
        typeEstateSelected : null ,
        stateOptions : [] ,
        stateSelected : null ,
        cityOptions : [] ,
        citySelected : null ,
        cityDisabled : true ,
        zoneOptions : [] ,
        zoneSelected : null ,
        zoneDisabled : true ,
        neighborhoodOptions : [] ,
        neighborhoodSelected : [] ,
        neighborhoodDisabled : true ,
        facadeSelected : [] ,
        coverSelected : [] ,
        coolHeatSelected : [] ,
        welfareSelected : [] ,
        kitchenEqSelected : [] ,
        kitchenCabinetSelected : [] ,
        safetySelected : [] ,
        bedroomNumSelected : "" ,
        elevatorSelected: false,
        storeSelected: false,
        parkingSelected: false ,
        areaRange: [30,3000],
        mortgageRange: [10,5000],
        rentRange: [0.5,200],
        priceRange: [100,900000],
        yearRange: [0,50],
        typeAlert: 3
      }
  }

  componentDidMount(){
    if(this.isMount){
      let userInfo = JSON.parse(localStorage.getItem("infoUserHomeInja"));
      document.title = 'ثبت درخواست ملک رایگان در وب سایت آگهی املاک هومینجا'
      if(userInfo.type.id < 5){
        if(userInfo.active){
          this.setState({notActivePage:false,requestHomePage:true});
          this.getTransaction();
          this.getTypeEstate();
          this.getState();
        }    
      }else{
        window.location.pathname = "/";
        this.isMount = false ;
      }
    }
  }

  componentWillUnmount(){
    this.isMount = false
  }

  getTransaction = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/trans/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({transactionOptions:res.data.data})
    })
    .catch((err) => {
        this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeTransaction = (event,newValue) => {
    this.setState({transactionSelected:null,priceDisabled:true,mortgageRentDisabled:true,priceRange:[100,900000],mortgageRange:[10,5000],rentRange:[0.5,200]});
    if(newValue !== null){
      this.setState({transactionSelected:newValue})
      switch (newValue.id) {
        case 1:
          this.setState({priceDisabled:false})
          break;
        case 2:
          this.setState({mortgageRentDisabled:false})
          break;
        case 3:
          this.setState({priceDisabled:false})
          break;
        default:
          break;
      }
    }
  }

  getTypeEstate = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/estate/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({typeEstateOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeTypeEstate = (event,newValue) =>{
    this.setState({typeEstateSelected:newValue})
  }  

  getState = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/state/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({stateOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeState = (event,newValue) =>{
    this.setState({cityDisabled:true,zoneDisabled:true,neighborhoodDisabled:true,stateSelected:null,citySelected:null,zoneSelected:null,neighborhoodSelected:[]})
    if (newValue !== null){
      this.getCity(newValue.id);
      this.setState({stateSelected:newValue});
    }
  }

  getCity = (stateValue) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/city/get',
      method: 'GET',
      params:{
        state_id:stateValue
      }
    }))
    .then((res) => {
        this.setState({cityOptions:res.data.data,cityDisabled:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeCity = (event,newValue) =>{
    this.setState({zoneDisabled:true,neighborhoodDisabled:true,citySelected:null,zoneSelected:null,neighborhoodSelected:[]})
    if (newValue !== null){
      this.getZone(newValue.id);
      this.setState({citySelected:newValue});
    }
  }

  getZone = (city) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/zone/get',
      method: 'GET',
      params:{
        city_id:city
      }
    }))
    .then((res) => {
        this.setState({zoneOptions:res.data.data,zoneDisabled:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeZone = (event,newValue) =>{
    this.setState({neighborhoodDisabled:true,zoneSelected:null,neighborhoodSelected:[]})
    if (newValue !== null){
      this.getNieghbourhood(newValue.id)
      this.setState({zoneSelected:newValue})
    }
  } 

  getNieghbourhood = (zone) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/neighb/get',
      method: 'GET',
      params:{
        zone_id:zone
      }
    }))
    .then((res) => {
        this.setState({neighborhoodOptions:res.data.data,neighborhoodDisabled:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeNeighborhood = (event,newValue) =>{
      this.setState({neighborhoodSelected:newValue})
  } 

  onChangeFacade = (event,newValue) => {
    this.setState({facadeSelected:[]});
    if(newValue.length > 0){
      this.setState({facadeSelected:newValue});
    }
  }

  onChangeCover = (event,newValue) => {
    this.setState({coverSelected:[]});
    if(newValue.length > 0){
      this.setState({coverSelected:newValue});
    }
  }

  onChangeCoolHeat = (event,newValue) => {
    this.setState({coolHeatSelected:[]});
    if(newValue.length > 0){
      this.setState({coolHeatSelected:newValue});
    }
  }

  onChangeWelfare = (event,newValue) => {
    this.setState({welfareSelected:[]});
    if(newValue.length > 0){
      this.setState({welfareSelected:newValue});
    }
  }

  onChangeKitchenEq = (event,newValue) => {
    this.setState({kitchenEqSelected:[]});
    if(newValue.length > 0){
      this.setState({kitchenEqSelected:newValue});
    }
  }

  onChangeKitchenCabinet = (event,newValue) => {
    this.setState({kitchenCabinetSelected:[]});
    if(newValue.length > 0){
      this.setState({kitchenCabinetSelected:newValue});
    }
  }

  onChangeSafety = (event,newValue) => {
    this.setState({safetySelected:[]});
    if(newValue.length > 0){
      this.setState({safetySelected:newValue});
    }
  }

  onChangeBedroomNum = (event,newValue) =>{
    this.setState({bedroomNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeElevator = (event,newValue) =>{
    this.setState({elevatorSelected:event.target.checked})
  }

  onChangeStore = (event,newValue) =>{
    this.setState({storeSelected:event.target.checked})
  }

  onChangeParking = (event,newValue) =>{
    this.setState({parkingSelected:event.target.checked})
  }

  onChangeMortgage = (event,newValue)=>{
    this.setState({mortgageRange:newValue})
  }

  onChangeAreaRange = (event,newValue)=>{
    this.setState({areaRange:newValue})
  }

  onChangeRent = (event,newValue)=>{
    this.setState({rentRange:newValue})
  }

  onChangePrice = (event,newValue)=>{
    this.setState({priceRange:newValue});
  }

  onChangeYear = (event,newValue)=>{
    this.setState({yearRange:newValue});
  }

  onCloseAlertModal = () => {
    this.setState({alertModal:false});
  }

  checkSubmitInfo = () => {
    switch(null){
      case this.state.stateSelected :
        this.setState({alertModal:true,alertMessage:"استان وارد نشده است",typeAlert:3});
        break;
      case this.state.citySelected :
        this.setState({alertModal:true,alertMessage:"شهر انتخاب نشده است",typeAlert:3});
        break;
      case this.state.zoneSelected :
        this.setState({alertModal:true,alertMessage:"منطقه انتخاب نشده است",typeAlert:3});
        break;
      case this.state.transactionSelected :
        this.setState({alertModal:true,alertMessage:"نوع معامله وارد نشده است",typeAlert:3});
        break;
      case this.state.typeEstateSelected :
        this.setState({alertModal:true,alertMessage:"نوع ملک وارد نشده است",typeAlert:3});
        break;
      default:
        this.submitRequest()
    }
  }

  checkFeatures = (selectedFeature) => {
    let result = [];
    for(let i=0 ; i<selectedFeature.length ; i++){
      result[i] = selectedFeature[i].f ;
    }
    return result ;
  }

  submitRequest = () => {
    this.setState({showGif: "",isLoading: true});
    let params = {
      transaction_id:this.state.transactionSelected.id,
      estate_id:this.state.typeEstateSelected.id,
      state_id:this.state.stateSelected.id,
      city_id:this.state.citySelected.id,
      area: JSON.stringify(this.state.areaRange),
      old: JSON.stringify(this.state.yearRange)
    }
    switch(this.state.transactionSelected.id){
      case 1 :
        params.price = JSON.stringify(this.state.priceRange);
        break ;
      case 2 :
        params.mortgage = JSON.stringify(this.state.mortgageRange);
        params.rent = JSON.stringify(this.state.rentRange);
        break ;
      case 3 :
        params.prebuy = JSON.stringify(this.state.priceRange);
        break ;
      default :
        break ;
    }
    if(this.state.bedroomNumSelected !== ""){
      params.bedroom_num = this.state.bedroomNumSelected;
    }
    if(this.state.zoneSelected){
      params.zone_id = this.state.zoneSelected.id ;
    }
    if(this.state.neighborhoodSelected.length > 0){
      let result = [] ;
      let selected = this.state.neighborhoodSelected ;
      for(let i=0 ; i<selected.length ; i++){
        result[i] = selected[i].id ;
      }
      params.neighbourhood_id = JSON.stringify(result) ;
    }
    if(this.state.facadeSelected.length > 0){
      params.facade = JSON.stringify(this.checkFeatures(this.state.facadeSelected));
    }
    if(this.state.coverSelected.length > 0){
      params.cover = JSON.stringify(this.checkFeatures(this.state.coverSelected));
    }
    if(this.state.coolHeatSelected.length > 0){
      params.condition = JSON.stringify(this.checkFeatures(this.state.coolHeatSelected));
    }
    if(this.state.welfareSelected.length > 0){
      params.welfare = JSON.stringify(this.checkFeatures(this.state.welfareSelected));
    }
    if(this.state.kitchenEqSelected.length > 0){
      params.kitchen = JSON.stringify(this.checkFeatures(this.state.kitchenEqSelected));
    }
    if(this.state.kitchenCabinetSelected.length > 0){
      params.cabinet = JSON.stringify(this.checkFeatures(this.state.kitchenCabinetSelected));
    }
    if(this.state.safetySelected.length > 0){
      params.safety = JSON.stringify(this.checkFeatures(this.state.kitchenCabinetSelected));
    }
    if(this.state.elevatorSelected){
      params.elevator = 1 ;
    }
    if(this.state.storeSelected){
      params.store = 1 ;
    }
    if(this.state.parkingSelected){
      params.parking = 1 ;
    }
    let token = localStorage.getItem('tokenUserHomeInja');
      Promise.resolve(axios({
        url: baseUrl+"v1/request/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ token},
        data: params
      }))
      .then((res) => {
        this.setState({showGif: "none",isLoading: false,alertModal:true,typeAlert:1,
          alertMessage:"ثبت درخواست ملک با موفقیت انجام شد در صورت تائید، پشتیبان تماس خواهد گرفت و درخواست ملک در قسمت درخواستهای من قابل رؤیت می باشد"
        });
        this.resetStates();
      })
      .catch((err) => {
        this.setState({showGif: "none",isLoading: false,alertModal:true,alertMessage:"درخواست ملک ثبت نشد",typeAlert:2});
        this.resetStates();
      });
  }

  resetStates = () => {
    this.setState({
          transactionSelected:null,priceDisabled:true,mortgageRentDisabled:true,typeEstateSelected : null ,
          stateSelected : null,citySelected : null ,cityDisabled : true ,zoneSelected : null ,zoneDisabled : true ,
          neighborhoodSelected : [] ,neighborhoodDisabled : true ,facadeSelected : [] ,coverSelected : [] ,coolHeatSelected : [] ,
          welfareSelected : [] ,kitchenEqSelected : [] ,kitchenCabinetSelected : [] ,safetySelected : [] ,bedroomNumSelected : "" ,
          elevatorSelected: false,storeSelected: false,parkingSelected: false ,areaRange: [30,3000],mortgageRange: [10,5000],rentRange:[0.5,200],priceRange:[100,900000],yearRange: [0,50]
        });
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column',alignItems:"center"}} sx={{marginRight:{md:'270px',xs:'0px'}}}>
        { this.state.notActivePage ?
            <Grid sx={{width:"100%"}}>
              <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>درخواست ملک</Typography>
              <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {"کاربر گرامی حساب شما فعال نمی باشد"}
                </Typography>
              </Grid>
            </Grid>
          : <Grid></Grid>
        }
        { this.state.requestHomePage ?
            <Grid sx={{width:"100%"}}>
              <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'6%',width:"100%"}}>
                <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواست ملک</Typography>
              </Grid>
              <Grid container columns={{xs:4,sm:8,lg:12,xl:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={this.state.stateOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField  {...params} placeholder='استان *'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    onChange={this.onChangeState}
                    value={this.state.stateSelected}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={this.state.cityOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField   {...params} placeholder='شهر *' />}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    onChange={this.onChangeCity}
                    value={this.state.citySelected}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                    disabled={this.state.cityDisabled}
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={this.state.zoneOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField  {...params} placeholder='منطقه *'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    onChange={this.onChangeZone}
                    value={this.state.zoneSelected}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                    disabled={this.state.zoneDisabled}
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={this.state.neighborhoodOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField  {...params} placeholder='محله *'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    onChange={this.onChangeNeighborhood}
                    value={this.state.neighborhoodSelected}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                    multiple
                    disableCloseOnSelect
                    disabled={this.state.neighborhoodDisabled}
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={this.state.transactionOptions}
                    getOptionLabel={(option) => option.des}
                    renderInput={(params) => <TextField  {...params} placeholder='نوع معامله *'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.des}
                      </li>
                    )}
                    onChange={this.onChangeTransaction}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                    value={this.state.transactionSelected}
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={this.state.typeEstateOptions}
                    getOptionLabel={(option) => option.des}
                    renderInput={(params) => <TextField  {...params} placeholder='نوع ملک *'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.des}
                      </li>
                    )}
                    onChange={this.onChangeTypeEstate}
                    clearText="حذف"
                    closeText="بستن"
                    openText="باز کردن"
                    value={this.state.typeEstateSelected}
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <TextField 
                    fullWidth    
                    onChange={this.onChangeBedroomNum}
                    className="ads-textfield"
                    id="ads-textfield-input"
                    value={convertToPersianNumber(this.state.bedroomNumSelected)}
                    label="تعداد خواب"
                    inputProps={{
                      maxLength: 3
                    }}
                  />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={facade}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='نمای ساختمان'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeFacade}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.facadeSelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={cover}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='کفپوش ساختمان'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeCover}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.coverSelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={cool_heat}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='سرمایش / گرمایش'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeCoolHeat}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.coolHeatSelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={welfare}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='امکانات رفاهی'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeWelfare}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.welfareSelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={kitchenEq}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='امکانات آشپزخانه'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeKitchenEq}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.kitchenEqSelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={kitchenCabinet}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='نوع کابینت'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeKitchenCabinet}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.kitchenCabinetSelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Autocomplete
                      id="ads-autocomplete-input"
                      className="ads-autocomplete"
                      options={safety}
                      getOptionLabel={(option) => option.n}
                      renderInput={(params) => <TextField  {...params} placeholder='امکانات امنیتی'/>}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                          />
                          {option.n}
                        </li>
                      )}
                      onChange={this.onChangeSafety}
                      clearText="حذف"
                      closeText="بستن"
                      openText="باز کردن"
                      multiple
                      disableCloseOnSelect
                      limitTags={1}
                      value={this.state.safetySelected}
                    />
                </Grid>
                <Grid item xs={4} xl={3} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>آسانسور</Typography>
                  <Checkbox checked={this.state.elevatorSelected} onChange={this.onChangeElevator}/>
                  <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>انباری</Typography>
                  <Checkbox checked={this.state.storeSelected} onChange={this.onChangeStore}/>
                  <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>پارکینگ</Typography>
                  <Checkbox checked={this.state.parkingSelected} onChange={this.onChangeParking}/>
                </Grid>
              </Grid> 
              <Grid container columns={{xs:4,sm:8,lg:12,xl:12}} sx={{padding:"0 8% 8% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}} alignItems="center">
                <Grid item xs={4} xl={3}>
                  <Grid sx={{padding:"8px"}}>
                    <Grid sx={{display:"flex",justifyContent:"space-between"}}>
                      <HomeinjaTypo style={{fontSize:'1em',display:"inline-block"}} text={"متراژ"}/>
                      <Grid>
                        <Typography style={{fontFamily:'shabnam',backgroundColor:'white',display:"inline-block"}} sx={{fontSize:'0.9em'}} component={'p'}>
                          {convertToPersianNumber(separator(this.state.areaRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.areaRange[0]))}
                        </Typography>
                        <HomeinjaTypo style={{fontSize:'.9em',display:"inline-block",marginRight:"5px"}} text={"متر مربع"}/>
                      </Grid>
                    </Grid>
                    <Slider
                      getAriaLabel={() => 'متراژ'}
                      valueLabelDisplay="auto"
                      min={30}
                      max={3000}
                      step={10}
                      value={this.state.areaRange}
                      valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' متر'}
                      onChange={this.onChangeAreaRange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} xl={3}>
                  <Grid sx={{padding:"8px"}}>
                    <Grid sx={{display:"flex",justifyContent:"space-between"}}>
                      <HomeinjaTypo style={{fontSize:'1em',display:"inline-block"}} text={"سن بنا"}/>
                      <Grid>
                        <Typography style={{fontFamily:'shabnam',backgroundColor:'white',display:"inline-block"}} sx={{fontSize:'0.9em'}} component={'p'}>
                          {convertToPersianNumber(this.state.yearRange[1]) + ' تا ' + convertToPersianNumber(this.state.yearRange[0])}
                        </Typography>
                        <HomeinjaTypo style={{fontSize:'.9em',display:"inline-block",marginRight:"5px"}} text={"سال"}/>
                      </Grid>
                    </Grid>
                    <Slider
                      getAriaLabel={() => 'سن بنا'}
                      valueLabelDisplay="auto"
                      min={0}
                      max={50}
                      value={this.state.yearRange}
                      valueLabelFormat={(x) =>convertToPersianNumber(x) + " سال "}
                      onChange={this.onChangeYear}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} xl={3} sx={{display:this.state.mortgageRentDisabled ? "none" : "block"}}>
                  <Grid sx={{padding:"8px"}}>
                    <Grid sx={{display:"flex",justifyContent:"space-between"}}>
                      <HomeinjaTypo style={{fontSize:'1em',display:"inline-block"}} text={"رهن"}/>
                      <Grid>
                        <Typography style={{fontFamily:'shabnam',backgroundColor:'white',display:"inline-block"}} sx={{fontSize:'0.9em'}} component={'p'}>
                          {convertToPersianNumber(separator(this.state.mortgageRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.mortgageRange[0]))}
                        </Typography>
                        <HomeinjaTypo style={{fontSize:'.9em',display:"inline-block",marginRight:"5px"}} text={"میلیون تومان"}/>
                      </Grid>
                    </Grid>
                    <Slider
                      getAriaLabel={() => 'قیمت'}
                      valueLabelDisplay="auto"
                      min={10}
                      max={5000}
                      value={this.state.mortgageRange}
                      valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                      onChange={this.onChangeMortgage}
                      disabled={this.state.mortgageRentDisabled}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} xl={3} sx={{display:this.state.mortgageRentDisabled ? "none" : "block"}}>
                  <Grid sx={{padding:"8px"}}>
                    <Grid sx={{display:"flex",justifyContent:"space-between"}}>
                      <HomeinjaTypo style={{fontSize:'1em',display:"inline-block"}} text={"اجاره"}/>
                      <Grid>
                        <Typography style={{fontFamily:'shabnam',backgroundColor:'white',display:"inline-block"}} sx={{fontSize:'0.9em'}} component={'p'}>
                          {convertToPersianNumber(separator(this.state.rentRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.rentRange[0]))}
                        </Typography>
                        <HomeinjaTypo style={{fontSize:'.9em',display:"inline-block",marginRight:"5px"}} text={"میلیون تومان"}/>
                      </Grid>
                    </Grid>
                    <Slider
                      getAriaLabel={() => 'قیمت'}
                      valueLabelDisplay="auto"
                      min={0.5}
                      max={200}
                      value={this.state.rentRange}
                      valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                      onChange={this.onChangeRent}
                      disabled={this.state.mortgageRentDisabled}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4} xl={3} sx={{display:this.state.priceDisabled ? "none" : "block"}}>
                  <Grid sx={{padding:"8px"}}>
                    <Grid sx={{display:"flex",justifyContent:"space-between"}}>
                      <HomeinjaTypo style={{fontSize:'1em',display:"inline-block"}} text={"قیمت"}/>
                      <Grid>
                        <Typography style={{fontFamily:'shabnam',backgroundColor:'white',display:"inline-block"}} sx={{fontSize:'0.9em'}} component={'p'}>
                          {convertToPersianNumber(separator(this.state.priceRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.priceRange[0]))}
                        </Typography>
                        <HomeinjaTypo style={{fontSize:'.9em',display:"inline-block",marginRight:"5px"}} text={"میلیون تومان"}/>
                      </Grid>
                    </Grid>
                    <Slider
                      getAriaLabel={() => 'قیمت'}
                      valueLabelDisplay="auto"
                      min={100}
                      max={900000}
                      value={this.state.priceRange}
                      valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                      onChange={this.onChangePrice}
                      disabled={this.state.priceDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{textAlign:"center",width:"100%"}}>
                <Button onClick={this.checkSubmitInfo} disabled={this.state.isLoading}
                  sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:{xs:"50%",md:"30%"},minWidth:{xs:"50%",md:"30%"},
                      margin:"auto",fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                >
                  ثبت درخواست
                </Button>
              </Grid>
            </Grid>
          : <Grid></Grid>
        }
        <AlertModal open={this.state.alertModal} close={this.onCloseAlertModal} message={this.state.alertMessage} type={this.state.typeAlert}/>
        <GifLogo display={this.state.showGif}/>
      </Grid>
    )
  };
}

export default RequestHome;