import { Autocomplete, Button, Grid, TextField, Typography ,Checkbox, TextareaAutosize} from "@mui/material";
import React from "react";
import { convertToPersianNumber, onlyReciveNumber, facade ,separator} from "./materilas/vmp";
import axios from "axios";
import { baseUrl } from "./materilas/vmp";
import "../styles/advertisements.css";
import { icon , checkedIcon , situation , kitchenEq , kitchenCabinet , cool_heat , safety , welfare , cover ,floorOptions} from "./materilas/vmp";
import AlertModal from "./materilas/alert";
import GifLogo from "./gifLogo";
import GetLocation from "./materilas/getLocation";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Resizer from "react-image-file-resizer";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

class CreateListing extends React.Component{

  isMount = true;

  constructor(props){
      super(props);
      this.state={
        showGif : "none",
        listingId : null ,
        notAvialablePage : true ,
        notAvialablePageMessage : "" ,
        primaryInfo : false ,
        additionalInfo : false ,
        locationInfo : false ,
        imageUpload : false ,
        alertModal : false ,
        alertMessage: "",
        isLoading: false ,
        transactionOptions : [],
        transactionSelected : null,
        typeEstateOptions : [] ,
        typeEstateSelected : null ,
        typeDocumentOptions : [] ,
        typeDocumentSelected : null ,
        situationSelected : null ,
        stateOptions : [] ,
        stateSelected : null ,
        cityOptions : [] ,
        citySelected : null ,
        cityDisabled : true ,
        zoneOptions : [] ,
        zoneSelected : null ,
        zoneDisabled : true ,
        neighborhoodOptions : [] ,
        neighborhoodSelected : null ,
        neighborhoodDisabled : true ,
        currentSituationOptions : [] ,
        currentSituationSelected : null,
        typeKitchenOptions : [] ,
        typeKitchenSelected : null,
        areaSelected:"",
        yearSelected:"",
        parkingNumSelected:"",
        bedroomNumSelected:"",
        masterRoomNumSelected:"",
        bathNumSelected:"",
        frenchWcNumSelected:"",
        floorAllSelected:"",
        floorNumSelected:"",
        iranianWcNumSelected:"",
        totalPriceSelected:"",
        prebuySelected:"",
        priceDisabled: true ,
        priceShow: "",
        mortgageSelected: "",
        rentSelected: "" ,
        mortgageRentDisabled: true,
        elevatorSelected: false,
        storeSelected: false,
        facadeSelected: [],
        kitchenEqSelected: [],
        kitchenCabinetSelected: [],
        coolHeatSelected: [],
        safetySelected: [],
        welfareSelected: [],
        coverSelected: [],
        uploadedImages: null ,
        clearText:'حذف',
        closeText:"بستن",
        openText:"باز کردن",
        noOptionsText:"فاقد اطلاعات",
        path: [],
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'ثبت آگهی ملک در وب سایت املاک هومینجا'
    if (this.isMount){     
      this.checkUserInfo();
      this.getTransaction();
      this.getTypeEstate();
      this.getTypeDocument();
      this.getState();
      this.getSituation();
      this.getKitchen();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  checkUserInfo = () => {
    this.setState({showGif:""});
    let token = localStorage.getItem('tokenUserHomeInja');
    Promise.resolve(axios({
      url: baseUrl + "v1/user/info",
      method: "GET",
      headers: {"Authorization":"bearer "+ token},
    }))
    .then((res) => {
      let isInfoComplete = res.data.data.informationCompelete ;
      let isActive = res.data.data.active ;
      let countListing = res.data.data.count_listing ;
      let listingLimit = res.data.data.type.active_listing ;
      let typeId = res.data.data.type.id
      if(typeId < 5){
        if(isInfoComplete){
          if(isActive){
            if(countListing >= listingLimit){
              this.setState({notAvialablePageMessage:"کاربر گرامی شما حداکثر تعداد آگهی مربوط به نوع کاربری خود را ثبت کرده اید"})
            }else{
              this.setState({notAvialablePage:false,primaryInfo:true});
            }
          }else{
            this.setState({notAvialablePageMessage:"کاربر گرامی حساب شما هنوز توسط پشتیبان فعال نشده است"})
          }
        }else{
          this.setState({notAvialablePageMessage:"کاربر گرامی اطلاعات خود را تکمیل نکرده اید برای تکمیل به بخش پروفایل و ویرایش اطلاعات مراجعه کنید."})
        }
      }else{
        this.setState({alertModal:true,alertMessage:"نوع کاربری شما مجاز به ثبت آگهی نیست",typeAlert:3});
        window.location.pathname = "/" ;
        this.isMount = false ;
      }  
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"ثبت آگهی با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
    })
    .finally(() => {
      this.setState({showGif:"none"});
    })
  }

  resetStates = () => {
    this.setState({
      showGif : "none",
      listingId : null,
      primaryInfo : false ,
      additionalInfo : false ,
      locationInfo : false ,
      imageUpload : false ,
      isLoading: false ,
      transactionSelected : null,
      typeEstateSelected : null ,
      typeDocumentSelected : null ,
      situationSelected : null ,
      stateSelected : null ,
      citySelected : null ,
      cityDisabled : true ,
      zoneSelected : null ,
      zoneDisabled : true ,
      neighborhoodSelected : null ,
      neighborhoodDisabled : true ,
      currentSituationSelected : null,
      typeKitchenSelected : null,
      areaSelected:"",
      yearSelected:"",
      parkingNumSelected:"",
      bedroomNumSelected:"",
      masterRoomNumSelected:"",
      bathNumSelected:"",
      frenchWcNumSelected:"",
      floorAllSelected:"",
      floorNumSelected:"",
      iranianWcNumSelected:"",
      totalPriceSelected:"",
      prebuySelected:"",
      priceDisabled: true ,
      priceShow: "",
      mortgageSelected: "",
      rentSelected: "" ,
      mortgageRentDisabled: true,
      elevatorSelected: false,
      storeSelected: false,
      facadeSelected: [],
      kitchenEqSelected: [],
      kitchenCabinetSelected: [],
      coolHeatSelected: [],
      safetySelected: [],
      welfareSelected: [],
      coverSelected: [],
      uploadedImages: null ,
    })
    this.smallImageResized = null ;
    this.largeImageResized = null ;
    this.imageFile = null ;
  } 

  getNotification = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    Promise.resolve(axios({
      method:'GET',
      url:baseUrl + 'v1/notification/get',
      headers:{'Authorization':'bearer ' + token},
    }))
    .then((res) =>{
      let info = JSON.stringify(res.data.data);
      localStorage.setItem("notification",info);
    })
    .catch(() =>{
      localStorage.removeItem("notification");
    })
  }

  /* ----------------------- primary info functions ------------------------ */

  onCloseAlertModal = () =>{
    this.setState({alertModal:false});
  }

  getTransaction = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/trans/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({transactionOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeTransaction = (event,newValue) =>{
    this.setState({transactionSelected:null,transactionInput:"",priceDisabled:true,totalPriceSelected:"",prebuySelected:"",priceShow:"",mortgageRentDisabled:true,
                  rentSelected:"",mortgageSelected:""})
    if (newValue !== null){
      this.setState({transactionSelected:newValue})
      switch (newValue.id) {
        case 1:
          this.setState({priceDisabled:false})
          break;
        case 2:
          this.setState({mortgageRentDisabled:false})
          break;
        case 3:
          this.setState({priceDisabled:false})
          break;
        default:
          break;
      }
    }
  }

  getTypeEstate = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/estate/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({typeEstateOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeTypeEstate = (event,newValue) =>{
      this.setState({typeEstateSelected:newValue})
  }  

  getTypeDocument = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/document/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({typeDocumentOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeTypeDocument = (event,newValue) =>{
    this.setState({typeDocumentSelected:newValue})
  }  

  getState = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/state/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({stateOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeState = (event,newValue) =>{
    this.setState({cityDisabled:true,zoneDisabled:true,neighborhoodDisabled:true,stateSelected:null,citySelected:null,zoneSelected:null,neighborhoodSelected:null})
    if (newValue !== null){
      this.getCity(newValue.id);
      this.setState({stateSelected:newValue});
    }
  }

  getCity = (stateValue) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/city/get',
      method: 'GET',
      params:{
        state_id:stateValue
      }
    }))
    .then((res) => {
        this.setState({cityOptions:res.data.data,cityDisabled:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeCity = (event,newValue) =>{
    this.setState({zoneDisabled:true,neighborhoodDisabled:true,citySelected:null,zoneSelected:null,neighborhoodSelected:null})
    if (newValue !== null){
      this.getZone(newValue.id);
      this.setState({citySelected:newValue});
    }
  }

  getZone = (city) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/zone/get',
      method: 'GET',
      params:{
        city_id:city
      }
    }))
    .then((res) => {
        this.setState({zoneOptions:res.data.data,zoneDisabled:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeZone = (event,newValue) =>{
    this.setState({neighborhoodDisabled:true,zoneSelected:null,neighborhoodSelected:null})
    if (newValue !== null){
      this.getNieghbourhood(newValue.id)
      this.setState({zoneSelected:newValue})
    }
  } 

  getNieghbourhood = (zone) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/neighb/get',
      method: 'GET',
      params:{
        zone_id:zone
      }
    }))
    .then((res) => {
        this.setState({neighborhoodOptions:res.data.data,neighborhoodDisabled:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeNeighborhood = (event,newValue) =>{
      this.setState({neighborhoodSelected:newValue})
  } 

  onChangeSituation = (event,newValue) =>{
    this.setState({situationSelected:null})
    if(newValue.length > 0 ){
      this.setState({situationSelected:newValue})
    }
  }

  getSituation = ()=>{
    Promise.resolve(axios({
      url: baseUrl+'v1/current/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({currentSituationOptions:res.data.data})
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  getKitchen = ()=>{
    Promise.resolve(axios({
      url: baseUrl+'v1/kitchen/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({typeKitchenOptions:res.data.data})
    })
    .catch((err) => {
        this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeKitchen = (event,newValue) =>{
      this.setState({typeKitchenSelected:newValue})
  }

  onChangeCurrentSituation = (event,newValue) =>{
      this.setState({currentSituationSelected:newValue})
  }

  onChangeArea = (event,newValue) =>{
    this.setState({areaSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeYear = (event,newValue) =>{
    this.setState({yearSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeParkingNum = (event,newValue) =>{
    this.setState({parkingNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeBedroomNum = (event,newValue) =>{
    this.setState({bedroomNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeMasterRoomNum = (event,newValue) =>{
    this.setState({masterRoomNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangebathNum = (event,newValue) =>{
    this.setState({bathNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeFloorAll = (event,newValue) =>{
    this.setState({floorAllSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeFrenchWcNum = (event,newValue) =>{
    this.setState({frenchWcNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeFloorNum = (event,newValue) =>{
    if(newValue){
      this.setState({floorNumSelected:newValue.f})
    }
  }

  onChangeIranianWcNum = (event,newValue) =>{
    this.setState({iranianWcNumSelected:onlyReciveNumber(event.target.value)});
  }

  onChangeMortgage = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({mortgageSelected:onlyReciveNumber(inputWithoutComma)});
  }

  onChangeRent = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({rentSelected:onlyReciveNumber(inputWithoutComma)});
  }

  onChangePrice = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    switch (this.state.transactionSelected.id) {
      case 1:
        this.setState({totalPriceSelected:onlyReciveNumber(inputWithoutComma),priceShow:onlyReciveNumber(inputWithoutComma)})
        break;
      case 3:
        this.setState({prebuySelected:onlyReciveNumber(inputWithoutComma),priceShow:onlyReciveNumber(inputWithoutComma)})
        break;
      default:
        break;
    }
  }

  onChangeElevator = (event,newValue) =>{
    this.setState({elevatorSelected:event.target.checked})
  }

  onChangeStore = (event,newValue) =>{
    this.setState({storeSelected:event.target.checked})
  }

  checkPrimaryInfo = () => {
    switch(null){
      case this.state.transactionSelected :
        this.setState({alertModal:true,alertMessage:"لطفا نوع معامله را وارد کنید",typeAlert:3});
        break;
      case this.state.typeEstateSelected :
        this.setState({alertModal:true,alertMessage:"لطفا نوع ملک را وارد کنید",typeAlert:3});
        break;
      case this.state.typeDocumentSelected :
        this.setState({alertModal:true,alertMessage:"لطفا نوع سند را وارد کنید",typeAlert:3});
        break;
      case this.state.stateSelected :
          this.setState({alertModal:true,alertMessage:"لطفا استان را وارد کنید",typeAlert:3});
          break;
      case this.state.citySelected :
        this.setState({alertModal:true,alertMessage:"لطفا شهر را وارد کنید",typeAlert:3});
        break;
      case this.state.zoneSelected :
        this.setState({alertModal:true,alertMessage:"لطفا منطقه را وارد کنید",typeAlert:3});
        break;
      case this.state.neighborhoodSelected :
        this.setState({alertModal:true,alertMessage:"لطفا محله را وارد کنید",typeAlert:3});
        break;
      case this.state.currentSituationSelected :
        this.setState({alertModal:true,alertMessage:"لطفا وضعیت کنونی ملک را وارد کنید",typeAlert:3});
        break;
      case this.state.typeKitchenSelected :
        this.setState({alertModal:true,alertMessage:"لطفا نوع آشچزخانه را وارد کنید",typeAlert:3});
        break;
      default :
        this.checkYearAndArea();
    }
  }

  checkYearAndArea = () => {
    if(this.state.areaSelected){
      if(this.state.yearSelected === ""){
        this.setState({alertModal:true,alertMessage:"لطفا سال ساخت را وارد کنید",typeAlert:3});
      }else{
        let yearSelected = this.state.yearSelected.toString();
        if(yearSelected.length < 4){
          this.setState({alertModal:true,alertMessage:"تاریخ باید چهار رقمی باشد برای مثال ۱۴۰۱",typeAlert:3});
        }else{
          this.submitPrimaryInfo();
        }
      }
    }else{
      this.setState({alertModal:true,alertMessage:"لطفا متراژ را وارد کنید",typeAlert:3});
    }
  }

  submitPrimaryInfo = () => {
      this.setState({showGif:"",isLoading:true})
      let params = {
        transaction_id:this.state.transactionSelected.id,
        document_id:this.state.typeDocumentSelected.id,
        estate_id:this.state.typeEstateSelected.id,
        kitchen_id:this.state.typeKitchenSelected.id,
        state_id:this.state.stateSelected.id,
        city_id:this.state.citySelected.id,
        zone_id:this.state.zoneSelected.id,
        neighbourhood_id:this.state.neighborhoodSelected.id,
        current_situation_id:this.state.currentSituationSelected.id,
      }
      if(this.state.areaSelected){
        params.area = this.state.areaSelected ;
      }
      if(this.state.yearSelected){
        params.year = this.state.yearSelected ;
      }
      if(this.state.parkingNumSelected){
        params.parking_num = this.state.parkingNumSelected ;
      }
      if(this.state.bedroomNumSelected){
        params.bedroom_num = this.state.bedroomNumSelected ;
      }
      if(this.state.masterRoomNumSelected){
        params.master_room_num = this.state.masterRoomNumSelected ;
      }
      if(this.state.bathNumSelected){
        params.bath_num = this.state.bathNumSelected ;
      }
      if(this.state.frenchWcNumSelected){
        params.french_wc_num = this.state.frenchWcNumSelected ;
      }
      if(this.state.floorAllSelected){
        params.floor_all = this.state.floorAllSelected ;
      }
      if(this.state.floorNumSelected){
        params.floor_num = this.state.floorNumSelected ;
      }
      if(this.state.iranianWcNumSelected){
        params.iranian_wc_num = this.state.iranianWcNumSelected ;
      }
      if(this.state.totalPriceSelected){
        params.total_price = this.state.totalPriceSelected ;
      }
      if(this.state.prebuySelected){
        params.prebuy = this.state.prebuySelected ;
      }
      if(this.state.mortgageSelected){
        params.mortgage = this.state.mortgageSelected ;
      }
      if(this.state.rentSelected){
        params.rent = this.state.rentSelected ;
      }
      if(this.state.elevatorSelected){
        params.elevator = 1 ;
      }
      if(this.state.storeSelected){
        params.store_num = 1 ;
      }
      let description = document.getElementById("primaryinfo-desc").value ;
      description = description.trim();
      if(description !== ""){
        params.description = description ;
      }

      let token = localStorage.getItem('tokenUserHomeInja');
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ token},
        data: params
      }))
      .then((res) => {
        this.setState({listingId:res.data.data});
        if(this.state.situationSelected){
          this.postSituaton(res.data.data);
        }else{
          this.setState({showGif:"none",isLoading:false,alertModal:true,alertMessage:"اطلاعات اولیه با موفقیت ثبت شد",typeAlert:1,primaryInfo:false,additionalInfo:true})
        }
      })
      .catch((err) => {
        switch(err.response.status){
          case 422 :
            this.setState({alertModal:true,alertMessage:"موجودی شما برای ثبت آگهی کافی نیست",typeAlert:2,showGif:"none",isLoading:false})
            break;
          default :
            this.setState({alertModal:true,alertMessage:"ثبت آگهی ناموفق بود لطفا مجدداً تلاش کنید یا با پشتیبانی تماس حاصل فرمائید",typeAlert:2,showGif:"none",isLoading:false})
        }
      })
      .finally(() => {
        this.getNotification();
      })
  }

  postSituaton = (id) =>{
    if(this.state.situationSelected !== null){
      let token = localStorage.getItem('tokenUserHomeInja');
      let params = this.situationInfo();
      params.listing_id = id;
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/situation",
        method: "POST",
        headers: {"Authorization":"bearer "+ token},
        data: params
      }))
      .then((res) => {
        this.setState({showGif:"none",isLoading:false,alertModal:true,alertMessage:"اطلاعات اولیه با موفقیت ثبت شد",typeAlert:1,primaryInfo:false,additionalInfo:true})
      })
      .catch((err) => {
        this.setState({showGif:"none",isLoading:false,alertModal:true,alertMessage:"در ذخیره ی اطلاعات اولیه مشکلی پیش آمد لطفا با پشتیبانی تماس بگیرید",typeAlert:2});
        this.resetStates();
      });
    }
  }

  situationInfo = () => {
    let situationSelect = this.state.situationSelected ;
    let result = {};
    
    for(let i = 0 ; i < situation.length ; i++){
      let isExist = false ;
      for(let j = 0 ; j < situationSelect.length ; j++){
        if(situation[i].f === situationSelect[j].f){
          isExist = true ;
        }
      }
      if(isExist){
        result[situation[i].f] = 1 ;
      }else{
        result[situation[i].f] = 0 ;
      }
    }
    return result ;
  }

  /* ----------------------- addintional info functions ------------------------ */

  checkFeatures = (selected,feature) => {
    let result = {};
    for(let i = 0 ; i < feature.length ; i++){
      let isExist = false ;
      for(let j = 0 ; j < selected.length ; j++){
        if(feature[i].f === selected[j].f){
          isExist = true ;
        }
      }
      if(isExist){
        result[feature[i].f] = 1 ;
      }else{
        result[feature[i].f] = 0 ;
      }
    }
    return result;
  }

  onChangeFacade = (event,newValue) => {
    this.setState({facadeSelected:newValue});
  }

  onChangeKitchenEq = (event,newValue) => {
    this.setState({kitchenEqSelected:newValue});
  }

  onChangeKitchenCabinet = (event,newValue) => {
    this.setState({kitchenCabinetSelected:newValue});
  }

  onChangeCoolHeat = (event,newValue) => {
    this.setState({coolHeatSelected:newValue});
  }

  onChangeSafety = (event,newValue) => {
      this.setState({safetySelected:newValue});
  }

  onChangeWelfare = (event,newValue) => {
      this.setState({welfareSelected:newValue});
  }

  onChangeCover = (event,newValue) => {
    this.setState({coverSelected:newValue});
  }

  submitAdditionalInfo = () => {
    this.setState({showGif:"",isLoading:true});
    let token = localStorage.getItem('tokenUserHomeInja');
    let facadeData = this.checkFeatures(this.state.facadeSelected,facade);
    facadeData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/facade/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: facadeData
    }))
    .then((res) => {
      this.postKitchenEq();
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات نمای ساختمان با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",showGif:"none",
        isLoading:false,facadeSelected:[],kitchenEqSelected:[],kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
      });
    });
  }

  postKitchenEq = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    let kitchenEqData = this.checkFeatures(this.state.kitchenEqSelected,kitchenEq);
    kitchenEqData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/equipment/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: kitchenEqData
    }))
    .then((res) => {
      this.postKitchenCabinet();
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات امکانات آشپزخانه با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
        showGif:"none",isLoading:false,kitchenEqSelected:[],kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2,
      });
    });
  }

  postKitchenCabinet = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    let kitchenCabinetData = this.checkFeatures(this.state.kitchenCabinetSelected,kitchenCabinet);
    kitchenCabinetData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/cabinet/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: kitchenCabinetData
    }))
    .then((res) => {
      this.postCoolHeat();
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات نوع کابینت با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",typeAlert:2,
        showGif:"none",kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],
      });
    });
  }

  postCoolHeat = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    let coolHeatData = this.checkFeatures(this.state.coolHeatSelected,cool_heat);
    coolHeatData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/che/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: coolHeatData
    }))
    .then((res) => {
      this.postSafety();
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات تهویه مطبوع با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
        showGif:"none",isLoading:false,coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
      });
    });
  }

  postSafety = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    let safetyData = this.checkFeatures(this.state.safetySelected,safety);
    safetyData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/safety/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: safetyData
    }))
    .then((res) => {
      this.postWelfare();
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات امکانات امنیتی با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
        showGif:"none",isLoading:false,safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
      });
    });
  }

  postWelfare = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    let welfareData = this.checkFeatures(this.state.welfareSelected,welfare);
    welfareData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/welfare/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: welfareData
    }))
    .then((res) => {
      this.postCover();
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات امکانات رفاهی با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
        showGif:"none",isLoading:false,welfareSelected:[],coverSelected:[],typeAlert:2
      });
    });
  }

  postCover = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    let coverData = this.checkFeatures(this.state.coverSelected,cover);
    coverData.listing_id = this.state.listingId ;
    Promise.resolve(axios({
      url: baseUrl+"v1/cover/create",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: coverData
    }))
    .then((res) => {
      this.setState({alertModal:true,alertMessage:"اطلاعات تکمیلی با موفقیت ثبت شد",additionalInfo: false, locationInfo: true,showGif:"none",isLoading:false,typeAlert:1})
    })
    .catch((err) => {
      this.setState({
        alertModal:true,alertMessage:"ذخیره اطلاعات کفپوش ساختمان با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
        showGif:"none",isLoading:false,coverSelected:[],typeAlert:2
      });
    });
  }

  /* ----------------------- location info functions ------------------------ */

  submitLocationInfo = () => {
    this.setState({showGif:"",isLoading:true})
    let latitudeInfo = localStorage.getItem("lat");
    let longitudeInfo = localStorage.getItem("lng");
    let token = localStorage.getItem('tokenUserHomeInja');
    if(latitudeInfo && longitudeInfo){
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/edit",
        method: "POST",
        headers: {"Authorization":"bearer "+ token},
        data: {
          latitude : latitudeInfo ,
          longitude : longitudeInfo ,
          listing_id : this.state.listingId ,
        }
      }))
      .then((res) => {
        this.setState({alertModal:true,alertMessage:"محل آگهی با موفقیت ثبت شد",showGif:"none",isLoading:false,locationInfo: false ,imageUpload: true,typeAlert:1});
        localStorage.removeItem('lat');
        localStorage.removeItem('lng');
      })
      .catch((err) => {
        this.setState({alertModal:true,alertMessage:"ثبت محل آگهی با مشکل مواجه شد در صورت تکرار مشکل با پشتیبان تماس حاصل فرمائید",showGif:"none",isLoading:false,
                      typeAlert:2});
        localStorage.removeItem('lat');
        localStorage.removeItem('lng');
      });
    }else{
      this.setState({alertModal:true,alertMessage:"شما برای این آگهی موقعیتی ثبت نکردید",locationInfo: false ,imageUpload: true,showGif:"none",isLoading:false,typeAlert:3});
    }
  }

  skipSubmitLocationInfo = () => {
    localStorage.removeItem('lat');
    localStorage.removeItem('lng');
    this.setState({locationInfo: false ,imageUpload: true});
  }

  /* ----------------------- image upload functions ------------------------ */
  smallImageResized ;
  largeImageResized ;
  imageFile ;

  onChangeAddImage = (e) => {
    if(e.target.files[0]){
      if(e.target.files[0].size > 5000000){
        this.setState({alertModal:true,typeAlert:3,
          alertMessage:"حجم عکس نباید بیشتر از ۵ مگابایت باشد لطفا اقدام به کاهش حجم عکس نمائید یا با پشتیبان تماس حاصل نمائید تا در کاهش حجم عکس شما را یاری نماید"
        });
      }else{
        this.setState({showGif:"",isLoading:true});
        this.imageFile = e.target.files[0] ;
        this.smallImageResizer();
      }
    }
  }

  smallImageResizer = () => {
      try {
        Resizer.imageFileResizer(
          this.imageFile,
          400,
          400,
          "JPEG",
          40,
          0,
          (uri) => {
            this.smallImageResized = uri ;
            this.largeImageResizer();
          },
          "file",
        );
      } catch (err) {
        this.setState({alertModal:true,alertMessage:"فایل موردنظر عکس نمیباشد",showGif:"none",isLoading:false,typeAlert:2});
      }
  }

  largeImageResizer = () => {
    try {
      Resizer.imageFileResizer(
        this.imageFile,
        1920,
        1080,
        "JPEG",
        70,
        0,
        (uri) => {
          this.largeImageResized = uri ;
          this.postLargeImage();
        },
        "file",
      );
    } catch (err) {
      this.setState({alertModal:true,alertMessage:"فایل موردنظر مشکل دارد",showGif:"none",isLoading:false,typeAlert:2});
    }
  } 

  postLargeImage = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    const formData = new FormData();
    formData.append("image", this.largeImageResized);
    formData.append("listing_id", this.state.listingId);
    formData.append("type", "L");
    Promise.resolve(axios({
      url: baseUrl+"v1/listing/addImages",
      method: "POST",
      headers: {"Authorization":"bearer "+ token},
      data: formData
    }))
    .then((res) => {
      this.postSmallImage();
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شد و عکس بارگزاری نشد",showGif:"none",isLoading:false,typeAlert:2});
    });
  }

  postSmallImage = () => {
    let token = localStorage.getItem('tokenUserHomeInja');
    const formData = new FormData();
    formData.append("image", this.smallImageResized);
    formData.append("listing_id", this.state.listingId);
    formData.append("type", "S");
    Promise.resolve(axios({
      url: baseUrl+"v1/listing/addImages",
      method: "POST",
      headers: {
        "Authorization":"bearer "+ token,
        "Content-Type": "multipart/form-data"
      },
      data: formData
    }))
    .then((res) => {
      this.setState({alertModal:true,alertMessage:"عکس با موفقیت بارگزاری شد",showGif:"none",isLoading:false,typeAlert:1});
      this.getUploadedImages();
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"سامانه با مشکل مواجه شد و عکس بارگزاری نشد",showGif:"none",isLoading:false,typeAlert:2});
      this.getLargeImage();
    });
  } 

  getLargeImage = () => {
    Promise.resolve(axios({
      url: baseUrl+'v1/listing/getOne',
      method: 'GET',
      params: {
        listing_id: this.state.listingId
      }
    }))
    .then((res) => {
      let largeImages = res.data.data.large ;
      this.deleteLargeImage(largeImages[largeImages.length - 1].id);
    })
  }

  deleteLargeImage = (id) => {
    let token = localStorage.getItem('tokenUserHomeInja');
    Promise.resolve(axios({
      url: baseUrl+"v1/listing/deleteImage",
      method: "DELETE",
      headers: {"Authorization":"bearer "+ token},
      params : {
        small_id : 0 ,
        large_id : id
      }
    }))
  }

  getUploadedImages = () => {
    Promise.resolve(axios({
      url: baseUrl+'v1/listing/getOne',
      method: 'GET',
      params: {
        listing_id: this.state.listingId
      }
    }))
    .then((res) => {
      this.setState({uploadedImages:res.data.data});
    })
    .catch((err) => {
      this.setState({alertModal:true,alertMessage:"بروزرسانی تصاویر با مشکل مواجه شد",typeAlert:2});
    });
  }

  uploadedImageThumbnails = () => {
    if(this.state.uploadedImages){
      let result = [];
      this.state.uploadedImages.small.forEach((element,index) => {
        result.push(
        <Grid item xs={2} key={index}>
          <Grid
            sx={{bgcolor:"lightblue",height:{xs:"140px",lg:"200px"},border:"2px solid #eee",borderRadius:"4px",position:"relative",
            background:`url(${this.state.uploadedImages.small[index].path})` ,
            backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
          >
            <Button onClick={() => this.deleteImage(index)} disabled={this.state.isLoading}
              sx={{position:"absolute",left:"-2px",bottom:"-2px",minWidth:"40px",maxWidth:"40px",borderRadius:"0 10px 0 4px",
              bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"}}}
            >
            <DeleteOutlineOutlinedIcon sx={{color:"black !important"}} />
            </Button>
          </Grid>
        </Grid>
        )
      });
      return result;
    }else{
      return <Grid></Grid>
    }
  }
  
  deleteImage = (index) => {
    this.setState({showGif:"",isLoading:true})
    let token = localStorage.getItem('tokenUserHomeInja');
    Promise.resolve(axios({
      url: baseUrl+"v1/listing/deleteImage",
      method: "DELETE",
      headers: {"Authorization":"bearer "+ token},
      params : {
        small_id : this.state.uploadedImages.small[index].id,
        large_id : this.state.uploadedImages.large[index].id
      }
    }))
    .then((res) => {
      this.setState({alertModal:true,alertMessage:"عکس با موفقیت حذف شد",showGif:"none",isLoading:false,typeAlert:1});
      this.getUploadedImages();
    })
    .catch((err) => {
      this.getUploadedImages();
      this.setState({alertModal:true,alertMessage:"حذف عکس با مشکل مواجه شد",showGif:"none",isLoading:false,typeAlert:2});
    });
  }

  submitAdvertisement = () => {
    this.resetStates();
    this.setState({alertModal:true,typeAlert:1,
      alertMessage:"ثبت آگهی با موفقیت انجام شد بعد از بررسی و تائید کارشناسان آگهی منتشر می شود و وضعیت آگهی در آگهی های من قابل مشاهده است",
      imageUpload:false,notAvialablePage:true
    });
    this.checkUserInfo();
  }

  render() {
    return (
      <Grid  sx={{marginTop:"120px",width:'100%',marginRight:{md:'270px',display:"flex",alignItems:"center",flexDirection:'column'}}}>
      {/*---------------information not complete page-----------------*/}
      { this.state.notAvialablePage ?
          <Grid sx={{width:"100%"}}>
            <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
              <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                {this.state.notAvialablePageMessage}
              </Typography>
            </Grid>
          </Grid>
        : <Grid></Grid>
      }
      {/*---------------primary information form-----------------*/}
      { this.state.primaryInfo ?
          <Grid sx={{width:"100%"}}>
            <Typography variant="h5" sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"0 6%"}}>۱ - اطلاعات اولیه</Typography>
            <Grid container columns={{xs:4,sm:8,md:12,lg:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}> 
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.transactionOptions}
                  getOptionLabel={(option) => option.des}
                  renderInput={(params) => <TextField  {...params} placeholder='نوع معامله *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.des}
                    </li>
                  )}
                  onChange={this.onChangeTransaction}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.typeEstateOptions}
                  getOptionLabel={(option) => option.des}
                  renderInput={(params) => <TextField  {...params} placeholder='نوع ملک *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.des}
                    </li>
                  )}
                  onChange={this.onChangeTypeEstate}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.typeDocumentOptions}
                  getOptionLabel={(option) => option.des}
                  renderInput={(params) => <TextField  {...params} placeholder='نوع سند *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.des}
                    </li>
                  )}
                  onChange={this.onChangeTypeDocument}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={situation}
                  getOptionLabel={(option) => option.n}
                  renderInput={(params) => <TextField  {...params} placeholder='موقعیت ملک'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.n}
                    </li>
                  )}
                  onChange={this.onChangeSituation}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                  multiple
                  limitTags={1}
                  disableCloseOnSelect
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.stateOptions}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField  {...params} placeholder='استان *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={this.onChangeState}
                  value={this.state.stateSelected}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.cityOptions}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField   {...params} placeholder='شهر *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={this.onChangeCity}
                  value={this.state.citySelected}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                  disabled={this.state.cityDisabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.zoneOptions}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField  {...params} placeholder='منطقه *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={this.onChangeZone}
                  value={this.state.zoneSelected}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                  disabled={this.state.zoneDisabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.neighborhoodOptions}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField  {...params} placeholder='محله *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={this.onChangeNeighborhood}
                  value={this.state.neighborhoodSelected}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                  disabled={this.state.neighborhoodDisabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.currentSituationOptions}
                  getOptionLabel={(option) => option.des}
                  renderInput={(params) => <TextField {...params} placeholder='وضعیت کنونی ملک *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.des}
                    </li>
                  )}
                  onChange={this.onChangeCurrentSituation}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={this.state.typeKitchenOptions}
                  getOptionLabel={(option) => option.des}
                  renderInput={(params) => <TextField {...params} placeholder='نوع آشپزخانه *'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.des}
                    </li>
                  )}
                  onChange={this.onChangeKitchen}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeArea}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.areaSelected)}
                  label="متراژ *"
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeYear}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.yearSelected)}
                  label="سال ساخت *"
                  inputProps={{
                    maxLength: 4
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeParkingNum}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.parkingNumSelected)}
                  label="تعداد پارکینگ"
                  inputProps={{
                    maxLength: 2
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeBedroomNum}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.bedroomNumSelected)}
                  label="تعداد خواب"
                  inputProps={{
                    maxLength: 3
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeMasterRoomNum}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.masterRoomNumSelected)}
                  label="تعداد مستر"
                  inputProps={{
                    maxLength: 2
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangebathNum}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.bathNumSelected)}
                  label="تعداد حمام"
                  inputProps={{
                    maxLength: 2
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeFrenchWcNum}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.frenchWcNumSelected)}
                  label="تعداد سرویس بهداشتی فرنگی"
                  inputProps={{
                    maxLength: 2
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeFloorAll}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.floorAllSelected)}
                  label="تعداد طبقات"
                  inputProps={{
                    maxLength: 3
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                  id="ads-autocomplete-input"
                  className="ads-autocomplete"
                  options={floorOptions}
                  getOptionLabel={(option) => option.n}
                  renderInput={(params) => <TextField  {...params} placeholder='طبقه'/>}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.n}
                    </li>
                  )}
                  onChange={this.onChangeFloorNum}
                  clearText={this.state.clearText}
                  closeText={this.state.closeText}
                  openText={this.state.openText}
                  noOptionsText={this.state.noOptionsText}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeIranianWcNum}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(this.state.iranianWcNumSelected)}
                  label="تعداد سرویس بهداشتی ایرانی"
                  inputProps={{
                    maxLength: 2
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangePrice}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(separator(this.state.priceShow))}
                  label="قیمت"
                  placeholder="تومان"
                  disabled={this.state.priceDisabled}
                  inputProps={{
                    maxLength: 20
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeMortgage}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(separator(this.state.mortgageSelected))}
                  label="رهن"
                  placeholder="تومان"
                  disabled={this.state.mortgageRentDisabled}
                  inputProps={{
                    maxLength: 20
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <TextField 
                  fullWidth    
                  onChange={this.onChangeRent}
                  className="ads-textfield"
                  id="ads-textfield-input"
                  value={convertToPersianNumber(separator(this.state.rentSelected))}
                  label="اجاره"
                  placeholder="تومان"
                  disabled={this.state.mortgageRentDisabled}
                  inputProps={{
                    maxLength: 20
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>آسانسور</Typography>
                <Checkbox checked={this.state.elevatorSelected} onChange={this.onChangeElevator}/>
                <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>انباری</Typography>
                <Checkbox checked={this.state.storeSelected} onChange={this.onChangeStore}/>
              </Grid>
            </Grid>
            <Grid sx={{padding:"1% 8%"}}>
              <Typography sx={{fontFamily:"shabnam",marginBottom:"10px"}}>توضیحات تکمیلی :</Typography>
              <TextareaAutosize maxLength={1200} className="primaryinfo-textarea" id="primaryinfo-desc"
                style={{width:"100%",maxWidth:"100%",minWidth:"100%",minHeight:"150px",backgroundColor:"#F2F2F2",border:"none",borderRadius:"8px",fontFamily:"shabnam",fontSize:"15px",
                        padding:"10px",boxSizing:"border-box"}}
              />
            </Grid>
            <Grid sx={{textAlign:"center"}}>
              <Button onClick={this.checkPrimaryInfo} disabled={this.state.isLoading}
                sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:{xs:"50%",md:"30%"},minWidth:{xs:"50%",md:"30%"},margin:"auto",
                  fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
              >
                ذخیره اطلاعات اولیه
              </Button>
            </Grid>
          </Grid>
        :<Grid></Grid>
      }
      {/*---------------additional information form-----------------*/}
      { this.state.additionalInfo ?
          <Grid sx={{width:"100%"}}>
            <Typography variant="h5" sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"0 6%"}}>۲ - اطلاعات تکمیلی</Typography>
            <Grid container columns={{xs:4,sm:8,md:12,lg:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={facade}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='نمای ساختمان'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeFacade}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={kitchenEq}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='امکانات آشپزخانه'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeKitchenEq}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={kitchenCabinet}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='نوع کابینت'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeKitchenCabinet}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={cool_heat}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='تهویه مطبوع'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeCoolHeat}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={safety}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='امکانات امنیتی'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeSafety}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={welfare}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='امکانات رفاهی'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeWelfare}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Autocomplete
                    id="ads-autocomplete-input"
                    className="ads-autocomplete"
                    options={cover}
                    getOptionLabel={(option) => option.n}
                    renderInput={(params) => <TextField  {...params} placeholder='کفپوش ساختمان'/>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{marginRight: 8}}
                          checked={selected}
                        />
                        {option.n}
                      </li>
                    )}
                    onChange={this.onChangeCover}
                    clearText={this.state.clearText}
                    closeText={this.state.closeText}
                    openText={this.state.openText}
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                  />
              </Grid>
            </Grid>
            <Grid sx={{textAlign:"center"}}>
              <Button onClick={this.submitAdditionalInfo} disabled={this.state.isLoading}
                sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:{xs:"50%",md:"30%"},minWidth:{xs:"50%",md:"30%"},margin:"auto",
                  fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
              >
                ذخیره اطلاعات تکمیلی
              </Button>
            </Grid>
          </Grid>
        :<Grid></Grid>
      }
      {/*---------------location information form-----------------*/}
      { this.state.locationInfo ?
        <Grid sx={{width:"100%"}}>
          <Typography variant="h5" sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"0 6% 2% 0"}}>۳ - تعیین محل آگهی</Typography>
          <Grid sx={{width:"90%",height:{xs:"300px",md:"600px"},margin:"auto",borderRadius:"8px",overflow:"hidden"}}>
            <GetLocation />
          </Grid>
          <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
              <Grid item xs={12} md={6}>
                <Button onClick={this.submitLocationInfo} disabled={this.state.isLoading}
                  sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                  fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                >
                  ذخیره محل آگهی
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={this.skipSubmitLocationInfo} disabled={this.state.isLoading}
                  sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                  fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                >
                  رد کردن این بخش
                </Button>
              </Grid>
            </Grid>
        </Grid>
        :<Grid></Grid>
      }
      {/*---------------image upload form-----------------*/}
      { this.state.imageUpload ?
          <Grid sx={{width:"100%"}}>
            <Typography variant="h5" sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"0 6% 2% 0"}}>۴ - بارگزاری عکس</Typography>
            <Grid sx={{width:{xs:"95%",lg:"80%"},margin:"auto",border:"2px solid #eee",borderRadius:"8px",padding:{xs:"40px 3px",md:"40px 10px"},textAlign:"center"}}>
              <Grid container spacing={{xs:.5,md:2,lg:1}} columns={{xs:4,sm:6,md:8,xl:12}} justifyContent="center">
                { this.state.uploadedImages === null ?
                    <Grid item xs={2}>
                      <Grid sx={{height:{xs:"140px",lg:"200px"},border:"2px solid #eee",borderRadius:"4px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <AddCircleOutlineIcon sx={{fontSize:"50px"}} />
                      </Grid>
                    </Grid>
                  :<Grid></Grid>
                }
                {this.uploadedImageThumbnails()}
              </Grid>
              <Button variant="contained" component="label" disabled={this.state.isLoading}
                sx={{fontFamily:"shabnam",padding:{xs:"10px",md:"15px 50px"},borderRadius:"4px 15px 4px 15px",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",
                    fontWeight:"600",marginTop:"40px"}}
              >
                افزودن عکس +
                <input type="file" hidden accept="image/*" onChange={this.onChangeAddImage}/>
              </Button>
            </Grid>
            <Grid sx={{textAlign:"center"}}>
              <Button onClick={this.submitAdvertisement} disabled={this.state.isLoading}
                sx={{fontFamily:"shabnam",padding:{xs:"10px 30px",md:"15px 100px"},borderRadius:"4px",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",
                    fontWeight:"600",marginTop:"40px"}}>
                ثبت آگهی
              </Button>
            </Grid>
          </Grid>
        : <Grid></Grid>
      }
      <AlertModal open={this.state.alertModal} close={this.onCloseAlertModal} message={this.state.alertMessage} type={this.state.typeAlert}/>
      <GifLogo display={this.state.showGif}/>
      </Grid>
    )
  };
}

export default CreateListing;