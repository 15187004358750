import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article17/01.jpg";
import photo02 from "../../images/mag/article17/02.jpg";
import photo03 from "../../images/mag/article17/03.jpg";
import photo04 from "../../images/mag/article17/04.jpg";
import photo05 from "../../images/mag/article17/05.jpg";
import photo06 from "../../images/mag/article17/06.jpg";
import photo07 from "../../images/mag/article17/07.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article17 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "کافه همیشه برای دورهمی نیست. گاهی لازم است تا ادم خلوت کند و در این خلوت تنها کتاب را راه می دهد. کافه کتاب مکانی برای عاشقان کتاب است.";
    document.getElementsByTagName('meta')["keywords"].content = "کافی شاپ کتاب | بهترین کافه کتاب های تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[16].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[16].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[16].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[16].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[16].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[16].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کافه کتاب - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[16].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه همیشه
                مکانی برای دورهمی و قرارهای دوستانه نیست. گاهی افراد نیاز به خلوت و تنهایی دارند. چه بهتر که این خلوت با مطالعه و خواندن کتاب مورد علاقه مان
                همراه باشد. در این مواقع کافه کتاب بهترین گزینه برای شماست. پس با هومینجا همراه باشید تا چند مورد از بهترین کافه کتاب ها را به شما معرفی کنیم.
              </Typography>
              <Typography className="h2" variant="h2">
                <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%DA%A9%D8%A7%D9%81%D9%87%E2%80%8C%DA%A9%D8%AA%D8%A7%D8%A8" target="_blank">کافه کتاب چیست؟</LinkMui>
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه ای انباشته از
                کتاب که افراد می توانند در آن هم کتاب بخوانند و هم نوشیدنی و خوراکی سفارش دهند. اما بطور کلی می توان گفت کافه کتاب مکانی برای عاشقان کتاب است. از
                قدیم کافه های تهران محل گردآوری شعرا و نویسندگان بود. کافه نادری از جمله معروفترین این کافه ها بود که پذیرای افراد سرشناس در زمینه علم وادب بود.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه کافه کتاب:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با اینکه کافه کتاب در دنیا چند سالی است که برقرار است اما در ایران پدیده ای نوظهور می باشد. ا
                ولین کافه کتاب ایران در سال 1358 هجری شمسی تاسیس شد. پس از آن کافه کتاب های دیگری هم در تهران دایر شدند. اما بعد مدتی کافه کتاب ها پلمپ شدند.
                بعد از آن نخستین کافه کتاب در دهه 80 با مدیریت شهلا لاهیجی دایر شد. اوایل راه اندازی بدلیل تداخل شغلی با این صنف برخورد می شد اما امروزه کافه
                کتاب های بسیاری مشغول فعالیت هستند و تعدادشان هم رو به افزایش است. ما در این مقاله از هومینجا به بررسی تعدادی از این کافه کتاب ها می پردازیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">
                  کافه های قلیان دار تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                کافه کتاب دارالخلافه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کافه کتاب دارالخلافه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه کتاب دارالخلافه شهر تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این عمارت در <Link className="link" to="/Listings" target="_blank">منطقه 11</Link> و اواسط کوچه مظفری خواه
                واقع شده است. نامگذاری این کافه مربوط به دوره آقا محمد خان قاجار می باشد. این عمارت متعلق به مهین الملک وزینانی بود.  سال ها بدون استفاده
                باقی مانده بود و تبدیل به مخروبه شده بود. بهزاد یعقوبی موسس این کافه آن را بازسازی نمود و در سال 1397 با نام دارالخلافه بازگشایی شد.  دارالخلافه
                در بین اهالی قلم بسیار شناخته شده است. در این کافه بیش از ۳,۵۰۰ عنوان کتاب‌ در زمینه‌های تاریخ، رمان، ادبیات موجود است. همچنین مجموعه‌های
                چاپی کمیاب همچون روزنامه‌های تربیت، دولت علیه، صوراسرافیل، شرف و شرافت، مراه السفر، وقایع اتفاقیه به‌صورت رایگان به علاقه‌مندان امانت داده می‌شوند.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس:  خیابان امام خمینی، خیابان شیخ هادی، کوچه مظفری خواه  (<LinkMui className="link" href="https://goo.gl/maps/9u7HyfMrxqQowY598" target="_blank">مشاهده روی نقشه</LinkMui>)
                </li>
                <li>
                  شماره تماس: ۰۲۱۶۶۷۲۹۴۰۶
                </li>
                <li>
                  ساعات کاری: همه‌روزه از ساعت ۸:۳۰ تا ۲۳
                </li>
                <li>
                  اینستاگرام: <LinkMui className="link" href="https://www.instagram.com/darolkhelafebookcafe/" target="_blank">darolkhelafebookcafe@</LinkMui>
                </li>
              </ul>
              <Typography className="h3" variant="h3">
                کافه کتاب رف
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کافه کتاب رف - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه کتاب رف نیاوران تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رف در لغت به‌معنی کفه‌های قفسه کتاب
                و طرز و ترتیب چیدمان کتاب‌های قفسه می باشد. این کافه یکی از بهترین کافه کتاب های تهران می باشد.
                کافه رف در یکی از بن‌بست‌های <Link className="link" to="/tehran-zone-1" target="_blank">شمال تهران</Link> واقع
                در خیابان نیاوران می باشد. قدمت بنای این کافه مربوط به 80سال قبل است. میز و صندلی‌های چوبی، پنجره‌های رنگی، تابلوهای قدیمی و گلدان‌های رنگارنگ
                حال و هوای عجیبی به این کافه داده است. علاوه بر کتاب خوانی در اتاق های طبقه بالای این مجموعه تعدادی بازی مجاز نیز برای مشتریان تعبیه شده است.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس: <Link className="link" to="/listings/buy_tehran_niyavaran" target="_blank">نیاوران</Link>، خیابان باهنر، بعد از مژده، بن‌بست شریعتی، پلاک یک
                  (<LinkMui className="link" href="https://goo.gl/maps/UMZstuvrTw9RXCbk9" target="_blank">مشاهده روی نقشه</LinkMui>)
                </li>
                <li>
                  شماره تماس: ۰۲۱۲۲۷۳۴۰۹۱
                </li>
                <li>
                  ساعات کاری: همه‌روزه از ساعت ۱۲ تا ۲۰
                </li>
                <li>
                  اینستاگرام: <LinkMui className="link" href="https://www.instagram.com/cafe.raf/" target="_blank">cafe.raf@</LinkMui>
                </li>
              </ul>
              <Typography className="h3" variant="h3">
                کافه شهر فرشته ها
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کافه شهر فرشته ها - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه کتاب شهر فرشته ها
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه یکی از مراکز فروش جامع لوازم تحریر، کتاب و صنایع دستی محسوب می شود. در مجموعه فرشته بخشی نیز برای کافه تعبیه شده است. کافه
                شهر فرشته ها در مکانی روباز و دنج قرار گرفته است. علاوه بر کتاب خدمات دیگری همچون ثبت سفارش تلفنی و آنلاین و ارسال پستی، فروش انواع گل و گیاه
                و تراریوم نیز ارائه می‌شود. دورهمی های نقد و معرفی کتاب با حضور افراد سرشناس و نویسندگان کتاب‌ها از دیگر فعالیت‌های شهر کتاب فرشته به شمار می آید.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }} >
                <li>
                  آدرس:  خیابان شریعتی، نرسیده به پل صدر، پلاک ۱۶۷۱ (<LinkMui className="link" href="https://goo.gl/maps/KVP5BLvPHcKLZWhb6" target="_blank">مشاهده روی نقشه</LinkMui>)
                </li>
                <li>
                  شماره تماس: ۰۲۱۲۲۶۰۷۳۴۲
                </li>
                <li>
                  ساعات کاری: شنبه تا پنجشنبه از ساعت ۱۰ تا ۲۲
                </li>
                <li>
                  اینستاگرام: <LinkMui className="link" href="https://www.instagram.com/fereshtehbookcity/" target="_blank">fereshtehbookcity@</LinkMui>
                </li>
              </ul>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مهم در مورد خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کافه کتاب ققنوس
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کافه کتاب ققنوس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه کتاب ققنوس خیابان وصال تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه دریک خانه‌ دو طبقه
                قدیمی بازسازی شده  قرار دارد . این مرکز فرهنگی دارای قدمت بالای 60 سال می باشد. طبقه همکف آن به گالری و فروشگاه و نمایشگاه کتاب، لوازم التحریر و
                گلدان‌های طبیعی اختصاص داده شده است. فروشگاه صنایع دستی در طبقه اول این مجموعه می باشد. کافه ققنوس در طبقه دوم  قرار دارد. برخورد دوستانه و در عین
                حال حرفه‌ای پرسنل کافه  ققنوس سبب جلب رضایت مشتریان شده است. این کافه در محیطی پر گل و گیاه، با میز و نیمکت‌های چوبی و راحت پذیرای افراد می باشد.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس:  خیابان وصال، کوچه شفیعی، جنب خانه سینما، پلاک یک (<LinkMui className="link" href="https://goo.gl/maps/3vBFfKyZiNbw3ZyY7" target="_blank">مشاهده روی نقشه</LinkMui>)
                </li>
                <li>
                  شماره تماس: ۰۲۱۶۶۴۰۳۳۸۶
                </li>
                <li>
                  ساعات کاری: یک‌شنبه تا جمعه از ساعت ۸:۳۰ تا ۲۲ و شنبه از ساعت ۱۵ تا ۲۲
                </li>
                <li>
                  اینستاگرام: <LinkMui className="link" href="https://www.instagram.com/qoqnoosbookcafe/" target="_blank">qoqnoosbookcafe@</LinkMui>
                </li>
              </ul>
              <Typography className="h3" variant="h3">
                کافه کتاب فراموشی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کافه کتاب فراموشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه کتاب فراموشی خیابان سهروردی تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مجموعه واقع در منطقه هفت شهریور ماه سال ۱۳۹۶ افتتاح شد. هدف از تاسیس این کافه ایجاد محفلی گرم و صمیمی برای دوست‌داران
                فرهنگ، هنر می باشد. نمای آجرهای سه‌سانتی سفید رنگ، پنجره‌های قرمز و لوگوی کافه فراموشی، سبب تمایز این ساختمان از دیگر ساختمان های این منطقه
                شده است. این که چرا نام این کافه فراموشی است سوال بسیاری از مشتریان این کافه است. با این وجود موسس آن فلسفه نامگذاری را بازگو نمی کند تا
                هر کس  برداشت خود را داشته باشد. برخورد حرفه ای پرسنل و طراحی و نورپردازی محیط، این کافه را برای دوست‌داران کتاب تبدیل به محیطی دنج و محبوب
                کرده است. برگزاری جشن‌های باستانی و نشست‌های انجمن ادبی، اکران و نقد فیلم، مراسم یادمان بزرگان شعر و ادب از دیگر فعالیت های این کافه می باشد.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس:  سهروردی جنوبی، خیابان اورامان، نبش کوچه اسلامی
                  (<LinkMui className="link" href="https://neshan.org/maps/@35.796950,51.446074,15.0z,0.0p/places/91477d2b10a9e547e70b4138774a057d" target="_blank">مشاهده روی نقشه</LinkMui>)
                </li>
                <li>
                  شماره تماس:  ۰۲۱۸۸۳۴۸۵۵۶، ۰۲۱۸۸۳۲۴۶۸۶ و ۰۹۳۸۷۴۲۴۲۶۶
                </li>
                <li>
                  ساعات کاری: شنبه تا پنجشنبه از ساعت ۹:۳۰ تا ۲۳:۴۵ و جمعه از ساعت از ساعت ۱۲ تا ۲۳:۴۵
                </li>
                <li>
                  اینستاگرام: <LinkMui className="link" href="https://www.instagram.com/cafe_faramooshi/" target="_blank">cafe_faramooshi@</LinkMui>
                </li>
              </ul>
              <Typography className="h3" variant="h3">
                کافه کتاب کربن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="کافه کتاب کربن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه کتاب کربن واقع در اندرزگو تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه کربن دارای  فضای 400 مترمربع است . این کافه در منطقه اندرگو تهران واقع شده است. این
                کافه سال 1398 با حضور  با حضور جمعی از اهالی فرهنگ و هنر بازگشایی شد. در کتاب فروشی کربن۲۰ هزار عنوان کتاب، نوشت‌افزار و بازی‌های فکری عرضه
                می‌شود. این مکان تنها تمرکزش فروش کتاب نیست بلکه برپایی جشن‌های امضاء کتاب، بزرگداشت‌ها و مناسبت‌های فرهنگی از دیگر اهداف این مجموعه می باشد.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس:   <Link className="link" to="/Listings/buy_tehran_andarzgoo" target="_blank">اندرزگو</Link>، جنب مسجد مفتخر، پلاک 63
                  (<LinkMui className="link" href="https://goo.gl/maps/EjJYZ2HHFQM7tcq88" target="_blank">مشاهده روی نقشه</LinkMui>)
                </li>
                <li>
                  شماره تماس:  ۰۲۱۲۲۲۰۴۹۳۰
                </li>
                <li>
                  ساعات کاری:  همه‌روزه از ساعت ۹ تا ۲۳
                </li>
                <li>
                  اینستاگرام: <LinkMui className="link" href="https://www.instagram.com/carbon.b.c/" target="_blank">carbon.b.c@</LinkMui>
                </li>
              </ul>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/book-cafe" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/book-cafe" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/book-cafe" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article17;