import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article18/01.jpg";
import photo02 from "../../images/mag/article18/02.jpg";
import photo03 from "../../images/mag/article18/03.jpg";
import photo04 from "../../images/mag/article18/04.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article18 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "حکیمیه از شرقی ترین مناطق تهران است ودارای آب و هوای بسیار مطلوبی می باشد.با هومینجا همراه باشید تا اطلاعات بیشتری از این محله بدست آورید.";
    document.getElementsByTagName('meta')["keywords"].content = "آشنایی با محله حکیمیه | امکانات محله حکیمیه | قیمت خانه در محله حکیمیه"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[17].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[17].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[17].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[17].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[17].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[17].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="محله حکیمیه - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[17].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد خرید آپارتمان یا
                اجاره ملکی را در محله حکیمیه دارید اما شناخت کافی از این محل را ندارید ، وبلاگ هومینجا این بار شما را با شرقی ترین محله تهران آشنا می کند .
                <br />
                محله حکیمیه شرقی ترین محله در <Link className="link" to="/tehran-zone-4" target="_blank">منطقه 4 تهران</Link> است . این محله به دلیل نزدیکی به پارک جنگلی سرخه حصار ، محدوده جنگلی
                غزال ، کوه های لشگرک ، لواسانات ، و ارتفاعات تهران از آب و هوای بسیار مطلوبی برخوردار است . بی شک این محله بهترین آب و هوای شرق تهران را دارد .
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                روستایی به نام چاهک
                که <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%85%DB%8C%D8%B1%D8%B2%D8%A7_%D8%B9%D9%84%DB%8C%E2%80%8C%D9%86%D9%82%DB%8C%E2%80%8C%D8%AE%D8%A7%D9%86_%D8%AD%DA%A9%DB%8C%D9%85%E2%80%8C%D8%A7%D9%84%D9%85%D9%85%D8%A7%D9%84%DA%A9" target="_blank" rel="noreferrer">میرزا علی نقی خان حکیم الممالک</LinkMui> آن را
                خریداری کرد . او پزشک ،نویسنده ، مترجم و یکی از دولتمردان دوره قاجار بود . که پس از  ساختن عمارت و باغ و آبادانی روستا آنجا را  حکیمیه را نامید .
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین بخش محله حکیمیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شمال غربی حکیمیه بهترین و مهمترین بخش این محله
                است . این بخش از محبوبیت خاصی برخورداراست . شهرک شهید بهشتی ، سازمان آب ، خیابان چمن آرا ، خیابان شقایق ، و سروستان دوم در این بخش قرار دارند .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                امکانات محله حکیمیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دسترسی آسان به امکانات رفاهی برای ساکنیناین محله  از مزایای مهم خریداری خانه در این محله میباشد .
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز آموزشی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="محله حکیمیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  درب اصلی دانشگاه عباسپور
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود مراکز آموزشی فراوان در این محله نگرانی ساکنین این محله را از بین می برد  .
                <br />
                مدرسه المهدی ، 15 خرداد،فرزانگان ، حکمت ، سروش نوین ، آرمان ، امیدان فردا ، امام خمینی
                <br />
                دانشگاه شهید بهشتی ، <LinkMui className="link" href="https://www.sbu.ac.ir/web/ace" target="_blank" rel="noreferrer" >پردیس شهید عباسپور</LinkMui> ،
                دانشگاه آزاد اسلامی واحد تهران شرق ، دانشگاه امام حسین ، <LinkMui className="link" href="دانشگاه پیام نور واحد تهران شرق" target="_blank">دانشگاه پیام نور واحد تهران شرق</LinkMui>
                ، دانشکده هوا فضا دانشگاه صنعتی خواجه نصیرالدین طوسی، پردیس شهید چمران ، دانشگاه فرهنگیان
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز درمانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مزیت مهم یک محله دسترسی
                آسان به مراکز بهداشتی و درمانی است . مهترین مراکز درمانی این محله مرکز خدمات جامع سلامت حکیمیه ، درمانگاه ایثار ،مجتمع پزشکی کوثر میباشند .
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز مذهبی و دینی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود مساجد و حسینیه های مختلف امتیاز خوبی است برای ساکنین محله است .
                <br />
                مسجد سید الشهداء ، مسجد جامع الزهرا ، مسجد نور الاصفیا ، مسجد حضرت رقیه ، مسجد حضرت امیر
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز ورزشی و تفریحی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="مجموعه ورزشی حکیمیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مراکز فرهنگی ورزشی محله حکیمیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بیشترین دغدغه جوانان نزدیکی به مکانهای ورزشی و تفریحی است .زندگی در این محله  این مشکل را کاملا برطرف می سازد.
                <br />
                <LinkMui className="link" href="https://progym.ir/hakimeh-sports-complex/" target="_blank" rel="noreferrer">مجموعه فرهنگی ورزشی حکیمیه</LinkMui>
                ، استخر تربیت معلم ، مجتمع ورزشی پردیس ، مجموعه ورزشی مصطفی خمینی ، استخر زمزم ، باشگاه آرین شرق ، سپهرداد، باشگاه خلیج فارس، باشگاه نام آوران
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز خرید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از آیتم های مهم در انتخاب مسکن دسترسی آسان به مراکز خرید میباشد . وجود فروشگاهها و مراکز خرید متعدد در این محله نیاز ساکنین این محل را تامین میکند .
                <br />
                بازارچه کوثر ، بازارچه الزهرا   در بلوار بهار
                <br />
                مرکز خرید ارکیده واقع در خیابان ارکیده دوم
                <br />
                مجتمع تجاری صدف در خیابان شکوفه
                <br />
                مجتمع تجاری نور ، پاساژ مروارید ،  بازار ولی عصر
              </Typography>
              <Typography className="h2" variant="h2">
                خطوط حمل و نقل
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله حکیمیه فاقد ایستگاه مترو است . ولی میتوان از ایستگاههای مترو فرهنگسرا در جوادیه تهرانپارس و ایستگاه مترو تهرانپارس جهت دسترسی به سایر محلات استفاده کرد .
                <br />
                خطوط BRT چهارراه تهرانپارس
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/buy_tehran_hakimieh" target="_blank">
                  خرید و فروش ملک آپارتمان در حکیمیه
                </Link>
                <br />
                <Link className="link" to="/Listings/rent_tehran_hakimieh" target="_blank">
                  رهن و اجاره ملک آپارتمان در حکیمیه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بوستان و فضای سبز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود بوستان و فضای سبز متعدد محله حکیمیه را به بهترین آب و هوا تبدیل کرده است .
                <br />
                بوستان جنگی ساحل ، پارک نور ، پارک اکرامی ، پارک صلح و دوستی ، پارک ریحانی
              </Typography>
              <Typography className="h2" variant="h2">
                خرید و فروش  در محله حکیمیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="فضای سبز حکیمیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بوستان حکیمیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                انتخاب محله حکیمیه میتواند اهدافی را دنبال کند :
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  سکونت : اگر این محله را برای زندگی انتخاب کرده اید بهترین گزینه می باشد. این محله با داشتن آب و هوای خنک و سالم بهترین محله برای سکونت می باشد .
                </li>
                <li>
                  سرمایه گذاری : انتخاب این محله
                  برای سرمایه گذاری یک ایده بسیار موفق است . محله حکیمیه در حال رشد و ترقی میباشد . اکثریت ساختمان های  این محله در بافت جدید  میباشند .
                </li>
                <li>
                  دسترسی به مناطق شرق تهران : موقعیت حکیمیه بعنوان شرق ترین محله تهران این امکان را دارد که راحتترین مسیر
                  را به نقاط شرقی دارند . کسبه ای که محل کار آنها مناطق شرق تهران مانند جاجرود است . بیشتر این منطقه را برای سکونت انتخاب میکنند .
                </li>
              </ul>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گر قصد خرید ملک در  حکیمیه را دارید این مقاله از سایت
                هومینجا را بخوانید تا به شما کمک کند بهترین انتخاب را داشته باشید . همچنین  شما میتوانید از مشاورین هومینجا برای انتخاب بهتر خوب کمک بخواهید .
              </Typography>
              <Typography className="h2" variant="h2">
                جمع بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله حکیمیه بدلیل داشتن آب و هوای پاک ، دسترسی آسان ، امکانات خوب ، موقعیت مناسب میتواند یکی از گزینه های ما برای انتخاب خانه باشد
                . چنانچه قصد خرید و فروش آپارتمان در این محله را داشتید می توانید از مشاورین سایت هومینجا کمک بخواهید تا به شما در انتخاب بهتر کمک کنند .
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Hakimiyeh" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Hakimiyeh" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Hakimiyeh" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article18;