import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article26/01.jpg";
import photo02 from "../../images/mag/article26/02.jpg";
import photo03 from "../../images/mag/article26/03.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article26 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "در ایران به اشخاصی که در حوزه معاملاک املاک فعالیت میکنند مشاور املاک گفته می شود که در این مقاله به بیان برخی از وظایف آنان می پردازیم.";
    document.getElementsByTagName('meta')["keywords"].content = "مشاور املاک چیست | به چه کسی مشاور املاک میگویند | مشاور املاک یعنی چی"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[25].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[25].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[25].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[25].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[25].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[25].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="مشاور املاک کیست - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[25].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="h2" variant="h2">
                مشاور املاک :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معنای لغوی مشاور املاک به انگلیسی real estate می باشد که به فرآیند مشاوره در زمینه معاملات املاک و مستغلات اطلاق میشود.
                <br />
                به شخص ارایه دهنده مشاوره در زمینه خرید و فروش خانه real estate agent گفته میشود و
                بنگاه های معاملات ملک یا آژانس هاس املاک درسطح شهر هم real estate agency نام دارند.
                <br />
                در ایران به اشخاصی که در حوزه معاملاک املاک فعالیت میکنند مشاورین املاک گفته می شود که در ذیل برخی از وظایف و مسولیتهای آنان بیان شده است.
              </Typography>
              <Typography className="h2" variant="h2">
                مشاور املاک کیست و تعریف موقعیت شغلی مشاور املاک :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="مشاور املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مشاور املاک کیست
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کلمه مشاور املاک از دو لغت معنادار تشکیل شده است. مشاور به معنای شخص حقیقی
                یا حقوقی که به واسطه تخصص و تجربه قابلیت راهنمایی در زمینه مربوطه را داراست. املاک هم به عنوان کلمه دوم، حوزه تخصصی مشاور را اعلام می کند.
                <br />
                مشاور کسی است که با آرامشی مثال زدنی به
                صحبت های افراد گوش فرا می دهد و دارای صبر و حوصله ی فراوان است. مشاور نیاز افراد را میشنود و براساس بودجه آنها بهترین ملک را پیشنهاد میدهد.
                <br />
                او کسی است که با تجربه و دیدگاه تخصصی خود در حوزه املاک و مستغلات، می تواند به
                متقاضیان این امر، چه خریدار و فروشنده و چه موجر و مستاجر، مشاوره تخصصی بدهد تا فرآیند خرید و فروش و یا رهن و اجاره را برای متقاضیان آسان گردد.
                <br />
                گرچه ممکن است یک مشاور املاک به شکل مستقل هم کار کند. اما مشاوران معمولاً تحت پوشش یک بنگاه معاملات ملکی، به عنوان نماینده مشتریان کار می‌کنند.  مشاوران و
                آژانس ها از طرف دولت مجوز دارند که به مراجعان خود مشاوره تخصصی ارایه دهند. قابلیت عقد قرارادادهای ملکی فقط برای بنگاههای دارا مجوز امکان دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                وظیفه مشاور املاک چیست :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از تعریف شغل مشاور املاک، حال نوبت به شرح وظایف کلی آن میرسد.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="وظیفه مشاور املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وظایف مشاور املاک
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                احراز اشخاص و مدارک :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از اولین وظایف مشاورین املاک با تجربه،
                احراز هویت دوطرف معامله است. از آنجایی که تعداد کلاهبرداری های ملکی روز به روز بیشتر میشود، مسئولیت مشاور املاک برای جلوگیری از تقلب بیشتر نمایان میشود.
                <br />
                مشاور املاک پس از احراز شخص مالک و یا وکیل آن، مدارک مربوط به
                ملک را به صورت دقیق مورد بررسی قرار میدهد. بررسی کارشناسانه اسناد مربوط به ملک از جمله وظایف اصلی مشاوران ملکی و حقوقی بنگاههای معاملاتی کشور می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                رویکرد صادقانه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مشاور املاک به واسطه مقام خود وظیفه دارد
                به صورت کاملا صادقانه هر دو طرف معامله را از تمام شرایط مطلع سازد. مشاور املاک نباید به هر قیمتی به فکر عقد قرارداد باشد و باید تمرکز خود را در ارتباط
                صادقانه با مشتری معطوف سازد. مشاورین املاک به خوبی می دانند که مردم پول، زمان و اعتماد خود را در اختیار مشاور قرار می دهند تا بهترین مشورت را دریافت کنند.
                <br />
                مشاورین املاک همواره باید توضیحات درستی به مشتری بدهند و متقاضی را معطل و
                سردرگم نکنند. درصورتی که شما بهترین خود باشید از هر مشتری، هزاران مشتری بوجود می آید. در واقع رفتار شما موجب تبلیغ و حتی درآمد بیشتر کار شما می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                ارزیابی دقیق :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از اصلی ترین انتظاراتی که مردم از مشاور املاک خود دارند،
                ارزیابی تخصصی و دقیق ملک آنهاست. لازم به ذکر است ارزیابی نیازهای خریداران و انطباق آنها با فایل های موجود، یکی دیگر از مزایای مشاور املاک حرفه ای می باشد.
                <br />
                تسلط مشاور املاک به قیمت انواع ملک مسکونی، تجاری و اداری محل خود و قیمت گذاری کارشناسانه او می تواند در کسب و کار خود برتری قابل توجهی ایجاد کند.
                <br />
                مشاور املاک معتمد با ارایه تمامی مزایا و معایب املاک می تواند بهترین همراه در معاملات مسکن شما باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                جمع بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از وب سایت املاک هومینجا تلاش کردیم مفاهیم اولیه مشاور املاک را شرح دهیم .
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مشاورین املاک")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مشاورین املاک
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/real-estate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/real-estate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/real-estate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article26;