import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article59/01.jpg";
import photo02 from "../../images/mag/article59/02.jpg";
import photo03 from "../../images/mag/article59/03.jpg";
import photo04 from "../../images/mag/article59/04.jpg";
import photo05 from "../../images/mag/article59/05.jpg";
import photo06 from "../../images/mag/article59/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article59 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "ویژگی های شهرک اکباتان فراتر از توقع اغلب افراد است. ما در هومینجا تمام ویژگی‌ها و امکانات شهرک اکباتان را مورد بررسی قرار می دهیم.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "بررسی کامل شهرک اکباتان | معرفی صفر تا صد شهرک اکباتان | همه چیز درباره شهرک اکباتان | قیمت آپارتمان در شهرک اکباتان | فلسفه ساخت شهرک اکباتان | ماجرای ساخت شهرک اکباتان | افراد مشهور شهرک اکباتان | مشکلات شهرک اکباتان | امکانات شهرک اکباتان | شرایط زندگی شهرک اکباتان | شهرک اکباتان از نمای بالا "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[58].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[58].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[58].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[58].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[58].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[58].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="شهرک اکباتان - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[58].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید برایتان جالب باشد بدانید که چرا خیلی از افراد تمایل دارند تا در شهرک اکباتان زندگی کنند. ما در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> به بررسی این شهرک می پردازیم و تمامی نکات جذاب در مورد این شهرک را مورد بررسی قرار می دهیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                شهرک اکباتان کجاست
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="نمای بالا شهرک اکباتان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نما از بالای اکباتان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین محله های غرب تهران شهرک اکباتان می باشد. واژه ی اکباتان از واژه های قدیمی فارسی محسوب می شود. اکباتان یا همان هگمتانه نام قدیم همدان است که به معنی محل گردهمایی می باشد. این شهرک در ناحیه 6 شهرداری و <Link className="link" to="/tehran-zone-5" target="_blank">  منطقه 5 تهران </Link> واقع است. این شهرک در دوران پهلوی ساخته شد. ساختمان های این شهرک از استحکام بالایی برخوردار هستند و در مقابل زلزله تا 9 ریشتر مقاومت می کنند. در صورتی که اکثر آپارتمان و <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">  برج های لوکس </Link> تهران تا زلزله 7 ریشتری مقاوم هستند.  سازه های این شهرک از مقاوم ترین سازه های زمان خود بود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-district-22" target="_blank">
                  بیشتر بخوانید : معرفی کامل منطقه ۲۲ تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مسئله فرسودگی مجتمع های مسکونی یکی از معضلات پیش روی شهرسازان و همچنین مالکین واحدهای مجتمع های مسکونی است ولی به دلیل معماری خاص این شهرک، عمر مفید خانه های اکباتان حداقل ۱۰۰ سال و حداکثر ۳۰۰ سال تخمین زده شده است. این بدین معناست که تا ۳ الی ۴ نسل می توانند در این شهرک زندگی کنند. اکباتان اولین شهرک و مجموعه ساختمانی در دنیا است که ۱۰۰ درصد ساختمان و ۱۰۰ درصد فضای سبز می باشد. ۲ میلیون و ۴۰۰ هزار متر مربع فضای سبز و ۲ میلیون و ۴۰۰ هزار متر مربع فضا به ساختمان اختصاص دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                وسعت شهرک اکباتان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وسعت این شهرک 5/94 کیلومتر مربع می باشد. بزرگی ای شهرک را اینگونه فرض کنید که 720 <LinkMui className="link" href="https://azadisportcomplex.com" target="_blank">  ورزشگاه آزادی </LinkMui> در آن جا می شود. این شهرک با گذشت 45 سال همچنان بعنوان بزرگترین شهرک مسکونی ایران محسوب می شود. مساحت این شهرک 200 هکتار می باشد که 15675 واحد مسکونی را در سه فاز در خود جای داده است. همچنین شهرک اکباتان از ۳ فاز تشکیل شده است که فاز ۲ شهرک نسبت به فاز ۱ و ۳ بزرگ‌تر می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                جمعیت شهرک اکباتان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شهرک جمعیتی بالغ بر 45 هزار نفر دارد. البته برخی جمعیتی معادل 100 هزار نفر را برای اکباتان تخمین زدند که کمی دور از ذهن می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه شهرک اکباتان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="تاریخچه شهرک اکباتان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاریخچه ساخت اکباتان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرک اکباتان را می توان اولین شهرک مدرن تهران دانست. همچنین این شهرک بزرگترین شهرک مسکونی خاورمیانه در زمان خود بود. برخی مهندسان آمریکایی را سازنده این شرکت می دانند و برخی دیگر، «ساخت» این شهرک را بوسیله شرکت‌های خارجی معرفی می‌کنند. اما طراحی و معماری این شهرک در سال 1340 توسط رحمان گلزار شبستری انجام گرفت. <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%B1%D8%AD%D9%85%D8%A7%D9%86_%DA%AF%D9%84%D8%B2%D8%A7%D8%B1" target="_blank">  رحمان گلزار </LinkMui> معمار برجسته ایرانی از دانش آموختگان <LinkMui className="link" href="https://finearts.ut.ac.ir" target="_blank">  دانشکده هنرهای زیبا </LinkMui> دانشگاه تهران بود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Chitgar-Neighborhood" target="_blank">
                  بیشتر بخوانید : چیتگر تهران کجاست؛ تمام اطلاعاتی که در مورد محله چیتگر نیاز دارید
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                داستان جالبی که پشت ساخت این شهرک مطرح است بدین منوال است که آقای رحمان گلزار یک آپارتمانی را با چهار طبقه دو واحدی برای خود می سازد. طراحی خاص این آپارتمان به نحوی بود که یکی از ملاک های بزرگ تهران از این ملک خوشش می آید و به دو برابر قیمت یعنی یک میلیون تومان این آپارتمان را از آقای گلزار خریداری می کند. از این مبلغ، 800 هزار تومان را به صورت نقدی به آقای گلزار پرداخت می کند و مابقی را با یک زمین 100 هزار متری که محل فعلی شهرک اکباتان می باشد، معاوضه می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از بازدید از زمین فکر شهرسازی به ذهن مهندس گلزار خطور می کند. با خرید 450 هزار متر دیگر از زمین های اطراف آن از نظام السطنه که از رجال قاجار بود این زمین ها را گسترش می دهد. البته رحمان گلزار برای خرید این زمین ها از دوستی به نام حاج عباس مس فروش 5 میلیون تومان می گیرد و بدین ترتیب عباس مس فروش شریک آقای گلزار در این شهرک می شود. اما در همان ابتدای کار شراکت آن ها به اتمام می رسد.
              </Typography>
              <Typography className="h3" variant="h3">
                ادعای غلط در مورد الگوبرداری شهرک از یک طرح خارجی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی اولیه این شهرک  با الهام از خیابان Avenue Foch که یکی از زیباترین خیابان های فرانسه بود؛ انجام شد. اما بعد از سفر مهندس گلزار به دانمارک جهت همکاری با یک شرکت دانمارکی فعال در زمینه بتن که با دیدن ساختمان شرکت دانمارکی، تصمیم عوض شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دانمارکی ها برای ساخت بتن چندین نفر را از آمریکا آوردند که در تکنولوژی «بتن لخت» که آن زمان در ایران نبود کمک کردند. اما برای فاز دوم شهرک نقشه توسط یک مهندس کره ای طراحی شد و طراحی فاز دو از فاز یک کمی متفاوت تر است.  در جریان انقلاب اموال مهندس گلزار به مصادره دولت درامد و شرکت نوسازی و ساختمان اکباتان تکمیل پروژه را بر عهده گرفت. ساخت تسهیلات ادامه پیدا کرد و فاز سوم تسهیلات در اوایل دهه هفتاد به اتمام رسید و به متقاضیان واگذار گردید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در مراسم 133 سالگی اتاق بازرگانی تهران از گلزار به عنوان سازنده شهرک اکباتان و بنیانگذار ساختمان سازی نوین ایران قدردانی شد.
              </Typography>
              <Typography className="h2" variant="h2">
                دسترسی به شهرک اکباتان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="مترو شهرک اکباتان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ایستگاه مترو اکباتان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرک اکباتان از شمال به بزرگراه شیخ فضل اله نوری از جنوب به جاده مخصوص کرج دسترسی دارد. همچنین این شهرک از غرب به نمایشگاه صنایع هوایی و از شرق به شهرک <Link className="link" to="/Listings/buy_tehran_apadana" target="_blank">  آپادانا </Link> محدود می شود. این شهرک با محله های بلوار فردوس، <Link className="link" to="/Listings/buy_tehran_sazmanebarname" target="_blank">  سازمان برنامه </Link>، شهرک آزادی و <Link className="link" to="/Listings/rent_tehran_shahrakefarhangiyan" target="_blank">  شهرک فرهنگیان </Link> همسایه هستند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Piroozi-street" target="_blank">
                  بیشتر بخوانید :  همه چیز در مورد محله پیروزی تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین برای دسترسی به این شهرک می توان از حمل و نقل عمومی نیز بهره برد. خط مترو و ایستگاه اکباتان به راحتی شما را به این محله می رساند. همچنین خطوط اتوبوسرانی میدان آزادی نیز دسترسی به این محله را سهولت می بخشد. همچنین یکی از راه های دسترسی به این محله استفاده از تاکسی های خطی میدان <Link className="link" to="/Azadi" target="_blank"> آزادی </Link> می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                امکانات رفاهی اکباتان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شهرک به صورت ایالتی جداگانه از تهران اداره می شود. تمامی امکانات مورد نیاز ساکنین از جمله بیمارستان، مدرسه، مراکز تفریحی و خرید در این شهرک فراهم است. این موضوع یکی از دلایل اصلی آسایش و آرامش ساکنین این شهرک می باشد. بنابراین افراد ساکن در این شهرک برای تامین نیازهای خود لزومی به خروج از شهرک ندارند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی شهرک به گونه ای است که یک خانم خانه‌دار برای خرید نیازی به سوار ماشین شدن و تحمل ترافیک ندارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                حتی مدارس به گونه ای جانمایی شده است که بچه‌ها بدون آنکه از خیابان عبور کنند، با عبور از فضای سبز به مدرسه می روند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی پنجره‌های شهرک از جنس آلومینیوم است و شیشه دوبل دارد. بنابراین با توجه به اینکه این شهرک نزدیک فرودگاه می باشد اما هیچ گونه صدایی ساکنین و افراد مجتمع را اذیت نمی کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این شهرک کلیه خدمات شهری از جمله سیستم جمع آوری فاضلاب و آب باران ، تصفیه خانه فاضلاب، مجموعه آموزشی و اداری و فضای سبز در نظر گرفته شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طراحی منحصر به فرد این شهرک به گونه ای است که هوا از فضای زیر ساختمان ها رد می شود. این قابلیت برتر باعث شده که با وجود اینکه این شهرک در ابتدای ورودی تهران است و کارخانجات زیادی در نزدیکی آن قرار دارد اما هوای آلوده وارد این شهرک نمی شود.
              </Typography>
              <Typography className="h2" variant="h2">
                فرهنگ مردم اکباتان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="فرهنگ مردم شهرک اکباتان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  فرهنگ مردم اکباتان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه فضاهای عمومی زیادی در این شهرک تعبیه شده است بنابراین روابط اجتماعی میان ساکنین این شهرک در گروه های مختلف سنی و جنسی کاملا مشهود است. همچنین اختصاصی بودن محدوده این شهرک برای افراد ساکن، سبب شکل گیری هویت شهرک اکباتان شده است و همین موضوع سبب شکل گیری نظام مدیریتی محله است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                واژه اروپای من یا اکباتانی بودن بین ساکنین این شهرک بسیار مرسوم است که نشان دهنده الگوی رفتاری آنها می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Shemiran-No" target="_blank">
                  بیشتر بخوانید : محله شمیران نو
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ساکنین شهرک اکباتان تعلق خاطر خاصی نسبت به این شهرک دارند بنابراین برای حفظ و رشد این شهرک بسیار کوشا هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت ملک و آپارتمان در شهرک اکباتان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به نوسانات شدید قیمت مسکن در ایران قیمت ملک در شهرک اکباتان معمولا قیمت ثابتی ندارد اما قیمت در این شهرک از سطح متوسط شهر مقداری بالاتر می باشد. پارامترهای متفاوتی در قیمت خانه و آپارتمان شهرک اکباتان دخیل هستند. جدا از متراژ واحد، اینکه آپارتمان در کدام فاز باشد یا نورگیر و ویو واحد به چه صورت باشد همگی بر روی قیمت ملک در این شهرک تاثیر گذار است. همچنین واحد هایی که بازسازی شدند نیز از قیمت بالاتری نسبت به دیگر واحد ها برخوردار هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                خرید و فروش آپارتمان در اکباتان تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرک اکباتان به دلیل بافت اجتماعی که دارد معمولا املاک مورد نظر برای فروش به عنوان فایل در دست مشاوران املاک منطقه است. سایت هومینجا با دراختیار داشتن مشاوران متخصص در تمام نقاط تهران آماده ارایه فایلهای مناسب برای <Link className="link" to="/Listing/1217" target="_blank"> خرید و فروش آپارتمان در شهرک اکباتان تهران </Link> است
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین برای <Link className="link" to="/Listing/964" target="_blank">  رهن و اجاره آپارتمان در شهرک اکباتان تهران </Link> با مشاوران هومینجا در ارتباط باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین جای شهرک اکباتان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="بهترین فاز شهرک اکباتان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بهترین فاز شهرک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که تمامی قسمت های شهرک طبق استانداردهای زندگی امروزی طراحی و معماری شده است بنابراین منطقه خاصی را نمی توان به عنوان بهترین جای این منطقه در نظر گرفت. اما با توجه به شرایط و معیارهای زندگی افراد قسمتی را برای سکونت انتخاب می کنند. مثلا از انجا که بیشترین تعداد مدارس در فاز یک می باشد بنابراین افرادی که محصل دارند فاز یک را برای زندگی برمی گزینند. همچنین با توجه به اینکه فاز یک و دو در زمان قبل از انقلاب ساخته شده است بنابراین فاز سوم برای کسانی که تمایلی به بازسازی ندارند انتخاب مناسب تری می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                افراد مشهور اکباتان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بازیگران و افراد مشهوری در این شهرک زندگی می کنند. از جمله آنها می توان به علیرام نورایی، نصرالله رادش،  رویا نونهالی، ساعد سهیلی و گلوریا هاردی، نیما نکیسا، امیر نوری، گوهر خیر اندیش، حمیرا ریاضی و علیرضا اوسیوند، عزت اله مهرآوران، مریم بوبانی، آناهیتا همتی، آتیلا پسیانی، سعید پیردوست و رامین ناصر نصیر اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                شهرک اکباتان نی نی سایت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجایی که هدف مجموعه هومینجا رفع تمام سوالات شما در زمینه شهرک اکباتان می باشد، لینک گفتگو در مورد این شهرک را در نی نی سایت ملاحظه بفرمایید.
              </Typography>
              <Grid className="box">
                <LinkMui className="link" href="https://www.ninisite.com/discussion/topic/1334404/%D8%B4%D9%87%D8%B1%DA%A9-%D8%A7%DA%A9%D8%A8%D8%A7%D8%AA%D8%A7%D9%86" target="_blank">
                  شهرک اکباتان نی نی سایت
                </LinkMui>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ای مقاله از هومینجا سعی کردیم تا شما را با ویژگی های این شهرک آشنا کنیم تا چنانچه قصد خرید یا رهن و اجاره را در این شهرک داشتید اطلاعات کاملی نسبت به آن در اختیارتان قرار داده باشیم. جدا از این بهتر است اگر قصد تهران گردی دارید حتما سری به این شهرک بزنید.
              </Typography>
              <Typography className="h4" variant="h4">
                با هومینجا یک کلیک تا قرارداد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مسیرهای زیر شما را راهنمایی می کند :
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_shahrakeekbatan'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در شهرک اکباتان
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_ekbatan'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در شهرک اکباتان
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article59;