import { Button, Grid, Link, Typography } from "@mui/material";
import { Link as LinkRouter } from "react-router-dom";
import React from "react";
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import siteDesign from "../images/enemad_pic.png";
import senfiPhoto from "../images/senfi.png";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';



class Footer extends React.Component {

    constructor() {
        super();
        this.state = {
            openDesc: false,
        }
    }

    onClickOpenDesc = () => {
        let toggle = !this.state.openDesc;
        this.setState({ openDesc: toggle });
    }

    onClickLink = (event) => {
        event.preventDefault()
    }

    render() {
        return (
            <Grid sx={{ padding: "20px 8%", bgcolor: "#002A45" }}>
                <Grid container sx={{ margin: "0 0 20px", display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
                    <Grid sx={{ width: { xs: "100%", md: "auto" } }}>
                        <Grid>
                            <Typography sx={{ color: "white", fontFamily: "shabnam", margin: "10px 0", textAlign: { xs: "center", md: "right" }, fontSize: "13px" }}>
                                هومینجا در شبکه های اجتماعی
                            </Typography>
                            <Grid sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" }, marginBottom: { xs: "10px", md: "0" } }}>
                                <Link sx={{ "&:hover svg": { color: "white !important" } }} href="http://twitter.com/homeinja">
                                    <TwitterIcon sx={{ color: "#9E9E9E !important", fontSize: "40px", transition: "0.4s all" }} />
                                </Link>
                                <Link sx={{ "&:hover svg": { color: "white !important" } }} href="https://www.instagram.com/homeinja2022/">
                                    <InstagramIcon sx={{ color: "#9E9E9E !important", fontSize: "40px", marginRight: "10px", transition: "0.4s all" }} />
                                </Link>
                                <Link sx={{ "&:hover svg": { color: "white !important" } }} href="https://wa.me/qr/SPF27MFJ2QTCA1">
                                    <WhatsappRoundedIcon sx={{ color: "#9E9E9E !important", fontSize: "40px", marginRight: "10px", transition: "0.4s all" }} />
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Typography sx={{ color: "white", fontFamily: "shabnam", margin: "10px 0", textAlign: { xs: "center", md: "right" }, fontSize: "13px" }}>
                                آدرس : خیابان هنگام،نبش کوچه الیاسی(ششم شرقی)،پلاک 1،واحد 5.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid sx={{ width: { xs: "100%", md: "auto" }, display: "flex", alignItems: "flex-start", justifyContent: { xs: "center", md: "flex-end" }, flexDirection: "row" }}>
                        <Grid sx={{
                            width: { xs: "60px", md: "80px" }, height: { xs: "80px", md: "100px" }, display: "inline-block", borderRadius: "4px",
                        }}>
                            <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=368024&amp;Code=uaD1N63w3S2ab7CPTUdb"><img referrerpolicy="origin" src="https://Trustseal.eNamad.ir/logo.aspx?id=368024&amp;Code=uaD1N63w3S2ab7CPTUdb" alt="" style={{ cursor: "pointer", width: "100%" }} id="uaD1N63w3S2ab7CPTUdb" /></a>
                        </Grid>
                        <Link
                            sx={{
                                width: { xs: "60px", md: "80px" }, height: { xs: "80px", md: "100px" }, display: "inline-block", bgcolor: "white", borderRadius: "4px", margin: "0 0 0 5px",
                                backgroundImage: `url(${siteDesign})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'
                            }}>
                        </Link>
                        <Link
                            sx={{
                                width: { xs: "60px", md: "80px" }, height: { xs: "80px", md: "100px" }, display: "inline-block", bgcolor: "white", borderRadius: "4px",
                                backgroundImage: `url(${siteDesign})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'
                            }}>
                        </Link>
                    </Grid>
                </Grid>
                <Grid container sx={{ margin: "0 0 20px" }}>
                    <Grid item xs={12} md={3} sx={{ textAlign: { xs: "center", md: "right" } }}>
                        <LinkRouter to="/Listings/buy_tehran" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان تهران </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_niyavaran" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان نیاوران </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_fereshte" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان فرشته </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_farmaniyeh" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان فرمانیه </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_zaaferanieh" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان زعفرانیه </LinkRouter>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ textAlign: { xs: "center", md: "right" } }}>
                        <LinkRouter to="/Listings/rent_tehran" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان تهران </LinkRouter>
                        <LinkRouter to="/Listings/rent_tehran_niyavaran" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان نیاوران </LinkRouter>
                        <LinkRouter to="/Listings/rent_tehran_fereshte" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان فرشته </LinkRouter>
                        <LinkRouter to="/Listings/rent_tehran_farmaniyeh" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان فرمانیه </LinkRouter>
                        <LinkRouter to="/Listings/rent_tehran_zaaferanieh" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان زعفرانیه </LinkRouter>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ textAlign: { xs: "center", md: "right" } }}>
                        <LinkRouter to="/Listings/buy_tehran_elahieh" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان الهیه </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_saadatabad" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان سعادت آباد </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_andarzgoo" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان اندرزگو </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_tajrish" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان تجریش </LinkRouter>
                        <LinkRouter to="/Listings/buy_tehran_poonak" style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">خرید و فروش ملک آپارتمان پونک </LinkRouter>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ textAlign: { xs: "center", md: "right" } }}>
                        <LinkRouter to={"/Listings/rent_tehran_elahieh"} style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان الهیه </LinkRouter>
                        <LinkRouter to={"/Listings/rent_tehran_saadatabad"} style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان سعادت آباد </LinkRouter>
                        <LinkRouter to={"/Listings/rent_tehran_andarzgoo"} style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان اندرزگو </LinkRouter>
                        <LinkRouter to={"/Listings/rent_tehran_tajrish"} style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان تجریش </LinkRouter>
                        <LinkRouter to={"/Listings/rent_tehran_poonak"} style={{ color: "white", textDecoration: "none", display: "block" }} target="_blank">رهن و اجاره ملک آپارتمان پونک</LinkRouter>
                    </Grid>
                </Grid>
                <Grid sx={{ width: "100%", height: "1px", bgcolor: "#676767" }}></Grid>
                <Grid sx={{ margin: { xs: "5px 0", md: "20px 0" } }}>
                    <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", textAlign: "center" }} variant="h3">
                        هومینجا ( شرکت وب گستران سورین ) بهترین سایت جستجو و ثبت آگهی خرید و فروش، رهن و اجاره ملک، مسکن، خانه، آپارتمان، برج، پنت هاوس، اداری است.
                    </Typography>
                    <Grid id="footer_desc"
                        sx={{
                            maxHeight: { xs: this.state.openDesc ? "9100px" : "0px", md: this.state.openDesc ? "670px" : "0px" },
                            transition: "max-height 0.6s linear", overflow: "hidden"
                        }}
                    >
                        <Typography variant="h3" sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", textAlign: "center" }}>
                            مالکان شخصی، صاحبان ملک، مشاوران
                            املاک فعال، آژانس های املاک و بنگاه های معاملات ملکی که تمایل به خرید و فروش، رهن و اجاره ملک، مسکن، خانه، آپارتمان، برج، پنت هاوس، اداری
                            را دارند، میتوانند آگهی ملک یا خانه خود را در هومینجا ثبت کنند. از جمله خدمات هومینجا می توان به عکاسی حرفه ای و تهیه کاتالوگ برای مالکان
                            خانه، تخصیص اعتبار به مشاوران املاک وآژانس ها و عقد قرارداد ملکی با تخفیف اشاره کرد. هومینجا با دراختیار داشتن تیم حقوقی، تیم تخصصی مشاوران
                            املاک با تجربه و تیم فنی برنامه نویسی بهترین تجربه معاملات املاک بر بستر اینترنت را برای شما فراهم کرده است.هومینجا به شما کمک می کند تا ملک، خانه،
                            آپارتمان، دفتر کار، ویلا و پنت هاوس مورد نظر خود را در سریعترین و راحترین راه بیابید. کمک به مشاورین املاک و صاحبان ملک برای افزایش درامد، هدف ماست.
                            <br />
                            حق کمیسیون املاک در هومینجا از عرف بازار کمتر می باشد و قرارداد ها همراه با کد رهگیری املاک زیر نظر اتحادیه املاک شهر و محل شما ارائه می گردد.
                        </Typography>
                        <Grid sx={{ width: "100%", height: "1px", bgcolor: "#676767", margin: "10px 0" }}></Grid>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            <strong>
                                خرید و فروش ملک، مسکن، خانه، آپارتمان، برج، پنت هاوس، اداری :
                            </strong>
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            با رشد تکنولوژی، روشهای سنتی بازاریابی املاک تغییرات محسوسی داشته است. از سپردن ملک به آژانسهای املاک
                            تا ثبت آگهی در سایت تخصصی املاک روند این تغییرات نشان میدهد. خرید و فروش ملک، مسکن، خانه، آپارتمان، برج، پنت هاوس، اداری بسیار ساده تر شده است.
                        </Typography>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            <strong>
                                رهن و اجاره ملک، مسکن، خانه، آپارتمان، برج، پنت هاوس، اداری :
                            </strong>
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            سایت املاک
                            هومینجا با پشتیبانی از انواع معاملات ملک مانند رهن و اجاره ملک، مسکن، خانه،
                            آپارتمان، برج، پنت هاوس، اداری در دسترس کابران
                            عزیز می باشد.صاحبان ملک ( موجر) با ثبت آگهی رهن و اجاره ملک خود می توانند در اسرع وقت خانه را اجاره دهند. لازم به ذکر است مستاجرین
                            با جستجوی ملک مورد نظر در بهترین سایت جستجوی ملک، مسکن،
                            خانه، آپارتمان، برج، پنت هاوس، اداری،
                            می توانند به راحتی خانه دار شوند.
                        </Typography>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            <strong>
                                بهترین سایت املاک :
                            </strong>
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            شرکت وب گستران سورین مالک سایت املاک هومینجا می باشد. بیش از 5 سال سابقه در طراحی انواع سایت، اپلیکیشن و
                            پلتفرم موجب طراحی بهترین سایت املاک در ایران شده است. هومینجا با پشتیبانی از انواع معاملات املاک و مستغلات مانند خرید و فروش، رهن و اجاره،
                            سرمایه گذاری و پیش خرید ملک، خانه، آپارتمان، برج، پنت هاوس، اداری، باغ، ویلا به عنوان بهترین سایت جستجو و ثبت آگهی ملک شناخته شده است.
                        </Typography>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            <strong>
                                بهترین سایت املاک برای ثبت آگهی :
                            </strong>
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            اگر قصد فروش یا رهن و اجاره ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس خود رادارید، پیشنهاد ما سایت املاک هومینجا می باشد.
                            ثبت راحت و رایگان آگهی ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس با فیلترهای پیشرفته بهترین تجربه معاملات را به ارمغان آورده است.
                        </Typography>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            <strong>
                                بهترین سایت جستجوی ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس :
                            </strong>
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            سایت املاک هومینجا به عنوان بهترین سایت ملک یابی با فیلترهای پیشرفته،
                            جستجوی ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس را آسانتر از هر زمان در اختیار شما قرار داده است. سامانه هوشمند جستجوی ملک هومینجا مناسب
                            برای کسانیست که به دنبال خانه اجاره ای، سرمایه گذاری در املاک، خرید یا رهن و اجاره ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس می باشند.
                        </Typography>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            <strong>
                                بهترین سایت املاک برای مالکان و انبوه سازان :
                            </strong>
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            درصورتی که شما سرمایه گذار، انبوه ساز و یا صاحب ملک، مسکن، خانه، آپارتمان، برج یا
                            پنت هاوس هستید و قصد فروش یا رهن و اجاره ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس خود را دارید، هومینجا بهترین سایت برای نمایش املاک شماست.
                            <br />
                            به واسطه بازدید بالای روزانه سایت املاک هومینجا، عنوان بهترین سایت املاک برای مالکان و انبوه سازان را از آن خود کرده است.
                        </Typography>
                        <Typography variant="h2" sx={{ color: "white", fontFamily: "shabnam", fontSize: "14px", margin: "4px 0", fontWeight: "bold" }}>
                            بهترین سایت برای مشاورین املاک و آژانس های املاک :
                        </Typography>
                        <Typography sx={{ color: "white", fontFamily: "shabnam", fontSize: "13px", margin: "4px 0" }}>
                            سایت جستجو و ثبت آگهی ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس هومینجا با در
                            اختیار داشتن بیشترین همکار و آگهی املاک به عنوان بزرگترین سایت فایلینگ املاک محسوب می شود. وجود بزرگترین دیوار آگهی املاک باعث شده هومینجا به عنوان
                            بهترین سایت برای مشاورین املاک و آژانس های املاک شناخته شود. افزایش درآمد مشاورین املاک و آژانسهای املاک ثمره تلاش هومینجا می باشد. پشتیبانی از انواع
                            معاملات املاک مانند خرید و فروش، رهن و اجاره و پیش خرید انواع ملک، مسکن، خانه، آپارتمان، برج یا پنت هاوس از جمله قابلیت های بهترین سایت املاک است.
                            <br />
                            برندسازی برای مشاورین املاک و آژانس های املاک با درج نام، آدرس، لوگو و تمام اطلات شما هدیه ی کوچکی از هومینجا می باشد.
                        </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "center" }}>
                        <Button onClick={this.onClickOpenDesc} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                            <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openDesc ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                        </Button>
                    </Grid>
                </Grid>
                <Grid sx={{ width: "100%", height: "1px", bgcolor: "#676767" }}></Grid>
                <Typography variant="h4" sx={{ color: "#9E9E9E", fontFamily: "shabnam", textAlign: "center", fontSize: "12px", marginTop: "20px" }}>
                    کلیه حقوق این وب سایت متعلق به شرکت وب گستران سورین می باشد
                </Typography>
            </Grid>
        );
    }

}

export default Footer;