import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article33/01.jpg";
import photo02 from "../../images/mag/article33/02.jpg";
import photo03 from "../../images/mag/article33/03.jpg";
import photo04 from "../../images/mag/article33/04.jpg";
import photo05 from "../../images/mag/article33/05.jpg";
import photo06 from "../../images/mag/article33/06.jpg";
import photo07 from "../../images/mag/article33/07.jpg";
import photo08 from "../../images/mag/article33/08.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article33 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "اسمارت هوم یا خانه هوشمند سیستمی است که به شما اجازه می دهد از راه دور خانه را کنترل کنید و بر سیستم امنیتی نظارت داشته باشید.";
    document.getElementsByTagName('meta')["keywords"].content = "اسمارت هوم | خانه خودکار | تجهیزات خانه هوشمند، مزایای خانه هوشمند ، خانه هوشمند چیست ، صفر تا صد خانه هوشمند"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[32].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[32].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[32].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[32].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[32].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[32].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="خانه هوشمند - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[32].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بشر همیشه به فکر یک زندگی راحت و بی دردسر است. از این رو تلاش های بسیاری نیز کرده است. فناوری خانه هوشمند یکی از ایده هایی است که به راحتی افراد خانه، کمک شایانی کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا مگ </Link> سعی کرده ایم که نکات مهم در رابطه با خانه هوشمند را ذکر کنیم. پس با ما همراه باشید تا با <Link className="link" to="/smart-home" target="_blank">   اسمارت هوم </Link>و تجهیزات آن آشنا شوید.
              </Typography>
              <Typography className="h2" variant="h2">
                خانه هوشمند چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="خانه هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اسمارت هوم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اسمارت هوم یا<LinkMui className="link" href="https://smart-homes.ir/" target="_blank">  خانه هوشمند </LinkMui>سیستمی است که به شما اجازه می دهد از راه دور وسایل خانه را کنترل کنید. با استفاده از این فناوری شما قادرید کنترل سیستم امنیتی خانه و دوربین ها را برعهده داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در خانه خودکار بهره وری انرژی از طریق کنترل لوازم با یک برنامه هوشمند در گوشی یا دستگاه هایی که قابلیت اتصال به اینترنت را دارند فراهم می شود. در اسمارت هوم تمامی وسایل هوشمند مانند کلیدها و لامپ ها و ترموستات ها و سیستم های ایمنی از طریق اینترنت یا تلفن و پیامک با یکدیگر در ارتباطند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به بیان ساده تر می توان خانه هوشمند را اینگونه تعریف کرد که مجموعه ای از ابزار است که به شما کمک می کند تا کنترل برخی وسایل برقی و مکانیکی را در خانه داشته باشید. این تکنولوژی علاوه بر کاهش هزینه های مصرف انرژی برای شما ایمنی و آسایش را نیز فراهم می کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                قابلیت های خانه خودکار
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="خانه ی هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خانه هوشمند چیست
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کنترل سیستم های روشنایی تا کنترل تجهیزات الکتریکی، سیستم سرمایش و گرمایش و کنترل های ورودی را می توان از قابلیت های خانه هوشمند برشمرد. برند تجهیزات انتخاب شده برای هوشمند سازی نقش بسزایی در امکانات پروژه برعهده دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر قابلیت های <LinkMui className="link" href="https://home.google.com/welcome/" target="_blank">  خانه هوشمند </LinkMui> می توان به کنترل هوشمند <Link className="link" to="/chandelier-in-decoration" target="_blank">   تجهیزات روشنایی </Link>، کنترل وسایل الکتریکی با موبایل اشاره کرد. همچنین کنترل درب پارکینگ و درب ورودی آپارتمان نیز از دیگر قابلیت های اسمارت هوم می باشد. پخش موسیقی دلخواه با موبایل و همچنین کنترل هوشمند شیر اصلی آب و گاز نیز از مواردی است که می توان برای خانه های هوشمند در نظر گرفت.
              </Typography>
              <Typography className="h2" variant="h2">
                مزایای اسمارت هوم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هوشمند سازی خانه به شما کمک می کند که کنترل بیشتری در مصرف انرژی داشته باشید. همچنین روشن و خاموش کردن کلیدها و <Link className="link" to="/keeping-apartment-flower" target="_blank">  آبیاری گیاهان </Link> و باز و بسته کردن پنجره نیز به صورت خودکار انجام می شود. خانه های هوشمند نسبت به خانه های معمولی امن تر هستند. همچنین شرایط زندگی در خانه های هوشمند برای افراد معلول و سالمند بسیار کمک کننده می باشد. همچنین برای کسانی که حیوانات خانگی دارند، این سیستم شرایط نگه داری را ارتقا می بخشد.
              </Typography>
              <Typography className="h2" variant="h2">
                امکانات رفاهی خانه هوشمند
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مهمترین امکانات خانه هوشمند را می توان امکانات رفاهی آن دانست. از آنجا که در اسمارت هوم افراد دسترسی کاملی به تجهیزات مختلف دارند می توانند مدیریت تجهیزات خانه خود را بر عهده گیرند. به عنوان مثال اگر در یک روز گرم تابستان قصد بازگشت به منزل را دارید می توانید قبل از ورودتان سیستم سرمایشی و تهویه را روشن کنید تا بعد از رسیدن به خانه از هوای مطبوعی بهره مند شوید.
              </Typography>
              <Typography className="h2" variant="h2">
                زمانبندی و تنظیم تجهیزات منزل هوشمند
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هنگامی که در مسافرت به سر می برید این هوشمند سازی به شما کمک می کند تا برنامه ریزی دقیقی برای آبیاری گیاهان منزلتان در نظر بگیرید. همچنین شما می توانید ایمنی خانه را با تنظیم سیستم های هشدار برای ورود افراد متفرقه، نشتی گاز و لوله های آب نیز فراهم آورید.
              </Typography>
              <Typography className="h2" variant="h2">
                تنظیم سیستم روشنایی خانه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="روشنایی خانه هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سیستم روشنایی هوشمند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با بکارگیری پرده های برقی و لامپ های هوشمند می توان نور خانه را در هر ساعتی از شبانه روز تامین کرد. شما همچنین می توانید با تنظیم فرمان های صوتی برای مدیریت خانه خود تمهیدات لازم را بکار برید.
              </Typography>
              <Typography className="h2" variant="h2">
                مراحل هوشمند سازی خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای هوشمند سازی خانه بهتر است که در ابتدا با یک شرکت هوشمند سازی خانه تماس بگیرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از انجاییکه تمامی دستگاه های خانه هوشمند نیاز به اینترنت دارند بنابراین باید اطمینان کافی حاصل شود که wifi قوی برای مدیریت اشتراک گذاری اطلاعات وجود دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که دستگاه های زیادی برای هوشمند سازی خانه وجود دارد بنابراین باید تحقیق کامل انجام شود تا بهترین تصمیم را برای انتخاب بگیرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای هوشمند سازی بهتر است که نیاز سنجی دقیقی انجام دهید. این موضوع که بدانید دنبال چه چیزی هستید و هدفتان از هوشمند سازی دقیقا چیست.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-interesting-things" target="_blank">
                  بیشتر بخوانید: دانستی های جالب در مورد خانه ها
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                چند ایده در مورد اتوماسیون خانه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در زیر به بررسی هوشمند سازی مابقی تجهیزات منزل اشاره خواهیم کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                قفل در هوشمند:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="قفل در هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قفل در خانه هوشمند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین موارد <LinkMui className="link" href="https://smartic.ir/smart-home/" target="_blank">  هوشمند سازی خانه </LinkMui> تعویض قفل در فعلی تان می باشد. با این کار دیگر نیازی نیست که شما از کلید استفاده کنید. برای باز و بسته کردن در می توانید از اپلیکیشن موبایلی اقدام کنید و هر زمان که تمایل داشتید گزارشی از رفت و آمدها داشته باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                سیستم هشدار هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با بکارگیری دستگاه های خانه خودکار می توانید از راه دور روی مسائل ایمنی یا امنیتی ملکتان نظارت داشته باشید. همچنین می توانید برای نشت، بازگذاشتن درب پارکینگ یا اگر وسیله ای به برق است هشدار دریافت کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                ترموستات هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تروستات هوشمند با توجه به عادات شما دما را خودکار تنظیم می کند. همچنین می توانید از راه دور دما را از طریق برنامه تنظیم کنید. ترموستات هوشمند همچنین میزان مصرف انرژی را گزارش می دهد و زمان تعویض فیلتر را به کاربران یادآوری می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                سیستم روشنایی هوشمند:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="روشنایی خانه هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  هوشمند سازی روشنایی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سیستم روشنایی هوشمند علاوه بر کنترل از راه دور، زمانیکه تشخیص می دهد افراد خانه در اتاق حضور ندارند نور محیط را کم می کند. همچنین لامپ های هوشمند نور خود را با توجه به روشنایی روز تنظیم می کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                دوربین های امنیتی هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با استفاده از این دوربین ها می توان خانه را زمانیکه خارج از منزل هستید از لحاظ امنیتی بررسی کنید. حسگرهای دوربین قادرند ساکنین و مهمان ها و حیوانات خانگی و دزد را از هم تشخیص دهند و صاحبخانه را بابت این رفتارهای مشکوک باخبر سازند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/small-house-decoration" target="_blank">
                  بیشتر بخوانید : دکوراسیون خانه های کوچک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                سنسور نشت آب:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمانیکه شما قصد پر کردن وان <Link className="link" to="/laundry-room" target="_blank">  حمام </Link> را دارید، شیر آب را باز می کنید و دنبال کار خود می روید. اگر یادتان برود که آب را ببندید سنسورهای نشت آب به شما برای بستن شیر آب یادآوری می کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                رله هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ماژول عملگر هوشمند برای قطع و وصل جریان الکتریکی شبیه فیوز عمل می کند. این نود را می توان پشت پریز نصب کنید و پریز سنتی را به هوشمند تبدیل کنید. با این عمل هر وسیله ای که به پریز وصل شود را می توانید از طریق تلفن همراه کنترل کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                تجهیزات مورد نیاز برای هوشمند سازی <Link className="link" to="/buying-house-in-Tehran" target="_blank">  خانه </Link>
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="تجهیزات خانه هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تجهیزات خانه هوشمند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قصد هوشمند سازی خانه را دارید، آشنایی با وسایل مورد نیاز برای آن اهمیت بسزایی دارد. این تجهیزات از تنوع بسیار بالایی برخوردار هستند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Midex" target="_blank">
                  بیشتر بخوانید : نمایشگاه میدکس چیست؟
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله این تجهیزات می توان به سنسورهای دما و نور - سنسور نشتی - سنسور تشخیص دود و گاز - آژیر - دزدگیر هوشمند - سیستم های سرمایش و گرمایش هوشمند - مانیتور لمسی - پرده برقی هوشمند - درب پارکینگ هوشمند - دوربین مدار بسته هوشمند - سیستم آبیاری گیاهان - قفل هوشمند - آیفون و هاب ها - کنترلر - گیت وی کنترل از راه دور چند منظوره اشاره کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                درصورت تمایل به خرید و یا اجاره خانه دارید به <Link className="link" to="/Listings" target="_blank">  بخش آگهی های وب سایت املاک هومینجا  </Link> مراجعه بفرمایید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مهمترین نکات خرید وسایل هوشمند سازی خانه، داشتن کیفیت بالا و قیمت مناسب و داشتن ضمانت نامه می باشد. بهتر است برای خرید محصولات هوشمند سازی سراغ برندهای معتبر بروید و از کیفیت آن آسوده خاطر باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله بهترین برندهای تجهیزات خانه هوشمند می توان موارد زیر را نام برد:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.se.com/us/en/" target="_blank">  اشنایدر | Schneider </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://interra.com.tr/en" target="_blank">  اینترا | INTERRA </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.zennio.com/" target="_blank">  زنیو | Zennio </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://global.abb/group/en" target="_blank">  ای بی بی | ABB </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.berker.com/" target="_blank">  برکر | Berker </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.multitek.com.tr/" target="_blank">  مولتی تک | Multitek </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://akuvox.com/" target="_blank">  اکواکس | Akuvox </LinkMui>
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سیستم های خوب اسمارت هوم باید به گونه ای باشند که هکرها نتوانند به اطلاعات دسترسی پیدا کنند. همچنین عملکرد گجت ها با هم تداخلی پیدا نکنند و در نهایت کاربرد آسانی داشته باشند.
              </Typography>
              <Typography className="h2" variant="h2">
                ساختمان هوشمند چگونه کار می کند؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="عملکرد خانه هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  عملکرد ساختمان هوشمند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از تجهیز سازی اسمارت هوم به بررسی عملکرد آن می پردازیم. کنترل سیستم <LinkMui className="link" href="https://www.amazon.com/Smart-Home/b?ie=UTF8&node=6563140011" target="_blank">  هوشمند خانه </LinkMui> از طریق موبایل میسر می شود. برای این منظور نیاز به نصب یک نرم افزار کاربردی است. این نرم افزار برای برندهای مختلف، متفاوت است. هر تولید کننده نرم افزار کاربردی اختصاصی خود را فراهم می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این اپلیکیشن ها متناسب با سیستم عامل های متفاوت عرضه می شوند. برای استفاده از تجهیزات هر تولید کننده باید نرم افزار همان تولید کننده را نصب کنیم. پس از نصب نرم افزار، کنترل کننده مرکزی را به اینترنت وصل می کنیم. اینکار از طریق نرم افزار کاربردی تلفن همراه انجام می پذیرد.
              </Typography>
              <Typography className="h2" variant="h2">
                سناریوهای اسمارت هوم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاربران می توانند سناریوهای مختلفی برای خانه هوشمند تعریف کنند. تعریف سناریو یعنی عملیاتی که به صورت همزمان بر روی چند نود انجام می پذیرد. به عنوان مثالی از این تعریف می توان زمانی را در نظر گرفت که شما از منزل خارج شده اید. شما می توانید برای این موقعیت، سناریوی خروج را تعریف کنید که همزمان لامپ ها خاموش شوند و سیستم امنیتی نیز فعال شود.
              </Typography>
              <Typography className="h2" variant="h2">نتیجه گیری:</Typography>
              <Typography className="p">
                با توجه به مطالب این مقاله از <Link className="link" to="/Terms" target="_blank">  هومینجا  </Link>  آموختیم که هوشمند سازی خانه با کنترل پذیری بالایی برای شما همراه است و شما می توانید کنترل تجهیزات خانه از <Link className="link" to="/building-facades-types" target="_blank">  نما  </Link> تا سیستم گرمایشی را از طریق موبایل مورد بررسی قرار دهید. همچنین زمانیکه در سفر هستید و مسافت زیادی با منزل خود فاصله دارید می توانید تمامی وضعیت تجهیزات خانه را بررسی کنید و دیگر ذهنتان درگیر باز و بسته بودن شیر آب و گاز نخواهد بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امیدواریم که این مطالب برای شما مفید واقع شده باشد.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("تکنولوژی و فناوری")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  تکنولوژی و فناوری
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article33;