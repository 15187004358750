import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article45/01.jpg";
import photo02 from "../../images/mag/article45/02.jpg";
import photo03 from "../../images/mag/article45/03.jpg";
import photo04 from "../../images/mag/article45/04.jpg";
import photo05 from "../../images/mag/article45/05.jpg";
import photo06 from "../../images/mag/article45/06.jpg";
import photo07 from "../../images/mag/article45/07.jpg";
import photo08 from "../../images/mag/article45/08.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article45 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "بدلیل شهرک های جدید و مدرن و برج های بلندی که چیتگر ساخته شده اند این محله معروف است. ما در هومینجا به معرفی این منطقه می پردازیم. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "امکانات محله چیتگر | همه چیز در مورد منطقه ۲۲ شهرداری تهران | خرید ملک در چیتگر | فروش ملک در چیتگر | رهن و اجاره ملک در محله چیتگر | دسترسی های منطقه چیتگر | قیمت ملک و آپارتمان در چیتگر | برج های شهرک چیتگر | موقعیت گردشگری چیتگر | امکانات محله چیتگر | معرفی کامل محله چیتگر در منطقه ۲۲ تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[44].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[44].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[44].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[44].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[44].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[44].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="چیتگر - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[44].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه ساکن تهران هستید حداقل یک بار اسم چیتگر را شنیده اید. این منطقه بدلیل شهرک های جدید و مدرن و برج های بلندی که دارد معروف است. در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> قصد داریم تا شما را با یکی از جدیدترین و بکرترین <Link className="link" to="/tehran-zones" target="_blank">  مناطق تهران </Link> آشنا کنیم.
              </Typography>
              <Typography className="h2" variant="h2">
                چیتگر کجاست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="منطقه چیتگر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  چیتگر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <b> منطقه چیتگر</b> یکی از محله های <Link className="link" to="/tehran-zone-22" target="_blank">  منطقه 22 تهران </Link> محسوب می شود. این منطقه در شمال غربی تهران واقع است و دارای دو بخش شمالی و جنوبی می باشد و همچنین از بزرگترین محله های تهران بشمار می رود. چیتگر با مساحت  6200 هکتاری که 1300 هکتار آن را فضای سبز دربرگرفته است منطقه ای منحصر بفرد می باشد. بافت این محله شامل ساختمان های نوساز، برج های بلند قامت و   لوکس  با <Link className="link" to="/building-facades-types" target="_blank">  نمای </Link> زیبا می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">
                  بیشتر بخوانید : الهیه تهران کجاست ؟ معرفی کامل خیابان فرشته و محله الهیه تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                چیتگر شمالی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله چیتگر شمالی از سمت شمال به اتوبان شهید همت و از جنوب به بزرگراه شهید لشگری و و از شرق به شهرک راه آهن  و از غرب به شهرک کارمندان شهرداری منتهی می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                چیتگر جنوبی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چیتگر جنوبی در همسایگی محله های وردآباد، شهرک دانشگاه، شهرک غزالی و شهرک شهرداری قرار دارد. این محله از شمال به بزرگراه شهید لشگری، از شرق به شهرک دانشگاه، از جنوب به بزرگراه فتح و از غرب به بلوار کرمان خودرو متصل می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمین های منطقه چیتگر در گذشته متعلق به خانواده فرمانروایان بود و توسط خاندان فیروزگر اداره می شد. این تاریخچه مربوط به دوران قبل از انقلاب اسلامی است. بعد از انقلاب زمین‌های موجود در این منطقه را به‌ عنوان زمین‌های ملی اعلام کردند و این زمین ها به شهرداری واگذار شد. با افزایش جمعیت در تهران و مشکلاتی که برای بافت و ساختار آن پدید آمد، مسئولین شهر  تصمیم گرفتند که در منطقه ۲۲ تهران بر اساس مدل‌های مدرن شهرسازی کنند. بر همین اساس تاکنون پروژه‌های بسیاری در این منطقه، احداث شده‌ است. از جمله پروژه‌های این منطقه می‌توان آبشار تهران، <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%AF%D8%B1%DB%8C%D8%A7%DA%86%D9%87_%DA%86%DB%8C%D8%AA%DA%AF%D8%B1" target="_blank">  دریاچه مصنوعی </LinkMui> چیتگر، محور چهارباغ، <LinkMui className="link" href="https://www.opark.ir/" target="_blank">  پارک آبی </LinkMui> چیتگر، <LinkMui className="link" href="https://www.iranmall.com/" target="_blank">  ایران مال </LinkMui> را نام برد. همچنین به برج‌های مسکونی در حال اجرا در این ناحیه مانند پروژه <LinkMui className="link" href="https://morvaride.com/project/%D9%BE%D8%B1%D9%88%DA%98%D9%87-%D8%B1%D9%88%D9%85%D9%86%D8%B3-%D9%85%D8%B1%D9%88%D8%A7%D8%B1%DB%8C%D8%AF-%D8%B4%D9%87%D8%B1-%D8%AA%D8%B9%D8%A7%D9%88%D9%86%DB%8C-%D8%AA%D9%88%D8%B3%D8%B9%D9%87-%D9%85%D8%B3%DA%A9%D9%86-%D9%87%D9%85%D8%AA/" target="_blank">  رومنس </LinkMui> در محدوده شهرک مروارید شهر می توان اشاره داشت.
              </Typography>
              <Typography className="h2" variant="h2">
                امکانات منطقه چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شهرک دارای جاذبه های گردشگری زیادی از جمله جنگل و دریاچه می باشد. آب و هوای این منطقه از تهران بسیار مطبوع و دلپذیر می باشد. چیتگر بدلیل امکانات تفریحی و رفاهی بی شماری که دارد یکی از مناطق گردشگری تهران محسوب می شود. دریاچه مصنوعی خلیج فارس، پارک جنگلی چیتگر، شهربازی و مراکز خرید متعددی که در این منطقه وجود دارد شهروندان زیادی را به سمت خود جذب کرده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">
                  بیشتر بخوانید : معرفی برج های لوکس منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                جاذبه های گردشگری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="تهران مال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تهران مال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جاذبه های گردشگری که در این منطقه وجود دارد و افراد زیادی را به سمت خود جلب کرده است می توان موارد زیر را نام برد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آبشار تهران
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دریاچه مصنوعی شهدای خلیج فارس
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پارک جنگلی چیتگر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خرگوش دره
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                لتمان کن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان جوانمردان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تهران مال
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                رزمال
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باملند
              </Typography>
              <Typography className="h2" variant="h2">
                پارک جنگلی چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این پارک در سال 1341 به عنوان یکی از مکان های تفریحی برای عموم مردم تاسیس شد. درخت های پارک جنگلی از گونه درختان سوزنی برگ و پهن برگ است. یکی از دلایل پاک بودن هوا و خنکی منطقه وجود این پارک جنگلی می باشد. همچنین از امکانات این پارک می توان به مجتمع فرهنگی، ورزشی، زمین بازی، سالن اجتماعات، انبار، ایستگاه و پیست دوچرخه سواری، ایستگاه آتش نشانی، رستوران، بوفه، ساختمان اداری، پارکینگ، آلاچیق و چادرهای اقامتی اشاره داشت.
              </Typography>
              <Typography className="h2" variant="h2">
                دریاچه چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دریاچه چیتگر یا دریاچه خلیج فارس در ضلع شمالی چیتگر قرار دارد. وسعت زیاد این دریاچه و نزدیکی به مرکز تفریحی بام لند این مجموعه را به جاذبه گردشگری تبدیل کرده است. این دریاچه دو اسکله می باشد و با اجاره دادن قایق های پدالی یا اتوبوسی یا شارژی می توانید از بودن در این دریاچه بیشتر لذت ببرید.
              </Typography>
              <Typography className="h2" variant="h2">
                بام لند
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="بام لند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بام لند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مرکز خرید بام لند که در قسمت شمالی این منطقه قرار دارد را می توان اولین مرکز تجاری روباز در ایران دانست. با اینکه زمان زیادی از احداث آن نمی گذرد اما مرکز بسیار محبوبی در بین تهرانی ها و بقیه شهر های مجاور تهران می باشد.مساحت این مجموعه 60 هزار متر مربع می باشد که حدود 13 هزار متر مربع آن به مجتمع تجاری اختصاص داده شده است. معماری این سبک تلفیقی از معماری ایران و اروپا می باشد. این مرکز شامل رستوران های متعدد، شهر موسیقی، شهر فرهنگ و شهر سلامتی می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                باشگاه سوار کاری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چندین باشگاه سوار کاری به منظور ایجاد محیطی مفرح در این منطقه احداث شده است که در زیر به معرفی تعدادی از آن ها می پردازیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه سوارکاری بام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه سوارکاری ابرش
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه سوارکاری آریاسب
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه سوارکاری میلاد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه سوارکاری چیتگر
              </Typography>
              <Typography className="h2" variant="h2">
                اهمیت منطقه چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد چیتگر را برای سکونت انتخاب کرده اند. دلایل زیادی را می توان برای اهمیت این منطقه برشمرد که در زیر به آن می پردازیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                جاذبه بصری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جاذبه های بصری که در چیتگر وجود دارد این منطقه را به یک منطقه توریستی تبدیل کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                اب و هوای مطبوع:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدلیل کاهش آلودگی در این منطقه افراد زیادی چیتگر را برای زندگی انتخاب کرده اند.
              </Typography>
              <Typography className="h2" variant="h2">
                شهرک های منطقه چیتگر:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="شهرک های منطقه چیتگر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شهرک های منطقه چیتگر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ساخت و ساز انبوه در این منطقه توسط ارگانهای دولتی و بخش خصوصی انجام می گیرد. زمانی که توان خرید یک واحد آپارتمانی را ندارید می‌توانید با خرید ده متر از پروژه های در حال ساخت این مجموعه، در این پروژه‌ها سهیم باشید. با توجه به شهرک سازی در این منطقه، چیتگر از 28 شهرک و 20 هزار واحد تشکیل شده است و می توان گفت یکی از بزرگترین و لوکس ترین شهرک های تهران به حساب می آید. در زیر به تعدادی از این شهرک ها اشاره می کنیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک صدرا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک چشمه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برج های خاتم
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک سپهر پردیس المپیک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک بهار
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک الماس
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک آفتاب 22
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شهرک نرگس
              </Typography>
              <Typography className="h2" variant="h2">
                معروف ترین محله های منطقه چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سه محله در این محله وجود دارد که محبوبیت ویژه ایی دارد که در اینجا اشاره ای به آن ها خواهیم داشت.
              </Typography>
              <Typography className="h3" variant="h3">
                غرب دریاچه چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دلایل محبوبیت این منطقه، نزدیکی به دریاچه چیتگر و امکانات رفاهی آن را می توان نام برد. غرب دریاچه چیتگر از بهترین مناطق چیتگر برای سرمایه گذاری می باشد. در این منطقه شهرک های بسیار زیبا، مدرن و مجتمع های مسکونی بسیار لوکسی ساخته شده است. اکثر واحد های این محله نو ساز هستند و با توجه به استانداردهای روز دنیا طراحی شده اند.
              </Typography>
              <Typography className="h3" variant="h3">
                بلوار کوهک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="بلوار کوهک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بلوار کوهک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این بلوار در نزدیکی پارک چیتگر واقع شده است و از غربی ترین محله در این منطقه می باشد. نزدیکی به ایستگاه مترو و دسترسی بسیار آسان به اکثر قسمت های تهران، این محله را به یکی از پرطرفدارترین مناطق تبدیل کرده است.
              </Typography>
              <Typography className="h3" variant="h3">
                آزادگان شرق
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ازادگان شرق را می توان یکی از دنج ترین محله های چیتگر دانست. این محله برای کسانی مناسب است که دنبال ارامش و منطقه خلوت و دنج می باشند. البته عدم دسترسی راحت به سایر مناطق تهران از مشکلات این منطقه می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                راه های دسترسی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دسترسی این منطقه به دیگر مناطق تهران به آسانی امکانپذیر است. چیتگر از شمال به اتوبان شهید همدانی، از جنوب به آزادراه تهران کرج، از شرق به بزرگراه آزادگان و از غرب به پیکان شهر و آزادشهر منتهی می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                دسترسی با خودرو شخصی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                راه های متعددی برای دسترسی به این منطقه با خودروی شخصی وجود دارد. از بزرگراه علامه جعفری وارد اتوبان شهید همدانی شده و از این اتوبان وارد بولوار گیاه شناسی شوید. این بولوار شما را به بولوار میعاد و دریاچه چیتگر هدایت می کند. البته این مسیر ورود به  پارک جنگلی چیتگر نیز می باشد. همچنین از بزرگراه آزادگان و یا بزرگراه همت نیز می توانید وارد بزرگراه شهید خرازی شوید. خروجی دریاچه شما را به دریاچه خلیج فارس می رساند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                دسترسی با مترو:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="مترو چیتگر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مترو چیتگر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای رفتن به پارک جنگلی می توانید از طریق مترو نیز اقدام کنید. این ایستگاه در سال 1384 به بهره برداری رسید. مترو چیتگر در کیلومتر 8 اتوبان تهران کرج و در بلوار کوهک قرار دارد و در فاصله بسیار کمی از این پارک واقع شده است. با استفاده از اتوبوس های شهرک شهید باقری بعد از پیاده شدن از ایستگاه مترو می توانید به دریاچه یا دیگر قسمت های این منطقه دسترسی پیدا کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: '700' }}>
                راه دسترسی با اتوبوس:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه تمامی بخش های این منطقه ایستگاه دارد بنابراین دسترسی به مراکز خرید و تفریحی منطقه با استفاده از اتوبوس بسیار راحت می باشد. برای رفتن به پارک جنگلی چیتگر نیز می‌توانید در ایستگاه اتوبوس چیتگر پیاده شوید. همچنین اگر قصد سفر به دریاچه را دارید، بهترین مسیر استفاده از اتوبوس‌های خط 322 در پایانه صادقیه می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                دانستنی هایی در مورد چیتگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                منطقه 22 تهران که بخش وسیعی از آن شامل محله چیتگر می باشد، یکی از پر تخلف ترین مناطق تهران محسوب می شود. ساخت و سازهایی که در این منطقه انجام می شود، حریم اتوبان‌های اطراف را اشغال کرده است. همچنین وجود برج‌های 15 تا 40 طبقه در این منطقه سبب عدم وزش بادهای غرب به شرق به داخل شهر تهران می شود که آلودگی شهر را بدنبال خواهد داشت. با وجود استقبال از مجموعه‌های تفریحی و تجاری در این منطقه، اما بار ترافیکی و مشکلات آلودگی و ترافیکی بسیاری در آینده بوجود خواهد آمد.
              </Typography>
              <Typography className="h2" variant="h2">
                مناسب بودن قیمت ملک در چیتگر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پایین بودن قیمت ملک در چیتگر نسبت به دیگر مناطق تهران یکی دیگر از دلایل انتخاب این منطقه می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت آپارتمان و مسکن در چیتگر:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="قیمت ملک در چیتگر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قیمت ملک در چیتگر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همانطور که در بالا گفته شد، این منطقه یکی از مدرن ترین محله های منطقه ۲۲ می باشد و هنوز هم قسمت هایی از آن در حال ساخت می باشد. برج های این مجموعه بالاترین استانداردهای برج سازی را دارند و دارای مقاومتی بالا در مقابل زلزله هستند. همچنین تمامی دیوارها دارای عایق حرارتی و صدا هستند و دیوارها کناف نسوز و نما نوعی سرامیک عایق می باشد. بهتر است برای اطلاع از قیمت ها  با چند نفر از مشاوران املاک  صحبت و مشورت کنید تا اطلاعاتی کامل در اختیارتان قرار دهند. بطور تقریبی قیمت ملک پیش فروش در این منطقه از متری 10 میلیون شروع می شود و بسته به آن برج و امکانات ملک گاهی به متری 100 میلیون نیز می رسد.
              </Typography>
              <Typography className="h2" variant="h2">
                خرید آپارتمان در چیتگر :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه تمامی برج های این منطقه از امکانات رفاهی وتفریحی خوبی برخوردارند و شرایط مناسبی را برای سکونت در این محل فراهم اورده اند، بنابراین گزینه مناسبی برای خرید و سرمایه گذاری ملکی می باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد <Link className="link" to="/Listings/buy_tehran_daryachechitgar" target="_blank">  خرید آپارتمان و ملک در چیتگر </Link> را دارید بهتر است با مشاورین هومینجا در تماس باشید تا بهترین موقعیت را به شما معرفی کنند.
              </Typography>
              <Typography className="h2" variant="h2">
                رهن و اجاره در چیتگر :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رهن و اجاره برای کسانی که توان مالی برای خرید خانه ندارند مناسب ترین گزینه می باشد. این منطقه با توجه به امکانات رفاهی مناسبی که دارد طرفداران زیادی را برای رهن و اجاره ی آپارتمان  به خود جلب کرده است. قیمت ملک برای اجاره در این منطقه نیز بسیار متفاوت است و بستگی به عواملی نظیر موقعیت دسترسی و نو ساز بودن ملک و یا قدیمی بودن آن، میزان متراژ، نزدیکی به دریاچه و بسیاری دیگر از عوامل دارد. اما با بررسی گزارشات و امار گرفته شده حداقل اجاره را برای این منطقه ماهیانه 10 میلیون می توان در نظر گرفت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد <Link className="link" to="/Listings/rent_tehran_daryachechitgar" target="_blank"> رهن و اجاره ملک و آپارتمان در چیتگر </Link>  را دارید بهتر است با مشاورین هومینجا در تماس باشید تا بهترین موقعیت را به شما معرفی کنند.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به مطالبی که در این مقاله از <LinkMui rel="noreferrer" className="link" href="https://homeinja.info/" target="_blank">  هومینجا </LinkMui> گفتیم چیتگر را بعنوان یکی از بهترین مناطق تهران برای زندگی می توان در نظر گرفت. امکانات رفاهی مناسب و دسترسی راحت به مناطق تهران از دلایل مناسب سکونت در این منطقه می باشد. امید است که این مطالب برای شما مفید واقع شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین برای مشاهده تمام آگهی های خرید و فروش ملک درتهران و رهن و اجاره ملک در تهران به لینکهای زیر مراجعه بفرمایید:
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>ممکن است مطالعه مقالات زیر هم برای شما مفید باشند.</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Azadi'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    محله آزادی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Hakimiyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    حکیمیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/niavaran-tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    محله نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Shemiran-No'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    شمیران نو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Piroozi-street'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    پیروزی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>



              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article45;