import { Button, Divider, Grid, OutlinedInput, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class PurchasesUser extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        lists:[],
        user:props.user,
        searchText:'',
        openAlert:false,
        message:'',
        giftShow:false,
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'تاریخچه خریدهای آگهی هومینجا'
    if (this.isMount){
      if (this.state.user !== null){
        if (this.state.user.user_type_id < 5){
          this.getPurchases();
        }
      }
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  getPurchases = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/purchase/get',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token},
      params:{
        user_id:this.state.searchText
      }
    }))
    .then((res) => {
      let items = res.data.data;
      this.setState({lists:items,giftShow:false})
    })
    .catch((err) => {
      this.setState({lists:[],giftShow:false})
    });
  }

  createPurchaseList = () =>{
    let items = [];
    this.state.lists.forEach((element,index) =>{
      items.push(
        <Grid item xs={12} key={index}
              style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around',
                      backgroundColor:index % 2 === 0 ? 'white' : '#CCF4FB',borderRadius:16,
                      width:'100%',marginBottom:'10px',flexWrap:'wrap',filter:'drop-shadow(0px 0px 2px rgba(0, 200, 233, 0.32))'}}
        >
          <Grid item lg={3} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'شماره آگهی : '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
              {convertToPersianNumber(element.listing_number)}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'نوع ارتقا : '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
              {convertToPersianNumber(element.plan.name)}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'تاریخ : '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
              {convertToPersianNumber(element.date)}
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{height:{md:'80px',xs:'60px'}}}
          >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'ساعت : '}</Typography>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
              {convertToPersianNumber(element.time)}
            </Typography>
          </Grid>
        </Grid> 
      )
    })
    return items;
  }

  onChangeSearch = (event) =>{
    this.setState({searchText:event.target.value.trim()});
  }

  onClickSearch = () =>{
    if (this.state.searchText.trim() !== ''){
      this.setState({giftShow:true});
      this.getPurchases();
    }
    else{
      this.setState({openAlert:true,message:'چیزی برای جستجو وارد نکرده اید.',typeAlert:3})
    }
  }
  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>خریدها</Typography>
        </Grid>
        {
          this.state.user !== null ?
            this.state.user.user_type_id < 5 ?
              this.state.lists.length > 0 ?
                <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                      sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                >   
                  {this.createPurchaseList()}
                </Grid>
              :
                <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                      sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                >   
                  <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                    <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                      {'شما تا این لحظه هیچ واریزی نداشته اید.'}
                    </Typography>
                  </Grid>
                </Grid>
            :
            <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                  sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
            >   
              <Grid item xs={10} style={{display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',alignItems:'center',marginTop:25}}>
                <Grid item xs={8} style={{display:'flex',width:'100%'}}>
                  <OutlinedInput variant="outlined" onChange={(event) => this.onChangeSearch(event)} value={this.state.searchText}
                    style={{borderStyle:'none',width:'100%',fontFamily:'shabnam',fontWeight:'bold',backgroundColor:'#F1F2F2',
                            maxHeight:70,borderRadius:0}}
                    sx={{height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
                    inputProps={{maxLength:30}}
                  />
                </Grid>
                <Grid item xs={4} style={{display:'flex',width:'100%'}}>
                  <Button onClick={this.onClickSearch} 
                    style={{backgroundColor:'#00C8E9',color:'black',fontFamily:'shabnam',
                            fontWeight:'bold',borderRadius:0,borderBottomLeftRadius:26}}
                    sx={{width:{xs:'100%'},height:{md:'60px',xs:'50px'},fontSize:{md:'20px',xs:'16px'}}}
                  >
                    {'جستجو'}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{display:'flex',alignItems:'center',width:'100%',marginTop:20,marginBottom:20}}>
                <Grid item lg={1.5} md={2.5} xs={2} style={{display:'flex',width:'100%'}}>
                  <Typography component={'p'} 
                    style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                    sx={{fontSize:{sm:'16px',xs:'13px'}}}
                  >
                    {'نتیجه جستجو'}
                  </Typography>
                </Grid>
                <Grid item lg={8.5} md={7.5} xs={8} style={{display:'flex',width:'100%',flexDirection:'column'}}>
                  <Divider/>
                </Grid>
                <Grid item xs={0.5} style={{display:'flex',width:'100%'}}>
                  <Typography component={'p'} 
                    style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                    sx={{fontSize:{sm:'16px',xs:'13px'}}}
                  >
                    {convertToPersianNumber(this.state.lists.length)}
                  </Typography>
                </Grid>
                <Grid item xs={1.5} style={{display:'flex',width:'100%'}}>
                  <Typography component={'p'} 
                    style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',textAlign:'center'}}
                    sx={{fontSize:{sm:'16px',xs:'13px'}}}
                  >
                    {'خرید'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}>
                {
                  this.state.lists.length > 0 ?
                    <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                          sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                    >   
                      {this.createPurchaseList()}
                    </Grid>
                  :
                    <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                          sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                    >   
                      <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                        <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                          {'هیچ موردی جهت نمایش وجود ندارد.'}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                
              </Grid>
            </Grid>
          :
          <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
          >   
            <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
              <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                {'مشکلی در دریافت اطلاعات وجود دارد.'}
              </Typography>
            </Grid>
          </Grid>
        }
        <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    )
  };
}

export default PurchasesUser;