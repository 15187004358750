import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Home from './component/home';
import Nav from './component/nav';
import AboutUs from './component/about';
import Contact from './component/contact';
import Listings from './component/listings';
import Listing from './component/listing';
import Profile from './component/panel';
import Agancy from './component/agancy';
import HomeMag from './component/magazine';
import FAQ from './component/faq';
import Terms from './component/terms';
import AgencyPage from './component/agencyPage';
import BadRequest from './component/badRequest';
import Article01 from './component/mag/article01';
import Article02 from './component/mag/article02';
import Article03 from './component/mag/article03';
import Article04 from './component/mag/article04';
import Article05 from './component/mag/article05';
import Article06 from './component/mag/article06';
import Article07 from './component/mag/article07';
import Article08 from './component/mag/article08';
import Article09 from './component/mag/article09';
import Article10 from './component/mag/article10';
import Article11 from './component/mag/article11';
import Article12 from './component/mag/article12';
import Article13 from './component/mag/article13';
import Article14 from './component/mag/article14';
import Article15 from './component/mag/article15';
import Article16 from './component/mag/article16';
import Article17 from './component/mag/article17';
import Article18 from './component/mag/article18';
import Article19 from './component/mag/article19';
import Article20 from './component/mag/article20';
import Article21 from './component/mag/article21';
import Article22 from './component/mag/article22';
import Article23 from './component/mag/article23';
import Article24 from './component/mag/article24';
import Article25 from './component/mag/article25';
import Article26 from './component/mag/article26';
import Article27 from './component/mag/article27';
import Article28 from './component/mag/article28';
import Article29 from './component/mag/article29';
import Article30 from './component/mag/article30';
import Article31 from './component/mag/article31';
import Article32 from './component/mag/article32';
import Article33 from './component/mag/article33';
import Article34 from './component/mag/article34';
import Article35 from './component/mag/article35';
import Article36 from './component/mag/article36';
import Article37 from './component/mag/article37';
import Article38 from './component/mag/article38';
import Article39 from './component/mag/article39';
import Article40 from './component/mag/article40';
import Article41 from './component/mag/article41';
import Article42 from './component/mag/article42';
import Article43 from './component/mag/article43';
import Article44 from './component/mag/article44';
import Article45 from './component/mag/article45';
import Article46 from './component/mag/article46';
import Article47 from './component/mag/article47';
import Article48 from './component/mag/article48';
import Article49 from './component/mag/article49';
import Article50 from './component/mag/article50';
import Article51 from './component/mag/article51';
import Article52 from './component/mag/article52';
import Article53 from './component/mag/article53';
import Article54 from './component/mag/article54';
import Article55 from './component/mag/article55';
import Article56 from './component/mag/article56';
import Article57 from './component/mag/article57';
import Article58 from './component/mag/article58';
import Article59 from './component/mag/article59';
import Article60 from './component/mag/article60';
import Article61 from './component/mag/article61';
import Article62 from './component/mag/article62';
import Article63 from './component/mag/article63';
import Article64 from './component/mag/article64';
import Article65 from './component/mag/article65';
import Article66 from './component/mag/article66';
import Article67 from './component/mag/article67';
import Article68 from './component/mag/article68';
import Article69 from './component/mag/article69';
import Article70 from './component/mag/article70';

import NotFound from './component/notFound';
import TehranZone01 from './component/tehranZones/zone01';
import TehranZone02 from './component/tehranZones/zone02';
import TehranZone03 from './component/tehranZones/zone03';
import TehranZone04 from './component/tehranZones/zone04';
import TehranZone05 from './component/tehranZones/zone05';
import TehranZone06 from './component/tehranZones/zone06';
import TehranZone07 from './component/tehranZones/zone07';
import TehranZone08 from './component/tehranZones/zone08';
import TehranZone09 from './component/tehranZones/zone09';
import TehranZone10 from './component/tehranZones/zone10';
import TehranZone11 from './component/tehranZones/zone11';
import TehranZone12 from './component/tehranZones/zone12';
import TehranZone13 from './component/tehranZones/zone13';
import TehranZone14 from './component/tehranZones/zone14';
import TehranZone15 from './component/tehranZones/zone15';
import TehranZone16 from './component/tehranZones/zone16';
import TehranZone17 from './component/tehranZones/zone17';
import TehranZone18 from './component/tehranZones/zone18';
import TehranZone19 from './component/tehranZones/zone19';
import TehranZone20 from './component/tehranZones/zone20';
import TehranZone21 from './component/tehranZones/zone21';
import TehranZone22 from './component/tehranZones/zone22';
import TehranZones from './component/tehranZones';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Nav/>
        <Routes>
                <Route path='/' element={<Home />} exact/>
                <Route path='/HomeMag' element={<HomeMag/>}/>
                <Route path='/Contact' element={<Contact/>}/>
                <Route path='/AboutUs' element={<AboutUs/>}/>
                <Route path='/Listings' element={<Listings/>}/>
                <Route path='/Agancy' element={<Agancy/>}/>
                <Route path='/Listing/:id' element={<Listing/>}/>
                <Route path='/Profile' element={<Profile user={JSON.parse(localStorage.getItem('infoUserHomeInja'))}/>}/>
                <Route path='/Profile/rl' element={<Profile user={JSON.parse(localStorage.getItem('infoUserHomeInja'))} page={'requesthome'}/>}/>
                <Route path='/Profile/pl' element={<Profile user={JSON.parse(localStorage.getItem('infoUserHomeInja'))} page={'createlisting'}/>}/>
                <Route path='/FAQ' element={<FAQ/>}/>
                <Route path='/Terms' element={<Terms/>}/>
                <Route path='/AgencyPage/:id' element={<AgencyPage/>}/>
                <Route path='/Bad-Request' element={<BadRequest/>}/>
                <Route path='*' element={<NotFound/>}/>
                <Route path='/Midex' element={<Article01/>}/>
                <Route path='/north-of-Tehran-cafe' element={<Article02/>}/>
                <Route path='/where-to-buy-sleep-product' element={<Article03/>}/>
                <Route path='/property-document' element={<Article04/>}/>
                <Route path='/Tehran-cafe-with-hooka' element={<Article05/>}/>
                <Route path='/premade-canopy' element={<Article06/>}/>
                <Route path='/way-to-get-house-loan' element={<Article07/>}/>
                <Route path='/fengshui' element={<Article08/>}/>
                <Route path='/chandelier-in-decoration' element={<Article09/>}/>
                <Route path='/Tehran-spectaculars' element={<Article10/>}/>
                <Route path='/keeping-apartment-flower' element={<Article11/>}/>
                <Route path='/laundry-room' element={<Article12/>}/>
                <Route path='/accent-wall' element={<Article13/>}/>
                <Route path='/Piroozi-street' element={<Article14/>}/>
                <Route path='/buying-house-in-Tehran' element={<Article15/>}/>
                <Route path='/Shemiran-No' element={<Article16/>}/>
                <Route path='/book-cafe' element={<Article17/>}/>
                <Route path='/Hakimiyeh' element={<Article18/>}/>
                <Route path='/Azadi' element={<Article19/>}/>
                <Route path='/moving-the-house' element={<Article20/>}/>
                <Route path='/real-estate-fraud' element={<Article21/>}/>
                <Route path='/building-certificate' element={<Article22/>}/>
                <Route path='/transferring-property' element={<Article23/>}/>
                <Route path='/real-estate-interesting-things' element={<Article24/>}/>
                <Route path='/house-tracking-code' element={<Article25/>}/>
                <Route path='/real-estate' element={<Article26/>}/>
                <Route path='/professional-real-estate' element={<Article27/>}/>
                <Route path='/amlak.mrud.ir' element={<Article28/>}/>
                <Route path='/tehran-winter-entertaiments' element={<Article29/>}/>
                <Route path='/best-restaurant-tehran-region1' element={<Article30/>}/>
                <Route path='/building-facades-types' element={<Article31/>}/>
                <Route path='/small-house-decoration' element={<Article32/>}/>
                <Route path='/smart-home' element={<Article33/>}/>
                <Route path='/pouf' element={<Article34/>}/>
                <Route path='/apartment-pre-sale' element={<Article35/>}/>
                <Route path='/mehr-house' element={<Article36/>}/>
                <Route path='/niavaran-tehran' element={<Article37/>}/>
                <Route path='/Business-Right' element={<Article38/>}/>
                <Route path='/Children-room-decoration' element={<Article39/>}/>
                <Route path='/Housing-Market-Prediction-1402' element={<Article40/>}/>
                <Route path='/Spring-Cleaning' element={<Article41/>}/>
                <Route path='/Tehran-District-One-Luxurious-Towers' element={<Article42/>}/>
                <Route path='/Real-Estate-Agency-License' element={<Article43/>}/>
                <Route path='/Elahiyeh-Neighborhood-Fereshteh-Street' element={<Article44/>}/>
                <Route path='/Chitgar-Neighborhood' element={<Article45/>}/>
                <Route path='/Interior-architecture-and-interior-design' element={<Article46/>}/>
                <Route path='/Successful-Women-Real-Estate' element={<Article47/>}/>
                <Route path='/top-5-real-estate-sites' element={<Article48/>}/>
                <Route path='/tehran-district-22' element={<Article49/>}/>
                <Route path='/best-cafes-iftar-til-suhur' element={<Article50/>}/>
                <Route path='/Andarzgoo-blvd' element={<Article51/>}/>
                <Route path='/Best-Iranian-Real-Estate-Site' element={<Article52/>}/>
                <Route path='/1402-Tehran-Construction-Cost' element={<Article53/>}/>
                <Route path='/Farmanieh-Neighborhood' element={<Article54/>}/>
                <Route path='/1402-House-Loan' element={<Article55/>}/>
                <Route path='/Home-Freshener' element={<Article56/>}/>
                <Route path='/IOT' element={<Article57/>}/>
                <Route path='/Home-Automation' element={<Article58/>}/>
                <Route path='/Ekbatan-Town' element={<Article59/>}/>
                <Route path='/Partnering-Construction-In-1402' element={<Article60/>}/>
                <Route path='/Best-Board-Game-Cafe-In-Tehran' element={<Article61/>}/>
                <Route path='/Zaferanieh-Neighborhood' element={<Article62/>}/>
                <Route path='/What-Is-Functional-Training' element={<Article63/>}/>
                <Route path='/what-is-house-renovation' element={<Article64/>}/>
                <Route path='/weight-loss-and-fitness' element={<Article65/>}/>
                <Route path='/Kamranieh-Neighborhood' element={<Article66/>}/>
                <Route path='/What-Is-Cardio-Exercises' element={<Article67/>}/>
                <Route path='/Jamshidie-Neighborhood' element={<Article68/>}/>
                <Route path='/Fasting-Diet' element={<Article69/>}/>
                <Route path='/What-Is-YOGA-Exercise' element={<Article70/>}/>

                <Route path='/zaferaniye' element={<Article62/>}/>
                <Route path='/tehran-zones' element={<TehranZones/>}/>
                <Route path='/tehran-zone-1' element={<TehranZone01/>}/>
                <Route path='/tehran-zone-2' element={<TehranZone02/>}/>
                <Route path='/tehran-zone-3' element={<TehranZone03/>}/>
                <Route path='/tehran-zone-4' element={<TehranZone04/>}/>
                <Route path='/tehran-zone-5' element={<TehranZone05/>}/>
                <Route path='/tehran-zone-6' element={<TehranZone06/>}/>
                <Route path='/tehran-zone-7' element={<TehranZone07/>}/>
                <Route path='/tehran-zone-8' element={<TehranZone08/>}/>
                <Route path='/tehran-zone-9' element={<TehranZone09/>}/>
                <Route path='/tehran-zone-10' element={<TehranZone10/>}/>
                <Route path='/tehran-zone-11' element={<TehranZone11/>}/>
                <Route path='/tehran-zone-12' element={<TehranZone12/>}/>
                <Route path='/tehran-zone-13' element={<TehranZone13/>}/>
                <Route path='/tehran-zone-14' element={<TehranZone14/>}/>
                <Route path='/tehran-zone-15' element={<TehranZone15/>}/>
                <Route path='/tehran-zone-16' element={<TehranZone16/>}/>
                <Route path='/tehran-zone-17' element={<TehranZone17/>}/>
                <Route path='/tehran-zone-18' element={<TehranZone18/>}/>
                <Route path='/tehran-zone-19' element={<TehranZone19/>}/>
                <Route path='/tehran-zone-20' element={<TehranZone20/>}/>
                <Route path='/tehran-zone-21' element={<TehranZone21/>}/>
                <Route path='/tehran-zone-22' element={<TehranZone22/>}/>
                <Route path='/Listings/buy_tehran' element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1}}}/>}/>
                <Route path='/Listings/rent_tehran' element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1}}}/>}/>
                <Route path='/Listings/buy_tehran_araj' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 3, name: 'اراج'}]}}/>}/>
                <Route path='/Listings/rent_tehran_araj' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 3, name: 'اراج'}]}}/>}/>
                <Route path='/Listings/buy_tehran_azgol' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 4, name: 'ازگل'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azgol' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 4, name: 'ازگل'}]}}/>}/>
                <Route path='/Listings/buy_tehran_estanbol' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 5, name: 'استانبول'}]}}/>}/>
                <Route path='/Listings/rent_tehran_estanbol' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 5, name: 'استانبول'}]}}/>}/>
                <Route path='/Listings/buy_tehran_esfandiyar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 6, name: 'اسفندیار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_esfandiyar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 6, name: 'اسفندیار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aghdasieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 7, name: 'اقدسیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aghdasieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 7, name: 'اقدسیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emamzadeghasem' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 8, name: 'امام زاده قاسم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamzadeghasem' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 8, name: 'امام زاده قاسم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_elahieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 9, name: 'الهیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_elahieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 9, name: 'الهیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_andarzgoo' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 10, name: 'اندرزگو'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_andarzgoo' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 10, name: 'اندرزگو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_evin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 11, name: 'اوین'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_evin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 11, name: 'اوین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abadan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 12, name: 'آبادان'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_abadan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 12, name: 'آبادان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ajudanieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 13, name: 'آجودانیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_ajudanieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 13, name: 'آجودانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_asef' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 14, name: 'آصف'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_asef' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 14, name: 'آصف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baghferdos' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 15, name: 'باغ فردوس'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_baghferdos' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 15, name: 'باغ فردوس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bametehran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 16, name: 'بام تهران'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_bametehran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 16, name: 'بام تهران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_artesh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 18, name: 'بلوار ارتش'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_artesh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 18, name: 'بلوار ارتش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_oshan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 19, name: 'بلوار اوشان'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_oshan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 19, name: 'بلوار اوشان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_afrigha' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 20, name: 'بلوار آفریقا'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_afrigha' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 20, name: 'بلوار آفریقا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_basij' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 21, name: 'بلوار بسیج'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_basij' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 21, name: 'بلوار بسیج'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahed' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 22, name: 'بلوار شاهد'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shahed' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 22, name: 'بلوار شاهد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kaveh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 23, name: 'بلوار کاوه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_kaveh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 23, name: 'بلوار کاوه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golha' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 24, name: 'بلوار گلها'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_golha' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 24, name: 'بلوار گلها'}]}}/>}/>
                <Route path='/Listings/buy_tehran_niruhavaeeblv' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 25, name: 'بلوار نیرو هوایی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_niruhavaeeblv' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 25, name: 'بلوار نیرو هوایی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_niruzamini' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 26, name: 'بلوار نیروی زمینی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_niruzamini' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 26, name: 'بلوار نیروی زمینی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_niruzamini' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 26, name: 'بلوار نیروی زمینی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_niruzamini' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 26, name: 'بلوار نیروی زمینی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_parkvay' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 27, name: 'پارک وی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_parkvay' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 27, name: 'پارک وی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_pirasteh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 28, name: 'پیراسته'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_pirasteh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 28, name: 'پیراسته'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tajrish' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 29, name: 'تجریش'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_tajrish' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 29, name: 'تجریش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jafarabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 30, name: 'جعفرآباد'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_jafarabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 30, name: 'جعفرآباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jamaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 31, name: 'جماران'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_jamaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 31, name: 'جماران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jamshideh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 32, name: 'جمشیدیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_jamshideh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 32, name: 'جمشیدیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_juzestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 33, name: 'جوزستان'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_juzestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 33, name: 'جوزستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_chizer' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 34, name: 'چیذر'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_chizer' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 34, name: 'چیذر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hesarbuali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 35, name: 'حصاربوعلی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_hesarbuali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 35, name: 'حصاربوعلی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hekmat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 36, name: 'حکمت'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_hekmat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 36, name: 'حکمت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_darabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 37, name: 'دارآباد'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_darabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 37, name: 'دارآباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_darband' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 38, name: 'دربند'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_darband' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 38, name: 'دربند'}]}}/>}/>
                <Route path='/Listings/buy_tehran_darakeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 39, name: 'درکه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_darakeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 39, name: 'درکه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dezashib' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 40, name: 'دزاشیب'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_dezashib' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 40, name: 'دزاشیب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dibajonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 41, name: 'دیباجی جنوبی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_dibajonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 41, name: 'دیباجی جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dibashomali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 42, name: 'دیباجی شمالی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_dibashomali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 42, name: 'دیباجی شمالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rostamabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 43, name: 'رستم آباد'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_rostamabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 43, name: 'رستم آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zaaferanieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 44, name: 'زعفرانیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_zaaferanieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 44, name: 'زعفرانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saadabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 45, name: 'سعد آباد'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_saadabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 45, name: 'سعد آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sohanak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 46, name: 'سوهانک'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_sohanak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 46, name: 'سوهانک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shariati' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 47, name: 'شریعتی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shariati' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 47, name: 'شریعتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakalborz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 48, name: 'شهرک البرز'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shahrakalborz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 48, name: 'شهرک البرز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakdaneshgabeheshti' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 49, name: 'شهرک دانشگاه بهشتی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shahrakdaneshgabeheshti' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 49, name: 'شهرک دانشگاه بهشتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakshahidchamran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 50, name: 'شهرک شهید چمران'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shahrakshahidchamran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 50, name: 'شهرک شهید چمران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahraknaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 51, name: 'شهرک نفت'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shahraknaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 51, name: 'شهرک نفت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidmahalati' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 52, name: 'شهید محلاتی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_shahidmahalati' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 52, name: 'شهید محلاتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sabunian' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 53, name: 'صابونیان'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_sabunian' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 53, name: 'صابونیان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dahebgharanie' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 54, name: 'صاحب قرانیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_dahebgharanie' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 54, name: 'صاحب قرانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 55, name: 'عمار'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_amar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 55, name: 'عمار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_fereshte' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 56, name: 'فرشته'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fereshte' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 56, name: 'فرشته'}]}}/>}/>
                <Route path='/Listings/buy_tehran_farmaniyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 57, name: 'فرمانیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_farmaniyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 57, name: 'فرمانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_falahi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 58, name: 'فلاحی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_falahi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 58, name: 'فلاحی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghaitarieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 59, name: 'قیطریه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_ghaitarieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 59, name: 'قیطریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kashanak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 60, name: 'کاشانک'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_kashanak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 60, name: 'کاشانک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kamranieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 61, name: 'کامرانیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_kamranieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 61, name: 'کامرانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golabdareh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 62, name: 'گلابدره'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_golabdareh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 62, name: 'گلابدره'}]}}/>}/>
                <Route path='/Listings/buy_tehran_makuepur' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 63, name: 'ماکوئی پور'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_makuepur' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 63, name: 'ماکوئی پور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mahmudieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 64, name: 'محمودیه'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_mahmudieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 64, name: 'محمودیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_moghadasardabili' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 65, name: 'مقدس اردبیلی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_moghadasardabili' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 65, name: 'مقدس اردبیلی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_moheddanesh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 66, name: 'موحد دانش'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_moheddanesh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 66, name: 'موحد دانش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_minicity' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 67, name: 'مینی سیتی'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_minicity' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 67, name: 'مینی سیتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_narenjestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 68, name: 'نارنجستان'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_narenjestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 68, name: 'نارنجستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nakhl' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 69, name: 'نخل'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_nakhl' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 69, name: 'نخل'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nobonyad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 70, name: 'نوبنیاد'}]}}/>}/>        
                <Route path='/Listings/rent_tehran_nobonyad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 70, name: 'نوبنیاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_niyavaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 71, name: 'نیاوران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_niyavaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 71, name: 'نیاوران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_velenjak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 72, name: 'ولنجک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_velenjak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 72, name: 'ولنجک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_valiasr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 73, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valiasr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 73, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_marjan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 74, name: 'مرجان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_marjan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 74, name: 'مرجان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bukan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 75, name: 'بوکان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bukan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 75, name: 'بوکان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_homayun' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 76, name: 'همایون'}]}}/>}/>
                <Route path='/Listings/rent_tehran_homayun' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                        nieghbourhood:[{id: 76, name: 'همایون'}]}}/>}/>
                <Route path='/Listings/buy_zone_one' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                       }}/>}/>
                <Route path='/Listings/rent_zone_one' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 1', id: 1},
                                                       }}/>}/>
                <Route path='/Listings/buy_tehran_ashrafiesfahani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 82, name: 'اشرفی اصفهانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ashrafiesfahani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 82, name: 'اشرفی اصفهانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_isar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 83, name: 'ایثار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_isar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 83, name: 'ایثار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ayvanak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 84, name: 'ایوانک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ayvanak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 84, name: 'ایوانک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zarbayejan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 85, name: 'آذربایجان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zarbayejan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 85, name: 'آذربایجان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_azadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 86, name: 'آزادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 86, name: 'آزادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_asemanha' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 87, name: 'آسمانها'}]}}/>}/>
                <Route path='/Listings/rent_tehran_asemanha' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 87, name: 'آسمانها'}]}}/>}/>
                <Route path='/Listings/buy_tehran_barghealestom' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 88, name: 'برق آلستوم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_barghealestom' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 88, name: 'برق آلستوم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ayatkashani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 90, name: 'بلوار آیت الله کاشانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ayatkashani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 90, name: 'بلوار آیت الله کاشانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_paknejad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 91, name: 'بلوار پاکنژاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_paknejad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 91, name: 'بلوار پاکنژاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_farahzadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 92, name: 'بلوار فرحزادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_farahzadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 92, name: 'بلوار فرحزادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_buali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 93, name: 'بوعلی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_buali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 93, name: 'بوعلی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_behbudi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 94, name: 'بهبودی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_behbudi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 94, name: 'بهبودی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_pardisan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 95, name: 'پردیسان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_pardisan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 95, name: 'پردیسان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_parvaz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 96, name: 'پرواز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_parvaz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 96, name: 'پرواز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_poonak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 97, name: 'پونک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_poonak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 97, name: 'پونک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tohid' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 98, name: 'توحید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tohid' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 98, name: 'توحید'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehranvila' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 99, name: 'تهران ویلا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehranvila' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 99, name: 'تهران ویلا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taimuri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 100, name: 'تیموری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taimuri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 100, name: 'تیموری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jalalahmad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 101, name: 'جلال آل احمد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jalalahmad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 101, name: 'جلال آل احمد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khosheshomali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 102, name: 'خوش شمالی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khosheshomali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 102, name: 'خوش شمالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_eskandarist' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 103, name: 'خیابان اسکندری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_eskandarist' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 103, name: 'خیابان اسکندری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_derakhti' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 104, name: 'درختی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_derakhti' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 104, name: 'درختی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_darya' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 105, name: 'دریا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_darya' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 105, name: 'دریا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_daryanno' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 106, name: 'دریان نو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_daryanno' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 106, name: 'دریان نو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zanjan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 107, name: 'زنجان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zanjan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 107, name: 'زنجان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sepehr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 108, name: 'سپهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sepehr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 108, name: 'سپهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_satarkhan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 109, name: 'ستارخان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_satarkhan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 109, name: 'ستارخان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sarv' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 110, name: 'سرو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sarv' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 110, name: 'سرو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saadatabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 111, name: 'سعادت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saadatabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 111, name: 'سعادت آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_marzdaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 112, name: 'مرزداران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_marzdaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 112, name: 'مرزداران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shademan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 113, name: 'شادمان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shademan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 113, name: 'شادمان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shadmehr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 114, name: 'شادمهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shadmehr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 114, name: 'شادمهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shohadayebargh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 115, name: 'شهدای شرکت برق'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shohadayebargh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 115, name: 'شهدای شرکت برق'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrara' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 116, name: 'شهرآرا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrara' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 116, name: 'شهرآرا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrkeazmayesh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 117, name: 'شهرک آزمایش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrkeazmayesh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 117, name: 'شهرک آزمایش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakejandarmeri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 118, name: 'شهرک ژاندارمری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakejandarmeri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 118, name: 'شهرک ژاندارمری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakegharb' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 119, name: 'شهرک غرب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakegharb' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 119, name: 'شهرک غرب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeghods' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 120, name: 'شهرک قدس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeghods' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 120, name: 'شهرک قدس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakehoma' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 121, name: 'شهرک هما'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakehoma' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 121, name: 'شهرک هما'}]}}/>}/>
                <Route path='/Listings/buy_tehran_chobtarash' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 122, name: 'شهید چوب تراش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_chobtarash' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 122, name: 'شهید چوب تراش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_chobtarash' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 122, name: 'شهید چوب تراش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_chobtarash' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 122, name: 'شهید چوب تراش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sadeghieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 123, name: 'صادقیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sadeghieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 123, name: 'صادقیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tarasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 125, name: 'طرشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tarasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 125, name: 'طرشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_farahzad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 126, name: 'فرحزاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_farahzad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 126, name: 'فرحزاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_falahzade' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 127, name: 'فلاح زاده'}]}}/>}/>
                <Route path='/Listings/rent_tehran_falahzade' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 127, name: 'فلاح زاده'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuhsar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 128, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuhsar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 128, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuhestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 129, name: 'کوهستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuhestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 129, name: 'کوهستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuyefaraz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 130, name: 'کوی فراز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuyefaraz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 130, name: 'کوی فراز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuyenasr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 131, name: 'کوی نصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuyenasr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 131, name: 'کوی نصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_gisha' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 132, name: 'گیشا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_gisha' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 132, name: 'گیشا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_metrosharif' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 133, name: 'مترو شریف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_metrosharif' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 133, name: 'مترو شریف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mokhaberat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 134, name: 'مخابرات'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mokhaberat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 134, name: 'مخابرات'}]}}/>}/>
                <Route path='/Listings/buy_tehran_modiriyat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 135, name: 'مدیریت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_modiriyat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 135, name: 'مدیریت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_homayunshar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 136, name: 'همایونشهر'}]}}/>}/>
                                                         <Route path='/Listings/rent_tehran_modiriyat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2},
                                                        nieghbourhood:[{id: 135, name: 'مدیریت'}]}}/>}/>
                <Route path='/Listings/buy_zone_two' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2}
                        }}/>}/>
                <Route path='/Listings/rent_zone_two' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 2', id: 2}
                        }}/>}/>
                <Route path='/Listings/buy_tehran_ehteshamiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 137, name: 'احتشامیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ehteshamiyeh' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 137, name: 'احتشامیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ekhtiyareh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 138, name: 'اختیاریه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ekhtiyareh' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 138, name: 'اختیاریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_esfandiyar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 139, name: 'اسفندیار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_esfandiyar' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 139, name: 'اسفندیار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amaniyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 140, name: 'امانیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_amaniyeh' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 140, name: 'امانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ararat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 141, name: 'آرارات'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ararat' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 141, name: 'آرارات'}]}}/>}/>
                <Route path='/Listings/buy_tehran_behroz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 143, name: 'بهروز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_behroz' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 143, name: 'بهروز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_pasdaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 144, name: 'پاسداران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_pasdaran' 
                        element={<Listings option={{transaction:3,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 144, name: 'پاسداران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jordan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 145, name: 'جردن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jordan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 145, name: 'جردن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jolfa' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 146, name: 'جلفا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jolfa' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 146, name: 'جلفا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hesari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 147, name: 'حصاری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hesari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 147, name: 'حصاری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khajeabdolah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 148, name: 'خواجه عبداله'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khajeabdolah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 148, name: 'خواجه عبداله'}]}}/>}/>
                <Route path='/Listings/buy_tehran_davodiye' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 149, name: 'داودیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_davodiye' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 149, name: 'داودیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_darbedovom' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 150, name: 'درب دوم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_darbedovom' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 150, name: 'درب دوم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_darus' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 151, name: 'دروس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_darus' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 151, name: 'دروس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dolat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 152, name: 'دولت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dolat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 152, name: 'دولت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kolahduz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 153, name: 'کلاهدوز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kolahduz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 153, name: 'کلاهدوز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dibaji' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 154, name: 'دیباجی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dibaji' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 154, name: 'دیباجی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rostamabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 155, name: 'رستم آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_rostamabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 155, name: 'رستم آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zargande' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 156, name: 'زرگنده'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zargande' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 156, name: 'زرگنده'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saidkhandan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 157, name: 'سید خندان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saidkhandan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 157, name: 'سید خندان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_seool' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 158, name: 'سئول'}]}}/>}/>
                <Route path='/Listings/rent_tehran_seool' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 158, name: 'سئول'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shaikhbahaee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 159, name: 'شیخ بهایی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shaikhbahaee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 159, name: 'شیخ بهایی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shiraz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 160, name: 'شیراز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shiraz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 160, name: 'شیراز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zafar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 161, name: 'ظفر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zafar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 161, name: 'ظفر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghoba' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 162, name: 'قبا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghoba' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 162, name: 'قبا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_gholhak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 163, name: 'قلهک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_gholhak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 163, name: 'قلهک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kavuseh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 164, name: 'کاوسیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kavuseh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 164, name: 'کاوسیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 165, name: 'گلستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_golestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 165, name: 'گلستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golestanshomali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 166, name: 'گلستان شمالی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_golestanshomali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 166, name: 'گلستان شمالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golestanjonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 167, name: 'گلستان جنوبی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_golestanjonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 167, name: 'گلستان جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_molasadra' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 168, name: 'ملاصدرا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_molasadra' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 168, name: 'ملاصدرا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ketabi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 169, name: 'میدان کتابی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ketabi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 169, name: 'میدان کتابی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mirdamad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 170, name: 'میرداماد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mirdamad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 170, name: 'میرداماد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_varasteh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 171, name: 'وارسته'}]}}/>}/>
                <Route path='/Listings/rent_tehran_varasteh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 171, name: 'وارسته'}]}}/>}/>
                <Route path='/Listings/buy_tehran_valiasr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 172, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valiasr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 172, name: 'ولیعصر'}]}}/>}/>                
                <Route path='/Listings/buy_tehran_vanak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 173, name: 'ونک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_vanak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        nieghbourhood:[{id: 173, name: 'ونک'}]}}/>}/>
                <Route path='/Listings/buy_zone_three' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                        }}/>}/>
                <Route path='/Listings/rent_zone_three' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 3', id: 3},
                                                       }}/>}/>
                <Route path='/Listings/buy_tehran_mehran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 174, name: 'مهران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mehran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 174, name: 'مهران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kazemabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 175, name: 'کاظم آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kazemabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 175, name: 'کاظم آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuhak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 176, name: 'کوهک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuhak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 176, name: 'کوهک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_majidieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 177, name: 'مجیدیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_majidieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 177, name: 'مجیدیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shamsabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 178, name: 'شمس آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shamsabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 178, name: 'شمس آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zarabkhaneh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 180, name: 'ضرابخانه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zarabkhaneh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 180, name: 'ضرابخانه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hosainabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 181, name: 'حسین آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hosainabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 181, name: 'حسین آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mobarkabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 182, name: 'مبارک آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mobarkabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 182, name: 'مبارک آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shiyan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 183, name: 'شیان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shiyan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 183, name: 'شیان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_lavizan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 184, name: 'لویزان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_lavizan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 184, name: 'لویزان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_elmosanat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 185, name: 'علم و صنعت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_elmosanat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 185, name: 'علم و صنعت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_narmak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 186, name: 'نارمک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_narmak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 186, name: 'نارمک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehranparsgharbi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 187, name: 'تهرانپارس غربی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehranparsgharbi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 187, name: 'تهرانپارس غربی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_javadieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 188, name: 'جوادیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_javadieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 188, name: 'جوادیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khakesefid' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 189, name: 'خاک سفید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khakesefid' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 189, name: 'خاک سفید'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehranparsshargi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 190, name: 'تهرانپارس شرقی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehranparsshargi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 190, name: 'تهرانپارس شرقی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghasemabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 191, name: 'قاسم آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghasemabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 191, name: 'قاسم آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dehnarmak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 192, name: 'ده نارمک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dehnarmak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 192, name: 'ده نارمک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_oghaf' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 193, name: 'اوقاف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_oghaf' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 193, name: 'اوقاف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shemiranno' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 194, name: 'شمیران نو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shemiranno' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 194, name: 'شمیران نو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hakimieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 195, name: 'حکیمیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hakimieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 195, name: 'حکیمیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghanatekosar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 196, name: 'قنات کوثر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghanatekosar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 196, name: 'قنات کوثر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuhsarshargi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 197, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuhsarshargi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 197, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_majidabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 198, name: 'مجید آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_majidabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 198, name: 'مجید آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_otubanbagheri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 200, name: 'اتوبان باقری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_otubanbagheri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 200, name: 'اتوبان باقری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_banihashem' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 202, name: 'بنی هاشم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_banihashem' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 202, name: 'بنی هاشم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_police' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 203, name: 'پلیس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_police' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 203, name: 'پلیس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_delavaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 204, name: 'دلاوران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_delavaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 204, name: 'دلاوران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_resalat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 205, name: 'رسالت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_resalat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 205, name: 'رسالت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_seraj' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 206, name: 'سراج'}]}}/>}/>
                <Route path='/Listings/rent_tehran_seraj' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 206, name: 'سراج'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shohada' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 207, name: 'شهدا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shohada' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 207, name: 'شهدا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sayadeshirazi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 208, name: 'صیاد شیرازی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sayadeshirazi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 208, name: 'صیاد شیرازی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_farjam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 209, name: 'فرجام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_farjam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 209, name: 'فرجام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_maidanemelat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 210, name: 'میدان ملت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_maidanemelat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 210, name: 'میدان ملت'}]}}/>}/>
               <Route path='/Listings/buy_tehran_heravi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 211, name: 'هروی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_heravi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 211, name: 'هروی'}]}}/>}/>
               <Route path='/Listings/buy_tehran_hengam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 212, name: 'هنگام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hengam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 212, name: 'هنگام'}]}}/>}/>
               <Route path='/Listings/buy_tehran_parvin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 213, name: 'پروین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_parvin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 213, name: 'پروین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saghdush' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 214, name: 'ساقدوش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saghdush' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 214, name: 'ساقدوش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_estakhr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 215, name: 'استخر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_estakhr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 215, name: 'استخر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nobonyad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 216, name: 'نوبنیاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nobonyad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 216, name: 'نوبنیاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_vafamanesh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 218, name: 'وفامنش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_vafamanesh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 218, name: 'وفامنش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_takavaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 219, name: 'تکاوران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_takavaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 219, name: 'تکاوران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_motahari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 220, name: 'مطهری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_motahari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 220, name: 'مطهری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tirandaz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 221, name: 'تیرانداز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tirandaz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 221, name: 'تیرانداز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jadetalu' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 222, name: 'جاده تلو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jadetalu' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 222, name: 'جاده تلو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jadedamavand'
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 223, name: 'جاده دماوند'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jadedamavand' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 223, name: 'جاده دماوند'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahraksharifi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 224, name: 'شهرک شریفی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahraksharifi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 224, name: 'شهرک شریفی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_araghi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 687, name: 'عراقی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_araghi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 687, name: 'عراقی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrak_omid' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 695, name: 'شهرک امید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrak_omid' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        nieghbourhood:[{id: 695, name: 'شهرک امید'}]}}/>}/>
                <Route path='/Listings/buy_zone_four' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                       }}/>}/>
                <Route path='/Listings/rent_zone_four' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 4', id: 4},
                                                        }}/>}/>
                <Route path='/Listings/buy_tehran_shahran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 225, name: 'شهران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 225, name: 'شهران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahreziba' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 226, name: 'شهرزیبا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahreziba' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 226, name: 'شهرزیبا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_andishe' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 227, name: 'اندیشه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_andishe' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 227, name: 'اندیشه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baharan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 228, name: 'بهاران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_baharan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 228, name: 'بهاران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 229, name: 'کن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 229, name: 'کن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_almahdi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 230, name: 'المهدی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_almahdi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 230, name: 'المهدی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baghefaiz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 231, name: 'باغ فیض'}]}}/>}/>
                <Route path='/Listings/rent_tehran_baghefaiz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 231, name: 'باغ فیض'}]}}/>}/>
                <Route path='/Listings/buy_tehran_punakjonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 232, name: 'پونک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_punakjonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 232, name: 'پونک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hesarak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 233, name: 'حصارک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hesarak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 233, name: 'حصارک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakenaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 234, name: 'شهرک نفت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakenaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 234, name: 'شهرک نفت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuohsar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 235, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuohsar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 235, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_moradabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 236, name: 'مرادآباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_moradabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 236, name: 'مرادآباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sazmanebarname' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 237, name: 'سازمان برنامه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sazmanebarname' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 237, name: 'سازمان برنامه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_eram' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 238, name: 'ارم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_eram' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 238, name: 'ارم'}]}}/>}/>                               
                <Route path='/Listings/buy_tehran_parvaz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 239, name: 'پرواز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_parvaz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 239, name: 'پرواز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sazmaneab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 240, name: 'سازمان آب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sazmaneab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 240, name: 'سازمان آب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abazar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 241, name: 'اباذر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abazar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 241, name: 'اباذر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ferdos' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 242, name: 'فردوس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ferdos' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 242, name: 'فردوس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mehranj' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 243, name: 'مهران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mehranj' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 243, name: 'مهران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ekbatan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 244, name: 'اکباتان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ekbatan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 244, name: 'اکباتان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bimeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 245, name: 'بیمه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bimeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 245, name: 'بیمه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_apadana' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 246, name: 'آپادانا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_apadana' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 246, name: 'آپادانا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ayatkashani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 247, name: 'آیت الله کاشانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ayatkashani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 247, name: 'آیت الله کاشانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_esfahaniashraf' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 248, name: 'اشرفی اصفهانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_esfahaniashraf' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 248, name: 'اشرفی اصفهانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_iranzamin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 249, name: 'ایران زمین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_iranzamin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 249, name: 'ایران زمین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bulvartaavon' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 250, name: 'بلوار تعاون'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bulvartaavon' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 250, name: 'بلوار تعاون'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bulvarferdos' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 251, name: 'بلوار فردوس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bulvarferdos' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 251, name: 'بلوار فردوس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bolivar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 252, name: 'بولیوار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bolivar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 252, name: 'بولیوار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_payambar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 253, name: 'پیامبر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_payambar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 253, name: 'پیامبر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_janatabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 254, name: 'جنت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_janatabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 254, name: 'جنت آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_satari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 255, name: 'ستاری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_satari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 255, name: 'ستاری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sardarjangal' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 256, name: 'سردار جنگل'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sardarjangal' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 256, name: 'سردار جنگل'}]}}/>}/>
                <Route path='/Listings/buy_tehran_solaghan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 257, name: 'سولقان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_solaghan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 257, name: 'سولقان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeekbatan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 259, name: 'شهرک اکباتان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeekbatan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 259, name: 'شهرک اکباتان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidmehdibakeri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 260, name: 'شهید مهدی باکری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidmehdibakeri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 260, name: 'شهید مهدی باکری'}]}}/>}/>                
                <Route path='/Listings/buy_tehran_dehkadeolampik' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 261, name: 'دهکده المپیک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dehkadeolampik' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 261, name: 'دهکده المپیک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bulvareayvank' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 262, name: 'بلوار ایوانک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bulvareayvank' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 262, name: 'بلوار ایوانک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abshar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 263, name: 'آبشار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abshar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 263, name: 'آبشار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shoara' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 264, name: 'شعرا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shoara' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 264, name: 'شعرا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakevalfajr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 265, name: 'شهرک والفجر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakevalfajr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 265, name: 'شهرک والفجر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sharbiyani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 266, name: 'شربیانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sharbiyani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 266, name: 'شربیانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nahid' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 267, name: 'ناهید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nahid' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 267, name: 'ناهید'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nirugah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 268, name: 'نیروگاه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nirugah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 268, name: 'نیروگاه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abshenasan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 269, name: 'آبشناسان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abshenasan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 269, name: 'آبشناسان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kankuee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 270, name: 'کنکوی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kankuee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 270, name: 'کنکوی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_earmkuee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 271, name: 'ارمکوی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_earmkuee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        nieghbourhood:[{id: 271, name: 'ارمکوی'}]}}/>}/>
                <Route path='/Listings/buy_zone_five' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                        }}/>}/>
                <Route path='/Listings/rent_zone_five' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 5', id: 5},
                                                       }}/>}/>
                <Route path='/Listings/buy_tehran_azar16' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 273, name: '16 آذر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azar16' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 273, name: '16 آذر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_argantin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 274, name: 'آرژانتین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_argantin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 274, name: 'آرژانتین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_asadabadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 275, name: 'اسدآبادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_asadabadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 275, name: 'اسدآبادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_esfandiyar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 276, name: 'اسفندیار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_esfandiyar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 276, name: 'اسفندیار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_eskandari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 277, name: 'اسکندری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_eskandari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 277, name: 'اسکندری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amirabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 278, name: 'امیر آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_amirabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 278, name: 'امیر آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_iranshahr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 279, name: 'ایرانشهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_iranshahr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 279, name: 'ایرانشهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bulvarafrigha' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 280, name: 'بلوار آفریقا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bulvarafrigha' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 280, name: 'بلوار آفریقا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bulvarkeshavarz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 281, name: 'بلوار کشاورز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bulvarkeshavarz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 281, name: 'بلوار کشاورز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_behjatabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 282, name: 'بهجت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_behjatabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 282, name: 'بهجت آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_beheshti' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 283, name: 'بهشتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_beheshti' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 283, name: 'بهشتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_parklaleh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 284, name: 'پارک لاله'}]}}/>}/>
                <Route path='/Listings/rent_tehran_parklaleh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 284, name: 'پارک لاله'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehranvilac' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 285, name: 'تهران ویلا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehranvilac' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 285, name: 'تهران ویلا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tavanir' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 286, name: 'توانیر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tavanir' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 286, name: 'توانیر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jamalzadeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 287, name: 'جمال زاده'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jamalzadeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 287, name: 'جمال زاده'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jomhuri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 288, name: 'جمهوری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jomhuri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 288, name: 'جمهوری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_janat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 289, name: 'جنت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_janat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 289, name: 'جنت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jahad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 290, name: 'جهاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jahad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 290, name: 'جهاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hafez' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 291, name: 'حافظ'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hafez' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 291, name: 'حافظ'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hejab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 292, name: 'حجاب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hejab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 292, name: 'حجاب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_roftegar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 293, name: 'رفتگر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_roftegar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 293, name: 'رفتگر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zagros' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 294, name: 'زاگرس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zagros' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 294, name: 'زاگرس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zartosht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 295, name: 'زرتشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zartosht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 295, name: 'زرتشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 296, name: 'ساعی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 296, name: 'ساعی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sanaee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 297, name: 'سنایی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sanaee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 297, name: 'سنایی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sindokht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 298, name: 'سیندخت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sindokht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 298, name: 'سیندخت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shaikhbahaeejonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 299, name: 'شیخ بهایی جنوبی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shaikhbahaeejonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 299, name: 'شیخ بهایی جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shirazjonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 300, name: 'شیراز جنوبی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shirazjonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 300, name: 'شیراز جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taleghani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 301, name: 'طالقانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taleghani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 301, name: 'طالقانی'}]}}/>}/>               
                <Route path='/Listings/buy_tehran_fatemi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 303, name: 'فاطمی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fatemi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 303, name: 'فاطمی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_felestin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 304, name: 'فلسطین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_felestin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 304, name: 'فلسطین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghaemmagham' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 305, name: 'قائم مقام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghaemmagham' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 305, name: 'قائم مقام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_gezalghalae' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 306, name: 'قزل قلعه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_gezalghalae' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 306, name: 'قزل قلعه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_karegarshomali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 307, name: 'کارگر شمالی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_karegarshomali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 307, name: 'کارگر شمالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kordestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 308, name: 'کردستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kordestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 308, name: 'کردستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_karimkhanezand' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 309, name: 'کریم خان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_karimkhanezand' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 309, name: 'کریم خان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_keshavarzgharbi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 310, name: 'کشاورز غربی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_keshavarzgharbi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 310, name: 'کشاورز غربی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_gandi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 311, name: 'گاندی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_gandi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 311, name: 'گاندی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golha' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 312, name: 'گلها'}]}}/>}/>
                <Route path='/Listings/rent_tehran_golha' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 312, name: 'گلها'}]}}/>}/>
                <Route path='/Listings/buy_tehran_larestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 313, name: 'لارستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_larestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 313, name: 'لارستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_maidanvaliasr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 315, name: 'میدان ولیعصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_maidanvaliasr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 315, name: 'میدان ولیعصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nejatolahi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 316, name: 'نجات اللهی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nejatolahi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 316, name: 'نجات اللهی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nasrat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 317, name: 'نصرت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nasrat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 317, name: 'نصرت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_vozara' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 318, name: 'وزراء'}]}}/>}/>
                <Route path='/Listings/rent_tehran_vozara' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 318, name: 'وزراء'}]}}/>}/>
                <Route path='/Listings/buy_tehran_yusefabab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 319, name: 'یوسف آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_yusefabab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        nieghbourhood:[{id: 319, name: 'یوسف آباد'}]}}/>}/>
                <Route path='/Listings/buy_zone_six' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        }}/>}/>
                <Route path='/Listings/rent_zone_six' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 6', id: 6},
                                                        }}/>}/>
                <Route path='/Listings/buy_tehran_ejaredar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 320, name: 'اجاره دار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ejaredar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 320, name: 'اجاره دار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aramanehalef' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 321, name: 'ارامنه الف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aramanehalef' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 321, name: 'ارامنه الف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aramanehbe' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 322, name: 'ارامنه ب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aramanehbe' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 322, name: 'ارامنه ب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emamhosain' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 323, name: 'امام حسین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamhosain' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 323, name: 'امام حسین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amjadiyehkhaghani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 324, name: 'امجدیه خاقانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_amjadiyehkhaghani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 324, name: 'امجدیه خاقانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_andisheh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 325, name: 'اندیشه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_andisheh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 325, name: 'اندیشه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_enghelab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 326, name: 'انقلاب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_enghelab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 326, name: 'انقلاب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_apadanahaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 327, name: 'آپادانا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_apadanahaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 327, name: 'آپادانا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baghesaba' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 328, name: 'باغ صبا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_baghesaba' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 328, name: 'باغ صبا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bakhtazad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 329, name: 'بخت آزاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bakhtazad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 329, name: 'بخت آزاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baharhaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 330, name: 'بهار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_baharhaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 330, name: 'بهار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_heshmatiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 331, name: 'حشمتیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_heshmatiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 331, name: 'حشمتیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hoghughee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 332, name: 'حقوقی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hoghughee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 332, name: 'حقوقی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khajenasir' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 333, name: 'خواجه نصیر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khajenasir' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 333, name: 'خواجه نصیر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khajenezam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 334, name: 'خواجه نظام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khajenezam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 334, name: 'خواجه نظام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dabestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 335, name: 'دبستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dabestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 335, name: 'دبستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dehghan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 336, name: 'دهقان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dehghan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 336, name: 'دهقان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sabalan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 337, name: 'سبلان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sabalan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 337, name: 'سبلان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sarbaz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 338, name: 'سرباز'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sarbaz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 338, name: 'سرباز'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sohrovardi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 339, name: 'سهروردی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sohrovardi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 339, name: 'سهروردی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shareghalef' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 340, name: 'شارق الف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shareghalef' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 340, name: 'شارق الف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shareghbe' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 341, name: 'شارق ب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shareghbe' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 341, name: 'شارق ب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shariatihaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 342, name: 'شریعتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shariatihaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 342, name: 'شریعتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidmadani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 343, name: 'شهید مدنی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidmadani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 343, name: 'شهید مدنی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidghandi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 344, name: 'شهید قندی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidghandi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 344, name: 'شهید قندی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shakhesafi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 345, name: 'شیخ صفی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shakhesafi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 345, name: 'شیخ صفی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abasabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 346, name: 'عباس آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abasabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 346, name: 'عباس آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghasr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 347, name: 'قصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghasr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 347, name: 'قصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kaj' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 348, name: 'کاج'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kaj' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 348, name: 'کاج'}]}}/>}/>
                <Route path='/Listings/buy_tehran_karimkhanhaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 349, name: 'کریمخان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_karimkhanhaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 349, name: 'کریمخان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_gorgan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 350, name: 'گرگان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_gorgan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 350, name: 'گرگان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_madanihaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 351, name: 'مدنی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_madanihaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 351, name: 'مدنی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_marvdasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 352, name: 'مرودشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_marvdasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 352, name: 'مرودشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_motahari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 353, name: 'مطهری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_motahari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 353, name: 'مطهری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mofatehjonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 354, name: 'مفتح جنوبی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mofatehjonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 354, name: 'مفتح جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nezamabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 355, name: 'نظام آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nezamabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 355, name: 'نظام آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nilufar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 356, name: 'نیلوفر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nilufar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 356, name: 'نیلوفر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_haftetir' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 357, name: 'هفت تیر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_haftetir' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 357, name: 'هفت تیر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_beheshtise' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 689, name: 'بهشتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_beheshtise' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 689, name: 'بهشتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saiyedkhandan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 690, name: 'سید خندان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saiyedkhandan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        nieghbourhood:[{id: 690, name: 'سید خندان'}]}}/>}/>
                <Route path='/Listings/buy_zone_seven' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        }}/>}/>
                <Route path='/Listings/rent_zone_seven' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 7', id: 7},
                                                        }}/>}/>
                <Route path='/Listings/buy_tehran_tehranpars' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 358, name: 'تهرانپارس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehranpars' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 358, name: 'تهرانپارس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dardasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 359, name: 'دردشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dardasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 359, name: 'دردشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_madaen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 360, name: 'مدائن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_madaen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 360, name: 'مدائن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hafthoaz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 361, name: 'هفت حوض'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hafthoaz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 361, name: 'هفت حوض'}]}}/>}/>
                <Route path='/Listings/buy_tehran_fadak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 362, name: 'فدک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fadak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 362, name: 'فدک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zarkash' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 363, name: 'زرکش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zarkash' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 363, name: 'زرکش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_narmakhasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 364, name: 'نارمک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_narmakhasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 364, name: 'نارمک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_majidiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 365, name: 'مجیدیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_majidiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 365, name: 'مجیدیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kerman' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 366, name: 'کرمان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kerman' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 366, name: 'کرمان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_lashgarsharghi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 367, name: 'لشگر شرقی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_lashgarsharghi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 367, name: 'لشگر شرقی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_lashgargharbi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 368, name: 'لشگر غربی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_lashgargharbi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 368, name: 'لشگر غربی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_vahidiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 369, name: 'وحیدیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_vahidiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 369, name: 'وحیدیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jashnvareh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 370, name: 'جشنواره'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jashnvareh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 370, name: 'جشنواره'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taslihat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 371, name: 'تسلیحات'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taslihat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 371, name: 'تسلیحات'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sabalane' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 372, name: 'سبلان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sabalane' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 372, name: 'سبلان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_madanihasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 373, name: 'مدنی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_madanihasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 373, name: 'مدنی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bakhtazad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 374, name: 'بخت آزاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bakhtazad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        nieghbourhood:[{id: 374, name: 'بخت آزاد'}]}}/>}/>
                <Route path='/Listings/buy_zone_eight' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        }}/>}/>
                <Route path='/Listings/rent_zone_eight' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 8', id: 8},
                                                        }}/>}/>
                <Route path='/Listings/buy_tehran_ostadmoeen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 375, name: 'استاد معین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ostadmoeen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 375, name: 'استاد معین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_akbari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 376, name: 'اکبری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_akbari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 376, name: 'اکبری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emamzadeabdolah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 377, name: 'امامزاده عبداله'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamzadeabdolah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 377, name: 'امامزاده عبداله'}]}}/>}/>
                <Route path='/Listings/buy_tehran_behbudi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 378, name: 'بهبودی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_behbudi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 378, name: 'بهبودی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taimurin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 379, name: 'تیموری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taimurin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 379, name: 'تیموری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_drhushyar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 380, name: 'دکتر هوشیار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_drhushyar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 380, name: 'دکتر هوشیار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zarand' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 381, name: 'زرند'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zarand' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 381, name: 'زرند'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sarasyab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 382, name: 'سرآسیاب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sarasyab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 382, name: 'سرآسیاب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_simetrij' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 383, name: 'سی متری جی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_simetrij' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 383, name: 'سی متری جی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shamshiri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 384, name: 'شمشیری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shamshiri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 384, name: 'شمشیری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeferdos' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 385, name: 'شهرک فردوس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeferdos' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 385, name: 'شهرک فردوس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahiddastghaib' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 386, name: 'شهید دستغیب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahiddastghaib' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 386, name: 'شهید دستغیب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_salehi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 387, name: 'صالحی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_salehi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 387, name: 'صالحی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sanati' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 388, name: 'صنعتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sanati' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 388, name: 'صنعتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_toos' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 389, name: 'طوس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_toos' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 389, name: 'طوس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_fath' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 390, name: 'فتح'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fath' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 390, name: 'فتح'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghazvin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 391, name: 'قزوین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghazvin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 391, name: 'قزوین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mehrabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 392, name: 'مهرآباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mehrabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        nieghbourhood:[{id: 392, name: 'مهرآباد'}]}}/>}/>
                <Route path='/Listings/buy_zone_ten' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                        }}/>}/>
                <Route path='/Listings/rent_zone_ten' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 9', id: 9},
                                                       }}/>}/>
                <Route path='/Listings/buy_tehran_beryanak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 395, name: 'بریانک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_beryanak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 395, name: 'بریانک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_solaimani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 396, name: 'سلیمانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_solaimani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 396, name: 'سلیمانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taimurite' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 397, name: 'تیموری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taimurite' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 397, name: 'تیموری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shabirij' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 398, name: 'شبیری جی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shabirij' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 398, name: 'شبیری جی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_salsabil' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 399, name: 'سلسبیل'}]}}/>}/>
                <Route path='/Listings/rent_tehran_salsabil' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 399, name: 'سلسبیل'}]}}/>}/>
                <Route path='/Listings/buy_tehran_haftchenar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 400, name: 'هفت چنار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_haftchenar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 400, name: 'هفت چنار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zanjanjonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 401, name: 'زنجان جنوبی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zanjanjonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 401, name: 'زنجان جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hashemi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 402, name: 'هاشمی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hashemi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 402, name: 'هاشمی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_karun' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 403, name: 'کارون'}]}}/>}/>
                <Route path='/Listings/rent_tehran_karun' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 403, name: 'کارون'}]}}/>}/>
                <Route path='/Listings/buy_tehran_azarbayejan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 404, name: 'آذربایجان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azarbayejan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 404, name: 'آذربایجان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_azadite' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 405, name: 'آزادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azadite' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 405, name: 'آزادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emmamkhomaini' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 406, name: 'امام خمینی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emmamkhomaini' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 406, name: 'امام خمینی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jaihoon' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 407, name: 'جیحون'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jaihoon' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 407, name: 'جیحون'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hesamadin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 408, name: 'حسام الدین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hesamadin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 408, name: 'حسام الدین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khosh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 409, name: 'خوش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khosh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 409, name: 'خوش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dampezeshki' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 410, name: 'دامپزشکی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dampezeshki' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 410, name: 'دامپزشکی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rudaki' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 411, name: 'رودکی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_rudaki' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 411, name: 'رودکی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sina' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 412, name: 'سینا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sina' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 412, name: 'سینا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_malekeashtar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 413, name: 'مالک اشتر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_malekeashtar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 413, name: 'مالک اشتر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghasroldasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 414, name: 'قصرالدشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghasroldasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 414, name: 'قصرالدشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_komail' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 415, name: 'کمیل'}]}}/>}/>
                <Route path='/Listings/rent_tehran_komail' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 415, name: 'کمیل'}]}}/>}/>
                <Route path='/Listings/buy_tehran_navab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 416, name: 'نواب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_navab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 416, name: 'نواب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ayatsaeedi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 417, name: 'آیت الله سعیدی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ayatsaeedi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        },nieghbourhood:[{id: 417, name: 'آیت الله سعیدی'}]}}/>}/>
                <Route path='/Listings/buy_zone_ten' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_ten' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 10', id: 10
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_abusaeed' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 418, name: 'ابوسعید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abusaeed' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 418, name: 'ابوسعید'}]}}/>}/>
                <Route path='/Listings/buy_tehran_orumieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 419, name: 'ارومیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_orumieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 419, name: 'ارومیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_eskandaridah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 420, name: 'اسکندری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_eskandaridah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 420, name: 'اسکندری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amirbahador' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 421, name: 'امیربهادر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_amirbahador' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 421, name: 'امیربهادر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amiriye' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 422, name: 'امیریه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_amiriye' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 422, name: 'امیریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_anbarnaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 423, name: 'انبار نفت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_anbarnaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 423, name: 'انبار نفت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_enghelabdah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 424, name: 'انقلاب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_enghelabdah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 424, name: 'انقلاب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_agahi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 425, name: 'آگاهی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_agahi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 425, name: 'آگاهی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_pastor' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 426, name: 'پاستور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_pastor' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 426, name: 'پاستور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jamalzadeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 427, name: 'جمال زاده'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jamalzadeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 427, name: 'جمال زاده'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jomhuri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 428, name: 'جمهوری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jomhuri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 428, name: 'جمهوری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_javadiye' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 429, name: 'جوادیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_javadiye' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 429, name: 'جوادیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hor' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 430, name: 'حر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hor' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 430, name: 'حر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hasanabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 431, name: 'حسن آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hasanabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 431, name: 'حسن آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_heshmatoldoleh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 432, name: 'حشمت الدوله'}]}}/>}/>
                <Route path='/Listings/rent_tehran_heshmatoldoleh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 432, name: 'حشمت الدوله'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dokhaniyat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 433, name: 'دخانیات'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dokhaniyat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 433, name: 'دخانیات'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rahahan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 434, name: 'راه آهن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_rahahan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 434, name: 'راه آهن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shapur' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 435, name: 'شاپور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shapur' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 435, name: 'شاپور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shush' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 436, name: 'شوش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shush' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 436, name: 'شوش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shaikhhadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 437, name: 'شیخ هادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shaikhhadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 437, name: 'شیخ هادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abasi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 438, name: 'عباسی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abasi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 438, name: 'عباسی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_fruzesh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 439, name: 'فروزش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fruzesh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 439, name: 'فروزش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_farhang' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 440, name: 'فرهنگ'}]}}/>}/>
                <Route path='/Listings/rent_tehran_farhang' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 440, name: 'فرهنگ'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghalamestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 441, name: 'قلمستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghalamestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 441, name: 'قلمستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_karegarjonubi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 442, name: 'کارگر جنوبی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_karegarjonubi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 442, name: 'کارگر جنوبی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kashan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 443, name: 'کاشان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kashan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 443, name: 'کاشان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_gomrok' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 444, name: 'گمرک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_gomrok' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 444, name: 'گمرک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_makhsus' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 445, name: 'مخصوص'}]}}/>}/>
                <Route path='/Listings/rent_tehran_makhsus' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 445, name: 'مخصوص'}]}}/>}/>
                <Route path='/Listings/buy_tehran_moniriyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 446, name: 'منیریه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_moniriyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 446, name: 'منیریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_maidaneghazvin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 447, name: 'میدان قزوین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_maidaneghazvin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 447, name: 'میدان قزوین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_vahdateslami' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 448, name: 'وحدت اسلامی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_vahdateslami' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 448, name: 'وحدت اسلامی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_valiasrdah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 449, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valiasrdah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 449, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_helaleahmar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 450, name: 'هلال احمر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valiasrdah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        },nieghbourhood:[{id: 449, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/buy_zone_eleven' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_eleven' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 11', id: 11
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_emamzadeyahya' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 451, name: 'امامزاده یحیی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamzadeyahya' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 451, name: 'امامزاده یحیی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_amirbahadorte' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 452, name: 'امیر بهادر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_amirbahadorte' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 452, name: 'امیر بهادر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aminhuzoor' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 453, name: 'امین حضور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aminhuzoor' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 453, name: 'امین حضور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_iran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 454, name: 'ایران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_iran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 454, name: 'ایران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_absharte' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 455, name: 'آبشار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_absharte' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 455, name: 'آبشار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bazar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 456, name: 'بازار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bazar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 456, name: 'بازار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baharestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 457, name: 'بهارستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_baharestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 457, name: 'بهارستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_pamenar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 458, name: 'پامنار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_pamenar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 458, name: 'پامنار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_panzdahekhordad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 459, name: 'پانزده خرداد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_panzdahekhordad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 459, name: 'پانزده خرداد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_picheshemiran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 460, name: 'پیچ شمیران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_picheshemiran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 460, name: 'پیچ شمیران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_takhti' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 461, name: 'تختی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_takhti' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 461, name: 'تختی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tupkhane' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 462, name: 'توپخانه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tupkhane' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 462, name: 'توپخانه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hafez' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 463, name: 'حافظ'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hafez' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 463, name: 'حافظ'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khorasan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 464, name: 'خراسان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khorasan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 464, name: 'خراسان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ray' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 466, name: 'ری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ray' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 466, name: 'ری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 467, name: 'سعدی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 467, name: 'سعدی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_solaimaniye' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 468, name: 'سلیمانیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_solaimaniye' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 468, name: 'سلیمانیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sanglach' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 469, name: 'سنگلج'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sanglach' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 469, name: 'سنگلج'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahpur' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 470, name: 'شاپور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahpur' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 470, name: 'شاپور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shokufe' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 471, name: 'شکوفه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shokufe' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 471, name: 'شکوفه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_safialishah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 472, name: 'صفی علیشاه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_safialishah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 472, name: 'صفی علیشاه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_eshratabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 473, name: 'عشرت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_eshratabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 473, name: 'عشرت آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ferdosi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 474, name: 'فردوسی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ferdosi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 474, name: 'فردوسی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghiyam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 475, name: 'قیام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghiyam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 475, name: 'قیام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kosar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 476, name: 'کوثر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kosar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 476, name: 'کوثر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_lalezarno' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 477, name: 'لاله زار نو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_lalezarno' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 477, name: 'لاله زار نو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mohamadieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 478, name: 'محمدیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mohamadieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 478, name: 'محمدیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_madanidvaz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 479, name: 'مدنی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_madanidvaz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 479, name: 'مدنی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_molavi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 480, name: 'مولوی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_molavi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 480, name: 'مولوی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_maidaneghiyam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 481, name: 'میدان قیام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_maidaneghiyam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 481, name: 'میدان قیام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_harandi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 482, name: 'هرندی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_harandi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 482, name: 'هرندی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hefdaheshahrivarte' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 483, name: 'هفده شهریور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hefdaheshahrivarte' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        },nieghbourhood:[{id: 483, name: 'هفده شهریور'}]}}/>}/>
                <Route path='/Listings/buy_zone_twelve' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_twelve' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 12', id: 12
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_eshraghi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 484, name: 'اشراقی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_eshraghi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 484, name: 'اشراقی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emamat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 485, name: 'امامت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 485, name: 'امامت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ashtiyani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 486, name: 'آشتیانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ashtiyani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 486, name: 'آشتیانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_piruzi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 487, name: 'پیروزی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_piruzi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 487, name: 'پیروزی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehranno' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 488, name: 'تهران نو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehranno' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 488, name: 'تهران نو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hafeziyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 489, name: 'حافظیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hafeziyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 489, name: 'حافظیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_damavand' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 490, name: 'دماوند'}]}}/>}/>
                <Route path='/Listings/rent_tehran_damavand' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 490, name: 'دماوند'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dehghan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 491, name: 'دهقان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dehghan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 491, name: 'دهقان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zahedgilani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 492, name: 'زاهد گیلانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zahedgilani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 492, name: 'زاهد گیلانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zainabiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 493, name: 'زینبیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zainabiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 493, name: 'زینبیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sorkhehesar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 494, name: 'سرخه حصار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sorkhehesar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 494, name: 'سرخه حصار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shora' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 495, name: 'شورا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shora' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 495, name: 'شورا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidasadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 496, name: 'شهید اسدی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidasadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 496, name: 'شهید اسدی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_safa' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 497, name: 'صفا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_safa' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 497, name: 'صفا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_niruhavaee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 498, name: 'نیروی هوایی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_niruhavaee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        },nieghbourhood:[{id: 498, name: 'نیروی هوایی'}]}}/>}/>
                <Route path='/Listings/buy_zone_thirteen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_thirteen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 13', id: 13
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_abuzar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 499, name: 'ابوذر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abuzar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 499, name: 'ابوذر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_otubanmahalati' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 500, name: 'اتوبان محلاتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_otubanmahalati' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 500, name: 'اتوبان محلاتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_afrasyabishomali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 501, name: 'افراسیابی شمالی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_afrasyabishomali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 501, name: 'افراسیابی شمالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ahang' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 502, name: 'آهنگ'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ahang' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 502, name: 'آهنگ'}]}}/>}/>
                <Route path='/Listings/buy_tehran_agangaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 503, name: 'آهنگران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_agangaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 503, name: 'آهنگران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_brujerdi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 504, name: 'بروجردی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_brujerdi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 504, name: 'بروجردی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_pasdaranegomnam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 505, name: 'پاسدارگمنام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_pasdaranegomnam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 505, name: 'پاسدارگمنام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_parastar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 506, name: 'پرستار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_parastar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 506, name: 'پرستار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taksirani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 507, name: 'تاکسیرانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taksirani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 507, name: 'تاکسیرانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jaberi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 508, name: 'جابری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jaberi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 508, name: 'جابری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_chaharsaddastgah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 509, name: 'چهارصد دستگاه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_chaharsaddastgah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 509, name: 'چهارصد دستگاه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khavaran' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 510, name: 'خاوران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khavaran' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 510, name: 'خاوران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dejgam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 511, name: 'دژکام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dejgam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 511, name: 'دژکام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dolab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 512, name: 'دولاب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dolab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 512, name: 'دولاب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sarasyab' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 513, name: 'سر آسیاب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sarasyab' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 513, name: 'سر آسیاب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sizdaheaban' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 514, name: 'سیزده آبان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sizdaheaban' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 514, name: 'سیزده آبان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 515, name: 'شاهین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 515, name: 'شاهین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shokufeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 516, name: 'شکوفه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shokufeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 516, name: 'شکوفه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shakib' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 517, name: 'شکیب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shakib' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 517, name: 'شکیب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrabi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 518, name: 'شهرابی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrabi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 518, name: 'شهرابی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidmahalati' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 519, name: 'شهید محلاتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidmahalati' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 519, name: 'شهید محلاتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saddastgah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 520, name: 'صد دستگاه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saddastgah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 520, name: 'صد دستگاه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_farzaneh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 521, name: 'فرزانه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_farzaneh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 521, name: 'فرزانه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_falah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 522, name: 'فلاح'}]}}/>}/>
                <Route path='/Listings/rent_tehran_falah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 522, name: 'فلاح'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghasrefiruz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 523, name: 'قصر فیروزه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghasrefiruz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 523, name: 'قصر فیروزه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mina' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 524, name: 'مینا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mina' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 524, name: 'مینا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nabard' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 525, name: 'نبرد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nabard' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 525, name: 'نبرد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nabiakram' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 526, name: 'نبی اکرم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nabiakram' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 526, name: 'نبی اکرم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_niknam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 527, name: 'نیکنام'}]}}/>}/>
                <Route path='/Listings/rent_tehran_niknam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 527, name: 'نیکنام'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hefdaheshahrivarfo' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 528, name: 'هفده شهریور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hefdaheshahrivarfo' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 528, name: 'هفده شهریور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aref' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 688, name: 'عارف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aref' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 688, name: 'عارف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shiva' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 693, name: 'شیوا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shiva' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 693, name: 'شیوا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_meghdad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 694, name: 'مقداد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_meghdad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        },nieghbourhood:[{id: 694, name: 'مقداد'}]}}/>}/>
                <Route path='/Listings/buy_zone_fourteen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_fourteen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 14', id: 14
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_abuzarfi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 529, name: 'ابوذر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abuzarfi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 529, name: 'ابوذر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_atabak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 530, name: 'اتابک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_atabak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 530, name: 'اتابک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_otubanbesat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 531, name: 'اتوبان بعثت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_otubanbesat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 531, name: 'اتوبان بعثت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_afsariye' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 532, name: 'افسریه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_afsariye' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 532, name: 'افسریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ahangfi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 533, name: 'آهنگ'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ahangfi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 533, name: 'آهنگ'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bisim' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 534, name: 'بی سیم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bisim' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 534, name: 'بی سیم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khavaranfi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 535, name: 'خاوران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khavaranfi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 535, name: 'خاوران'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khavarshahr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 536, name: 'خاورشهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khavarshahr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 536, name: 'خاورشهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dolatabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 537, name: 'دولت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dolatabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 537, name: 'دولت آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_razavieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 538, name: 'رضویه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_razavieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 538, name: 'رضویه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shushfi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 539, name: 'شوش'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shushfi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 539, name: 'شوش'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeamirmomenin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 540, name: 'شهرک امیرالمومنین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeamirmomenin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 540, name: 'شهرک امیرالمومنین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidbrujerdi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 541, name: 'شهید بروجردی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidbrujerdi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 541, name: 'شهید بروجردی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tayeb' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 542, name: 'طیب'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tayeb' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 542, name: 'طیب'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghasrefiruz' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 543, name: 'قصرفیروزه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghasrefiruz' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 543, name: 'قصرفیروزه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghiyamdasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 544, name: 'قیامدشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghiyamdasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 544, name: 'قیامدشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kianshahr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 545, name: 'کیانشهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kianshahr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 545, name: 'کیانشهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_masudieh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 546, name: 'مسعودیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_masudieh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 546, name: 'مسعودیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_moshiriyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 547, name: 'مشیریه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_moshiriyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 547, name: 'مشیریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_motahari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 548, name: 'مطهری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_motahari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 548, name: 'مطهری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mazaheri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 549, name: 'مظاهری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mazaheri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 549, name: 'مظاهری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_minaee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 550, name: 'مینابی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_minaee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 550, name: 'مینابی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_valfajr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 551, name: 'والفجر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valfajr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 551, name: 'والفجر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hashemabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 552, name: 'هاشم آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hashemabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        },nieghbourhood:[{id: 552, name: 'هاشم آباد'}]}}/>}/>
                <Route path='/Listings/buy_zone_fifteen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_fifteen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 15', id: 15
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_bagheazari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 553, name: 'باغ آذری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bagheazari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 553, name: 'باغ آذری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_takhtisi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 554, name: 'تختی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_takhtisi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 554, name: 'تختی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_javadiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 555, name: 'جوادیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_javadiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 555, name: 'جوادیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_charsaddastgah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 556, name: 'چهارصد دستگاه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_charsaddastgah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 556, name: 'چهارصد دستگاه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khazaneh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 557, name: 'خزانه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khazaneh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 557, name: 'خزانه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rahahansi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 558, name: 'راه آهن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_rahahansi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 558, name: 'راه آهن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rajaee' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 559, name: 'رجایی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_rajaee' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 559, name: 'رجایی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakebesat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 560, name: 'شهرک بعثت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakebesat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 560, name: 'شهرک بعثت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aliabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 561, name: 'علی آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aliabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 561, name: 'علی آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_naziabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 562, name: 'نازی آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_naziabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 562, name: 'نازی آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_helalahmar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 563, name: 'هلال احمر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_helalahmar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 563, name: 'هلال احمر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_yakhchiabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 564, name: 'یاخچی آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_yakhchiabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        },nieghbourhood:[{id: 564, name: 'یاخچی آباد'}]}}/>}/>
                <Route path='/Listings/buy_zone_sixteen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_sixteen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 16', id: 16
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_abuzarse' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 565, name: 'ابوذر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abuzarse' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 565, name: 'ابوذر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emamzadehasan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 566, name: 'امام زاده حسن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamzadehasan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 566, name: 'امام زاده حسن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ayatsaeedi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 567, name: 'آیت اله سعیدی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ayatsaeedi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 567, name: 'آیت اله سعیدی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_azarise' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 568, name: 'آذری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azarise' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 568, name: 'آذری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_baghekhazane' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 569, name: 'باغ خزانه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_baghekhazane' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 569, name: 'باغ خزانه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_beryanakse' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 570, name: 'بریانک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_beryanakse' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 570, name: 'بریانک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_blursazi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 571, name: 'بلورسازی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_blursazi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 571, name: 'بلورسازی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_jalili' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 572, name: 'جلیلی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_jalili' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 572, name: 'جلیلی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zarand' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 573, name: 'زرند'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zarand' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 573, name: 'زرند'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zamzam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 574, name: 'زمزم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zamzam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 574, name: 'زمزم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zahtabi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 575, name: 'زهتابی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zahtabi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 575, name: 'زهتابی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sajad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 576, name: 'سجاد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sajad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 576, name: 'سجاد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_safnard' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 577, name: 'صفنارد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_safnard' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 577, name: 'صفنارد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_falahse' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 578, name: 'فلاح'}]}}/>}/>
                <Route path='/Listings/rent_tehran_falahse' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 578, name: 'فلاح'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghazvinse' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 579, name: 'قزوین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghazvinse' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 579, name: 'قزوین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghalemorghi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 580, name: 'قلعه مرغی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghalemorghi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 580, name: 'قلعه مرغی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golchin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 581, name: 'گلچین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_golchin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 581, name: 'گلچین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_moghadam' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 582, name: 'مقدم'}]}}/>}/>
                <Route path='/Listings/rent_tehran_moghadam' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 582, name: 'مقدم'}]}}/>}/>
                <Route path='/Listings/buy_tehran_maydanebaharan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 583, name: 'میدان بهاران'}]}}/>}/>
                <Route path='/Listings/rent_tehran_maydanebaharan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        },nieghbourhood:[{id: 583, name: 'میدان بهاران'}]}}/>}/>
                <Route path='/Listings/buy_zone_seventeen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_seventeen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 17', id: 17
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_otubaneazadegan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 585, name: 'اتوبان آزادگان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_otubaneazadegan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 585, name: 'اتوبان آزادگان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_emamkhmaini' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 586, name: 'امام خمینی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_emamkhmaini' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 586, name: 'امام خمینی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_behdasht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 587, name: 'بهداشت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_behdasht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 587, name: 'بهداشت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tohid' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 588, name: 'توحید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tohid' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 588, name: 'توحید'}]}}/>}/>
                <Route path='/Listings/buy_tehran_toliddaru' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 589, name: 'تولیدارو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_toliddaru' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 589, name: 'تولیدارو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_khalijefars' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 590, name: 'خلیج فارس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khalijefars' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 590, name: 'خلیج فارس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_rajaeeei' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 591, name: 'رجائی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_rajaeeei' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 591, name: 'رجائی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_saeedabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 592, name: 'سعید آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_saeedabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 592, name: 'سعید آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shadabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 593, name: 'شاد آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shadabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 593, name: 'شاد آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shamsabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 594, name: 'شمس آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shamsabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 594, name: 'شمس آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahraksahebzaman' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 595, name: 'شهرک صاحب الزمان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahraksahebzaman' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 595, name: 'شهرک صاحب الزمان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeferdosei' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 596, name: 'شهرک فردوس'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeferdosei' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 596, name: 'شهرک فردوس'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakevaliasr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 597, name: 'شهرک ولیعصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakevaliasr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 597, name: 'شهرک ولیعصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakesadeghiye' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 599, name: 'شهرک صادقیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakesadeghiye' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 599, name: 'شهرک صادقیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_fathei' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 600, name: 'فتح'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fathei' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 600, name: 'فتح'}]}}/>}/>
                <Route path='/Listings/buy_tehran_valiasrei' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 602, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valiasrei' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 602, name: 'ولیعصر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hefdaheshahrivarei' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 603, name: 'هفده شهریور'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hefdaheshahrivarei' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 603, name: 'هفده شهریور'}]}}/>}/>
                <Route path='/Listings/buy_tehran_yaftabadei' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 604, name: 'یافت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_yaftabadei' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        },nieghbourhood:[{id: 604, name: 'یافت آباد'}]}}/>}/>
                <Route path='/Listings/buy_zone_eighteen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_eighteen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 18', id: 18
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_khaniabadno' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 605, name: 'خانی آباد نو'}]}}/>}/>
                <Route path='/Listings/rent_tehran_khaniabadno' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 605, name: 'خانی آباد نو'}]}}/>}/>
                <Route path='/Listings/buy_tehran_esfandiyari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 606, name: 'اسفندیاری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_esfandiyari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 606, name: 'اسفندیاری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bastan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 607, name: 'بستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bastan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 607, name: 'بستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bahmanyar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 608, name: 'بهمنیار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bahmanyar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 608, name: 'بهمنیار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shariatini' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 609, name: 'شریعتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shariatini' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 609, name: 'شریعتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shokufehni' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 610, name: 'شکوفه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shokufehni' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 610, name: 'شکوفه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nematabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 611, name: 'نعمت آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nematabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 611, name: 'نعمت آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dolatkhah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 612, name: 'دولت خواه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dolatkhah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 612, name: 'دولت خواه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_esmaeelabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 613, name: 'اسماعیل آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_esmaeelabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 613, name: 'اسماعیل آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidkazemi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 614, name: 'شهید کاظمی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidkazemi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 614, name: 'شهید کاظمی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_resalatni' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 615, name: 'رسالت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_resalatni' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 615, name: 'رسالت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abdolabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 616, name: 'عبدل آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abdolabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 616, name: 'عبدل آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_yakhchiabda' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 617, name: 'یاخچی آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_yakhchiabda' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 617, name: 'یاخچی آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakebesat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 618, name: 'شهرک بعثت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakebesat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        },nieghbourhood:[{id: 618, name: 'شهرک بعثت'}]}}/>}/>
                <Route path='/Listings/buy_zone_nineteen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_nineteen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 19', id: 19
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_ababansizdah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 619, name: '13 آبان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ababansizdah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 619, name: '13 آبان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_estakhrtw' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 620, name: 'استخر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_estakhrtw' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 620, name: 'استخر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aghdasiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 621, name: 'اقدسیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aghdasiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 621, name: 'اقدسیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_aminabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 622, name: 'امین آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_aminabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 622, name: 'امین آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bulvarshahrdari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 623, name: 'بلوار شهرداری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bulvarshahrdari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 623, name: 'بلوار شهرداری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_beheshtitw' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 624, name: 'بهشتی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_beheshtitw' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 624, name: 'بهشتی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_taghiabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 625, name: 'تقی آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_taghiabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 625, name: 'تقی آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_javanmard' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 626, name: 'جوانمرد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_javanmard' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 626, name: 'جوانمرد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_hamzeabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 627, name: 'حمزه آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_hamzeabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 627, name: 'حمزه آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dolatabadtw' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 628, name: 'دولت اباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dolatabadtw' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 628, name: 'دولت اباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_daylaman' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 629, name: 'دیلمان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_daylaman' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 629, name: 'دیلمان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sartakht' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 630, name: 'سرتخت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sartakht' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 630, name: 'سرتخت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahadat' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 631, name: 'شهادت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahadat' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 631, name: 'شهادت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrray' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 632, name: 'شهرری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrray' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 632, name: 'شهرری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_safaeeyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 633, name: 'صفائیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_safaeeyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 633, name: 'صفائیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zahirabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 634, name: 'ظهیر آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zahirabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 634, name: 'ظهیر آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abadsabadtw' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 635, name: 'عباس آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abadsabadtw' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 635, name: 'عباس آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_alaadin' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 636, name: 'علائین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_alaadin' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 636, name: 'علائین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_ghayuri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 637, name: 'غیوری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_ghayuri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 637, name: 'غیوری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_firuzabadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 638, name: 'فیروزآبادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_firuzabadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 638, name: 'فیروزآبادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kahrizak' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 639, name: 'کهریزک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kahrizak' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 639, name: 'کهریزک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_mansuriyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 640, name: 'منصوریه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_mansuriyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 640, name: 'منصوریه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_naezami' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 641, name: 'نظامی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_naezami' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 641, name: 'نظامی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_nafarabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 642, name: 'نفر آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_nafarabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 642, name: 'نفر آباد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_valiabad' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 643, name: 'ولی آباد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_valiabad' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        },nieghbourhood:[{id: 643, name: 'ولی آباد'}]}}/>}/>
                <Route path='/Listings/buy_zone_twenty' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_twenty' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 20', id: 20
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_otubantehrankaraj' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 644, name: 'اتوبان تهران کرج'}]}}/>}/>
                <Route path='/Listings/rent_tehran_otubantehrankaraj' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 644, name: 'اتوبان تهران کرج'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bashgahenaft' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 645, name: 'باشگاه نفت'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bashgahenaft' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 645, name: 'باشگاه نفت'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bozorgrahefath' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 646, name: 'بزرگراه فتح'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bozorgrahefath' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 646, name: 'بزرگراه فتح'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehransar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 647, name: 'تهرانسر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehransar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 647, name: 'تهرانسر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_tehransarshomali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 648, name: 'تهرانسر شمالی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_tehransarshomali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 648, name: 'تهرانسر شمالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_chitgar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 649, name: 'چیتگر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_chitgar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 649, name: 'چیتگر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakebistbahman' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 650, name: 'شهرک 22بهمن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakebistbahman' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 650, name: 'شهرک 22بهمن'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeesteghlal' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 651, name: 'شهرک استقلال'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeesteghlal' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 651, name: 'شهرک استقلال'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeazadi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 652, name: 'شهرک آزادی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeazadi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 652, name: 'شهرک آزادی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakedaneshgah' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 653, name: 'شهرک دانشگاه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakedaneshgah' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 653, name: 'شهرک دانشگاه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakedaneshgahshirif' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 654, name: 'شهرک دانشگاه شریف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakedaneshgahshirif' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 654, name: 'شهرک دانشگاه شریف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakedarya' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 655, name: 'شهرک دریا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakedarya' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 655, name: 'شهرک دریا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeshahrdari' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 656, name: 'شهرک شهرداری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeshahrdari' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 656, name: 'شهرک شهرداری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakeghazali' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 657, name: 'شهرک غزالی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakeghazali' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 657, name: 'شهرک غزالی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakefarhangiyan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 658, name: 'شهرک فرهنگیان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakefarhangiyan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 658, name: 'شهرک فرهنگیان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakekazemiyeh' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 659, name: 'شهرک کاظمیه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakekazemiyeh' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 659, name: 'شهرک کاظمیه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_fadakto' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 660, name: 'فدک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_fadakto' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 660, name: 'فدک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_verdavard' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 661, name: 'ورد آورد'}]}}/>}/>
                <Route path='/Listings/rent_tehran_verdavard' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 661, name: 'ورد آورد'}]}}/>}/>
                <Route path='/Listings/buy_tehran_vilashahr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 662, name: 'ویلا شهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_vilashahr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        },nieghbourhood:[{id: 662, name: 'ویلا شهر'}]}}/>}/>
                <Route path='/Listings/buy_zone_Twenty_one' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_Twenty_one' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 21', id: 21
                                                        }}}/>}/>
                <Route path='/Listings/buy_tehran_omid' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 663, name: 'امید'}]}}/>}/>
                <Route path='/Listings/rent_tehran_omid' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 663, name: 'امید'}]}}/>}/>
                <Route path='/Listings/buy_tehran_andishe' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 664, name: 'اندیشه'}]}}/>}/>
                <Route path='/Listings/rent_tehran_andishe' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 664, name: 'اندیشه'}]}}/>}/>
                <Route path='/Listings/buy_tehran_azadshahr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 665, name: 'آزاد شهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_azadshahr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 665, name: 'آزاد شهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_bozorgrahshahidkharazi' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 666, name: 'بزرگراه شهید خرازی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_bozorgrahshahidkharazi' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 666, name: 'بزرگراه شهید خرازی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_paykanshahr' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 667, name: 'پیکان شهر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_paykanshahr' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 667, name: 'پیکان شهر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_juzani' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 668, name: 'جوزانی'}]}}/>}/>
                <Route path='/Listings/rent_tehran_juzani' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 668, name: 'جوزانی'}]}}/>}/>
                <Route path='/Listings/buy_tehran_daryachechitgar' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 669, name: 'دریاچه چیتگر'}]}}/>}/>
                <Route path='/Listings/rent_tehran_daryachechitgar' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 669, name: 'دریاچه چیتگر'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dejban' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 670, name: 'دژبان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dejban' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 670, name: 'دژبان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_dehkadeolampik' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 671, name: 'دهکده المپیک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_dehkadeolampik' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 671, name: 'دهکده المپیک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zibadashtbala' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 672, name: 'زیبا دشت بالا'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zibadashtbala' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 672, name: 'زیبا دشت بالا'}]}}/>}/>
                <Route path='/Listings/buy_tehran_zibadashtpaeen' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 673, name: 'زیبا دشت پایین'}]}}/>}/>
                <Route path='/Listings/rent_tehran_zibadashtpaeen' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 673, name: 'زیبا دشت پایین'}]}}/>}/>
                <Route path='/Listings/buy_tehran_sharif' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 674, name: 'شریف'}]}}/>}/>
                <Route path='/Listings/rent_tehran_sharif' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 674, name: 'شریف'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakegolestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 675, name: 'شهرک گلستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakegolestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 675, name: 'شهرک گلستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahidbagheri' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 676, name: 'شهید باقری'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahidbagheri' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 676, name: 'شهید باقری'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuhsartoto' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 677, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuhsartoto' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 677, name: 'کوهسار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_kuhaktoto' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 678, name: 'کوهک'}]}}/>}/>
                <Route path='/Listings/rent_tehran_kuhaktoto' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 678, name: 'کوهک'}]}}/>}/>
                <Route path='/Listings/buy_tehran_golestan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 679, name: 'گلستان'}]}}/>}/>
                <Route path='/Listings/rent_tehran_golestan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 679, name: 'گلستان'}]}}/>}/>
                <Route path='/Listings/buy_tehran_maidanesahel' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 680, name: 'میدان ساحل'}]}}/>}/>
                <Route path='/Listings/rent_tehran_maidanesahel' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 680, name: 'میدان ساحل'}]}}/>}/>
                <Route path='/Listings/buy_tehran_abshartoto' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 691, name: 'آبشار'}]}}/>}/>
                <Route path='/Listings/rent_tehran_abshartoto' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 691, name: 'آبشار'}]}}/>}/>
                <Route path='/Listings/buy_tehran_shahrakerahahan' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 692, name: 'شهرک راه آهن'}]}}/>}/>
                <Route path='/Listings/rent_tehran_shahrakerahahan' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        },nieghbourhood:[{id: 692, name: 'شهرک راه آهن'}]}}/>}/>
                <Route path='/Listings/buy_zone_Twenty_two' 
                        element={<Listings option={{transaction:1,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        }}}/>}/>
                <Route path='/Listings/rent_zone_Twenty_two' 
                        element={<Listings option={{transaction:2,state:{"name":"تهران","id":1},city:{"name":"تهران","id":1},zone:{name: 'منطقه 22', id: 22
                                                        }}}/>}/>
        
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
