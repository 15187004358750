import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article22/01.jpg";
import photo02 from "../../images/mag/article22/02.jpg";
import photo03 from "../../images/mag/article22/03.jpg";
import photo04 from "../../images/mag/article22/04.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";



class Article22 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "هر نوع ساخت وسازی در ایران تنها در صورتی مجاز است که پروانه ساخت داشته باشد که صدور پروانه ساخت از سوی شهرداری محل اقامت می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "پروانه ساختمان چیست | مراحل صدور پروانه ملک | صفر تا صد دریافت مجوز ساخت خانه | معرفی مراحل دریافت جواز ساختمان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[21].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[21].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[21].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[21].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[21].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[21].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="همه چیز راجع به صدور پروانه ساختمان - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[21].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="h2" variant="h2">
                پروانه ساخت چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="پروانه ساخت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پروانه ساختمان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هر نوع ساخت و‌سازی در ایران تنها در صورتی مجاز است که پروانه ساخت داشته باشد.
                این مجوز از سوی شهرداری شهر محل اقامت صادر می‌شود که به آن پروانه ساخت می‌گویند. در اختیار داشتن این مجوز به معنای قانونی بودن عملیات
                اجرایی در حال انجام است. دریافت پروانه ساخت فرآیند سختی است که ما در این مقاله از وبسایت هومینجا به صفر تا صد دریافت آن خواهیم پرداخت.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مراحل صدور پروانه ساختمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نحوه صدور پروانه ساخت و ساز به شرط آن که مدارک شما کامل باشد و از فرآیند آن مطلع باشید، آنقدر‌ها هم مشکل نخواهد بود.
                برای دریافت پروانه ساخت شش مرحله وجود دارد که باید پشت سر گذاشته شود. در ادامه با توضیح مراحل درخواست پروانه ساختمان با ما همراه باشید.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="صدور پروانه ساختمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مراحل صدور پروانه ساختمان
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                گام نخست: تشکیل پرونده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اولین مرحله
                از دریافت پروانه ساختمان تشکیل پرونده است. برای تشکیل پرونده شخص مالک ساختمان و یا وکیل قانونی او باید اقدامات اولیه را انجام دهد. در این
                مرحله تمام مدارک مورد نیاز باید به یکی از دفاتر خدمات الکترونیک در نزدیکی محل پروژه تحویل شود. در دفتر خدماتی شما درخواست خود مبنی بر دریافت
                پروانه تخریب و نوسازی را ثبت می‌کنید و با ارائه مدارک آن برای درخواست بازدید از ملک نیز اقدام می‌کنید و همزمان درخواست آن را نیز ثبت می‌نمایید.
                <br />
                با ثبت این درخواست در تاریخی که مشخص شده و به اطلاع شما می‌رسد، یک
                مأمور از سوی شهرداری برای بازدید از ملک مذکور مراجعه کرده و اطلاعاتی مثل ابعاد و مساحت ملک، بنای موجود، عرض گذر و… را در پرونده شما ثبت می‌کند.
              </Typography>
              <Typography className="h3" variant="h3">
                گام دوم: آماده‌سازی دستور نقشه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در گام بعدی پس از بازدید و بررسی زمین توسط کارشناس شهرداری، دستور طرح
                نقشه از سوی شهرداری برای مالک ارسال می‌شود. در این دستور اطلاعاتی از جمله: اصلاحی شهرداری، تعداد طبقات مجاز برای ساخت و مساحت هر طبقه اعلام می‌شود.
                <br />
                باید اشاره کنیم که این اطلاعات بر اساس مستنداتی از جمله پهنه، مساحت، عرض گذر و… که قبلاً توسط کارشناس در پرونده ثبت شده است ارائه می‌شود.
              </Typography>
              <Typography className="h3" variant="h3">
                گام سوم: طراحی نقشه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مرحله که مهمترین و اصلی‌ترین بخش کار در فرآیند دریافت پروانه ساختمان است، نقشه اصلی ساختمان طراحی می‌شود. طراحی نقشه بر اساس دستور نقشه دریافتی
                از شهرداری انجام می‌گیرد. نقشه‌های طراحی شده با ضوابط و آیین‌نامه‌های شهرداری و آتش نشانی بررسی می‌شوند و طراحی بهینه روی آن‌ها انجام می‌گیرد.
                <br />
                نقشه‌ها از منظر مسائلی چون زیبایی، تأمین پارکینگ، هماهنگی و هارمونی بنا و… باید در بهترین حالت ممکن قرار داشته باشند.
                <br />
                با تکمیل طراحی نقشه‌ها باید مجدداً به
                دفاتر خدمات الکترونیک مراجعه کنید تا بررسی‌های لازم روی آن‌ها انجام شود. اگر نیاز به بازنگری یا اصلاح بود قبل از ثبت به اطلاع مالک یا وکیل قانونی او می رسد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/transferring-property" target="_blank">
                  بیشتر بخوانید : مراحل سند زنی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                گام چهارم: پرداخت عوارض
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از این که دستور نقشه و طراحی نقشه معماری را پشت سر گذاشتید، نوبت به پرداخت عوارض می‌رسد. میزان عوارض بر اساس
                فاکتور‌هایی چون تراکم موجود، تعداد طبقات، متراژ ساختمان و… محاسبه می‌شود. مالک موظف است که مبلغ تعیین شده را بدون کم و کاست به اداره درآمد مالیات پرداخت کند.
              </Typography>
              <Typography className="h3" variant="h3">
                گام پنجم: آماده‌سازی مدارک پیش‌نویس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از این که همه مراحل مربوط به پرداخت عوارض
                و دریافت تأییدیه پرداخت عوارض تخریب و نوسازی انجام شد، باید مدارک پیش‌نویس را تهیه کنید. برای تهیه این مدارک مالک ساختمان باید شخصاً به دفاتر
                خدمات مراجعه کرده و فرم درخواستی با عنوان تعیین ناظر را تکمیل کند. بعد از پایان این مرحله باید منتظر تماس و معرفی ناظر از سوی دفتر خدماتی بود.
                <br />
                بعد از تعیین ناظر
                قراردادی بین مالک و ناظر منعقد می‌شود. این قرارداد برای سهمیه یا برگ سبز و تعهد رعایت اصلاحی و تأییدیه ناظر نما، به دفتر خدماتی تحویل داده می‌شود.
              </Typography>
              <Typography className="h4" variant="h4">
                فهرست مدارک پیش‌نویس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مدارک پیش‌نویس در مرحله قبل راجع به آن صحبت شد. مدارکی که باید به دفاتر خدماتی ارائه شود تا ناظر معرفی گردد، عبارتند از:
              </Typography>
              <ul className="p">
                <li>برگه سبز برگ مهرشده ۴ رشته شامل ساز، معماری، برق و مکانیک</li>
                <li>برگ سبز (سهمیه) ناظر به همراه قرارداد مالک و ناظر</li>
                <li>برگه تعهد رعایت اصلاحیه و تأییدیه نما</li>
                <li>فایل‌های نقشه سازه، برق و مکانیک</li>
                <li>چک لیست‌های مبحث ۶۱۹ بارگذاری و زلزله</li>
                <li>برگه تعهد مشاوره ژئوتکنیک و تأییدیه خاک</li>
              </ul>
              <Typography className="h3" variant="h3">
                گام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از انجام همه مراحل قبلی کار به اتمام رسیده است.
                در این مرحله نوبت به چاپ پروانه ساخت می‌رسد. بعد از این که همه مدارک کنترل شد نوبت به دریافت تأییدیه‌های دیگری می‌رسد که فهرست آن به این شرح است:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>طرح تفصیلی بر و کف</li>
                <li>رئیس صدور پروانه</li>
                <li>معاون شهرسازی و معماری</li>
                <li>تأییدیه شهر</li>
              </ul>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با دریافت این چهار تأییدیه پروانه ساخت و ساز صادر و چاپ می‌شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مهم در مورد خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مدت زمان اعتبار پروانه ساخت و ساز
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="پروانه ساختمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اعتبار پروانه ساخت
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پروانه ساخت و ساز معمولاً تا ۲ سال بعد از تاریخ صدور از اعتبار برخوردار است. همچنین مدت زمان لازم رای ساخت تمام
                پروژه‌های ساختمانی بر اساس متراژ و تعداد طبقات آن‌ها اعلام می‌شود. در صورتی که مالک نتواند در مدت زمان تعیین شده پروژه ساخت و ساز را تکمیل کند در مرحله
                اول به او اخطار داده می‌شود. گام بعدی مشمول پرداخت عوارض تعویق خواهد شد. در حالت کلی یک بازه زمانی ۴ ساله برای ساخت و ساز یک ساختمان در نظر گرفته می‌شود.
                <br />
                همچنین شهرداری فرصت کافی برای تمدید پروانه ساخت برای هر ساختمان را در نظر می‌گیرد که بعد از پایان این مهلت
                امکان تمدید پروانه ساخت وجود نخواهد داشت. در صورتی که مدت زمان ساخت پروژه به پایان برسد و عملیات ساخت هنوز تمام نشده باشد مالک باید مجوز ساخت جدید
                دریافت کند. هچنین مالک ملزم به پرداخت ۲۰ درصد از محاسبات پروانه به نرخ روز خواهد بود. به این ترتیب پروانه قبلی برای یک دوره سه ساله تمدید خواهد شد.
              </Typography>
              <Typography className="h2" variant="h2">
                هزینه صدور پروانه ساخت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هزینه صدور پروانه ساخت و ساز در هر شهر و استان
                با توجه به مصوبات منطقه‌ای تعیین می‌شود. هزینه‌های دریافت پروانه ساخت و ساز توسط شهرداری محاسبه و به مالک اعلام می‌گردد. با این حال مبالغی که به سازمان
                نظام مهندسی و مهندسین طراح، ناظر و مجری پرداخت می‌گردد به صورت سالانه و توسط سازمان نظام مهندسی محاسبه و اعلام می‌شود که عموماً در تمام کشور یکسان است.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع‌بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دریافت پروانه ساخت و ساز برای هر نوع ساختمان‌سازی در سراسر
                کشور یک الزام است که نیازمند به طی شدن مراحل اداری دارد. در این مقاله از سامانه انلاین معاملات ملک هومینجا، به صفر تا صد دریافت آن پرداخته شده است.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/building-certificate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/building-certificate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/building-certificate" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article22;