import { Grid, Typography } from "@mui/material";
import React from "react";
import Avatar from '../images/avatar1.png';
import { convertToPersianNumber } from "./materilas/vmp";

class InfoUser extends React.Component{

  isMount = false;

  constructor(props){
      super(props);
      this.state={
        userPicture:null,
        user:JSON.parse(localStorage.getItem('infoUserHomeInja'))
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'اطلاعات کاربری سامانه درج آگهی املاک هومینجا'
    if (this.isMount){
      if (this.state.user !== null){
        this.setState({userPicture:this.state.user.picture_path})
      }
      else{
        this.setState({userPicture:null})
      }
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'0px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>اطلاعات کاربری</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',flexDirection:'column',marginTop:50,width:'100%'}}
              sx={{paddingLeft:{md:'130px',xs:'10px'},paddingRight:{md:'100px',xs:'5px'}}}
        >
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <Grid style={{borderStyle:'solid',borderRadius:'100%',borderColor:'#00C8E9',borderWidth:'10px',
                                display:'flex',alignItems:'center',justifyContent:'center'}}
                        sx={{width:{sm:'200px',xs:'120px'},height:{sm:'200px',xs:'120px'}}}
            >
              {
                this.state.userPicture !== null ?
                  <Typography 
                      style={{width:'100%',height:'100%',borderRadius:'100%',backgroundImage:`url(${this.state.userPicture})`,
                      backgroundRepeat:'no-repeat',backgroundSize:'cover'}}
                  ></Typography>
                :
                  <Typography component={'img'} src={Avatar} alt="avatar"
                      sx={{width:{sm:'130px',xs:'45px'},height:{sm:'130px',xs:'45px'}}}
                  ></Typography>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} 
              style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',flexWrap:'wrap',width:'100%'}}
              sx={{paddingRight:'5%',paddingLeft:'5%',marginTop:{sm:'80px',xs:'40px'}}}
        >
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}  
              sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'نام و نام خانوادگی: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{this.state.user.name !== null && this.state.user.lastname !== null ? this.state.user.name + ' ' + this.state.user.lastname : 
                this.state.user.name !== null ? this.state.user.name : this.state.user.lastname !== null ? this.state.user.lastname : 'تکمیل نشده' }</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'شماره موبایل: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{convertToPersianNumber(this.state.user.mobile)}</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'نام پدر: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{this.state.user.fathername !== null ? this.state.user.fathername : 'تکمیل نشده'}</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'نوع کاربری: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{this.state.user.type.description}</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'شماره ملی: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{convertToPersianNumber(this.state.user.meli_number !== null ? this.state.user.meli_number : 'تکمیل نشده')}</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'لینک اینستاگرام: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{this.state.user.instagram_link !== null ? this.state.user.instagram_link : 'تکمیل نشده'}</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'تاریخ تولد: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{convertToPersianNumber(this.state.user.date_of_birth !== null ? this.state.user.date_of_birth : 'تکمیل نشده')}</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                sx={{marginTop:{sm:'50px',xs:'20px'}}}
          >
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'right',width:'40%'}}
                  sx={{fontSize:{sm:'16px',xs:'14px'}}}
              >{'وضعیت: '}</Typography>
              <Typography style={{fontFamily:'shabnam',fontWeight:'bold',width:'60%'}}
                  sx={{fontSize:{sm:'20px',xs:'17px'},textAlign:{lg:'right',md:'center',sm:'right',xs:'left'}}}
              >{this.state.user.active ? 'فعال' : 'غیرفعال'}</Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  };
  
}

export default InfoUser;