import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article49/01.jpg";
import photo02 from "../../images/mag/article49/02.jpg";
import photo03 from "../../images/mag/article49/03.jpg";
import photo04 from "../../images/mag/article49/04.jpg";
import photo05 from "../../images/mag/article49/05.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article49 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "منطقه ۲۲ در شمال غربی تهران واقع است و غربی ترین و جدیدترین منطقه شهرداری تهران محسوب می شود. این منطقه به قطب گردشگری تبدیل شده است.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "منطقه ۲۲ شهرداری تهران | آشنایی با منطقه ۲۲ |  محله های منطقه ۲۲ | امکانات منطقه ۲۲ | دسترسی های منطقه ۲۲ | منطقه ۲۲ تهران کجا میشه |معرفی کامل منطقه ۲۲ شهر تهران | نقشه منطقه ۲۲ تهران | منطقه ۲۲ تهران شامل چه محله های است | شهرک های منطقه ۲۲ تهران "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[48].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[48].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[48].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[48].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[48].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[48].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="منطقه 22 تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[48].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                منطقه ۲۲ تهران جدیدترین منطقه شهرداری تهران می باشدکه در شمال غرب تهران قرار دارد. <Link className="link" to="/tehran-zone-22" target="_blank">  منطقه بیست و دوم </Link> را می توان از <Link className="link" to="/tehran-zones" target="_blank">  مناطق </Link> پیشرفته تهران دانست که 6200 هکتار مساحت دارد. آب و هوای خوب این منطقه سبب شده که تعدادی از پایتخت نشینان این منطقه را برای سکونت انتخاب کنند. همچنین منطقه ۲۲ تهران را می توان بزرگترین منطقه ایران نامید. این منطقه تبدیل به قطب گردشگری شده است و مراکز تجاری و تفریحی زیادی را در خود جای داده است.
              </Typography>
              <Typography className="h2" variant="h2">
                شهرک ها و محله های منطقه ۲۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="شهرک های منطقه 22 - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شهرک های منطقه ۲۲
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این منطقه دارای شهرک ها و محله های زیادی است که در ادامه به بررسی معروفترین آن ها می پردازیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Chitgar-Neighborhood" target="_blank">
                  بیشتر بخوانید : چیتگر تهران کجاست؛ تمام اطلاعاتی که در مورد محله چیتگر نیاز دارید
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                شهرک گلستان:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/Listings/buy_tehran_shahrakegolestan" target="_blank">  شهرک گلستان </Link> یا شهرک راه آهن را می توان از قدیمی ترین شهرک های منطقه ۲۲ نامید. این شهرک از غرب به <Link className="link" to="/Listings/rent_tehran_maidanesahel" target="_blank">  میدان ساحل </Link> و دریاچه شهدای خلیج فارس و از شرق به بزرگراه آزادگان و از شمال به بزرگراه همت غرب و از جنوب به پارک جنگلی چیتگر دسترسی دارد. شهرک گلستان شامل دو بخش گلستان غربی و گلستان شرقی می شود. از غرب بلوار کاج تا بلوار طبیعت را گلستان غربی و از شرق بلوار کاج تا اتوبان آزادگان را گلستان شرقی می نامند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                علاوه بر مجتمع های مسکونی فراوانی که در این شهرک قرار دارد مراکز اداری و تجاری بزرگی نیز در شهرک گلستان در حال ساخت می باشد. از جمله این مراکز تجاری می توان به تهران مال و مالمون مال اشاره داشت.
              </Typography>
              <Typography className="h3" variant="h3">
                شهرک چیتگر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از معروفترین محله های منطقه 22، محله چیتگر می باشد. این محله به دلیل وجود دریاچه و پارک جنگلی چیتگر دارای آب و هوای دلپذیر و مطبوعی است. <Link className="link" to="/Chitgar-Neighborhood" target="_blank">  چیتگر </Link> به دلیل مراکز <LinkMui className="link" href="https://www.iranmall.com" target="_blank">  ایران مال </LinkMui> و <LinkMui className="link" href="https://www.kojaro.com/attraction/25484-%D8%A8%D8%A7%D9%85-%D9%84%D9%86%D8%AF-%DA%86%DB%8C%D8%AA%DA%AF%D8%B1" target="_blank">  بام لند </LinkMui> که از جاذبه های این منطقه است تبدیل به یک قطب گردشگری شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج های معروفی در این منطقه وجود دارد که می توان به برج بیمه، برج صیاد شیرازی، برج نگین و برج چیتگر اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                پیکان شهر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شهرک که در غرب دریاچه چیتگر واقع است در ابتدا برای سکونت کارکنان <LinkMui className="link" href="https://esale.ikco.ir/#!" target="_blank">  ایران خودرو </LinkMui> در نظر گرفته شد. در سال 1383 در یک مزایده تمام واحدهای مسکونی این شهرک به فروش رسید. از آنجا که فضای سبز وسیعی در این منطقه قرار دارد بنابراین <Link className="link" to="/Listings/buy_tehran_paykanshahr" target="_blank">  پیکان شهر </Link> از آب و هوای دلپذیری نیز برخوردار است. دسترسی به این شهرک از طریق <Link className="link" to="/Listings/buy_tehran_bozorgrahshahidkharazi" target="_blank">  بزرگراه خرازی </Link> و همچنین اتوبان تهران - کرج به راحتی امکانپذیر است.
              </Typography>
              <Typography className="h3" variant="h3">
                شهرک نگین غرب:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرک نگین غرب را می توان از محله های محبوب و نوساز منطقه ۲۲ دانست که در غرب دریاچه چیتگر قرار دارد. در این محله خانه ها و آپارتمان های لوکس با امکانات عالی زیاد دیده می شود. این شهرک بدلیل نبود ترافیک، دارای آب و هوای بی نظیری است. بلوار اصلی این شهرک اردستانی نام دارد. نگین غرب از شمال با شهرک دانشگاه شریف و از غرب با آتی شهر در ارتباط است. همچنین از لحاظ دسترسی به مترو، متروی وردآباد نزدیکترین ایستگاه به این شهرک می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">
                  بیشتر بخوانید : الهیه تهران کجاست ؟ معرفی کامل خیابان فرشته و محله الهیه تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                شهرک شهید باقری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرک باقری متشکل از دو بافت قدیمی و جدید می باشد که فضایی متفاوت ایجاد کرده است. بافت قدیمی این شهرک دارای 500 بلوک مسکونی قدیمی می باشد در صورتی که بافت جدید آن برج های مسکونی مدرن و نوسازی را شامل می شود. از مهم ترین برج های این شهرک می توان برج سفید و برج سما را نام برد. از آنجا که این شهرک در نزدیکی آبشار تهران وپارک جنگلی لتمان واقع است بنابراین آب و هوای مطبوعی نیز دارد.
              </Typography>
              <Typography className="h3" variant="h3">
                شهرک صدرا:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرک صدرا یکی از محله های منطقه ۲۲ می باشد که در سال 1387 تمامی واحد های مسکونی آن آماده تحویل شد.نام این شهرک از نام امام موسی صدر برگرفته است. معماری ایتالیایی این شهرک زیبایی چشمگیری را به این شهرک داده است. صدرا دارای بهترین امکانات ورزشی و رفاهی می باشد. مساحت این شهرک حدود 40  هکتار می باشد که حدود یک سوم از این بخش به فضای سبز اختصاص دارد. از بوستان های معروف در این شهرک می توان به بوستان خرگوش دره، باغ نو، بوستان افرا و گلفام اشاره داشت.
              </Typography>
              <Typography className="h3" variant="h3">
                شهرک شریف:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="شهرک شریف - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شهرک شریف
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  بیشتر بخوانید : معرفی کامل محله نیاوران تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شهرک در سال 1352 با هدف سکونت کارکنان و اساتید دانشگاه صنعتی شریف در محدوده وردآباد شمالی احداث شد. طراحی <Link className="link" to="/Listings/buy_tehran_sharif" target="_blank">  شهرک شریف </Link> تلفیقی از <Link className="link" to="/Interior-architecture-and-interior-design" target="_blank"> معماری </Link> کشورهای ترکیه و اسپانیا و معماری ایرانی می باشد. اکثر ساکنین این شهرک افراد تحصیلکرده و با اصالت هستند. محیط امن و آرام این محله باعث رنج قیمتی بالا در این شهرک نسبت به سایر شهرک های منطقه ۲۲ شده است. مراکز تجاری و رفاهی بسیار لوکس در این شهرک ساخته شده است که از برجسته ترین آن ها می توان به آرتمیس و <LinkMui className="link" href="https://rosemall.com" target="_blank"> رزمال </LinkMui> اشاره کرد. از خیابان های معروف این شهرک می توان خیابان تهران، همدان و گلستان را نام برد.
              </Typography>
              <Typography className="h3" variant="h3">
                زیبادشت:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زیبادشت از <b>محله های منطقه ۲۲ </b> می باشد که از آلودگی و شلوغی تهران به دور می باشد. این شهرک در گذشته بخشی از روستای کن محسوب می شد که با گسترش شهرسازی در تقسیمات جدید شهرداری از محله های منطقه 22 به شمار می آید. همچنین این شهرک به دو بخش <Link className="link" to="/Listings/buy_tehran_zibadashtbala" target="_blank">  زیبادشت بالا </Link> و زیبا دشت <Link className="link" to="/Listings/rent_tehran_zibadashtpaeen" target="_blank">  پایین </Link> تقسیم شده است. زیبا دشت به واسطه بزرگراه همت و آزادگان و همچنین خیابان المپیک بهترین دسترسی را در بین مناطق <Link className="link" to="/tehran-zone-5" target="_blank"> غربی </Link> پایتخت دارد. از مراکز تفریحی این منطقه می توان به بوستان جوانمردان و تفرجگاه کن اشاره کرد. کمبود خطوط اتوبوس و دوری به مترو را تنها معایب این شهرک می توان دانست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                علاوه بر شهرک هایی که در بالا ذکر شد این منطقه دارای شهرک های دیگری نیز می باشد. از جمله این شهرک ها می توان شهرک لاله، امیرکبیر، یاس، کوثر، هوانیروز، شهرک چشمه و آزاد شهر را نام برد.
              </Typography>
              <Typography className="h2" variant="h2">
                سرمایه گذاری در منطقه ۲۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که این منطقه از وسیع ترین مناطق ۲۲گانه تهران می باشد پروژه های بسیاری نیز در این منطقه در حال ساخت هستند. خیلی از افراد حتی اگر قصد سکونت در این منطقه را نداشته باشند ولی این منطقه را برای سرمایه گذاری انتخاب می کنند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Piroozi-street" target="_blank">
                  بیشتر بخوانید :  خیابان پیروزی
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                دسترسی منطقه ۲۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ساکنین این منطقه به دلیل وجود آزاد راه تهران - کرج در جنوب، اتوبان شهید خرازی در شمال و بزرگراه آزادگان در غرب به راحتی می توانند به تمام نقاط تهران دسترسی داشته باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین در این منطقه چندین ایستگاه مترو از جمله متروی <LinkMui className="link" href="https://azadisportcomplex.com" target="_blank">  ورزشگاه آزادی </LinkMui>، ایران خودرو، متروی چیتگر و متروی وردآورد قرار دارد که جابجایی را برای افراد این منطقه میسر می کند.
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز آموزشی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مراکز آموزشی مهم در این منطقه می توان به دانشکده کشاورزی تربیت مدرس، دانشگاه علوم انتظامی، پژوهشگاه پلیمر و پتروشیمی، بنیاد علمی بین المللی مغز و اعصاب ایران و <LinkMui className="link" href="https://atu.ac.ir/fa" target="_blank">  دانشگاه علامه طباطبایی </LinkMui> اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                فضای سبز و بوستان های منطقه ۲۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="لتمان کن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  لتمان کن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تعداد بوستان ها و فضاهای سبز در این منطقه بسیار زیاد است و حدود 1200 هکتار از مساحت این منطقه به فضای سبز اختصاص دارد. از جمله بوستان های مهم این منطقه می توان پارک جنگلی چیتگر، پارک جنگلی خرگوش دره، لتمان کن، پارک جنگلی وردآورد، بوستان بانوان ریحانه و بوستان جوانمردان را نام برد.
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز خرید
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="تهران مال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تهران مال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از دلایل شلوغی این منطقه وجود مراکز تجاری و پاساژهای مدرنی است که در این چند سال اخیر تاسیس شده است. از جمله این مراکز می توان به ایران مال، گلستان خیام، سرزمین کودک آفتاب، بام لند، تهران مال، طوبی و شهر ارگانیک اشاره کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Shemiran-No" target="_blank">
                  بیشتر بخوانید : محله شمیران نو
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مراکز درمانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مراکز درمانی مهم در منطقه ۲۲ می توان درمانگاه المهدی، درمانگاه شهرک راه آهن، پلی کلینیک فیاض بخش، درمانگاه رازی، صدرا، المپیک، درمانگاه دکتر مستوفی و گلچین را نام برد.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مطلب از هومینجا سعی کردیم. اطلاعات کامل و دقیقی از منطقه ۲۲ تهران ارائه دهیم تا با این منطقه جدید شهرداری تهران آشنا شوید. برج‌ها و امکانات رفاهی متعدد و  آب و هوای مطبوع این منطقه سبب جذب تعداد زیادی از افراد به سوی این منطقه شده است. امید است این مطالب برایتان مفید واقع شود.
              </Typography>
              <Typography className="h4" variant="h4">
                با هومینجا یک کلیک تا قرارداد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطالعه مقالات زیر به شناخت شما از محلات تهران کمک فراوانی می کند.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/HomeMag'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    بهترین مقالات املاک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Azadi'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معرفی محله آزادی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Hakimiyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معرفی محله حکیمیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/buying-house-in-Tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    نکات مهم در مورد خرید ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Tehran-District-One-Luxurious-Towers'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    برج های لوکس منطقه ۱ تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Housing-Market-Prediction-1402'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_daryachechitgar'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در چیتگر
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_daryachechitgar'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در چیتگر
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_dehkadeolampik'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره در دهکده المپیک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article49;