import { Grid, Typography } from "@mui/material";
import React from "react";
import gif from '../images/gifLogo.gif';

class GifLogo extends React.Component{

    render(){
        return(
            <Grid style={{position:'fixed',display:this.props.display,top:"20vh",right:'50%',zIndex:"4000",transform:"translate(50%,50%)"}}>
                <Typography component={'img'} src={gif} style={{width:'100%'}} alt="load-gif"></Typography>
            </Grid>
        )
    }
}

export default GifLogo;