import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article25/01.jpg";
import photo02 from "../../images/mag/article25/02.jpg";
import photo03 from "../../images/mag/article25/03.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article25 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "پس از ثبت قرارداد در سامانه معاملات و املاک کشوریک کد رهگیری 13 رقمی منحصر به فرد جهت جلوگیری از جرایم معاملات ملکی صادر می شود.";
    document.getElementsByTagName('meta')["keywords"].content = "سامانه کد رهگیری املاک | استعلام کد رهگیری املاک | دریافت کد رهگیری | اهمیت کد رهگیری املاک"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[24].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[24].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[24].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[24].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[24].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[24].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کد رهگیری املاک چیست؟ - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[24].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ثبت قراردادهای معاملات ملکی کد رهگیری ارائه شده است. با استفاده از این کد طرفین معامله می توانند از
                صحت قراداد خود مطلع شوند. با هومینجا بلاگ همراه باشید تا با کد رهگیری ملک و نحوه دریافت آن و همچنین موارد مرتبط با کد رهگیری بیشتر آشنا شوید.
              </Typography>
              <Typography className="h2" variant="h2">
                کد رهگیری چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کد رهگیری - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کد رهگیری چیست؟
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هنگام انجام معاملات ملکی، دریافت کد رهگیری یکی از ملزومات است. این کد جهت جلوگیری از جرایم معاملات ملکی و بدست اوردن آمار دقیق
                در حوزه مسکن ضروری می باشد. به موجب این کد، اطمینان بخشی طرفین معامله برای انواع معاملات ملکی اعم از رهن و اجاره، خرید و فروش ملک فراهم می شود.
                <br />
                این کد در سال 1387 از سوی دولت
                برای اصناف و بازرگانان بدلیل نداشتن اعتبار کافی قراردادهای دست نویس صادر شد. در این بخشنامه الزام شد که تمامی مشاورین املاک می بایست تمام معاملات
                ملکی قولنامه ای خود اعم از ثبت قراردادهای خرید و فروش املاک و رهن و اجاره را در سامانه ثبت معاملات و املاک کشور ثبت نمایند و کد مربوط به قرارداد را
                دریافت نمایند. طرفین معامله نیز با استعلام قرارداد املاک با کدملی و کدرهگیری می توانند بدهی به شهرداری یا بانک ها را برای ملک مورد نظر مشاهده نمایند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/transferring-property" target="_blank">
                  بیشتر بخوانید : مراحل سند زنی
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                <LinkMui className="link" href="https://saman.mrud.ir/" target="_blank" rel="noreferrer" >
                  وام ودیعه مسکن
                </LinkMui>
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی اجاره نامه ها باید شامل کد رهگیری باشد.
                بنابراین تمام مستاجرین می بایست قراردادهای خود را در دفاتر معتبر و دارای مجوز منعقد کنند تا بتوانند کد را دریافت کنند و وام اجاره خانه را به ثبت برسانند.
              </Typography>
              <Typography className="h2" variant="h2">
                مراحل دریافت کد رهگیری املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به منظور دریافت این کد املاک می بایست ابتدا مشاورین املاک قرارداد قولنامه ای را در سامانه ثبت معاملات و املاک کشور به ثبت برسانند.
              </Typography>
              <Typography className="h2" variant="h2">
                نحوه ورود به سایت ثبت معاملات املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مشاورین املاک ابتدا می بایست وارد سایت سامانه ثبت معاملات و املاک کشور به
                نشانی <LinkMui className="link" href="https://www.iranamlaak.ir" target="_blank" rel="noreferrer">https://www.iranamlaak.ir</LinkMui> شوند. پس
                از ورود به این سامانه گزینه ورود به سامانه را انتخاب کنند.
                <br />
                مشاورین پیش از ورود به سایت برای دریافت این کد می بایست ابتدا در سامانه ثبت معاملات املاک ثبت نام کرده باشند.
                <LinkMui className="link" href="https://www.iranamlaak.ir/" target="_blank" rel="noreferrer" >
                  برای ورود مستقیم به سامانه ثبت معاملات املاک کلیک کنید
                </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                نکات لازم جهت دریافت کد رهگیری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کد رهگیری املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ثبت کد رهگیری املاک
                </figcaption>
              </figure>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  هنگام ثبت قرارداد می بایست اصل مدارک هویتی نظیر کارت ملی و شناسنامه یا کارت اقامت اتباع خارجی به همراه اصل مدارک مالکیت در اختیار مشاورین املاک گذاشته شود.
                </li>
                <li>
                  مدارک مالکیت می تواند به صورت سند تک برگی، قولنامه
                  ، بنچاق ارائه شود. همچنین در مورد ملک هایی که تحت انحصار وراثت هستند می بایست وکالت نامه یا گواهی انحصار وراثت در اختیار مشاور املاک گذاشته شود.
                </li>
                <li>
                  در هنگام ثبت قرارداد بین طرفین
                  ، هر طرف معامله می بایست یک شاهد برای ثبت قرارداد همراه خود بیاورد که ارائه مدارک شناسایی آن نیز جهت دریافت این کد از ضروریات است.
                </li>
                <li>
                  در قراردادهای بالا برگه کارشناسی ملک فنی و حقوقی مورد نیاز است.
                </li>
                <li>
                  پس از تکمیل فرم پیش نویس قرارداد ، مشاور املاک می بایست اطلاعات مربوطه را در سامانه ثبت معاملات املاک بارگذاری کند.
                </li>
                <li>
                  تاییدیه کد پستی ملک هم از مواردی ضروری جهت دریافت کد می باشد.
                </li>
                <li>
                  پس از بارگذاری اطلاعات، قرارداد باید در سه نسخه پرینت شود. یک نسخه نزد مشاور املاک و دو نسخه دیگر در اختیار هر یک از طرفین معامله قرار می گیرد.
                </li>
              </ul>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از ثبت قرارداد قولنامه ای و دریافت کد در بالای صفحات قرارداد یک بارکد قرار گرفته است. این بارکد شامل تمامی
                مشخصات معامله انجام شده می باشد. مشخصات معامله شامل آدرس دقیق ملک مورد معامله، نام و نام خانوادگی طرفین معامله، نام مالک و نام مستاجر می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/way-to-get-house-loan" target="_blank">
                  بیشتر بخوانید : نحوه دریافت وام مسکن
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                هزینه دریافت کد رهگیری املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طبق اعلام رییس اتحادیه مشاوران املاک دریافت کد
                رهگیری برای طرفین معامله رایگان است. مشاورین املاک نباید بجز دریافت حق الزحمه مشاوره هزینه ای بابت ارائه کد از طرفین دریافت نمایند.
                <br />
                سامانه ثبت معاملات املاک و مستغلات کشور در زمان ثبت قرارداد، هزینه دریافت کد را از دفاتر مشاور املاک دریافت می کنند.
                <br />
                جهت مشاوره آخرین تعرفه می بایست به صفحه تعرفه صدور در کد رهگیری در سامانه مراجعه نمایید.
              </Typography>
              <Typography className="h2" variant="h2">
                کد رهگیری اجاره نامه چند رقمی است؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کد رهگیری 13 رقمی می باشد. پس از ثبت قرارداد در
                سامانه این کد به صورت پیامک برای هر یک از طرفین معامله ارسال می شود. این کد در سمت چپ برگه چهارم مبایعه نامه نیز قرار گرفته شده است.
              </Typography>
              <Typography className="h2" variant="h2">
                نحوه استعلام کد رهگیری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افراد می تواننید با مراجعه به سامانه ثبت معاملات املاک و وارد نمودن کد ملی و کد رهگیری، از صحت قرارداد قولنامه ای خود اطمینان حاصل نمایند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جهت استعلام باید از طریق مراحل زیر اقدام نمایید:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  ابتدا می بایست وارد سامانه ثبت معاملات املاک شوید. در بالای صفحه قسمت خدمات روی گزینه استعلام کد رهگیری کلیک کنید.
                </li>
                <li>
                  در صفحه جدید کد ملی و کد رهگیری را به همراه کد امنیتی وارد کنید و دکمه جستجو را کلیک نمایید.
                </li>
                <li>
                  در صورت صحت اطلاعات، قرارداد مورد نظر نمایش داده می شود.
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                دریافت کد رهگیری با پیامک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جهت استعلام از طریق پیامک، می توانید شماره ملی و کد رهگیری قرارداد را با فرمت (کدملی#کد رهگیری) به شماره 110203001 ارسال کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                هزینه استعلام از طریق پیام کوتاه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای استعلام و ارتباط با سامانه املاک و مستغلات کشور با پیام کوتاه، هزینه رایگان خواهد بود. یعنی ارسال پیامک از طرف هر فرد هزینه
                ای نخواهد داشت اما در صورت پاسخ سامانه و دریافت پیام از سامانه، مبلغ 200 تومان بعنوان هزینه خدمات از حساب فرد کسر می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/property-document" target="_blank">
                  بیشتر بخوانید : انواع سند ملکی
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                سوالات متداول:
              </Typography>
              <Typography className="h3" variant="h3">
                1- کد رهگیری املاک قولنامه ای چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هنگامی که یک قراداد قولنامه
                ای در یکی از دفاتر املاک دارای مجوز منعقد می شود، دفتر املاک اطلاعات مربوطه را در سامانه ثبت معاملات املاک کشور یا سایت ایران املاک
                بارگزاری می کند. سامانه یک عدد 13 رقمی منحصر به فرد برای استعلام صادر می کند که این همان کد رهگیری املاک قولنامه ای می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                2- آیا دریافت کد رهگیری الزامی است؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بله، تمامی معاملات ملکی قولنامه ای ملزم به دریافت این کد هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                3- کد رهگیری را چگونه می توان دریافت نمود؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از اینکه قرارداد منعقد شد مشاور املاک می بایست اطلاعات را در سامانه املاک کشور ثبت کند و کد را دریافت نماید.
              </Typography>
              <Typography className="h3" variant="h3">
                4- کد رهگیری املاک چند رقمی است؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کد رهگیری املاک یک عدد 13 رقمی منحصر به فرد می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                5- در صورت فراموشی کدرهگیری چه کنیم؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در صورت فراموشی یا گم کردن کد رهگیری ملک خود می توانید با مراجعه به دفاتر املاک یا دادن اطلاعات قرارداد به اتحادیه مشاورین آن را بازیابی کنید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/house-tracking-code" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/house-tracking-code" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/house-tracking-code" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article25;