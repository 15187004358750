import { Autocomplete, Button, Checkbox, Divider, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl, checkedIcon, icon } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class RequestContract extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
    super(props);
    this.state={
      giftShow:false,
      openAlert:false,
      message:'',
      transactionOption:[],
      transactionSelect:null,
      typeEstateOption:[],
      typeEstateSelect:null,
      disableTypeEstate:true,
      stateOption:[],
      stateSelect:null,
      disableState:true,
      cityOption:[],
      citySelect:null,
      disableCity:true,
      zoneOption:[],
      zoneSelect:null,
      disableZone:true,
      neibOption:[],
      neibSelect:null,
      disableNeib:true,
      clearText:'حذف',
      closeText:"بستن",
      openText:"باز کردن",
      noOptionsText:"فاقد اطلاعات",
      textDescription:'کاربر گرامی درخواست قرارداد خود را ثبت نمایید و سپس کارشناسان برای تعیین وقت عقد قرارداد و مدارک لازم جهت عقد قرار داد باشما تماس خواهند گرفت.',
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'درخواست قرارداد ملک در سامانه معاملات املک هومینجا'
    if (this.isMount){
      this.getTransaction();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false})
  }

  getTransaction = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/trans/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({transactionOption:res.data.data})
    })
    .catch((err) => {
        this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeTransaction = (event,newValue) =>{
    this.setState({disableTypeEstate:true,disableState:true,disableCity:true,disableZone:true,disableNeib:true,transactionSelect:null,typeEstateSelect:null,
                  stateSelect:null,citySelect:null,zoneSelect:null,neibSelect:null});
    if (newValue !== null){
      this.setState({transactionSelect:newValue});
      this.getTypeEstate();
    }
  }

  getTypeEstate = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/estate/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({typeEstateOption:res.data.data,disableTypeEstate:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeTypeEstate = (event,newValue) =>{
    this.setState({disableState:true,disableCity:true,disableZone:true,disableNeib:true,typeEstateSelect:null,
                  stateSelect:null,citySelect:null,zoneSelect:null,neibSelect:null});
    if (newValue !== null){
      this.setState({typeEstateSelect:newValue});
      this.getState();
    }
  }

  getState = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/state/get',
      method: 'GET'
    }))
    .then((res) => {
        this.setState({stateOption:res.data.data,disableState:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeState = (event,newValue) =>{
    this.setState({disableCity:true,disableZone:true,disableNeib:true,stateSelect:null,citySelect:null,zoneSelect:null,neibSelect:null});
    if (newValue !== null){
      this.setState({stateSelect:newValue});
      this.getCity(newValue.id);
    }
  }

  getCity = (stateValue) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/city/get',
      method: 'GET',
      params:{
        state_id:stateValue
      }
    }))
    .then((res) => {
        this.setState({cityOption:res.data.data,disableCity:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeCity = (event,newValue) =>{
    this.setState({disableZone:true,disableNeib:true,citySelect:null,zoneSelect:null,neibSelect:null})
    if (newValue !== null){
      this.getZone(newValue.id);
      this.setState({citySelect:newValue});
    }
  }

  getZone = (city) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/zone/get',
      method: 'GET',
      params:{
        city_id:city
      }
    }))
    .then((res) => {
        this.setState({zoneOption:res.data.data,disableZone:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeZone = (event,newValue) =>{
    this.setState({disableNeib:true,zoneSelect:null,neibSelect:null})
    if (newValue !== null){
      this.getNieghbourhood(newValue.id)
      this.setState({zoneSelect:newValue})
    }
  } 

  getNieghbourhood = (zone) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/neighb/get',
      method: 'GET',
      params:{
        zone_id:zone
      }
    }))
    .then((res) => {
        this.setState({neibOption:res.data.data,disableNeib:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeNeighborhood = (event,newValue) =>{
    this.setState({neibSelect:newValue})
  } 

  onClickRequest = () =>{
    if (this.state.transactionSelect !== null){
      if (this.state.typeEstateSelect !== null){
        if (this.state.stateSelect !== null){
          if (this.state.citySelect !== null){
            if (this.state.zoneSelect !== null){
              if (this.state.neibSelect !== null){
                this.setState({giftShow:true});
                this.contractRequest();
              }
              else{
                this.setState({openAlert:true,message:'لطفا محله را انتخاب بفرمایید.',typeAlert:3})
              }
            }
            else{
              this.setState({openAlert:true,message:'لطفا منطقه را انتخاب بفرمایید.',typeAlert:3})
            }
          }
          else{
            this.setState({openAlert:true,message:'لطفا شهر را انتخاب بفرمایید.',typeAlert:3})
          }
        }
        else{
          this.setState({openAlert:true,message:'لطفا استان را انتخاب بفرمایید.',typeAlert:3})
        }
      }
      else{
        this.setState({openAlert:true,message:'لطفا نوع ملک را انتخاب بفرمایید.',typeAlert:3})
      }
    }
    else{
      this.setState({openAlert:true,message:'لطفا نوع معامله را انتخاب بفرمایید.',typeAlert:3})
    }
  }

  contractRequest = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/contract/create',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        state_id:this.state.stateSelect.id,
        city_id:this.state.citySelect.id,
        zone_id:this.state.zoneSelect.id,
        neighbourhood_id:this.state.neibSelect.id,
        estate_id:this.state.typeEstateSelect.id,
        transaction_id:this.state.transactionSelect.id,
      }
    }))
    .then(() => {
      this.setState({openAlert:true,message:'درخواست قرارداد شما با موفقیت ثبت گردید.',giftShow:false,disableTypeEstate:true,
                      disableState:true,disableCity:true,disableZone:true,disableNeib:true,transactionSelect:null,typeEstateSelect:null,
                      stateSelect:null,citySelect:null,zoneSelect:null,neibSelect:null,typeAlert:1})
    })
    .catch(() => {
      this.setState({openAlert:true,message:'عملیات با خطا مواجه شد لطفا بعد مجدد متحان نمایید یا با پشتیبانی تماس بگیرید.',giftShow:false,typeAlert:2})
    });
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواست قرارداد</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >    
          <Grid item xs={10} sx={{marginTop:{md:'150px',xs:'30px'}}}
                style={{display:'flex',width:'100%',flexDirection:'row',alignItems:'center',justifyContent:'space-evenly',flexWrap:'wrap'}}
          >
            <Grid item sm={6} xs={12} sx={{paddingLeft:{lg:'10%',md:'8%',sm:'10%',xs:'5%'},paddingRight:{lg:'10%',md:'8%',sm:'10%',xs:'5%'}}}
                  style={{marginBottom:15}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                options={this.state.transactionOption}
                getOptionLabel={(option) => option.des}
                renderInput={(params) => <TextField  {...params} placeholder='نوع معامله'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.des}
                  </li>
                )}
                onChange={this.onChangeTransaction}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid item sm={6} xs={12} sx={{paddingLeft:{lg:'10%',md:'8%',sm:'10%',xs:'5%'},paddingRight:{lg:'10%',md:'8%',sm:'10%',xs:'5%'}}}
                  style={{marginBottom:15}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                options={this.state.typeEstateOption}
                getOptionLabel={(option) => option.des}
                renderInput={(params) => <TextField  {...params} placeholder='نوع ملک'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.des}
                  </li>
                )}
                onChange={this.onChangeTypeEstate}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableTypeEstate}
              />
            </Grid>
            <Grid item sm={6} xs={12} sx={{paddingLeft:{lg:'10%',md:'8%',sm:'10%',xs:'5%'},paddingRight:{lg:'10%',md:'8%',sm:'10%',xs:'5%'}}}
                  style={{marginBottom:15}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                options={this.state.stateOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='استان'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeState}
                value={this.state.stateSelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableState}
              />
            </Grid>
            <Grid item sm={6} xs={12} sx={{paddingLeft:{lg:'10%',md:'8%',sm:'10%',xs:'5%'},paddingRight:{lg:'10%',md:'8%',sm:'10%',xs:'5%'}}}
                  style={{marginBottom:15}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                options={this.state.cityOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='شهر'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeCity}
                value={this.state.citySelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableCity}
              />
            </Grid>
            <Grid item sm={6} xs={12} sx={{paddingLeft:{lg:'10%',md:'8%',sm:'10%',xs:'5%'},paddingRight:{lg:'10%',md:'8%',sm:'10%',xs:'5%'}}}
                  style={{marginBottom:15}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                options={this.state.zoneOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='منطقه'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeZone}
                value={this.state.zoneSelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableZone}
              />
            </Grid>
            <Grid item sm={6} xs={12} sx={{paddingLeft:{lg:'10%',md:'8%',sm:'10%',xs:'5%'},paddingRight:{lg:'10%',md:'8%',sm:'10%',xs:'5%'}}}
                  style={{marginBottom:15}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                options={this.state.neibOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='محله'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeNeighborhood}
                value={this.state.neibSelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableNeib}
              />
            </Grid>
            <Grid item xs={12} style={{width:'100%',marginTop:30}}>
              <Divider/>
              <Typography variant="h4" sx={{fontSize:{md:'25px',xs:'20px'}}}
                  style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'justify',marginTop:'30px'}}
              >
                {this.state.textDescription}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{width:'100%',display:'flex',justifyContent:'center',marginTop:100}}>
              <Button onClick={this.onClickRequest} sx={{width:{md:'50%',xs:'80%'}}}
                      style={{backgroundColor:'#00C8E9',borderRadius:8,height:'50px',color:'black',
                              fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}>
                {'ثبت درخواست'}
              </Button>
            </Grid>
          </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
      </Grid>
    )
  };
}

export default RequestContract;