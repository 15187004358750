import React, { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { Typography } from "@mui/material";

const position = [35.7219, 51.3347]

let DefaultIcon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: icon,
    shadowUrl: iconShadow
  });
L.Marker.prototype.options.icon = DefaultIcon;

function Get() {
    const [position, setPosition] = useState(null)
    const map = useMapEvents({
      click: (e) => {
        localStorage.setItem('lat',e.latlng.lat)
        localStorage.setItem('lng',e.latlng.lng)
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      }     
    })
    return position === null ? null : (
        <Marker position={position}>
            <Popup><Typography variant="body1" style={{fontFamily:'shabnam'}}>{'ملک مورد نظر اینجاست'}</Typography> </Popup>
        </Marker>
    )
}

export default function GetLocation(){
    return(
        <MapContainer style={{height:'100%',width:'100%'}} center={position} zoom={12}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
            <Get/>
        </MapContainer>
    )
}