import { Backdrop, Box, Button, Divider, Grid, Input, Modal, OutlinedInput, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToEnglishNumber, convertToPersianNumber, onlyReciveNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import NoLogo from '../images/noLogoAgancy.png';
import Trash from '../images/trash.png';
import Open from '../images/openFolder.png';
import Resizer from "react-image-file-resizer";

class EditAgancy extends React.Component {

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      message: '',
      giftShow: false,
      searchText: '',
      searchList: [],
      editModal: false,
      editAgancy: null,
      name: '',
      manager: '',
      address: '',
      phone: '',
      mobile: '',
      webSite: '',
      insta: '',
      licence: '',
      imgLicence: null,
      imgLogo: null,
      statusAgancy: false,
      typeAlert: 3
    }
  }

  componentDidMount() {
    this.isMount = true;
    if (this.isMount) {
      if (this.props.user.user_type_id === 6) {
        this.getNewAgancy();
      }
    }
  }

  componentWillUnmount() {
  }

  onChangeSearch = (event) => {
    this.setState({ searchText: event.target.value.trim() });
  }

  closeAlertModal = () => {
    this.setState({ openAlert: false });
  }

  getNewAgancy = () => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/agancy/new',
      method: 'GET',
      headers: { 'Authorization': 'bearer ' + this.token },
    }))
      .then((res) => {
        let items = res.data.data;
        this.setState({ searchList: items, giftShow: false })
      })
      .catch((err) => {
        this.setState({ searchList: [], giftShow: false })
      });
  }

  search = () => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/agancy/search',
      method: 'GET',
      headers: { 'Authorization': 'bearer ' + this.token },
      params: {
        search: this.state.searchText
      }
    }))
      .then((res) => {
        this.setState({ searchList: res.data.data, giftShow: false })
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: 'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.', giftShow: false, typeAlert: 2 })
      });
  }

  onClickSearch = () => {
    if (this.state.searchText.trim() !== '') {
      this.setState({ giftShow: true });
      this.search();
    }
    else {
      if (this.props.user.user_type_id === 6) {
        this.setState({ giftShow: true });
        this.getNewAgancy();
      }
      else {
        this.setState({ openAlert: true, message: 'چیزی برای جستجو وارد نکرده اید.', typeAlert: 3 })
      }
    }
  }

  createSerchList = () => {
    let list = [];
    this.state.searchList.forEach((element, index) => {
      list.push(
        <Grid item xs={12} key={index}
          style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', flexWrap: 'wrap', marginTop: 10,
            background: 'linear-gradient(270deg, rgba(204, 244, 251, 0.14) 0%, #FFFFFF 100%)', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14)',
            borderRadius: '0px 40px 0px 0px'
          }}>
          <Grid item sm={2} xs={3} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            {
              this.state.searchList[index].logo_path === null ?
                <Typography component={'img'} src={NoLogo} style={{ height: 120, width: '90%', borderRadius: '0px 40px 0px 0px' }} alt="no-logo"></Typography>
                :
                <Typography
                  style={{
                    display: 'flex', alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: 120, width: '90%',
                    borderRadius: '0px 40px 0px 0px', backgroundImage: `url(${this.state.searchList[index].logo_path})`, backgroundPosition: 'center'
                  }}
                ></Typography>
            }

          </Grid>
          <Grid item sm={8} xs={9} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 5 }}>
              <Grid item md={2.5} xs={4} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {'اسم املاک:'}
                </Typography>
              </Grid>
              <Grid item md={9.5} xs={8} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {element.name}
                </Typography>
              </Grid>

            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 5 }}>
              <Grid item md={2.5} xs={4} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {'اسم مدیر:'}
                </Typography>
              </Grid>
              <Grid item md={9.5} xs={8} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {element.manager_name}
                </Typography>
              </Grid>


            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 5 }}>
              <Grid item md={2.5} xs={4} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {'شماره جواز:'}
                </Typography>
              </Grid>
              <Grid item md={9.5} xs={8} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {element.licence_number}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', marginBottom: 5 }}>
              <Grid item md={2.5} xs={4} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {'آدرس:'}
                </Typography>
              </Grid>
              <Grid item md={9.5} xs={8} style={{ width: '100%' }}>
                <Typography component={'p'}
                  style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%', overflowX: 'hidden' }}
                  sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                >
                  {convertToPersianNumber(element.city.name) + '-' + convertToPersianNumber(element.address)}
                </Typography>
              </Grid>


            </Grid>
          </Grid>
          <Grid item sm={2} xs={12} style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
            <Button onClick={() => this.showEditModal(element.id)}
              style={{ backgroundColor: '#00C8E9', color: 'black', width: '100%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold' }}
              sx={{ height: { sm: '120px', xs: '60px' } }}
            >
              {'ویرایش'}
            </Button>
          </Grid>
        </Grid>
      )
    })
    return list;
  }

  showEditModal = (agancy) => {
    this.setState({ editModal: true })
    this.getAgancy(agancy);
  }

  closeEditModal = () => {
    this.setState({ editModal: false, editAgancy: null, imgLicence: null, imgLogo: null, name: '', manager: '', address: '', phone: '', mobile: '', webSite: '', insta: '', licence: '' })
  }

  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  }

  onChangeManager = (event) => {
    this.setState({ manager: event.target.value });
  }

  onChangeMobile = (event) => {
    this.setState({ mobile: convertToEnglishNumber(convertToPersianNumber(event.target.value)) });
  }

  onChangePhone = (event) => {
    this.setState({ phone: convertToEnglishNumber(convertToPersianNumber(event.target.value)) });
  }

  onChangeSite = (event) => {
    this.setState({ webSite: event.target.value });
  }

  onChangeInsta = (event) => {
    this.setState({ insta: event.target.value });
  }

  onChangeLicenceNumber = (event) => {
    this.setState({ licence: convertToEnglishNumber(convertToPersianNumber(onlyReciveNumber(event.target.value))) });
  }

  onChangeAddress = (event) => {
    this.setState({ address: convertToEnglishNumber(convertToPersianNumber(event.target.value)) });
  }

  getAgancy = (id) => {
    Promise.resolve(axios({
      method: 'GET',
      url: baseUrl + 'v1/agancy/get',
      headers: { 'Authorization': 'bearer ' + this.token },
      params: {
        agancy_id: id
      }
    }))
      .then((res) => {
        res = res.data.data;
        this.setState({
          imgLicence: res.licence_path, imgLogo: res.logo_path, editAgancy: res.id, name: res.name, manager: res.manager_name, phone: res.phone,
          mobile: res.mobile, webSite: res.website, insta: res.instagram_link, licence: res.licence_number, address: res.address, editModal: true,
          statusAgancy: res.active === 1 ? true : false
        })
      })
      .catch(() => {
        this.setState({ imgLicence: null, imgLogo: null, name: '', manager: '', address: '', phone: '', mobile: '', webSite: '', insta: '', licence: '' })
      })
  }

  onChangeLogo = (e) => {
    if (e.target.files[0]) {
      this.imageFile = e.target.files[0];
      this.imageResizer('L')
    }
  }

  onChangeLicence = (e) => {
    if (e.target.files[0]) {
      this.imageFile = e.target.files[0];
      this.imageResizer('LI')
    }
  }

  imageResizer = (t) => {
    try {
      Resizer.imageFileResizer(
        this.imageFile,
        1000,
        500,
        "JPEG",
        70,
        0,
        (uri) => {
          this.imageResize = uri;
          this.addImageAgancy(t);
        },
        "file",
      );
    } catch (err) {
      this.setState({ openAlert: true, message: "فایل موردنظر عکس نمیباشد", typeAlert: 2 });
    }
  }

  addImageAgancy = (t) => {
    const formData = new FormData();
    formData.append("image", this.imageResize);
    formData.append("id", this.state.editAgancy);
    formData.append("type", t);
    this.setState({ giftShow: true });
    Promise.resolve(axios({
      url: baseUrl + "v1/agancy/addImage",
      method: "POST",
      headers: {
        "Authorization": "bearer " + this.token,
        "Content-Type": "multipart/form-data"
      },
      data: formData
    }))
      .then((res) => {
        this.setState({ openAlert: true, message: 'عکس با موفقیت ذخیره شد', giftShow: false, typeAlert: 1 })
        this.getAgancy(this.state.editAgancy);
      })
      .catch((err) => {
        this.setState({ openAlert: true, message: "عکس بارگزاری نشد", giftShow: false, typeAlert: 2 });
      });
  }

  deleteImageAgancy = (t) => {
    Promise.resolve(axios({
      method: 'POST',
      url: baseUrl + 'v1/agancy/deleteImage',
      headers: { 'Authorization': 'bearer ' + this.token },
      data: {
        id: this.state.editAgancy,
        type: t
      }
    }))
      .then((res) => {
        this.setState({ openAlert: true, message: 'عکس  با موفقیت حذف شد', giftShow: false, typeAlert: 1 })
        this.getAgancy(this.state.editAgancy);
      })
      .catch(() => {
        this.setState({ openAlert: true, message: "عملیات حذف ناموق بود.", giftShow: false, typeAlert: 2 })
      })
  }



  onClickSave = () => {
    if (this.state.name !== '') {
      if (this.state.manager !== '') {
        if (this.state.phone !== '') {
          if (this.state.mobile !== '') {
            if (this.state.licence !== '') {
              if (this.state.address !== '') {
                this.setState({ giftShow: true, editModal: false })
                this.editAgancy();
              }
              else {
                this.setState({ openAlert: true, message: 'لطفا آدرس را وارد کنید.', typeAlert: 3 })
              }
            }
            else {
              this.setState({ openAlert: true, message: 'لطفا شماره جوازکسب آژانس را وارد کنید.', typeAlert: 3 })
            }
          }
          else {
            this.setState({ openAlert: true, message: 'لطفا شماره موبایل مدیر آژانس را وارد کنید.', typeAlert: 3 })
          }
        }
        else {
          this.setState({ openAlert: true, message: 'لطفا شماره تلفن آژانس را وارد کنید.', typeAlert: 3 })
        }
      }
      else {
        this.setState({ openAlert: true, message: 'لطفا نام مدیر آژانس را وارد کنید.', typeAlert: 3 })
      }
    }
    else {
      this.setState({ openAlert: true, message: 'لطفا نام آژانس را وارد کنید.', typeAlert: 3 })
    }
  }

  editAgancy = () => {
    console.log(this.state.webSite.trim())
    Promise.resolve(axios({
      url: baseUrl + 'v1/agancy/edit',
      method: 'POST',
      headers: { 'Authorization': 'bearer ' + this.token },
      data: {
        id: this.state.editAgancy,
        name: this.state.name.trim(),
        manager_name: this.state.manager.trim(),
        phone: this.state.phone.trim(),
        mobile: this.state.mobile.trim(),
        instagram_link: this.state.insta !== '' && this.state.insta !== null ? this.state.insta.trim() : null,
        website: this.state.webSite !== '' && this.state.webSite !== null ? this.state.webSite.trim() : null,
        address: this.state.address.trim(),
        licence_number: this.state.licence.trim()
      }
    }))
      .then((res) => {
        this.closeEditModal();
        this.setState({ openAlert: true, message: ' اطلاعات آژانس با موفقیت ویرایش گردید.', giftShow: false })
        if (this.state.searchText !== '') {
          this.search();
        }
        else {
          if (this.props.user.user_type_id === 6) {
            this.setState({ giftShow: true });
            this.getNewAgancy();
          }
        }
      })
      .catch(() => {
        this.closeEditModal();
        this.setState({ openAlert: true, message: 'عملیات با خطا مواجه شد لطفا بعد مجدد امتحان نمایید یا با پشتیبانی تماس بگیرید.', giftShow: false, typeAlert: 2 })
      });
  }

  changeStatusAgancy = () => {
    Promise.resolve(axios({
      url: baseUrl + 'v1/agancy/active',
      method: 'POST',
      headers: { 'Authorization': 'bearer ' + this.token },
      data: {
        id: this.state.editAgancy
      }
    }))
      .then(() => {
        this.closeEditModal();
        this.setState({ openAlert: true, message: ' اطلاعات آژانس با موفقیت ویرایش گردید.', giftShow: false, typeAlert: 1 })
        if (this.state.searchText !== '') {
          this.search();
        }
        else {
          if (this.props.user.user_type_id === 6) {
            this.setState({ giftShow: true });
            this.getNewAgancy();
          }
        }
      })
      .catch(() => {
        this.closeEditModal();
        this.setState({ openAlert: true, message: 'عملیات با خطا مواجه شد لطفا بعد مجدد امتحان نمایید یا با پشتیبانی تماس بگیرید.', giftShow: false, typeAlert: 2 })
      });
  }

  render() {

    return (
      <Grid item style={{ marginTop: 120, width: '100%', display: 'flex', flexDirection: 'column' }} sx={{ marginRight: { md: '280px', xs: '15px' } }}>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', marginRight: '5%' }}>
          <Typography variant="h5" style={{ fontFamily: 'shabnam', fontWeight: 'bold' }}>جستجو آژانس املاک</Typography>
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 50, width: '100%' }}
          sx={{ flexDirection: { xs: 'column' }, paddingLeft: { lg: '100px', md: '50px', xs: '10px' }, paddingRight: { lg: '80px', md: '40px', xs: '5px' } }}
        >
          <Grid item xs={10} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', alignItems: 'center', marginTop: 25 }}>
            <Grid item xs={8} style={{ display: 'flex', width: '100%' }}>
              <OutlinedInput variant="outlined" onChange={(event) => this.onChangeSearch(event)} value={this.state.searchText}
                style={{
                  borderStyle: 'none', width: '100%', fontFamily: 'shabnam', fontWeight: 'bold', backgroundColor: '#F1F2F2',
                  maxHeight: 70, borderRadius: 0
                }}
                sx={{ height: { md: '60px', xs: '50px' }, fontSize: { md: '20px', xs: '16px' } }}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', width: '100%' }}>
              <Button onClick={this.onClickSearch}
                style={{
                  backgroundColor: '#00C8E9', color: 'black', fontFamily: 'shabnam',
                  fontWeight: 'bold', borderRadius: 0, borderBottomLeftRadius: 26
                }}
                sx={{ width: { xs: '100%' }, height: { md: '60px', xs: '50px' }, fontSize: { md: '20px', xs: '16px' } }}
              >
                {'جستجو'}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 20, marginBottom: 20 }}>
            <Grid item lg={1.5} md={2.5} xs={2} style={{ display: 'flex', width: '100%' }}>
              <Typography component={'p'}
                style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%', textAlign: 'center' }}
                sx={{ fontSize: { sm: '16px', xs: '13px' } }}
              >
                {'نتیجه جستجو'}
              </Typography>
            </Grid>
            <Grid item lg={8.5} md={7.5} xs={8} style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
              <Divider />
            </Grid>
            <Grid item xs={0.5} style={{ display: 'flex', width: '100%' }}>
              <Typography component={'p'}
                style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%', textAlign: 'center' }}
                sx={{ fontSize: { sm: '16px', xs: '13px' } }}
              >
                {convertToPersianNumber(this.state.searchList.length)}
              </Typography>
            </Grid>
            <Grid item xs={1.5} style={{ display: 'flex', width: '100%' }}>
              <Typography component={'p'}
                style={{ fontFamily: 'shabnam', fontWeight: 'bold', width: '100%', textAlign: 'center' }}
                sx={{ fontSize: { sm: '16px', xs: '13px' } }}
              >
                {'آژانس'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            {
              this.state.searchList.length === 0 ?
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '20%' }}>
                  <Typography variant="h1" sx={{ fontSize: { md: '26px', xs: '16px' } }}
                    style={{ fontFamily: 'shabnam', fontWeight: 'bold', textAlign: 'center', marginBottom: 5, width: '100%' }}>
                    {'هیچ موردی برای نمایش وجود ندارد.'}
                  </Typography>
                </Grid>
                :
                this.createSerchList()
            }
          </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'} />
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert} />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.editModal}
            onClose={this.closeEditModal}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 2000 }}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: { md: 'center', xs: 'start' }, justifyContent: 'center', overflowY: 'scroll' }}
          >
            <Grid item xs={10}
              style={{
                backgroundColor: 'white', display: 'flex', width: '80%', border: '2px solid', borderColor: 'black',
                flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '6px 16px 6px 16px', zIndex: "0",
                boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'
              }}>
              <Grid item xs={11} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 20 }}>
                <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5 }}>{'ویرایش اطلاعات'}</Typography>
              </Grid>
              <Grid item xs={11} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'نام : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeName(event)} value={this.state.name}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'مدیر آژانس : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeManager(event)} value={this.state.manager}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'شماره تلفن : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangePhone(event)} value={convertToPersianNumber(this.state.phone)}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'شماره موبایل : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeMobile(event)} value={convertToPersianNumber(this.state.mobile)}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 11 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'آدرس وب سایت : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeSite(event)} value={this.state.webSite}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'لینک اینستاگرام : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeInsta(event)} value={this.state.insta}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'شماره جوازکسب : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeLicenceNumber(event)} value={convertToPersianNumber(this.state.licence)}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', height: 35 }}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'آدرس : '}</Typography>
                  <OutlinedInput variant="outlined"
                    onChange={(event) => this.onChangeAddress(event)} value={convertToPersianNumber(this.state.address)}
                    style={{ borderStyle: 'solid', borderWidth: 1, width: '70%', fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', overflow: 'scroll', height: 35 }}
                    inputProps={{ maxLength: 60 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={11} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}

                >
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'عکس جواز کسب : '}</Typography>
                  <Box
                    style={{
                      display: 'flex', alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '70%', backgroundPosition: 'center',
                      backgroundImage: this.state.imgLicence !== null ? `url(${this.state.imgLicence})` : null, position: 'relative', marginTop: 10,
                      filter: 'drop-shadow(0px 0px 4px rgba(0, 200, 233, 0.18))', borderWidth: 1, borderStyle: 'solid', borderRadius: 16
                    }}
                    sx={{ height: { md: '250px', xs: '200px' } }}
                  >
                    {
                      this.state.imgLicence !== null ?
                        <Button style={{
                          display: 'flex', position: 'absolute', width: 64, height: 55, bottom: 0, left: 0, backgroundColor: '#00C8E9',
                          borderBottomLeftRadius: 16, borderTopRightRadius: 16
                        }} onClick={() => this.deleteImageAgancy('LI')}>
                          <Typography component={'img'} src={Trash} style={{ width: 32, height: 32 }} alt="trash"></Typography>
                        </Button>
                        :
                        <label htmlFor="button-fileLicence">
                          <Input accept="image/*" id="button-fileLicence" type="file" style={{ display: 'none' }} onChange={this.onChangeLicence} />
                          <Button style={{
                            display: 'flex', position: 'absolute', width: 64, height: 55, bottom: 0, left: 0, backgroundColor: '#00C8E9',
                            borderBottomLeftRadius: 16, borderTopRightRadius: 16
                          }} component='span'>
                            <Typography component={'img'} src={Open} style={{ width: 32, height: 32 }} alt="open"></Typography>
                          </Button>
                        </label>

                    }

                  </Box>
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}

                >
                  <Typography component={'p'} style={{ fontFamily: 'shabnam', fontSize: '16px', marginRight: '1%', marginBottom: 5, width: '70%' }}>{'عکس لوگو : '}</Typography>
                  <Box
                    style={{
                      display: 'flex', alignItems: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '70%', backgroundPosition: 'center',
                      backgroundImage: this.state.imgLogo !== null ? `url(${this.state.imgLogo})` : null, position: 'relative', marginTop: 10,
                      filter: 'drop-shadow(0px 0px 4px rgba(0, 200, 233, 0.18))', borderWidth: 1, borderStyle: 'solid', borderRadius: 16
                    }}
                    sx={{ height: { md: '250px', xs: '200px' } }}
                  >
                    {
                      this.state.imgLogo !== null ?
                        <Button style={{
                          display: 'flex', position: 'absolute', width: 64, height: 55, bottom: 0, left: 0, backgroundColor: '#00C8E9',
                          borderBottomLeftRadius: 16, borderTopRightRadius: 16
                        }} onClick={() => this.deleteImageAgancy('L')}>
                          <Typography component={'img'} src={Trash} style={{ width: 32, height: 32 }} alt="trash"></Typography>
                        </Button>
                        :
                        <label htmlFor="button-fileLogo">
                          <Input accept="image/*" id="button-fileLogo" type="file" style={{ display: 'none' }} onChange={this.onChangeLogo} />
                          <Button style={{
                            display: 'flex', position: 'absolute', width: 64, height: 55, bottom: 0, left: 0, backgroundColor: '#00C8E9',
                            borderBottomLeftRadius: 16, borderTopRightRadius: 16
                          }} component='span'>
                            <Typography component={'img'} src={Open} style={{ width: 32, height: 32 }} alt="open"></Typography>
                          </Button>
                        </label>

                    }

                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={11} style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, flexWrap: 'wrap' }}>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Button onClick={this.onClickSave}
                    style={{
                      backgroundColor: '#00C8E9', borderRadius: 8, height: '50px', color: 'black',
                      fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', marginBottom: 50
                    }}
                    sx={{ width: { sm: '50%', xs: '70%' } }}
                  >
                    {'ذخیره تغییرات'}
                  </Button>
                </Grid>
                <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                  <Button onClick={this.closeEditModal}
                    style={{
                      backgroundColor: 'red', borderRadius: 8, height: '50px', color: 'white',
                      fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', marginBottom: 50
                    }}
                    sx={{ width: { sm: '50%', xs: '70%' } }}
                  >
                    {'انصراف'}
                  </Button>
                </Grid>
                {
                  this.props.user.user_type_id === 6 ?
                    <Grid item md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', marginBottom: 5 }}>
                      <Button onClick={this.changeStatusAgancy}
                        style={{
                          backgroundColor: '#003249', borderRadius: 8, height: '50px', color: 'white',
                          fontFamily: 'shabnam', fontSize: '20px', fontWeight: 'bold', marginBottom: 50
                        }}
                        sx={{ width: { sm: '50%', xs: '70%' } }}
                      >
                        {this.state.statusAgancy ? 'غیرفعال' : 'فعال'}
                      </Button>
                    </Grid>
                    :
                    null
                }

              </Grid>
            </Grid>
          </Modal>
        </Grid>

      </Grid>
    )
  };
}

export default EditAgancy;