import { Autocomplete, Button, Checkbox, FormControl, Grid, OutlinedInput, TextField, Typography } from "@mui/material";
import React from "react";
import axios from "axios";
import { baseUrl } from "./materilas/vmp";
import AlertModal from "./materilas/alert";

class City extends React.Component{

  isMount = false;

  constructor(props){
    super(props);
    this.state={
      nameState:[],
      city:[],
      nameInput:'',
      idChoose:null,
      active:false,
      openAlert:false,
      message:'',
      citySearch:null,
      inputcity:'',
      disableCity:true,
      stateId:null,
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true;
    if (this.isMount){
      this.getState();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  getState = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/state/get',
      method: 'GET',
    }))
    .then((res) => {
        this.setState({nameState:res.data.data})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }
  
  onChangeState = (event,newValue) =>{
    this.setState({nameInput:'',idChoose:null,citySearch:null,inputcity:'',active:false,stateId:null,disableCity:true})
    if (newValue !== null){
      this.setState({stateId:newValue.id})
      this.getCity(newValue.id)
    }
  }

  getCity = (stateValue) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/city/get',
      method: 'GET',
      params:{
        state_id:stateValue
      }
    }))
    .then((res) => {
        this.setState({city:res.data.data,disableCity:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    });
  }

  onChangeCity = (event,newValue) =>{
    this.setState({nameInput:'',idChoose:null,citySearch:null,inputcity:'',active:false})
    if (newValue !== null){
      this.setState({nameInput:newValue.name,idChoose:newValue.id,active:newValue.active === 1 ? true : false,citySearch:newValue,inputcity:newValue.name})
    }
  }

  onChangeName = (event) =>{
    this.setState({nameInput:event.target.value})
  }

  onChangeActive = () =>{
    this.setState({active:!this.state.active})
  }

  onCloseAlert = () =>{
    this.setState({openAlert:false})
  }

  editCity = () =>{
    if (this.state.idChoose !== null){
      if (this.state.nameInput !== ''){
        let token = localStorage.getItem('tokenUserHomeInja');
        Promise.resolve(axios({
          url: baseUrl+'v1/city/edit',
          method: 'POST',
          headers:{'Authorization':'bearer ' + token},
          data:{
            id:this.state.idChoose,
            name:this.state.nameInput,
            active:this.state.active,
            state_id:this.state.stateId
          }
        }))
        .then((res) => {
            this.setState({nameInput:'',active:false,idChoose:null,citySearch:null,inputcity:'',openAlert:true,message:'ویرایش با موفقیت انجام شد',typeAlert:1})
            this.getCity(this.state.stateId);
        })
        .catch((err) => {
            this.setState({nameInput:'',active:false,idChoose:null,citySearch:null,inputcity:'',openAlert:true,message:err.response.data.message,typeAlert:2}) 
        });
      }
      else{
        this.setState({openAlert:true,message:'فیلد نام نباید خالی باشد',typeAlert:3})
      }
    }
    else{
      this.setState({openAlert:true,message:'موردی جهت ویرایش انتخاب نشده',typeAlert:3})
    }
  }

  saveCity = () =>{
    if (this.state.stateId !== null){
      if (this.state.nameInput !== ''){
        let token = localStorage.getItem('tokenUserHomeInja');
          Promise.resolve(axios({
            url: baseUrl+'v1/city/create',
            method: 'POST',
            headers:{'Authorization':'bearer ' + token},
            data:{
              name:this.state.nameInput,
              state_id:this.state.stateId
            }
          }))
          .then((res) => {
              this.setState({nameInput:'',active:false,idChoose:null,citySearch:null,inputcity:'',openAlert:true,message:'افزودن با موفقیت انجام شد',typeAlert:1})
              this.getCity(this.state.stateId);
          })
          .catch((err) => {
              this.setState({nameInput:'',active:false,idChoose:null,citySearch:null,inputcity:''})
              if (err.response.status === 500){
                this.setState({openAlert:true,message:err.response.data.message,typeAlert:2})
              }
              else{
                this.setState({openAlert:true,message:'نام شهر تکراری است',typeAlert:2})
              }
          });
      }
      else{
        this.setState({openAlert:true,message:'فیلد نام نباید خالی باشد',typeAlert:3})
      }
    }
    else{
      this.setState({openAlert:true,message:'لطفا استان مورد نظر جهت افزودن شهر را انتخاب نمایید',typeAlert:3})
    }
  }

  render() {

    return (
      <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',sm:'10px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',marginTop:100,marginBottom:100}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>ویرایش و افزودن شهرها</Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
          <Grid item md={6} xs={12} className="employeeAuto">
            <Autocomplete
              id="state_combo"
              className="combo_employee"
              options={this.state.nameState}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField className="textField" {...params} placeholder='استان'/>}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={this.onChangeState}
              clearText='حذف'
              closeText="بستن"
              openText="باز کردن"
              noOptionsText="فاقد اطلاعات"                              
            />
          </Grid>
          <Grid item md={6} xs={12} className="employeeAuto">
            <Autocomplete
              id="state_combo"
              className="combo_employee"
              options={this.state.city}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField className="textField" {...params} placeholder='شهر'/>}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={this.state.citySearch}
              inputValue={this.state.inputcity}
              disabled={this.state.disableCity}
              onChange={this.onChangeCity}
              clearText='حذف'
              closeText="بستن"
              openText="باز کردن"
              noOptionsText="فاقد اطلاعات"                              
            />
          </Grid>
        </Grid>
        <Grid item xs={12} 
                style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:100,borderStyle:'solid',borderRadius:20,
                        borderColor:'#01C7E9',width:'99%',flexWrap:'wrap'}}>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
            <FormControl sx={{width:{sm:'50ch',xs:'30ch'},justifyContent:'center',alignItems:'center'}}>
              <OutlinedInput placeholder='نام' inputProps={{maxLength:25}}
                style={{fontFamily:'shabnam',borderStyle:'solid',borderWidth:2}} 
                sx={{width:{xs:'80%'},height:50}}
                onChange={this.onChangeName}
                value={this.state.nameInput}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
            <Checkbox checked={this.state.active} onChange={this.onChangeActive}  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}/>
            <p style={{fontSize:'1.5em',fontWeight:'700'}}>{'فعال'}</p>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
            <Button style={{backgroundColor:'#01C7E9',height:50,width:'50%',fontFamily:'shabnam',color:'black'}} onClick={this.editCity}>
              ویرایش شهر
            </Button>
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{display:'flex',justifyContent:'center',padding:5}}>
            <Button style={{backgroundColor:'#01C7E9',height:50,width:'50%',fontFamily:'shabnam',color:'black'}} onClick={this.saveCity}>
              افزودن شهر
            </Button>
          </Grid>
        </Grid>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.onCloseAlert} type={this.state.typeAlert}/>
      </Grid>
    )
  };
}

export default City;