import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Typography } from "@mui/material";

let DefaultIcon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: icon,
    shadowUrl: iconShadow
  });
L.Marker.prototype.options.icon = DefaultIcon;

export default function ShowLocation(props){
    return(
        <MapContainer style={{height:'100%',width:'100%',...props.style}} center={props.position} zoom={15}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
            <Marker position={props.position}>
                <Popup><Typography variant="body1" style={{fontFamily:'shabnam'}}>{'ملک مورد نظر اینجاست'}</Typography> </Popup>
            </Marker>
        </MapContainer>
    )
}