import { Autocomplete, Box, Button, Checkbox, Divider, Grid, Input, OutlinedInput, TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl, checkedIcon, convertToEnglishNumber, convertToPersianNumber, icon, onlyReciveNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import Resizer from "react-image-file-resizer";
import GiftLogo from '../component/gifLogo';
import Trash from '../images/trash.png';
import Open from '../images/openFolder.png';

class CreateAgancy extends React.Component{

  isMount = false;
  imageFile = '';
  imageResize = '';
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
    super(props);
    this.state={
      partOne:true,
      partTwo:false,
      name:'',
      manager:'',
      stateOption:[],
      stateSelect:null,
      cityOption:[],
      citySelect:null,
      disableCity:true,
      zoneOption:[],
      zoneSelect:null,
      disableZone:true,
      address:'',
      phone:'',
      mobile:'',
      webSite:'',
      insta:'',
      licence:'',
      openAlert:false,
      message:'',
      giftShow:false,
      clearText:'حذف',
      closeText:"بستن",
      openText:"باز کردن",
      noOptionsText:"فاقد اطلاعات",
      agancyId:null,
      imgLicence:null,
      imgLogo:null,
      saveDisable:false,
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true;
    if (this.isMount){
      this.getState();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  onChangeName = (event) =>{
    this.setState({name:event.target.value});
  }

  onChangeManager = (event) =>{
    this.setState({manager:event.target.value});
  }

  onChangeMobile = (event) =>{
    this.setState({mobile:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  onChangePhone = (event) =>{
    this.setState({phone:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  onChangeSite = (event) =>{
    this.setState({webSite:event.target.value});
  }

  onChangeInsta = (event) =>{
    this.setState({insta:event.target.value});
  }

  onChangeLicenceNumber = (event) =>{
    this.setState({licence:convertToEnglishNumber(convertToPersianNumber(onlyReciveNumber(event.target.value)))});
  }

  onChangeAddress = (event) =>{
    this.setState({address:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  getState = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/state/get',
      method: 'GET'
    }))
    .then((res) => {
      this.setState({stateOption:res.data.data})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeState = (event,newValue) =>{
    this.setState({disableCity:true,disableZone:true,stateSelect:null,citySelect:null,zoneSelect:null});
    if (newValue !== null){
      this.setState({stateSelect:newValue});
      this.getCity(newValue.id);
    }
  }

  getCity = (stateValue) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/city/get',
      method: 'GET',
      params:{
        state_id:stateValue
      }
    }))
    .then((res) => {
        this.setState({cityOption:res.data.data,disableCity:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeCity = (event,newValue) =>{
    this.setState({disableZone:true,citySelect:null,zoneSelect:null})
    if (newValue !== null){
      this.getZone(newValue.id);
      this.setState({citySelect:newValue});
    }
  }

  getZone = (city) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/zone/get',
      method: 'GET',
      params:{
        city_id:city
      }
    }))
    .then((res) => {
        this.setState({zoneOption:res.data.data,disableZone:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'متاسفانه سامانه با مشکل مواجه شده لطفا بعدا اقدام کنید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    });
  }

  onChangeZone = (event,newValue) =>{
    this.setState({zoneSelect:null})
    if (newValue !== null){
      this.setState({zoneSelect:newValue})
    }
  }

  onClickSave = () =>{
    if (this.state.name.trim() !== ''){
      if (this.state.manager.trim() !== ''){
        if (this.state.phone !== ''){
          if (this.state.mobile !== ''){
            if (this.state.licence !== ''){
              if (this.state.stateSelect !== null){
                if (this.state.citySelect !== null){
                  if (this.state.zoneSelect !== null){
                    if (this.state.address !== ''){
                      this.setState({giftShow:true,saveDisable:true})
                      this.createAgancy();
                    }
                    else{
                      this.setState({openAlert:true,message:'لطفا آدرس را وارد کنید.',typeAlert:3})
                    }
                  }
                  else{
                    this.setState({openAlert:true,message:'لطفا منطقه را وارد کنید.',typeAlert:3})
                  }
                }
                else{
                  this.setState({openAlert:true,message:'لطفا شهر را وارد کنید.',typeAlert:3})
                }
              }
              else{
                this.setState({openAlert:true,message:'لطفا استان را وارد کنید.',typeAlert:3})
              }
            }
            else{
              this.setState({openAlert:true,message:'لطفا شماره جوازکسب آژانس را وارد کنید.',typeAlert:3})
            }
          }
          else{
            this.setState({openAlert:true,message:'لطفا شماره موبایل مدیر آژانس را وارد کنید.',typeAlert:3})
          }
        }
        else{
          this.setState({openAlert:true,message:'لطفا شماره تلفن آژانس را وارد کنید.',typeAlert:3})
        }
      }
      else{
        this.setState({openAlert:true,message:'لطفا نام مدیر آژانس را وارد کنید.',typeAlert:3})
      }
    }
    else{
      this.setState({openAlert:true,message:'لطفا نام آژانس را وارد کنید.',typeAlert:3})
    }
  }

  createAgancy = () =>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/agancy/create',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        name:this.state.name,
        manager_name:this.state.manager,
        phone:this.state.phone,
        mobile:this.state.mobile,
        instagram_link:this.state.insta !== '' ? this.state.insta : null,
        website:this.state.webSite !== '' ? this.state.webSite : null,
        state_id:this.state.stateSelect.id,
        city_id:this.state.citySelect.id,
        zone_id:this.state.zoneSelect.id,
        address:this.state.address,
        licence_number:this.state.licence
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:' اطلاعات آژانس با موفقیت ثبت گردید.',giftShow:false,name:'',manager:'',stateSelect:null,partTwo:true,
                    citySelect:null,disableCity:true,zoneSelect:null,disableZone:true,address:'',phone:'',mobile:'',webSite:'',insta:'',licence:'',
                    partOne:false,agancyId:res.data.data,saveDisable:false,typeAlert:1})
    })
    .catch(() => {
      this.setState({openAlert:true,message:'عملیات با خطا مواجه شد لطفا بعد مجدد امتحان نمایید یا با پشتیبانی تماس بگیرید.',giftShow:false,name:'',
                      manager:'',stateSelect:null,partTwo:false,citySelect:null,disableCity:true,zoneSelect:null,disableZone:true,address:'',phone:'',
                      mobile:'',webSite:'',insta:'',licence:'',partOne:true,saveDisable:false,typeAlert:2})
    });
  }

  onChangeLogo = (e) => {
    if(e.target.files[0]){
      this.imageFile = e.target.files[0] ;
      this.imageResizer('L')
    }
  }

  onChangeLicence = (e) =>{
    if(e.target.files[0]){
      this.imageFile = e.target.files[0] ;
      this.imageResizer('LI')
    }
  }

  imageResizer = (t) => {
      try {
        Resizer.imageFileResizer(
          this.imageFile,
          1000,
          500,
          "JPEG",
          70,
          0,
          (uri) => {
            this.imageResize = uri ;
            this.addImageAgancy(t);
          },
          "file",
        );
      } catch (err) {
        this.setState({openAlert:true,message:"فایل موردنظر عکس نمیباشد",typeAlert:2});
      }
  }

  addImageAgancy = (t) => {
    const formData = new FormData();
    formData.append("image", this.imageResize);
    formData.append("id", this.state.agancyId);
    formData.append("type", t);
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/agancy/addImage",
      method: "POST",
      headers: {
        "Authorization":"bearer "+ this.token,
        "Content-Type": "multipart/form-data"
      },
      data: formData
    }))
    .then((res) => {
      this.setState({openAlert:true,message:'عکس با موفقیت ذخیره شد',giftShow:false,typeAlert:1})
      this.getAgancy();
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"عکس بارگزاری نشد",giftShow:false,typeAlert:2});
    });
  } 

  deleteImageAgancy = (t) =>{
    Promise.resolve(axios({
      method:'POST',
      url:baseUrl + 'v1/agancy/deleteImage',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        id:this.state.agancyId,
        type:t
      }
    }))
    .then((res) =>{
      this.setState({openAlert:true,message:'عکس  با موفقیت حذف شد',giftShow:false,typeAlert:1})
      this.getAgancy();
    })
    .catch(() =>{
      this.setState({openAlert:true,message:"عملیات حذف ناموق بود.",giftShow:false,typeAlert:2})
    })
  }

  getAgancy = () =>{
    Promise.resolve(axios({
      method:'GET',
      url:baseUrl + 'v1/agancy/get',
      headers:{'Authorization':'bearer ' + this.token},
      params:{
        agancy_id:this.state.agancyId
      }
    }))
    .then((res) =>{
      this.setState({imgLicence:res.data.data.licence_path,imgLogo:res.data.data.logo_path,agancyId:res.data.data.id})
    })
    .catch(() =>{
      this.setState({imgLicence:null,imgLogo:null})
    })
  }

  endAgancy = () =>{
    this.setState({openAlert:true,message:'اطلاعات آژانس تکمیل کردید.',typeAlert:1,partOne:true,partTwo:false,imgLicence:null,imgLogo:null,agancyId:null})
  }

  render() {

    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>ایجاد آژانس املاک</Typography>
        </Grid>
        <Grid item xs={12} style={{display:this.state.partOne ? 'flex' : 'none',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >    
          <Grid item xs={10} style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%',flexWrap:'wrap',marginTop:50}}>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'نام : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangeName(event)} value={this.state.name}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:30}}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'مدیر آژانس : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangeManager(event)} value={this.state.manager}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:30}}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'شماره تلفن : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangePhone(event)} value={convertToPersianNumber(this.state.phone)}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:12}}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'شماره موبایل : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangeMobile(event)} value={convertToPersianNumber(this.state.mobile)}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:11}}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'آدرس وب سایت : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangeSite(event)} value={this.state.webSite}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:30}}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'لینک اینستاگرام : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangeInsta(event)} value={this.state.insta}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:30}}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'شماره جوازکسب : '}</Typography>
              <OutlinedInput variant="outlined"  
                          onChange={(event) => this.onChangeLicenceNumber(event)} value={convertToPersianNumber(this.state.licence)}
                          style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                          inputProps={{maxLength:20}}
              />
            </Grid>
            <Grid item xs={12} style={{width:'100%',marginTop:30,marginBottom:30}}>
              <Divider style={{color:"black"}}/>
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} 
                  style={{marginBottom:15,display:'flex',alignItems:'center',width:'70%',justifyContent:'center'}}
                  sx={{paddingLeft:{xs:'7%'},paddingRight:{xs:'7%'}}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete ads_autocomplete_agancy"
                fullWidth
                options={this.state.stateOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='استان'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeState}
                value={this.state.stateSelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableState}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} 
                  style={{marginBottom:15,display:'flex',alignItems:'center',width:'70%',justifyContent:'center'}}
                  sx={{paddingLeft:{xs:'7%'},paddingRight:{xs:'7%'}}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                fullWidth
                options={this.state.cityOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='شهر'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeCity}
                value={this.state.citySelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableCity}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} 
                  style={{marginBottom:15,display:'flex',alignItems:'center',width:'70%',justifyContent:'center'}}
                  sx={{paddingLeft:{xs:'7%'},paddingRight:{xs:'7%'}}}
            >
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                fullWidth
                options={this.state.zoneOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='منطقه'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeZone}
                value={this.state.zoneSelect}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
                disabled={this.state.disableZone}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'آدرس : '}</Typography>
            <OutlinedInput variant="outlined"  
                        onChange={(event) => this.onChangeAddress(event)} value={convertToPersianNumber(this.state.address)}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',overflow:'scroll'}}
                        inputProps={{maxLength:60}}
            />
          </Grid>
          <Grid item xs={12} style={{width:'100%',display:'flex',justifyContent:'center',marginTop:100}}>
            <Button onClick={this.onClickSave} disabled={this.state.saveDisable}
                    style={{backgroundColor:'#00C8E9',borderRadius:8,height:'50px',color:'black',
                            fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                    sx={{width:{sm:'50%',xs:'70%'}}}
          >
              {'ذخیره اطلاعات آژانس'}
            </Button>
          </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
        <Grid item xs={12} style={{display:this.state.partTwo ? 'flex' : 'none',alignItems:'center',marginTop:150,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >   
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',flexWrap:'wrap',width:'100%'}}>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column'}} 
                  sx={{paddingLeft:'20px',paddingRight:'20px'}}
            >
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'100%'}}>{'عکس جواز کسب : '}</Typography>
              <Box 
                style={{display:'flex',alignItems:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover',width:'100%',backgroundPosition:'center',height:450,
                        backgroundImage:this.state.imgLicence !== null ? `url(${this.state.imgLicence})` : null,position:'relative',marginTop:10,
                        filter:'drop-shadow(0px 0px 4px rgba(0, 200, 233, 0.18))',borderWidth:1,borderStyle:'solid',borderRadius:16}}
              >
                {
                  this.state.imgLicence !== null ?
                    <Button style={{display:'flex',position:'absolute',width:64,height:55,bottom:0,left:0,backgroundColor:'#00C8E9',
                                    borderBottomLeftRadius:16,borderTopRightRadius:16}} onClick={() => this.deleteImageAgancy('LI')}>
                      <Typography component={'img'} src={Trash} style={{width:32,height:32}} alt="trash"></Typography>
                    </Button>
                  :
                    <label htmlFor="button-fileLicence">
                      <Input accept="image/*" id="button-fileLicence" type="file" style={{display:'none'}} onChange={this.onChangeLicence}/>
                      <Button style={{display:'flex',position:'absolute',width:64,height:55,bottom:0,left:0,backgroundColor:'#00C8E9',
                                    borderBottomLeftRadius:16,borderTopRightRadius:16}} component='span'>
                        <Typography component={'img'} src={Open} style={{width:32,height:32}} alt="open"></Typography>
                      </Button>
                    </label>
                    
                }
                
              </Box>
            </Grid>
            <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column'}} 
                  sx={{paddingLeft:'20px',paddingRight:'20px'}}
            >
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'100%'}}>{'عکس لوگو : '}</Typography>
              <Box 
                style={{display:'flex',alignItems:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover',width:'100%',backgroundPosition:'center',height:450,
                        backgroundImage:this.state.imgLogo !== null ? `url(${this.state.imgLogo})` : null,position:'relative',marginTop:10,
                        filter:'drop-shadow(0px 0px 4px rgba(0, 200, 233, 0.18))',borderWidth:1,borderStyle:'solid',borderRadius:16}}
              >
                {
                  this.state.imgLogo !== null ?
                    <Button style={{display:'flex',position:'absolute',width:64,height:55,bottom:0,left:0,backgroundColor:'#00C8E9',
                                    borderBottomLeftRadius:16,borderTopRightRadius:16}} onClick={() => this.deleteImageAgancy('L')}>
                      <Typography component={'img'} src={Trash} style={{width:32,height:32}} alt="trash"></Typography>
                    </Button>
                  :
                    <label htmlFor="button-fileLogo">
                      <Input accept="image/*" id="button-fileLogo" type="file" style={{display:'none'}} onChange={this.onChangeLogo}/>
                      <Button style={{display:'flex',position:'absolute',width:64,height:55,bottom:0,left:0,backgroundColor:'#00C8E9',
                                    borderBottomLeftRadius:16,borderTopRightRadius:16}} component='span'>
                        <Typography component={'img'} src={Open} style={{width:32,height:32}} alt="open"></Typography>
                      </Button>
                    </label>
                    
                }
                
              </Box>
            </Grid>
          </Grid> 
          
          <Grid item xs={12} style={{width:'100%',display:'flex',justifyContent:'center',marginTop:100}}>
            <Button onClick={this.endAgancy} 
                    style={{backgroundColor:'#00C8E9',borderRadius:8,height:'50px',color:'black',
                            fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}
                    sx={{width:{sm:'50%',xs:'70%'}}}
            >
              {'ثبت آژانس'}
            </Button>
          </Grid>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
      </Grid>
    )
  };
}

export default CreateAgancy;