import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article37/01.jpg";
import photo02 from "../../images/mag/article37/02.jpg";
import photo03 from "../../images/mag/article37/03.jpg";
import photo04 from "../../images/mag/article37/04.jpg";
import photo05 from "../../images/mag/article37/05.jpg";
import photo06 from "../../images/mag/article37/06.jpg";
import photo07 from "../../images/mag/article37/07.jpg";
import photo08 from "../../images/mag/article37/08.jpg";
import photo09 from "../../images/mag/article37/09.jpg";
import photo10 from "../../images/mag/article37/10.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article37 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "شاید خیلی از شما قصد خرید یا اجاره ملک در محله نیاوران تهران را داشته باشید اما آشنایی زیادی با این منطقه نداشته باشید.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "آشنایی با محله نیاوران تهران ، صفر تا صد محله نیاوران تهران ، محله لوکس نیاوران تهران ، امکانات محله نیاوران تهران ، همه چیز درباره نیاوران تهران ، نقشه و تصاویر نیاوران تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[36].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[36].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[36].access} />
        <Grid container sx={{ maxWidth: "1600px", zIndex: "200" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[36].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[36].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[36].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="محله نیاوران تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[36].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید خیلی از شما قصد خرید یا اجاره ملک در  <Link className="link" to="/niavaran-tehran" target="_blank"> محله نیاوران </Link> تهران را داشته باشید اما آشنایی زیادی با این منطقه نداشته باشید. ما در  <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link> سعی کردیم تا حدودی شما را با این محله آشنا کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از بلاگ هومینجا قصد داریم شما را با تمام جنبه های جفرافیایی و اجتماعی محله نیاوران تهران آشنا کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خواندن این مقاله کمک شایانی به افرادی که قصد <Link className="link" to="/Listings/buy_tehran_niyavaran" target="_blank"> خرید و فروش </Link> و یا <Link className="link" to="/Listings/rent_tehran_niyavaran" target="_blank"> رهن و اجاره ملک </Link>، اپارتمان و خانه در محله ی نیاوران تهران را دارند، خواهد کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Piroozi-street" target="_blank">
                  بیشتر بخوانید : معرفی محله پیروزی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نیاوران تهران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="نیاوران قدیم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نیاوران قدیم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ارتباط با نام گذاری نیاوران روایات متعددی وجود دارد. برخی معتقدند محله نیاوران تهران در گذشته پر از نیزارهایی بوده که مردم برای جمع آوری به این منطقه می رفتند. به همین دلیل این منطقه به صورت عامیانه به "نی آوران" معروف بوده است. با مرور زمان این محله به نیاوران تغییر نام پیدا کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برخی از ساکنین قدیمی هم اذعان دارند که به دلیل تعداد فراوان درختان انار موجود در منطقه، آن را "نارون" نامیدند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                عده ی اندکی هم بر این باورند که "نیا" به معنی عظمت و بزرگی، "ور" به معنی صاحب و مالک آن می باشد پس نیاوران به معنی صاحب عظمت است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                البته محله نیاوران تهران یکی از لوکس ترین <Link className="link" to="/tehran-zones" target="_blank"> مناطق شهر تهران </Link> محسوب میشود. وجود بافت شیک و مدرن همراه با باغهای قدیمی و درختان کهن، سبب شد نیاوران به یکی از مطبوع ترین محله های <Link className="link" to="/tehran-zone-1" target="_blank"> منطقه یک تهران </Link> تبدیل شود.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه محله نیاوران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کاخ صاحبقرانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاخ صاحبقرانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بافت حال حاضر نیاوران تهران را می‌توان سلیقه‌ی ناصرالدین شاه دانست. ناصرالدین شاه با خرید زمین‌ها و ساختمان‌های اطراف از مالکان آن، همه را تخریب و پایه‌های این محله ایان نشین را بنا نهاد. وجود کاخ <Link className="link" to="/Listings/rent_tehran_dahebgharanie" target="_blank"> صاحبقرانیه </Link>، کوشک احمد شاهی، بنای کوچک محمد شاه و  کاخ نیاوران ، نشان دهنده ی علاقه ی خاص قجرها و پهلوی ها به محله ی نیاوران تهران است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فتحعلی شاه قاجار اولین حکمرانی بود که در این منطقه باغ ییلاقی ایجاد کرد و پس از او نوبت به محمد شاه قاجار رسید. محمد شاه قاجار نیز این منطقه را ، منطقه ای خوش آب و هوا یافت و اقدام به ساختن خانه ای در باغ نیاوران کرد. خانه محمد شاه قاجار را نمی توان <Link className="link" to="/real-estate-interesting-things" target="_blank"> کاخ </Link> و یا عمارت نام برد و در حال حاضر نیز آثاری از آن در دست نیست ، اما می توان حدس زد که خانه محمد شاه، عمارتی بود که در قواره بناها و کاخ های ماندگار قاجار نبود. عمارت هایی که در زمان فتحعلی شاه قاجار و محمد شاه بنا شدند، در زمان ساخت کاخ نیاوران در زمان محمد رضا شاه تخریب شدند. امروزه آثاری از این بناها باقی نمانده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آن زمان است که نیاوران منطقه ای شناخته شده در <Link className="link" to="/north-of-Tehran-cafe" target="_blank"> شمال شهر تهران </Link> به حساب می آید. از آن زمان به بعد شاهان قاجار مانند ناصرالدین‌شاه قاجار «کاخ صاحبقرانیه» را در این محله ساخت. پس از آن ها شاهان پهلوی قدم به نیاوران گذاشتند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Hakimiyeh" target="_blank">
                  بیشتر بخوانید : معرفی محله حکیمیه تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نیاوران تهران کجاست ؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="نیاوران تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خیابان نیاوران تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%86%DB%8C%D8%A7%D9%88%D8%B1%D8%A7%D9%86#:~:text=%D9%86%DB%8C%D8%A7%D9%88%D8%B1%D8%A7%D9%86%D8%8C%20%DB%8C%DA%A9%DB%8C%20%D8%A7%D8%B2%20%D9%85%D8%AD%D9%84%D9%87%20%D9%87%D8%A7%D9%89,%DA%A9%D8%A7%D9%85%D8%B1%D8%A7%D9%86%DB%8C%D9%87%20%D9%88%20%D8%AF%D8%B2%D8%A7%D8%B4%DB%8C%D8%A8%20%D9%87%D9%85%E2%80%8C%D9%85%D8%B1%D8%B2%20%D8%A7%D8%B3%D8%AA." target="_blank"> نیاوران </LinkMui> در منطقه یک شهرداری تهران قرار دارد و به عنوان یکی از شمالی ترین محله های تهران محسوب می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همجواری با کوههای کلکچال و <Link className="link" to="/Listings/buy_tehran_darband" target="_blank"> دربند </Link>، همچنین وجود تراکم بالای درختان باعث ایجاد هوای مطبوع و خوشایند در این منطقه شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله نیاوران از شمال به رشته کوههای البرز، از غرب به <Link className="link" to="/Listings/rent_tehran_tajrish" target="_blank"> تجریش </Link>، از شرق به <Link className="link" to="/Listings/buy_tehran_darabad" target="_blank"> داراباد </Link> و از جنوب به <Link className="link" to="/Listings/buy_tehran_kamranieh" target="_blank">کامرانیه  </Link> و <Link className="link" to="/Listings/buy_tehran_dezashib" target="_blank"> دزاشیب </Link> منتهی می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیابان شهید <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%85%D8%AD%D9%85%D8%AF%D8%AC%D9%88%D8%A7%D8%AF_%D8%A8%D8%A7%D9%87%D9%86%D8%B1" target="_blank"> دکتر محمد جواد باهنر </LinkMui> (نیاوران)، خیابان اصلی محله محسوب می شود که میدان نیاوران را به میدان قدس و ابتدای خیابان شریعتی وصل میکند.
              </Typography>
              <Typography className="h2" variant="h2">
                معرفی اماکن مهم نیاوران تهران :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همان طور که پیش تر نیز بیان شد، محله ی نیاوران تهران به دلیل قدمت، دارای بناهای تاریخی متعددی می باشد. همچنین زندگی مرفه ترین بخش افراد جامعه در این محله باعث ساخت بسیار زیاد مکانهای تجاری، ورزشی، درمانی و تفریحی شده است که در ذیل مهم ترین آنها معرفی خواهد شد.
              </Typography>
              <Typography className="h3" variant="h3">
                اماکن تاریخی فرهنگی نیاوران تهران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="موزه جهان نما - هومینجا" className="image" />
                <figcaption className="image_caption">
                  موزه جهان نما کاخ نیاوران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله نیاوران تهران به دلیل قرارگیری کاخ ها و مکانهای فرهنگی متعدد میزبان گردشگران زیادی از نقاط مختلف تهران می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی کامل مناطق تهران به همراه عکس و نقشه
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله مکان های تاریخی نیاوران می توان به <LinkMui className="link" href="https://niavaranmu.ir/" target="_blank">  مجموعه فرهنگی تاریخی نیاوران </LinkMui>، موزه جهان‌نما، کاخ صاحبقرانیه، کوشک احمدشاهی، کاخ اختصاصی نیاوران، باغ کتیبه‌ها، موزه خودروهای اختصاصی کاخ نیاوران و موزه کتابخانه سلطنتی اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                اماکن تجاری محله نیاوران تهران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="اطلس مال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مجتمع تجاری اطلس مال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به دلیل تقاضای بالا در این منطقه و هم چنین عدم مشکل مالی اهالی، روز به روز بر تعداد مجتمع ها و مراکز تجاری و خرید افزوده می شود. به صورتی که بیشترین و لوکس ترین پاساژهای تهران در منطقه یک و محله های اطراف نیاوران قرار دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اطلس مال، مجتمع روشا، مجتمع نارون، مجتمع کیمیا، <Link className="link" to="/Listing/773" target="_blank"> مجتمع اداری تجاری نیاوران </Link> از مراکز تجاری محله نیاوران می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                طبیعت گردی و پارک های نیاوران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="پارک نیاوران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بوستان نیاوران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود رشته کوههای البرز در شمال و تراکم درختان، نیاوران را به یک باغ بزرگ تبدیل کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این پوشش علاوه بر ایجاد هوای خنک و مطبوع تر از بقیه نقاط تهران، باعث جذب خانواده ها برای <Link className="link" to="/tehran-winter-entertaiments" target="_blank"> خوش گذرانی </Link> و تفریح شده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Shemiran-No" target="_blank">
                  بیشتر بخوانید : معرفی محله شمیران نو تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پارک بزرگ نیاوران، مجموعه فرهنگی تفریحی شهید باهنر، پارک <Link className="link" to="/Listing/1002" target="_blank"> جمشیدیه </Link>، پارک مهر <Link className="link" to="/Listing/1038" target="_blank"> عمار </Link>، دربند، بوستان روشن، بوستان حصارک نیز از بوستان های نیاوران تهران می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وزارت راه و شهرسازی در حال حاضر بستری را در فضای وب و اینترنت راه اندازی کرده است. بنابراین متقاضیان این طرح این امکان را دارند که به راحت‌ترین شکل ممکن فرآیند و مراحل ثبت نام خود را نهایی کنند. همچنین می توانند پیگیری‌های مورد نیاز در خصوص نتایج نهضت مسکن را انجام دهند.
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز بهداشتی و درمانی نیاوران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="بیمارستان فرهنگیان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بیمارستان فرهنگیان باهنر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                منطقه یک تهران دارای مجهزترین و به روزترین بیمارستان ها و مراکز درمانی در سطح شهر می باشد که تعدادی از آنها را نام می بریم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بیمارستان فرهنگیان باهنر، بیمارستان نور افشار، درمانگاه حصار بوعلی، بیمارستان قلب <Link className="link" to="/Listing/655" target="_blank"> جماران </Link> (بقیه الله جماران)، درمانگاه شهرداری مراکز درمانی نیاوران هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                خیابان های معروف و لوکس نیاوران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="جمشیدیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خیابان جمشیدیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نیاوران هم مانند بقیه مناطق تهران بخش های خوب و بخش های متوسط است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بخش هایی از امامزاده قاسم یا قسمت های قدیمی جماران به علت بافت قدیمی و تراکم بالای جمعیت از ارزش کمتری برخوردار هستند. در ذیل بهترین خیابان های محله نیاوران را جهت زندگی معرفی میکنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/Listing/1002" target="_blank"> خیابان جمشیدیه </Link>، خیابان یاسر، <Link className="link" to="/Listing/1038" target="_blank"> خیابان عمار </Link>، <Link className="link" to="/Listing/18" target="_blank"> خیابان بوکان </Link>، <Link className="link" to="/Listing/771" target="_blank"> خیابان مرجان </Link>، <Link className="link" to="/Listing/587" target="_blank"> خیابان نخجوان </Link>، خیابان مقدسی (مژده)،
              </Typography>
              <Typography className="h2" variant="h2">
                خرید و فروش خانه، آپارتمان و ملک در نیاوران :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="نیاوران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایت املاک هومینجا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با مراجعه به آگهی های <Link className="link" to="/Listings/buy_tehran_niyavaran" target="_blank"> خرید و فروش آپارتمان های مسکونی نیاوران </Link> در سایت تخصصی املاک <Link className="link" to="/" target="_blank"> هومینجا </Link> متوجه خواهید شد که حداقل قیمت هر متر مربع آپارتمان (بررسی آذر 1401) در این محله 100 میلیون تومان است که برای بعضی از برجهای لوکس تا متری 250 میلیون هم می رسد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به دلیل ارتفاع 1800 متری محله ی نیاوران قیمت پنت هاوس از  خیابان <Link className="link" to="/Listings/buy_tehran_fereshte" target="_blank"> فرشته </Link> و محله <Link className="link" to="/Listings/buy_tehran_saadatabad" target="_blank"> سعادت آباد </Link> بالاتر است.
              </Typography>
              <Typography className="h2" variant="h2">
                رهن و اجاره خانه، آپارتمان و ملک در نیاوران :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به هزینه های بالای ملک در محله نیاوران تهران، رهن و اجاره آپارتمان مورد توجه بسیاری از مرفه نشینان است.
                شما میتوانید با ثبت درخواست ملک در سایت هومینجا در کوتاه ترین زمان به مناسب ترین ملک دست پیدا کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Azadi" target="_blank">
                  بیشتر بخوانید : معرفی محله آزادی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نتیجه گیری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امکانات، دسترسی و موقعیت مناسب محله نیاوران و همچنین بافت خوب این منطقه یک امتیاز ارزشمند برای  خانه‌دار شدن در این خیابان می باشد. بنابراین می توان نتیجه گرفت نیاوران یکی از محله های خوب تهران برای سکونت می باشد. چنانچه قصد خرید یا اجاره خانه یا آپارتمان را در این منطقه داشتید می توانید از مشاوران هومینجا کمک بگیرید تا با راهنمایی حرفه ای این مشاوران  بهترین و مناسب ترین ملک را در نیاوران پیدا کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین <Link className="link" to="/best-restaurant-tehran-region1" target="_blank"> رستوران ها </Link> و <Link className="link" to="/north-of-Tehran-cafe" target="_blank"> کافه های بسیار زیبا </Link> و دنج با خدمات عالی در محله ی نیاوران تهران به وفور یافت می شود. پس برای آشنایی بیشتر شما را به مطالعه <Link className="link" to="/HomeMag" target="_blank"> مقالات هومینجا </Link> دعوت میکنم.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_aghdasieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در اقدسیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_aghdasieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در اقدسیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_tajrish'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در تجریش
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_tajrish'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در تجریش
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{
                bgcolor: "#eee", textAlign: "center", maxHeight: { xs: this.state.openLinks ? "5600px" : "0px", md: this.state.openLinks ? "1850px" : "0px" }
                , transition: "max-height 0.6s linear", overflow: "hidden"
              }}
              >
                <Link to={'/Listings/buy_tehran_jamaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در جماران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_amar'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در عمار
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_jamaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در جماران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک آپارتمان تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک آپارتمان تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_jamshideh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در جمشیدیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_jamshideh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در جمشیدیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_amar'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در عمار
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>

                <Link to={'/Listings/buy_tehran_moghadasardabili'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک  آپارتمان در مقدس اردبیلی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_moghadasardabili'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک  آپارتمان در مقدس اردبیلی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>

              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article37;