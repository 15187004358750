import { Button, Grid, Pagination, Stack, Typography, TextField } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import backgroundImageCover from "../images/mag/background.jpg";
import { convertToPersianNumber } from "./materilas/vmp";
import { article } from "./mag/articles";
import { setCanonicalTag } from "./materilas/vmp";
import SearchIcon from '@mui/icons-material/Search';
import "../styles/textFiled.css";

class Magazine extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      articleInfo: null,
      search: "",
      articleLength: 0
    }
  }

  componentDidMount() {
    document.title = "بهترین مقالات املاک | هومینجا"
    document.getElementById("main-heading").innerHTML = "مجله اینترنتی املاک و مستغلات هومینجا"
    document.getElementsByTagName('meta')["description"].content = "جدیدترین مقالات حوزه املاک و مناسب ترین محتوای تولید شده در حوزه های معاملات ملک، آموزش مشاورین املاک، مسائل حقوقی ملک، دانستی های املاک، معرفی مناطق و محله های تهران، فناوری و تکنولوژی های صنعت ساختمان را در هوم مگ مطالعه نمایید."
    document.getElementsByTagName('meta')["keywords"].content = "آموزش املاک | مطالب مفید حوزه املاک | ارائه کننده برترین مقالات املاک و مستغلات | بهترین محتوا در حوزه املاک | مقالات تخصصی خانه و مسکن | مقالات آموزشی مناطق و محلات تهران | مجله تخصصی املاک و مستغلات | موضوعات مربوط به املاک | آموزش مشاور املاک | دانستنی های ملکی و حقوقی | جدیدترین مطالب و مقالات ملکی | لیست مقالات هومینجا | مقالات بهترین سایت جستجوی ملک "
    let articleTag = localStorage.getItem("articleTag");
    if (articleTag) {
      let result = [];
      for (let i = 0; i < article.length; i++) {
        if (article[i].tags.includes(articleTag)) {
          result.push(article[i]);
        }
      }
      this.setState({ articleInfo: result });
    } else {
      let result = [...article];
      result.reverse();
      this.setState({ articleInfo: result });
    }
    setCanonicalTag();

  }
  componentWillUnmount() {
    localStorage.removeItem("articleTag");
  }

  searchHandler = (e) => {
    this.setState({ search: e.target.value })
    let list = []
    if (e.target.value !== "") {
      article.forEach((item) => {
        if ((item.title.includes(e.target.value)) || (item.desc.includes(e.target.value)) || (item.h1.includes(e.target.value))) list.push(item)
      })
    }
    else {
      list = article
    }
    this.setState({ articleInfo: list })
  }

  createArticle = () => {
    let result = [];
    let atricleData = this.state.articleInfo.slice((this.state.page - 1) * 10, this.state.page * 10);
    atricleData.forEach((element, index) => {
      result.push(
        <Grid container key={index}
          sx={{
            bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", "&:hover": { boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.3)" },
            transition: "all 0.3s", margin: "10px 0"
          }}
        >
          <Grid item xs={12} md={4}
            sx={{
              width: "100%", minHeight: { xs: "200px", md: "300px" }, background: `url(${element.image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat",
              backgroundSize: "cover", borderRadius: "0 8px 8px 0"
            }}
          >
          </Grid>
          <Grid item xs={12} md={8} sx={{ padding: { xs: "5px", md: "10px 40px" }, textAlign: { xs: "center", md: "right" } }}>
            <Grid sx={{ display: "flex", justifyContent: "space-between", borderBottom: "2px solid #eee", padding: { xs: "5px 0", md: "10px 0" } }}>
              <Grid>
                <Typography sx={{ fontFamily: "shabnam", color: "gray", display: "inline-block", fontSize: { xs: "11px", md: "16px" } }}>{"نویسنده :"}</Typography>
                <Typography sx={{ fontFamily: "shabnam", color: "gray", display: "inline-block", marginRight: "5px", fontSize: { xs: "11px", md: "16px" } }}>
                  {element.author}
                </Typography>
              </Grid>
              <Grid>
                <Typography sx={{ fontFamily: "shabnam", color: "gray", display: "inline-block", fontSize: { xs: "11px", md: "16px" } }}>{"تاریخ :"}</Typography>
                <Typography sx={{ fontFamily: "shabnam", color: "gray", display: "inline-block", marginRight: "5px", fontSize: { xs: "11px", md: "16px" } }}>
                  {convertToPersianNumber(element.date)}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Typography variant="h2" sx={{ fontFamily: "shabnam", fontSize: { xs: "20px", md: "25px" }, fontWeight: "bold", color: "black", padding: { xs: "10px 0 5px 0", md: "50px 0 30px 0" } }}>
                {convertToPersianNumber(element.h1)}
              </Typography>
              <Typography variant="h3" sx={{ fontFamily: "shabnam", fontSize: { xs: "15px", md: "16px" }, fontWeight: "normal", color: "black", padding: "10px 0" }}>
                {convertToPersianNumber(element.desc)}
              </Typography>
            </Grid>
            <Grid sx={{ textAlign: { xs: "center", sm: "left" }, margin: "5px" }}>
              <Button component={Link} to={element.link} target="_blank"
                sx={{ backgroundColor: "#FFFF !important", color: "#676a79", borderRadius: "15px", border: "1px solid #14C9E8 ", "&:hover": { backgroundColor: "#14C9E8 !important", color: "white" } }}>
                <Typography component={"p"} variant={"p"} sx={{ fontSize: "12px", fontFamily: "shabnam" }}>ادامه مطلب</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    })
    return result;
  }

  onChangePagination = (event, value) => {
    this.setState({ page: value });
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" } }}>
        <Grid
          sx={{
            width: "100%", height: { xs: "200px", md: "300px" }, backgroundImage: `url(${backgroundImageCover})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover",
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
          }}
        >
          <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "35px", fontWeight: "bold" }}>
            {"هوم‌مگ"}
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", color: "white", fontSize: "25px" }}>
            {"مقالات هومینجا"}
          </Typography>
        </Grid>
        <Grid>

        </Grid>
        <Grid>
          <Grid sx={{ bgcolor: "#E7F0F3", width: "100%", padding: { xs: "10px 10px", md: "10px 50px", xl: "10px 250px" }, minHeight: "calc(100vh - 288px)" }}>
            <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", padding: { xs: "20px 0", md: "40px 0" } }}>
              <TextField
                placeholder="جستجو"
                value={this.state.search}
                onChange={(e) => this.searchHandler(e)}
                className="text_Field"
                variant='outlined'
                InputLabelProps={{ sx: { fontSize: { xs: "12px", md: "16px" } } }}
                sx={{
                  width: { xs: "264px", sm: "310px", md: "621px" }, background: "#FCFCFC", border: "1px solid #01A7C4", borderRadius: "100px",
                  "& fieldset": { border: 'none' }, padding: "0 20px"
                }}
                InputProps={{
                  endAdornment: <SearchIcon sx={{ color: "#01A7C4" }} />,
                }}
              />
            </Grid>
            {this.state.articleInfo ?
              <>
                {this.createArticle()}
                <Grid sx={{ width: "100%", display: "flex", justifyContent: "center", padding: "20px 0" }}>
                  {this.state.articleInfo.length > 10 ?
                    <Stack spacing={2} sx={{ direction: "ltr" }}>
                      <Pagination
                        count={Math.ceil(this.state.articleInfo.length / 10)}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        onChange={this.onChangePagination}
                      />
                    </Stack>
                    : <Grid></Grid>
                  }
                </Grid>
              </>
              : <Grid></Grid>
            }
          </Grid>
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography component={"p"} >
            منظور از سامانه معاملات املاک هومینجا پلتفرم درج و جستجوی آگهی ملک هومینجا و وب سایت املاک و مستغلات هومینجا می باشد. از جمله خدمات وب سایت تخصصی و پلتفرم املاک و مستغلات هومینجا می توان به درخواست ملک، عقد قرارداد ملکی، خرید و فروش انواع ملک، آپارتمان، خانه ویلایی، برج، مسکن، اداری و تجاری، پنت هاوس اشاره کرد. همچنین رهن و اجاره انواع ملک، آپارتمان، خانه ویلایی، برج، مسکن، اداری و تجاری، پنت هاوس از دیگر خدمات هومینجا می باشد. از دیگر بخش های هومینجا می توان هوم مگ  را نام برد. هوم مگ  به عنوان وبلاگ و صفحه آموزشی، ناشر بهترین و مفیدترین مقالات و مطالب آموزنده در حوزه املاک و مستغلات در ایران است. در هومینجا مگ سعی بر این شده است که مطالب آموزشی مفیدی در اختیار خوانندگان و کاربران قرار گیرد. از جمله دسته بندی مطالب هوم مگ می توان به معرفی مناطق تهران، محله گردی، مسائل حقوقی املاک و آپارتمان اشاره کرد. سبک زندگی، آموزش مشاورین املاک و معماری و دکوراسیون منزل از دیگر دسته بندی های هوم مگ می باشد.
          </Typography>
          <Typography component={"p"}  >
            اگر بدنبال خواندن مطلبی در رابطه با سبک زندگی هستید، پیشنهاد ما به شما مقاله ی نکاتی در مورد نگهداری گیاهان آپارتمانی می باشد. از دیگر مطالب مرتبط با این دسته بندی می توان انتخاب لوستر مناسب در دکوراسیون داخلی منزل و فنگ شویی دانست.
            در حوزه مسائل حقوقی املاک می توان مقالات نکات ضروری پیش خرید و پیش فروش آپارتمان، نهضت ملی مسکن و مسکن مهر و یا مقاله جذاب کد رهگیری املاک را نام برد.
          </Typography>
          <Typography component={"p"} >
            برای کسانیکه بدنبال ملک در یک منطقه خاص هستند ولی اطلاعاتی در رابطه با ان منطقه و محله ندارند مقالات محله گردی را پیشنهاد می دهیم. این مقالات با تاریخچه منطقه شروع می شود و در ادامه به بررسی تمامی امکانات منطقه و راه های دسترسی به آن محله می پردازد.
            چنانچه شما قصد دارید بعنوان مشاور املاک در این زمینه فعالیت کنید، هومینجا به ارائه مطالبی میپردازد که شما را در این زمینه راهنمایی میکند.
          </Typography>
          <Typography component={"p"} >
            همچنین هوم مگ برای کسانیکه بدنبال ایده های جذاب برای طراحی و دکوراسیون منزل می باشند نیز راهکارهایی را ارائه داده است.
            از معرفی انواع نما، کف تا طراحی منزل و هر نوع مطلب در مورد املاک توسط تیم محتوای هومینجا نگارش و منتشر شده است.
            معرفی بهترین و معتبرترین آژانس های املاک هم جزو خدمات رایگان هومینجا می باشد.
            از دیگر بخش های جذاب هوم مگ بخش کافه گردی مقالات می باشد. این مقالات به معرفی کافه ها و رستوران های جذاب در تهران پرداخته است.
          </Typography>
        </Grid>
      </Grid>
    )
  };
}

export default Magazine;