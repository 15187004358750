import SideMenu from "./sildeMenu";
import React from "react";
import { setCanonicalTag } from "./materilas/vmp";


class Panel extends React.Component{

    isMount = false;

    dataBackupSide = [
        {
            s:'آگهی ها',
            d:['آگهی های جدید','جستجو آگهی','گزارش شده'],
            a:['newlistings','searchlisting','reports']
        },
        {
            s:'کاربران',
            d:['کاربران جدید','جستجو کاربر','درخواست تبدیل','درخواست ملکها','درخواستهای VIP','درخواست بازدیدها','درخواست قراردها','لغو بازدیدها'],
            a:['newuser','searchuser','convertlist','requesthomelist','requestvip','visitrequest','contractrequest','cancelvisit']
        },
        {
            s:'تاریخچه',
            d:['خریدها','واریزها','برداشت ها'],
            a:['buy','deposit','withdraw']
        },
        {
            s:'آژانس املاک',
            d:['ایجاد','جستجو'],
            a:['createagancy','editagancy']
        },
        {
            s:'تیکت ها',
            d:['تیکت ها','ارسال تیکت'],
            a:['tickets','sendticket']
        },
        
        {
            s:'اطلاعات پایه',
            d:['استان','شهر','منطقه','محله','اسناد','نوع ملک','نوع معامله'],
            a:['state','city','zone','neighbourhood','doucument','estate','transaction']
        },
    ];

    dataAdminSide = [
        {
            s:'آگهی ها',
            d:['جستجو آگهی','گزارش شده'],
            a:['searchlisting','reports']
        },
        {
            s:'کاربران',
            d:['جستجو کاربر','درخواست تبدیل','درخواست قراردها','افزایش موجودی','تعریف پشتیبان','برداشت ها'],
            a:['searchuser','convertlist','contractrequest','addinventory','definesuport','withdrawadmin']
        },
        {
            s:'تاریخچه',
            d:['خریدها','واریزها','برداشت ها'],
            a:['buy','deposit','withdraw']
        },
        {
            s:'آژانس املاک',
            d:['ایجاد','جستجو','آمار آگهی ها'],
            a:['createagancy','editagancy','numberagency']
        },
        {
            s:'اطلاعات پایه',
            d:['استان','شهر','منطقه','محله','اسناد','نوع ملک','نوع معامله'],
            a:['state','city','zone','neighbourhood','doucument','estate','transaction']
        },
    ];

    dataUserSide = [
        {
            s:'پروفایل',
            d:['اطلاعات کاربری','ویرایش اطلاعات','تبدیل کاربری','ثبت درخواست ملک',' درخواستهای ملک من','بازدیدهای من','درخواست قرارداد','موجودی','برداشت'],
            a:['infouser','edituser','requsetconvert','requesthome','requesthomelist','visituser','requestcontract','inventory','withdrawuser']
        },
        {
            s:'آگهی ها',
            d:['ثبت آگهی','آگهی های من','بازدید آگهی های من','درخواستهای VIP','نشان شده ها'],
            a:['createlisting','listinguser','visitlitinguser','requestvip','marks']
        },
        {
            s:'تاریخچه',
            d:['خریدها','واریزها','برداشت ها'],
            a:['buy','deposit','withdraw']
        },
        {
            s:'تیکت ها',
            d:['تیکت ها','ارسال تیکت'],
            a:['tickets','sendticket']
        },
    ];

    constructor(props){
        super(props);
        this.state={
            type:'',
            dataSide:[],
            page:props.page !== undefined ? props.page : null
        }

    }

    componentDidMount(){
        this.isMount = true;
        document.title = "پنل کاربران پلتفرم خرید و فروش ، رهن و اجاره املاک | هومینجا"
        if (this.isMount){
            if (this.props.user){
                if (this.props.user.user_type_id === 1 || this.props.user.user_type_id === 2 || this.props.user.user_type_id === 3 || this.props.user.user_type_id === 4){
                    this.setState({type:'U',dataSide:this.dataUserSide,page:'infouser'})
                }
                else{
                    if (this.props.user.user_type_id === 5){
                        this.setState({type:'B',dataSide:this.dataBackupSide})
                    }
                    else{
                        if (this.props.user.user_type_id === 6){
                            this.setState({type:'A',dataSide:this.dataAdminSide})
                        }
                        else{
                            window.location.pathname="/"
                        }
                    }
                }
            }
            else{
                window.location.pathname="/"
            }
            setCanonicalTag();
        }
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    render(){
        return(
            <SideMenu type={this.state.type} data={this.state.dataSide} user={this.props.user} page={this.state.page}/>               
        )
    }
}

export default Panel;