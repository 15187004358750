import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article13/01.jpg";
import photo02 from "../../images/mag/article13/02.jpg";
import photo03 from "../../images/mag/article13/03.jpg";
import photo04 from "../../images/mag/article13/04.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import SideBar from "./SideBar";

class Article13 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "زمانیکه یک دیوار نسبت به بقیه دیوارهای اتاق متفاوت است اکسنت وال گفته می شود. با رنگ آمیزی یا کاغذ دیواری می توان دیوار را برجسته کرد.";
    document.getElementsByTagName('meta')["keywords"].content = "کاربردهای دیوار شاخص در طراحی داخلی | بایدها و نبایدهای استفاده از اکسنت وال | متریال مورد استفاده در دیوار شاخص | دیوار شاخص چیست | همه چیز در مورد دیوار شاخص"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[12].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[12].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[12].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[12].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[12].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[12].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="اکسنت وال چیست؟ - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[12].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در معماری قدیم خیلی اکسنت وال (Accent-Wall) مرسوم نبود اما در معماری عصر جدید دیوار شاخص به عنوان یکی از مهم ترین و کاربردی ترین بخش طراحی و تعییر <Link className="link" to="/small-house-decoration" target="_blank"> دکوراسیون </Link> نام می برند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اینکه دیوار شاخص چیست؟ چرا چنین نامی برای آن در نظر گرفته شده؟ انواع مختلف دیوار شاخص کدام است؟ و سوالات دیگری که ممکن است به ذهن هر یک از شما برسد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مطلب از <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link> سعی کردیم به جواب تک تک
                این سوالات بپردازیم. همچنین پیشنهاداتی را نیز برای اجرای اکسنت وال به شما ارائه می دهیم. پس با هومینجا بلاگ همراه باشید تا اطلاعاتی کافی در این زمینه بیابید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از راهکارهای موثر و عالی برای عوض کردن دکوراسیون خانه استفاده از دیوار شاخص می باشد. اکسنت وال به این معناست که ما دیواری داریم که از لحاظ
                ظاهری متفاوت از دیگر دیوارهای خانه می باشد. این جداسازی  باید به گونه ای باشد که بر زیبایی خانه بیفزاید نه اینکه فضای خانه را جدا کند. با رنگ آمیزی یا
                کاغذ دیواری یا حتی نصب تابلو می توان دیوار مورد نظر را برجسته کرد. بدین ترتیب دیوار مورد نظر بیشتر به چشم می آید. اکسنت وال با دیگر دیوارهای خانه متفاوت است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مطلب از هومینجا به معرفی اکسنت وال پرداخته ایم.
                همچنین در این مقاله از بلاگ هومینجا به بایدها و نبایدهای استفاده از دیوار شاخص و همچنین کاربردها و متریال بکار رفته در اکسنت وال می پردازیم.
              </Typography>
              <Typography className="h2" variant="h2">
                دیوار شاخص به چه معناست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="دیوار شاخص - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اکسنت وال یا دیوار شاخص
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمانیکه یک دیوار نسبت به بقیه دیوارهای اتاق متفاوت است دیوار شاخص گفته می شود.
                این تفاوت می تواند گاهی تنها از رنگ اکسنت وال نسبت به بقیه دیوارها باشد. اما گاهی می تواند تغییرات چشمگیرتری داشته باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Spring-Cleaning" target="_blank">
                  بیشتر بخوانید : خانه تکانی عید را چطور و از کجا شروع کنیم ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                چگونه اکسنت وال ایجاد کنیم؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای دیوار شاخص
                می توانید از رنگ های جیغ و تند استفاده کنید. اما انتخاب این رنگ ها باید به گونه ای باشد که هماهنگی کامل با رنگ و دکوراسیون دیوارها و قسمت های <Link className="link" to="/children-room-decoration" target="_blank"> اتاق </Link>
                داشته باشد. می توانید از روش های خاصی برای شاخص کردن دیوار استفاده کنید. مثل راه راه کردن دیوار یا استفاده از رنگ های متناسب و براق برای اکسنت وال.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین می توانید از کاشی و سنگ کاری
                به جای رنگ آمیزی برای برجسته کردن دیوار خانه استفاده کنید. نصب تابلوی نقاشی با عکس نیز یکی دیگر از راه های داشتن دیوار شاخص می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همیشه نیاز نیست برای دیوار شاخص
                از رنگ های خیره کننده مثل آبی و قرمز استفاده کنید. زمانیکه رنگ آمیزی اتاق خنثی باشد رنگ قهوه ای می تواند انتخاب مناسبی برای اکسنت وال باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دیوار شاخص تنها به رنگ نقاشی دیوار محدود نمی شود. قفسه و طاقچه روی
                دیوار نیز می تواند حکم دیوار شاخص را داشته باشد. از دیگر مواردی که می توانید دیوار شاخص را مزین کنید قاب های عکس خانوادگی و یا یک پارچه باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای چیدمان ایده آل، خواندن مقاله <Link className="link" to="/fengshui" target="_blank"> فنگ شویی </Link> خالی از لطف نیست.
              </Typography>
              <Typography className="h2" variant="h2">
                انتخاب دیوار اکسنت وال
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دیواری که در و پنجره دارد انتخاب مناسبی برای دیوار شاخص نیست. دیواری که برای اکسنت وال انتخاب می شود  می بایست یکپارچه و کامل باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/smart-home" target="_blank">
                  بیشتر بخوانید : خانه هوشمند چیست
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کدام قسمت از منزل می تواند برای اکسنت وال یا دیوار شاخص در نظر گرفته شود؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اکسنت وال یا دیوار شاخص هر قسمتی
                از منزل می تواند در نظر گرفته شود. برای هر یک از فضای آشپزخانه، اتاق نشیمن، اتاق پذیرایی و حتی اتاق خواب ها نیز  دیوار شاخص تعیین کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گاهی می توان قسمت هایی از خانه که بیشتر به چشم می آید را به
                عنوان دیوار شاخص در نظر گرفت.نقاطی از خانه که ایده آل برای تبدیل شدن به دیوار شاخص هستند دیواری است که سر <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> تخت خواب </Link> پشت آن قرار می گیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین دیواری که یکی از وسایل مهم اتاق مقابل آن تعبیه شده یا تابلوی نقاشی یا یک وسیله تزئینی روی آن قرار داده شده است نیز انتخاب خوبی برای اکسنت وال است.
              </Typography>
              <Typography className="h2" variant="h2">
                توصیه هایی در مورد رنگ امیزی دیوار شاخص:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت نکات مهمی در مورد انتخاب رنگ دیوار شاخص گفته می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                1- انتخاب رنگ متناسب با دیوارها
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="رنگ دیوار شاخص - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انتخاب رنگ دیوار شاخص
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                بهتر است رنگ دیوار شاخص با رنگ بقیه دیوارها متناسب باشد اما چنانچه رنگ بقیه
                دیوارها تیره است بهترین انتخاب رنگ برای دیوار شاخص رنگ روشن است. اما چنانچه بر عکس این موضوع وجود دارد بهتر است رنگ انتخابی دیوار شاخص رنگ تیره باشد.
                <br />
                چنانچه ملاک خاصی برای انتخاب رنگ شاخص ندارید بهتر است سایر دیوارها را دو درجه تیره تر از رنگ دیوار انتخاب کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/laundry-room" target="_blank">
                  بیشتر بخوانید : لاندری روم چیست
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                2- هماهنگی رنگ اکسنت وال با بقیه قسمت ها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ایجاد تناسب بین رنگ دیوار
                شاخص با بقیه فضای خانه بهتر است رنگ دیوار شاخص را با یکی از قسمت های خانه هماهنگ کنید. مثلا رنگ دیوار شاخص را از رنگ کوسن های مبل یا <Link className="link" to="/pouf" target="_blank"> پاف </Link> اتخاب کنید.
                توجه داشته باشید که رنگ دیوار شاخص نباید همرنگ قسمت های غالب انتخاب شود. برای اکسنت وال می توانید از کاغذ دیواری هم بجای رنگ استفاده کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع دیوار شاخص
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در اینجا به معرفی انواع دیوار شاخص می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                دیوار شاخص آجری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اکسنت وال آجری می تواند بیشتر در اطرف شومینه بکار رود. آجر تنها به رنگ قرمز و نارنجی نیست. شما می توانید از آجرهای سفید و سیاه و طوسی هم برای این بخش بکار ببرید.
                <br />
                همچنین در محیط های اداری که مرتبط با صنایع و کارخانجات می باشد نیاز به کارگیری دیوار شاخص آجری نیز گزینه مناسبی است.
                یکی از مزایای اکسنت وال  آجری این است که به هزینه زیادی نیاز ندارد. می توانید با کاغذ دیواری یا فوم های آماده طرح آجر، دیوار شاخص را به شکل آجر نمایان کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                اکسنت وال سنگی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="اکسنت وال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اکسنت وال سنگی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قصد دارید دیوار را به شکلی لوکس و لاکچری در اورید اکسنت وال سنگی گزینه
                مناسبی برای این مورد می باشد. متریال بکار رفته در این دیوار شاخص می تواند سنگ باشد. سنگ ها هم بصورت تایل های کوچک و هم تایل های بزرگ و هم
                یکپارچه انتخاب می شوند. هر چه سنگ های منتخب شما رگه های بیشتری داشته باشند جلوه دیوار بیشتر می شود. دیوار شاخص سنگی بیشتر از دیگر اکسنت وال
                ها فضا را لوکس می کند. از این دیوار شاخص در نمای خارجی ساختمان نیز می توان بکار برد. این دیوار شاخص  گزینه مناسبی برای مکان های اداری هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                دیوار رنگی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هدف از دیوار شاخص تنها یک دیوار رنگی متفاوت نیست. بلکه قرار است ببینیم چه رنگی باید بکار ببریم که دیوار این فضا را شاخص کند.
                از دیوار شاخص رنگی بیشتر می توان در اتاق خواب یا غذاخوری یا اتاق مطالعه بکار برد. اما دیوار شاخص در واحد های اداری نیز کاربرد دارند. مثل
                دیوار شاخص در <Link className="link" to="/best-restaurant-tehran-region1" target="_blank"> رستوران </Link> ها و یا اتاق فکر شرکت های تجاری و اداری. بهتر است رنگی را برای دیوار شاخص انتخاب کنید که باعث شادی و رضایت شما می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                دیوار شاخص با کاغذ دیواری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاغذ دیواری جز متریال های نسبتا ارزان و راحت برای دیوار شاخص می باشد. برای نشان دادن فضا
                به صورت کشیده تر می توانید از کاغذ دیواری راه راه افقی استفاده کنید. با کاغذ دیواری راه راه عمودی نیز می توانید آن قسمت را مرتفع تر نشان دهید.
              </Typography>
              <Typography className="h2" variant="h2">
                نتیجه گیری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بنابراین با توجه به مطالب گفته شده در این مقاله از <LinkMui className="link" href="https://homeinja.info/" target="_blank"> هومینجا </LinkMui> می توانید متناسب با  فضای خانه، دیواری  را به <Link className="link" to="/accent-wall" target="_blank"> اکسنت وال </Link> اختصاص دهید.
              </Typography>

              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/premade-canopy'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    سایبان پیش ساخت چیست؟
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/chandelier-in-decoration'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    انتخاب لوستر مناسب در دکوراسیون داخلی منزل
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/keeping-apartment-flower'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    نکاتی که در مورد نگهداری گیاهان آپارتمانی باید بدانیم
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("تکنولوژی و فناوری")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  تکنولوژی و فناوری
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/accent-wall" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/accent-wall" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/accent-wall" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article13;