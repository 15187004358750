import { Box, Button, FormControl, Grid, IconButton, InputAdornment, Modal, OutlinedInput, Typography } from '@mui/material';
import City from '../images/city.png';
import React from 'react';
import axios from 'axios';
import { baseUrl, convertToEnglishNumber, convertToPersianNumber, phoneNumber } from './materilas/vmp';
import AlertModal from './materilas/alert';
import GifLogo from './gifLogo';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/recaptcha.css';

class AuthModal extends React.Component {

    intervalTimer = '';
    constructor(props) {
        super(props);
        this.state = {
            sendCode: false,
            sendCodeForgot: false,
            mobile: '',
            openAlert: false,
            message: '',
            code: '',
            showGif: 'none',
            second: 59,
            minute: 1,
            codeInput: '',
            pass: '',
            reppass: '',
            forgot: false,
            showPass: false,
            typeAlert: 3,
            captcha: false,
            type: props.type
        }
    }

    oncloseAlert = () => [
        this.setState({ openAlert: false })
    ]

    onChangeMobile = (event) => {
        this.setState({ mobile: convertToEnglishNumber(convertToPersianNumber(event.target.value)) })
    }

    onclickSendCode = () => {
        if (this.props.type === 'R') {
            if (!this.state.sendCode) {
                if (phoneNumber(this.state.mobile)) {
                    if (this.state.captcha) {
                        if (this.state.mobile[0] !== 0) {
                            this.setState({ showGif: '' });
                            this.exist('R');
                        }

                        else {
                            this.setState({ openAlert: true, message: 'شماره موبایل صحیح نمیباشد', typeAlert: 3 })
                        }
                    } else {
                        this.setState({ openAlert: true, message: 'تیک من ربات نیستم را بزنید', typeAlert: 3 })
                    }
                }
                else {
                    this.setState({ openAlert: true, message: 'شماره موبایل صحیح نمیباشد', typeAlert: 3 })
                }
            }
            else {
                if (this.state.codeInput.length === 4) {
                    if (this.state.captcha) {
                        if (this.state.pass === this.state.reppass) {
                            if (parseInt(this.state.code) === parseInt(this.state.codeInput)) {
                                this.setState({ showGif: '' });
                                this.register();
                            }
                            else {
                                this.setState({ openAlert: true, message: 'کد تایید مطابقت ندارد', typeAlert: 3 })
                            }
                        }
                        else {
                            this.setState({ openAlert: true, message: 'رمز عبور و تکرار آن با هم مطابقت ندارد', typeAlert: 3 })
                        }
                    }
                    else {
                        this.setState({ openAlert: true, message: 'تیک من ربات نیستم را بزنید', typeAlert: 3 })
                    }
                }
                else {
                    this.setState({ openAlert: true, message: 'کد وارد شده باید 6 رقم باشد', typeAlert: 3 })
                }
            }
        }
        else {
            if (this.state.forgot) {
                if (this.state.sendCodeForgot) {
                    if (this.state.codeInput.length === 4) {
                        if (this.state.captcha) {
                            if (this.state.pass === this.state.reppass) {
                                if (parseInt(this.state.code) === parseInt(this.state.codeInput)) {
                                    this.setState({ showGif: '' });
                                    this.changePass();
                                }
                                else {
                                    this.setState({ openAlert: true, message: 'کد تایید مطابقت ندارد', typeAlert: 3 })
                                }
                            }
                            else {
                                this.setState({ openAlert: true, message: 'رمز عبور و تکرار آن با هم مطابقت ندارد', typeAlert: 3 })
                            }
                        } else {
                            this.setState({ openAlert: true, message: 'تیک من ربات نیستم را بزنید', typeAlert: 3 })
                        }
                    }
                    else {
                        this.setState({ openAlert: true, message: 'کد وارد شده باید 6 رقم باشد', typeAlert: 3 })
                    }
                }
                else {
                    if (phoneNumber(this.state.mobile)) {
                        if (this.state.captcha) {
                            if (this.state.mobile[0] !== 0) {
                                this.setState({ showGif: '' });
                                this.exist('F');
                            }
                            else {
                                this.setState({ openAlert: true, message: 'شماره موبایل صحیح نمیباشد', typeAlert: 3 })
                            }
                        }
                        else {
                            this.setState({ openAlert: true, message: 'تیک من ربات نیستم را بزنید', typeAlert: 3 })
                        }
                    }
                    else {
                        this.setState({ openAlert: true, message: 'شماره موبایل صحیح نمیباشد', typeAlert: 3 })
                    }
                }
            }
            else {
                if (phoneNumber(this.state.mobile)) {
                    if (this.state.captcha) {
                        if (this.state.mobile[0] !== 0) {
                            if (this.state.pass.length > 0) {
                                this.setState({ showGif: '' });
                                this.exist('L');
                            }
                            else {
                                this.setState({ openAlert: true, message: 'لطفا رمز عبور خود را وارد کنید.', typeAlert: 3 })
                            }
                        }
                        else {
                            this.setState({ openAlert: true, message: 'شماره موبایل صحیح نمیباشد', typeAlert: 3 })
                        }
                    } else {
                        this.setState({ openAlert: true, message: 'تیک من ربات نیستم را بزنید', typeAlert: 3 })
                    }
                }
                else {
                    this.setState({ openAlert: true, message: 'شماره موبایل صحیح نمیباشد', typeAlert: 3 })
                }
            }
        }

    }

    startTimer = (type) => {
        if (this.intervalTimer !== '') {
            clearInterval(this.intervalTimer)
        }
        this.intervalTimer = setInterval(() => {
            this.setState({ second: this.state.second - 1 })
            if (this.state.second === 0) {
                if (this.state.minute === 1) {
                    this.setState({ minute: 0, second: 59 })
                }
                else {
                    this.setState({ sendCode: false, sendCodeForgot: false, code: '', codeInput: '', pass: '', reppass: '' })
                    clearInterval(this.intervalTimer);
                }
            }
        }, 1000);
    }

    exist = (type) => {
        Promise.resolve(axios({
            method: 'GET',
            url: baseUrl + 'v1/user/exist',
            params: {
                mobile: this.state.mobile
            }
        }))
            .then(() => {
                if (type === 'R') {
                    this.codeSend(type);
                }
                if (type === 'L' || type === 'F') {
                    this.setState({
                        pass: '', mobile: '', openAlert: true, showGif: 'none', typeAlert: 3,
                        message: 'با این شماره هنوز ثبت نامی صورت نگرفته لطفا از قسمت ثبت نام نسبت به ایجاد حساب کاربری اقدام بفرمایید.'
                    })
                }
            })
            .catch(() => {
                if (type === 'R') {
                    this.setState({
                        code: '', sendCode: false, showGif: 'none', openAlert: true, mobile: '', typeAlert: 3,
                        message: 'با این شماره موبایل قبلا ثبت نام انجام شده است.'
                    });
                }
                if (type === 'F') {
                    this.codeSend(type)
                }
                if (type === 'L') {
                    this.login();
                }

            })
    }

    codeSend = (type) => {
        Promise.resolve(axios({
            method: 'POST',
            url: baseUrl + 'v1/otp/send',
            data: {
                mobile: this.state.mobile
            }
        }))
            .then((res) => {
                this.setState({
                    code: res.data.data.code, second: res.data.data.ttl > 59 ? res.data.data.ttl - 60 : res.data.data.ttl,
                    minute: res.data.data.ttl > 60 ? 1 : 0, showGif: 'none', sendCode: type === 'F' ? false : true,
                    sendCodeForgot: type === 'F' ? true : false
                });
                this.startTimer(type);
            })
            .catch((err) => {
                this.setState({
                    code: '', sendCode: false, showGif: 'none', openAlert: true, typeAlert: 2,
                    message: 'سامانه هم اکنون با مشکل مواجه است لطفا بعدا مجدد تلاش نمایید.'
                });
            })
    }

    onChangeCode = (event) => {
        this.setState({ codeInput: event.target.value });
    }

    onChangePass = (event) => {
        this.setState({ pass: event.target.value });
    }

    onChangeRepPass = (event) => {
        this.setState({ reppass: event.target.value });
    }

    register = () => {
        Promise.resolve(axios({
            method: 'POST',
            url: baseUrl + 'v1/user/register',
            data: {
                mobile: this.state.mobile,
                password: this.state.pass
            }
        }))
            .then((res) => {
                this.setState({ showGif: 'none' })
                localStorage.setItem('tokenUserHomeInja', res.data.data.access_token);
                window.location.reload();
            })
            .catch((err) => {
                this.setState({
                    code: '', sendCode: false, showGif: 'none', openAlert: true, codeInput: '', pass: '', reppass: '', typeAlert: 2,
                    message: 'سامانه هم اکنون با مشکل مواجه است لطفا بعدا مجدد تلاش نمایید.'
                });
            })
    }

    onClickForgot = () => {
        this.setState({ forgot: true, pass: '', reppass: '', codeInput: '', captcha: false })
    }

    onClickLogin = () => {
        this.setState({ forgot: false, pass: '', reppass: '', codeInput: '', captcha: false })
    }

    onClickShowPass = () => {
        this.setState({ showPass: !this.state.showPass })
    }

    changePass = () => {
        Promise.resolve(axios({
            method: 'POST',
            url: baseUrl + 'v1/user/changePassword',
            data: {
                mobile: this.state.mobile,
                password: this.state.pass
            }
        }))
            .then((res) => {
                this.setState({ showGif: 'none' })
                this.setState({ openAlert: true, message: 'تغییر رمز با موفقیت انجام شد.', showGif: 'none', forgot: false, sendCodeForgot: false, typeAlert: 1 })
            })
            .catch((err) => {
                this.setState({
                    showGif: 'none', openAlert: true, codeInput: '', pass: '', reppass: '', forgot: false, sendCodeForgot: false, typeAlert: 2,
                    message: 'سامانه هم اکنون با مشکل مواجه است لطفا بعدا مجدد تلاش نمایید.'
                });
            })
    }

    login = () => {
        Promise.resolve(axios({
            method: 'POST',
            url: baseUrl + 'v1/user/login',
            data: {
                mobile: this.state.mobile,
                password: this.state.pass
            }
        }))
            .then((res) => {
                this.setState({ showGif: 'none' })
                localStorage.setItem('tokenUserHomeInja', res.data.data.access_token);
                localStorage.setItem('infoUserHomeInja', JSON.stringify(res.data.data.user));
                window.location.reload();
            })
            .catch((err) => {
                this.setState({
                    showGif: 'none', openAlert: true, pass: '', typeAlert: 2,
                    message: 'اطلاعات کاربری جهت ورود صحیح نیست.'
                });
            })
    }

    onChangeRechapta = (value) => {
        value != null ? this.setState({ captcha: true }) : this.setState({ captcha: false })
    }

    render() {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.props.open}
                onClose={this.props.close}
                closeAfterTransition
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'shabnam' }}
            >
                <Grid style={{
                    backgroundColor: 'white', border: '2px solid', borderColor: 'black', display: 'flex', flexDirection: 'column',
                    alignItems: 'center', justifyContent: 'center',
                    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)'
                }}
                    sx={{ padding: { md: '50px 60px 100px 60px', sm: '20px 20px 50px 20px', xs: '20px 5px 5px 5px' } }}
                >
                    <Typography component={'img'} src={City} sx={{ width: { md: 350, sm: 300, xs: 250 }, height: { md: 250, xs: 150 } }} alt="sign-in-city-logo"></Typography>
                    <Typography component={'h1'} variant='h1'
                        style={{ fontFamily: 'shabnam', fontSize: '1.5em', fontWeight: '700', color: '#01C7E9', marginTop: 15, marginBottom: 15 }}>
                        {this.props.type === 'R' ? 'ثبت نام ' : this.state.forgot ? 'فراموشی رمز عبور' : 'ورود'}
                    </Typography>
                    {
                        this.props.type === 'R' ?
                            this.state.sendCode ?
                                <Box component={'form'} autoComplete="off" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                        <OutlinedInput placeholder='کد تایید' inputProps={{ maxLength: 4 }}
                                            style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                            sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                            onChange={this.onChangeCode}
                                            value={this.state.codeInput}
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                        <OutlinedInput placeholder='رمز عبور' inputProps={{ maxLength: 25, style: { paddingRight: '13px' } }}
                                            style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                            sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                            onChange={this.onChangePass}
                                            value={this.state.pass}
                                            type={this.state.showPass ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.onClickShowPass}
                                                        edge="end"
                                                    >
                                                        {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                        <OutlinedInput placeholder='تکرار رمز عبور' inputProps={{ maxLength: 25, style: { paddingRight: '13px' } }}
                                            style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                            sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                            onChange={this.onChangeRepPass}
                                            value={this.state.reppass}
                                            type={this.state.showPass ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.onClickShowPass}
                                                        edge="end"
                                                    >
                                                        {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <ReCAPTCHA
                                            sitekey="6LfqzRMpAAAAAB3g0mOMA6g0Pzm_Y_Sz9sMFgrUO"
                                            onChange={(value) => { this.onChangeRechapta(value) }}
                                            className="g-recaptcha-login"
                                            hl="fa"
                                        />
                                    </FormControl>

                                </Box>
                                :
                                <Box component={'form'} autoComplete="off">
                                    <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                        <OutlinedInput placeholder='شماره موبایل' inputProps={{ maxLength: 11 }}
                                            style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                            sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                            onChange={this.onChangeMobile}
                                            value={convertToPersianNumber(this.state.mobile)}
                                        />
                                        <ReCAPTCHA
                                            sitekey="6LfqzRMpAAAAAB3g0mOMA6g0Pzm_Y_Sz9sMFgrUO"
                                            onChange={(value) => { this.onChangeRechapta(value) }}
                                            className="g-recaptcha-login"
                                            hl="fa"
                                        />

                                    </FormControl>
                                </Box>


                            :
                            this.state.forgot ?
                                this.state.sendCodeForgot ?
                                    <Box component={'form'} autoComplete="off" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                            <OutlinedInput placeholder='کد تایید' inputProps={{ maxLength: 4 }}
                                                style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                                sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                                onChange={this.onChangeCode}
                                                value={this.state.codeInput}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                            <OutlinedInput placeholder='رمز عبور' inputProps={{ maxLength: 25, style: { paddingRight: '13px' } }}
                                                style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                                sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                                onChange={this.onChangePass}
                                                value={this.state.pass}
                                                type={this.state.showPass ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.onClickShowPass}
                                                            edge="end"
                                                        >
                                                            {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                            <OutlinedInput placeholder='تکرار رمز عبور' inputProps={{ maxLength: 25, style: { paddingRight: '13px' } }}
                                                style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                                sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                                onChange={this.onChangeRepPass}
                                                value={this.state.reppass}
                                                type={this.state.showPass ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.onClickShowPass}
                                                            edge="end"
                                                        >
                                                            {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <ReCAPTCHA
                                                sitekey="6LfqzRMpAAAAAB3g0mOMA6g0Pzm_Y_Sz9sMFgrUO"
                                                onChange={(value) => { this.onChangeRechapta(value) }}
                                                className="g-recaptcha-login"
                                                hl="fa"
                                            />
                                        </FormControl>
                                        <Button variant='text' style={{ fontFamily: 'shabnam', marginBottom: "20px" }} onClick={this.onClickLogin}>
                                            {'ورود'}
                                        </Button>
                                    </Box>
                                    :
                                    <Box component={'form'} autoComplete="off" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                            <OutlinedInput placeholder='شماره موبایل' inputProps={{ maxLength: 11 }}
                                                style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                                sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                                onChange={this.onChangeMobile}
                                                value={convertToPersianNumber(this.state.mobile)}
                                            />
                                            <ReCAPTCHA
                                                sitekey="6LfqzRMpAAAAAB3g0mOMA6g0Pzm_Y_Sz9sMFgrUO"
                                                onChange={(value) => { this.onChangeRechapta(value) }}
                                                className="g-recaptcha-login"
                                                hl="fa"
                                            />
                                        </FormControl>
                                        <Button variant='text' style={{ fontFamily: 'shabnam', marginBottom: "20px" }} onClick={this.onClickLogin}>
                                            {'ورود'}
                                        </Button>
                                    </Box>
                                :
                                <Box component={'form'} autoComplete="off" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                        <OutlinedInput placeholder='شماره موبایل' inputProps={{ maxLength: 11 }}
                                            style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                            sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                            onChange={this.onChangeMobile}
                                            value={convertToPersianNumber(this.state.mobile)}
                                        />

                                    </FormControl>
                                    <FormControl sx={{ width: { sm: '50ch', xs: '30ch' }, alignItems: 'center' }}>
                                        <OutlinedInput placeholder='رمز عبور' inputProps={{ maxLength: 25, style: { paddingRight: '13px' } }}
                                            style={{ fontFamily: 'shabnam', marginBottom: 20, borderStyle: 'solid', borderWidth: 1 }}
                                            sx={{ width: { md: '80%', sm: '60%', xs: '80%' }, height: { md: '4vh', sm: '6vh', xs: '5vh' } }}
                                            onChange={this.onChangePass}
                                            value={this.state.pass}
                                            type={this.state.showPass ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.onClickShowPass}
                                                        edge="end"
                                                    >
                                                        {this.state.showPass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <ReCAPTCHA
                                            sitekey="6LfqzRMpAAAAAB3g0mOMA6g0Pzm_Y_Sz9sMFgrUO"
                                            onChange={(value) => { this.onChangeRechapta(value) }}
                                            className="g-recaptcha-login"
                                            hl="fa"
                                        />
                                    </FormControl>
                                    <Button variant='text' style={{ fontFamily: 'shabnam', margin: "20px 0" }} onClick={this.onClickForgot}>
                                        {'فراموشی رمزعبور'}
                                    </Button>
                                </Box>
                    }
                    <Button variant='outlined' onClick={this.onclickSendCode}
                        style={{ width: '20ch', backgroundColor: '#01C7E9', color: 'black', fontFamily: 'shabnam' }}
                        sx={{ height: { md: '4vh', sm: '5vh', xs: '4vh' }, margin: "20px 0" }}
                    >
                        {this.props.type === 'R' ? this.state.sendCode ? 'ثبت نام' : 'ارسال کد' :
                            this.state.forgot ? this.state.sendCodeForgot ? 'تغییر رمز' : 'ارسال کد' : 'ورود'}
                    </Button>
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <p style={{
                            display: this.props.type === 'R' ? this.state.sendCode ? 'flex' : 'none' :
                                this.state.forgot ? this.state.sendCodeForgot ? 'flex' : 'none' : 'none', backgroundColor: '#01C7E9',
                            textAlign: 'center', height: 30, justifyContent: 'center', minWidth: '30%', alignItems: 'center'
                        }}>
                            {convertToPersianNumber('0' + this.state.minute) + ':' + (this.state.second > 9 ?
                                convertToPersianNumber(this.state.second) : convertToPersianNumber('0' + this.state.second))}
                        </p>
                    </Grid>

                    <AlertModal open={this.state.openAlert} close={this.oncloseAlert} message={this.state.message} type={this.state.typeAlert} />
                    <GifLogo display={this.state.showGif} />
                </Grid>
            </Modal>

        )
    }

}

export default AuthModal;



