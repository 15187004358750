import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article31/01.jpg";
import photo02 from "../../images/mag/article31/02.jpg";
import photo03 from "../../images/mag/article31/03.jpg";
import photo04 from "../../images/mag/article31/04.jpg";
import photo05 from "../../images/mag/article31/05.jpg";
import photo06 from "../../images/mag/article31/06.jpg";
import photo07 from "../../images/mag/article31/07.jpg";
import photo08 from "../../images/mag/article31/08.jpg";
import photo09 from "../../images/mag/article31/09.jpg";
import photo10 from "../../images/mag/article31/10.jpg";
import photo11 from "../../images/mag/article31/11.jpg";
import photo12 from "../../images/mag/article31/12.jpg";
import photo13 from "../../images/mag/article31/13.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article31 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "نمای ساختمان اولین المان مهم یک بنا می باشد که پوسته هر ساختمان را تشکیل می دهد. نما نقش بسیار مهمی در زیبایی و جذابیت ساختمان ایفا میکند.";
    document.getElementsByTagName('meta')["keywords"].content = "نمای ساختمان چیست؟ | دسته بندی نمای انواع ساختمان | نمای ساختمان همراه با عکس و توضیحات | انواع مصالح در نمای ساختمان | انواع نمای خارجی ساختمان | ضوابط اجرای انواع نمای ساختمان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[30].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[30].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[30].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[30].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[30].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[30].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="نمای ساختمان - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[30].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از<Link className="link" to="/" target="_blank"> سایت املاک هومینجا </Link> قصد داریم تا به معرفی کلی مربوط به انواع نمای ساختمان همراه با عکس و توضیحات آن بپردازیم، پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای ساختمان اولین المان مهم یک بنا می باشد. نمای یک ساختمان نقش پر رنگی در جذابیت و زیبایی آن بازی می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای طراحی نمای ساختمان، طراحان و مهندسان با توجه به شرایط آب و هوایی، موقعیت جغرافیایی، تعداد طبقات ساختمان، سلیقه افراد و بودجه آن ها،  نوع مصالح را انتخاب می کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای ساختمان، یک لایه ی محافظ ساختمان است که علاوه بر زیبایی از عوامل مختلف هم حفاظت و نگهداری میکند.
                هم چنین رعایت ضوابط شهرسازی از اصول اولیه طراحی نمای ساختمان است.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع نمای ساختمان :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                حال به بررسی و معرفی انواع نمای ساختمان همراه با عکس و توضیحات مربوطه خواهیم پرداخت.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای آجری :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="نمای آجری - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ساختمان آجری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای آجری جزء قدیمی ترین نمای های مرسوم در ساختمان است. آجر تنوع بسیار بالایی دارد که بسته به سلیقه طراح انتخاب و استفاده می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله انواع آجر می توان به آجر نما نسوز، آجر نما طرح چوب، آجر دکوراسیون داخلی، لعاب دار حصیری، آجر نما سفید، آجر نما قرمز، کارتنی، دکوراتیو، کف فرش، آجر نما زرد، رستیک، شاموتی، آجر نما نسوز سفید، آجر نما نسوز قرمز، آجر نما نسوز انگلیسی، آجر نما پلاک و قزاقی اشاره نمود.
              </Typography>
              <Typography className="h4" variant="h4">
                ضوابط اجرای نمای آجری :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بر اساس ضوابط، امکان رنگ آمیزی نمای آجری وجود ندارد. نوع آجر بر اساس بافت سنتی هر شهر انتخاب و استفاده می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای کامپوزیتی :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="نمای کامپوزیت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ساختمان کامپوزیتی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای کامپوزیتی براساس استفاده از ورق های کامپوزیت آلومینیوم صورت می گیرد.

                از مزایای نمای کامپوزیتی ساختمان میتوان به مقاومت بالای حرارتی، رطوبتی و صوتی، وزن پایین، قابلیت شستشو و تنوع رنگ اشاره کرد.

                از معایب نمای کامپوزیتی می توان به قیمت بالا و اجرای مشکل آن اشاره کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/building-certificate" target="_blank">
                  بیشتر بخوانید : همه چیز راجع به صدور پروانه ساختمان
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                ضوابط اجرایی نمای کامپوزیتی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                استفاده از نمای کامپوزیت و آلومینیومی، بر اساس ضوابط اجرایی حداکثر 20 درصد در سطح کلی نمای ساختمان مشرف به معابر مجاز می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای سیمانی :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="نمای بتنی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نما بتنی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای سیمانی یا بتنی از جمله انواع نماهای می باشد که اجرای آن به نسبت بقیه نماها سریع و کم هزینه می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله مزایای نمای سیمانی می توان به مقاومت بالا در برابر حریق و شسنشوی آسان اشاره کرد.
                از جمله معایب استفاده از نمای سیمانی امکان شکستگی و ترک خوردگی در آن است. عایق صوتی ضعیفی دارد و همچنین گرد و خاک را به راحتی به خود جذب می کند.
              </Typography>
              <Typography className="h4" variant="h4">
                ضوابط اجرایی نمای سیمانی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله مهمترین ضوابط مربوط  به طراحی و اجرای نمای سیمانی میتوان به عدم بکارگیری از شیشه و فلز در نما اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                نما سنگی :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="نما سنگی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سنگ نما
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای سنگی از جمله نماهای لوکس است که همیشه زیبایی و جذابیتی خاص داشته است.
                نمای رومی از معروف ترین انواع نمای سنگی ساختمان می باشد که از سنگ مرمر، سنگ تراورتن یا سنگ گرانیت استفاده می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مزایای نمای سنگ میتوان به زیبایی، مقاومت بالا در برابر عوامل طبیعی اشاره کرد.
                استفاده از مواد بی کیفیت میتواند به ترک خوردن سنگ منجر شود واز مزایای ان میتوان به ضرورت شستشوی مداوم اشاره کرد.
              </Typography>
              <Typography className="h4" variant="h4">
                نکات اجرایی نمای سنگی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                براساس ضوابط مربوط به نماسازی، رعایت نکات ایمنی در هنگام استفاده از سنگ الزامی بوده و امکان استفاده از هر نوع رنگ سنگ در ساخت نما امکان پذیر نمی باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                نما شیشه ای :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="نما شیشه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ساختمان شیشه ای
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای شیشه ای ساختمان از جمله پرطرفدارترین نماهای ساختمانی است که در اکثر نماهای ساختمان های مسکونی، تجاری و اداری استفاده می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وظیفه اصلی نمای شیشه ای( که به نما شیشه ای کرتین وال هم شهرت دارد،) علاوه بر زیبایی، تامین نور و گرما برای فضای داخل ساختمان است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال چیست؟
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                ضوابط اجرایی نمای شیشه ای :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ضوابط اجرای نما در شهرهای مختلف و بر اساس بافت شهر می تواند متفاوت باشد.اصولاً در اجرای نمای شیشه ای از شیشه سکوریت و یا شیشه رفلکس استفاده می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای بایرامیکس :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="نمای بایرامیکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بایرامیکس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای بایرامیکس از انواع نماهای دکوراتیو محسوب می شود. بایرامیکس به صورت ملات متشکل از سنگدانه، رزین های طبیعی و مصنوعی، رنگ و افزودنی های دیگر می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مزایای نمای بایرامیکس میتوان به تنوع رنگ زیاد و قابلیت شستشو با آب ذکر کرد و از معایب آن هم میتوان به مقاومت پایین و عمر کوتاه آن اشاره کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این  نوع نما بر روی انواع سطوح فلزی، چوبی، سیمانی و آجری قابل اجراست.
              </Typography>
              <Typography className="h4" variant="h4">
                ضوابط اجرایی نمای بایرامیکس :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سطح محل اجرا باید حتی الامکان عاری از مواد چرب و گرد و غبار باشد توصیه می شود کلیه سطوح به ویژه سطوح چرب و دارای گرد و غبار قبل از اجرا با آستر بایرامیکس توسط غلطک، بورس و یا پیستوله پوشش داده شود.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای چوبی :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="نمای چوبی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ساختمان با نمای چوبی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از زیباترین و جذاب ترین نماهای ساختمان نمای چوبی است. استفاده از چوب در خانه های لوکس، همچنین ترکیب آن با فلز و شیشه ای زیبای نمای ساختمان را دوچندان می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چوب ترمووود نوعی چوب نما است که از وزن سبک اما مقاومت بالا برخوردار می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مزایای نمای چوب می توان به شکل پذیری و برش کاری در اجرا اشاره کرد. مقاومت بالا در برابر نفوذ حشرات و رطوبت، طول عمر مناسب و امکان تعویض نمای چوبی از دیگر مزایای  آن است.
              </Typography>
              <Typography className="h4" variant="h4">
                ضوابط اجرایی نمای چوبی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اجرای نمای چوبی ساختمان نیز مانند سایر نماها دارای ضوابطی می باشد. بسته به بافت شهری میزان درصد استفاده از چوب توسط سازمان مربوطه تعیین می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای اتیکس :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="نمای اتیکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اتیکس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای اتیکس نوعی عایق برای ساختمان به حساب می آید که آن را در برابر عواملی طبیعی نظیر رطوبت و حرارت محافظت می نماید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله عایق هایی که برای نمای اتیکس استفاده می شوند، فوم پلی استایرن منبسط شده و پشم معدنی می باشند.
                پوشش نهایی نمای اتیکس می تواند چوب، آجر لعابدار، فلز و اندود باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله معایب نمای اتیکس، هزینه ی بالای نصب و نگهداری آن است.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای سرامیکی :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="نمای سرامیکی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای سرامیکی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                درحال حاضر نمای سرامیکی جزو انواع نماهای پرطرفدار در صنعت ساختمان محسوب می شود.
                نمای سرامیکی با دو روش خشک و مهار شده یا تر و چسبانده شده نصب می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مزایای نمای سرامیکی می توان به مقاومت بالا در برابر یخ زدگی، رطوبت و حریق اشاره کرد.
                به دلیل بازتاب بالای نور توسط نمای سرامیکی، استفاده از آن در مکان های شلوغ توصیه نمی شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/chandelier-in-decoration" target="_blank">
                  بیشتر بخوانید : انتخاب لوستر مناسب در دکوراسیون منزل
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                نمای مینرال :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="نمای مینرال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مینرال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نمای مینرال از ترکیب سنگ های مختلف معدنی، سنگ مرمر و سفید به همراه پلاستر ساخته می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مزایای نمای مینرال می توان به هزینه پایین و سرعت بالای اجرا، سازگاری با محیط زیست، تنوع در رنگ و طرح و مقاوم بودن در برابر آتش سوزی اشاره کرد.
                بزرگ ترین عیب نمای مینرال عدم مقاومت در برابر رطوبت می باشد. به همین خاطر به مرور زمان دچار آسیب دیدگی جدی می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                نمای کنیتکس :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo13} alt="نمای کنیتکس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای کنیتکس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از نماهای پرکاربرد ساختمان کنیتکس است که از ترکیب الیاف های مصنوعی، اکسید تیتان و غیره بدست می آید. کنیتکس خاصیت ضد آب دارد که برای استفاده در نمای بیرونی بسیار مناسب می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                نانو نما :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo12} alt="نانو نما - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای نانو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نانو به عنوان پوشش نامرئی، به محافظت از سطوح کمک می کند. از مزایای استفاده از نانو نما می توان به مقاومت بالا در برابر رطوبت اشاره کرد که به عمر بالای بنا کمک می کند.
              </Typography>
              <Typography className="h2" variant="h2">نتیجه گیری :</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بنابراین با توجه به مطالب گفته شده در این مقاله از هومینجا می توانید متناسب با  شرایط آب و هوایی، موقعیت جغرافیایی، تعداد طبقات ساختمان، بهترین نما را انتخاب کنید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article31;