import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article16/01.jpg";
import photo02 from "../../images/mag/article16/02.jpg";
import photo03 from "../../images/mag/article16/03.jpg";
import photo04 from "../../images/mag/article16/04.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article16 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "اگر قصد خرید ملک در شمیران نو را دارید ما در هومینجا به شما کمک می کنیم تا با آشنایی نسبی با این منطقه انتخاب بهتری داشته باشید.";
    document.getElementsByTagName('meta')["keywords"].content = "امکانات شمیران نو | دسترسی های محله شمیران نو | قیمت ملک در شمیران نو"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[15].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[15].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[15].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[15].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[15].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[15].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="محله شمیران نو - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[15].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محله شمیران نو از محلات شرق تهران و <Link className="link" to="/tehran-zone-4" target="_blank">منطقه 4 شهرداری</Link> می باشد. شمیران نو
                محله ای بین لویزان و نارمک است.  از شمال به بزرگراه زین الدین و از جنوب به <Link className="link" to="/Listings" target="_blank">نیروهوایی</Link> و
                خیابان الزهرا وصل می شود. این منطقه از غرب بزرگراه
                امام علی و از شرق به خیابان هنگام محدود می شود . این محله با محلات شیان ،لویزان ، نارمک ، کوهک ،اوقاف ، مجیدیه ، و حسین آباد مجاورت دارد .
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه شمیران نو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در گذشته بیشتر زمین های این محله برای مراتع چراگاهی و تفریحی
                استفاده میشد و خالی از سکنی بود . مالکان اصلی شمیران نو هفت برادر جعفری بودند . در سال 1330چندین هکتار از زمین ها را خریداری کردند. آنها
                زمین ها را بین مردم  و ارگان دولتی تقسیم کردند .بعد گذشت زمان ساکنین این محله از قشر خانزاده و مرفه به قشر معمولی تغییر کردند . مهاجرانی
                که به <Link className="link" to="/tehran-zones" target="_blank">تهران</Link> می آمدند در این محل ساکن می شدند
                و روز به روز بر جمعیت آن افزوده می شد.  بخشی از این زمین ها هنوز در تملک ارگان های دولتی است .
              </Typography>
              <Typography className="h2" variant="h2">
                آیا محله شمیران نو انتخاب مناسبی برای سکونت است؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="شمیران نو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شمیران نو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هنگامی که وارد این محله می شوید اولین چیزی که شما را اذیت میکند ترافیک سنگین و شلوغی محله است . با
                وجود فروشگاهها و مغازه های زیاد هنوز معابر توسط دست فروشان اشغال شده است . بافت این محله قدیمی است. بیشتر کوچه های این محله باریک می باشد
                . تراکم ساختمانی بالا ، نبودن پلیس راهور ، متصل نبودن برخی کوچه های باریک به فاضلاب شهری از معایب این محله می باشد. سند نداشتن نیمی از خانه
                ها ، تراکم جمعیت ، عدم جمع آوری زباله در بعضی کوچه ها از دیگر مشکلات این خیابان می باشد. عدم آشنایی مردم این محله به قوانین شهر سازی ، عدم
                دسترسی ضلع غربی به بزرگراه امام علی ، و ناآگاهی مردم به قوانین و مقررات نیز می تواند از دیگر دلایلی باشد که شما را برای انتخاب دچار تردید کند .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Azadi" target="_blank">
                  بیشتر بخوانید : محله آزادی
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                دسترسی محله
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="تهران شمیران نو  - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دسترسی شمیران نو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ورود به این خیابان می توان از بزرگراه زین الدین
                شرق و از دو فرعی خیابان استقلال و خیابان هنگام اقدام کرد . دیگر ورودی های این محله از طریق خیابان های نعمت زاده، نونوار و فضلعلی می باشد.
                <br />
                محله شمیران نو ایستگاه مترو ندارد. ولی ایستگاه مترو
                علم و صنعت و سر سبز نزدیکترین مسیر را به محله شمیران نو دارند .خطوط اتوبوسرانی بیشترمحله را پوشش میدهد و راه ارتباطی با دیگر محله ها است  .
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز خرید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود مراکز متعدد خرید در این محله نیاز ساکنین این محله را برطرف کرده است . قیمت اجناس در این منطقه در حد پایین تری نسبت به محلات اطراف است .
                <br />
                پاساژ تهران ، پاساژ رضا ، پاساژ مهدیه، پاساژ گلها و پاساژ جزیره از پاساژهای شلوغ این محله می باشد .
              </Typography>
              <Typography className="h2" variant="h2">
                مدارس و مراکز فرهنگی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود مدارسی که جمعیت بالای این محله را پوشش می دهد از نکاتی است که برای ساکنین این محله حائز اهمیت است
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مدرسه شهید شهسواری ، مدرسه صابر عیسی زاده، دبیرستان آبسال ، مدرسه بنت الهدی صدر و مدرسه شهید پاکدامن از مدارس این خیابان هستند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Hakimiyeh" target="_blank">
                  بیشتر بخوانید : محله حکیمیه
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مراکز ورزشی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="مراکز ورزشی شمیران نو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مجموعه فرهنگی ورزشی شهدای شمیران نو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قشر نوجوان و جوانی که در این محل زندگی می کنند اوغات فراقت خود را صرف ورزش می کنند. جوانان سعی می کنند از زمان خود بهترین بازدهی را داشته باشند
                . نامدارانی از رشته کیوکوشین مانند  مصطفی دانشفر از ساکنین این محل می باشند . مجموعه ورزشی شهدای شمیران نو  از مراکز ورزشی این محل می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                آب و هوای محله شمیران نو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مجاورت این محله با جنگلهای لویزان آب و هوای خنک و خوبی را برای
                این منطقه بوجود آورده است . همچنین این امکان رابه ساکنین این محله می دهد که تفریحگاه خوبی برای گذراندن اوقات فراغت و ورزش صبحگاهی داشته باشند .
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت ملک در شمیران نو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قیمت ملک در این محله نسبت به محلات مجاور کمتر است. علت این امر را می توان پایین بودن قیمت زمین و عدم داشتن سند و  قولنامه ای بودن اقلیت خانه ها دانست
                . شلوغی ، ترافیک بالا ، عدم داشتن پارکینگ و جای پارک ، عدم رعایت قوانین آپارتمان نشینی از مواردی است که بر قیمت خانه در ماین محله تاثیرگذار است .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/buy_tehran_shemiranno" target="_blank">
                  خرید و فروش ملک آپارتمان در شمیران نو
                </Link>
                <br />
                <Link className="link" to="/Listings/rent_tehran_shemiranno" target="_blank">
                  رهن و اجاره ملک آپارتمان در شمیران نو
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                حرف آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دسترسی آسان و قیمت مناسب ملک امتیازات خوبی برای این محله است
                .اگر قصد خرید ملک در این محله را دارید باید قبل از آن این محله را به درستی بشناسید و نقاط قوت و ضعف آن را بدانید . با خواندن این مقاله از سایت هومینجا
                آشنایی نسبی با محله شمیران نو بدست می آورید که میتواند به انتخاب بهتر شما کمک کند . مشاورین هومینجا نیز به کمک شما می آیند تا خرید بهتری داشته باشید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Shemiran-No" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Shemiran-No" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Shemiran-No" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article16;