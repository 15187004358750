import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article07/01.jpg";
import photo02 from "../../images/mag/article07/02.jpg";
import photo03 from "../../images/mag/article07/03.jpg";
import photo04 from "../../images/mag/article07/04.jpg";
import photo05 from "../../images/mag/article07/05.jpg";
import photo06 from "../../images/mag/article07/06.jpg";
import photo07 from "../../images/mag/article07/07.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article07 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "این روزها با توجه به بالا رفتن قیمت ملک خرید مسکن بسیار دشوار شده است. بطوریکه خیلی از افراد بدون دریافت وام مسکن قادر به خرید ملک نیستند.";
    document.getElementsByTagName('meta')["keywords"].content = "شرایط دریافت وام مسکن | مراحل گرفتن وام مسکن | مدارک لازم دریافت وام مسکن | صفر تا صد دریافت وام مسکن 1401 | دریافت وام مسکن از بانک ها "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[6].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[6].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[6].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[6].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[6].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[6].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="نحوه دریافت وام مسکن - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[6].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این روزها با توجه به بالا رفتن
                قیمت ملک خرید مسکن بسیار دشوار شده است. بطوریکه خیلی از افراد بدون دریافت وام مسکن قادر به خرید ملک نیستند. برای دریافت وام نیاز است که اشخاص
                دارای شرایط لازم باشند. در این مقاله از <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link> به شرایط و نحوه دریافت <Link className="link" to="/way-to-get-house-loan" target="_blank"> وام خرید </Link> مسکن و مدارک لازم جهت دریافت تسهیلات و میزان مبلغ وام می پردازیم.
              </Typography>
              <Typography className="h2" variant="h2">
                وام مسکن چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وام مسکن، گونه‌ای از وام است،که ضمانت آن یک دارایی حقیقی است و جهت تأمین هزینه خریداری، ساخت‌وساز یا
                نوسازی ملک،
                توسط موسسات مالی
                ، شرکت‌های بیمه یا بانک‌ها در
                اختیار اشخاص حقیقی یا حقوقی قرار می‌گیرد.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع وام بانک مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="وام مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دریافت وام مسکن از بانک مسکن
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وام خرید مسکن به طور اختصاصی
                مربوط به بانک مسکن می باشد ولی از بانک های دیگر هم می توان اعتبار ارائه این تسهیلات را دریافت نمود. بانک مسکن، وام‌های متفاوتی برای مشتریان
                خود عرضه می کند که از معروف‌ترین آن می توان به اعتبار خرید مسکن اشاره کرد. در اینجا به انواع تسهیلاتی که بانک مسکن ارائه می دهد می پردازیم:
              </Typography>
              <Typography className="h3" variant="h3">
                وام مسکن یکم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این وام شامل افرادی می شود که برای
                اولین بار از تسهیلات وام مسکن استفاده می کنند. از شرایط دریافت این تسهیلات این است که متقاضیان
                در <LinkMui className="link" href="https://www.bank-maskan.ir/" target="_blank" rel="noreferrer">بانک مسکن</LinkMui> حساب سپرده داشته باشند. مبلغ این
                وام بستگی به شهر محل سکونت شما دارد. مبلغ اعتبار هر نفر در تهران ۸۰ میلیون تومان و در شهرهایی با جمعیت ۲۰۰ هزار نفر به بالا ۶۰ میلیون تومان و
                در شهرهای کوچک‌تر ۴۰ میلیون تومان می باشد. این مبالغ مربوط به سال ۱۴۰۱ است و در سال‌های آتی افزایش خواهد یافت. همچنین وام مسکن یکم زوجین در شهر
                تهران ۱۶۰ میلیون تومان، در شهرهایی با جمعیت ۲۰۰ هزار نفر به بالا ۱۲۰ میلیون تومان و در شهرهای کوچک‌تر ۸۰ میلیون تومان می باشد. برای دریافت این
                نوع اعتبار با نرخ سود ۸ درصد و نرخ سود ۶ درصد برای بناهای فرسوده، نیاز به سپرده گذاری است. میزان سپرده برای اعتبار ۸۰ میلیون تومانی ۴۰ میلیون
                ، برای وام ۶۰ میلیون تومانی ۳۰ میلیون و برای اعتبار ۴۰ میلیون تومانی ۲۰ میلیون تومان می باشد. این سپرده باید حداقل یک سال در حساب شما بماند.
              </Typography>
              <Typography className="h3" variant="h3">
                وام خرید مسکن  با اوراق حق تقدم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد دریافت این نوع وام بانک مسکن را دارید، باید اوراق حق تقدم خریداری کنید. متقاضیان
                این وام افرادی هستند که یا پس انداز کافی ندارند یا شرایط افتتاح حساب جدید برایشان مقدور نیست.این تسهیلات به دو صورت فردی و زوجین ارائه می‌شود.
                سقف اعتبار فردی ۲۰۰ میلیون تومان و سقف اعتبار زوجین با ارائه عقدنامه کتبی، ۴۰۰ میلیون تومان می باشد. حداکثر زمان بازپرداخت این وام ۱۴۴ ماه
                یعنی ۱۲ سال است. در ضمن به این مبلغ اعتبار، ۸۰ میلیون هم وام جعاله یا همان تعمیرات مسکن اضافه می‌شود که شما می‌توانید به صورت فردی مجموعا ۲۸۰
                میلیون و به صورت زوجین، ۴۸۰ میلیون تومان دریافت نمایید. حداکثر زمان بازپرداخت وام جعاله هم، ۵ سال می باشد که به اقساط وام مسکن اضافه می‌شود.
              </Typography>
              <Typography className="h3" variant="h3">
                وام مسکن جوانان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="وام مسکن جوانان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وام مسکن جوانان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد برای فرزندانشان فکر خرید خانه هستند. یکی از راه های خرید مسکن ،
                تهیه وام مسکن جوانان می باشد. لازمه دریافت چنین تسهیلاتی،
                سپرده‌گذاری از دوران نوجوانی در بانک مسکن است. شما می‌توانید با واریز ماهیانه ۲۰۰ تا ۴۰۰ هزار تومان طی ۵ تا ۱۵ سال، ۴۰۰ میلیون تا ۱ میلیارد
                تومان اعتبار دریافت نمایید.  از شرایط لازم برای دریافت این وام فیش حقوقی مستقل، نداشتن چک برگشتی و بدهی صاحب حساب می باشد. مدت زمان بازپرداخت
                اعتبار ۱ میلیارد تومانی با سود ۹ درصد، تا سقف ۲۰ سال تعیین شده است. البته این مبالغ  مربوط به سال ۱۴۰۱ است و در سال‌های آینده افزایش دارد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                مدارک مورد نیاز برای دریافت وام جوانان بانک مسکن:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }} >
                <li>اصل و کپی <Link className="link" to="/property-document" target="_blank"> سند </Link> مالکیت</li>
                <li>اصل و کپی <Link className="link" to="/building-certificate" target="_blank"> پروانه ساخت </Link></li>
                <li>اصل و کپی پایان کارهای ساختمانی</li>
                <li>اصل و کپی مبایعه‌نامه رسمی با <Link className="link" to="/house-tracking-code" target="_blank"> کد رهگیری </Link></li>
                <li>اصل و کپی صفحات شناسنامه و کارت ملی خریدار و فروشنده ملک</li>
                <li>قبض برق ساختمان، همراه با مستندات کد پستی ملک خریداری‌شده</li>
                <li>ارائه اصل دفترچه یا کارت</li>
                <li>اصل و کپی گواهی حقوق یا <Link className="link" to="/Business-Right" target="_blank"> جواز کسب </Link></li>
                <li>اصل و کپی سند رسمی</li>
                <li>اصل و کپی وکالت‌نامه (در صورت وجود وکیل)</li>
                <li>اصل و کپی اجاره‌نامه ملک (املاک اوقافی)</li>
                <li>تکمیل نمونه فرم‌های دریافت وام مسکن جوانان</li>
              </ul>
              <Typography className="h3" variant="h3">
                وام تعمیرات بانک مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="وام تعمیرات - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وام تعمیرات بانک مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه بافت ملک شما فرسوده است برای تعمیرات و نوسازی آن می‌توانید
                از وام تعمیرات بانک مسکن با نام جعاله استفاده کنید.
                این نوع وام روی سند ملک شما داده می شود. بنابراین حتی اگر ملک شما نیازی به بازسازی نداشته باشد، می‌توانید این وام را دریافت کرده و برای امور
                دیگری صرف نمایید. برای دریافت این وام باید اوراق مشخص شده بانک را خریداری کنید. سپس با ارائه سند ملک به عنوان ضمانت با مراجعه به شعب بانک
                مسکن، برای دریافت اعتبار اقدام نمایید. سقف این اعتبار ۸۰ میلیون تومان می باشد. حداکثر زمان بازپرداخت اقساط نیز ۸۰ ماه و سود آن ۱۷.۵ درصد است.
              </Typography>
              <Typography className="h3" variant="h3">
                وام ودیعه مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین می توان <LinkMui className="link" href="https://www.bank-maskan.ir/-217" target="_blank" rel="noreferrer">وام کمک ودیعه مسکن</LinkMui> تا
                سقف ۷۰ میلیون تومان با سود ۱۷.۵ درصد و بازپرداخت ۳۶ ماهه دریافت نمایید. سقف این اعتبار در تهران ۷۰ میلیون، در شهرهایی با
                جمعیت بالای ۲۰۰ هزار نفر ۶۰ میلیون و در شهرهای کوچک‌تر ۵۰ میلیون تومان می باشد. شرایط اعطای  بانک مسکن برای پرداخت این اعتبار به شرح زیر می باشد:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>افتتاح حساب و سپرده گذاری در بانک مسکن</li>
                <li>قبل از اینکه عقد قرارداد منزل جدید، با قرارداد فعلی خود به شعب بانک مراجعه کنید تا بانک برای شما پرونده تشکیل دهد.</li>
                <li>بعد از اینکه قرارداد منزل جدید را نوشتید، بانک بررسی‌های لازم را انجام می‌دهد و بعد از آن اعتبار را اعطا می‌کند.</li>
                <li>داشتن کد رهگیری</li>
                <li>سکونت در شهر محل درخواست حداقل 5 سال</li>
                <li>سرپرست خانوار بودن و نبودن ملکی به نام شما</li>
                <li>نگرفتن هیچ گونه تسهیلات مسکن در گذشته</li>
              </ul>
              <Typography className="h3" variant="h3">
                تسهیلات مطب و دفتر کار
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد <Link className="link" to="/Listing/813" target="_blank"> خرید مطب </Link> یا <Link className="link" to="/Listing/70" target="_blank"> دفتر کار </Link> دارید و یا برای خرید تجهیزات آن نیاز به وام دارید، می‌توانید از تسهیلات
                بانک مسکن بهره ببرید. برای دریافت این تسهیلات، باید گردش مالی حساب شما به حد کافی
                باشد و سپرده مورد نظر بانک را هم در حسابتان موجود باشد.
                سقف اعتبار این وام ۱ میلیارد و ۲۰۰ هزار تومان با بازپرداخت ۳۶ ماهه است. سقف خرید تجهیزات نیز ۵۰۰ میلیون تومان با  بازپرداخت ۲۴ ماه می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                شرایط لازم جهت دریافت تسهیلات مطب و دفتر کار:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>داشتن مدارک لازم جهت مطب و یا دفترکار مورد تایید شعبه</li>
                <li>دارای گردش مالی مناسب در حساب جاری و یا حساب سپرده ممتاز</li>
                <li>کسب <LinkMui className="link" href="https://donya-e-eqtesad.com/%D8%A8%D8%AE%D8%B4-%D8%A8%D9%88%D8%B1%D8%B3-6/663813-%D8%A2%D8%B4%D9%86%D8%A7%DB%8C%DB%8C-%D8%A8%D8%A7-%D8%B1%D8%AA%D8%A8%D9%87-%D8%A8%D9%86%D8%AF%DB%8C-%D8%A7%D8%B9%D8%AA%D8%A8%D8%A7%D8%B1%DB%8C" target="_blank" rel="noreferrer">رتبه اعتباری </LinkMui> BBB</li>
                <li>ارایه وثایق قابل قبول شعبه</li>
              </ul>
              <Typography className="h2" variant="h2">
                نحوه دریافت وام مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="وام مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دریافت وام مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای دریافت وام مسکن می توانید هم به صورت حضوری و هم آنلاین اقدام نمایید. برای دریافت تسهیلات بصورت آنلاین وارد سایت بانک مسکن شوید سپس
                بخش سامانه درخواست تسهیلات بروید و دادخواست  خود را ثبت نمایید. سن بالای 18 سال و باز کردن حساب سپرده از شرایط الزامی دریافت وام مسکن می باشد.
                <br />
                با توجه به شرایط سخت جامعه
                برای خانه دار شدن افراد زیادی به دنبال اطلاع از نحوه دریافت وام خرید مسکن و یا خرید و فروش وام مسکن از طریق انتقال امتیاز آن هستند. به طور
                کلی دریافت تسهیلات مسکن دارای یکسری شرایط می باشد که در زیر به آن ها می پردازیم اما امکان دارد که هر کدام از این شرایط به مرور زمان تغییر کند.
                <br />
                از تاریخ <Link className="link" to="/transferring-property" target="_blank"> جواز </Link> ملک کمتر از 15 سال گذشته باشد و ارزش ریالی آن به اندازه هشتاد درصد از مبلغ وام باشد.
                <br />
                شرایط سنی بالای 18 سال
                <br />
                نداشتن سابقه هیچ گونه چک برگشتی
                <br />
                نداشتن بدهی بانکی
                <br />
                باز کردن حساب سپرده گذاری
                <br />
                مطابقت درآمد افراد با جدول اقساط
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مهم در مورد خرید و فروش ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مدارک لازم برای اخذ وام خرید مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مدارکی که برای دریافت تسهیلات خرید مسکن مورد نیاز است به شرح زیر می باشد:
                <br />
                1- کارت حساب یا اوراق حق تقدم
                <br />
                2- اصل و کپی مبایعه‌نامه/ همراه با کد رهگیری
                <br />
                3- اصل و کپی صفحات سند
                <br />
                4- اصل و کپی بنچاق  ارائه تقسیم‌نامه جایگزین بنچاق در صورت نوساز بودن ملک)
                <br />
                5- اصل و کپی صورت‌مجلس تفکیکی (در صورتی که سند متعلق به آپارتمان باشد نیازی نیست)
                <br />
                6- اصل و کپی تمام پروانه‌های ساخت (اعم از تخریب و نوسازی، تغییر نقشه )
                <br />
                7- اصل و کپی ملک مورد خریداری/ دارای کدپستی
                <br />
                8- اصل و کپی تمام صفحات شناسنامه و کارت ملی خریدار/خریداران و فروشنده/فروشندگان
                <br />
                9- اصل و کپی وکالتنامه فروش به انضمام صفحات شناسنامه موکل و اصل و اصل و کپی کارت ملی و شناسنامه وکیل (در صورتی که ملک توسط وکیل به فروش می‌رسد)
                <br />
                10- گواهی اشتغال متقاضی تسهیلات
                <br />
                11- افتتاح حساب قرض‌الحسنه به نام خریدار
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط دریافت تسهیلات مسکن از بانک مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="تسهیلات مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دریافت تسهیلات مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جهت دریافت تسهیلات خرید مسکن ، بانک مسکن، این وام را به صورت غیر حضوری، به مشتریان خود، ارائه می کند. بنابراین
                ، افراد متقاضی، می توانند با توجه به شروط لازم و همچنین، دریافت فرم ج مسکن ملی، جهت دریافت تسهیلات خرید مسکن، از طریق این بانک اقدام نمایند.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط دریافت تسهیلات خرید مسکن از بانک ملی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.bmi.ir/" target="_blank" rel="noreferrer">بانک ملی </LinkMui>، وام خرید مسکن را به صورت غیر حضوری، به متقاضیان، ارائه می دهد. افراد متقاضی، می توانند با توجه به شرایط لازم از طریق بانک ملی اقدام نمایند.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>برای دریافت تسهیلات خرید مسکن از بانک ملی، افراد باید حساب مشخصی را در بانک ملی، باز کنند.</li>
                <li>افراد متقاضی دریافت وام خرید منزل مسکن نباید دارای سابقه چک برگشتی و سوء سابقه <Link className="link" to="/real-estate-fraud" target="_blank"> کلاهبرداری </Link> باشند.</li>
                <li>افراد نباید هیچگونه بدهی به بانک داشته باشند.</li>
              </ul>
              <Typography className="h2" variant="h2">
                شرایط دریافت تسهیلات خرید مسکن بانک سامان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="بانک سامان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دریاف تسهیلات مسکن بانک سامان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دریافت <LinkMui className="link" href="https://www.sb24.ir/loans/personal-loans/home-buy-loan" target="_blank" rel="noreferrer">تسهیلات خرید مسکن از بانک سامان</LinkMui> بصورت
                آنلاین انجام پذیر است. وام خرید مسکن بانک سامان، برای متقاضیان مستلزم شرایطی است که در ادامه به آن می پردازیم.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>بانک سامان، ملک مورد نظر را ارزش گذاری می کند.</li>
                <li>حداکثر به میزان 50 درصد از ارزش ملک، امکان دریافت وام، برای اشخاص وجود دارد.</li>
                <li>مبلغ بازپرداخت، بر اساس توان فرد دریافت کننده وام می باشد.</li>
                <li>شرط سنی دریافت کننده وام بالای 18 سال می باشد.</li>
                <li>افراد دارای سابقه چک برگشتی در سیستم بانکی قادر به دریافت وام نخواهند بود.</li>
                <li>ملک خریداری شده تا پایان پرداخت تسهیلات، در رهن بانک قرار می گیرد.</li>
              </ul>
              <Typography className="h2" variant="h2">
                افزایش سقف وام مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.cbi.ir/simplelist/4425.aspx" target="_blank" rel="noreferrer">شورای پول </LinkMui> و اعتبار
                مصوب وام مسکنی که از محل خرید این اوراق قابل دریافت است، تا سقف 200 میلیون تومان برای زوجین در <Link className="link" to="/tehran-zones" target="_blank"> شهر تهران </Link> قابلیت افزایش دارد. همچنین وام جعاله (تعمیرات مسکن)
                هم تا رقم  40 میلیون تومان افزایش یافت.
                <br />
                رقم وام مسکن از محل اوراق در شهرهای مرکز استان و جمعیت بالای 200 هزار نفر، برای زوجین 160 میلیون تومان و در سایر شهرها 120 میلیون تومان می باشد.
                <br />
                اوراق مسکن بانک مسکن در <LinkMui className="link" href="https://www.ifb.ir/" target="_blank" rel="noreferrer">فرابورس </LinkMui> منتشر خواهد شد و امکان خرید آن‌ها در کارگزاری‌های بورس و شعب بانک مسکن فراهم شده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                سوالات متداول
              </Typography>
              <Typography className="h3" variant="h3">
                آیا پس از دریافت وام مسکن امکان تعویض وثیقه وجود دارد؟
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  فرد از دو روش می تواند وثیقه را تعویض کند؛ روش اول اینکه سند ملک دیگری که به نام خودش
                  می باشد را برای تعویض وثیقه به بانک ارائه دهد.روش دوم به این شکل است که  می‌تواند اقدام به ارائه سند ملکی کند که به نام شخص دیگری غیر از خودش می باشد
                  . باید در نظر داشت که روش دوم موقتی است؛ یعنی فرد تنها می تواند بین ۶ ماه تا یک سال وثیقه ملک تحت مالکیت فرد دیگری را به‌عنوان وثیقه تعویضی به بانک ارائه دهد.
                </li>
              </ul>
              <Typography className="h3" variant="h3">
                شرایط متقاضی برای استفاده از تسهیلات مسکن یکم چیست؟
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>متقاضی دارای حداقل 18 سال سن باشد. تسهیلات گیرنده  درآمد مستقل داشته باشد و توانایی پرداخت اقساط را دارا باشد.</li>
                <li>متقاضی از تسهیلات بانک مسکن و سایر بانکها جهت خرید ، احداث واحد مسکونی و تعمیر واحد مسکونی(جعاله) استفاده نکرده باشد.</li>
                <li>همچنین متقاضی از امکانات دولتی برای تهیه مسکن استفاده نکرده باشد</li>
              </ul>
              <Typography className="h3" variant="h3">
                بر روی چه املاکی نمی توان از تسهیلات خرید مسکن استفاده کرد؟
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li> واحدهاي مسكوني كه غير قابل سكونت باشند.</li>
                <li>ساختمانهاي 1 و 2 واحدي كه بدون پروانه ساختماني ساخته شده باشند.</li>
                <li>واحدهاي ساختماني كه بعد از صدور پايان كار ساختماني و بدون پروانه ساختماني جديد ساخته شده باشند.</li>
                <li> واحدهاي ساختماني كه در آنها واحدهايي به صورت اضافه اشكوب بدون پروانه ساختماني ساخته شده باشند.</li>
                <li>واحدهايي كه آئين نامه 2800 شهرداري در آن رعايت نشده باشد.</li>
              </ul>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با هومینجا همراه باشید تا از اخرین اخبار و مطالب حوزه مسکن آگاه شوید . مشاوران و متخصصان هومینجا راحت ترین و کم هزینه ترین مسیر خانه دار شدن شما.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/way-to-get-a-house-loan" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/way-to-get-a-house-loan" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/way-to-get-a-house-loan" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article07;