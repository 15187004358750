import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article24/01.jpg";
import photo02 from "../../images/mag/article24/02.jpg";
import photo03 from "../../images/mag/article24/03.jpg";
import photo04 from "../../images/mag/article24/04.jpg";
import photo05 from "../../images/mag/article24/05.jpg";
import photo06 from "../../images/mag/article24/06.jpg";
import photo07 from "../../images/mag/article24/07.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article24 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "خیلی از شما بدنبال شنیدن حقایق عجیب و شگفت انگیز در مورد ملک هستید. ما در هومینجا به تعدادی از این دانستنی های جالب می پردازیم";
    document.getElementsByTagName('meta')["keywords"].content = "حقیقت های باورنکردنی املاک | مطالب جذاب در مورد املاک | اطلاعات عمومی املاک جالب | خانه های حیرت انگیز | معرفی خاص ترین املاک جهان | مکانهای دیدنی و منحصر به فرد"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[23].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[23].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[23].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[23].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[23].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[23].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="دانستنی های جالب املاک - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[22].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به احتمال زیاد خیلی از شما بدنبال
                شنیدن حقایق عجیب و شگفت انگیز در مورد ملک هستید. مثلا گرانترین خانه های جهان متعلق به چه کسانی است؟ یا خانه های شناور در آب در کدام کشورها
                قرار دارند؟ این ها سوالاتی هستند که دانستنش برای خیلی از افراد جالب است. ما در هومینجا به تعدادی از این سوالات پرداخته ایم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                1-گرانترین خانه های جهان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در میان میلیون ها ویلا و قصر مجللی که روی کره ی زمین وجود دارد خانه
                هایی هستند که به دلیل امکانات ویژه و منطقه ای که در آن واقع شده اند قیمت های باورنکردنی دارند. از جمله آن ها می توان به موارد زیر اشاره کرد:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                قصر باکینگهام
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از گران ترین خانه های دنیا <LinkMui href="https://fa.wikipedia.org/wiki/%DA%A9%D8%A7%D8%AE_%D8%A8%D8%A7%DA%A9%DB%8C%D9%86%DA%AF%D9%87%D8%A7%D9%85" className="link" target="_blank" rel="noreferrer">قصر باکینگهام</LinkMui> است
                که محل سکونت خانواده سلطنتی بریتانیا می باشد. کاخ باکینگهام شامل ۷۷۶ اتاق است.
                ۱۸۸اتاق مخصوص کارمندان و ۹۲ اتاق مخصوص دفاتر افراد مختلف کاخ می باشد. این کاخ                همچنین ۱۹ سالن تشریفاتی و ۷۸ سرویس بهداشتی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                عمارت Fleur de Lys بورلی هیلز
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="خانه ای در بورلی هیلز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  عمارتی در بورلی هیلز کالیفرنیا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                عمارت Fleur de Lys واقع در کالیفرنیا به
                ارزش ۱۲۵ میلیون دلار از دیگر خانه های گران قیمت جهان می باشد. این عمارت دارای یک زیر زمین ۳۰۰۰ فوت مربعی برای شراب، کتاب خانه دو
                طبقه، اتاق مخصوص نگهداری کارد و چنگال، اتاق مخصوص باله، چند زمین تنیس، استخر، مرکز آب درمانی و همچنین یک آشپزخانه تجاری می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                عمارت Seven the Pinnacle بیگ اسکای
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                Seven the Pinnacle در مونتانا به ارزش ۱۵۵ میلیون دلار نیز از گران ترین ملک های موجود می باشد. از امکانات این خانه جذاب می توانیم
                به سالن ورزشی، سیستم گرمایش از کف، تله کابین به سمت مناطق برفی، چند استخر و همچنین         زیرزمین اشاره کنیم.
              </Typography>
              <Typography className="h2" variant="h2">
                2- کدام کشور خانه قدیمی ندارد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ژاپن یک ملک بعد از ۲۰ یا ۳۰ سال تمام ارزش خود را
                از دست می دهد. هنگامی که شخصی بعد از این مدت از خانه نقل مکان کند خانه وی تخریب می شود و خانه جدیدی در همان مکان ساخته می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                3- بلندترین برج های دنیا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید برای شما جالب باشد که  که بدانید بلندترین
                برج های  ساخته شده دنیا، کجا قرار دارد و نامش چیست؟ ما در هومینجا بلاگ به معرفی چند مورد از بلندترین برج ها از لحاظ ارتفاع پرداخته ایم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                برج الخلیفه واقع در دبی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="برج خلیفه دبی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بلندترین برج دنیا در دبی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج الخلیفه با ۸۲۸ متر ارتفاع  بلندترین برج جهان می باشد. این برج دارای ۱۶۳ طبقه است و ساخت آن  ۶ سال زمان برد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                برج شانگهای چین
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دومین آسمان خراش بلند دنیا برج شانگهای
                چین می باشد. این برج ۶۳۲ متر ارتفاع دارد. ساخت پروژه برج شانگهای چین در سال ۲۰۱۵ به اتمام رسید. این آسمانخراش چینی شامل ۹ سازه استوانه ای شکل
                است. نمای این سازه به صورت         مارپیچی و خاص طراحی شده است . ساختار این برج به گونه ای است که در برابر طوفان و زلزله و باد مقاوم می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                4- عجیب ترین خانه های دنیا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه هایی در جهان وجود دارند که دارای معماری و طراحی خاصی هستند. ما در این بخش به معرفی این خانه های عجیب می پردازیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                خانه‌ای با قابلیت تنظیم ارتفاع و چشم‌انداز دلخواه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="خانه اب هوشمند در کشور چک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خانه ای با تنظیم ارتفاع
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این خانه در  ۱۰۰ کیلومتری شمال شرقی پراگ (پایتخت جمهوری چک) ساخته شده است. خانه
                بصورتی ساخته شده است که قابلیت تنظیم ارتفاع دارد. این خانه به بالا و پایین حرکت می کند و
                به جهات مختلف می چرخد. با استفاده از این قابلیت می توان چشم‌انداز دلخواه خود را ایجاد کرد.
              </Typography>
              <Grid className="box">
                <LinkMui className="link" href="https://homeinja.com/Tehran-cafe-with-hooka" target="_blank" rel="norefferer">
                  کافه های قلیان دار تهران
                </LinkMui>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                خانه ای از ظروف چینی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه چینی (Porcelain House)  از عجیب ‌ترین خانه ‌های دنیا می باشد. این خانه در منطقه هبی در تیانجین
                چین واقع شده است. این خانه چینی حدود ۱۰۰ سال از عمرش می گذرد و در محوطه ای به وسعت ۴۲۰۰ متر مربع بنا شده                است.
              </Typography>
              <Typography className="h2" variant="h2">
                5- باریک ترین خانه جهان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="باریک ترین خانه دنیا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  باریکترین خانه دنیا در لهستان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باریک ترین خانه دنیا، خانه ی باریک در خیابان 22 کلوندای شهر ورشو در کشور لهستان است.
                صاحب این خانه نویسنده می باشد. این خانه باریک بین دو ساختمان قرار دارد. باریک ترین بخش این خانه 92 سانتی متر و پهن ترین قسمت آن 152 سانتی متر می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                6- گرانترین بازار املاک و مستغلات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                موناکو برای نهمین سال پیاپی با ۱ میلیون دلار برای ۱۷ متر مربع بعنوان
                گرانترین بازار جهانی مسکن شناخته شده است. بدنبال موناکو، هنگ کنگ با ۱ میلیون دلار برای ۲۰ متر جایگاه دوم گرانترین بازار مسکن را به خود اختصاص داده است.
              </Typography>
              <Typography className="h2" variant="h2">
                7- معروف ترین برج های کج دنیا
              </Typography>
              در این بخش به معرفی معروف ترین برج های کج در جهان می پردازیم. اما باید این نکته را یاداوری کرد کهه این برج ها از ابتدا کج نبودند و به مرور زمان و تحت تاثیر مسائل گوماگون از جمله تغییرات آب و هوایی یا نشست زمین و زلزله کج شدند.
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                برج کج زورهوزن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج زورهوزن در کشور آلمان می باشد. ارتفاع زورهوزن
                تقریبا ۲۸ متر می باشد. قدمت این برج به قرن ۱۳ میلادی برمی گردد. برج زورهوزن حدود ۵٫۱۹ درجه خمیدگی دارد و میزان کج بودن آن از برج  پیزا هم بیشتر می باشد.
              </Typography>
              <Grid className="box">
                <LinkMui className="link" href="https://homeinja.com/real-estate" target="_blank" rel="noreferrer">
                  مشاور املاک کیست ؟
                </LinkMui>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                برج کج پیتزا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیزا معروف ترین برج کج دنیا می باشد. این برج در توسکانی ایتالیا قرار دارد. قدمت این برج مربوط به قرن ۱۲ میلادی است. ساخت برج پیزا حدود ۲ قرن
                (از سال ۱۱۷۳ تا ۱۳۷۰) بطول انجامید.
              </Typography>
              <Typography className="h2" variant="h2">
                8- زشت ترین خانه جهان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="زشت ترین خانه" className="image" />
                <figcaption className="image_caption">
                  خانه ای زشت در بلژیک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بلژیک زشت ترین خانه های جهان را دارد. این موضوع بدلیل نبود قوانین سخت گیرانه می باشد. بعضی از این خانه‌ها به‌قدری زشت هستند که کسی تمایلی به بازدید آن‌ها ندارد.
              </Typography>
              <Typography className="h2" variant="h2">
                9- گرانترین خیابان های ایران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بر خلاف اینکه همه خیابان <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank"> فرشته </Link> را بهترین و گرانترین
                خیابان می دانند اما خیابان <Link className="link" to="/Listings/buy_tehran_golestanshomali" target="_blank"> گلستان شمالی </Link>  <Link className="link" to="/Listings/buy_tehran_aghdasieh" target="_blank"> اقدسیه </Link> بعنوان گرانترین خیابان تهران می باشد. در شهر مشهد هم مناطق احمد آباد، سجاد و فرامرز بعنوان بهترین و گرانترین
                خیابان های مشهد محسوب می شوند.اکثر اعیان نشینان و ثروتمندان مشهدی در این خیابان ها سکونت دارند. در اصفهان نیز منطقه مرداویج این عنوان را از آن خود کرده است.
              </Typography>
              <Typography className="h2" variant="h2">
                10- موفق ترین مشاور املاک دنیا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جید میلز با بیش از
                6 میلیارد دلار فروش حرفه ای بعنوان اولین مشاور املاک دنیا شناخته می شود. مشتریان میلز افراد مشهور هالیوود مانند بریتنی اسپیرز،جنیفر آنیستون،چارلی شین می باشند.
              </Typography>
              <Typography className="h2" variant="h2">
                11- لوکس ترین خانه های متحرک دنیا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="خانه متحرک فولکنر" className="image" />
                <figcaption className="image_caption">
                  لوکس ترین خانه متحرک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شرکت آلمانی فولکنر  عنوان گران‌ترین خانه متحرک دوسلدورف با قیمت  ۱.۷ میلیون دلار را از آن خود کرده است. تنوع امکانات قابل توجه برای فضای داخلی ولکنر پرفرمنس S،
                مشابه هتل پنج‌ستاره
                یا خانه‌های لوکس افراد متمول می باشد. کف‌پوش چوب یا گرانیت، تخت‌خواب راحت دونفره، مبلمان چرم و حتی سیستم صوتی ۳۵۴ هزار دلاری از امکانات این خانه متحرک می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                12-ترسناک ترین خانه جهان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت به معرفی خانه های ترسناک در دنیا می پردازیم. برای هر کدام از این خانه ها داستان وحشتناکی تعریف شده است که خیلی از آن ها در حد یک افسانه می باشد. با این وجود کمتر کسی جرات نزدیک شدن به آن را پیدا می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                قله فرانکلین
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه ترسناک قله فرانکلین که به خانه جن زده معروف است، مخوف ترین خانه
                جهان بشمار می رود. این خانه دارای ۳۰ اتاق می باشد. اتاق های این خانه بسیار پرپیچ و خم هستند. این موضوع یکی از دلایل ترسناک بودن این خانه می              باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                ویلای جزیره کارلتون
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این خانه در سال
                ۱۸۹۰ در نیویورک ساخته شد. اووکیف صاحب این ویلا بود. چند روز قبل از اینکه خانواده وی در این خانه اقامت کنند همسر اوکیف فوت کرد.  در شب اول اقامت نیز خود اوکیف
                بر اثر سکته قلبی درگذشت. پس از این جریان کسی حاضر به اقامت و بازسازی در این خانه نشد. به همین دلیل این خانه نیز یکی از ترسناک ترین خانه های جهان بشمار می رود.
              </Typography>
              <Typography className="h2" variant="h2">
                13- بزرگترین خانه های جهان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بزرگترین ملک های جهان مربوط به کشور استرالیا می باشد. متوسط خانه های
                استرالیایی حدود ۲۳۲ متر مربع می باشد. بعد از استرالیا بزرگترین خانه های جهان مربوط به کشور آمریکا می باشد. متوسط خانه های امریکا تقریبا ۱۷۶ متر مربع می باشد.
                <br />
                ما در بلاگ هومینجا سعی کردیم تا به تعدادی از دانستنی های جالب در مورد ملک های جهان بپردازیم. امیدواریم این مطالب برای شما مفید و جذاب بوده باشد.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/real-estate-interesting-things" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/real-estate-interesting-things" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/real-estate-interesting-things" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article24;