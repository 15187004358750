import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article12/01.jpg";
import photo02 from "../../images/mag/article12/02.jpg";
import photo03 from "../../images/mag/article12/03.jpg";
import photo04 from "../../images/mag/article12/04.jpg";
import photo05 from "../../images/mag/article12/05.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import SideBar from "./SideBar";

class Article12 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "لاندری روم یک واژه انگلیسی است و به اتاق لباسشویی یا رختشورخانه اشاره دارد.فضای کوچکی که به شستن و خنک کردن لباس اختصاص می دهیم.";
    document.getElementsByTagName('meta')["keywords"].content = "لاندری روم در آپارتمان | طراحی لاندری روم | ویژگی های اتاق لباسشویی | نکاتی که در مورد لاندری روم باید در نظر گرفت | رختشویخانه چیست"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[11].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[11].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[11].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[11].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[11].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[11].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="لاندری روم چیست، کاربردهای لاندری روم در طراحی در طراحی داخلی خانه - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[11].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید برای خیلی از شما واژه لاندری روم به گوشتان آشنا باشد. اما اینکه لاندری روم چیست و چه ویژگی هایی دارد موضوعی است که در بلاگ هومینجا به آن پرداخته ایم.
                <br />
                طراحی و <Link className="link" to="/small-house-decoration" target="_blank">دکوراسیون</Link> بیشتر خانه های امروزی شباهت بسیاری به خانه های اروپایی پیدا کرده است.
                یکی از مواردی که برای مدرن تر کردن خانه ها بکار می رود لاندری روم است. 
              </Typography>
              <Typography className="h2" variant="h2">
                لاندری روم چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لاندری روم یا اتاق لباسشویی (یا به اصطلاح قدیمی ها رختشویخانه) که کلمه ای انگلیسی می باشد ، مکانی است که برای شست شوی البسه و خشک شویی و اتوکشی مورد استفاده قرار می گیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در قدیم زیرزمین خانه را برای شست و شو
                انتخاب می کردند اما امروزه در خانه های بزرگ تر یک بخش مجزا را برای این منظور مد نظر قرار می دهند. اتاق لباسشویی فضایی خدماتی بشمار می رود
                اما می تواند بسایر لوکس و زیبا طراحی شود. این فضا تنها محدود به خانه نمی باشد بلکه تمامی هتل ها و بیمارستان ها نیز مجهز به این فضا می باشند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Spring-Cleaning" target="_blank">
                  بیشتر بخوانید : نکات خانه تکانی عید سریع
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                لوازم و تجهیزات لاندری روم
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="لوازم لاندری روم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  لوازم و تجهیزات لاندری روم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در اتاق لباسشویی
                علاوه بر ماشین لباسشویی، می توان خشک کن نیز قرار داد. اتو و میزاتو، جالباسی، مایعات و پودر رختشویی نیز از دیگر موارد مورد نیاز اتاق لباسشویی می باشد.
                <br />
                قفسه بندی این فضا برای قراردادن لوازم و تجهیزات شستشو، <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> ملحفه ها </Link> ، حوله ها و پارچه های تمیز کننده و جلوگیری از ریخت و پاش نیز از موارد مهم دیگر می باشد.
                <br />
                پیشخوان نیز برای جدا کردن لباس ها و تاکردن و مرتب کردن ان ها نیز می تواند انتخاب مناسبی باشد. اما اگر فضای کافی ندارید می توانید از میزتاشو استفاده کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/pouf" target="_blank">
                  بیشتر بخوانید : پاف چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بهترین مکان قرارگیری لاندری روم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه اتاق خواب محل جمع آوری لباس های کثیف و قراردادن
                لباس های تمیز است یکی از مکان های مناسب برای این فضا می تواند کنار <Link className="link" to="/children-room-decoration" target="_blank"> اتاق خواب </Link>  باشد. محل دیگری که برای رختشویخانه می توان در نظر گرفت کنار حمام است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که حمام محل تعویض لباس های کثیف می باشد نزدیک حمام نیز می تواند گزینه مناسب دیگری برای تعبیه لاندری روم باشد. البته با توجه به اینکه در اغلب آپارتمان های امروزی با مشکل کمبود فضا مواجه هستیم می توان از بالکن یا تراس نیز برای اتاق لباسشویی استفاده کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مهمترین مزایای قرار دادن رختشویخانه درتراس یا بالکن خشک شدن سریع لباس ها به دلیل تابش نور خورشید و فضای آزاد می باشد. هم چنین مطالعه مقاله <Link className="link" to="/fengshui" target="_blank"> فنگ شویی </Link>  می تواند به شما در طراحی مناسب منزل کمک کند.
              </Typography>
              <Typography className="h2" variant="h2">
                ابعاد لاندری روم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                انتخاب فضای اختصاص داده شده مناسب اتاق لباسشویی یکی از موضوعاتی
                است که باید به آن توجه داشت .زیرا کوچک بودن بیش از حد مزیت محسوب نمی شود. حداقل فضایی که باید برای این فضا در نظر داشت 1.5 متر در 1.5 متر می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                ویژگی های مهم در اتاق لاندری روم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای اینکه بهترین کارایی را از اتاق لباسشویی داشته باشیم نیاز است که چند ویژگی مهم را مد نظر قرار دهیم
              </Typography>
              <Typography className="h3" variant="h3">نور پردازی:</Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="نور لاندری روم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ویژگی های مهم در اتاق لاندری روم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نور پردازی این فضا باید به گونه ای باشد که لک روی
                لباس قابل مشاهده باشد و میزان کثیفی لباس مشخص باشد. بهترین گزینه برای تامین نور، استفاده از نور طبیعی می باشد.
                در صورت عدم امکان تعبیه پنجره می توان از چراغ های ال ای دی دار زیر کابینت ها یا یک <Link className="link" to="/chandelier-in-decoration" target="_blank"> لوستر </Link>  مناسب استفاده کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/keeping-apartment-flower" target="_blank">
                  بیشتر بخوانید : نکاتی که در مورد نگهداری گیاهان اپارتمانی باید بدانیم
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                تهویه مطبوع:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اتاق لباسشویی به دلیل اینکه در معرض رطوبت می باشد می تواند مرکز تجمع بوهای نامطبوع باشد. برای پیشگیری از بوی نم باید به فکر تعبیه تهویه مناسب و قوی بود. در صورتی که خانه ی شما <Link className="link" to="/smart-home" target="_blank"> هوشمند </Link>  باشد تهیه به صورت اتوماتیک عمل می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                عایق صوتی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به استفاده از ماشین لباسشویی و خشک کن امکان سروصدای زیاد حین کار وجود دارد. برای کاهش صدا بهتر است از عایق های صوتی و صفحات ضد لرزش استفاده کنیم.
              </Typography>
              <Typography className="h3" variant="h3">
                رنگ مناسب:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باتوجه به فضای کم لاندری روم بهتر است که از رنگ های روشن برای این فضا استفاده کنیم. رنگ روشن باعث انعکاس نور بیشتر نیز می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                کف پوش های مناسب:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="ویژگی لاندری روم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کف پوش های مناسب برای لاندری روم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به دلیل استفاده از آب انتخاب کفپوش مناسب بسایر حائز اهمیت است. بهتر است از کفپوش های ضد آب و ضد رطوبت برای اتاق لباسشویی
                استفاده کنید . در این صورت از ایجاد هزینه های اضافی که شامل تعمیر خرابی های ایجاد شده از رطوبت می شوید در امانید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نکات مهم در طراحی لاندری روم
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                چنانچه فضای اختصاص داده شده به لاندری روم کوچک باشد بهتر است برای ایجاد نظم بهتر، <Link className="link" to="/accent-wall" target="_blank"> دیوارها </Link>  را تا حد امکان قفسه بندی کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای انتخاب محل مناسب رختشورخانه باید دقت لازم را داشت چون تجهیزات قرار داده شده در این اتاق سروصدای بالایی دارند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دسترسی به شیر آب و چاه فاضلاب نیز نکته بسیار مهم دیگر می باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                قرار دادن تهویه هوای مناسب بدلیل استفاده از مواد شوینده حائز اهمیت است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بهتر است در اتاق های کوچک از میز و رخت آویز تاشو استفاده کرد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سینک ظرفشویی در لاندری روم برای شستشوی لباس با دست بسیار کاربردی است.
              </Typography>
              <Typography className="h2" variant="h2">
                نتیجه گیری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="چیدمان لاندری روم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نکات مهم در طراحی و دکوراسیون لاندری روم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بنابراین می توان نتیجه گرفت با توجه به اینکه ماشین های لباسشویی در اکثریت خانه های امروزی در آشپزخانه قرار دارند، به دلیل استفاده از آب و ایجاد رطوب ممکن است به کابینت های آشپزخانه آسیب برسانند. بنابراین برای پیشگیری از چنین مشکلاتی هومینجا در این <Link className="link" to="/laundry-room" target="_blank"> مقاله </Link> ، به شما پیشنهاد فضایی جداگانه را برای شستن لباس ها و همچنین خشک کردن آن ها می دهد. برای داشتن همچین فضایی می توان به راحتی بخش هایی از فضای خانه را که استفاده مفیدی برای ما ندارند به این موضوع اختصاص دهیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با انجام این تغییرات کوچک و اعمال یک طراحی مناسب می توان از این مکان ها بعنوان اتاق لباسشویی استفاده کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رختکن حمام یا حتی زیر زمین یک خانه را می توان با طراحی منحصر بفرد به اتاقی برای شستشوی لباس ها و جمع آوری آن ها تبدیل کرد.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listing/1138'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید آپارتمان لاکچری در منطقه یک تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listing/457'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان لاکچری در منطقه یک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/best-restaurant-tehran-region1'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    بهترین رستوران های منطقه یک تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/north-of-Tehran-cafe'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    بهترین کافه های منطقه یک تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/laundry-room" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/laundry-room" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/laundry-room" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article12;