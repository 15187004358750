import { AppBar, Badge, Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Modal, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import '../styles/nav.css';
import MenuLogo from '../images/menu.png';
import CloseLogo from '../images/closeLogo.png';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import axios from 'axios';
import AuthModal from '../component/authmodal';
import { baseUrl, convertToPersianNumber } from './materilas/vmp';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LogoutIcon from '@mui/icons-material/Logout';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import moment from "jalali-moment";
import profile from '../images/profile.png';
import login from '../images/login.png';
import borderActive from '../images/borderActive.png';
class Nav extends React.Component{

    isMount = false;
    interval1 = '';
    interval2 = '' ;
    interval3 = '' ;
    prevScrollpos = window.pageYOffset;
    userToken = localStorage.getItem('tokenUserHomeInja');

    constructor(props){
        super(props);
        this.state={
          display:false,
          statusUser:false,
          pictureUser:null,
          notif:1,
          openAuthModal:false,
          userType:0,
          openNotification: false,
          notificationInfo: [],
          disableNotifButton : false ,
          openMenu: false ,
          anchorEl:null,
        }
    }

    componentDidMount(){
      this.isMount = true;
      this.linkClickActive();
      if (this.isMount){
        this.checkStatus();
        window.addEventListener('resize',() =>{
            this.setState({display:false})
        });
        window.addEventListener('scroll',() => {
          if (window.location.pathname === '/' && window.innerWidth >= 900){ 
            this.onScrolling()
          }
          else{
            var n = Array.from(document.getElementsByClassName("navbar"));
            n.forEach(ne => {
              ne.style.top = 0;
            })
          }
        })
        this.interval1 = setInterval(() => {
          this.checkStatus();
          if(localStorage.getItem('infoUserHomeInja')){
            this.getNotification();
          }
        }, 43200000);
        this.interval2 = setInterval(() => {
          if(localStorage.getItem("notification")){
            let info = JSON.parse(localStorage.getItem("notification"));
            this.setState({notificationInfo:info,notif:info.length});
          }else{
            this.setState({notificationInfo:[],notif:0});
          }
        },5000)
      }
    }

    componentWillUnmount(){
      this.isMount = false;
      window.removeEventListener('resize',() =>{});
      clearInterval(this.interval1);
      clearInterval(this.interval2);
    }

    linkClickActive = () => {
      const navLinks = document.getElementsByClassName('linkNav');
      for (let i = 0; i < navLinks.length; i++) {
        navLinks[i].addEventListener('click', function () {
          let current = document.getElementsByClassName('active');
          if(current.length !== 0){
            current[0].className = current[0].className.replace(' active', '');
            this.className += ' active';
          }else{
            this.className += ' active';
          }
        });
      }
    }

    menuClick = () =>{
      this.setState({display:!this.state.display})
    }

    onScrolling = () => {
      this.onCloseMenu();
      var currentPos = window.pageYOffset;
      var n = Array.from(document.getElementsByClassName("navbar"));
      if (this.prevScrollpos >= currentPos){
        n.forEach(ne => {
          ne.style.top = 0;
        })
      } else{
        n.forEach(ne => {
          ne.style.top = '-90px';
          ne.style.transition = 'top 0.3s';
        })
      }
      this.prevScrollpos = currentPos;
    }

    checkStatus = () =>{
      var token = localStorage.getItem('tokenUserHomeInja');
      Promise.resolve(axios({
        method:'GET',
        url:baseUrl + 'v1/user/authCheck',
        headers:{'Authorization':'bearer ' + token},
      }))
      .then(() =>{
        this.infoUser(token);
      })
      .catch(() =>{
        localStorage.clear('infoUserHomeInja');
        this.setState({statusUser:false})
      })
    }

    infoUser = (token) =>{
      Promise.resolve(axios({
        method:'GET',
        url:baseUrl + 'v1/user/info',
        headers:{'Authorization':'bearer ' + token},
      }))
      .then((res) =>{
        this.getNotification()
        localStorage.setItem('infoUserHomeInja',JSON.stringify(res.data.data));
        this.setState({statusUser:true,pictureUser:res.data.data.picture_path,userType:res.data.data.user_type_id})
      })
      .catch(() =>{
        this.setState({statusUser:false})
      })
    }

    openAuth = (type) =>{
      switch (type) {
        case 'L':
          this.setState({openAuthModal:true,modalType:type,});
          this.deleteActiveClass();
          break;
        case 'R':
          this.setState({openAuthModal:true,modalType:type,});
          this.deleteActiveClass();
          break;
        default:
          break;
      }
      this.setState({openAuthModal:true});
    }

    onCloseAuth = () =>{
      this.setState({openAuthModal:false})
    }

    getNotification = () => {
      Promise.resolve(axios({
        method:'GET',
        url:baseUrl + 'v1/notification/get',
        headers:{'Authorization':'bearer ' + this.userToken},
      }))
      .then((res) =>{
        this.setState({notificationInfo:res.data.data,notif:res.data.data.length});
        let info = JSON.stringify(res.data.data);
        localStorage.setItem("notification",info);
      })
      .catch(() =>{
        this.setState({notificationInfo:[],notif:0});
        localStorage.removeItem("notification");
      })
    }

    onClickNotification = () => {
      this.setState({openNotification:true});
      this.deleteActiveClass();
    } 

    onCloseNotification = () => {
      this.setState({openNotification:false});
    }

    createNotification = () => {
      let result = [];
      let info = this.state.notificationInfo ;
      info.forEach((element,index) => {
        result.push(
          <Grid sx={{bgcolor:"white",padding:"10px",borderRadius:"8px",margin:"5px 0",border:"1px solid #dcdada"}} key={index}>
            <Grid sx={{display:"flex",justifyContent:"space-between"}}>
              <Grid>
                <Typography sx={{display:"inline-block",fontFamily:"shabnam",fontSize:"16px",color:"#01A7C4"}}>
                  نوع اعلان :
                </Typography>
                <Typography sx={{display:"inline-block",fontFamily:"shabnam",fontSize:"16px",marginRight:"5px",color:"#01A7C4"}}>
                  { element.type === "P" ?
                      "خرید"
                    : element.type === "W" ?
                        "برداشت"
                      : element.type === "D" ?
                          "واریز"
                        : element.type === "T" ?
                            "تیکت"
                          : " "
                  }   
                </Typography>
              </Grid>
              <Typography sx={{fontFamily:"shabnam"}}>
              {convertToPersianNumber(moment(element.created_at).format('jYYYY/jMM/jDD'))}
              </Typography>
            </Grid>
            <Grid sx={{width:"100%",height:"2px",bgcolor:"#eee",margin:"10px 0"}}></Grid>
            <Typography sx={{display:"inline-block",fontFamily:"shabnam",fontSize:"16px",margin:"10px 0"}}>
              {element.des}
            </Typography>
          </Grid>
        )
      });
      return result;
    }

    deleteNotification = () => {
      this.setState({openNotification:false,disableNotifButton:true});
      Promise.resolve(axios({
        method:'POST',
        url:baseUrl + 'v1/notification/view',
        headers:{'Authorization':'bearer ' + this.userToken},
      }))
      .then((res) =>{
        this.setState({notificationInfo:[],notif:0});
        localStorage.removeItem("notification");
      })
      .catch(() =>{
        this.getNotification();
      })
      .finally(() => {
        this.setState({disableNotifButton:false});
      })
    }

    logOut = () =>{
      Promise.resolve(axios({
        method:'POST',
        url:baseUrl + 'v1/user/logout',
        headers:{'Authorization':'bearer ' + this.userToken},
      }))
      .then((res) =>{
        window.location.replace('/');
        localStorage.removeItem('tokenUserHomeInja');
        localStorage.removeItem('infoUserHomeInja');
      })
      .catch(() =>{
        
      })      
    }

    onClickMenu = (event) => {
      event.preventDefault();
      let toggle = !this.state.openMenu ;
      this.setState({openMenu:toggle,anchorEl:event.currentTarget});
    }
    
    onCloseMenu = () => {
      this.setState({openMenu:false});
      this.deleteActiveClass();
    }

    deleteActiveClass = () => {
      let activeElement = document.getElementsByClassName("active");
      if(activeElement.length > 0){
        activeElement[0].className = activeElement[0].className.replace(' active', '');
      }
    }

    render(){
        return(
          <AppBar className="navbar" sx={{boxShadow:this.state.display?"none !important":" 0px 7px 8px rgba(0, 0, 0, 0.25), inset -1px 1px 4px rgba(0, 0, 0, 0.25), inset 1px -1px 4px rgba(0, 0, 0, 0.25) !important"}}>
            <Grid item xl={12} lg={12} md={12} className='navbarGridOrg'>
              <Grid item xl={1} lg={1} md={1} className='gridLogo'>
                <Link to={'/'} onClick={this.deleteActiveClass} style={{display:"flex",alignItems:'center',justifyContent:"center"}}>
                  <Typography component={'img'} className='logo' src={Logo} alt="main-logo"></Typography>
                </Link>
              </Grid>
              <Grid item xl={6} lg={7} md={7} className='gridLink'>
                <Link to={'/'} className='linkNav'>
                  <Typography component={'p'} variant='p' className='textNav'> صفحه اصلی</Typography>
                  <Typography component={"img"} src={borderActive} className='activeNav' />
                </Link>
                <Link to={'/Listings'} className='linkNav'>
                  <Typography component={'p'} variant='p' className='textNav'> آگهی‌ها</Typography>
                  <Typography component={"img"} src={borderActive} className='activeNav' />
                </Link>
                <Link to={'/HomeMag'} className='linkNav'>
                  <Typography component={'p'} variant='p' className='textNav'> هوم مگ</Typography>
                  <Typography component={"img"} src={borderActive} className='activeNav' />
                </Link>
                <Link to={'/Agancy'} className='linkNav'>
                  <Typography component={'p'} variant='p' className='textNav'> آژانس‌ها</Typography>
                  <Typography component={"img"} src={borderActive} className='activeNav' />
                </Link>
                <Link to={'/Contact'} className='linkNav'>
                  <Typography component={'p'} variant='p' className='textNav'> تماس</Typography>
                  <Typography component={"img"} src={borderActive} className='activeNav' />
                </Link>
                <a className='linkNav' style={{cursor:"pointer"}} href="/#" 
                  onClick={(event) => {this.onClickMenu(event)}}
                >
                  <Typography component={'p'} variant='p' className='textNav'> اطلاعات</Typography>
                  <Typography component={"img"} src={borderActive} className='activeNav' />
                </a>
                <Menu
                  open={this.state.openMenu}
                  onClose={this.onCloseMenu}
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  sx={{"& .MuiPaper-root":{bgcolor:"#14C9E8"}}}
                >
                  <MenuItem>
                    <Link to={'/AboutUs'} className='menuLink' onClick={this.onCloseMenu}>
                      <Typography component={'p'} variant='p' className='textNav'> درباره ما</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to={'/Terms'} className='menuLink'>
                      <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu}> قوانین</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to={'/FAQ'} className='menuLink'>
                      <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu}> سوالات متداول</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to={'/tehran-zones'} className='menuLink'>
                      <Typography component={'p'} variant='p' className='textNav' onClick={this.onCloseMenu}> معرفی مناطق تهران</Typography>
                    </Link>
                  </MenuItem>
                </Menu> 
              </Grid>
              {
                this.state.statusUser ?
                <Grid item xl={3} lg={3} md={3} className='gridButton' style={{marginRight:'10%'}}>
                  <Tooltip title={<Typography sx={{fontFamily:"shabnam",fontSize:"12px"}}>اعلان ها</Typography>}>
                    <Badge badgeContent={this.state.notif} max={99} overlap='circular' color='primary' onClick={this.onClickNotification}>
                      <IconButton style={{backgroundColor:'white'}} size='small'>
                        <NotificationsIcon style={{color:'black',width:35,height:35}}/>
                      </IconButton>
                    </Badge>
                  </Tooltip>  
                  <Tooltip title={<Typography sx={{fontFamily:"shabnam",fontSize:"12px"}}>پروفایل</Typography>}>
                    <IconButton style={{backgroundColor:'white'}} size='small' component={Link} to={'/Profile'} onClick={this.deleteActiveClass}>
                      <PersonIcon style={{color:'black',width:35,height:35}}/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Typography sx={{fontFamily:"shabnam",fontSize:"12px"}}>خروج</Typography>}>
                    <IconButton style={{backgroundColor:'white'}} size='small' onClick={this.logOut}>
                      <LogoutIcon style={{color:'black',width:35,height:35}}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                :
                <Grid item xl={3} lg={3} md={4} className='gridButton'>
                  <Grid sx={{display:"flex",alignItems:'center',justifyContent:"center",width:" 110px",height:"45px",background:"#F5F5F5",border:" 1px solid #14C9E8",
                              boxShadow:" 2px 4px 10px rgba(0, 0, 0, 0.25)",borderRadius:"8px 18px",transform:"rotate(-0.37deg)",marginLeft:"30px"}}>
                    <Link to={'/'} style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row',textDecoration:'none'}}>
                    <Typography component={"img"} src={login} sx={{margin:"0 2px"}} />
                      <Typography component={'p'} variant='p' className='textList'  sx={{ color: "#14C9E8 !important"}} onClick={() => this.openAuth('L')}> ورود</Typography>
                    </Link>
                  </Grid>
                  <Grid  sx={{display:"flex",alignItems:'center',justifyContent:"center",width:" 110px",height:"45px",background:"#14C9E8",borderWidth:"1px 0px 0px 1px",
                              borderStyle:"solid",borderColor:"#F5F5F5",boxShadow:"2px 4px 10px rgba(0, 0, 0, 0.25)",borderRadius:"8px 18px",transform:"rotate(-0.37deg)"}} >
                    <Link to={'/'} style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row',textDecoration:'none'}}>
                      <Typography component={'p'} variant='p' className='textList' sx={{color:"white !important"}} onClick={() => this.openAuth('R')}> ثبت نام</Typography>
                      <Typography component={"img"} src={profile} sx={{margin:"0 2px"}} />
                    </Link>
                  </Grid>
              </Grid>
              }
              
            </Grid>
            <Grid item sm={12} xs={12} className='navbarGridOrgS'>
              <Grid item sm={6} xs={6} className='gridImage'>
                <Button onClick={this.menuClick}>
                  <Typography component={'img'} className='menuImg' src={MenuLogo} sx={{display:this.state.display ? "none":"flex"}} alt="menu-img"></Typography>
                  <Typography component={'img'} className='close-img' src={ CloseLogo} sx={{display:this.state.display ? "flex":"none"}}  alt="close-img"></Typography>
                </Button>
              </Grid>
              <Grid item sm={6} xs={6} className='gridImage' style={{justifyContent:'flex-end'}}>
                <Link to={'/'} style={{display:"flex",alignItems:'center',justifyContent:"center"}}>
                  <Typography component={'img'} className='logo' src={Logo} alt="logo"></Typography>
                </Link>
              </Grid>
            </Grid>
            <Divider sx={{width:"100vw"}}/>
            <Grid item sm={12} xs={12} className='menuOrg' style={{height:window.innerHeight,display:this.state.display ? 'flex' : 'none',overflowY:'scroll'}}>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> صفحه‌اصلی</Typography>
                  </Link>
                </Grid>
                {
                  this.state.statusUser ? 
                  <Grid item sm={6} xs={6} className='rowList' style={{justifyContent:'space-evenly'}}>
                    <Badge badgeContent={this.state.notif} max={99} overlap='circular' color='primary' onClick={this.onClickNotification}>
                      <IconButton style={{backgroundColor:'#14C9E8 '}} size='small'>
                        <NotificationsIcon className='iconImg' style={{width:30,height:30}} sx={{color:'white'}}/>
                      </IconButton>
                    </Badge>
                    <IconButton style={{backgroundColor:'#14C9E8 '}} size='small' component={Link} to={'/Profile'} onClick={this.menuClick}>
                      <PersonIcon className='iconImg' style={{color:'white',width:30,height:30}}/>
                    </IconButton>
                    <IconButton style={{backgroundColor:'#14C9E8 '}} size='small' onClick={this.logOut}>
                      <LogoutIcon className='iconImg' style={{color:'white',width:30,height:30}}/>
                    </IconButton>
                  </Grid>
                  :
                  <Grid item sm={6} xs={6} className='rowList loginList' style={{justifyContent:'flex-end',alignItems:"center"}}>
                    <Link to={'/'} className='linkButtonLoginList' style={{display:"flex",alignItems:'center',justifyContent:"center",width:" 100px",height:"45px",background:"#F5F5F5",border:" 1px solid #14C9E8",
                              boxShadow:" 2px 4px 10px rgba(0, 0, 0, 0.25)",borderRadius:"8px 18px",transform:"rotate(-0.37deg)",marginLeft:"10px"}}>
                      <Typography component={"img"} src={login} />
                      <Typography component={'p'} variant='p' className='textList' sx={{ color: "#14C9E8 !important"}} onClick={() => this.openAuth('L')}> و‌رود</Typography>
                    </Link>
                    <Link to={'/'} className='linkButtonRegisterList' style={{display:"flex",alignItems:'center',justifyContent:"center",width:" 100px",height:"45px",background:"#14C9E8",borderWidth:"1px 0px 0px 1px",
                              borderStyle:"solid",borderColor:"#F5F5F5",boxShadow:"2px 4px 10px rgba(0, 0, 0, 0.25)",borderRadius:"8px 18px",transform:"rotate(-0.37deg)"}}>
                      <Typography component={'p'} variant='p' className='textList' sx={{color:"white !important"}} onClick={() => this.openAuth('R')}> ثبت‌ نام</Typography>
                      <Typography component={"img"} src={profile} />
                    </Link>
                  </Grid>
                }
                
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/Listings'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> آگهی‌ ها</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/HomeMag'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> هوم‌مگ</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/Agancy'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> آژانس ها</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/FAQ'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> سوالات‌ متداول</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/Terms'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> قو‌انین</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/AboutUs'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> درباره‌ما</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/Contact'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> تما‌س</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
              <Grid className='menuRow'>
                <Grid item sm={6} xs={6} className='rowList'>
                  <Link to={'/tehran-zones'} className='linkNav' style={{padding:8,width:'50%'}} onClick={this.menuClick}>
                    <Typography component={'p'} variant='p' className='textList'> معرفی مناطق ‌ تهران</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Divider/>
            </Grid>
            <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType}/>
            <Modal
              open={this.state.openNotification}
              onClose={this.onCloseNotification}
            >
              <Box sx={{width:{xs:"90%",md:"700px"},position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",padding:{xs:"5px",md:"10px"},height:{xs:"95%",md:"auto"},
                        overflowY:"auto",bgcolor:"white"}}
              >
                <Grid>
                  <Button sx={{padding:"0",minWidth:"30px",maxWidth:"30px","&:hover":{bgcolor:"transparent"}}} onClick={this.onCloseNotification}>
                    <HighlightOffRoundedIcon sx={{fontSize:"35px"}}/>
                  </Button>
                  <Typography sx={{fontFamily:"shabnam",fontSize:"18px",fontWeight:"bold",textAlign:"center",marginBottom:"10px"}}>
                    اعلان های شما
                  </Typography>
                </Grid>
                <Grid sx={{width:"100%",bgcolor:"#eee",height:{xs:"76%",md:"600px"},overflowY:"auto",padding:"6px",borderRadius:"4px",border:"1px solid #eee"}}>
                  { this.state.notificationInfo.length > 0 ?
                      this.createNotification()
                    : <Grid sx={{width:"100%",height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <Typography sx={{fontFamily:"shabnam",fontSize:"16px",fontWeight:"bold"}}>
                          اعلان های شما خالی می باشد   
                        </Typography>
                        <NotificationsNoneIcon sx={{color:"black !important"}} />
                      </Grid>
                  }
                </Grid>
                <Grid sx={{textAlign:"center",marginTop:"10px"}}>
                  <Button onClick={this.deleteNotification} disabled={this.state.disableNotifButton}
                    sx={{fontFamily:"shabnam",fontSize:"16px",color:"black",bgcolor:"#14C9E8","&:hover":{bgcolor:"#14C9E8"},fontWeight:"bold"}}
                  >
                    حذف اعلان ها
                  </Button>
                </Grid>
              </Box>
            </Modal>
          </AppBar>
              
            
        )
    }
}

export default Nav;