import { Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import { convertToPersianNumber,baseUrl, separator } from "./materilas/vmp";
import { Box } from "@mui/system";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

class WithdrawAdmin extends React.Component {

  isMount = false ;
  token = localStorage.getItem("tokenUserHomeInja");

  constructor(props){
    super(props);
    this.state = {
      giftShow: false ,
      openAlert: false ,
      message: "" ,
      modalOpen: false ,
      withdrawInfo: [] ,
      withdrawId: null,
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true ;
    if(this.isMount){
      this.getWithdraw()
    }
  }

  componentWillUnmount(){
    this.isMount = false ;
  }

  getWithdraw = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/withdraw/get",
      method: "GET",
      headers: {'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      this.setState({withdrawInfo:res.data.data});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
    })
  }

  onCloseModal = () => {
    this.setState({modalOpen:false,withdrawId:null});
  }

  closeAlertModal = () => {
    this.setState({openAlert:false,modalOpen:false});
  }

  onClickWithdraw = (element) => {
    this.setState({modalOpen:true,withdrawId:element.id});
  }

  onClickConfirm = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/withdraw/confirm",
      method: "POST",
      headers: {'Authorization':'bearer ' + this.token},
      data: {
        withdraw_id: this.state.withdrawId
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"برداشت با موفقیت تایید شد",typeAlert:1});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false,withdrawId:null});
    })
  }

  createWithdraw = () => {
    let result = [];
    let withdrawInfo = this.state.withdrawInfo ;
    withdrawInfo.forEach((element,index) => {
      result.push(
        <Grid container sx={{width:"100%",margin:"10px 0",borderRadius:"4px 30px 4px 4px",bgcolor:"#EBFAFC",border:"2px solid #eee",alignItems:"center",padding:"10px 0"}}>
          <Grid item xs={12} md={3} sx={{textAlign:"center"}}>
            <Typography sx={{fontSize:"20px",fontWeight:"bold",fontFamily:"shabnam",display:"inline-block"}}>
              مبلغ :
              {convertToPersianNumber(separator(element.amount))}
            </Typography>
            <Typography sx={{fontSize:"20px",fontWeight:"bold",fontFamily:"shabnam",display:"inline-block",marginRight:"5px"}}>
              ریال
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign:"center"}}>
            <Grid>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"5px 0",display:"inline-block",marginRight:"5px"}}>
                نام کاربر :
              </Typography>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"5px 0",display:"inline-block",marginRight:"5px"}}>
                {element.user.name} {element.user.lastname}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"5px 0",display:"inline-block",marginRight:"5px"}}>
              شماره کاربری : 
              </Typography>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"5px 0",display:"inline-block",marginRight:"5px"}}>
                {convertToPersianNumber(element.user.id)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign:"center"}}>
            <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",padding:"5px 0"}}>
              شماره تلفن : {convertToPersianNumber(element.user.mobile)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{textAlign:"center"}}>
            <Button onClick={() => this.onClickWithdraw(element)}
              sx={{bgcolor:"rgb(0,50,77)",color:"white",fontFamily:"shabnam",fontWeight:"bold",width:"70%","&:hover":{bgcolor:"rgb(0,50,77)"},padding:"10px 0"}}
            >
              تایید برداشت
            </Button>
          </Grid>
        </Grid>
      );
    })
    return result ;
  }

  render(){
    return(
      <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px',xs:'0'}}}>
          <Grid style={{display:'flex',flexDirection:'row',margin:'0 6% 40px 0'}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>تایید برداشت کاربران</Typography>
          </Grid>
          <Grid sx={{width:"100%",padding:"0 6%"}}>
            { this.state.withdrawInfo.length > 0 ?
                this.createWithdraw()
              : <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                    sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                >   
                    <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                        <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                        {'درخواست برداشتی وجود ندارد'}
                        </Typography>
                    </Grid>
                </Grid>
            }
          </Grid>
          <Modal
            open={this.state.modalOpen}
            onClose={this.onCloseModal}
          >
            <Box sx={{bgcolor:"white",position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",padding:{xs:"25px 5px",md:"25px"}}}>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",width:"100%",textAlign:"center"}}>
                آیا از تایید برداشت مطمئن هستید؟
              </Typography>
              <Grid sx={{marginTop:"20px",display:"flex",justifyContent:"space-between"}}>
                <Button onClick={this.onClickConfirm}
                  sx={{fontFamily:"shabnam",fontWeight:"bold",color:"black",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},padding:"10px 35px",margin:"0 3px"}}
                >
                  بله
                  <CheckIcon sx={{color:"black !important"}} />
                </Button>
                <Button onClick={this.onCloseModal}
                  sx={{fontFamily:"shabnam",fontWeight:"bold",color:"black",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},padding:"10px 35px",margin:"0 3px"}}
                >
                  خیر
                  <CloseIcon sx={{color:"black !important"}}/>
                </Button>
              </Grid>
            </Box>
          </Modal>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    )
  }
}

export default WithdrawAdmin ;