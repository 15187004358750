import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article21/01.jpg";
import photo02 from "../../images/mag/article21/02.jpg";
import photo03 from "../../images/mag/article21/03.jpg";
import photo04 from "../../images/mag/article21/04.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article21 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "برای جلوگیری از کلاهبرداری ملک چه باید کنیم؟ در هومینجا ما شما را راهنمایی می کنیم تا از به دام افتادن توسط افراد فرصت طلب جلوگیری کنیم";
    document.getElementsByTagName('meta')["keywords"].content = "روش های شایع کلاهبرداری در فروش  خانه | کلاهبرداری ملکی | آشنایی با انواع روش های کلاهبرداری"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[20].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[20].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[20].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[20].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[20].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[20].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="انواع کلاهبرداری در معاملات ملک - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[20].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به از بین رفتن بسیاری از کسب و کارها و مشکلات اقتصادی، فعالیت کلاهبرداران و سودجویان افزایش چشمگیری یافته است.  افزایش قیمت
                مسکن و زمین، سودجویان را به سمت معاملات املاک سوق می دهد . بزرگ ترین دارایی مردم زمین و ملک آنها به شمار می رود که از بین رفتن آن توسط کلاهبرداران
                موجب صدمات جبران ناپذیری می شود . یکی از عمده ترین عواملی که باعث به دام افتادن افراد عادی  می شود عدم آگاهی از شرایط و نحوه عقد قرارداد است.
                <br />
                پس با هومینجا همراه شوید تا هم آگاهی شما را افزایش دهیم و هم با مطمئن ترین راه، معاملات شما را انجام دهیم.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع کلاهبرداری در زمینه املاک و مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کلاهبرداری املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انواع کلاهبرداری در زمینه املاک و مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی بر این شده که چند مورد از بیشترین کلاهبرداری های انجام شده در زمینه معاملاک ملک و خرید و فروش مسکن را توضیح دهیم.
              </Typography>
              <Typography className="h3" variant="h3">
                ۱. پیش فروش ملک های نیمه ساز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هنگامی که قصد خرید و یا سرمایه گذاری بر روی ملک های نیمه ساز (یا به اصطلاح پیش خرید آنها) را دارید بسیار محتاط برخورد کنید.
                <br />
                بسیاری از کلاهبرداران بعد
                از فروش واحد به شما یا متواری میشوند و یا ملک را به چندین خریدار می فروشند. متاسفانه در هر دو مورد ملکی به خریدار تحویل داده نخواهد شد.
                <br />
                معمولا بعضی از دفاتر املاک هم، همدست کلاهبرداران هستند تا به شما اطمینان در معامله را القا کنند.
                پس داشتن مشاور املاک مطمئن می تواند شما را از بسیاری از گرفتاری ها در امان نگه دارد. ما با هومینجا در کنار شما و حافظ منافع شما هستیم.
              </Typography>
              <Typography className="h3" variant="h3">
                ۲. کلاهبرداری در املاک با عنوان تسهیلات بانکی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در چند سال گذشته بارها دیده شد که سودجویان
                با آگهی های گسترده در سطح شهر کار کردند. آگهی ها با مضمون دریافت تسهیلات بانکی با کمترین کارمزد، افراد را به سوی خود جلب می کردند.
                <br />
                سپس با استفاده از اسناد جعلی و سربرگ‌های دولتی وزارت تعاون و رفاه اجتماعی، نمایندگانی را جهت ارزش‌گذاری املاک متقاضیان
                اعزام  و از افراد بی‌تجربه کلاهبرداری می‌کردند. با توجه به اسناد ارائه شده،مردم سرمایه خود را در اختیار کلاهبرداران قرار می دادند.
                <br />
                این گروه از کلاهبرداران به صورت دوره ای نام
                ، شهرت و آدرس محل فعالیت خود را تغییر می دادند تا هم ردیابی را سخت تر کنند و هم راه فرار از مسئولیت را برای آنها باز کند.
              </Typography>
              <Grid className="box">
                <LinkMui className="link" href="/way-to-get-house-loan" target="_blank" rel="noreferrer" >
                  بیشتر بخوانید : نحوه دریافت وام مسکن
                </LinkMui>
              </Grid>
              <Typography className="h3" variant="h3">
                ۳. معامله ملک در رهن بانک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="ملک در رهن بانک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  معامله ملک در رهن بانک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بر اساس قوانین مدنی کشور، خرید و فروش مال دیگران جرم  محسوب می‌شود و شامل مجازات است. باید توجه داشته باشید اگر قصد خرید ملکی را دارید که در
                رهن بانک است، نیاز است تا در قرارداد این مورد و مدت زمانی که ملک در رهن است ذکر شود. با هومینجا از هر گونه معاملات مشکوک مصون خواهید بود.
              </Typography>
              <Typography className="h3" variant="h3">
                4. دفاتر قلابی  و فاقد مجوز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بیشترین موارد گزارش شده در زمینه کلاهبرداری معاملات ملک و مسکن، معاملات در دفاتر غیر قانونی و فاقد
                مجوزهای لازم از اتحادیه املاک می باشد. کلاهبرداران با اجاره یک دفتر و طراحی زیبا بستر لازم جهت فعالیت مجرمانه را فراهم می آورند. کلاهبرداران
                در لباس مشاور املاک با همدستی یکی از طرفین معامله که خود را خریدار معرفی میکند اقدام به تنظیم مبایعه نامه جعلی میکنند. با ارزش گذاری
                غیر واقعی سعی در برانگیزی طمع شما را دارند. به این صورت ملک شما را با مبلغ ناچیزی به عنوان ودیعه قولنامه می کنند و سپس متواری می شوند.
                <br />
                از آنجا که این دفاتر فاقد مجوز بوده دسترسی و شکایت هم برای زیان دیده بسیار سخت و طاقت فرسا می باشد.
                <br />
                یکی از بیشترین تعداد پرونده ها در حوزه کلاهبرداری خانه به این نوع اختصاص دارد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/property-document" target="_blank">
                  بیشتر بخوانید : انواع سند رسمی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                5. مبایعه نامه جعلی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%85%D8%A8%D8%A7%DB%8C%D8%B9%D9%87%E2%80%8C%D9%86%D8%A7%D9%85%D9%87" target="_blank" rel="noreferrer" > مبایعه نامه</LinkMui>  به عنوان قراردادی بین خریدار و فروشنده بصورت توافقی ثبت می شود
                . گاهی در مبایعه نامه مطلبی با اگاهی یا
                بصورت تصادفی ذکر می شود که با واقعیت در تضاد است. بعنوان مثال متراژ نحوه پرداخت و مسایلی که باید هر دو طرف با اطلاع کامل آن را امضا کنند .
                <br />
                بیشتر کلاهبرداری های حوزه املاک به دلیل کمبودی اگاهی نسبت به عقد قرارداد و  خلا مشاور معتمد نشات میگیرد.
              </Typography>
              <Typography className="h3" variant="h3">
                6. خرید و فروش ملک  با ارز های دیجیتال
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="خرید املاک با ارز دیجیتال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خرید و فروش ملک  با ارز های دیجیتال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در آخرین گزارشهای موجود در حوزه کلاهبرداری ملکی، دفتری توسط پلیس فتا پلمپ شد. این دفتر خرید و فروش ملک با ارز های دیجیتال نامعتبر انجام می دادند.
                <br />
                اولا طبق قانون هییت وزیران سال 98 ارزهای دیجیتال نمی توانند به عنوان ابزار
                پرداخت استفاده شوند و ثانیا ارزهای دیجیتال شامل نوسات زیاد هستند و به هیچ عنوان افراد بدون دانش لازم نباید به معاملات انها
                وارد شوند. کلاهبرداران با یک ارز دیجیتال نا معبتر و بدون ارزش اقدام به خرید ملکهای مردم کردند که با اقدام به موقع پلیس از فرار انها جلوگیری شد.
              </Typography>
              <Typography className="h2" variant="h2">
                نتیجه گیری:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمام موارد گفته شد در این مطلب از هومینجا به دلیل عدم اگاهی
                مردم از نحوه تنظیم قرارداد و مدارک لازم جهت احراز هویت دو طرف معامله می باشد. هم چنین در بعضی موارد طمع مردم هم به کلاهبرداران کمک مضاعفی کرده است
                <br />
                به هر حال با داشتن مشاور املاک مطمین و متخصص شما را نسبت به هر گونه فعالیت مخرب بیمه می کند
                <br />
                تمامی امور ملکی خود را به ما بسپارید.
              </Typography>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/real-estate-fraud" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/real-estate-fraud" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/real-estate-fraud" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article21;