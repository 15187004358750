import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article19/01.jpg";
import photo02 from "../../images/mag/article19/02.jpg";
import photo03 from "../../images/mag/article19/03.jpg";
import photo04 from "../../images/mag/article19/04.jpg";
import photo05 from "../../images/mag/article19/05.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";



class Article19 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "ما در اینجا به معرفی محله آزادی که از پرتردد ترین خیابان های تهران می باشد می پردازیم. با ما همراه شوید تا بیشتر با این خیابان آشنا شوید";
    document.getElementsByTagName('meta')["keywords"].content = "آشنایی با محله آزادی | امکانات محله آزادی | قیمت ملک درمحله آزادی"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[18].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[18].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[18].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[18].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[18].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[18].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="محله آزادی - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[18].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدنبال خرید
                ملک درمحله آزادی هستید اما آشنایی به این محله ندارید . مقالات سایت هومینجا میتواند اطلاعاتی در اختیار شما قرار دهد که بهترین انتخاب را داشته باشید .
                <br />
                آزادی یکی از شلوغ ترین محله های غربی تهران است . از میدان انقلاب شروع شده و تا میدان آزادی ادامه دارد.
                آزادی جزوه <Link className="link" to="/tehran-zones" target="_blank">منطقه 9و 10 شهرداری تهران</Link> است .
                <br />
                وجود مکانها و موسسات مهم در خیابان آزادی آن را به معروفترین خیابان تهران تبدل کرده است .
              </Typography>
              <Typography className="h2" variant="h2">
                بافت محله آزادی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بافت  آزادی قدیمی است . هنوز ساختمان هایی
                با قدمت دیرین و کوچه هایی باریک در آن وجود دارد .این محله در قدیم با نام آیزنهاور شناخته میشد که بعد از انقلاب به خیابان آزادی تغییر نام داد .
              </Typography>
              <Typography className="h2" variant="h2">
                برج آزادی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="برج آزادی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای برج آزادی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج آزادی در سال 1349 توسط معمار ایرانی حسین امانت طراحی و بنا گردید .برج آزادی  نماد شهر تهران و کشور
                ایران است . این بنا شامل سه طبقه است. در طبقه اول و دوم موزه و نگارخانه وجود دارد و طبقه سوم جهت بازدید عموم مردم از مناظر اطراف آزاد می باشد .
              </Typography>
              <Typography className="h2" variant="h2">
                مکان های مهم محله آزادی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وزارتخانه ها و مرکز آموزشی و خدماتی بسیار باعث ازدحام جمعیت و ترافیک سنگین در  محله آزادی شده است .
                <br />
                بیمه تامین اجتماعی
                <br />
                سازمان فنی و حرفه ای
                <br />
                سازمان میراث فرهنگی
                <br />
                اداره کار و رفاه و تامین اجتماعی
                <br />
                اداره پست منطقه 10
                <br />
                کارخانه نوشابه و دلستر زمزم
                <br />
                ساختمان شهرداری منطقه 10
              </Typography>
              <Typography className="h3" variant="h3">
                مساجد و اماکن مذهبی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اعتقادات مذهبی ساکنین  آزادی حسینیه و مساجد فراوانی
                را در خود جا داده است . از جمله این اماکن مذهبی می توان به مسجد صاحب الزمان ،حسینیه امام حسن دانشگاه صنعتی شریف ،حسینیه خویی ها اشاره کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز درمانی و بیمارستان ها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مواردی که باید در شناخت محله مورد
                توجه قرارداد دسترسی به مراکز درمانی میباشد . در خیابان آزادی این امکان فراهم است که به آسانی به بهترین مرکز بهداشتی دسترسی داشته باشیم .
                <br />
                درمانگاه  22 بهمن ،  درمانگاه بهگر ، درمانگاه جیحون ، بیمارستان فوق تخصصی آزادی (میمنت) ،بیمارستان آرش ، بیمارستان شهریار ، بیمارستان لولاگر
                ، درمانگاه دامپزشکی از معروفترین این مراکز می باشند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/buy_tehran_azadi" target="_blank">
                  خرید و فروش ملک آپارتمان در آزادی
                </Link>
                <br />
                <Link className="link" to="/Listings/rent_tehran_azadi" target="_blank">
                  رهن و اجاره ملک آپارتمان در آزادی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                بوستان ها و مراکز تفریحی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از نقاط ضعف خیابان آزادی کمبود فضای سبز نسبت به ازدحام جمعیت است که باعث شده هوای مناسبی نداشته باشد .
                <br />
                بوستان المهدی ، بهنود، ارمغان ، کمیل ، مجتمع تفریحی ورزشی شهدای جیحون ، شهر بازی المهدی مراکز تفریحی این خیابان می باشند.
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز آموزشی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="دانشگاه شریف - هومینجا" className="image" />
                <figcaption className="image_caption">
                  درب ورودی دانشگاه صنعتی شریف
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود مراکز آموزشی متعدد در محله آزادی آن را به گزینه خوب برای خرید ملک تبدیل کرده است  .
                <br />
                مدارس حرا، مکتب الزهرا ،قوی فکر ، عدل پرور ، منیره عاصمی ، محمد عاصمی مراکز آموزشی بنام این محله می باشد.
                <br />
                دانشگاه صنعتی شرف یکی از برترین دانشگاهای ایران و رتبه 47 جهان است .
                <br />
                دانشکده مدیریت دانشگاه آزاد تهران مرکز و دانشکده دامپزشکی نیز از دیگر دانشگاه های معروف این خیابان می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                حمل و نقل
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="مترو آزادی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ایستگاه مترو آزادی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود ایستگاههای متعدد مترو دسترسی محله آزادی را به آسانی امکان پذیر کرده است .
                <br />
                مترو انقلاب ، شادمان ، توحید ، نواب ، استادمعین ، میدان آزادی
                <br />
                پایانه اتوبوسرانی غرب
                <br />
                پایانه تاکسیرانی غرب
                <br />
                خطوط BRT
              </Typography>
              <Typography className="h2" variant="h2">
                خیابان ها و بزرگراهها ی مهم محله آزادی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="آزادی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نما از بالای برج آزادی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اتوبان یادگار امام ، بزرگراه سعیدی
                <br />
                خیابان نواب ، کارون ، جیحون ، آذربایجان ، خوش ، رودکی ، استادمعین ، قصرالدشت ،امام خمینی (سپه)
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز خرید در خیابان آزادی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دسترسی به مراکز خرید از مزایای محله آزادی است که جمعیت زیادی را در خود اسکان داده است .
                <br />
                مرکز خرید معین مال
                <br />
                فروشگاهای مختلف البسه ومواد غذایی در چهارراه گلی و خیابان هاشمی
                <br />
                مرکز کتاب تهران در میدان انقلاب
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت ملک در خیابان آزادی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قیمت ملک در  آزادی در سطح متوسط رو به پایین است.
                هرچه به سمت خیابان آزادی پیش می ورد قیمت ملک گرانتر می شود . اگه قصد خرید یا اجاره در خیابان آزادی را دارید بهتر است
                از مشاورین هومینجا کمک بخواهید تا با راهنمایی آن ها انتخاب دقیق و هوشمندانه تری داشته باشید .
              </Typography>
              <Typography className="h2" variant="h2">
                حرف آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دسترسی  به مراکز مهم خدماتی و درمانی و همچنین سهولت حمل و نقل ، آزادی را به یکی از پرجمعیت ترین
                محلات تهران تبدیل کرده است
                .اگر بدنبال مکان مناسبی برای حرفه  و محل سکونت هستید بهترین مشاور برای شما سایت هومینجاست. پس با ما همراه باشید و فرصت را از دست ندهید.
              </Typography>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Azadi" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Azadi" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Azadi" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article19;