import {Button, Grid, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber,  separator } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import NoLogo from '../images/noLogoAgancy.png';
import { Link } from "react-router-dom";

class NewListings extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');
  interval1 = '';

  constructor(props){
      super(props);
      this.state={
        openAlert:false,
        message:'',
        giftShow:false,
        newListing:[],
        typeAlert:3
      }
  }

  componentDidMount(){
    this.isMount = true;
    if (this.isMount){
      this.getNewListing();
      this.interval1 = setInterval(() => {
        this.getNewListing();
      }, 600000);
    }
  }

  componentWillUnmount(){
    this.isMount = false;
    clearInterval(this.interval1);
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  getNewListing = () =>{
    this.setState({giftShow:true})
    Promise.resolve(axios({
      url: baseUrl + 'v1/listing/new',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token},
    }))
    .then((res) => {
      let items = res.data.data;
      this.setState({newListing:items,giftShow:false})
    })
    .catch((err) => {

      this.setState({newListing:[],giftShow:false,openAlert:true,message:'دریافت اطلاعات با مشکل مواجه شد.',typeAlert:2})
    });
  }

  viewListing = (id) =>{
    localStorage.setItem('idListing',id)
  }

  activeListing = (id) =>{
    this.setState({giftShow:true})
    Promise.resolve(axios({
      url: baseUrl + 'v1/listing/active',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        listing_id:id
      }
    }))
    .then((res) => {
      this.getNewListing();
      this.setState({openAlert:true,message:'آگهی با موفقیت فعال گردید.' + convertToPersianNumber(id) + 'آگهی شماره:',giftShow:false,typeAlert:1})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'فعال کردن آگهی با مشکل مواجه شد.',giftShow:false,typeAlert:2})
    });
  }

  needCorrect = (id) =>{
    this.setState({giftShow:true})
    Promise.resolve(axios({
      url: baseUrl + 'v1/listing/needCorrect',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        listing_id:id
      }
    }))
    .then((res) => {
      this.getNewListing();
      this.setState({openAlert:true,giftShow:false,typeAlert:1,
                      message:'وضعیت آگهی به نیاز به اصلاح تغییر یافت لطفا به کاربر مربوطه اطلاع رسانی شود.' + convertToPersianNumber(id) + 'آگهی شماره:'})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'عملیات با مشکل مواجه شد.',giftShow:false,typeAlert:2})
    });
  }

  createNewListing = () =>{
    let list = [];
    this.state.newListing.forEach((element,index) =>{
      list.push(
        <Grid item xs={12} key={index} 
              style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%',flexWrap:'wrap',marginTop:10,marginBottom:10,
                      background:'linear-gradient(270deg, rgba(204, 244, 251, 0.14) 0%, #FFFFFF 100%)',boxShadow:'0px 2px 4px rgba(0, 0, 0, 0.14)',
                      borderRadius:'0px 40px 0px 0px'}}>
          <Grid item sm={2} xs={6} style={{display:'flex',alignItems:'center',width:'100%'}}>
            {
              element.small.length === 0 ?
                <Typography component={'img'} src={NoLogo} style={{height:120,width:'90%',borderRadius:'0px 40px 0px 0px'}} alt="no-logo"></Typography>
              :
                <Typography
                  style={{display:'flex',alignItems:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover',height:120,width:'90%',
                          borderRadius:'0px 40px 0px 0px',backgroundImage:`url(${element.small[0].path})`,backgroundPosition:'center'}}
                ></Typography>
            }
            
          </Grid>
          <Grid item sm={3.5} xs={6} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center'}}>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'استان:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.state.name}
                </Typography>
              </Grid>
              
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'شهر:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.city.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'منطقه:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.zone.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'محله:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',overflowX:'hidden'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {element.neighbourhood.name}
                </Typography>
              </Grid>
              
              
            </Grid>
          </Grid>
          <Grid item sm={3.5} xs={12} style={{display:'flex',width:'100%'}} 
                sx={{flexDirection:{sm:'column',xs:'row'},flexWrap:{sm:'nowrap',xs:'wrap'},marginTop:{sm:'0px',xs:'10px'}}}>
            <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'موبایل:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {convertToPersianNumber(element.user.mobile)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
              <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {'متراژ:'}
                </Typography>
              </Grid>
              <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                <Typography component={'p'} 
                  style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                  sx={{fontSize:{sm:'16px',xs:'13px'}}}
                >
                  {convertToPersianNumber(element.area)}
                </Typography>
              </Grid>
            </Grid>
            {
              element.rent !== null ?
                <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                  <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                    <Typography component={'p'} 
                      style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                      sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                      {'اجاره:'}
                    </Typography>
                  </Grid>
                  <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                    <Typography component={'p'} 
                      style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                      sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                      {convertToPersianNumber(separator(element.rent))}
                    </Typography>
                  </Grid>
                </Grid>
              :
                null
            }
            {
              element.mortgage !== null ?
                <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                  <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                    <Typography component={'p'} 
                      style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                      sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                      {'رهن:'}
                    </Typography>
                  </Grid>
                  <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                    <Typography component={'p'} 
                      style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                      sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                      {convertToPersianNumber(separator(element.mortgage))}
                    </Typography>
                  </Grid>
                </Grid>
              :
                null
            }
            {
              element.total_price !== null || element.prebuy !== null?
                <Grid item sm={12} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',marginBottom:5}}>
                  <Grid item lg={2.5} sm={4} xs={6} style={{width:'100%'}}>
                    <Typography component={'p'} 
                      style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%'}}
                      sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                      {'قیمت:'}
                    </Typography>
                  </Grid>
                  <Grid item lg={9.5} sm={8} xs={6} style={{width:'100%'}}>
                    <Typography component={'p'} 
                      style={{fontFamily:'shabnam',fontWeight:'bold',width:'100%',color:'#01A7C4'}}
                      sx={{fontSize:{sm:'16px',xs:'13px'}}}
                    >
                      {element.total_price !== null ? convertToPersianNumber(separator(element.total_price)) : convertToPersianNumber(separator(element.prebuy))}
                    </Typography>
                  </Grid>
                </Grid>
              :
                null
            }
          </Grid>
          <Grid item sm={3} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}} 
                sx={{flexDirection:{sm:'column',xs:'row'},flexWrap:{sm:'nowrap',xs:'wrap'},marginTop:{sm:'0px',xs:'10px'}}}>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button component={Link} to={"/Listing/" + element.id} target={'_blank'}
                  style={{backgroundColor:'#003249',color:'white',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'مشاهده'}
              </Button>
            </Grid>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button onClick={() => this.activeListing(element.id)} 
                  style={{backgroundColor:'#00C8E9',color:'black',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'تایید'}
              </Button>
            </Grid>
            <Grid item sm={10} xs={6} style={{display:'flex',flexDirection:'row',width:'100%',alignItems:'center',justifyContent:'center',marginBottom:5}}>
              <Button onClick={() => this.needCorrect(element.id)} 
                  style={{backgroundColor:'white',color:'black',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',border:'1px solid #01C7E9',
                          boxShadow:'0px 1px 2px rgba(0, 0, 0, 0.14)'}}
                  sx={{height:{sm:'30px',xs:'40px'},width:{sm:'100%',xs:'80%'}}}
              >
                {'اصلاح'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )
    })
    return list;
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>آگهی های جدید</Typography>
        </Grid>
        {
          <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
          >    
            {
              this.state.newListing.length === 0 ?
                <Grid item xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',marginTop:'20%'}}>
                  <Typography variant="h1" sx={{fontSize:{md:'26px',xs:'16px'}}}
                    style={{fontFamily:'shabnam',fontWeight:'bold',textAlign:'center',marginBottom:5,width:'100%'}}>
                    {'هیچ موردی برای نمایش وجود ندارد.'}
                  </Typography>
                </Grid>
              :
                this.createNewListing()
            }
            <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
            <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          </Grid>
        }
        
        
      </Grid>
    )
  };
}

export default NewListings;