import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article55/01.jpg";
import photo02 from "../../images/mag/article55/02.jpg";
import photo03 from "../../images/mag/article55/03.jpg";
import photo04 from "../../images/mag/article55/04.jpg";
import photo05 from "../../images/mag/article55/05.jpg";
import photo06 from "../../images/mag/article55/06.jpg";
import photo07 from "../../images/mag/article55/07.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article55 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "باتوجه به درخواست بالای مردم برای دریافت وام مسکن بر آن شدیم تا در این مقاله شما را با شرایط و مدارک لازم جهت دریافت وام مسکن ۱۴۰۲ آشنا کنیم. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = " دریافت وام مسکن ۱۴۰۲ | شرایط وام مسکن ۱۴۰۲ | شرایط دریافت وام خرید مسکن | شرایط دریافت تسهیلات خرید مسکن | دریافت وام مسکن از بانک ها | شرایط گرفتن وام مسکن با سند خانه سال ۱۴۰۲ | وام خرید مسکن زوجین | مدارک لازم دریافت وام مسکن ۱۴۰۲ |  مبلغ وام خرید مسکن ۱۴۰۲ | وام ودیعه مسکن | وام ساخت مسکن"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[54].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[54].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[54].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[54].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[54].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[54].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="وام ودیعه مسکن 1402 - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[54].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                باتوجه به درخواست بالای مردم برای دریافت انواع مختلف وام مسکن و هم چنین افزایش سرسام آور هزینه <Link className="link" to="/buying-house-in-Tehran" target="_blank">  خرید ملک </Link>، بر آن شدیم تا در این مقاله شما را با شرایط و مدارک لازم جهت دریافت {/* <LinkMui className="link" href="https://blog.dgshahr.com/kind-of-loans" target="_blank">*/} انواع وام {/*</LinkMui> */}مسکن در سال ۱۴۰۲ آشنا کنیم.</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین نحوه دریافت وام مسکن از بانک های مختلف هم توضیح داده شده است، پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط و نحوه دریافت وام مسکن ۱۴۰۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="وام مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وام مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بی‌ثبات‌ترین بازارهای اقتصادی در چند سال اخیر  بازار مسکن بوده است، به طوری که طی این سال‌ها قیمت مسکن به شکل باورنکردنی افزایش یافته و می‌توان مدعی شد که خرید خانه برای بسیاری از اقشار جامعه ناممکن شده است. ارائه وام و تسهیلات در حوزه مسکن یکی از روش‌های حمایتی دولت در این زمینه است که توسط بانک‌های عامل صورت می‌گیرد. وام خرید مسکن از جمله وام‌های تأثیرگذاری است که توسط تعدادی از بانک‌های عامل با شرایط و ضوابط خاص به خریداران عرضه می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مبلغ و شرایط وام مسکن با توجه به قوانین و شرایط بازار از سوی دولت تعیین شده و پس از تکمیل مدارک توسط متقاضی از سوی بانک ارائه می‌گردد. ما در این مقاله قصد داریم به شرایط و نحوه دریافت وام مسکن در سال ۱۴۰۲ بپردازیم. با ما همراه باشید تا با جزئیات دریافت این وام از جمله مدارک لازم جهت دریافت این وام، میزان مبلغ وام، میزان سپرده مورد نیاز، جدول اقساط آن و… آشنا شوید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/1402-Tehran-Construction-Cost" target="_blank">
                  بیشتر بخوانید : برآورد هزینه ساخت ساختمان در تهران سال ۱۴۰۲
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرایط دریافت وام خرید مسکن در سال ۱۴۰۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="دریافت وام مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  شرایط دریافت وام مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افزایش متوالی قیمت مسکن در سال‌های اخیر باعث شده است که خرید مسکن برای بسیاری از جوانان و خانواده‌ها به یک رویا بدل شود. با این وجود همچنان افراد بسیاری وجود دارند که امید دارند با دریافت وام خرید مسکن و یا از طریق خرید و فروش وام مسکن از طریق انتقال امتیاز، بتوانند صاحب خانه شود. دریافت تسهیلات مسکن در سال ۱۴۰۲ مستلزم شرایطی است که تنها در صورت احراز آن خریدار می‌تواند وام مسکن را از بانک‌های عامل درخواست و دریافت کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حال حاضر بانک‌های دولتی و خصوصی بسیاری اقدام به ارائه وام مسکن می‌کنند. وام مسکن از نظر مبلغ و شرایط احراز دریافت به انواع مختلفی تقسیم می‌شود که شامل وام ودیعه مسکن، وام مسکن جوانان، وام مسکن روستایی، وام خرید مسکن، وام بازسازی مسکن و… می‌شود. در ادامه به تفصیل در مورد وام خرید مسکن، شرایط، مدارک و مبلغ آن صحبت خواهیم کرد. ضمن این که شرایط دریافت وام در برخی از بانک‌ها از سایرین متفاوت است. مهمترین شرایطی که از سوی قانون گذار برای دریافت وام مسکن اعلام شده به این شرح است:
              </Typography>

              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دارا بودن سن ۱۸ سال تمام برای فرد متقاضی دریافت وام خرید منزل مسکونی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                عدم وجود سابقه چک برگشتی در هیچ یک از بانک‌های کشور برای شخص متقاضی وام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                عدم وجود بدهی بانکی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در برخی از بانک‌ها برای دریافت وام خرید مسکن نیاز به افتتاح حساب سپرده‌گذاری خواهد بود، در حالی که در برخی دیگر از بانک‌ها می‌توان با خرید اوراق ممتاز، وام خرید مسکن را دریافت کرد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                درآمد فرد متقاضی وام باید با مبالغ جدول اقساط مطابق بوده و توانایی پرداخت آن را داشته باشد. عموما بانک ها، برای تعیین حداکثر مبلغ وام، درآمد افراد را استعلام و در نظر می‌گیرند.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط دریافت وام خرید مسکن از بانک مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بانک مسکن این وام را به صورت غیر حضوری به مشتریان خود ارائه می‌کند؛ به این ترتیب افراد متقاضی وام می‌توانند با توجه به شروط لازم و با دریافت فرم ج مسکن ملی، برای دریافت وام خرید مسکن از این بانک اقدام کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بانک سامان  نیز یکی دیگر از بانک‌های عامل پرداخت وام خرید مسکن است. تسهیلات خرید مسکن به روش‌های مختلفی به افراد متقاضی واجد شرایط پرداخت می‌شود. به طور مثال در برخی از طرح‌های موجود در بانک مسکن نیاز است که فرد متقاضی اقدام به سپرده‌گذاری نماید اما در بسیاری از بانک‌ها با خرید اوراق ممتاز، وام خرید مسکن به شخص متقاضی پرداخت می‌شود. مهمترین شرایط بانک ملی برای پرداخت وام خرید مسکن به این شرح است:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای دریافت حداکثر مبلغ وام خرید مسکن، ملک مورد نظر نباید بیش از ۲۵ سال قدمت داشته باشد. در غیر این صورت سقف تسهیلات کاهش می‌یابد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای دریافت وام خرید منزل مسکونی، از محل اوراق ممتاز، امکان خرید اوراق حق تقدم، به میزان قیمت نصف وام، برای افراد وجود دارد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای دریافت وام خرید منزل مسکونی، از محل‌های دیگر، لازم است که افراد، حساب مشخصی را در بانک، باز کرده باشند.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط دریافت تسهیلات خرید مسکن ۱۴۰۲ از بانک ملی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="تسهیلات بانک ملی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تسهیلات بانک ملی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وام خرید مسکن بانک ملی نیز به صورت غیر حضوری به متقضیان ارائه خواهد شد. فرد متقاضی در صورت احراز شرایط لازم می‌تواند برای دریافت وام خرید مسکن از این بانک اقدام کند. مهمترین شرایط دریافت وام خرید مسکن از بانک ملی به این شرح است:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای دریافت تسهیلات مسکن از بانک ملی، فرد باید حساب شخصی در بانک ملی داشته باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فرد متقاضی نباید سابقه چک برگشتی داشته باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                به افرادی که دارای بدهی به بانک می‌باشند، وام خرید مسکن تعلق نخواهد گرفت.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حداکثر مبلغ وام خرید مسکن با توجه به درآمد افراد تعیین گردیده و درآمد متقاضی توسط بانک ملی استعلام می‌گردد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرایط دریافت تسهیلات خرید مسکن ۱۴۰۲ از بانک سامان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وام مسکن بانک سامان نیز به صورت کاملا آنلاین در اختیار متقاضیان قرار می‌گیرد. مهمترین شرایط بانک سامان برای ارائه وام خرید مسکن به متقاضیان به این شرح است:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای دریافت وام خرید منزل مسکونی، از بانک سامان، ملک مورد نظر، ارزش‌گذاری می‌شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حداکثر به میزان ۵۰ درصد از ارزش ملک، امکان دریافت وام، برای اشخاص وجود دارد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مبلغ بازپرداخت، بر اساس توان فرد دریافت کننده وام، تعیین می‌شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اشخاصی که می‌خواهند این وام را دریافت کنند، می‌بایست بالای ۱۸ سال سن داشته باشند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                به افرادی که دارای سابقه چک برگشتی در سیستم بانکی کشور می‌باشند، وام تعلق نخواهد گرفت.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ملک خریداری شده تا پایان پرداخت تسهیلات، در رهن بانک قرار خواهد گرفت.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ملک خریداری شده، باید ۶ دانگ، به راحتی قابل خرید و فروش، بلامعارض، ارزنده و غیره باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط گرفتن وام مسکن با سند خانه سال ۱۴۰۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از شرایط دریافت وام خرید مسکن، دریافت وام با استفاده از سند ملک است. در این صورت نیز متقاضیان باید واجد برخی از شرایطی که اعلام می شود باشند تا بتوانند از این تسهیلات استفاده نمایند. این شرایط عبارتند از:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ارائه سند رسمی املاک معامله شده و ثبت شده در دفاتر اسناد رسمی و یا دفترخانه‌ها به بانک مسکن، به عنوان ضمانت ملکی برای دریافت وام مسکن، مورد قبول خواهد بود. (<Link className="link" to="/property-document" target="_blank">  اسناد </Link>  عادی و یا همان مبایعه نامه‌های نوشته شده در بنگاه‌های معاملات ملکی، از رسمیت و سندیت کافی، برای ارائه به عنوان وثیقه ملکی معتبر، در زمان دریافت وام مسکن، به رسمیت شناخته نخواهند شد.)
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط دریافت وام خرید مسکن زوجین بدون سپرده
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="وام مسکن زوجین - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وام مسکن زوجین
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از وام‌های موجود در حوزه مسکن، وام خرید مسکن بدون سپرده مخصوص زوجین است. برای دریافت وام مسکن زوجین نیاز است که شرایط دریافت تسهیلات از سوی زوجین احراز شود. شرایطی که در حال حاضر برای دریافت این وام اعلام شده به این شرح است:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مبلغ وام مسکن زوجین به ترتیب برای استان <Link className="link" to="/tehran-zones" target="_blank">  تهران </Link> ، شهرهای بزرگ و دیگر شهرها ۱۰۰.۸۰ و ۶۰ میلیون تومان است. دریافت این مبلغ برای زوجین زمانی میسر است که سقف وام اوراق را دریافت نمایند و اگر میزان وام دریافتی از محل اوراق، از میزان سقف مذکور در هر شهر، پایین‌تر باشد امکان استفاده از این وام وجود ندارد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سود تسهیلات مسکن زوجین بدون سپرده، ۱۸ درصد خواهد بود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدت زمان بازپرداخت این وام سال و با توجه به مبلغ تسهیلات (۱۰ میلیون تومان) مبلغ ۲۵۴ هزارتومان برای هر هریک از زوجین است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                وام تسهیلات مسکن زوجین به هرکدام از آن‌ها به صورت جداگانه پرداخت خواهد شد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هریک از زوجین درخواست دهنده وام باید دارای حساب پس‌انداز یا سپرده به میزان نصف مبلغ دریافتی تسهیلات باشند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                معرفی یک ضامن معتبر با دارا بودن فیش حقوقی و درآمد ماهانه سه برابر مبلغ قسط وام از مهمترین شرایط دریافت وام مسکن زوجین خواهد بود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مدارک لازم جهت دریافت وام خرید مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اطلاع از شرایط و نحوه دریافت وام خرید مسکن و تکمیل مدارک مورد نیاز کمک می‌کند که فرآیند دریافت وام مسکن آسان‌تر باشد. در حال حاضر بانک‌های سامان، ملی و مسکن انواع وام خرید منزل مسکونی را به متقاضیان ارائه می‌کنند که همان طور که در بخش‌های قبل مطالعه کردید شرایط این بانک‌ها در بخش عمومی یکسان و در برخی شرایط و مدارک لازم کمی متفاوت است. مدارک عمومی که معمولا برای دریافت وام مسکن باید ارائه شود عبارتند از:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه کارت ملی و شناسنامه دریافت کننده وام و کپی از صفحات آن (معمولا صفحه اول و دوم)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه کارت ملی و شناسنامه عکس دار ضامنین وام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه کارت ملی و شناسنامه فرد فروشنده ملک و کپی از آن‌ها
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه گواهی اشتغال به کار و یا ارائه فیش حقوقی با ذکر حقوق دریافتی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در صورتی که ملک، توسط وکیل، به فروش می‌رسد، ارائه اصل و کپی وکالتنامه وکیل، ارائه صفحات شناسنامه و کارت ملی موکل و ارائه شناسنامه و کارت ملی وکیل، الزامی است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل و کپی از سند ملک، با شرایطی که بانک، مد نظر دارد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه کارت پایان خدمت و یا کارت معافیت قانونی، برای آقایان در بسیاری از بانک ها، الزامی می‌باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل و کپی از <Link className="link" to="/building-certificate" target="_blank">  پروانه ساخت </Link>  که نشان دهنده تاریخ ساخت و قدمت ملک باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مدارک لازم جهت دریافت وام خرید منزل مسکونی ۱۴۰۲ از بانک مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای دریافت وام خرید مسکن از بانک مسکن مدارک مورد نیاز شامل موارد زیر می‌شود :
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل شناسنامه و کارت ملی فرد وام گیرنده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه کپی از صفحات شناسنامه و پشت و روی کارت ملی فرد وام گیرنده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه کارت حساب بانکی (در صورتی که فرد بخواهد از طریق حساب بانکی خود، وام خرید منزل مسکونی دریافت نماید.)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اوراق حق تقدم (در صورتی که فرد، بخواهد بدون حساب بانکی و با اوراق حق تقدم، وام خرید مسکن را دریافت نماید.)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل و کپی پروانه‌های ساخت که نشان دهنده میزان قدمت ملک باشد (این مدارک را می‌توان از شهرداری‌ها دریافت نمود.)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل و کپی از مبایعه نامه به همراه <Link className="link" to="/house-tracking-code" target="_blank">  کد رهگیری </Link>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل و کپی از کارت پایان خدمت و یا کارت معافیت قانونی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل شناسنامه و کارت ملی فرد فروشنده ملک و کپی از آن‌ها
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تکمیل فرم درخواست وام خرید مسکن در بانک مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مدارک لازم جهت دریافت وام خرید منزل مسکونی ۱۴۰۲ از بانک ملی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای دریافت وام خرید مسکن از بانک ملی مدارک نیز مورد نیاز است :
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تکمیل فرم درخواست دریافت وام خرید منزل مسکونی در بانک مسکن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه اصل شناسنامه و کارت ملی فرد وام گیرندهارائه مدارک مربوط به افتتاح حساب ارائه سند ملک به بانک و احراز مالکیت به بانک، الزامی است.ارائه قبض آب، برق، تلفن و غیره، برای احراز شرایط کد پستی ملک، الزامی است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ملک، معمولا توسط کارشناس، ارزش‌گذاری خواهد شد و مدرک مربوط به ارزش گذاری، باید به بانک ارائه شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مدارک لازم جهت دریافت وام خرید منزل مسکونی ۱۴۰۲ از بانک سامان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دریافت وام خرید مسکن از بانک سامان نیازمند مدارک زیر خواهد بود :
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل و فتوکپی از شناسنامه وام گیرنده
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل و فتوکپی از کارت ملی وام گیرندهارائه سند ملک ۶ دانگ، با شرایطی که بانک اعلام نموده است (ملک نباید اوقافی باشد، بلامعارض باشد و غیره)ارائه مدرک مربوط به ارزیابی ملک توسط بانک، الزامی است.ارائه وثیقه مشخص شده از سوی بانک و یا ارائه مدارک شناسایی ضامنین، الزامی می‌باشد.              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ارائه مدرکی برای احراز درآمد فرد متقاضی وام گیرنده، مانند گواهی اشتغال به کار، با قید درآمد و یا فیش حقوقی به بانک، الزامی است.
              </Typography>
              <Typography className="h2" variant="h2">
                میزان مبلغ وام خرید مسکن ۱۴۰۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="مبلغ وام مسکن ۱۴۰۲ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  وام خرید مسکن ۱۴۰۲
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگرچه در قانون جدید ارائه وام خرید مسکن سال ۱۴۰۲ اشاره شده بود که با توجه به افزایش قیمت مسکن، ارزش وام اعطایی از سوی بانک‌های عامل مبلغ ۷۵۰ میلیون تومان خواهد بود، اما هنوز بانک‌های عاملی چون بانک مسکن و بانک ملی از این قانون تبعیت نکرده و مبلغ وام ارائه شده از سوی آن‌ها به مراتب کمتر از مبلغ تعیین شده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مبلغ وام خرید مسکن ۱۴۰۲ در بانک مسکن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سقف تسهیلات بانک مسکن از محل صندوق پس‌انداز مسکن اولی ها: سقف تسهیلات بانک مسکن، به صورت انفرادی، در شهر تهران، ۸۰ میلیون تومان، سقف تسهیلات خرید مسکن، به صورت انفرادی، در مراکز استان‌ها و شهر‌های بالای ۲۰۰ هزار جمعیت، ۶۰ میلیون تومان و در سایر شهر ها، ۴۰ میلیون تومان است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سقف تسهیلات از صندوق پس‌انداز مسکن: سقف تسهیلات خرید مسکن ۱۴۰۲، از صندوق پس‌انداز مسکن، ۲۰ میلیون تومان بود که به میزان ۸۰ درصد از ملک ارزش‌گذاری شده تعلق می‌گیرد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سقف تسهیلات بانک مسکن از اوراق ممتاز: سقف تسهیلات بانک مسکن، به صورت انفرادی، در شهر تهران، ۱۰۰ میلیون تومان، سقف تسهیلات خرید مسکن، به صورت انفرادی، در مراکز استان‌ها و شهر‌های بالای ۲۰۰ هزار جمعیت، ۸۰ میلیون تومان و در سایر شهر ها، ۶۰ میلیون تومان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مبلغ وام خرید مسکن ۱۴۰۲ در بانک ملی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سقف تسهیلات خرید مسکن، در بانک ملی، فقط ۶۰ میلیون تومان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افراد دارای شرایط، می‌توانند در کنار این وام، وام جعاله مسکن بانک ملی به مبلغ ۱۰ میلیون تومان را نیز دریافت نمایند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مبلغ وام خرید مسکن ۱۴۰۲ در بانک سامان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وام خرید منزل مسکونی، بانک سامان، حداکثر مبلغی است که از سوی بانک مرکزی، اعلام می شود. در واقع سقف تسهیلات خرید مسکن، از سوی بانک سامان، ۷۵۰ میلیون تومان است. (این مبلغ وام، برای کلان شهر‌ها خواهد بود.) سقف تسهیلات خرید مسکن، در شهر‌های کوچک، از سوی بانک سامان، ۵۰۰ میلیون تومان است. این وام به میزان ۵۰ درصد از کل مبلغ خرید مسکن، به افراد واجد شرایط ۱۴۰۲، تعلق می‌گیرد.
              </Typography>
              <Typography className="h2" variant="h2">
                میزان سپرده مورد نیاز جهت دریافت وام خرید مسکن ۱۴۰۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="سپرده مورد نیاز وام مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سپرده مورد نیاز وام مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                میزان سپرده مورد نیاز جهت دریافت وام خرید مسکن، برای آن دسته از طرح‌هایی که نیاز به سپرده‌گذاری دارند، مشخص است. معمولا این سپرده باید در مدت زمان مشخصی در بانک بماند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                میزان سپرده مورد نیاز جهت دریافت وام خرید منزل مسکونی ۱۴۰۲​ در بانک مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مقدار سپرده‌ای که برای دریافت وام خرید مسکن از بانک مسکن به این شرح است:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                - برای دریافت وام خرید منزل مسکونی از محل صندوق پس‌انداز مسکن اولی ها: برای دریافت وام خرید منزل مسکونی، از محل صندوق پس‌انداز مسکن یکم، نیاز است که اشخاص، حتما در بانک مسکن، حساب باز کرده باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این وام، به افرادی تعلق می‌گیرد که تاریخ باز کردن حساب آن ها، قبل از ۸ آذر ماه دو سال گذشته باشد. میزان سپرده مورد نیاز برای دریافت وام ۸۰ میلیون تومانی، ۱۳.۵ میلیون تومان، میزان سپرده مورد نیاز برای دریافت وام ۶۰ میلیون تومانی، ۱۰ میلیون تومان و میزان سپرده مورد نیاز برای دریافت وام ۴۰ میلیون تومانی، ۶.۷ میلیون تومان است. حداقل مدت زمان سپرده گذاری، سه سال است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                - مقدار سپرده برای دریافت وام خرید مسکن از صندوق پس‌انداز مسکن: افراد متقاضی باید حداقل به مدت یک سال تا حداکثر دو سال، سپرده‌گذاری داشته باشند و حداقل میزان سپرده گذاری، ۲.۹ میلیون تومان باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                - میزان سپرده مورد نیاز، جهت دریافت وام خرید منزل مسکونی از اوراق ممتاز: دریافت وام خرید منزل مسکونی از محل اوراق ممتاز، نیاز به سپرده‌گذاری نیست و افراد، می‌توانستند با خرید اوراق ممتاز از سازمان بورس، اقدام به دریافت وام خرید منزل مسکونی نمایند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                میزان سپرده مورد نیاز جهت دریافت وام خرید منزل مسکونی ۱۴۰۲ در بانک ملی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مقدار سپرده مورد نیاز برای دریافت وام خرید مسکن از بانک ملی ۱۶ میلیون تومان است، در یکی از طرح‌ها این مبلغ باید به مدت یک ماه و در یکی از طرح ها، این مبلغ باید به مدت سه ماه در حساب بماند تا افراد شرایط لازم را برای دریافت وام خرید منزل مسکونی داشته باشند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-fraud" target="_blank">
                  بیشتر بخوانید : انواع کلاهبرداری در معاملات ملک
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مقدار سپرده مورد نیاز برای دریافت وام خرید مسکن از بانک سامان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افرادی که قصد دریافت ۷۵۰ میلیون تومان وام از بانک سامان را دارند، باید حتما در بانک سامان، دارای حساب قرض الحسنه باشند. فرد متقاضی وام خرید منزل مسکونی باید به مدت ۳ تا ۶ ماه، میانگین حساب داشته باشد. همچنین، در نظر داشته باشید که به میزان ۱۵ درصد از مبلغ کل وام، نزد بانک مسدود خواهد شد.
              </Typography>
              <Typography className="h2" variant="h2">
                وام ودیعه مسکن چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در چند سال گذشته دولت وقت با توجه به شرایط بحرانی دوران کرونا و مشکلات کسب و کارها در این دوران، اقدام به ارائه وام ودیعه مسکن به مستأجرین واجد شرایط کرد. میزان مبلغ وام اجاره مسکن ۱۴۰۲، برای متقاضیان دریافت این وام جهت پرداخت اجاره مسکن، از سوی بانک مرکزی، در کلانشهر‌ها و همچنین، سایر شهر‌های دیگر، مشخص می‌گردد.
              </Typography>
              <Typography className="h2" variant="h2">
                میزان مبلغ وام بازسازی خانه در سال ۱۴۰۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در سال ۱۴۰۲ بسیاری از بانک ها به ارائه وام بازسازی خانه روی آورده اند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                میزان وام بازسازی منزل در بانک ها متفاوت است که در زیر این میزان این وام در بعضی از بانکها را شرح میدهیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                میزان مبلغ وام بازسازی خانه در بانک ملی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مبلغ وام بازسازی خانه در سال ۱۴۰۲، ۴۰ میلیون تومان بوده است که در بانک ملی این میزان به ۵۰ میلیون تومان افزایش یافته است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                میزان مبلغ وام بازسازی خانه در بانک مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                میزان مبلغ وام بازسازی خانه در سال ۱۴۰۲ در بانک مسکن به محل اقدام آن بستگی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر از محل سپرده گذاری اقدام به دریافت وام کنید تا سقف ۵ میلیون تومان می باشد. اگر از محل اوراق ممتاز یا همان حق تقدم، درخواست وام دارید سقف مبلغ وام از بانک مسکن ۴۰ میلیون تومان خواهد بود.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله از وب سایت هومینجا سعی کردیم تا شما را با شرایط، مدارک و نحوه دریافت انواع وام مسکن در سال 1402 را به طور مفصل شرح دهیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گروه هومینجا علاوه بر ارائه بهترین وب سایت در زمینه جستجو و درج آگهی املاک، آماده مشاوره و راهنمایی شما عزیزان در امور مربوط به معاملات ملک می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                هومینجا سامانه <Link className="link" to="/Best-Iranian-Real-Estate-Site" target="_blank">  تخصصی </Link>  املاک و مستغلات
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("بازار مسکن ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  بازار مسکن

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article55;