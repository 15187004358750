import {Button, Grid,TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl,convertToPersianNumber} from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


class AgancyAdmin extends React.Component{

    isMount = false ;
    token = localStorage.getItem("tokenUserHomeInja");

    constructor(props){
      super(props);
      this.state={
        message:'',
        giftShow:false,
        openAlert:false,
        agenciesInfo: null,
        agencySort: null,
        searchText: "",
        agencyCounter: 5,
        typeAlert:3
      }
    }

    componentDidMount(){
      document.title = "معرفی آژانس های املاک | آژانس ها"
      this.isMount = true ;
      if(this.isMount){
        this.getAllAgency();
      }
    }

    componentWillUnmount(){
      this.isMount = false ;
    }

    getAllAgency = () => {
      this.setState({giftShow:true});
      Promise.resolve(axios({
        url: baseUrl + "v1/agancy/statistics",
        method: "GET",
      }))
      .then((res) => {
        this.setState({agenciesInfo:res.data.data,agencySort:res.data.data});
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2});
      })
      .finally(()=>{
        this.setState({giftShow:false});
      })
    }

    closeAlertModal = () => {
      this.setState({openAlert:false});
    }

    onChangeSearchText = (event,value) => {
      this.setState({searchText:event.target.value})
    }

    onClickSearch = () => {
      let agenciesInfo = this.state.agenciesInfo ;
      let searchText = this.state.searchText ;
      searchText = searchText.trim();
      let result = [] ;
      if(searchText === ""){
        this.setState({agencyCounter:5,agencySort:this.state.agenciesInfo});
      }else{
        for(let i = 0 ; i < agenciesInfo.length ; i++){
          if(agenciesInfo[i].name.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
          if(agenciesInfo[i].manager_name.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
          if(agenciesInfo[i].phone.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
          if(agenciesInfo[i].address.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
          let count = agenciesInfo[i].count.toString();
          if( count.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
          if(agenciesInfo[i].inputer.name.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
          if(agenciesInfo[i].inputer.lastname.includes(searchText)){
            result.push(agenciesInfo[i]);
            continue
          }
        }
        this.setState({agencyCounter:5,agencySort:result});
      }
    }

    createAgency = () => {
      let result = [];
      let agencies = this.state.agencySort.slice(0,this.state.agencyCounter);
      agencies.forEach((element,index) => {
        result.push(
          <Grid container alignItems="center" key={index}
            sx={{width:"100%",margin:"10px 0",border:"2px solid #9AD6F8",borderRadius:"30px 8px 8px 8px",padding:"10px 0",
              background:"linear-gradient(0deg, rgba(154,214,248,0.1) 0%, rgba(255,255,255,1) 100%)"}} 
          >
            <Grid item xs={12} md={3} sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Typography component={"img"} src={element.logo_path ? element.logo_path : noLogoAgancyImage} alt="agency_logo"
                  sx={{width:"120px",height:"120px",borderRadius:"50%"}}
                />
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container sx={{textAlign:{xs:"center",md:"right"}}}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",color:"#01A7C4"}}>نام آژانس:</Typography>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"normal",display:"inline-block",marginRight:"5px"}}>{element.name}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",color:"#01A7C4"}}>نام مدیر:</Typography>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"normal",display:"inline-block",marginRight:"5px"}}>{element.manager_name}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",color:"#01A7C4"}}>شماره تماس:</Typography>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"normal",display:"inline-block",marginRight:"5px"}}>{convertToPersianNumber(element.phone)}</Typography>
                </Grid>
              </Grid>
              <Grid container sx={{textAlign:{xs:"center",md:"right"},marginTop:{xs:"0",md:"15px"}}}>
                <Grid item xs={12} md={4}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",color:"#01A7C4"}}>آدرس:</Typography>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"normal",display:"inline-block",marginRight:"5px"}}>{convertToPersianNumber(element.address) }</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",color:"#01A7C4"}}>تعداد آگهی:</Typography>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"normal",display:"inline-block",marginRight:"5px"}}>{convertToPersianNumber(element.count)}</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",color:"#01A7C4"}}>ثبت کننده آژانس:</Typography>
                  <Typography sx={{fontFamily:"shabnam",fontWeight:"normal",display:"inline-block",marginRight:"5px"}}>
                    {element.inputer.name} {element.inputer.lastname}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      });
      return result ;
    }

    onClickMoreAgency = () => {
      this.setState({agencyCounter:this.state.agencyCounter + 5});
    }

    onClickEnter = (event) => {
      if(event.key === "Enter"){
        this.onClickSearch()
      }
    }

    onClickClearSearch = () => {
      this.setState({searchText:""});
    }

    render() {
      return (
          <Grid sx={{marginTop:{xs:"120px",md:"150px"},width:'100%',marginRight:{md:'270px',xs:'0'}}}>
            <Grid 
              sx={{width:"100%"}}
            >
              <Grid sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                <Grid sx={{textAlign:"center",width:{xs:"98%",md:"95%",lg:"40%"}}}>
                  <Grid sx={{display:"flex",padding:"10px 0 0"}}>
                    <TextField 
                      sx={{bgcolor:"#eee",borderRadius:"0 4px 30px 0",flexGrow:"1"}}
                      InputProps={{
                        sx:{
                          fontFamily:"shabnam",fontSize:"20px"
                        }
                      }}
                      value={this.state.searchText}
                      onChange={this.onChangeSearchText}
                      inputProps={{ maxLength: 30 }}
                      onKeyPress={(event) => {this.onClickEnter(event)}}
                    />
                    { this.state.searchText.length > 0 ?
                        <Button onClick={this.onClickClearSearch}
                          sx={{bgcolor:"#eee","&:hover":{bgcolor:"#eee"},"&:hover svg":{color:"black !important"},minWidth:"30px",maxWidth:"30px",borderRadius:"0",color:"white"}} 
                        >
                          <CloseRoundedIcon sx={{color:"gray !important"}}/>
                        </Button>
                      : <Grid sx={{width:"30px",bgcolor:"#eee"}}></Grid>
                    }
                    <Button disabled={this.state.giftShow} onClick={this.onClickSearch}
                      sx={{bgcolor:"#14C9E8","&:hover":{bgcolor:"#14C9E8"},flexBasis:"200px",borderRadius:"30px 0 0 4px",fontFamily:"shabnam",fontWeight:"bold",color:"white",
                        fontSize:{xs:"15px",md:"20px"}}} 
                    >
                      جستجو آژانس
                      <SearchRoundedIcon sx={{color:"white !important",fontSize:{xs:"20px",lg:"30px"}}}/>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",padding:"30px 0 0",fontSize:"20px"}}>لیست آژانس ها</Typography>
              <Grid sx={{width:"100%",padding:"2% 2%"}}>
                {this.state.agencySort ?
                  this.state.agencySort.length > 0 ?
                    <>
                      {this.createAgency()}
                      { this.state.agencySort.length > this.state.agencyCounter ?
                          <Grid item xs={4} lg={8} xl={12} sx={{width:"100%",textAlign:"center",padding:"4% 0"}}>
                            <Button sx={{width:"300px",bgcolor:"rgb(20, 201, 232)",fontFamily:"shabnam",fontWeight:"bold",color:"black"}}
                              onClick={this.onClickMoreAgency}
                            >
                              {"موارد بیشتر"}
                            </Button>
                          </Grid>
                        :<Grid></Grid>
                      }
                    </>  
                  : <Grid>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",padding:"20px 0"}}>
                        آژانسی جهت نمایش وجود ندارد
                      </Typography>
                    </Grid>
                : <Grid></Grid>
                }
              </Grid>
            </Grid>
            
              
            <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
            <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          </Grid>
      )
    };
}

export default AgancyAdmin;