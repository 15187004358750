import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import React from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useState } from "react";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SideBar = ({ access }) => {
  const [openSide, setOpenSide] = useState(false);
  const [expand, setExpand] = useState(false);
  const [expandAccess, setExpandAccess] = useState(false)

  const toggleAcordion = () => {
    expand === false && expandAccess === true && setExpandAccess(false)
    setExpand((prev) => !prev);
  };

  const toggleAccess = () => {
    expand === true && expandAccess === false && setExpand(false)
    setExpandAccess((prev) => !prev);
  };

  const sideHandler = () => {
    setOpenSide(!openSide)
    setExpand(false)
    setExpandAccess(false)
  }

  const Data = ["محله گردی", "دانستنی ها", "معماری و دکوراسیون", "مسائل حقوقی", "تکنولوژی و فناوری", "خانه و سبک زندگی", "بازار مسکن", "مشاورین املاک"]

  const onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  };

  return (
    <>
      <Grid sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", width: { md: "18%", lg: "15%" }, position: "fixed", top: "110px", right: "3%", flexDirection: 'column' }}>
        <Grid sx={{
          display: { xs: 'none', md: "flex" }, alignItems: "center", justifyContent: "center", width: "100%", padding: "10px 5px", background: "white",
          boxShadow: "0px 0px 14px 0px rgb(20,201,232)", borderRadius: "0 50px", flexDirection: "column"
        }}>
          <Typography sx={{ fontSize: { md: "14px", lg: "16px" }, color: "#4B4B4B", fontWeight: "700", width: "100%", textAlign: 'center', padding: "5px 0", fontFamily: "shabnam" }}>دسته بندی ها</Typography>
          {
            Data.map((item, index) => (
              <NavLink to={"/HomeMag"} onClick={() => onClickTag(item)} key={index} style={{ textDecoration: 'none', width: "100%", display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column" }} target="_blank">
                <Typography sx={{ fontSize: { md: "14px", lg: "16px" }, color: "#4B4B4B", textAlign: 'center', fontFamily: "shabnam", padding: "5px 0" }}>
                  {item}
                </Typography>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "1px", width: "80%", background: "#E7F0F3", margin: "5px 0" }}></Grid>
              </NavLink>
            ))
          }
        </Grid>
        {
          access.length > 0 &&
          <Grid sx={{
            display: { xs: 'none', md: "flex" }, alignItems: "center", justifyContent: "center", width: "100%", padding: "20px 10px", background: "white",
            boxShadow: "0px 0px 14px 0px rgb(20,201,232)", borderRadius: "0 50px", margin: "30px 0", flexDirection: 'column'
          }}>

            <Typography sx={{ fontSize: { md: "14px", lg: "16px" }, color: "#4B4B4B", fontWeight: "700", width: "100%", textAlign: 'center', padding: "10px 0", fontFamily: "shabnam" }}>دسترسی سریع</Typography>
            {
              access.map((item, index) => (
                <NavLink to={item.link} key={index} style={{ textDecoration: 'none' }} target="_blank">
                  <Typography sx={{ fontSize: { md: "14px", lg: "16px" }, color: "#4B4B4B", textAlign: 'center', fontFamily: "shabnam", padding: "5px 0" }}>
                    {item !== undefined && item.text.length <= 30 ? item.text : (item.text.substr(0, 30) + "...")}
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "1px", width: "100%", background: "#E7F0F3", margin: "5px 0" }}></Grid>
                </NavLink>
              ))
            }
          </Grid>
        }

      </Grid>
      <Grid sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", justifyContent: "flex-end", margin: "10px 0px", width: "100%", position: openSide ? "fixed" : "unset", top: "70px", zIndex: "300", left: "10px" }}>
        <DashboardIcon onClick={sideHandler} sx={{ fontSize: "30px", filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)", padding: "0 5px" }} />
      </Grid>
      <Grid sx={{ display: openSide ? 'flex' : "none", alignItems: 'center', justifyContent: 'flex-start', flexDirection: "column", overflow: "scroll !important", width: "100vw", height: "100vh", position: 'fixed', top: "50px", background: "#FFFFFF", }}>
        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "90%", sm: "100%" }, marginTop: "80px" }}>
          <Accordion onClick={toggleAcordion} expanded={expand ? true : false} sx={{ border: "none", boxShadow: "1px 2px 7px 0px rgba(0, 0, 0, 0.15)", borderRadius: "16px !important", width: "100%", }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#77BABF", fontSize: { xs: "17px", md: "24px" } }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ "& .MuiExpansionPanelSummary-root.Mui-expanded": { borderTop: "none" }, borderRadius: "16px" }}
            >
              <Typography sx={{ fontSize: "16px", color: "#4B4B4B", fontFamily: "shabnam" }}>دسته بندی ها</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", flexDirection: "column" }}>
                {
                  Data.map((item, index) => (
                    <NavLink to={"/HomeMag"} key={index} onClick={() => onClickTag(item)} style={{ textDecoration: 'none', display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column", width: "80%" }} target="_blank">
                      <Typography sx={{ fontSize: "12px", color: "#4B4B4B", textAlign: 'center', fontFamily: "shabnam", padding: "5px 0" }}>
                        {item}
                      </Typography>
                      <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "1px", width: "100%", background: "#E7F0F3", margin: "5px 0" }}></Grid>
                    </NavLink>
                  ))
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {access.length > 0 &&
          <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: { xs: "90%", sm: "100%" }, marginTop: "20px" }}>
            <Accordion onClick={toggleAccess} expanded={expandAccess ? true : false} sx={{ border: "none", boxShadow: "1px 2px 7px 0px rgba(0, 0, 0, 0.15)", borderRadius: "16px !important", width: "100%", }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#77BABF", fontSize: { xs: "17px", md: "24px" } }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ "& .MuiExpansionPanelSummary-root.Mui-expanded": { borderTop: "none" }, borderRadius: "16px" }}
              >
                <Typography sx={{ fontSize: "16px", color: "#4B4B4B", fontFamily: "shabnam" }}>دسترسی سریع</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ "& .MuiCollapse-root": { visibility: openSide ? "visible !important" : "hidden !important" } }}>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", width: "100%", flexWrap: "wrap" }}>
                  {
                    access.map((item, index) => (
                      <NavLink key={index} to={item.link} style={{ textDecoration: 'none' }} target="_blank">
                        <Typography sx={{ fontSize: "12px", color: "#4B4B4B", textAlign: 'center', fontFamily: "shabnam", padding: "5px 0" }}>
                          {item !== undefined && item.text.length <= 40 ? item.text : (item.text.substr(0, 40) + "...")}
                        </Typography>
                        <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "1px", width: "100%", background: "#E7F0F3", margin: "5px 0" }}></Grid>
                      </NavLink>
                    ))
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>}
      </Grid>
    </>
  )
}

export default SideBar;