import { Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import HelpIcon from '@mui/icons-material/Help';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setCanonicalTag } from "./materilas/vmp";


class FAQ extends React.Component {

  componentDidMount() {
    document.title = " پرسش و پاسخ وب سایت املاک و مستغلات | هومینجا";
    document.getElementById("main-heading").innerHTML = "خرید - فروش - رهن - اجاره - ملک - مسکن - آپارتمان - خانه - برج - اداری - پنت هاوس - هومینجا"
    document.getElementsByTagName('meta')["description"].content =
      "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
    setCanonicalTag();
  }

  closeAlertModal = () => {
    this.setState({ openAlert: false });
  }

  register = [
    {
      q: "برای ثبت نام در وب سایت املاک هومینجا چه کاری باید انجام بدم؟ ",
      a: "بعد از فشردن دکمه ثبت نام و وارد کردن شماره تلفن همراه، کد پیامک شده را به همراه رمز عبور ثبت کنید."
    },
    {
      q: "برای ثبت نام در سامانه هومینجا باید مبلغی پرداخت کنم؟",
      a: "خیر ثبت نام دروب سایت هومینجا کاملا رایگان می باشد."
    },
    {
      q: "آیا بعد از ثبت نام امکان ویرایش شماره همراه وجود دارد؟",
      a: "خیر. هر پروفایل براساس شماره همراه یکتا ساخته می شود و تغییر آن امکان پذیر نیست."
    },
    {
      q: "آیا برای استفاده از خدمات وب سایت هومینجا حتما باید ثبت نام کنم؟",
      a: "برای جستجوی ملک نیازی به ثبت نام نیست ولی برای استفاده از خدمات دیگر که در ذیل گفته می شود ثبت نام الزامی است."
    },
    {
      q: "آیا امکان ثبت نام بدون کدملی وجود دارد؟",
      a: "بله شما می توانید با ثبت نام از امکانات پنل خود آگاه شوید ولی برای جلوگیری ازانتشار آگهی با  اطلاعات اشتباه، درج کدملی در قسمت اطلاعات الزامی است."
    },
    {
      q: "آیا می توانم پروفایل کاربری خود را حذف کنم؟",
      a: "خیرپروفایل قابل حذف نیست. در صورت عدم فعالیت و یا انجام اقدامات خارج از قوانین سایت مانند درج اگهی نامناسب و اقدامات خرابکارانه،غیرفعال می شود."
    },
    {
      q: "آیا امکان مراجعه حضوری به دفتر مشاورین هومینجا وجود دارد؟",
      a: "بله در حال حاضر شعبه تهرانپارس غربی – خیابان برادران زفرقندی – پلاک ۱۴۸ – واحد ۶۵ فعال و میزبان شماست."
    },
    {
      q: "سامانه هوشمند املاک هومینجا برای چه کسانی مناسب است؟",
      c: [
        <span>
          ۱.	مشاوران و مدیران آژانس املاکی  که خواهان افزایش فروش و بازدید فایل هایشان در تخصصی ترین سامانه درج آگهی املاک هستند.
          <br />
          ۲. مالکان به منظور درج آگهی املاک خود همراه با تهیه کاتالوگ رایگان و انجام کلیه امور مربوط به عقد  قرارداد ملک نیزمی توانند از پلتفرم تخصصی هومینجا بهره ببرند.
          <br />
          ۳.	کسانی که به دنبال خرید، فروش و اجاره یا سرمایه گذاری در املاک هستند.
          <br />
          ۴. کسانی که تمایل دارند بعنوان مشاور آنلاین هومینجا از معاملات املاک درامد کسب کنند.
        </span>
      ]
    },

  ]

  userType = [
    {
      q: "تعداد آگهی های فعال انواع کاربری به چه صورت است؟",
      c: [
        <TableContainer sx={{ overflow: "auto" }}>
          <Table sx={{ maxWidth: "1200px", minWidth: "600px", margin: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    کاربر عادی
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    صاحب ملک
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    مشاور مستقل
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    مشاور هومینجا
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{ fontFamily: "shabnam" }}>
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۳
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۵۰
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۵۰۰
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۱۵۰۰
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ]
    },
    {
      q: "کاربرعادی چیست ؟",
      a: "هر کاربری که دروب سایت هومینجا ثبت نام کند، درابتدا کاربر عادی است. سپس می تواند در صورت تمایل به احراز هویت، پروفایل کاربری خود را به مالک، مشاورمستقل ویا مشاور هومینجا تبدیل کند."
    },
    {
      q: "آیا اعتبار اولیه ای که به تمام انواع کاربری داده می شود، یکسان است؟",
      a: "خیر  پس از تبدیل کاربری، اعتبارات ماهانه بسته به نوع کاربری متغیر است. در صورت استفاده از اعتبار هدیه، هر ماه  اعتبار رایگان به شما تعلق می گیرد."
    },
    {
      q: "چگونه درخواست تغییر کاربری دهم؟ چه مدتی زمان می برد تا تغییر کاربری تایید شود؟",
      a: "وارد پنل کاربری شده سپس از قسمت  پروفایل تغییر کاربری را انتخاب کنید. دراسرع وقت، تغییر کاربری شما تایید می شود."
    },
    {
      q: "آیا صاحبان ملک می توانند مستقیم آگهی ملک خود را در هومینجا ثبت کنند؟ ",
      a: "بله بعد از تایید پشتیبان به عنوان مالک دسترسی شما برای درج اگهی باز می باشد."
    },
    {
      q: "آیا شماره تماس مالک نمایش داده می شود؟",
      a: "خیر شما می توانید در صورت تمایل برای هر آگهی درخواست بازدید داشته باشید."
    },
    {
      q: "چه مدارکی جهت ثبت نام مشاورین هومینجا مورد نیاز است؟",
      a: "مدارک هویتی و سفته بانکی که باید بصورت حضوری به شرکت وب گستران سورین ارائه شود. همچنین قراردادی به صورت حضوری تنظیم وعقد می شود."
    },
    {
      q: "من مشاور املاک  هستم . چطور به افزایش فروش من کمک می کنید؟",
      a: "ما به شما اعتبار رایگان می دهیم تا آگهی شما با کمترین هزینه بین سایت های همکار درج ، و در محیط اینترنت شناخته شود."
    },
    {
      q: "آیا مشاورین املاک می توانند در خواست ملک ثبت کنند؟ ",
      a: "بله قابلیت ثبت درخواست ملک  برای مشاوران املاک هم وجود دارد."
    },
    {
      q: "مشاور مستقل هستم می توانم بعنوان مشاور هومینجا نیز فعالیت داشته باشم؟",
      a: "بله با عقد قرارداد مابین شما و هومینجا می توانید در هر دو زمینه فعالیت کنید."
    },
    {
      q: "آیا مشاورین مستقل می توانند از فایلینگ املاک هومینجا استفاده کنند؟",
      a: "مشاوران مستقل از مزایای اعتبارات ماهانه جهت به حداقل رساندن هزینه آگهی های خود برخوردارند. لذا می توانند از تمام آگهی های دیگر سایت هم استفاده رایگان داشته باشند."
    },
    {
      q: "فرق بین مشاور مستقل و وابسته هومینجا چیست؟",
      a: "مشاور وابسته ، مشاور هومینجا می باشد و از تمامی امکانات پلتفرم هومینجا بصورت رایگان استفاده می کند. مشاور مستقل با برند خود کار می کند و دسترسی های کمتری نسبت به مشاور هومینجا دارد."
    },
    {
      q: "چه کسانی می توانند بعنوان مشاوران املاک هومینجا ثبت نام کنند؟",
      a: "کسانیکه علاقه مند به کسب درامد از معاملات ملک هستند چه بصورت حضوری و چه بصورت آنلاین میتوانند به جمع مشاورین هومینجا بپیوندند. برای اطلاعات بیشتر با پشتیبان هومینجا در ارتباط باشید."
    },
    {
      q: "چگونه می توان در هومینجا بعنوان مشاور ثبت نام کرد؟",
      a: "پس از ثبت نام و تایید آن، از قسمت پروفایل اقدام به تبدیل کاربری به مشاور وابسته هومینجا کنید."
    },
    {
      q: "چگونه می توانم در هومینجا درآمد داشته باشم؟",
      a: "مشاورین وابسته هومینجا علاوه بر دسترسی به درخواستهای ملک موجود در سامانه هومینجا، میتوانند اگهی ملک هم به تعداد بالا منتشر کنند. از هر فعالیت مشاورین هومینجا که به عقد قرارداد منجر شود، درصدی از کمیسیون معامله را دریافت میکنند."
    },
    {
      q: "مزیت ثبت نام به صورت مشاور هومینجا چیست؟",
      a: "از تمامی معاملاتی که با فعالیت شما تنظیم شده است کارمزد دریافت می کنید. مشاور آنلاین هومینجا محدود به یک محله و منطقه نیست. پس درامد مشاوران هومینجا سقفی ندارد."
    },

  ]

  conditionAndExpence = [
    {
      q: "خدمات سایت املاک  هومینجا چیست؟",
      a: "خدمات وب سایت جست و جوی املاک و مستغلات هومینجا شامل: خرید و فروش انواع ملک، رهن و اجاره انواع ملک مسکونی، اداری و تجاری، درخواست انواع ملک و مسکن، عقد قراردادهای ملکی می باشد."
    },
    {
      q: "درج آگهی در هومینجا چگونه انجام می شود؟",
      a: "بعد از ثبت نام و وارد شدن به پنل کاربری اطلاعات آگهی را تکمیل و) با انتخاب فیلترهای اضافه)  آگهی خود را منتشر کنید. هرچه اطلاعات ملک کاملتر و دقیق تر باشد بازخورد اگهی شما بیشتر خواهد بود."
    },
    {
      q: "برای ثبت آگهی خانه نیاز به خرید اشتراک هست؟",
      a: "برای ثبت آگهی نیاز به اعتبار دارید که بر اساس هر نوع کاربری هر ماه هدیه از هومینجا دریافت می کنید. اگر بیشتر از آن نیاز داشته باشید از طریق دکمه افزایش موجودی در پنل کاربری خود اقدام به افزایش اعتبار میفرمایید."
    },
    {
      q: "آیا امکان درج عکس ملک در هومینجا وجود دارد؟",
      a: "بله شما می توانید با درج چندین عکس به بازدید بیشتر ملک خود کمک کنید."
    },
    {
      q: "آیا امکان ویرایش اطلاعات ثبت شده ملکی وجود دارد؟",
      a: "بله. در صورت ویرایش، آگهی شماغیرفعال می شود و در صف انتشار قرار می گیرد تا زمانیکه پشتیبان بررسی و تایید نماید."
    },
    {
      q: "برای فروش ملک در هومینجا چه کنم؟",
      a: "ابتدا ثبت نام کنید بعد از تایید کاربری آگهی ملک را ثبت نمایید. بعد از تایید آگهی تمام کارها را به هومینجا بسپارید."
    },
    {
      q: "آیا از هومینجا می توان برای رهن و اجاره استفاده کرد؟",
      a: "بله هومینجا انواع معاملات ملک را پشتیبانی می کند."
    },
    {
      q: "مدت اعتبار هر آگهی چقدر است؟ ",
      a: "تا زمانی که شخص آگهی دهنده آن را غیرفعال کند آگهی دارای اعتبار است."
    },
    {
      q: "اعتبار هدیه را تا چه مدت میتوان استفاده کرد؟ مدت استفاده از اعتبار هدیه محدود است؟",
      a: "محدودیت زمانی برای استفاده از اعتبار هدیه وجود ندارد. اما تا زمانی که از اعتبار اولیه استفاده نشود اعتبار هدیه مجدد به کابر تعلق نمی گیرد."
    },
    {
      q: "آیا خدمات عکاسی از منزل هم دارید؟",
      a: "بله هومینجا برای مالکانی که ملک خود را فقط در سایت هومینجا آگهی کنند خدمات عکاسی و تهیه کاتالوگ از منزل هم ارائه می کند."
    },
    {
      q: "آگهی منتشر شده چه مدت روی وب سایت هومینجا می ماند؟",
      a: "تا زمانی که ملک به فروش برسد یا مالک آن را حذف کند."
    },
    {
      q: "آیا امکان ذخیره آگهی مورد علاقه در هومینجا وجود دارد؟",
      a: "بله با کلیک روی آگهی صفحه ای باز می شود که با ضربه روی نشان کردن آگهی می توان اگهی مورد نظر را ذخیره کرد. سپس در قسمت نشان داده شده در پروفایل کاربر قابل مشاهده می باشد."
    },
    {
      q: "آیا لوکیشن تمام آگهی ها در هومینجا مشخص شده است؟",
      a: "در صورت درج لوکیشن توسط کاربر مکان ملک مورد نظر قابل نمایش است."
    },
    {
      q: "محدوده فعالیت هومینجا چه مناطقی می باشد؟",
      a: "در حال حاضر با تمرکز بر تهران فعالیت خود را آغاز کرده است. اما هدف سایت هومینجا تبدیل شدن به بزرگترین بانک املاک کشورمی باشد."
    },
    {
      q: "چگونه می توان در هومینجا ملک مورد نظر خود را جستجو کرد؟",
      a: "در صفحه اصلی با فیلترهای پیشرفته نوع ملک مورد نظر خود را جستجو کنید."
    },
    {
      q: "آیا امکان جستجوی ملک با مشخصات مورد نظر وجود دارد؟",
      a: "بله در صفحه اصلی با استفاده از فیلترهای پیشرفته به راحتی ملک خود را جستجو کنید."
    },
    {
      q: "پس از پیدا کردن ملک مورد نظر چه باید کنم؟",
      a: "در صفحه آگهی ثبت بازدید را انتخاب نمایید. مشاورین و پشتیبانان هومینجا در اسرع وقت برای شما وقت بازدید تعیین می کنند."
    },
    {
      q: "فرق بین نردبان و تازه سازی در چیست؟",
      a: "در تازه سازی تاریخ انتشار شما مجددا به روز می شود. اما در نردبان آگهی شما در شهر و محله مورد نظر در صفحات اول قرار می گیرد."
    },
    {
      q: "چگونه می توانم آگهی خود را vip کنم؟",
      a: "درخواست آن را در قسمت آگهی من اعلام می کنید تا به تربیت اولویت وارد vip مورد نظر شوید."
    },
    {
      q: "برای بروز رسانی  تاریخ آگهی و نمایش چه کاری باید انجام دهم؟",
      a: "برای بروز رسانی آگهی وارد پروفایل شوید. سپس روی آگهی مورد نظر کلیک کنید و سپس دکمه تازه سازی را بزنید."
    },
    {
      q: "مدت نمایش آگهی vip  چند روز است؟",
      a: "در حال حاضر سه روز می باشد و در آینده نسبت به تعداد و نیاز کاربران احتمال بیشتر یا کمتر شدن آن وجود دارد."
    },
    {
      q: "برای نردبان کردن آگهی در هومینجا چه باید کرد؟",
      a: "از قسمت آگهی های من می توانید اگهی مورد نظرتان را نردبان کنید."
    },
    {
      q: "آیا می توان آگهی رهن و اجاره را نیز vip کرد؟",
      a: "بله برای هر آگهی که درهومینجا ثبت می شود می توان درخواست vip ثبت کرد."
    },
    {
      q: " هزینه خدمات مربوط به آگهی ملک در سایت هومینجا چقدر است؟",
      c: [
        <TableContainer sx={{ overflow: "auto" }}>
          <Table sx={{ maxWidth: "1200px", minWidth: "900px", margin: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    نوع
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    محل قرارگیری
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    مدت زمان
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    هزینه
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontFamily: "shabnam" }} align="center">
                    توضیحات
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" sx={{ fontFamily: "shabnam" }}>
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    آگهی عادی
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ fontFamily: "shabnam" }}>
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    صفحه آگهی ها
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    نامحدود
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۱۰۰۰۰ تومان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    بر اساس محله و منطقه به ترتیب تاریخ انتشار نمایش داده می شود
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" >
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    تازه سازی
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    صفحه آگهی ها
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    نامحدود
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۱۰۰۰۰ تومان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    تاریخ انتشار به روز می شود
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" >
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    نردبان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    صفحه آگهی ها
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    سه روز
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۱۵۰۰۰ تومان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    بر اساس محله و منطقه در اولویت هستند
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" >
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    Vip1
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    اول صفحه اصلی سایت
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    سه روز
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۳۹۹۰۰۰ تومان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    دارای صف انتشار می باشد و بر اساس تاریخ رزرو به ترتیب منتشر می شود
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" >
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    Vip2
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    صفحه اصلی بعد VIP1
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    سه روز
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۱۹۹۰۰۰ تومان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    دارای صف انتشار می باشد و بر اساس تاریخ رزرو به ترتیب منتشر می شود
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" >
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    Vip3
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    صفحه اصلی بعد VIP2
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    سه روز
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    ۹۹۰۰۰ تومان
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ fontFamily: "shabnam" }}>
                    دارای صف انتشار می باشد و بر اساس تاریخ رزرو به ترتیب منتشر می شود
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ]
    },
    {
      q: "من چگونه می توانم همیشه آگهی در صف اول نمایش قرار دهم؟",
      a: "شما می توانید از ویژگی های مختلف نظیر تبدیل به vip و رزرو آن ، آگهی خود را در صفحه اول هومینجا قرار دهید."
    },
    {
      q: "برای ثبت نام بعنوان مشاور باید هزینه ای پرداخت کرد؟",
      a: "خیر. ثبت نام در هومینجا برای همه رایگان است .هزینه تنها بابت درج آگهی، تازه سازی و نردبان و همچنین ارتقا به vip دریافت می شود."
    },
    {
      q: "چطور می توان زمان عقد قرارداد را تنظیم کنم؟",
      a: "با ورود به پنل کاربری روی  قسمت درخواست قرارداد کلیک کنید. سپس اطلاعات مربوطه را وارد کنید تا پشتیبان های هومینجا با شما تماس بگیرند."
    },
    {
      q: "آیا قرارداد همراه با کد رهگیری است؟",
      a: "بله قراردادهای هومینجا بصورت رسمی و با قابلیت درج کد رهگیری سامانه املاک و معاملات کشورمی باشد."
    },
    {
      q: "آیا قراردادها را می توان به صورت آنلاین تنظیم کرد؟",
      a: "هومینجا در زمینه تکنولوژی پیشرو می باشد. به محض دسترسی شرکت وب گستران سورین به API سامانه ثنا قوه قضایی امکان بستن قرارداد الکترونیکی محیا می شود. در این صورت دیگر نیازی به صرف هیچ گونه وقت، انرژی و هزینه ای تا عقد قرارداد نخواهید داشت."
    },
    {
      q: "آیا در هومینجا امکان عقد قرارداد ملکی وجود دارد؟",
      a: "بله شما در هومینجا در کنار مشاوران املاک و کارشناسان حقوقی ما تمامی قراردادهای خود را می توانید تنظیم و عقد بفرمایید."
    },
    {
      q: "آیا در هومینجا می توان اپارتمان خرید و فروش کرد؟",
      a: "بله شما می توانید درخواست / آگهی خود را درج نمایید. همچنین برای عقد قرارداد نیز به دفتر هومینجا مراجعه نمایید."
    },
    {
      q: "کمیسیون (هزینه عقد قرارداد ) در هومینجا چقدر است؟",
      a: [
        <>
          اگر خریدار و فروشنده یا مستاجر و موجر خارج از هومینجا به توافق رسیده باشند ۷۰درصد تخفیف کمیسیون شامل حال آنها می شود.
          <br />
          در مورد بقیه موارد با پشتیبان تماس بگیرید.
        </>
      ]
    },
  ]

  other = [
    {
      q: "چه کسانی می توانند از وب سایت تخصصی املاک هومینجا استفاده کنند؟ ",
      a: "هومینجا برای تمامی ایرانی های فعال در حوزه املاک و کسانی که علاقه مند به معاملات ملکی هستند خدمات ارائه می کند."
    },
    {
      q: "مزایای سامانه هوشمند هومینجا نسبت به دیگر سایت های املاک چیست؟",
      a: "هزینه بسیار ناچیز درج آگهی – خدمات عکاسی حرفه ای از منزل – عقد قرارداد با تخفیف – قابلیت کسب درآمد برای همه افراد جامعه."
    },
    {
      q: "چطور می توان با پشتیبان هومینجا در ارتباط  بود؟",
      a: "چت انلاین – تیکت – واتساپ – تلفن ثابت شرکت – ایمیل."
    },
    {
      q: "صحت فایلینگ چگونه مورد بررسی قرار می گیرد؟ آیا از صحت فایلینگ مطمئن باشم؟",
      a: "تمامی آگهی ها و درخواست ها از سمت پشتیبان بررسی می شود. پس از بررسی صحت اطلاعات شما تایید و یا رد می شود. همچنین از قسمت گزارش می توانید ما را در عرضه کیفیت بالاتر خدمات کمک کنید."
    },
    {
      q: "اگر آگهی من به فروش / اجاره رفت چه کنم؟",
      a: "وارد پروفایل خود شوید و آگهی را حذف کنید یا به پشتیبان هومینجا اطلاع دهید."
    },
    {
      q: "آیا می توان از میزان بازخورد آگهی در سایت مطلع شد؟",
      a: "بله تعداد بازدید هر آگهی در پروفایل شما و در صفحه آگهی موردنظر نمایش داده می شود."
    },
    {
      q: "آیا بعد از شارژ پنل و عدم استفاده از سرویس می توان درخواست عودت وجه کرد؟",
      a: "موجودی که کاربر شخصا شارژ کرده قابل برداشت است. اما اعتبارات فقط برای استفاده در سایت هومینجا جهت حداقل کردن هزینه پرداختی توسط کاربر می باشد."
    },
    {
      q: "آیا می توان همزمان دو پنل در هومینجا داشت؟",
      a: "هر شماره همراه تنها می تواند یک پنل کاربری داشته باشد."
    },
    {
      q: "شرکت حقوقی حامی وب سایت تخصصی املاک هومینجا کیست؟",
      a: [
        <Typography sx={{ fontFamily: "shabnam" }}> <a className="zone_text_link" href="https://sorinapp.com" target="_blank">وب گستران سورین </a>به شماره ثبت 521747 حامی مادی و معنوی سایت املاک و مستغلات هومینجا می باشد.</Typography>
      ]
    },

  ]


  createAccordion = (questionAndAnswer) => {
    let result = [];
    questionAndAnswer.forEach((element, index) => {
      result.push(
        <Accordion sx={{ borderRadius: "8px", '&:before': { display: 'none' }, margin: "2px 0", boxShadow: "none" }} key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", fontSize: "16px" }} variant="h2">
              {element.q}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "shabnam" }}>
              {element.a}
            </Typography>
            {element.c ? element.c : null}
          </AccordionDetails>
        </Accordion>
      )
    });
    return result;
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, width: "100%", bgcolor: "#E7F0F3", padding: "4% 0" }}>
        <Grid sx={{ margin: { xs: "0 5%", md: '0 10%' } }}>
          <Grid sx={{ paddingBottom: "2%", display: "flex", alignItems: "center" }}>
            <HelpIcon sx={{ fontSize: { xs: "30px", md: "40px" } }} />
            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold", display: "inline-flex", marginRight: "10px" }}>
              سوالات متداول
            </Typography>
          </Grid>
          <Grid sx={{ bgcolor: "white", borderRadius: "8px", margin: "8px 0", padding: "10px 0" }}>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>
              تذکر
            </Typography>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "20px", fontWeight: "normal", textAlign: "center" }}>
              منظور از سامانه معاملات املاک هومینجا، پلتفرم درج و جستجوی اگهی ملک هومینجا، وب سایت املاک و مستغلات هومینجا و ... تماما homeinja.com  می باشد
            </Typography>
          </Grid>
          <Grid sx={{ bgcolor: "white", borderRadius: "8px", margin: "8px 0", padding: "10px 0" }}>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "25px", fontWeight: "bold", textAlign: "center" }}>
              ثبت نام
            </Typography>
          </Grid>
          {this.createAccordion(this.register)}
          <Grid sx={{ bgcolor: "white", borderRadius: "8px", margin: "8px 0", padding: "10px 0" }}>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "25px", fontWeight: "bold", textAlign: "center" }}>
              انواع کاربری
            </Typography>
          </Grid>
          {this.createAccordion(this.userType)}
          <Grid sx={{ bgcolor: "white", borderRadius: "8px", margin: "8px 0", padding: "10px 0" }}>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "25px", fontWeight: "bold", textAlign: "center" }}>
              شرایط و هزینه خدمات آگهی
            </Typography>
          </Grid>
          {this.createAccordion(this.conditionAndExpence)}
          <Grid sx={{ bgcolor: "white", borderRadius: "8px", margin: "8px 0", padding: "10px 0" }}>
            <Typography sx={{ fontFamily: "shabnam", fontSize: "25px", fontWeight: "bold", textAlign: "center" }}>
              سایر
            </Typography>
          </Grid>
          <Grid sx={{ display: "none" }}>
            <Typography>
              ما در این صفحه به پرسش و پاسخ وب سایت املاک و مستغلات | هومینجا می پردازیم.
            </Typography>
            <Typography>
              در این سایت به خرید - فروش - رهن - اجاره - ملک - مسکن - آپارتمان - خانه - برج - اداری - پنت هاوس - هومینجا می پردازیم.
            </Typography>
          </Grid>
          {this.createAccordion(this.other)}
        </Grid>
      </Grid>
    )
  };
}

export default FAQ;