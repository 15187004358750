import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import photo01 from "../../images/mag/article69/01.jpg";
import photo02 from "../../images/mag/article69/02.jpg";
import photo03 from "../../images/mag/article69/03.jpg";
import photo04 from "../../images/mag/article69/04.jpg";
import photo05 from "../../images/mag/article69/05.jpg";
import "../../styles/article.css";
import {
  ArticleAd1,
  ArticleAd2,
  ArticleAd3,
  ArticleAd4,
} from "../mag/articleAd";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import { article as articlesData } from "../mag/articles";
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article69 extends React.Component {
  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    };
  }

  componentDidMount() {
    document.getElementsByTagName("meta")["description"].content =
      "رژیم فستینگ یا روزه داری روشی برای کاهش وزن می باشد که در آن فرد چرخه ای از دو دوره روزه داری و غذاخوری را طی می کند.";
    document.getElementsByTagName("meta")["keywords"].content =
      "لاغری با رژیم فستینگ | نمونه رژیم فستینگ | در رژیم فستینگ چی بخوریم و چی نخوریم | انواع رژیم فستینگ | انواع رژیم روزه داری | رژیم روزه د اری متناوب | همه چیز در مورد رژیم فستینگ ";
    document.getElementsByTagName("meta")["author"].content =
      "شرکت وب گستران سورین";
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName("meta")["description"].content =
      "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML =
      articlesData[68].h1 + " | هومینجا ";
  };

  setTitle = () => {
    document.title = articlesData[68].title + " | هومینجا ";
  };

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  };

  openAuth = (type) => {
    switch (type) {
      case "L":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      case "R":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  };

  onCloseAuth = () => {
    this.setState({ openAuthModal: false });
  };

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  };


  render() {
    return (
      <Grid
        sx={{
          marginTop: { xs: "61px", md: "88px" },
          bgcolor: "#E7F0F3",
          padding: { xs: "5px", md: "15px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" }
        }}
      >
        <SideBar access={articlesData[68].access} />
        <Grid container sx={{ maxWidth: "1610px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              sx={{
                bgcolor: "white",
                borderRadius: "8px",
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                padding: { xs: "5px", md: "30px" },
              }}
            >
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[68].h1}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px 0 20px",
                }}
              >
                <Typography className="date_author">
                  نویسنده : {articlesData[68].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[68].date)}
                </Typography>
              </Grid>
              <Typography
                component={"img"}
                src={photo01}
                alt="رژیم فستینگ - هومینجا  "
                className="image"
              />
              <Typography
                className="h2"
                variant="h2"
                style={{ display: "none" }}
              >
                {articlesData[68].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ممکن است شما هم کلمه فستینگ یا رژیم فستینگ را شنیده باشید، اما
                انواع آن را نشناسید یا از تاثیرات این رژیم بر بدن خبر نداشته
                باشید. ما در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هوم مگ </Link> در سایت <Link className="link" to="/" target="_blank">  هومینجا </Link> سعی داریم شما
                را با رژیم فستینگ، انواع آن، مزایا و معایب آن، خوراکی های مفید و
                غیر مفید برای این رژیم آشنا کنیم و در انتها به معرفی یکی از
                بهترین <LinkMui className="link" href="https://instagram.com/nilla__fit?igshid=MzRlODBiNWFlZA==" target="_blank"> مربیان </LinkMui> بدنسازی و تناسب اندام بپردازیم.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                فلسفه رژیم فستینگ این است که طبق ساعت و زمان مشخص به بدن غذا
                نرسانیم تا بدن برای تامین انرژی از چربی انباشته شده استفاده کند.
                کسانی که دچار اضافه وزن هستند، کلمه فستینگ احتمالا زیاد به
                گوششان خورده است. پس اگر شما هم جز این افراد هستید و قصد <Link className="link" to="/weight-loss-and-fitness" target="_blank">  کاهش وزن </Link> با گرفتن رژیم فستینگ را دارید ولی نمی دانید باید از کجا شروع
                کنید تا اخر این مقاله با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                رژیم فستینگ چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo02}
                  alt="رژیم فستینگ - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  فستینگ یا رژیم روزه داری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ یکی از سریع ترین و معروف ترین رژیم های حال حاضر
                دنیاست. این رژیم تنها رژیمی است که شما در آن دچار استپ وزنی
                نخواهید شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در رژیم فستینگ بر خلاف سایر رژیم های لاغری که بیشتر بر روی نوع و
                مقدار مواد غذایی مصرفی دخالت دارند، این رژیم بیشتر بر روی زمان
                مصرف غذا تمرکز دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در رژیم فستینگ شما طبق ساعت معینی می توانید غذا میل کنید.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/What-Is-Cardio-Exercises"
                  target="_blank"
                >
                  بیشتر بخوانید : تمرینات کاردیو برای کاهش وزن و چربی سوزی{" "}
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                رژیم فستینگ چگونه باعث لاغری می شود؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر جز افرادی هستید که می خواهید این رژیم را بگیرید احتمالا به
                خاطر کاهش وزن سریع ان می باشد. زمانی که شما در ساعاتی از روز غذا
                مصرف نکنید، کالری دریافتی بدن شما کاهش می یابد. رژیم فستینگ باعث
                تغییر در سطح برخی از هورمون ها می شود، یعنی سطح هورمون رشد
                انسانی را افزایش و سطح انسولین را کاهش می دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این تغییرات هورمونی برای بدن مفید است و باعث چربی سوزی می شود.
                با این تفاسیر می توان گفت رژیم فستینگ باعث افزایش متابولیسم می
                شود.
              </Typography>

              <Typography className="h2" variant="h2">
                انواع رژیم فستینگ چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ دارای انواع مختلفی است که ما در این مقاله به انواع
                ان اشاره خواهیم کرد. توجه داشته باشید برای گرفتن رژیم فستینگ
                حتما به متخصص تغذیه مراجعه نمایید. پزشک با توجه به سیستم بدن هر
                فرد، بهترین نوع این رژیم را پیشنهاد می دهد.
              </Typography>
              <Typography className="h3" variant="h3">
                1- رژیم فستینگ 8/16 :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                انجام دادن این نوع رژیم به این صورت است که شما 24 ساعت از شبانه
                روز را به دو قسمت 16 ساعت و 8 ساعت تقسیم می کنید. در 16 ساعت شما
                چیزی نمی خورید و در 8 ساعت شما می توانید وعده های غذایی خود را
                میل کنید. البته در 16 ساعتی که شما اجازه مصرف غذا را ندارید، می
                توانید از مایعات بدون کالری مثل آب، چای و قهوه بدون شکر استفاده
                نمایید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                مثلا اگر شما از ساعت 9 شب این رژیم را شروع کردید تا ساعت 1
                بعدازظهر فردا چیزی جز مایعات بدون کالری نباید میل کنید. بعد از
                آن در 8 ساعت با فاصله منظم می توانید نهار، عصرانه و شامتان را
                میل کنید. شاید در نگاه اول این رژیم به نظرتان سخت بیاید ولی این
                را در نظر داشته باشید در بیشترین تایمی که شما نباید کالری مصرف
                کنید در ساعت شب و موقع خوابتان است در نتیجه احساس گرسنگی نخواهید
                داشت.
              </Typography>
              <Typography className="h3" variant="h3">
                2- رژیم فستینگ 5/2 :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یکی دیگر از انواع رژیم فستینگ نوع 5/2 ان است. در این نوع شما
                باید 2 روز در هفته را رژیم بگیرید. شما در این دو روز فقط باید
                500 تا 600 کالری دریافت کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                خانم ها: 500 کالری دریافتی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                آقایان: 600 کالری دریافتی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                توجه داشته باشید دو روزی که شما قصد انجام این رژیم را دارید
                نباید پشت سرهم باشد. مثلا اگر شنبه می خواهید رژیم را انجام دهید
                روز بعدی برای رژیم را سه شنبه در نظر بگیرید.
              </Typography>
              <Typography className="h3" variant="h3">
                رژیم فستینگ 12 ساعته :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ 12 ساعته جز ساده ترین نوع این رژیم است. معمولا
                پزشکان متخصص برای کسانی این نوع را تجویز می کنند که نخوردن غذا
                به مدت طولانی برایشان سخت است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این نوع رژیم فرد 12 ساعت از روز را چیزی نمی خورد و 12 ساعت
                دیگر را وعده های غذایی خود را میل می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای این نوع رژیم توصیه می شود ممنوعیت غذا را از 8 شب شروع کنید
                زیرا بیشترین تایم رژیم در طول شبانه روز و مدت خواب شما اتفاق می
                افتد.
              </Typography>
              <Typography className="h3" variant="h3">
                رژیم فستینگ 24 ساعته :
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo03}
                  alt="فستینگ 24 ساعته - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  فستینگ 24 ساعته
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ 24 ساعته که به ( خوردن - نخوردن - خوردن ) هم معروف
                است از نوع بسیار سخت فستینگ است. این رژیم برای فعالیت های روزانه
                شما ممکن است مشکلات زیادی مانند سردرد، بی حالی شدید، حالت تهوع
                را به همراه داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این نوع رژیم شما در 24 ساعت فقط مجاز به مصرف مایعات بدون
                کالری هستید.
              </Typography>
              <Typography className="h3" variant="h3">
                رژیم فستینگ یک روز در میان :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یکی دیگر از انواع رژیم فستینگ، نوع یک روز در میان آن است. در این
                رژیم فرد باید فقط 500 کالری مصرفی داشته باشد. در روزهای تغذیه هم
                انتخاب نوع و میزان غذا با خود فرد است.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                به یاد داشته باشید کسانی که شرایط پزشکی خاصی دارند حتما قبل از
                انجام این نوع از رژیم فستینگ با پزشک خود مشورت کنند.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/weight-loss-and-fitness"
                  target="_blank"
                >
                  بیشتر بخوانید : کاهش وزن اصولی و ترفندهای رسیدن به تناسب اندام
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                رژیم فستینگ جنگجو :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ جنگجو جز سخت ترین نوع این رژیم است. در این نوع رژیم
                میزان کالری مصرفی فرد به مقدار پایین است. این رژیم اینگونه است
                که فرد فقط چند وعده میوه و سبزیجات خام طی 20 ساعت می تواند مصرف
                کند. زمان غذا خوردن در این رژیم فقط 4 ساعت است. در این 4 ساعت
                فرد باید به اندازه ی کافی از پروتئین، کربوهیدرات و چربی های سالم
                مصرف کند.
              </Typography>
              <Typography className="h2" variant="h2">
                کدام نوع از رژیم فستیینگ برای کاهش وزن مناسب تر است؟
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo04}
                  alt="رژیم 16 به 8 - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">رژیم 16 به 8</figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برنامه رژیم فستینگ 16 به 8 یکی از رایج ترین سبک های این رژیم است
                که فرد به مدت 16 ساعت کالری مصرف نمی کند. اما نوع 5 به 2 این
                رژیم نیز بر اساس تجزیه و تحلیل اخیر انجام شده در کنار رژیم 16 به
                8 ایستاده است. در کل تشخیص پزشک است که به شما می گوید کدام نوع
                آن برای شما مناسب تر است. شما برای گرفتن بهترین نتیجه در این
                رژیم باید از مواد سالمی استفاده کنید که از پروتئین بالایی
                برخوردارند.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                از میوه و سبزیجات زیادی در رژیم خود استفاده کنید.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                سیب، موز، پرتقال، انبه، هلو میوه هایی هستند که بسیار برای بدن
                شما مفید است و بسیاری از ویتامین های بدن شما را تامین می کند.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                چربی های سالم مثل روغن کنجد یا زیتون مصرف کنید.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                از کینوا، برنج قهوه ای، جو دو سر، نان جو در رژیم خود استفاده
                کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                فواید رژیم فستینگ چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم هایی مثل رژیم فستینگ که شما باید کالری کمی در روز به بدن
                خود برسانید، سبب تغییرات فیزیکی بدن، کم شدن کالری و در نتیجه
                کاهش وزن بدن می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ روی باکتری های مفید روده اثر مثبت می گذارد و باعث
                افزایش هورمون ادیپونکتین ( موارد کلیدی چربی سوزی ) می شود. این
                رژیم سبب پیشرفت بدن در آب کردن چربی ها خواهد شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ بیماری هایی نظیر فشار خون و سطح بالای کلسترول را
                کنترل می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                باعث افزایش کالری سوزی بدن می شود. وقتی شما کالری مصرفیتان کم می
                شود بدن برای سوخت و ساز سراغ چربی های انباشته شده می رود و در
                نتیجه چربی سوزی شروع می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                زمانی که از خواب بیدار می شوید و اولین وعده غذاییتان را می خورید
                بدنتان شروع به تولید انسولین می کند. انسولین هورمونی برای ذخیره
                سازی چربی است. با گرفتن رژیم فستینگ می توانید این مرحله را برای
                چند ساعت نگه دارید، بنابراین بدنتان چربی سوزی بیشتری خواهد داشت.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                رژیم فستینگ می تواند باعث کاهش استرس و افزایش تمرکز شما شود.
              </Typography>
              <Typography className="h2" variant="h2">
                آیا می توانیم در دوره رژیم فستینگ ورزش کنیم؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سوال اینجاست که ایا با توجه به کالری دریافتی کم، نیاز به فعالیت
                ورزشی هست یا خیر؟! باید بگوییم بله! افرادی که دچار اضافه وزن
                هستند وقتی با رژیم می خواهند وزن خود را پایین بیاورند، آب شدن
                چربی های انباشته بدنشان باعث شل شدن و افتادگی پوستشان می شود.
                شما با ورزش کردن اصولی و صحیح در دوره رژیم خود می توانید جلوی
                این اتفاق را بگیرید. یکی از ورزش هایی که برای کاهش وزن همراه با
                این رژیم پیشنهاد می شود تمرینات کاردیو می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ما می توانیم در این راه به شما کمک کنیم تا به صورت اصولی و بدون
                اسیب به تناسب اندام برسید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                جهت ارتباط با ما پیج اینستاگرام <LinkMui className="link" href="https://www.instagram.com/p/CrnVaUFLe3M/?igshid=MzRlODBiNWFlZA==" target="_blank"> نیلافیت </LinkMui> را دنبال کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                مواد غذایی که باید در رژیم فستینگ مصرف کنیم چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo05}
                  alt="غذاهای مجاز رژیم فستینگ - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  غذاهای مجاز رژیم فستینگ
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در رژیم فستینگ لازم است ساعاتی که شما مجاز به صرف غذا هستید،
                غذاهای سالم و مناسبی مصرف کنید تا این رژیم برای شما نتیجه مطلوبی
                به همراه داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از غذاهای مجاز در این رژیم می توان به موارد زیر اشاره کرد :
              </Typography>
              <Typography className="h3" variant="h3">
                غذاهای دریایی:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                غذاهای دریایی مانند انواع ماهی ها، میگو که حاوی مقادیر زیادی
                پروتئین و چربی مفید است. شما زمانی که مجاز به غذا خوردن هستید
                باید غذاهایی مصرف کنید که سرشار از پروتئین است. پروتئین به شما
                کمک می کند تا دیرتر احساس گرسنگی کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                انواع میوه:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                شما می توانید در رژیم فستیگ از انواع توت، اواکادو، گلابی و
                هندوانه استفاده نمایید. میوه ها، ویتامین بدن را تامین میکنند و
                باعث شادابی پوست می گردند.
              </Typography>
              <Typography className="h3" variant="h3">
                سبزیجات:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                استفاده از سبزیجاتی مانند انواع کلم، اسفناج، جعفری برای شما
                ضروری است زیرا سبزیجات دارای فیبر هستند. فیبر هم مانند پروتئین
                به شما کمک می کند تا دیرتر احساس گرسنگی نمایید.
              </Typography>
              <Typography className="h3" variant="h3">
                غذاهایی با حجم بالا بخورید:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                غذاهای سیر کننده وکم کالری مانند ذرت بو داده، انواع سبزیجات خام
                را حتما در رژیم خود جای دهید.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/What-Is-Functional-Training"
                  target="_blank"
                >
                  بیشتر بخوانید : فانکشنال ترینینگ و بررسی کامل آن
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                غذاهای غیر مجاز در رژیم فستینگ:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این رژیم هم مانند تمامی رژیم های لاغری، ممنوعیت هایی دارد. برای
                گرفتن نتیجه بهتر باید خوردن برخی از غذاها را کنار بگذارید. با
                این کار زودتر به هدفتان می رسید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از غذاهای غیر مجاز در این رژیم می توان موارد زیر را نام برد:
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                انواع <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">  فست فود </Link>ها: غذاهای اماده و فست فودها پر از چربی های مضر و
                شکر مخفی می باشند که روند لاغری شما را به تعویق می اندازند.
                همچنین اگر بدن سالمی می خواهید باید دور فست فود را خط بکشید.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                چربی های اشباع و ترانس: چربی های ترانس و اشباع شده از نوع بد و
                مضر اسیدهای چرب هستند که مشکلاتی مانند مشکلات قلبی، تاثیر
                نامطلوب بر میزان انسولین، مشکلات التهابی و ... را بهمراه دارند.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                در این رژیم استفاده از اسنک هایی مانند چیپس، پفک، تخمه و ...
                ممنوع می باشد.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                مواد قندی مانند انواع شیرینی ها، شکلات، نوشابه و ... نیز در دسته
                غذاهای غیر مجاز قرار می گیرد.
              </Typography>
              <Typography className="h2" variant="h2">
                نکاتی درباره رژیم فستینگ:
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                در دوره فستینگ مواد غذایی مانند شیرینی، نان سفید، غذاهای چرب و
                پخته شده در روغن ناسالم باید محدود گردد.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                برای کاهش احساس گرسنگی در دوره فستینگ می توانید از روش هایی
                مانند آب لیمو، چای سبز، قهوه بدون شکر استفاده کنید.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                برای حفظ انرژی و قدرت بدن در دوره فستینگ باید از مواد غذایی مغذی
                و سالم مانند مرغ، ماهی و سبزیجات استفاده کنید.
              </Typography>
              <Typography
                className="p"
                component="li"
                sx={{ textAlign: "justify" }}
              >
                فستینگ برای افرادی مانند کودکان، زنان باردار، افراد مبتلا به
                بیماری های دیابت و افرادی که نیاز به مصرف داروهای خاص دارند
                پیشنهاد نمی شود.
              </Typography>
              <Typography className="h2" variant="h2">
                سخن پایانی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                در این مقاله سعی کردیم که رژیم <Link className="link" to="/Fasting-Diet" target="_blank"> فستینگ </Link> را توضیح دهیم و به بررسی
                نکاتی که باید در این دوره رعایت شود بپردازیم. توصیه می کنیم اگر
                دچار اضافه وزن هستید زیر نظر پزشک متخصص این رژیم را بگیرید.
                متاسفانه برخی از افراد به طور خودسر این رژیم را می گیرند و از
                عوارض آن بی خبرند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همچنین همانطور که اشاره کردیم این رژیم دارای انواع مختلفی می
                باشد که پزشک با توجه به وضعیت بدنی هر فرد نوع آن را مشخص می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                هم چنین با دنبال کردن پیچ ورزشی nilla__fit در <LinkMui className="link" href="https://sorinapp.com/Academy/what-is-application" target="_blank"> اپلیکیشن </LinkMui>
                اینستاگرام از اخرین مطالب آموزشی بهره مند شوید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#4B4B4B",
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                >
                  برچسب ها :
                </Typography>
                <Button
                  component={Link}
                  to={"/HomeMag"}
                  onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{
                    color: "#4B4B4B",
                    bgcolor: "#E7F0F3",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#E7F0F3" },
                  }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography
                  sx={{
                    fontFamily: "shabnam",
                    color: "#C5CCCE",
                    textAlign: "center",
                  }}
                >
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui
                    href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <WhatsAppIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TwitterIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TelegramIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem("infoUserHomeInja") ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سپردن ملک
                      </Typography>
                      <AddBusinessRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سفارش ملک
                      </Typography>
                      <HouseRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("L")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ورود
                      </Typography>
                      <PersonRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("R")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ثبت نام
                      </Typography>
                      <PersonAddAlt1RoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal
          open={this.state.openAuthModal}
          close={() => this.onCloseAuth()}
          type={this.state.modalType}
        />
      </Grid>
    );
  }
}

export default Article69;
