import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article64/01.jpg";
import photo02 from "../../images/mag/article64/02.jpg";
import photo03 from "../../images/mag/article64/03.jpg";
import photo04 from "../../images/mag/article64/04.jpg";
import photo05 from "../../images/mag/article64/05.jpg";
import photo06 from "../../images/mag/article64/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article64 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "بازسازی خانه یا نوسازی منزل به فرآیند بهبود ساختار کهنه یا آسیب دیده ساختمان می گویند که معمولا در ساختمان های تجاری و مسکونی صورت می گیرد. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "بازسازی یا نوسازی خانه | صفر تا صد بازسازی خانه | نکات مهم بازسازی خانه | همه چیز در مورد بازسازی ملک | مراحل بازسازی خانه مسکونی | بازسازی خانه قدیمی با کمترین هزینه | اهمیت بازسازی خانه | تخریب و بازسازی آپارتمان "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[63].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[63].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "62px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[63].access} />
        <Grid container sx={{ maxWidth: "1620px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[63].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[63].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[63].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="بازسازی خانه - هومینجا  " className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[63].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به هر اقدامی که به <b> نوسازی </b> قسمت‌های آسیب دیده خانه منجر شود و یا تغییرات و تعمیرات ساختار یک سازه و ساختمان را انجام دهد، بازسازی خانه می گویند. بازسازی گاهی می تواند به سبکی تعویض شیرآلات و یا یک رنگ ساده باشد و در برخی مواقع به تخریب و بازسازی کامل ملک منجر شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بازسازی خانه یکسری اصول دارد و طبق آن باید این مراحل انجام شود. کسی که اقدام به بازسازی می کند باید دانش عملی و حرفه ای داشته باشد. ما در این مقاله از <Link className="link" to="/" target="_blank">  هومینجا </Link> به بررسی تمامی نکات و اصول لازم جهت بازسازی منزل می پردازیم. پس با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="نوسازی خانه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نوسازی خانه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا زیر ساخت خانه پس از چندین سال، فرسوده می شوند و باید تعمیر شوند. همچنین گاهی نیز این اتفاق می افتد که شما بعد از چندین سال سکونت نیاز به تنوع و تغییر مکان داشته باشید. اما به دلیل قیمت بالای آپارتمان‌های <Link className="link" to="/Listing/1330" target="_blank">  نوساز </Link> خیلی از افراد ترجیح می دهند برای ایجاد تغییرات و بهبود منزل، خانه را بازسازی کنند. هنگامی که دوباره سازی به گونه ای انجام شود که اسکلت ساختمان تغییری نکند اما این تغییرات سبب افزایش عمر سازه شود، به این عمل مرمت ساختمان یا بازسازی (Renovation) می گویند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Partnering-Construction-In-1402" target="_blank">
                  بیشتر بخوانید : قوانین و شرایط مشارکت در ساخت
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مراحل بازسازی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمانی که تصمیم به بازسازی گرفته می شود باید مراحلی که در بازسازی بکار می رود رعایت شود تا بتوان نتیجه مطلوبی از بازسازی گرفت. ما در ادامه این اصول را بیان می کنیم چنانچه با این مراحل آشنایی ندارید با ما همراه باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                تعیین بخش هایی که نیاز به بازسازی دارند
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در بازسازی منزل ابتدا باید یک کلیتی از طراحی جدید منزل در نظر گرفته شود. سپس تمام کارها و اقداماتی که  نیاز دارید صورت پذیرد را لیست کنید. بهتر است تمامی این موارد با در نظر گرفتن متراژ و فضای خانه و استاندارد های طراحی انجام گیرد. همچنین تمامی قسمت های خانه که نیاز به تعمیر دارند باید مشخص شود.
              </Typography>
              <Typography className="h3" variant="h3">
                تهیه چک لیست بازسازی خانه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="بازسازی سرویس بهداشتی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بازسازی سرویس بهداشتی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در بازسازی بهتر است لیستی از قسمت هایی که نیاز به تعویض و مرمت دارند را داشته باشید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در و پنجره‌های دوجداره و ضد سرقت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نقاشی و کاغذ دیواری دیوارها و سقف
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بررسی چاه فاضلاب و تعویض لوله‌کشی‌های مربوط به آب
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعویض شیرآلات سرویس بهداشتی، حمام، آشپزخانه و <Link className="link" to="/laundry-room" target="_blank">  رختشویخانه </Link>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                انتخاب روشویی و توالت فرنگی و ایرانی متناسب با فضا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بکارگیری قطعات ضروری آشپزخانه مانند هود، گاز، سینک ظرفشویی، کابینت‌ها و فر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نصب کفپوش مناسب
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعیین <Link className="link" to="/chandelier-in-decoration" target="_blank">  نورپردازی </Link> متناسب با طراحی جدید فضا
              </Typography>
              <Typography className="h3" variant="h3">
                اختصاص بودجه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تمام مواردی که در بازسازی قصد انجام آن را دارید یک بودجه و زمانی را مشخص نمایید. با توجه به اقداماتی که برای بازسازی و نوسازی خانه صورت می پذیرد باید یک برآورد هزینه انجام گیرد. مدیریت هزینه ها باید به گونه ای انجام شود که هزینه بازسازی خانه از خرید خانه نوساز بیشتر نشود.
              </Typography>
              <Typography className="h3" variant="h3">
                مشاوره از شرکت بازسازی خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای اینکه کارهای مربوط به بازسازی بدون مشکل انجام پذیرد بهتر است که از افراد متخصص در این زمینه مشورت بگیرید. امروزه شرکت های بسیاری در این زمین فعالیت دارند که صفر تا صد کار را به عهده می گیرند و کار بازسازی ملک را به بهترین شکل انجام می دهند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از دلایلی که بهتر است از تجربه مشاوران استفاده کرد این است شرکت‌های بازسازی متعهد می شوند و کارهای مربوط به بازسازی خانه را پیگیری می‌کنند و تجهیزات مورد نیاز را در کمترین زمان فراهم می‌کنند بنابراین زمان بازسازی ملک کاهش می یابد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/1402-House-Loan" target="_blank">
                  بیشتر بخوانید : شرایط در یافت وام بازسازی مسکن
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین امکان دارد یکسری از تغییرات نیاز به مجوز قانونی داشته باشند و چون شما از آن آگاهی ندارید مشمول جریمه شوید. شرکت های بازسازی چون به تمامی قانون و قواعد آشنایی دارند بنابراین شما را از آن مطلع می سازند.
              </Typography>
              <Typography className="h4" variant="h4">
                برای بازسازی خانه چه باید کرد؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در زیر مراحلی که برای بازسازی خانه باید به ان توجه کنیم را بطور خلاصه بیان کردیم:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بررسی قسمت هایی که قصد بازسازی دارید  و تعیین الویت ها
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعیین بودجه بازسازی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                استخدام مشاوران بازسازی خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دریافت مجوزهای مورد نیاز بازسازی خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شروع عملیات تخریب ساختمان قدیمی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بازسازی لوله ها و تاسیسات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تعویض کابینت های کهنه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                انجام کاغذ دیواری و نقاشی دیوار و سقف
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بازسازی کف خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بازسازی و نظافت <Link className="link" to="/building-facades-types" target="_blank">  نما </Link>
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از تعیین اولویت ها و برنامه‌ی اجرایی پروژه برای بخش های مختلف، گزینه های منتخب برای هر فضا را بر روی <Link className="link" to="/Interior-architecture-and-interior-design" target="_blank">  پلان </Link> ساختمان علامت گذاری می کنند. بدین ترتیب کار بازسازی خانه آغاز می شود. در بازسازی ابتدا زیرساخت‌های خانه یعنی لوله کشی ساختمان یا سیستم گرمایشی و سرمایشی تعمیر می‌ شود. بعد از آن قسمت‌هایی که آسیب دیده اند نظیر لوله کشی‌ها، برق کشی ها و مقاوم سازی ساختمان مورد مرمت قرار می گیرند. در مرحله آخر تغییرات ظاهری یا تغییر <Link className="link" to="/small-house-decoration" target="_blank">  دکوراسیون </Link> انجام می‌پذیرد.
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت بازسازی و نوسازی خانه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="بازسازی و نوسازی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تفاوت بازسازی و نوسازی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید خیلی از افراد نوسازی و بازسازی را یکی بدانند اما در اصل این دو واژه با هم متفاوت هستند. نوسازی بیشتر به ظاهر خانه توجه دارد. تعویض کف‌پوش، کاغذ دیواری یا نقاشی دیوارها، عوض کردن شیرآلات خانه و مواردی از این قبیل شامل نوسازی ساختمان می شود. اما بازسازی را می توان دوباره سازی خانه دانست. بازسازی خانه انواع متفاوتی دارد و مواردی مانند تغییر در تأسیسات خانه، تغییر فضاهای داخلی، رفع فرسودگی خانه را شامل می شود. به طور کلی می توان گفت که هر آنچه در خانه نیاز به تعمیر جدی داشته باشد بازسازی می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                فواید بازسازی خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاهش هزینه ها با صرفه جویی در مصرف انرژی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سرمایه گذاری مطمئن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بهینه تر شدن فضا با تغییر ابعاد خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اثر مطلوب بر روحیه افراد خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هزینه‌ی کمتر نسبت به خرید و تعویض خانه جدیدتر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                طراحی خانه به سلیقه ی دلخواه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ایجاد تنوع در محیط خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدرن‌سازی قسمت های مختلف خانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مقاوم سازی بنا و ایده گرفتن از طراحی‌های جدید
              </Typography>
              <Typography className="h2" variant="h2">
                هزینه بازسازی خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجایی که معیار های متفاوتی در بازسازی ملک مورد نظر است و از متریال های متفاوتی نیز استفاده می شود، بنابراین نمی توان قیمت واحدی را برای بازسازی خانه تعیین کرد. اما بطور کلی می توان هزینه بازسازی ملک را بر پنج مبنا قرار داد. هزینه خرید مصالح، هزینه حمل و نقل زباله‌های ایجاد شده در فرایند بازسازی، هزینه تعمیرات، هزینه تغییرات مربوط به دکوراسیون، هزینه پیمانکاران و مجریان.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/1402-Tehran-Construction-Cost" target="_blank">
                  بیشتر بخوانید : هزینه ساخت ساختمان در تهران سال ۱۴۰۲
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                زمان لازم جهت بازسازی خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                عوامل مختلفی در مدت زمان لازم جهت بازسازی ملک نقش اساسی دارند. بعنوان مثال، متراژ خانه یا وسعت فضایی که نیاز به بازسازی دارد در زمان بازسازی  نقش بسزایی دارند. همچنین بودجه شما و موقعیت جغرافیایی ملک نیز از دیگر عوامل تاثیرگذار در مدت زمان بازسازی می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                بازسازی قسمت های مختلف خانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی قسمت های منزل را می توان بازسازی کرد که هر کدام اصول خاص خود را دارند. در این بخش به بررسی بازسازی هر بخش از ساختمان می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                بازسازی آشپزخانه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="بازسازی آشپزخانه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بازسازی آشپزخانه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدلیل استفاده مداوم روزانه از آشپزخانه این بخش، یکی از قسمت های اصلی ساختمان برای افراد خانه مخصوصا برای خانم خانه دار می باشد. همچنین می توان گفت این بخش زودتر از بقیه قسمت های خانه دچار فرسایش می شود. بنابراین نیاز به تغییرات دارد و هزینه ی بیشتری نیز برای بازسازی شامل آن می شود. مواردی که در تعمیر و تغییرات آشپزخانه می توان به آن توجه کرد شامل نصب کابینت‌های جدید، تعویض سینک ظرفشویی و هود، نصب گاز، کف‌سازی و تعویض لوله ها می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                البته اگر از لحاظ هزینه ای محدودیتی نباشد می توان موارد بیشتری را در بازسازی آشپزخانه لحاظ کرد مانند سینک گرانیتی، فر توکار، گاز صفحه ای و موارد دیگر.
              </Typography>
              <Typography className="h3" variant="h3">
                بازسازی سرویس‌ بهداشتی و حمام
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بازسازی سرویس بهداشتی و حمام جدا از تاثیری که در زیبا سازی این بخش دارد در بهداشت و نظافت آن نیز موثر می باشد. همچنین تعویض لوله ها و تأسیسات و بهره‌گیری از مصالح مرغوب و استاندارد مشکلات ناشی از نشتی آب  و رطوبت را نیز از بین می برد. بهتر است در تعویض روشویی و شیر آلات از متریال مرغوب و با کیفیت استفاده شود که دوام بالاتری را به همراه داشته باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Housing-Market-Prediction-1402" target="_blank">
                  بیشتر بخوانید : پیش بینی بازار مسکن در سال ۱۴۰۲
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                بازسازی اتاق خواب
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که اتاق خواب مکانی برای استراحت و بازیابی آرامش می باشد بنابراین بازسازی این بخش از خانه نیز از اهمیت بالایی برخوردار است. بازسازی فضای اتاق خواب باید به‌گونه‌ای باشد که فضای کافی برای لباس‌ها، میز آرایش، تخت‌خواب و نصب کمدهای جدید داشته باشد. همچنین رنگی که برای دیوار در نظر گرفته می شود باید مکانی ارام بخش و دلباز را تداعی کند.
              </Typography>
              <Typography className="h3" variant="h3">
                بازسازی سالن پذیرایی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="بازسازی سالن پذیرایی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بازسازی سالن پذیرایی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از انجا که این بخش بیشتر از دیگر قسمت های خانه به چشم می آید باید منظره ای چشم نواز داشته باشد. بازسازی این فضا باید به‌گونه‌ای باشد که نورپردازی خوبی داشته باشد و سالن بزرگتر دیده شود.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی بر این داشتیم مهمترین نکاتی که برای بازسازی خانه ضروری است را ذکر کنیم. با رعایت این اصول می توانید خانه را به سبک دلخواه خود تغییر دهید. امید است این مطالب مفید واقع شود.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article64;