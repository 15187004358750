import { Grid, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import { Link } from "react-router-dom";
import { convertToPersianNumber, separator } from "./materilas/vmp";


class Vip3 extends React.Component {


    createVip = () =>{
        let show = [];
        let vipWithoutArchived = [];
        for(let i = 0 ; i < this.props.vip.length ; i++){
            if(this.props.vip[i].status_id !== 5){
                vipWithoutArchived.push(this.props.vip[i]);
            }
        }
        vipWithoutArchived.forEach((element,index) => {
            show.push(
                <Grid key={index} item md={4} xs={12} sx={{marginBottom:{xs:"20px",md:"0"},padding:"1%"}}>
                    <Button  component={Link} to={"/Listing/" + element.id} target="_blank" sx={{padding:"0",display:"block",minWidth:"100%",minHeight:"100%",color:"black"}}>
                        <Box sx={{height:{xs:"280px",sm:"450px",md:"385px",lg:"500px"},backgroundImage:"linear-gradient(0deg, rgba(1,199,233,0) 0%, rgba(1,199,233,1) 90%)", 
                                borderRadius:{xs:"4px 4px 4px 4px",md:"9px 9px 8px 8px"}, display:"flex",justifyContent:"center",alignContent:"center",flexWrap:"wrap",
                                boxShadow:{xs:"0px 8px 10px rgb(1 167 196 / 12%)",md:"1px 4px 24px rgba(0, 0, 0, 0.12)"},"&:hover":{boxShadow:{xs:"0px 8px 10px rgb(1 167 196 / 12%)",md:"1px 4px 24px rgba(0, 0, 0, 0.3)"}},
                                transition:"0.3s all"
                                }}>
                            <Box sx={{width:{xs:"99%",md:"100%"},height:{xs:"66%",sm:"65%",md:"67%"},backgroundRepeat:"no-repeat", backgroundSize:"cover",backgroundPosition:"center",
                                    backgroundImage:`url(${element.large.length !== 0 ? element.large[0].path : ""})`, borderRadius:{xs:"4px 4px 4px 4px",md:"7px 7px 0 0"},
                                    boxSizing:"border-box"}}
                            ></Box>
                            <Box sx={{height:"33%",width:{xs:"99%",md:"100%"}, bgcolor:"white",display:"flex",justifyContent:"center",alignContent:"center",
                                    flexDirection:"column",textAlign:"center"}}
                            >
                                <HomeinjaTypo variant="h2" component="h2" style={{margin:"10px 0"}}
                                text={element.estate.des +" "+convertToPersianNumber(element.area) +" متری "+element.neighbourhood.name}
                                sx={{fontSize: {lg:'1.7em',md: "1.2em", sm: "1em", xs: "1em" },fontWeight:"700 !important"}}
                                ></HomeinjaTypo>
                                <Grid sx={{fontFamily:"shabnam",typography:{xs:{fontSize:"15px"},md:{ fontSize:"20px"},lg:{ fontSize:"30px"}},fontWeight: "700"}}>
                                {element.show_price ?
                                    element.transaction_id === 1 ?
                                        <>
                                            <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight: "600",display:"inline-block"}}>قیمت:</Typography>
                                            <Typography
                                                sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight:"600",display:"inline-block",marginRight:"5px"}}
                                            >
                                                {   parseInt(element.total_price)   ?
                                                        `${convertToPersianNumber(separator(element.total_price ))} تومان`
                                                    :   "توافقی"
                                                }
                                            </Typography>
                                        </>    
                                    :   element.transaction_id === 2 ?
                                        <>
                                            <Grid>
                                               <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight: "600",display:"inline-block"}}>رهن:</Typography>
                                                {   parseInt(element.mortgage) !== 0 ?
                                                    <HomeinjaTypo  price={parseInt(element.mortgage)}
                                                            sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight:"600",display:"inline-block",marginRight:"5px"}}
                                                        /> 
                                                    :   <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight: "600",display:"inline-block",marginRight:"5px"}}>
                                                            توافقی
                                                        </Typography>
                                                } 
                                            </Grid>
                                            <Grid>
                                               <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight: "600",display:"inline-block"}}>اجاره:</Typography>
                                                {   parseInt(element.rent) !== 0 ?
                                                    <HomeinjaTypo  price={parseInt(element.rent)}
                                                            sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight:"600",display:"inline-block",marginRight:"5px"}}
                                                        /> 
                                                    :   <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight: "600",display:"inline-block",marginRight:"5px"}}>
                                                            توافقی
                                                        </Typography>
                                                } 
                                            </Grid>
                                        </>
                                        :   <>
                                                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight: "600",display:"inline-block"}}>پیش خرید:</Typography>
                                                <Typography
                                                    sx={{fontFamily:"shabnam",fontSize:{xs:"14px",md:"15px",lg:"18px"},fontWeight:"600",display:"inline-block",marginRight:"5px"}}
                                                >
                                                    {   parseInt(element.prebuy)   ?
                                                            `${convertToPersianNumber(separator(element.prebuy ))} تومان`
                                                        :   "توافقی"
                                                    }
                                                </Typography>
                                            </>
                                :   <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"15px",md:"20px",lg:"25px"},fontWeight: "600",display:"inline-block"}}>قیمت : توافقی</Typography>
                                }
                                </Grid>
                            </Box>
                        </Box>
                    </Button>
                </Grid>
            )
        });
        return show;
    }

    render(){
        return(
            <>
                {   this.props.vip ? 
                        <Grid sx={{padding:{xs:"0 4% 6% 4%",md:"0 9% 6% 9%"}}}>
                            <Grid container justifyContent="center" >{this.createVip()}</Grid>
                        </Grid>
                    : <Box></Box> 
                }
            </>
        );
    }
}

export default Vip3;