import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Autocomplete, TextField ,Checkbox, TextareaAutosize} from "@mui/material";
import { icon , checkedIcon  , kitchenEq , kitchenCabinet , cool_heat , safety , welfare , cover, facade  } from "./materilas/vmp";
import { convertToEnglishNumber, convertToPersianNumber, onlyReciveNumber,separator,situation} from "./materilas/vmp";
import React from "react";
import { baseUrl } from "./materilas/vmp";
import axios from "axios";
import AlertModal from './materilas/alert';
import GifLogo from './gifLogo';
import locationIcon from "../images/listing/location.png";
import vip1Image from "../images/listing/vip1.png";
import vip2Image from "../images/listing/vip2.png";
import vip3Image from "../images/listing/vip3.png";
import ladderImage from "../images/listing/ladder.png";
import refreshImage from "../images/listing/refresh.png";
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import {statusListing} from "./materilas/vmp";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import GetLocation from "./materilas/getLocation";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Resizer from "react-image-file-resizer";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link } from "react-router-dom";

class ListingUser extends React.Component{
 
    isMount = false ;
    token = localStorage.getItem('tokenUserHomeInja');
    userInfo = JSON.parse(localStorage.getItem("infoUserHomeInja"));

    constructor(props){
        super(props);
        this.state={
          noAdsPage : true ,
          listingUserPage : false ,
          searchText: "",
          listingUserInfoSearched:[],
          listingUserShow:[],
          listingUserInfo : [] ,
          openAlert:false,
          message:"",
          showGif:"none",
          planButtonDisable: true ,
          editAndDeleteButtonDisable: false ,
          isLoading: false,
          plans : null ,
          deleteModal : false ,
          deleteModalId : null ,
          editModal : false ,
          modalInfo : null ,
          listingEditInfo : null ,
          primaryInfoEditPage : false ,
          additionalInfoEditPage : false ,
          locationInfoEditPage : false ,
          imageEditPage : false ,
          areaSelected:"",
          yearSelected:"",
          parkingNumSelected:"",
          bedroomNumSelected:"",
          masterRoomNumSelected:"",
          bathNumSelected:"",
          frenchWcNumSelected:"",
          iranianWcNumSelected:"",
          totalPriceSelected:"",
          prebuySelected:"",
          mortgageSelected: "",
          rentSelected: "" ,
          elevatorSelected: false,
          storeSelected: false,
          facadeSelected: [],
          facadeDefault: [],
          kitchenEqSelected: [],
          kitchenEqDefault: [],
          kitchenCabinetSelected: [],
          kitchenCabinetDefault: [],
          coolHeatSelected: [],
          coolHeatDefault: [],
          safetySelected: [],
          safetyDefault: [],
          welfareSelected: [],
          welfareDefault: [],
          coverSelected: [],
          coverDefault: [],
          uploadedImages: null ,
          clearText:'حذف',
          closeText:"بستن",
          openText:"باز کردن",
          noOptionsText:"فاقد اطلاعات",
          typeAlert:3,
          typeDocumentOptions : [] ,
          typeDocumentSelected : null ,
          situationSelected:[],
          currentSituationOptions:[],
          currentSituationSelected: null,
          typeKitchenOptions : [] ,
          typeKitchenSelected : null,
        }
    }

    componentDidMount(){
      this.isMount = true;
      document.title = 'آگهی های من در بهترین وب سایت آگهی املاک ایران'
      if (this.isMount){     
        this.getListingUser();
      }
    }

    componentWillUnmount(){
      this.isMount = false ;
    }

    resetStates = () => {
      this.setState({
        editAndDeleteButtonDisable: false ,
        modalInfo : null ,
        listingEditInfo : null ,
        primaryInfoEditPage : false ,
        additionalInfoEditPage : false ,
        locationInfoEditPage : false ,
        imageEditPage : false ,
        areaSelected:"",
        yearSelected:"",
        parkingNumSelected:"",
        bedroomNumSelected:"",
        masterRoomNumSelected:"",
        bathNumSelected:"",
        frenchWcNumSelected:"",
        iranianWcNumSelected:"",
        totalPriceSelected:"",
        prebuySelected:"",
        mortgageSelected: "",
        rentSelected: "" ,
        elevatorSelected: false,
        storeSelected: false,
        facadeSelected: [],
        facadeDefault: [],
        kitchenEqSelected: [],
        kitchenEqDefault: [],
        kitchenCabinetSelected: [],
        kitchenCabinetDefault: [],
        coolHeatSelected: [],
        coolHeatDefault: [],
        safetySelected: [],
        safetyDefault: [],
        welfareSelected: [],
        welfareDefault: [],
        coverSelected: [],
        coverDefault: [],
        uploadedImages: null ,
        searchText: "",
        typeDocumentOptions : [] ,
        typeDocumentSelected : null ,
        situationSelected:[],
        currentSituationSelected: null,
        typeKitchenSelected : null,
      })
    }
    

    getNotification = () => {
      Promise.resolve(axios({
        method:'GET',
        url:baseUrl + 'v1/notification/get',
        headers:{'Authorization':'bearer ' + this.token},
      }))
      .then((res) =>{
        let info = JSON.stringify(res.data.data);
        localStorage.setItem("notification",info);
      })
      .catch(() =>{
        localStorage.removeItem("notification");
      })
    }

     /* ----------------------- listing users functions ------------------------ */

    getListingUser = () => {
      this.setState({listingUserInfo:[],listingUserShow:[],showGif:""});
      Promise.resolve(axios({
        url: baseUrl + "v1/user/listing",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        if(res.data.data.length > 0){
          this.setState({listingUserInfo: res.data.data ,listingUserShow: res.data.data, listingUserPage : true , noAdsPage: false});
          this.getPlans();
          document.getElementById("search-input").value = "" ;
        }else{
          this.setState({listingUserInfo:[],listingUserShow:[], listingUserPage:false , noAdsPage:true});
        }
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
      })
      .finally(()=>{
        this.setState({showGif:"none"});
      })
    }

    getPlans = () => {
      Promise.resolve(axios({
        url: baseUrl + "v1/plan/get",
        method: "GET",
      }))
      .then((res) => {
          this.setState({plans: res.data.data,planButtonDisable:false});
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است و بخش ارتقاء آگهی غیرفعال است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
      })
    }

    oncloseAlert = () =>{
      this.setState({openAlert:false});
    }

    checkStatusListing = (element) => {
      let status = element.status.des ;
      for(let i = 0 ; i < statusListing.length ; i++){
        if(status === statusListing[i].f){
          return statusListing[i].n ;
        }
      }
    }

    onClickVip1 = (element) => {
      if(element.small.length > 0){
        this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
        let planId ;
        let planMessage = "";
        for(let i = 0 ; i<this.state.plans.length ; i++){
          if(this.state.plans[i].name === "Vip1"){
            planId = this.state.plans[i].id ;
            planMessage = this.state.plans[i].plan;
          }
        }
        Promise.resolve(axios({
          url: baseUrl+"v1/listing/upGrade",
          method: "POST",
          headers: {"Authorization":"bearer "+ this.token},
          data : {
            listing_id : element.id,
            plan_id : planId
          }
        }))
        .then((res) => {
          this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,message:`آگهی به ${planMessage} ارتقاء یافت`,typeAlert:1});
          this.getListingUser();
          this.getNotification();
        })
        .catch((err) => {
          switch(err.response.status){
            case 400 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
              break;
            case 404 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
              break;
            case 426 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی هنوز فعال نشده است"});
              break;
            case 422 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"موجودی حساب شما کافی نمی باشد"});
              break;
            default :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا مجددا تلاش کنید در صورت تکرار مشکل با پشتیبانی تماس حاصل فرمائید"});
          }
        });
      }else{
        this.setState({openAlert:true,message:"برای ارتقا آگهی به vip1 نیاز است که آگهی تصویر داشته باشد",typeAlert:3});
      }
    }

    onClickVip2 = (element) => {
      if(element.small.length > 0){
        this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
        let planId ;
        let planMessage = "";
        for(let i = 0 ; i<this.state.plans.length ; i++){
          if(this.state.plans[i].name === "Vip2"){
            planId = this.state.plans[i].id ;
            planMessage = this.state.plans[i].plan;
          }
        }
        Promise.resolve(axios({
          url: baseUrl+"v1/listing/upGrade",
          method: "POST",
          headers: {"Authorization":"bearer "+ this.token},
          data : {
            listing_id : element.id,
            plan_id : planId
          }
        }))
        .then((res) => {
          this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,message:`آگهی به ${planMessage} ارتقاء یافت`,typeAlert:1});
          this.getListingUser();
          this.getNotification();
        })
        .catch((err) => {
          switch(err.response.status){
            case 400 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
              break;
            case 404 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
              break;
            case 426 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی هنوز فعال نشده است"});
              break;
            case 422 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"موجودی حساب شما کافی نمی باشد"});
              break;
            default :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا مجددا تلاش کنید در صورت تکرار مشکل با پشتیبانی تماس حاصل فرمائید"});
          }
        });
      }else{
        this.setState({openAlert:true,message:"برای ارتقا آگهی به vip2 نیاز است که آگهی تصویر داشته باشد",typeAlert:3});
      }
    }

    onClickVip3 = (element) => {
      if(element.small.length > 0){
        this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
        let planId ;
        let planMessage = "";
        for(let i = 0 ; i<this.state.plans.length ; i++){
          if(this.state.plans[i].name === "Vip3"){
            planId = this.state.plans[i].id ;
            planMessage = this.state.plans[i].plan;
          }
        }
        Promise.resolve(axios({
          url: baseUrl+"v1/listing/upGrade",
          method: "POST",
          headers: {"Authorization":"bearer "+ this.token},
          data : {
            listing_id : element.id,
            plan_id : planId
          }
        }))
        .then((res) => {
          this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,message:`آگهی به ${planMessage} ارتقاء یافت`,typeAlert:1});
          this.getListingUser();
          this.getNotification();
        })
        .catch((err) => {
          switch(err.response.status){
            case 400 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
              break;
            case 404 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
              break;
            case 426 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی هنوز فعال نشده است"});
              break;
            case 422 :
              this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"موجودی حساب شما کافی نمی باشد"});
              break;
            default :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
                message:"آگهی ارتقاء نیافت لطفا مجددا تلاش کنید در صورت تکرار مشکل با پشتیبانی تماس حاصل فرمائید"});
          }
        });
      }else{
        this.setState({openAlert:true,message:"برای ارتقا آگهی به vip3 نیاز است که آگهی تصویر داشته باشد",typeAlert:3});
      }
    }

    onClickLadder = (element) => {
      this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
      let planId ;
      let planMessage = "";
      for(let i = 0 ; i<this.state.plans.length ; i++){
        if(this.state.plans[i].name === "Ladder"){
          planId = this.state.plans[i].id ;
          planMessage = this.state.plans[i].plan;
        }
      }
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/upGrade",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data : {
          listing_id : element.id,
          plan_id : planId
        }
      }))
      .then((res) => {
        this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,message:`آگهی به ${planMessage} ارتقاء یافت`,typeAlert:1});
        this.getListingUser();
        this.getNotification();
      })
      .catch((err) => {
        switch(err.response.status){
          case 400 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
            break;
          case 404 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
            break;
          case 426 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی هنوز فعال نشده است"});
            break;
          case 422 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"موجودی حساب شما کافی نمی باشد"});
            break;
          default :
          this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی ارتقاء نیافت لطفا مجددا تلاش کنید در صورت تکرار مشکل با پشتیبانی تماس حاصل فرمائید"});
        }
      });
    }

    onClickRefresh = (element) => {
      this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
      let planId ;
      let planMessage = "";
      for(let i = 0 ; i<this.state.plans.length ; i++){
        if(this.state.plans[i].name === "Refresh"){
          planId = this.state.plans[i].id ;
          planMessage = this.state.plans[i].plan;
        }
      }
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/upGrade",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data : {
          listing_id : element.id,
          plan_id : planId
        }
      }))
      .then((res) => {
        this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,message:`آگهی به ${planMessage} ارتقاء یافت`,typeAlert:1});
        this.getListingUser();
        this.getNotification();
      })
      .catch((err) => {
        switch(err.response.status){
          case 400 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
            break;
          case 404 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی ارتقاء نیافت لطفا با پشتیبانی تماس حاصل فرمائید"});
            break;
          case 426 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی هنوز فعال نشده است"});
            break;
          case 422 :
            this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"موجودی حساب شما کافی نمی باشد"});
            break;
          default :
          this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
              message:"آگهی ارتقاء نیافت لطفا مجددا تلاش کنید در صورت تکرار مشکل با پشتیبانی تماس حاصل فرمائید"});
        }
      });
    }

    onClickDeleteAds = (element) => {
      this.setState({deleteModal:true,deleteModalId:element.id});
    }

    onCloseDeleteAds = () => {
      this.setState({deleteModal:false,deleteModalId:null});
    }

    submitDeleteAds = () => {
      this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/delete",
        method: "DELETE",
        headers: {"Authorization":"bearer "+ this.token},
        params : {
          listing_id : this.state.deleteModalId
        }
      }))
      .then((res) => {
        this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,message:"آگهی با موفقیت حذف شد",typeAlert:1,
          deleteModal:false,deleteModalId:null
        });
        this.getListingUser();
      })
      .catch((err) => {
        this.setState({showGif:"none",planButtonDisable:false,editAndDeleteButtonDisable:false,openAlert:true,typeAlert:2,
        message:"آگهی حذف نشد لطفا با پشتیبانی تماس حاصل فرمائید",deleteModal:false,deleteModalId:null})
      });
    }

    onClickView = (element) => {
      localStorage.setItem("idListing",JSON.stringify(element.id));
    }

    listingUserAds = () => {
      let result = [];
      if(this.state.listingUserShow.length > 0){
        this.state.listingUserShow.forEach((element,index) => {
            result.push(
              <Grid item xs={4} md={4} lg={4} xl={4} key={element.id}>
                <Grid sx={{border:"2px solid #eee",borderRadius:"0 40px 0 0",overflow:"hidden"}}>
                  <Grid container columns={{xs:12}}>
                    <Grid item xs={5} sx={{background:`url(${element.small.length > 0 ? element.small[0].path : noLogoAgancyImage})`,
                      backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
                    >
                    </Grid>
                    <Grid item xs={7}>
                      <Grid sx={{display:"flex",alignItems:"center",padding:"7% 0 0 0"}}>
                      <Typography component={"img"} src={locationIcon} alt="location icon" sx={{width:{xs:"15px",md:"20px"},height:"auto",marginRight:"3%"}}></Typography>
                      <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"10px",display:"inline-block"}}>
                        {element.state.name}  -
                      </Typography>
                      <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"5px",display:"inline-block"}}>
                        {element.neighbourhood.name}
                      </Typography>
                      </Grid>
                        { element.show_price === 1 ?
                            element.transaction_id === 1 ?
                              <Grid sx={{padding:"25px 0"}}>
                                <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600"}} >
                                    خرید :
                                  </Typography>
                                  <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                                    { parseInt(element.total_price) ?
                                        `${convertToPersianNumber(separator(element.total_price))} تومان`
                                      : "توافقی"
                                    }
                                  </Typography>
                              </Grid>
                            : element.transaction_id === 2 ?
                                <Grid sx={{padding:"12.5px 0"}}>
                                  <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600"}} >
                                      رهن :
                                    </Typography>
                                    <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                                      { parseInt(element.mortgage)  ?
                                          `${convertToPersianNumber(separator(element.mortgage))} تومان`
                                        : "توافقی"
                                      }
                                    </Typography>
                                    <br />
                                    <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600"}} >
                                      اجاره :
                                    </Typography>
                                    <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                                      { parseInt(element.rent) ?
                                          `${convertToPersianNumber(separator(element.rent))} تومان`
                                        : "توافقی"
                                      }
                                    </Typography>
                                </Grid>
                              : element.transaction_id === 3 ?
                                  <Grid sx={{padding:"25px 0"}}>
                                    <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600"}} >
                                        پیش خرید :
                                      </Typography>
                                      <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                                        { parseInt(element.prebuy) ?
                                            `${convertToPersianNumber(separator(element.prebuy))} تومان`
                                          : "توافقی"
                                        }
                                      </Typography>
                                  </Grid>
                                : <Grid></Grid>
                          : <Grid sx={{padding:"25px 0"}}>
                              <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600"}} >
                                  قیمت :
                                </Typography>
                                <HomeinjaTypo style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600"}} text={"توافقی"}  />
                            </Grid>
                        }
                      <Grid sx={{paddingBottom:"7%"}}>
                        <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600"}}>وضعیت :</Typography>
                        <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"5px",fontWeight:"600"}}>{this.checkStatusListing(element)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {  this.userInfo.user_type_id < 4 ?
                      element.active === 1 ?
                        element.plan_id === 1 || element.plan_id === 6 ?
                          <Grid container columns={{xs:12}} sx={{bgcolor:"#CCF4FB",padding:{xs:"0 5px",md:"15px"},height:{xs:"135px",sm:"200px",md:"190px",lg:"130px"}}} alignItems="center">
                            <Grid item container xs={2} alignItems="center" justifyContent="center">
                                <Typography sx={{fontSize:{xs:"15px",md:"15px"},fontFamily:"shabnam",fontWeight:"600"}}>ارتقاء آگهی</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Button sx={{padding:"0",minWidth:"100%",maxWidth:"100%"}} disabled={this.state.planButtonDisable} onClick={() => this.onClickVip1(element)}>
                                <Typography component={"img"} src={vip1Image} alt="button" sx={{width:"100%",height:"auto"}}></Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={2}>
                              <Button sx={{padding:"0",minWidth:"100%",maxWidth:"100%"}} disabled={this.state.planButtonDisable} onClick={() => this.onClickVip2(element)}>
                                <Typography component={"img"} src={vip2Image} alt="button" sx={{width:"100%",height:"auto"}}></Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={2}>
                              <Button sx={{padding:"0",minWidth:"100%",maxWidth:"100%"}} disabled={this.state.planButtonDisable} onClick={() => this.onClickVip3(element)}>
                                <Typography component={"img"} src={vip3Image} alt="button" sx={{width:"100%",height:"auto"}}></Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={2}>
                              <Button sx={{padding:"0",minWidth:"100%",maxWidth:"100%"}} disabled={this.state.planButtonDisable} onClick={() => this.onClickLadder(element)}>
                                <Typography component={"img"} src={ladderImage} alt="button" sx={{width:"100%",height:"auto"}}></Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={2}>
                              <Button sx={{padding:"0",minWidth:"100%",maxWidth:"100%"}} disabled={this.state.planButtonDisable} onClick={() => this.onClickRefresh(element)}>
                                <Typography component={"img"} src={refreshImage} alt="button" sx={{width:"100%",height:"auto"}}></Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        : <Grid container sx={{bgcolor:"#CCF4FB",padding:{xs:"0 5px",md:"15px"},height:{xs:"135px",sm:"200px",md:"190px",lg:"130px"}}} alignItems="center">
                            <Typography sx={{fontSize:{xs:"15px",md:"15px"},fontFamily:"shabnam",fontWeight:"600",margin:"auto"}}>
                              { element.plan_id === 2 ?
                                  "آگهی به vip 1 ارتقا یافته است"
                                : element.plan_id === 3 ?
                                    "آگهی به vip 2 ارتقا یافته است"
                                  : element.plan_id === 4 ?
                                      "آگهی به vip 3 ارتقا یافته است"
                                    : element.plan_id === 5 ?
                                        "آگهی به نردبان ارتقا یافته است"
                                      : "آگهی ارتقاء یافته است"
                              }
                              

                            </Typography>
                          </Grid>
                      : <Grid container sx={{bgcolor:"#CCF4FB",padding:{xs:"0 5px",md:"15px"},height:{xs:"135px",sm:"200px",md:"190px",lg:"130px"}}} alignItems="center">
                          <Typography sx={{fontSize:{xs:"15px",md:"15px"},fontFamily:"shabnam",fontWeight:"600",margin:"auto"}}>آگهی غیرفعال است</Typography>
                        </Grid>
                    : <Grid container sx={{bgcolor:"#CCF4FB",padding:{xs:"0 5px",md:"15px"},height:{xs:"135px",sm:"200px",md:"190px",lg:"130px"}}} alignItems="center">
                        <Typography sx={{fontSize:{xs:"15px",md:"15px"},fontFamily:"shabnam",fontWeight:"600",margin:"auto"}}>ارتقاء برای نوع کاربری شما غیر فعال شده است</Typography>
                      </Grid>
                  } 
                  <Grid container columns={{xs:12}} spacing={.3}>
                    <Grid item xs={4}>
                      <Button onClick={() => this.onClickDeleteAds(element)}
                        sx={{minWidth:"100%",maxWidth:"100%",bgcolor:"#00C8E9",borderRadius:"0","&:hover":{bgcolor:"#00C8E9"}}} disabled={this.state.editAndDeleteButtonDisable}
                      >
                        <DeleteOutlineIcon sx={{color:"black !important",margin:"3% 0"}}/>
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button onClick={() => this.onClickEdit(element)}
                        sx={{minWidth:"100%",maxWidth:"100%",bgcolor:"#00C8E9",borderRadius:"0","&:hover":{bgcolor:"#00C8E9"}}} disabled={this.state.editAndDeleteButtonDisable}
                      >
                        <EditIcon sx={{color:"black !important",margin:"3% 0"}}/>
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button component={Link} to={"/Listing/" + element.id} target="_blank"
                        sx={{minWidth:"100%",maxWidth:"100%",bgcolor:"#00C8E9",borderRadius:"0","&:hover":{bgcolor:"#00C8E9"}}} disabled={this.state.editAndDeleteButtonDisable}
                      >
                        <VisibilityRoundedIcon sx={{color:"black !important",margin:"3% 0"}}/>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
        });
      }else{
        result.push(
          <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",margin:"50px auto"}}>
            آگهی جهت نمایش وجود ندارد
          </Typography>
        )
      }
      return result ;
    }

    onChangeSearchText = (event,value) => {
      this.setState({searchText:event.target.value})
    }

    onClickSearch = () => {
      let listingUserInfo = this.state.listingUserInfo ;
      let searchText = document.getElementById("search-input").value ;
      searchText = searchText.trim();
      let result = [] ;
      if(searchText === ""){
        this.setState({listingUserShow:listingUserInfo});
      }else{
          for(let i = 0 ; i < listingUserInfo.length ; i++){
            if(listingUserInfo[i].neighbourhood.name.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
              result.push(listingUserInfo[i]);
              continue ;
            }
            if(listingUserInfo[i].state.name.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
              result.push(listingUserInfo[i]);
              continue ;
            }
            if(listingUserInfo[i].city.name.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
              result.push(listingUserInfo[i]);
              continue ;
            }
            if(listingUserInfo[i].zone.name.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
              result.push(listingUserInfo[i]);
              continue ;
            }
            if(listingUserInfo[i].total_price !== null){
              if(listingUserInfo[i].total_price.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
                result.push(listingUserInfo[i]);
                continue ;
              }
            }
            if(listingUserInfo[i].rent !== null){
              if(listingUserInfo[i].rent.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
                result.push(listingUserInfo[i]);
                continue ;
              }
            }
            if(listingUserInfo[i].prebuy !== null){
              if(listingUserInfo[i].prebuy.includes(convertToEnglishNumber(convertToPersianNumber(searchText)))){
                result.push(listingUserInfo[i]);
                continue ;
              }
            }
          }
        this.setState({listingUserShow:result});
      }
    }

     /* ----------------------- edit modal functions ------------------------ */

    onCloseEditModal = () => {
      this.setState({editModal:false,planButtonDisable:false,editAndDeleteButtonDisable:false});
      this.resetStates();
      this.getListingUser();
    }

    onClickEdit = (element) => {
      this.setState({showGif:"",planButtonDisable:true,editAndDeleteButtonDisable:true});
        Promise.resolve(axios({
          url: baseUrl+'v1/current/get',
          method: 'GET'
        }))
        .then((res) => {
            this.setState({currentSituationOptions:res.data.data});
            this.getKitchen(element)
        })
        .catch((err) => {
          this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است و بخش ویرایش غیر فعال است",typeAlert:2,showGif:"none",planButtonDisable:false,
          editAndDeleteButtonDisable:false});
        });
    }

    getKitchen = (element)=>{
      Promise.resolve(axios({
        url: baseUrl+'v1/kitchen/get',
        method: 'GET'
      }))
      .then((res) => {
          this.setState({typeKitchenOptions:res.data.data});
          this.getDocument(element);
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است و بخش ویرایش غیر فعال است",typeAlert:2,showGif:"none",planButtonDisable:false,
        editAndDeleteButtonDisable:false});
      });
    }

    getDocument = (element) => {
      Promise.resolve(axios({
          url: baseUrl+'v1/document/get',
          method: 'GET'
        }))
        .then((res) => {
            this.setState({typeDocumentOptions:res.data.data});
            this.getListingInfo(element);
        })
        .catch((err) => {
          this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است و بخش ویرایش غیر فعال است",typeAlert:2,showGif:"none",planButtonDisable:false,
          editAndDeleteButtonDisable:false});
        });
    }

    getListingInfo = (element) => {
      Promise.resolve(axios({
        url: baseUrl + "v1/listing/getOne",
        method: "GET",
        params: {
          listing_id:element.id
        }
      }))
      .then((res) => {
          this.setState({listingEditInfo:res.data.data,uploadedImages:res.data.data,showGif:"none",editModal:true,modalInfo:res.data.data,primaryInfoEditPage:true});
          this.setDefaultValues(res.data.data);
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است و بخش ویرایش غیر فعال است",typeAlert:2,planButtonDisable:false,editAndDeleteButtonDisable:false,showGif:"none"});
      });
    }

    setDefaultValues = (info) => {
      this.setState({areaSelected:info.area ? info.area : "",yearSelected:info.year ? info.year : "",parkingNumSelected:info.parking_num ? info.parking_num : "",
        bedroomNumSelected: info.bedroom_num ? info.bedroom_num : "",masterRoomNumSelected: info.master_room_num ? info.master_room_num : "",
        bathNumSelected:info.bath_num ? info.bath_num : "",iranianWcNumSelected: info.iranian_wc_num ? info.iranian_wc_num : "",frenchWcNumSelected:info.french_wc_num ? info.french_wc_num :"",
        elevatorSelected: info.elevator ? true : false,storeSelected: info.store_num ? true : false , typeDocumentSelected: info.document ? info.document : null,
        situationSelected: this.situationDefault(info.situation), currentSituationSelected : info.current ? info.current : null,typeKitchenSelected : info.tkitch ? info.tkitch  : null
      });
      if(info.total_price !== null){
        this.setState({totalPriceSelected:info.total_price})
      }
      if(info.prebuy !== null){
        this.setState({prebuySelected:info.prebuy})
      }
      if(info.rent !== null){
        this.setState({rentSelected:info.rent})
      }
      if(info.mortgage !== null){
        this.setState({mortgageSelected:info.mortgage})
      }
      if(info.description !== null){
        document.getElementById("primaryinfo-desc").value = info.description ;
      }
      if(info.facade !== null){
        let defaultData = this.defaultFeatures(info.facade,facade)
        this.setState({facadeDefault : defaultData , facadeSelected : defaultData});
      }
      if(info.kitchen !== null){
        let defaultData = this.defaultFeatures(info.kitchen,kitchenEq)
        this.setState({kitchenEqDefault : defaultData , kitchenEqSelected : defaultData})
      }
      if(info.cabinet !== null){
        let defaultData = this.defaultFeatures(info.cabinet,kitchenCabinet)
        this.setState({kitchenCabinetDefault : defaultData , kitchenCabinetSelected : defaultData})
      }
      if(info.condition !== null){
        let defaultData = this.defaultFeatures(info.condition,cool_heat)
        this.setState({coolHeatDefault : defaultData , coolHeatSelected : defaultData})
      }
      if(info.safety !== null){
        let defaultData = this.defaultFeatures(info.safety,safety)
        this.setState({safetyDefault : defaultData , safetySelected : defaultData})
      }
      if(info.welfare !== null){
        let defaultData = this.defaultFeatures(info.welfare,welfare)
        this.setState({welfareDefault : defaultData , welfareSelected : defaultData})
      }
      if(info.cover !== null){
        let defaultData = this.defaultFeatures(info.cover,cover)
        this.setState({coverDefault : defaultData , coverSelected : defaultData})
      }
    }

    situationDefault = (situationData) => {
      let situations = [];
      let result = [] ;
      for(let i in situationData){
        if(i !== "id" && i !== "listing_id"){
          if(parseInt(situationData[i])){
            situations.push(i)
          }else{
            continue
          }
        }
      }
      for(let i = 0 ; i < situations.length ; i++){
        for(let j = 0 ; j < situation.length ; j++){
          if(situations[i] === situation[j].f){
            result.push(situation[j]);
          }
        }
      }
      return result;
    }

    defaultFeatures = (defaultFeatures,feature) => {
      let result = [] ;
        for(let key in defaultFeatures){
          if(key !== "id" && key !== "listing_id"){
            if(parseInt(defaultFeatures[key])){
              for(let i = 0 ; i < feature.length ; i++){
                if(key === feature[i].f){
                  let item = {};
                  item.f = feature[i].f;
                  item.n = feature[i].n;
                  result.push(item);
                }
              }
            }
          }
        }
      return result;
    }

     /* ----------------------- edit primary info functions ------------------------ */

    onChangeTypeDocument = (event,newValue) =>{
      this.setState({typeDocumentSelected:newValue})
    } 

    onChangeArea = (event,newValue) =>{
      this.setState({areaSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeYear = (event,newValue) =>{
      this.setState({yearSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeParkingNum = (event,newValue) =>{
      this.setState({parkingNumSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeBedroomNum = (event,newValue) =>{
      this.setState({bedroomNumSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeMasterRoomNum = (event,newValue) =>{
      this.setState({masterRoomNumSelected:onlyReciveNumber(event.target.value)});
    }

    onChangebathNum = (event,newValue) =>{
      this.setState({bathNumSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeFrenchWcNum = (event,newValue) =>{
      this.setState({frenchWcNumSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeIranianWcNum = (event,newValue) =>{
      this.setState({iranianWcNumSelected:onlyReciveNumber(event.target.value)});
    }

    onChangeMortgage = (event,newValue) =>{
      let inputWithoutComma = event.target.value.replace(/,/g,"");
      this.setState({mortgageSelected:onlyReciveNumber(inputWithoutComma)});
    }
  
    onChangeRent = (event,newValue) =>{
      let inputWithoutComma = event.target.value.replace(/,/g,"");
      this.setState({rentSelected:onlyReciveNumber(inputWithoutComma)});
    }
  
    onChangeTotalPrice = (event,newValue) =>{
      let inputWithoutComma = event.target.value.replace(/,/g,"");
      this.setState({totalPriceSelected:onlyReciveNumber(inputWithoutComma)});
    }

    onChangePrebuy = (event,newValue) =>{
      let inputWithoutComma = event.target.value.replace(/,/g,"");
      this.setState({prebuySelected:onlyReciveNumber(inputWithoutComma)});
    }

    onChangeElevator = (event,newValue) =>{
      this.setState({elevatorSelected:event.target.checked})
    }
  
    onChangeStore = (event,newValue) =>{
      this.setState({storeSelected:event.target.checked})
    }

    onChangeSituation = (event,newValue) =>{
      this.setState({situationSelected:[]})
      if(newValue.length > 0 ){
        this.setState({situationSelected:newValue})
      }
    }

    onChangeCurrentSituation = (event,newValue) =>{
      this.setState({currentSituationSelected:newValue})
    }

    onChangeKitchen = (event,newValue) =>{
      this.setState({typeKitchenSelected:newValue})
  }

    situationInfo = () => {
      let situationSelect = this.state.situationSelected ;
      let result = {};
      
      for(let i = 0 ; i < situation.length ; i++){
        let isExist = false ;
        for(let j = 0 ; j < situationSelect.length ; j++){
          if(situation[i].f === situationSelect[j].f){
            isExist = true ;
          }
        }
        if(isExist){
          result[situation[i].f] = 1 ;
        }else{
          result[situation[i].f] = 0 ;
        }
      }
      return result ;
    }

    checkPrimaryEditInfo = () => {
      switch(null){
        case this.state.typeDocumentSelected :
          this.setState({openAlert:true,message:"لطفا نوع سند را وارد کنید",typeAlert:3});
          break;
        case this.state.currentSituationSelected :
          this.setState({openAlert:true,message:"لطفا وضعیت کنونی ملک را وارد کنید",typeAlert:3});
          break;
        case this.state.typeKitchenSelected :
          this.setState({openAlert:true,message:"لطفا نوع آشپزخانه را وارد کنید",typeAlert:3});
          break;
        default :
          this.checkYearAndArea();
      }
    }

    checkYearAndArea = () => {
      if(this.state.areaSelected){
        if(this.state.yearSelected === ""){
          this.setState({openAlert:true,message:"لطفا سال ساخت را وارد کنید",typeAlert:3});
        }else{
          let yearSelected = this.state.yearSelected.toString();
          if(yearSelected.length < 4){
            this.setState({openAlert:true,message:"تاریخ باید چهار رقمی باشد برای مثال ۱۴۰۱",typeAlert:3});
          }else{
            this.submitEditPrimaryInfo();
          }
        }
      }else{
        this.setState({alertModal:true,alertMessage:"لطفا متراژ را وارد کنید",typeAlert:3});
      }
    }  

    submitEditPrimaryInfo = () => {
      this.setState({showGif:"",isLoading:true})
      let params = {
        listing_id:this.state.listingEditInfo.id,
        document_id:this.state.typeDocumentSelected.id,
        transaction_id:this.state.listingEditInfo.transaction_id,
        estate_id:this.state.listingEditInfo.estate_id,
        kitchen_id:this.state.typeKitchenSelected.id,
        state_id:this.state.listingEditInfo.state_id,
        city_id:this.state.listingEditInfo.city_id,
        zone_id:this.state.listingEditInfo.zone_id,
        neighbourhood_id:this.state.listingEditInfo.neighbourhood_id,
        current_situation_id:this.state.currentSituationSelected.id,
      }
      if(this.state.areaSelected){
        params.area = this.state.areaSelected ;
      }
      if(this.state.yearSelected){
        params.year = this.state.yearSelected ;
      }
      if(this.state.parkingNumSelected){
        params.parking_num = this.state.parkingNumSelected ;
      }
      if(this.state.bedroomNumSelected){
        params.bedroom_num = this.state.bedroomNumSelected ;
      }
      if(this.state.masterRoomNumSelected){
        params.master_room_num = this.state.masterRoomNumSelected ;
      }
      if(this.state.bathNumSelected){
        params.bath_num = this.state.bathNumSelected ;
      }
      if(this.state.frenchWcNumSelected){
        params.french_wc_num = this.state.frenchWcNumSelected ;
      }
      if(this.state.iranianWcNumSelected){
        params.iranian_wc_num = this.state.iranianWcNumSelected ;
      }
      if(this.state.totalPriceSelected){
        params.total_price = this.state.totalPriceSelected ;
      }
      if(this.state.prebuySelected){
        params.prebuy = this.state.prebuySelected ;
      }
      if(this.state.mortgageSelected){
        params.mortgage = this.state.mortgageSelected ;
      }
      if(this.state.rentSelected){
        params.rent = this.state.rentSelected ;
      }
      if(this.state.elevatorSelected){
        params.elevator = 1 ;
      }
      if(this.state.storeSelected){
        params.store_num = 1 ;
      }
      let description = document.getElementById("primaryinfo-desc").value ;
      description = description.trim();
      if(description !== ""){
        params.description = description ;
      }
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/edit",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: params
      }))
      .then((res) => {
          this.postSituaton();
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"ویرایش اطلاعات اولیه  ثبت نشد",showGif:"none",isLoading:false,typeAlert:2})
      });
    }

    postSituaton = () =>{
      if(this.state.situationSelected !== null){
        let token = localStorage.getItem('tokenUserHomeInja');
        let params = this.situationInfo();
        params.listing_id = this.state.listingEditInfo.id;
        Promise.resolve(axios({
          url: baseUrl+"v1/listing/situation",
          method: "POST",
          headers: {"Authorization":"bearer "+ token},
          data: params
        }))
        .then((res) => {
          this.setState({showGif:"none",isLoading:false,openAlert:true,message:"ویرایش اطلاعات اولیه با موفقیت ثبت شد",typeAlert:1,primaryInfoEditPage:false,additionalInfoEditPage:true,})
        })
        .catch((err) => {
          this.setState({showGif:"none",isLoading:false,openAlert:true,message:"در ویرایش اطلاعات اولیه مشکلی پیش آمد لطفا با پشتیبانی تماس بگیرید",typeAlert:2});
          this.resetStates();
        });
      }
    }

    skipEditPrimaryInfo = () => {
      this.setState({primaryInfoEditPage:false,additionalInfoEditPage:true});
    }

    /* ----------------------- edit additional info functions ------------------------ */

    checkFeatures = (selected,feature) => {
      let result = {};
      for(let i = 0 ; i < feature.length ; i++){
        let isExist = false ;
        for(let j = 0 ; j < selected.length ; j++){
          if(feature[i].f === selected[j].f){
            isExist = true ;
          }
        }
        if(isExist){
          result[feature[i].f] = 1 ;
        }else{
          result[feature[i].f] = 0 ;
        }
      }
      return result;
    }
  
    onChangeFacade = (event,newValue) => {
      this.setState({facadeSelected:newValue});
    }
  
    onChangeKitchenEq = (event,newValue) => {
      this.setState({kitchenEqSelected:newValue});
    }
  
    onChangeKitchenCabinet = (event,newValue) => {
      this.setState({kitchenCabinetSelected:newValue});
    }
  
    onChangeCoolHeat = (event,newValue) => {
      this.setState({coolHeatSelected:newValue});
    }
  
    onChangeSafety = (event,newValue) => {
        this.setState({safetySelected:newValue});
    }
  
    onChangeWelfare = (event,newValue) => {
        this.setState({welfareSelected:newValue});
    }
  
    onChangeCover = (event,newValue) => {
      this.setState({coverSelected:newValue});
    }
  
    submitEditAdditionalInfo = () => {
      this.setState({showGif:"",isLoading:true});
      let facadeData = this.checkFeatures(this.state.facadeSelected,facade);
      facadeData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/facade/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: facadeData
      }))
      .then((res) => {
        this.postKitchenEq();
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات نمای ساختمان با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",showGif:"none",
          isLoading:false,facadeSelected:[],kitchenEqSelected:[],kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],
          typeAlert:2});
      });
    }
  
    postKitchenEq = () => {
      let kitchenEqData = this.checkFeatures(this.state.kitchenEqSelected,kitchenEq);
      kitchenEqData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/equipment/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: kitchenEqData
      }))
      .then((res) => {
        this.postKitchenCabinet();
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات امکانات آشپزخانه با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
          showGif:"none",isLoading:false,kitchenEqSelected:[],kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],
          typeAlert:2});
      });
    }
  
    postKitchenCabinet = () => {
      let kitchenCabinetData = this.checkFeatures(this.state.kitchenCabinetSelected,kitchenCabinet);
      kitchenCabinetData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/cabinet/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: kitchenCabinetData
      }))
      .then((res) => {
        this.postCoolHeat();
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات نوع کابینت با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
          showGif:"none",kitchenCabinetSelected:[],coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],isLoading:false,typeAlert:2
        });
      });
    }
  
    postCoolHeat = () => {
      let coolHeatData = this.checkFeatures(this.state.coolHeatSelected,cool_heat);
      coolHeatData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/che/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: coolHeatData
      }))
      .then((res) => {
        this.postSafety();
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات تهویه مطبوع با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
          showGif:"none",isLoading:false,coolHeatSelected:[],safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
        });
      });
    }
  
    postSafety = () => {
      let safetyData = this.checkFeatures(this.state.safetySelected,safety);
      safetyData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/safety/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: safetyData
      }))
      .then((res) => {
        this.postWelfare();
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات امکانات امنیتی با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
          showGif:"none",isLoading:false,safetySelected:[],welfareSelected:[],coverSelected:[],typeAlert:2
        });
      });
    }
  
    postWelfare = () => {
      let welfareData = this.checkFeatures(this.state.welfareSelected,welfare);
      welfareData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/welfare/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: welfareData
      }))
      .then((res) => {
        this.postCover();
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات امکانات رفاهی با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
          showGif:"none",isLoading:false,welfareSelected:[],coverSelected:[],typeAlert:2
        });
      });
    }
  
    postCover = () => {
      let coverData = this.checkFeatures(this.state.coverSelected,cover);
      coverData.listing_id = this.state.listingEditInfo.id ;
      Promise.resolve(axios({
        url: baseUrl+"v1/cover/create",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: coverData
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"ویرایش اطلاعات تکمیلی با موفقیت ثبت شد",additionalInfoEditPage: false, locationInfoEditPage: true,showGif:"none",isLoading:false,
        typeAlert:1})
      })
      .catch((err) => {
        this.setState({
          openAlert:true,message:"ویرایش اطلاعات کفپوش ساختمان با مشکل مواجه شد مجددا تلاش کنید  در صورت تکرار مشکل با پشنیبان تماس حاصل فرمائید ",
          showGif:"none",isLoading:false,coverSelected:[],typeAlert:2
        });
      });
    }

    skipEditAdditionalInfo = () => {
      this.setState({additionalInfoEditPage:false,locationInfoEditPage:true});
    }

    /* ----------------------- edit location info functions ------------------------ */

    locationInfoEditPage = () => {
      if(this.state.listingEditInfo.latitude === null && this.state.listingEditInfo.longitude === null){
        return (
          <Grid sx={{width:"100%"}}>
            <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8% 2% 0"}}>۳ - تعیین محل آگهی</Typography>
            <Grid sx={{width:"90%",height:{xs:"300px",md:"600px"},margin:"auto",borderRadius:"8px",overflow:"hidden"}}>
              <GetLocation />
            </Grid>
            <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
              <Grid item xs={12} md={6}>
                <Button onClick={this.submitLocationInfo} disabled={this.state.isLoading}
                  sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                  fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                >
                  ذخیره محل آگهی
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button onClick={this.skipSubmitLocationInfo} disabled={this.state.isLoading}
                  sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                  fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                >
                  رد کردن این بخش
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      }else{
        this.setState({locationInfoEditPage:false,imageEditPage:true});
        return <Grid></Grid>
      }
    }

    submitLocationInfo = () => {
      this.setState({showGif:"",isLoading:true})
      let latitudeInfo = localStorage.getItem("lat");
      let longitudeInfo = localStorage.getItem("lng");
      if(latitudeInfo && longitudeInfo){
        Promise.resolve(axios({
          url: baseUrl+"v1/listing/edit",
          method: "POST",
          headers: {"Authorization":"bearer "+ this.token},
          data: {
            latitude : latitudeInfo ,
            longitude : longitudeInfo ,
            listing_id : this.state.listingEditInfo.id ,
          }
        }))
        .then((res) => {
          this.setState({openAlert:true,message:"محل آگهی با موفقیت ثبت شد",showGif:"none",isLoading:false,locationInfoEditPage:false,imageEditPage:true,typeAlert:1});
          localStorage.removeItem('lat');
          localStorage.removeItem('lng');
        })
        .catch((err) => {
          this.setState({openAlert:true,message:"ثبت محل آگهی با مشکل مواجه شد در صورت تکرار مشکل با پشتیبان تماس حاصل فرمائید",showGif:"none",isLoading:false,typeAlert:2});
          localStorage.removeItem('lat');
          localStorage.removeItem('lng');
        });
      }else{
        this.setState({openAlert:true,message:"شما برای این آگهی موقعیتی ثبت نکردید",locationInfoEditPage: false ,imageEditPage: true,showGif:"none",isLoading:false,typeAlert:3});
      }
    }

    skipSubmitLocationInfo = () => {
      this.setState({locationInfoEditPage: false ,imageEditPage: true});
    }

    /* ----------------------- edit image functions ------------------------ */

    smallImageResized ;
    largeImageResized ;
    imageFile ;

    onChangeAddImage = (e) => {
      if(e.target.files[0]){
        if(e.target.files[0].size > 5000000){
          this.setState({openAlert:true,typeAlert:3,
            message:"حجم عکس نباید بیشتر از ۵ مگابایت باشد لطفا اقدام به کاهش حجم عکس نمائید یا با پشتیبان تماس حاصل نمائید تا در کاهش حجم عکس شما را یاری نماید"
          });
        }else{
          this.setState({showGif:"",isLoading:true});
          this.imageFile = e.target.files[0] ;
          this.smallImageResizer();
        }
      }
    }
  
    smallImageResizer = () => {
        try {
          Resizer.imageFileResizer(
            this.imageFile,
            400,
            400,
            "JPEG",
            40,
            0,
            (uri) => {
              this.smallImageResized = uri ;
              this.largeImageResizer();
            },
            "file",
          );
        } catch (err) {
          this.setState({openAlert:true,message:"فایل موردنظر عکس نمیباشد",showGif:"none",isLoading:false,typeAlert:2});
        }
    }
  
    largeImageResizer = () => {
      try {
        Resizer.imageFileResizer(
          this.imageFile,
          1920,
          1080,
          "JPEG",
          70,
          0,
          (uri) => {
            this.largeImageResized = uri ;
            this.postLargeImage();
          },
          "file",
        );
      } catch (err) {
        this.setState({openAlert:true,message:"فایل موردنظر مشکل دارد",showGif:"none",isLoading:false,typeAlert:2});
      }
    } 
  
    postLargeImage = () => {
      const formData = new FormData();
      formData.append("image", this.largeImageResized);
      formData.append("listing_id", this.state.listingEditInfo.id);
      formData.append("type", "L");
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/addImages",
        method: "POST",
        headers: {"Authorization":"bearer "+ this.token},
        data: formData
      }))
      .then((res) => {
        this.postSmallImage();
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شد و عکس بارگزاری نشد",showGif:"none",isLoading:false,typeAlert:2});
      });
    }
  
    postSmallImage = () => {
      const formData = new FormData();
      formData.append("image", this.smallImageResized);
      formData.append("listing_id", this.state.listingEditInfo.id);
      formData.append("type", "S");
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/addImages",
        method: "POST",
        headers: {
          "Authorization":"bearer "+ this.token,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"عکس با موفقیت بارگزاری شد",showGif:"none",isLoading:false,typeAlert:1});
        this.getUploadedImages();
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شد و عکس بارگزاری نشد",showGif:"none",isLoading:false,typeAlert:2});
        this.getLargeImage();
      });
    } 
  
    getLargeImage = () => {
      Promise.resolve(axios({
        url: baseUrl+'v1/listing/getOne',
        method: 'GET',
        params: {
          listing_id: this.state.listingEditInfo.id
        }
      }))
      .then((res) => {
        let largeImages = res.data.data.large ;
        this.deleteLargeImage(largeImages[largeImages.length - 1].id);
      })
    }
  
    deleteLargeImage = (id) => {
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/deleteImage",
        method: "DELETE",
        headers: {"Authorization":"bearer "+ this.token},
        params : {
          small_id : 0 ,
          large_id : id
        }
      }))
    }
  
    getUploadedImages = () => {
      Promise.resolve(axios({
        url: baseUrl+'v1/listing/getOne',
        method: 'GET',
        params: {
          listing_id: this.state.listingEditInfo.id
        }
      }))
      .then((res) => {
        this.setState({uploadedImages:res.data.data});
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"بروزرسانی تصاویر با مشکل مواجه شد",typeAlert:2});
      });
    }
  
    uploadedImageThumbnails = () => {
      if(this.state.uploadedImages){
        let result = [];
        this.state.uploadedImages.small.forEach((element,index) => {
          result.push(
          <Grid item xs={2} key={index}>
            <Grid
              sx={{bgcolor:"lightblue",height:{xs:"140px",lg:"200px"},border:"2px solid #eee",borderRadius:"4px",position:"relative",
              background:`url(${this.state.uploadedImages.small[index].path})` ,
              backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
            >
              <Button onClick={() => this.deleteImage(index)} disabled={this.state.isLoading}
                sx={{position:"absolute",left:"-2px",bottom:"-2px",minWidth:"40px",maxWidth:"40px",borderRadius:"0 10px 0 4px",
                bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"}}}
              >
              <DeleteOutlineOutlinedIcon sx={{color:"black !important"}} />
              </Button>
            </Grid>
          </Grid>
          )
        });
        return result;
      }else{
        return <Grid></Grid>
      }
    }
    
    deleteImage = (index) => {
      this.setState({showGif:"",isLoading:true});
      let token = localStorage.getItem('tokenUserHomeInja');
      Promise.resolve(axios({
        url: baseUrl+"v1/listing/deleteImage",
        method: "DELETE",
        headers: {"Authorization":"bearer "+ token},
        params : {
          small_id : this.state.uploadedImages.small[index].id,
          large_id : this.state.uploadedImages.large[index].id
        }
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"عکس با موفقیت حذف شد",showGif:"none",isLoading:false,typeAlert:1});
        this.getUploadedImages();
      })
      .catch((err) => {
        this.getUploadedImages();
        this.setState({openAlert:true,message:"حذف عکس با مشکل مواجه شد",showGif:"none",isLoading:false,typeAlert:2});
      });
    }

    submitEdit = () => {
      if(this.state.uploadedImages.small.length !== this.state.uploadedImages.large.length){
        this.sendPhotoProblemTicket();
      }else{
        this.setState({openAlert:true,message:"تغییرات با موفقیت ثبت شد",editModal:false,typeAlert:1});
        this.resetStates();
        this.getListingUser(); 
      }
    }

    sendPhotoProblemTicket = () => {
      this.setState({giftShow:true,isLoading:true});
      Promise.resolve(axios({
        url: baseUrl + 'v1/ticket/create',
        method: 'POST',
        headers: {"Authorization":"bearer "+ this.token},
        data: {
          title: "تصاویر large و small این آگهی مشکل دارد" ,
          category_id: 1 ,
          message: ` آگهی ${this.state.uploadedImages.id}`
        }
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"تغییرات با موفقیت ثبت شد",editModal:false,typeAlert:1,showGif:"none",isLoading:false});
        this.resetStates();
        this.getListingUser(); 
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"فایلهای تصاویر شما دارای مشکل است لطفا با پشتیبیانی تماس بگیرید",editModal:false,typeAlert:2,showGif:"none",isLoading:false});
        this.resetStates();
        this.getListingUser();
      })
    }

    onClickEnter = (event) => {
      if(event.key === "Enter"){
        this.onClickSearch();
      }
    }
    
    render() {
      return (
        <Grid  sx={{marginTop:"120px",width:'100%',marginRight:{md:'270px'},display:"flex",alignItems:"center",flexDirection:'column'}}>
          { this.state.noAdsPage ?
            <Grid sx={{width:"100%"}}>
              <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>آگهی های من</Typography>
              <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {"کاربر گرامی شما آگهی ثبت شده ای ندارید"}
                </Typography>
              </Grid>
            </Grid>
          : <Grid></Grid>
          }
          { this.state.listingUserPage ?
              this.state.editModal === false ?
                <Grid sx={{width:"100%"}}>
                  <Grid container sx={{padding:"0 6%"}}>
                    <Grid item xs={12} md={3}>
                      <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},fontWeight:'bold'}}>آگهی های من</Typography>
                      <Grid>
                        <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"0.8rem",md:"1rem"},fontWeight:'bold',display:"inline-block",color:"gray"}}>تعداد آگهی  :</Typography>
                        <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"0.8rem",md:"1rem"},fontWeight:'bold',display:"inline-block",marginRight:"5px",color:"gray"}}>
                          {convertToPersianNumber(this.state.listingUserInfo.length)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid sx={{display:"flex",padding:{xs:"10px 0 0",md:"0"}}}>
                        <TextField 
                          sx={{bgcolor:"rgb(241, 242, 242)",borderRadius:"0 30px 4px 0",flexGrow:"1"}}
                          InputProps={{
                            sx:{
                              fontFamily:"shabnam",fontSize:"20px"
                            }
                          }}
                          id="search-input"
                          inputProps={{ maxLength: 100 }}
                          onKeyPress={(event) => {this.onClickEnter(event)}}
                        />
                        <Button disabled={this.state.giftShow} onClick={this.onClickSearch}
                          sx={{bgcolor:"#14C9E8","&:hover":{bgcolor:"#14C9E8"},flexBasis:"200px",borderRadius:{xs:"4px 0 0 30px",md:"4px 0 0 4px"},fontFamily:"shabnam",fontWeight:"bold",color:"white",
                            fontSize:{xs:"15px",md:"20px"}}} 
                        >
                          جستجو آگهی
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container sx={{padding:{xs:"2% 2%",md:"2% 6%"}}} spacing={{ xs: 1, md:2}} columns={{ xs: 4, md: 4, lg:8, xl: 12 }}>
                    {this.listingUserAds()}
                  </Grid>
                </Grid>
              : <Grid></Grid>
          : <Grid></Grid>
          }
          <Modal
            open={this.state.editModal}
            onClose={this.onCloseEditModal}
            sx={{overflowY:"auto",width:"100%"}}
          >
            <Box sx={{position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width:{xs:"95%",md:"90%"},bgcolor:'white',height:"90vh",overflowY:"auto",
              display:{xs:"static",md:"flex"},alignItems:"center"}}
            >
              { this.state.primaryInfoEditPage ?
                  <Grid sx={{width:"100%"}}>
                    <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8%"}}>۱ - ویرایش اطلاعات اولیه</Typography>
                    <Grid sx={{width:"85%",margin:"auto"}}>
                      <Grid container columns={{xs:4,sm:8,md:12,lg:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}> 
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                            id="ads-autocomplete-input"
                            className="ads-autocomplete"
                            options={this.state.typeDocumentOptions}
                            getOptionLabel={(option) => option.des}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField  {...params} placeholder='نوع سند *'/>}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                />
                                {option.des}
                              </li>
                            )}
                            onChange={this.onChangeTypeDocument}
                            value={this.state.typeDocumentSelected}
                            clearText={this.state.clearText}
                            closeText={this.state.closeText}
                            openText={this.state.openText}
                            noOptionsText={this.state.noOptionsText}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                        <Autocomplete
                          id="ads-autocomplete-input"
                          className="ads-autocomplete"
                          options={situation}
                          getOptionLabel={(option) => option.n}
                          renderInput={(params) => <TextField  {...params} placeholder='موقعیت ملک'/>}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                              />
                              {option.n}
                            </li>
                          )}
                          onChange={this.onChangeSituation}
                          value={this.state.situationSelected}
                          clearText={this.state.clearText}
                          closeText={this.state.closeText}
                          openText={this.state.openText}
                          noOptionsText={this.state.noOptionsText}
                          multiple
                          limitTags={1}
                          disableCloseOnSelect
                        />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                            id="ads-autocomplete-input"
                            className="ads-autocomplete"
                            options={this.state.currentSituationOptions}
                            getOptionLabel={(option) => option.des}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} placeholder='وضعیت کنونی ملک *'/>}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{marginRight: 8}}
                                  checked={selected}
                                />
                                {option.des}
                              </li>
                            )}
                            onChange={this.onChangeCurrentSituation}
                            value={this.state.currentSituationSelected}
                            clearText={this.state.clearText}
                            closeText={this.state.closeText}
                            openText={this.state.openText}
                            noOptionsText={this.state.noOptionsText}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                        <Autocomplete
                          id="ads-autocomplete-input"
                          className="ads-autocomplete"
                          options={this.state.typeKitchenOptions}
                          getOptionLabel={(option) => option.des}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} placeholder='نوع آشپزخانه '/>}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                              />
                              {option.des}
                            </li>
                          )}
                          value={this.state.typeKitchenSelected}
                          onChange={this.onChangeKitchen}
                          clearText={this.state.clearText}
                          closeText={this.state.closeText}
                          openText={this.state.openText}
                          noOptionsText={this.state.noOptionsText}
                        />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeArea}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.areaSelected)}
                            label="متراژ"
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeYear}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.yearSelected)}
                            label="سال ساخت"
                            inputProps={{
                              maxLength: 4
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeParkingNum}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.parkingNumSelected)}
                            label="تعداد پارکینگ"
                            inputProps={{
                              maxLength: 2
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeBedroomNum}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.bedroomNumSelected)}
                            label="تعداد خواب"
                            inputProps={{
                              maxLength: 3
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeMasterRoomNum}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.masterRoomNumSelected)}
                            label="تعداد مستر"
                            inputProps={{
                              maxLength: 2
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangebathNum}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.bathNumSelected)}
                            label="تعداد حمام"
                            inputProps={{
                              maxLength: 2
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeFrenchWcNum}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.frenchWcNumSelected)}
                            label="تعداد سرویس بهداشتی فرنگی"
                            inputProps={{
                              maxLength: 2
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <TextField 
                            fullWidth    
                            onChange={this.onChangeIranianWcNum}
                            className="ads-textfield"
                            id="ads-textfield-input"
                            value={convertToPersianNumber(this.state.iranianWcNumSelected)}
                            label="تعداد سرویس بهداشتی ایرانی"
                            inputProps={{
                              maxLength: 2
                            }}
                          />
                        </Grid>
                        { this.state.modalInfo ?
                            this.state.modalInfo.transaction_id === 1 ?
                              <Grid item xs={4} lg={3}>
                                <TextField 
                                  fullWidth    
                                  onChange={this.onChangeTotalPrice}
                                  className="ads-textfield"
                                  id="ads-textfield-input"
                                  value={convertToPersianNumber(separator(this.state.totalPriceSelected))}
                                  label="قیمت خرید"
                                  placeholder="تومان"
                                  inputProps={{
                                    maxLength: 20
                                  }}
                                />
                              </Grid>
                            : this.state.modalInfo.transaction_id === 3 ?
                                <Grid item xs={4} lg={3}>
                                  <TextField 
                                    fullWidth    
                                    onChange={this.onChangePrebuy}
                                    className="ads-textfield"
                                    id="ads-textfield-input"
                                    value={convertToPersianNumber(separator(this.state.prebuySelected))}
                                    label="قیمت پیش خرید"
                                    placeholder="تومان"
                                    inputProps={{
                                      maxLength: 20
                                    }}
                                  />
                                </Grid>
                              : <>
                                  <Grid item xs={4} lg={3}>
                                    <TextField 
                                      fullWidth    
                                      onChange={this.onChangeMortgage}
                                      className="ads-textfield"
                                      id="ads-textfield-input"
                                      value={convertToPersianNumber(separator(this.state.mortgageSelected))}
                                      label="رهن"
                                      placeholder="تومان"
                                      inputProps={{
                                        maxLength: 20
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={4} lg={3}>
                                    <TextField 
                                      fullWidth    
                                      onChange={this.onChangeRent}
                                      className="ads-textfield"
                                      id="ads-textfield-input"
                                      value={convertToPersianNumber(separator(this.state.rentSelected))}
                                      label="اجاره"
                                      placeholder="تومان"
                                      inputProps={{
                                        maxLength: 20
                                      }}
                                    />
                                  </Grid>
                                </>
                          : <Grid></Grid>
                        }
                        <Grid item xs={4} lg={3} sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                          <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>آسانسور</Typography>
                          <Checkbox checked={this.state.elevatorSelected} onChange={this.onChangeElevator}/>
                          <Typography sx={{display:"inline-block",fontFamily:"shabnam"}}>انباری</Typography>
                          <Checkbox checked={this.state.storeSelected} onChange={this.onChangeStore}/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid sx={{padding:"1% 14%"}}>
                      <Typography sx={{fontFamily:"shabnam",marginBottom:"10px"}}>توضیحات تکمیلی :</Typography>
                      <TextareaAutosize maxLength={1200} className="primaryinfo-textarea" id="primaryinfo-desc"
                        style={{width:"100%",maxWidth:"100%",minWidth:"100%",minHeight:"150px",backgroundColor:"#F2F2F2",border:"none",borderRadius:"8px",fontFamily:"shabnam",fontSize:"15px",
                                padding:"10px",boxSizing:"border-box"}}
                      />
                    </Grid>
                    <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Button onClick={this.checkPrimaryEditInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                            fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                        >
                          ویرایش اطلاعات اولیه
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button onClick={this.skipEditPrimaryInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                            fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                        >
                          رد کردن این بخش
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                : <Grid></Grid>
              }
              { this.state.additionalInfoEditPage ?
                  <Grid sx={{width:"100%"}}>
                    <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8%"}}>۲ - ویرایش اطلاعات تکمیلی</Typography>
                    <Grid sx={{width:"85%",margin:"auto"}}>
                      <Grid container columns={{xs:4,sm:8,md:12,lg:12}} sx={{padding:"2% 8%"}} rowSpacing={1} columnSpacing={{xs:1,sm:2,md:4}}>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={facade}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='نمای ساختمان'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeFacade}
                              defaultValue={this.state.facadeDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={kitchenEq}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='امکانات آشپزخانه'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeKitchenEq}
                              defaultValue={this.state.kitchenEqDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={kitchenCabinet}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='نوع کابینت'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeKitchenCabinet}
                              defaultValue={this.state.kitchenCabinetDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={cool_heat}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='تهویه مطبوع'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeCoolHeat}
                              defaultValue={this.state.coolHeatDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={safety}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='امکانات امنیتی'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeSafety}
                              defaultValue={this.state.safetyDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={welfare}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='امکانات رفاهی'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeWelfare}
                              defaultValue={this.state.welfareDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Autocomplete
                              id="ads-autocomplete-input"
                              className="ads-autocomplete"
                              options={cover}
                              getOptionLabel={(option) => option.n}
                              renderInput={(params) => <TextField  {...params} placeholder='کفپوش ساختمان'/>}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                  />
                                  {option.n}
                                </li>
                              )}
                              onChange={this.onChangeCover}
                              defaultValue={this.state.coverDefault}
                              isOptionEqualToValue={(option, value) => option.f === value.f}
                              clearText={this.state.clearText}
                              closeText={this.state.closeText}
                              openText={this.state.openText}
                              multiple
                              limitTags={1}
                              disableCloseOnSelect
                            />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container sx={{textAlign:"center",padding:"0 14%",width:"85%",margin:"auto"}} spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Button onClick={this.submitEditAdditionalInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                            fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                        >
                          ویرایش اطلاعات تکمیلی
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button onClick={this.skipEditAdditionalInfo} disabled={this.state.isLoading}
                          sx={{bgcolor:"#00C8E9",color:"black","&:hover":{bgcolor:"#00C8E9"},fontFamily:"shabnam",maxWidth:"100%",minWidth:"100%",margin:"auto",
                            fontSize:{xs:"15px",md:"20px"},marginBottom:"20px"}}
                        >
                          رد کردن این بخش
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                : <Grid></Grid>
              }
              { this.state.locationInfoEditPage ?
                  <Grid sx={{width:"100%"}}>{this.locationInfoEditPage()}</Grid>
                : <Grid></Grid>
              }
              { this.state.imageEditPage ?
                  <Grid sx={{width:"100%"}}>
                    <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"20px",md:"30px"},padding:"0 8% 2% 0"}}>۴ - بارگزاری عکس</Typography>
                    <Grid sx={{width:{xs:"95%",lg:"80%"},margin:"auto",border:"2px solid #eee",borderRadius:"8px",padding:{xs:"40px 3px",md:"40px 10px"},textAlign:"center"}}>
                      <Grid container spacing={{xs:.5,md:2,lg:1}} columns={{xs:4,sm:6,md:8,xl:12}} justifyContent="center">
                        { this.state.uploadedImages.small.length === 0 ?
                            <Grid item xs={2}>
                              <Grid sx={{height:{xs:"140px",lg:"200px"},border:"2px solid #eee",borderRadius:"4px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <AddCircleOutlineIcon sx={{fontSize:"50px"}} />
                              </Grid>
                            </Grid>
                          :<Grid></Grid>
                        }
                        {this.uploadedImageThumbnails()}
                      </Grid>
                      <Button variant="contained" component="label" disabled={this.state.isLoading}
                        sx={{fontFamily:"shabnam",padding:{xs:"10px",md:"15px 50px"},borderRadius:"4px 15px 4px 15px",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",
                            fontWeight:"600",marginTop:"40px"}}
                      >
                        افزودن عکس +
                        <input type="file" hidden accept="image/*" onChange={this.onChangeAddImage}/>
                      </Button>
                    </Grid>
                    <Grid sx={{textAlign:"center"}}>
                      <Button onClick={this.submitEdit} disabled={this.state.isLoading}
                        sx={{fontFamily:"shabnam",padding:{xs:"10px 30px",md:"15px 100px"},borderRadius:"4px",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},color:"black",
                            fontWeight:"600",marginTop:"40px"}}>
                        ثبت تغییرات
                      </Button>
                    </Grid>
                  </Grid>
                : <Grid></Grid>
              }
            </Box>
          </Modal>
          <Modal
            open={this.state.deleteModal}
            onClose={this.onCloseDeleteAds}
          >
            <Box sx={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%, -50%)',bgcolor:'white',padding:"40px",textAlign:"center",width:{xs:"90%",md:"auto"}}}>
              <Typography sx={{fontFamily:"shabnam",fontSize:"20px",padding:"20px 0"}}>آیا از حذف آگهی مطمئن هستید ؟</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button onClick={this.onCloseDeleteAds}
                    sx={{maxWidth:"100%",minWidth:"100%",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},borderRadius:"0",fontFamily:"shabnam",fontSize:"20px",color:"black"}}
                  >
                    خیر
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={this.submitDeleteAds}
                    sx={{maxWidth:"100%",minWidth:"100%",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},borderRadius:"0",fontFamily:"shabnam",fontSize:"20px",color:"black"}}
                  >
                    بله
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <AlertModal open={this.state.openAlert} close={this.oncloseAlert} message={this.state.message} type={this.state.typeAlert}/>
          <GifLogo display={this.state.showGif}/>
        </Grid>
      )
    };
}

export default ListingUser;