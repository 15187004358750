import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article51/01.jpg";
import photo02 from "../../images/mag/article51/02.jpg";
import photo03 from "../../images/mag/article51/03.jpg";
import photo04 from "../../images/mag/article51/04.jpg";
import photo05 from "../../images/mag/article51/05.jpg";
import photo06 from "../../images/mag/article51/06.jpg";
import photo07 from "../../images/mag/article51/07.jpg";
import photo08 from "../../images/mag/article51/08.jpg";
import photo09 from "../../images/mag/article51/09.jpg";
import photo10 from "../../images/mag/article51/10.jpg";
import photo11 from "../../images/mag/article51/11.jpg";
import photo12 from "../../images/mag/article51/12.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article51 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "بلوار اندرزگو از خیابان های شمال شرق تهران می باشد که در منطقه یک شهرداری تهران واقع است و به محلی برای دور دور قشر جوان تبدیل شده است. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "خیابان اندرزگو کجاست | اندرزگو تهران کجاست | اندرزگو همراه با عکس و نقشه | خیابان اندرزگو قیطریه تهران | مسیر رفتن به بلوار اندرزگو | قیمت ملک، آپارتمان در اندرزگو | خرید ملک در اندرزگو | رهن و اجاره ملک در اندرزگو | معرفی یکی از لوکس ترین محله های منطقه ۱ تهران | معرفی کامل محله اندرزگو | معرفی کافه و رستوران در اندرزگو"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[50].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[50].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[50].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[50].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[50].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[50].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="عکس اندرزگو تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[50].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مقاله قصد داریم به معرفی کامل خیابان اندرزگو تهران بپردازیم. مطالبی چون دلیل معروفیت اندرزگو، تفریحات، رستوران ها، مراکز خرید و ... را برای شما شرح می دهیم و از جذابیتهای بلوار اندرزگو تهران با شما بیشتر صحبت خواهیم کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس با بلاگ <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> همراه باشید تا صفر تا صد خیابان اندرزگو تهران را بررسی کنیم.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="خیابان اندرزگو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خیابان اندرزگو تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نام اندرزگو در ذهن مردم تداعی گر ترافیک پر حجم آخر هفته همراه با رستوران های فراوان، کافه های دنج، دور دور جوانان تهرانی در بلوار اندرزگو می باشد. به تدریج و با اضافه شدن انواع رستوران ها و غذا ها اندرزگو به قطب خوراکی تهران معرف شده است. اگر به دنبال تفریح هستین قطعا بلوار اندرزگو یکی از بهترین پیشنهادهای هومینجا برای پاساژگردی و سرو غذاست.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  بیشتر بخوانید :  معرفی کامل محله نیاوران تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                خیابان اندرزگو تهران کجاست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                احتمالا آدرس اندرزگو را هر جوانی در <Link className="link" to="/tehran-zones" target="_blank">  تهران </Link> می داند و یکی از رستوران ها را به شما توصیه خواهد کرد. اما بلوار اندرزگوی تهران کجاست؟ اندرزگو نام خیابانی در <Link className="link" to="/tehran-zone-1" target="_blank">  منطقه 1 </Link> شهرداری تهران است. این خیابان از شرق به چهار راه کامرانیه و از غرب به <Link className="link" to="/Listings/rent_tehran_kaveh" target="_blank">  بلوار کاوه </Link> منتهی می شود. اندرزگو در محله قیطریه تهران واقع شده است و با محلات حکمت، <Link className="link" to="/Listings/rent_tehran_chizer" target="_blank">  چیذر </Link> و <Link className="link" to="/Listings/buy_tehran_farmaniyeh" target="_blank">  فرمانیه </Link> همجوار است. این بلوار را به نام اندرزگو قیطریه، اندرزگو فرمانیه و اندرزگو فرشته نیز می شناسند . دلیل این امر هم منتهی شدن خیابان اندرزگو به خیابان های اصلی <Link className="link" to="/Listings/buy_tehran_fereshte" target="_blank">  فرشته </Link>، فرمانیه، <Link className="link" to="/Listings/buy_tehran_kamranieh" target="_blank">  کامرانیه </Link> و قیطریه می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                نقشه بلوار اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همان طور که گفته شده خیابان اندرزگو همجوار با پارک قیطریه، بلوار کاوه، چهار راه کامرانیه، خیابان لواسانی و محله چیذر می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در زیر نقشه بلوار اندرزگوی منطقه 1 تهران را مشاهده می فرمایید:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="نقشه بلوار اندرزگو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نقشه بلوار اندرزگو تهران
                </figcaption>
              </figure>
              <Typography className="h2" variant="h2">
                تاریخچه خیابان اندرزگو تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نام این بلوار از اسم شهید <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%B3%DB%8C%D8%AF_%D8%B9%D9%84%DB%8C_%D8%A7%D9%86%D8%AF%D8%B1%D8%B2%DA%AF%D9%88" target="_blank">  سید علی </LinkMui> اندرزگو، روحانی مبارز زمان محمدرضا شاه گرفته شده است که در سال ۱۳۱۸ متولد و در شهریور ۱۳۵۷ توسط ساواک به شهادت رسید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طرح و نقشه این بلوار لوکس منطقه یک توسط تیم شهرسازی تهران زیر نظر فرح پهلوی ترسیم شد. بعد از انقلاب به دلیل بافت قدیمی و وجود خانه و مغازه های قدیمی، اجرای پروژه بسیار سخت شده بود. سرانجام در سال 1383 اجرای پروژه بلوار اندرزگو کلید خورد و با هزینه 50 میلیارد تومانی در سال 1385 پایان یافت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قبل از ساخت خیابان اندرزگو، این محل مسیر عبور مردم از قیطریه و <Link className="link" to="/Listings/buy_tehran_dezashib" target="_blank">  دزاشیب </Link> و نیاوران بود. هم چنین قبل از ساخت پل صدر و ورودی اختصاصی اندرزگو، این محل از تعمیرگاه ها، چوب فروشی و <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">  قلیان </Link> فروشی مملو بود. پس از اجرای کامل پل صدر به تدریج پاساژها، برج های لوکس و رستوران ها فعالیت خود را آغاز کردند .
              </Typography>
              <Typography className="h2" variant="h2">
                خیابان های معروف اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیابان‌های اشکستان پور، عبدالهی، ارکیده، چیذری، سلیمی و طالقانی از جمله خیابان های معروف بلوار اندرزگو می باشند که با وجود شلوغ بودن افراد زیادی متقاضی سکونت در این خیابان ها هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز خرید اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به تدریج با ورود مردم برای گردش و تفریح، پاساژ و مراکز خرید لوکس در خیابان اندرزگو تاسیس شد که در ذیل به برخی از آنها اشاره خواهیم کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">
                  بیشتر بخوانید : الهیه تهران کجاست ؟ معرفی کامل خیابان فرشته و محله الهیه تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرکز خرید اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از لوکس ترین پاساژهای اندرزگو که در تقاطع اندرزگو و <Link className="link" to="/Listings/buy_tehran_ghaitarieh" target="_blank">  قیطریه </Link> واقع شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرکز خرید سانا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="مرکز خرید سانا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پاساژ سانا اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معروف ترین پاساژ و مرکز خرید بلوار اندرزگو، مرکز خرید <LinkMui className="link" href="https://sanashoppingcenter.com" target="_blank">  سانا </LinkMui> است . مجتمع تجاری سانا شامل رستوران، فود کورت و فروشگاه های پوشاک، عطر، جواهر و ... می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرکز خرید فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مجتمع های تازه تاسیس مرکز خرید فرمانیه است که واقع در بلوار اندرزگو نبش کوچه آقایی می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرکز خرید روبان نور
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین مراکز خرید محله <Link className="link" to="/Listings/buy_tehran_hekmat" target="_blank">  حکمت </Link> تهران محسوب می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرکز خرید دریای نور
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مرکز خرید در انتهای بلوار اندرزگو واقع شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرکز خرید آوان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مرکز خرید تخصصی بانوان که فروش شال، مانتو اختصاص دارد و گالری های متعدد زنانه در <LinkMui className="link" href="https://avancollection.com" target="_blank">  مرکز خرید آوان </LinkMui> واقع شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                بام برند سنتر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="بام برند سنتر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بام برند سنتر اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مجتمع برند سنتر (بام سنتر ۱ فرمانیه) که زیر نظر گروه توسعه مهندسی بام طراحی و ساخته شده است. برند سنتر فرمانیه در زمینی به مساحت ۱۱۴۴ متر مربع در خیابان فرمانیه تهران روبروی باشگاه ورزشی و سالن فرمانیه قرار دارد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : بهترین کافه های شمال تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                خانه سام
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نام <LinkMui className="link" href="https://www.sam-home.com" target="_blank">  خانه سام </LinkMui> برای خیلی از علاقه مندان به لوازم و <Link className="link" to="/small-house-decoration" target="_blank">  دکور خانه </Link> آشنا می باشد. یکی از شعب خانه سام در بلوار اندرزگو می باشد. این فروشگاه زنجیره ای محصولات برندهای معتبر لوازم خانه و آشپزخانه نظیرتفال، بوش،ایکیا، دکتر اوتکر، بامبوم، لایفهایت، مولینکس، چینی زرین، فیلیپس و پرومکس را ارئه می دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین رستوران های خیابان اندرزگو تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به دلیل وجود رستوران های فراوان و برندهای غذایی، اندرزگو  به قطب غذایی با تنوع بالا تبدیل شده است. مسلما سلیقه هر نفر در انتخاب رستوران مهمترین تاثیر را دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ذیل به معرفی تعدادی از رستوران ها بر حسب نظرات مردم می پردازیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                رستوران شمشیری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.shamshirifood.com" target="_blank">  رستوران شمشیری </LinkMui> یکی از قدیمی ترین رستوران های بازار تهران محسوب می شود که طعم اصیل غذاهای ایرانی را ارئه می دهد. شعبه اندرزگوی رستوران شمشیری به صورت آشپزخانه (بیرون بر) فعالیت می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                رستوران هانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از شعبه های رستوران معروف هانی، در بلوار اندرزگو، تقاطع بلوار کاوه می باشد. در این رستوران انواع غذاهای ایرانی و بین المللی ارائه می شود. غذا در این رستوران به صورت سلف سرویس سرو می شود. همچنین در این شعبه از رستوران هانی می توانید غذاهای آماده و کنسروی نیز تهیه نمایید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                مرغ سوخاری خروس
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="مرغ سوخاری خروس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مرغ سوخاری خروس اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خروس را می توان یکی از بهترین سوخاری های تهران نامید. در خروس می توانید انواع مرغ سوخاری ساده و اسپایسی، قارچ سوخاری و سیب زمینی را میل کنید. خروس فضای کوچکی دارد که خیلی برای نشستن مناسب نیست و بیشتر به صورت بیرون بر غذا سرو می کند. خروس هیچ شعبه دیگری در تهران و شهرستان ها ندارد و تنها شعبه اصلی آن در خیابان اندرزگو می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های شمال تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                دونر گاردن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قصد خوردن یک دونر خوشمزه را دارید پیشنهاد ما به شما دونر گاردن می باشد. این رستوران برای سرو غذا از نان مخصوص به خودش استفاده می کند. همچنین بدلیل استفاده از گوشت تازه ای که در کارخانه دونر گاردن تهیه می شود از کیفیت غذایی بالایی برخوردار است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                برگرلند
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="برگرلند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  برگرلند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رستوران <LinkMui className="link" href="https://burgerland.com" target="_blank">  برگرلند </LinkMui> اندرزگو یکی از شعب زنجیره ای این فست فود می باشد. این رستوران دارای منوی متنوعی است. کیفیت غذایی این رستوران بسیار بالاست. برگرلند در تمامی وعده های غذایی آماده خدمت رسانی به مشتریان می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                میخوش
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.meykhosh.ir/order/meykhoshfarmanie" target="_blank">  میخوش </LinkMui> از دیگر رستوران های زنجیره ای می باشد که یکی از شعبه های آن در بلوار اندرزگو واقع است. این شعبه از میخوش دکوری بسیار لوکس و چشم نواز دارد که در دو طبقه تعبیه شده است. این رستوران همراه با منویی متنوع از مشتریان خود پذیرایی می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                جیگرکی شبزدگان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر هوس خوردن چند سیخ دل و جیگر با کیفیت را کرده اید حتما سری به جیگرکی شبزدگان اندرزگو بزنید. تهیه مواد اولیه تازه و عالی و همچنین کیفیت و طعم بی نظیر غذای این رستوران باعث جذب مشتریان زیادی شده است. به تازگی نیز پره کباب را به منوی خود اضافه کرده است که ترکیبی از گوشت فیله تازه بره و دورپیچ پرده چربی می باشد که از نرمی داخل دهان آب می شود. بنابراین پیشنهاد می کنیم هر زمان که گذرتان به اندرزگو افتاد حتما سری هم به جیگرکی شبزدگان بزنید و این غذای لذیذ را حتما تست کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                رستوران قابلمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قابلمه از رستوران های معروف قیطریه می باشد که در اندرزگو، خیابان سلیمی شمالی قرار دارد. این رستوران با دکوری سنتی فضایی دل انگیز را ایجاد کرده است. همچنین با سرو انواع غذاهای ایرانی در قابلمه های روحی صرف غذا را دلچسب تر کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                ساندویچ شمرون کباب
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شمرون کباب برای تمام دوستداران کباب نامی آشناست. شعبه جدید شمرون کباب،  <LinkMui className="link" href="https://www.instagram.com/sandwichkabab" target="_blank">  ساندویچی شمرون کباب </LinkMui> در اندرزگو می باشد.  در این شعبه کباب بصورت ساندویچ سرو می شود که طعم متفاوتی را ایجاد می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                فست فود باگت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از معروف ترین فست فودهای اندرزگو که شعبه های متعددی در سطح شهر تهارن دارد، رستوران باگت اندرزگو می باشد. فضای بزرگ دوطبقه همراه با منوی کامل از مزایای باگت اندرزگو است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                رستوران عمو سهراب
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="رستوران عموسهراب - هومینجا" className="image" />
                <figcaption className="image_caption">
                  عمو سهراب اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رستوران عمو سهراب را سهراب ام جی <LinkMui className="link" href="https://soundcloud.com/sohrab-mj" target="_blank">  Sohrab MJ </LinkMui> خواننده گروه رپ معروف زدبازی ZedBazi مدیریت می کند. فضای سنتی و سبک قهوه خانه ای این رستوران همچنین کیفیت غذاهای سنتی عمو سهراب، باعث محبوبیت این رستوران شده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : بهترین کافه کتاب های تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                هیزم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر عاشق برگر هستید حتما سری به رستوران هیزم اندرزگو بزنید. این رستوران انواع مختلفی از برگر را دارد که روی هیزم طبخ می کند. ا زجذاب ترین پیشنهاد های این رستوران برگر با قلم هیزمی می باشد که طعم فوق العاده ای دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                رستوران حاج نصرت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر رستوران های خوب اندرزگو می توان رستوران حاج نصرت را نام برد.  اگر تصمیم به رفتن این رستوران گرفتید  تست قیمه نثار این رستوران خالی از لطف نیست. چنانچه از سرویس بیرون بر این رستوران استفاده کردید اصلا نگران سرد شدن غذا نباشید چون ظروف این رستوران مایکروفری هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین کافه های اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت معروفترین کافه های اندرزگو را معرفی خواهیم کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کافه عمارت روشنان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="کافه عمارت روشنان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه عمارت روشنان اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه عمارت روشنان در یک خانه قدیمی در محله اندرزگو قرار دارد. بنای این ساختمان مربوط به اواخر دوره قاجاریه یا اوایل پهلوی می باشد. این کافه دارای دو حیاط و یک عمارت می باشد که در حیاط مخصوص کافه و در دوم مربوط به فضای داخل می باشد که به فروش کتاب و صنایع دستی اختصاص دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کافه سولیس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از کافه های معروف محله اندرزگو کافه سولیس می باشد. در این کافه شما می توانید انواع نوشیدنی های سرد و گرم، پیتزا و ساندویچ را سفارش دهید. از دیگر امکانات این کافه می توان به اینترنت رایگان، پارکینگ و موسیقی زنده اشاره کرد. همچنین این کافه برای قرارهای دوستانه و عاشقانه فضای دنجی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                امیر شکلات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://amirchocolate.com" target="_blank">  امیر شکلات </LinkMui> در اکثر شهرهای ایران شعبه دارد. این کافه در سال 1383 توسط امیر ناهید در شریعتی افتتاح شد. این کافه تنها نماینده انحصاری قهوه فیلیکوری ایتالیا است. یکی از شعب امیر شکلات در تهران، خیابان اندرزگو می باشد.  امیر شکلات جدا از قهوه، انواع کیک، آبمیوه، گلاسه و بستنی، میلک شیک و انواع نوشیدنی های گرم و سرد را با بهترین کیفیت ارائه می دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کافه آقای بازیگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه به دنبال یک فضای دنج و آرام برای قرارهای دوستانه یا کاری خود هستید بهتر است سری به کافه آقای بازیگر بزنید. کیفیت بالای سرویس ها و منوی متنوع باعث محبوبیت این کافه شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                بنللی لانژ
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="بنللی لانژ اندرزگو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بنللی لانژ اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شما در <LinkMui className="link" href="https://benellilounge.com" target="_blank">  بنللی لانژ </LinkMui> می توانید جدیدترین غذاهای روز دنیا را تست کنید. در این کافه از بهترین مواد اولیه و رسپی های اورجینال استفاده می شود. چنانچه تصمیم به برپایی ایونت یا همایشی دارید انتخاب بنللی لانژ خالی از لطف نیست. همچنین در بنللی لانژ می توانید برای دیدن فوتبال از قبل میز رزرو کنید و از تماشای فوتبال لذت بیشتری ببرید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-cafes-iftar-til-suhur" target="_blank">
                  بیشتر  بخوانید : بهترین کافه ها از افطار تا سحر در تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کافه نیشکر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر در اندرزگو دنبال کافه ای برای کشیدن قلیان هستید بهتر است سری به کافه نیشکر بزنید. این کافه که در طبقه فوقانی قنادی نیشکر واقع است دارای انواع خوراکی های خوشمزه ایتالیایی می باشد. همچنین موسیقی زنده این کافه فضای کافه را هیجا انگیز تر کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کافه کوچه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دکوراسیون خاص کافه کوچه سبب محبوبیت آن شده است. در این کافه سعی بر این است با استفاده از تیرهای قدیمی فضای یک کوچه قدیمی بازسازی شود. محیط کوچک این کافه برای ملاقات های دو نفره مناسب می باشد. همچنین برای کسانی که به نور کم عادت دارند فضای کافه کوچه یک انتخاب عالی است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کافه رئیس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از کافه های زنجیره ای معروف می توان به کافه رئیس اشاره کرد که یکی از شعب آن در اندرزگو واقع است. هر زمان هوس نوشیدن یک فنجان قهوه در محیطی دنج و بدون هیاهو را داشتید حتما سری به کافه رئیس بزنید.
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز درمانی خیابان اندرزگو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="بیمارستان توانبخشی رفیده - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بیمارستان در اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مراکز درمانی خیابان اندرزگو می توان به بیمارستان توانبخشی رفیده، کلینیک خانه ایرانی، کلینیک آوان، کلینیک تخصصی مشاوره خانواده ایرانی اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                فروشگاه ارگانیک فروشی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امروزه محصولات ارگانیک طرفداران بسیاری برای خود پیدا کرده است. از فروشگاه های معروف اندرزگو که محصولات ارگانیک ارائه می دهند می توان میوه و سبزی ارگانیک حس خوب، فروشگاه تعاونی گل باغ سی، <LinkMui className="link" href="https://shop.bamika.ir" target="_blank">  بامیکا </LinkMui>، کالوک، آبمیوه ارگانیک شیوان را نام برد.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت زمین اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر سری به خیابان اندرزگو زده باشید از دیدن این همه ساختمان در خیابان اندرزگو و کوچه هایش تعجب می کنید. می توان گفت که تمامی زمین های این محله ساخته شده است و زمین خالی برای معامله وجود ندارد و اگر هم باشد قیمتی میلیاردی دارد که هر کسی توان معامله آن را ندارد.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت ملک اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به امکانات خیابان اندرزگو تهران، خیلی از افراد خواهان زندگی در این خیابان هستند. محله اندرزگو را می توان ترکیبی از خانه های نوساز و چندین سال ساخت قدیمی دانست. ولی با این وجود قیمت ملک در اغلب نقاط اندرزگو یکسان می باشد. البته می توان گفت متقاضی برای خیابان های نزدیک به نیاوران و فرمانیه بیشتر است.
              </Typography>
              <Typography className="h2" variant="h2">
                خرید ملک، آپارتمان اندرزگو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo12} alt="خرید ملک خیابان اندرزگو تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خرید خانه اندرزگو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که اندرزگو را می توان بورس خانه های لوکس و گران دانست بنابراین برای <Link className="link" to="/Listings/buy_tehran_andarzgoo" target="_blank"> خرید ملک و آپارتمان </Link> در این خیابان باید بودجه بالایی نیز در اختیار داشته باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                خرید اداری و تجاری اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود پاساژهای فراوان برای قشر مرفه و پولدار جامعه توجیه اصلی سرمایه گذاران و کاسبان برای مهاجرت به اندرزگو است. با توجه به تردد بالای اندرزگو خیلی از صاحبین کسب و کار بدنبال خرید واحد <Link className="link" to="/Listing/241" target="_blank"> اداری تجاری </Link> در این خیابان هستند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود همزمان جندین مرکز خرید و رستوران های متعدد ضمانت صرف وقت مردم در خیابان اندرزگو است. همین امر صاحبان کسب و کارها را متقاعد کرده که اندرزگو بهترین مکان برای بیزینس است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Chitgar-Neighborhood" target="_blank">
                  بیشتر بخوانید : چیتگر تهران کجاست؛ تمام اطلاعاتی که در مورد محله چیتگر نیاز دارید
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                رهن و اجاره اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد تمایل دارند که اندرزگو را برای سکونت انتخاب کنند ولی توانایی خرید ملک و آپارتمان را در این منطقه ندارند. بنابراین رهن و اجاره را ترجیح می دهند. ولی بطور کلی می توان گفت که قیمت <Link className="link" to="/Listings/rent_tehran_andarzgoo" target="_blank"> رهن و اجاره </Link> نیز در اندرزگو بسیار بالاست و هر کسی براحتی از پس آن بر نمی آید.
              </Typography>
              <Typography className="h2" variant="h2">
                دور دور اندرزگو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بلوار اندرزگو به مکانی برای تفریح جوانان تبدیل شده است. برخی از این تفریحات مزاحمت هایی را برای اهالی منطقه ایجاد کرده است. یکی از این تفریحات دور دور خودروها می باشد که ترافیک سنگینی را حتی در طول شب ایجاد می کند. این ترافیک ها آلودگی صوتی و آلودگی هوا را برای محله به ارمغان آورده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                عدم وجود مکانی برای تفریحات مختص به جوانان در کشور دلیل اصلی بروز تفریحات کاذب مانند دور دور در خیابان و اتلاف وقت بی هدف جوانان شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از اصلی ترین خیابانهای تهران برای ماشین گردی یا به اصطلاح دور دور خیابان اندرزگو تهران می باشد. هم چنین الهیه هم برای دور دور های شبانه جذابیتی خاص برای جوانان پولدار تهرانی دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این <Link className="link" to="/Andarzgoo-blvd" target="_blank">  مطلب </Link> از   هومینجا  سعی کردیم اطلاعات کامل و دقیقی از خیابان اندرزگو ارائه دهیم تا با یکی از خیابان های بالا شهر تهران آشنا شوید. چنانچه قصد زندگی در این خیابان را ندارید ولی حتما برای تفریح سری به این خیابان بزنید.
              </Typography>
              <Typography className="p" variant="p" style={{ fontWeight: "bold" }}>
                با هومینجا یک کلیک تا قرارداد
              </Typography>
              <Typography className="h2" variant="h2">
                مطالعه آزاد :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای افزایش آگاهی خود، مطالعه مقالات زیر پیشنهاد می شود.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/tehran-district-22'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معرفی کامل منطقه ۲۲ تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/top-5-real-estate-sites'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معرفی بهترین سایت آگهی ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Housing-Market-Prediction-1402'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Business-Right'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    سرقفلی مغازه به زبان ساده
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Azadi'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    آزادی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Hakimiyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    حکیمیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Shemiran-No'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    شمیران نو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Piroozi-street'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    پیروزی
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <br />
              <Typography variant="p" style={{ fontWeight: "bold" }}>
                هم چنین مسیر های زیر شما را به آگهی های مربوط به محله های مختلف راهنمایی میکند.
              </Typography>
              <br />
              <Grid sx={{ bgcolor: "#eee", textAlign: "center", marginTop: '10px' }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article51;