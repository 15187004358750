import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article30/01.jpg";
import photo02 from "../../images/mag/article30/02.jpg";
import photo03 from "../../images/mag/article30/03.jpg";
import photo04 from "../../images/mag/article30/04.jpg";
import photo05 from "../../images/mag/article30/05.jpg";
import photo06 from "../../images/mag/article30/06.jpg";
import photo07 from "../../images/mag/article30/07.jpg";
import photo08 from "../../images/mag/article30/08.jpg";
import photo09 from "../../images/mag/article30/09.jpg";
import photo10 from "../../images/mag/article30/10.jpg";
import photo11 from "../../images/mag/article30/11.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article30 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "اگر برای صرف یک غذای خوشمزه دنبال یک رستوران عالی هستید ما دراین مقاله از هومینجا شما را با بهترین رستوران های منطقه 1 آشنا می کنیم.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی رستوران های منطقه یک | لیست بهترین رستوران های شمال تهران | رستوران های شیک منطقه یک تهران | رستوران گردی منطقه ۱ تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[29].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[29].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[29].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[29].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[29].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[29].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="رستوران منطقه 1 - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[29].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با اطمینان  می توان گفت، غذاهای ایرانی جزء خوشمزه ترین و لذیذترین غذاهای دنیاست. شاید تمایل داشته باشید برای صرف یک غذای خوشمزه به رستوران بروید. بنابراین دنبال یک رستوران شیک با کیفیت غذای عالی هستید. ما در این مقاله از هومینجا شما را با ۱۰ مورد از بهترین رستوران های منطقه 1 تهران آشنا می کنیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                ۱ ) رستوران باغ گیلاس
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="باغ گیلاس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران باغ گیلاس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر به دنبال یک رستوران شیک برای صرف غذای خوشمزه هستین، پیشنهاد ما به شما رستوران باغ گیلاس می باشد.
                کیفیت خوب غذای این رستوران و فضای دل انگیز آن باعث شده باغ گیلاس مورد توجه افراد زیادی واقع شود. این رستوران چند طبقه را می توان از بهترین رستوران های منطقه 1 تهران در محله های زعفرانیه و ولنجک دانست. غذا در این رستوران هم در فضای سربسته و هم محوطه سرباز سرو می شود.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: ولنجک، بلوار دانشجو، روبروی خوابگاه متاهلین</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/p/8hwExjE9-T/'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Grid className="box">
                <Link className="link" to="/tehran-winter-entertaiments" target="_blank">
                  بیشتر بخوانید : تفریحات زمستانی تهران

                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۲ ) رستوران اس پی یو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="رستوران اس پی یو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران SPU
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر به درکه رفتین حتما سری هم به رستوران اس پی یو بزنید. این رستوران را می توان از بهترین رستوران های منطقه 1 دانست. پیش غذاهای خوشمزه، دسرهای رنگارنگ و جذاب و کباب های خوش طعم، محیط دلباز، منظره چشم نواز و طبیعت بکر همگی از دلایلی هستند که باعث محبوبیت این رستوران شده اند. کباب برگ و شیشلیک از معروف‌ترین غذاهای این رستوران می باشد. آلاچیق‌های زیبا و محیط باصفای رستوران لذت خوردن غذا را دوچندان می کند.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: درکه، میدان درکه</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/spu.restaurant.darakeh/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : کافه های شمال تهران

                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۳ ) رستوران شاندیز
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="شاندیز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران شاندیز
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاندیز جردن یک رستوران بین‌المللی است که میزبان تعداد زیادی از مهمانان داخلی و خارجی می باشد. معماری و نمای‌داخلی منحصربه‌فرد این رستوران، باعث جذابیت این رستوران برای بسیاری از گردشگران خارجی می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این رستوران از بهترین رستوران‌هایی است که انواع کباب‌ها، ماهیچه، کتف و گردن را با طعمی متفاوت سرو می کند. پیشنهاد می کنیم که حتما شیشلیک شاندیز را امتحان کنید.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: جردن، بلوار صبا</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/shandiz.restaurants/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Typography className="h2" variant="h2">
                ۴ ) رستوران مس توران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="رستوران مستوران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مستوران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رستوران مستوران که به مس توران (به معنای سرزمین مس) نیز معروف است در سال ۱۳۹۴ در نیاوران تأسیس شد. فضای داخلی رستوران مستوران به سبک معماری دوران صفوی طراحی شده است.
                در ورودی چوبی و کف سیمانی رستوران از کاشی‌های اصیل ایرانی پوشانده شده است. ظروف مسی به سبک قدیمی در همه جای رستوران قرار دارد. رستوران مستوران را به تمام افرادی که عاشق فضای سنتی و قدیمی هستند پیشنهاد می دهیم.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: میدان نیاوران به سمت تجریش، جنب مهمان سرای وزارت امور خارجه</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/mestooran_restaurant/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Typography className="h2" variant="h2">
                ۵ ) رستوران حس توران منطقه 1
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="حس توران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  حس توران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رستوران حس توران یکی از بهترین رستوران‌های تهران در فود کورت خیابان فرشته می باشد. طراحی فضای داخلی این رستوران بسیار هنرمندانه می باشد و یادآر دوران پادشاهی کوروش کبیر می باشد. فضا، سرویس‌دهی و کیفیت غذا، همگی سبب شده که این رستوران جزء رستوران های درجه یک و لوکس تهران قرار گیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کباب‌های مخصوص این رستوران روی شمشیر‌های تزئینی سرو می‌شوند.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: خیابان فرشته، نبش خیابان نیلوفر و مریم غربی، طبقه دوم ساختمان رویال</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/hestooran_restaurant/'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Typography className="h2" variant="h2">
                ۶ ) رستوران اصیل
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="رستوران اصیل - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران اصیل
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فضای داخلی رستوران بسیار با اصالت و بر اساس قواعد مینیمالیسم طراحی شده است. پرسنل این رستوران با لباس فرم مخصوص به خود پذیرای مهمانان این مجموعه هستند. همچنین غذا در رستوران اصیل، در ظروف مسی سرو می شود.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: قیطریه، پایین‌تر از پارک قیطریه، جنب بانک پارسیان</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/asil_restaurant/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Typography className="h2" variant="h2">
                ۷ ) رستوران تراس
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="رستوران تراس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تراس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                رستوران تراس جز یکی از لوکس ترین رستوران های منطقه 1 تهران می باشد. این رستوران باتوجه به تراس تابستانی نامگذاری شده است. در این رستوران انواع غذاهای فرنگی و گریل با قیمت نسبتا بالایی سرو میشود. این رستوران شعب زیادی دارد اما تابلو ندارد. فضای داخلی و تراس این رستوران و سبک موسیقی پخش شده در آن یادآور رستوران های انگلیسی می باشد.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: خیابان فرشته، مریم شرقی، بعد از گل فروشی الهیه، پلاک ۶۱</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/terrace_tehran/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Typography className="h2" variant="h2">
                ۸  ) رستوران کوهپایه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="رستوران کوهپایه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران کوهپایه تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کوهپایه از رستوران های بزرگ دربند می باشد. محیط این رستوران دارای دوبخش فضاي بسته و باز است. فضای سرپوشیده با دکوراسیونی شیک و مدرن طراحی شده است و فضای باز رستوران مملو از درختان و گل های زیبا و رودخانه می باشد. محيط هر دو فضا بسیار وسیع و زیبا است . این رستوران به صورت طبقه‌ای بر روی کوه قرار دارد. تمامی میزها از امکانات گرمایشی و سرمایشی مناسبي براي تمام فصول برخوردار هستند.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: شميران، دربند،بالاتر از ميدان سربند، ميدان كوهپايه</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/koohpayerestaurant/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Typography className="h2" variant="h2">
                ۹  ) رستوران ناکایا تهران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="رستوران ناکایا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران ناکایا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از بهترین رستوران های منطقه 1 می توان به رستوران ناکایا اشاره کرد. سرآشپز این مجموعه اندی چانگ است که روش های نوینی را در آشپزی دارد. رستوران ناکایا علاوه بر غذاهای لذیذش دکوراسیونی فوق العاده جذاب و گیرا نیز دارد. این رستوران را میتوان رقیب سر سخت سوشی خونه شهرک غرب در نظر داشت.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: بلوار اندرزگو، مجتمع تجاری سانا، طبقه دوم</Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/nakaya.sushi/?hl=en'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : کافه کتاب

                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۱۰  ) رستوران عمو هوشنگ
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="رستوران عمو هوشنگ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  عمو هوشنگ نیاوران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این رستوران در سال 1301 تاسیس شده است. رستورانی در نیاوران که چایش را در استکان و  نعلبکی سرو می کند. مغازه ای با سقفی بلند و دیوارهایی پوشیده از کاشی که مهمترین جاذبه آن عمو هوشنگ صاحب رستوران می باشد. پیرمردی با موهای سفیدش و چهره مهربانی که دارد میزبان مهمان های این رستوران می باشد. قیمه های این رستوران از دیزی هایش خوشمزه تر است و خانم ها نیز از ظهر به بعد می توانند برای صرف غذا همراه آقایون به این رستوران مراجعه کنند.
              </Typography>
              <Grid sx={{ display: 'flex', width: '90%' }}>
                <Typography component={'li'}></Typography>
                <Typography component={'p'} sx={{ textAlign: 'justify', fontFamily: 'shabnam !important' }}>آدرس: نیاوران، نرسیده به میدان باهنر، کوچه عبدالهی </Typography>
              </Grid>
              <Grid sx={{ display: 'flex', width: '90%', marginBottom: '10px' }}>
                <Typography component={'li'} sx={{ textAlign: 'center', fontFamily: 'shabnam !important' }}>نشانی اینترنتی:</Typography>
                <Typography component={'a'} href={'https://www.instagram.com/amoo.hoshang/'} target="_blank" sx={{ marginRight: '1%', fontFamily: 'shabnam !important' }}>اینستاگرام</Typography>
              </Grid>

              <Typography className="h2" variant="h2">جمع بندی:</Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا درتلاش است تا همواره اطلاعات مناسبی در اختیار کاربران قرار دهد. با دنبال کردن وب سایت و هم چنین بلاگ هومینجا از اخرین اخبار و مطالب جذاب ما مطلع شوید. ما در این مقاله سعی کردیم شما را با بهترین رستوران های منطقه 1 تهران بیشتر آشنا کنیم.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article30;