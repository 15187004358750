import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article40/01.jpg";
import photo02 from "../../images/mag/article40/02.jpg";
import photo03 from "../../images/mag/article40/03.jpg";
import photo04 from "../../images/mag/article40/04.jpg";
import photo05 from "../../images/mag/article40/05.jpg";
import photo06 from "../../images/mag/article40/06.jpg";
import photo07 from "../../images/mag/article40/07.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article40 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "این روزها آگاهی از افزایش و یا کاهش قیمت در بخش مسکن اهمیت زیادی پیدا کرده و پیش‌بینی آینده این بازار در سال ۱۴۰۲ برای عده زیادی مهم است.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "  تاثیر رشد ارز بر قیمت مسکن ، بررسی بازار مسکن در سال ۱۴۰۰ و ۱۴۰۱ در تهران ، بررسی دلایل افزایش قیمت مسکن در سال اخیر ، چشم انداز قیمت مسکن در سال ۱۴۰۲ "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[39].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[39].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[39].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[39].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[39].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[39].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="هومینجا - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[39].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله با بررسی رشد قیمت ملک در سالهای ۱۴۰۰ و ۱۴۰۱ و هم چنین تاثیر تورم و افزایش نرخ ارز، به پیش بینی بازار مسکن در سال ۱۴۰۲ پرداختیم. مطالعه این مقاله از سایت هومینجا به تصمیم گیری در مورد بهترین نوع <LinkMui className="link" href="https://donya-e-eqtesad.com/%D8%A8%D8%AE%D8%B4-%D9%88%D8%A8-%DA%AF%D8%B1%D8%AF%DB%8C-96/3758638-%D8%B3%D8%B1%D9%85%D8%A7%DB%8C%D9%87-%DA%AF%D8%B0%D8%A7%D8%B1%DB%8C-%D8%AF%D8%B1-%D8%A7%D8%B1%D8%B2%D9%87%D8%A7%DB%8C-%D8%AF%DB%8C%D8%AC%DB%8C%D8%AA%D8%A7%D9%84" target="_blank">  سرمایه گذاری </LinkMui> در سال ۱۴۰۲ کمک شایانی خواهد کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="مسکن 1402 - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیش بینی بازار مسکن 1402
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همان‌طور که می‌دانید بازار مسکن یکی از داغ‌ترین و در عین حال چالش‌ برانگیزترین بازارها به شمار می‌رود. عده زیادی از افراد در جامعه کنونی تمایل به خرید مسکن دارند. عده‌ای هم می‌خواهند با <Link className="link" to="/Listings/buy_tehran" target="_blank"> خرید ملک </Link> در این بازار به سرمایه‌گذاری دست بزنند. به همین دلیل بیشتر افراد با این بازار به نحوی سروکار دارند و تغییر و تحولات آن را پیگیری می‌کنند. این روزها آگاهی از افزایش و یا کاهش قیمت در بخش مسکن اهمیت بسیار بالایی پیدا کرده و پیش‌بینی آینده بازار مسکن در سال ۱۴۰۲ برای عده زیادی اهمیت دارد. در این مقاله قصد داریم بازار مسکن در سال ۱۴۰۲ را پیش بینی کرده و افزایش و یا کاهش قیمت آن را بررسی کنیم. پس اگر شما هم به دنبال <Link className="link" to="/buying-house-in-Tehran" target="_blank"> خرید خانه </Link> هستید و یا قصد سرمایه‌گذاری در این بازار را دارید با ما همراه باشید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/mehr-house" target="_blank">
                  بیشتر بخوانید : همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                بررسی بازار مسکن در سال ۱۴۰۰ و ۱۴۰۱ در تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیش از اینکه بازار مسکن در سال ۱۴۰۲ را پیش‌بینی و بررسی کنیم لازم است به بررسی کاهش و یا افزایش قیمت مسکن در دو سال اخیر یعنی سال‌های ۱۴۰۰ و ۱۴۰۱ در تهران بپردازیم. طی این دو سال مجموعه دلایلی از قبیل تورم و افزایش قیمت دلار باعث شد بازار مسکن در شرایط مناسبی قرار نگیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به طور کلی و همان‌طور که می‌دانید اقتصاد کنونی کشور ایران با چالش‌های بسیار زیادی دست و پنجه نرم می‌کند. وجود تورم و گرانی در بخش‌های مختلف باعث شده تا وضعیت بازارهای مالی از جمله بخش مسکن نیز چندان مساعد نباشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطابق با آمارهای منتشر شده از <LinkMui className="link" href="https://www.amar.org.ir/statistical-information" target="_blank">  مرکز آمار ایران </LinkMui> از شاخص قیمت، چنین بر می‌آید که تورم نقطه‌ای در کشور در سال ۱۴۰۱ نسبت به سال قبل در دی ماه، به عدد ۵۱.۳ درصد رسیده است. این موضوع سبب شده تا قیمت مسکن نیز در سال ۱۴۰۱ نسبت به سال ۱۴۰۰ در همین ماه، ۳۹.۵ درصد رشد را تجربه کرده باشد.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="قیمت ملک 1402 - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بررسی قیمت ملک در 1402
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاهش ارزش پول ملی و از سوی دیگر افزایش نرخ دلار نیز در این تغییر قیمت بی‌تاثیر نبوده است. این عوامل را نیز می‌توان از جمله دلایل افزایش قیمت مسکن طی دو سال اخیر به شمار آورد. بررسی تاثیر افزایش قیمت دلار بر  قیمت مسکن نکته بسیار مهمی است. به گونه‌ای که تغییر نرخ ارز باعث شده تا قیمت بسیاری از مصالح ساختمانی هم روندی رو به افزایش را تجربه کنند .
              </Typography>
              <Typography className="h2" variant="h2">
                تاثیر نوسانات بازار ارز و سرمایه در ورود سرمایه گذاران به بازار ملک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطابق با نظر کارشناسان برتر ملکی کشور و متخصصان هومینجا، در حال حاضر دو شکل و یا دو نوع تقاضا در بازار مسکن به چشم می‌خورد. یک نمونه از این تقاضا به تقاضاهای مصرفی ارتباط پیدا می‌کند. شرایط کنونی به نحوی است که تقاضاهای مصرفی به دلیل قیمت‌های بسیار بالا با کاهش بسیار شدیدی مواجه شوند.دیگری تقاضای سرمایه گذاری است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در شرایط فعلی مصرف کنندگان واقعی، توان ورود به این بازار را ندارند. بخش دیگر تقاضاهای بازار ملک و مسکن، به تقاضاهای سرمایه‌ای مربوط می‌شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این بازار بنا به دلایلی از قبیل تورم و یا گرانی و یا اطمینان نداشتن به بازارهای موازی شکل گرفت. تقاضاهایی که در حالت معمول کاذب هستند. اما در هر صورت وجود دارند و وجود آن‌ها را نمی‌توان نادیده گرفت. از سوی دیگر عرضه کافی برای پاسخگویی به این حجم تقاضا وجود ندارد و لذا به افزایش قیمت در بازار مسکن منجر می‌گردد .
              </Typography>
              <Typography className="h2" variant="h2">
                بیشترین رشد قیمت مسکن در تهران در کدام منطقه است؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="افزایش قیمت مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رشد قیمت مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وضعیت بحرانی حاکم بر بازار ملک و مسکن باعث شد تا برخی از مناطق در کلانشهر <Link className="link" to="/tehran-zones" target="_blank"> تهران </Link> بیشترین نرخ و رشد قیمت مسکن را تجربه کنند. در <Link className="link" to="/tehran-zone-20" target="_blank"> مناطق جنوبی </Link> این شهر پیشتر امکان خانه دار شدن برای افرادی که درآمدها و یا بودجه کمتری دارند وجود داشت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالی که این روزها این اقدام نیز بسیار دشوار شده است. به گونه‌ای که درصد افزایش قیمت مسکن در طول یک سال اخیر در پر معامله‌ترین مناطق جنوبی این شهر به خصوص <Link className="link" to="/tehran-zone-19" target="_blank"> منطقه 19 </Link> به چیزی در حدود ۶۰ درصد رسید. نمی‌توان تاثیر تقاضا بر افزایش قیمت املاک و مسکن در این منطقه را نادیده گرفت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به گونه‌ای که بسیاری از شهروندان پایتخت نشین که نمی‌توانند در نقاط دیگر شهر به خرید خانه اقدام کنند، به سمت <Link className="link" to="/tehran-zone-7" target="_blank"> مناطق مرکزی </Link> و جنوبی تهران روی آورده‌اند. پس تقاضای بسیار بالایی برای خرید ملک در این مناطق شکل گرفت و سبب شد تا قیمت مسکن در این مناطق با افزایش قابل توجهی مواجه شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالی که متوسط رشد قیمت مسکن در تهران در سال ۱۴۰۰، ۳۸ درصد گزارش شد. به این ترتیب می‌توان گفت روند افزایش قیمت مسکن در این مناطق در سال آینده هم همچنان ادامه پیدا می‌کند .
              </Typography>
              <Typography className="h2" variant="h2">
                دلایل افزایش قیمت مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهمترین دلایل افزایش قیمت مسکن، اقتصاد کلان است. هما­ن­طور که می­دانید بخش مسکن یکی از مهمترین بخش­های اقتصادی در جامعه محسوب می­شود. این بخش پیوندهای بسیار عمیق و تنگاتنگی با اقتصاد کلان کشور دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همه ساله چیزی در حدود ۲۰ تا ۳۰ درصد از سرمایه ثابت کشور به این بخش اختصاص پیدا می‌کند. به طور متوسط نیز به همین نسبت از نقدینگی کل کشور صرف این بازار می­گردد. به این ترتیب این بازار رابطه­ای بسیار قدرتمند، تنگاتنگ و گسترده با دیگر بخش‌ها و بازارهای اقتصادی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از تحولات این بخش­ها هم متاثر خواهد شد و یا بر این قسمت­ها اثراتی به وجود خواهد آورد. از سوی دیگر ویژگی‌های ساختاری بخش مسکن را نمی­توان نادیده گرفت. بنابراین و برای اینکه بتوانید تحلیل درستی از بازار مسکن داشته باشید، باید به سه ویژگی اصلی آن توجه کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله این ویژگی­ها می­توان به رشد پلکانی قیمت مسکن اشاره کرد و یا وجود نوسانات دوره­ای شدید در تولید و ساخت مسکن را نام برد. این بخش سهم بسیار بالایی را در سبد هزینه خانوارها به خود اختصاص می­دهد. پس می­توان گفت قیمت مسکن از یک روند فزاینده و خطی پیروی نمی­کند. بلکه منحنی رشد آن به شکل پلکانی است و حول محوری به نام تورم جریان و نوسان دارد. لذا مهمترین دلیل افزایش قیمت مسکن، تورم و یا گرانی است .
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاثیر افزایش جمعیت بر افزایش قیمت مسکن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهم‌ترین چالش‌های پیش روی دست اندرکاران امور اجرایی در کشورهای مختلف، تحولاتی است که در جمعیت صورت می‌پذیرد. در حال حاضر یکی از مهم‌ترین روش‌ها برای ارزیابی درجه توسعه یافتگی کشورها و مناطق مختلف، بررسی ساختار سنی و جنسی جمعیت و مطالعه این فاکتور محسوب می‌شود. پس رشد جمعیت اهمیت بسیار بالایی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از سوی دیگر نوسانات قیمت در بخش مسکن هم از مهمترین چالش‌های اقتصادی کشورها محسوب می‌شود. نکته‌ای که باید به آن اشاره شود این است که قیمت مسکن و افزایش و یا کاهش آن، به شیوه‌ای معنادار و مثبت تحت تاثیر هزینه‌های واقعی یک متر بنا قرار خواهد گرفت. رشد جمعیت شهری نیز در قیمت ملک و مسکن اثرگذار است. به طور کلی و با توجه به نتایج پژوهش‌هایی که در این زمینه صورت پذیرفت می‌توان گفت عواملی از قبیل نرخ شهرنشینی و میزان جمعیت ساکن در شهرها، نرخ اجاره بها، نرخ بیکاری، شاخص قیمت مصالح ساختمانی و ... همه از جمله عوامل و موارد تاثیر گذار بر افزایش قیمت مسکن هستند.
              </Typography>
              <Typography className="h2" variant="h2">
                تاثیر افزایش قیمت دلار بر قیمت مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="دلار و قیمت مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاثیر دلار بر افزایش قیمت مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از باورهای بسیار مهم در میان کارشناسان و صاحبنظران اقتصادی و مردم این است که قیمت دلار با قیمت مسکن ارتباط بسیار نزدیکی دارد. به گونه‌ای که با افزایش نرخ دلار شاهد افزایش قیمت در بخش مسکن نیز خواهیم بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طبق نظر برخی از کارشناسان این حوزه اولین بازاری که تحت تاثیر قیمت دلار قرار گرفت و به افزایش قیمت مبتلا می‌گردد بازارهای کالاهایی از قبیل مواد اولیه، طلا، سکه، خودرو و سپس کالاهای واقعی هستند. بازار دومی که تحت تاثیر افزایش نرخ دلار قرار می‌گیرد بازار سهام است. بعد از آن نیز <Link className="link" to="/Listings" target="_blank"> بازار املاک و مستغلات </Link> واقع می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بنابراین رشد قیمت دلار به طور کاملا مستقیم بازار مسکن و املاک را متوجه خود نمی‌سازد. به طور کلی می‌توان گفت مسکن به طور ذاتی با تورم و گرانی همراه و همگام می‌شود. لذا شاخص تورم نسبت به دیگر انواع شاخص‌ها بر قیمت مسکن تاثیر گذارتر خواهد بود. با این وجود افزایش نرخ ارز نیز نوعی جو روانی در جامعه به وجود می‌آورد. در این شرایط افراد و سازمان‌هایی که با بازار ملک سر و کار دارند، ملک خود را با قیمتی بالاتر از قیمت مصوب اعلام خواهند کرد. این روند نیز به افزایش قیمت مسکن منجر خواهد شد.
              </Typography>
              <Typography className="h2" variant="h2">
                تاثیر آژانس‌ها و سایت‌های املاک بر افزایش یا کاهش قیمت مسکن
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="سایت جستجوی ملک هومینجا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایت املاک و مستغلات هومینجا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                طبق گزارشی که <LinkMui className="link" href="https://rc.majlis.ir/fa" target="_blank">  مرکز پژوهش های مجلس </LinkMui> به انتشار نتایج آن دست زد، قیمت مسکن در کشور تحت تاثیر چند عامل مهم، افزایش پیدا کرده است. این افزایش قیمت در بازار مسکن را می‌توان از سال ۱۳۹۷ به بعد به صورت جهشی مشاهده کرد.              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست؟
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از نظر پژوهشگران این مجموعه، مهمترین علت جهش قیمت مسکن در کشور ایران تبدیل شدن مسکن از یک کالای مصرفی به یک نوع کالای سرمایه‌ای است. به طور کلی عوامل و مواردی از قبیل افزایش نرخ ارز و یا تورم ناشی از افزایش قیمت تمام شده ساخت، می‌تواند بر سرمایه‌اس شدن این کالا نقش و سهم موثر داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                علاوه بر عوامل ذکر شده، قیمت مسکن در حال حاضر تحت تاثیر میزان تعرفه فعالیت و خدمات ارائه شده توسط <Link className="link" to="/real-estate" target="_blank"> مشاوران املاک </Link> نیز است. همان‌طور که می‌دانید در حال حاضر آژانس‌های املاک بسیار زیادی در سراسر کشور مشغول به فعالیت هستند. <Link className="link" to="/AboutUs" target="_blank"> سایت های املاک </Link> زیادی نیز راه اندازی شده‌اند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فعالیت و هدف اصلی این بنگاه‌ها مشاوره امور ملکی به متقاضیان خرید، فروش و اجاره ملک است. این احتمال وجود دارد که نرخ و تعرفه تعیین شده خدمات توسط مشاوران املاک دلیل اصلی افزایش قیمت مسکن محسوب نشود.
              </Typography>
              <Typography className="h3" variant="h3">
                عوامل تاثیرگذار دیگر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اما با این حال تعیین نرخ دلالی توسط این مجموعه‌ها در این زمینه اثرگذار خواهد بود. در حقیقت به نفع یک بنگاه و یا آژانس و سایت املاک است که معامله ملک با قیمت بالاتری صورت پذیرد. به همین دلیل کاهش نرخ خدمات ارائه شده توسط این مشاوران می‌تواند قیمت مسکن را هم با کاهش مواجه سازد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با این وجود نتایج این پژوهش‌ها نشان می‌دهد که حتی با کاهش ۵۰ درصدی تعرفه خدمات مشاوران املاک و یا وبسایت‌های ملکی، مشکل افزایش قیمت مسکن به صورت اساسی و جدی حل و فصل نمی‌شود. بسیاری از کارشناسان و صاحبنظران اقتصادی بر این باورند که مشکل اصلی تعرفه خدمات ارائه شده توسط سایت‌ها و آژانس‌های املاک، موضوع تعارض منافع قیمت ملک با تعرفه اعلام شده توسط این مجموعه‌ها است.  در واقع و از آنجایی که با افزایش قیمت ملک درآمد و سود بیشتری هم نصیب آژانس‌ها و بنگاه‌های املاک خواهد شد، پس این مجموعه‌ها سعی و تلاش خود را به کار می‌گیرند تا ملک با قیمت بالاتری معامله شود. این کار نیز خود به خود به افزایش قیمت مسکن منجر خواهد شد .
              </Typography>
              <Typography className="h2" variant="h2">
                پیش بینی و چشم انداز قیمت و بازار مسکن در سال ۱۴۰۲
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="پیش بینی مسکن 1402 - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پیش بینی بازار مسکن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به شرایط اقتصادی که در کشور حاکم است نمی‌توان آینده مناسب و مطلوبی را برای بازار مسکن پیش بینی کرد. اگر افزایش تورم همچنان ادامه داشته باشد قیمت مسکن هم به تبع آن افزایش بیشتری را تجربه خواهد کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مطابق با آماری که از <LinkMui className="link" href="https://www.cbi.ir/" target="_blank">  بانک مرکزی </LinkMui> منتشر شد در سال ۱۴۰۱ و در ماه آذر، میانگین قیمت هر متر خانه در تهران به حدود ۱۰۰ میلیون تومان رسید. به نظر می‌رسد بهترین اقدام برای حل مشکلات موجود در بخش مسکن و بازار ملک، کنترل تورم و بازارهای موازی باشد. نظر کارشناسان برتر ملکی این است که قیمت مسکن در سال ۱۴۰۲ چیزی در حدود ۱۴ درصد افزایش پیدا کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای بررسی بیشتر این موضوع بهتر است به سال ۱۴۰۰ بازگردیم و تورم مسکن در آن سال را بررسی کنیم. اتفاقی ویژه در  بخش مسکن در سال ۱۴۰۰ رخ داد که صاحب نظران به این رویداد پسا جهش نام دادند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/building-certificate" target="_blank">
                  بیشتر بخوانید : همه چیز راجع به صدور پروانه ساختمان
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در پی این حادثه قیمت مسکن افزایش بسیار زیادی را تجربه کرد. در سال بعد این روند با کاهش مواجه شد. بسیاری از کارشناسان اقتصادی به ویژه متخصصان و فعالان در حوزه مسکن بر این باورند که در سال ۱۴۰۲ شاهد یک نوع وضعیت میانه خواهیم بود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این موضوع به این معنا است که قیمت مسکن در سال ۱۴۰۲ با شیبی کمتر نسبت به سال ۱۴۰۰ و البته بیشتر از سال ۱۴۰۱ افزایش پیدا خواهد کرد. متوسط رشد پیش بینی شده توسط این دسته از متخصصین نیز چیزی در حدود ۱۴ درصد است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نتایج این پیش بینی‌ها نشان می‌دهد که سرعت رشد قیمت مسکن در سال آینده و در سه ماهه ابتدایی آن در کلانشهر تهران، نسبتاً پایین خواهد بود و احتمال افزایش قیمت شدیدتر آن از تابستان ۱۴۰۲ وجود دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link> سعی داریم تا به تمام موضوعات و سرفصل های حوزه املاک بپردازیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس با ما همراه شوید تا علاوه بر خدمات منحصربه فرد سایت املاک هومینجا، اطلاعات شما را ارتقاء ببخشیم.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش خانه آپارتمان ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره خانه آپارتمان ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article40;