import { Autocomplete, Button, Grid, IconButton, Input, OutlinedInput, Typography ,Checkbox , TextField} from "@mui/material";
import React from "react";
import { baseUrl, convertToEnglishNumber, convertToPersianNumber ,  icon , checkedIcon } from "./materilas/vmp";
import axios from "axios";
import Avatar from '../images/avatar1.png';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AlertModal from "./materilas/alert";
import Resizer from "react-image-file-resizer";
import GiftLogo from '../component/gifLogo';

class EditUserInfo extends React.Component{

  isMount = false;
  imageFile = '';
  imageResize = '';
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        userPicture:null,
        user:JSON.parse(localStorage.getItem('infoUserHomeInja')),
        name:'',
        lastname:'',
        father:'',
        meli:'',
        birth:'',
        insta:'',
        openAlert:false,
        message:'',
        giftShow:false,
        typeAlert:3,
        agencyOptions:[],
        agencySelected:null,
      }
  }

  componentDidMount(){
    document.title = "ویرایش اطلاعات در سامانه جستجو املاک هومینجا";
    this.isMount = true;
    if (this.isMount){
      if (this.state.user !== null){
        this.setState({userPicture:this.state.user.picture_path,name:this.state.user.name !== null ? this.state.user.name : '',
                        lastname:this.state.user.lastname !== null ? this.state.user.lastname : '',
                        father:this.state.user.fathername !== null ? this.state.user.fathername : '',
                        meli:this.state.user.meli_number !== null ? this.state.user.meli_number : '',
                        birth:this.state.user.date_of_birth !== null ? this.state.user.date_of_birth : '',
                        insta:this.state.user.instagram_link !== null ? this.state.user.instagram_link : '',
                        agencySelected:this.state.user.agency !== null ? this.state.user.agency : null 
                      })
      }
      else{
        this.setState({userPicture:null,name:'',lastname:'',father:'',meli:'',birth:'',insta:''})
      }
      if(this.state.user.type.id === 3){
        this.getAllAgency();
      }
    }
  }

  componentWillUnmount(){
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  onChangeAddImage = (e) => {
    if(e.target.files[0]){
      this.imageFile = e.target.files[0] ;
      this.imageResizer()
    }
  }

  imageResizer = () => {
      try {
        Resizer.imageFileResizer(
          this.imageFile,
          200,
          200,
          "JPEG",
          50,
          0,
          (uri) => {
            this.imageResize = uri ;
            this.addImageUser();
          },
          "file",
        );
      } catch (err) {
        this.setState({openAlert:true,message:"فایل موردنظر عکس نمیباشد",typeAlert:2});
      }
  }

  addImageUser = () => {
    const formData = new FormData();
    formData.append("image", this.imageResize);
    formData.append("user_id", this.state.user.id);
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/user/addImage",
      method: "POST",
      headers: {
        "Authorization":"bearer "+ this.token,
        "Content-Type": "multipart/form-data"
      },
      data: formData
    }))
    .then((res) => {
      this.setState({openAlert:true,message:'عکس کاربری با موفقیت ذخیره شد',giftShow:false,typeAlert:1})
      this.infoUser();
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"عکس بارگزاری نشد",giftShow:false,typeAlert:2});
    });
  } 

  infoUser = () =>{
    Promise.resolve(axios({
      method:'GET',
      url:baseUrl + 'v1/user/info',
      headers:{'Authorization':'bearer ' + this.token},
    }))
    .then((res) =>{
      localStorage.setItem('infoUserHomeInja',JSON.stringify(res.data.data));
      this.setState({user:res.data.data,userPicture:res.data.data.picture_path})
    })
    .catch(() =>{
      this.setState({user:JSON.parse(localStorage.getItem('infoUserHomeInja'))})
    })
  }

  onChangeName = (event) =>{
    this.setState({name:event.target.value});
  }

  onChangeLastname = (event) =>{
    this.setState({lastname:event.target.value});
  }

  onChangeFather = (event) =>{
    this.setState({father:event.target.value});
  }

  onChangeMeli = (event) =>{
    this.setState({meli:convertToEnglishNumber(convertToPersianNumber(event.target.value))});
  }

  onChangeBirth = (event) =>{
    let input = convertToEnglishNumber(convertToPersianNumber(event.target.value));
    input = input.replaceAll("/","");
    let birthDate = input.split("");
    if(birthDate.length > 4){
      birthDate.splice(4,0,"/");
    }
    if(birthDate.length > 7){
      birthDate.splice(7,0,"/");
    }
    birthDate = birthDate.toString();
    birthDate = birthDate.replaceAll(",","");
    this.setState({birth:convertToEnglishNumber(convertToPersianNumber(birthDate))});
  }

  onChangeInsta = (event) =>{
    this.setState({insta:event.target.value});
  }

  onClickSave = () =>{
    if (this.state.name.length === 0){
      this.setState({openAlert:true,message:'لطفا اسم خود را وارد نمایید.',name:'',typeAlert:3});
    }
    else{
      if(/[0-9۰۱۲۳۴۵۶۷۸۹]/.test(this.state.name)){
        this.setState({openAlert:true,message:'برای نام خود نمی توانید از اعداد استفاده کنید',name:'',typeAlert:3});
      }
      else{
        if (this.state.lastname.length === 0){
          this.setState({openAlert:true,message:'لطفا نام خانوادگی را وارد نمایید.',lastname:'',typeAlert:3})
        }
        else{
          if(/[0-9۰۱۲۳۴۵۶۷۸۹]/.test(this.state.lastname)){
            this.setState({openAlert:true,message:'برای نام خانوادگی نمی توانید از اعداد استفاده کنید',lastname:'',typeAlert:3})
          }
          else{
            if (this.state.father.length === 0){
              this.setState({openAlert:true,message:'لطفا نام پدر را وارد نمایید.',father:'',typeAlert:3})
            }
            else{
              if(/[0-9۰۱۲۳۴۵۶۷۸۹]/.test(this.state.father)){
                this.setState({openAlert:true,message:'برای نام پدر نمی توانید از اعداد استفاده کنید',lastname:'',typeAlert:3})
              }
              else{
                let meli = /^\d{10}$/;
                if (!this.state.meli.match(meli)){
                  this.setState({openAlert:true,message:'شماره ملی صحیح نیست.',meli:'',typeAlert:3})
                }
                else{
                  let valueSplit = this.state.birth.split('/');
                  if (this.state.birth.length !== 10 || valueSplit.length !== 3 || valueSplit[0].length !== 4 || valueSplit[1] > 12 || valueSplit[3] > 31){
                    this.setState({openAlert:true,message:' فرمت تاریخ تولد صحیح نیست برای مثال : ۱۳۶۶/۰۱/۰۱',birth:'',typeAlert:3})
                  }
                  else{
                    this.setState({giftShow:true});
                    this.editUser();
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  editUser = () =>{

    Promise.resolve(axios({
      method:'POST',
      url:baseUrl + 'v1/user/edit',
      headers:{'Authorization':'bearer ' + this.token},
      data:{
        name:this.state.name,
        lastname:this.state.lastname,
        fathername:this.state.father,
        meli_number:this.state.meli,
        date_of_birth:this.state.birth,
        instagram_link:this.state.insta !== '' ? this.state.insta : null,
        user_id:this.state.user.id,
        agency_id:this.state.agencySelected ? this.state.agencySelected.id : null
      }
    }))
    .then(() =>{
      this.setState({openAlert:true,message:'اطلاعات کاربری با موفقیت ذخیره شد.',giftShow:false,typeAlert:1});
      this.infoUser();
    })
    .catch(() =>{
      this.setState({openAlert:true,message:'عملیات با مشکل مواجه شد.',giftShow:false,typeAlert:2})
    })
  }

  onChangeAgency = (event,newValue) => {
    this.setState({agencySelected:newValue});
  }

  getAllAgency = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/agancy/all",
      method: "GET",
    }))
    .then((res) => {
      this.setState({agencyOptions:res.data.data});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
    })
  }

  render() {
    return (
    <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
      <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
        <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>ویرایش اطلاعات</Typography>
      </Grid>
      <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
            sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
      >    
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginBottom:10}}>
          <Grid style={{borderStyle:'solid',borderRadius:'100%',borderColor:'#00C8E9',borderWidth:'10px',
                              display:'flex',alignItems:'center',justifyContent:'center'}}
                      sx={{width:{sm:'200px',xs:'120px'},height:{sm:'200px',xs:'120px'}}}
          >
            {
              this.state.userPicture !== null ?
                <Typography 
                    style={{width:'100%',height:'100%',borderRadius:'100%',backgroundImage:`url(${this.state.userPicture})`,
                            backgroundRepeat:'no-repeat',backgroundSize:'cover'}}
                ></Typography>
              :
                <Typography component={'img'} src={Avatar} alt="avatar"
                    sx={{width:{sm:'130px',xs:'45px'},height:{sm:'130px',xs:'45px'}}}
                ></Typography>
            }
          </Grid>
        </Grid>   
        <Grid item xs={12} style={{width:'100%',display:'flex',justifyContent:'center'}}>
          <label htmlFor="button-file">
            <Input accept="image/*" id="button-file" type="file" style={{display:'none'}} onChange={this.onChangeAddImage}/>
            <IconButton size="large" component="span">
              <PhotoCamera sx={{width:{sm:'50px',xs:'40px'},height:{sm:'40px',xs:'30px'}}}/>
            </IconButton>
          </label>
        </Grid>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',width:'100%',flexWrap:'wrap',marginTop:50}}>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'نام : '}</Typography>
            <OutlinedInput variant="outlined" autoComplete="name"  
                        onChange={(event) => this.onChangeName(event)} value={this.state.name}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                        inputProps={{maxLength:30}}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'نام خانوادگی : '}</Typography>
            <OutlinedInput variant="outlined" autoComplete="name"  
                        onChange={(event) => this.onChangeLastname(event)} value={this.state.lastname}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                        inputProps={{maxLength:30}}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'نام پدر : '}</Typography>
            <OutlinedInput variant="outlined" autoComplete="name"  
                        onChange={(event) => this.onChangeFather(event)} value={this.state.father}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                        inputProps={{maxLength:30}}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'شماره ملی : '}</Typography>
            <OutlinedInput variant="outlined" autoComplete="name"  
                        onChange={(event) => this.onChangeMeli(event)} value={convertToPersianNumber(this.state.meli)}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                        inputProps={{maxLength:10}}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'تاریخ تولد : '}</Typography>
            <OutlinedInput variant="outlined" autoComplete="name" placeholder="روز/ماه/سال"
                        onChange={(event) => this.onChangeBirth(event)} value={convertToPersianNumber(this.state.birth)}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold'}}
                        inputProps={{maxLength:10}}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={6} xs={12} style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',marginBottom:5}}>
            <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:'70%'}}>{'لینک اینستاگرام : '}</Typography>
            <OutlinedInput variant="outlined" autoComplete="name"  
                        onChange={(event) => this.onChangeInsta(event)} value={this.state.insta}
                        style={{borderStyle:'solid',borderWidth:1,width:'70%',fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',overflow:"hidden"}}
                        inputProps={{maxLength:50}}
            />
          </Grid>
        </Grid>
        { this.state.user.type.id === 3 ?
          <Grid item xs={12} style={{width:'100%',marginTop:5,display:"flex",justifyContent:"center"}}>
            <Grid sx={{display:'flex',flexDirection:'column',width:{xs:"70%",sm:"50%",md:"70%",xl:'40%'}}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',marginRight:'1%',marginBottom:5,width:"100%"}}>{'آژانس: '}</Typography>
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                sx={{border:"1px solid black",borderRadius:"4px"}}
                options={this.state.agencyOptions}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField  {...params} placeholder='انتخاب آژانس'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                onChange={this.onChangeAgency}
                value={this.state.agencySelected}
                clearText="پاک کردن"
                closeText="بستن"
                openText="باز کردن"
                noOptionsText="فاقد اطلاعات"
              />
            </Grid>
          </Grid>
          : <Grid></Grid>
        }
        <Grid item xs={12} style={{width:'100%',display:'flex',justifyContent:'center',marginTop:50}}>
          <Button onClick={this.onClickSave} 
                  style={{backgroundColor:'#00C8E9',borderRadius:8,width:'50%',height:'50px',color:'black',
                          fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',marginBottom:50}}>
            {'ذخیره اطلاعات'}
          </Button>
        </Grid>
        <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    </Grid>
    )
  };
}

export default EditUserInfo;