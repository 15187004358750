import React from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import WhyHominjaVideo from "../videos/Services.mp4";

class WhyHominja extends React.Component {

    constructor(){
        super();
        this.state = {
        }
    }

    componentDidMount() {}

    play = () => {
        this.setState({pauseVideo:false})
    }

    pause = () => {
        this.setState({pauseVideo:true})
    }

    render(){
        return(
            <Box sx={{background:"linear-gradient(269.83deg,rgba(1,199,233,0.52)0.31%,rgba(1,167,196,0.52)100.17%)",padding:{xs:"30px 4%",md:"15px 0 15px 8%"}}}>
                <Grid container>
                    <Grid item xs={12} md={5} sx={{textAlign:{xs:"center",md:"right"},display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"flex-start"}}>
                        <Box sx={{width:{xs:"80%",md:"100%"},margin:{xs:"auto",md:"5px 0"},
                                background:{
                                            xs:"linear-gradient(90deg, rgba(255, 255, 255, 0) -3.07%, rgba(255, 255, 255, 0.665) 51.06%, rgba(255, 255, 255, 0) 108.95%)",
                                            md:"linear-gradient(90deg, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0.7) 126.22%)"
                                            }
                                }}>
                            <Typography sx={{fontSize:{xs:"16px",sm:"18px",md:"23px",lg:"20px"},fontWeight:"800",fontFamily:"shabnam",padding:{xs:"15px 0",md:"10px 12% 10px 0"}}}>
                                چرا هومینجا؟
                            </Typography>
                        </Box>
                        <Grid sx={{padding:{xs:"20px 0",md:"25px 12% 25px 0"},textAlign:"right",margin:{xs:"auto",md:"5px 0"}}}>
                            <Typography 
                                sx={{fontFamily:"shabnam",fontSize:{xs:"11px",sm:"16px",md:"15px",lg:"17px"},margin:{xs:"4px 0",md:"20px 0",lg:"25px 0"},fontWeight:"bold",textAlign:"justify"}}
                            >
                                - همراهی تمامی کاربران در تمام مراحل خرید و اجاره
                            </Typography>
                            <Typography 
                                sx={{fontFamily:"shabnam",fontSize:{xs:"11px",sm:"16px",md:"15px",lg:"17px"},margin:{xs:"4px 0",md:"20px 0",lg:"25px 0"},fontWeight:"bold",textAlign:"justify"}}
                            >
                                - به حداقل رساندن رفت و آمدهای اضافی در فرایند مشاهده و درخواست املاک موردنظر
                            </Typography>
                            <Typography 
                                sx={{fontFamily:"shabnam",fontSize:{xs:"11px",sm:"16px",md:"15px",lg:"17px"},margin:{xs:"4px 0",md:"20px 0",lg:"25px 0"},fontWeight:"bold",textAlign:"justify"}}
                            >
                                - ارایه فایلهای واقعی به مشتریان و مشاوره قیمتی به صاحبان املاک
                            </Typography>
                            <Typography 
                                sx={{fontFamily:"shabnam",fontSize:{xs:"11px",sm:"16px",md:"15px",lg:"17px"},margin:{xs:"4px 0",md:"20px 0",lg:"25px 0"},fontWeight:"bold",textAlign:"justify"}}
                            >
                                - متشکل از تیم حقوقی جهت تایید مراحل قولنامه و تحویل ملک
                            </Typography>
                            <Typography 
                                sx={{fontFamily:"shabnam",fontSize:{xs:"11px",sm:"16px",md:"15px",lg:"17px"},margin:{xs:"4px 0",md:"20px 0",lg:"25px 0"},fontWeight:"bold",textAlign:"justify"}}
                            >
                                - پشتیبانی ۲۴ ساعته جهت راهنمایی کاربران و برطرف کردن مشکلات
                            </Typography>
                            <Typography 
                                sx={{fontFamily:"shabnam",fontSize:{xs:"11px",sm:"16px",md:"15px",lg:"17px"},margin:{xs:"4px 0",md:"20px 0",lg:"25px 0"},fontWeight:"bold",textAlign:"justify"}}
                            >
                                - اختصاص اعتبارهای ماهانه رایگان جهت ثبت آگهی برای صاحبان ملک و مشاورین املاک 
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7} sx={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                        <Box sx={{position:"relative",width:{xs:"100%",md:"80%"}}}>
                            <Card sx={{width:"100%",display:"flex",alignItems:"center",background:"black"}}>
                                <CardMedia component='video' image={WhyHominjaVideo} preload="metadata" id="whyHomeingaVideo" sx={{width:"100%",height:"auto"}} controls onPlay={this.play} onPause={this.pause}>
                                </CardMedia>
                            </Card> 
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

export default WhyHominja;