import React from "react";
import logoBackground from '../images/logoBackground.png';
import { Grid, Typography } from "@mui/material";
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { setCanonicalTag } from "./materilas/vmp";
import { Link } from "react-router-dom";

class AboutUs extends React.Component {

  componentDidMount() {
    document.title = " درباره تیم متخصص معاملات املاک ایران (تهران) | هومینجا";
    document.getElementById("main-heading").innerHTML = "در مورد بهترین شرکت املاک در تهران بیشتر بخوانید "
    document.getElementsByTagName('meta')["description"].content = "شرکت وب گستران سورین خدماتی نظیر کد نویسی پیشرفته، برنامه نویسی نرم افزاری، طراحی پیاده سازی انواع پلتفرم و سایت، طراحی اپلیکیشن، طراحی UI/UX رابط کاربری و تجربه کاربری، سئو را ارائه میکند.";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی بهترین شرکت نرم افزاری ایران | طراحی سایت | طراحی اپلیکیشن | سئو و بهینه سازی سایت برای موتورهای جستجو گر | طراحی UI.UX اختصاصی | پروژه برنامه نویسی دانشگاهی | بهترین شرکت طراحی وب سایت در تهران"
    setCanonicalTag();
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, width: "100%", bgcolor: "#E7F0F3", padding: "40px 0" }}>
        <Grid
          sx={{
            background: `url(${logoBackground})`, backgroundPosition: { xs: "50% 60%", md: "50% 80%" }, backgroundRepeat: "no-repeat", backgroundSize: { xs: "50%", md: "40% auto" }, margin: {
              xs: "0 2%",
              md: '0 10%'
            }, backgroundAttachment: "fixed", bgcolor: "white", padding: "16px", borderRadius: "8px"
          }}
        >
          <Grid sx={{ padding: "2% 0 2%", display: "flex", alignItems: "center" }}>
            <FeedOutlinedIcon sx={{ fontSize: { xs: "30px", md: "40px" } }} />
            <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "20px", md: "30px" }, fontWeight: "bold", display: "inline-flex", marginRight: "10px" }}>
              درباره ی هومینجا
            </Typography>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "justify" }}>
            شرکت وب گستران <a className="zone_text_link" href="https://sorinapp.com/" target="_blank">سورین</a>  (سهامی خاص) به طور رسمی در سال ۱۳۹۶ فعالیت خود را در حوزه فناوری اطلاعات
            و ارتباطات آغاز کرده است. وب گستران سورین متشکل از تیم مجرب و متخصص در زمینه طراحی و
            ساخت انواع قراردادهای هوشمند و پلتفرم بربستر اینترنت با زبان های مختلف برنامه نویسی می باشد.
            <br />
            طراحی و ساخت انواع سایت و اپلیکیشن های موبایلی، طراحی تخصصی انواع پلتفرم های مبادله رمزارز و همچنین سایت های
            حسابداری و مالی از دیگر فعالیت های این شرکت می باشد.
            <br />
            از دیگر خدمات وب گستران سورین می توان به موارد زیر اشاره کرد:
            <br />
            طراحی و پیاده سازی انواع وب سایت های مدیریت و تولید محتوا از جمله خبری و اموزشی و...
            <br />
            طراحی انواع سایت های فروشگاهی، پزشکی و گردشگری و درج آگهی و ...
            <br />
            مشاوره و پیاده سازی کسب و کارها بر بستر اینترنت ورود به عرصه اقتصاد دیجیتال.
            <br />
            خدمات بهینه سازی سایت در موتورهای جستجوگر.
            <br />
            طراحی انواع پلتفرم های مبادله و نگهداری رمزارز.
          </Typography>
          <Typography component={"h3"} sx={{ fontFamily: "shabnam", fontSize: { xs: "18px", md: "23px" }, fontWeight: "bold", margin: "2% 0" }}>
            سایر خدمات:
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "justify" }}>
            همچنین انجام مطالعات فنی و اقتصادی در زمینه تکنولوژی نوظهور مالی،توسعه بسترهای نرم افزاری و
            خدمات شبکه ای،توسعه ی زیر ساخت مشاغل و توسعه کسب و کارهای تجاری از طریق مدیریت و بکارگیری دیتا و دیتا بیس های تخصصی تجارت الکترونیکی از خدمات سورین می باشد. از دیگر کارهایی که در وب گستران سورین انجام می شود می توان 
            انتقال دانش، فناوری و ایده های کسب و کار ،طراحی، نظارت، مشاوره، اجرا و مدیریت راهبردی و توسعه مدیریت مالی از طریق شتاب دهنده ها
            و استارت آپ های مختلف و فناوری نوین، کلیه فناوری های مبتنی بر بلاک چین، خدمات مرتبط با فناوری نوظهور بر بستر اینترنت را نام برد. وب گستران سورین همچنین خدماتی مانند  برگزاری
            دوره های آموزشی مرتبط با موضوع شرکت، خرید و فروش کدهای نرم افزاری و پایگاه داده های مربوط را ارائه می دهد.
          </Typography>
          <Typography component={"h2"} sx={{ fontFamily: "shabnam", fontSize: { xs: "20px", md: "25px" }, fontWeight: "bold", margin: "2% 0" }}>
            محصولات وب گستران سورین:
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "justify" }}>
            •	پلتفرم ریتابکس با قابلیت مبادله 70 رمز ارز.
            <br />
            •	اپلیکیشن مخصوص دریافت اطلاعات خبری و آماری برای موسسه مالی و اعتباری نور به صورت خصوصی.
            <br />
            •	طراحی اپلیکیشن های مربوط به بازه های آماری پلتفرم موسسه مالی و اعتباری نور.
            <br />
            •	سایت املاک و مستغلات حراجینه به صورت اختصاصی.
            <br />
            •	پلتفرم حسابداری نمایشگاه های اتومبیل بصورت فروش خصوصی.
            <br />
            •	سایت خدمات روان درمانی پاب برای ایرانی های خارج و داخل کشور.
            <br />
            •	وبلاگ آموزشی  آکادمی کریپتوکامرس با بیش از ۲۰۰۰ مقاله بروز در حوزه رمزارز و بلاک چین.
          </Typography>
          <Typography component={"h3"} sx={{ fontFamily: "shabnam", fontSize: { xs: "18px", md: "23px" }, fontWeight: "bold", margin: "2% 0" }}>
            صرافی ارز دیجیتال کریپتوکامرس:
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "justify", marginTop: "2%" }}>
            اما از اصلی ترین محصول وب گستران سورین می توان به <b>صرافی <a className="zone_text_link" href="https://mycryptocommerce.com/" target="_blank">کریپتوکامرس</a></b> اشاره کرد. پلتفرم مبادله رمزارز کریپتوکامرس در سال ۱۳۹۸
            پس از ۱۰ ماه کدنویسی افتتاح شد . هم اکنون بیش از سه سال از فعالیت رسمی صرافی می گذرد و بالغ بر ۴۵۰ رمزارز را پشتیبانی می کند.
            <br />
            قابلیتهای نظیر حد سود و ضرر با نقدینگی بالا باعث رفع نیاز کامل هم وطنان در زمینه خرید و مبادله (نوسان گیری) رمزارزهای معتبر بازار شده است.
            <br />
            هم چنین عضویت در انجمن بلاک چین ایران مهر تاییدی بر تکنولوژی بکار رفته در پلتفرم کریپتوکامرس می باشد.
            <br />
            هومینجا سامانه خرید و فروش آنلاین املاک بعنوان تازه ترین
            محصول وب گستران سورین می باشد. هومینجا سعی بر این دارد تا بزرگترین پایگاه داده در تهران را در اختیار خود قرار دهد و کم کم با گسترش پایگاه
            اطلاعاتی آن را به کل ایران تعمیم دهد. هدف از هومینجا یافتن ملک از مسیری به روز و کارامد و کم هزینه است. ملکی که با صرف کم ترین انرژی و
            وقت تا عقد قرارداد به معامله برسد.  همچنین ایجاد راه ارتباطی مطمئن بین متقاضیان و عرضه کنندگان مسکن ، از دیگر اهداف کاربردی هومینجا می باشد.
          </Typography>
          <Typography sx={{ fontFamily: "shabnam", fontSize: { xs: "16px", md: "20px" }, textAlign: "justify", margin: "2% 0 5%" }}>
            <Link className="zone_text_link" to="/" target="_blank">هومینجا</Link> امکان اشتغال زایی برای افرادی که دنبال یک شغل مناسب با درامدی عالی هستند را فراهم می آورد.
            <br />
            همچنین سعی بر این دارد تا در اینده ای نزدیک امکان بستن قرارداد
            الکترونیکی را نیز فراهم کند. مشاوران و تیم متخصص هومینجا با تکیه بر اصل صداقت و شفافیت تا پایان پیداکردن ملک موردنظر با شما همراه هستند.
          </Typography>
          <Typography sx={{ display: "none" }}>
            ما در این صفحه  سعی بر این داریم تا شما در مورد بهترین شرکت املاک در تهران بیشتر بخوانید و با خدمات این شرکت آشنا شوید. همچنین شما درباره تیم متخصص معاملات املاک ایران (تهران) | هومینجا بیشتر اطلاعات کسب می نمایید.
          </Typography>
        </Grid>
      </Grid>
    )
  };
}

export default AboutUs;