import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import Trash from '../images/trash.png';
import LocationImg from '../images/location.png';
import AlertModal from '../component/materilas/alert';
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import { Link } from "react-router-dom";
import GiftLogo from '../component/gifLogo';

class RequestHomeList extends React.Component{

  isMount = false;
  token = localStorage.getItem('tokenUserHomeInja');

  constructor(props){
      super(props);
      this.state={
        lists:[],
        openAlert:false,
        message:'',
        typeAlert: 3,
        giftShow:false,
      }
  }

  componentDidMount(){
    this.isMount = true;
    document.title = 'درخواستهای ملک من در سامانه جستجوی ملک هومینجا'
    if (this.isMount){
      this.getRequestUser();
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  closeAlertModal = () =>{
    this.setState({openAlert:false});
  }

  getRequestUser = () =>{
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + 'v1/request/get',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      let items = res.data.data;
      this.setState({lists:items});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(() => {
      this.setState({giftShow:false});
    });
  }

  deleteRequest = (id)=>{
    Promise.resolve(axios({
      url: baseUrl + 'v1/request/delete',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      params:{
        request_id:id
      }
    }))
    .then(() => {
      this.setState({openAlert:true,message:'درخواست ملک شما با موفقیت حذف گردید.',typeAlert:1});
      this.getRequestUser();
    })
    .catch(() => {
      this.setState({openAlert:true,message:'عملیات با خطا مواجه شد لطفا مجدد امتحان نمایید یا با پشتیبانی تماس بگیرید.',typeAlert:2})
    })
  }

  onClickRequest = (item) => {
    let estate = [];
    estate.push(item.estate);
    let option = {
      transaction:item.transaction.id,
      state:item.state,
      city:item.city,
      zone:item.zone,
      nieghbourhood:item.neighbourhood,
      estate:estate,
      room:item.bedroom_num,
      area:JSON.parse(item.area),
      mortage:JSON.parse(item.mortgage),
      rent:JSON.parse(item.rent),
      price:JSON.parse(item.price)
    }
    localStorage.setItem('optionSearchHomeInja',JSON.stringify(option));
  }

  createListRequest = () =>{
    let items = [];
    this.state.lists.forEach((element,index) =>{
      items.push(
        this.props.user.user_type_id < 5 ?
          <Grid key={index} item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',marginBottom:'10px'}}>
            
            <Grid item sm={2} xs={1.5} style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}
                  sx={{height:{sm:'80px',xs:'60px'}}}
            >
              
              <Button style={{width:'100%',display:'flex',alignItems:'center',height:'100%',backgroundColor:'#01C7E9'}}
                  onClick={() => this.deleteRequest(element.id)}
              >
                <Typography component={'img'} src={Trash} sx={{width:{sm:'26px',xs:'22px'},height:{sm:'28px',xs:'24px'}}} alt="trash"></Typography>
              </Button>
            </Grid>
            <Grid item sm={10} xs={10.5}>
              <Button style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',backgroundColor:'#CCF4FB',
                              boxShadow:'0px 2px 4px rgba(20, 201, 232, 0.2)',borderTopLeftRadius:32}}
                      sx={{height:{sm:'80px',xs:'60px'}}} component={Link} to={'/Listings'} onClick={() => this.onClickRequest(element)} target="_blank"
              >
                <Typography component={'img'} src={LocationImg} alt="location"
                    sx={{marginLeft:{sm:'20px',xs:'5px'},width:{sm:'28px',xs:'22px'},height:{sm:'30px',xs:'24px'}}}
                ></Typography>
                <Typography component={'p'} style={{fontFamily:'shabnam',fontWeight:'700',textAlign:'center',color:'black'}}
                    sx={{fontSize:{sm:'19px',xs:'10px'}}}
                >
                  {
                    element.state.name + ' - ' + (element.city !== null ? element.city.name + ' - ' : '') + 
                    (element.zone !== null ? convertToPersianNumber(element.zone.name) + ' - ' : '') + 
                    (element.neighbourhood !== null ? element.neighbourhood.name + ' - ' : '') + 
                    element.transaction.des + ' - '  + element.estate.des
                  }
                </Typography>
              </Button>
              
            </Grid>
          </Grid>
        :
        <Button style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',marginBottom:'10px',
                backgroundColor:'#CCF4FB',boxShadow:'0px 2px 4px rgba(20, 201, 232, 0.2)',borderTopLeftRadius:32}}
            sx={{height:{sm:'80px',xs:'60px'}}} component={Link} to={'/Listings'} onClick={() => this.onClickRequest(element)}>
          <Grid key={index} item xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',flexWrap:'wrap'}}>
            
            <Grid item md={2.5} xs={6} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontWeight:'700',textAlign:'center',color:'black'}}
                  sx={{fontSize:{sm:'19px',xs:'10px'}}}
              >
                {element.user.name + ' ' + element.user.lastname}
              </Typography>
            </Grid>
            <Grid item md={2.5} xs={6} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontWeight:'700',textAlign:'center',color:'black'}}
                  sx={{fontSize:{sm:'19px',xs:'10px'}}}
              >
                {convertToPersianNumber(element.user.mobile)}
              </Typography>
            </Grid>
            <Grid item md={7} xs={12} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
              <Typography component={'p'} style={{fontFamily:'shabnam',fontWeight:'700',textAlign:'center',color:'black'}}
                  sx={{fontSize:{sm:'19px',xs:'10px'}}}
              >
                {
                  element.state.name + ' - ' + (element.city !== null ? element.city.name + ' - ' : '') + 
                  (element.zone !== null ? convertToPersianNumber(element.zone.name) + ' - ' : '') + 
                  (element.neighbourhood !== null ? element.neighbourhood.name + ' - ' : '') + 
                  element.transaction.des + ' - '  + element.estate.des
                }
              </Typography>
            </Grid>
          </Grid>
        </Button>
       
        
      )
    })
    return items;
  }

  render() {
    return (
      <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
        <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواستهای ملک </Typography>
        </Grid>
        <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
              sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'}}}
        >        
          {
            this.state.lists.length > 0 ?
              this.createListRequest()
            :
              <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                  {'شما درخواست ملک فعالی جهت نمایش ندارید.'}
                </Typography>
              </Grid>
          }
        </Grid>
        <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    )
  };
}

export default RequestHomeList;