import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article14/01.jpg";
import photo02 from "../../images/mag/article14/02.jpg";
import photo03 from "../../images/mag/article14/03.jpg";
import photo04 from "../../images/mag/article14/04.jpg";
import photo05 from "../../images/mag/article14/05.jpg";
import photo06 from "../../images/mag/article14/06.jpg";
import photo07 from "../../images/mag/article14/07.jpg";
import photo08 from "../../images/mag/article14/08.jpg";
import photo09 from "../../images/mag/article14/09.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article14 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "آشنایی با امکانات ، سطح دسترسی و قیمت ملک در خیابان پیروزی که یکی از قدیمی ترین محلات شرق تهران می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "آشنایی با خیابان پیروزی |امکانات خیابان پیروزی | محله های خیابان پیروزی | قیمت ملک در خیابان پیروزی"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[13].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[13].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[13].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[13].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[13].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[13].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="خیابان پیروزی - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[13].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شاید خیلی از شما قصد خرید یا اجاره ملک
                در خیابان پیروزی را داشته باشید اما آشنایی زیادی با این منطقه نداشته باشید. ما در بلاگ هومینجا سعی کردیم تا حدودی شما را با این محله آشنا کنیم.
                <br />
                پیروزی یا همان فرح آباد سابق یکی از قدیمی ترین محله های شرق تهران محسوب می شود. این خیابان جز منطقه 13 شهرداری
                تهران می باشد. خیابان پیروزی از غرب به میدان شهدا و از شرق به کلاهدوز متصل می شود. دسترسی از جنوب به بزرگراه محلاتی و از شمال به نیروهوایی می باشد.
                <br />
                در خیابان پیروزی محله های
                بزرگ و کوچک زیادی بهم پیوند خورده اند. خیلی از محله های  پیروزی قدیمی هستند و از قدمت بالایی برخوردارند. در این خیابان اکثر مکان ها با نام نیروهوایی
                دیده می شود. از پادگان گرفته تا سالن های ورزشی و تالارهای پذیرایی. همچنین خیابان و کوچه هایی در این خیابان موجودند که مربوط به نیروهوایی می شوند.
                <br />
                دسترسی آسان
                این محله به نقاط مختلف تهران یکی از دلایل محبوبیت محله پیروزی می باشد. امکانات رفاهی در کنار قیمت نسبتا مناسب یکی از مزیت های خوب این خیابان می باشد.
                <br />
                منطقه پیروزی با موقعیت جغرافیایی خوبی که دارد  در نقطه اتصال چند خیابان قرار گرفته
                است  که هر کدام از این خیابان‌ها در پیروزی شناخته‌شده هستند. خیابان های معروف پیروزی شامل  پرستار، چهارراه کوکاکولا، نیروهوایی و چهارصد دستگاه می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Azadi" target="_blank">
                  بیشتر بخوانید : محله آزادی
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاریخچه خیابان پیروزی:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="فرح آباد - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای قدیم خیابان آزادی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیدایش این محله برمی گردد به دهه پنجاه یعنی زمانیکه یکی از شاهان قاجار برای دسترسی به کاخ دوشان تپه شروع به ساخت جاده کرد.جاده ای که از دروازه دوشان تپه
                (شهدای امروزی) تا ارتفاعات دوشان تپه کشیده می شود. این مسیر در زمان پهلوی به فرح اباد
                معروف شد. سال 57 نیروهوایی دراین خیابان واقع شد. از آنجا که شروع انقلاب از این منطقه شروع شد بعد از پیروزی انقلاب ،این خیابان پیروزی نام گرفت.
              </Typography>
              <Typography className="h2" variant="h2">
                بافت خیابان پیروزی تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بافت کوچه های این خیابان سنتی است. اما به دلیل وجود رونق خرید و اجارۀ آپارتمان در پیروزی ،آپارتمان ها مدرن تر شده
                و  تغییر زیادی در بافت این منطقه ایجاد شد. در این محله مانند محله الهیه تهران خانه های سنتی و قدیمی در کنار آپارتمان های نوساز قرار گرفته
                اند. پس اگر قصد خرید و  یا اجاره خانه و آپارتمان در خیابان پیروزی  رو دارید، میتوانید انتخاب‌های متفاوتی را در این خیابان مورد بررسی قرار دهید.
              </Typography>
              <Typography className="h2" variant="h2">
                دسترسی خیابان پیروزی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="خیابان پیروزی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ایستگاه مترو پیروزی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اتصال چندین خیابان در پیروزی این منطقه جزو خوش مسیرترین خیابان‌های شهر تهران بشمار می‌رود.
                <br />
                با احداث بزرگراه امام علی در این خیابان دسترسی به اکثر خیابان ها آسان گردیده است.علاوه بر این اتوبان های بسیج و محلاتی نیز رفت و آمد
                با ماشین شخصی را برای ساکنین این محله تسهیل کرده است. دسترسی به این بزرگراه ها نقش بسزایی نیز در کم کردن بار ترافیکی این خیابان داشته است.
                <br />
                همچنین اگر طرفدار وسایل نقلیه هستید خطوط مترو و تاکسی و ایستگاه های اتوبوس. امکانات مناسبی را برای جابجایی مردم این محله ایجاد کرده است.
              </Typography>
              <Typography className="h2" variant="h2">
                امکانات خیابان پیروزی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دسترسی عالی به انواع امکانات رفاهی برای ساکنین  پیروزی از امتیازات ارزشمند خانه‌دار شدن در این محله است. در اینجا به هر کدام از این
                امکانات به طور جداگانه می پردازیم. مراکز تجاری و تفریحی بسیاری در خیابان پیروزی واقع شده اند که به خاطر قیمت مناسبشان  همیشه پر از مشتری هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز آموزشی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تعدد مراکز آموزشی خوب در این خیابان نگرانی از این بابت را برطرف کرده است.
                <br />
                مدرسۀ اندیشه امام
                <br />
                منتظر المهدی
                <br />
                شهید مفتح
                <br />
                شکوفه‌های قرآن
                <br />
                چندین موسسۀ آموزش زبان هم در این محله قرار گرفته است. دانشگاه آزاد واحد تهران جنوب نیز از دیگر مراکز آموزشی مهم خیابان پیروزی بشمار می رود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Hakimiyeh" target="_blank">
                  بیشتر بخوانید : محله حکیمیه
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مراکز مذهبی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مانند سایر محله های تهران، در منطقه پیروزی هم مسجد و حسینیه های معروفی وجود دارد که میزبان ساکنین این منطقه می باشد.
                <br />
                مسجد صاحب الزمان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="مسجد صاحب الزمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مسجد صاحب الزمان محله پیروزی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مسجد علوی
                <br />
                حسینیه ائمه اطهار
                <br />
                مسجد امام جعفر صادق
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز دولتی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                استراتژیک‌ترین مراکز دولتی در محله‌ی پیروزی بیشتر مراکز نظامی می باشد.
                <br />
                فرودگاه نظامی دوشان تپه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="مراکز دولتی پیروزی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  فرودگاه نظامی دوشان تپه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پادگان نیروهوایی ارتش
                <br />
                مهمات و اسلحه سازی ارتش
                <br />
                ستاد مشترک سپاه
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز تفریحی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از دلایل شلوغی این محله تعدد بالای  مراکز تفریحی و پارک ها نسبت به دیگر محلات تهران می باشد.
                <br />
                سینما پیروزی
                <br />
                خانه و موزه دکتر حسابی
                <br />
                باغ و کوشک وثوق الدوله
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="مراکز تفریحی خیابان پیروزی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  باغ و کوشک وثوق الدوله
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بوستان پیروزی
                <br />
                پارک پرستار
                <br />
                بوستان چهارصد دستگاه
                <br />
                پارک گل نما
                <br />
                بوستان نیلوفر
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Listings/buy_tehran_piruzi" target="_blank">
                  خرید و فروش ملک آپارتمان در پیروزی
                </Link>
                <br />
                <Link className="link" to="/Listings/rent_tehran_piruzi" target="_blank">
                  رهن و اجاره ملک آپارتمان در پیروزی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مراکز خرید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پیروزی یکی از محلات شناخته شده تهران می باشد  که مراکز خرید بسیاری در
                آن وجود دارد. قیمت اجناس در پیروزی معقولانه هست و می توان خرید خوبی را در این خیابان تجربه کرد. در اینجا تعدادی از این مراکز را بیان می کنیم.
                <br />
                مرکز خرید کسا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="مرکز خرید کسا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مرکز خرید کسا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پاساژ پانوراما
                <br />
                فروشگاه های مانتو فروشی خیابان اول نیرو هوایی
                <br />
                مراکز خرید بیست و یک متری دهقان
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز درمانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از موارد مهمی که در بررسی محله
                باید مدنظر قرار داد  تعداد کافی مراکز درمانی و بهداشتی  می باشد. در پیروزی مراکز درمانی خوبی وجود دارد که  پاسخگوی نیازهای درمانی ساکنین می باشد.
                <br />
                بیمارستان فجر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="بیمارستان فجر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بیمارستان فجر خیابان پیروزی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                درمانگاه فرهنگیان
                <br />
                درمانگاه شبانه روزی طوبی
              </Typography>
              <Typography className="h2" variant="h2">
                آب و هوای محله پیروزی تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وجود بوستان های متعدد موجب شده که آب وهوای محله پیروزی در شرایط بهتری نسبت به دیگر مناطق تهران قرار گیرد.  از آن جایی که آب و هوای
                تمیز
                و سرشار از اکسیژن نعمت بزرگی محسوب می شود به همین دلیل منطقه پیروزی جزء یکی از بهترین محلات تهران از لحاظ آب و هوایی نیز به شمار می آید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی مناطق تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین محله خیابان‌ پیروزی تهران کجاست؟
                <br />
                خیابان پرستار یکی از بهترین محله های پیروزی برای زندگی می باشد. با اینکه خرید خانه و آپارتمان در پیروزی از
                رنج قیمتی نسبتا مناسبی برخوردار است اما  محله پرستار بالاترین شاخص قیمت را دارا می باشد.در گذشته پرستار محل سکونت وزرا و فرماندهان و وکلا بود.
                قیمت خانه ها و آپارتمان های این خیابان با آپارتمان های محله های خوب تهران برابری می کند. این محله چون در روز پرستار بهره برداری شد به همین
                دلیل  به خیابان پرستار نامگذاری شد. در قدیم این محله بسیار باصفا و سرسبز بود اما امروزه از زیبایی گذشته خبری نیست. این  خیابان انواع امکانات
                رفاهی از جمله باشگاه­‌های ورزشی، فروشگاه‌­های مختلف را دارا می باشد. بافت خیابان پرستار نسبت به  بافت دیگر محله های خیابان  پیروزی متفاوت می باشد.
                <br />
                اکثر خانه های این محله سنتی می باشد اما آپارتمان های نوساز نیز در آن به وفور یافت می شود. با وجود تردد بسیار اما محیطی بسیار آرام را برای زندگی دارد.
                <br />
                برای پیدا کردن بهترین خانه و آپارتمان در خیابان پیروزی <LinkMui className="link" href="http://homeinja.com">اینجا</LinkMui> کلیک کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت ملک  در خیابان پیروزی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="کوکاکولا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمای خیابان پیروزی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قیمت ملک در
                پیروزی از متوسط قیمت ،کمی بالاتر است. البته قیمت خیابان به خیابان این منطقه بسیار متفاوت است و از نوسان بالایی برخوردار است. به عنوان مثال قیمت ملک
                در پرستار با خیابان یوسف آباد برابری می کند. در عوض قیمت در محله های دولاب و دلگشا از قیمت پایین تری نسبت به دیگر محله های خیابان پیروزی برخوردار است.
                <br />
                از مهمترین دلایل اختلاف قیمت در این محله می توان به قدیمی یا نوساز بودن ملک ، امکانات و سطح دسترسی اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                نتیجه گیری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امکانات، دسترسی و موقعیت مناسب منطقه پیروزی، بافت خوب این محله و قیمت مناسب ملک یک امتیاز ارزشمند برای  خانه‌دار
                شدن در این خیابان می باشد .بنابراین می توان نتیجه گرفت  پیروزی یکی از محله های خوب تهران برای زندگی می باشد.چنانچه قصد خرید یا اجاره خانه یا آپارتمان
                را در این منطقه داشتید می توانید از مشاوران هومینجا کمک بگیرید تا با راهنمایی حرفه ای این مشاوران  بهترین و مناسب ترین ملک را در این خیابان پیدا کنید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/Piroozi-street" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/Piroozi-street" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/Piroozi-street" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article14;