import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article20/01.jpg";
import photo02 from "../../images/mag/article20/02.jpg";
import photo03 from "../../images/mag/article20/03.jpg";
import photo04 from "../../images/mag/article20/04.jpg";
import photo05 from "../../images/mag/article20/05.jpg";
import photo06 from "../../images/mag/article20/06.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article20 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "از نکات مهم در اثاث کشی صرف کمترین زمان و انرژی و کمترین میزان خسارت به وسایل و حتی افراد با رعایت بهترین نظم می باشد.";
    document.getElementsByTagName('meta')["keywords"].content = "نکات ضروری اسباب کشی راحت | اسباب کشی ایمن و راحت"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[19].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[19].title + " | هومینجا "
  }


  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[19].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[19].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[19].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[19].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="فوت و فن اثاث کشی راحت - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[19].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یک ضرب المثال انگلیسی وجود دارد که ” اگر سه بار اثاث کشی کنی به اندازه یک آتش سوزی ضرر میبینی”. اکثر مردم حداقل یک بار تجربه اثاث کشی را
                داشته اند. خیلی از افراد مستاجر بودند و مجبور شدند که سالی یکبار اثاث کشی کنند. بعضی از افراد هم با وجود اینکه مالک بودند برای نقل مکان به یک
                خانه جدید اسباب کشی را تجربه کرده اند. یکی از نکات مهم در اثاث کشی صرف کمترین زمان و انرژی با کمترین میزان خسارت به وسایل و حتی افراد و رعایت
                بهترین نظم می باشد. ما در این مقاله از هومینجا سعی بر این داریم تا با بیان یکسری نکات این کار طاقت فرسا را برای شما به یک عمل ساده تبدیل کنیم.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="اثاث کشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  فوت و فن اثاث کشی
                </figcaption>
              </figure>
              <Typography className="h2" variant="h2">
                نکات مهم هنگام اثاث کشی
              </Typography>
              <Typography className="h3" variant="h3">
                سر فرصت کارها را انجام دهید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جمع آوری اسباب و اثاثیه را به دقیقه نود موکول نکیند. بهتر است از زمانیکه تصمیم به اثاث کشی
                کردید شروع به برنامه ریزی کنید. سعی کنید هر هفته حداقل یک بخش از خانه را خالی کنید تا زمان اثاث کشی دلهره ای از بابت این موضوع نداشته باشید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                لیستی از وسایل خود جمع آوری کنید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است لیستی از تمامی وسایل منزلتان
                داشته باشید. ازاین لیست انهایی را که مورد نیاز هستند بسته بندی کنید. به احتمال خیلی زیاد وسایلی هستند که شما استفاده نمی کنید. بنابراین
                بهتر است که این وسایل کنار گذاشته شوند. مخصوصا اگر نقل مکان شما به فضایی کوچکتر از مکان فعلی است بهتر است یا این وسایل را بفروشید و یا ببخشید.
              </Typography>
              <Typography className="h3" variant="h3">
                مکانی برای ذخیره سازی در نظر بگیرید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وسایلی دارید که الان مورد نیاز شما نیستند اما ممکن است در آینده مورد استفاده شما قرار گیرند. بهتر است مکانی جهت انبار کردن این وسایل در نظر بگیرید.
              </Typography>
              <Typography className="h2" variant="h2">
                برای اثاث کشی از کجا شروع کنیم؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین روش اثاث کشی این است که جمع آوری وسایل را از شلوغ ترین و سخت ترین اتاق ها شروع کنید. جمع آوری اسباب و اثاثیه برخی قسمت ها کاری سخت و
                زمان بر است. بنابراین بهتر است تا انرژی شما تمام نشده جمع آوری این اتاق ها را در اولویت قرار دهید. بطور مثال بسته بندی وسایل شکستنی کار سخت
                و حساسی است . این کار نیاز به دقت بیشتری نیز دارد. پس سعی کنید ابتدا این کار را انجام دهید بعد به سراغ جمع آوری کتاب ها یا رختخواب ها بروید.
              </Typography>
              <Typography className="h4" variant="h4">
                اما بطور کلی در این قسمت به مواردی می پردازیم که با رعایت آنها میتوانید یک اثاث کشی راحت را تجربه کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                نکات مهم هنگام بسته بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                لیستی از کارهای اثاث کشی را مدنظر قرار دهید.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="اثاث کشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نکات اثاث کشی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وسایل مورد نیاز جهت اسباب کشی مانند کارتن در سایزهای مختلف، چسب نواری پهن، قیچی، برچسب، طناب و ماژیک را از قبل تهیه کنید.
                <br />
                بهتر است هنگام بسته بندی روی هر جعبه محتویات داخل آن را بنویسید.
                <br />
                تا زمانیکه جمع آوری یک بخش از خانه تمام نشده سراغ بخش دیگر نروید.
                <br />
                برای آسیب نرسیدن به پایه های مبل دور پایه ها را با کاغذ باطله یا روزنامه بپوشانید.
                <br />
                تمام وسایل سنگین را در یک کارتن قرار ندهید.
                <br />
                ظرف های حاوی مایعات رادر کیسه های پلاستیکی قرار دهید و جداگانه بسته بندی کنید.
                <br />
                لباس های آویزی را داخل کیسه بزرگ یا کارتن های مخصوص حمل لباس قرار دهید.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="اثاث کشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بسته بندی لباس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بین هر دو بشقاب چینی یک بشقاب یکبار مصرف قرار دهید .  این عمل از شکستن بشقاب ها جلوگیری می کنید.
                <br />
                پیچ و مهره ها را در کیسه پلاستیکی گذاشته و در دسترس قرار دهید.
                <br />
                با پلاستیک های مخصوص وسایل شکستنی را بسته بندی کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/laundry-room" target="_blank">
                  بیشتر بخوانید : لاندری روم چیست ؟
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                از دیگر مواردی که رعایت آنها بسیار به شما کمک می کند می توان به موارد زیر اشاره کرد :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="بسته بندی وسایل اثاث کشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  چیدمان وسایل برای جابه جایی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای جابجایی آسان بهتر است حتی الامکان وسایل بزرگ را به قسمت های کوچک تفکیک کنید.
                <br />
                سعی کنید برای جلوگیری از آسیب دیدن وسایل در حین اثاث کشی حتی الامکان تمام وسایل را بسته بندی کنید.
                <br />
                دور کشوها را با سلفون بپوشانید تا حین جابجایی باز نشوند.
                <br />
                برای وسایل برقی بهتر است از کارتن های مخصوص خودشان استفاده کنید.
                <br />
                برای استحکام بیشتر جعبه ها بهتر است از چسب نواری پهن یا طناب های ضخیم استفاده کنید.
                <br />
                لوازم ضروری مثل صابون و لوازم بهداشتی را در دسترس قرار دهید.
                <br />
                لوازم گران قیمت جداگانه بسته بندی شود. بهتر است این وسایل را  با خودروی شخصی حمل کنید.
                <br />
                روی جعبه ها جای دست ایجاد کنید تا هنگام جابجایی دچار مشکل نشوید.
                <br />
                بهتر است قبل از جدا کردن اتصالات از آنها عکس بگیرید تا هنگام اتصال مجدد دچار سردرگمی نشوید.
                <br />
                می توانید از وسایل نرم مانند جوراب و حوله به عنوان ضربه گیر استفاده کنید.
                <br />
                چنانچه مبلمان شما دارای روکش پارچه ای است بهتر است هنگام اسباب کشی مبل ها را با روکش مخصوص بپوشایند.
                <br />
                تا قبل از اسباب کشی مواد غذایی فاسد شدنی را مصرف کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                نکات روز اثاث کشی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                حین اثاث کشی ابتدا وسایل سنگین را بارگیری کنید.
                <br />
                اشیای ارزشمند مانند طلا و کارت های اعتباری و کارت شناسایی را نزد خود نگه دارید.
                <br />
                یک وعده غذایی سبک همراه داشته باشید.
                <br />
                بهتر است برای جابجایی از شرکت های خدماتی و افراد باتجربه کمک بگیرید.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="شرکت اثاث کشی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خدمات اثاث کشی
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی مناطق تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نکاتی برای باز کردن جعبه ها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بعد از انتقال اثاثیه یک مرحله مهم باقی می ماند. قراردادن لوازم سرجای خودش یکی دیگر از دغدغه های اسباب کشی می باشد.
                <br />
                از آنجا که بازکردن و جابجایی جعبه ها کمی دشوار
                بنظر می رسد و ممکن است این کار را به تعویق بیندازید، بنابراین بهتر است زمان مشخصی برای اتمام کارها در نظر بگیریم.
                <br />
                در چیدمان منزل جدید ابتدا وسایل ضروری مانند یخچال و گاز را جابجا کنید.
                <br />
                از جعبه های آشپزخانه شروع کنید. چیدمان وسایل آشپزخانه و قراردادن آن ها در کابینت معمولا زمان بر می باشد.
                <br />
                کارتن ها را همزمان باز نکنید تا دچار سردرگمی نشوید.
                <br />
                هر نیم ساعت تایمی را برای استراحت در نظر بگیرید تا خسته نشوید.
                <br />
                لباس های مربوط به کمد را هر چه سریعتر آویزان کنید
                <br />
                وسایل بزرگ مانند مبلمان و تخت را در گوشه خانه قرار دهید تا از آشفتگی فضای مورد نظر جلوگیری کنید.
                <br />
                چنانچه کف خانه شما موکت است روی آن را با پلاستیک ضخیم بپوشانید تا از کثیفی آن جلوگیری کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مطلب از هومینجا سعی بر این داشتیم تا به
                تمام نکات لازم جهت اسباب کشی راحت  بپردازیم. امید است تا با رعایت این نکات تجربه ای دلنشین از یک اثاث کشی داشته باشید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("خانه و سبک زندگی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  خانه و سبک زندگی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/moving-the-house" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/moving-the-house" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/moving-the-house" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article20;