import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import AlertModal from "./materilas/alert";
import { convertToPersianNumber,baseUrl} from "./materilas/vmp";
import GiftLogo from '../component/gifLogo';
import axios from "axios";

class ContractList extends React.Component {

  token = localStorage.getItem("tokenUserHomeInja");
  user = JSON.parse(localStorage.getItem("infoUserHomeInja"));
  isMount = false ;

  constructor(props){
    super(props);
    this.state = {
      giftShow: false ,
      openAlert: false ,
      message: "" ,
      contractInfo: [],
      typeAlert:3
    }
  }

  componentDidMount(){
    this.isMount = true ;
    if(this.isMount){
      if(this.user.user_type_id === 5){
        this.getContract();
      }
      if(this.user.user_type_id === 6){
        this.getDoneContract();
      }
    }
  }

  componentWillUnmount() {
    this.isMount = false ;
  }

  closeAlertModal = () => {
    this.setState({openAlert:false});
  }

  getContract = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/contract/get",
      method: "GET",
      headers: {'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      this.setState({contractInfo:res.data.data});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
    })
  }

  getDoneContract = () => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/contract/done",
      method: "GET",
      headers: {'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      this.setState({contractInfo:res.data.data});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
    })
  }

  onClickConfirm = (element) => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/contract/confirm",
      method: "POST",
      headers: {'Authorization':'bearer ' + this.token},
      data: {
        contract_id: element.id
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"قرداد با موفقیت تایید شد",typeAlert:1});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
      this.getContract();
    })
  }

  onClickDelete = (element) => {
    this.setState({giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + "v1/contract/delete",
      method: "POST",
      headers: {'Authorization':'bearer ' + this.token},
      data: {
        contract_id: element.id
      }
    }))
    .then((res) => {
      this.setState({openAlert:true,message:"قرداد با موفقیت حذف شد",typeAlert:1});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
    })
    .finally(()=>{
      this.setState({giftShow:false});
      this.getContract();
    })
  }

  createContract = () => {
    let result = [];
    let contractInfo = this.state.contractInfo ;
    contractInfo.forEach((element,index) => {
      result.push(
        <Grid 
          sx={{width:"100%",borderRadius:"8px 30px 8px 8px",background:"linear-gradient(0deg, rgba(154,214,248,0.03), rgba(154,214,248,0.06))",
          margin:"15px 0",padding:"10px",boxShadow:"0px 3px 3px 0px rgba(0,0,0,0.2)",border:"2px solid #eee"}} key={index}
        >
          <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:"20px",textAlign:"center",padding:"5px",borderBottom:"3px solid #eee"}}>
            {element.transaction.des}
          </Typography>
          <Grid container sx={{margin:"10px 0"}}>
            <Grid item xs={12} md={4} sx={{padding:{xs:"0",sm:"10px 0"},textAlign:"center"}}>
              <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontWeight:"bold"}}>نام :</Typography>
              <Typography sx={{fontFamily:"shabnam",marginRight:"5px",display:"inline-block",fontWeight:"bold"}}>
                {element.user.name} {element.user.lastname} 
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{padding:{xs:"0",sm:"10px 0"},textAlign:"center"}}>
              <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontWeight:"bold"}}>تلفن :</Typography>
              <Typography sx={{fontFamily:"shabnam",marginRight:"5px",display:"inline-block",fontWeight:"bold"}}>
                {convertToPersianNumber(element.user.mobile)} 
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{padding:{xs:"0",sm:"10px 0"},textAlign:"center"}}>
              <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontWeight:"bold"}}>نوع ملک :</Typography>
              <Typography sx={{fontFamily:"shabnam",marginRight:"5px",display:"inline-block",fontWeight:"bold"}}>
                {element.estate.des} 
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{padding:{xs:"0",sm:"10px 0"},textAlign:"center"}}>
              <Typography sx={{fontFamily:"shabnam",display:"inline-block",fontWeight:"bold"}}>آدرس :</Typography>
              <Typography sx={{fontFamily:"shabnam",marginRight:"5px",display:"inline-block",fontWeight:"bold"}}>
                {element.state.name} - {element.city.name} - {element.zone.name} - {element.neighbourhood.name}
              </Typography>
            </Grid>
            { this.user.user_type_id === 5 ?
                <Grid item xs={12}  sx={{padding:"10px 0",textAlign:"center"}}>
                  <Button onClick={() => this.onClickConfirm(element)} disabled={this.state.giftShow}
                    sx={{bgcolor:"rgb(0, 200, 233)",fontWeight:"bold",color:"black",fontFamily:"shabnam",padding:"10px 40px","&:hover":{bgcolor:"rgb(0, 200, 233)"},margin:"0 5px 0"}}
                  >
                    تایید
                  </Button>
                  <Button onClick={() => this.onClickDelete(element)} disabled={this.state.giftShow}
                    sx={{bgcolor:"rgb(0, 55, 73)",fontWeight:"bold",color:"white",fontFamily:"shabnam",padding:"10px 40px","&:hover":{bgcolor:"rgb(0, 55, 73)"},margin:"0 5px 0"}}
                  >
                    حذف
                  </Button>
                </Grid>
              : <Grid></Grid>
            }
          </Grid>
        </Grid>
      );
    })
    return result;
  }

  render(){
    return(
      <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px',xs:'0'}}}>
        <Grid style={{display:'flex',flexDirection:'row',margin:'0 6% 40px 0'}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواستهای قرارداد</Typography>
        </Grid>
        <Grid sx={{padding:{xs:"0 10px",sm:"0 50px",lg:"0 100px",xl:"0 150px"}}}>
          { this.state.contractInfo.length > 0 ?
              this.createContract()
            : <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                  sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
              >   
                  <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                      <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                      {'درخواست قراردادی وجود ندارد'}
                      </Typography>
                  </Grid>
              </Grid>
          }
        </Grid>
        <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
        <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
      </Grid>
    );
  }
}

export default ContractList ;