import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article63/01.jpg";
import photo02 from "../../images/mag/article63/02.jpg";
import photo03 from "../../images/mag/article63/03.jpg";
import photo04 from "../../images/mag/article63/04.jpg";
import photo05 from "../../images/mag/article63/05.jpg";
import photo06 from "../../images/mag/article63/06.jpg";
import photo07 from "../../images/mag/article63/07.jpg";
import photo08 from "../../images/mag/article63/08.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article63 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "اگر از آن دسته از افرادی هستید که تایم کافی برای باشگاه رفتن ندارید می توانید ورزش فانکشنال را انتخاب کنید تا بدون دستگاه به هدف خود برسید. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "تمرینات فانکشنال | فواید فانکشنال ترینینگ | فانکشنال فیتنس | مفهوم فانکشنال | فانکشنال و تناسب اندام | ویژگی تمرینات فانکشنال | ورزش فانکشنال یعنی چی | "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[62].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[62].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "62px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[62].access} />
        <Grid container sx={{ maxWidth: "1620px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[62].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[62].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[62].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="فانکشنال ترینینگ - هومینجا  " className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[62].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از ورزش هایی که امروزه بسیار ترند شده است تمرینات فانکشنال می باشد. با این ورزش که بسیار هم پرطرفدار است تمرینات ورزشی جدیدی را می توان تجربه کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر شما جزء افردی هستید که به هر دلیلی نمی توانید به باشگاه بروید، ورزش فانکشنال یک انتخاب مناسب برای شماست. چرا که بدون استفاده از دستگاه یا وسیله خاصی می توانید به هدف خود برسید.
              </Typography>
              <Typography className="h2" variant="h2">
                فانکشنال چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="فانکشنال ترینینگ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  فانکشنال ترینینگ
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مهمترین ترندهای دنیای فیتنس، تمرینات فانکشنال هست که بسیار هم پرطرفدار شده است. مفهوم تمرینات فانکشنال در ابتدا توسط متخصصان فیزیوتراپی مورد استفاده قرار گرفت تا بیماران بتوانند با استفاده از این تمرینات زودتر دوران نقاهت را پشت سر بگذرانند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Best-Board-Game-Cafe-In-Tehran" target="_blank">
                  بیشتر بخوانید : کافه بردگیم؛ معرفی بهترین کافه های بازی در تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در تمرینات این ورزش شما عضلات درگیر در فعالیت های روزانه را تمرین می دهید تا بدن قوی تری داشته باشید. همچنین خطر اسیب دیدگی و ضعف عملکردی را کاهش می دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فانکشنال یک ورزش هوازی و قدرتی می باشد که قدرت بدنی را افزایش می بخشد. تمرینات فانکشنال را می توان هم در باشگاه و هم در منزل انجام داد. این ورزش را با انواع وزنه ها می توان انجام داد. همچنین تمرینات این ورزش بدون وزنه و دستگاه نیز صورت می پذیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ورزش از آنجا که قدرت عضلانی بسیار زیادی دارد و سبب افزایش و استقامت بدنی نیز می شود بنابراین برای کسانی که تحت عمل جراحی قرار گرفته اند نیز مفید می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه فانکشنال
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ورزش اولین بار توسط گرگ کلاسمن که از مربیان مشهور آمریکایی بود به جامعه ورزشی معرفی شد.این بدنساز حرفه ای با ادغام حرکات شدید در سال 2000 این ورزش را بنیان نهاد. سرانجام جامعه جهانی این ورزش را در سال 2003 به رسمیت شناخت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کلاسمن در ابتدا ژیمناستیک کار می کرد که برای افزایش قدرت بدنی، بدنسازی را شروع کرد. بعدها تصمیم گرفت که این دو ورزش را با هم ادغام کند. بنابراین فانکشنال از ترکیب حرکات ژیمناستیک و دمبل ابداع شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ورزش تمریناتی سریع و تند ولی در تایم کوتاه دارد که کالری سوزیی بالایی را به همراه دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                هدف تمرینات فانکشنال چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="تمرینات فانکشنال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تمرینات فانکشنال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سبک زندگی امروزی باعث شده که بسیاری از ما برای انجام کارهای عادی مثل راه رفتن بیش از حد معمول یا برداشتن اجسام سنگین دچار مشکل شویم، هدف اصلی فانکشنال ترینینگ این است که توانایی جسمی افراد را برای انجام فعالیت های رومزه افزایش دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                فواید تمرینات فانکشنال:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                انجام حرکات فانکشنال فوایدی را به همراه دارد که ما در زیر به آن می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                1- بهبود الگوی حرکتی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدن انسان برای حرکت ساخته شده است. اگر شما در جوانی تمرینات فانکشنال مثل اسکات، شنا سوئدی و ... بزنید، در دوران میانسالی قادر به حرکت راحت و بدون مشکل برای کارهایی مثل نشست و برخواست از روی زمین، جا به جا کردن کیسه های خرید و ... خواهید بود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-winter-entertaiments" target="_blank">
                  بیشتر بخوانید : تفریحات و ورزشهای زمستانی تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                2- بهبود عملکرد ورزشی در ورزشکارن:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به عنوان مثال ورزشکاران رشته فوتبال برای بالا بردن عملکرد خود در فوتبال، چاره ای جز تقویت عضلات سرینی، همسترینگ و چهار سر ندارند!
              </Typography>
              <Typography className="h3" variant="h3">
                3- اندامی زیباتر و خوش فرم تر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در تمرینات این ورزش گروه های عضلانی بزرگ به خوبی تمرین می کنند و همین امر پس از مدتی توده عضلانی فرد را بالا می برد و توده چربی هم کاهش می یابد.
              </Typography>
              <Typography className="h3" variant="h3">
                4- پیشگیری از آسیب دیدگی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمرینات فانکشنال با بهبود هماهنگی، تحرک کلی و تقویت عضلات بدن به کاهش خطر آسیب دیدگی منجر می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                5- کاهش دردهای مفصلی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای کسانی که از دردهای دائمی کمر، گردن، زانو و ... رنج می برند، تمرینات فنکشنال می تواند بسیار مفید و امن باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                6- عضله سازی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با وجود همه ی این تفاسیر، آیا فانکشنال در ما عضله سازی هم می کند؟ بله. تمام فواید تمرینات این ورزش به عضله سازی آن باز می گردد.
              </Typography>
              <Typography className="h3" variant="h3">
                7- افزایش کالری سوزی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمرینات فانکشنال در مقایسه با تمرینات سنتی و قدیمی کالری سوزی بالاتری دارد! بدن برای مصرف یک لیتر اکسیژن حدود 5 کالری انرژی می سوزاند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هر زمان که از عضلات بیشتری استفاده می کنید، کالری بیشتری می سوزانید. در فانکشنال چه اتفاقی می افتد؟ در این ورزش با درگیر کردن عضلات بدن، کالری سوزی بالاتری رخ می دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت فانکشنال و بدنسازی چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="تفاوت فانکشنال و بدنسازی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تفاوت فانکشنال و بدنسازی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فانکشنال را می توان به نوعی بدنسازی دانست اما تفاوت هایی که با این ورزش دارد سبب جدا سازی آن با این رشته ورزشی شده است.  بر خلاف بدنسازی که شما باید وزنه های سنگین را بلند کنید و یا با استفاده از تجهیزات باشگاهی به بدنتان فشار زیادی را وارد کنید، فانکشنال اینگونه نیست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در تمرینات این ورزش شما در حد تعادل به گونه ای عضلاتتان را درگیر می کنید که عمکلکرد و قدرت آن ها را بالا ببرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حقیقت یکی دیگر از تفاوت های فانکشنال و بدنسازی این است که شما در بدنسازی به صورت تفکیکی روی قدرت و حجم عضلات کار می کنید اما در فانکشنال با یک تیر دو نشان می زنید، یعنی به طور همزمان روی افزایش قدرت و عملکرد یک گروه عضلانی مرتبط با یکدیگر تاثیر می گذارید.
              </Typography>
              <Typography className="h2" variant="h2">
                آیا تمرینات فانکشنال برای همه افراد مناسب است؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                می توان گفت این ورزش برای تمامی افراد مناسب می باشد. حتی خانم های باردار نیز زیر نظر پزشک خود می توانند این تمرینات را انجام دهند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر مدت زمان زیادی از ورزش نکردن شما می گذرد یا مشکلی در سلامتی خود دارید بهتر است با این ورزش را با ترینات مربوط به وزن بدن آغاز کنید. اما باید این نکته را توجه داشته باشید که در مقابل تمریناتی که ضربه زیاد دارند، مقاومت نکنید، زیرا امکان دارد که مفاصل و بافت های نرم در معرض خطر قرار گیرند.
              </Typography>
              <Typography className="h2" variant="h2">
                آیا ورزش فانکشنال عارضه ای ایجاد می کند؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هر ورزشی اگر بصورت صحیح و اصولی انجام شود عارضه ای را به همراه ندارد. ورزش فانکشنال نیز از این قاعده مستثنی نیست. تمرینات این ورزش نیز اگر به درستی صورت گیرد نه تنها ضرری به بدن نمی رساند بلکه برای سلامتی نیز مفید واقع می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران و ۹ جاذبه گردشگری همراه با عکس و آدرس
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما توصیه می کنیم که برای انجام هر نوع ورزشی از مربیان و متخصص این رشته کمک بگیرید.
              </Typography>
              <Typography className="h3" variant="h3">
                مراحل تمرینی :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قصد دارید تمرینات فانکشنال را در برنامه روزانه خود داشته باشید می توانید ابتدا با  چند تمرین ساده شروع کنید که در بهبود تعادل و هماهنگی بدن نیز موثر باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نکته حائز اهمیت در این است زمانی که می خواهید تمرینات را انجام دهید حواستان باشد تا به جای شدید انجام دادن، روی صحیح انجام دادن تمرینات تمرکز کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                حرکت ددباگ (dead bug):
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمرینات فانکشنال با بهبود هماهنگی، تحرک کلی و تقویت عضلات بدن به کاهش خطر آسیب دیدگی منجر می شود.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="حرکت ددباگ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ددباگ
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر به تازگی تمرین خود را شروع کرده اید، این تمرین به اماده سازی شکم شما برای انجام حرکات دشوارتر کمک می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                حرکت shoulder flexion:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این تمرین ساده مخصوص شانه، مناسب برای ورزشکارانی است که می خواهند وضعیت بدن خود را بهبود ببخشند و انعطاف پذیری در قسمت بالایی بدن را افزایش دهند.
              </Typography>
              <Typography className="h3" variant="h3">
                حرکت پلانک (plank) :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="حرکت پلانک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  حرکت پلانک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این تمرین موجب بهبود ثبات هسته مرکزی بدن می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                غذاهای مناسب برای افرادی که تمرین فانکشنال می کنند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تغذیه نقش موثری در تمرینات ورزشی دارد. بنابراین ورزشکاران با رعایت یک برنامه غذایی مناسب می توانند نتیجه بهتری را از ورزش کردن بدست آورند. چنانچه از یک رژیم غذایی نامناسب استفاده کنید نباید انتظار داشته باشید که تغییرات ایده الی را از این ورزش بگیرید. در ادامه به بررسی مواد غذایی مورد نیاز برای تمرینات فانکشنال می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                چربی ها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای تامین انرژی مورد نیاز بدن به چربی ها نیاز دارید. البته باید به این موضوع توجه داشته باشید که نباید در مصرف چربی ها زیاده روی کنید. استفاده بیش از حد از چربی ها باعث تجمع چربی و افزایش وزن می گردد.
              </Typography>
              <Typography className="h3" variant="h3">
                کربوهیدرات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از مواد غذایی که تاثیر بی نظیری بر روی سوخت و ساز بدن دارد کربوهیدرات ها هستند. اما در مصرف این دسته از مواد غذایی نیز مانند چربی ها نباید زیاده روی کرد. مصرف بیش از حد این مواد غذایی ممکن است سبب ابتلا به دیابت و بیماری های دیگر شود. همچنین مصرف کم تر از نیاز بدن به کربوهیدرات موجب می شود که بدن از پروتئین ها استفاده کند که اثر مخربی بر بدن می گذارد.
              </Typography>
              <Typography className="h2" variant="h2">
                پروتئین
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="مواد غذایی پروتئین دار - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مواد غذایی پروتئین دار
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مواد غذایی مورد نیاز بدن پروتئین ها را می توان نام برد. از آنجا که بافت های بدن از پروتئین ساخته می شوند بنابراین اگر پروتئین مورد نیاز بدن تامین نشود بدن قادر به عضله سازی نیست. میزان پروتئین هر نفر بستگی به سن و میزان فعالیت هر شخص دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین مربی فانکشنال:
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="بهترین مربی فانکشنال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  اینستاگرام بهترین مربی فانکشنال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهترین شیوه برای شروع تمرینی فانکشنال یافتن یک مربی ماهر است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                من متخصص رشته ی فانکشنال دارای مدرک بین المللی فانکشنال و آمادگی جسمانی می توانم شما را به هدفتون برسانم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اموزش های من ترکیبی از بهترین اموزش های فانکشنال هست که به شما کمک می کند تا قدرت، تعادل، انعطاف، تمرکز را در بدن خود بازیابی کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه می خواهید در کلاس های آنلاین و آفلاین من شرکت کنید با شماره تلفن 09335441075 از طریق واتساپ و یا پیج اینستاگرام nilla_fit در ارتباط باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                سخن پایانی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                راه کسب نتیجه از تمرینات فانکشنال این است که باید به طور منظم انجام شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اینگونه متوجه تغییرات خوبی در ظاهر خود خواهید شد. سعی کنید تمرینات روتین خود را به شکل دو الی سه بار در هفته انجام دهید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به مرور زمان با قوی تر شدن و هماهنگ تر شد بدن، سعی کنید تمرینات جدیدتری را در برنامه ی خود بگنجانید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article63;