import {Button,  Grid, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class ConvertList extends React.Component{

    isMount = false;
    token = localStorage.getItem('tokenUserHomeInja');

    constructor(props){
        super(props);
        this.state={
            lists:[],
            giftShow:false,
            openAlert:false,
            message:'',
            typeAlert: 3
        }
    }

    componentDidMount(){
        this.isMount = true;
        if (this.isMount){
            this.getConvert();
        }
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    closeAlertModal = () =>{
        this.setState({openAlert:false});
    }

    getConvert = () =>{
        Promise.resolve(axios({
            url: baseUrl+'v1/convert/get',
            method: 'GET',
            headers:{'Authorization':'bearer ' + this.token}
        }))
        .then((res) => {
            this.setState({lists:res.data.data,giftShow:false})
        })
        .catch((err) => {
            this.setState({lists:[],giftShow:false})
        });
    }

    createConvertList = () =>{
        let items = [];
        this.state.lists.forEach((element,index) =>{
          items.push(
            <Grid item xs={12} key={index}
                  style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around',
                          backgroundColor:'white',borderTopRightRadius:16,width:'100%',marginBottom:'10px',flexWrap:'wrap',
                          filter:'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.14))'}}
            >
              <Grid item lg={2.5} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{height:{md:'80px',xs:'60px'}}}
              >
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'نام کاربر: '}</Typography>
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
                  {element.user.name + ' ' + element.user.lastname}
                </Typography>
              </Grid>
              <Grid item lg={3} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{height:{md:'80px',xs:'60px'}}}
              >
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'نوع درخواست: '}</Typography>
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
                  {element.type.description}
                </Typography>
              </Grid>
              <Grid item lg={2.5} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{height:{md:'80px',xs:'60px'}}}
              >
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px',fontWeight:'bold'}}>{'موبایل : '}</Typography>
                <Typography component={'p'} style={{fontFamily:'shabnam',fontSize:'16px'}}>
                  {convertToPersianNumber(element.user.mobile)}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xs={12} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}
                    sx={{height:{md:'80px',xs:'60px'}}}
              >
                <Grid item lg={6} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
                    <Button style={{backgroundColor:'#01C7E9',borderRadius:5,boxShadow:'0px 0px 1px rgba(255, 255, 255, 0.14)',
                                    fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',color:'white'}}
                        sx={{width:{xs:'70%'}}} onClick={() => this.confirm(element.id)}
                    >
                        {'تایید'}
                    </Button>
                </Grid>
                <Grid item lg={6} style={{display:'flex',width:'100%',alignItems:'center',justifyContent:'center'}}>
                    <Button style={{backgroundColor:'white',borderRadius:5,border:'2px solid #00C8E9',filter:'drop-shadow(0px 0px 1px rgba(255, 255, 255, 0.14))',
                                    fontFamily:'shabnam',fontSize:'20px',fontWeight:'bold',color:'black'}}
                        sx={{width:{xs:'70%'}}} onClick={() => this.deleteConvert(element.id)}
                    >
                        {'رد کردن'}
                    </Button>
                </Grid>
              </Grid>
            </Grid> 
          )
        })
        return items;
    }

    confirm = (id) =>{
        this.setState({giftShow:true})
        Promise.resolve(axios({
            url: baseUrl+'v1/convert/confirm',
            method: 'POST',
            headers:{'Authorization':'bearer ' + this.token},
            data:{
                convert_id:id
            }
        }))
        .then((res) => {
            this.getConvert();
            this.setState({openAlert:true,giftShow:false,typeAlert:1,
                            message:this.props.user.user_type_id === 5 ? 'درخواست تبدیل کاربری به مدیریت ارسال شد.' : 'درخواست تبدیل کاربری انجام شد.'})
        })
        .catch((err) => {
            this.setState({openAlert:true,message:'درخواست تبدیل کاربری انجام نشد.',giftShow:false,typeAlert:2})
        });
    }

    deleteConvert = (id) =>{
        this.setState({giftShow:true})
        Promise.resolve(axios({
            url: baseUrl+'v1/convert/delete',
            method: 'POST',
            headers:{'Authorization':'bearer ' + this.token},
            data:{
                convert_id:id
            }
        }))
        .then((res) => {
            this.getConvert();
            this.setState({openAlert:true,message:'درخواست تبدیل کاربری حذف شد.',giftShow:false,typeAlert:1})
        })
        .catch((err) => {
            this.setState({openAlert:true,message:'درخواست حذف انجام نشد.',giftShow:false,typeAlert:2})
        });
    }

    render() {

      return (
        <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'280px',xs:'15px'}}}>
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'5%'}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>درخواستهای تبدیل کاربری</Typography>
          </Grid>
            {
                this.state.lists.length > 0 ?
                    <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                        sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                    >   
                        {this.createConvertList()}
                    </Grid>
                :
                    <Grid item xs={12} style={{display:'flex',alignItems:'center',marginTop:50,width:'100%'}}
                        sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'80px',md:'50px',xs:'10px'},paddingRight:{lg:'70px',md:'40px',xs:'5px'}}}
                    >   
                        <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                            <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                            {'درخواست تبدیل کاربری جهت نمایش وجود ندارد.'}
                            </Typography>
                        </Grid>
                    </Grid>
            }
          
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
            <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
        </Grid>
      )
    };
}

export default ConvertList;