import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article44/01.jpg";
import photo02 from "../../images/mag/article44/02.jpg";
import photo03 from "../../images/mag/article44/03.jpg";
import photo04 from "../../images/mag/article44/04.jpg";
import photo05 from "../../images/mag/article44/05.jpg";
import photo06 from "../../images/mag/article44/06.jpg";
import photo07 from "../../images/mag/article44/07.jpg";
import photo08 from "../../images/mag/article44/08.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article44 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "خیابان فرشته یکی از لاکچری‌ترین خیابان‌های تهران می باشد و معروفیت آن بیشتر برای برج‌های مسکونی و تجاری و ویلاهای لوکس و گران‌قیمت آن است. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "خیابان فرشته کجاست، لاکچری ترین خیابان پایتخت، دلیل نامگذاری خیابان فرشته، مهمترین خیابان های محله فرشته، آشنایی با محله الهیه ، امکانات محله الهیه ، قیمت خانه در فرشته ، خرید و فروش آپارتمان در الهیه"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[43].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[43].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[43].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[43].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[43].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[43].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="خیابان فرشته - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[43].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد خرید آپارتمان یا اجاره ملکی را در محله الهیه و یا خیابان فرشته تهران دارید اما شناخت کافی از این محله ندارید ، <Link className="link" to="/" target="_blank">  وبلاگ هومینجا </Link>  شما را با لاکچری ترین محله تهران آشنا می کند .
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیابان فرشته یا خیابان مرتضی فیاضی از خیابان‌های <Link className="link" to="/tehran-zone-1" target="_blank"> منطقه 1 شهرداری تهران </Link> و ناحیه 7 است. فرشته یا قلب پنت هاوس تهران از معروف ترین خیابان های محله الهیه و جز بزرگترین خیابان های تهران می باشد. این خيابان از چهارراه <Link className="link" to="/Listings/buy_tehran_parkvay" target="_blank">  پارک وی </Link>، در غرب آغاز و به محدوده <Link className="link" to="/Listings/buy_tehran_ghaitarieh" target="_blank">  پل رومی </Link> در شرق منتهی می‌شود. نزدیک ترین راه دسترسی به خیابان زیبای فرشته از طریق مترو، ایستگاه مترو شهید آیت الله <Link className="link" to="/Listings/rent_tehran_sadr" target="_blank">  صدر </Link> است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این خیابان به موازات بزرگراه مدرس قرار دارد. با عبور از این بزرگراه، می توانید خانه های لوکس و برج های فرشته مانند برج های دوقلوی تهران را ملاحظه نمایید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاریخچه خیابان فرشته
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="الهیه سابق - هومینجا" className="image" />
                <figcaption className="image_caption">
                  الهبه در گذشته
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <b>محله الهیه </b> که خیابان فرشته هم جزئی از آن بحساب می آید، در گذشته جزء اراضی خورآذین یا خلازیر بشمار می رفت. مالک آن یکی از رجال دوران قاجاریه بود. انتهای  فرشته از سه باغ سرسبز و بزرگ تشکیل میشد و  جزء باغ‌ها و زمین‌های سرسبز شمیرانات بحساب می امد. این باغ ها در زمان حال به باغ سفارت روسیه در نزدیکی پل <Link className="link" to="/Listings/rent_tehran_ghaitarieh" target="_blank">  رومی </Link>، باغ سفارت آلمان و باغ سفارت ترکیه معروف هستند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از طرح  تفصیلی و با توجه به آب و هوای مناسب این خیابان، ساخت و ساز ساختمان های چند طبقه در این خیابان رونق یافت. خانه‌های ویلایی به پاساژهای معروف، مراکز خرید بزرگ و باغ برج ها تبدیل شدند. اما با وجود <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank"> برج </Link>ها و آپارتمان‌های مدرن؛ هنوز هم بخشی از بافت قدیمی در فرشته وجود دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با وجود آلودگی و ترافیک شهری،  فرشته هنوز هم بهترین آب‌وهوای <Link className="link" to="/tehran-zones" target="_blank">  پایتخت </Link> را دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت ملک در فرشته
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                الهیه بدلیل <Link className="link" to="/Listing/1129" target="_blank">  خانه های گرانقیمتی </Link>که دارد معروف است. نمونه‌هایی از لوکس‌ترین برج‌ها، پنت هاوس و <Link className="link" to="/Listing/1116" target="_blank"> خانه های ویلایی </Link> با متراژهای بالا و امکانات عجیب و غریب در <b> خیابان فرشته تهران </b> قرار دارد. بنابراین اگر از لحاظ قیمتی <Link className="link" to="/Listings/buy_tehran_fereshte" target="_blank">  خرید آپارتمان </Link> در فرشته را بررسی کنیم، با بالاترین قیمت‌های خرید آپارتمان در تهران روبرو می‌شویم. پس چنانچه قصد سکونت در الهیه را دارید، باید سرمایه‌ی زیادی را برای این محله در نظر بگیرید. قیمت ملک در فرشته حدودا متری 100 تا 350 میلیون اعلام شده که قیمت‌های بالاتر از این هم گزارش شده است.
              </Typography>
              <Typography className="h2" variant="h2">
                دسترسی خیابان فرشته
              </Typography>

              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="خیابان فرشته تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دسترسی خیابان فرشته
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مزیت‌ خیابان فرشته که برای خریداران خانه هم بسیار مهم است، دسترسی مناسب این محله به دو اتوبان چمران و صدر و نزدیکی به خیابان ولیعصر می باشد. به دلیل وجود بزرگراه‌ها و خیابان‌های مهم مثل <Link className="link" to="/Listings/buy_tehran_zaaferanieh" target="_blank">  زعفرانیه </Link> و الهیه در اطراف این منطقه به هر نقطه‌ای از شهر می‌توانید براحتی دسترسی داشته باشید. با اینکه از نظر مسافت، فرشته با مناطق جنوبی و مرکزی تهران فاصله‌ی زیادی دارد و ایستگاه مترو و اتوبوس مخصوصی در این خیابان نیست اما تاکسی‌ها و برخی از ایستگاه‌های بی آرتی و همچنین مترو در نزدیکی محدوده‌ی فرشته، کار رفت و آمد را با وسایل نقلیه‌ی عمومی  آسان کرده است. البته باید به این موضوع نیز توجه داشت که این محله به دلیل وجود <Link className="link" to="/Business-Right" target="_blank"> مراکز تجاری </Link> و تفریحی، در بیشتر مواقع با ترافیک زیادی همراه است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Azadi" target="_blank">
                  بیشتر بخوانید : معرفی محله ی آزادی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                خیابان های مهم منطقه فرشته تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در اطراف فرشته، سه خیابان فرعی مهم قرار دارد. خیابان آقا بزرگی که نسبت به بقیه خیابان ها طویل تر می باشد. این خیابان در شمال فرشته قرار دارد. در خیابان آقا بزرگی، برج‌های بسیاری دیده می شود که از معروف‌ترین آن‌ها، می توان <LinkMui className="link" rel="noreferrer" href="https://neshan.org/maps/places/ab1601f04e96a4fdc7bc6a55c833ab7f/%D8%AA%D9%87%D8%B1%D8%A7%D9%86+%D8%A7%D9%84%D9%87%DB%8C%D9%87+%D8%B3%D8%A7%D8%AE%D8%AA%D9%85%D8%A7%D9%86+%D9%BE%D8%A7%D8%B1%D8%A7%D9%85%DB%8C%D8%B3+%D8%A7%D9%84%DB%8C%D8%AA" target="_blank">  برج پارامیس </LinkMui> فرشته را نام برد. خیابان بعدی چناران می باشد. این خیابان‌ از خیابان های معروف فرشته است و  خانه باغ‌های بسیار تماشایی در آن دیده می شود. دیگر خیابان مهم اطراف این محله، خیابان بوسنی و هرزگوین می باشد. در این خیابان <Link className="link" to="/north-of-Tehran-cafe" target="_blank">  کافه </Link> و <Link className="link" to="/best-restaurant-tehran-region1" target="_blank"> رستوران های لوکس </Link> و مجللی واقع شده است. همچنین وجود باغ تیمور و باغ نمازی این خیابان را به منطقه ای خوش آب و هوا تبدیل کرده است.
              </Typography>
              <Typography className="h2" variant="h2">
                چرا الهیه از مناطق پرطرفدار شمال تهران است؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="سفارت نیجریه الهیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سفارت نیجریه
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کوچه‌های خلوت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بزرگراه های زیاد در اطراف الهیه که رفت و آمد را آسان می کند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                وجود برج‌های مجلل و زیبا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                محله‌ای سرسبز و خوش آب‌وهوا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                محل قرارگیری سفارت‌خانه‌های مهم سوئیس، نیجریه ترکیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                وجود سام سنتر، گران‌ترین مرکز خرید تهران در این محله
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                وجود سه باغ شرکت نفت و <LinkMui rel="noreferrer" className="link" href="https://teheran.diplo.de/" target="_blank">  سفارت آلمان </LinkMui> و <LinkMui className="link" rel="noreferrer" href="http://tehran.emb.mfa.gov.tr/Mission" target="_blank">  سفارت ترکیه </LinkMui> در الهیه
              </Typography>
              <Typography className="h2" variant="h2">
                امکانات فرشته
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فرشته به لحاظ امکانات حرف اول را برای سکونت می زند. بهترین مراکز ورزشی، آموزشی تا تفریحی و تجاری مشتاقان زیادی رو به این محله جذب کرده است. نزدیکی خیابان فرشته به شمیرانات و کوههای <Link className="link" to="/niavaran-tehran" target="_blank">  نیاوران </Link> هم آب و هوای خوب را برای این منطقه به ارمغان آورده است و  این محله را از لحاظ دسترسی به ییلاقات بی نیاز کرده است. تقریبا تمامی برج های الهیه <Link className="link" to="/smart-home" target="_blank"> هوشمند </Link> هستند. کافه و رستوران‌های لوکس و خاص را هم در این خیابان می توانید پیدا کنید. در زیر به شماری از امکانات این محله می پردازیم پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مکان های دیدنی الهیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="موزه دکتر حسابی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  موزه دکتر حسابی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جاهای دیدنی فرشته می توان <LinkMui className="link" rel="noreferrer" href="https://fa.wikipedia.org/wiki/%D9%85%D9%88%D8%B2%D9%87_%D8%AF%DA%A9%D8%AA%D8%B1_%D8%AD%D8%B3%D8%A7%D8%A8%DB%8C" target="_blank">  موزه دکتر حسابی </LinkMui> را نام برد. خانه سه طبقه با یک در سبزرنگ که  تمام وسایل دکتر حسابی در آن نگهداری می‌شود و مربوط به دوران پهلوی می باشد. خانه دکتر حسابی دارای یک باغ بزرگ است که در آن درختان و گونه‌های گیاهی جالبی وجود دارد و همچنین پرندگان متعددی هم در آن‌ زندگی می کنند. البته موزه موسیقی ایران و کافه سینما در باغ فردوس را نیز می توان از دیگر مکان های دیدنی الهیه نام برد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Shemiran-No" target="_blank">
                  بیشتر بخوانید: معرفی محله ی شمیران نو تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مراکز خرید فرشته تهران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="سام سنتر فرشته تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سام سنتر فرشته
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز خرید <LinkMui className="link" rel="noreferrer" href="https://www.instagram.com/samcenter_official/" target="_blank">  سام سنتر </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کویین سنتر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز خرید فرشته
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوتیک مال ملل
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز خرید اتوپیا
              </Typography>
              <Typography className="h2" variant="h2">
                پارک ها و بوستان ها
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باغ نمازی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان آقا بزرگی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان بابک
              </Typography>
              <Typography className="h2" variant="h2">
                گالری ها
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="گالری سلوک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  گالری سلوک الهیه تهران
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گالری سلوک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گالری آریانا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" rel="noreferrer" href="https://www.kia-gallery.com/" target="_blank"> کیا گالری </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گالری سلام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گالری هنری ساتیا
              </Typography>
              <Typography className="h2" variant="h2">
                کافه ها و رستوران های فرشته
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="ادرس فود هال فرشته - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ادرس فود هال فرشته
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" rel="noreferrer" href="https://www.instagram.com/boomirestaurant/" target="_blank"> کافه رستوران بومی </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حس توران
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                رستوران لئون
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                رستوران پارک وی
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Hakimiyeh" target="_blank">
                  بیشتر بخوانید : معرفی محله حکیمیه تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مطلب از <LinkMui className="link" rel="noreferrer" href="https://homeinja.info/" target="_blank">  هومینجا </LinkMui> سعی کردیم. اطلاعات کامل و دقیقی از <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">  محله ی الهیه </Link> تهران و خیابان فرشته در اختیارتان قرار دهیم تا با یکی از لاکچری ترین و اعیان‌نشین‌ترین خیابان‌های پایتخت آشنا شوید. برج‌ها و امکانات رفاهی متعدد در این خیابان، سکونت در فرشته را مخصوص قشر پولدار و پردرآمد جامعه قرار داده است. اگر به قصد شهرگردی سری به فرشته زدید پاساژها و مراکز خرید و رستوران‌های آن شما را سرگرم می کند.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>لینک های مرتبط </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    آگهی های خرید و فروش ملک آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    مشاهده آگهی های رهن و اجاره ملک آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_elahieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    آگهی های خرید و فروش ملک آپارتمان در الهیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_elahieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    مشاهده آگهی های رهن و اجاره ملک آپارتمان در الهیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_fereshte'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    آگهی های خرید و فروش ملک آپارتمان در فرشته
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_fereshte'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    مشاهده آگهی های رهن و اجاره ملک آپارتمان در فرشته
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>



              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article44;