import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import axios from "axios";
import { baseUrl } from "./materilas/vmp";
import { convertToPersianNumber , onlyReciveNumber , separator} from "./materilas/vmp";
import GiftLogo from "./gifLogo";
import AlertModal from "./materilas/alert";
import moment from "jalali-moment";

class WithdrawUser extends React.Component {
  
  isMount = false ;
  token = localStorage.getItem("tokenUserHomeInja");

  constructor(props){
    super(props);
    this.state = {
      giftShow: true ,
      alertModal: false ,
      message: "",
      amountUser : 0 ,
      amountSelected : "",
      bankCard: "",
      disableButton: false,
      typeAlert: 3
    }
  }

  componentDidMount(){
    this.isMount = true ;
    document.title = 'برداشت از سامانه املاک هومینجا'
    if(this.isMount){
      this.getInventory();
    }
  }

  componentWillUnmount(){
    this.isMount = false ;
  }

  onCloseAlertModal = () => {
    this.setState({alertModal:false});
  }

  getInventory = () =>{
    this.setState({disableButton:true,giftShow:true});
    Promise.resolve(axios({
      url: baseUrl + 'v1/user/inventory',
      method: 'GET',
      headers:{'Authorization':'bearer ' + this.token}
    }))
    .then((res) => {
      this.setState({amountUser:res.data.data.rial,disableButton:false,giftShow:false});
    })
    .catch((err) => {
      this.setState({alertModal:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش نمائید",giftShow:false,typeAlert:2});
    })
  }

  onChangePrice = (event,newValue) =>{
    let inputWithoutComma = event.target.value.replace(/,/g,"");
    this.setState({amountSelected:onlyReciveNumber(inputWithoutComma)})
  }
  
  onChangeBankCard = (event,newValue) => {
    this.setState({bankCard:onlyReciveNumber(event.target.value)});
  }

  onClickSubmit = () => {
    if(this.state.amountSelected !== ""){
      if(this.state.bankCard !== ""){
        if(this.state.amountSelected <= this.state.amountUser){
          this.postWithdraw();
        }else{
          this.setState({alertModal:true,message:"مبلغ وارد شده بیشتر از موجودی حساب شما است",typeAlert:2});
        }
      }else{
      this.setState({alertModal:true,message:"شماره کارت وارد نشده است",typeAlert:3});
      }
    }else{
      this.setState({alertModal:true,message:"مبلغ وارد نشده است",typeAlert:3});
    }
  }

  postWithdraw = () => {
    this.setState({giftShow:true,disableButton:true});
    let currentTime = new Date();
    let hour = currentTime.getHours();
    let minute = currentTime.getMinutes();
    let date = moment(currentTime).format('jYYYY/jMM/jDD');
    Promise.resolve(axios({
      url: baseUrl + 'v1/withdraw/create',
      method: 'POST',
      headers:{'Authorization':'bearer ' + this.token},
      data: {
        time: hour + ":" + minute ,
        date: date ,
        amount: this.state.amountSelected ,
        des: this.state.bankCard
      }
    }))
    .then((res) => {
      this.setState({alertModal:true,message:"درخواست برداشت شما با موفقیت ثبت شد",typeAlert:1});
    })
    .catch((err) => {
      switch(err.response.status){
        case 409 :
          this.setState({alertModal:true,message:"کاربرگرامی شما درخواست برداشت ثبت شده دارید لطفا شکیبا باشید",typeAlert:3});
          break;
        default:
          this.setState({alertModal:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش نمائید",typeAlert:2});
      }
    })
    .finally(() => {
      this.setState({giftShow:false,disableButton:false})
    })
  }

  render(){
    return(
      <Grid style={{marginTop:120,width:'100%'}} sx={{marginRight:{md:'270px',xs:"0"}}}>
        <Grid style={{display:'flex',flexDirection:'row',marginRight:'6%',width:"100%"}}>
          <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>برداشت</Typography>
        </Grid>
        <Grid container spacing={{xs:0,md:3}} sx={{padding:{xs:"20px 3%",md:"100px 10%",lg:"100px 20%"},width:"100%"}}>
          <Grid item xs={12}>
            <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center",marginBottom:"5px"}}>
              موجودی
            </Typography>
            <Grid sx={{border:"2px solid #eee",width:{xs:"100%",md:"50%"},padding:"20px 0",margin:"auto",borderRadius:"8px"}}>
              <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center"}}>
                {convertToPersianNumber(separator(this.state.amountUser))} ریال
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{fontFamily:"shabnam",marginBottom:"5px",fontWeight:"bold"}}>
              مبلغ به ریال
            </Typography>
            <TextField 
              fullWidth    
              onChange={this.onChangePrice}
              className="ads-textfield"
              id="ads-textfield-input"
              value={convertToPersianNumber(separator(this.state.amountSelected))}
              inputProps={{
                maxLength: 11
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{fontFamily:"shabnam",marginBottom:"5px",fontWeight:"bold"}}>
              شماره کارت مقصد
            </Typography>
            <TextField 
              fullWidth    
              onChange={this.onChangeBankCard}
              className="ads-textfield"
              id="ads-textfield-input"
              value={convertToPersianNumber(this.state.bankCard)}
              inputProps={{
                maxLength: 16
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{display:"flex",justifyContent:"center"}}>
            <Button onClick={this.onClickSubmit}
              sx={{fontFamily:"shabnam",fontSize:"16px",fontWeight:"bold",color:"black",bgcolor:"#00C8E9","&:hover":{bgcolor:"#00C8E9"},width:{xs:"100%",md:"50%"},
                  padding:"10px",margin:{xs:"20px 0",md:"0"}}}>
              درخواست برداشت
            </Button>
          </Grid>
        </Grid>
          <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",textAlign:"center"}}>
            کاربر گرامی درخواست برداشت شما توسط پشتیبانی بررسی خواهد شد و پس از تایید، مبلغ مورد نظر به حساب شما انتقال خواهد داده شد   
          </Typography>
          <AlertModal open={this.state.alertModal} close={this.onCloseAlertModal} message={this.state.message} type={this.state.typeAlert}/>
          <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
      </Grid>
    )
  }
}

export default WithdrawUser ;