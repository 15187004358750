import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article41/01.jpg";
import photo02 from "../../images/mag/article41/02.jpg";
import photo03 from "../../images/mag/article41/03.jpg";
import photo04 from "../../images/mag/article41/04.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article41 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "در این مطلب از هومینجا سعی داریم برای انجام خانه تکانی عید در کمترین زمان ممکن تمامی نکات و ترفندهای مربوط به آن را بازگو کنیم.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "ترفندهای خانه تکانی سریع برای شاغلین، لیست کارهای خانه تکانی، نکاتی برای تمیز کردن آشپزخانه در خانه تکانی ، همه چیز در مورد خانه تکانی عید نوروز"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[40].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[40].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[40].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[40].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[40].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[40].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="خانه تکانی عید - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[40].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه تکانی عید امری زمان بر است. دانستن اصول و نکات خانه تکانی و در کنار آن برنامه‌ریزی درست بهترین روش برای خانه تکانی می باشد. ما در این مقاله از <Link className="link" to="/" target="_blank"> هومینجا </Link> سعی داریم تا تمامی نکات و ترفندهای مربوط به خانه تکانی را برای شما بازگو کنیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه تکانی عید یا به اصطلاح Spring Cleaning یکی از رسوم قدیمی برای آماده شدن عید نوروز می باشد که هر سال با نزدیک شدن به بهار انجام می شود. نظافت و تمیز کردن خانه از آلودگی‌ها از آداب و سنت‌های دیرینه ما ایرانی‌ها است که به استقبال سال جدید می رویم. خانه‌تکانی کاری سخت و زمان‌بر است مخصوصا اگر شاغل باشید و یا <Link className="link" to="/children-room-decoration" target="_blank"> کودک </Link> کم سن داشته باشید. ولی جای نگرانی نیست. با یک برنامه‌ریزی درست و دقیق می‌توانید به راحتی و به سرعت خانه‌تکانی را به اتمام رسانید.
              </Typography>
              <Typography className="h2" variant="h2">
                خانه تکانی عید را چطور شروع کنیم؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="خانه تکانی عید - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رسم خانه تکانی عید
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                1- برنامه ریزی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همیشه با یک برنامه ریزی درست می‌توان کارها را براحتی انجام داد. این اصل در خانه تکانی عید نوروز نیز بسیار کارا می باشد. بنابراین برای جلوگیری از سردرگمی و کلافگی هنگام خانه تکانی یک برنامه ریزی دقیق انجام دهید. یک قلم و کاغذ بردارید و تمامی موارد نظافت بخش‌های مختلف خانه را بدون اولویت یادداشت نمایید. در این صورت از زیاد و نامعلوم بودن کارها هراسی ندارید و همچنین دیگر نیازی نیست کاری را دوبار انجام دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                2- زمان بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است روزهایی که فرصت برای خانه تکانی دارید را مشخص کنید. مخصوصا اگر شاغل هستید باید زمان‌های خالی خود را در نظر بگیرید. با توجه به زمانی که دارید و همچنین میزان کثیفی بخش‌های مختلف خانه، برنامه ریزی خانه تکانی را تنظیم کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                3- اولویت بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمانی که روزهای خالی برای خانه تکانی را پیدا کردید، پس به راحتی می‌توانید کارها را اولویت بندی کنید. بهتر است بخش‌هایی از خانه که مدت زمان زیادی از تمیزکاری آنها می‌گذرد را در اولویت کارهایتان قرار دهید. همچنین برای انجام یکسری از کارها نیز مانند قالیشویی و خشکشویی بهتر است زودتر اقدام نمایید.
              </Typography>
              <Typography className="h3" variant="h3">
                4- تقسیم وظایف
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که برای خانه تکانی عید انرژی و نیروی زیادی نیاز هست، بنابراین به تنهایی نمی‌توانید تمام قسمت‌های خانه را تمیز کنید. بنابراین برای اینکه خسته نشوید و تمامی وظایف بر عهده یک نفر نباشد بهتر است که کارهای خانه بین اعضای خانواده تقسیم گردد یا یشنهاد می‌کنیم برخی از کارهای سنگین و پر انرژی را به نیروهای خدماتی باتجربه واگذار نمایید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/fengshui" target="_blank">
                  بیشتر بخوانید : فنگ شویی چیست؟ از کاربرد علم فنگ شویی در خانه چه می دانید؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                خانه تکانی بخش‌های مختلف منزل
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="خانه تکانی آشپزخانه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خانه تکانی آشپزخانه
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                1. آشپزخانه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                یخچال را بررسی نمایید و مواد غذایی فاسد و تاریخ گذشته را دور اندازید. همچنین گرد و غبار پشت یخچال و فریزر را با جاروبرقی و دستمال تمیز کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اگر وسایل اضافی و جاگیر در کابینت هست از کابینت بیرون بیاندازید. سطح بالای کابینت‌ جایی ست که چربی و گرد و خاک دارد. بنابراین بهتر است حداقل سالی یک یا دوبار تمیز شوند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                چربی اجاق گاز، توستر و مایکروفر را با مواد شوینده مخصوص  تمیز کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پرده‌های آشپزخانه را حتما بشویید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هود را تمیز کنید و در صورت نیاز فیلتر هود را نیز تعویض کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاشی‌های دیوار آشپزخانه و سقف را در صورت امکان بشویید. برای شستشوی سینک از شوینده مخصوص سینک و شیر آلات استفاده نمایید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای پاک کردن کابینت های MDF از مواد شوینده مانند وایتکس، آستون؛ الکل صنعتی ، مستقیم استفاده نکنید. زیرا این مواد به سطح رو کش دارکابینت آسیب می رسانند. برای استفاده از این مواد بهتر است آن ها را با آب رقیق کنید و سپس بکار برید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای تمیز کردن کابینت های فلزی از پودرهای ماشین لباسشویی و اسپری های پاک کننده ی چند منظوره استفاده کنید تا براحتی چربی و لکه های روی کابینت را پاک کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای تمیز کردن فر داخل اجاق گاز بهتر است ترکیبی از  نمک و  جوش شیرین  آب تهیه کنید و در قسمت های داخل فر خاموش بپاشید. سپس با پارچه مرطوب داخل فر را دستمال بکشید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                2. سرویس بهداشتی و حمام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                داخل توالت و روشویی را با جرم‌گیر ضدعفونی کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاشی های دیوار و کف حمام و سرویس بهداشتی را با حتما با تی و فرچه بشویید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اگر از پرده ضد آب داخل حمام استفاده می کنید حتما آن را درآورده و بشویید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                شیرآلات و دوش را با اسپری مخصوص تمیز کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/laundry-room" target="_blank"> اتاق لباسشویی </Link> را کاملا تمیز کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                3.اتاق نشیمن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                در صورتیکه قصد عوض کردن پرده های اتاق را ندارید آنها را بشویید و یا به خشکشویی بدهید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فرش‌های اتاق را به قالیشویی بدهید و یا اگر به تازگی شسته اید فقط شامپو فرش کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/pouf" target="_blank"> مبلمان </Link> را با جاروبرقی تمیز کنید و اگر از روکش‌های رومبلی استفاده می‌کنید آنها را بشویید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تمیزکردن شیشه‌های پنجره، آینه و چراغ‌های روشنایی را حتما بخاطر بسپارید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خانه‌تکانی عید فرصت خوبی برای تغییر <Link className="link" to="/small-house-decoration" target="_blank"> دکوراسیون </Link> است. بهتر است برای سال جدید دکور خانه را نیز تعویض کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                4.اتاق خواب
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> روتختی </Link>، ملحفه ها را شسته و برای خشک شدن آنها را در معرض جریان هوا و نور خورشید قرار دهید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کمد و کشوی لباس‌ها را خالی کرده و داخل آن را تمیز کنید. لباس‌هایی که دیگر استفاده نمی کنید را به نیازمندان دهید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کنج دیوارها و سقف را که محلی برای جمع شدن گردوغبار است حتما تمیز کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اگر از آن دسته افرادی هستید که به مطالعه کردن علاقه مندید و در اتاق خواب خود کتابخانه دارید باید حداقل بعد از یکسال آنها را از کتابخانه بیرون بیاورید و بعد از تمیز کردن مجدد در کتابخانه قرار دهید.
              </Typography>
              <Typography className="h2" variant="h2">
                مواردی که در خانه تکانی عید باید به آن توجه داشت
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="بی نظمی در خانه تکانی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بی نظمی و سردرگمی در خانه تکانی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                1- برای اینکه در خانه تکانی عید دچار سردرگمی و بی نظمی نشوید بهتر است که اتاق ها و بخش های مختلف خانه را یکی پس از دیگری تمیز کنید. همچنین تمام وسایل داخل کمدها و کشوها را همزمان بیرون نریزید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                2- برای اینکه هنگام خانه تکانی خسته نشوید یک موسیقی پر هیجان و پر جنب و جوش بگذارید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                3- در خانه تکانی عید برای تمیز کردن کمدها و وسایل حتماً از پارچه‌های میکروفیبر استفاده کنید که ضد گرد و غبار هستند. در هر اتاق اگر پرده‌ وجود دارد ابتدا آن‌ را جدا کنید و بشویید و بعد از تمیزی اتاق مجدد در جای خود نصب کنید. همچنین برای تمیز کردن <Link className="link" to="/chandelier-in-decoration" target="_blank"> لوسترها </Link> و وسایل روشنایی از دستمال خشک، استفاده کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                4- تمیز کردن کف پوش‌ها و سرامیک‌ها بسیار مهم هستند. برای این کار باید مبلمان و اسباب و اثاثیه را از جای خود حرکت دهید تا بهتر کفپوش‌ها و سرامیک‌ها تمیز شوند. بناراین بهتر است که فرش یا موکت کف ساختمان را جمع کنید تا وسایل راحت تر جابجا شوند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/smart-home" target="_blank">
                  بیشتر بخوانید : خانه هوشمند چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نکات مهم خانه تکانی عید
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                تمیزکردن جاکفشی، دستگیره‌های در و بالکن را به یاد داشته باشید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خانه‌تکانی عید فرصت مناسبی برای مرتب کردن انباری خانه می باشد. با استفاده از نظم‌دهنده‌ها می توانید فضای انباری را سر و سامان دهید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                به گلدان‌های داخل خانه، راه‌پله و بالکن توجه داشته باشید. برگ های خشک‌ و ریخته را بردارید. برگ‌ <Link className="link" to="/keeping-apartment-flower" target="_blank"> گیاهان </Link> را با دستمال نرم و مرطوب تمیز کنید تا برگ ها براق شوند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                زباله‌ها را در حین تمیز کردن حتما تفکیک کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برای اینکه خانه تکانی سالم تری داشته باشید می توانید از شوینده های طبیعی را بکار ببرید.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مطلب از هومینجا سعی کردیم خانه‌تکانی با برنامه‌ریزی درست را آموزش دهیم. چنانچه شما کارمند هستید و تایم کمی برای خانه تکانی ندارید بهتر است <Link className="link" to="/Spring-Cleaning" target="_blank"> خانه تکانی </Link> خود را زودتر شروع کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا درتلاش است تا همواره اطلاعات مناسبی در اختیار کاربران قرار دهد. با دنبال کردن <Link className="link" to="/" target="_blank"> وب سایت </Link> و هم چنین <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link> از اخرین <Link className="link" to="/Listings" target="_blank"> آگهی های املاک </Link> و مطالب جذاب ما مطلع شوید.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>ممکن است مطالب زیر هم برای شما جذاب و مفید باشد:</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/tehran-zones'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    معرفی کامل مناطق تهران همراه با عکس و نقشه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/AboutUs'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    در مورد شرکت وب گستران سورین (هومینجا) بیشتر بدانیم
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ textAlign: "center" }}>
                <Button onClick={this.onClickOpenLinks} disableRipple sx={{ "&:hover": { bgcolor: "transparent" } }}>
                  <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", transform: this.state.openLinks ? "rotate(180deg)" : "rotate(0deg)", transition: "all 0.5s" }} />
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article41;