import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article48/01.jpg";
import photo02 from "../../images/mag/article48/02.jpg";
import photo03 from "../../images/mag/article48/03.jpg";
import photo04 from "../../images/mag/article48/04.jpg";
import photo05 from "../../images/mag/article48/05.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article48 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "هومینجا؛سامانه انلاین املاک به معرفی 5 سایت برتر املاک که انواع معاملات خرید و فروش یا رهن و اجاره انواع ملک را پشتیبانی میکنند، میپردازد.| هومینجا";
    setCanonicalTag();
    let link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', 'https://www.evimshahane.com/5-%d9%88%d8%a8-%d8%b3%d8%a7%db%8c%d8%aa-%d8%a8%d8%b1%d8%aa%d8%b1%d8%ae%d8%b1%db%8c%d8%af-%d9%88-%d9%81%d8%b1%d9%88%d8%b4-%d8%a7%d9%85%d9%84%d8%a7%da%a9%d8%8c-%d8%a2%d9%be%d8%a7%d8%b1%d8%aa%d9%85%d8%a7/');
    document.head.appendChild(link);
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[47].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[47].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[47].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[47].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[47].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[47].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="هومینجا سایت - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[47].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این متن صرفا یک رپرتاژ اگهی از سایت اویم شاهانه می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                لینک منبع : <LinkMui className="link" href="https://www.evimshahane.com/5-%d9%88%d8%a8-%d8%b3%d8%a7%db%8c%d8%aa-%d8%a8%d8%b1%d8%aa%d8%b1%d8%ae%d8%b1%db%8c%d8%af-%d9%88-%d9%81%d8%b1%d9%88%d8%b4-%d8%a7%d9%85%d9%84%d8%a7%da%a9%d8%8c-%d8%a2%d9%be%d8%a7%d8%b1%d8%aa%d9%85%d8%a7/" target="_blank"> بهترین سایت خرید و فروش آپارتمان </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با  پیشرفت حوزه تکنولوژی و فناوری اطلاعات نحوه ی جستجوی مردم برای رفع انواع نیازهای خود تغییرات چشمگیری داشته است. بطوریکه تقریبا همه افراد به دنبال رفع نیاز خود در فضای مجازی هستند. نیازها شامل خدمات، خرید، مطالعات و ایجاد ارتباطات با دیگران می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این روزها مردم کمتر حاضرند وقت و انرژی خود را در سطح شهر تلف کنند تا خواسته خود را تامین کنند. یکی از اصلی ترین نیاز افراد مسکن می باشد .
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مشغله کاری، کمبود زمان ، ترافیک و آلودگی هوا و همچنین صرف هزینه اضافی باعث تغییر روش <Link className="link" to="/Listings" target="_blank"> جستجوی ملک </Link>، خانه و یا آپارتمان شده است و یافتن ملک بوسیله سر زدن به آژانس املاک منسوخ شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به منظور راحتی و افزایش رضایت افراد <b> سایت های آگهی املاک </b> تاسیس شدند و شروع بکار کردند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">
                  بیشتر بخوانید : برج های لوکس منطقه یک تهران را بشناسید
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین اطلاعات غلط و اشتباه در زمینه معاملات املاک موجب تحمیل ضرر و زیان هنگفت دوطرف معامله شده است. بنابراین وجود سامانه و <b> سایت املاک و مستغلات </b>علاوه بر درج آگهی ملک، جستجوی راحت، موقعیت و امکانات ملک به همراه عکس و توضیحات شما را به خانه دلخواه خود نزدیکتر می کند.
              </Typography>
              <Typography className="h2" variant="h2">
                بررسی بهترین سایت های خرید و فروش ملک آپارتمان :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امروزه اکثر آژانس های املاک فعالیت خود را از دفاتر سنتی به سایت های درج آگهی و جستجوی ملک منتقل کرده اند. این امر علاوه بر افزایش فروش، موجب گسترش کسب و کار آنها از یک محله به یک شهر یا استان و حتی کل کشورشده است. بنابراین در این مقاله از بلاگ هومینجا به بررسی و معرفی بهترین سایت املاک و مستغلات که معاملات خرید و فروش یا رهن و اجاره انواع ملک و آپارتمان را پشتیبانی می کنند می پردازیم.
              </Typography>
              <Typography className="h2" variant="h2">
                معرفی برترین سایت های آگهی و جستجوی ملک :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت 5 سایت برتر در زمینه خرید و فروش و اجاره ملک را به شما معرفی خواهیم کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                1 - سایت تخصصی ملک هومینجا :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="وب سایت هومینجا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایت جستجوی ملک هومینجا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در سال 1400 پروژه وب سایت املاک و مستغلات <Link className="link" to="/" target="_blank">  هومینجا </Link> توسط شرکت <LinkMui className="link" href="http://sorinapp.com" target="_blank"> وب گستران سورین </LinkMui> (سهامی خاص ) تعریف شد که نزدیک به 7 ماه پروسه طراحی و ساخت آن زمان برد. در نهایت در سال 1401 <b> سایت تخصصی ملک</b> هومینجا لانچ شد و کار خود را شروع کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Housing-Market-Prediction-1402" target="_blank">
                  بیشتر بخوانید : پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایت آگهی و جستجوی املاک هومینجا به عنوان پیشرفته ترین سامانه املاک و مستغلات در ایران شناخته شده است. دلیل این امر ساخت حرفه ای سایت، استفاده از آخرین تکنولوژی های حوزه کامپیوتر، همکاری با وکلای باسابقه حقوقی، کارشناسان ثبتی و مشاوران متخصص املاک می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایت خرید و فروش ملک هومینجا با تمرکز در <Link className="link" to="/tehran-zone-1" target="_blank">  منطقه 1 </Link>، <Link className="link" to="/tehran-zone-3" target="_blank">  3 </Link> و <Link className="link" to="/tehran-zone-4" target="_blank">  4 </Link> تهران کار خود را آغاز کرد. هدف مارکت پلیس هومینجا پوشش کامل <Link className="link" to="/tehran-zones" target="_blank"> مناطق تهران </Link> و سپس ارایه خدمات در کل ایران می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                از ویژگیهای وب سایت معاملات املاک هومینجا :
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                جستجوی ملک به راحتی و با فیلترهای پیشرفته
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                درج آسان آگهی انواع ملک با اطلاعات، امکانات و تصاویر ملک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                مشاوره برای انواع معاملات ملک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                ثبت درخواست ملک و ارایه ی فایلهای مختلف توسط پشتیبان
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                قابلیت عقد قرارداد با تخفیف در کمیسیون به نسبت عرف بازار
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                قابل استفاده برای مالکان، انبوه سازان، مستاجرین، مشاوران و آژانس های املاک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                راههای ارتباطی متنوع مانند چت آنلاین، تیکت ، واتس آپ، تماس با تلفن دفتر و همراه هومینجا
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                پایین ترین هزینه درج آگهی با تخصیص اعتبارات رایگان ماهانه
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                قسمت <Link className="link" to="/HomeMag" target="_blank">  بلاگ </Link> با ارائه مقالات مربوط به حوزه املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ها بخشی از مزایای استفاده از حرفه ای ترین سایت املاک و مستغلات ایران است.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کیلید - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایت کیلید
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این سایت در زمینه خرید و فروش و رهن و اجاره اپارتمان در حال کار می باشد که به مشاوران املاک در قالب پنل های گران قیمت خدمات ارائه می کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  بیشتر بخوانید : نیاوران تهران ؛ معرفی کامل محله نیاوران منطقه یک تهران
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                ویژگی های سایت کلید :
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                مناسب فقط برای مشاوران
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                امکان جستجوی ملک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                اپلیکیشن اندروید
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                شامل مقالات متعدد
              </Typography>
              <Typography className="h3" variant="h3">
                3 - سایت املاک دیوار :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="سایت دیوار - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دیوار
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایت دیوار در سال 92 برای خرید و فروش کالاهای دسته دوم شروع به کار کرد. به تازگی دسته بندی جداگانه ای برای املاک تعبیه شده است. هزینه بالای آگهی و وجود همه نوع لوازم خانگی از معایب سایت دیوار می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                ویژگی های سایت دیوار :
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                دسته بندی مختلف
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                جستجوی ساده املاک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                اپلیکیشن اندروید
              </Typography>
              <Typography className="h3" variant="h3">
                سایت املاک ادرس :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این وب سایت با تمرکز بر رهن و اجاره ملک و آپارتمان در تهران تاسیس شد. با گذشت زمان مشارکت در ساخت هم به خدمات این سایت اضافه شد.
              </Typography>
              <Typography className="h4" variant="h4">
                ویژگی های سایت ادرس :
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                طراحی زیبا و دلنشین
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                جستجوی آسان آگهی
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                وبلاگ با مقالات مفید
              </Typography>
              <Typography className="h3" variant="h3">
                5 - سایت املاک ایملک :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="سایت ایملک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ایملک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این سایت تنها فایل های ویلایی قرار می گیرند که دارای تمامی ویژگی های خوب برای خرید و فروش هستند.  هیچ فایل نامعتبر و بلاتکلیف در این سامانه قرار نگرفته است. همچنین این سایت ویلاهایی را در اختیار می گیرد که در استان البرز قرار دارند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                پرسش و پاسخ
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برخی از سوالات کابران را در این قسمت پاسخ داده ایم:
              </Typography>
              <Typography className="h3" variant="h3">
                چرا هومینجا را به عنوان بهترین سایت جستجو و درج آگهی املاک معرفی کردید ؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                معمولا دو نوع سایت آگهی وجود دارد: یکی از نوع عمومی و دیگری سایت تخصصی می باشد. سایتهای عمومی معمولا کیفیت پایین تری از آگهی را دارا هستند و اطلاعات نامرتبط و دروغین بین آگهی ها فراوان است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایت املاک هومینجا تخصصی ترین سایت املاک و مستغلات می باشد. زیرا از حرفه ای ترین تیم نرم افزاری وب گستران سورین استفاده میکند که باعث ایجاد  تجربه کاربری شیرین همراه با آخرین تکنولوژی های فناوری اطلاعات شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بررسی تمام آگهی ها توسط تیم پشتیبانی، درج نامحدود عکس، تعیین وقت بازدید و خدمات دیگر در حوزه املاک، هومینجا را از بقیه سایتهای املاک متمایز کرده است.
              </Typography>
              <Typography className="h3" variant="h3">
                سایت ملکی هومینجا مناسب چه کسانی می باشد و به هر نوع کابر خود چه خدماتی ارایه می کند؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاربران وب سایت هومینجا به 4 گروه تقسیم میشوند که خدمات هر دسته هم به تفضیل در ذیل ذکر شده است.
              </Typography>
              <Typography className="h4" variant="h4">
                1- مالکان و موجران محترم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خدماتی که <LinkMui className="link" href="https://homeinja.info" target="_blank">  هومینجا </LinkMui> به صورت رایگان در اختیار مالکان قرار می دهد عبارت است از :
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                عکاسی حرفه ای از ملک شما
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                عقد تمامی قراردادها با تخفیف در کمیسیون نسبت به عرف بازار
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                پنل کاربری حرفه ای با امکاناتی نظیر : درج راحت آگهی، امکان تعیین ساعات بازدید از ملک شما، قابلیت ارتقا آگهی به صفحه ی اصلی برای بازخورد بهتر، تخصیص اعتبارات رایگان ماهانه برای حداقل کردن هزینه خدمات
              </Typography>
              <Typography className="h4" variant="h4">
                2- مستاجران، خریداران، سازندگان و سرمایه گذاران در حوزه املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خدمات وب سایت هومینجا برای کسانی که به دنبال ملک به منظور خرید، اجاره و یا سرمایه گذاری هستند بدین شرح است:
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                ثبت درخواست ملک : پشتیبان های سایت پس از ثبت درخواست ملک در کمترین زمان مناسبترین ملک را باتوجه به شرایط شما معرفی می کنند.
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                ثبت درخواست قرارداد : چنانچه ملک خود را در سایت هومینجا پیدا کرده باشید یا به هر طریق دیگر، می توانید با ثبت درخواست قرارداد، آن را با تخفیف نسبت به عرف بازار منعقد نمایید.
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                جستجوی آسان بین آگهی ها با فیلترهای پیشرفته              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                امکان نشانه دار کردن ملک              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                امکان ثبت درخواست بازدید از ملک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                پشتیبانی کامل شما از لحظه ثبت درخواست تا عقد قرارداد
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست؟
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                3- مشاوران مستقل و آژانس های املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی فعالان و همکاران محترم اعم از مشاوران مستقل و آژانس های املاک از خدمات زیر بهره مند می شوند.
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                پنل کاربری حرفه ای با امکان درج نامحدود آگهی
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                مدیریت آگهی ها و جستجوی آسان بین فایل ها
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                درج تمام اگهی ها با نام و شماره شما
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                امکان ثبت درخواست انواع قرارداد ها
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                تخصیص اعتبارات رایگان برای حداقل شدن هزینه درج آگهی
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                امکان ثبت درخواست ملک مورد نظر و دریافت فایل توسط پشتیبان
              </Typography>
              <Typography className="h4" variant="h4">
                4- مشتاقان و علاقه مندان به کسب درآمد از معاملات املاک  (بدون نیاز به مجوز و حضور فیزیکی در دفتر و مجوز مشاورین املاک)
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از تمامی جوانان و افرادی که علاقه به معاملات املاک  دارند دعوت می نماییم تا به عنوان مشاور وابسته هومینجا شروع به کار کنند تا از عقد قرارداد ها کسب درآمد داشته باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شما با عقد قرارداد داخلی در شرکت وب گستران سورین به عنوان مشاور وابسته معرفی می شوید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از جمله خدمات هومینجا به این دسته از هم وطنان شامل :
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                پنل حرفه ای با مدیریت و حسابداری قراردادها
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                دسترسی به تمام فایلهای هومینجا
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                امکان ثبت نامحدود درخواست ملک
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                دریافت کمیسیون از قراردادهای فعال شما
              </Typography>
              <Typography className="p" component='li' sx={{ textAlign: 'justify' }}>
                تخصیص بالای اعتبار برای رایگان شدن تمام آگهی های شما
              </Typography>
              <Typography className="h4" variant="h4">
                پس با هومینجا واقعا یک کلیک تا قرارداد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                منبع : <LinkMui className="link" href="https://www.evimshahane.com/5-%d9%88%d8%a8-%d8%b3%d8%a7%db%8c%d8%aa-%d8%a8%d8%b1%d8%aa%d8%b1%d8%ae%d8%b1%db%8c%d8%af-%d9%88-%d9%81%d8%b1%d9%88%d8%b4-%d8%a7%d9%85%d9%84%d8%a7%da%a9%d8%8c-%d8%a2%d9%be%d8%a7%d8%b1%d8%aa%d9%85%d8%a7/" target="_blank"> اویم شاهانه </LinkMui>
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/HomeMag'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    بهترین مقالات املاک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/tehran-zones'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_elahieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در الهیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_elahieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در الهیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("بازار مسکن ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  بازار مسکن

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article48;