import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article23/01.jpg";
import photo02 from "../../images/mag/article23/02.jpg";
import photo03 from "../../images/mag/article23/03.jpg";
import photo04 from "../../images/mag/article23/04.jpg";
import photo05 from "../../images/mag/article23/05.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article23 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "سند زدن ملک اقدامی بسیار حساس است. بسیاری از خریداران و فروشندگان به دلیل عدم آگاهی از مراحل سند زدن ملک با مشکلاتی مواجه میشوند.";
    document.getElementsByTagName('meta')["keywords"].content = "از قولنامه تا سند | نحوه سند زدن ملک | آشنایی با جزییات مراحل سند زدن | مراحل سند زدن خانه"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[22].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[22].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[22].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[22].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[22].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[22].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="مراحل سند زدن ملک - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[22].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سند زدن ملک و یا خانه که به
                آن‌، به‌ نام‌ زدن نیز گفته می‌شود، اقدامی بسیار حساس است. این امر نیاز به‌ دقت و صرف وقت و حوصله زیادی هم دارد. برای این کار باید گام‌هایی را نیز
                پشت سر بگذارید تا مراحل سند زدن یا انتقال سند ملک شما انجام گیرد. این کار با جزئیات فراوانی همراه بوده که باید حتماً به آن‌ها توجه داشته باشید.
                <br />
                در واقع پیدا کردن یک خانه و یا آپارتمان
                و سپس خرید آن در ابتدا اقدامی بسیار ساده به‌ نظر می‌رسد. اما انجام این کارها با دشواری‌هایی نیز همراه بوده و پیچیدگی‌های مخصوص به خود را دارد.
                <br />
                بسیاری از خریداران و فروشندگان به دلیل عدم آگاهی از مراحل سند زدن ملک، از ابتدا تا انتها با مشکلاتی مواجه می‌شوند. این افراد مراحل انتقال
                سند آپارتمان از ابتدا تا انتها را نمی‌دانند. بنابراین با یکی از مهم‌ترین چالش‌ها در فرآیند معاملات ملک و مباحث و موازین حقوقی آن روبرو می­گردند.
                <br />
                در این مقاله قصد داریم مراحل
                سند زدن ملک را از ابتدا تا انتها  مرور و بررسی کنیم. اگر شما هم تمایل دارید گام به گام با مراحل سند زدن ملک  آشنا شوید با هومینجا همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                آشنایی با مراحل سند زدن ملک و معرفی مدارک لازم برای سند زدن ملک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="سند زدن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  آشنایی با مراحل سند زدن ملک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به‌ طور کلی یک معامله ملکی در کشور ایران طی چند مرحله اصلی انجام می‌شود. در ادامه به این مراحل می پردازیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی کامل مناطق تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                گام اول از قولنامه تا سند: توافقات اولیه میان خریدار و فروشنده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس‌ از این‌ که خانه و یا ملک و آپارتمان مورد نظر خود را انتخاب کردید، لازم است اقدام بسیار مهمی
                انجام دهید. این اقدام انجام مذاکره با فروشنده خانه و یا آپارتمان است. با انجام این مذاکرات، توافقاتی اولیه میان خریدار و فروشنده ملک انجام خواهد شد.
              </Typography>
              <Typography className="h3" variant="h3">
                گام دوم از قولنامه تا سند: توافق نهایی میان خریدار و فروشنده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس‌ از این‌ که خریدار و فروشنده در زمینه بسیاری از مسائل از قبیل قیمت ملک و… به
                توافقات و مذاکرات اولیه پرداختند، با پذیرش شرایط ملک به گام بعدی وارد می‌شوند. در این مرحله، جریان معامله ملک رسمی‌تر میشود. در اینجا باید تمامی مطالب
                گفته‌ شده در جریان مذاکرات میان دو طرف به شکل کتبی درآید . سپس مطابق با آن، قراردادی میان آن‌ها تنظیم گردد. به این قرارداد در اصطلاح، قولنامه گفته می‌شود.
                <br />
                قولنامه دارای برخی از مشکلات و معایب است.
                برای نمونه این نوع توافق کتبی در محکمه‌ها قابل پذیرش نبوده و در واقع از قدرت اثبات برخوردار نیست. بنابراین اگر میان خریدار ملک و یا فروشنده آن، اختلافی
                به وجود آید نمی‌توانند به دادگاه و یا محاکم قضایی مراجعه نموده و به آن استناد کنند.به‌ همین دلیل در حال حاضر به‌ جای نوشتن قولنامه، مبایعه نامه می‌نویسند.
              </Typography>
              <Typography className="h4" variant="h4">
                در حقیقت ، تفاوت‌های اساسی میان قولنامه و مبایعه نامه وجود دارد که باید پیش‌ از طی کردن مراحل سند زدن یا انتقال سند ملک به آن توجه داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مبایعه نامه در واقع یک قرارداد و یا سند معتبر به شمار می‌رود. در صورتی‌ که این سند توسط دفاتر
                املاک ثبت شود، به شکل قانونی درمی‌آید. یعنی با استفاده از آن و در صورت بروز هرگونه مشکل میان خریدار و یا فروشنده ملک، چه خریدار باشید و چه فروشنده این
                امکان را دارید که مبایعه نامه خود را به محاکم قضایی ارائه دهید. از طریق آن دادخواهی کنید و ضرر و زیان و خسارت‌های خود را از جریان معامله دریافت کنید.
                <br />
                این در حالی است که قولنامه تنها یک توافق‌نامه‌ کتبی بوده و ارزش
                قضایی و یا حقوقی هم ندارد. مبایعه نامه را می‌توانید خودتان، یعنی خریدار و فروشنده با یکدیگر تنظیم کنند و یا برای نوشتن آن به بنگاه املاک مراجعه کنید.
                <br />
                این متن در واقع نوعی سند غیر رسمی خواهد بود که پیش‌ از رسمی شدن خرید و یا فروش میان دو طرف معامله درج و تنظیم شده‌ است.
                <br />
                در مبایعه نامه ذکر مشخصات خریدار، موضوع و ملک مورد معامله و مشخصات کامل جغرافیایی آن ضرورت دارد و باید مساحت ملک، مشخصات ثبتی آن، مبلغ مورد معامله
                (قیمت واحد و قیمت کل به ریال و یا معادل آن تومان)، زمان و نحوه
                پرداخت وجه، زمان حضور در دفترخانه و تنظیم کردن سند، شرایط خاص خریدار و فروشنده و… درج و ثبت گردد.
              </Typography>
              <Typography className="h4" variant="h4">
                درج دیگر مشخصات در مبایعه نامه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="مبایعه نامه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مشخصات مبایعه نامه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حالت معمول و برای این‌ که معامله شفافیت بیشتری پیدا کند به مبایعه نامه، ویژگی‌ها و مشخصات دیگری را هم اضافه می‌کنند. برای نمونه شرایط پشیمانی
                از معامله و خسارت آن، فریب خوردن در معامله و… را درج می‌نمایند. این کار کمک می‌کند تا معامله‌ شما بهتر انجام‌ شود. بدین ترتیب میزان خسارات آن‌هم کم‌تر شود.
                <br />
                مثلاً مدت زمانی را برای پشیمانی و یا انصراف از معامله مشخص کنید. بسیاری از افراد در این گام به درج این نکته اقدام می‌کنند که مثلاً اگر خریدار یا فروشنده
                ملک تا مدت زمان ۴۸ ساعت پشیمان شده و یا انصراف خود از معامله را اعلام کردند، می‌توانند بدون هیچ‌گونه خسارت و یا عواقبی جریان معامله را بر هم بزنند.
                <br />
                حتی می‌توانید مبلغی را نیز برای خسارت تعیین کنید و یا مواردی را به آن اضافه نمایید که به خریدار و یا فروشنده ملک امکان فسخ قرارداد را می‌دهد.
              </Typography>
              <Typography className="h4" variant="h4" sx={{ textAlign: 'justify' }}>
                همان‌طور که می‌دانید مطابق با قوانین،
                در برخی از موارد، طرفین یک معامله اجازه دارند قرارداد بین‌شان را فسخ کنند. به اجازه و امکان فسخ قرارداد خیار و یا اختیار فسخ معامله گفته می‌شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در بسیاری از موارد خریدار و یا فروشنده ملک، اختیار خیارات را از خود ساقط می‌کند. در بسیاری از مبایعه نامه‌ها عبارتی ( اسقاط کافه خیارات ولو خیار فاحش)
                درج می‌گردد. کاربرد و نوشتن این عبارت در یک مبایعه
                نامه باعث می‌شود تا شما نتوانید طبق هیچ شرایطی معامله انجام‌ شده را فسخ کنید. یعنی حتی اگر پس‌ از انجام معامله متوجه شوید که فریب خورده‌اید، هیچ کاری
                از دستتان بر نخواهد آمد. شما نمی‌توانید به‌ هیچ‌ عنوان معامله را به‌ هم بزنید. پس در این شرایط باید دقت داشته باشید . بدانید که چه خیاراتی را پذیرفته و
                چه خیاراتی را رد می‌کنید. پس‌ از این‌ که تمامی موارد مورد نظر خود را در مبایعه نامه نوشتید، باید خریدار و فروشنده و حداقل دو شاهد زیر آن را امضا کنند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/real-estate-fraud" target="_blank">
                  بیشتر بخوانید : انواع کلاهبرداری در معاملات ملک
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                گام سوم از قولنامه تا سند: کد رهگیری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این گام
                توافقات لازم میان طرفین معامله انجام‌ می شود . همچنین مبایعه‌نامه ای نیز نوشته می شود. پس برای تنظیم سند به بنگاه‌های معامله املاک مراجعه کنید.
                مبایعه نامه خود را در سیستم نرم‌افزاری جامع و سراسری بنگاه‌های املاک ثبت نمایید. لازم است حتماً به بنگاهی معتبر مراجعه کنید که رسمیت داشته باشد.
                <br />
                در بنگاه مشخصات خریدار و فروشنده و ملک مورد معامله در سیستم نرم‌افزاری ثبت می‌گردد
                و یک کد رهگیری برای آن صادر می‌شود. در این‌ صورت امکان فروش یک ملک به چند نفر وجود نخواهد داشت. چرا که در این‌ صورت سیستم با خطا مواجه خواهد شد.
                <br />
                انجام این گام از ضروریات معامله و انتقال سند ملکی است. چراکه
                امکان تقلب و کلاه‌برداری در تمامی معاملات وجود دارد. این احتمال وجود دارد که یک فروشنده، یک ملک و یا آپارتمان را در آن واحد به چند نفر بفروشد.
                <br />
                برای‌اینکه از بروز چنین مشکلاتی پیشگیری به
                عمل آید، بهتر است مطمئن شوید که مبایعه نامه شما از سلامت کافی برخوردار شده‌ است. پس باید در این گام به یک بنگاه املاک مراجعه کرده و درخواست کد رهگیری دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                گام چهارم از قولنامه تا سند: مراجعه به دفتر اسناد رسمی و تشکیل پرونده
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="سند ملک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  امضای قولنامه و انتقال سند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مرحله فروشنده ملک باید با در دست داشتن برخی از مدارک به دفتر اسناد رسمی مراجعه کند تا در آن پرونده تشکیل دهد.
                در هنگام مراجعه به دفاتر اسناد رسمی فروشنده باید شناسنامه و اصل سند را به‌ همراه داشته باشد.از جمله مدارک مورد نیاز برای تشکیل پرونده انتقال
                و سند زدن ملک می‌توان به اصل سند مالکیت و تصویر آن و اصل بنچاق و تصویر آن اشاره کرد. لازم است بدانید که بنچاق به یک کاغذ گفته می‌شود که در سایز
                A2 فراهم شده است . در بنچاق، مشخصات فروشنده
                و خریدار درج شده‌ است. همچنین در این سند باید تمامی هزینه‌هایی که طرفین معامله پرداخت کرده‌اند نیز نوشته شود. در بیشتر موارد به ارائه این سند
                نیازی پیدا نخواهید کرد. چراکه دفترخانه یک سند از آن را از دفترخانه قبلی که فروشنده به آن‌جا مراجعه کرده، دریافت نموده و نزد خود نگه‌داری می‌کند.
                <br />
                از دیگر مدارک مورد
                نیاز برای تشکیل پرونده انتقال و سند زدن ملک می‌توان به تصویر شناسنامه و کارت ملی خریدار و فروشنده اشاره کرد و یا اصل و کپی وکالت‌نامه، اصل پایان
                کار و یا عدم خلافی و کپی آن، صورت مجلس تفکیکی درباره آپارتمان، اصل حصر وراثت و کپی آن، اصل فرم مالیات بر ارث و کپی آن در صورت لزوم و… را نام برد.
              </Typography>
              <Typography className="h3" variant="h3">
                گام پنجم از قولنامه تا سند: استعلام از شهرداری و پرداخت مالیات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مرحله، فروشنده لیست مدارک مورد نیاز را که از
                دفترخانه اسناد رسمی دریافت نموده را تکمیل می‌کند. وی باید به ارگان‌هایی از قبیل شهرداری، دارایی، بخشداری مراجعه کرده و استعلامات مورد نیاز را انجام دهد.
                <br />
                از سوی دیگر دفترخانه اسناد رسمی نیز وظیفه دارد برای انتقال سند از ارگان‌های شهرداری، دارایی، بیمه (برای املاک تجاری) بخشداری (برای املاک خارج از محدوده شهری)
                و… استعلام نموده و به این نکته پی ببرد که ملک مورد نظر شما بدهی دارد یا خیر.
                <br />
                همچنین به بررسی این موضوع پرداخته می‌شود که سند گرو نبوده ؟ و یا در طرح ساخت و ساز شهرداری واقع نشده
                است تا از طریق این بررسی‌ها اجازه معامله ملک مورد نظر صادر گردد. در غیر این صورت و با وجود اشکال در هر کدام از این فرایندها، اجازه معامله صادر نخواهد شد.
                <br />
                پس‌ از آن فروشنده ملک، نامه و یا نوشته‌ای را از دفتر اسناد رسمی دریافت می کند. سپس به مراحل دیگری اقدام می‌کند که برای به نام کردن آپارتمان و یا سند زدن الزامی هستند.
              </Typography>
              <Typography className="h4" variant="h4">
                برای نمونه در این مرحله باید به شهرداری و یا بخشداری مراجعه کنید .
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شهرداری به بررسی این موضوع اقدام می کند که ساختمان و یا
                ملک مورد نظر شما طبق پروانه ساخته‌ شده یا خیر. در صورتی‌ که آپارتمان و یا ساختمان مورد نظر مطابق با پروانه ساخته نشده باشد، فروشنده ملزم به پرداخت جریمه و خسارت خواهد شد.
                از سوی دیگر فروشنده باید عوارض نوسازی ساختمان را نیز پرداخت و هزینه‌ای را هم برای جمع کردن
                زباله، کمک به آموزش‌ و پرورش و… به شهرداری پرداخت کند. یکی دیگر از ارگان‌هایی که باید فروشنده ملک به آن مراجعه کند تا مراحل سند زدن یا انتقال سند ملک را به اتمام برساند،
                دارایی است. بسته
                به قیمت ملک چیزی در حدود ۴ درصد مالیات نقل‌ و انتقال خواهد داشت. اگر ملک مورد معامله تجاری باشد فروشنده باید به بیمه نیز مراجعه کند و اقدامات مورد نیاز را انجام دهد.
              </Typography>
              <Typography className="h3" variant="h3">
                گام آخر از قولنامه تا سند: عملیات انتقال سند ملک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="انتقال سند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قولنامه تا انتقال سند با هومینجا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس‌ از این‌که در گام قبل، فروشنده تمامی بدهی‌های ملک مورد نظر را پرداخت کرد، در این مرحله از مراحل سند زدن ملک، عملیات انتقال سند انجام خواهد شد.
                <br />
                در حالت معمول خریدار ملک و فروشنده آن در مبایعه نامه، تاریخی را در نظر می گیرند. مطابق با آن تاریخ مدنظر، به دفترخانه اسناد رسمی
                مراجعه می‌نمایند. در این مرحله فروشنده باید تمامی مدارک مورد نیاز را با خود به‌ همراه داشته باشد. همچنین پاسخ‌ استعلام‌های دریافت شده از ارگان‌ها را نیز به دفترخانه ارائه دهد.
                <br />
                این امکان وجود دارد که یکی از طرفین معامله در دفترخانه حاضر نشود و طرف دیگر معامله هم نتواند به‌ هیچ‌ عنوان با وی تماس برقرار کند.
                در این‌ صورت طرف حاضر شده، ملزم است تا پایان ساعت اداری در دفتر خانه بنشیند. در صورت عدم حضور طرف دیگر معامله، می تواند یک گواهی عدم حضور از دفترخانه دریافت کند.
                <br />
                با استفاده از گواهی عدم حضور، یک طرف معامله می‌تواند به دادگاه صالحه مراجعه کند و هزینه و یا خسارت پشیمانی و انصراف را
                از طرف دیگر معامله دریافت نماید. اما اگر هر دو طرف در تاریخ تعیین‌ شده در دفترخانه اسناد رسمی حاضر شوند، پس از ارائه مدارک، عملیات انتقال سند انجام خواهد شد.
              </Typography>
              <Typography className="h2" variant="h2">
                هزینه‌های سند زدن ملک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در گام ثبت مبایعه نامه در
                سیستم نرم‌افزاری جامع و سراسری، دفتر معاملات از هر دو طرف معامله به میزان ۵ درصد از قیمت اصلی ملک، هزینه دریافت خواهد کرد. از سوی دیگر فروشنده ملک باید به شهرداری نیز
                هزینه‌هایی را پرداخت کند. این هزینه‌ها بابت خلافی، نوسازی ساختمان و یا جمع‌آوری زباله و… دریافت خواهند شد. هزینه‌ای هم بابت نقل‌ و انتقال توسط دارایی دریافت خواهد شد.
                <br />
                اگر مورد معامله، پیش‌ از آن اجاره داده‌ شده باشد، باید مالیات بر اجاره هم پرداخت شود.
                این هزینه هم توسط دارایی دریافت خواهد شد. دفترخانه نیز هزینه‌ای را به‌ عنوان حق‌التحریر دریافت خواهد کرد. این هزینه به‌ صورت مساوی از خریدار و فروشنده دریافت می‌گردد.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/transferring-property" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/transferring-property" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/transferring-property" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article23;