import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article54/01.jpg";
import photo02 from "../../images/mag/article54/02.jpg";
import photo03 from "../../images/mag/article54/03.jpg";
import photo04 from "../../images/mag/article54/04.jpg";
import photo05 from "../../images/mag/article54/05.jpg";
import photo06 from "../../images/mag/article54/06.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article54 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "محله فرمانیه تهران که به پنت هاوس های لوکس شناخته می شود در منطقه یک شهرداری تهران قرار دارد و بهشت برج های لوکس تهران نیز نامیده می شود. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "همه چیز در مورد محله فرمانیه | خرید ملک در فرمانیه | فروش ملک در فرمانیه | رهن و اجاره ملک در محله فرمانیه | امکانات خیابان فرمانیه | قیمت ملک و آپارتمان در فرمانیه تهران | معرفی کامل محله فرمانیه در منطقه ۱ تهران | بهترین خیابان فرمانیه | قیمت خانه در فرمانیه"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[53].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[53].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[53].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[53].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[53].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[53].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="فرمانیه تهران - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[53].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فرمانیه یکی از مناطق مرفه نشین تهران می باشد. این محله با اینکه ظاهری نوساز و لوکس دارد اما دارای قدمتی ۲۰۰ ساله است. ما در این مقاله از هومینجا به بررسی کامل این منطقه و امکانات آن می پردازیم. با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                فرمانیه تهران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="محله فرمانیه تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  محله فرمانیه تهران
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیابان فرمانیه که در <Link className="link" to="/tehran-zone-1" target="_blank">  منطقه یک شهرداری </Link> تهران قرار دارد از جنوب به <Link className="link" to="/Listings/buy_tehran_ghaitarieh" target="_blank">  قیطریه </Link>، از شرق به <Link className="link" to="/Listings/rent_tehran_kashanak" target="_blank">  کاشانک </Link> و از غرب به <Link className="link" to="/Listings/buy_tehran_hekmat" target="_blank">  حکمت </Link> وصل می شود. فرمانیه از تقاطع <Link className="link" to="/Listings/rent_tehran_pasdaran" target="_blank">  پاسداران </Link> شروع می شود و تا چهار راه <Link className="link" to="/Listings/buy_tehran_kamranieh" target="_blank"> کامرانیه </Link> و بلوار <Link className="link" to="/Listings/buy_tehran_andarzgoo" target="_blank">  اندرزگو </Link> ادامه پیدا می کند. این محله به دلیل وجود ساختمان های مجلل و پنت هاوس های سوپر لوکس در منطقه یک شناخته شده است.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زمین های فرمانیه در سال 1326 توسط <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%AD%D8%B3%DB%8C%D9%86_%D9%85%DB%8C%D8%B1%D8%B2%D8%A7_%D9%81%D8%B1%D9%85%D8%A7%D9%86%D9%81%D8%B1%D9%85%D8%A7#:~:text=%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%AD%D8%B3%DB%8C%D9%86%20%D9%81%D8%B1%D9%85%D8%A7%D9%86%D9%81%D8%B1%D9%85%D8%A7%D8%A6%DB%8C%D8%A7%D9%86%20%D8%AF%D8%B1%20%D8%B2%D9%85%D8%A7%D9%86%20%D9%82%D8%A7%D8%AC%D8%A7%D8%B1,%D8%AE%D9%88%D8%AF%20%D8%B1%D8%A7%20%D8%A7%D8%B2%20%D8%AF%D8%B3%D8%AA%20%D8%AF%D8%A7%D8%AF." target="_blank">  عبدالحسن میرزا فرمانفرما </LinkMui> نخست وزیر زمان قاجاریه از محمد ولی خان آصف السلطنه خریداری شد. به همین دلیل این محله فرمانیه نامگذاری شد. فرمانفرما دارای چندین همسر و فرزندان زیادی بود. بخش اعظمی از ثروت خود را بین فرزندان همسر اولش تقسیم بندی کرد. یکی از فرزندان وی بنام فیروز که به ولخرجی نیز شهرت داشت بخشی از املاک خود را به سفارت ایتالیا فروخت. به دلیل قرار گرفتن باغ و سفارت ایتالیا در این خیابان، محله فرمانیه دارای آب و هوای بسیار مطبوعی است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Andarzgoo-blvd" target="_blank">
                  بیشتر بخوانید : خیابان اندرزگو تهران کجاست
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                امکانات منطقه فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ازویژگی های این منطقه می توان به آب و هوای دلپذیر به دلیل وجود بوستان‌ها و فضای سبز گسترده و امنیت بالای محله اشاره کرد. همچنین فرهنگ و امکانات رفاهی فرمانیه از سطح بالایی برخوردار است. <Link className="link" to="/north-of-Tehran-cafe" target="_blank">  کافه </Link> ها و رستوران های لاکچری و <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">  برج های لوکس </Link> بسیاری در این منطقه وجود دارد. همچنین مراکز فرهنگی و آموزشگاه های معتبر بسیاری در فرمانیه قرار دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                برج های معروف فرمانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="برج خیابان فرمانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  برج خیابان فرمانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فرمانیه را می توان بهشت برج های لوکس و گران قیمت تهران دانست.
              </Typography>
              <Typography className="h3" variant="h3">
                برج آسمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج به دلیل سازه بلندی که دارد به برج آسمان معروف است. ارتفاع این برج به 100 متر می رسد که در 37 طبقه و 104 واحد مسکونی تعبیه شده است. واحدهای این برج مسکونی، اداری و تجاری می باشد. دفتر مرکزی کشتیرانی جمهوری اسلامی نیز در این برج واقع است.
              </Typography>
              <Typography className="h3" variant="h3">
                برج‌های دوقلوی فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج از قدیمی ترین برج های این منطقه می باشد که در دهه 70ساخته شده است. برج دوقلوی فرمانیه در خیابان یگانه فرمانیه و در نزدیکی بیمارستان فرمانیه و بام برند سنتر قرار دارد. این برج دارای 30 طبقه و 300 واحد مسکونی می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                برج مسکونی تجاری اداری کوه نور
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مجتمع کوه نور در انتهای بلوار اندرزگو از محله فرمانیه قرار دارد. این برج در نزدیکی مرکز خرید سانا و امامزاده علی اکبر چیذر واقع است.
              </Typography>
              <Typography className="h3" variant="h3">
                برج رولکس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از گرانترین برج های تهران را می توان برج رولکس نام برد. این برج توسط محمد حیدری در 16 طبقه و 52 واحد طراحی شده است. معماری خاص این برج به گونه ای است که این مجموعه تمامی امکانات یک هتل پنج ستاره را دارا می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                برج زنبق
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برج زنبق فرمانیه در 22 طبقه و 57 واحد مسکونی ساخته شده است و یکی از برج های معروف فرمانیه می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                مجتمع مسکونی فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج که در دهه 90 به بهره برداری رسید شامل  9 طبقه به مساحت 35620متر مربع می باشد. فرم هندسی و استوانه ای این مجموعه همراه با سطوح افقی که فضای بیرونی را در هر طبقه شکل می دهد <Link className="link" to="/Interior-architecture-and-interior-design" target="_blank">  معماری </Link> منحصر به فردی به این برج داده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">
                  بیشتر بخوانید : الهیه تهران کجاست ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                خیابان های مهم فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تقاطع خیابان لواسانی و پاسداران از بهترین نقاط این محله به حساب می آید. همچنین خیابان <Link className="link" to="/Listings/buy_tehran_dibashomali" target="_blank">  دیباجی شمالی </Link> نیز از خیابان های اعیان نشین فرمانیه به شمار می رود. همچنین دیگر خیابان های مهم فرمانیه را می توان خیابان بازدار، تنگستان، کوهستان و خیابان شهید ظهری نام برد. اما مهم بودن خیابان های فرمانیه بستگی به سلیقه شما نیز دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد پیاده روی در فضای سبز دارید پیشنهاد ما به شما خیابان درختی فرمانیه می باشد که دارای پیاده‌روهای وسیع و راحت است و فروشگاه های زیادی نیز در آن دیده می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                اماکن مهم فرمانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="باغ سفارت ایتالیا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  باغ سفارت ایتالیا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فرمانیه را می توان بعنوان یکی از محله‌های دیپلماتیک تهران دانست. این محله را نمی توان تنها میزبان باغ سفارت <LinkMui className="link" href="https://ambteheran.esteri.it/ambasciata_teheran/it" target="_blank">  ایتالیا </LinkMui> دانست بلکه سفارتخانه‌ها و سکونت‌گاه‌ دیپلمات‌های دیگر کشورها نیز در این محله هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                سفارت خانه های فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باغ و سفارت ایتالیا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.swedenabroad.se/es/embajada/iran-teheran/faq-to-the-embassy/how-can-i-get-an-appointment-to-visit-the-migration-section" target="_blank">  سفارت سوئد </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت پادشاهی هلند
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت پادشاهی نروژ
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت جمهوری چک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت ترکمنستان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت چین
              </Typography>
              <Typography className="h3" variant="h3">
                سایر اماکن فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه بانک ملت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://pardisfarmanieh.com" target="_blank">  مجتمع پردیس فرمانیه </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اداره راه و شهرسازی شهرستان شمیرانات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="http://www.irisl.net" target="_blank">  کشتیرانی جمهوری اسلامی ایران </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سازمان بنادر و دریانوردی جمهوری اسلامی ایران
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                هلال احمر شمیرانات
              </Typography>
              <Grid className="box">
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  بیشتر بخوانید : معرفی کامل محله نیاوران تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مراکز خرید فرمانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="مرکز خرید فرمانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مرکز خرید فرمانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                علاوه بر فروشگاه شهروند و بازار میوه و تره بار لواسانی که برای رفع مایحتاج افراد ساکن در فرمانیه وجود دارد، پاساژها و مراکز خرید لوکس و مدرنی نیز در این منطقه احداث شده است که در زیر به برخی از انها اشاره می کنیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="http://www.artemisfarmanieh.com" target="_blank">  پاساژ آرتمیس </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز خرید فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                برند سنتر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پارک سنتر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز خرید کوهستان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کوه نور فرمانیه
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز تفریحی فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجموعه ورزشی فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                استخر الغدیر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                باشگاه اسکواش دیباجی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان سنبل
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان دستواره
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان نارنجستان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان دیباجی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان صدر
              </Typography>
              <Typography className="h2" variant="h2">
                موزه ها و بناهای تاریخی فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                عبارت و باغ فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                موزه و نمایشگاه نقشه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بام چیذر
              </Typography>
              <Typography className="h2" variant="h2">
                مراکز درمانی فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://farmanieh-hospital.com/fa" target="_blank">  بیمارستان فرمانیه </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کلینیک زیبایی فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز جراجی کیان فرمانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ساختمان پزشکان فرمانیه
              </Typography>
              <Typography className="h2" variant="h2">
                مسیر رفتن به فرمانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به اینکه نزدیکترین مترو به فرمانیه نوبنیاد یا <Link className="link" to="/Listings/buy_tehran_aghdasieh" target="_blank">  اقدسیه </Link> می باشد بنابراین دسترسی به این محله با مترو چندان ساده نیست. اما از آنجا که خطوط اتوبوسرانی و تاکسی میدان نوبنیاد و  تجریش به مینی‌سیتی از فرمانیه عبور می‌کنند می توان از این طریق به فرمانیه دسترسی پیدا کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مهم‌ترین خطوط اتوبوسرانی فرمانیه، خطوط اتوبوس پایانه لاله به پارک وی، شهرک صدف به بلوار صبا و میدان <Link className="link" to="/Listings/buy_tehran_azgol" target="_blank">  ازگل </Link> به <Link className="link" to="/Listings/buy_tehran_tajrish" target="_blank">  تجریش </Link> را می توان نام برد. همچنین از طریق اتوبان امام علی (ع)، اتوبان صدر و اتوبان ارتش نیز براحتی می توان وارد محله فرمانیه شد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه 1 تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                قیمت آپارتمان در فرمانیه تهران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="قیمت آپارتمان در فرمانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  قیمت ملک در فرمانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با وجود اینکه قیمت ملک و آپارتمان در فرمانیه بسیار بالاست اما قیمت در تمامی قسمت های فرمانیه یکسان نیست. قیمت آپارتمان در فرمانیه به عوامل متعددی بستگی دارد. از جمله این عوامل می توان به سن بنا، کیفیت متریال استفاده شده، نوع ملک اشاره کرد. اما بطور کلی دلیل گرانی ملک در فرمانیه را می توان متراژهای بالای واحدها و آب و هوای دلپذیر این منطقه دانست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حال حاضر (تاریخ نگارش مقاله 1402/02) قیمت هر متر مربع آپارتمان در فرمانیه بین 130 میلیون تومان تا 450 میلیون تومان متغیر می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد <Link className="link" to="/Listings/buy_tehran_farmaniyeh" target="_blank">  خرید ملک در فرمانیه </Link> را دارید با <Link className="link" to="/Contact" target="_blank">  مشاوران هومینجا </Link> در تماس باشید تا بهترین ملک با مناسب ترین قیمت به شما پیشنهاد داده شود.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد شاید تصمیم به سکونت در منطقه فرمانیه را داشته باشند اما آشنایی کاملی از این خیابان نداشته باشند. ما در این <Link className="link" to="/Farmanieh-Neighborhood" target="_blank">  مقاله </Link> سعی کردیم تا به معرفی کامل این محله بپردازیم. امید است که مطالعه این مطلب در انتخاب شما مفید واقع شود.
              </Typography>
              <Typography className="h4" variant="h4">
                با هومینجا یک کلیک تا قرارداد
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_farmaniyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در فرمانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_farmaniyeh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره اپارتمان در فرمانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_andarzgoo'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در اندرزگو
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_kamranieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در کامرانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_kamranieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره اپارتمان در کامرانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش ملک در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_niyavaran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره ملک در نیاوران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article54;