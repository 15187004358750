import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article57/01.jpg";
import photo02 from "../../images/mag/article57/02.jpg";
import photo03 from "../../images/mag/article57/03.jpg";
import photo04 from "../../images/mag/article57/04.jpg";
import photo05 from "../../images/mag/article57/05.jpg";
import photo06 from "../../images/mag/article57/06.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article57 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = " اینترنت اشیا یعنی ارتباط دستگاه‌ها با اینترنت که از طریق این ارتباط کاربران دارای دسترسی مجاز به این شبکه، امکان کنترل این لوازم را دارند.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "کاربرد اینترنت اشیا | اینترنت اشیا به زبان ساده | اینترنت اشیا و مدیریت انرژی | نحوه کار اینترنت اشیا | IOT به زبان ساده | تاریخچه اینترنت اشیا | Internet Of Things | اینترنت اشیا در ایران "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[56].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[56].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[56].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[56].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[56].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[56].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="اینترنت اشیا - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[56].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در دنیای امروزی شاهد فناوری های نوظهوری هستیم که یکی از این فناوری ها اینترنت اشیا می باشد. شاید بارها این واژه به گوشتان خورده باشد اما ندانید که تا چه حد در حوزه تکنولوژی تاثیرگذار بوده است. این فناوری تمام جوانب زندگی را دستخوش تغییر کرده است که از مهمترین کاربردهای آن می توان پیاده سازی در <Link className="link" to="/smart-home" target="_blank">  خانه های هوشمند </Link> را نام برد. بنابراین با ما در این مقاله از <Link className="link" to="/" target="_blank">  هومینجا </Link> همراه باشید تا شما را با این علم جدید آشنا کنیم.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/premade-canopy" target="_blank">
                  بیشتر بخوانید : سایبان پیش ساخته و انواع آن
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                اینترنت اشیا چیست
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="اینترنت اشیا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  IOT
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اینترنت اشیا یا IOT که مخفف کلمه Internet Of Things می باشد به ارتباط اینترنتی بین اشیا و تجهیزات اشاره دارد. لوازمی که به اینترنت وصل می شوند توسط نرم افزارهایی که در تلفن‌همراه، کامیپوتر، تبلت‌، ساعت‌هوشمند و تلویزیون‌ نصب هستند، کنترل می شوند. IOT یک ایده نوین در تکنولوژی دنیاست که کمک شایانی در تمام جنبه های زندگی دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به زبان ساده می توان گفت اینترنت اشیا یعنی زمانی که دستگاه ها با شبکه وب ارتباط برقرار می کنند کاربرانی که دسترسی به این شبکه دارند می توانند کنترل لوازم متصل به شبکه را در دست بگیرند.
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت IOT و IOE
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیلی از افراد مفهوم یکسانی برای این دو واژه در نظر می گیرند در صورتی که IOT به اینترنت اشیا و IOE مه مخفف کلمه Internet Of Everythings هست، به اینترنت همه چیز اشاره دارد. از دیگر تفاوت های بین IOT و IOE می توان به این موضوع پرداخت که IOT تنها اشیای متصل به شبکه را هدف قرار می دهد در صورتی که IOE به داده‌ها، ارتباط انسان و ماشین و حتی ترکیبی از آن‌ها توجه دارد.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه اینترنت اشیا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="تاریخچه IOT - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاریخچه IOT
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بین سال های 1980 تا 1983 بود که شرکت <LinkMui className="link" href="https://www.coca-colacompany.com" target="_blank">  کوکاکولا </LinkMui> تصمیم گرفت در یک طرح آزمایشی در لابراتوار برای کاهش نیروهای انسانی و هزینه اضافی، بخش کوچکی از شرکت را کنترل کند. بعد از آن در چند آزمایشگاه دیگر کارهای مشابهی انجام شد تا توانستند به کمک اینترنت برخی از کارها را به صورت ریموت کنترل کنند. پس از آن در سال ١٩٩۴ انجمن علمی <LinkMui className="link" href="https://www.ieee.org" target="_blank">  IEEE </LinkMui> با تعیین استاندارد هایی این فناوری جدید را مطرح کرد. اما سرانجام در سال ١٩٩٩ اینترنت اشیا توسط <LinkMui className="link" href="https://en.wikipedia.org/wiki/Kevin_Ashton" target="_blank">  کوین اشتون </LinkMui> (Kevin Ashton) و همکارانش در مقاله ای علمی ارائه شد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تکنولوژی IOT یکی از فناوری های برتر جهان چهارم می باشد. کشورهای زیادی در سراسر دنیا از جمله آمریکا، چین، آلمان، سنگاپور و برزیل و کره جنوبی برای راه اندازی و بهره وری از آن پیشگام شدند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال یا دیوار شاخص چیست
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاریخچه اینترنت اشیا در ایران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ایران هم چند سالی است که بعنوان بیستمین کشور در این عرصه گام نهاده است. اولین نفری که بحث اینترنت اشیا را در ایران مطرح کرد رضا راجی از توسعه دهندگان اینترنت اشیا و کارآفرینان این حوزه بود که اکنون به عنوان مشاور در شرکت های بنام و بزرگ آمریکا فعال است. از نظر عملیاتی می توان هوشمند سازی سیستم های برق را اولین بهره گیری از فناوری IOT در ایران دانست. IOT در ایران همچنین در زمینه های طراحی خانه هوشمند ، ساخت بیمارستان های هوشمند ، طراحی پارکینگ هوشمند و هوشمند سازی دیتاسنتر ها نیز نقش مهمی ایفا کرده است.
              </Typography>
              <Typography className="h2" variant="h2">
                فعالان حوزه IOT در ایران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                گرچه حوزه استفاده از اینترنت اشیا در ایران خیلی گسترده نیست اما شرکت هایی هستند که در این حوزه فعال هستند. از جمله فعالان این تکنولوژی می توان شرکت های <LinkMui className="link" href="http://www.favamouj.com" target="_blank">  فاواموج </LinkMui>، <LinkMui className="link" href="http://www.secaas.ir" target="_blank">  سیکاس </LinkMui>، پارس نت و مگفا را نام برد. طبق اظهارات مجید رسولی، مجری طرح اینترنت اشیا، ۱۵۰ شرکت خصوصی در حوزه IoT فعالیت می کنند. از این تعداد ۶۳ درصد از آنها در بخش های ارتباطات، ۵۰ درصد در ارائه سرویس ها و اپلیکیشن ها، ۴۲ درصد در بخش سخت افزار، سنسورها، نگهداری و پردازش اطلاعات و باقی در بخش پلتفرم ها و امنیت فعال می باشند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هم چنین شرکت <LinkMui className="link" href="https://sorinapp.com" target="_blank">  وب گستران سورین </LinkMui> با تجربه در مبحث تولید نرم افزار نیاز مهندسان ایرانی را مرتفع کرده است.
              </Typography>
              <Typography className="h2" variant="h2">
                کاربردهای اینترنت اشیا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کاربرد اینترنت اشیا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاربرد اینترنت اشیا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                IOT در زندگی روزمره کاربردهای چشمگیری دارد که ما در ادامه به آن می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                سلامت هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اینترنت اشیا در این قسمت سنسورهایی هستند که به بدن بیمار متصل می شوند و علائم حیاتی بیمار را کنترل می کنند و سپس این علائم را به مرکز بررسی سلامت می فرستند.
              </Typography>
              <Typography className="h3" variant="h3">
                خودروهای هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاربرد اینترنت اشیا را در خودروهای هوشمند می توان سنسورهایی دانست که میزان باد تایرهای ماشین را بررسی می کنند و چنانچه مشکلی باشد راننده را مطلع می کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                خانه‌های هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سیستم های گرمایشی و سرمایشی با استفاده از تلفن همراه موقعیت جغرافیایی صاحب خانه را دریافت می کنند و گرمایش و سرمایش خانه را تنظیم می کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                شهر هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای هوشمند سازی یک شهر می توان ایده هایی مانند کنترل ترافیک، روشنایی سطح شهر، مدیریت جای پارک خودروها، آبیاری هوشمند فضای سبز را مطرح کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                محیط زیست:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در حوزه ی محیط زیست با بهره برداری از حسگرها، بر کیفیت آب و هوا و شرایط جوی نظارت می شود. از دیگر کاربردهای اینترنت اشیا در این زمینه می توان به جلوگیری از جنگل زدایی اشاره کرد. بدین ترتیب که هر زمان درختی در معرض آسيب يا قطع شدن قرار می‌گيرد، به محافظین قانونی محیط زیست پیامی با اعلام موقعیت جغرافیایی آن درخت فرستاده می‌شود.
              </Typography>
              <Typography className="h3" variant="h3">
                پوشیدنی‌های هوشمند
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اینترنت اشیا تنها مختص خانه های هوشمند و برنامه آموزشی نیست بلکه شامل پوشیدنی های هوشمند نیز می شود.  در بافت پارچه‌ی این لباس‌ها، لوازم و قطعات الکترونیکی انعطاف پذیری بکار رفته است که موجب می شود مجهز به تکنولوژی هوشمند شوند. با پوشیدن  لباس‌های هوشمند می توانند از وضعیت جسمانی و سلامت خود مطلع شوند.
              </Typography>
              <Typography className="h3" variant="h3">
                سیستم‌های امنیتی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای برقراری امنیت با IOT می توان، بررسی خودکار درب‌های ورودی، دوربین‌های مدار بسته و اطلاع‌رسانی در خصوص بروز مشکل را انجام داد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                اینترنت اشیا در هوشمند سازی خانه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="اینترنت اشیا و خانه هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  IOT و خانه هوشمند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امروزه هوشمند سازی خانه بسیار رایج شده است و این فناوری به سرعت در حال رشد و توسعه می باشد.  اینترت اشیا نقش بسیار مهمی در هوشمند کردن خانه ایفا می کند. با استفاده از IOT هر وسیله ای از خانه می تواند به اینترنت وصل شود. در زیر به بررسی تعدادی از این تجهیزات اشاره ای خواهیم داشت.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                کنترل کننده مرکزی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با هوشمند سازی، خانه مجهز به یک سیستم کنترل کننده مرکزی و خودکار می‌شود که هسته اصلی یک خانه هوشمند می باشد. با استفاده از این سیستم می‌توان به صورت ریموت با نرم افزارهایی که روی  شبکه اینترنت هستند، تمام امور را مدیریت کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                سنسورهای هوشمندسازی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سنسورهای هوشمند سازی، تجهیزات هوشمندی هستند که اطلاعات را از اطراف می گیرند و در اختیار پردازنده مرکزی می گذارند. بسته به انتظاراتی که از خانه هوشمند می رود، سنسور های مختلفی برای هر پروژه انتخاب می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                پنجره هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در پنجره های هوشمند تنها با فشردن یک دکمه می توان نور را تنظیم کرد که این سطح از کنترل، صرفه جویی بسیاری در هزینه های گرمایشی و روشنایی  به همراه دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify', fontWeight: "700" }}>
                یخچال هوشمند:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با استفاده از این قابلیت در خانه های هوشمند می توان از محتوای داخلی یخچال بدون نیاز به باز کردن درب آن مطلع شد. همچنین در صورتی که در عملکرد قطعه ای اختلال ایجاد شود و یا فیلتر نیاز به تعویض داشته باشد می توان هشدار دریافت کرد. از دیگر قابلیت های یخچال هوشمند می توان به تنظیم آب گرم و سرد با درجه حرارت دلخواه اعضای خانواده و یا بسته شدن درب یخچال زمانی که هر دو دست پر است، اشاره کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                مزایای اینترنت اشیا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دستگاه های الکترونیکی با یکدیگر ارتباط پیدا می کنند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بسته های اطلاعاتی در یک شبکه انتقال می یابند که باعث صرفه جویی در زمان و هزینه می شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مداخله انسان کمتر می شود و در نتیجه خطا کاهش می یابد و سطح کیفیت زندگی با خودکارسازی وظایف بالا می رود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دسترسی به داده ها در هر زمان و مکانی امکانپذیر می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                معایب اینترنت اشیا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                با افزایش دستگاه های متصل به شبکه، حجم بالاتری از اطلاعات جابجا می شود بنابراین زمینه برای هکرها و نفوذهای غیر قانونی نیز بیشتر می شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدیریت با تعدد ادوات IOT با مشکل روبرو می شود.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                از آنجایی که تمام دستگاه ها به شبکه متصل هستند ایجاد مشکل در یک سیستم ممکن است بقیه دستگاه ها را نیز با مشکل مواجه کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Best-Iranian-Real-Estate-Site" target="_blank">
                  بیشتر بخوانید : سایت آنلاین تخصصی خرید و فروش، رهن و اجاره املاک و مستغلات هومینجا
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرکت های فعال اینترنت اشیا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="شرکت CISCO - هومینجا" className="image" />
                <figcaption className="image_caption">
                  CISCO
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شرکت های مطرحی هستند که در زمینه اینترت اشیا فعالیت دارند . در زیر به معرفی این شرکت ها می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                شرکت Cisco
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تاسیس این شرکت در سال ۱۹۸۴ بود. <LinkMui className="link" href="https://www.cisco.com" target="_blank">  Cisco </LinkMui> یکی از شرکت های بزرگ ارائه دهنده سخت افزار شبکه می باشد. این سخت افزارها نقشی اساسی در اینترنت اشیا دارند. سیسکو به تازگی راهکارهایی را برای کمک به امنیت اینترنت اشیا به نام  Manufacture Usage Description یا MUD ارائه کرده است. هدف اصلی MUD، کاهش یا حذف فرایندهای شناسایی تجهیزات ایترنت اشیا برای مدیران و کارشناسان امنیت شبکه می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                شرکت Bosch
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.bosch.com" target="_blank">  بوش </LinkMui> را می توان از پرچم داران اینترنت اشیا دانست. این شرکت بسترهای متفاوتی از IOT را پشتیبانی می کند. شرکت BOSCH سابقه ای طولانی در این زمینه دارد و تاکنون 250 پروژه و 10میلیون دستگاه متصل به IoT را مدیریت کرده است.
              </Typography>
              <Typography className="h3" variant="h3">
                شرکت Alphabet
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شرکت که مادر شرکت گوگل می باشد از بزرگترین شرکت های حمایت کننده در حوزه IOT می باشد. گوگل علاوه بر اینکه اصلی ترین موتور جست‌و‌جوی جهان می باشد، محصولات مانیتورینگ و نظارت بر منازل را هم تحت عنوان Nest  عرضه می کند.  Google Cloud IoT را شرکت گوگل با استفاده از قدرت تحلیل داده خود ارائه داده است که مجموعه‌ای کامل از ابزارها را برای اتصال دستگاه‌ها با هدف تحلیل داده و افزایش کیفیت بازدهی می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                شرکت IBM
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.ibm.com/de-de" target="_blank">  IBM </LinkMui>، شرکتی قدیمی و معروف در زمینه سخت‌افزار و نرم‌افزار محسوب می شود. همچنین در زمینه اینترنت اشیا نیز فعالیت وسیعی دارد. این شرکت در زمینه IOT پلتفرمی را به نامWatson IoT روانه بازار کرده است.
              </Typography>
              <Typography className="h3" variant="h3">
                شرکت Amazon
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این شرکت نیز در زمینه IOT از رقیبان گوگل به شمار می رود. <LinkMui className="link" href="https://www.amazon.com" target="_blank">  آمازون </LinkMui> چندین محصول مهم را در جهت هوشمند سازی خانه ارائه داده است و همچنان سعی بر این دارد تا تولید و توسعه سیستم‌های IOT بیشتری را برای مدیریت و اتصال دستگاه‌هایش ارائه دهد.
              </Typography>
              <Typography className="h3" variant="h3">
                صرافی ارز دیجیتال کریپتوکامرس
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                صرافی ارز دیجیتال کریپتوکامرس که محصول اصلی شرکت <LinkMui className="link" href="https://sorinapp.com" target="_blank">  وب گستران سورین </LinkMui> می باشد. این صرافی به عنوان معتبرترین صرافی ارز دیجیتال ایرانی اقدام به عرضه رمز ارز <LinkMui className="link" href="https://www.iota.org" target="_blank">  IOTA  </LinkMui> کرده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شما با ورود و ثبت نام در <LinkMui className="link" href="https://mycryptocommerce.com" target="_blank">  سایت کریپتوکامرس </LinkMui> می توانید به دو روش <LinkMui className="link" href="https://mycryptocommerce.com/Store" target="_blank">  فروشگاه </LinkMui> و <LinkMui className="link" href="https://mycryptocommerce.com/Market" target="_blank">  بازار </LinkMui> ارز دیجیتال آیوتا را تهیه بفرمایید تا ار رشد این ارز ریجیتال کسب سود بفرمایید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای اطلاع از نحوه ثبت نام، احراز هویت و خرید ارز دیجیتال آیوتا به <LinkMui className="link" href="https://mycryptocommerce.com/Guide" target="_blank">  راهنمای سایت </LinkMui> صرافی مراجعه بفرمایید.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به این موضوع که امروزه مدیریت زمان و انرژی مبحث مهمی به شمار می رود بنابراین با اینترنت اشیا در مبحث خانه هوشمند می توان در صرفه جویی و بهینه سازی انرژی، گام بزرگی برداشت. امید است که این مطلب از <LinkMui className="link" href="https://homeinja.info" target="_blank">  هومینجا </LinkMui> مفید واقع شود.
              </Typography>
              <Typography className="h4" variant="h4">
                هومینجا در کنار شما تا خانه دار شدن
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("تکنولوژی و فناوری ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  تکنولوژی و فناوری

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article57;