import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article61/01.jpg";
import photo02 from "../../images/mag/article61/02.jpg";
import photo03 from "../../images/mag/article61/03.jpg";
import photo04 from "../../images/mag/article61/04.jpg";
import photo05 from "../../images/mag/article61/05.jpg";
import photo06 from "../../images/mag/article61/06.jpg";
import photo07 from "../../images/mag/article61/07.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article61 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "کافه های بردگیم مکان تفریحی مناسب برای سپری کردن لحظاتی هیجان انگیز برای علاقه مندان به بازی‌ و سرگرمی‌ می باشد. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "معرفی بهترین کافه بازی تهران | معرفی کافه بردگیم تهران | لیست کافه بازی تهران | جذاب ترین کافه بردگیم تهران | کامل ترین کافه بردگیم | بهترین کافه بردگیم های تهران "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[60].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[60].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[60].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[60].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[60].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[60].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کافه بردگیم - هومینجا  " className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[60].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بازی های دسته جمعی این روزها طرفداران زیادی پیدا کرده است. برای انجام این بازی ها می توانید کافه های بردگیم را انتخاب کنید. در این کافه ها امکان انتخاب بازی های متنوع متناسب با هر سن و سلیقه ای وجود دارد. ما در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> به معرفی تعدادی از بهترین کافه بردگیم های تهران می پردازیم. در ادامه با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدون شک کافه بردگیم را یکی از بهترین مکان های تفریحی می توان دانست. کافه بردگیم یا Board Game Cafe به کافه بازی های رومیزی نیز معروف است. فضای این کافه ها به گونه ای طراحی شده است که امکان بازی در کافه را فراهم می آورد. بازی‌های بردگیم بر اساس نوع داستان و قوانینی که دارند دارای دسته‌بندی‌های متفاوتی هستند. در زیر به بررسی این بازی ها می پردازیم.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع بازی های بردگیم
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="انواع بردگیم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انواع بردگیم
                </figcaption>
              </figure>
              <Typography className="h3" variant="h3">
                بازی آموزشی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مدل از بازی ها با اینکه بیشتر برای آموزش کودکان بکار می رود، اما در بزرگسالان نیز کارایی دارد. در این نوع بازی آموزش و بازی در کنار هم قرار دارند. بعنوان مثالی از بازی های آموزشی می توان به بازی های Azul و Kingdomino اشاره داشت که برای اموزش زبان به <Link className="link" to="/children-room-decoration" target="_blank">  کودکان </Link> استفاده می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">
                  بیشتر بخوانید : معرفی بهترین کافه های قلیان دار در تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                بازی معمایی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این دسته می توان بازی‌های استوژیت و سرنج را می توان نام برد که در طول بازی مدام نیاز به تجزیه و تحلیل دارند.
              </Typography>
              <Typography className="h3" variant="h3">
                بازی جاسوسی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بازی های جاسوسی ژانری مافیایی و جاسوسی دارند. هر بازیکن رازی دارد که نباید جاسوس ها از آن مطلع شوند. بازی کودتا را از معروف ترین مدل این نوع بازی می توان نامید.
              </Typography>
              <Typography className="h3" variant="h3">
                بازی تعاملی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مدل بازی برنده شدن از آن گروهی است که با یکدیگر تعامل می کنند. بازی های جالیز و forbidden island، betrayal Netrunner را در این دسته می توان جای داد.
              </Typography>
              <Typography className="h3" variant="h3">
                بازی استراتژیکی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این بازی ها تابع یکسری قانون و قواعدی هستند که با پیروی از آن ها می توان برنده بازی بود. بازی های pandemic Dungeon Petz، Through the Age A new story of civilization را می توان به عنوان نمونه ای از این مدل بازی ها نام برد.
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین کافه بردگیم های تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این بخش سعی بر این داریم تا تعدادی از این کافه بردگیم های معروف تهران را بهمراه آدرس و مشخصات معرفی کنیم.
              </Typography>
              <Typography className="h3" variant="h3">
                کافه برد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه را می توان اولین کافه بردگیم در تهران و دومین کافه بازی بعد از کافه کندو شیراز دانست. این کافه شامل تنوع بالایی از انواع بازی ها متناسب با هر سن و سلیقه ای می باشد. در کافه برد صفر تا صد هر بازی ای که انتخاب می کنید آموزش داده می شود. همچنین در این کافه بازی می توانید از فروشگاه <LinkMui className="link" href="https://madamak.com" target="_blank">  مادمک </LinkMui> واقع در این کافه هر بازی که تمایل دارید خریداری کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : معرفی بهترین کافه های شمال تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس :  خیابان <Link className="link" to="/Listings/buy_tehran_motahari" target="_blank">  مطهری </Link>، نرسیده به <Link className="link" to="/Listings/buy_tehran_sohrovardi" target="_blank">  سهروردی </Link>، ساختمان ۱۲۰، طبقه اول
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس : ۸۸۸۶۷۹۲۴-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                بردگیم فکرکده
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کافه بردگیم فکرکده - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بردگیم فکرکده
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه بردگیم  از مجموعه کافه های زنجیره ای در ایران می باشد. فکرکده علاوه بر 8 شعب فعال در تهران دارای شعبی در شهرهای اصفهان، همدان، اهواز، اراک و کیش نیز می باشد. در برخی از شعب این کافه انواع بازی های فکری، کامپیوتری و همچنین اتاق فرار قرار دارد. یکی از ویژگی های خاص این کافه بازی، طراحی بازی های ایرانی می باشد که سبب تمایز آن از سایر بردگیم ها شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس : <Link className="link" to="/Listings/rent_tehran_jordan" target="_blank">  جردن </Link>، پایین‌تر از <Link className="link" to="/Listings/rent_tehran_esfandiyar" target="_blank">  اسفندیار </Link>، یزدان پناه، پلاک۴۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس : ۸۸۱۹۶۱۸۵-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه اطلسی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کافه اطلسی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه اطلسی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه یکی از کافه های روباز تهران در عمارتی قدیمی در خیابان فلسطین تهران واقع است. کافه اطلسی دارای فضایی سنتی و دلنشین می باشد. در این کافه بازی، علاوه بر انواع بازی های فکری، بازی های کامپیوتری و پلی استیشن نیز به بزگسالان و کودکان ارائه می دهد. همچنین در این کافه بازی مافیا نیز برگزار می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : بهترین کافه کتاب های تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس : میدان <Link className="link" to="/Listings/rent_tehran_felestin" target="_blank">  فلسطین </Link>، خیابان <Link className="link" to="/Listings/buy_tehran_taleghani" target="_blank">  طالقانی </Link> غرب، خیابان سرپرست جنوبی، کوچه پارس، پلاک سه، طبقه سه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس :  ۶۶۹۷۵۲۳۷-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه الک دولک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کافه بردگیم الک دولک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بردگیم الک دولک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه الک دولک یکی از کافه بازی های شمال تهران می باشد که دارای انواع بازی های فکری و مهیج است . در این کافه دوشنبه های جذابی در انتظار مشتریان می باشد که با رخداد های هیجان انگیزی غافلگیر می شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس : تهران، <Link className="link" to="/Listings/buy_tehran_farmaniyeh" target="_blank"> فرمانیه </Link>، <Link className="link" to="/Andarzgoo-blvd" target="_blank">  بلوار اندرزگو </Link>، بن بست تابانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس :  ۲۲۲۴۲۱۸۳-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه دن کلاب
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کافه بردگیم دن کلاب - هومینجا" className="image" />
                <figcaption className="image_caption">
                  بردگیم دن کلاب
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                دن  کلاب را اولین کافه تخصصی بازی <LinkMui className="link" href="https://filmnet.ir/contents/sc6Vyay/The-Godfather" target="_blank">  مافیا </LinkMui> می توان دانست. این کافه همچنین اولین باشگاه بازی های تخصصی بزگسالان به شمار می آید. این کافه در ابتدا با نام دن کار خود را شروع کرد اما پس از گذر زمان و توسعه بازی های کافه به دن کلاب تغییر نام داد. همچنین این کافه جدا از تهران شعب دیگری در شهرهای کرج، اصفهان، کرمانشاه و مشهد نیز دارد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس شعبه مرکزی: فاطمی، <Link className="link" to="/Listings/buy_tehran_golha" target="_blank"> میدان گلها </Link>، ابتدای کاج شمالی پلاک ۱۱۵
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس :  ۸۸۹۵۹۲۰-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه پارادیزو
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر کافه بردگیم تهران می توان به کافه پارادیزو اشاره کرد. این کافه به سبک راک طراحی شده است. گیتارهای الکترونیک آویزان روی دیوار و همچنین مبلمان قرمز داخل کافه محیطی هیجان انگیز را برای مشتریان تداعی می کند که لذت بازی را دو چندان می کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : معرفی بهترین رستوران های منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس: خیابان مطهری ، مهرداد، پلاک ۱۲
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس : ۸۸۸۴۸۲۲-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه ب لین
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ب لین به زبان کردی به معنای عهد و پیمان می باشد. این کافه در نزدیکی پارک هنرمندان می باشد. کافه ب لین دارای دو فضای باز و بسته می باشد و دارای محیطی دنج برای خواندن کتاب و نوشیدن قهوه و همچنین محیطی جالب برای بازی های گروهی می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس: خیابان <Link className="link" to="/Listings/buy_tehran_karimkhanezand" target="_blank"> کریمخان </Link>، خیابان <Link className="link" to="/Listings/rent_tehran_iranshahr" target="_blank">  ایرانشهر </Link>، پایین‌تر از پارک هنرمندان، کوچه دهقانی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس : ۸۶۰۷۲۳۸۵-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه بلوز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه در بازی تخته نرد مهارت دارید پیشنهاد ما به شما کافه بلوز می باشد. در این کافه مسابقات تخته نیز برگزار می شود. البته این کافه بیشتر پاتوق موتورسواران و راک بازها می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس: خیابان سپهبد قرنی، خیابان شاداب غربی، نرسیده به تقاطع ورشو، پلاک ۴٩
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس : ٨٨٨۵٣٢٨۷-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه گیک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="کافه گیک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه گیک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر کافه بردگیم های تهران، کافه گیک را می توان نام برد. در این کافه برای بازی کردن نیازی به سفارش دادن ندارید.  در این کافه هیچ دسترسی به تلویزیون و اینترنت ندارید بنابراین می توانید تمام حواس خود را به بازی بدهید و نهایت لذت را از بازی و سرگرمی های این کافه ببرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس: کریمخان، میرزای شیرازی، نبش یازدهم، خانه‌ موزه بتهوون
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس : ۸۸۳۱۴۶۲۹-۰۲۱
              </Typography>
              <Typography className="h3" variant="h3">
                کافه بازی بازا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه بازی بازا یکی از بهترین کافه بردگیم های تهران به شمار می آید. در این کافه بیش از 450 بازی فکری وجود دارد. همچنین برای شب در این کافه بازی مافیا برگزار می شود. از دیگر ویژگی های این کافه پخش مسابقات مهم فوتبال می باشد که علاقه مندان به این رشته را دور هم جمع می کند. همچنین در این کافه امکان خرید، فروش و تعویض بازی‌های دست دوم نیز فراهم است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : معرفی بهترین مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آدرس: خیابان <Link className="link" to="/Listings/buy_tehran_apadanahaft" target="_blank">  خرمشهر </Link>، پلاک ۱۳۳
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                شماره تماس برای رزرو: ۲۷۹۲۰۷۹-۰۹۱۲
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مقاله از هومینجا سعی بر این داشتیم تا لیستی از بهترین کافه بردگیم های تهران را معرفی کنیم تا با مطالعه آن بهترین کافه را برای بازی و سرگرمی انتخاب کنید. امید است که مطالعه این مطلب برای شما مفید واقع شود.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/best-cafes-iftar-til-suhur'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    بهترین کافه ها برای افطار تا سحر
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/tehran-winter-entertaiments'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    تفریحات زمستانی تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article61;