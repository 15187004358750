import { Autocomplete, Button, Checkbox, Grid, TextareaAutosize, TextField, Typography } from "@mui/material";
import React from "react";
import { baseUrl, checkedIcon, icon } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';

class SendTicket extends React.Component{

  isMount = false ;
  token = localStorage.getItem('tokenUserHomeInja');

    constructor(props){
        super(props);
        this.state={
          giftShow: false ,
          openAlert: false,
          ticketTitle: "",
          categoryOptions: [],
          categorySelected :null,
          ticketMessage: "",
          clearText:'حذف',
          closeText:"بستن",
          openText:"باز کردن",
          noOptionsText:"فاقد اطلاعات",
          typeAlert:3,
          message:""
        }
    }

    componentDidMount(){
      this.isMount = true ;
      document.title = 'ارسال تیکت هومینجا'
      if(this.isMount){
        this.getCategory()
      }
    }

    componentWillUnmount(){
      this.isMount = false ;
    }

    getCategory = () => {
      Promise.resolve(axios({
        url: baseUrl + 'v1/category/getCategory',
        method: 'GET',
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        this.setState({categoryOptions:res.data.data})
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش نمائید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
      });
    }

    closeAlertModal = () => {
      this.setState({openAlert:false});
    }

    onChangeTicketTitle = (event,newValue) => {
      this.setState({ticketTitle:event.target.value});
    }

    onChangeCategory = (event,newValue) => {
      this.setState({categorySelected:newValue});
    }

    onChangeTicketMessage = (event,newValue) => {
      this.setState({ticketMessage:event.target.value})
    }

    onClickSend = () => {
      let title = this.state.ticketTitle ;
      title = title.trim();
      if(title !== ""){
        if(this.state.categorySelected !== null){
          let description = this.state.ticketMessage ;
          description = description.trim();
          if(description !== ""){
            this.sendTicket()
          }else{
            this.setState({openAlert:true,message:"متن تیکت خالی می باشد",typeAlert:3})
          }
        }else{
          this.setState({openAlert:true,message:"دسته بندی تیکت انتخاب نشده است",typeAlert:3})
        }
      }else{
        this.setState({openAlert:true,message:"عنوان تیکت خالی می باشد",typeAlert:3})
      }
    }

    sendTicket = () => {
      this.setState({giftShow:true});
      let ticketTitle = this.state.ticketTitle ;
      ticketTitle = ticketTitle.trim();
      let description = document.getElementById("ticket_Desc").value ;
      description = description.trim();
      Promise.resolve(axios({
        url: baseUrl + 'v1/ticket/create',
        method: 'POST',
        headers: {"Authorization":"bearer "+ this.token},
        data: {
          title: ticketTitle ,
          category_id: this.state.categorySelected.id ,
          message: description
        }
      }))
      .then((res) => {
        this.setState({openAlert:true,message:"تیکت با موفقیت ارسال شد",giftShow:false,typeAlert:1});
        this.resetTicket()
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا مجددا تلاش نمائید در صورت تکرار با پشتیبانی تماس حاصل فرمائید",giftShow:false,typeAlert:2});
      })
      .finally(()=>{
        this.setState({ticketMessage:""})
      })
    }

    resetTicket = () => {
      this.setState({ticketTitle:"",categorySelected:null});
      document.getElementById("ticket_Desc").value = "" ;
    }

    render() {
      return (
        <Grid item style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px'}}}>
          <Grid item xs={12} style={{display:'flex',flexDirection:'row',marginRight:'6%'}}>
            <Typography variant="h5" style={{fontFamily:'shabnam',fontWeight:'bold'}}>ارسال تیکت</Typography>
          </Grid>
          <Grid item xs={12} style={{display:'flex',alignItems:'center',md:"50px",width:'100%'}}
                sx={{flexDirection:{xs:'column'},paddingLeft:{lg:'100px',md:'50px',xs:'10px'},paddingRight:{lg:'80px',md:'40px',xs:'5px'},marginTop:{xs:"10px",md:"50px"}}}
          >    
            <Grid sx={{width:{xs:"90%",md:"60%"}}}>
              <TextField 
                sx={{bgcolor:"#F2F2F2"}}
                fullWidth    
                onChange={this.onChangeTicketTitle}
                className="ads-textfield"
                id="ads-textfield-input"
                value={this.state.ticketTitle}
                placeholder="عنوان تیکت"
                inputProps={{
                  maxLength: 40
                }}
              />
            </Grid>
            <Grid sx={{width:{xs:"90%",md:"60%"},margin:"10px 0"}}>
              <Autocomplete
                id="ads-autocomplete-input"
                className="ads-autocomplete"
                sx={{bgcolor:"#F2F2F2"}}
                options={this.state.categoryOptions}
                getOptionLabel={(option) => option.name_fa}
                renderInput={(params) => <TextField  {...params} placeholder='دسته بندی'/>}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name_fa}
                  </li>
                )}
                onChange={this.onChangeCategory}
                clearText={this.state.clearText}
                closeText={this.state.closeText}
                openText={this.state.openText}
                noOptionsText={this.state.noOptionsText}
              />
            </Grid>
            <Grid sx={{width:{xs:"90%",md:"60%"}}}>
              <TextareaAutosize 
                value={this.state.ticketMessage}
                onChange={this.onChangeTicketMessage}
                className="primaryinfo-textarea" id="ticket_Desc" placeholder="متن تیکت" maxLength={500}
                style={{width:"100%",maxWidth:"100%",minWidth:"100%",minHeight:"150px",backgroundColor:"#F2F2F2",border:"none",borderRadius:"8px",fontFamily:"shabnam",
                  fontSize:"15px",padding:"13px",boxSizing:"border-box",height:"300px"}}
              />
            </Grid>
            <Grid sx={{width:{xs:"90%",md:"60%"},display:"flex",justifyContent:{xs:"center",md:"flex-end"},margin:"10px 0"}}>
              <Button onClick={this.onClickSend} sx={{bgcolor:"#14C9E8",padding:"10px 0",minWidth:{xs:"50%",md:"30%"},maxWidth:{xs:"50%",md:"30%"},"&:hover":{bgcolor:"#14C9E8"}}}>
                <Typography sx={{color:"black",fontFamily:"shabnam",fontSize:"20px"}}>ارسال</Typography>
              </Button>
            </Grid>
            <GiftLogo display={this.state.giftShow ? '' : 'none'}/>
            <AlertModal open={this.state.openAlert} message={this.state.message} close={this.closeAlertModal} type={this.state.typeAlert}/>
          </Grid>
          
        </Grid>
      )
    };
}

export default SendTicket;