import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';

var persianDigits = "۰۱۲۳۴۵۶۷۸۹";
var persianMap = persianDigits.split("");

export const convertToPersianNumber = (input) =>{
    return input.toString().replace(/\d/g,function(m){
        return persianMap[parseInt(m)];
    });
}

export const convertToEnglishNumber = (input) =>{
    return input.replace(/[\u06F0-\u06F90]/g, function(m){

        return persianDigits.indexOf(m);
    });
}

export const separator = (input) =>{
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const phoneNumber = (input) =>{
    let phoneno = /^\d{11}$/;
    if (input.match(phoneno)){
        return true;
    }
    else{
        return false;
    }
}

export const onlyReciveNumber = (input) =>{
    if(input && input !== " " && input !== "0" && input !== "۰"){
        let number = input.trim() ;
        number = convertToEnglishNumber(convertToPersianNumber(number));
        if(isNaN(number) === false){
            return parseInt(number) ;
        }else{
            return "" ;
        }
    }else{
        return "" ;
    }
}

export const setCanonicalTag = () => {
        let link = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', window.location.protocol + '//' + window.location.host + window.location.pathname);
        document.head.appendChild(link);
}

export const baseUrl = 'https://api.homeinja.com/api/';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckIcon fontSize="small" />;

export const bedRoom = [ {num:'0'},{num:'1'},{num:'2'},{num:'3'},{num:'4'},{num:'5'},{num:'6'},{num:'7'},{num:'8'},{num:'9'},{num:'10'},{num:'11+'}];

export const facade = [{f:'brick',n:'آجری'},{f:'composite',n:'کامپوزیتی'},{f:'cement',n:'سیمانی'},{f:'stone',n:'سنگی'},{f:'glass',n:'شیشه ای'},{f:'wood',n:'چوبی'},
                        {f:'atix',n:'اتیکس'},{f:'ceramic',n:'سرامیکی'},{f:'mineral',n:'مینرال'},{f:'roman',n:'رومی'},{f:'knitex',n:'کنیتگس'},{f:'nano',n:'نانو'},
                        {f:'bayramics',n:'بایرامیکس'},{f:'stretch',n:'استرچ متال'},{f:'dry',n:'نما خشک'},{f:'granite',n:'گرانیت'},{f:'concrete',n:'بتنی'},
                        {f:'term_wood',n:'ترموود'},{f:'monolith',n:'منونولیت'},{f:'travertine',n:'تراورتن'},{f:'polymeric',n:'پلیمری'},{f:'neoclassical',n:'نئوکلاسیک'},
                        {f:'classical',n:'کلاسیک'}];

export const cover = [{f:'ceramic',n:'سرامیک'},{f:'mosaic',n:'موزاییک'},{f:'stone',n:'سنگ'},{f:'wooden_parquet',n:'پارکت چوبی'},{f:'laminate',n:'لمینت'},
                        {f:'pvc',n:'پی وی سی'},{f:'industrial',n:'صنعتی'},{f:'bamboo',n:'بامبو'},{f:'glass',n:'شیشه ای'},{f:'brick',n:'آجری'},{f:'leather',n:'چرمی'},
                        {f:'epoxy',n:'اپوکسی'},{f:'concrete',n:'بتنی'},{f:'cork',n:'چوب پنبه ای'},{f:'threed',n:'سه بعدی'},{f:'granules',n:'گرانولی'},
                        {f:'sport',n:'ورزشی'},{f:'carpet',n:'موکت'},{f:'polyurethane',n:'پلی اورتان'},{f:'vinyl',n:'وینیل'},{f:'tile',n:'کاشی'},
                        {f:'linoleum',n:'لینولیوم'},{f:'ermalat',n:'ارملات'},{f:'term_wood',n:'ترموود'}];

export const cool_heat = [{f:'water_cooler',n:'کولرآبی'},{f:'gas_cooler',n:'کولرگازی'},{f:'split',n:'اسپلیت'},{f:'chiler',n:'چیلر'},{f:'fancoil',n:'فن کویل'},
                            {f:'under_floor_heating',n:'گرمایش از کف'},{f:'duct_split',n:'داکت اسپلیت'},{f:'air_conditioner',n:'هواساز'},
                            {f:'gas_central_ven',n:'تهویه مرکزی گازسوز'},{f:'electric_ven',n:'تهویه الکتریکی'},{f:'zent',n:'دستگاه زنت'},{f:'econopack',n:'اکونوپک'},
                            {f:'unit_package',n:'پکیج یونیت'},{f:'air_washer',n:'ایرواشر'},{f:'rooftab_package',n:'روفتاپ پکیج'},{f:'heating',n:'شوفاژ'},
                            {f:'water_heater',n:'آبگرمکن'}];

export const kitchenEq = [{f:'desktop_gas',n:'گاز رومیزی'},{f:'oven',n:'فر'},{f:'shooting',n:'شوتینگ زباله'},{f:'dish_washer',n:'محل ظرفشویی'},
                            {f:'laundry',n:'محل لباسشویی'},{f:'vacuum_cleaner',n:'جاروبرقی مرکزی'},{f:'dirty_kitchen',n:'آشپزخانه کثیف'},
                            {f:'preparation_sink',n:'سینک آماده سازی'},{f:'warming_drawer',n:'کشوی گرمکن'},{f:'ice_in',n:'یخ ساز توکار'}];

export const kitchenCabinet = [{f:'mdf',n:'ام دی اف'},{f:'glass',n:'گلاس'},{f:'natural_wood',n:'چوب طبیعی'},{f:'vacuum',n:'وکیوم'},{f:'wood_reneer',n:'روکش چوب'},
                                {f:'enzo',n:'انزو'},{f:'polish',n:'رنگ پولیشی'},{f:'jewelry',n:'جواهر'},{f:'melamine',n:'ملامینه'},{f:'high_glass',n:'های گلاس'},
                                {f:'polyglass',n:'پلی گلاس'},{f:'metallic',n:'فلزی'},{f:'neoclassical',n:'نئوکلاسیک'},{f:'high_leaf',n:'هایکلیاف'}];

export const safety = [{f:'cctv',n:'دوربین مداربسته'},{f:'lobby_man',n:'لابی من'},{f:'fire_alarm',n:'اعلام حریق'},{f:'extinguish',n:'اطفاء حریق'},
                        {f:'security_door',n:'درب ضد سرقت'},{f:'smart_home',n:'خانه هوشمند'},{f:'anti_earth',n:'ضد زلزله'},{f:'video_iphone',n:'آیفون تصویری'},
                        {f:'warning_alarm',n:'آژیر خطر'},{f:'emergency_exit',n:'خروج اضطراری'},{f:'sensor_door_window',n:'سنسور درب و پنجره'},
                        {f:'burglar_alarm',n:'دزدگیر'},{f:'control_panel',n:'کنترل پنل'},{f:'smart_door',n:'قفل درب هوشمند'},{f:'external_control',n:'اکس کنترل'},
                        {f:'electric_fences',n:'نرده های برقی'},{f:'parking_jack',n:'جک پارکینگی'}];

export const welfare = [{f:'interior_design',n:'دیزاین داخلی'},{f:'interior_lighting',n:'نورپردازی داخلی'},{f:'double_glazed_window',n:'پنجره دو جداره'},
                        {f:'patio',n:'پاسیو'},{f:'balcony',n:'بالکن'},{f:'roof_garden',n:'روف گاردن'},{f:'conference_hall',n:'سالن اجتماعات'},
                        {f:'children_playroom',n:'سالن بازی کودکان'},{f:'gym',n:'باشگاه ورزشی'},{f:'pool',n:'سالن بیلیارد'},{f:'central_antena',n:'آنتن مرکزی'},
                        {f:'laundry',n:'خشکشویی'},{f:'internal_elevator',n:'آسانسور داخلی'},{f:'fountain',n:'آب نما'},{f:'cinema',n:'سینما'},
                        {f:'jccuzzi',n:'جکوزی'},{f:'squash',n:'سالن اسکواش'},{f:'clothes_room',n:'اتاق لباس'},{f:'centeral_vacuum',n:'جاروبرقی مرکزی'},
                        {f:'japanese_garden',n:'باغ ژاپنی'},{f:'sun_room',n:'سان روم گاردن'},{f:'vertical_parking',n:'پارکینگ طبقاتی'},
                        {f:'media_room',n:'اتاق میدیا'},{f:'emergency_power',n:'برق اضطراری'},{f:'laundry_room',n:'لاندری روم'}];

export const report = [{f:'content',n:'محتوای آگهی'},{f:'unreal',n:'غیر واقعی '},{f:'contact',n:'اطلاعات تماس'},{f:'non_exist',n:'ناموجود'},
                        {f:'price',n:'قیمت'},{f:'photo',n:'عکس'},{f:'address',n:'آدرس'}];

export const situation = [{f:'north',n:'شمالی'},{f:'sauth',n:'جنوبی'},{f:'eastern',n:'شرقی'},{f:'western',n:'غربی'}];

export const statusListing = [{f:'New',n:'جدید'},{f:'Queue',n:'در صف انتشار'},{f:'Correction',n:'نیاز به اصلاح'},{f:'Published',n:'منتشر شده'},{f:'Archive',n:'بایگانی'}]

export const floorOptions = [{f:'-5',n:'۵ -'},{f:'-4',n:'۴ -'},{f:'-3',n:'۳ -'},{f:'-2',n:'۲ -'},{f:'-1',n:'۱ -'},{f:'0',n:'همکف'},{f:'1',n:'۱'},{f:'2',n:'۲'}
                      ,{f:'3',n:'۳'},{f:'4',n:'۴'},{f:'5',n:'۵'},{f:'6',n:'۶'},{f:'7',n:'۷'},{f:'8',n:'۸'},{f:'9',n:'۹'},{f:'10',n:'۱۰'},{f:'11',n:'۱۱'}
                      ,{f:'12',n:'۱۲'},{f:'13',n:'۱۳'},{f:'14',n:'۱۴'},{f:'15',n:'۱۵'},{f:'16',n:'۱۶'},{f:'17',n:'۱۷'},{f:'18',n:'۱۸'},{f:'19',n:'۱۹'}
                      ,{f:'20',n:'۲۰'},{f:'21',n:'۲۱'},{f:'22',n:'۲۲'},{f:'23',n:'۲۳'},{f:'24',n:'۲۴'},{f:'25',n:'۲۵'},{f:'26',n:'۲۶'},{f:'27',n:'۲۷'}
                      ,{f:'28',n:'۲۸'},{f:'29',n:'۲۹'},{f:'30',n:'۳۰'},{f:'31',n:'۳۱'},{f:'32',n:'۳۲'},{f:'33',n:'۳۳'},{f:'34',n:'۳۴'},{f:'35',n:'۳۵'}
                      ,{f:'36',n:'۳۶'},{f:'37',n:'۳۷'},{f:'38',n:'۳۸'},{f:'39',n:'۳۹'},{f:'40',n:'۴۰'}]

