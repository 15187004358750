import { Container } from '@mui/material';
import React from 'react';
import "../styles/home.css";
import VIP1 from './vip1';
import axios from 'axios';
import Vip2 from './vip2';
import SearchBox from './searchBox';
import Cta from './callToAction';
import Vip3 from './vip3';
import WhyHominja from './whyHominja';
import Services from './services';
import Footer from './footer';
import { baseUrl } from './materilas/vmp';
import { setCanonicalTag } from "./materilas/vmp";


class Home extends React.Component{

    isMount = false;

    constructor(props){
        super(props);
        this.state={
            vip1: null,
            vip2: null,
            vip3: null,
        }
    }

    componentDidMount(){
        document.title = "خرید، فروش، رهن، اجاره، ملک، مسکن، آپارتمان، خانه، برج، اداری، پنت هاوس | هومینجا"
        document.getElementById("main-heading").innerHTML = "خرید - فروش - رهن - اجاره - ملک - مسکن - آپارتمان - خانه - برج - اداری - پنت هاوس - هومینجا"
        document.getElementsByTagName('meta')["description"].content="خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
        this.isMount = true;
        if (this.isMount){
            this.getVip();
            setCanonicalTag();
        }
    }

    componentWillUnmount(){
        this.isMount = false;
    }

    getVip = () =>{
        Promise.resolve(axios({
            url: baseUrl+'v1/listing/getVip',
            method: 'GET'
        }))
        .then((res) => {
            this.setState({
                vip1: res.data.data.VIP1.length !== 0 ? res.data.data.VIP1[0] : null,
                vip2: res.data.data.VIP2.length !== 0 ? res.data.data.VIP2 : null,
                vip3: res.data.data.VIP3.length !== 0 ? res.data.data.VIP3 : null,
            })
        })
        .catch((err) => {
            this.setState({
                vip1: null,
                vip2: null,
                vip3: null,
            })
        });
    }

    render(){
        return(
            <Container disableGutters maxWidth="2000px">
                <VIP1 vip={this.state.vip1} />
                <SearchBox />
                <Vip2 vip={this.state.vip2}/>
                <Cta />
                <Vip3 vip={this.state.vip3}/>
                <WhyHominja />
                <Services />
                <Footer />
            </Container>
        )
    }
}

export default Home;