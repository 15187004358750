import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import manWithHome from "../images/manWithHome.png";
import manWithSearch from "../images/manWithSearch.png";
import AuthModal from '../component/authmodal';

class Cta extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            openAuthModal:false
        };
    }

    componentDidMount() {}

    onCloseAuth = () =>{
        this.setState({openAuthModal:false})
    }

    isLoggedIn = () =>{
        this.deleteActiveClass();
        localStorage.getItem('infoUserHomeInja') ? 
            this.setState({openAuthModal:false})
        : this.setState({openAuthModal:true})
    }

    deleteActiveClass = () => {
        let activeElement = document.getElementsByClassName("active");
        if(activeElement.length > 0){
            activeElement[0].className = activeElement[0].className.replace(' active', '');
        }
    }

    render(){
        return(
            <>
                <Grid sx={{padding:{xs:"10% 8%" , md:"6% 17%"}}}>
                    <Grid container spacing={{xs:1,md:4}}>
                        <Grid item xs={6}>
                            <Button component={Link} to={localStorage.getItem('infoUserHomeInja') ? "/profile/pl" : "#"} onClick={this.isLoggedIn}
                                sx={{boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",border:{xs:"1px solid #B4EEF8",md:"none"},borderRadius:"4px",bgcolor:{xs:"rgba(253, 255, 255, 0.98);",md:"white"},
                                    cursor:"pointer",color:"black"}}
                            >
                                <Grid container>
                                    <Grid item xs={12} md={5} sx={{padding:{xs:"10% 20% 0 20%", md:"8% 5%"}}}>
                                        <Typography component={"img"} src={manWithHome} alt="manWithHome" sx={{width:"100%" , height:"auto"}}></Typography>
                                    </Grid>
                                    <Grid item container xs={12} md={7} sx={{textAlign:{xs:"center", md:"right"}}} alignContent="center">
                                        <Grid item xs={12}>
                                            <Typography sx={{fontFamily:"shabnam !important",fontSize:{xs:"12px",md:"23px"},fontWeight:"900",padding:{xs:"0",md:"0 0 30px 0"}}}>
                                                سپردن ملک
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{fontFamily:"shabnam !important",fontSize:{xs:"9px",md:"14px"},padding:{xs:"2% 2% 8% 2%",md:"20px 0 0 5%"},fontWeight:"600"}}>
                                                ملک خود را در بزرگترین بانک اطلاعاتی املاک به نمایش بگذارید.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button component={Link} to={localStorage.getItem('infoUserHomeInja') ? "/profile/rl" : "#"} onClick={this.isLoggedIn} 
                                sx={{boxShadow:"0px 2px 4px rgba(0, 0, 0, 0.24)",border:{xs:"1px solid #B4EEF8",md:"none"},borderRadius:"4px",
                                    bgcolor:{xs:"rgba(253, 255, 255, 0.98);", md:"white"},cursor:"pointer",color:"black",height:"100%"}}
                            >
                                <Grid container>
                                    <Grid item xs={12} md={5} sx={{padding:{xs:"10% 20% 0 20%",md:"8% 5%"}}}>
                                        <Typography component={"img"} src={manWithSearch} alt="manWithHome" sx={{width:"100%",height:"auto"}}></Typography>
                                    </Grid>
                                    <Grid item container xs={12} md={7} sx={{textAlign:{xs:"center",md:"right"}}} alignContent="center">
                                        <Grid item xs={12}>
                                            <Typography sx={{fontFamily:"shabnam !important",fontSize:{xs:"12px",md:"23px"},fontWeight:"900",padding:{xs:"0",md:"0 0 30px 0"}}}>
                                                سفارش ملک
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography sx={{fontFamily:"shabnam !important",fontSize:{xs:"9px",md:"14px"},padding:{xs:"2% 2% 8% 2%",md:"20px 0 0 5%"},fontWeight:"600"}}>
                                            در کمترین زمان مناسب ترین ملک را برای شما پیدا می‌کنیم.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <AuthModal open={this.state.openAuthModal} close={this.onCloseAuth} type={"L"}/>   
            </>
        );
    }
}

export default Cta ;