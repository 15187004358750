import { Button, Grid, Tooltip, Typography, Skeleton } from "@mui/material";
import React from "react";
import axios from "axios";
import { baseUrl, convertToPersianNumber, separator, setCanonicalTag } from "./materilas/vmp";
import AgencyPageBackground from "../images/AgencyPageBackground.jpg"
import AgencyPageBackgroundSmall from "../images/AgencyPageBackground_small.jpg"
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import AlertModal from './materilas/alert';
import { Link } from "react-router-dom";
import noLogoAgancyImage from "../images/noLogoAgancy.png";
import locationIcon from "../images/listing/location.png";
import CabinIcon from '@mui/icons-material/Cabin';


class AgencyPage extends React.Component {

  isMount = false ;

  constructor(props){
    super(props);
    this.state = {
      message : "" ,
      openAlert : false ,
      typeAlert : 3 ,
      agencyInfo : null ,
      agencyListingSkeleton : true ,
      agencyListingInfo : [] ,
      adsCounter : 9 ,
      agencyId : window.location.pathname.substring(12) ,
    }
  }

  componentDidMount() {
    this.isMount = true ;
    if(this.isMount){
      this.getAgency();
      this.getAgencyListing();
      setCanonicalTag();
    }
  };

  getAgency = () => {
    Promise.resolve(axios({
      url: baseUrl+'v1/agancy/get',
      method: 'GET',
      params:{
        agancy_id: this.state.agencyId
      } 
    }))
    .then((res) => {
      this.setState({agencyInfo:res.data.data});
      this.setTitle(res.data.data);
      this.setH1(res.data.data);
    })
    .catch((err) => {
      switch(err.response.status){
        case 404 :
            window.location.pathname = "/Not-Found" ;
          break ;
        case 400 :
            window.location.pathname = "/Bad-Request" ;
          break ;
        default :
          this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2});
      }
    })
  }

  setTitle = (agencyInfo) => {
    document.title = "آژانس مسکن " + agencyInfo.name + " - خرید ، فروش، رهن، اجاره، آپارتمان، خانه | هومینجا" ;
  }

  setH1 = (agencyInfo) => {
    document.getElementsByTagName("h1")[0].innerHTML = "آژانس مسکن " + agencyInfo.name + " خرید، فروش، رهن، اجاره، آپارتمان، خانه | هومینجا" ;
  }

  getAgencyListing = () => {
    Promise.resolve(axios({
      url: baseUrl+'v1/agancy/getListing',
      method: 'GET',
      params:{
        agancy_id: this.state.agencyId
      } 
    }))
    .then((res) => {
      this.setState({agencyListingInfo:res.data.data,agencyListingSkeleton:false});
    })
    .catch((err) => {
      this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است",typeAlert:2,agencyListingSkeleton:false});
    });
  }

  oncloseAlert = () =>{
    this.setState({openAlert:false});
  }

  createAds = () => {
    let result = [];
    let ads = this.state.agencyListingInfo.slice(0,this.state.adsCounter);
    ads.forEach((element,index)=>{
      result.push(
        <Grid item xs={4} lg={4} xl={4} sx={{width:"100%",padding:"10px"}} key={element.created_at}>
          <Button disableRipple component={Link} to={"/Listing/"+element.id}  target="_blank"
            sx={{width:"100%",padding:"0","&:hover":{bgcolor:"transparent",boxShadow:"0px 0px 17px -5px #343434"},color:"black",borderRadius:"40px 8px 8px 8px",boxShadow:"0px 0px 17px -5px #D1D1D1",
                border:
                element.plan_id === 2 ?
                  "2px solid #faa638"
                : element.plan_id === 3 ?
                    "2px solid #fbb151"
                  : element.plan_id === 4 ?
                      "2px solid #fcc983"
                    : element.plan_id === 5 ?
                        "2px solid #c7c1d7"
                      : "2px solid #eee"
                ,background:
                  element.plan_id === 2 ?
                    "linear-gradient(0deg, rgba(250,166,56,0.7) 0%, rgba(255,255,255,1) 100%)"
                  : element.plan_id === 3 ?
                      "linear-gradient(0deg, rgba(251,177,81,0.5) 0%, rgba(255,255,255,1) 100%)"
                    : element.plan_id === 4 ?
                        "linear-gradient(0deg, rgba(252,201,131,0.5) 0%, rgba(255,255,255,1) 100%)"
                      : element.plan_id === 5 ?
                          "linear-gradient(0deg, rgba(199,193,215,0.5) 0%, rgba(255,255,255,1) 100%)"
                        : "linear-gradient(0deg, rgba(120,231,245,0.03) 0%, rgba(255,255,255,1) 100%)"
                }}
          >
            <Grid sx={{borderRadius:"40px 8px 8px 8px",overflow:"hidden",width:"100%"}}>
              <Grid container sx={{width:'100%'}} columns={{xs:12,sm:12,lg:12}}>
                <Grid item xs={5} sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                  { element.show_price === 1 ?
                      element.transaction_id === 1 ?
                        <>
                          <Typography sx={{width:"100%",fontFamily:"shabnam",textAlign:"center",fontWeight:"bold"}}>قیمت</Typography>
                          <Typography sx={{fontWeight:"bold",fontSize:{xs:"12px",md:"16px"},fontFamily:"shabnam"}}>
                            { parseInt(element.total_price) ?
                              `${convertToPersianNumber(separator(element.total_price))} تومان`
                              : "توافقی"
                            }
                          </Typography>
                        </>
                      : element.transaction_id === 3 ?
                          <>
                            <Typography sx={{width:"100%",fontFamily:"shabnam",textAlign:"center",fontWeight:"bold"}}>پیش خرید</Typography>
                            <Typography sx={{fontWeight:"bold",fontSize:{xs:"12px",md:"16px"},fontFamily:"shabnam"}}>
                              { parseInt(element.prebuy) ?
                                  `${convertToPersianNumber(separator(element.prebuy))} تومان`
                                  : "توافقی"
                              }
                            </Typography>
                          </>
                        : element.transaction_id === 2 ?
                            <>
                              <Typography sx={{width:"100%",fontFamily:"shabnam",textAlign:"center",fontWeight:"bold"}}>رهن</Typography>
                              <Typography sx={{fontWeight:"bold",fontSize:{xs:"12px",md:"16px"},fontFamily:"shabnam"}}>
                                { parseInt(element.mortgage) ?
                                    `${convertToPersianNumber(separator(element.mortgage))} تومان`
                                  : "توافقی"
                                }
                              </Typography>
                              <Typography sx={{width:"100%",fontFamily:"shabnam",textAlign:"center",fontWeight:"bold"}}>اجاره</Typography>
                              <Typography sx={{fontWeight:"bold",fontSize:{xs:"12px",md:"16px"},fontFamily:"shabnam"}}>
                                { parseInt(element.rent) ?
                                    `${convertToPersianNumber(separator(element.rent))} تومان`
                                  : "توافقی"
                                }
                              </Typography>
                            </>
                          :<Grid></Grid>
                    : <>
                        <Typography sx={{width:"100%",fontFamily:"shabnam",textAlign:"center",fontWeight:"bold"}}>قیمت</Typography>
                        <Typography sx={{width:"100%",fontFamily:"shabnam",textAlign:"center",fontWeight:"bold"}}>توافقی</Typography>
                      </>
                  }
                </Grid>
                <Grid item xs={7} 
                  sx={{width:"100%",height:{xs:"150px",md:"200px"},background:`url(${element.small.length > 0 ? element.small[0].path : noLogoAgancyImage})`,
                  backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover",borderRight:"1px solid #eee",borderBottom:"1px solid #eee"}}
                >
                  {element.small.length > 0 ? 
                    <Typography component={"img"} src={element.small[0].path} sx={{display:"none"}}
                      alt = {`${convertToPersianNumber(element.area) + " متری " + element.state.name + " - " + element.neighbourhood.name + " | هومینجا"}`}
                    />
                  : null}
                </Grid>
              </Grid>
              <Grid container sx={{width:"100%",padding:"30px 0"}} columns={{xs:12,sm:12,lg:12}}>
                <Grid item xs={5} sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
                  <Typography sx={{fontFamily:"shabnam",textAlign:"center",fontWeight:"bold",width:"auto"}}>متراژ :</Typography>
                  <Typography sx={{fontFamily:"shabnam",textAlign:"center",fontWeight:"bold",width:"auto",marginRight:"5px"}}>
                    {convertToPersianNumber(element.area)}
                  </Typography>
                  <Typography sx={{fontFamily:"shabnam",textAlign:"center",fontWeight:"bold",width:"auto",marginRight:"5px"}}>
                    متر
                  </Typography>
                </Grid>
                <Grid item xs={7} sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
                  <Typography component={"img"} src={locationIcon} alt="icon" sx={{width:"20px",height:"auto"}}/>
                  <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"center",fontWeight:"bold",width:"auto",marginRight:"5px"}}>
                    {element.state ? element.state.name : ""} -
                  </Typography>
                  <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"16px",md:"20px"},textAlign:"center",fontWeight:"bold",width:"auto",marginRight:"5px"}}>
                    {element.neighbourhood ? element.neighbourhood.name : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      )
    })
    return result ;
  }

  createSkeleton = () => {
    let result = [];
    for(let i = 0 ; i < 9 ; i++){
      result.push(
        <Grid item xs={4} lg={4} xl={4} sx={{width:"100%",padding:"10px"}} key={i}>
          <Grid
            sx={{width:"100%",padding:"0","&:hover":{bgcolor:"transparent"},color:"black",boxShadow:"0px 0px 17px -5px #D1D1D1",
                background:"linear-gradient(0deg, rgba(120,231,245,0.03) 0%, rgba(255,255,255,1) 100%)",borderRadius:"30px 4px 4px 4px"}}
          >
            <Grid sx={{borderRadius:"30px 4px 4px 4px",overflow:"hidden",width:"100%"}}>
              <Grid container sx={{width:'100%'}} columns={{xs:12,sm:12,lg:12}}>
                <Grid item xs={5} sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                  <Skeleton variant="text" width="50%" animation="wave"/>
                  <Skeleton variant="text" width="60%" animation="wave"/>
                </Grid>
                <Grid item xs={7} sx={{width:"100%",height:"200px",borderBottom:"1px solid #eee"}}>
                  <Skeleton variant="rectangular" width="100%" height="100%" animation="wave"/>
                </Grid>
              </Grid>
              <Grid container sx={{width:"100%",padding:"30px 0"}} columns={{xs:12,sm:12,lg:12}}>
                <Grid item xs={5} sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
                  <Skeleton variant="text" width="50%" animation="wave"/>
                </Grid>
                <Grid item xs={7} sx={{width:"100%",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"row"}}>
                  <Skeleton variant="text" width="50%" animation="wave"/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
    return result ;
  }

  onClickMoreAds = () => {
    this.setState({adsCounter:this.state.adsCounter + 18});
  }

  render() {
    return (
      <>
      { this.state.agencyInfo ?
        <Grid sx={{marginTop:{xs:"60px",sm:"70px",md:"88px"},width:"100%"}}>
          <Grid sx={{width:"100%",background:{xs:`url(${AgencyPageBackgroundSmall})`,md:`url(${AgencyPageBackground})`},backgroundPosition:"center",
                    backgroundRepeat:"no-repeat",backgroundSize:{xs:"cover",md:"cover"},textAlign:{xs:"center",md:"right"}}}>
            <Grid sx={{padding:"3% 12%"}}>
              <Grid sx={{background:"rgba(93, 211, 240, 0.08)",boxShadow:"0 4px 30px rgba(0, 0, 0, 0.4)",backdropFilter:"blur(8.5px)",WebkitBackdropFilter:"blur(8.5px)",
                        border:"1px solid rgba(93, 211, 240, 0.19)",borderRadius:"8px",display:"inline-block",padding:"4% 5%"}}
              >
                <Grid sx={{width:"180px",height:"180px",bgcolor:"white",borderRadius:"8px",background:`url(${this.state.agencyInfo.logo_path ? this.state.agencyInfo.logo_path : noLogoAgancyImage})`,
                  backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}></Grid>
                <Typography variant="h2" sx={{fontFamily:"shabnam",fontSize:"20px",color:"white",textAlign:"center",padding:"15px 0 10px"}}>
                  {convertToPersianNumber("آژانس " + this.state.agencyInfo.name)}
                </Typography>
                <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  { this.state.agencyInfo.website ?
                      <Tooltip title={<Typography sx={{fontFamily:"shabnam",fontSize:"12px"}}>وبسایت آژانس</Typography>}>
                        <a style={{display:"inline-block",padding:"0",margin:"4px"}} href={this.state.agencyInfo.website} target="_blank" rel="noreferrer">
                          <LanguageIcon sx={{color:"white !important"}} />
                        </a>
                      </Tooltip>
                    : <Grid></Grid>
                  }
                  { this.state.agencyInfo.instagram_link ?
                      <Tooltip title={<Typography sx={{fontFamily:"shabnam",fontSize:"12px"}}>صفحه اینستاگرام آژانس</Typography>}>
                        <a style={{display:"inline-block",padding:"0",margin:"4px"}} href={this.state.agencyInfo.instagram_link} target="_blank" rel="noreferrer">
                          <InstagramIcon sx={{color:"white !important"}} />
                        </a>
                      </Tooltip>
                    : <Grid></Grid>
                  } 
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{padding:{xs:"0 4px",md:"0 12%"}}}>
            <Grid sx={{margin:"3% 0"}}>
              <Typography sx={{fontFamily:"shabnam",fontSize:"20px",fontWeight:"bold",textAlign:"center"}} variant="h2">
                {convertToPersianNumber(" اطلاعات آژانس " + this.state.agencyInfo.name)}
              </Typography>
              <Grid container sx={{border:"2px solid #dbdbdb",borderRadius:"20px 8px 20px 8px",margin:"2% 0",padding:"20px 0",flexDirection:"column"}}>
                <Grid item container xs={12}>
                  <Grid item xs={12} md={6} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:{xs:"column",md:"row"}}}>
                    <PersonRoundedIcon sx={{fontSize:"30px",margin:"0 6px"}}/>
                    <Typography sx={{fontFamily:"shabnam",fontSize:"18px",textAlign:"center"}}>
                    {"نام مدیر: " + this.state.agencyInfo.manager_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:{xs:"column",md:"row"}}}>
                    <PhoneEnabledRoundedIcon sx={{fontSize:"30px",margin:"0 6px"}}/>
                    <Typography sx={{fontFamily:"shabnam",fontSize:"18px",textAlign:"center"}}>
                      {"شماره تماس: " + (this.state.agencyInfo.phone ? convertToPersianNumber(this.state.agencyInfo.phone) : "بدون شماره تماس")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{display:"flex"}}>
                  <Grid sx={{flexGrow:"1",display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                    <Grid sx={{height:"3px",bgcolor:"#dbdbdb",width:"85%"}}></Grid>
                  </Grid>
                  <Grid sx={{display:"flex",alignItems:"center"}}>
                    <FmdGoodRoundedIcon sx={{color:"#dbdbdb !important",margin:"0 5px",fontSize:"35px"}}/>
                  </Grid>
                  <Grid sx={{flexGrow:"1",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                    <Grid sx={{height:"3px",bgcolor:"#dbdbdb",width:"85%"}}></Grid>
                  </Grid>
                </Grid>
                  <Typography sx={{fontFamily:"shabnam",fontSize:"18px",textAlign:"center"}}>
                    {this.state.agencyInfo.address ? convertToPersianNumber(this.state.agencyInfo.address) : "آدرس اضافه نشده است"}
                  </Typography>
              </Grid>
            </Grid>
            <Grid sx={{margin:"3% 0"}}>
              <Typography sx={{fontFamily:"shabnam",fontSize:"20px",fontWeight:"bold",textAlign:"center"}}>
              آگهی ها
              </Typography>
              <Grid sx={{borderTop:"2px solid #01A7C4",borderBottom:"2px solid #01A7C4",padding:"2% 0",margin:"1% 0"}}>
                <Grid container sx={{width:'100%'}} columns={{xs:4,lg:8,xl:12}} justifyContent="center">
                  { this.state.agencyListingSkeleton ?
                      this.createSkeleton()
                    : this.state.agencyListingInfo.length > 0 ?
                        this.createAds()
                      : <Grid xs={12} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                          <CabinIcon sx={{fontSize:"50px"}}/>
                          <Typography sx={{fontFamily:"shabnam",fontSize:"16px"}}>
                            این آژانس آگهی جهت نمایش ندارد
                          </Typography>
                        </Grid>
                  }
                  { this.state.agencyListingInfo.length > this.state.adsCounter ?
                      <Grid item xs={4} lg={8} xl={12} sx={{width:"100%",textAlign:"center",padding:"4% 0 0"}}>
                        <Button sx={{width:"300px",bgcolor:"#01A7C4",fontFamily:"shabnam",color:"white","&:hover":{bgcolor:"#01A7C4"}}}
                          onClick={this.onClickMoreAds}
                        >
                          {"مشاهده آگهی های بیشتر"}
                        </Button>
                      </Grid>
                    :<Grid></Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{bgcolor:"#0a5b6414",height:"40px"}}></Grid>
        </Grid>
        : <Grid sx={{marginTop:{xs:"60px",sm:"70px",md:"88px"},width:"100%"}}>
            <Grid sx={{width:"100%",bgcolor:"#eee",textAlign:{xs:"center",md:"right"}}}>
              <Grid sx={{padding:"3% 12%"}}>
                <Grid sx={{padding:"4% 5%",bgcolor:"white",borderRadius:"8px",display:"inline-block"}}>
                    <Skeleton variant="rectangular" width={180} height={180} animation="wave"/>
                    <Typography variant="h2" sx={{fontFamily:"shabnam",fontSize:"20px",color:"white",textAlign:"center",padding:"15px 0 10px"}}>
                      <Skeleton animation="wave"/>
                    </Typography>
                    <Grid sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      <Skeleton sx={{margin:"8px"}} width="20px" height="30px" animation="wave"/>
                      <Skeleton sx={{margin:"8px"}} width="20px" height="30px" animation="wave"/>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{padding:{xs:"0 4px",md:"0 12%"}}}>
              <Grid sx={{margin:"3% 0"}}>
                <Grid sx={{display:"flex",justifyContent:"center"}}>
                  <Skeleton animation="wave" width="20%"/>
                </Grid>
                <Grid container sx={{border:"2px solid #dbdbdb",borderRadius:"20px 8px 20px 8px",margin:"2% 0",padding:"20px 0",flexDirection:"column"}}>
                  <Grid item container xs={12}>
                    <Grid item xs={12} md={6} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:{xs:"column",md:"row"}}}>
                      <Skeleton animation="wave" width="20px" height="30px" sx={{margin:"0 6px"}}/>
                      <Skeleton animation="wave" width="40%"/>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:{xs:"column",md:"row"}}}>
                      <Skeleton animation="wave" width="20px" height="30px" sx={{margin:"0 6px"}}/>
                      <Skeleton animation="wave" width="40%"/>
                    </Grid>
                  </Grid>
                  <Grid sx={{display:"flex"}}>
                    <Grid sx={{flexGrow:"1",display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                      <Grid sx={{height:"3px",bgcolor:"#dbdbdb",width:"85%"}}></Grid>
                    </Grid>
                    <Grid sx={{display:"flex",alignItems:"center"}}>
                      <FmdGoodRoundedIcon sx={{color:"#dbdbdb !important",margin:"0 5px",fontSize:"35px"}}/>
                    </Grid>
                    <Grid sx={{flexGrow:"1",display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                      <Grid sx={{height:"3px",bgcolor:"#dbdbdb",width:"85%"}}></Grid>
                    </Grid>
                  </Grid>
                    <Grid sx={{display:"flex",justifyContent:"center"}}>
                      <Skeleton width="30%" animation="wave" sx={{margin:"3px 0"}}/>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      }
      <AlertModal open={this.state.openAlert} close={this.oncloseAlert} message={this.state.message} type={this.state.typeAlert}/>
      </>
    )
  };
}

export default AgencyPage ;