import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import photo01 from "../../images/mag/article34/01.jpg";
import photo02 from "../../images/mag/article34/02.jpg";
import photo03 from "../../images/mag/article34/03.jpg";
import photo04 from "../../images/mag/article34/04.jpg";
import photo05 from "../../images/mag/article34/05.jpg";
import photo06 from "../../images/mag/article34/06.jpg";
import photo07 from "../../images/mag/article34/07.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article34 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "پاف چیست؟ نشیمنی کم ارتفاع است که از لحاظ نرمی و سبکی شبیه کوسن می باشد. پاف معمولا بدون پایه می باشد و فضای صندوقچه ای ندارد.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "کاربرد پاف در دکوراسیون منزل، راهنمای خرید پاف، معرفی انواع پاف، پاف یا پوف ، صفر تا صد انتخاب پاف"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[33].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[33].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[33].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[33].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[33].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[33].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="پاف - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[33].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                احتمالا در هنگام خرید مبلمان، کلمه پاف به گوشتان خورده است اما شاید ندانید که دقیقا پاف چیست و چه کاربردی دارد.  در این مقاله از <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link> به تعریف پاف می پردازیم. همچنین در این  <Link className="link" to="/pouf" target="_blank"> مطلب </Link> از هومینجا به پاسخ سوالاتی از این قبیل، کاربرد پاف چیست؟ یا چند مدل پاف داریم؟ خواهیم پرداخت.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="پاف - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پاف چیست
                </figcaption>
              </figure>
              <Typography className="h2" variant="h2">
                معنی کلمه پاف
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کلمه پاف یا پوف (pouffe) واژه ای است که ریشه فرانسوی دارد. این کلمه بعدها در انگلیسی تغییر کرد و تبدیل به واژه  pouf شده است. کلمه پفک که در فارسی کاربرد دارد نیز از واژه پاف یا پوف گرفته شده است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پاف، نشیمنی کم ارتفاع است که از لحاظ نرمی و سبکی شبیه کوسن می باشد. پاف همانند کوسن مبل بیشتر جنبه تزئینی دارد. بدنه پاف دارای چهارچوبی است که از بیرون دید ندارد و بدنه آن پارچه ای می باشد. خیلی از افراد پاف و اتومان را یکی می دانند اما پاف معمولا بدون پایه می باشد و فضای صندوقچه ای ندارد.
              </Typography>
              <Typography className="h2" variant="h2">
                تفاوت پاف و اتومان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="پاف و اتومان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پاف و اتومان
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/moving-the-house" target="_blank">
                  بیشتر بخوانید : فوت و فن اثاث کشی راحت
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برخلاف اتومان که بیشتر در <Link className="link" to="/small-house-decoration" target="_blank"> دیزاین </Link> های رسمی کاربرد دارد پاف ها معمولا راحتی خاصی دارند. پاف معمولا از اتومان کوچکتر است و نمی توان زیاد بر روی آن وسایل گذاشت. اتومان دارای چارچوب و پایه می باشد که مانند مبل روی آن روکش وجود دارد. در صورتیکه پاف ساختاری سبک از جنس فوم فشرده و متراکم است که لایه بیرونی آن متشکل از پارچه می باشد.
              </Typography>
              <Typography className="p">
                باوجود تفاوت‌هایی که در بالا اشاره کردیم، پاف و اتومان را از لحاظ کاربردی می‌توان بسیار شبیه به هم در نظر گرفت.
              </Typography>
              <Typography className="h2" variant="h2">
                پاف چه کاربردهایی دارد؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کاربرد پاف - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاربردهای پاف
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پاف با اینکه جنبه کاربردی دارد اما برای <Link className="link" to="/fengshui" target="_blank"> تزئین و دکوراسیون </Link>   خانه نیز کارایی دارد. از پاف می توان بعنوان مبلمان کمکی برای نشستن استفاده کرد. همچنین در برخی از موارد پاف ها بعنوان زیرپایی مبل یا در مواردی بعنوان میز عسلی کارایی دارند.
              </Typography>
              <Typography className="h2" variant="h2">
                پاف چه مدلی بخرم؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="انواع پاف - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انواع پاف
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پاف های متنوعی در <LinkMui className="link" href="https://www.digikala.com/search/category-sofapouffeandbeanbag/" target="_blank">  بازار </LinkMui>    وجود دارد که از لحاظ استایل و اندازه متفاوت می باشد. برای نشستن باید از پاف هایی استفاده کنید که نرم و پفی و در عین حال راحت هستند. برای استفاده از پاف بعنوان میزعسلی بهتر است از پاف های سفت و مسطح استفاده شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/laundry-room" target="_blank">
                  بیشتر بخوانید : لاندری روم چیست ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                جنس پاف
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای انتخاب جنس پاف بهتر است بر اساس محل استفاده تصمیم گیری شود. این مسئله که پاف را در کجای منزل میخواهید بکار ببرید در انتخاب جنس پاف تاثیر بسزایی دارد. همچنین جنس پاف باید با بقیه دکوراسیون منزل سازگاری داشته باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پاف های با رویه مخمل یا ابریشم برای اتاق پذیرایی مناسب می باشند. برای دکور اتاق نیز می توان پاف های مخملی بکار برد. این مدل پاف ها جنس نرم و لطیفی دارند و بهتر است که کنار تخت یا پرده استفاده شوند. معمولا رنگ پاف  <Link className="link" to="/where-to-buy-sleep-product" target="_blank"> اتاق خواب </Link> با روتختی یا پرده ست می شود. برای دید چشم نواز بهتر است از یک قالیچه در کنار ان استفاده کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از پاف های با رویه چرم فاکس که به راحتی تمیز و شسته می شوند بهتر است برای ورودی منزل استفاده شود. همچنین می توان از پاف های چرمی برای میزعسلی استفاده کرد. این مدل پاف دید تجملی به فضای منزل می دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                رنگ و طرح پاف
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است رنگ پاف با دیگر اجزای دکوراسیون مطابقت داشته باشد و از رنگ هایی استفاده شود که مکمل یکدیگر باشند. پاف های طرحدار بهتر است کنار دیوار یا مبلمان ساده قرار گیرند. اگر دیوار یا مبلمان منزل طرحدار می باشد برای جذابیت دکوراسیون از پاف های تک رنگ استفاده کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع پاف
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ذیل انواع مختلف پاف را معرفی میکنیم:
              </Typography>
              <Typography className="h3" variant="h3">
                جلوی مبلی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="پاف جلو مبلی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پاف جلو مبلی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پاف زیرپایی مناسبی برای جلوی مبلمان راحتی است. از پاف می توان بعنوان میز کوچکی برای قراردادن سینی نیز استفاده کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ممکن است خواندن مقاله  <Link className="link" to="/smart-home" target="_blank"> خانه هوشمند </Link> هم برای شما مفید باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                جلوی تلویزیون
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از پاف می توانید برای جلوی تلویزیون هم استفاده کنید. همچنین با قرار دادن شال مبل می توانید به تزئین بهتر فضای هال و نشیمن کمک کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                پای تخت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از پاف ها برای پای تخت نیز می توان استفاده کرد. برای پای تخت دونفره بهتر است از دو پاف بهره ببرید. از پاف داخل اتاق می توان برای نشستن هنگام تعویض لباس استفاده کرد. همچنین می توانید روبدوشامبرتان را پیش از خواب روی پاف قرار دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                اتاق کودک
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="پاف کودک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پاف کودک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از پاف در اتاق کودک نیز می توان استفاده کرد. البته بهتر است پافی که در اتاق کودک بکار می برید بافتی نرم و لطیف داشته باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                ورودی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از پاف می توان در ورودی درب منزل استفاده کرد. همچنین می توان این مدل پاف را در راهرو قرار داد. جایی که شما بتوانید بشینید و به راحت پوشیدن یا راحت دراوردن کفش هایتان کمک کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/chandelier-in-decoration" target="_blank">
                  بیشتر بخوانید : انتخاب لوستر مناسب در دکوراسیون منزل
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                جمع بندی:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی نموده‌ایم با معرفی پاف و کاربرد آن، به شما در انتخاب پاف مناسب کمک کرده باشیم. امیدواریم مطالب این مقاله از هومینجا برای شما مفید واقع شود.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("معماری و دکوراسیون")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  معماری و دکوراسیون
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article34;