import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article58/01.jpg";
import photo02 from "../../images/mag/article58/02.jpg";
import photo03 from "../../images/mag/article58/03.jpg";
import photo04 from "../../images/mag/article58/04.jpg";
import photo05 from "../../images/mag/article58/05.jpg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";
class Article58 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = " هوشمند سازی ساختمان امنیت و آسایش را برای ساکنین فراهم می آورد. در این مقاله سعی کردیم تا به معرفی ساختمان هوشمند و تجهیزات آن بپردازیم.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "هوشمند سازی ساختمان | ویژگی های ساختمان هوشمند | مزایای هوشمند سازی ساختمان | تفاوت ساختمان هوشمند و خانه هوشمند | کاربرد های ساختمان هوشمند | امکانات ساختمان هوشمند | انواع سیستم ساختمان هوشمند | نمونه ساختمان هوشمند | قیمت هوشمند سازی ساختمان "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[57].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[57].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[57].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[57].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[57].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[57].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="ساختمان هوشمند - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[57].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با پیدایش <Link className="link" to="/IOT" target="_blank">  اینترنت اشیا </Link> مفهوم هوشمند سازی نیز رواج بسیاری پیدا کرد. از پر کاربرد ترین مباحث هوشمند سازی می توان به ساختمان هوشمند اشاره کرد. ساختمان هوشمند امروزه در زمینه <Link className="link" to="/Interior-architecture-and-interior-design" target="_blank">  معماری </Link> و ساختمان سازی واژه ای آشناست و از فناوری های روز محسوب می شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس در این مقاله از <Link className="link" to="/HomeMag" target="_blank">  هومینجا </Link> با ما همراه باشید تا نکاتی را در مورد ساختمان هوشند بیان کنیم و در مورد مزیت ها و امکانات اتوماسیون ساختمان بحث کنیم.
              </Typography>
              <Typography className="h2" variant="h2">
                ساختمان هوشمند چیست ؟
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="ساختمان هوشمند - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ساختمان هوشمند
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یک ساختمان هوشمند با بهره گیری از سنسورها، نرم افزار ها و اتصالات اینترنت عمل می کند. با ترکیب این موارد به نظارت ویژگی ها و مشخصات ساختمان، گرد آوری داده از بخش های مختلف ساختمان و تحلیل داده ها می پردازد. تمامی این امور به منظور بهینه سازی مصرف انرژی و عملکرد ساختمان انجام می پذیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی امکانات ساختمان هوشمند با یک سیستم هوشمند یکپارچه در ارتباط هستند و از راه دور قابلیت کنترل دارند. البته می توان گفت در ساختمان هوشند تمامی این کارها بوسیله موبایل و اینترنت انجام می شود. ساختار ساختمان هوشمند به این صورت عمل می کند که کنترل عملکرد های مختلف ساختمان مانند سرما و گرما، تهویه هوا یا نورپردازی و امنیت به صورت کاملا اتوماتیک انجام می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/premade-canopy" target="_blank">
                  بیشتر بخوانید : سایبان پیش ساخته چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ساختمان هوشمند یعنی چه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هوشمندسازی ساختمان ترجمه واژه Structure Automation یا Home Automation می باشد. این واژه به معنای اجرای فرایند، بدون دخالت مستقیم عامل انسانی است. این اتوماسیون در بخش صنعتی با نام Automation و در بخش مسکونی با نام Home Aautomation یا سیستم مدیریت ساختمان BMS معروف است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                BMS از کلمه Building Managment System می آید و به معنی مدیریت سیستم کنترل ساختمان می باشد. این سیستم به وسیله یک پردازنده مرزی کنترل اطلاعات ساختمان، مدیریت ماژول ها و سخت افزارها را اداره می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سیستم مدیریت ساختمان شامل دو بخش نرم افزاری و سخت افزاری می باشد. بخش سخت افزاری آن سنسورها، ترموستات ها، شیرهای برقی و مانیتور را پشتیبانی می کند. بخش نرم افزاری آن توسط پروتکل های XML, Bacnet, Lan اجرا می گردد.
              </Typography>
              <Typography className="h2" variant="h2">
                هوشمند سازی ساختمان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="هوشمند سازی ساختمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاریخچه IOT
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای اتوماسیون ساختمان نیاز است که که سیستم های اصلی ساختمان مانند سیستم نور پردازی و <Link className="link" to="/chandelier-in-decoration" target="_blank">  لوسترها </Link>، کنتور برق، پمپ آب و سیستم گرمایش و سرمایش به سنسورها و سیستم کنترل متصل شوند. در سازه های پیشرفته حتی می توان آسانسورها را نیز هوشمند کرد. هوشمند سازی با یکپارچه کردن چهار عنصر اصلی یعنی سامانه، رفتار، سرویس ها و مدیریت انجام می پذیرد. ساختمان های هوشمند مجهز به یک زیر ساخت ارتباطی قوی می باشند که نسبت به وضعیت های متغیر محیط واکنش نشان می دهند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اتوماسیون ساختمان از قانون خاصی پیروی نمی کند. تنها موضوع مشترکی که در تمامی ملک های هوشمند به کار می رود یکپارچه بودن سیستم عملکردی آن سازه می باشد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال یا دیوار شاخص چیست
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مزایای ساختمان هوشمند
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ساختمان های هوشمند دارای امکاناتی هستند. این قابلیت ها مزایایی را برای افرادی که در آن زندگی می کنند به همراه دارند. در زیر به بیان برخی از این مزیت ها می پردازیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بهینه کردن مصرف انرژی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاهش هزینه ها
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بهبود عملکرد ساختمان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ایجاد امنیت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کنترل امور مربوط به ساختمان از راه دور
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت هوشمند سازی ساختمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای هوشمند سازی ساختمان یک نرخ مصوب را نمی توان در نظر گرفت. هوشمندسازی ساختمان به عوامل متعددی بستگی دارد. نوع ساختمان، متراژ، برند و قیمت تجهیزات همگی از عواملی هستند که روی قیمت اتوماسیون ساختمان تاثیر گذارند. با مشخص شدن متراژ، تعداد تجهیزات مانند سنسور، تایمر و دیمر و هزینه هوشمند سازی مشخص می شود. البته یک نکته را باید در نظر داشت که در برخی از ساختمان های قدیمی به دلیل نبود سیم کشی امکان پیاده سازی اتوماسیون با پروتکل KNX مقدور نیست.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آنجا که قیمت تجهیزات هوشمند سازی بالا می باد بنابراین بهتر است که این کار توسط افراد متخصص و مجرب انجام پذیرد.
              </Typography>
              <Typography className="h2" variant="h2">
                انواع هوشمند سازی ساختمان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="انواع هوشمند سازی ساختمان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  انواع اتوماسیون ساختمان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اتوماسیون ساختمان در بخش های مختلفی انجام می پذیرد.  کنترل سیستم های تامین روشنایی، سیستم های حفاظتی و امنیتی، سیستم های تأمین گرمایش و سرمایش، کنترل تاسیسات  همگی از مواردی هستند که در هوشمند کردن بنا مورد توجه قرار می گیرد.
              </Typography>
              <Typography className="h3" variant="h3">
                هوشمند سازی سیستم روشنایی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به  نیاز و بودجه مورد نظر می توان امکاناتی نظیر کنترل از راه دور روشنایی با استفاده از تلفن همراه، تعریف برنامه زمان بندی برای روشنایی فضاهای مختلف، استفاده از سنسورهای حرکتی برای روشنایی در زمان حضور را برای اتوماسیون ساختمان به اجرا در آورد. همچنین کنترل شدت روشنایی و رنگ نور از ساده ترین قابلیت های هوشمندسازی سیستم روشنایی ساختمان می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                هوشمند سازی سیستم سرمایش و گرمایش
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اتوماسیون سیستم گرمایش و سرمایش ساختمان به دو صورت مرکزی و منفرد انجام می شود. هوشمندسازی مرکزی مانند مرکزی نظیر بویلر، چیلر، پمپ، برج خنک کننده و هوشمند سازی منفرد نیز نظیر فن کویل، کولر، هواساز می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای هوشمندسازی ساختمان نوع تجهیزات و پروتکل ارتباطی آن ها نقش به سزایی در تعیین سطح کنترل و هماهنگی این تجهیزات دارند. با هوشمند کردن سیستم سرمایشی و گرمایشی دیگر مشکلی بابت خاموش نکردن وسایل در هنگام خروج از منزل وجود ندارد. همچنین قبل از ورود به ساختمان نیز می توان هوای دلخواه را تنظیم کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                هوشمندسازی تاسیسات ساختمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برخی از مشاعات ساختمان مانند استخر، سونا و جکوزی نیاز به مراقبت ویژه ای دارند. با نصب سنسورها و پنل های مورد نیاز می توان وضعیت آب استخر و همچنین رطوبت محل را همواره کنترل کرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Midex" target="_blank">
                  بیشتر بخوانید : نمایشگاه میدکس
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                اجرای سیستم آبیاری هوشمند
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از دیگر اقدامات کاربردی در ساختمان هوشمند، هوشمندسازی سیستم آبیاری ساختمان می باشد. این سیستم متناسب با شرایط ویژه هر گیاه و مقدار نور و رطوبت مورد نیازش عمل می کند. ویژگی این سیستم این است که حتی از راه دور نیز قابلیت کنترل <Link className="link" to="/keeping-apartment-flower" target="_blank">  آبیاری گیاهان </Link> نیز امکانپذیر است.
              </Typography>
              <Typography className="h3" variant="h3">
                هوشمند سازی سیستم صوتی ساختمان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هوشمندسازی سیستم صوتی و تصویری ساختمان های مسکونی این امکان را برای ساکنین فراهم می کند که افراد آرشیو مخصوص به خود را داشته باشند و هر زمان که تمایل داشتند از آن استفاده کنند.
              </Typography>
              <Typography className="h3" variant="h3">
                هوشمند سازی موتورخانه و انواع سنسور نشت گاز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با هوشمندسازی موتورخانه ساختمان، هزینه مصرفی به مقدار قابل توجهی کاهش پیدا می کند. با استفاده از سنسور های نشت گاز هوشمند و همچنین شیرهای گاز هوشمند می توان در جهت اتوماسیون موتورخانه گام برداشت.
              </Typography>
              <Typography className="h2" variant="h2">
                ساختمان های هوشمند معروف دنیا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="ساختمان هوشمند سنگاپور - هومینجا" className="image" />
                <figcaption className="image_caption">
                  برج های هوشمند معروف دنیا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ساختمان های بی شماری در جهان وجود دارند که توسط این سیستم مدیریت می شوند. ما در زیر به بررسی تعدادی از معروفترین این ساختمان ها می پردازیم.
              </Typography>
              <Typography className="h3" variant="h3">
                گلوماک (شانگهای چین)
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ساختمان اداری به مساحت 6000 فوت از پایدارترین ساختمان های آسیا به شمار می آید. این سازه را می توان اولین ساختمانی دانست که مجوز living building for net water and carbon و zero energy را دارد. از آنجا که این ساختمان دارای 5 سیستم تصفیه هوا می باشد بنابراین می توان گفت که گلوماک بهترین کیفیت هوا را در بین ساختمان های شانگهای داراست.
              </Typography>
              <Typography className="h3" variant="h3">
                برج پایتخت (سنگاپور اندونزی)
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ساختمان 52 طبقه دارای سیستم چرخشی بازیابی انرژی تهویه مطبوع می باشد که هوای خنک را بازیابی می کند. این ساختمان بدلیل معروف بودن در صرفه جویی انرژی و آب برنده جایزه <LinkMui className="link" href="https://www1.bca.gov.sg/buildsg/sustainability/green-mark-certification-scheme" target="_blank">  Green Mark Platinum </LinkMui> شد. همچنین این ساختمان ردیاب های حرکتی را در لابی و حمام ها دارد که در مصرف انرژی صرفه جویی می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                ساختمان DPR (سانفرانسیسکو  کالیفرنیا)
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این دفتر با ارتفاع 24 فوت، اولین ساختمانی است که مجوز Net-Zero NZEB را از سانفرانسیسکو دریافت کرد. از امکانات ویژه ساختمان <LinkMui className="link" href="https://www.dpr.com/company/locations/san-francisco-california" target="_blank">  DPR </LinkMui> می توان به سیستم گرمایش آب خورشیدی پشت بام، پنجره های الکتروکرومیک، نورگیر اتوماتیک انرژی خورشیدی، پنکه سقفی با مصرف حداقل انرژی اشاره داشت.
              </Typography>
              <Typography className="h3" variant="h3">
                مرکز انرژی دوک (شارلوت  کارولینای شمالی)
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این برج 51 طبقه متعلق به بانک <LinkMui className="link" href="https://www.wellsfargo.com" target="_blank">  Wells Fargo  </LinkMui> می باشد. این ساختمان بالاترین گواهینامه زیست محیطی LEED Platinum را از آن خود کرده است. از قابلیت های این ساختمان می توان بازیافت آب های توالت، برگرداندن مجدد  10 میلیون گالن آب به چرخه محیط زیست، استفاده از پانل های خورشیدی و پمپ های حرارتی زمین برای تامین انرژی را نام برد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Best-Iranian-Real-Estate-Site" target="_blank">
                  بیشتر بخوانید : وب سایت هومینجا؛ به روزترین تکنولوژی در حوزه معاملات املاک
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امروزه بحث هوشمندسازی بسیار گسترده شده است. هوشمندسازی ساختمان از کارآمدترین تکنولوژی های روز، به منظور افزایش بهره وری سیستم های ساختمان می باشد. اتوماسیون ساختمان امنیت و آسایش را برای ساکنین فراهم می آورد. ما در این مقاله از <LinkMui className="link" href="https://homeinja.info" target="_blank">  هومینجا </LinkMui> سعی کردیم تا به معرفی ساختمان هوشمند و تجهیزات هوشمندسازی ساختمان بپردازیم. امید است که با خواندن این مقاله به سوالات ساختمان هوشمند چیست، اجزای یک ساختمان هوشمند چیست، نحوه هوشمندسازی ساختمان چگونه است و قسمت هوشمندسازی بر چه اساس تعیین میشود و ... پاسخ داده باشیم.
              </Typography>
              <Typography className="h4" variant="h4">
                هومینجا در کنار شما تا خانه دار شدن
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("تکنولوژی و فناوری ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  تکنولوژی و فناوری

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article58;