import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article02/01.jpg";
import photo02 from "../../images/mag/article02/02.jpg";
import photo03 from "../../images/mag/article02/03.jpg";
import photo04 from "../../images/mag/article02/04.jpg";
import photo05 from "../../images/mag/article02/05.jpg";
import photo06 from "../../images/mag/article02/06.jpg";
import photo07 from "../../images/mag/article02/07.jpg";
import photo08 from "../../images/mag/article02/08.jpg";
import photo09 from "../../images/mag/article02/09.jpg";
import photo10 from "../../images/mag/article02/10.jpg";
import photo11 from "../../images/mag/article02/11.jpg";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd"
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";


class Article02 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "کافه مکانی است که می توانید با دوستان و آشنایان خود برای یک قرار دوستانه یا قرار کاری ملاقات داشته باشید";
    document.getElementsByTagName('meta')["keywords"].content = "بهترین کافه های شمال تهران | کافه های دنج شمال تهران | لوکس ترین کافه های شمال تهران | کافه های روباز شمال تهران | کافه های شمال تهران برای تولد | معرفی کافه های زیبای بالاشهر تهران | برترین کافه های شمال تهران"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[1].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[1].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[1].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[1].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[1].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[1].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="کافه های شمال تهران - هومینجا" className="image" />
              <Typography variant="h2" className="h2" style={{ display: 'none' }}>
                {articlesData[1].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه مکانی است که می توانید با دوستان و آشنایان
                خود برای یک قرار دوستانه یا قرار کاری ملاقات داشته باشید. ساعت ها به گفتگو بنشینید و در صورت تمایل نوشیدنی یا غذا میل کنید. یا حتی
                بعد از یک روز کاری سخت برای فرار از خستگی و یا دلتنگی هایتان می توانید به کافه بروید.  ما در <Link className="link" to="/north-of-Tehran-cafe" target="_blank"> این مقاله </Link> از وب سایت <Link className="link" to="/" target="_blank"> هومینجا </Link> در نظر
                داریم تا به معرفی 10 مورد از کافه های شمال <Link className="link" to="/tehran-zones" target="_blank"> تهران </Link> بپردازیم. با ما همراه باشید تا پاتوق جدیدی برای دورهمی های دوستانه تان پیدا کنید.
              </Typography>
              <Typography variant="h2" className="h2">
                1- کافه گالری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کافه گالری - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه گالری
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/tehran-winter-entertaiments" target="_blank">
                  بیشتر بخوانید : تفریحات زمستانی تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین کافه ها که در زیباترین نقطه تهران قرار دارد، کافه گالری می باشد.  این کافه در باغ موزه هنر ایرانی دکتر محمود حسابی
                واقع شده است . این مکان زیبا و پر انرژی از سال 1393 به روی عموم بازگشایی شده است. باغ موزه هنر دارای معماری منحصربه‌فرد
                و چشم‌نوازی بوده که مساحتی بالغ بر یک هکتار را دارا می باشد. محیط تاریخی و سنتی باغ در نگاه اول شما را مجذوب خود میکند.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس: تهران، الهیه، خیابان <Link className="link" to="/Listing/783" target="_blank"> فرشته </Link>، خیابان بوسنی هرزگوین، چهارراه دکتر حسابی، <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%A8%D8%A7%D8%BA_%D9%85%D9%88%D8%B2%D9%87_%D9%87%D9%86%D8%B1_%D8%A7%DB%8C%D8%B1%D8%A7%D9%86%DB%8C" target="_blank"> باغ موزه هنر ایرانی </LinkMui>، پلاک ۲۵
                </li>
                <li>
                  ساعت کاری: 8.30 تا 23.30
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                2- کافه شمرون
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کافه شمرون - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه شمرون
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://cafeshemroon.com/" target="_blank"> کافه شمرون </LinkMui>
                تهران دارای شعبه های بی شماری می باشد که شعبه ی الهیه جزء یکی از بهترین کافه های روباز شمال تهران به حساب می آید.این کافه دارای
                قدمت زیادی است. شما در منوی این رستوران انواع غذاهای ایتالیایی، غذاهای ایرانی، نوشیدنی های خوشگوار و عرقیجات سنتی را می بینید.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس : <Link className="link" to="/Listing/478" target="_blank"> الهیه </Link>، خیابان تختی، نبش بن بست لاله، خانه فرهنگ خیام
                </li>
                <li>
                  ساعت کاری: 8.30 تا 23.30
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                3- کافه موگه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کافه موگه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه موگه
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه موگه یکی از بهترین کافه های بالا شهر می باشد که در خیابان <Link className="link" to="/Listing/1091" target="_blank"> فرمانیه </Link> واقع
                شده است . این کافه با وجود فضای کوچک دکوراسیونی مدرن دارد و فضایی دوستانه و صمیمی را برای مشتریان خود ایجاد کرده است. در این کافه،
                استعمال سیگار و <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank"> قلیان </Link> آزاد می باشد. از امتیازات جالب این کافه می توان به پخش بازی فوتبال در تلویزیونی بزرگ در بخشی از سالن اشاره کرد.
              </Typography>
              <ul className="P" sx={{ textAlign: 'justify' }} >
                <li>
                  آدرس : <Link className="link" to="/Listing/811" target="_blank"> کامرانیه </Link> شمالی، فرمانیه، ابتدای خیابان آریا، پلاک۳
                </li>
                <li>
                  ساعت کاری: 11.30 تا 24
                </li>
              </ul>
              <Typography className="h2" variant="h2">
                4- کافه درسا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کافه درسا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه درسا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه <LinkMui className="link" href="https://www.dorsa.net/cafe" target="_blank"> درسا </LinkMui> با فضایی باز و دلنشین جز بهترین
                کافه های شمال تهران می باشد. این کافه زیبا چشم‌اندازی جذاب رو به تهران دارد. <Link className="link" to="/small-house-decoration" target="_blank"> طراحی و چیدمان </Link> این کافه به گونه ای است که شما را یاد کافه
                های ترکیه می اندازد. کشیدن سیگار در این مکان آزاد است. در ضمن <LinkMui className="link" href="https://www.instagram.com/dorsacafe/" target="_blank">کافه درسا </LinkMui> پارکینگ هم دارد و از این بابت نگرانی بابت جای پارک خود ندارید.
                پیشنهاد ما این است چنانچه گذرتان به این کافه خورد حتما کروسان ژامبونران یا برانیز گرم با بستنی، لاته ماکیاتو و استیک  آن را امتحان کنید.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس  : خیابان <Link className="link" to="/Listings/buy_tehran_shariati" target="_blank"> شریعتی </Link> ، نرسیده به دوراهی دولت، بالاتر از تقاطع یخچال، خانه درسا، طبقه سوم
                </li>
                <li>
                  ساعت کار: 10 تا 23
                </li>
              </ul>
              <Typography variant="h2" className="h2">
                5- کافه لوکال
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کافه لوکال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه لوکال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه لوکال در منطقه <Link className="link" to="/Listings/buy_tehran_bametehran" target="_blank"> بام تهران </Link> یعنی <Link className="link" to="/Listings/buy_tehran_velenjak" target="_blank"> ولنجک </Link> قرار گرفته است. کافه لوکال  با وجود ظرفیت محدودی که دارد اما طراحی مدرن و لوکسی دارد. فضای کافه با
                چوب و گل و گیاه <Link className="link" to="/fengshui" target="_blank"> طراحی </Link> شده است. بیشتر افراد این کافه را بخاطر کوکتل‌های خوشمزه میوه‌اش می‌شناسند. اکثر غذاهایی که در منوی <LinkMui className="link" href="https://www.localcafe.ir/" target="_blank"> کافه لوکال </LinkMui> سرو می‌شوند
                غذاهای فرنگی هستند. نوشیدن قهوه اسپشیالتی و چایی و دمنوش های دمی در این فضای زیبا و دلنشین تجربه متفاوتی را برای مشتریان به ارمغان می آورد.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس : تهران،  بام تهران ، <Link className="link" to="/Listings/rent_tehran_velenjak" target="_blank"> کوچه هفتم </Link>، کافه لوکال
                </li>
                <li>
                  ساعت کار : 9 تا 23
                </li>
              </ul>
              <Typography variant="h2" className="h2">
                6- کافه لیوینگ روم
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="کافه لیوینگ روم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از لیوینگ روم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه جز لاکچری ترین کافه های شمال تهران می باشد. دکوراسیون
                این کافه به سبک یک اتاق نشیمن بسیار شیک طراحی شده است. سرآشپز این کافه بدلیل بشقاب آرایی فوق العاده ای که دارد زبان زد خاص و عام شده است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه 1 تهران
                </Link>
              </Grid>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس : <Link className="link" to="/Listings/buy_tehran_zaaferanieh" target="_blank"> زعفرانیه </Link>، تقاطع خیابان زعفرانیه و طاهری، بامیک زعفرانیه
                </li>
                <li>
                  ساعت کار: 9 تا 23
                </li>
              </ul>
              <Typography variant="h2" className="h2">
                7- چای بار انجمن خوشنویسان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="چای بار انجمن خوشنویسان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه چای بار
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه جز هنری ترین کافه های بالا شهر به حساب
                می آید. <LinkMui className="link" href="https://www.instagram.com/chaibartehran/" target="_blank"> چای بار </LinkMui> داخل یک خانه قدیمی که به سبک قجری طراحی شده است، قرار دارد. شما می‌توانید هم از فضای داخل سالن و هم فضای باغ بهره ببرید.
                همچنین سیگار کشیدن در فضای بیرونی کافه آزاد است. منوی این کافه بسیار تکمیل است. اما به شما توصیه می‌کنیم که آش روز آن را حتما امتحان کنید.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس  : خیابان دزاشیب یا همان خیابان کریمی، خیابان <Link className="link" to="/Listings/buy_tehran_dezashib" target="_blank"> خیابان سلیمی شمالی </Link>، پلاک ۱۴۵
                </li>
                <li>
                  ساعت کار: 9 تا 23
                </li>
              </ul>
              <Typography variant="h2" className="h2">
                8- کافه سینسیر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="کافه سینسیر - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه سینسیر
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.instagram.com/sincere.restaurant/" target="_blank"> کافه سینسیر </LinkMui> یکی از لوکس ترین کافه های شمال شهر تهران می باشد.این کافه مدتی است که به جای «کافه ژ» مشغول  به فعالیت است.
                ظرفیت این کافه بسیار زیاد است و می تواند پذیرای ۲۰۰ مهمان باشد. این کافه  منویی بین المللی دارد بنابراین جوابگوی هر نوع سلیقه‌ای است.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس : خیابان <Link className="link" to="/Listings/rent_tehran_elahieh" target="_blank"> دزاشیب </Link>، خیابان آقا بزرگی، خیابان صحرا، خیابان پارسا، برج افرا
                </li>
                <li>
                  ساعت کار: 12-24
                </li>
              </ul>
              <Typography variant="h2" className="h2">
                9- کافه ویکولو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="کافه ویکولو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه ویکولو
                </figcaption>
              </figure>
              <Grid className="box">
                <Link className="link" to="/tehran-zones" target="_blank">
                  بیشتر بخوانید : معرفی مناطق تهران همراه با نقشه و تصاویر
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://www.instagram.com/vicologroup/" target="_blank">کافه ویکولو </LinkMui>  از بهترین کافه های الهیه تهران می باشد. این کافه ی زیبا در فضای روف تاپ مرکز خرید مدرن الهیه قرار دارد.
                طراحی فضای این <LinkMui className="link" href="https://www.cafeyab.com/guide/cafe-vicolo" target="_blank">کافه </LinkMui> کوچه های قدیمی اروپا را تداعی می کند. با توجه به اینکه قیمت های این کافه بسیار بالاست اما ویکولو همیشه شلوغ است.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس: تهران، الهیه، <Link className="link" to="/Listings/rent_tehran_elahieh" target="_blank"> ،آفریقای شمالی </Link>، مجتمع مدرن الهیه، رویال فلور، پلاک ۴
                </li>
                <li>
                  ساعت کاری: 10 تا 24
                </li>
              </ul>
              <Typography variant="h2" className="h2">
                10- کافه روبرتو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="کافه روبرتو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  نمایی از کافه روبرتو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://robertocafe.ir/" target="_blank"> کافه روبرتو </LinkMui> یکی از بهترین کافه رستوران های شمال تهران می باشد. فضای این کافه بسیار مناسب
                دورهمی های دوستانه است. با توجه به شلوغی کافه  فضا مناسب قرارهای دونفره نیست. با توجه به کیفیت عالی غذاهای این کافه قیمت ها کمی گران می باشد.
                نکته جالب درمورد این کافه این است که مدیریت این کافه شخصی ایتالیایی است درنتیجه می‌توانید قهوه و شیرینی های خوشمزه ای در این کافه میل کنید.
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>
                  آدرس کافه روبرتو: خیابان <Link className="link" to="/Listings/rent_tehran_pasdaran" target="_blank"> پاسداران </Link>، بوستان دوم، پلاک چهار
                </li>
                <li>
                  ساعت کار : 10 تا 23
                </li>
              </ul>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/north-of-Tehran-cafe" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/north-of-Tehran-cafe" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/north-of-Tehran-cafe" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article02;