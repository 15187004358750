import React from "react";
import { Grid,Button,Typography,Autocomplete,TextField, Checkbox, Slider } from "@mui/material";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import { Link } from "react-router-dom";
import axios from 'axios';
import AlertModal from "./materilas/alert";
import { Box } from "@mui/system";
import { checkedIcon, convertToPersianNumber, icon, separator,baseUrl,bedRoom } from "./materilas/vmp";

class SearchBox extends React.Component{

  stateOption = null;
  cityOption = null;
  zoneOption = null;
  neibOption = [];
  estateOption = [];
  roomNumber = null;
  isMount = false;
  
  constructor(props){
      super(props);
      this.state={
          activeRent:true,
          stateType:'R',
          typeEstate:[],
          nameState:[],
          city:[],
          disableCity:true,
          zone:[],
          disableZone:true,
          nieghbourhood:[],
          disableNaib:true,
          openAlert:false,
          messageAlert:'',
          ariaRange:[30,3000],
          mortageRange:[10,5000],
          rentRange:[0.5,200],
          priceRange:[100,900000],
          stateChoose:{name:'تهران',id:1},
          inputCity:'',
          inputZone:'',
          inputNeib:[],
          typeAlert:3
        }
  }

  componentDidMount(){
    this.isMount = true;
    if(this.isMount){
      this.getState();
      this.getTypeEstate();
      this.getCity(this.state.stateChoose.id)
    }
  }

  componentWillUnmount(){
    this.isMount = false;
  }

  onClickButton = (type) =>{
    this.setState({activeRent:type === this.state.stateType ? this.state.activeRent : !this.state.activeRent,stateType:type})
  }

  onCloseAlert = () =>{
    this.setState({openAlert:false});
  }

  getTypeEstate = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/estate/get',
      method: 'GET'
    }))
    .then((res) => {
      this.setState({typeEstate:res.data.data})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'ارتباط با اینترنت خود را چک بفرمایید.',typeAlert:2})
    });
  }

  getState = () =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/state/get',
      method: 'GET'
    }))
    .then((res) => {
      this.setState({nameState:res.data.data})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'ارتباط با اینترنت خود را چک بفرمایید.',typeAlert:2})
    });
  }

  onChangeState = (event,newValue) =>{
    this.setState({inputCity:'',inputZone:'',inputNeib:[],disableCity:true,disableZone:true,disableNaib:true})
    this.stateOption = this.cityOption = this.zoneOption = this.neibOption = null;
    if (newValue !== null){
      this.getCity(newValue.id);
      this.stateOption = {
        name:newValue.name,
        id:newValue.id
      }
    }
  }

  getCity = (stateValue) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/city/get',
      method: 'GET',
      params:{
        state_id:stateValue
      }
    }))
    .then((res) => {
      this.setState({city:res.data.data,disableCity:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'ارتباط با اینترنت خود را چک بفرمایید.',typeAlert:2})
    });
  }

  onChangeCity = (event,newValue) =>{
    this.setState({inputCity:'',inputZone:'',inputNeib:[],disableZone:true,disableNaib:true})
    this.cityOption = this.zoneOption = this.neibOption = null;
    if (newValue !== null){
      this.getZone(newValue.id);
      this.cityOption = {
        name:newValue.name,
        id:newValue.id
      }
      this.setState({inputCity:newValue.name})
    }
  }

  getZone = (city) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/zone/get',
      method: 'GET',
      params:{
        city_id:city
      }
    }))
    .then((res) => {
      this.setState({zone:res.data.data,disableZone:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'ارتباط با اینترنت خود را چک بفرمایید.',typeAlert:2})
    });
  }

  onChangeZone = (event,newValue) =>{
    this.setState({inputZone:'',inputNeib:[],disableNaib:true})
    this.zoneOption = this.neibOption = null;
    if (newValue !== null){
      this.getNieghbourhood(newValue.id)
      this.zoneOption = {
        name:newValue.name,
        id:newValue.id
      }
      this.setState({inputZone:newValue.name})
    }
  }

  getNieghbourhood = (zone) =>{
    Promise.resolve(axios({
      url: baseUrl+'v1/neighb/get',
      method: 'GET',
      params:{
        zone_id:zone
      }
    }))
    .then((res) => {
      this.setState({nieghbourhood:res.data.data,disableNaib:res.data.data.length > 0 ? false : true})
    })
    .catch((err) => {
      this.setState({openAlert:true,message:'ارتباط با اینترنت خود را چک بفرمایید.',typeAlert:2})
    });
  }

  onChangeNieghbourhood = (event,value) =>{
    this.neibOption = value;
    this.setState({inputNeib:value})
  }

  onChangeTypeEstate = (event,value) =>{
    this.estateOption = value;
    
  }

  onChangeBedRoom = (event,newValue) =>{
    if (newValue !== null){
      if (newValue.num !== '11+'){
        this.roomNumber = newValue.num;
      }
      else{
        this.roomNumber = 'great'
      }
    }
    else{
      this.roomNumber = null
    }
  }

  onChangeAria = (event,newValue)=>{
    this.setState({ariaRange:newValue})
  }

  onChangeMortage = (event,newValue)=>{
    this.setState({mortageRange:newValue})
  }

  onChangeRent = (event,newValue)=>{
    this.setState({rentRange:newValue})
  }

  onChangePrice = (event,newValue)=>{
    this.setState({priceRange:newValue})
  }

  onSearchButton = () =>{
    this.deleteActiveClass();
    let option = {
      transaction:this.state.activeRent ? 2 : 1,
      state:this.stateOption !== null ? this.stateOption : this.state.stateChoose,
      city:this.cityOption,
      zone:this.zoneOption,
      nieghbourhood:this.neibOption,
      estate:this.estateOption,
      room:this.roomNumber,
      area:this.state.ariaRange,
      mortage:this.state.mortageRange,
      rent:this.state.rentRange,
      price:this.state.priceRange
    }
    localStorage.setItem('optionSearchHomeInja',JSON.stringify(option))
  }

  deleteActiveClass = () => {
    let activeElement = document.getElementsByClassName("active");
    if(activeElement.length > 0){
      activeElement[0].className = activeElement[0].className.replace(' active', '');
    }
  }

  render(){
      return(
          <Grid sx={{display:'flex',justifyContent:'center'}} className="GridSearch">
              <Grid item xs={10} sx={{zIndex:20}}>
                  <Grid style={{display:'flex',flexDirection:'row'}}>
                      <Button variant="contained" onClick={() => this.onClickButton('R')}
                              style={{fontFamily:'shabnam',fontWeight:'bold',backgroundColor:this.state.activeRent ? '#B4EEF8' : '#FFFFFF',
                                      color:'black',borderTopRightRadius:'25px'}}
                                      sx={{fontSize:{lg:'1.25em',md:'1em',sm:'0.9em',xs:'0.6em'},width:{lg:'15%',md:'18%',sm:'20%',xs:'30%'},
                                              height:{lg:'64px',md:'58px',sm:'50px',xs:'45px'}}}
                      >رهن و اجاره</Button>
                      <Button variant="contained" onClick={() => this.onClickButton('B')}
                              style={{fontFamily:'shabnam',fontWeight:'bold',backgroundColor:this.state.activeRent ? '#FFFFFF' : '#B4EEF8',
                                      color:'black',borderTopLeftRadius:'25px',marginRight:-1}}
                              sx={{fontSize:{lg:'1.25em',md:'1em',sm:'0.9em',xs:'0.6em'},width:{lg:'15%',md:'18%',sm:'20%',xs:'30%'},
                                      height:{lg:'64px',md:'58px',sm:'50px',xs:'45px'}}}
                      >خرید</Button>
                  </Grid>
                  <Grid item xs={12} className='searchGrid'>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={6} className="autoCompleteGrid">
                        <Autocomplete
                          id="state_combo"
                          className="combo"
                          options={this.state.nameState}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField className="textField" {...params} placeholder="استان"/>}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          defaultValue={this.state.stateChoose}
                          value={this.stateOption}
                          onChange={this.onChangeState}
                          clearText='حذف'
                          closeText="بستن"
                          openText="باز کردن"
                          noOptionsText="فاقد اطلاعات"                              
                        />
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={6} className="autoCompleteGrid">
                          <Autocomplete
                            id="state_combo"
                            className="combo"
                            options={this.state.city}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField className="textField" {...params} placeholder='شهر'/>}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            disabled={this.state.disableCity}
                            onChange={this.onChangeCity}
                            clearText='حذف'
                            closeText="بستن"
                            openText="باز کردن"
                            noOptionsText="فاقد اطلاعات"
                            inputValue={this.state.inputCity}
                          />
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={6} className="autoCompleteGrid">
                          <Autocomplete
                            id="state_combo"
                            className="combo"
                            options={this.state.zone}
                            getOptionLabel={(option) => convertToPersianNumber(option.name)}
                            renderInput={(params) => <TextField className="textField" {...params} placeholder='منطقه'/>}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            disabled={this.state.disableZone}
                            onChange={this.onChangeZone}
                            clearText='حذف'
                            closeText="بستن"
                            openText="باز کردن"
                            noOptionsText="فاقد اطلاعات"
                            inputValue={convertToPersianNumber(this.state.inputZone)}
                          />
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={6} className="autoCompleteGrid">
                          <Autocomplete
                            id="state_combo"
                            className="combo"
                            options={this.state.nieghbourhood}
                            getOptionLabel={(option) => convertToPersianNumber(option.name)}
                            renderInput={(params) => <TextField className="textField" {...params} placeholder='محله'/>}
                            disabled={this.state.disableNaib}
                            disableCloseOnSelect
                            multiple
                            value={this.state.inputNeib}
                            limitTags={1}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} style={{padding:"0px"}}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: {xs:0,md:8}}}
                                    checked={selected}
                                />
                                {convertToPersianNumber(option.name)}
                                </li>
                            )}
                            onChange={this.onChangeNieghbourhood}
                            clearText='حذف'
                            closeText="بستن"
                            openText="باز کردن"
                            noOptionsText="فاقد اطلاعات"
                          />
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={6} className="autoCompleteGrid">
                          <Autocomplete
                          id="state_combo"
                          className="combo"
                          options={this.state.typeEstate}
                          getOptionLabel={(option) => option.des}
                          renderInput={(params) => <TextField className="textField" {...params} placeholder='نوع ملک'/>}
                          disableCloseOnSelect
                          multiple
                          limitTags={1}
                          renderOption={(props, option, { selected }) => (
                              <li {...props}>
                              <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                              />
                              {option.des}
                              </li>
                          )}
                          onChange={this.onChangeTypeEstate}
                          clearText='حذف'
                          closeText="بستن"
                          openText="باز کردن"
                          noOptionsText="فاقد اطلاعات"
                          />
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={6} className="autoCompleteGrid">
                          <Autocomplete
                          id="state_combo"
                          className="combo"
                          options={bedRoom}
                          getOptionLabel={(option) => convertToPersianNumber(option.num)}
                          renderInput={(params) => <TextField className="textField" {...params} placeholder='تعداد اتاق'/>}
                          renderOption={(props, option, { selected }) => (
                              <li {...props}>
                              <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                              />
                              {convertToPersianNumber(option.num)}
                              </li>
                          )}
                          onChange={this.onChangeBedRoom}
                          clearText='حذف'
                          closeText="بستن"
                          openText="باز کردن"
                          noOptionsText="فاقد اطلاعات"
                          />
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={12} className="sliderGrid">
                          <Box sx={{width:{xl:215,lg:215,md:215,sm:280,xs:'88%'},marginRight:{xl:'3%',lg:'3%',md:'3%',sm:'3%',xs:'4%'}}} className="sliderBox">
                              <Slider
                                  getAriaLabel={() => 'متراژ'}
                                  valueLabelDisplay="auto"
                                  min={30}
                                  max={3000}
                                  step={10}
                                  value={this.state.ariaRange}
                                  onChange={this.onChangeAria}
                                  valueLabelFormat={(x) =>convertToPersianNumber(x) + ' مترمربع '}
                              />
                          </Box>
                              <Grid style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginRight:'2%'}} className="sliderText">
                              <HomeinjaTypo sx={{fontSize:{sm:'0.9em',xs:'0.6em'}}} text={'متراژ - مترمربع'}/>
                              <Typography style={{fontFamily:'shabnam',backgroundColor:'white'}} sx={{fontSize:{sm:'1em',xs:'0.7em'}}} component={'p'}>
                                  {convertToPersianNumber(this.state.ariaRange[1]) + ' تا ' + convertToPersianNumber(this.state.ariaRange[0])}
                              </Typography>
                          </Grid>    
                      </Grid>
                      <Grid item xl={2.4} lg={3} md={4} sm={6} xs={12} className="sliderGrid">
                          <Box sx={{width:{xl:215,lg:215,md:215,sm:280,xs:'88%'},marginRight:{xl:'3%',lg:'3%',md:'3%',sm:'3%',xs:'4%'}}} className="sliderBox">
                              <Slider
                                  getAriaLabel={() => 'رهن'}
                                  valueLabelDisplay="auto"
                                  min={10}
                                  max={5000}
                                  step={20}
                                  value={this.state.mortageRange}
                                  onChange={this.onChangeMortage}
                                  valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                                  disabled={!this.state.activeRent ? true : false}
                              />
                          </Box>
                          <Grid style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginRight:'2%'}} className="sliderText">
                              <HomeinjaTypo sx={{fontSize:{sm:'0.9em',xs:'0.6em'}}} text={'رهن - میلیون تومان'}/>
                              <Typography style={{fontFamily:'shabnam',backgroundColor:'white'}} sx={{fontSize:{sm:'1em',xs:'0.7em'}}} component={'p'}>
                                  {convertToPersianNumber(separator(this.state.mortageRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.mortageRange[0]))}
                              </Typography>
                          </Grid>    
                      </Grid>
                      <Grid item xl={2.4} lg={6} md={4} sm={6} xs={12} className="sliderGrid">
                          <Box sx={{width:{xl:215,lg:430,md:215,sm:280,xs:'88%'},marginRight:{xl:'3%',lg:'6%',md:'3%',sm:'3%',xs:'4%'}}} className="sliderBox">
                              <Slider
                                  getAriaLabel={() => 'اجاره'}
                                  valueLabelDisplay="auto"
                                  min={0.5}
                                  max={200}
                                  step={1}
                                  value={this.state.rentRange}
                                  onChange={this.onChangeRent}
                                  valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                                  disabled={!this.state.activeRent ? true : false}
                              />
                          </Box>
                          <Grid style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginRight:'2%'}} className="sliderText">
                              <HomeinjaTypo sx={{fontSize:{sm:'0.9em',xs:'0.6em'}}} text={'اجاره - میلیون تومان'}/>
                              <Typography style={{fontFamily:'shabnam',backgroundColor:'white'}} sx={{fontSize:{sm:'1em',xs:'0.7em'}}} component={'p'}>
                                  {convertToPersianNumber(separator(this.state.rentRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.rentRange[0]))}
                              </Typography>
                          </Grid>    
                      </Grid>
                      <Grid item xl={2.4} lg={6} md={4} sm={6} xs={12} className="sliderGrid">
                          <Box sx={{width:{xl:215,lg:430,md:215,sm:280,xs:'88%'},marginRight:{xl:'3%',lg:'6%',md:'3%',sm:'3%',xs:'4%'}}} className="sliderBox">
                              <Slider
                                  getAriaLabel={() => 'قیمت'}
                                  valueLabelDisplay="auto"
                                  min={100}
                                  max={900000}
                                  step={200}
                                  value={this.state.priceRange}
                                  onChange={this.onChangePrice}
                                  valueLabelFormat={(x) =>convertToPersianNumber(separator(x)) + ' میلیون تومان '}
                                  disabled={this.state.activeRent ? true : false}
                              />
                          </Box>
                          <Grid style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginRight:'2%'}} className="sliderText">
                              <HomeinjaTypo sx={{fontSize:{xs:"0.6em",sm:'0.8em'}}} text={'قیمت - میلیون تومان'}/>
                              <Typography style={{fontFamily:'shabnam',backgroundColor:'white'}} sx={{fontSize:{sm:'1em',xs:'0.7em'}}} component={'p'}>
                                  {convertToPersianNumber(separator(this.state.priceRange[1])) + ' تا ' + convertToPersianNumber(separator(this.state.priceRange[0]))}
                              </Typography>
                          </Grid>    
                      </Grid>
                      <Grid item xs={12} sx={{display:'flex',justifyContent:'end',alignItems:'center',flexDirection:'row',marginTop:'3vh'}}>
                          <Button style={{backgroundColor:'#01C7E9',height:65,borderBottomLeftRadius:25,
                                          borderTopRightRadius:25,color:'black',fontFamily:'shabnam',fontSize:'1.5em',fontWeight:'bold'}} 
                                  variant="contained" component={Link} to="/Listings" className="searchButton"
                                  sx={{width:{xl:'25%',lg:'30%',md:'30%',sm:'35%'}}}
                                  onClick={() => this.onSearchButton()}
                          >
                              جستجو
                          </Button>
                      </Grid>
                  </Grid>
                  
              </Grid>
              
              <AlertModal open={this.state.openAlert} close={this.onCloseAlert} message={this.state.messageAlert} type={this.state.typeAlert}/>
          </Grid>
      )
  }

   
}

export default SearchBox;


