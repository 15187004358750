import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import photo01 from "../../images/mag/article70/01.jpg";
import photo02 from "../../images/mag/article70/02.jpg";
import photo03 from "../../images/mag/article70/03.jpg";
import photo04 from "../../images/mag/article70/04.jpg";
import photo05 from "../../images/mag/article70/05.jpg";
import photo06 from "../../images/mag/article70/06.JPG";
import photo07 from "../../images/mag/article70/07.jpg";

import "../../styles/article.css";
import {
  ArticleAd1,
  ArticleAd2,
  ArticleAd3,
  ArticleAd4,
} from "../mag/articleAd";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import { article as articlesData } from "../mag/articles";
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article70 extends React.Component {
  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false,
    };
  }

  componentDidMount() {
    document.getElementsByTagName("meta")["description"].content =
      "به مجموعه ای از فعالیت ذهنی، روحی و جسمی برای رسیدن به آرامش و سلامت بدن در فلسفه سنتی هند باستان، یوگا می گویند.";
    document.getElementsByTagName("meta")["keywords"].content =
      "ورزش یوگا در خانه | تکنیک انجام حرکات یوگا | بهترین حرکات یوگا در منزل | یوگا و آرامش ذهن | تاثیر یوگا بر روح | فواید یوگا بر سلامت جسم | یوگا و درمان افسردگی | یوگا و تمرکز ذهن | وسایل مورد نیاز برای یوگا | انواع حرکات یوگا | یوگا و انعطاف بدن | بهترین مربی یوگا";
    document.getElementsByTagName("meta")["author"].content =
      "شرکت وب گستران سورین";
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName("meta")["description"].content =
      "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML =
      articlesData[69].h1 + " | هومینجا ";
  };

  setTitle = () => {
    document.title = articlesData[69].title + " | هومینجا ";
  };

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  };

  openAuth = (type) => {
    switch (type) {
      case "L":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      case "R":
        this.setState({ openAuthModal: true, modalType: type });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  };

  onCloseAuth = () => {
    this.setState({ openAuthModal: false });
  };

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  };

  render() {
    return (
      <Grid
        sx={{
          marginTop: { xs: "61px", md: "88px" },
          bgcolor: "#E7F0F3",
          padding: { xs: "5px", md: "15px" },
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SideBar access={articlesData[69].access} />
        <Grid container sx={{ maxWidth: "1610px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              sx={{
                bgcolor: "white",
                borderRadius: "8px",
                boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)",
                padding: { xs: "5px", md: "30px" },
              }}
            >
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[69].h1}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "10px 0 20px",
                }}
              >
                <Typography className="date_author">
                  نویسنده : {articlesData[69].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[69].date)}
                </Typography>
              </Grid>
              <Typography
                component={"img"}
                src={photo01}
                alt="یوگا - هومینجا  "
                className="image"
              />
              <Typography
                className="h2"
                variant="h2"
                style={{ display: "none" }}
              >
                {articlesData[69].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یوگا مجموعه ای از فعالیت های جسمی، روحانی و ذهنی است. یوگا در
                هند باستان بوجود آمد. محققان هنوز نتوانستند بیابند که ورزش یوگا
                توسط چه کسی کشف شده است. چرا که تاریخ شکل گیری یوگا به سال های
                بسیار دور بر می گردد. منظور از یوگا یکپارچگی و اتحاد با خویشتن
                است. برای دست یابی به این اتحاد، مجموعه ای از حالات و تمریناتی
                طراحی شد که به این نوع حرکات{" "}
                <LinkMui
                  className="link"
                  href="https://fa.wikipedia.org/wiki/%D8%A2%D8%B3%D8%A7%D9%86%D8%A7"
                  target="_blank"
                >
                  {" "}
                  اسانا{" "}
                </LinkMui>{" "}
                می گویند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اساناهای یوگا به قرار گرفتن در حالاتی که در آن روح ، جسم و ذهن
                در ارامش کامل قرار دارند می گویند. حرکات یوگا برای سلامتی بدن
                خواص بسیاری دارد که در ادامه به آن می پردازیم. در این مقاله قصد
                داریم در مورد فواید ورزش یوگا و تاثیر آن بر آرامش ذهن و جسم صحبت
                کنیم و هم چنین بعضی از بهترین حرکات یوگا در منزل را به شما آموزش
                دهیم.
              </Typography>
              <Typography className="h2" variant="h2">
                یوگا چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ورزش یوگا یک ایین معنوی است، مجموعه ای از تمرینات ذهنی، جسمی و
                روحی برای بالا بردن کیفیت زندگی است تا افراد به ارامش و مراقبه
                دست پیدا کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یوگا به ورزش مدیتیشن مرتبط است یعنی هر چه فرد در تمرینات یوگا
                مهارت داشته باشد، در مدیتیشن نیز موفق تر خواهد بود. ورزش یوگا
                دارای ابعاد فلسفی می باشد. به گفته کارشناسان فلسفه یوگا با روح
                درونی انسان و گیتی سروکار دارد. تمرینات یوگا می تواند روح انسان
                را سرشار از ارامش کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ورزش یوگا به هزاران سال پیش باز می گردد و روز به روز طرفداران
                این ورزش بیشتر می شود.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Fasting-Diet" target="_blank">
                  بیشتر بخوانید : رژیم فستینگ چیست؟ انواع آن و خوراکی های مجاز
                  این رژیم
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                انواع یوگا چیست؟
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo03}
                  alt="انواع یوگا - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">انواع یوگا</figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یوگا نیز مانند بسیاری از ورزش های دیگر دارای انواع گوناگونی است.
                اگر شما جز افرادی هستید که قادر به شناسایی بهترین سبک یوگای خود
                نیستید، بهتر است ابتدا در کلاس های مبتدی یوگا شرکت کنید تا
                بدانید این سبک از ورزش مورد علاقه شما است یا خیر.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ما در این مقاله سبک های مختلف یوگا را به شما معرفی می کنیم تا
                شما راحت تر و با توجه به سلیقه خود، نوع آن را انتخاب کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                هاتا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سبک یوگا هاتا در زبان معاصر به معنی آهسته و ارام است. هاتا
                بهترین سبک از این ورزش برای مبتدیان است زیرا در این نوع سبک
                تمرینات به صورت ارام و اهسته اجرا می شود. این سبک یوگا به راحتی
                در خانه قابل انجام است.
              </Typography>
              <Typography className="h3" variant="h3">
                انوسارا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <LinkMui
                  className="link"
                  href="https://en.wikipedia.org/wiki/John_Friend_(yogi)"
                  target="_blank"
                >
                  {" "}
                  جان فرند{" "}
                </LinkMui>{" "}
                کسی است که این سبک از یوگار را به وجود آورد. در انوسارا تاکید
                موکد روی هم ترازی فیزیکی و فلسفه مثبت مبتنی بر اعتقاد به خوبی
                ذاتی موجودات است. کلاس های سبک یوگا انوسارا معمولا شاد می باشد
                که تمرکز بر روی گشایش قلب است. از این سبک از یوگا به کسانی
                پیشنهاد می شود که استرس شدیدی در زندگی دارند.
              </Typography>
              <Typography className="h3" variant="h3">
                جریان وینیاسا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                جریان وینیاسا سبک شدیدتری از یوگا است. تمرکز این سبک بر روی تنفس
                افراد است. وینیاسا با تعدادی حرکت سلام بر خورشید اغاز می شود.
                برای انجام این سبک به تجربه در انجام حرکات مبتدی یوگا نیاز است.
              </Typography>
              <Typography className="h3" variant="h3">
                اشتانگا:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                فردی به نام پاتابی جویس یوگا اشتانگا را در دهه 1960 تاسیس کرد.
                اشتانگا یکی از نخستین سبک های یوگا به حساب می آید. اشتانگا سبکی
                جاری و سریع است. حرکات در این سبک از یوگا با ترتیب مشخص انجام می
                شود و این ترتیب بدون تغییر است. در این سبک از یوگا فرد باید از
                قدرت بدنی بالایی برخوردار باشید، زیرا حرکات مستمر از یک حرکت به
                حرکت بعدی وجود دارد.
              </Typography>
              <Typography className="h3" variant="h3">
                بیکرام:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                موسس یوگای بیکرام، بیکرام چود هدری بوده است. در این سبک از یوگا
                درجه اتاق خانه باید بین 35 تا 40 باشد. گرما محیط باعث ازاد شدن
                عضلات گرفته و تعریق می شود که نوعی پاکسازی بدن محسوب می شود.
              </Typography>
              <Typography className="h3" variant="h3">
                وینیاسای قدرتی باپتیست:
              </Typography>

              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo02}
                  alt="وینیاسای قدرتی - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  وینیاسای قدرتی{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یوگا وینیاسای قدرتی باپتیست توسط بارون باپتیست تاسیس شد. کلاس
                های این سبک از یوگا در اتاقی با درجه بالا برگزار می شود و باعث
                تعریق افراد می شود. این سبک از یوگا به ندرت در منزل کارایی دارد.
                انجام این نوع حرکت یوگا به لامت جسم شما کمک شایانی می کند.
              </Typography>

              <Typography className="h3" variant="h3">
                اینگار:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سبک اینگار یوگا یعنی قرار دادن بدن در بهترین حالت هم ترازی که
                برای تحقق این هدف از وسایلی مثل اجر و بند یوگا استفاده می شود.
                تمرینات اینگار معمولا مبتنی بر ماندن در حرکات برای مدت زمان
                طولانی است. برای انجام این نوع حرکت نیاز به آمادگی متوسط
                جسمانیست درغیر اینصورت احتمال آسیب دیدگی بسیار بالاست.
              </Typography>
              <Typography className="h3" variant="h3">
                فارست:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                فارست یوگا توسط شخصی به نام انا فارست تاسیس شد. هدف فارست تقویت
                بدن و ازادسازی دردهای سرکوب شده است که تاکید آن روی تقویت عضلات
                شکم و تنفس عمیق است. این حرکت به تناسب اندام شما کمک میکند.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/What-Is-Cardio-Exercises"
                  target="_blank"
                >
                  بیشتر بخوانید : تمرینات کاردیو برای کاهش وزن و چربی سوزی
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                اینتگرال:
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اینتگرال یوگا سبک ارام هاتا است که مبتنی بر ایده ها و اصول سوامی
                سات چیداناندا است. هدف وی تلفیق جسم، ذهن و روح است.
              </Typography>
              <Typography className="h2" variant="h2">
                فواید یوگا برای بدن و ذهن
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همانطور که اشاره کردیم ورزش یوگا علاوه بر فواید جسمی، فواید
                بسیاری برای رسیدن به ارامش ذهنی و روحی در افراد است. از جمله
                فواید ذهنی یوگا عبارت است از:
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>
                کمک به درمان افسردگی
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo04}
                  alt="یوگا و درمان افسردگی - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  یوگا و درمان افسردگی{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                همانطور که قابل مشاهده است افراد زیادی در این دوران از افسردگی
                رنج می برند. یوگا یک درمان مکمل برای افراد در کنار پزشکشان است.
                تحقیقات نشان می دهد انجام حرکات یوگا به طور منظم می تواند
                افسردگی را به میزان زیادی کاهش دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                حتی می توان یوگا را جایگزین داروهای افسردگی کرد (داروهای افسردگی
                نه تنها برای شما مفید نیست بلکه بعد از مدتی استفاده از این
                داروها عوارض زیادی برای شما بر جای خواهد گذاشت. در نتیجه شما می
                توانید ورزش را جایگزین کنید به شرط انکه به صورت منظم انجام دهید.
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>
                کمک به کاهش استرس
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                استرس منشا بسیاری از بیماری ها است پس هیچ وقت استرس بی مورد را
                به حال خود رها نکنید. تحقیقات نشان می دهد یوگا تاثیر شگفت انگیزی
                در کاهش استرس دارد.کاهش استرس و فشار ناشی از کار و مسایل
                خانوادگی باعث افزایش شادابی بدن و استقامت شما میشود.
              </Typography>

              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>
                افزایش تمرکز
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ایجاد هماهنگی که در تمرینات یوگا وجود دارد باعث افزایش تمرکز و
                توانایی مغز می شود. ورزش یوگا به دلیل نوع تکنیک انجام آن و
                آرامکش ذاتی ورزش، ناخودآگاه تاثیر به سزایی برو روی آرامش فکری
                شما دارد. افزایش آرامش ذهن به تصمیم گیری بهتر منتهی میشود.
              </Typography>

              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>
                بهبود کیفیت خواب
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                داشتن خواب ناکافی باعث خستگی، اشفتگی، عدم تمرکز، استرس، چهره ای
                کدر و خسته در طول روز می شود. انجام حرکات یوگا به طور منظم باعث
                ارامش و در نتیجه بهبود کیفیت خواب می شود. خواب عمیق و به اندازه
                تاثیر به سزایی در روح، ذهن و جسن انسان دارد. با انجام یوگا
                درمنزل به بهبود سلامت جسم و ذهن خود بپردازید.
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>
                کنار امدن با مشکلات
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اغلب افراد در طول روز با مشکلات کاری، خانوادگی، ملی و ... روبرو هستند.

                یوگا به دلیل ارامش ذهنی که به افراد می دهد، باعث می شود افراد با منطق بیشتری نسبت به مشکلات خود رفتار کنند. تمام موارد گفته شده به عنوان فواید حرکات یوگا به بهبود تصمیم گیری که ناشی از عملکرد درست مغر می باشد، منتهی می شود.
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/weight-loss-and-fitness"
                  target="_blank"
                >
                  بیشتر بخوانید : کاهش وزن اصولی و ترفندهای رسیدن به تناسب اندام
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                فواید یوگا برای جسم و تناسب اندام
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo05}
                  alt="یوگا و تناسب اندام - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  یوگا و تناسب اندام{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ورزش یوگا نیز مانند ورزش های دیگر اگر با رعایت رژیم غذایی سالم همراه باشد فواید بسیار زیادی برای جسم ما دارد از جمله این فواید عبارت است از:
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>
                قدرت عضلانی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برخی از حرکات یوگا دارای تمرینات پرفشاری است که می تواند تاثیر چشمگیری در بالا بردن قدرت عضلات داشته باشد. البته باید بدانید اگر هدفتان بالا بردن قدرت عضلانی است تنها یوگا برای شما کافی نیست اما به شما کمک می کند تا در کنار ورزشی دیگر زودتر به هدفتان برسید. ورزش بدن سازی و یا حتی ورزش های استقامتی مثل دو و پیاده روی به تناسب و زیبایی جسم شما کمک فراوانی می کند.
              </Typography>

              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>

                انعطاف پذیری و تعادل
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یکی از اصلی ترین و با ارزش ترین فواید یوگا رسیدن به انعطاف بدنی است. پس اگر جز افرادی هستید که علاقه به داشتن بدنی منعطف دارند یوگا بهترین گزینه برایشان است. به یاد داشته باشید داشتن بدنی منعطف صبر زیادی می خواهد. پس اگر ماه اول به هدف دلخواه خود نرسیدید ناامید نشوید و ادامه دهید. اساناها اغلب تمرینات کششی و اهسته هستند و اگر به طور منظم ادامه داشته باشند شما می توانید به هدف خود برسید، کافی است روزی 20 دقیقه حرکات را انجام دهید بعد از گذشت 3 ماه شما نتیجه دلخواه را می بینید.
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>

                بهبود تنفس
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                به تمرینات تنفسی که در یوگا انجام می دهید پرانایاما گفته می شود. انجام حرکات پرایاناما به مرور زمان باعث می شود کیفیت تنفس شما بهتر شود. در نتیجه اکسیژن بیشتری به مغز و بافت های بدن شما می رسد. چراکه کار یوگا بهیود عملکرد و ارتباط اعضای بدن با جسم و روح است و این ارتباط با زیبایی رفتار انسان در کوچکترین حرکات منجر می شود.
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>

                بهبود بیماری
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ورزش یوگا یکی از بهترین روشها برای بهبود بیماری است. از ورزش یوگا می توان به عنوان یک درمان مکمل در کنار درمان های پزشکی استفاده کرد. شرکت در کلاس های یوگا خستگی بعد از بیماری را کاهش می دهد و باعث بهبود سریع تر بیماری می شود و به نوعی باعث کوتاه شدن زمان نقاحت و افزایش سرعت ریکاوری منجر می شود.
              </Typography>
              <Typography className="p" component={"li"} sx={{ textAlign: "justify", fontWeight: "700" }}>

                سلامت قلب
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                از دیگر فواید یوگا بهبود سلامت قلب است. انجام حرکات یوگا باعث ارامش در افراد می شود. در نتیجه این امر استرس افراد نیز کمتر خواهد شد. و با پایین امدن فشار خون خطر ابتلا به بیماری های قلبی کم می شود. پس اگر جز افرادی هستید که بیماری قلبی دارند ورزش یوگا بهترین گزینه برای شماست.
              </Typography>
              <Typography className="h2" variant="h2">
                انجام حرکات یوگا در خانه
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo06}
                  alt="یوگا در خانه - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  یوگا در خانه{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                اگر تصمیم دارید یوگا را در خانه و منزل انجام دهید اولین سوالی که معمولا ذهنتان را درگیر می کند این است که، چه وسایلی برای این ورزش احتیاج دارید؟
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                خوشبختانه باید بگویم که یوگا جز ورزش هایی است که با تجهیزات کم می توانید این ورزش را در خانه انجام دهید.
              </Typography>
              <Typography className="h3" variant="h3">
                1- داشتن مت مناسب
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                برای شروع یوگا در خانه به یک مت احتیاج دارید. مت باعث می شود برای انجام حرکات، تعادل بیشتری داشته باشید.
              </Typography>
              <Typography className="h3" variant="h3">
                2- از مربی کمک بگیرید
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                امروزه خیلی از کلاس ها به صورت انلاین برگزار می شود اگر قصد دارید ورزش یوگا را در خانه شروع کنید حتما از یک مربی کمک  بگیرید. اینگونه شما به بدنتان اسیبی وارد نخواهید کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                3- رعایت اصل اضافه بار
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                سعی کنید اصل اضافه بار را در ورزشتان حفظ کنید، یعنی اگر مبتدی هستید اول از حرکات ساده و زمان کم شروع کنید و بعد به تدریج حرکات را پیچیده تر کنید و ساعت تمرین را زیادتر.
              </Typography>
              <Typography className="h3" variant="h3">
                4- سراغ حرکات اصلی یوگا بروید
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                بهتر است برای شروع چند حرکت اصلی یوگا را انجام دهید و به مرور زمان حرکات را پیچیده تر کنید. برخی از حرکات اصلی یوگا عبارتند از:
              </Typography>
              <Grid className="box">
                <Link
                  className="link"
                  to="/What-Is-Functional-Training"
                  target="_blank"
                >
                  بیشتر بخوانید : فانکشنال ترینینگ و بررسی کامل آن؛ معرفی بهترین مربی خانم فانکشنال
                </Link>
              </Grid>
              <Typography className="h4" variant="h4">
                4-1 حرکت درخت
              </Typography>
              <figure className="image_wrapper">
                <Typography
                  component={"img"}
                  src={photo07}
                  alt="حرکت درخت در یوگا - هومینجا"
                  className="image"
                />
                <figcaption className="image_caption">
                  حرکت درخت در یوگا{" "}
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این حرکت باعث تقویت تعادل و بالا بردن قدرت میان تنه شما می شود. اگر جز افرادی هستید که فشار خون پایین دارند این حرکت برای شما مناسب نیست.
              </Typography>
              <Typography className="h4" variant="h4">
                4-2 حرکت مار کبری
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این حرکت باعث تقویت فیله کمر شما می شود اگر دچار اسیب های مربوط به ستون فقرات هستید این حرکت برای شما مناسب نمی باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                4-3 وضعیت کودک
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                این حرکت باعث می شود شما کشش خوبی را از گردن تا لگن خود احساس کنید. اگر باردار هستید و فشار خون بالایی دارید این حرکت را انجام ندهید.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                نکته: اگر باردار هستید و دچار دردهای مفصلی هستید و تعادل بدنتان ضعیف است، به هیچ عنوان ورزش یوگا را در خانه انجام ندهید.
              </Typography>
              <Typography className="h2" variant="h2">
                یوگا و کاهش وزن :
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                یوگا ورزشی است که می تواند اثرات خوبی در کاهش وزن داشته باشد، ولی برای افرادی که دچار اضافه وزن زیادی هستند به تنهایی این ورزش را توصیه نمی کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                <strong>پس ارتباط ورزش یوگا با لاغری چیست؟</strong>
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                انجام حرکات یوگا باعث می شود ذهن افراد ارامش بیشتری را تجربه کند. یکی از عوامل موثر در چاقی و پرخوری استرس است. یوگا استرس را در بدن کاهش می دهد و تمرکز را بیشتر می کند و فرد را به داشتن زندگی سالم تر تشویق می کند. در نتیجه افراد اگاهانه تر غذایی که می خواهند مصرف کنند را انتخاب می کنند و بیشتر سراغ مواد سالم برای تهیه غذا می روند. همانطور که اشاره کردیم یوگا کیفیت خواب را افزایش می دهد. بی خوابی نیز یکی از عوامل پرخوری و چاقی محسوب می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                سخن پایانی
              </Typography>
              <Typography className="p" sx={{ textAlign: "justify" }}>
                ما در این مقاله شما را با انواع ورزش های یوگا اشنا کردیم تا شما انتخاب راحتی داشته باشید. این را در نظر بگیرید اگر فردی مبتدی هستید برای شروع از <LinkMui className="link" href="https://www.instagram.com/p/CrnVaUFLe3M/?igshid=MzRlODBiNWFlZA==" target="_blank"> مربی </LinkMui> متخصص یوگا کمک بگیرید. جهت مشاهده بیشتر مطالب ورزشی و گرفتن برنامه ورزشی می توانید پیج <LinkMui className="link" href="https://instagram.com/nilla__fit?igshid=MzRlODBiNWFlZA==" target="_blank"> اینستاگرام </LinkMui> ما را دنبال کنید.
              </Typography>



              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid
                sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    color: "#4B4B4B",
                    padding: "5px",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                  }}
                >
                  برچسب ها :
                </Typography>
                <Button
                  component={Link}
                  to={"/HomeMag"}
                  onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{
                    color: "#4B4B4B",
                    bgcolor: "#E7F0F3",
                    margin: "5px",
                    fontFamily: "shabnam",
                    borderRadius: "5px",
                    fontSize: "14px",
                    "&:hover": { bgcolor: "#E7F0F3" },
                  }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography
                  sx={{
                    fontFamily: "shabnam",
                    color: "#C5CCCE",
                    textAlign: "center",
                  }}
                >
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui
                    href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <WhatsAppIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TwitterIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                  <LinkMui
                    href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments"
                    target="_blank"
                    sx={{
                      color: "#C5CCCE",
                      "&:hover": { color: "gray" },
                      transition: "0.3s all",
                    }}
                  >
                    <TelegramIcon
                      sx={{ fontSize: "50px", color: "inherit !important" }}
                    />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem("infoUserHomeInja") ? (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سپردن ملک
                      </Typography>
                      <AddBusinessRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      component={Link}
                      to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        سفارش ملک
                      </Typography>
                      <HouseRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("L")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ورود
                      </Typography>
                      <PersonRoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => this.openAuth("R")}
                      sx={{
                        border: "2px solid #0F92A9",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0",
                        width: "100%",
                        transition: "all 0.5s",
                        "&:hover": {
                          border: "2px solid white",
                          bgcolor: "#0F92A9",
                          "& p": { color: "white" },
                          "& svg": { color: "white !important" },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "shabnam",
                          fontWeight: "bold",
                          color: "#0F92A9",
                        }}
                      >
                        ثبت نام
                      </Typography>
                      <PersonAddAlt1RoundedIcon
                        sx={{ fontSize: "40px", color: "#0F92A9 !important" }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal
          open={this.state.openAuthModal}
          close={() => this.onCloseAuth()}
          type={this.state.modalType}
        />
      </Grid>
    );
  }
}

export default Article70;
