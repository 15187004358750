import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article06/01.jpg";
import photo02 from "../../images/mag/article06/02.jpg";
import photo03 from "../../images/mag/article06/03.jpg";
import photo04 from "../../images/mag/article06/04.jpg";
import photo05 from "../../images/mag/article06/05.jpg";
import photo06 from "../../images/mag/article06/06.jpg";
import photo07 from "../../images/mag/article06/07.jpg";
import photo08 from "../../images/mag/article06/08.jpg";
import photo09 from "../../images/mag/article06/09.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles";
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";



class Article06 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "سایبان پیش ساخت نسل جدیدی از سایبان ها می باشند. این نوع از سایبان ها در قسمت خارجی ساختمان وصل می شوند.";
    document.getElementsByTagName('meta')["keywords"].content = "انواع سایبان های پیش ساخت | مزایای سایبان پیش ساخت | کاربردهای سایبان پیش ساخت | سایبان پیش ساخته چیست | صفر تا صد سایبان پیش ساخته"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[5].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[5].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[5].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[5].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[5].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[5].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="سایبان پیش ساخت چیست؟ - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[5].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }} >
                سایبان پیش ساخت نسل جدیدی از سایبان ها می باشند. این نوع از سایبان ها در
                قسمت خارجی ساختمان وصل می شوند. سایبان های پیش ساخت علاوه بر اینکه نصب آسان و سریعی دارند از لحاظ اقتصادی نیز مقرون به صرفه هستند. اگر اسم
                سایبان پیش ساخت به گوشتان خورده و تمایل دارید در این مورد بیشتر بدانید با <Link className="link" to="/HomeMag" target="_blank"> بلاگ هومینجا </Link> همراه باشید تا اطلاعات جامعی از این موضوع بدست آورید.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایبان اولین بار در مصر باستان و سوریه استفاده شد. اولین نامی
                که برای سایبان در نظر گرفتند حصیرهای بافته بود. سایه بان ها در نیمه اول قرن 19 رواج یافتند. مردم از الوار و چدن سایبان درست میکردند. برای
                بستن سایبان در این دوره باید بصورت دستی اقدام می کردند. در سال های بعد از جنگ استفاده از سایبان همه گیر شد. در نیمه دوم قرن19، سایبان ها با
                ماشین های صنعتی ساخته شدند.این نوع از سایبان ها برای باز و بسته شدن نیاز به نیروی دست نداشتند. بلکه از یک بازو برای بستن آن استفاده می شد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/where-to-buy-sleep-product" target="_blank">
                  بیشتر بخوانید : کالای خواب از کجا بخریم ؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                سایبان چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در تعریف سایبان باید گفت  نوعی سقف است که ممکن است ثابت یا جمع شونده باشد. سایبان ها معمولا به قسمت خارجی دیوارها و <Link className="link" to="/building-facades-types" target="_blank">  نمای ساختمان </Link>  متصل می شوند. با استفاده از سایبان می توان از فضای مورد نظر در مقابل نور آفتاب یا بارش باران و برف محافظت کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                سایبان پیش ساخته
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="سایبان پیش ساخته چیست - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایبان پیش ساخته
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با وجود اینکه همه ما از هوای آزاد لذت می بریم، اما گاهی نور خورشید باعث آزارمان می شود. به همین دلیل به دنبال مکانی سایه دار هستیم. حتی امکان
                دارد گاهی در بیرون منزل در معرض بارش مستقیم باران قرارگرفته باشید. در این صورت برای جلوگیری از خیس شدن بدنبال یک سرپناه یا یک سایبان هستیم.
                <br />
                سایبان های پیش ساخت، امروزه با متریال های متفاوتی از جمله فلز، چوب و پارچه ساخته شده اند. با توجه به شرایط
                آب و هوایی هر منطقه از یک نوع خاص از سایبان های پیش ساخته استفاده می شود. بنابراین بهتر است که در این مورد از یک فرد متخصص و آشنا کمک بگیرید.
              </Typography>
              <Typography className="h2" variant="h2">
                کاربردهای سایبان های پیش ساخت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مثلا برای پارک نمودن ماشین خود بدنبال سایه می گردید. سایبان پیش ساخت
                همچنین برای درب و پنجره های ساختمان های مسکونی و تجاری نیز کاربرد دارند. همچنین سایبان حیاط که برای آلاچیق بکار می روند یا سایبان پیش ساخت که برای
                حفاظت از تاسیسات ساختمانی قرار می گیرند. سایبان استخر که به منظور ایجاد فضایی امن در مقابل تابش خورشید و بارش باران مورد استفاده قرار می گیرند.
              </Typography>
              <Typography className="h3" variant="h3">
                انواع سایبان های پیش ساخت:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایبان های پیش ساخت به دو دسته سایبان های ثابت و متحرک تقسیم می شوند:
              </Typography>
              <Typography className="h4" variant="h4">
                سایبان های ثابت
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="سایبان پیش ساخت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایبان ثابت
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مدل از سایبان ها بر روی یک قاب فلزی و چوبی نصب می شوند. سایبان های ثابت قابلیت جابجایی ندارند. این نوع از سایبان ها معمولا بالای درب و پنجره نصب می شوند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/keeping-apartment-flower" target="_blank">
                  بیشتر بخوانید : نکاتی در مورد نگهداری گیاهان اپارتمانی
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از مزایای این نوع سایبان می توان به موارد زیر اشاره کرد
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>دوام و استحکام بالا</li>
                <li>حفاظت در برابر نور خورشید</li>
              </ul>
              <Typography className="h4" variant="h4">
                سایبان های متحرک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایبان های متحرک را می توان بعد از نصب جابجا کرد. در بسیاری از مراکز خرید از این نوع سایبان استفاده می شود. ابعاد این سایبان ها به صورت طول
                3 متر و عرض 310 سانتی متر می باشد. سایبان های متحرک معمولا از جنس برزنتی می باشند. بسیار مقاوم هستند. نصب آن ها کمتر از دو دقیقه زمان می برد.
                در این مدل از سایبان ها می توان اندازه سایبان را با توجه به تابش خورشید کنترل کرد. از سایبان های پیش ساخت در درب ورودی نیز می توان استفاده کرد.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="سایه بان پیش ساخت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایبان متحرک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مدل از سایبان ها به دو دسته دستی و برقی تقسیم می شوند.
              </Typography>
              <Typography className="h2" variant="h2">
                مزایا و معایب استفاده از سایبان
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هر یک از انواع سایبان ها می توانند مزایا و معایبی را به همراه داشته باشند. از مهمترین مزایای این سایبان ها می توان به نصب راحت و هزینه کم آن اشاره کرد. این سایبان ها از قبل در سایزهای متنوع و استاندارد ساخته شده اند. همچنین این سایبان ها می توانند محافظ خوبی در برابر سرقت و دزدی باشند. این نوع سایبان قابلیت اتصال به <Link className="link" to="/smart-home" target="_blank"> خانه هوشمند </Link> را دارا هستند. همچنین از این مدل سایبان می توان به عنوان سایبان خودروی مهمان نیز استفاده کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                مزایای سایبان پیش ساخت
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>ایجاد فضایی مناسب جهت بازی کودکان</li>
                <li>ایجاد سایه ای خنک</li>
                <li>کاهش مصرف انرژی</li>
              </ul>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="سایبان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مزایای سایبان پیش ساخت
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اما معایبی که برای این مدل از سایبان ها می توان ذکر کرد این است که نگه داری بیشتری نسبت به سایبان های سنتی دارند
                . همچنین طول عمر آن ها کمتر است. اما امروزه با توجه به پیشرفت تکنولوژی این مورد نیز برطرف شده و سایبان های پیش ساخت دوام بیشتری پیدا کرده اند.
              </Typography>
              <Typography className="h2" variant="h2">
                کاربردهای سایبان پیش ساخت:
              </Typography>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>سایبان ماشین که بعنوان پارکینگ برای ساختمان های مسکونی و یا سازمان های غیر مسکونی مثل مدارس و بیمارستان ها مورد استفاده قرار می گیرد.</li>
                <li>سایبان های پیش ساخت درب و پنجره که به بارانگیر نیز معروف است.</li>
                <li>سایبان پیش ساخت حیاط که برای فضای سرپوشیده مانند آلاچیق در ویلاها بکار می رود.</li>
              </ul>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="سایبان آلاچیق - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاربردر سایبان پیش ساخت
                </figcaption>
              </figure>
              <ul className="p" sx={{ textAlign: 'justify' }}>
                <li>سایبان پیش ساخت برای محافظت از تاسیسات ساختمانی</li>
                <li>سایبان استخر که برای استخرهای روباز کارایی دارد.</li>
                <li>استفاده از سایبان پیش ساخته بعنوان المانی در معماری. این مدل از سایبان ها برای فرم دهی، دیزاین و نورپردازی بسیار مناسب هستند.</li>
              </ul>
              <Grid className="box">
                <Link className="link" to="/Midex" target="_blank">
                  بیشتر بخوانید : نمایشگاه میدکس
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مدل های مختلف سایبان پیش ساخت:
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایبان های پیش ساخت به هشت مدل مختلف دسته بندی می شوند.
              </Typography>
              <Typography className="h3" variant="h3">
                1- سایبان پیش ساخته برقی یا سایبان پارچه ای
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مدل از سایبان از یک موتور برقی بهمراه پوششی برزنتی تشکیل شده است. سایبان برقی  روی تراس جلوی مغازه
                ها و فروشگاه ها مورد استفاده قرار می گیرد. این مدل سایبان ها عمر متوسطی دارند. برای محل هایی مناسب هستند که در ساعات خاصی از روز نیاز به سایه دارند.
              </Typography>
              <Typography className="h3" variant="h3">
                2- سایبان پیش ساخت پنجره یا بارانگیر
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="انواع سایبان پیش ساخت - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایبان پیش ساخت پنجره
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بارانگیر یا همان سایبان پنجره سایبانی است که برای نصب
                بر روی پنجره ها و درب ها مناسب می باشد. این نوع سایبان بعنوان سایبان تراس نیز کارایی دارد. بارانگیرها سبک و مقاوم هستند و براحتی روی دیوار نصب می شوند.
              </Typography>
              <Typography className="h3" variant="h3">
                3- سایبان های پی وی سی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این سایبان ها برای وسایلی که از جنس پی وی سی هستند کارایی دارند. تانکر مخازن آب یکی از این وسایلی است که از سایه بان
                PVC استفاده می کند. این نوع سایبان ها کمترین فضا را اشغال می کنند
                . سایبان های پی وی سی بسیار
                در مقابل نور خورشید مقاوم هستند.
                هزینه آن بسیار کم و مقرون به صرفه می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                4- سایبان پارکینگ
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تابش مستقیم نور خورشید ممکن است منجر به رنگ پریدگی یا سوختگی روی بدنه خودرو  شود. حتی ممکن است به موتور خودرو نیز آسیب وارد شود. بنابراین می توان برای حفاظت از خودرو ها از سایبان پارکینگ استفاده کرد. این سایبان ها به گونه ای هستند که می توانند جلوی تابش مضر نور خورشید را بگیرند. نصب سایبان پارکینگ بسیار راحت است. جنس این سایه بان باید بسیار مرغوب باشد تا بتوانند جلوی نور خورشید و اشعه UV را بگیرند. پوشش سقف سایبان پارکینگ معمولا از جنس پلی کربنات، پی وی سی و ورق های گالوانیزه می باشد.
              </Typography>
              <Typography className="h3" variant="h3">
                5- سایبان چتری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="سایبان برقی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایبان پیش ساخت چتری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این مدل از سایبان ها سبک، راحت و بی دردسر هستند. سایبان های
                چتری بیشتر شبیه به یک چتر هستند و برای خنک کردن محوطه زیر خود بسیار کاربرد دارند. مرغوب ترین جنس مورد استفاده در سایبان های چتری برزنت می باشد.
                برزنت در برابر رطوبت بسیار مقاوم است و براحتی دچار پارگی نمی شود.سایان های چتری را می توان  به شکل ثابت بکار برد و هم بشکل متحرک. برای نوع ثابت
                با استفاده از مصالح مختلف روی زمین ثابت نگه داشته ی شود. نوع متحرک آن روی پایه محکم فولادی استوار است. تعمیر این نوع از سایبان ها بسیار آسان است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/accent-wall" target="_blank">
                  بیشتر بخوانید : اکسنت وال چیست؟
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                6- سایبان های آکاردئونی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این سایبان ها مانند آکاردئون باز و بسته
                می شوند . بیشتر در محیط های اداری و تجاری از این مدل سایبان ها استفاده می شود. استایلی رسمی و شیک دارند و خیلی مناسب فضای خانه و ویلا نیستند.
              </Typography>
              <Typography className="h3" variant="h3">
                7- سایبان استخری
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="سایه بان برقی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایبان استخری
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سایبان های استخری با ریموت کنترل باز و بسته می شوند. بهترین گزینه برای استخرهای لوکس می باشند. جنس این مدل از سایبان ها از کربنات یا آلومنیوم است.
              </Typography>
              <Typography className="h3" variant="h3">
                8- سایبان پلی کربنات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پلی کربات نوعی پلاسیک شفاف است که تا  دمای 140 درجه بسیار مقاوم است. پلی کربنات همچنین عایق الکتریکی خوبی به حساب می آید.
                سایبان های
                پلی کربنات نصب ساده و راحتی دارند. در برابر ضربه بسیار مقاوم هستند. در برابر گرما و سرما نیز مقاومت خوبی نشان می دهند. همچنین قابل شستشو هستند.
              </Typography>
              <Typography className="h3" variant="h3">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مطلب از هومینجا به تعریفی از سایبان های پیش ساخت و کاربردهای آن پرداختیم. همچنین انواع این سایبان ها را معرفی کردیم. چنانچه به دنبال
                سایبانی
                پیش ساخت برای محیط اداره یا منزل خود هستید با استفاده از این بانک اطلاعاتی از هومینجا می توانید بهترین سایبان را برای فضای مورد نظر خود تهیه کنید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/premade-canopy" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/premade-canopy" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/premade-canopy" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article06;