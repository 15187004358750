import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article65/01.jpg";
import photo02 from "../../images/mag/article65/02.jpg";
import photo03 from "../../images/mag/article65/03.jpg";
import photo04 from "../../images/mag/article65/04.jpg";
import photo05 from "../../images/mag/article65/05.jpg";
import photo06 from "../../images/mag/article65/06.jpg";
import photo07 from "../../images/mag/article65/07.jpg";
import photo08 from "../../images/mag/article65/08.jpg";
import photo09 from "../../images/mag/article65/09.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article65 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "امروزه روش های زیادی برای کاهش وزن وجود دارد که باعث آسیب رساندن به بدن می شود. ما در این مقاله کاهش وزن اصولی را مورد بررسی قرار می دهیم. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "کاهش وزن اصولی و بدون بازگشت | راه هایی برای رسیدن به تناسب اندام | استپ وزنی در کاهش وزن و راه کارهایی برای شکستن آن | بهترین راه برای کاهش وزن صحیح | راههای ساده برای کاهش وزن | نکات اولیه رسیدن به اندام مناسب "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[64].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[64].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "62px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[64].access} />
        <Grid container sx={{ maxWidth: "1620px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[64].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[64].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[64].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="چاقی و کاهش وزن - هومینجا  " className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[64].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                امروزه روش های لاغری زیادی وجود دارد که اکثر این روش ها بسیار عجیب غریب و خطرناک است. بیشتر افراد برای کاهش وزن به سراغ قرص های لاغری می روند ولی باید بدانند که با این کار سلامتی خود را شدیدا  به خطر می اندازند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با این حال روش های واقعی و موثری وجود دارد که می تواند شما را برای کاهش وزن تا رسیدن به وزن دلخواه یاری دهد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با ما همراه باشید تا در این <Link className="link" to="/Weight-Loss-and-Fitness" target="_blank"> مقاله </Link> در مورد روش صحیح کاهش وزن و راههای اصولی رسیدن به تناسب اندام با هم بررسی کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از شما انتظار داریم تا در انتهای مقاله بتوانید به سوالاتی نظیر گام های کاهش وزن چیست و روش های رسیدن به تناسب اندام کدامند را پاسخ دهید.
              </Typography>
              <Typography className="h2" variant="h2">
                کاهش وزن اصولی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کاهش وزن اصولی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاهش وزن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                متاسفانه افراد بسیاری که به دنبال کاهش وزن هستند، نتیجه مطلوبی را از این بابت نمی گیرند. توجه نکردن به یک سری نکات مهم و اصولی باعث می شود که شما به نتیجه دلخواه خود نرسید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی بر این داریم تا کلیدی ترین نکات برای کاهش وزن اصولی را بیان کنیم.
              </Typography>
              <Typography className="h3" variant="h3">
                اولین گام برای کاهش وزن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اولین گام در این راه برنامه ریزی دقیق و مناسب می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یک دفتر بردارید و علت کاهش وزن خود را یادداشت نمایید. سپس اهداف مورد نظر و راه رسیدن به این اهداف را مشخص کنید. نوشتن به شما انگیزه می دهد و شما را به اهدافتان نزدیک می کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/What-Is-Functional-Training" target="_blank">
                  بیشتر بخوانید :  فانکشنال ترینینگ و بررسی کامل آن؛ معرفی بهترین مربی خانم فانکشنال
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                دومین گام در کاهش وزن، رعایت رژیم غذایی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="رژیم غذایی مناسب - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رژیم غذایی مناسب
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مهمترین مسئله در کاهش وزن، رعایت رژیم غذایی می باشد. هنگامی که صحبت از رژیم غذایی می شود برخی از افراد بر این باورند که باید بسیاری از مواد غذایی را از لیست خود حذف کنند. ولی واقعیت امر اینگونه نیست. شما می توانید زیر نظر یک متخصص <LinkMui className="link" href="https://kermany.com" target="_blank"> تغذیه </LinkMui>، تعادل را به رژیم غذایی خود برگردانید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سعی کنید غذاهای سالم را جایگزین غذاهای ناسالم کنید. به عنوان مثال بهتر است <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%B1%D9%88%D8%BA%D9%86_%D8%B2%DB%8C%D8%AA%D9%88%D9%86" target="_blank">  روغن زیتون </LinkMui> جایگزین روغن های ناسالم شود. همچنین بهتر است از سبزیجات و میوه جات و پروتئین های بدون چربی استفاده نمایید.
              </Typography>
              <Typography className="h3" variant="h3">
                سومین گام در کاهش وزن، قراردادن بدن در نقصان کالری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قبل از اینکه به این موضوع بپردازیم بهتر است ابتدا توضیحی در مورد کالری و نقصان کالری بدهیم.
              </Typography>
              <Typography className="h4" variant="h4">
                کالری چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کالری واحدی از انرژی می باشد که شما به واسطه مصرف انرژی بدست می آورید.
              </Typography>
              <Typography className="h4" variant="h4">
                محاسبه میزان کالری مورد نیاز بدن در طول در روز
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اینکه کالری مورد نیاز شما در طول روز چقدر است کاملا به هدف و تحرک شما بستگی دارد. کالری دریافتی باید کاملا دقیق باشد. کالری نباید آنقدر زیاد باشد که بدن دچار اضافه وزن شود. همچنین اگر بدن کمتر از حد نیاز کالری دریافت کند، بدن برای سوخت و ساز عضلات را درگیر می کند.
              </Typography>
              <Typography className="h4" variant="h4">
                نقصان کالری چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر شما مقدار کالری که مصرف می کنید از مقدار فعالیتتان کمتر باشد، اینگونه بدن شما نقصان کالری پیدا می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر همین روند ادامه پیدا کند، وزن به مرور کاهش پیدا می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                چهارمین گام در کاهش وزن، ورزش و انتخاب مربی حرفه ای
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="ورزش مناسب کاهش وزن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ورزش مناسب کاهش وزن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اصلی ترین گام در کاهش وزن رعایت رژیم غذایی می باشد. اگر در کنار این موضوع ورزش را نیز در برنامه خود قرار دهید سریع تر به اهدافتان خواهید رسید. ورزش کردن همچنین باعث می شود تا بعد از کاهش وزن، پوست بدنتان شل نشود. در این راه شما باید سراغ <LinkMui className="link" href="https://instagram.com/nilla__fit?igshid=MzRlODBiNWFlZA==" target="_blank">  مربی </LinkMui> ورزشی متخصص لاغری بروید که نه تنها به شما وعده لاغری در یک ماه را ندهد بلکه از شما بخواهد در این راه صبر و استمرار زیادی داشته باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                راه هایی برای رسیدن به تناسب اندام
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همانطور که در بالا بیان شد، راه هایی اصولی و بدون بازگشت برای رسیدن به تناسب اندام وجود دارد.  ما در اینجا برخی از این نکات کلیدی جهت رسیدن به تناسب اندام را بررسی خواهیم کرد.
              </Typography>
              <Typography className="h3" variant="h3">
                1- مصرف غذای سالم
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در رژیم غذایی این نکته بسیار حائز اهمیت می باشد که از چه موادی برای تهیه رژیم باید استفاده کنیم.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از روش های کاهش وزن این است که برای تهیه غذا از محصولاتی استفاده کنید که ارزش غذایی زیادی داشته باشند مثل مرغ، حبوبات، سبزیجات و ... .
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                محصولات مرکب مثل ناگت، انواع کنسروها مقدار زیادی کالری پنهان دارند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کار باعث می شود شما احساس سیری داشته باشید و اشتهای شما تحریک نشود.
              </Typography>
              <Typography className="h2" variant="h2">
                2- استفاده از پروتئین
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مهمترین راه در کاهش وزن این است که از <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D9%BE%D8%B1%D9%88%D8%AA%D8%A6%DB%8C%D9%86" target="_blank">  پوتئین </LinkMui> کافی استفاده کنید. پروتئین به میزان لازم سبب می شود تا کمتر احساس گرسنگی کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                جای دادن پروتئین در وعده صبحانه مثل خوردن تخم مرغ آبپز و <LinkMui className="link" href="https://g.co/kgs/GvFNdm" target="_blank">  جو پرک </LinkMui> به کاهش وزن شما کمک می کند.
              </Typography>
              <Typography className="h3" variant="h3">
                3- میان وعده سالم
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="میان وعده سالم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  میان وعده سالم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است بجای اینکه یخچال خود را با خوراکی های ناسالم و تنقلات مانند شیرینی، چیپس و پفک پر کنید با میوه و سبزیجات و <LinkMui className="link" href="https://www.digikala.com/product/dkp-7445693/%DA%AF%D8%B1%D8%A7%D9%86%D9%88%D9%84%D8%A7-%D8%B1%DA%98%DB%8C%D9%85%DB%8C-%D9%86%D9%88%D9%86%D8%AF-380-%DA%AF%D8%B1%D9%85/" target="_blank">  گرانولا </LinkMui> جایگزین کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : معرفی بهترین کافه کتاب های تهران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                4- نوشید آب
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بدن ما گاهی حس تشنگی را با حس گرسنگی اشتباه می گیرد. پس هر زمان احساس گرسنگی به شما دست داد ابتدا آب میل کنید. شما باید روزانه 3 لیتر آب بنوشید. در هنگام تشنگی نباید نوشیدنی ها و ابمیوه های شکر دار را جایگزین آب کنید. بهتر است هنگامی که عطش دارید خاکشیر و تخم شربتی میل کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                5- انجام ورزش های هوازی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این ورزش ها برای چربی سوزی و کاهش وزن بسیار موثر هستند. هم چنین به سلامت کلی بدن و روان کمک بسزایی می کند. یکی از راههای جلوگیری از پیری زودرس و افسردگی انجام انواع ورزش های هوازی در طبیعت می باشد.
              </Typography>
              <Typography className="h4" variant="h4">
                ورزش های هوازی چیست؟
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ورزش های هوازی مانند دویدن، دوچرخه سواری، شنا و <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%B2%D9%88%D9%85%D8%A8%D8%A7" target="_blank">  زومبا </LinkMui> به فعالیت های شدیدی می گویند که ضربان قلب را افزایش دهد.
              </Typography>
              <Typography className="h3" variant="h3">
                6- شکر مخفی در غذاها
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد دارید مصرف شکر مخفی را به حداقل برسانید، باید خوردن برخی از خواکی ها را از سبد غذایی خود حذف کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کیک های آماده و ماست های پرچرب سرشار از شکر هستند. هچنین سس های پرچرب و فست فودها مقدار قابل توجهی شکر دارند.
              </Typography>
              <Typography className="h3" variant="h3">
                7- استفاده نکردن از غذاهای آماده
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                غذاهای آماده نیز سرشار از شکر می باشند. بهتر است از غذاهای اماده و کنسروها تا حد ممکن استفاده نکنید. غذاهای خانگی بهترین جایگزین برای غذاهای کنسروی می باشند. بعنوان مثال به جای تن ماهی از ماهی تازه استفاده نمایید.
              </Typography>
              <Typography className="h3" variant="h3">
                8- مصرف قهوه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="مصرف قهوه و کاهش وزن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تاثیر قهوه در کاهش وزن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قهوه از نوشیدنی هایی است که سرشار از <LinkMui className="link" href="https://kalleh.com/book/article/%D8%A2%D9%86%D8%AA%DB%8C-%D8%A7%DA%A9%D8%B3%DB%8C%D8%AF%D8%A7%D9%86-%DA%86%DB%8C%D8%B3%D8%AA" target="_blank"> آنتی اکسیدان </LinkMui> می باشد. با مصرف یک فنجان قهوه قبل از تمرین انرژی شما افزایش می یابد و بهتر تمرین خواهید کرد. بهتر است برای رفع تلخی قهوه همراه ان یک لیوان آب بنوشید تا سراغ شیرینی جات نروید.
              </Typography>
              <Typography className="h3" variant="h3">
                9- فیبر کافی بخورید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                فیبر نوع خاصی از کربوهیدرات می باشد که در غذاهای گیاهی مانند سبزیجات و غلات به وفور یافت می شود . مصرف فیبر باعث می شود شما دیرتر احساس گرسنگی کنید.
              </Typography>
              <Typography className="h3" variant="h3">
                10- خواب کافی داشته باشید
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تحقیقات نشان می دهد اکثر افرادی که شب ها تا دیر وقت بیدار هستند و خواب کافی ندارند دچار اضافه وزن می شوند.
              </Typography>
              <Typography className="h3" variant="h3">
                11- استفاده از پروبیوتیک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پروبیوتیک نوعی باکتری و مخمر می باشد که به گوارش و هضم غذا در معده کمک می کند. استفاده از پروبیوتیک در رژیم غذایی مانع از  جذب برخی چربی ها در بدن می شود. برای جذب پروبیوتیک در بدن می توانید از <LinkMui className="link" href="https://kalleh.com/book/article/%D9%85%D8%A7%D8%B3%D8%AA-%D9%BE%D8%B1%D9%88%D8%A8%DB%8C%D9%88%D8%AA%DB%8C%DA%A9-%DA%86%DB%8C%D8%B3%D8%AA/#:~:text=%D9%85%D8%A7%D8%B3%D8%AA%20%D9%BE%D8%B1%D9%88%D8%A8%DB%8C%D9%88%D8%AA%DB%8C%DA%A9%20%D8%AD%D8%A7%D9%88%DB%8C%20%D9%85%D9%88%D8%A7%D8%AF%DB%8C%20%D9%87%D9%85%DA%86%D9%88%D9%86,%D8%AA%D9%82%D9%88%DB%8C%D8%AA%20%D8%B3%DB%8C%D8%B3%D8%AA%D9%85%20%D8%A7%DB%8C%D9%85%D9%86%DB%8C%20%D9%87%D9%85%20%D9%85%D9%88%D8%AB%D8%B1%D9%86%D8%AF." target="_blank">  ماست </LinkMui> های پروبیوتیک استفاده نمایید.
              </Typography>
              <Typography className="h3" variant="h3">
                12- انجام ورزش فانکشنال
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="تمرینات فانکشنال - هومینجا" className="image" />
                <figcaption className="image_caption">
                  تمرینات فانکشنال
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به تمریناتی که شما با وزن بدن خود، حرکات را انجام می دهید، ورزش فانکشنال می گویند. ورزش فانکشنال علاوه بر این که به شما برای رسیدن به تناسب اندام کمک می کند، قدرت عضلات شما را نیز بالا می برد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای اطلاعات بیشتر از <Link className="link" to="/What-Is-Functional-Training" target="_blank">  ورزش فانکشنال </Link> لطفا به مقاله فانکشنال مراجعه نمایید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : معرفی بهترین و جذاب ترین کافه های شمال تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                تاثیر کاهش وزن در درمان pcos ( تخمدان پلی کیستیک )
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چاقی مفرط تاثیر مخربی بر بدن دارد. ممکن است چاقی برای خانم ها مشکلاتی در تولید مثل و <LinkMui className="link" href="https://fa.wikipedia.org/wiki/%D8%B3%D9%86%D8%AF%D8%B1%D9%85_%D8%AA%D8%AE%D9%85%D8%AF%D8%A7%D9%86_%D9%BE%D9%84%DB%8C%E2%80%8C%DA%A9%DB%8C%D8%B3%D8%AA%DB%8C%DA%A9" target="_blank">  سندروم </LinkMui> pcos را بوجود آورد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این گروه از افراد باید رژیم غذایی خود را از فیبر زیاد، میوه و سبزیجات قرار دهند. همچنین از مصرف قند پرهیز کنند.
              </Typography>
              <Typography className="h2" variant="h2">
                فواید کاهش وزن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چاقی مشکلات زیادی را برای افرادی به وجود می آورد، از جمله بیمارسی های جسمی که به دنبال دارد می توان به دیابت، فشار خون بالا، کبد پرب و بیماری های دیگر اشاره کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین از نظر روحی به فردی افسرده و بدون اعتماد به نفس تبدیل خواهید شد. کاهش وزن برای شما فواید بسیاری به همراه دارد. باعث می شود شما به زندگی دید بهتری داشته باشید و از بسیاری بیماری ها خلاص شوید و اعتماد به نفس پیدا کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در اینجا ما به بررسی فواید کاهش وزن می پردازیم:
              </Typography>
              <Typography className="h3" variant="h3">
                1- تنظیم قند خون
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افرادی که اضافه وزن دارند عملکرد انسولین در بدنشان دچار اختلال می شود. کاهش وزن کمک می کند تا عملکرد <LinkMui className="link" href="https://clinicghodad.com/blog/%D8%AF%DB%8C%D8%A7%D8%A8%D8%AA/%D8%A7%D9%86%D8%B3%D9%88%D9%84%DB%8C%D9%86-%DA%86%DB%8C%D8%B3%D8%AA/" target="_blank">  انسولین </LinkMui> در بدن بهبود یابد.
              </Typography>
              <Typography className="h3" variant="h3">
                2- کاهش خطر سکته مغزی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="کاهش سکته مغزی با کاهش وزن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاهش سکته مغزی با کاهش وزن
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همانطور که گفته شد چاقی باعث افزایش فشار خون در بدن می شود در نتیجه احتمال ایجاد سکته مغزی در این دسته از افراد بالا می رود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاهش وزن به شما کمک می کند تا رگ ها انقباض کمتری داشته باشند.
              </Typography>
              <Typography className="h3" variant="h3">
                3- اعتماد به نفس بالا
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                افرادی که دچار اضافه وزن هستند وقتی تصمیم به کاهش وزن می گیرند بعد از رسیدن به هدفشان اعتماد به نفس زیادی را هم از اراده ای که داشتند و هم از ظاهر جدیدشان بدست می آورند.
              </Typography>
              <Typography className="h3" variant="h3">
                4- بدنی سالم تر با تحرک بیشتر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کاهش وزن باعث می شود تا مفاصل و زانوهای شما فشار کمتری را نسبت به  قبل تحمل کنند. در نتیجه این امر تحرک شما بیشتر و اسان تر می شود.
              </Typography>
              <Typography className="h4" variant="h4">
                استپ وزنی در کاهش وزن
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر بعد از اخرین کاهش وزنی که داشتید یک ماه بگذرد و همچنان وزنتان ثابت مانده باشد اولین کاری که باید انجام دهید این است که سایزتان را هم اندازه گیری کنید. اگر سایزتان تغییر نکرده بود شما دچار استپ وزنی شده اید ولی اگر تغییر کرده بود شما دچار <LinkMui className="link" href="https://fitclub.ir/blog/%D8%AF%D9%84%D8%A7%DB%8C%D9%84-%D8%A7%D8%B3%D8%AA%D9%BE-%D9%88%D8%B2%D9%86%DB%8C/#:~:text=%D8%A7%D8%B3%D8%AA%D9%BE%20%D9%88%D8%B2%D9%86%DB%8C%20%D8%A8%D9%87%20%D8%A7%DB%8C%D9%86%20%D9%85%D8%B9%D9%86%D8%A7,%D8%AA%D9%88%D9%82%D9%81%20%DA%A9%D8%A7%D9%87%D8%B4%20%D9%88%D8%B2%D9%86%20%D9%85%D9%88%D8%AB%D8%B1%20%D9%87%D8%B3%D8%AA%D9%86%D8%AF." target="_blank"> استپ وزنی </LinkMui> نشده اید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چیزی که باید راجع به استپ وزن باید بدانید این است که در همه افراد اتفاق می افتد. دلیل این امر را می توان اینگونه گفت که بدن هوشمندانه عمل می کند. یعنی وقتی که متوجه می شود که غذای کمتری وارد بدن شده است احساس قحطی می کند. بنابراین برای حفظ جان ما سوخت و ساز بدن را به حداقل می رساند تا چربی کمتری از دست دهد.
              </Typography>
              <Typography className="h2" variant="h2">
                راه حل هایی برای جلوگیری از استپ وزنی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="استپ وزنی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  استپ وزنی
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خوردن یک وعده غذای پر پروتئین
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                داشتن یک وعده بوفه باز ( بدون هیچ محدودیتی هر غذایی دوست دارید میل نمایید )
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                داشتن روزانه 45 دقیقه پیاده روی تند بطوری که خسته شوید
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نوشیدن آب کافی ( نوشیدن نیم لیتر آب سوخت و ساز بدن را افزایش می دهد )
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                داشتن روزانه 7 تا 8 ساعت خواب کافی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نداشتن استرس جهت کاهش وزن ( استرس باعث ترشح هورمون کورتیزول در بدن می شود که این هورمون سبب استپ وزنی می شود )
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گرفتن رژیم شوک زیر نظر متخصص رژیم و تغذیه ( یکی از بهترین رژیم ها برای جلوگیری از استپ وزنی رژیم فستینگ است )
              </Typography>
              <Typography className="h4" variant="h4">
                هورمون گرلین، دشمن لاغری
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وقتی معده شما خالی باشد هورمون گرلین در بدن تولید می شود. ترشح این هورمون اشتهای شما را افزایش می دهد. همچنین باعث می شود که مغز فرمان به خوردن غذا دهد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : معرفی بهترین رستوران های بالا شهر
                </Link>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تولید هورمون گرلین تقریبا هر 3 ساعت یک بار در بدن اتفاق می افتد و باعث بزرگ شدن معده هم می شود تا فضای کافی برای هضم غذا فراهم شود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای جلوگیری از افزایش هورمون گرلین باید یک سری نکات را رعایت کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                1- وعده های غذایی را حذف نکنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                2- شیرینی و غذاهای پرشکر را از برنامه غذایی حذف کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                3- خواب کافی داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                4- ویدئوهایی که باعث تحریک اشتهای شما می شود را نبینید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                5- غذاهایی که پروتئین و فیبر کافی دارد را در سبد غذایی خود بگنجانید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                6- چای سبز بنوشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                7- هر سه ساعت یک بار غذای سبک و کم حجم بخورید.
              </Typography>
              <Typography className="h2" variant="h2">
                معرفی مربی متخصص لاغری و تناسب اندام
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همان طور که در بالا بیان شد نیاز به مربی متخصص لاغری برای رسیدن به تناسب اندام الزامی می باشد. دلیل این امر جلوگیری از حرکات اشتباهکه منجر به آسیب بدنی می شود. یک مربی خوش اندام علاوه بر افزایش انگیزه شما برای لاغری، به انجام هرچه صحیح تر حرکات ورزشی کمک شایانی می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با دنبال کردن پیچ اینستاگرام نیلافیت <LinkMui className="link" href="https://www.instagram.com/p/CrnVaUFLe3M/?igshid=MzRlODBiNWFlZA==" target="_blank">  nilla__fit  </LinkMui>  و ارتباط با مربی تناسب اندام 09335441075 ( خانم تاجی) لاغری خود را تضمین کنید.
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اگر قصد کاهش وزن دارید گول تبلیغات تلویزیونی و قرص های لاغری و مربیان سودجو که وعده ی یک ماه لاغر شدن را به شما می دهند نخورید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کار شدنی نیست و فقط سلامت شما را به خطر می اندازد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این راه تنها صبر و حوصله و استمرار خودتان کافی نیست. داشتن یک مربی حرفه ای در این راه کمک شایانی به شما می کند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این زمینه می توانیم به شما کمک کنیم تا جدا از اینکه این کار برای شما دشوار نباشد، شما بتوانید نهایت لذت را نیز در راه رسیدن به هدفتان ببرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                وب گستران <LinkMui className="link" href="https://sorinapp.com" target="_blank">  سورین </LinkMui> دوستدار و حامی ورزشکاران کشور عزیزمان می باشد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای ارسال مقالات خود جهت انتشار با نام شما در وب سایت <Link className="link" to="/" target="_blank">  هومینجا </Link> از طریق چت آنلاین با ما در ارتباط باشید.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article65;