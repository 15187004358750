import React from 'react';
import { Grid, Snackbar, Typography } from '@mui/material';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { convertToPersianNumber } from './vmp';

export default function AlertModal (props){

    const action = (
        <Grid sx={{minWidth:{xs:"350px",sm:"400px"},bgcolor:"#fff",borderRadius:"8px",boxShadow:"0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                    padding:"20px 10px",border:props.type === 1 ? "2px solid #4caf50" : props.type === 2 ? "2px solid #d32f2f" : "2px solid #14C9E8"}}
        >
            <Grid sx={{textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center"}}>
                {   props.type === 1 ?
                        <TaskAltRoundedIcon sx={{color:"#2e7d32 !important",fontSize:"30px"}}/>
                    :   props.type === 2 ?
                            <WarningAmberRoundedIcon sx={{color:"#d32f2f !important",fontSize:"30px"}}/>
                        :   <ErrorOutlineRoundedIcon sx={{color:"#014361 !important",fontSize:"30px"}}/>
                }
                
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",marginRight:"5px",color:props.type === 1 ? "#2e7d32" : props.type === 2 ? "#d32f2f" : "#014361",fontSize:"20px"}}>
                    هومینجا
                </Typography>
            </Grid>
            <Grid sx={{textAlign:"center",marginTop:"5px"}}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",color:props.type === 1 ? "#2e7d32" : props.type === 2 ? "#d32f2f" : "#014361",fontSize:"20px"}}>
                    {convertToPersianNumber(props.message)}
                </Typography>
            </Grid>
        </Grid>
    )
    return(
        
        <Snackbar
            open={props.open}
            autoHideDuration={6000}
            onClose={props.close}
            anchorOrigin={{ vertical:"top", horizontal:"center" }}
            sx={{ height: 300 ,"& .MuiPaper-root":{bgcolor:"transparent",boxShadow:"none",display:"flex",justifyContent:"center",padding:"0"}}}
            message = {action}
        >
        </Snackbar>
    )
}


