import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article62/01.jpg";
import photo02 from "../../images/mag/article62/02.jpg";
import photo03 from "../../images/mag/article62/03.jpg";
import photo04 from "../../images/mag/article62/04.jpg";
import photo05 from "../../images/mag/article62/05.jpg";
import photo06 from "../../images/mag/article62/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article62 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "ما در این مقاله از هومینجا به بررسی کامل منطقه زعفرانیه تهران می پردازیم تا با این منطقه اعیان نشین و گران قیمت تهران آشنا شوید. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "محله زعفرانیه تهران | بهترین خیابان زعفرانیه تهران | رهن و اجاره در زعفرانیه تهران | قیمت خانه در زعفرانیه تهران | زعفرانیه محله اعیان نشین | بررسی محله زعفرانیه "
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[61].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[61].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "61px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[61].access} />
        <Grid container sx={{ maxWidth: "1610px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[61].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[61].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[61].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="زعفرانیه - هومینجا  " className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[61].desc}
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زعفرانیه یکی از مناطق اعیان نشین تهران می باشد. این محله دارای بافتی مدرن و سنتی می باشد. ما در این مقاله از هومینجا به بررسی کامل این منطقه، امکانات آن و شرایط زندگی، قیمت خانه و دسترسی و موقعیت آن می پردازیم. با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                محله زعفرانیه تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زعفرانیه در شمال شرقی تهران قرار دارد و از محله های ناحیه 2 و <Link className="link" to="/tehran-zone-1" target="_blank">  منطقه یک </Link> شهرداری تهران به شمار می آید. این محله را از مرتفع ترین محله های پایتخت می توان دانست که در کوهپایه البرز واقع است. زعفرانیه در ارتفاع 1650 تا 1800 متر بالاتر از سطح دریا قرار دارد و به همین دلیل دارای آب و هوای بسیار مطلوب و کوهستانی می باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                تاریخچه زعفرانیه تهران
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="زعفرانیه قدیم - هومینجا" className="image" />
                <figcaption className="image_caption">
                  زعفرانیه قدیم
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این خیابان در دهه ۱۳۴۰ هجری شمسی به عنوان منطقه مسکونی تهران شکل گرفت. پیشینه محله زعفرانیه به روستای اسدآباد که بین دره‌های <Link className="link" to="/Listings/buy_tehran_darband" target="_blank">  دربند </Link> و فرحزاد قرار داشت برمی گردد. زعفرانیه در گذشته به شکل روستایی در شمال غربی تجریش بود. بخش اعظمی از زمین های روستا تعلق به فردی به نام حسین علی خان موید الممالک داشت. اکثر این زمین ها به صورت زراعی بود و تا نزدیکی باغ فردوس ادامه داشت. بعد از احداث خیابان پهلوی یا ولیعصر فعلی در زمان رضا خان ، جاده‌ای برای دسترسی به اسدآباد ساخته شد که از باغ فردوس تا شمال سعد‌آباد منتهی می شد. محله زعفرانیه فعلی نیز حوالی این خیابان پدید آمد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Farmanieh-Neighborhood" target="_blank">
                  بیشتر بخوانید : معرفی کامل محله فرمانیه همراه با قیمت آپارتمان و امکانات
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                علت نامگذاری محله زعفرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در ابتدا این منطقه به دلیل اداره برقی که در آن قرار داشت برق بامداد نام داشت و بعدها به نام زعفرانیه تغییر کرد . زعفرانیه یکی از نام های زمان قاجار بود. عده ای معتقدند این نام از رنگ کاخ سعدآباد که به رنگ زعفرانی است گرفته شده است. زیرا افرادی که قصد رفتن به این خیابان را داشتند نشانی در زعفرانی کاخ را می دادند. البته فرضیه دیگری که در این مورد وجود دارد این است که اراضی زعفرانیه توسط محمد شاه قاجار به یکی از کنیزانش به نام زعفران باجی بخشیده شده است و نام زعفرانیه از این موضوع منشا می گیرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی دیگر از دلایل نامگذاری این خیابان را می توان کشت پنجاه من پیاز زعفران در اواخر حکومت محمدرضا شاه دانست. کشت زعفران در نزدیکی این محل صورت گرفت و از آن به بعد این خیابان با نام زعفرانیه شناخته شد.
              </Typography>
              <Typography className="h2" variant="h2">
                موقعیت زعفرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زعفرانیه از شمال با ارتفاعات <Link className="link" to="/tehran-winter-entertaiments" target="_blank">  توچال </Link>، از جنوب با خیابان ولیعصر و <Link className="link" to="/Listings/buy_tehran_mahmudieh" target="_blank">  محمودیه </Link> و باغ فردوس، از شرق با تجریش و دربند و از غرب با <Link className="link" to="/Listings/buy_tehran_velenjak" target="_blank">  ولنجک </Link> همجوار است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این محله زیر تله کابین توچال قرار دارد بنابراین احتمال رانش در این منطقه زیاد است و همچنین چون روی گسل شمال تهران است بنابراین با وقوع زلزله دچار خسارت زیان باری می شود.
              </Typography>
              <Typography className="h2" variant="h2">
                دسترسی به زعفرانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="دسترسی به زعفرانیه تهران - هومینجا" className="image" />
                <figcaption className="image_caption">
                  دسترسی به زعفرانیه
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زعفرانیه دارای ایستگاه تاکسی و اتوبوس و مترو اختصاصی نیست ولی برای دسترسی به این محله می توان از وسایل حمل و نقل محله های مجاور استفاده کرد.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای دسترسی به زعفرانیه با مترو، نزدیک ترین ایستگاه؛ متروی تجریش می باشد و سپس بوسیله تاکسی های خطی یا BRT می توان به زعفرانیه رفت. همچنین به دلیل اینکه این محله در محدوده‌ طرح ترافیک و زوج و فرد قرار ندارد برای دسترسی به زعفرانیه می توانید از خودروی شخصی استفاده کنید. از بزرگراه چمران و خیابان های ولیعصر و شریعتی می توانید به این منطقه تردد داشته باشید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Andarzgoo-blvd" target="_blank">
                  بیشتر بخوانید : با امکانات و برج های لوکس اندرزگو تهران آشنا شوید
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                زندگی در زعفرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                منطقه زعفرانیه یکی از مناطق شمالی و معروف تهران می باشد. زعفرانیه یکی از محله های اعیان نشین و گران قیمت تهران است که دارای بافتی سنتی و مدرن می باشد. ساکنین این محله از افراد مرفه و با اصالت تهران می باشند. این منطقه بدلیل طبیعت زیبا و فضای سبز فراوان، امکانات تفریحی و ورزشی و مراکز خرید یکی از محله‌های محبوب تهران به شمار می رود.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زندگی در زعفرانیه برای افرادی که عاشق محیط سرسبز هستند بسیار مناسب است. زعفرانیه دارای امکانات ورزشی و باشگاه‌های بزرگی است که برای علاقه مندان به ورزش گزینه خوبی می باشد. همچنین مراکز خرید فراوان و مجتمع‌های تجاری در این منطقه به ساکنان این امکان را می دهد که کالاهای مورد نیاز خود را به راحتی تهیه کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اما باید در نظر داشت که هزینه زندگی در زعفرانیه بسیار بالاست و برای زندگی در این منطقه باید نسبت به دیگر مناطق <Link className="link" to="/tehran-zones" target="_blank">  تهران </Link> هزینه‌ بیشتری پرداخت کرد.
              </Typography>
              <Typography className="h2" variant="h2">
                اماکن مهم زعفرانیه تهران
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت به معرفی اجمالی  مکان های مهم در محله زعفرانیه خواهیم پرداخت.
              </Typography>
              <Typography className="h3" variant="h3">
                اماکن دولتی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="سفارت بلاروس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سفارت بلاروس
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فرمانداری شهرستان شمیرانات
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دادگاه ویژه روحانیت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                صندوق مهر امام رضا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارتخانه آفریقای جنوبی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارتخانه برزیل
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارتخانه ویتنام
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارتخانه غنا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارتخانه بلاروس
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت ژاپن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سفارت سوریه
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">
                  بیشتر بخوانید : با محله اعیان نشین الهیه بیشتر آشنا شوید
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مراکز درمانی زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خانه سلامت زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                درمانگاه خیریه عمل
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                درمانگاه اسد آباد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مرکز اورژانس اجتماعی
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز خرید زعفرانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="پلازا زعفرانیه - هومینجا" className="image" />
                <figcaption className="image_caption">
                  پلازا زعفرانیه
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://palladiummall.com" target="_blank">  پالادیوم </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بامیک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پلازا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="http://www.paramispark.com/projects/paramis-zaferaniyeh" target="_blank">  پارمیس </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گالریا
              </Typography>
              <Typography className="h3" variant="h3">
                بوستان های محله زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان ولنجک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان ریحان (<Link className="link" to="/Listings/rent_tehran_asef" target="_blank">  آصف </Link>)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان ارغوان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                بوستان تندرستی(ساسان)
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز علمی و آموزشی زعفرانیه تهران
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدرسه متوسطه متوسطه دخترانه غیردولتی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دبستان سخت کوشان پسرانه غیردولتی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدرسه دولتی متوسطه دوره دوم دخترانه سمیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دبستان دخترانه غیر دولتی آفتاب آذرین دختران
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دبستان پسرانه شهید فرهنگیان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                راهنمایی پسرانه هجرت دولتی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دبیرستان و راهنمایی پسرانه فارابی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دانشگاه آزاد اسلامی واحد علوم دریایی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مجتمع آموزشی شهید مهدوی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دانشکده علوم و فنون دریایی دانشگاه آزاد واحد تهران شمال
              </Typography>
              <Grid className="box">
                <Link className="link" to="/niavaran-tehran" target="_blank">
                  بیشتر بخوانید : معرفی کامل محله مرفه نشین نیاوران
                </Link>
              </Grid>
              <Typography className="h3" variant="h3">
                مراکز ورزشی محله زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن ورزشی بقیه الله
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن ورزشی <LinkMui className="link" href="https://www.gymcenter.ir/%D8%A8%D8%A7%D8%B4%DA%AF%D8%A7%D9%87-%D9%87%D8%A7/gym241288/%D9%85%D8%AC%D9%85%D9%88%D8%B9%D9%87-%D9%88%D8%B1%D8%B2%D8%B4%DB%8C-%DA%A9%D8%A7%D9%BE-%D8%B2%D8%B9%D9%81%D8%B1%D8%A7%D9%86%DB%8C%D9%87" target="_blank">  کاپ </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن ورزشی تاج بانو
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                سالن بدن سازی یونیک
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                استخر و سونا زعفرانیه
              </Typography>
              <Typography className="h3" variant="h3">
                اماکن تاریخی زعفرانیه
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کاخ سعدآباد - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کاخ سعدآباد
                </figcaption>
              </figure>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاخ موزه سعدآباد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاخ موزه ملت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                موزه میرعماد
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کاخ شهرام (موزه نظامی)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                موزه هنرهای زیبا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                خانه ‌ی تاریخی استاد مهدیان
              </Typography>
              <Typography className="h3" variant="h3">
                مراکز فرهنگی زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                رصد خانه ی زعفرانیه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                عمارت تماشاگه زمان
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://bmn.ir" target="_blank"> بنیاد ملی نخبگان </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                فرهنگستان هنر
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کانون پرورش فکری کودکان و نوجوان
              </Typography>
              <Typography className="h2" variant="h2">
                بهترین خیابان های زعفرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                زعفرانیه تهران نیز مانند دیگر محله های تهران دارای خیابان های مهم  اصلی می باشد. از بهترین خیابان های زعفرانیه می توان آصف، <Link className="link" to="/Listings/buy_tehran_makuepur" target="_blank">  ماکویی پور </Link>، مهر، زاله زر و <Link className="link" to="/Listings/rent_tehran_moghadasardabili" target="_blank">  مقدس اردبیلی </Link> را نام برد. همچنین میدان الف از میدان های قدیمی این محله می باشد و جزء بهترین میدان های زعفرانیه می باشد. خیابان امیر سوری گران قیمت ترین ملک های زعفرانیه را در خود جا داده است. خیابان پسیان زعفرانیه را نیز با اصالت ترین خیابان زعفرانیه می توان دانست.
              </Typography>
              <Typography className="h2" variant="h2">
                قیمت خانه در زعفرانیه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خیابان زعفرانیه تهران به دلیل امکانات بی نظیری که دارد از مناطق لاکچری تهران محسوب می شود. ساختمان های این محله توسط معماران و طراحان سرشناس و بنامی ساخته شده است. حتی ساختمان های قدیمی زعفرانیه نیز در دسته زیباترین ساختمان های تهران قرار دارد. متراژ اکثر خانه های این منطقه زیر 300 متر است. قیمت خانه در زعفرانیه بسیار بالاست. خیابان امیر سوری گرانترین ملک های این منطقه را دارد. بنابراین چنانچه قصد خرید یا رهن و اجاره در این منطقه را دارید باید بودجه زیادی را بدین منظور کنار بگذارید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-District-One-Luxurious-Towers" target="_blank">
                  بیشتر بخوانید : با لوکس ترین برج های منطقه یک تهران بیشتر آشنا شوید
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مقاله از هومینجا سعی کردیم تا به بررسی جامع منطقه زعفرانیه تهران بپردازیم تا اگر قصد سکونت در این منطقه را دارید آشنایی کاملی از این منطقه بدست آورید. امید است این مطالب مفید واقع شود.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran_zaaferanieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید آپارتمان در زعفرانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran_zaaferanieh'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان زعفرانیه
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("محله گردی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  محله گردی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article62;