import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article50/01.jpg";
import photo02 from "../../images/mag/article50/02.jpg";
import photo03 from "../../images/mag/article50/03.jpg";
import photo04 from "../../images/mag/article50/04.jpg";
import photo05 from "../../images/mag/article50/05.jpg";
import photo06 from "../../images/mag/article50/06.jpg";
import photo07 from "../../images/mag/article50/07.jpg";
import photo08 from "../../images/mag/article50/08.jpg";
import photo09 from "../../images/mag/article50/09.jpg";
import photo10 from "../../images/mag/article50/10.jpg";
import photo11 from "../../images/mag/article50/11.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article50 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "در این مقاله از هومینجا برآنیم تا شما را با بهترین کافه و رستوران هایی که از افطار تا سحر آماده پذیرایی از روزه داران هستند آشنا کنیم.| هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "شبگردی از افطار تا سحر | رستوران هایی که تا سحر باز هستند | کافه مناسب ماه رمضان | کافه برای سحری | کافه برای افطاری | بهترین کافه برای افطار تا سحر | کافه شبانه روزی | بهترین رستوران برای افطار تا سحر | رستوران های باز در ماه رمضان"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }


  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[49].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[49].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[49].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[49].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[49].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[49].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="بهترین کافه افطار تا سحر - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[49].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با فرا رسیدن ماه رمضان تمامی کافه ها و رستوران ها در کشورهای اسلامی تعطیل می باشند. بنابراین بسیاری از رستوران ها و کافه های <Link className="link" to="/tehran-zones" target="_blank">  مناطق تهران </Link> تایم کاری خود را به افطار تا سحر تغییر می دهند. خیلی از افراد نیز تمایل دارند کافه هایی را برای افطاری انتخاب نمایند که بتوانند تا سحر در آنجا بمانند. ما در این مقاله از <LinkMui className="link" href="https://homeinja.info" target="_blank">  هومینجا </LinkMui> بر آنیم تا بهترین کافه های از افطار تا سحر را به شما معرفی کنیم. پس با ما همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                1. کافه آرتوس
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="کافه رستوران آرتوس - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه آرتوس
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                آرتوس در ماه رمضان در کنار غذاهای ایتالیایی منوی ویژه ای هم برای ماه رمضان تدارک دیده است. در این رستوران شما می توانید تا سحر با بازی مافیا، بازی بردگیم و موسیقی زنده شبی خاطره انگیز را برای خودتان رقم بزنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: کیلومتر ۳ جاده فشم، قبل از هتل آرینا
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/artoos_lounge" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Grid className="box">
                <Link className="link" to="/best-restaurant-tehran-region1" target="_blank">
                  بیشتر بخوانید : بهترین رستوران های منطقه ۱ تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۲. کافه رستوران هرموسو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="کافه هرموسو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه هرموسو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                هرموسو از دیگر کافه هایی است که می توان در ماه رمضان افطار تا سحر را در آن سپری کرد. این کافه در شمال تهران و در محله <Link className="link" to="/Elahiyeh-Neighborhood-Fereshteh-Street" target="_blank">  الهیه </Link> واقع است و دارای منوی کاملی از انواع غذاهای ایرانی و بین المللی و همچنین نوشیدنی و دسر می باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: <Link className="link" to="/Listings/rent_tehran_fereshte" target="_blank">  فرشته </Link>، خ نیلوفر، مجتمع تجاری یوتوپیا، طبقه ۶
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/hermoso.restaurant" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                ۳. کافه باراکا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="کافه باراکا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه باراکا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                یکی از بهترین انتخاب ها برای کافه از افطار تا سحر، کافه باراکا می باشد. کافه باراکا در مرکز شهر قرار دارد. این کافه جذاب با حیاطی سرسبز دارای منوی متنوعی برای افطار و سحر می باشد. تایم کاری این کافه از ساعت 19 تا 3 بامداد است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: بالاتر از میدان <Link className="link" to="/Listings/buy_tehran_enghelab" target="_blank">  انقلاب </Link>، خیابان <Link className="link" to="/Listings/buy_tehran_karegarshomali" target="_blank">  کارگر شمالی </Link>، کوچه فروزانفر (رهنما)، پلاک ١٩
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/baccara_cafe" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Grid className="box">
                <Link className="link" to="/book-cafe" target="_blank">
                  بیشتر بخوانید : دنج ترین کافه کتاب در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۴. کافه رستوران ۷۰۳
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کافه رستوران ۷۰۳ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه رستوران ۷۰۳
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این کافه رستوران یکی از کافه های شناخته شده <Link className="link" to="/Listings/buy_tehran_marzdaran" target="_blank">  مرزداران </Link> می باشد. از ویژگی های منحصر بفرد این رستوران می توان به دکوراسیون فوق العاده و ویوی دل انگیز این کافه اشاره کرد. منوی این رستوران شامل انواع غذاها و نوشیدنی ها می باشد. همچنین این رستوران در ماه رمضان نیز پذیرای خوبی برای افطار تا سحر مهمانان می باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: مرزداران،خیابان ابراهیمی،برج الوند،واحد 703
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/703_restaurant" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                ۵. کافه لتو
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="کافه لتو - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه لتو
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از گزینه های مناسب برای افطار تا سحر می توان کافه لتو را پیشنهاد داد. فضا و نور پردازی این کافه بسیار دلپذیر می باشد. در این کافه همچنین امکان دیدن مسابقات ورزشی و انجام بازی های گروهی فراهم است. بنابراین چنانچه تصمیم به کافه نشینی تا سحر را دارید، انتخاب کافه لتو خالی از لطف نیست.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: <Link className="link" to="/listing/1151" target="_blank">  بهار شمالی </Link>، خيابان وركش،پلاك ١٧/٤
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/letocafe" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-spectaculars" target="_blank">
                  بیشتر بخوانید : مکان های دیدنی تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۶. خانه پاپریکا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo07} alt="خانه پاپریکا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  خانه پاپریکا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                چنانچه قصد دارید برای افطار تا سحر کافه ای را انتخاب کنید ما به شما کافه پاپریکا را پیشنهاد می دهیم. در این کافه شما می توانید تایم افطار تا سحر را به بازی های رومیزی جذابی که برای شما در نظر گرفته اند اختصاص دهید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: <Link className="link" to="/Listings/rent_tehran_enghelab" target="_blank"> خیابان انقلاب </Link>، خیابان نجات‌اللهی(ویلا) کوچه محمدی، نبش کوچه افشار
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/paprika__house" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                ۷. کافه یانکی
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo08} alt="کافه یانکی - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران یانکی
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه یانکی دارای محیطی بسیار دنج برای دورهمی های دوستانه و خانوادگی می باشد. کافه یانکی تنها کافه ای است که هر 365 روز سال را تا ساعت 5 صبح اماده سرویس دهی می باشد. بنابراین این کافه نیز یک پیشنهاد جذاب برای افطار تا سحر است. در این کافه قلیان سرو نمی شود اما استعمال سیگار آزاد می باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: خیابان قرني، نرسيده به <Link className="link" to="/Listings/buy_tehran_karimkhanezand" target="_blank">  کریمخان </Link>، هتل بلور، طبقه اول
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/cafe.yankee" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Tehran-cafe-with-hooka" target="_blank">
                  بیشتر بخوانید : کافه های قلیان دار در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۸. کافه حیات هزار افسان
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo09} alt="کافه حیات هزار افسان - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کافه حیات هزار افسان
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                حیات هزار افسان در یک حیاط قدیمی قرار دارد که دارای محیطی دنج می باشد. این کافه برای کسانی که دنبال یک فضای ارامبخش هستند بسیار مناسب است. همچنین این کافه منوی ویژه ای برای ماه رمضان در نظر گرفته است. فضای این کافه در شب بسیار دل انگیز می باشد. بنابراین پیشنهاد می کنیم حتما برای افطار سری به این کافه بزنید و از محیط این کافه تا سحر لذت ببرید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: <Link className="link" to="/Listings/buy_tehran_felestin" target="_blank"> خیابان فلسطین </Link>، خیابان بزرگمهر، خیابان برادران شهید مظفر(صبا)
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/hezarafsan.cafe" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                ۹. کافه دلسا
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo10} alt="کافه دلسا - هومینجا" className="image" />
                <figcaption className="image_caption">
                  رستوران دلسا
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه دلسا را یکی از بهترین کافه رستوران های پاسداران می توان دانست. این کافه در دو طبقه تعبیه شده است که دارای روف گاردن با فضای بزرگی می باشد. همچنین دلسا دارای منوی متنوعی از غذاها می باشد که برای ماه رمضان افطاری را نیز به منوی خود اضافه کرده است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: <Link className="link" to="/Listings/rent_tehran_pasdaran" target="_blank"> پاسداران </Link>، روبروی <Link className="link" to="/Listing/908" target="_blank">  نگارستان </Link> ۵، پ ۴۶
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/cafedelsa" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Grid className="box">
                <Link className="link" to="/north-of-Tehran-cafe" target="_blank">
                  بیشتر بخوانید : بهترین کافه های شمال تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                ۱۰. کافه ال ماسو لانژ
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo11} alt="ال  ماسو لانژ - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ال ماسو لانژ
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                کافه ال ماسو لانژ با طراحی و دکوراسیون متفاوت پذیرای مهمانان از افطار تا سحر می باشد. ال ماسو لانژ از کافه های معروف منطق تهرانپارس می باشد. این کافه دارای دو فضای باز و بسته می باشد و پیشنهاد ویژه ای برای شب های رمضان می باشد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                آدرس: <Link className="link" to="/Listings/buy_tehran_tehranparsgharbi" target="_blank">  تهرانپارس </Link>،بالاتر از میدان شاهد(پروین)نبش خ خدابنده،طبقه فوقانی <LinkMui className="link" href="https://www.sbank.ir" target="_blank">  بانک سرمایه </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشانی اینترنتی: <LinkMui className="link" href="https://www.instagram.com/elmaso.lounge" target="_blank">  اینستاگرام </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                ما در این مقاله از <Link className="link" to="/best-cafes-iftar-til-suhur" target="_blank">  هومینجا </Link> سعی بر این داشتیم که برای ماه رمضان شما را با فضاهای جذاب و آرام آشنا کنیم تا اوقات خوشی را در کنار بستگان و دوستان برای افطار تا سحر سپری کنید. امید است این مقاله مفید واقع شود.
              </Typography>
              <Typography className="h4" variant="h4">
                با هومینجا یک کلیک تا قرارداد
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                برای مشاهده صفحات زیر از سایت هومینجا کلیک کنید.
              </Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    خرید و فروش آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    رهن و اجاره آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/HomeMag'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    بهترین مقالات املاک و مستغلات
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/top-5-real-estate-sites'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    5 سایت برتر خرید و فروش ملک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>

              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("دانستنی ها ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  دانستنی ها

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article50;