import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { baseUrl, convertToPersianNumber, separator } from "./materilas/vmp";
import axios from "axios";
import AlertModal from "./materilas/alert";
import GiftLogo from '../component/gifLogo';
import locationIcon from "../images/listing/location.png";
import noLogoAgancyImage from "../images/noLogoAgancy.png";

class VisitListingUser extends React.Component{

    isMount = false ;
    token = localStorage.getItem('tokenUserHomeInja');

    constructor(props){
        super(props);
        this.state={
          giftShow : "none" ,
          openAlert : false ,
          message : "" ,
          noVisitPage : true ,
          visitListingPage : false ,
          detailedListingPage : false ,
          visitListingUserInfo : null ,
          visitors : null,
          largeImage : null ,
          cityName : null ,
          neighbourhoodName : null,
          typeAlert : 3
        }
    }

    componentDidMount(){
      this.isMount = true ;
      document.title = 'بازدید آگهی های من در پلتفرم درج آگهی املاک هومینجا'
      if(this.isMount){
        this.getVisitListingUser();
      }
    }

    componentWillUnmount(){
      this.isMount = false ;
    }

    oncloseAlert = () =>{
      this.setState({openAlert:false});
    }

    getVisitListingUser = () => {
      Promise.resolve(axios({
        url: baseUrl + "v1/user/listingVisits",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
      }))
      .then((res) => {
        if(res.data.data.length > 0){
          this.setState({visitListingUserInfo:res.data.data,visitListingPage:true,noVisitPage:false});
        }
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",typeAlert:2})
      })
    }

    visitListings = () => {
      let result = [] ;
      this.state.visitListingUserInfo.forEach((element,index) => {
        result.push(
          <Grid item xs={4} lg={4} xl={4} key={index}>
            <Button onClick={() => this.onClickVisitListing(index)}
              sx={{border:"2px solid #ebffff",maxWidth:"100%",minWidth:"100%",borderTopRightRadius:"40px",padding:"0",overflow:"hidden"}}
            >
              <Grid container sx={{flexWrap:"nowrap"}}>
                <Grid item xs={6} 
                  sx={{background:`url(${element.listing.small.length > 0  ? element.listing.small[0].path : noLogoAgancyImage})`,
                      backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
                >
                </Grid>
                <Grid item xs={6}>
                  <Grid sx={{display:"flex",alignItems:"center",padding:"10% 0 5% 0"}}>
                    <Typography component={"img"} src={locationIcon} alt="location icon" sx={{width:{xs:"15px",md:"20px"},height:"auto",marginRight:"3%"}}></Typography>
                    <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"10px",display:"inline-block",color:"black"}}>
                      {element.listing.city.name}  -
                    </Typography>
                    <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"5px",display:"inline-block",color:"black"}}>
                      {element.listing.neighbourhood.name}
                    </Typography>
                  </Grid>
                  { element.listing.show_price ?
                      element.listing.transaction_id === 1 ?
                        <Grid sx={{padding:"25px 0",textAlign:"right"}}>
                          <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600",color:"black"}} >
                              خرید :
                            </Typography>
                            <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                              { element.listing.total_price ?
                                  `${convertToPersianNumber(separator(element.listing.total_price))} تومان`
                                : "توافقی"
                              }
                            </Typography>
                        </Grid>
                      : element.listing.transaction_id === 2 ?
                          <Grid sx={{padding:"12.5px 0",textAlign:"right"}}>
                            <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600",color:"black"}} >
                                رهن :
                              </Typography>
                              <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                                { element.listing.mortgage ?
                                    `${convertToPersianNumber(separator(element.listing.mortgage))} تومان`
                                  : "توافقی"
                                } 
                              </Typography>
                              <br />
                              <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600",color:"black"}} >
                                اجاره :
                              </Typography>
                              <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600",fontFamily:"shabnam"}}>
                                { element.listing.rent ?
                                    `${convertToPersianNumber(separator(element.listing.rent))} تومان`
                                  : "توافقی"
                                }
                              </Typography>
                          </Grid>
                        : element.listing.transaction_id === 3 ?
                            <Grid sx={{padding:"25px 0",textAlign:"right"}}>
                              <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600",color:"black"}} >
                                  پیش خرید :
                                </Typography>
                                <Typography style={{display:"inline-block",marginRight:"5px",color:"#01A7C4",fontWeight:"600"}} price={parseInt(element.listing.prebuy)}>
                                  { element.listing.prebuy ?
                                      `${convertToPersianNumber(separator(element.listing.prebuy))} تومان`
                                    : "توافقی"
                                  }
                                </Typography>
                            </Grid>
                          : <Grid></Grid>
                    : <Grid sx={{padding:"25px 0",textAlign:"right"}}>
                        <Typography sx={{display:"inline-block",fontFamily:"shabnam",marginRight:"3%",fontWeight:"600",color:"black"}} >
                            قیمت : توافقی
                        </Typography>
                      </Grid>
                  }
                </Grid>
              </Grid>
            </Button>
          </Grid>
        )
      });
      return result ;
    }

    onClickVisitListing = (index) => {
      this.setState({giftShow:""})
      Promise.resolve(axios({
        url: baseUrl + "v1/user/getVisitorListing?",
        method: "GET",
        headers: {"Authorization":"bearer "+ this.token},
        params: {
          listing_id:this.state.visitListingUserInfo[index].listing.id
        }
      }))
      .then((res) => {
        this.setState({visitors:res.data.data,cityName:this.state.visitListingUserInfo[index].listing.city.name,giftShow:"none",
          neighbourhoodName:this.state.visitListingUserInfo[index].listing.neighbourhood.name,visitListingPage:false,detailedListingPage:true,
          largeImage:this.state.visitListingUserInfo[index].listing.large ? this.state.visitListingUserInfo[index].listing.large[0].path : null})
      })
      .catch((err) => {
        this.setState({openAlert:true,message:"سامانه با مشکل مواجه شده است لطفا با پشتیبانی تماس حاصل فرمائید",giftShow:"none",typeAlert:2})
      })
    }

    visitors = () => {
      let result = [];
        this.state.visitors.forEach((element,index) => {
          result.push(
            <Grid item container xs={12} key={index}>
              <Grid item xs={3}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>{convertToPersianNumber(this.state.visitors[0].date)} </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>{convertToPersianNumber(this.state.visitors[0].time)} </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",fontSize:{xs:"12px",md:"16px"}}}>{this.state.visitors[0].visitor.name}</Typography>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",display:"inline-block",marginRight:"5px",fontSize:{xs:"12px",md:"16px"}}}>{this.state.visitors[0].visitor.lastname}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>{convertToPersianNumber(this.state.visitors[0].visitor.mobile)} </Typography>
              </Grid>
            </Grid>
          )
        });
      return result ;
    }

    onClickBack = () => {
      this.setState({visitors:null,cityName:null,neighbourhoodName:null,visitListingPage:true,detailedListingPage:false,largeImage:null})
    }

    render() {
      return (
        <Grid style={{marginTop:120,width:'100%',display:'flex',flexDirection:'column'}} sx={{marginRight:{md:'270px'}}}>
          { this.state.noVisitPage ?
              <Grid sx={{width:"100%"}}>
                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>بازدید آگهی های من</Typography>
                <Grid style={{display:'flex',marginTop:'20%',width:'100%',height:'400px'}}>
                  <Typography variant="h1" style={{fontFamily:'shabnam',fontSize:'30px',textAlign:'center',fontWeight:'bold',width:'100%'}}>
                    {"کاربر گرامی شما بازدیدی ندارید"}
                  </Typography>
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          { this.state.visitListingPage ?
              <Grid sx={{width:"100%"}}>
                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>بازدید آگهی های من</Typography>
                <Grid container sx={{padding:{xs:"2% 2%",md:"2% 6%"}}} spacing={{xs:1,md:2}} columns={{xs:4,lg:8,xl:12}}>
                  {this.visitListings()}
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          { this.state.detailedListingPage ?
              <Grid sx={{width:"100%"}}>
                <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"1.2rem",md:"1.5rem"},padding:"0 6%",fontWeight:'bold'}}>جزئیات بازدید آگهی های من</Typography>
                <Grid sx={{border:"2px solid #eee",margin:{xs:"2% 2%",md:"2% 6%"},borderRadius:"100px 4px 4px 4px"}}>
                  <Grid sx={{height:{xs:"200px",md:"300px"},position:"relative",borderRadius:"100px 4px 4px 4px",overflow:"hidden"}}>
                    <Grid 
                      sx={{width:"100%",height:"90%",background:`url(${this.state.largeImage ? this.state.largeImage : ""})`,backgroundPosition:"center",
                          backgroundRepeat:"no-repeat",backgroundSize:"cover"}}
                    >
                    </Grid>
                      <Grid 
                        sx={{width:{xs:"40%",md:"30%"},display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"5px",bgcolor:"white",position:"absolute",
                        right:"4%",bottom:"5%",padding:"10px 0"}}
                      >
                        <Typography component={"img"} src={locationIcon} alt="location icon" sx={{width:{xs:"15px",md:"20px"},height:"auto",marginRight:"3%"}}></Typography>
                        <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"10px",display:"inline-block",color:"black"}}>
                          {this.state.cityName}  -
                        </Typography>
                        <Typography sx={{fontSize:{xs:"15px",md:"20px"},fontFamily:"shabnam",fontWeight:"600",marginRight:"5px",display:"inline-block",color:"black"}}>
                          {this.state.neighbourhoodName}
                        </Typography>
                      </Grid>
                      <Button sx={{bgcolor:"#00C8E9",position:"absolute",left:"4%",bottom:"5%",padding:"10px","&:hover":{bgcolor:"#00C8E9"}}} onClick={this.onClickBack}>
                        <Typography sx={{fontFamily:"shabnam",fontSize:{xs:"15px",md:"20px"},fontWeight:"600",color:"black"}}>بازگشت</Typography>
                      </Button>
                  </Grid>
                  <Grid container sx={{padding:"4% 4%",textAlign:"center"}} rowSpacing={3}>
                    <Grid item xs={3}>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>تاریخ بازدید</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>ساعت بازدید</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>نام بازدید کننده</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{fontFamily:"shabnam",fontWeight:"bold",fontSize:{xs:"12px",md:"16px"}}}>شماره تلفن بازدیدکننده</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{height:"3px",bgcolor:"#00C8E9",borderRadius:"2px",margin:"2% 0 1% 0",paddingTop:"0 !important"}}></Grid>
                    {this.visitors()}
                  </Grid>
                </Grid>
              </Grid>
            : <Grid></Grid>
          }
          
          <GiftLogo display={this.state.giftShow}/>
          <AlertModal open={this.state.openAlert} message={this.state.message} close={this.oncloseAlert} type={this.state.typeAlert}/>
        </Grid>
      )
    };
}

export default VisitListingUser;