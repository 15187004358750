import React from "react";
import "../../styles/article.css";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import photo01 from "../../images/mag/article28/01.jpg";
import photo02 from "../../images/mag/article28/02.jpg";
import photo03 from "../../images/mag/article28/03.jpg";
import photo04 from "../../images/mag/article28/04.jpg";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article28 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "اوایل سال 1400 سامانه ملی املاک و اسکان کشور با هدف ثبت و ذخیره اطلاعات مرتبط با حوزه اسکان و املاک در ایران راه اندازی شد .";
    document.getElementsByTagName('meta')["keywords"].content = "سامانه ثبت املاک | سامانه ایران املاک | ثبت نام در سامانه ملی املاک و اسکان کشور | راهنمای سایت ملی املاک و اسکان کشور"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }

  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[27].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[27].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", overflow: "clip", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[27].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[27].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[27].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[27].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="معرفی سامانه ملی املاک و اسکان کشور - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[27].desc}
              </Typography>
              <Grid container sx={{ display: { xs: "flex", md: "none" } }} spacing={0.5}>
                <Grid item xs={6}>
                  <ArticleAd1 />
                </Grid>
                <Grid item xs={6}>
                  <ArticleAd2 />
                </Grid>
              </Grid>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                خانه به عنوان یکی از اساسی‌ترین نیازهای بشر،
                هم به لحاظ امنیت و هم به لحاظ نگاه سرمایه‌ای در همه کشورها مورد توجه است. با توجه به اهمیت این موضوع و همچنین استفاده
                از خدمات الکترونیک و آنلاین در ثبت و ذخیره اطلاعات مربوط به املاک در ایران، از اوایل سال ۱۴۰۰ خورشیدی سامانه ملی املاک و
                اسکان کشوربا هدف ثبت و ذخیره اطلاعات مرتبط با حوزه اسکان و املاک در ایران راه اندازی شد. همه افراد دارای ملک (استیجاری یا تملک) به
                دستور دولت وقت ملزم به ثبت اطلاعات ملک یا املاک خود در این سامانه شدند. مهمترین هدف راه اندازی
                این سامانه که توسط وزارت راه و شهرسازی معرفی شد، اجرایی شدن طرح مالیات بر واحدهای مسکونی خالی بود.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="سامانه ملی املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سامانه ملی املاک
                </figcaption>
              </figure>
              <Typography className="h2" variant="h2">
                ثبت اطلاعات در سایت املاک کشور
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همه افراد سرپرست خانواده (مالک یا مستأجر) ملزم به ثبت نام
                در سامانه و ثبت اطلاعات ملکی که در آن اسکان دارند شدند. ضمن این که افراد مالک ساختمان‌های
                خالی نیز ملزم به ثبت اطلاعات همه املاک تحت تملک خود در این سامانه هستند. به این ترتیب قانون
                دریافت مالیات از همه دارندگان املاک حتی خانه‌های خالی از سکنه به راحتی قابلیت اجرا پیدا می‌کند.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  کد رهگیری املاک چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نگاهی به سامانه ملی املاک و اسکان کشور
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="http://news.mrud.ir/" target="_blank" rel="noreferrer">وزارت راه مسکن و شهرسازی</LinkMui>،  این سامانه جامع را راه اندازی کرد.
                با ورود به سایت <LinkMui className="link" href="https://amlak.mrud.ir/" target="_blank" rel="noreferrer">amlak.mrud.ir</LinkMui> همه افراد حقیقی و حقوقی می‌توانند
                به ثبت املاک خود در آن بپردازند. طراحی وب سایت املاک و اسکان کشور بسیار ساده و کاربردی می باشد. با ورود به صفحه
                نخست سایت می‌توانید فهرست همه بخش‌های موجود را در قسمت
                بالای سایت مشاهده کنید. این فهرست شامل اخبار و اطلاعیه ها، پشتیبانی و تماس با گردانندگان سایت،
                بخش سوالات متداول، راهنما و ثبت افراد حقوقی می‌شود.
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="ثبت نام سامانه ملی املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  ثبت نام در سامانه ملی املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در قسمت اصلی سایت بخش‌های مرتبط با ورود افراد حقیقی (به واسطه دولت من)، افراد حقوقی (به واسطه دولت من) طراحی شده است.
                همچنین دستورالعمل‌های خود اظهاری مجددا به صورت باکس‌های کاملا مستقل،
                وجود دارند. امکان ورود هر شخص حقیقی و حقوقی بر اساس ماهیت فردی را به شکل مستقل فراهم می‌سازد.
                <br />
                استفاده از دستور العمل ثبت خوداظهاری و یا مطالعه
                راهنمای ثبت نام نیز می‌تواند برای کسانی که نمی‌دانند چگونه باید اطلاعات خود را در این سامانه ثبت کنند بسیار کاربردی باشد.
                <br />
                تمامی اخبار و <LinkMui className="link" href="https://homeinja.info/" target="_blank" rel="noreferrer">اطلاعیه‌های حوزه مسکن</LinkMui> و املاک در صفحه نخست سایت به
                صورت خبر و با تاریخ انتشار قرار گرفته است. کاربر می‌تواند بر حسب نیاز خود اطلاعات و اخبار مرتبط با این حوزه را مطالعه نماید.
                <br />
                نکات مهم استفاده از این سامانه در قسمت پایین سایت برای کاربران، ذکر شده است. توجه به آن‌ها در عملکرد بهتر سامانه تأثیرگذار خواهد بود.
              </Typography>
              <Typography className="h2" variant="h2">
                رفع همه نیازهای کاربر در زمینه ثبت اطلاعات
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                سامانه املاک
                راه اندازی شده توسط وزارت راه و شهرسازی بسیار کاربر محور، ساده و کاربردی است. در صورتی که نیاز به استفاده از خدمات آن داشته باشید هیچ ابهامی به
                عنوان کاربر برای مراجعه به بخش‌های مختلف سایت نخواهید داشت. ضمن این که در نظر گرفتن بخش سوالات متداول، می‌تواند پاسخگوی نیمی از سوالات کاربران باشد.
                <br />
                در این بخش بسیاری
                از سوالات مشترک کاربران با پاسخ کامل به نمایش درآمده است. توصیه شده است که کاربر برای دریافت پاسخ سوالات خود ابتدا این بخش را به دقت مطالعه کند.
                <br />
                همچنین در بخش تماس با ما
                در سایت املاک کشور، کاربر می‌تواند با وارد کردن اطلاعاتی چون کد ملی، شماره موبایل و عنوان سوال، پیام خود را به صورت متنی جهت پاسخگویی ارسال کند.
                <br />
                در این بخش علاوه بر ارسال
                پیام متنی، شماره تلفن‌هایی درج شده است. کاربر می‌تواند در ساعات اداری برای دریافت پاسخ پرسش‌ها و رفع ابهامات در زمینه سامانه با آن‌ها تماس بگیرد.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/buying-house-in-Tehran" target="_blank">
                  بیشتر بخوانید : نکات مربوط به خرید ملک در تهران
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                راهنمای سایت
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                نکات مرتبط با هر نوع مسکن و ملکی از خانه‌های
                سازمانی و واگذاری ملک، ملک‌های موروثی و… تا فهرست اطلاعات مورد نیاز برای ثبت نام و راهنمای گام به گام ثبت اطلاعات در سایت به صورت دسته‌بندی شده است.
                <br />
                هم چنین در بخش راهنمای وب سایت املاک و اسکان کشور
                قرار گرفته است. کاربر می‌تواند به راحتی با مطالعه بخش مورد نیاز خود چگونگی ثبت اطلاعات و فهرست اطلاعاتی که باید در سامانه درج شود را مشاهده نماید.
              </Typography>
              <Typography className="h2" variant="h2">
                مزایای استفاده از امکانات سایت املاک کشور
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="سایت ملی املاک ,و مسکن - هومینجا" className="image" />
                <figcaption className="image_caption">
                  سایت ملی املاک کشور
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                این سامانه با هدف ثبت اطلاعات مسکن و املاک در همه شهرهای ایران و به منظور تعیین مالیات بر واحدهای خالی اجرایی شد. بنابراین همه
                افراد حقیقی و حقوقی چه صاحب ملک باشند و چه ملکی را در اجاره داشته باشند، موظف هستند که اطلاعات مرتبط با ملک مذکور را در سامانه یاد شده وارد نمایند.
                <br />
                کاربری سامانه همه انواع مالکیت‌ها و انواع املاک موجود
                (استیجاری، اقامتگاهی، آپارتمانی، ویلایی، مسکونی، تجاری و…) را شامل می‌شود. در صورتی که کاربر گزینه ثبت نهایی را انتخاب کرده باشد
                امکان ویرایش اطلاعات وجود ندارد. بنابراین بسیار مهم است که اطلاعات به درستی و با دقت در سامانه درج شود.
                <br />
                با مراجعه به بخش پرسش‌های متداول سایت کاربر می‌تواند پاسخ
                بسیاری از پرسش‌های خود در زمینه‌های مختلف را مشاهده کند. در این بخش به همه پرسش‌های احتمالی کاربران پاسخ داده شود. این پرسش ها می تواند چگونگی ثبت
                نام تا اطلاعاتی که در مورد آن‌ها ابهاماتی وجود دارد باشد. بنابراین مراجعه به این بخش قبل از اقدام برای ثبت اطلاعات می‌تواند بسیار مفید و کاربردی باشد.
              </Typography>
              <Typography className="h2" variant="h2">
                جمع‌بندی
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                با توجه به ارائه خدمات
                آنلاین به کاربران در حوزه‌های مختلف و همچنین استفاده از خدمات دولت الکترونیک، راه اندازی سامانه ملی املاک و اسکان کشور گامی بزرگ در راستای ثبت و
                ذخیره داده‌های آماری بخش املاک و مسکن می باشد. همچنین راهی مطمئن برای تعیین میزان مالیات بر مسکن و املاک بوده است. ما در این مقاله از سایت هومینجا
                تلاش کردیم تا با معرفی این سامانه و نگاهی موشکافانه به جزئیات همه بخش‌های موجود در سایت، کاربران عزیز را با خدمات و اهداف سایت املاک کشور آشنا کنیم.
              </Typography>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی
                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/amlak.mrud.ir" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/amlak.mrud.ir" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/amlak.mrud.ir" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }

}

export default Article28;