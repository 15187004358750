import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import callUs from '../images/callUs.png';
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShowLocation from "./materilas/showLocation";
import { setCanonicalTag } from "./materilas/vmp";



const position = [35.749147, 51.503316];

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    document.title = "تماس با شرکت وب گستران سورین | سامانه املاک هومینجا";
    document.getElementById("main-heading").innerHTML = "ارتباط با بهترین سایت جستجو املاک و درج آگهی ملک در ایران "
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
    document.getElementsByTagName('meta')["keywords"].content = "تلفن بهترین آژانس املاک تهران | تماس با بهترین سایت رهن و اجاره ملک | ارتباط با بهترین سایت خرید و فروش آپارتمان | برترین سامانه جستجوی املاک و مستغلات در ایران | آدرس شرکت آگهی آپارتمان و ویلا | بهترین مکان ثبت آگهی ملک | بهترین سایت جستجوی ملک"
    setCanonicalTag();
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Grid container
        sx={{
          marginTop: { xs: "60px", md: "88px" }, width: "100%", height: { xs: 'auto', md: 'calc(100vh - 88px)' },
          background: {
            xs: "linear-gradient(179.63deg, #CCF4FB -0.57%, rgba(204, 244, 251, 0) 99.68%)", md: "linear-gradient(270deg, #CCF4FB 0%, rgba(204, 244, 251, 0) 100%)"
          }
        }}
      >
        <Grid item xs={12} md={5} sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", padding: { xs: "15px 15px", md: "0 50px" }, textAlign: "center" }}>
          <Typography component={"img"} src={callUs} alt="callUs" sx={{ width: { xs: "20%", md: "30%" }, height: "auto" }}></Typography>
          <Grid sx={{ padding: { xs: "5px 0", md: "30px 0" } }}>
            <Typography sx={{ fontFamily: "shabnam", display: "inline-block", fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold" }}>شماره تلفن :</Typography>
            <Link href="tel:0217671265"
              sx={{ fontFamily: "shabnam", display: "inline-block", direction: "ltr", fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold", marginRight: "5px", textDecoration: "none" }}
            >
              02177442770
            </Link>
          </Grid>
          <Typography sx={{ fontFamily: "shabnam", display: "inline-block", fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold" }}>آدرس : خیابان هنگام،نبش کوچه الیاسی(ششم شرقی)،پلاک 1،واحد 5.</Typography>
          <Typography sx={{ fontFamily: "shabnam", display: "inline-block", fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold", padding: { xs: "5px 0", md: "30px 0 0 0" } }}>برای ارتباط با سامانه املاک هومینجا علاوه بر چت آنلاین و تیکت، می توانید با شماره های شرکت تماس گرفته و یا به صورت حضوری به شرکت سورین مراجعه نمایید.</Typography>
          <Typography sx={{ fontFamily: "shabnam", display: "inline-block", fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold", padding: { xs: "5px 0", md: "30px 0 0 0" } }}>از ساعت 10 صبح الی 17 عصر پذیرای شما خواهیم بود.</Typography>
          <Typography sx={{ fontFamily: "shabnam", display: { xs: "none", md: "inline-block" }, fontSize: { xs: "15px", md: "20px" }, fontWeight: "bold", padding: { xs: "5px 0", md: "30px 0" } }}>هومینجا در شبکه های اجتماعی</Typography>
          <Grid sx={{ display: { xs: "none", md: "flex" }, alignItems: "cetner", justifyContent: "center" }}>
            <Link href="https://wa.me/qr/SPF27MFJ2QTCA1" target="_blank"
              sx={{
                borderRadius: "50%", "&:hover": { boxShadow: "0px 1px 8px 0px #000000" }, display: "inline-flex", width: { xs: "20px", md: "40px" }, height: { xs: "20px", md: "40px" },
                transition: "all 0.3s", alignItems: "center", justifyContent: "center", marginLeft: { xs: "2px", md: "15px" }, bgcolor: "rgba(0, 42, 69, 1)",
                marginBottom: { xs: "0", md: "20px", lg: "0" }, WebkitMaskImage: "-webkit-radial-gradient(white, black)"
              }}
            >
              <WhatsappRoundedIcon sx={{ width: { xs: "10px", md: "32px" }, height: { xs: "10px", md: "32px" }, color: "white !important", "&:hover": { transform: "scale(1.3, 1.3)" }, transition: "all 0.1s" }} />
            </Link>
            <Link href="https://www.instagram.com/homeinja2022/" target="_blank"
              sx={{
                borderRadius: "50%", "&:hover": { boxShadow: "0px 1px 8px 0px #000000" }, display: "inline-flex", width: { xs: "20px", md: "40px" }, height: { xs: "20px", md: "40px" },
                transition: "all 0.3s", alignItems: "center", justifyContent: "center", marginLeft: { xs: "2px", md: "15px" }, bgcolor: "rgba(0, 42, 69, 1)"
              }}
            >
              <InstagramIcon sx={{ width: { xs: "10px", md: "32px" }, height: { xs: "10px", md: "32px" }, color: "white !important", "&:hover": { transform: "scale(1.3, 1.3)" }, transition: "all 0.1s" }} />
            </Link>
            <Link href="http://twitter.com/homeinja" target="_blank"
              sx={{
                borderRadius: "50%", "&:hover": { boxShadow: "0px 1px 8px 0px #000000" }, display: "inline-flex", width: { xs: "20px", md: "40px" }, height: { xs: "20px", md: "40px" },
                transition: "all 0.3s", alignItems: "center", justifyContent: "center", marginLeft: { xs: "2px", md: "15px" }, bgcolor: "rgba(0, 42, 69, 1)"
              }}
            >
              <TwitterIcon sx={{ width: { xs: "10px", md: "32px" }, height: { xs: "10px", md: "32px" }, color: "white !important", "&:hover": { transform: "scale(1.3, 1.3)" }, transition: "all 0.1s" }} />
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Grid sx={{ width: { xs: "300px", md: "400px", lg: "600px" }, height: { xs: "300px", md: "400px", lg: "600px" }, borderRadius: "50%", overflow: "hidden" }}>
            <ShowLocation position={position} style={{ borderRadius: "50%", overflow: "hidden" }} />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" }, padding: "40px 0 " }}>
          <Typography sx={{ fontFamily: "shabnam", display: { xs: "block", md: "none" }, fontSize: "15px", fontWeight: "bold", textAlign: "center" }}>هومینجا در شبکه های اجتماعی</Typography>
          <Grid container sx={{ width: "40%", margin: "auto" }}>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <Link href="https://wa.me/qr/SPF27MFJ2QTCA1" target="_blank"
                sx={{
                  borderRadius: "50%", "&:hover": { boxShadow: "0px 1px 8px 0px #000000" }, display: "inline-flex", width: { xs: "30px", md: "40px" }, height: { xs: "30px", md: "40px" },
                  transition: "all 0.3s", alignItems: "center", justifyContent: "center", bgcolor: "rgba(0, 42, 69, 1)",
                  marginBottom: { xs: "0", md: "20px", lg: "0" }
                }}
              >
                <WhatsappRoundedIcon sx={{ width: { xs: "20px", md: "32px" }, height: { xs: "20px", md: "32px" }, color: "white !important", "&:hover": { transform: "scale(1.3, 1.3)" }, transition: "all 0.1s" }} />
              </Link>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <Link href="https://www.instagram.com/homeinja2022/" target="_blank"
                sx={{
                  borderRadius: "50%", "&:hover": { boxShadow: "0px 1px 8px 0px #000000" }, display: "inline-flex", width: { xs: "30px", md: "40px" }, height: { xs: "30px", md: "40px" },
                  transition: "all 0.3s", alignItems: "center", justifyContent: "center", bgcolor: "rgba(0, 42, 69, 1)"
                }}
              >
                <InstagramIcon sx={{ width: { xs: "20px", md: "32px" }, height: { xs: "20px", md: "32px" }, color: "white !important", "&:hover": { transform: "scale(1.3, 1.3)" }, transition: "all 0.1s" }} />
              </Link>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <Link href="http://twitter.com/homeinja" target="_blank"
                sx={{
                  borderRadius: "50%", "&:hover": { boxShadow: "0px 1px 8px 0px #000000" }, display: "inline-flex", width: { xs: "30px", md: "40px" }, height: { xs: "30px", md: "40px" },
                  transition: "all 0.3s", alignItems: "center", justifyContent: "center", bgcolor: "rgba(0, 42, 69, 1)"
                }}
              >
                <TwitterIcon sx={{ width: { xs: "20px", md: "32px" }, height: { xs: "20px", md: "32px" }, color: "white !important", "&:hover": { transform: "scale(1.3, 1.3)" }, transition: "all 0.1s" }} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ display: "none" }}>
          <Typography>
            ما در این صفحه اطلاعات تماس با شرکت وب گستران سورین | سامانه املاک هومینجا را ذکر می کنیم.
          </Typography>
          <Typography>
            همچنین به اطلاعاتی در موردارتباط با بهترین سایت جستجو املاک و درج آگهی ملک در ایران  می پردازیم.
          </Typography>
          <Typography>
            برای ارتباط با سامانه املاک هومینجا علاوه بر چت آنلاین و تیکت، می توانید با شماره های شرکت تماس گرفته و یا به صورت حضوری به شرکت سورین مراجعه نمایید.
            برای ارتباط با قوی ترین فایلینگ ایران و دریافت خدمات مشاوره و کارشناسی ملک تماس بگیرید.
            هومینجا به عنوان بهترین سایت املاک و مستغلات ایران آماده ارائه خدمات به آژانس های املاک، مشاورین مححترم و مالکان عزیز می باشد.
            از ساعت 10 صبح الی 19 عصر پذیرای شما خواهیم بود.

          </Typography>
        </Grid>
      </Grid>
    )
  };
}

export default Contact;