import { Grid, Typography } from "@mui/material";
import React from "react";
import HomeinjaTypo from "./materilas/homeinjaTypo";
import { Link } from "react-router-dom";
import BackgroundPhoto from '../images/BackgroundPhoto.png'
import { convertToPersianNumber, separator } from "./materilas/vmp";

class VIP1 extends React.Component {
  isMount = false;
  stateId = null;
  cityId = null;
  zoneId = null;
  neighbourhoodId = [];
  typeEsateId = [];
  bedroomNum = null;

  render() {
    return (
      <Grid container className="vipOne" 
            style={this.props.vip ? 
                    this.props.vip.status_id !== 5 ?
                      this.props.vip.large.length !== 0 ? 
                        { backgroundImage: `url(${this.props.vip.large[0].path})` } 
                      : { backgroundImage: `url(${BackgroundPhoto})` } 
                    : { backgroundImage: `url(${BackgroundPhoto})` }
                  : { backgroundImage: `url(${BackgroundPhoto})` }}
            sx={{minHeight:{lg:'90vh',md:"90vh",sm:"85vh",xs:"80vh"}}}
      >
        { this.props.vip ?
            this.props.vip.status_id !== 5 ?
              this.props.vip.large.length !== 0 ?
                <Typography component={"img"} src={this.props.vip.large[0].path} sx={{display:"none"}}
                  alt = {`${this.props.vip.estate.des + " " + convertToPersianNumber(this.props.vip.area) + " متری " +  this.props.vip.neighbourhood.name + " | هومینجا"}`}
                />
              : null
            : null
          : null
        }
        <Grid item  xs={11} sx={{width:{xs:'90%'},marginTop:{xl:'35vh',lg:'30vh',md:'40vh',sm:'35vh', xs:'25vh'}}} className="gridItemVip1" >
        {
          this.props.vip ? 
            this.props.vip.status_id !== 5 ?
              <Grid className="titleGrid" component={Link} to={"/Listing/" + this.props.vip.id} target="_blank">
                <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}}
                              //text={"آپارتمان 150 متری منظریه جنوبی"}
                              text={this.props.vip.estate.des +" " +convertToPersianNumber(this.props.vip.area)  +" متری " +this.props.vip.neighbourhood.name}
                              sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                ></HomeinjaTypo>
                { this.props.vip.show_price === 1 ?
                    this.props.vip.transaction_id === 3 ?
                      <Grid style={{display:'flex',flexDirection:'row'}} >
                        <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}} //price={5000000000}
                                      text={' پیش خرید'}
                                      sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                        ></HomeinjaTypo>
                        <Typography variant="h2" component="h2" style={{marginBottom:15,marginRight:10,fontFamily:"shabnam"}} //price={5000000000}
                          sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                        >
                          { parseInt(this.props.vip.prebuy)  ?
                              `${convertToPersianNumber(separator(this.props.vip.prebuy))} تومان`
                            : "توافقی"
                          }
                        </Typography>
                      </Grid>
                    : this.props.vip.transaction_id === 1 ?
                        <Grid style={{display:'flex',flexDirection:'row'}} >
                          <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}} //price={5000000000}
                                        text={' قیمت'}
                                        sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                          ></HomeinjaTypo>
                          <Typography variant="h2" component="h2" style={{marginBottom:15,marginRight:10,fontFamily:"shabnam"}} //price={5000000000}
                            sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                          >
                            { parseInt(this.props.vip.total_price) ?
                                `${convertToPersianNumber(separator(this.props.vip.total_price))} تومان`
                              : "توافقی"
                            }
                          </Typography>
                        </Grid>
                      : this.props.vip.transaction_id === 2 ?
                          <Grid>
                            <Grid style={{display:'flex',flexDirection:'row'}} >
                              <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}} //price={5000000000}
                                            text={' رهن'}
                                            sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                              ></HomeinjaTypo>
                              <Typography variant="h2" component="h2" style={{marginBottom:15,marginRight:10,fontFamily:"shabnam"}} //price={5000000000}
                                sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                              >
                                { parseInt(this.props.vip.mortgage) ?
                                    `${convertToPersianNumber(separator(this.props.vip.mortgage))} تومان`
                                  : "توافقی"
                                }
                              </Typography>
                            </Grid>
                            <Grid style={{display:'flex',flexDirection:'row'}} >
                              <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}} //price={5000000000}
                                            text={'اجاره'}
                                            sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                              ></HomeinjaTypo>
                              <Typography variant="h2" component="h2" style={{marginBottom:15,marginRight:10,fontFamily:"shabnam"}} //price={5000000000}
                                sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                              >
                                { parseInt(this.props.vip.rent) ?
                                    `${convertToPersianNumber(separator(this.props.vip.rent))} تومان`
                                  : "توافقی"
                                }
                              </Typography>
                            </Grid>
                          </Grid>
                        : <Grid style={{display:'flex',flexDirection:'row'}} >
                            <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}} //price={5000000000}
                                          text={'قیمت'}
                                          sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                            ></HomeinjaTypo>
                            <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15,marginRight:10}} //price={5000000000}
                                          text={"توافقی"}
                                          sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                            ></HomeinjaTypo>
                          </Grid>
                  : <Grid style={{display:'flex',flexDirection:'row'}} >
                      <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15}} //price={5000000000}
                                    text={'قیمت'}
                                    sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                      ></HomeinjaTypo>
                      <HomeinjaTypo variant="h2" component="h2" style={{marginBottom:15,marginRight:10}} //price={5000000000}
                                    text={"توافقی"}
                                    sx={{fontSize: {lg:'2em',md: "1.5em", sm: "1.2em", xs: "1em" }}}
                      ></HomeinjaTypo>
                    </Grid>
                } 
              </Grid>
            : <Grid item xs={12}  sx={{ marginBottom: { md: "250px", xs: "0px" } }}></Grid>
          : <Grid item xs={12}  sx={{ marginBottom: { md: "250px", xs: "0px" } }}></Grid>
        }
         
        </Grid>
        
        {/*<Grid container className="vipOneMainGrid" item alignItems="baseline" marginTop="100px"
              sx={{ width: { lg: "55%", sm: "65%", xs: "80%" } }}
        >
          {this.props.vip ? (
            <Grid container item xs={12} flexDirection="column" spacing={4} sx={{ justifyContent: { md: "flex-end", xs: "center" } }}
              alignItems="flex-start"
              className="titleContainer"
            >
              <Grid item>
                <HomeinjaTypo variant="h2" component="h2"
                              text={this.props.vip.estate.des +" " +this.props.vip.area +" متری " +this.props.vip.neighbourhood.name}
                              sx={{fontSize: { md: "48px", sm: "38px", xs: "18px" }}}
                ></HomeinjaTypo>
              </Grid>
              <Grid item>
                <HomeinjaTypo variant="h3" component="h3" price={this.props.vip.total_price}
                              sx={{fontSize: { md: "36px", sm: "28px", xs: "18px" }}}
                ></HomeinjaTypo>
              </Grid>
              <Grid item>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button variant="contained"
                          sx={{width: { md: "270px", xs: "105px" },fontSize: { md: "22px", xs: "10px" },borderRadius: "61px",backgroundColor: "#003249"}}
                  >
                    مشاهده جزئیات ملک
                  </Button>
                </Link>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}  sx={{ marginBottom: { md: "250px", xs: "0px" } }}></Grid>
          )}
          
          <Grid item container xs={12}  sx={{marginTop: { md: "50px", xs: "0px" }}} spacing={2}>
            <Grid item container xs={6}>
              <Card sx={{display: "flex",justifyContent: "center",alignItems: "center",flexWrap: "wrap"}}
              >
                <CardActionArea>
                  <Grid container justifyContent="center" alignItems="center" height="100%"
                        sx={{flexDirection: { sm: "row", xs: "column" }}}
                  >
                    <Grid item sm={5} xs={3} sx={{padding: {lg: "20px",sm: "10px",xs: "10px 0px 0px 0px"}}} justifyContent="center" alignItems="center"
                    >
                      <CardMedia sx={{ marginBottom: "0px" }} component="img" image={manWithHome} alt="manWithHome"/>
                    </Grid>
                    <Grid item xs={12} sm={7} sx={{ xl: "30px", md: "15px", xs: "0px" }}>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div"
                          sx={{textAlign: { sm: "right", xs: "center" },fontSize: { xl: "24px", md: "16px", xs: "12px" },fontWeight: "700",fontFamily: "shabnam",
                                marginBottom: { lg: "30px", md: "15px", sm: "5px" }}}
                        >
                          سپردن ملک
                        </Typography>
                        <Typography variant="body2" sx={{textAlign: { sm: "justify", xs: "center" },fontSize: { xl: "15px", md: "13px", xs: "11px" },
                                                          fontWeight: "400",fontFamily: "shabnam"}}
                        >
                          ملک خود را با بزرگترین بانک اطلاعاتی املاک به نمایش
                          بگذارید.
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item container xs={6}>
              <Card sx={{display: "flex",justifyContent: "center",alignItems: "center",flexWrap: "wrap"}}
              >
                <CardActionArea>
                  <Grid container justifyContent="center" alignItems="center" height="100%" sx={{flexDirection: { sm: "row", xs: "column" }}}
                  >
                    <Grid item sm={5} xs={3} sx={{padding: {lg: "20px",sm: "10px",xs: "10px 0px 0px 0px"}}} justifyContent="center" alignItems="center">
                      <CardMedia component="img" image={manWithSearch} alt="manWithSearch"/>
                    </Grid>
                    <Grid item xs={12} sm={7} sx={{ xl: "30px", md: "15px", xs: "0px" }}>
                      <CardContent>
                      <Typography gutterBottom variant="h5" component="div"
                          sx={{textAlign: { sm: "right", xs: "center" },fontSize: { xl: "24px", md: "16px", xs: "12px" },fontWeight: "700",fontFamily: "shabnam",
                                marginBottom: { lg: "30px", md: "15px", sm: "5px" }}}
                        >
                          سفارش ملک
                        </Typography>
                        <Typography variant="body2" sx={{textAlign: { sm: "justify", xs: "center" },fontSize: { xl: "15px", md: "13px", xs: "11px" },
                                                          fontWeight: "400",fontFamily: "shabnam"}}
                        >
                          در کمترین زمان مناسب‌ترین ملک را برای شما پیدا
                          می‌کنیم.
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          </Grid>*/}
      </Grid>
    );
  }
}

export default VIP1;
