import { Button, Grid, Typography, Link as LinkMui } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import photo01 from "../../images/mag/article43/01.jpg";
import photo02 from "../../images/mag/article43/02.jpg";
import photo03 from "../../images/mag/article43/03.jpg";
import photo04 from "../../images/mag/article43/04.jpg";
import photo05 from "../../images/mag/article43/05.jpg";
import photo06 from "../../images/mag/article43/06.jpg";
import "../../styles/article.css";
import { ArticleAd1, ArticleAd2, ArticleAd3, ArticleAd4 } from "../mag/articleAd";
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import { article as articlesData } from "../mag/articles"
import { convertToPersianNumber, setCanonicalTag } from "../materilas/vmp";
import AuthModal from "../authmodal";
import SideBar from "./SideBar";

class Article43 extends React.Component {

  constructor() {
    super();
    this.state = {
      openAuthModal: false,
      modalType: 0,
      openLinks: false
    }
  }

  componentDidMount() {
    document.getElementsByTagName('meta')["description"].content = "دریافت مجوز املاک یک فرآیند نسبتاً سریع و مقرون به صرفه است و شما با دانستن مراحل این کار، می توانید برای دریافت آن اقدام کنید. | هومینجا";
    document.getElementsByTagName('meta')["keywords"].content = "شرایط، مدارک و مراحل صدور جواز مشاور املاک ، معرفی سایت جهت اخذ انلاین جواز ، مراحل اخد فیزیکی مجوز املاک ، همه چیز در مورد صدور پروانه کسب آژانس املاک ، مراحل تاسیس و دریافت جواز آژانس املاک ، نکات مهم راه اندازی دفتر مشاور املاک ، صفر تا صد تاسیس بنگاه املاک"
    document.getElementsByTagName('meta')["author"].content = "شرکت وب گستران سورین"
    setCanonicalTag();
    this.setH1();
    this.setTitle();
  }

  componentWillUnmount() {
    document.getElementsByTagName('meta')["description"].content = "خرید و فروش ملک مسکونی، تجاری، اداری، برج،پنت هاوس، رهن و اجاره خانه، املاک، آپارتمان، پیش خرید واحد مسکونی. با هومینجا یک کلیک تا قرارداد";
  }
  setH1 = () => {
    document.getElementById("main-heading").innerHTML = articlesData[42].h1 + " | هومینجا ";
  }

  setTitle = () => {
    document.title = articlesData[42].title + " | هومینجا "
  }

  onClickTag = (tag) => {
    localStorage.setItem("articleTag", tag);
  }

  openAuth = (type) => {
    switch (type) {
      case 'L':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      case 'R':
        this.setState({ openAuthModal: true, modalType: type, });
        this.deleteActiveClass();
        break;
      default:
        break;
    }
    this.setState({ openAuthModal: true });
  }

  onCloseAuth = () => {
    this.setState({ openAuthModal: false })
  }

  onClickOpenLinks = () => {
    let toggle = !this.state.openLinks;
    this.setState({ openLinks: toggle });
  }

  render() {
    return (
      <Grid sx={{ marginTop: { xs: "60px", md: "88px" }, bgcolor: "#E7F0F3", padding: { xs: "5px", md: "15px" }, display: "flex", justifyContent: "center", flexDirection: { xs: "column", md: "row" } }}>
        <SideBar access={articlesData[42].access} />
        <Grid container sx={{ maxWidth: "1600px" }} spacing={{ xs: 0, md: 1 }}>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd1 />
            <ArticleAd3 />
          </Grid>
          <Grid item xs={12} md={7} >
            <Grid sx={{ bgcolor: "white", borderRadius: "8px", boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.1)", padding: { xs: "5px", md: "30px" } }}>
              <Grid sx={{ borderBottom: "2px solid #eee" }}>
                <Typography variant="h2" className="title">
                  {articlesData[42].h1}
                </Typography>
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-between", margin: "10px 0 20px" }}>
                <Typography className="date_author">
                  نویسنده :  {articlesData[42].author}
                </Typography>
                <Typography className="date_author">
                  {convertToPersianNumber(articlesData[42].date)}
                </Typography>
              </Grid>
              <Typography component={"img"} src={photo01} alt="پروانه کسب املاک - هومینجا" className="image" />
              <Typography className="h2" variant="h2" style={{ display: 'none' }}>
                {articlesData[42].desc}
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این مقاله سعی داریم شما را با مراحل، شرایط، مدارک و نحوه دریافت جواز تاسیس و کسب آژانس املاک آشنا کنیم. پس با <Link className="link" to="/HomeMag" target="_blank"> هومینجا </Link> همراه باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                مقدمه :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo02} alt="صدور جواز املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  صدور جواز مشاور املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                به عنوان یک <Link className="link" to="/real-estate" target="_blank">  مشاور املاک </Link> تنوع زیادی در کارتان خواهید داشت. هر روز باید از خانه‌ها مختلف بازدید کرده و با مشتریان متفاوتی سر و کار داشته باشید. ورود به چنین شغلی معمولا آسان است و شما می‌توانید با داشتن یک سری مهارت‌های ارتباطی وارد این عرصه شوید. دریافت مجوز املاک یک فرآیند نسبتاً سریع و مقرون به صرفه است و کافی است با دانستن مراحل این کار، برای دریافت آن اقدام کنید. اگر می‌خواهید آژانس املاک داشته باشید، باید سرمایه‌گذاری اولیه را در دستور کار قرار دهید که این امر بسته به جایی که مجوز خود را از آنجا دریافت می‌کنید، متفاوت است.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                داشتن آژانس املاک جزء مشاغل پر درآمد به حساب می‌آيد. شما باید به صورت قانونی جواز کسب دریافت کنید تا بتوانید در این شغل وارد شوید. همچنین شما می‌توانید به عنوان مشاوره املاک در آژانس فعالیت داشته باشید. مشاور املاک شخصی است که به افراد در فروش، اجاره و مدیریت املاک خود کمک می‌کند؛ برای اینکه به عنوان یک مشاور املاک و مستغلات مشغول به کار شوید، باید مجوز کار املاک را دریافت کنید که به شما امکان می‌دهد به عنوان مشاور املاک در کشور فعالیت کنید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/amlak.mrud.ir" target="_blank">
                  بیشتر بخوانید : معرفی سامانه ملی املاک و اسکان کشور
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                شرایط صدور مجوز مشاور املاک :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                قبل از این‌که بتوانید مشاور املاک شوید باید برای کسب جواز آژانس املاک اقدام نمایید تا بتوانید با خیال راحت وارد این حوزه شوید و منبع درآمد داشته باشید. برای دریافت چنین جوازی باید مهارت‌هایی را نیز بدانید. اتحادیه مشاورین املاک برای داوطلبان دوره‌های تخصصی برگزار می‌کند تا بتوانند این افراد مهارت‌های خود را سنجیده و در صورت قبولی بتوانند وارد مراحل دیگری شوند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                درست است که مشاور املاک به ظاهر حرفه ساده و راحتی بیاید، اما اگر کسی بدون مهارت‌های لازم وارد این حرفه شود، شکست می‌خورد. چون نه تنها درآمدی به دست نمی‌آورد بلکه امکان تغییر دادن شغل در این کار زیاد است. برای تبدیل شدن به <Link className="link" to="/professional-real-estate" target="_blank">  مشاور املاک حرفه ای </Link> راه زیادی در پیش دارید. در نتیجه این اتحادیه اعلام کرده ابتدا باید دوره‌های تخصصی را بگذرانند و سپس برای کسب جواز اقدام کنند.
              </Typography>
              <Typography className="h2" variant="h2">
                مدارک و مراحل صدور جواز مشاور املاک :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo03} alt="مدارک صدور جواز - هومینجا" className="image" />
                <figcaption className="image_caption">
                  مدارک صدور جواز مشاور املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در این قسمت بصورت کلی مراحل دریافت جواز کسب آژانس املاک توضیح می دهیم.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                متقاضی باید به صورت کتبی درخواست صدور مجوز کند. سپس کد رهگیری را می‌گیرند و <LinkMui className="link" href="https://amlaktehran.org/" target="_blank"> اتحادیه </LinkMui> از ملک آن‌ها بازدید می‌کند.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                مدارک باید برای تعیین شدن توسط اتحادیه مورد بررسی قرار بگیرد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نیروی انتظامی نیز باید تایید صلاحیت کند. همچنین سوابق و تایید شدن مدارک از الزامات است.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                پرونده باید توسط رییس اتحادیه آژانس املاک به تایید صلاحیت برسد.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دریافت کسب و تحویل آن به مشاور املاک
              </Typography>
              <Grid className="box">
                <Link className="link" to="/house-tracking-code" target="_blank">
                  بیشتر بخوانید : کد رهگیری املاک چیست؟
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                الزاماتی که باید هنگام دریافت جواز بدانید :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همیشه شنیده‌ایم که از هر ۵ مشاور املاک ۴ نفر شکست خورده‌اند، ولی کسانی که نحوه راه اندازی اصولی آژانس املاک را بداند، نباید نگران آینده کاری خود باشند. زمانی که مشاور املاک می‌شوید، به نحوی رئیس خود هستید و می‌توانید نسبت به سایر شغل‌ها درآمد زیادی داشته باشید. تجارت کردن یکی از ویژگی‌های بارز مشاور املاک است. همچنین شما باید برنامه برای کار خود داشته باشید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                داشتن مهارت‌های ارتباطی و توانایی صحبت کردن به‌ گونه‌ای که مشتریان متقاعد شوند تا با شما کار کنند، از جمله موارد مهمی است که باید در نظر داشته باشید. زمانی که می‌خواهید ملک‌های مختلف را <Link className="link" to="/Listings/buy_tehran" target="_blank">  خرید و فروش </Link> کنید، لازم است تا قدرت بیان خوبی داشته باشید. به همین دلیل لازم است شرایطی داشته باشید که بتوانید اقدام به کسب جواز آژانس املاک نمایید و باید بدانید که دریافت پروانه کسب‌وکار یک فرایند طولانی و سخت است. به همین دلیل باید به صورت اصولی جلو روید تا در مراحل بعدی دچار مشکل نشوید.
              </Typography>
              <Typography className="h2" variant="h2">
                شرایط لازم برای کسب جواز آژانس املاک :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                در زیر شرایط لازم و اولیه برای کسب جواز را مشاهده کنید:
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حداقل سن ۱۸
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                متاهل بودن
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                حداقل داشتن مدرک دیپلم
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گذراندن دوره سربازی یا داشتن برگه معافیت
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                گذراندن دوره‌های تخصصی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                دریافت تاییدیه توسط اتحادیه آژانس املاک
              </Typography>
              <Typography className="h2" variant="h2">
                مدارک مورد نیاز برای دریافت جواز کسب آژانس املاک :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo04} alt="جواز کسب آژانس املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  جواز کسب املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                موارد زیر به عنوان مدارک مورد نیاز جهت دریافت جواز مشاوران الزامیست.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل تمامی صفحات شناسنامه
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل پشت و روی کارت ملی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                اصل و تصویر گذرنامه، اقامت معتبر و پروانه کار
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                نشان دادن کد پستی و تاییدیه این کد از سمت اداره پست
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                ۱۲ قطعه عکس پرسنلی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                کپی مدارک تحصیلی
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                واریزی هزینه تعیین شده به حساب خزانه
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                همچنین مدارک دیگری نیز باید ارائه دهید تا توسط نیروی انتظامی مورد بررسی قرار بگیرد. این مدارک در ادامه بیان شده است و باید آن‌ها را داشته باشید. این مدارک شامل گواهی عدم اعتیاد است. همچنین داشتن گواهی عدم سوء پیشینه کیفری از دیگر مدارک الزامی است که باید به نیروی انتظامی تحویل دهید. یکی از مراحل کسب جواز گرفتن تاییدیه بازرس‌های نیروی انتظامی است که به ملک شما مراجعه می‌کنند و آن را بررسی می‌نمایند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                از آن‌جایی که باید دوره‌های تخصصی را بگذرانید ارائه این گواهی‌ به اتحادیه الزامی است. اگر در امتحان مردود شوید، باید دوباره در امتحان شرکت کنید، اما در صورت قبولی، مجوز کارگزاری مسکن برای شما صادر می‌شود. به طور کلی برای درخواست مجوز فعالیت املاک و مستغلات باید یک سابقه کیفری تمیز داشته باشید. بررسی پیشینه شامل انگشت نگاری شما نیز می شود و مجوز ملک باید بین ۹۰ روز پس از دریافت مورد بررسی پیشینه قرار داده شود.
              </Typography>
              <Typography className="h2" variant="h2">
                نکات مهم راه اندازی دفتر یا بنگاه مشاور املاک
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                راه اندازی آژانس املاک و مستغلات می‌تواند سودمندترین شعلی باشد که شما در زندگی شخصی و مالی خود انجام داده‌اید. در حالی که موارد زیادی وجود دارد که باید برای اطمینان از موفقیت کسب و کار در نظر بگیرید، در ادامه مراحل ضروری برای کمک به شما در شروع کار وجود دارد. همان طور که گفته شد؛ دوره‌‌های تخصصی را باید به خوبی بگذرانید و طرح کسب‌وکار را تهیه کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مانند هر کسب‌وکار جدید، آماده‌ بودن در این کار ارزش زیادی دارد. اگر می‌خواهید آژانس شما موفق شود، تنظیم یک طرح تجاری ضروری است. این طرح باید اهداف، استراتژی‌ها، رشد و سرمایه مورد نیاز کسب و کار شما را مشخص کند. قرار دادن همه چیز در یک سند مکتوب به شما کمک می‌کند تا تصمیمات استراتژیک خود در مورد چگونگی دستیابی به موفقیت تجاری در سمت مشاور املاک را بگیرید. مراحلی که در ادامه می‌آیند، همه حوزه‌هایی هستند که باید در این طرح بگنجانید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تحقیق در زمینه بازار و رقابت در زمینه <Link className="link" to="/Agancy" target="_blank">  آژانس املاک </Link> بسیار حیاتی است؛ انجام تحقیقات یک گام مهم در راه اندازی آژانس املاک و مستغلات شما است. شناخت بازاری که وارد آن می‌شوید و رقبای موجود آن به شما امکان می‌دهد فرصت‌ها و تهدیدهای درک شده را بسنجید. همچنین شکاف‌های موجود در بازار را شناسایی ‌کنید تا بتوانید آن را با ارائه خدمات پر کنید. در نتیجه، یافته‌های تحقیق به شما کمک می‌کند تا تصمیمات استراتژیک در مورد آژانس خود بگیرید.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/apartment-pre-sale" target="_blank">
                  بیشتر بخوانید : نکات ضروری برای پیش خرید و پیش فروش آپارتمان
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                مدل کسب و کار املاک و مستغلات را انتخاب کنید
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo05} alt="کسب و کار املاک و مستغلات - هومینجا" className="image" />
                <figcaption className="image_caption">
                  کسب و کار املاک و مستغلات
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                پس از تکمیل تحقیقات خود، می‌توانید بهترین مدل کسب و کار املاک و مستغلات را برای خود انتخاب کنید. هنگام انتخاب مدل مناسب باید به نکات زیادی توجه کرد. آیا آژانس شما مدیریت فروش و املاک را ارائه می‌دهد یا فقط در ارائه یک سرویس تخصص دارد؟ آیا یک دفتر مجازی هستید یا فروشگاه فیزیکی خواهید داشت؟ شما باید این تصمیمات را با توجه به یافته ها، تخصص، منابع، اندازه شرکت و برنامه‌های رشد خود بگیرید. همچنین رقابتی پایدار را به وجود بیاورید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تحقیقات شما باید فرصت‌هایی را پدید بیاورد که املاک شما را با دیگران متمایز کند. این فرصت‌ها شکاف‌هایی در بازار هستند که هیچ آژانس املاک دیگری آن را پر نکرده است. پر کردن این شکاف‌ها می‌تواند به یک ویژگی رقابتی پایدار آژانس شما تبدیل شود. مثلا می‌توانید خدماتی به مشتریان خود دهید که می‌دانید رقبای شما حاضر نیستند آن را انجام دهند. شما باید ساختار حقوقی درستی را اجرا کنید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                مهم است که آژانس املاک و مستغلات شما ساختار قانونی مناسبی داشته باشد. ساختار شما بر مالیات، سود، زیان، استراتژی‌های خروج و تعهدات قانونی شما تأثیر می‌گذارد. برای یافتن بهترین ساختار برای آژانس خود، باید با حسابدار و وکیل خود صحبت کنید. اگر ساختار حقوقی شما ثابت نیست و ممکن است با رشد کسب و کارتان تغییر کند، ضروری است که برای شروع ساختار حقوقی مناسبی داشته باشید.
              </Typography>
              <Typography className="h2" variant="h2">
                پیش بینی هزینه‌ها و درآمد از آژانس املاک :
              </Typography>
              <figure className="image_wrapper">
                <Typography component={"img"} src={photo06} alt="هزینه و درآمد آژانس املاک - هومینجا" className="image" />
                <figcaption className="image_caption">
                  درآمد آژانس املاک
                </figcaption>
              </figure>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                اکنون زمان خوبی است که برای تاسیس آژانس املاک حساب و کتاب کنید. تهیه صورت جریان نقدی و پیش بینی درآمد به شما نشان می‌دهد که برای شروع و رشد کسب و کار خود به چه چیزهایی نیاز دارید. پیش‌بینی هزینه‌ها، درآمد و سود به شما امکان می‌دهد ببینید چه زمانی به اعضای تیم و منابع جدید نیاز خواهید داشت. این دانش به شما کمک می‌کند تا تصمیمات آگاهانه‌ای در مورد رشد پایدار کسب و کار خود بگیرید.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                بهتر است که برنامه بازاریابی خود را نیز تهیه کنید. تنظیم یک برنامه بازاریابی، به اندازه انجام طرح کسب‌وکار شما مهم است. برنامه بازاریابی شما باید ناشی از تحقیقات شما باشد تا بتوانید یک آژانس املاک منحصر به فردی بسازید. همچنین، باید استراتژی‌ها و کانال‌های تبلیغاتی خود را مشخص کنید. به عنوان مثال، باید در نظر بگیرید که چگونه رسانه‌های اجتماعی، بازاریابی مستقیم، روابط عمومی و بازاریابی محتوا می‌توانند برای آژانس شما آگاهی ایجاد کنند.
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                راه اندازی یک آژانس املاک موفق به معنای ساختن یک تیم موفق است. برای اطمینان از اینکه کارمندان شما با کسب و کار شما رشد می‌کنند، هر کدام باید یک برنامه آموزشی و انگیزشی داشته باشند. نگه داشتن تیم خود در صدر بازی با آموزش مداوم و پاداش‌ها راهی عالی برای افزایش بهره وری، عملکرد و سود است.
              </Typography>
              <Grid className="box">
                <Link className="link" to="/Business-Right" target="_blank">
                  بیشتر بخوانید : سرقفلی مغازه و تمام نکاتی که باید در نظر گرفت
                </Link>
              </Grid>
              <Typography className="h2" variant="h2">
                نتیجه گیری :
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تمامی مراحل لازم برای اخذ مجوز کسب آژانس دیجیتال گفته شد. شما باید ابتدا واجد شرایط گفته شده باشید و سپس مدارک خود را آماده کنید و به اتحادیه مربوطه تحویل دهید. همچنین برای اخذ آنلاین می‌توانید از سایت‌های زیر استفاده کنید.
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://enamad.ir" target="_blank">  enamad.ir </LinkMui>
              </Typography>
              <Typography className="p" component="li" sx={{ textAlign: 'justify' }}>
                <LinkMui className="link" href="https://ecunion.ir/" target="_blank">  ecunion.ir </LinkMui>
              </Typography>
              <Typography className="h2" variant="h2">
                کلام آخر
              </Typography>
              <Typography className="p" sx={{ textAlign: 'justify' }}>
                تیم محتوای هومینجا همواره در تلاش است تا اطلاعات مناسبی در اختیار کاربران قرار دهد. با دنبال کردن <Link className="link" to="/" target="_blank">  وب سایت </Link> و هم چنین <LinkMui className="link" href="https://homeinja.info" target="_blank">  بلاگ هومینجا </LinkMui> از اخرین اخبار و مطالب جذاب ما مطلع شوید. ما در این مقاله سعی کردیم شما را با زیر و بم دریافت <Link className="link" to="/Real-Estate-Agency-License" target="_blank">  جواز آژانس مشاور املاک </Link> آشنا کنیم. امیدواریم که این مطلب برای شما جذاب بوده باشد.
              </Typography>
              <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", margin: "20px 0" }}>هم چنین ممکن است مطالب زیر برای شما مفید باشد :</Typography>
              <Grid sx={{ bgcolor: "#eee", textAlign: "center" }}>
                <Link to={'/Housing-Market-Prediction-1402'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/mehr-house'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/real-estate-fraud'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    انواع کلاهبرداری در معاملات ملک
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/buying-house-in-Tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    نکات مهم در مورد خرید ملک در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/buy_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    آگهی خرید و فروش املاک و آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
                <Link to={'/Listings/rent_tehran'} className="zone_link" target="_blank">
                  <Typography className="zone_link_text">
                    آگهی رهن و اجاره املاک و آپارتمان در تهران
                    <SearchRoundedIcon className="zone_link_icon" />
                  </Typography>
                </Link>
              </Grid>



              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography sx={{ color: "#4B4B4B", padding: "5px", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px" }}>برچسب ها :</Typography>
                <Button component={Link} to={"/HomeMag"} onClick={() => this.onClickTag("مسائل حقوقی ")}
                  sx={{ color: "#4B4B4B", bgcolor: "#E7F0F3", margin: "5px", fontFamily: "shabnam", borderRadius: "5px", fontSize: "14px", "&:hover": { bgcolor: "#E7F0F3" } }}
                >
                  مسائل حقوقی

                </Button>
              </Grid>
              <Grid sx={{ height: "2px", bgcolor: "#eee" }}></Grid>
              <Grid sx={{ padding: "20px 0" }}>
                <Typography sx={{ fontFamily: "shabnam", color: "#C5CCCE", textAlign: "center" }}>
                  اشتراک گذاری
                </Typography>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkMui href="whatsapp://send?text=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <WhatsAppIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://twitter.com/share?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TwitterIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                  <LinkMui href="https://t.me/share/url?url=https://homeinja.com/tehran-winter-entertaiments" target="_blank"
                    sx={{ color: "#C5CCCE", "&:hover": { color: "gray" }, transition: "0.3s all" }}
                  >
                    <TelegramIcon sx={{ fontSize: '50px', color: 'inherit !important' }} />
                  </LinkMui>
                </Grid>
              </Grid>
              {localStorage.getItem('infoUserHomeInja') ?
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/pl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سپردن ملک</Typography>
                      <AddBusinessRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button component={Link} to={"/profile/rl"}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>سفارش ملک</Typography>
                      <HouseRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
                : <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('L')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ورود</Typography>
                      <PersonRoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button onClick={() => this.openAuth('R')}
                      sx={{
                        border: "2px solid #0F92A9", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0", width: "100%", transition: "all 0.5s",
                        "&:hover": { border: "2px solid white", bgcolor: "#0F92A9", "& p": { color: "white" }, "& svg": { color: "white !important" } }
                      }}
                    >
                      <Typography sx={{ fontFamily: "shabnam", fontWeight: "bold", color: "#0F92A9" }}>ثبت نام</Typography>
                      <PersonAddAlt1RoundedIcon sx={{ fontSize: "40px", color: "#0F92A9 !important" }} />
                    </Button>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid item xs={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <ArticleAd2 />
            <ArticleAd4 />
          </Grid>
        </Grid>
        <AuthModal open={this.state.openAuthModal} close={() => this.onCloseAuth()} type={this.state.modalType} />
      </Grid>
    )
  }
}

export default Article43;