import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import sabtAgahiIcon from "../images/services/sabtAgahiIcon.png";
import cameraIcon from "../images/services/cameraIcon.png";
import gharardadIcon from "../images/services/gharardadIcon.png";
import moshaveranIcon from "../images/services/moshaveranIcon.png";
import searchHomeIcon from "../images/services/searchHomeIcon.png";
import siteDesign from "../images/services/siteDesign.png";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';


class Services extends React.Component {

    scrollTopHandler = () => {
        window.scrollTo({top:0, behavior:"smooth"});
    }

    render(){
        return(
            <Box sx={{padding:{xs:"30px 5% 0 5%",md:"100px 0 0 0"}}}>
                <Box sx={{textAlign:"center"}}>
                    <Typography variant='h2' component='h2' align='center'
                        sx={{fontSize: {xs: '16px', md: "30px"}, fontWeight: "700", fontFamily: "shabnam", padding: {xs: "20px 0", md: "30px 0"}, color: "#003249"}}
                    >
                        خدمات هومینجا
                    </Typography>   
                    <Grid container sx={{padding:{xs:"0",sm:"4%",md:"0 25% 30px 25%"}}}>
                        <Grid item xs={6} md={4}  
                            sx={{padding:"2%",borderLeft:"3px solid #eee",borderBottom:"3px solid #eee"}}
                        >
                            <Box sx={{width:"30%",margin:"auto"}}>
                                <Typography component={"img"} src={sabtAgahiIcon} alt="service" sx={{width:"100%",height:"auto"}}></Typography>
                            </Box>
                            <Typography sx={{fontSize:{xs:"10px",md:"20px"},fontFamily:"shabnam",fontWeight:"700",padding:"10px 0"}}>ثبت آگهی ملک </Typography>
                            <Typography sx={{fontSize:{xs:"10px",md:"16px"},fontFamily:"shabnam",fontWeight:"500"}}>
                                در تخصصی ترین سایت املاک، رایگان آگهی ملک ثبت کنید.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} 
                            sx={{padding:"2%",borderLeft:{xs:"none",md:"3px solid #eee"},borderRight:"3px solid #eee",borderBottom:"3px solid #eee"}}
                        >
                            <Box sx={{width:"30%",margin:"auto"}}>
                                <Typography component={"img"} src={searchHomeIcon} alt="service" sx={{width:"100%",height:"auto"}}></Typography>
                            </Box>
                            <Typography sx={{fontSize:{xs:"10px",md:"20px"},fontFamily:"shabnam",fontWeight:"700",padding:"10px 0"}}>درخواست ملک</Typography>
                            <Typography sx={{fontSize:{xs:"10px",md:"16px"},fontFamily:"shabnam",fontWeight:"500"}}>
                                در کمترین زمان، مناسبترین ملک را به شما پیشنهاد می دهیم 
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} 
                            sx={{padding:"2%",borderRight:{xs:"none",md:"3px solid #eee"},borderBottom:"3px solid #eee",borderTop:{xs:"3px solid #eee",md:"none"},borderLeft:{xs:"3px solid #eee",md:"none"}}}
                        >
                            <Box sx={{width:"30%",margin:"auto"}}>
                                <Typography component={"img"} src={cameraIcon} alt="service" sx={{width:"100%",height:"auto"}}></Typography>
                            </Box>
                            <Typography sx={{fontSize:{xs:"10px",md:"20px"},fontFamily:"shabnam",fontWeight:"700",padding:"10px 0"}}>مالکان</Typography>
                            <Typography sx={{fontSize:{xs:"10px",md:"16px"},fontFamily:"shabnam",fontWeight:"500"}}>
                                عکاسی رایگان و تهیه کاتالوگ از ملک شما وظیفه ماست. 
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} 
                            sx={{padding:"2%",borderLeft:{xs:"none",md:"3px solid #eee"},borderRight:{xs:"3px solid #eee",md:"none"},borderTop:"3px solid #eee",borderBottom:{xs:"3px solid #eee",md:"none"}}}
                        >
                            <Box sx={{width:"30%",margin:"auto"}}>
                                <Typography component={"img"} src={gharardadIcon} alt="service" sx={{width:"100%",height:"auto"}}></Typography>
                            </Box>
                            <Typography sx={{fontSize:{xs:"10px",md:"20px"},fontFamily:"shabnam",fontWeight:"700",padding:"10px 0"}}>عقد قرار داد</Typography>
                            <Typography sx={{fontSize:{xs:"10px",md:"16px"},fontFamily:"shabnam",fontWeight:"500"}}>
                                قراردادهای ملکی خود را با تخفیف عقد کنید.
                            </Typography>
                            </Grid>
                        <Grid item xs={6} md={4} 
                            sx={{padding:"2%",borderLeft:"3px solid #eee",borderRight:{xs:"none",md:"3px solid #eee"},borderTop:"3px solid #eee"}}>
                            <Box sx={{width:"30%",margin:"auto"}}>
                                <Typography component={"img"} src={moshaveranIcon} alt="service" sx={{width:"100%",height:"auto"}}></Typography>
                            </Box>
                            <Typography sx={{fontSize:{xs:"10px",md:"20px"},fontFamily:"shabnam",fontWeight:"700",padding:"10px 0"}}>مشاوران وابسته هومینجا</Typography>
                            <Typography sx={{fontSize:{xs:"10px",md:"16px"},fontFamily:"shabnam",fontWeight:"500"}}>
                                با پیوستن به تیم هومینجا از معاملات ملک درآمد کسب کنید. 
                            </Typography>
                            </Grid>
                        <Grid item xs={6} md={4} 
                            sx={{padding:"2%",borderRight:"3px solid #eee",borderTop:"3px solid #eee"}}
                        >
                            <Box sx={{width:"30%",margin:"auto"}}>
                                <Typography component={"img"} src={siteDesign} alt="service" sx={{width:"100%",height:"auto"}}></Typography>
                            </Box>
                            <Typography sx={{fontSize:{xs:"10px",md:"20px"},fontFamily:"shabnam",fontWeight:"700",padding:"10px 0"}}>مشاوران و مدیران آژانس</Typography>
                            <Typography sx={{fontSize:{xs:"10px",md:"16px"},fontFamily:"shabnam",fontWeight:"500"}}>
                                افزایش فروش با کمترین هزینه را با ما تجربه کنید.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{padding:{xs:"20px 0",md:"25px 0 25px"}}}>
                    <Box onClick={this.scrollTopHandler} 
                        sx={{bgcolor:"#002a45de",position:"relative",width:{xs:"40px",md:"60px"},height:{xs:"40px",md:"60px"},borderRadius:"50%",margin:"auto",cursor:"pointer"}}
                    >
                        <KeyboardArrowUpRoundedIcon sx={{fontSize:{xs:"40px",md:"60px"},position:"absolute",top:{xs:"-5px",md:"-7px"},color:"white !important"}} />
                        <KeyboardArrowUpRoundedIcon sx={{fontSize:{xs:"40px",md:"60px"},position:"absolute",top:{xs:"5px",md:"7px"},color:"white !important"}} />
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default Services;